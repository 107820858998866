import Api from '../Api';
import { useMutation } from 'react-query';
import { IExportDailyPunches, IExportRangePunches } from '../../types';
export const useExportDailyPunches = () => {
  const {
    mutate: exportDailyPunches,
    isLoading,
    isSuccess,
    isError,
  } = useMutation((data: IExportDailyPunches) =>
    Api.SiteTaktExporting.exportDailyPunches(data),
  );

  return { exportDailyPunches, isLoading, isSuccess, isError };
};

export const useExportRangePunches = () => {
  const {
    mutate: exportRangePunches,
    isLoading,
    isSuccess,
    isError,
  } = useMutation((data: IExportRangePunches) =>
    Api.SiteTaktExporting.exportRangePunches(data),
  );

  return { exportRangePunches, isLoading, isSuccess, isError };
};
