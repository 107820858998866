import React from 'react';
import { Button, Typography } from '@mui/joy';

interface INotificationCardButtonProps {
  children: string;
  onClick: () => void;
}

export const NotificationCardButton = ({
  children,
  onClick,
}: INotificationCardButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="soft"
      sx={{
        px: 0.5,
        pt: 0.2,
        pb: 0.2,
        ml: 0.5,
        minHeight: 0,
        borderRadius: '4px',
      }}
    >
      <Typography fontWeight="normal" fontSize="0.75rem">
        {children}
      </Typography>
    </Button>
  );
};
