import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { ICountResponse, IOtherCostsModel, IOtherCostsState } from '../types';

// default state
const defaultState: IOtherCostsState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  yearNumber: NaN,
  weekNumber: NaN,
  yearWeek: NaN,
  isRepeatable: false,
  value: NaN,
  description: '',
  list: [],
  count: 0,
  is_other_costs_list_loading: false,
  is_other_costs_count_loading: false,
};

/**
 * Flush actions
 */
const flushOtherCostsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createOtherCostsRequest = (state: IOtherCostsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createOtherCostsSuccess = (
  state: IOtherCostsState,
  payload: IOtherCostsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createOtherCostsFailed = (state: IOtherCostsState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getOtherCostsListRequest = (state: IOtherCostsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_other_costs_list_loading: true,
});

const getOtherCostsListSuccess = (
  state: IOtherCostsState,
  payload: IOtherCostsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
  is_other_costs_list_loading: false,
});

const getOtherCostsListFailed = (state: IOtherCostsState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  is_other_costs_list_loading: false,
});

/**
 * Get count actions
 */
const getOtherCostsCountRequest = (state: IOtherCostsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_other_costs_count_loading: true,
});

const getOtherCostsCountSuccess = (
  state: IOtherCostsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  is_other_costs_count_loading: false,
});

const getOtherCostsCountFailed = (
  state: IOtherCostsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  is_other_costs_count_loading: false,
});

/**
 * Deletion actions
 */
const deleteOtherCostsRequest = (state: IOtherCostsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteOtherCostsFailed = (state: IOtherCostsState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateOtherCostsRequest = (state: IOtherCostsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateOtherCostsFailed = (state: IOtherCostsState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * OtherCosts reducer
 */
export const otherCosts = createReducer<IOtherCostsState>({}, defaultState)
  // flush actions
  .on(actions.flushOtherCostsState, flushOtherCostsState)
  // creation actions
  .on(actions.createOtherCostsRequest, createOtherCostsRequest)
  .on(actions.createOtherCostsSuccess, createOtherCostsSuccess)
  .on(actions.createOtherCostsFailed, createOtherCostsFailed)
  // get list actions
  .on(actions.getOtherCostsListRequest, getOtherCostsListRequest)
  .on(actions.getOtherCostsListSuccess, getOtherCostsListSuccess)
  .on(actions.getOtherCostsListFailed, getOtherCostsListFailed)
  // get count actions
  .on(actions.getOtherCostsCountRequest, getOtherCostsCountRequest)
  .on(actions.getOtherCostsCountSuccess, getOtherCostsCountSuccess)
  .on(actions.getOtherCostsCountFailed, getOtherCostsCountFailed)
  // deletion actions
  .on(actions.deleteOtherCostsRequest, deleteOtherCostsRequest)
  .on(actions.deleteOtherCostsFailed, deleteOtherCostsFailed)
  // updating actions
  .on(actions.updateOtherCostsRequest, updateOtherCostsRequest)
  .on(actions.updateOtherCostsFailed, updateOtherCostsFailed);

export default otherCosts;
