import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ILoggedInModel, ISupervisorViewState } from '../../types';

// default state
const defaultState: ISupervisorViewState = {
  refreshKey: 0,
  loggedInList: [],
  isLoggedInListDataLoading: false,
};

/**
 * Flush actions
 */
const flushSupervisorViewState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Get list actions
 */
const getLoggedInListRequest = (state: ISupervisorViewState) => ({
  ...state,
  error: false,
  isLoggedInListDataLoading: true,
});

const getLoggedInListSuccess = (
  state: ISupervisorViewState,
  payload: ILoggedInModel[],
) => ({
  ...state,
  loggedInList: payload,
  error: false,
  isLoggedInListDataLoading: false,
});

const getLoggedInListFailed = (
  state: ISupervisorViewState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isLoggedInListDataLoading: false,
});

/**
 * SupervisorView reducer
 */
export const supervisorView = createReducer<ISupervisorViewState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushSupervisorViewState, flushSupervisorViewState)
  // get logged in employees list actions
  .on(actions.getLoggedInListRequest, getLoggedInListRequest)
  .on(actions.getLoggedInListSuccess, getLoggedInListSuccess)
  .on(actions.getLoggedInListFailed, getLoggedInListFailed);

export default supervisorView;
