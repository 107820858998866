import { createAction } from 'redux-act';

// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';
import { filterForGlobalSitesDropdown } from '../../utils/helpers/filter';

import {
  ICountResponse,
  ICreateSiteRequest,
  IdsArray,
  IFilter,
  ISetArchiveStatusArgs,
  ISiteModel,
  IUpdateSite,
  PayloadWithFilters,
} from '../../types';

// Flush actions
export const flushSiteState = createAction('Flush site data in store');

// Creation actions
export const createSiteRequest = createAction<
  PayloadWithFilters<ICreateSiteRequest>
>('Make request to create a new site');
export const createSiteSuccess = createAction<ISiteModel>(
  'New site has been created successfully',
);
export const createSiteFailed = createAction('Cannot create a new site');

// Fetch sites actions
export const getSiteListRequest = createAction<IFilter>(
  'Make request to fetch site list',
);
export const getSiteListSuccess = createAction<ISiteModel[]>(
  'Sites list has been fetched successfully',
);
export const getSiteListFailed = createAction('Cannot fetch site list');

// get sites count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getSiteCountRequest = createAction<IFilter>(
  'Make request to get site count',
);
export const getSiteCountSuccess = createAction<ICountResponse>(
  'Sites count has been fetched successfully',
);
export const getSiteCountFailed = createAction('Cannot get site count');

// delete actions
export const deleteSiteRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete sites',
);
export const deleteSiteSuccess = createAction('Sites successfully deleted');
export const deleteSiteFailed = createAction('Cannot delete sites');

export const setArchivedStatusOfSiteRequest = createAction<
  PayloadWithFilters<Array<ISetArchiveStatusArgs>>
>('Make request to set archive status of sites');
export const setArchivedStatusOfSiteSuccess = createAction<
  Array<ISetArchiveStatusArgs>
>('Successfully set archive status');
export const setArchivedStatusOfSiteFailed = createAction(
  'Cannot set archive status sites',
);

// Updating actions
export const updateSitesRequest = createAction<
  PayloadWithFilters<IUpdateSite[]>
>('Make request to update sites');
export const updateSitesFailed = createAction('Cannot update sites');
export const updateSitesSuccess = createAction('Sites successfully updated');

// UpdateDesiredEmployeesNumber actions
export const updateSitesDesiredEmployeesNumberRequest = createAction<
  PayloadWithFilters<
    Pick<IUpdateSite, 'id' | 'requiredAssociates' | 'absenteeism'>[]
  >
>('Make request to update UpdateDesiredEmployeesNumber');
export const updateSitesDesiredEmployeesNumberFailed = createAction(
  'Cannot update UpdateDesiredEmployeesNumber',
);
export const updateSitesDesiredEmployeesNumberSuccess = createAction(
  'UpdateDesiredEmployeesNumber success',
);

// Fetch combobox list actions
export const getSiteComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of sites',
);
export const getSiteComboboxListSuccess = createAction<Partial<ISiteModel>[]>(
  'Combobox list of sites has been fetched successfully',
);
export const getSiteComboboxListFailed = createAction(
  'Cannot fetch combobox sites list',
);

// Fetch sites for global dropdown list actions
export const getSiteForGlobalDropdownRequest = createAction<IFilter>(
  'Make request to fetch sites for global dropdown',
  // if not filter passed, use 'filterForGlobalSitesDropdown' by default
  (payload) => payload?.filter ?? filterForGlobalSitesDropdown,
);
export const getSiteForGlobalDropdownSuccess = createAction<{
  globalDropdownList: ISiteModel[];
  globallySelected: number[];
}>('Sites for global dropdown has been fetched successfully');
export const getSiteForGlobalDropdownFailed = createAction(
  'Cannot fetch sites for global dropdown',
);
// Set globally selected sites
export const setGloballySelectedSites = createAction<number[]>(
  'Successfully selected sites globally',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Site').takeExportActions();

export const exportSiteRequest = exportReportRequest;
export const exportSiteSuccess = exportReportSuccess;
export const exportSiteFailed = exportReportFailed;
