import React from 'react';
import { chain, get } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';
import {
  AnyObject,
  IManualProductionSettings,
  ProductionSettingConfig,
} from '../../types';

export const productionBaseFields = {
  employee: {
    title: 'Employee',
    enabled: true,
  },
  shift: {
    title: 'Shift',
    enabled: true,
  },
  bom: {
    title: 'BOM',
    enabled: true,
  },
  date: {
    title: 'Date',
    enabled: true,
  },
  qty: {
    title: 'QTY',
    enabled: true,
  },
  container: {
    title: 'Container',
    enabled: true,
  },
  isPartialContainer: {
    title: 'Partial',
    enabled: false,
  },
  lineOrDoor: {
    title: 'Line Or Door',
    enabled: true,
  },
  comment: {
    title: 'Comment',
    enabled: true,
  },
  startTime: {
    title: 'Start Time',
    enabled: true,
  },
  endTime: {
    title: 'End Time',
    enabled: true,
  },
};

const getSettingsFetchParameters = (siteId: number) => {
  const filter = { filter: { where: { siteId: { eq: siteId } } } };
  const queryKey = getQueryKey('/production-settings', filter);

  return { filter, queryKey };
};

export const useProductionSettings = (siteId: number) => {
  const { filter, queryKey } = getSettingsFetchParameters(siteId);
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => Api.Production.getSettings(filter),
    { enabled: Boolean(siteId), retry: false, cacheTime: 0 },
  );

  const config = React.useMemo<IManualProductionSettings>(
    () => data ?? ({} as IManualProductionSettings),
    [data],
  );

  return {
    config,
    isLoading,
    error,
  };
};

export const useProductionSettingsFields = (siteId: number) => {
  const { config, isLoading } = useProductionSettings(siteId);

  const metatags = React.useMemo(
    () =>
      chain(config.metatagColumns)
        .reduce((acc, cur) => {
          const mt = get(config, ['settings', 'metatags', cur.name]);

          if (mt) {
            acc[cur.name] = { ...mt };
          } else {
            acc[cur.name] = {
              type: 'text',
              name: cur.name,
              title: cur.name,
              options: [],
            };
          }

          return acc;
        }, {})
        .value(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.metatagColumns, config.settings?.metatags],
  );

  const fields = React.useMemo(
    () =>
      chain(Object.keys(productionBaseFields))
        .reduce((acc, cur) => {
          acc[cur] = get(
            config.settings?.fields && config.settings?.fields[cur]
              ? config.settings?.fields
              : productionBaseFields,
            [cur],
          );
          return acc;
        }, {} as ProductionSettingConfig['fields'])
        .value(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.settings?.fields],
  );

  return {
    productionSettingsId: config.id,
    fields,
    isLoading,
    metatags,
  };
};

export const useProductionSettingsForm = (siteId: number) => {
  const { queryKey } = getSettingsFetchParameters(siteId);
  const queryClient = useQueryClient();

  // get data
  const {
    productionSettingsId,
    fields,
    metatags,
    isLoading,
  } = useProductionSettingsFields(siteId);

  // get mutation functions for sending data to server
  const { mutate: createSettings, isLoading: createLoading } = useMutation(
    (data: AnyObject) => Api.Production.createSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
  const { mutate: updateSettings, isLoading: updateLoading } = useMutation(
    (data: AnyObject) => Api.Production.updateSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  // submit function
  const sendToServer = (settings: ProductionSettingConfig) => {
    // update Production Settings
    if (productionSettingsId) {
      const data = {
        id: productionSettingsId,
        settings,
      };

      updateSettings(data);
    }
    // create new Production Settings
    else {
      const data = {
        siteId,
        settings,
      };

      createSettings(data);
    }
  };

  const sendLoading = createLoading || updateLoading;

  return { fields, metatags, isLoading, sendToServer, sendLoading };
};
