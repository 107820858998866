import React from 'react';
import { getBorderSxPartForListItem } from './common';
import { ICommonListItemProps } from './common.types';
import { Checkbox, ListItem as JoyListItem, Sheet } from '@mui/joy';

type IListItemWithCheckboxProps<M> = Omit<
  ICommonListItemProps,
  'onClick' | 'id'
> & {
  onClick: (isChecked: boolean, id: M extends number ? number : string) => void;
  isChecked: boolean;
  isIndeterminate?: boolean;
  id: M extends number ? number : string;
};

export const ListItemWithCheckbox = <T extends string | number>({
  underline,
  children,
  onClick,
  isChecked,
  isIndeterminate,
  id,
  ...props
}: IListItemWithCheckboxProps<T>) => {
  const onListItemClick = () => {
    onClick(!isChecked, id);
  };

  return (
    <JoyListItem
      onClick={onListItemClick}
      sx={{
        ...getBorderSxPartForListItem(Boolean(underline)),
        display: 'flex',
        alignItems: 'center',
      }}
      {...props}
    >
      <Sheet sx={{ display: 'flex' }}>
        <Checkbox
          size="sm"
          checked={isChecked}
          indeterminate={isIndeterminate}
        />
      </Sheet>
      {children}
    </JoyListItem>
  );
};
