import React, { useEffect, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFilter } from '../../../../../modules/utils/hooks';
import {
  ModelsToDelete,
  useHasUserAccessToAction,
} from '../../../../../config';
import * as actions from '../../../../../modules/actions';
import {
  getAdditionalFeeListForTable,
  getIsAdditionalFeeDataLoading,
} from '../../../../../modules/selectors/additionalFee.selector';

import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../../../../modules/types';
import CreateAdditionalFee from './Forms/CreateAdditionalFee';
import UpdateAdditionalFee from './Forms/UpdateAdditionalFee';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useAdditionalFeeDefaultFilter } from 'src/modules/utils/hooks/additional-fee.hooks';

const AdditionalFeeList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasUserAccessToCreateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.additional_fee.create.id,
  );
  const hasUserAccessToUpdateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.additional_fee.update.id,
  );
  const hasUserAccessToDeleteEntity = useHasUserAccessToAction(
    manageEntitiesConfig.additional_fee.delete.id,
  );

  const defaultFilter = useAdditionalFeeDefaultFilter();

  const { list, count } = useSelector(
    getAdditionalFeeListForTable,
    shallowEqual,
  );
  const isTableDataLoading = useSelector(getIsAdditionalFeeDataLoading);

  const { filterCount, filterList = {} } = useFilter(defaultFilter);

  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState<boolean>(
    false,
  );
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState<boolean>(
    false,
  );

  const [selectedIds, setSelectedIds] = React.useState<IdsArray>([]);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'site.name', disablePadding: false, label: t('shifts.site_name') },
    {
      id: 'additionalFeeCategory.name',
      disablePadding: false,
      label: t('additional-fee.category'),
    },
    {
      id: 'name',
      disablePadding: false,
      label: t('additional-fee.name'),
    },
    {
      id: 'quantity',
      disablePadding: false,
      label: t('additional-fee.quantity'),
    },
    {
      id: 'isTemporary',
      disablePadding: false,
      label: t('additional-fee.isTemporary'),
    },
    {
      id: 'startingYearWeek',
      disablePadding: false,
      label: t('additional-fee.startingYearWeek'),
    },
    {
      id: 'finishingYearWeek',
      disablePadding: false,
      label: t('additional-fee.finishingYearWeek'),
    },
    {
      id: 'cpu',
      disablePadding: false,
      label: t('additional-fee.cpu'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('shifts.site_name'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'additionalFeeCategory.name',
      label: t('additional-fee.category'),
      operator: 'like',
    },
    {
      name: 'name',
      label: t('additional-fee.name'),
      operator: 'like',
    },
  ];

  // make request to fetch siteImportingSettings when component is mounted
  useEffect(() => {
    dispatch(actions.fetchAdditionalFeeListRequest(filterList));
    dispatch(actions.fetchAdditionalFeeCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include } = props;
    const offset = page * rowsPerPage;
    dispatch(
      actions.fetchAdditionalFeeListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatch(
      actions.fetchAdditionalFeeCountRequest({
        filter: { where, include },
      }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatch(
      actions.deleteAdditionalFeeRequest({
        data: { ids },
        filters: {
          list: filterList.filter ?? {},
          count: filterCount.filter ?? {},
        },
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setSelectedIds(ids);
    setIsUpdateFormVisible(true);
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={list}
        count={count}
        isTableDataLoading={isTableDataLoading}
        selectIndex="id"
        tableName={t('additional-fee.fee_table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={hasUserAccessToDeleteEntity ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdateEntity ? handleUpdating : undefined}
        deleteModelName={ModelsToDelete.CostPlusSettings}
        include={defaultFilter.include}
        createEntityBtnProps={
          hasUserAccessToCreateEntity
            ? {
                title: t('common.create'),
                onClick: () =>
                  setIsCreateFormVisible((prevState) => !prevState),
              }
            : undefined
        }
      />
      <>
        <CreateAdditionalFee
          isOpen={isCreateFormVisible}
          onClose={() => setIsCreateFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter ?? {}}
        />
        <UpdateAdditionalFee
          isOpen={isUpdateFormVisible}
          onClose={() => setIsUpdateFormVisible(false)}
          idsToUpdate={selectedIds}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter ?? {}}
        />
      </>
    </>
  );
};

export default AdditionalFeeList;
