import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { IdsArray } from 'src/modules/types';
import { sendSmsToEmployeesScheme } from 'src/modules/schemes/employees/send-sms-to-employees.scheme';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { addProcessStatus } from 'src/modules/actions';

interface IEmployeesSmsNotificationProps {
  isOpen: boolean;
  onClose: () => void;
  employeesIds: IdsArray;
}

export const EmployeesSmsNotification = ({
  isOpen,
  onClose,
  employeesIds,
}: IEmployeesSmsNotificationProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(sendSmsToEmployeesScheme);

  const formik = useFormik({
    initialValues: {
      text: '',
      employeesIds: employeesIds.map(Number),
    },
    validate,
    enableReinitialize: true,
    onSubmit: async (data) => {
      onClose();

      formik.resetForm();

      await Api.Employee.bulkSms(data);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.sms_successfully_send',
        }),
      );
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      {isOpen && (
        <>
          <DrawerHeader onCloseClick={onClose}>
            {t('common.send_message')}
          </DrawerHeader>
          <DrawerBody>
            <DrawerBodyContent>
              <FormFieldContainer>
                <FormikTextarea
                  required
                  id="text"
                  name="text"
                  label={t('common.message')}
                  placeholder={t('employees.cancel_shift_placeholder')}
                  formik={formik}
                  minRows={4}
                />
              </FormFieldContainer>
            </DrawerBodyContent>
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar
              onReset={onReset}
              onApply={formik.handleSubmit}
              onCancel={onClose}
              applyButtonType="submit"
            />
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
