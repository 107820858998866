import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  IAdditionalFeeState,
  IAdditionalFeeModel,
  IAdditionalFeeCategoryModel,
} from '../types';

// default state
const defaultState: IAdditionalFeeState = {
  feeList: [],
  categoryList: [],
  feeCount: 0,
  categoryCount: 0,
  is_additional_fee_list_loading: false,
  is_additional_fee_count_loading: false,
  is_additional_fee_category_list_loading: false,
  is_additional_fee_category_count_loading: false,
};

const setAdditionalFeeCategoriesList = (
  state: IAdditionalFeeState,
  payload: IAdditionalFeeCategoryModel[],
) => ({
  ...state,
  categoryList: payload,
  is_additional_fee_category_list_loading: false,
});
const setAdditionalFeeCategoriesListRequest = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_category_list_loading: true,
});
const setAdditionalFeeCategoriesListFailed = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_category_list_loading: false,
});

const setAdditionalFeeCategoriesCount = (
  state: IAdditionalFeeState,
  payload: number,
) => ({
  ...state,
  categoryCount: payload,
  is_additional_fee_category_count_loading: false,
});
const setAdditionalFeeCategoriesCountRequest = (
  state: IAdditionalFeeState,
) => ({
  ...state,
  is_additional_fee_category_list_loading: true,
});
const setAdditionalFeeCategoriesCountFailed = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_category_list_loading: false,
});

const fetchAdditionalFeeList = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_list_loading: true,
});

const setAdditionalFeeList = (
  state: IAdditionalFeeState,
  payload: IAdditionalFeeModel[],
) => ({
  ...state,
  feeList: payload,
  is_additional_fee_list_loading: false,
});

const fetchAdditionalFeeListFailed = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_list_loading: false,
});

const fetchAdditionalFeeCount = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_count_loading: true,
});

const setAdditionalFeeCount = (
  state: IAdditionalFeeState,
  payload: number,
) => ({
  ...state,
  feeCount: payload,
  is_additional_fee_count_loading: false,
});

const fetchAdditionalFeeCountFailed = (state: IAdditionalFeeState) => ({
  ...state,
  is_additional_fee_count_loading: false,
});

export const additionalFee = createReducer<IAdditionalFeeState>(
  {},
  defaultState,
)
  .on(
    actions.fetchAdditionalFeeCategoryListRequest,
    setAdditionalFeeCategoriesListRequest,
  )
  .on(
    actions.fetchAdditionalFeeCategoryListSuccess,
    setAdditionalFeeCategoriesList,
  )
  .on(
    actions.fetchAdditionalFeeCategoryListFailure,
    setAdditionalFeeCategoriesListFailed,
  )

  .on(
    actions.fetchAdditionalFeeCategoriesCountRequest,
    setAdditionalFeeCategoriesCountRequest,
  )
  .on(
    actions.fetchAdditionalFeeCategoriesCountSuccess,
    setAdditionalFeeCategoriesCount,
  )
  .on(
    actions.fetchAdditionalFeeCategoriesCountFailure,
    setAdditionalFeeCategoriesCountFailed,
  )
  .on(actions.fetchAdditionalFeeListRequest, fetchAdditionalFeeList)
  .on(actions.fetchAdditionalFeeListFailure, fetchAdditionalFeeListFailed)
  .on(actions.fetchAdditionalFeeListSuccess, setAdditionalFeeList)
  .on(actions.fetchAdditionalFeeCountRequest, fetchAdditionalFeeCount)
  .on(actions.fetchAdditionalFeeCountFailure, fetchAdditionalFeeCountFailed)
  .on(actions.fetchAdditionalFeeCountSuccess, setAdditionalFeeCount);

export default additionalFee;
