import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';

import { Api } from '../utils';
import * as actions from '../actions';

import {
  ICountResponse,
  ICreateSiteTaktExportingSettings,
  IdsArray,
  IFilter,
  ISiteTaktExportingSettingsModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createSiteTaktExportingSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<
      PayloadWithFilters<ICreateSiteTaktExportingSettings>
    >): SagaIterator {
      try {
        const response: ISiteTaktExportingSettingsModel = yield call(
          Api.SiteTaktExportingSettings.create,
          data,
        );

        yield put(
          actions.getSiteTaktExportingSettingsListRequest({
            filter: filters.list,
          }),
        );
        yield put(
          actions.getSiteTaktExportingSettingsCountRequest({
            filter: filters.count,
          }),
        );

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        yield put(actions.createSiteTaktExportingSettingsSuccess(response));
      } catch (e) {
        yield put(actions.createSiteTaktExportingSettingsFailed());
      }
    },
  },
  {
    action: actions.getSiteTaktExportingSettingsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ISiteTaktExportingSettingsModel[] = yield call(
          Api.SiteTaktExportingSettings.list,
          action.payload,
        );
        yield put(actions.getSiteTaktExportingSettingsListSuccess(response));
      } catch (e) {
        yield put(actions.getSiteTaktExportingSettingsListFailed());
      }
    },
  },
  {
    action: actions.getSiteTaktExportingSettingsCountRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.SiteTaktExportingSettings.count,
          action?.payload,
        );
        yield put(actions.getSiteTaktExportingSettingsCountSuccess(response));
      } catch (e) {
        yield put(actions.getSiteTaktExportingSettingsCountFailed());
      }
    },
  },
  {
    action: actions.deleteSiteTaktExportingSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
      try {
        yield call(Api.SiteTaktExportingSettings.delete, {
          where: { id: { inq: data } },
        });

        yield put(
          actions.getSiteTaktExportingSettingsListRequest({
            filter: filters.list,
          }),
        );
        yield put(
          actions.getSiteTaktExportingSettingsCountRequest({
            filter: filters.count,
          }),
        );

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        yield put(actions.deleteSiteTaktExportingSettingsSuccess());
      } catch (e) {
        yield put(actions.deleteSiteTaktExportingSettingsFailed());
      }
    },
  },
  {
    action: actions.updateSiteTaktExportingSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<
      PayloadWithFilters<ISiteTaktExportingSettingsModel[]>
    >): SagaIterator {
      try {
        yield call(Api.SiteTaktExportingSettings.bulkUpdate, data);

        yield put(
          actions.getSiteTaktExportingSettingsListRequest({
            filter: filters.list,
          }),
        );
        yield put(
          actions.getSiteTaktExportingSettingsCountRequest({
            filter: filters.count,
          }),
        );

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
        yield put(actions.updateSiteTaktExportingSettingsSuccess());
      } catch (e) {
        yield put(actions.updateSiteTaktExportingSettingsFailed());
      }
    },
  },
];
