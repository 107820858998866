import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deleteBomRequest,
  exportBomRequest,
  getBomCountRequest,
  getBomListRequest,
} from '../../modules/actions';
import {
  getBomCount,
  getBomRefreshKey,
  getBomsTable,
} from '../../modules/selectors/bom.selector';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useSearch,
  useCreateExportProps,
  useFilter,
  useFetchSitesCombobox,
  useBrowserHistoryFunctions,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, paths, useHasUserAccessToAction } from '../../config';
import { useBomsDefaultFilters } from 'src/modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const BomsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.bom.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.bom.update.id,
  );

  const defaultFilter = useBomsDefaultFilters();

  const fetchSitesCombobox = useFetchSitesCombobox();

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch Bom list
  const list = useSelector(getBomsTable, shallowEqual);
  const exportProps = useCreateExportProps(exportBomRequest);
  const count = useSelector(getBomCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('boms.name') },
    { id: 'site.name', disablePadding: false, label: t('boms.site') },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('boms.name'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('boms.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
  ];

  // make request to fetch boms when component is mounted
  useEffect(() => {
    dispatcher(getBomListRequest(filterList));
    // get total count
    dispatcher(getBomCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getBomListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getBomCountRequest({ filter: { where, include: newInclude } }));
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteBomRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: paths.BOMS_UPDATE, search });
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={list}
          count={count}
          selectIndex="id"
          tableName={t('boms.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          include={defaultFilter.include}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.Bom}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function BomsListRefreshable() {
  return <BomsList key={useSelector(getBomRefreshKey)} />;
}
