import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { useFetchResources } from '../../../../modules/utils';
import { AnyObject } from '../../../../modules/types';
import { ComboBoxOption } from '../../../ComboBox';
import { getResourcesOptions } from '../../../../modules/selectors/resources.selector';

interface ISupervisorComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  action: string;
  resource: string;
}

const ResourcesWithActionsComboBox: React.FC<ISupervisorComboboxProps> = (
  props,
) => {
  const fetchResources = useFetchResources();

  React.useEffect(() => {
    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resources = useSelector(getResourcesOptions, shallowEqual);

  const handleChange = (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    props.formik.setFieldError(props.action, undefined);
    props.formik.setFieldValue(
      props.action,
      value ? (value as AnyObject).entity : null,
    );
    props.formik.setFieldValue(
      props.resource,
      value ? (value as AnyObject).resourceUrl : null,
    );
  };

  return (
    <FormikCombobox
      {...props}
      required={true}
      onChange={handleChange}
      formik={props.formik}
      placeholder={props.placeholder}
      options={resources}
      error={props.error}
    />
  );
};

export default ResourcesWithActionsComboBox;
