import { ISagaActionBind } from '../../types';
import { IProductionDailyUphBySiteAndMetatagModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionDailyUphBySiteAndMetatagListRequest,
    saga: createListSaga<IProductionDailyUphBySiteAndMetatagModel>({
      apiCall: Api.ProductionDailyUphBySiteAndMetatag.list,
      actionSuccess: actions.getProductionDailyUphBySiteAndMetatagListSuccess,
      actionFailed: actions.getProductionDailyUphBySiteAndMetatagListFailed,
    }),
  },
  {
    action: actions.getProductionDailyUphBySiteAndMetatagCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionDailyUphBySiteAndMetatag.count,
      actionSuccess: actions.getProductionDailyUphBySiteAndMetatagCountSuccess,
      actionFailed: actions.getProductionDailyUphBySiteAndMetatagCountFailed,
    }),
  },
  {
    action: actions.exportProductionDailyUphBySiteAndMetatagRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionDailyUphBySiteAndMetatag.export,
      actionSuccess: actions.exportProductionDailyUphBySiteAndMetatagSuccess,
      actionFailed: actions.exportProductionDailyUphBySiteAndMetatagFailed,
    }),
  },
];
