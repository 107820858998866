import React from 'react';
import { noop } from 'lodash';
import Highcharts, { SeriesAreaOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, useTheme } from '@mui/joy';
import { AnyObject } from 'src/modules/types';
import { highchartsReactComponentProps } from '../../common';
import Exporting from 'highcharts/modules/exporting';

Exporting(Highcharts);

interface ISeriesOption extends SeriesAreaOptions {
  name: string;
  unit: string;
}

interface ISynchronizedChartsProps {
  series: Array<ISeriesOption & { title: Highcharts.Options['title'] }>;
  categories: Array<string>;
  chartHeight?: 'fit' | number;
  xAxis?: Highcharts.Options['xAxis'];
  yAxis?: Highcharts.Options['yAxis'];
  tooltip?: Highcharts.Options['tooltip'];
  chartsRefs?: Array<React.MutableRefObject<Highcharts.Chart | null>>;
  formatter?: (
    item: ISeriesOption & { title: Highcharts.Options['title'] },
    value: number,
  ) => string;
}

// Cache the default values to restore when this example unmounts
const oldReset = Highcharts.Pointer.prototype.reset;
const oldHighlight = (Highcharts.Point.prototype as any).highlight;

export const SynchronizedCharts = ({
  series,
  chartHeight,
  xAxis,
  yAxis,
  tooltip,
  categories,
  formatter,
  chartsRefs,
}: ISynchronizedChartsProps) => {
  const theme = useTheme();
  const [_chartHeight, setChartHeight] = React.useState<number | undefined>(
    chartHeight === 'fit' ? 1 : chartHeight,
  );
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    // Override the Highcharts prototypes here, so they only apply to this component
    Highcharts.Pointer.prototype.reset = noop;

    (Highcharts.Point.prototype as any).highlight = function (event: any) {
      this.onMouseOver(); // Show the hover marker
      this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };
  }, []);

  React.useEffect(() => {
    // Adjust every single chart height
    const containerElement = containerRef.current;
    const footer = document.getElementById('footer');

    const footerHeight = footer?.offsetHeight;
    const containerHeight = containerElement?.offsetHeight;

    if (chartHeight) {
      if (chartHeight === 'fit') {
        containerHeight &&
          footerHeight &&
          setChartHeight((containerHeight + footerHeight) / series.length);
      } else {
        setChartHeight(chartHeight);
      }
    }
  }, [chartHeight, series.length]);

  React.useEffect(
    // Restore default highcharts behavior on component unmount
    () => () => {
      Highcharts.Pointer.prototype.reset = oldReset;
      (Highcharts.Point.prototype as any).highlight = oldHighlight;
    },
    [],
  );

  const handleBoxEvents = (e: any) => {
    for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
      const chart = Highcharts.charts[i];

      if (chart) {
        // Find coordinates within the chart
        const event = chart.pointer.normalize(e);
        // Get the hovered point
        const point = chart.series[0].searchPoint(event, true);

        if (point) {
          (point as any).highlight(e);
        }
      }
    }
  };

  function syncExtremes(this: any, e: any) {
    const thisChart = this.chart;

    if (e.trigger !== 'syncExtremes') {
      // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function (chart: any) {
        if (chart !== thisChart) {
          if (chart.xAxis[0].setExtremes) {
            // It is null while updating
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
              trigger: 'syncExtremes',
            });
          }
        }
      });
    }
  }

  const options = React.useMemo(
    () => ({
      chart: {
        height: _chartHeight,
        marginLeft: 60,
        style: {
          fontFamily: "Inter, 'sans serif'",
        },
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        events: {
          setExtremes: syncExtremes,
        },
        categories,
        ...xAxis,
        crosshair: {
          width: 1,
          // color: '#E8E8E8',
        },
      },
      yAxis: {
        ...yAxis,
        title: {
          text: null,
        },
      },
      tooltip: {
        formatter: function () {
          if (formatter) {
            return formatter((this as AnyObject).series, (this as AnyObject).y);
          }
          return `${
            (this as AnyObject).series.name
          }: ${(this as AnyObject).y.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`;
        },
        positioner: function (): any {
          return {
            x: (this as any).chart.chartWidth - (this as any).label.width - 40,
            y: 10,
          };
        },
        borderWidth: 0,
        backgroundColor: 'none',
        pointFormat: '{point.y}',
        headerFormat: '',
        shadow: false,
        style: {
          fontSize: '12px',
          color: theme.palette.colors.text.text_primary.main,
        },
        ...tooltip,
      },

      exporting: {
        enabled: false,
      },
    }),
    [
      _chartHeight,
      categories,
      formatter,
      theme.palette.colors.text.text_primary.main,
      tooltip,
      xAxis,
      yAxis,
    ],
  );

  return (
    <Box
      ref={containerRef}
      onMouseMove={handleBoxEvents}
      onTouchMove={handleBoxEvents}
      sx={{ minWidth: '100%', minHeight: '100%' }}
    >
      {series.map(({ title, ...seriesItem }, i) => (
        <HighchartsReact
          key={i}
          ref={chartsRefs ? chartsRefs[i] : undefined}
          highcharts={Highcharts}
          options={{
            ...options,
            title,
            series: [
              {
                ...seriesItem,
                color: (Highcharts as any).getOptions().colors[i],
                fillOpacity: 0.3,
                tooltip: {
                  valueSuffix: '',
                  valuePrefix: seriesItem.unit + ' ',
                },
              },
            ],
          }}
          containerProps={highchartsReactComponentProps.containerProps}
        />
      ))}
    </Box>
  );
};
