import { createAction } from 'redux-act';
import {
  IClientModel,
  ICountResponse,
  ICreateClientRequest,
  IdsArray,
  IFilter,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushClientState = createAction('Flush client data in store');

// Creation actions
export const createClientRequest = createAction<
  PayloadWithFilters<ICreateClientRequest>
>('Make request to create a new client');
export const createClientSuccess = createAction(
  'New client has been created successfully',
);
export const createClientFailed = createAction('Cannot create a new client');

// Fetch clients actions
export const getClientListRequest = createAction<IFilter>(
  'Make request to fetch client list',
);
export const getClientListSuccess = createAction<IClientModel[]>(
  'Clients list has been fetched successfully',
);
export const getClientListFailed = createAction('Cannot fetch client list');

// get clients count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getClientCountRequest = createAction<IFilter>(
  'Make request to get client count',
);
export const getClientCountSuccess = createAction<ICountResponse>(
  'Clients count has been fetched successfully',
);
export const getClientCountFailed = createAction('Cannot get client count');

// delete actions
export const deleteClientRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete clients',
);
export const deleteClientSuccess = createAction('Successfully deleted client');
export const deleteClientFailed = createAction('Cannot delete clients');

// Updating actions
export const updateClientsRequest = createAction<
  PayloadWithFilters<IClientModel[]>
>('Make request to update clients');
export const updateClientsSuccess = createAction(
  'Clients successfully updated',
);
export const updateClientsFailed = createAction('Cannot update clients');

// Fetch combobox list actions
export const getClientComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of clients',
);
export const getClientComboboxListSuccess = createAction<
  Partial<IClientModel>[]
>('Combobox list of clients has been fetched successfully');
export const getClientComboboxListFailed = createAction(
  'Cannot fetch combobox clients list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Client').takeExportActions();

export const exportClientRequest = exportReportRequest;
export const exportClientSuccess = exportReportSuccess;
export const exportClientFailed = exportReportFailed;
