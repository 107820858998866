import { JSONSchemaType } from 'ajv';
import { ICreateShiftRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createShiftScheme: JSONSchemaType<ICreateShiftRequest> = {
  type: 'object',
  required: ['name', 'siteId', 'startTime', 'endTime'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    startTime: {
      type: 'string',
      format: 'time',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        format: i18n.t('error.validation.time'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    endTime: {
      type: 'string',
      format: 'time',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        format: i18n.t('error.validation.time'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    delay: {
      type: 'number',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.number'),
      },
    },
    desiredEmployeesNumber: {
      type: 'number',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.number'),
      },
    },
    lunchMin: {
      type: 'number',
      nullable: true,
    },
    lunchAdj: {
      type: 'number',
      nullable: true,
    },
    overnight: {
      type: 'boolean',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.boolean'),
      },
    },
    default: {
      type: 'boolean',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.boolean'),
      },
    },
  },
  if: {
    anyOf: [
      {
        not: {
          required: ['lunchMin'],
        },
      },
      {
        properties: {
          lunchMin: {
            not: {
              const: 0,
            },
          },
        },
      },
    ],
  },
  then: {
    required: ['lunchAdj'],
    properties: {
      lunchAdj: {
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
          type: i18n.t('error.validation.required'),
        },
      },
    },
  },
  else: {
    if: {
      anyOf: [
        {
          not: {
            required: ['lunchAdj'],
          },
        },
        {
          properties: {
            lunchAdj: {
              not: {
                const: 0,
              },
            },
          },
        },
      ],
    },
    then: {
      required: ['lunchMin'],
      properties: {
        lunchMin: {
          isNotEmpty: true,
          type: 'number',
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
            type: i18n.t('error.validation.number'),
          },
        },
      },
    },
  },
};
