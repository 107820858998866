import React from 'react';
import { useTranslation } from 'react-i18next';

import { IFilterPanelOption } from 'src/components/FilterPanel';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelDatePicker } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelDatePicker';
import { FilterPanelYearWeekRange } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanleYearWeekRange';
import { FilterPanelDepartmentsCombobox } from '../../../../../components/FilterPanel/FilterPanelFilters/FilterPanelDepartmentsCombobox';
import { AnyObject } from 'src/modules/types';

export interface IWeeklyHoursDashboardFilterPanelFilters {
  siteId?: number;
  department?: string;
  yearWeek?: { from: number; to: number };
}

interface IWeeklyHoursDashboardFilterPanelProps {
  onApplyFilters: (
    appliedFilters: IWeeklyHoursDashboardFilterPanelFilters,
  ) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const WeeklyHoursDashboardFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IWeeklyHoursDashboardFilterPanelProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = React.useState<
    IWeeklyHoursDashboardFilterPanelFilters
  >({});

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<{
      otherFilters: IWeeklyHoursDashboardFilterPanelFilters;
    }>(
      (obj, filterId) => {
        obj.otherFilters[filterId] = filters[filterId];
        return obj;
      },
      {
        otherFilters: {} as IWeeklyHoursDashboardFilterPanelFilters,
      },
    );

    onApplyFilters(appliedFiltersData.otherFilters);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'siteId':
        return (
          <FilterPanelSitesCombobox
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'department':
        return (
          <FilterPanelDepartmentsCombobox
            propertyAsId="name"
            filterId={filter.id}
            siteId={filters.siteId}
            startDecorator={`${filter.label}:`}
            value={(filters as AnyObject)[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'date':
        return (
          <FilterPanelDatePicker
            filterId={filter.id}
            value={filters[filter.id] ?? null}
            onChange={(date) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: date,
              }));
            }}
          />
        );
      case 'yearWeek':
        return (
          <FilterPanelYearWeekRange
            filterId={filter.id}
            fromStartDecorator={`${t('common.from_year_week')}:`}
            toStartDecorator={`${t('common.to_year_week')}:`}
            onChange={(value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: { from: value?.from?.id, to: value?.to?.id },
              }));
            }}
          />
        );
    }

    return null;
  };

  return (
    <ReportPageFilterPanel
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
