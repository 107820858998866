import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../components/EnhancedTable';
import {
  deletePointRequest,
  exportPointRequest,
  getPointCountRequest,
  getPointListRequest,
} from '../../../../modules/actions';
import {
  getIsPointsDataLoading,
  getPointCount,
  getPointsTable,
} from '../../../../modules/selectors/point';
import { ITableFilter } from '../../../../components/EnhancedTable/EnhancedTableFilter';
import { IPointModel, IdsArray } from '../../../../modules/types';
import {
  useCreateExportProps,
  useFetchPointTypesCombobox,
  useFilter,
  usePointsDefaultFilters,
  removalReasonsToLocationKeyMapper,
  useSupervisorCombobox,
} from '../../../../modules/utils/hooks';
import { map } from 'lodash';
import { ModelsToDelete, useHasUserAccessToAction } from '../../../../config';
import { getPointTypesComboboxList } from '../../../../modules/selectors/pointType';
import { ComboBoxOption } from '../../../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import Thumbnail from '../../../../components/Thumbnail';
import {
  parse,
  format,
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  DATETIME_FORMAT,
} from '../../../../modules/utils/dateWrapper';
import { Box } from '@mui/material';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { RemovePoints } from './RemovePoints';
import { FlipBackwardSvg } from 'src/components/svgIcons';
import { CreatePoint } from './CreatePoint';
import { UpdatePoints } from './UpdatePoints';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { getSupervisorsAndGMsBySitesComboboxList } from '../../../../modules/selectors/user';
import { useDefaultHiddenHeadCells } from '../../../../modules/utils/hooks/table';

const HEAD_CELLS_HIDDEN_BY_DEFAULT = [
  'isAutomatic',
  'approved',
  'rejected',
  'rejectReason.name',
];

export const PointsList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [
    isRemovePointFormVisible,
    setIsRemovePointFormVisible,
  ] = React.useState(false);
  const [
    isCreatePointFormVisible,
    setIsCreatePointFormVisible,
  ] = React.useState(false);
  const [
    isUpdatePointsPointFormVisible,
    setIsUpdatePointsFormVisible,
  ] = React.useState(false);

  const [selectedPointsIds, setSelectedPointsIds] = React.useState<IdsArray>(
    [],
  );

  const [pointsToRemove, setPointsToRemove] = React.useState<
    Array<IPointModel>
  >([]);

  const hasUserAccessToRemovePoints = useHasUserAccessToAction(
    manageEntitiesConfig.point.remove.id,
  );
  const hasUserAccessToUpdatePoints = useHasUserAccessToAction(
    manageEntitiesConfig.point.update.id,
  );
  const hasUserAccessToCreatePoints = useHasUserAccessToAction(
    manageEntitiesConfig.point.create.id,
  );
  const hasUserAccessToDeletePoints = useHasUserAccessToAction(
    manageEntitiesConfig.point.delete.id,
  );

  const isTableDataLoading = useSelector(getIsPointsDataLoading);

  const defaultFilter = usePointsDefaultFilters();

  const initialFilterData = React.useMemo(() => ({}), []);

  // fetch Point list
  const list = useSelector(getPointsTable, shallowEqual);
  const exportProps = useCreateExportProps(exportPointRequest);
  const fetchPointTypesCombobox = useFetchPointTypesCombobox();

  // make request to fetch point types and employees from the server if we don't have them in the store
  useEffect(() => {
    fetchPointTypesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch pointTypes list from store
  const pointTypes = useSelector(getPointTypesComboboxList);

  // computed list with icons
  const computedList = useMemo(
    () =>
      map(list, (item) => ({
        ...item,
        datetime: composeDate(
          item.datetime,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
        editedByUser: item.editedByUser?.id
          ? `${item.editedByUser.firstName} ${item.editedByUser.lastName}`
          : null,
        approvedOrRejectedBy: item.approvedOrRejectedBy?.id
          ? `${item.approvedOrRejectedBy?.firstName} ${item.approvedOrRejectedBy?.lastName}`
          : null,
        approvedOrRejectedAt: item.approvedOrRejectedAt
          ? composeDate(
              item.approvedOrRejectedAt,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : null,
        employee: {
          ...item.employee,
          supervisor: `${item.employee?.supervisor?.firstName} ${item.employee?.supervisor?.lastName}`,
          active: item.employee?.active ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
        },
        isAutomatic: item.isAutomatic ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        approved: item.approved ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        rejected: item.rejected ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        isRemoved: item.isRemoved ? (
          <NoIcon color="primary" />
        ) : (
          <YesIcon color="primary" />
        ),
        attachment: item.attachment ? (
          <Thumbnail src={item.attachment} alt={item.attachment} />
        ) : null,
        removalReason: item.removalReason
          ? t(
              `points.${
                removalReasonsToLocationKeyMapper[item.removalReason] as string
              }`,
            )
          : null,
        removalReasonAttachments: item.removalReasonAttachments
          ? (item.removalReasonAttachments as any).map(
              (attachment: string, index: number) => (
                <Box key={attachment} marginTop={index ? '8px' : 0}>
                  <Thumbnail src={attachment} alt={attachment} />
                </Box>
              ),
            )
          : null,
      })),
    [list, t],
  );

  const count = useSelector(getPointCount, shallowEqual);

  const fetchSupervisorsCombobox = useSupervisorCombobox();

  useEffect(() => {
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch supervisors providers list from store
  const supervisors = useSelector(
    getSupervisorsAndGMsBySitesComboboxList,
    shallowEqual,
  );

  const { filterCount, filterList } = useFilter(defaultFilter);

  const headCells: HeadCell[] = [
    {
      id: 'employee.firstName',
      disablePadding: false,
      label: t('points.emp_first_name'),
    },
    {
      id: 'employee.lastName',
      disablePadding: false,
      label: t('points.emp_last_name'),
    },
    { id: 'employee.badge', disablePadding: false, label: t('points.badge') },
    {
      id: 'employee.supervisor',
      disablePadding: false,
      disableSorting: true,
      label: t('points.supervisor'),
    },
    {
      id: 'employee.active',
      disablePadding: false,
      label: t('points.emp_active'),
    },
    {
      id: 'employee.site.name',
      disablePadding: false,
      label: t('points.site.name'),
    },
    { id: 'datetime', disablePadding: false, label: t('points.datetime') },
    {
      id: 'pointType.reason',
      disablePadding: false,
      label: t('points.reason'),
    },
    {
      id: 'pointType.point',
      disablePadding: false,
      label: t('points.points'),
    },
    { id: 'isRemoved', disablePadding: false, label: t('points.isRemoved') },
    {
      id: 'isAutomatic',
      disablePadding: false,
      label: t('points.isAutomatic'),
    },
    { id: 'approved', disablePadding: false, label: t('points.approved') },
    { id: 'rejected', disablePadding: false, label: t('points.rejected') },
    {
      id: 'rejectReason.name',
      disablePadding: false,
      label: t('points.reject_reason'),
    },
    {
      id: 'approvedOrRejectedBy',
      disablePadding: false,
      label: t('points.approvedOrRejectedBy'),
    },
    {
      id: 'approvedOrRejectedAt',
      disablePadding: false,
      label: t('points.approvedOrRejectedAt'),
    },
    {
      id: 'editedByUser',
      disablePadding: false,
      label: t('common.edited_by'),
    },
    { id: 'attachment', disablePadding: false, label: t('points.attachment') },
    {
      id: 'removalReason',
      disablePadding: false,
      label: t('points.removal_reason'),
    },
  ];

  useDefaultHiddenHeadCells({
    headCells,
    excludeHeadCellsList: HEAD_CELLS_HIDDEN_BY_DEFAULT,
    tableName: t('points.table_name.points'),
  });

  const filters: ITableFilter[] = [
    {
      name: 'employee.id',
      label: t('points.employee'),
      operator: 'eq',
      type: 'comboboxEmployee',
      propertyAsID: 'id',
    },
    {
      name: 'employee.badge',
      label: t('points.badge'),
      operator: 'like',
    },
    {
      name: 'employee.supervisorId',
      label: t('points.supervisor'),
      operator: 'eq',
      type: 'combobox',
      options: supervisors as ComboBoxOption[],
    },
    {
      name: 'employee.siteId',
      label: t('points.site.name'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'datetime',
      label: t('points.datetime'),
      operator: 'gte',
      type: 'datetime',
    },
    {
      name: 'pointType.id',
      label: t('points.reason'),
      operator: 'eq',
      type: 'combobox',
      options: pointTypes as ComboBoxOption[],
    },
    {
      name: 'isRemoved',
      label: t('points.points'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: false, label: t('common.active') },
          { value: true, label: t('common.unactive') },
        ],
      },
    },
  ];

  // make request to fetch points when component is mounted
  useEffect(() => {
    dispatch(getPointListRequest(filterList));
    // get total count
    dispatch(getPointCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;

    dispatch(
      getPointListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatch(getPointCountRequest({ filter: { where, include: newInclude } }));
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatch(
      deletePointRequest({
        data: ids,
        filters: { list: filterList.filter ?? {}, count: filterCount.filter },
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setIsUpdatePointsFormVisible(true);
    setSelectedPointsIds(ids);
  };

  const handlePointsRemove = (ids: IdsArray) => {
    const idsNumbers = ids.map(Number);

    const filteredPoints = list.filter(
      (item) => idsNumbers.includes(item.id) && !item.isRemoved,
    );

    const pointsToRemove = filteredPoints.map((point) => ({
      ...point,
      datetime: point.datetime
        ? format(DATETIME_FORMAT)(new Date(point.datetime))
        : '',
    }));

    setPointsToRemove(pointsToRemove);
    setIsRemovePointFormVisible(true);
  };

  return (
    <>
      <EnhancedTable
        ignoreGlobalSitesOnExport
        data={computedList}
        count={count}
        selectIndex="id"
        disableQsFilters
        tableName={t('points.table_name.points')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={hasUserAccessToDeletePoints ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdatePoints ? handleUpdating : undefined}
        include={defaultFilter.include}
        initialFilterData={initialFilterData}
        isTableDataLoading={isTableDataLoading}
        exportProps={exportProps}
        deleteModelName={ModelsToDelete.Point}
        createEntityBtnProps={
          hasUserAccessToCreatePoints
            ? {
                title: t('points.add'),
                onClick: () => setIsCreatePointFormVisible((prev) => !prev),
              }
            : undefined
        }
        customAction={
          hasUserAccessToRemovePoints
            ? {
                title: t('common.remove'),
                icon: <FlipBackwardSvg />,
                onClick: handlePointsRemove,
              }
            : undefined
        }
      />

      <>
        <CreatePoint
          isOpen={isCreatePointFormVisible}
          onClose={() => setIsCreatePointFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />

        <UpdatePoints
          pointsIds={selectedPointsIds}
          isOpen={isUpdatePointsPointFormVisible}
          onClose={() => setIsUpdatePointsFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />

        <RemovePoints
          points={pointsToRemove}
          isOpen={isRemovePointFormVisible}
          onClose={() => setIsRemovePointFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />
      </>
    </>
  );
};
