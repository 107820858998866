import { JSONSchemaType } from 'ajv';
import { ICreatePolicyRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createPolicyScheme: JSONSchemaType<ICreatePolicyRequest> = {
  type: 'object',
  required: ['v0', 'v1', 'v2'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    v0: {
      type: 'string',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    v1: {
      type: 'string',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    v2: {
      type: 'string',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    v3: {
      nullable: true,
      type: 'string',
    },
  },
};

export const createBulkPolicyScheme: JSONSchemaType<Array<
  ICreatePolicyRequest
>> = {
  type: 'array',
  items: createPolicyScheme,
};
