type LocalStorageKey =
  | 'tablesConfiguration'
  | 'globallySelectedSites'
  | 'tableConfigSize'
  | 'tableConfigDisableLineBreak'
  | 'tableConfigStriped'
  | 'pageSetting'
  | 'isIncludeOnlyDeletedEntitiesModeEnabled';

export const setItemInLocalStorage = <
  T extends Record<string, unknown> | string | number | string[] | number[]
>(
  key: LocalStorageKey,
  data: T,
) => {
  const parsedData = JSON.stringify(data);

  localStorage.setItem(key, parsedData);
};

export const getItemFromLocalStorage = <T>(key: LocalStorageKey): T | null => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

export const removeItemFromLocalStorage = (key: LocalStorageKey) => {
  localStorage.removeItem(key);
};
