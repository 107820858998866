import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { LineChart } from 'src/components/Charts/2d/LineChart';
import { fetchFinancialReportsWeeklyGrossMarginBySitePercentage } from 'src/modules/utils/Api/financialReports';
import {
  calculateTopNSites,
  generateSumByEntity,
  useActiveTopSitesOptions,
} from '../financialReports.helpers';
import { SelectForChart } from 'src/components/SelectForChart';
import { OptionForChart } from 'src/components/SelectForChart/OptionForChart';
import { IFinancialReportsWeeklyGrossMarginBySiteDataItem } from 'src/modules/types/financial-reports';
import { chartHeight } from '../financialReports.constants';

interface IFinancialReportsWeeklyGrossMarginBySitePercentageProps {
  siteId?: number;
}

export const FinancialReportsWeeklyGrossMarginBySitePercentage = ({
  siteId,
}: IFinancialReportsWeeklyGrossMarginBySitePercentageProps) => {
  const { t } = useTranslation();

  const activeTopSitesOptions = useActiveTopSitesOptions();

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const [activeTopSites, setActiveTopSites] = React.useState<
    'top3' | 'top10' | 'all'
  >(activeTopSitesOptions[0].id);

  const { data: rawData, getData, isLoading } = useGetDataForCharts(
    fetchFinancialReportsWeeklyGrossMarginBySitePercentage,
    {
      initialData: [],
    },
  );

  const data = rawData.map((item) => ({
    ...item,
    sum: item.grouped_percent.reduce((calc, current) => (calc += current), 0),
  }));

  const sumBySiteArray = generateSumByEntity(data, 'id', 'sum');

  const mapDataItemToSeriesItem = React.useCallback(
    (item: IFinancialReportsWeeklyGrossMarginBySiteDataItem) => ({
      type: 'line' as const,
      name: item.name,
      data: item.grouped_percent.map((item) => (item ? item : 0)),
    }),
    [],
  );

  const categories = React.useMemo(() => {
    if (!data.length) {
      return [];
    }

    return data[0].grouped_week_formatted;
  }, [data]);

  const series = React.useMemo(() => {
    if (activeTopSites === 'top3') {
      const top3SiteIds = calculateTopNSites(sumBySiteArray, 3).map(Number);

      return data
        .filter((item) => top3SiteIds.includes(item.id))
        .map(mapDataItemToSeriesItem);
    }
    if (activeTopSites === 'top10') {
      const top10SiteIds = calculateTopNSites(sumBySiteArray, 10).map(Number);

      return data
        .filter((item) => top10SiteIds.includes(item.id))
        .map(mapDataItemToSeriesItem);
    }

    return data.map(mapDataItemToSeriesItem);
  }, [activeTopSites, data, mapDataItemToSeriesItem, sumBySiteArray]);

  React.useEffect(() => {
    getData({ siteId });
  }, [getData, siteId]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('financial_reports.weekly_gross_margin_by_site_percentage')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() =>
              exportChart('weekly_gross_margin_by_site_percentage')
            }
          />

          <SelectForChart
            value={activeTopSites}
            onChange={(_, value) => {
              if (value) {
                setActiveTopSites(value);
              }
            }}
          >
            {activeTopSitesOptions.map((option) => (
              <OptionForChart key={option.id} value={option.id}>
                {option.label}
              </OptionForChart>
            ))}
          </SelectForChart>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <LineChart
          fullscreenTitle={t(
            'financial_reports.weekly_gross_margin_by_site_percentage',
          )}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
