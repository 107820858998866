import React from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import {
  ISignatureFieldProps,
  SignatureField,
} from '../_ui-kit/SignatureField';

interface IFormikSignatureFieldProps
  extends Omit<ISignatureFieldProps, 'onSave'> {
  id: string;
  formik: AnyObject;
  errorMode?: ErrorMode;
}

export const FormikSignatureField = ({
  id,
  formik,
  label,
  errorMode = 'onFormSubmit',
  ...signatureFieldProps
}: IFormikSignatureFieldProps) => {
  const handleEnd = (dataUrl: string) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, dataUrl);
  };

  const handleClear = () => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, undefined);
  };

  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  return (
    <SignatureField
      label={label}
      onEnd={handleEnd}
      onClear={handleClear}
      helperText={showError ? error : undefined}
      helperTextProps={{
        sx: { color: 'colors.text.text_error_primary.main', ml: 0 },
      }}
      {...signatureFieldProps}
    />
  );
};

export default React.memo(FormikSignatureField, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
