import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Share01Svg } from 'src/components/svgIcons';
import {
  HoursOptions,
  WeekStartDay,
  useHasUserAccessToAction,
} from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getSiteListRequest,
  getSiteCountRequest,
  exportSiteRequest,
  setArchivedStatusOfSiteRequest,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import {
  useArchivedSitesDefaultFilters,
  useCreateExportProps,
} from 'src/modules/utils';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsData,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  getIsSiteDataLoading,
  getSiteCount,
  getSiteTableWithParsedRows,
} from 'src/modules/selectors/site';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { getTimezones } from 'src/modules/utils/dateWrapper';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import { map } from 'lodash';
import { ActionConfirmation } from 'src/components/ActionConfirmation';

export const ArchivedSites = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [isConfirmArchiveOpened, setIsConfirmArchiveOpened] = React.useState(
    false,
  );

  const defaultFilter = useArchivedSitesDefaultFilters();

  const exportProps = useCreateExportProps(exportSiteRequest);

  const isDataLoading = useSelector(getIsSiteDataLoading);

  const allowedToArchive = useHasUserAccessToAction(
    manageEntitiesConfig.site.archive.id,
  );

  const timezones = getTimezones();

  const clients = useSelector(getClientsComboboxList);

  const inclusionObj = React.useMemo(() => {
    return {
      client: {
        relationType: 'left',
      },
    };
  }, []);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('sites.name'),
      },
      {
        id: 'timezone',
        orderConfig: { orderBy: 'timezone' },
        label: t('sites.timezone'),
      },
      {
        id: 'state',
        orderConfig: { orderBy: 'state' },
        label: t('sites.state'),
      },
      {
        id: 'costPlus',
        orderConfig: { orderBy: 'costPlus' },
        label: t('sites.costPlus'),
      },
      {
        id: 'fcGmPercentage',
        orderConfig: { orderBy: 'fcGmPercentage' },
        label: t('sites.fcGmPercentage'),
      },
      {
        id: 'paymentTerms',
        orderConfig: { orderBy: 'paymentTerms' },
        label: t('sites.paymentTermsTitle'),
      },
      {
        id: 'requiredAssociates',
        orderConfig: { orderBy: 'requiredAssociates' },
        label: t('sites.requiredAssociates'),
      },
      {
        id: 'absenteeism',
        orderConfig: { orderBy: 'absenteeism' },
        label: t('sites.absenteeism'),
      },
      {
        id: 'client.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['client', 'scope'],
        },
        label: t('sites.client'),
      },
      {
        id: 'lunchAdj',
        orderConfig: { orderBy: 'lunchAdj' },
        label: t('sites.lunchAdj'),
      },
      {
        id: 'financialAutomated',
        orderConfig: { orderBy: 'financialAutomated' },
        label: t('sites.financialAutomated'),
      },
      {
        id: 'managementCost',
        orderConfig: {
          orderBy: 'managementCost',
        },
        label: t('sites.managementCost'),
      },
      {
        id: 'pricingType',
        orderConfig: {
          orderBy: 'pricingType',
        },
        label: t('sites.pricingType'),
      },
      {
        id: 'incentiveType',
        orderConfig: {
          orderBy: 'incentiveType',
        },
        label: t('sites.incentiveType'),
      },
      {
        id: 'hoursOption',
        orderConfig: {
          orderBy: 'hoursOption',
        },
        label: t('sites.hoursOption'),
      },
      {
        id: 'startWeekDay',
        orderConfig: {
          orderBy: 'startWeekDay',
        },
        label: t('sites.startWeekDay'),
      },
      {
        id: 'overtimeBasis',
        orderConfig: {
          orderBy: 'overtimeBasis',
        },
        label: t('sites.overtimeBasis'),
      },
      {
        id: 'baselineType',
        orderConfig: {
          orderBy: 'baselineType',
        },
        label: t('sites.baselineType'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );
  const filterFieldsConfiguration = React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      timezone: {
        value: '',
        property: 'timezone',
        operator: 'eq' as const,
      },
      requiredAssociates: {
        value: '',
        property: 'requiredAssociates',
        operator: 'eq' as const,
      },
      absenteeism: {
        value: '',
        property: 'absenteeism',
        operator: 'eq' as const,
      },
      client: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['client', 'scope'] as InclusionPath,
      },
      hoursOption: {
        value: '',
        property: 'hoursOption',
        operator: 'eq' as const,
      },
      startWeekDay: {
        value: '',
        property: 'startWeekDay',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'createdAt',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
    defaultWhere: defaultFilter.where,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getSiteTableWithParsedRows,
    selectCount: getSiteCount,
    getEntitiesRequest: getSiteListRequest,
    getEntitiesCountRequest: getSiteCountRequest,
  });

  const handleUnarchive = () => {
    setIsConfirmArchiveOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToArchive) {
    actionsConfiguration.push({
      tooltip: t('common.retrieve_from_archive'),
      icon: <Share01Svg />,
      onClick: handleUnarchive,
    });
  }

  return (
    <>
      <TableContextProvider>
        <TableToolbar>
          <TableTitle>{t('sites.archived_table_name')}</TableTitle>
          <TableActions>
            <Filter onSubmit={onFiltersFormSubmit}>
              <FormFieldContainer>
                <ComboboxTableFilter
                  label={getLabel({
                    filterName: 'client',
                    labelPrefix: t('sites.client'),
                  })}
                  options={clients as any}
                  {...getFilterCommonPropsByFilterName('client')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <ComboboxTableFilter
                  label={getLabel({
                    filterName: 'hoursOption',
                    labelPrefix: t('sites.hoursOption'),
                  })}
                  options={
                    map(HoursOptions, (option) => ({
                      id: option,
                      name: option,
                    })) as any
                  }
                  {...getFilterCommonPropsByFilterName('hoursOption')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <ComboboxTableFilter
                  label={getLabel({
                    filterName: 'startWeekDay',
                    labelPrefix: t('sites.startWeekDay'),
                  })}
                  options={
                    map(WeekStartDay, (option) => ({
                      id: option,
                      name: option,
                    })) as any
                  }
                  {...getFilterCommonPropsByFilterName('startWeekDay')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <ComboboxTableFilter
                  label={getLabel({
                    filterName: 'timezone',
                    labelPrefix: t('sites.timezone'),
                  })}
                  options={timezones as any}
                  {...getFilterCommonPropsByFilterName('timezone')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'name',
                    labelPrefix: t('common.name'),
                  })}
                  {...getFilterCommonPropsByFilterName('name')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'requiredAssociates',
                    labelPrefix: t('sites.requiredAssociates'),
                  })}
                  {...getFilterCommonPropsByFilterName('requiredAssociates')}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <TextFieldFilter
                  label={getLabel({
                    filterName: 'absenteeism',
                    labelPrefix: t('sites.absenteeism'),
                  })}
                  {...getFilterCommonPropsByFilterName('absenteeism')}
                />
              </FormFieldContainer>
            </Filter>

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={filter}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />
          </TableActions>
        </TableToolbar>

        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <TableComponent
            tableUniqueKey="setup/archived_sites"
            sx={{
              flexGrow: 0,
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={setPage}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </Box>
      </TableContextProvider>

      <ActionConfirmation
        type="success"
        open={isConfirmArchiveOpened}
        onCancel={() => setIsConfirmArchiveOpened(false)}
        onOk={() => {
          dispatch(
            setArchivedStatusOfSiteRequest({
              data: selectedEntitiesIds.map((id) => ({
                id: Number(id),
                archived: false,
              })),
              filters: {
                list: filter,
                count: { where: filter.where, include: filter.include },
              },
            }),
          );

          setIsConfirmArchiveOpened(false);
          setSelectedEntitiesIds([]);
        }}
      >
        {`${t('sites.unarchive_confirm')} ?`}
      </ActionConfirmation>
    </>
  );
};
