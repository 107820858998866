import React from 'react';
import { DataGrid, GridValidRowModel, DataGridProps } from '@mui/x-data-grid';
import { Grid } from '@mui/material';

import { MUI_SIZE_12 } from 'src/config';
import { KpiDataGridWrapper } from './KpiDataGridWrapper';
import { IKpiDataGridRow } from 'src/modules/types';

export interface IKpiTableProps<R extends GridValidRowModel = any>
  extends DataGridProps<R> {
  isLoading?: boolean;
  handleRowUpdate?(
    updatedRow: IKpiDataGridRow,
    originRow: IKpiDataGridRow,
  ): Promise<IKpiDataGridRow>;
}

export const KpiTable: React.FC<IKpiTableProps> = ({
  isLoading,
  rows,
  columns,
  handleRowUpdate,
  ...rest
}) => {
  return (
    <Grid container={true}>
      <Grid item={true} xs={MUI_SIZE_12}>
        <KpiDataGridWrapper>
          <DataGrid
            {...rest}
            {...(handleRowUpdate ? { processRowUpdate: handleRowUpdate } : {})}
            autoHeight
            loading={isLoading}
            rows={rows}
            columns={columns}
            onProcessRowUpdateError={(e) => console.log(e)}
          />
        </KpiDataGridWrapper>
      </Grid>
    </Grid>
  );
};
