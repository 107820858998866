import React from 'react';
import {
  ITableActionsExportProps,
  TableActionsExport,
} from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { ReportPageContext } from '../ReportPageContext';
import { useReportsProps } from 'src/modules/utils/hooks/reportPage.hooks';
import {
  ExportType,
  IExportColumnsData,
  IExportResponse,
} from 'src/modules/types';
import { useDispatch } from 'react-redux';
import * as actions from '../../../modules/actions';
import { Api, i18n } from 'src/modules/utils';
import fileDownload from 'js-file-download';

const exportFileTypes: [string, ExportType][] = [
  ['onExportCSV', 'csv'],
  ['onExportPDF', 'pdf'],
  ['onExportExcel', 'xls'],
  ['onExportEmail', 'email'],
];

const FUNCTION_NAME = 0;
const FILE_TYPE = 1;

type IReportPageExportProps = ITableActionsExportProps & {
  reportProps: ReturnType<typeof useReportsProps>;
};

export const ReportPageExport = ({
  reportProps,
  ...props
}: IReportPageExportProps) => {
  const { activeReportExportData, activeReportId } = React.useContext(
    ReportPageContext,
  );

  const [isDataExporting, setIsDataExporting] = React.useState(false);

  const activeReportConfigDetails = activeReportId
    ? reportProps[activeReportId]
    : null;

  const dispatch = useDispatch();

  if (!activeReportConfigDetails || !activeReportExportData) {
    return null;
  }

  const exportProps = exportFileTypes.reduce((acc, exportType) => {
    acc[exportType[FUNCTION_NAME]] = async ({
      filter,
      ...restColumnData
    }: IExportColumnsData) => {
      try {
        setIsDataExporting(true);
        const exportData = {
          exportType: exportType[FILE_TYPE],
          ...restColumnData,
          ...activeReportExportData,
          filter: {
            ...filter,
          },
        };

        const { content, fileName }: IExportResponse = await Api.Report.export(
          activeReportConfigDetails.resourceUrl,
          exportData as any,
        );

        setIsDataExporting(false);

        dispatch(actions.setIsDataRequested(false));

        if (fileName) {
          fileDownload(content, fileName);
        }

        if (exportType[FILE_TYPE] === 'email') {
          dispatch(
            actions.addProcessStatus({
              variant: 'success',
              message: i18n.t('export.email.success'),
            }),
          );
        }
      } catch {
        setIsDataExporting(false);
      }
    };

    return acc;
  }, {});

  return (
    <TableActionsExport
      isDataExporting={isDataExporting}
      requestFilters={activeReportExportData.filter as any}
      {...exportProps}
      {...props}
    />
  );
};
