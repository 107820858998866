import React from 'react';
import { Option, OptionProps, Typography } from '@mui/joy';

type IOptionForChartProps = OptionProps;

export const OptionForChart = ({
  sx,
  children,
  ...props
}: IOptionForChartProps) => {
  return (
    <Option sx={{ ...sx }} {...props}>
      <Typography level="text_sm" textColor="colors.text.text_primary.main">
        {children}
      </Typography>
    </Option>
  );
};
