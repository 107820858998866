import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import {
  Api,
  useFetchPointTypesCombobox,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IdsArray } from 'src/modules/types';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  generateFormDataFields,
  generateFullIdInMultiEntitiesForm,
} from 'src/modules/utils/helpers/form';
import { createPointsScheme } from 'src/modules/schemes/points';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { getPointTypesComboboxList } from 'src/modules/selectors/pointType';
import { getEmployeeList } from 'src/modules/selectors/employee';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import FormikImageUpload from 'src/components/Formik/FormikImageUpload';
import * as actions from '../../../modules/actions';

interface ICreatePointProps {
  isOpen: boolean;
  onClose: () => void;
  employeesIds: IdsArray;
}

export const EmployeesPagePointCreate = ({
  isOpen,
  onClose,
  employeesIds,
}: ICreatePointProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allEmployees = useSelector(getEmployeeList);

  const selectedEmployeesFullData = allEmployees.filter((employee) =>
    employeesIds.includes(employee.id),
  );

  const validate = useValidate(createPointsScheme);

  const formik = useFormik({
    initialValues: employeesIds.map((employeeId) => ({
      employeeId: Number(employeeId),
      datetime: '',
      pointTypeId: NaN,
    })),
    validate,
    enableReinitialize: true,
    onSubmit: async (data) => {
      onClose();

      formik.resetForm();

      for (const item of data) {
        await Api.Point.create(generateFormDataFields(item) as any);
      }

      dispatch(
        actions.addProcessStatus({
          variant: 'success',
          title:
            data.length > 1
              ? 'points.points_successfully_created'
              : 'points.point_successfully_created',
        }),
      );
    },
  });

  const fetchPointTypesCombobox = useFetchPointTypesCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole);

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  React.useEffect(() => {
    fetchPointTypesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // fetch pointTypes list from store
  const pointTypes = useSelector(getPointTypesComboboxList);

  const getPointsTypesBySiteId = React.useCallback(
    (siteId: number) => {
      return pointTypes.filter(
        (pointType) => pointType.siteId === siteId,
      ) as any;
    },
    [pointTypes],
  );

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      {isOpen && (
        <>
          <DrawerHeader onCloseClick={onClose}>{t('points.add')}</DrawerHeader>
          <DrawerBody>
            {selectedEmployeesFullData.map((employee, index) => (
              <DrawerBodyContent key={employee.id}>
                <DrawerBodySectionTitle>
                  {`${employee.firstName} ${employee.lastName}`}
                </DrawerBodySectionTitle>

                <FormFieldContainer>
                  <FormikDatetimepicker
                    required
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('datetime', index)}
                    label={t('points.datetime')}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    required
                    id={generateFullIdInMultiEntitiesForm('pointTypeId', index)}
                    label={t('points.type')}
                    placeholder={t('common.select')}
                    options={
                      employee.site?.id
                        ? getPointsTypesBySiteId(employee.site.id)
                        : []
                    }
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikImageUpload
                    name={generateFullIdInMultiEntitiesForm('file', index)}
                    id={generateFullIdInMultiEntitiesForm('file', index)}
                    isImageFromPropsSourceOfTruth
                    inputId={generateFullIdInMultiEntitiesForm('file', index)}
                    file={formik.values[index]?.file}
                    formik={formik}
                  />
                </FormFieldContainer>
              </DrawerBodyContent>
            ))}
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar
              onReset={onReset}
              onApply={formik.handleSubmit}
              onCancel={onClose}
              applyButtonType="submit"
            />
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
