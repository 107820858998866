import {
  IProductionWeeklyCpuDetailedModel,
  IProductionWeeklyCpuDetailedState,
} from '../../types/productionCpuReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionWeeklyCpuDetailedState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyCpuDetailedListRequest,
  getListSuccess: getCardinalHealthWeeklyCpuDetailedListSuccess,
  getListFailed: getCardinalHealthWeeklyCpuDetailedListFailed,
} = reducerCreator.takeListHandlers<
  IProductionWeeklyCpuDetailedState,
  IProductionWeeklyCpuDetailedModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyCpuDetailedCountRequest,
  getCountSuccess: getCardinalHealthWeeklyCpuDetailedCountSuccess,
  getCountFailed: getCardinalHealthWeeklyCpuDetailedCountFailed,
} = reducerCreator.takeCountHandlers<IProductionWeeklyCpuDetailedState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyCpuDetailed = createReducer<
  IProductionWeeklyCpuDetailedState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyCpuDetailedListRequest,
    getCardinalHealthWeeklyCpuDetailedListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuDetailedListSuccess,
    getCardinalHealthWeeklyCpuDetailedListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuDetailedListFailed,
    getCardinalHealthWeeklyCpuDetailedListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyCpuDetailedCountRequest,
    getCardinalHealthWeeklyCpuDetailedCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuDetailedCountSuccess,
    getCardinalHealthWeeklyCpuDetailedCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuDetailedCountFailed,
    getCardinalHealthWeeklyCpuDetailedCountFailed,
  );

export default cardinalHealthWeeklyCpuDetailed;
