import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ComboBoxOption } from 'src/components/ComboBox';
import {
  getInvoiceListRequest,
  getMonthInvoiceListRequest,
} from 'src/modules/actions';
import {
  getInvoicesByIds,
  getMonthInvoicesByIds,
} from 'src/modules/selectors/invoice';
import { IdsArray, IStoreState } from 'src/modules/types';
import { defaultOrderDetails } from '../helpers/filter';
import { useSiteRelationInclusion } from './sites';
import {
  LastDayOfWeek,
  LastMonthOfYear,
  RetailCalendarFactory,
  WeekCalculation,
  WeekGrouping,
} from 'retail-calendar';
import { format } from 'date-fns';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

const useInvoiceInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => [siteRelationInclusion], [siteRelationInclusion]);
};

export const useInvoiceInclusionObject = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      site: {
        relationType: 'left',
        scope: {
          include: {
            client: {
              relationType: 'left',
            },
          },
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

export const usePlacementTypeOptions = () => {
  const { t } = useTranslation();

  return ([
    {
      id: 'invoice-bill-to',
      name: t('invoice.placements.invoice_bill_to'),
    },
    {
      id: 'invoice-questions',
      name: t('invoice.placements.invoice_questions'),
    },
  ] as unknown) as ComboBoxOption[];
};

export const useContactTypeOptions = () => {
  const { t } = useTranslation();

  return ([
    {
      id: 'name',
      name: t('invoice.contact_types.name'),
    },
    {
      id: 'email',
      name: t('invoice.contact_types.email'),
    },
    {
      id: 'position-title',
      name: t('invoice.contact_types.position-title'),
    },
    {
      id: 'street',
      name: t('invoice.contact_types.street'),
    },
    {
      id: 'city-state-zip',
      name: t('invoice.contact_types.city-state-zip'),
    },
    {
      id: 'phone-number',
      name: t('invoice.contact_types.phone-number'),
    },
    {
      id: 'additional-phone-number',
      name: t('invoice.contact_types.additional-phone-number'),
    },
  ] as unknown) as ComboBoxOption[];
};

export const useInvoiceListDefaultFilters = () => {
  const include = useInvoiceInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};
export const useMonthInvoiceListDefaultFilters = () => {
  const include = useInvoiceInclusion();

  return React.useMemo(
    () => ({
      order: [`yearNumber desc`, `monthNumber desc`],
      include,
    }),
    [include],
  );
};

export const useFetchInvoicesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();

  const invoices = useSelector(
    (state) => getInvoicesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  const invoiceInclusion = useInvoiceInclusion();

  return () => {
    if (isEmpty(invoices)) {
      dispatcher(
        getInvoiceListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: invoiceInclusion,
          },
        }),
      );
    }
  };
};
export const useFetchMonthInvoicesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();

  const invoices = useSelector(
    (state) => getMonthInvoicesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  const invoiceInclusion = useInvoiceInclusion();

  return () => {
    if (isEmpty(invoices)) {
      dispatcher(
        getMonthInvoiceListRequest({
          filter: {
            where: {
              monthInvoiceId: {
                inq: ids,
              },
            },
            include: invoiceInclusion,
          },
        }),
      );
    }
  };
};

export const useMonthOptions = () => {
  return React.useMemo(
    () =>
      Array.from({ length: 12 }, (_, month) => {
        const date = new Date(2000, month);
        const monthName = format(date, 'MMMM');
        return { id: month, name: monthName };
      }),
    [],
  );
};

export const useAccountingCalendarGeneratorForTarget = () => (
  yearNumber: number,
) => {
  const calendarArgs = {
    weekCalculation: WeekCalculation.LastDayBeforeEOM,
    weekGrouping: WeekGrouping.Group454,
    lastDayOfWeek: LastDayOfWeek.Saturday,
    lastMonthOfYear: LastMonthOfYear.January,
  };

  const calendar = new RetailCalendarFactory({ ...calendarArgs }, yearNumber);

  if (yearNumber === 2023) {
    const calendar2024 = new RetailCalendarFactory({ ...calendarArgs }, 2024);

    return {
      ...calendar,
      months: calendar.months.map((monthDetails) => {
        if (monthDetails.monthOfYear === 12) {
          const weeks = [
            ...monthDetails.weeks,
            calendar2024.months[0].weeks[0],
          ];
          return {
            ...monthDetails,
            weeks,
            numberOfWeeks: weeks.length,
            gregorianStartDate: weeks[0].gregorianStartDate,
            gregorianEndDate: weeks[weeks.length - 1].gregorianEndDate,
          };
        }

        return { ...monthDetails };
      }),
    };
  }

  if (yearNumber === 2024) {
    const calendar2025 = new RetailCalendarFactory({ ...calendarArgs }, 2025);

    return {
      ...calendar,
      months: calendar.months.map((monthDetails, i) => {
        if (i === calendar.months.length - 1) {
          const weeks = [
            ...monthDetails.weeks.filter((_, i) => Boolean(i)),
            calendar2025.months[0].weeks[0],
          ];

          return {
            ...monthDetails,
            weeks,
            gregorianStartDate: weeks[0].gregorianStartDate,
            gregorianEndDate: weeks[weeks.length - 1].gregorianEndDate,
          };
        }

        const weeks = [
          ...monthDetails.weeks.filter((_, i) => Boolean(i)),
          calendar.months[i + 1].weeks[0],
        ];

        return {
          ...monthDetails,
          weeks,
          gregorianStartDate: weeks[0].gregorianStartDate,
          gregorianEndDate: weeks[weeks.length - 1].gregorianEndDate,
        };
      }),
    };
  }

  return calendar;
};
