import { createAction } from 'redux-act';
import {
  IAreaBasedBaselineModel,
  IAreaBasedBaselineWithRelations,
  ICountResponse,
  ICpuBasedBaselineWithRelations,
  ICreateAreaBasedBaseline,
  ICreateCpuBasedBaseline,
  ICreateBaselineRequest,
  IdsArray,
  IFilter,
  IManageCpuBasedBaselines,
  IBaselineDeleteRequest,
  IBaselineListResponse,
  IBaselineModel,
  IUpdateAreaBasedBaseline,
  IUpdateCpuBasedBaseline,
  IUpdateBaseline,
  PayloadWithNavigateFunc,
} from 'src/modules/types';
import { ExportActionCreator } from 'src/modules/utils/helpers/creators/export';

// CPU based baselines
export const flushCpuBasedBaselineState = createAction(
  'Flush cpu based baseline state data in store',
);

export const createCpuBasedBaselineRequest = createAction<
  PayloadWithNavigateFunc<ICreateCpuBasedBaseline[]>
>('Make request to create cpu based baseline');
export const createCpuBasedBaselineSuccess = createAction(
  'Cpu based baseline successfully created',
);
export const createCpuBasedBaselineFailed = createAction(
  'Failed to create cpu based baseline',
);

export const getCpuBasedBaselineListRequest = createAction<IFilter>(
  'Make request to get cpu based baseline',
);
export const getCpuBasedBaselineListSuccess = createAction<
  ICpuBasedBaselineWithRelations[]
>('Cpu based baseline successfully fetched');
export const getCpuBasedBaselineListFailed = createAction(
  'Failed to fetch cpu based baseline',
);

export const getCpuBasedBaselineCountRequest = createAction<IFilter>(
  'Make request to get cpu based baseline count',
);
export const getCpuBasedBaselineCountSuccess = createAction<ICountResponse>(
  'Cpu based baseline count successfully fetched',
);
export const getCpuBasedBaselineCountFailed = createAction(
  'Failed to fetch cpu based baseline count',
);

export const updateCpuBasedBaselinesRequest = createAction<
  PayloadWithNavigateFunc<IUpdateCpuBasedBaseline[]>
>('Make request to update cpu based baselines');
export const updateCpuBasedBaselinesSuccess = createAction(
  'Cpu based baselines updated successfully',
);
export const updateCpuBasedBaselinesFailed = createAction(
  'Cannot update cpu based baselines',
);

export const manageCpuBasedBaselinesRequest = createAction<
  PayloadWithNavigateFunc<IManageCpuBasedBaselines>
>('Make request to manage cpu based baselines');
export const manageCpuBasedBaselinesSuccess = createAction(
  'Cpu based baselines managed successfully',
);
export const manageCpuBasedBaselinesFailed = createAction(
  'Cannot manage cpu based baselines',
);

export const deleteCpuBasedBaselineRequest = createAction<IdsArray>(
  'Make request to delete cpu based baselines',
);
export const deleteCpuBasedBaselineSuccess = createAction<IdsArray>(
  'Cpu based baselines successfully deleted',
);
export const deleteCpuBasedBaselineFailed = createAction(
  'Cannot delete cpu based baselines',
);

export const {
  exportReportRequest: exportCpuBasedBaselineRequest,
  exportReportSuccess: exportCpuBasedBaselineSuccess,
  exportReportFailed: exportCpuBasedBaselineFailed,
} = new ExportActionCreator('CpuBasedBaseline').takeExportActions();

// Area based baselines
export const flushAreaBasedBaselineState = createAction(
  'Flush cpu based baseline state data in store',
);

export const createAreaBasedBaselineRequest = createAction<
  PayloadWithNavigateFunc<ICreateAreaBasedBaseline>
>('Make request to create cpu based baseline');
export const createAreaBasedBaselineSuccess = createAction<
  IAreaBasedBaselineModel
>('Area based baseline successfully created');
export const createAreaBasedBaselineFailed = createAction(
  'Failed to create cpu based baseline',
);

export const getAreaBasedBaselineListRequest = createAction<IFilter>(
  'Make request to get cpu based baseline',
);
export const getAreaBasedBaselineListSuccess = createAction<
  IAreaBasedBaselineWithRelations[]
>('Area based baseline successfully fetched');
export const getAreaBasedBaselineListFailed = createAction(
  'Failed to fetch cpu based baseline',
);

export const getAreaBasedBaselineCountRequest = createAction<IFilter>(
  'Make request to get cpu based baseline count',
);
export const getAreaBasedBaselineCountSuccess = createAction<ICountResponse>(
  'Area based baseline count successfully fetched',
);
export const getAreaBasedBaselineCountFailed = createAction(
  'Failed to fetch cpu based baseline count',
);

export const updateAreaBasedBaselinesRequest = createAction<
  PayloadWithNavigateFunc<IUpdateAreaBasedBaseline[]>
>('Make request to update cpu based baselines');
export const updateAreaBasedBaselinesSuccess = createAction(
  'Area based baselines updated successfully',
);
export const updateAreaBasedBaselinesFailed = createAction(
  'Cannot update cpu based baselines',
);

export const deleteAreaBasedBaselineRequest = createAction<IdsArray>(
  'Make request to delete cpu based baselines',
);
export const deleteAreaBasedBaselineFailed = createAction(
  'Cannot delete cpu based baselines',
);

export const {
  exportReportRequest: exportAreaBasedBaselineRequest,
  exportReportSuccess: exportAreaBasedBaselineSuccess,
  exportReportFailed: exportAreaBasedBaselineFailed,
} = new ExportActionCreator('AreaBasedBaseline').takeExportActions();

// Flush actions
export const flushBaselineState = createAction('Flush baseline data in store');

// Creation actions
export const createBaselineRequest = createAction<
  PayloadWithNavigateFunc<ICreateBaselineRequest>
>('Make request to create a new baseline');
export const createBaselineSuccess = createAction<IBaselineModel>(
  'New baseline has been created successfully',
);
export const createBaselineFailed = createAction(
  'Cannot create a new baseline',
);

// Fetch baselines actions
export const getBaselineListRequest = createAction<{
  siteId: number;
  filter: IFilter;
}>('Make request to fetch baseline list');
export const getBaselineListSuccess = createAction<IBaselineListResponse>(
  'Baselines list has been fetched successfully',
);
export const getBaselineListFailed = createAction('Cannot fetch baseline list');

// delete actions
export const deleteBaselineRequest = createAction<IBaselineDeleteRequest>(
  'Make request to delete baselines',
);
export const deleteBaselineFailed = createAction('Cannot delete baselines');

// Updating actions
export const updateBaselinesRequest = createAction<
  PayloadWithNavigateFunc<IUpdateBaseline[]>
>('Make request to update baselines');
export const updateBaselinesFailed = createAction('Cannot update baselines');

// Fetch combobox list actions
export const getBaselineComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of baselines',
);
export const getBaselineComboboxListSuccess = createAction<
  Partial<IBaselineModel>[]
>('Combobox list of baselines has been fetched successfully');
export const getBaselineComboboxListFailed = createAction(
  'Cannot fetch combobox baselines list',
);

// export actions
export const {
  exportReportRequest: exportBaselineRequest,
  exportReportSuccess: exportBaselineSuccess,
  exportReportFailed: exportBaselineFailed,
} = new ExportActionCreator('Baseline').takeExportActions();
