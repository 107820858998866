import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISiteBillingDetailsFormData } from 'src/modules/types';

export interface IValueChangeArgs {
  value: string;
  path: string;
}

interface IInvoiceContactDetailsFieldsProps {
  fields: Partial<ISiteBillingDetailsFormData>[];
  onValueChange: (args: IValueChangeArgs) => void;
}

export const InvoiceContactDetailsFields: React.FC<IInvoiceContactDetailsFieldsProps> = ({
  fields,
  onValueChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      {fields.map((item, i) => (
        <FormControl
          style={{ margin: '8px 0' }}
          key={item.placeType ? item.type + item.placeType : item.type}
        >
          <FormLabel>{t(`invoice.contact_types.${item.type}`)}</FormLabel>
          <Input
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={item.value}
            onChange={(e) => {
              const value = e.target.value;
              const path = `[${i}].value`;

              onValueChange({ value, path });
            }}
          />
        </FormControl>
      ))}
    </Box>
  );
};
