import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { ICreateShiftRequest } from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { createShiftScheme } from 'src/modules/schemes/shift/create-shift.scheme';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { Api } from 'src/modules/utils';
import FormikTimepicker from 'src/components/Formik/FormikTimepicker';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { ifSiteHasLunch } from 'src/modules/selectors/site';

const initialValues = {
  name: '',
  startTime: '',
  endTime: '',
  siteId: NaN,
};

interface IShiftsCreateProps {
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}

export const ShiftsCreate: React.FC<IShiftsCreateProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(createShiftScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: ICreateShiftRequest) => {
      await Api.Shift.create(data);
      formik.resetForm();
      onCreate();
    },
  });

  const hasLunch = useSelector(ifSiteHasLunch)(formik.values.siteId);

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('shifts.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikTextField
              id="name"
              name="name"
              required={true}
              formik={formik}
              variant="outlined"
              fullWidth={true}
              label={t('shifts.name')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('shifts.site_name')}
              placeholder={t('shifts.site_name')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="desiredEmployeesNumber"
              name="desiredEmployeesNumber"
              formik={formik}
              variant="outlined"
              fullWidth={true}
              label={t('shifts.desiredEmployeesNumber')}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTimepicker
              id="startTime"
              formik={formik}
              required={true}
              label={t('shifts.start_time')}
              fullWidth={true}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTimepicker
              id="endTime"
              formik={formik}
              required={true}
              label={t('shifts.end_time')}
              fullWidth={true}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="overnight"
              formik={formik}
              label={t('shifts.overnight')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="default"
              formik={formik}
              label={t('shifts.default')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="delay"
              name="delay"
              formik={formik}
              variant="outlined"
              fullWidth={true}
              label={t('shifts.delay')}
              type="number"
            />
          </FormFieldContainer>
          {hasLunch ? (
            <>
              <FormFieldContainer>
                <FormikTextField
                  id="lunchMin"
                  name="lunchMin"
                  formik={formik}
                  variant="outlined"
                  fullWidth={true}
                  label={t('shifts.lunchMin')}
                  type="number"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  id="lunchAdj"
                  name="lunchAdj"
                  formik={formik}
                  variant="outlined"
                  fullWidth={true}
                  label={t('shifts.lunchAdj')}
                  type="number"
                />
              </FormFieldContainer>
            </>
          ) : (
            <></>
          )}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
