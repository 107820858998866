import { JSONSchemaType } from 'ajv';
import { IUserUpdateProfileRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const updateProfileScheme: JSONSchemaType<IUserUpdateProfileRequest> = {
  type: 'object',
  required: ['lang', 'firstName', 'lastName'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    firstName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lastName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lang: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    phoneNumber: {
      nullable: true,
      type: 'string',
    },
  },
};
