import React from 'react';
import { useState, useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Box, IconButton, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Trash04Svg, UploadToCloudSvg } from '../svgIcons';
import { getFileExtensionFromFileName } from 'src/modules/utils/helpers/common';

export interface IFilePreview {
  url: string;
  fileName: string;
  fileSize: number;
  mimeType: string;
}
export interface FileData {
  file?: File;
  preview: IFilePreview;
}

const defaultFilesData: FileData[] = [];

export interface IUploadFilesProps {
  inputId: string;
  onChange: (files: Array<FileData>) => void;
  filesData?: Array<FileData>;
  btnLabel?: string;
  accept?: Accept;
}

export const UploadFiles = ({
  inputId,
  btnLabel,
  onChange,
  accept,
  filesData = defaultFilesData,
}: IUploadFilesProps) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<FileData[]>(filesData);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: {
        url: URL.createObjectURL(file),
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.type,
      },
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleRemoveFile = (index: number) => {
    const fileToRemoveData = files.find((_, i) => i === index);

    setFiles(files.filter((_, i) => i !== index));

    if (fileToRemoveData)
      URL.revokeObjectURL(fileToRemoveData.preview.fileName);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept ?? {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
    },
  });

  React.useEffect(() => {
    filesData.forEach((fileData) =>
      URL.revokeObjectURL(fileData.preview.fileName),
    );

    setFiles(filesData);
  }, [filesData]);

  React.useEffect(() => {
    onChange(files);
  }, [files, onChange]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        {...getRootProps()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'colors.background.bg_primary',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'colors.border.border_secondary',
          borderRadius: 12,
          px: 3,
          py: 2,

          '&:hover': {
            borderColor: 'colors.border.border_brand',
          },
        }}
      >
        <input {...getInputProps()} id={inputId} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <UploadToCloudSvg />

          <Box sx={{ pt: 1.5 }}>
            <Typography
              textColor="colors.buttons.tertiary_colors.button_tertiary_color_fg"
              level="text_sm"
              fontWeight="semi_bold"
            >
              {btnLabel ?? t('common.click_to_upload')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {files.map(({ preview }, index) => (
          <Box
            key={preview.fileName}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              bgcolor: 'colors.background.bg_primary',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'colors.border.border_secondary',
              borderRadius: 12,
              mt: 1,
              p: 2,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {preview.mimeType.startsWith('image/') ? (
                  <Box
                    component="img"
                    sx={{ maxWidth: 60, maxHeight: 60, borderRadius: '12px' }}
                    src={preview.url}
                    alt={preview.fileName}
                  />
                ) : (
                  <Box
                    sx={{
                      p: 0.5,
                      borderRadius: 12,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'colors.border.border_secondary',
                    }}
                  >
                    <Typography
                      level="text_xs"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      {getFileExtensionFromFileName(preview.fileName)}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{ pl: 1 }}>
                <Box>
                  <Typography
                    level="text_sm"
                    textColor="colors.text.text_secondary"
                  >
                    {preview.fileName}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    level="text_sm"
                    textColor="colors.text.text_tertiary"
                  >
                    {(preview.fileSize / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignSelf: 'start' }}>
              <IconButton
                onClick={() => handleRemoveFile(index)}
                sx={{ minWidth: 0, minHeight: 0 }}
              >
                <Trash04Svg />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
