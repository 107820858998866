import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICountResponse,
  IDowntimeChartPointData,
  IIssueModel,
  IIssueState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IIssueState = {
  refreshKey: 0,
  id: NaN,
  downTime: '',
  issueTypeId: NaN,
  departmentId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  downtimeChartData: [],
  isDowntimeChartDataLoading: false,
};

/**
 * Flush actions
 */
const flushIssueState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createIssueRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createIssueSuccess = (state: IIssueState, payload: IIssueModel) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createIssueFailed = (state: IIssueState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getIssueListRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getIssueListSuccess = (state: IIssueState, payload: IIssueModel[]) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getIssueListFailed = (state: IIssueState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getIssueCountRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getIssueCountSuccess = (state: IIssueState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getIssueCountFailed = (state: IIssueState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteIssueRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteIssueFailed = (state: IIssueState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateIssuesRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateIssuesFailed = (state: IIssueState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getIssueComboboxListRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getIssueComboboxListSuccess = (
  state: IIssueState,
  payload: Partial<IIssueModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getIssueComboboxListFailed = (state: IIssueState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get downtime chart data
 */
const getDowntimeChartDataRequest = (state: IIssueState) => ({
  ...state,
  error: false,
  isDowntimeChartDataLoading: true,
});

const getDowntimeChartDataSuccess = (
  state: IIssueState,
  payload: IDowntimeChartPointData[],
) => ({
  ...state,
  downtimeChartData: payload,
  error: false,
  isDowntimeChartDataLoading: false,
});

const getDowntimeChartDataFailed = (state: IIssueState) => ({
  ...state,
  isDowntimeChartDataLoading: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IIssueState>();

const exportIssueRequest = exportRequest;
const exportIssueSuccess = exportSuccess;
const exportIssueFailed = exportFailed;

/**
 * Issue reducer
 */
export const issue = createReducer<IIssueState>({}, defaultState)
  // flush actions
  .on(actions.flushIssueState, flushIssueState)
  // creation actions
  .on(actions.createIssueRequest, createIssueRequest)
  .on(actions.createIssueSuccess, createIssueSuccess)
  .on(actions.createIssueFailed, createIssueFailed)
  // get list actions
  .on(actions.getIssueListRequest, getIssueListRequest)
  .on(actions.getIssueListSuccess, getIssueListSuccess)
  .on(actions.getIssueListFailed, getIssueListFailed)
  // get count actions
  .on(actions.getIssueCountRequest, getIssueCountRequest)
  .on(actions.getIssueCountSuccess, getIssueCountSuccess)
  .on(actions.getIssueCountFailed, getIssueCountFailed)
  // deletion actions
  .on(actions.deleteIssueRequest, deleteIssueRequest)
  .on(actions.deleteIssueFailed, deleteIssueFailed)
  // updating actions
  .on(actions.updateIssuesRequest, updateIssuesRequest)
  .on(actions.updateIssuesFailed, updateIssuesFailed)
  // get combobox list actions
  .on(actions.getIssueComboboxListRequest, getIssueComboboxListRequest)
  .on(actions.getIssueComboboxListSuccess, getIssueComboboxListSuccess)
  .on(actions.getIssueComboboxListFailed, getIssueComboboxListFailed)
  // export actions
  .on(actions.exportIssueRequest, exportIssueRequest)
  .on(actions.exportIssueSuccess, exportIssueSuccess)
  .on(actions.exportIssueFailed, exportIssueFailed)
  // export actions
  .on(actions.getDowntimeChartDataRequest, getDowntimeChartDataRequest)
  .on(actions.getDowntimeChartDataSuccess, getDowntimeChartDataSuccess)
  .on(actions.getDowntimeChartDataFailed, getDowntimeChartDataFailed);

export default issue;
