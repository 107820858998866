import { JSONSchemaType } from 'ajv';

// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { IAdditionalFee } from '../../types';
import { createAdditionalFeeScheme } from './create-additional-fee.scheme';

export const updateAdditionalFeeScheme: JSONSchemaType<IAdditionalFee[]> = {
  type: 'array',
  items: {
    ...createAdditionalFeeScheme,
    properties: {
      ...createAdditionalFeeScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
