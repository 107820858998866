import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import reduce from 'lodash/reduce';
import { Alert } from '@mui/joy';

import { MUI_SIZE_12 } from 'src/config';
import { AnyObject, ICountResponse } from 'src/modules/types';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';

interface IProps {
  siteId?: number;
  startDate?: string;
  endDate?: string;
}

const reportUrl = '/optoro/daily-report';

export const OptoroDaily: React.FC<IProps> = ({
  siteId,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'transactionDate',
  };

  const where = React.useMemo(() => {
    const and = [];

    if (startDate) {
      and.push({ transactionDate: { gte: startDate } });
    }
    if (endDate) {
      and.push({ transactionDate: { lte: endDate } });
    }

    return {
      siteId,
      ...(and.length ? { and } : {}),
    };
  }, [siteId, startDate, endDate]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    AnyObject[]
  >(reportUrl, []);
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const showData = React.useMemo(() => siteId && startDate && endDate, [
    endDate,
    siteId,
    startDate,
  ]);

  const columnNames = [
    'id',
    'siteId',
    'siteName',
    'shiftId',
    'shiftName',
    'shiftStartDate',
    'transactionDate',
    'keyDate',
    'qty',
    'Event ID',
    'Event Time',
    'Warehouse Identifier',
    'Warehouse Name',
    'Program Name',
    'User Login',
    'Activity Type',
    'Handling Unit Name',
    'Handling Unit Key Type',
    'Disposition At Event Completion',
    'Condition At Event Completion',
    'Storage Location At Event Completion',
    'Benchmark Value',
    'Benchmark Value Type',
    'SKU',
    'UPC',
    'Product Title',
    'Product Category',
    'Product Subcategory',
    'Product Leaf Category',
    'RMA Identifier',
    'RMA Item Identifier',
    'Forward Order Identifier',
    'Inbound ASN Number',
    'Inbound ASN Line Identifier',
    'Notes',
  ];

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () =>
      columnNames.map((i) => ({
        id: i,
        orderConfig: { orderBy: i },
        label: i,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    ...(showData ? { fetchData, fetchCountData } : {}),
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  const computedList = React.useMemo(
    () =>
      reduce<AnyObject, AnyObject[]>(
        data,
        (acc, cur) => {
          acc.push({
            ...cur,
            Notes: cur.Notes ? JSON.stringify(cur.Notes) : '',
          });
          return acc;
        },
        [],
      ),
    [data],
  );

  return (
    <>
      {!showData && (
        <Grid container={true}>
          <Grid item={true} xs={MUI_SIZE_12}>
            <Alert variant="soft">{t('reports.select_site_from_filter')}</Alert>
          </Grid>
        </Grid>
      )}
      {showData && (
        <ReportTable
          data={computedList as any}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
