import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/joy';
import { MUI_SIZE_12 } from '../../config';
import { Alert } from '@mui/joy';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';

interface IWeeklyUphBySiteAndDepartmentProps {
  year?: number;
  week?: number;
  siteId?: number;
  department?: string;
}

const reportDataUrl = '/perry-ellis/weekly-uph/by-site-and-department';

const defaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

export const WeeklyUphByDepartment = ({
  year,
  week,
  siteId,
  department,
}: IWeeklyUphBySiteAndDepartmentProps) => {
  const { t } = useTranslation();
  const isSiteSelected = Boolean(siteId);

  const where = React.useMemo(
    () => ({
      year: year ?? undefined,
      week: week ?? undefined,
      siteId: siteId ?? undefined,
      department: department ?? undefined,
    }),
    [year, week, siteId, department],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportDataUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportDataUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.week'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year_week'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'Department',
        orderConfig: { orderBy: 'Department' },
        label: t('productions_uph_reports.department'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'hrs',
        orderConfig: { orderBy: 'hrs' },
        label: t('productions_uph_reports.hrs'),
      },
      {
        id: 'uph',
        orderConfig: { orderBy: 'uph' },
        label: t('productions_uph_reports.uph'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  React.useEffect(() => {
    if (siteId) {
      fetchData({
        filter: {
          where,
          limit,
          offset: page,
          order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
        },
      });

      fetchCountData({ filter: { where } });
    }
  }, [fetchCountData, fetchData, limit, order, orderBy, page, siteId, where]);

  return (
    <>
      {!isSiteSelected && (
        <Grid container={true}>
          <Grid xs={MUI_SIZE_12}>
            <Alert variant="soft">{t('reports.select_site_from_filter')}</Alert>
          </Grid>
        </Grid>
      )}
      {isSiteSelected && (
        <ReportTable
          data={data.items as any}
          count={countData.count}
          summaryData={data.summary}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportDataUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
