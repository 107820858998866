import { JSONSchemaType } from 'ajv';
import { ICreateDepartmentRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { DepartmentIncentive } from '../../../config';

const getIncentiveSettings = (
  prop: DepartmentIncentive.costPlus | DepartmentIncentive.indirect,
) => ({
  type: 'object',
  default: {
    price: {
      [DepartmentIncentive.costPlus]: null,
      [DepartmentIncentive.indirect]: null,
    },
  },
  required: ['price'],
  properties: {
    price: {
      type: 'object',
      required: [prop],
      properties: {
        [prop]: {
          type: 'number',
          errorMessage: {
            required: i18n.t('error.validation.required'),
            type: i18n.t('error.validation.required'),
          },
        },
      },
    },
  },
});

export const createDepartmentScheme = ({
  type: 'object',
  required: ['name', 'siteId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    costPlus: {
      nullable: true,
      type: ['string', 'number'] as any,
      pattern: '^$|^[+-]?([0-9]+\\.?[0-9]*|\\.[0-9]+)$',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        pattern: i18n.t('error.validation.number'),
      },
    },
    staticCostPlus: {
      nullable: true,
      type: ['string', 'number'] as any,
      pattern: '^$|^[+-]?([0-9]+\\.?[0-9]*|\\.[0-9]+)$',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        pattern: i18n.t('error.validation.number'),
      },
    },
    siteId: {
      default: NaN,
      type: 'number',
      isNotEmpty: true,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    parentId: {
      nullable: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    active: {
      nullable: true,
      type: 'boolean',
    },
    indirect: {
      nullable: true,
      type: 'boolean',
    },
    incentive: {
      nullable: true,
      type: 'string',
      enum: Object.values(DepartmentIncentive),
    },
    archived: {
      nullable: true,
      type: 'boolean',
    },
    includeToUph: {
      nullable: true,
      type: 'boolean',
    },
  },
  if: {
    properties: {
      incentive: {
        const: DepartmentIncentive.costPlus,
      },
    },
  },
  then: {
    properties: {
      incentiveSettings: getIncentiveSettings(DepartmentIncentive.costPlus),
    },
  },
  else: {
    if: {
      properties: {
        incentive: {
          const: DepartmentIncentive.indirect,
        },
      },
    },
    then: {
      properties: {
        incentiveSettings: getIncentiveSettings(DepartmentIncentive.indirect),
      },
    },
  },
} as unknown) as JSONSchemaType<
  Pick<
    ICreateDepartmentRequest,
    | 'siteId'
    | 'name'
    | 'costPlus'
    | 'staticCostPlus'
    | 'parentId'
    | 'active'
    | 'indirect'
    | 'incentive'
    | 'incentiveSettings'
    | 'archived'
    | 'includeToUph'
    | 'includeToCostPlus'
  >
>;
