import React from 'react';
import { CssBaseline, Container, Typography, Button, Grid } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useBrowserHistoryFunctions } from 'src/modules/utils';
import { getUserObjectFromStorage } from '../modules/utils/user';
import { getLandingRouteBasedOnRole } from '../modules/utils/helpers/route.helper';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../components/PageContent';

export default function Error404() {
  const { pushToHistory, historyGo } = useBrowserHistoryFunctions();

  const { t } = useTranslation();

  /** get back handler **/
  // get current user role from localStorage directly
  const { auth: { role = '' } = {} } = getUserObjectFromStorage();
  // default route might differ dependently on a user role
  const homeRoute: string = getLandingRouteBasedOnRole(role);
  const goBack = () => historyGo(-1);
  const goHome = () => pushToHistory(homeRoute);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <Container
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          component="main"
          maxWidth="md"
        >
          <CssBaseline />
          <Typography sx={{ fontWeight: 'bold' }} component="h1">
            {t('error.404')}
          </Typography>
          <Typography component="h3">{t('error.not_found')}</Typography>
          <Grid
            // item={true}
            // container={true}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              sx={{
                marginLeft: '20px',
                marginTop: 3,
              }}
              onClick={goBack}
              // variant="contained"
              color="primary"
              // startIcon={<ArrowBackIcon />}
            >
              {t('common.back')}
            </Button>
            <Button
              sx={{
                marginLeft: '20px',
                marginTop: 3,
              }}
              onClick={goHome}
              // variant="contained"
              color="primary"
              // startIcon={<HomeIcon />}
            >
              {t('error.go_home')}
            </Button>
          </Grid>
        </Container>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
}
