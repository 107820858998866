export const brand = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
  950: '#062C41',
};

export const warning = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
  950: '#4E1D09',
};

export const error = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
  950: '#55160C',
};

export const success = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
  900: '#054F31',
  950: '#053321',
};

export const gray = {
  25: '#FCFCFD',
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
  950: '#0C111D',
};

export const blueLight = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
  950: '#062C41',
};

export const base = {
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'rgba(71, 84, 103, 1)',
};

export const colors = {
  text: {
    text_primary: {
      main: gray[900],
      on_brand: base.white,
    },
    text_secondary: {
      main: gray[700],
      hover: gray[800],
      on_brand: brand[200],
    },
    text_tertiary: {
      main: gray[600],
    },
    text_placeholder: {
      main: gray[500],
    },
    text_error_primary: {
      main: error[600],
    },
  },
  background: {
    bg_primary: base.white,
    bg_secondary: gray[50],
    bg_primary_solid: gray[950],
    bg_secondary_solid: gray[600],
    bg_primary_hover: gray[50],
    bg_success_secondary: success[100],
    bg_error_secondary: error[100],
    bg_warning_secondary: warning[100],
    bg_active: gray[50],
    bg_tertiary: gray[100],
  },
  border: {
    border_primary: gray[300],
    border_secondary: gray[200],
    border_tertiary: gray[100],
    border_disabled_subtle: gray[200],
    border_error: error[300],
    border_brand: brand[300],
  },
  avatar: {
    avatar_bg: gray[100],
    avatar_contrast_border: 'rgba(0,0,0,0.08)',
    avatar_focus_border: 'rgba(152, 162, 179, 0.14)',
  },
  foreground: {
    fg_primary: gray[900],
    fg_secondary: gray[700],
    fg_white: base.white,
    fg_disabled_subtle: gray[300],
    fg_quinary: gray[400],
    fg_error_primary: error[600],
    fg_senary: gray[300],
    fg_quarterary: gray[500],
  },
  alpha: {
    white_50: `${base.white}50`,
  },
  utility: {
    brand: {
      utility_brand_100: blueLight[100],
      utility_brand_600: blueLight[600],
      utility_brand_700: blueLight[700],
      utility_brand_800: blueLight[800],
    },
    error: {
      utility_error_50: error[50],
      utility_error_100: error[100],
      utility_error_200: error[200],
      utility_error_300: error[300],
    },
    success: {
      utility_success_50: success[50],
      utility_success_200: success[200],
    },
    warning: {
      utility_warning_50: warning[50],
      utility_warning_100: warning[100],
      utility_warning_200: warning[200],
    },
  },
  icons: {
    featured_icon_light_fg_error: error[600],
    featured_icon_light_fg_warning: warning[600],
    featured_icon_light_fg_success: success[600],
  },
  buttons: {
    tertiary: {
      button_tertiary_fg: gray[600],
    },
    tertiary_colors: {
      button_tertiary_color_fg: blueLight[700],
    },
  },
};
