import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IdsArray, IUpdateDepartment, AnyObject } from 'src/modules/types';
import { getDepartmentList } from 'src/modules/selectors/department';
import { useFormikInUpdateForm } from 'src/modules/utils/hooks/common/forms';
import { updateDepartmentsDesiredEmployeesNumberRequest } from 'src/modules/actions';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { updateDesiredEmployeesScheme } from 'src/modules/schemes/departments/update-desired-employees.scheme';

interface IDepartmentsUpdateEmployeesNumberProps {
  isOpen: boolean;
  onClose: () => void;
  entitiesToUpdateIds: IdsArray;
  filterList: AnyObject;
  filterCount: AnyObject;
}

export const DepartmentsUpdateEmployeesNumber = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  entitiesToUpdateIds,
}: IDepartmentsUpdateEmployeesNumberProps) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const allDepartments = useSelector(getDepartmentList).map((item) => ({
    id: item.id,
    name: item.name,
    desiredEmployeesNumber: item.desiredEmployeesNumber as any,
  }));

  const entitiesToUpdateFullData = allDepartments.filter((department) =>
    entitiesToUpdateIds.includes(department.id),
  );

  const validate = useValidate(updateDesiredEmployeesScheme);

  const formik = useFormikInUpdateForm({
    initialValues: entitiesToUpdateFullData,
    validate,
    onSubmit: (
      data: Pick<IUpdateDepartment, 'id' | 'desiredEmployeesNumber'>[],
    ) => {
      dispatcher(
        updateDepartmentsDesiredEmployeesNumberRequest({
          data: data.map(({ id, desiredEmployeesNumber }) => ({
            id,
            desiredEmployeesNumber,
          })),
          filters: {
            list: filterList,
            count: filterCount,
          },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('departments.updateDesiredEmployeesNumber')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {entitiesToUpdateFullData.map((department, index) => (
            <DrawerBodySectionContent key={department.id}>
              <DrawerBodySectionTitle>{department.name}</DrawerBodySectionTitle>
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.desiredEmployeesNumber`}
                  name={`${index}.desiredEmployeesNumber`}
                  label={t('departments.desiredEmployeesNumber')}
                  autoComplete="desiredEmployeesNumber"
                  formik={formik}
                  type="number"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
