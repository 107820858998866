import {
  ICardinalHealthDailyReportModel,
  ICardinalHealthDailyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthDailyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthDailyUphBySiteListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthDailyReportState,
  ICardinalHealthDailyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthDailyUphBySiteCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthDailyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthDailyUphBySite = createReducer<
  ICardinalHealthDailyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthDailyUphBySiteListRequest,
    getCardinalHealthDailyUphBySiteListRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteListSuccess,
    getCardinalHealthDailyUphBySiteListSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteListFailed,
    getCardinalHealthDailyUphBySiteListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthDailyUphBySiteCountRequest,
    getCardinalHealthDailyUphBySiteCountRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteCountSuccess,
    getCardinalHealthDailyUphBySiteCountSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteCountFailed,
    getCardinalHealthDailyUphBySiteCountFailed,
  );

export default cardinalHealthDailyUphBySite;
