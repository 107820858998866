import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
  ICountResponse,
  IFilter,
  ISagaAction,
  IWhere,
  ListWithSummary,
} from '../../../../types';
import { Action } from 'redux';

export interface ListWithSummarySagaCreatorConfig {
  apiCall: (...args: any) => any;
  actionSuccess: (...args: any) => Action;
  actionFailed: () => Action;
}

export const createListSaga = <Model>({
  apiCall,
  actionSuccess,
  actionFailed,
}: ListWithSummarySagaCreatorConfig) => {
  return function* (action: ISagaAction<IFilter>): SagaIterator {
    try {
      const response: ListWithSummary<Model> = yield call(
        apiCall,
        action.payload,
      );
      yield put(actionSuccess(response));
    } catch (e) {
      yield put(actionFailed());
    }
  };
};

export const createCountSaga = ({
  apiCall,
  actionSuccess,
  actionFailed,
}: ListWithSummarySagaCreatorConfig) => {
  return function* (action?: ISagaAction<IWhere>): SagaIterator {
    try {
      const response: ICountResponse = yield call(apiCall, action?.payload);
      yield put(actionSuccess(response));
    } catch (e) {
      yield put(actionFailed());
    }
  };
};
