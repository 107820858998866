import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  IEmployeesActiveButNotPresentModel,
  IFilledAssociatesModel,
  IFilterWhere,
  IListWithSummary,
} from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';

export const useFilledAssociatesTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const useFilledAssociatesComposeWhere = (
  where: Partial<IEmployeesActiveButNotPresentModel>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
    }),
    [selectedSites, where.siteId],
  );
};

export const useFilledAssociates = (reportUrl: string, where: IFilterWhere) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('filledAssociates.siteName'),
      },
      {
        id: 'requiredAssociates',
        orderConfig: { orderBy: 'requiredAssociates' },
        label: t('filledAssociates.requiredAssociates'),
      },
      {
        id: 'activeCount',
        orderConfig: { orderBy: 'activeCount' },
        label: t('filledAssociates.activeCount'),
      },
      {
        id: 'filledAssociates',
        orderConfig: { orderBy: 'filledAssociates' },
        disableSorting: true,
        label: t('filledAssociates.filledAssociates'),
      },
    ],
    [t],
  );

  return useBaseReport<IListWithSummary<IFilledAssociatesModel>>({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'asc',
    defOrderBy: 'siteName',
    initialData: {
      items: [],
      summary: [],
    },
  });
};
