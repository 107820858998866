import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { ExceptionsFromProductionReport } from 'src/components/Reports/ExceptionsFromProductionReport';
import { MUI_SIZE_12 } from '../../config';
import { Alert } from '../../components/Alert';
import { clients } from '../../modules/constants';

const headCellsToOmit = ['id', 'siteId', 'shiftId', 'date', 'endDate'];

interface IPerryEllisExceptionFromProductionProps {
  siteId?: number;
}

export const PerryEllisExceptionFromProduction = ({
  siteId,
}: IPerryEllisExceptionFromProductionProps) => {
  const { t } = useTranslation();

  const isSiteSelected = Boolean(siteId);

  return (
    <>
      {!isSiteSelected && (
        <Grid container={true}>
          <Grid item={true} xs={MUI_SIZE_12}>
            <Alert variant="outlined" severity="info">
              {t('reports.select_global_site')}
            </Alert>
          </Grid>
        </Grid>
      )}
      {isSiteSelected && (
        <ExceptionsFromProductionReport
          siteId={siteId}
          client={clients.perryEllis}
          headCellsToOmit={headCellsToOmit}
        />
      )}
    </>
  );
};
