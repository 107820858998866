import { extendTheme } from '@mui/joy/styles';

import {
  base as lightBase,
  gray as lightGray,
  brand as lightBrand,
  error as lightError,
  colors as lightColors,
  warning as lightWarning,
  success as lightSuccess,
} from './light';
import {
  base as darkBase,
  gray as darkGray,
  brand as darkBrand,
  error as darkError,
  colors as darkColors,
  warning as darkWarning,
  success as darkSuccess,
} from './dark';

export const theme = extendTheme({
  cssVarPrefix: 'ngroup',

  colorSchemes: {
    light: {
      palette: {
        background: {
          body: lightGray[50],
        },
        base: lightBase,
        gray: lightGray,
        brand: lightBrand,
        error: lightError,
        colors: lightColors,
        warning: lightWarning,
        success: lightSuccess,
      },
    },
    dark: {
      palette: {
        background: {
          body: darkGray[950],
        },
        base: darkBase,
        gray: darkGray,
        brand: darkBrand,
        error: darkError,
        colors: darkColors,
        warning: darkWarning,
        success: darkSuccess,
      },
    },
  },

  typography: {
    text_xs: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
    },
    text_sm: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    text_md: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    text_xl: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
    },
    display_sm: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    display_md: {
      fontSize: '2.25rem',
      lineHeight: '2.75rem',
      letterSpacing: '-2%',
    },
  },

  fontWeight: {
    regular: 400,
    medium: 500,
    semi_bold: 600,
    bold: 700,
  },

  shadow: {
    sx: '0px 1px 2px 0px #1018280D',
  },

  transitions: {
    create: (
      cssProperties: string | Array<string>,
      config: { easing: string; duration: number },
    ) => {
      if (Array.isArray(cssProperties)) {
        return cssProperties
          .map(
            (property) => `${property} ${config.duration}ms ${config.easing}`,
          )
          .join(', ');
      }

      return `${cssProperties} ${config.duration}ms ${config.easing}`;
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },

  components: {
    JoyButton: {
      styleOverrides: {
        root: () => ({
          borderRadius: 'var(--Button-radius, var(--ngroup-radius-md))',
        }),
      },
    },
    JoyButtonGroup: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'md' && {
            height: 40,
          }),
        }),
      },
    },
    JoySheet: {
      styleOverrides: {
        root: {},
      },
    },
    JoyListItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.vars.palette.gray[50],
          },
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...theme.typography.text_sm,
          fontWeight: theme.fontWeight.medium,
          borderRadius: theme.radius.md,
          color: theme.palette.colors.text.text_primary.main,
          backgroundColor: theme.palette.common.white,

          ['&:focus-within::before']: {
            '--Input-focused': 0,
          },
          ['&:focus-within']: {
            outlineStyle: 'solid',
            outlineWidth: '4px',
            outlineColor: ownerState.error
              ? theme.palette.error['100']
              : theme.palette.brand['100'],
            borderColor: ownerState.error
              ? theme.palette.colors.border.border_error
              : theme.palette.colors.border.border_brand,
          },

          minHeight: 24,

          ...(ownerState.size === 'xs' && {
            height: 24,
          }),
          ...(ownerState.size === 'md' && {
            height: 40,
          }),
          ...(ownerState.size === 'lg' && {
            height: 44,
          }),
        }),
        input: {
          ['&:autofill']: {
            borderRadius: 'inherit',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...theme.typography.text_sm,
          fontWeight: theme.fontWeight.medium,
          borderRadius: theme.radius.md,
          color: theme.palette.colors.text.text_primary.main,
          backgroundColor: theme.palette.common.white,

          ['&::before']: {
            boxShadow: 'none',
          },
          ['&:focus-within']: {
            outlineStyle: 'solid',
            outlineWidth: '4px',
            outlineColor: ownerState.error
              ? theme.palette.error['100']
              : theme.palette.brand['100'],
            borderColor: ownerState.error
              ? theme.palette.colors.border.border_error
              : theme.palette.colors.border.border_brand,
          },

          '& textarea::placeholder': {
            fontWeight: theme.fontWeight.regular,
            color: theme.palette.colors.text.text_placeholder,
            ...theme.typography.text_md,
          },
        }),
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...theme.typography.text_sm,
          fontWeight: theme.fontWeight.medium,
          borderRadius: theme.radius.md,
          color: theme.palette.colors.text.text_primary.main,
          backgroundColor: theme.palette.common.white,

          ['&:focus-within::before']: {
            '--Input-focused': 0,
          },
          ['&:focus-within']: {
            outlineStyle: 'solid',
            outlineWidth: '4px',
            outlineColor: ownerState.error
              ? theme.palette.error['100']
              : theme.palette.brand['100'],
            borderColor: ownerState.error
              ? theme.palette.colors.border.border_error
              : theme.palette.colors.border.border_brand,
          },

          minHeight: 24,

          ...(ownerState.size === 'md' && {
            height: 40,
          }),
          ...(ownerState.size === 'lg' && {
            height: 44,
          }),
        }),

        input: ({ theme }) => ({
          '&::placeholder': {
            fontWeight: theme.fontWeight.regular,
            color: theme.palette.colors.text.text_placeholder,
            ...theme.typography.text_md,
          },
        }),
      },

      defaultProps: {
        size: 'md',
      },
    },
    JoyAutocompleteOption: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 8,
          marginLeft: 8,
          marginRight: 8,
          borderRadius: 6,
          cursor: 'pointer',

          '&:hover': {
            backgroundColor: `${theme.palette.colors.background.bg_active} !important`,
          },
          '&:not(:last-of-type)': {
            marginBottom: 1,
          },

          '&[aria-selected="true"]': {
            backgroundColor: theme.palette.colors.background.bg_active,
          },
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.sm,
          color: theme.palette.colors.text.text_secondary.main,
          borderColor: theme.palette.colors.border.border_primary,
          backgroundColor: theme.palette.colors.background.bg_primary,
          borderWidth: '1px',
          borderStyle: 'solid',
        }),
      },
    },
    JoyTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          '& thead th': {
            borderBottomWidth: '1px !important',
          },
          '& th': {
            padding: 0,
            userSelect: 'none',
            backgroundColor: 'transparent',
            borderColor: theme.palette.colors.border.border_secondary,
          },
          '& td': {
            padding: 0,
            backgroundColor: 'transparent',
            borderColor: theme.palette.colors.border.border_secondary,
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: theme.palette.colors.background.bg_secondary,
          },
          '& tr:nth-of-type(even)': {
            backgroundColor: theme.palette.colors.background.bg_primary,
          },
          '& tr.error': {
            backgroundColor: theme.palette.colors.background.bg_error_secondary,
          },
          '& tr.warning': {
            backgroundColor:
              theme.palette.colors.background.bg_warning_secondary,
          },
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          minHeight: '1.5rem',
          ...(ownerState.size === 'xl' && {
            minHeight: 52,
          }),
          minWidth: 120,
        }),
        listbox: {
          borderRadius: 8,
        },
      },
    },
    JoyOption: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            padding: 8,
            marginLeft: 8,
            marginRight: 8,
            borderRadius: 6,
            cursor: 'pointer',

            '&:hover': {
              backgroundColor: `${theme.palette.colors.background.bg_active} !important`,
            },
            '&:not(:last-of-type)': {
              marginBottom: 1,
            },

            '&[aria-selected="true"]': {
              backgroundColor: theme.palette.colors.background.bg_active,
            },
            // backgroundColor: `${theme.palette.colors.background.bg_primary} !important`,
            ...(ownerState.selected === true && {
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.colors.background.bg_active}`,
              },
            }),
          };
        },
      },
    },
    JoyDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.colors.border.border_secondary,
        }),
      },
    },
    JoyAccordionGroup: {
      // styleOverrides: {
      //   root: {
      //     '& .MuiAccordionSummary-button, & .MuiAccordionDetails-content': {
      //       padding: '13px 16px',
      //     },
      //   },
      // },
    },
  },
});
