import { IWhere } from 'src/modules/types';
import Api from '.';
import { IFinancialReportsWeeklyGrossMarginBySiteDataItem } from 'src/modules/types/financial-reports';

export const fetchFinancialReportsWeeklyGrossMarginBySiteMoney = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<IFinancialReportsWeeklyGrossMarginBySiteDataItem>
  >(`/financial-tracker/weekly`, { filter: { where } });

  return response;
};

export const fetchFinancialReportsWeeklyGrossMarginBySitePercentage = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<IFinancialReportsWeeklyGrossMarginBySiteDataItem>
  >(`/financial-tracker/weekly`, { filter: { where } });

  return response;
};
