import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

export interface IPromptProps {
  title?: string;
  open?: boolean;
  message: JSX.Element | string;
  onOk: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Prompt = ({
  open = true,
  title,
  message,
  onOk,
  onCancel,
}: IPromptProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={open}
        keepMounted={true}
        onClose={onCancel}
        className="prompt-dialog"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {title || t('common.modal.confirm_action')}
        </DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t('common.cancel')}</Button>
          <Button onClick={onOk} color="primary">
            {t('common.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Prompt;
