import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/joy';

import { useChartRequiredUtils } from 'src/components/Charts/common';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { costsByDayAndDepartmentChartHeight } from 'src/pages/Payboard/Costs/costs.constants';
import { MUI_SIZE_2 } from 'src/config';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { useCostByDayAndDepartment } from 'src/modules/utils';
import StackedAreaChart from 'src/components/Charts/2d/StackedAreaChart';

interface ICostByDayAndDepartmentProps {
  siteId?: number;
  dateFrom?: string;
  dateTo?: string;
}

export const CostByDayAndDepartment: React.FC<ICostByDayAndDepartmentProps> = ({
  siteId,
  dateFrom,
  dateTo,
}) => {
  const { t } = useTranslation();

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const { series, categories, isDataLoading } = useCostByDayAndDepartment({
    siteId,
    dateFrom,
    dateTo,
  });

  return (
    <>
      {(!dateFrom || !dateTo) && (
        <Alert variant="soft" sx={{ mt: MUI_SIZE_2 }}>
          {t('payboard.cost.by_day_and_department.site_filter_alert')}
        </Alert>
      )}
      {isDataLoading && <MainContentLoader />}
      {!isDataLoading && dateFrom && dateTo && (
        <ChartContainer>
          <ChartContainerHeader
            title={t('payboard.cost.by_day_and_department.table_name')}
          >
            <ChartContainerToolbar>
              <ChartContainerUtils
                onToggleFullScreen={toggleFullScreen}
                onExport={() => exportChart(`costs_by_day_and_department`)}
              />
            </ChartContainerToolbar>
          </ChartContainerHeader>
          <ChartContainerBody isLoading={isDataLoading}>
            <StackedAreaChart
              ref={chartRef}
              height={costsByDayAndDepartmentChartHeight}
              categories={categories}
              series={series}
            />
          </ChartContainerBody>
        </ChartContainer>
      )}
    </>
  );
};
