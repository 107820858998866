import React from 'react';
import { MultiRowFormContextProvider } from '../MultiRowFormContext';
import { Box } from '@mui/material';

export const MultiRowFormContainer: React.FC = ({ children }) => {
  return (
    <MultiRowFormContextProvider>
      <Box>{children}</Box>
    </MultiRowFormContextProvider>
  );
};
