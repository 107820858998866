import { useTranslation } from 'react-i18next';
import React from 'react';
import { Report, ReportPage } from 'src/components/ReportPage';
import { clients } from 'src/modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { McEntireDailyUphBySite } from './DailyUphBySite';
import { McEntireDailyUphBySiteAndShift } from './DailyUphBySiteAndShift';
import { McEntireWeeklyUphBySite } from './WeeklyUphBySite';
import { McEntireWeeklyUphBySiteAndShift } from './WeeklyUphBySiteAndShift';
import { McEntireWeeklyCpu } from './WeeklyCpu';
import WeeklyGrossMargin from './WeeklyGrossMargin';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
import { McEntireScorecard } from './Scorecard';
import UphChart from 'src/components/ReportPage/UphChart';
import { PageContentWithTopToolbar } from '../../components/PageContent';
import { ReportPageItemContainer } from '../../components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from '../../components/ReportPage/ReportTableToolbar';
import { SelectHeadCells } from '../../components/Table/components/TableActions/components/SelectHeadCells';
import { UploadImportingFile } from '../../components/Table/components/TableActions/components/UploadImportingFile';
import {
  IMcEntireReportsFilterPanelFilters,
  McEntireReportsFilterPanel,
} from './McEntireReportsFilterPanel';
import { McEntireDaily } from './Daily';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from '../../components/ReportPage/ReportPageActionsGroupItem';
import { ReportPageExport } from '../../components/ReportPage/ReportPageExport';

const reportsDefaultFilters: IMcEntireReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

const mcEntireClient = clients.mcEntire;

export const McEntireReports = () => {
  const { t } = useTranslation();

  const reportsProps = useReportsProps(mcEntireClient);

  const hasUserAccessToUploadMcEntireProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[clients.mcEntire].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IMcEntireReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
      },
      { id: 'shiftId', label: t('productions_uph_reports.shift.name') },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
      { id: 'startDate', label: t('common.startDate') },
      { id: 'endDate', label: t('common.endDate') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IMcEntireReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={mcEntireClient}>
        <ReportPageItemContainer>
          <ReportTableToolbar reportName={t('main_menu.mc-entire.main')}>
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>
            </ReportPageActionsGroupContainer>

            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <ReportPageExport reportProps={reportsProps} />
              </ReportPageActionsGroupItem>

              {hasUserAccessToUploadMcEntireProductionData && (
                <ReportPageActionsGroupItem>
                  <UploadImportingFile clientKey={clients.mcEntire} />
                </ReportPageActionsGroupItem>
              )}
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
        </ReportPageItemContainer>
        <ReportPageItemContainer>
          <McEntireReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportsProps.dailyReport}>
          <ReportPageItemContainer fullHeight>
            <McEntireDaily
              siteId={appliedFilters.siteId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyLphBySite}>
          <ReportPageItemContainer>
            <UphChart
              reportTypeId={reportsProps.dailyLphBySite.reportTypeId}
              client={mcEntireClient}
              filters={{
                startDate: appliedFilters.startDate,
                endDate: appliedFilters.endDate,
              }}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            <McEntireDailyUphBySite
              siteId={appliedFilters.siteId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyLphBySiteAndShift}>
          <ReportPageItemContainer fullHeight>
            <McEntireDailyUphBySiteAndShift
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              startDate={appliedFilters.startDate}
              endDate={appliedFilters.endDate}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyLphBySite}>
          <ReportPageItemContainer>
            <UphChart
              reportTypeId={reportsProps.weeklyLphBySite.reportTypeId}
              client={mcEntireClient}
              filters={{
                year: appliedFilters.year,
                week: appliedFilters.week,
              }}
            />
          </ReportPageItemContainer>
          <ReportPageItemContainer fullHeight>
            <McEntireWeeklyUphBySite
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyLphBySiteAndShift}>
          <ReportPageItemContainer fullHeight>
            <McEntireWeeklyUphBySiteAndShift
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              shiftId={appliedFilters.shiftId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyCpu}>
          <ReportPageItemContainer fullHeight>
            <McEntireWeeklyCpu
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
              year={appliedFilters.year}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMargin}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.scorecard}>
          <ReportPageItemContainer fullHeight>
            <McEntireScorecard
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
