import { isEmpty } from 'lodash';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getAreaBasedBaselineListRequest,
  getCpuBasedBaselineListRequest,
  getBaselineListRequest,
} from 'src/modules/actions';
import {
  getAreaBasedBaselineByIds,
  getCpuBasedBaselineBySiteIds,
} from 'src/modules/selectors/baseline.selector';
import { IdsArray, IStoreState } from 'src/modules/types';
import { defaultOrderDetails } from '../helpers/filter';
import { useSiteRelationInclusion } from './sites';
import { getBaselinesByIds } from '../../selectors/baseline.selector';
import { ROWS_PER_PAGE_DEFAULT } from '../../../components/EnhancedTable';

const useBaselineInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => [siteRelationInclusion], [siteRelationInclusion]);
};

export const useBaselineListDefaultFilters = () => {
  const include = useBaselineInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};

export const useFetchAreaBasedBaselineByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();

  const baselines = useSelector(
    (state) => getAreaBasedBaselineByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  const baselineInclusion = useBaselineInclusion();

  return () => {
    if (isEmpty(baselines)) {
      dispatcher(
        getAreaBasedBaselineListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: baselineInclusion,
          },
        }),
      );
    }
  };
};
export const useFetchCpuBasedBaselineBySiteIds = (siteIds: IdsArray) => {
  const dispatcher = useDispatch();

  const baselines = useSelector(
    (state) => getCpuBasedBaselineBySiteIds(state as IStoreState)(siteIds),
    shallowEqual,
  );

  const baselineInclusion = useBaselineInclusion();

  return () => {
    if (isEmpty(baselines)) {
      dispatcher(
        getCpuBasedBaselineListRequest({
          filter: {
            where: {
              siteId: {
                inq: siteIds,
              },
            },
            include: baselineInclusion,
          },
        }),
      );
    }
  };
};

export const baselinesInclusions = [
  {
    relation: 'site',
  },
  {
    relation: 'baselineMetadata',
    scope: {
      include: [
        {
          relation: 'metatag',
        },
      ],
    },
  },
];

export const baselineComboboxFilter = {
  filter: {
    fields: {
      id: true,
      name: true,
    },
    include: baselinesInclusions,
  },
};

/**
 * A custom hook to fetch baselines from store if they exist otherwise to make a request to fetch needed baselines from
 * the server
 */
export const useFetchBaselines = (siteId: number, ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch baselines list from store
  const baselines = useSelector(
    (state) => getBaselinesByIds(state as IStoreState)(siteId, ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(baselines)) {
      dispatcher(
        getBaselineListRequest({
          siteId: +siteId,
          filter: {
            filter: {
              where: {
                id: {
                  inq: ids,
                },
              },
              offset: 0,
              limit: ROWS_PER_PAGE_DEFAULT,
              include: baselinesInclusions,
            },
          },
        }),
      );
    }
  };
};
