import { IProductionDailyUphBySiteAndMetatagModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionDailyUphBySiteAndSku',
);

// get list actions
export const {
  getListRequest: getProductionDailyUphBySiteAndMetatagListRequest,
  getListSuccess: getProductionDailyUphBySiteAndMetatagListSuccess,
  getListFailed: getProductionDailyUphBySiteAndMetatagListFailed,
} = actionCreator.takeListActions<IProductionDailyUphBySiteAndMetatagModel>();

// get count actions
export const {
  getCountRequest: getProductionDailyUphBySiteAndMetatagCountRequest,
  getCountSuccess: getProductionDailyUphBySiteAndMetatagCountSuccess,
  getCountFailed: getProductionDailyUphBySiteAndMetatagCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionDailyUphBySiteAndMetatagRequest,
  exportReportSuccess: exportProductionDailyUphBySiteAndMetatagSuccess,
  exportReportFailed: exportProductionDailyUphBySiteAndMetatagFailed,
} = new ExportActionCreator(
  'ProductionDailyUphBySiteAndSku',
).takeExportActions();
