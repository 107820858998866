export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATETIME_FORMAT_TO_SHOW = 'yyyy-MM-dd HH:mm';
export const DATETIME_FORMAT_TO_PASS = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
export const TIME_FORMAT = 'HH:mm:ss';
export const EXPIRES_AT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const DATETIME_TIMEZONE = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const DAY_OF_WEEK = 'EEEE';
export const YEAR_MONTH = 'yyyyMM';
export const MONTH = 'MM';
export const YEAR_WEEK_ISO = "yyyy-'W'ww";

export type DATE_FORMATS =
  | typeof DATE_FORMAT
  | typeof DATETIME_FORMAT_TO_SHOW
  | typeof DATETIME_FORMAT_TO_PASS
  | typeof TIME_FORMAT
  | typeof EXPIRES_AT_FORMAT
  | typeof DATETIME_TIMEZONE
  | typeof DAY_OF_WEEK
  | typeof DATETIME_FORMAT
  | typeof YEAR_MONTH
  | typeof MONTH
  | typeof YEAR_WEEK_ISO
  | string;
