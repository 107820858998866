import { Transforms } from 'slate';

import { createParagraph } from './paragraph';

interface EmbedProps {
  url: string;
  width: string;
  height: string;
}

export const createImageNode = (
  alt: string,
  { url, width, height }: EmbedProps,
) => ({
  type: 'image',
  alt,
  url,
  width,
  height,
  children: [{ text: '' }],
});
export const createVideoNode = ({ url, width, height }: EmbedProps) => ({
  type: 'video',
  url,
  width,
  height,
  children: [{ text: '' }],
});

export const insertEmbed = (editor: any, embedData: any, format: string) => {
  const { url, width, height } = embedData;

  if (!url) return;
  embedData.width = width ? `${width}px` : '100%';
  embedData.height = height ? `${height}px` : 'auto';
  const embed =
    format === 'image'
      ? createImageNode('EditorImage', embedData)
      : createVideoNode(embedData);

  Transforms.insertNodes(editor, embed, { select: true });
  Transforms.insertNodes(editor, createParagraph(''), { mode: 'highest' });
};
