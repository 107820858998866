import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { createAdditionalFeeScheme } from '../../../../../../modules/schemes';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCheckbox from '../../../../../../components/Formik/FormikCheckbox';
import { useGetYearWeeksOptionsForAdditionalFee } from '../../../../../../modules/utils/hooks/common/yearWeek.hooks';
import * as actions from '../../../../../../modules/actions';
import AdditionalFeeCategoriesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/AdditionalFeeCategoryCombobox';
import FormikCombobox from '../../../../../../components/Formik/FormikCombobox';
import { IFilterData, IWhere } from '../../../../../../modules/types';

const initialValues = {
  siteId: NaN,
  additionalFeeCategoryId: NaN,
  name: '',
  quantity: NaN,
  isTemporary: false,
  startingYearWeek: undefined,
  finishingYearWeek: undefined,
  cpu: NaN,
};

interface ICreateAdditionalFee {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateAdditionalFee = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateAdditionalFee) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(createAdditionalFeeScheme);

  const [isTemporary, setIsTemporary] = React.useState<boolean>(false);
  const yearWeeks = useGetYearWeeksOptionsForAdditionalFee(
    new Date('2024-01-01T03:00:00'),
    2,
  );

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        actions.createAdditionalFeeRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('additional-fee.create_fee')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              formik={formik}
              placeholder={t('payboard.site')}
              required
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <AdditionalFeeCategoriesComboBox
              id="additionalFeeCategoryId"
              formik={formik}
              placeholder={t('additional-fee.category')}
              required
              selectedSiteId={formik.values.siteId}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="name"
              variant="outlined"
              fullWidth
              label={t('additional-fee.name')}
              name="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="isTemporary"
              formik={formik}
              label={t('additional-fee.isTemporary')}
              onChange={(event) => {
                setIsTemporary(event.target.checked);
              }}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              id="startingYearWeek"
              placeholder={t('additional-fee.startingYearWeek')}
              options={yearWeeks}
              formik={formik}
              disabled={!isTemporary}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              id="finishingYearWeek"
              placeholder={t('additional-fee.finishingYearWeek')}
              options={yearWeeks}
              formik={formik}
              disabled={!isTemporary}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="quantity"
              variant="outlined"
              fullWidth
              label={t('additional-fee.quantity')}
              placeholder={t('common.add_your_value_here')}
              name="quantity"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="cpu"
              variant="outlined"
              fullWidth
              label={t('additional-fee.cpu')}
              placeholder={t('common.add_your_value_here')}
              name="cpu"
              formik={formik}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default CreateAdditionalFee;
