import React from 'react';
import * as Yup from 'yup';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useSelector } from 'react-redux';
import { ICreateBaselineRequest } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { map, noop } from 'lodash';
import Metatags from 'src/pages/Productions/components/Metatags';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import { generateCreateBaselineScheme } from 'src/modules/schemes/baseline';

const generateInitialValues = (metatags: string[], siteId: number) => {
  const meta = metatags.reduce((all, metatag) => {
    all[metatag] = '';

    return all;
  }, {});

  return {
    baseline: NaN,
    minCpu: NaN,
    maxCpu: NaN,
    siteId,
    ...meta,
  };
};
interface IBaselineCreateRecordProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ICreateBaselineRequest) => void;
}

export const BaselineCreateRecord = ({
  isOpen,
  onClose,
  onSubmit,
}: IBaselineCreateRecordProps) => {
  const { t } = useTranslation();

  const siteIdFormik = useFormik({
    initialValues: { siteId: NaN },
    validationSchema: Yup.object({
      siteId: Yup.string().required(t('error.validation.required')),
    }),
    onSubmit: noop,
  });

  const metatagNames = useSelector(getMetatagsNames);
  const metatags = React.useMemo(
    () =>
      siteIdFormik.values.siteId && metatagNames[siteIdFormik.values.siteId]
        ? metatagNames[siteIdFormik.values.siteId]
        : [],
    [siteIdFormik.values.siteId, metatagNames],
  );

  const formik = useFormik({
    initialValues: generateInitialValues(metatags, siteIdFormik.values.siteId),
    validate: useValidate(generateCreateBaselineScheme(metatags)),
    onSubmit: async (data) => {
      siteIdFormik.handleSubmit();

      if (siteIdFormik.isValid) {
        onSubmit({
          ...data,
          siteId: Number(siteIdFormik.values.siteId),
          baseline: Number(data.baseline),
          minCpu: Number(data.minCpu),
          maxCpu: Number(data.maxCpu),
        });

        formik.resetForm();
        siteIdFormik.resetForm();
      }
    },
  });

  const onReset = () => {
    formik.resetForm();
    siteIdFormik.resetForm();
  };

  React.useEffect(() => {
    formik.setFieldValue('siteId', siteIdFormik.values.siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIdFormik.values.siteId]);

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('baseline.create_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <SitesComboBox
                id="siteId"
                required={true}
                formik={siteIdFormik}
                label={t('standards.site')}
                placeholder={t('standards.site')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                label={t('baseline.baseline')}
                id="baseline"
                name="baseline"
                autoComplete="baseline"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                label={t('baseline.min_cpu')}
                id="minCpu"
                name="minCpu"
                autoComplete="minCpu"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                label={t('baseline.max_cpu')}
                id="maxCpu"
                name="maxCpu"
                autoComplete="maxCpu"
                formik={formik}
              />
            </FormFieldContainer>

            {map(metatags, (metatag: string) => (
              <Metatags name={metatag} formik={formik} key={metatag} />
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
