import { IdsArray, IStoreState } from '../types';
import { createSelector } from 'reselect';
import { filter, map } from 'lodash';

export const getStaffingProviderRefreshKey = (state: IStoreState) =>
  state.staffingProvider.refreshKey;

export const getStaffingProviderData = (state: IStoreState) =>
  state.staffingProvider;

/**
 * Get server error
 * @param staffingProvider - State staffingProvider
 */
export const getServerError = ({ staffingProvider }: IStoreState) =>
  staffingProvider.error;

/**
 * Get staffingProvider list
 * @param staffingProvider - State staffingProvider
 */
export const getStaffingProviderList = ({ staffingProvider }: IStoreState) =>
  staffingProvider.list;

/**
 * Get staffingProviders by array of ids
 * @param staffingProvider - State staffingProvider
 */
export const getStaffingProvidersByIds = createSelector(
  getStaffingProviderList,
  (staffingProviders) => (ids: IdsArray) =>
    filter(staffingProviders, (staffingProvider) =>
      ids.includes(`${staffingProvider.id}`),
    ),
);

/**
 * Get staffingProvider count
 * @param staffingProvider - State staffingProvider
 */
export const getStaffingProviderCount = ({ staffingProvider }: IStoreState) =>
  staffingProvider.count;

/**
 * Get combobox list
 */
export const getStaffingProvidersList = ({ staffingProvider }: IStoreState) =>
  staffingProvider.comboboxList;

/**
 * Prepare combobox list
 */
export const getStaffingProvidersComboboxList = createSelector(
  getStaffingProvidersList,
  (staffingProviders) =>
    map(staffingProviders, (staffingProvider) => ({
      id: staffingProvider.id,
      name: staffingProvider.staffingProvider,
    })),
);
