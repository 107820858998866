import qs, { IStringifyOptions } from 'qs';
import { merge } from 'lodash';
import { AnyObject } from '../../types';
import { QS_PARSER_DEFAULT_DEPTH } from '../../../config';

/**
 * A custom hook to compose data for query string
 */
export const useSearch = () => (
  data: AnyObject,
  options: IStringifyOptions = {},
) =>
  qs.stringify(
    data,
    merge(
      {
        allowDots: true,
        depth: QS_PARSER_DEFAULT_DEPTH,
      },
      options,
    ),
  );
