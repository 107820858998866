import { createAction } from 'redux-act';

import {
  IAdditionalFeeCategory,
  IAdditionalFeeCategoryModel,
  IAdditionalFee,
  IAdditionalFeeModel,
  IdsArray,
  IFilter,
  PayloadWithFilters,
} from '../types';

export const fetchAdditionalFeeListRequest = createAction<IFilter>(
  'Fetch Additional Fee list',
);
export const fetchAdditionalFeeListSuccess = createAction<
  IAdditionalFeeModel[]
>('Set fetched Additional fee list to store');
export const fetchAdditionalFeeListFailure = createAction(
  'Cannot fetch Additional Fee list',
);

export const fetchAdditionalFeeCountRequest = createAction<IFilter>(
  'Fetch Additional Fee count',
);
export const fetchAdditionalFeeCountSuccess = createAction<number>(
  'Set fetched Additional fee count to store',
);
export const fetchAdditionalFeeCountFailure = createAction(
  'Cannot fetch Additional Fee count',
);

export const createAdditionalFeeRequest = createAction<
  PayloadWithFilters<IAdditionalFee>
>('Make request to create Additional Fee');
export const createAdditionalFeeSuccess = createAction(
  'New Additional Fee has been created successfully',
);

export const deleteAdditionalFeeRequest = createAction<
  PayloadWithFilters<{
    ids: IdsArray;
  }>
>('Make request to delete Additional Fee');
export const deleteAdditionalFeeFailure = createAction(
  'Cannot delete new Additional Fee',
);

export const sendForUpdateAdditionalFeesRequest = createAction<
  PayloadWithFilters<IAdditionalFeeModel[]>
>('Send data for update Additional Fees');

export const sendForUpdateAdditionalFeesFailure = createAction(
  'Cannot fetch for update Additional Fee Categories List',
);

// Categories
export const fetchAdditionalFeeCategoryListRequest = createAction<IFilter>(
  'Fetch Additional Fee Categories List',
);
export const fetchAdditionalFeeCategoryListSuccess = createAction<
  IAdditionalFeeCategoryModel[]
>('Set Additional Fee Categories List');
export const fetchAdditionalFeeCategoryListFailure = createAction(
  'Cannot fetch Additional Fee Categories List',
);

export const fetchAdditionalFeeCategoriesCountRequest = createAction<IFilter>(
  'Fetch Additional Fee Categories Count',
);
export const fetchAdditionalFeeCategoriesCountSuccess = createAction<number>(
  'Set Additional Fee Categories Count',
);
export const fetchAdditionalFeeCategoriesCountFailure = createAction(
  'Cannot fetch Additional Fee Categories Count',
);

export const deleteAdditionalFeeCategoryRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete Additional Fee Category');
export const deleteAdditionalFeeCategoryFailure = createAction(
  'Cannot delete new Additional Fee Category',
);

export const createAdditionalFeeCategoryRequest = createAction<
  PayloadWithFilters<IAdditionalFeeCategory>
>('Make request to create Additional Fee Category');
export const createAdditionalFeeCategorySuccess = createAction(
  'New Additional Fee Category has been created successfully',
);
export const createAdditionalFeeCategoryFailure = createAction(
  'Cannot create new Additional Fee Category',
);

export const getAdditionalFeeCategoryListForUpdateRequest = createAction<
  IdsArray
>('Fetch for update Additional Fee Categories List');

export const sendForUpdateAdditionalFeeCategoriesRequest = createAction<
  PayloadWithFilters<IAdditionalFeeCategory[]>
>('Send data for update Additional Fee Categories');

export const sendForUpdateAdditionalFeeCategoriesFailure = createAction(
  'Cannot fetch for update Additional Fee Categories List',
);
