import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICountResponse,
  IOpenPositionModel,
  IOpenPositionState,
} from '../../types';

// default state
const defaultState: IOpenPositionState = {
  refreshKey: 0,
  id: NaN,
  positionId: NaN,
  numberNeeded: NaN,
  shiftId: NaN,
  dateStart: '',
  siteId: NaN,
  departmentId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushOpenPositionState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createOpenPositionRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createOpenPositionSuccess = (
  state: IOpenPositionState,
  payload: IOpenPositionModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createOpenPositionFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteOpenPositionRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteOpenPositionSuccess = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const deleteOpenPositionFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateOpenPositionsRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateOpenPositionSuccess = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const updateOpenPositionsFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getOpenPositionListRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getOpenPositionListSuccess = (
  state: IOpenPositionState,
  payload: IOpenPositionModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getOpenPositionListFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getOpenPositionCountRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getOpenPositionCountSuccess = (
  state: IOpenPositionState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getOpenPositionCountFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getOpenPositionComboboxListRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getOpenPositionComboboxListSuccess = (
  state: IOpenPositionState,
  payload: Partial<IOpenPositionModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getOpenPositionComboboxListFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Close open positions actions
 */
const closeOpenPositionRequest = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const closeOpenPositionSuccess = (state: IOpenPositionState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const closeOpenPositionFailed = (
  state: IOpenPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * OpenPosition reducer
 */
export const openPosition = createReducer<IOpenPositionState>({}, defaultState)
  // flush actions
  .on(actions.flushOpenPositionState, flushOpenPositionState)
  // creation actions
  .on(actions.createOpenPositionRequest, createOpenPositionRequest)
  .on(actions.createOpenPositionSuccess, createOpenPositionSuccess)
  .on(actions.createOpenPositionFailed, createOpenPositionFailed)
  // deletion actions
  .on(actions.deleteOpenPositionRequest, deleteOpenPositionRequest)
  .on(actions.deleteOpenPositionSuccess, deleteOpenPositionSuccess)
  .on(actions.deleteOpenPositionFailed, deleteOpenPositionFailed)
  // updating actions
  .on(actions.updateOpenPositionsRequest, updateOpenPositionsRequest)
  .on(actions.updateOpenPositionSuccess, updateOpenPositionSuccess)
  .on(actions.updateOpenPositionsFailed, updateOpenPositionsFailed)
  // get list actions
  .on(actions.getOpenPositionListRequest, getOpenPositionListRequest)
  .on(actions.getOpenPositionListSuccess, getOpenPositionListSuccess)
  .on(actions.getOpenPositionListFailed, getOpenPositionListFailed)
  // get count actions
  .on(actions.getOpenPositionCountRequest, getOpenPositionCountRequest)
  .on(actions.getOpenPositionCountSuccess, getOpenPositionCountSuccess)
  .on(actions.getOpenPositionCountFailed, getOpenPositionCountFailed)
  // close actions
  .on(actions.closeOpenPositionRequest, closeOpenPositionRequest)
  .on(actions.closeOpenPositionSuccess, closeOpenPositionSuccess)
  .on(actions.closeOpenPositionFailed, closeOpenPositionFailed)
  // get combobox list actions
  .on(
    actions.getOpenPositionComboboxListRequest,
    getOpenPositionComboboxListRequest,
  )
  .on(
    actions.getOpenPositionComboboxListSuccess,
    getOpenPositionComboboxListSuccess,
  )
  .on(
    actions.getOpenPositionComboboxListFailed,
    getOpenPositionComboboxListFailed,
  );

export default openPosition;
