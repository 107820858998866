import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IClientModel, IClientState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IClientState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  clientKey: '',
  list: [],
  comboboxList: [],
  count: 0,
  isClientsListRequestInProgress: false,
  isClientsCountRequestInProgress: false,
  isClientCreateRequestInProgress: false,
  isClientUpdateRequestInProgress: false,
  isClientDeleteRequestInProgress: false,
};

/**
 * Flush actions
 */
const flushClientState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createClientRequest = (state: IClientState) => ({
  ...state,
  error: false,
  isClientCreateRequestInProgress: true,
});

const createClientSuccess = (state: IClientState, payload: IClientModel) => ({
  ...state,
  ...payload,
  error: false,
  isClientCreateRequestInProgress: false,
});

const createClientFailed = (state: IClientState) => ({
  ...state,
  isClientCreateRequestInProgress: false,
});

/**
 * Get list actions
 */
const getClientListRequest = (state: IClientState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isClientsListRequestInProgress: true,
});

const getClientListSuccess = (
  state: IClientState,
  payload: IClientModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
  isClientsListRequestInProgress: false,
});

const getClientListFailed = (state: IClientState) => ({
  ...state,
  is_data_requested: false,
  isClientsListRequestInProgress: false,
});

/**
 * Get count actions
 */
const getClientCountRequest = (state: IClientState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isClientsCountRequestInProgress: true,
});

const getClientCountSuccess = (
  state: IClientState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  isClientsCountRequestInProgress: false,
});

const getClientCountFailed = (state: IClientState) => ({
  ...state,
  is_data_requested: false,
  isClientsCountRequestInProgress: false,
});

/**
 * Deletion actions
 */
const deleteClientRequest = (state: IClientState) => ({
  ...state,
  error: false,
  isClientDeleteRequestInProgress: true,
});

const deleteClientSuccess = (state: IClientState) => ({
  ...state,
  isClientDeleteRequestInProgress: false,
});

const deleteClientFailed = (state: IClientState) => ({
  ...state,
  isClientDeleteRequestInProgress: false,
});

/**
 * Updating actions
 */
const updateClientsRequest = (state: IClientState) => ({
  ...state,
  error: false,
  isClientUpdateRequestInProgress: true,
});

const updateClientsSuccess = (state: IClientState) => ({
  ...state,
  isClientUpdateRequestInProgress: false,
});

const updateClientsFailed = (state: IClientState) => ({
  ...state,
  isClientUpdateRequestInProgress: false,
});

/**
 * Get combobox list actions
 */
const getClientComboboxListRequest = (state: IClientState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getClientComboboxListSuccess = (
  state: IClientState,
  payload: Partial<IClientModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getClientComboboxListFailed = (state: IClientState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IClientState>();

const exportClientRequest = exportRequest;
const exportClientSuccess = exportSuccess;
const exportClientFailed = exportFailed;

/**
 * Client reducer
 */
export const client = createReducer<IClientState>({}, defaultState)
  // flush actions
  .on(actions.flushClientState, flushClientState)
  // creation actions
  .on(actions.createClientRequest, createClientRequest)
  .on(actions.createClientSuccess, createClientSuccess)
  .on(actions.createClientFailed, createClientFailed)
  // get list actions
  .on(actions.getClientListRequest, getClientListRequest)
  .on(actions.getClientListSuccess, getClientListSuccess)
  .on(actions.getClientListFailed, getClientListFailed)
  // get count actions
  .on(actions.getClientCountRequest, getClientCountRequest)
  .on(actions.getClientCountSuccess, getClientCountSuccess)
  .on(actions.getClientCountFailed, getClientCountFailed)
  // deletion actions
  .on(actions.deleteClientRequest, deleteClientRequest)
  .on(actions.deleteClientSuccess, deleteClientSuccess)
  .on(actions.deleteClientFailed, deleteClientFailed)
  // updating actions
  .on(actions.updateClientsRequest, updateClientsRequest)
  .on(actions.updateClientsSuccess, updateClientsSuccess)
  .on(actions.updateClientsFailed, updateClientsFailed)
  // get combobox list actions
  .on(actions.getClientComboboxListRequest, getClientComboboxListRequest)
  .on(actions.getClientComboboxListSuccess, getClientComboboxListSuccess)
  .on(actions.getClientComboboxListFailed, getClientComboboxListFailed)
  // export actions
  .on(actions.exportClientRequest, exportClientRequest)
  .on(actions.exportClientSuccess, exportClientSuccess)
  .on(actions.exportClientFailed, exportClientFailed);

export default client;
