import React from 'react';
import { useTranslation } from 'react-i18next';
import PayboardSummary from 'src/pages/TimeKeeping/Payboard/panels/PayboardSummary';
import PayboardDetailed from 'src/pages/TimeKeeping/Payboard/panels/PayboardSummaryDetailed';
import PayboardByWeek from 'src/pages/TimeKeeping/Payboard/panels/PayboardByWeek';
import PayboardKronos from 'src/pages/TimeKeeping/Payboard/panels/PayboardKronos';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { PayboardSummaryByWorkWeek } from './panels/PayboardSummaryByWorkWeek';
import PayboardByDay from './panels/PayboardByDay';
import PayboardByWeekDetailed from './panels/PayboardByWeekDetailed';

export const Payboard = () => {
  const { t } = useTranslation();

  const hasUserAccessToPayboardSummaryTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_SUMMARY_TAB,
  );
  const hasUserAccessToPayboardDetailedTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_DETAILED_TAB,
  );
  const hasUserAccessToPayboardBYWeekTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_BY_WEEK_TAB,
  );
  const hasUserAccessToPayboardByWeekDetailedTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_BY_WEEK_DETAILED_TAB,
  );
  const hasUserAccessToPayboardByDayTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_BY_DAY_TAB,
  );
  const hasUserAccessToPayboardKronosTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_KRONOS_TAB,
  );
  const hasUserAccessToPayboardSummaryByWorkWeekTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_SUMMARY_BY_WORK_WEEK_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('payboard.summary'),
          hasUserAccess: hasUserAccessToPayboardSummaryTab,
          component: <PayboardSummary />,
        },
        {
          title: t('payboard.detailed'),
          hasUserAccess: hasUserAccessToPayboardDetailedTab,
          component: <PayboardDetailed />,
        },
        {
          title: t('payboard.by_week'),
          hasUserAccess: hasUserAccessToPayboardBYWeekTab,
          component: <PayboardByWeek />,
        },
        {
          title: t('payboard.by_week_detailed'),
          hasUserAccess: hasUserAccessToPayboardByWeekDetailedTab,
          component: <PayboardByWeekDetailed />,
        },
        {
          title: t('payboard.by_day'),
          hasUserAccess: hasUserAccessToPayboardByDayTab,
          component: <PayboardByDay />,
        },
        {
          title: t('payboard.kronos'),
          hasUserAccess: hasUserAccessToPayboardKronosTab,
          component: <PayboardKronos />,
        },
        {
          title: t('payboard.summary_by_work_week.title'),
          hasUserAccess: hasUserAccessToPayboardSummaryByWorkWeekTab,
          component: <PayboardSummaryByWorkWeek />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToPayboardBYWeekTab,
      hasUserAccessToPayboardByWeekDetailedTab,
      hasUserAccessToPayboardByDayTab,
      hasUserAccessToPayboardDetailedTab,
      hasUserAccessToPayboardKronosTab,
      hasUserAccessToPayboardSummaryTab,
      hasUserAccessToPayboardSummaryByWorkWeekTab,
      t,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
