import React from 'react';
import get from 'lodash/get';

import { IHeadCell, Order } from 'src/modules/types/table';
import { Pagination, TableComponent, TableContent } from 'src/components/Table';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  ITableSelectedItemsActionsProps,
  TableSelectedItemsActions,
} from 'src/components/Table/components/TableSelectedItemsActions';
import { IdsArray } from 'src/modules/types';

interface ITableWithActionsProps<E, T = E>
  extends Omit<ITableSelectedItemsActionsProps, 'selectedItemsQuantity'> {
  onPageChange: (page: number) => void;
  onSort: (orderBy: string, order: Order) => void;
  onRowsPerPageChange: (rowsCount: number) => void;
  onRowCheckboxClick: (items: Array<T>) => void;
  selectedItems: Array<E>;
  data: Array<E>;
  tableUniqueKey: string;
  count: number;
  isDataLoading: boolean;
  selectedIndex: keyof E;
  limit: number;
  page: number;
  headCells: IHeadCell[];
  order?: Order;
  orderBy?: string;
  heightWithTabs?: boolean;
}

export const TableWithActions = <E,>({
  onPageChange,
  onRowsPerPageChange,
  onSort,
  onRowCheckboxClick,
  order,
  limit,
  page,
  orderBy,
  headCells,
  data,
  count,
  isDataLoading,
  selectedIndex,
  tableUniqueKey,
  selectedItems,
  actionsConfiguration,
  deleteActionConfiguration,
  updateActionConfiguration,
  deleteModelName,
  heightWithTabs,
}: ITableWithActionsProps<E>) => {
  const handlePageChange = (_: any, page: number) => {
    onPageChange(page);
  };

  const handleRowCheckboxClick = (ids: IdsArray) => {
    onRowCheckboxClick(
      data.filter((item) => ids.includes(get(item, [selectedIndex]))),
    );
  };

  return (
    <TableComponent
      heightWithTabs={heightWithTabs}
      tableUniqueKey={tableUniqueKey}
      sx={{
        height: isDataLoading || !data.length ? '100%' : 'auto',
      }}
    >
      {isDataLoading && <MainContentLoader />}

      <TableContent
        data={data as any}
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onSort={onSort}
        onRowCheckboxClick={handleRowCheckboxClick}
        isDataLoading={isDataLoading}
        selectedRows={selectedItems.map((item) => get(item, [selectedIndex]))}
      />

      {Boolean(selectedItems.length) && (
        <TableSelectedItemsActions
          deleteModelName={deleteModelName}
          selectedItemsQuantity={selectedItems.length}
          deleteActionConfiguration={deleteActionConfiguration}
          updateActionConfiguration={updateActionConfiguration}
          actionsConfiguration={actionsConfiguration}
        />
      )}

      <Pagination
        currentPageNumber={page}
        count={count}
        rowsPerPage={limit}
        onPageChange={handlePageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableComponent>
  );
};
