import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FilterValue, FilterValueDescriptor } from 'src/modules/types/table';
import { DepartmentsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DepartmentsComboBoxFilter';
import { DateTimePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DateTimePickerFilter';
import { DownTimeTypesComboBoxFilter } from '../../../../components/Table/components/TableActions/components/Filter/components/DownTimeTypesComboBoxFilter';

interface IAbsenteeHistoricalFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(
    filtersData: Record<string, FilterValue>,
  ): Record<string, FilterValueDescriptor>;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const DowntimeFilter: React.FC<IAbsenteeHistoricalFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <DateTimePickerFilter
          label={getLabel({
            filterName: 'downTime',
            labelPrefix: t('issues.downtime'),
          })}
          {...getFilterCommonPropsByFilterName('downTime')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <DepartmentsComboBoxFilter
          label={getLabel({
            filterName: 'departmentId',
            labelPrefix: t('issues.department'),
          })}
          {...getFilterCommonPropsByFilterName('departmentId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <DownTimeTypesComboBoxFilter
          label={getLabel({
            filterName: 'issueTypeId',
            labelPrefix: t('issues.type'),
          })}
          {...getFilterCommonPropsByFilterName('issueTypeId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'description',
            labelPrefix: t('issues.description'),
          })}
          {...getFilterCommonPropsByFilterName('description')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'duration',
            labelPrefix: t('issues.duration'),
          })}
          {...getFilterCommonPropsByFilterName('duration')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
