import React from 'react';
import { useTranslation } from 'react-i18next';
import { PointsList } from './panels/PointsList';
import { PointsSummary } from './panels/PointsSummary';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { AutomaticPointsToApprove } from './panels/AutomaticPointsToApprove';

export const Points = () => {
  const { t } = useTranslation();

  const hasUserAccessToPointsListTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEES_POINTS_LIST_TAB,
  );

  const hasUserAccessToPointsSummaryTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEES_POINTS_TOTAL_EARNED_TAB,
  );

  const hasUserAccessToAutomaticPointsToApproveTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEES_AUTOMATIC_POINTS_TO_APPROVE_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('points.table_name.points'),
          hasUserAccess: hasUserAccessToPointsListTab,
          component: <PointsList />,
        },
        {
          title: t('points.table_name.points_summary'),
          hasUserAccess: hasUserAccessToPointsSummaryTab,
          component: <PointsSummary />,
        },
        {
          title: t('points.table_name.automatic_points_to_approve'),
          hasUserAccess: hasUserAccessToAutomaticPointsToApproveTab,
          component: <AutomaticPointsToApprove />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToAutomaticPointsToApproveTab,
      hasUserAccessToPointsListTab,
      hasUserAccessToPointsSummaryTab,
      t,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
