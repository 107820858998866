import React from 'react';
import { IFilterPanelOption } from 'src/components/FilterPanel';
import { FilterPanelTextField } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelTextField';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';

export interface IFinancialReportsFilterPanelFilters {
  yearNumber?: number;
  weekNumber?: number;
  siteId?: number;
  yearWeekRange?: {
    from?: number;
    to?: number;
  };
}

interface IFinancialReportsFilterPanelProps {
  onApplyFilters: (appliedFilters: IFinancialReportsFilterPanelFilters) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const FinancialReportsFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IFinancialReportsFilterPanelProps) => {
  const [filters, setFilters] = React.useState<
    IFinancialReportsFilterPanelFilters
  >({
    siteId: undefined,
    weekNumber: undefined,
    yearNumber: undefined,
    yearWeekRange: {
      from: undefined,
      to: undefined,
    },
  });

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce((acc, filterId) => {
      acc[filterId] = filters[filterId];

      return acc;
    }, {});

    onApplyFilters(appliedFiltersData);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    if (filter.id === 'yearNumber' || filter.id === 'weekNumber') {
      return (
        <FilterPanelTextField
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters[filter.id] ?? ''}
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: e.target.value,
            }));
          }}
        />
      );
    }

    if (filter.id === 'siteId') {
      return (
        <FilterPanelSitesCombobox
          excludeTestSites
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters[filter.id] ?? null}
          onChange={(_, value) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: value?.id,
            }));
          }}
        />
      );
    }

    return null;
  };

  return (
    <ReportPageFilterPanel
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
