// @@TODO: finilize this file after application is redesigned
import React, { useEffect, useCallback, useMemo } from 'react';
import { map, get, isEmpty, has, cloneDeep } from 'lodash';
import { Table, Checkbox, TableProps, Sheet } from '@mui/joy';
import EnhancedTableToolbar, {
  EnhancedTableToolbarProps,
} from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { ITableFilterData, ITableFilter } from './EnhancedTableFilter';
import {
  AnyObject,
  IdsArray,
  IFilter,
  IFilterInclude,
  IFilterOrder,
  IFilterWhere,
  IWhere,
  SummaryInfo,
} from '../../modules/types';
import { useEnhancedTableHelpers, useQueryParams } from '../../modules/utils';
import EnhancedTableSummary from './EnhancedTableSummary';
import { shallowEqual, useSelector } from 'react-redux';
import {
  useHeadCellsWithVisibilityFlag,
  useBrowserHistoryFunctions,
} from 'src/modules/utils';
import { defaultOrderDetails } from 'src/modules/utils/helpers/filter';
import { getCurrentRole } from '../../modules/selectors/auth';
import { SxProps } from '@mui/joy/styles/types';
import { useMainLayoutContext } from '../Layout/MainLayout/MainLayoutContext';
import { NoData } from './NoData';
import { Td } from '../TableUiComponents/Td';
import { Tr } from '../TableUiComponents';
import { EnhancedTablePagination } from './EnhancedTablePagination/indext';
import {
  EnhancedTableSelectedItemsActions,
  ICustomAction,
} from './EnhancedTableSelectedItemsActions';
import { MainContentLoader } from '../Layout/components/PageTour/MainContentLoader';

export const ROWS_PER_PAGE_DEFAULT = 25;

export const ROWS_PER_PAGE_ALL = -1;

export const PAGE_DEFAULT = 0;

export type TableOrder = 'asc' | 'desc';

export const commonRowsPerPage = [10, 25, 50, 100];

type RowsPerPage = number | { value: number; label: string };

// @@TODO: adjust this interface since it is using
// material data
export interface IEnhancedTablePaginationProps {
  rowsPerPage: RowsPerPage;
  onPageChange: (
    event: React.ChangeEvent<any>,
    newPage: number,
    context?: AnyObject,
  ) => void;
  onRowsPerPageChange: (event: any) => void;
  rowsPerPageOptions: Array<RowsPerPage>;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
}

/**
 * Header cell type
 */
export interface HeadCell {
  // can be in dot notation like a[0].b.c[1]
  id: string;
  label: string | React.ReactNode;
  disablePadding: boolean;
  disableSorting?: boolean;
  orderByAnotherField?: string;
  noWrapTd?: boolean;
}

export interface HeadCellWithVisibilityFlag extends HeadCell {
  isVisible: boolean;
}

export interface HeadCellWithOrderBy extends HeadCellWithVisibilityFlag {
  orderBy: string;
}

export interface ITableSyncProps {
  page: number;
  rowsPerPage: number;
  order?: IFilterOrder;
  where?: IWhere;
  include?: IFilterInclude[];
}

/**
 * Table component props type
 */
export interface IEnhancedTableProps<O>
  extends Pick<EnhancedTableToolbarProps, 'createEntityBtnProps'> {
  // title of table
  tableName: string;
  // table data
  data: O[];
  // summary rows
  summaryRows?: SummaryInfo[];
  // unique index to select rows by
  selectIndex?: string;
  // head cells list
  headCells: HeadCell[];
  // total count of rows
  count?: number;
  // default order by property
  defaultOrderBy?: Extract<keyof O, string>;

  defaultOrder?: TableOrder;
  // if set, after resetting filters it will be reset to defaultWhere state
  defaultWhere?: IWhere;
  // table filters to be displayed in a modal popup
  filters?: ITableFilter[];
  // initial filter data
  initialFilterData?: ITableFilterData;
  // sync table with server data (changing page, changing rows per page, table sorting)
  // since we have to  do the same dispatch with almost the same action parameters, it makes sense to merge all
  // of those callbacks (like for example onPageChange, onRowsPerPageChange and other) in one
  onSync: (props: ITableSyncProps, additionalTableData?: AnyObject) => void;
  // handle items deleting
  onDelete?: (ids: IdsArray) => void;
  // handle updating
  onUpdate?: (ids: IdsArray) => void;
  onFillInQuestionnaire?: (ids: IdsArray) => void;
  // @@TODO: add proper interface
  renderActions?: any;
  // renderActions?: (
  //   ids: IdsArray,
  //   closeCallback: CloseActionMenuItemType,
  // ) => React.ReactElement<MenuItemProps>[];
  // render footer cells
  renderTableFooter?: () => React.ReactElement;
  // by default checkboxes are always enabled
  disableSelection?: boolean;
  disableQsFilters?: boolean;
  disablePagination?: boolean;
  // row click handle
  onRowClick?: (row: AnyObject) => void;
  // some Tables may need to support inclusions
  include?: IFilterInclude[];
  // density
  size?: TableProps['size'];
  exportProps?: Omit<
    EnhancedTableToolbarProps['exportProps'],
    'onClose' | 'isOpen'
  >;
  additionalWhereForExport?: IWhere;
  ignoreGlobalSitesOnExport?: boolean;
  // model name to be passed to the server to get information about cascade deletion
  readonly deleteModelName?: string;
  // hide filter's reset button
  hideFilterResetButton?: boolean;
  sxOfTableContainer?: SxProps;
  paperEvaluation?: number;
  hideTableName?: boolean;
  customPagination?: Partial<
    Pick<
      IEnhancedTablePaginationProps,
      | 'hideNextButton'
      | 'hidePrevButton'
      | 'onPageChange'
      | 'onRowsPerPageChange'
    >
  >;
  hideFilters?: boolean;
  customAction?: ICustomAction;
  customActions?: Array<ICustomAction>;
  resetToDefaultFilters?: boolean;
  filterComponent?: React.ReactElement;
  isTableDataLoading?: boolean;
  infoMessage?: React.ReactNode | string;
  hideTable?: boolean;
}

export default function EnhancedTable<T>(props: IEnhancedTableProps<T>) {
  const { pageWithCopyrightContentHeight } = useMainLayoutContext();

  const qsFilters = useQueryParams() as IFilter;

  const {
    data,
    summaryRows,
    selectIndex = '',
    count,
    filters,
    disableQsFilters,
    renderTableFooter,
    headCells,
    defaultOrder = defaultOrderDetails.order,
    defaultOrderBy = defaultOrderDetails.orderBy,
    defaultWhere,
    hideFilterResetButton,
    customPagination,
    isTableDataLoading,
  } = props;

  const initialFilterData = cloneDeep(props.initialFilterData) ?? {};

  const [order, setOrder] = React.useState(defaultOrder);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(PAGE_DEFAULT);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWS_PER_PAGE_DEFAULT);
  const [where, setWhere] = React.useState<IWhere>(initialFilterData);
  const [include, setInclude] = React.useState<IFilterInclude[] | undefined>(
    props.include ?? [],
  );
  const {
    resetFiltersInQueryParams,
    pushFilterDataObjectToQueryParams,
  } = useBrowserHistoryFunctions();
  const {
    headCellsWithVisibilityFlag,
    setHeadCellsWithVisibilityFlag,
  } = useHeadCellsWithVisibilityFlag(headCells, props.tableName);

  const onCellVisibilityChange = (cell: HeadCellWithVisibilityFlag) => {
    setHeadCellsWithVisibilityFlag(
      headCellsWithVisibilityFlag.map((c) => (c.id === cell.id ? cell : c)),
    );
  };

  const headCellsWithoutHidden = React.useMemo(() => {
    return headCellsWithVisibilityFlag.filter((cell) => cell.isVisible);
  }, [headCellsWithVisibilityFlag]);

  const {
    composeInclusion,
    composeWhere,
    composeOrder,
  } = useEnhancedTableHelpers({
    where,
    orderBy,
    order,
    rowsPerPage,
    include,
    headCells: headCellsWithoutHidden,
    page,
  });

  const localizationKeys = useMemo(() => summaryRows?.map((s) => s.title), [
    summaryRows,
  ]);

  /**
   * Set page and rows per page from qs if it exists
   */
  const initFilters = useCallback(() => {
    if (has(qsFilters, ['filter', 'include'])) {
      setInclude(get(qsFilters, ['filter', 'include']));
    }

    if (has(qsFilters, ['filter', 'where'])) {
      setWhere(get(qsFilters, ['filter', 'where']));
    }

    if (defaultWhere) {
      setWhere(defaultWhere);
    }

    if (
      (count ?? 0) > 0 &&
      has(qsFilters, ['filter', 'offset']) &&
      has(qsFilters, ['filter', 'limit'])
    ) {
      setRowsPerPage(+qsFilters!.filter!.limit!);
      setPage(qsFilters!.filter!.offset! / qsFilters!.filter!.limit!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  // since props.include is read only we need to clone it
  // in order to do that, we need to call useEffect with empty array of dependencies to call it only one time
  useEffect(() => {
    setInclude(props.include);
    initFilters();
  }, [initFilters, props.include]);

  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    const { filter } = qsFilters;
    if (!disableQsFilters && isEmpty(filter)) {
      pushFilterDataObjectToQueryParams({
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        where,
        order: composeOrder(orderBy, order),
        include: props.include,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  /**
   * Reset query string filters
   */
  const resetQsFilters = () => {
    if (!disableQsFilters) {
      // reset qs filters
      resetFiltersInQueryParams();
    }
  };

  /**
   * On sync event handling
   * @param syncData - data to be synced
   */
  const onSync = (syncData: ITableSyncProps) => {
    if (!disableQsFilters) {
      pushFilterDataObjectToQueryParams({
        limit: syncData.rowsPerPage,
        offset: syncData.page * syncData.rowsPerPage,
        where: syncData.where,
        order: syncData.order,
        include: syncData.include,
      });
    }

    props.onSync(syncData);
  };

  /**
   * Sorting handler
   */
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: Extract<keyof T, string>,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    const inclusion = composeInclusion(property, newOrder, where);
    onSync({
      page,
      rowsPerPage,
      order: composeOrder(property, newOrder),
      where: composeWhere(where),
      include: inclusion,
    });
  };

  /**
   * Click select all handler
   */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = map(props.data, (n: any) => n[selectIndex]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  /**
   * Table row click handler
   */
  const handleClick = (name: string) => {
    if (props.disableSelection) return;

    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  /**
   * Changing current page handler
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setSelected([]);
    setPage(newPage);
    const inclusion = composeInclusion(orderBy, order, where);
    onSync({
      page: newPage,
      rowsPerPage,
      order: composeOrder(orderBy, order),
      where: composeWhere(where),
      include: inclusion,
    });
  };

  /**
   * Changing rows per page handler
   */
  const handleChangeRowsPerPage = (rowsCount: number) => {
    const inclusion = composeInclusion(orderBy, order, where);
    setSelected([]);
    setRowsPerPage(rowsCount);
    setPage(PAGE_DEFAULT);
    onSync({
      page: PAGE_DEFAULT,
      rowsPerPage: rowsCount,
      order: composeOrder(orderBy, order),
      where: composeWhere(where),
      include: inclusion,
    });
  };

  // handle filtering
  const handleFiltering = (filterData: IFilterWhere) => {
    setSelected([]);
    // reset current page
    setPage(PAGE_DEFAULT);
    // keep where object to be sent upon other sync requests
    setWhere(filterData);
    const inclusion = composeInclusion(orderBy, order, filterData);
    // synchronize the table
    onSync({
      page: PAGE_DEFAULT,
      rowsPerPage,
      order: composeOrder(orderBy, order),
      where: composeWhere(filterData),
      include: inclusion,
    });
  };

  // handle reset filters
  const handlerFilterReset = () => {
    setSelected([]);
    const defFilter = {
      page: PAGE_DEFAULT,
      rowsPerPage,
      order: composeOrder(orderBy, order),
      where: composeWhere(props.defaultWhere ?? {}),
      include: props.include,
    };

    // reset filters
    setWhere(props.defaultWhere ?? {});
    // synchronize the table
    setInclude(props.include);
    setPage(PAGE_DEFAULT);

    onSync(defFilter);

    if (props.resetToDefaultFilters) {
      pushFilterDataObjectToQueryParams(defFilter);
    } else {
      resetQsFilters();
    }
  };

  // handle delete confirming
  const handleDelete = () => {
    if (props.onDelete) {
      // reset current page
      setPage(PAGE_DEFAULT);
      setRowsPerPage(ROWS_PER_PAGE_DEFAULT);
      // reset filters
      setWhere(initialFilterData);
      // reset rows per page
      props.onDelete(selected);
      // reset selected items
      setSelected([]);
    }
  };

  /**
   * Checking if a row is selected
   */
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const hasData = !isEmpty(data);

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'auto',
        height: pageWithCopyrightContentHeight,
      }}
    >
      <EnhancedTableToolbar
        title={props.tableName}
        filters={filters}
        initialFilterData={initialFilterData}
        onFilter={handleFiltering}
        onFilterReset={handlerFilterReset}
        createEntityBtnProps={props.createEntityBtnProps}
        exportProps={props.exportProps}
        isQsFiltersDisabled={disableQsFilters}
        composeFilters={{
          page,
          rowsPerPage,
          where,
          include,
          order,
          orderBy,
          headCells,
          localizationKeys,
        }}
        cellsSelectorPros={{
          onCellVisibilityChange,
          onAllCellsVisibilityChange: setHeadCellsWithVisibilityFlag,
          cellsWithVisibilityFlag: headCellsWithVisibilityFlag,
        }}
        additionalWhereForExport={props.additionalWhereForExport}
        ignoreGlobalSitesOnExport={props.ignoreGlobalSitesOnExport}
        hideFilterResetButton={hideFilterResetButton}
        hideTableName={props.hideTableName}
        hideFilters={props.hideFilters}
        filterComponent={props.filterComponent}
      />

      {props.infoMessage}

      {!props.hideTable && (
        <Sheet
          sx={{
            display: 'flex',
            flexGrow: Number(!hasData),
            width: '100%',
            borderWidth: 1,
            overflow: 'hidden',
            borderRadius: 'md',
            borderStyle: 'solid',
            borderColor: 'colors.border.border_primary',
            position: 'relative',
            ...props.sxOfTableContainer,
          }}
        >
          {isTableDataLoading && <MainContentLoader />}

          <Sheet
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <Sheet
              sx={{
                overflow: 'auto',
                width: '100%',
                height: '100%',
                flexGrow: 1,
              }}
            >
              <Table
                sx={{
                  minWidth: '100%',
                  width: 'auto',
                }}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                stickyHeader
                stickyFooter
              >
                <thead>
                  {hasData && (
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort as any}
                      rowCount={data.length}
                      headCells={headCellsWithoutHidden}
                      disableSelection={props.disableSelection}
                    />
                  )}
                </thead>
                <tbody>
                  {hasData &&
                    data.map((row, index) => {
                      const isItemSelected = isSelected(
                        (row as AnyObject)[selectIndex],
                      );

                      return (
                        <Tr
                          onClick={
                            props.onRowClick
                              ? props.onRowClick.bind(null, row as AnyObject)
                              : handleClick.bind(
                                  null,
                                  (row as AnyObject)[selectIndex],
                                )
                          }
                          aria-checked={isItemSelected}
                          key={(row as AnyObject)[selectIndex] || index}
                          {...(has(row, 'className')
                            ? { className: (row as AnyObject)['className'] }
                            : {})}
                          {...(has(row, 'sx')
                            ? { sx: (row as AnyObject)['sx'] }
                            : {})}
                        >
                          {!props.disableSelection && (
                            <Td>
                              <Checkbox size="sm" checked={isItemSelected} />
                            </Td>
                          )}

                          {map(headCellsWithoutHidden, (headColumn) => (
                            <Td
                              key={headColumn.id}
                              noWrap={headColumn.noWrapTd}
                            >
                              {get(row, headColumn.id) as React.ReactNode}
                            </Td>
                          ))}
                        </Tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  {hasData &&
                    summaryRows &&
                    summaryRows.map((summary, i) => (
                      <EnhancedTableSummary
                        key={i}
                        headCells={headCellsWithoutHidden}
                        summary={summary}
                        disableSelection={props.disableSelection}
                      />
                    ))}
                  {hasData && renderTableFooter && renderTableFooter()}
                </tfoot>
                {!hasData && !isTableDataLoading && (
                  <Sheet
                    component="tbody"
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: 'inherit',
                    }}
                  >
                    <NoData />
                  </Sheet>
                )}
              </Table>
            </Sheet>
            <EnhancedTableSelectedItemsActions
              selectedItemsIds={selected}
              onUpdate={props.onUpdate}
              onDelete={props.onDelete && handleDelete}
              onFillInQuestionnaire={props.onFillInQuestionnaire}
              deleteModelName={props.deleteModelName}
              customAction={
                props.customAction
                  ? {
                      ...props.customAction,
                      onClick: () => {
                        props.customAction?.onClick(selected);
                        if (props.customAction?.resetSelected) {
                          setSelected([]);
                        }
                      },
                    }
                  : undefined
              }
              customActions={
                props.customActions
                  ? props.customActions.map((customAction) => ({
                      ...customAction,
                      onClick: () => {
                        customAction?.onClick(selected);
                        if (customAction?.resetSelected) {
                          setSelected([]);
                        }
                      },
                    }))
                  : undefined
              }
            />
            {props.disablePagination ? null : customPagination ? null : (
              <EnhancedTablePagination
                count={props.count ?? 0}
                rowsPerPage={rowsPerPage}
                currentPageNumber={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Sheet>
        </Sheet>
      )}
    </Sheet>
  );
}
