import React from 'react';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';

interface IReportTableToolbarProps {
  reportName?: string;
  children?: React.ReactNode;
}

export const ReportTableToolbar = ({
  reportName,
  children,
}: IReportTableToolbarProps) => {
  return (
    <TableToolbar>
      {reportName && <TableTitle>{reportName}</TableTitle>}
      <TableActions>{children}</TableActions>
    </TableToolbar>
  );
};
