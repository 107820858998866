import React, { useMemo } from 'react';
import { filter, get, map } from 'lodash';
import { ComboBoxOption } from '../ComboBox';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import { ErrorMode } from './types';
import { Theme } from '@mui/material/styles';

import {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  FormControl,
  TextField,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { AnyObject } from '../../modules/types';

export type MultipleSelectOption = ComboBoxOption;

interface IProps<Values>
  extends Omit<
    AutocompleteProps<MultipleSelectOption, true, undefined, undefined>,
    'renderInput'
  > {
  id: string;
  required?: boolean;
  formik: FormikHelpers<Values> & FormikState<Values>;
  options: MultipleSelectOption[];
  errorMode?: ErrorMode;
}

export default function FormikMultipleSelectWithSearch<Values>(
  props: IProps<Values>,
) {
  const {
    id,
    formik,
    errorMode = 'onFormSubmit',
    title,
    options,
    fullWidth,
    ...restProps
  } = props;

  const handleChange = (
    event: React.ChangeEvent<AnyObject>,
    values: MultipleSelectOption[],
  ) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, map(values, 'id'));
  };

  const handleOptionSelected = (
    option: MultipleSelectOption,
    value: MultipleSelectOption,
  ) => {
    return option.id === value.id;
  };

  const value = useMemo(
    () =>
      filter(options, (option) =>
        get(formik.values, id, []).includes(option.id),
      ) as MultipleSelectOption[],
    [formik.values, id, options],
  );

  const error = useMemo(() => get(formik.errors, id), [formik.errors, id]);
  const touched = useMemo(() => get(formik.touched, id), [formik.touched, id]);
  const showError = useMemo<boolean>(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const { required } = restProps;
    return (
      <TextField
        required={required}
        {...params}
        error={showError}
        helperText={showError && error}
        variant="standard"
        label={title}
      />
    );
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      sx={{
        minWidth: 120,
        maxWidth: 300,
        margin: (theme: Theme) => theme.spacing(1),
      }}
    >
      <Autocomplete
        {...restProps}
        multiple
        options={options}
        value={value}
        getOptionLabel={(option: MultipleSelectOption) => option.name}
        renderInput={renderInput}
        onChange={handleChange}
        isOptionEqualToValue={handleOptionSelected}
      />
    </FormControl>
  );
}
