import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useValidate } from '../../../../modules/utils';
import { updateChecklistRequest } from '../../../../modules/actions';
import { IChecklistModel } from 'src/modules/types';
import { FormActionsContainer } from 'src/components/Form/FormActionsContainer';
import { SubmitButton } from 'src/components/Form/SubmitButton';
import { useChecklistFormFields } from 'src/modules/utils/hooks/checklist';
import { updateChecklistScheme } from 'src/modules/schemes/checklist';
import { getChecklists } from 'src/modules/selectors/checklist.selector';
import { Box } from '@mui/material';

interface IUpdateChecklistProps {
  checklistId: number;
  onSubmit: (data: Partial<IChecklistModel>) => void;
}

export const UpdateChecklist: React.FC<IUpdateChecklistProps> = ({
  // taskData,
  // onClose,
  checklistId,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validate = useValidate(updateChecklistScheme);

  const checklists = useSelector(getChecklists);

  const checklistDetails = checklists.find(
    (checklist) => checklist.id === checklistId,
  )!;

  const formik = useFormik({
    validate,
    enableReinitialize: true,
    initialValues: checklistDetails,
    onSubmit: (data) => {
      onSubmit(data);

      dispatch(
        updateChecklistRequest({
          id: data.id,
          done: false,
          title: data.title,
          siteId: data.siteId,
          shiftId: data.shiftId,
          userId: data.userId,
        }),
      );
    },
  });

  const fieldsForUpdateChecklistForm = useChecklistFormFields(formik);

  return (
    <Box
      component="form"
      noValidate
      sx={{ minWidth: '100%' }}
      onSubmit={formik.handleSubmit}
    >
      {fieldsForUpdateChecklistForm}

      <FormActionsContainer>
        <SubmitButton>{t('common.update')}</SubmitButton>
      </FormActionsContainer>
    </Box>
  );
};
