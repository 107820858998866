import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IEmailData } from '../../../modules/types/export';
import { useValidate } from '../../../modules/utils';
import { emailFormScheme } from '../../../modules/schemes';
import { TextField } from 'src/components/_ui-kit/TextField';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { Textarea } from 'src/components/_ui-kit/Textarea';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';

interface IEmailDataFormProps {
  isOpen: boolean;
  onExport: (data: IEmailData) => void;
  onCancel: () => void;
}

export const EmailDataForm = ({
  isOpen,
  onExport,
  onCancel,
}: IEmailDataFormProps) => {
  const { t } = useTranslation();

  const initialValues: IEmailData = {
    to: '',
    subject: '',
    cc: '',
    bcc: '',
    message: '',
  };

  const validate = useValidate<IEmailData>(emailFormScheme);

  const onSubmit = (data: IEmailData) => {
    onExport(data);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      onSubmit(data);

      onCancel();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer
      component="form"
      onSubmit={formik.handleSubmit as any}
      open={isOpen}
      onClose={onCancel}
      anchor="right"
    >
      <DrawerHeader onCloseClick={onCancel}>
        {t('export.email.form_title')}
      </DrawerHeader>
      <DrawerBody sx={{ py: 3 }}>
        {Object.keys(formik.initialValues).map((field) => {
          if (field === 'message') {
            return (
              <FormFieldContainer key={field}>
                <Textarea
                  id={field}
                  name={field}
                  label={t(`export.email.${field}_field`)}
                  variant="outlined"
                  value={(formik.values as any)[field]}
                  onChange={formik.handleChange}
                  error={
                    (formik.touched as any)[field] &&
                    Boolean((formik.errors as any)[field])
                  }
                  helperText={
                    (formik.touched as any)[field] &&
                    (formik.errors as any)[field]
                  }
                />
              </FormFieldContainer>
            );
          }

          return (
            <FormFieldContainer key={field}>
              <TextField
                name={field}
                id={field}
                label={t(`export.email.${field}_field`)}
                variant="outlined"
                required={field === 'to'}
                value={(formik.values as any)[field]}
                onChange={formik.handleChange}
                error={
                  (formik.touched as any)[field] &&
                  Boolean((formik.errors as any)[field])
                }
                helperText={
                  (formik.touched as any)[field] &&
                  (formik.errors as any)[field]
                }
              />
            </FormFieldContainer>
          );
        })}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onCancel={onCancel}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
