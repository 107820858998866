import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  activateUserRequest,
  deactivateUserRequest,
  deleteUserRequest,
  exportUserRequest,
  getUserCountRequest,
  getUserListRequest,
  lockUserRequest,
  resetPasswordByAdminRequest,
  unlockUserRequest,
} from '../../modules/actions';
import {
  getUserCount,
  getUserListWithAttributesNames,
  getUserRefreshKey,
  getUsersComboboxList,
  getUsersMap,
} from '../../modules/selectors/user';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { AnyObject, IdsArray } from '../../modules/types';
import { isArray, map, mergeWith } from 'lodash';
import { Lock as MuiLock, LockOpen } from '@mui/icons-material';
import { NoIcon, YesIcon } from '../../components/Icons';
import { Tooltip } from '@mui/material';
import { ComboBoxOption } from '../../components/ComboBox';
import { getCurrentRole } from '../../modules/selectors/auth';
import {
  ModelsToDelete,
  roles as rolesConfig,
  useHasUserAccessToAction,
} from '../../config';
import {
  useSearch,
  useCreateExportProps,
  useFetchClientsCombobox,
  useFetchStaffingProvidersCombobox,
  useFilter,
  useUsersDefaultFilters,
  useBrowserHistoryFunctions,
  useFetchShiftsCombobox,
  useFetchActiveDepartmentsCombobox,
  useSupervisorCombobox,
  useFetchRolesCombobox,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import { getClientsComboboxList } from '../../modules/selectors/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { getShiftsComboboxList } from '../../modules/selectors/shift';
import { getDepartmentsComboboxList } from '../../modules/selectors/department';
import { getRolesComboboxListByFieldId } from '../../modules/selectors/role';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { ICustomAction } from '../../components/EnhancedTable/EnhancedTableSelectedItemsActions';
import {
  Key,
  Unlock,
  UserCheck,
  UserCross,
  Lock,
} from '../../components/svgIcons';
import { ActionConfirmation } from '../../components/ActionConfirmation';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const UsersList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.user.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.user.update.id,
  );

  const defaultFilter = useUsersDefaultFilters();

  // fetch comboboxes lists
  const fetchClientsCombobox = useFetchClientsCombobox();
  const fetchStaffingProviderCombobox = useFetchStaffingProvidersCombobox();
  const exportProps = useCreateExportProps(exportUserRequest);
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();
  const fetchRolesCombobox = useFetchRolesCombobox();

  // make request to fetch staffing providers from the server if we don't have them in the store
  useEffect(() => {
    fetchStaffingProviderCombobox();
    fetchClientsCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchSupervisorsCombobox();
    fetchRolesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roles = useSelector(getRolesComboboxListByFieldId, shallowEqual)('key');

  const [isConfirmLogoutOpen, setIsConfirmLogoutOpen] = useState<boolean>(
    false,
  );
  const [selectedIds, setSelectedIds] = React.useState<IdsArray>([]);

  const supervisors = useSelector(getUsersComboboxList, shallowEqual);

  // fetch User list
  const list = useSelector(getUserListWithAttributesNames, shallowEqual);

  // computed list with icons
  const computedList = useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          role: item.role || (
            <Tooltip
              arrow
              placement="top"
              title={t('users.role_needed') as string}
            >
              <FontAwesomeIcon color="red" fontSize={18} icon={faCommentAlt} />
            </Tooltip>
          ),
          locked: item.locked ? (
            <MuiLock color="error" />
          ) : (
            <LockOpen color="primary" />
          ),
          activated: item.activated ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          supervisor: item.supervisorId
            ? `${item.supervisor?.firstName} ${item.supervisor?.lastName}`
            : null,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  const count = useSelector(getUserCount, shallowEqual);

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // fetch staffing providers list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );

  const shifts = useSelector(getShiftsComboboxList, shallowEqual);
  const departments = useSelector(getDepartmentsComboboxList, shallowEqual);

  // fetch clients list from store
  const clients = useSelector(getClientsComboboxList, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'firstName', disablePadding: false, label: t('users.first_name') },
    { id: 'lastName', disablePadding: false, label: t('users.last_name') },
    { id: 'email', disablePadding: false, label: t('users.email') },
    { id: 'activated', disablePadding: false, label: t('users.activated') },
    { id: 'locked', disablePadding: false, label: t('users.locked') },
    { id: 'role', disablePadding: false, label: t('users.role') },
    {
      id: 'siteName',
      disablePadding: false,
      disableSorting: true,
      label: t('users.site_name'),
    },
    {
      id: 'clientName',
      disablePadding: false,
      disableSorting: true,
      label: t('users.client_name'),
    },
    {
      id: 'staffingName',
      disablePadding: false,
      disableSorting: true,
      label: t('users.staff_prov'),
    },
    { id: 'phoneNumber', disablePadding: false, label: t('users.phoneNumber') },
    { id: 'wmsLogin', disablePadding: false, label: t('users.wmsLogin') },
    { id: 'shift.name', disablePadding: false, label: t('users.shiftId') },
    {
      id: 'department.name',
      disablePadding: false,
      label: t('users.departmentId'),
    },
    {
      id: 'supervisor',
      disablePadding: false,
      disableSorting: true,
      label: t('users.supervisorId'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'firstName',
      label: t('users.first_name'),
      operator: 'like',
    },
    {
      name: 'lastName',
      label: t('users.last_name'),
      operator: 'like',
    },
    {
      name: 'email',
      label: t('users.email'),
      operator: 'like',
    },
    {
      name: 'activated',
      label: t('users.activated'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'locked',
      label: t('users.locked'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'role',
      label: t('users.role'),
      operator: 'eq',
      type: 'combobox',
      options: roles,
    },
    {
      name: 'userAttributes.site.id',
      label: t('users.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'userAttributes.clientId',
      label: t('users.client'),
      operator: 'eq',
      type: 'combobox',
      options: clients as ComboBoxOption[],
    },
    {
      name: 'userAttributes.staffingId',
      label: t('users.staff_prov'),
      operator: 'eq',
      type: 'combobox',
      options: staffingProviders as ComboBoxOption[],
    },
    {
      name: 'phoneNumber',
      label: t('users.phoneNumber'),
      operator: 'like',
    },
    {
      name: 'wmsLogin',
      label: t('users.wmsLogin'),
      operator: 'like',
    },
    {
      name: 'supervisorId',
      label: t('users.supervisorId'),
      operator: 'like',
      type: 'combobox',
      options: supervisors,
    },
    {
      name: 'shiftId',
      label: t('users.shiftId'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'departmentId',
      label: t('users.departmentId'),
      operator: 'eq',
      type: 'combobox',
      options: departments as ComboBoxOption[],
    },
  ];

  // make request to fetch users when component is mounted
  useEffect(() => {
    dispatcher(getUserListRequest(filterList));
    // get total count
    dispatcher(getUserCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getUserListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where: mergeWith(
            where,
            defaultFilter.where,
            (objValue: AnyObject[], srcValue: AnyObject[]) => {
              if (isArray(objValue)) {
                return objValue.concat(srcValue);
              }
              return objValue;
            },
          ),
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getUserCountRequest({ filter: { where, include: newInclude } }));
  };

  const usersMap = useSelector(getUsersMap, shallowEqual);

  const getUsersList = React.useCallback(
    (ids: IdsArray) => (
      <ul>
        {ids
          .map((id) => usersMap[id])
          .map((u) => (
            <li key={u.id}>
              #{u.id} {u.firstName} {u.lastName} ({u.email}) - {u.role}
            </li>
          ))}
      </ul>
    ),
    [usersMap],
  );

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteUserRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: '/users/update', search });
  };

  // handle activating
  const handleActivate = (ids: IdsArray) => {
    dispatcher(activateUserRequest(ids));
  };

  // handle deactivating
  const handleDeactivate = (ids: IdsArray) => {
    dispatcher(deactivateUserRequest(ids));
  };

  // handle reset-password
  const handleResetPassword = () => {
    dispatcher(resetPasswordByAdminRequest(selectedIds));
    setSelectedIds([]);
    setIsConfirmLogoutOpen(false);
  };

  // handle unlocking
  const handleUnlock = (ids: IdsArray) => {
    dispatcher(unlockUserRequest(ids));
  };

  // handle locking
  const handleLock = (ids: IdsArray) => {
    dispatcher(lockUserRequest(ids));
  };

  const customActions: Array<ICustomAction> = React.useMemo(
    () => {
      const actions = [
        {
          title: t('users.activate'),
          icon: <UserCheck />,
          onClick: handleActivate,
        },
        {
          title: t('users.deactivate'),
          icon: <UserCross />,
          onClick: handleDeactivate,
        },
        {
          title: t('users.unlock'),
          icon: <Unlock />,
          onClick: handleUnlock,
        },
        {
          title: t('users.lock'),
          icon: <Lock />,
          onClick: handleLock,
        },
      ];

      if (
        [
          rolesConfig.SUPER_ADMIN,
          rolesConfig.ADMIN,
          rolesConfig.GM,
          rolesConfig.ACCOUNTING,
        ].includes(currentRole)
      ) {
        actions.push({
          title: t('users.reset_password'),
          icon: <Key />,
          onClick: (ids) => {
            setIsConfirmLogoutOpen(true);
            setSelectedIds(ids);
          },
        });
      }

      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <ActionConfirmation
          type="success"
          open={isConfirmLogoutOpen}
          onCancel={() => setIsConfirmLogoutOpen(false)}
          onOk={() => handleResetPassword()}
        >
          {t('users.confirm_reset_password')}
          {getUsersList(selectedIds)}
        </ActionConfirmation>
        <EnhancedTable
          disableQsFilters
          data={computedList}
          count={count}
          selectIndex="id"
          tableName={t('users.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          customActions={customActions}
          include={defaultFilter.include}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.User}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function UsersListRefreshable() {
  return <UsersList key={useSelector(getUserRefreshKey)} />;
}
