import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateStaffingProviderRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IStaffingProviderModel,
  PayloadWithNavigateFunc,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new staffingProvider
 */
export const createStaffingProviderRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateStaffingProviderRequest>
>): SagaIterator {
  try {
    const response: IStaffingProviderModel = yield call(
      Api.StaffingProvider.create,
      data,
    );
    yield put(actions.flushStaffingProviderState());
    yield put(actions.createStaffingProviderSuccess(response));
    yield call(navigate, { pathname: '/staffing-providers' });
  } catch (e) {
    yield put(actions.createStaffingProviderFailed());
  }
};

/**
 * Get staffingProvider list
 */
export const getStaffingProviderListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IStaffingProviderModel[] = yield call(
      Api.StaffingProvider.list,
      action.payload,
    );
    yield put(actions.getStaffingProviderListSuccess(response));
  } catch (e) {
    yield put(actions.getStaffingProviderListFailed());
  }
};

/**
 * Get staffingProvider count
 */
export const getStaffingProviderCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.StaffingProvider.count,
      action?.payload,
    );
    yield put(actions.getStaffingProviderCountSuccess(response));
  } catch (e) {
    yield put(actions.getStaffingProviderCountFailed());
  }
};

/**
 * Delete staffingProviders
 */
export const deleteStaffingProviderRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.StaffingProvider.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushStaffingProviderState());
  } catch (e) {
    yield put(actions.deleteStaffingProviderFailed());
  }
};

/**
 * Bulk update staffingProviders
 */
export const updateStaffingProvidersRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IStaffingProviderModel[]>
>): SagaIterator {
  try {
    yield call(Api.StaffingProvider.bulkUpdate, data);
    yield put(actions.flushStaffingProviderState());
    yield call(navigate, { pathname: '/staffing-providers' });
  } catch (e) {
    yield put(actions.updateStaffingProvidersFailed());
  }
};

/**
 * Get combobox list
 */
export const getStaffingProviderComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IStaffingProviderModel>[] = yield call(
      Api.StaffingProvider.list,
      action.payload,
    );
    yield put(actions.getStaffingProviderComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getStaffingProviderComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.StaffingProvider.export,
  actionFailed: actions.exportStaffingProviderFailed,
  actionSuccess: actions.exportStaffingProviderSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportStaffingProviderRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createStaffingProviderRequest,
    saga: createStaffingProviderRequestSaga,
  },
  {
    action: actions.getStaffingProviderListRequest,
    saga: getStaffingProviderListRequestSaga,
  },
  {
    action: actions.getStaffingProviderCountRequest,
    saga: getStaffingProviderCountRequestSaga,
  },
  {
    action: actions.deleteStaffingProviderRequest,
    saga: deleteStaffingProviderRequestSaga,
  },
  // bulk updating staffingProviders
  {
    action: actions.updateStaffingProvidersRequest,
    saga: updateStaffingProvidersRequestSaga,
  },
  {
    action: actions.getStaffingProviderComboboxListRequest,
    saga: getStaffingProviderComboboxListRequestSaga,
  },
  {
    action: actions.exportStaffingProviderRequest,
    saga: exportStaffingProviderRequestSaga,
  },
];
