import React from 'react';
import { IHeadCell, ISortDetails, Order } from 'src/modules/types/table';
import { Th } from 'src/components/TableUiComponents';

export interface IHeadCellProps extends IHeadCell {
  handleSort?: (sortBy: string) => void;
}

export const HeadCell: React.FC<IHeadCellProps & ISortDetails> = ({
  id,
  label,
  order,
  orderBy,
  handleSort,
  disableSorting,
}) => {
  const sortDirection: Order | undefined = orderBy === id ? order : undefined;

  return (
    <Th
      sortDirection={sortDirection}
      onClick={handleSort && !disableSorting ? () => handleSort(id) : undefined}
    >
      {label}
    </Th>
  );
};
