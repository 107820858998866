import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileForm } from '../ProfileForm';
import {
  addProcessStatus,
  changeNotificationsPreferencesRequest,
} from '../../../modules/actions';
import { getNotificationsPreferences } from '../../../modules/selectors/user';
import { Checkbox } from 'src/components/_ui-kit/Checkbox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';

const NotificationsPreferences = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationsPreferences = useSelector(getNotificationsPreferences);
  const [
    notificationsPreferencesState,
    setNotificationsPreferencesState,
  ] = useState(notificationsPreferences);

  const isSomeCheckboxChanged = () =>
    Object.keys(notificationsPreferencesState).some(
      (notificationType) =>
        notificationsPreferencesState[notificationType] !==
        notificationsPreferences[notificationType],
    );

  const handleFormSubmit = () => {
    // Display success process status if preferences did not change
    if (!isSomeCheckboxChanged()) {
      dispatch(
        addProcessStatus({
          variant: 'success',
          message: 'profile.change_notifications_preferences_success',
        }),
      );
      return;
    }

    dispatch(
      changeNotificationsPreferencesRequest(notificationsPreferencesState),
    );
  };

  const onReset = () => {
    setNotificationsPreferencesState({
      smsNotificationsEnabled: false,
      emailNotificationsEnabled: false,
      webNotificationsEnabled: false,
    });
  };

  return (
    <ProfileForm
      onApply={handleFormSubmit}
      onReset={onReset}
      title={t('profile.profile_settings')}
    >
      <FormFieldContainer>
        <Checkbox
          name="webNotificationsEnabled"
          label={t('profile.web_notifications')}
          checked={Boolean(
            notificationsPreferencesState['webNotificationsEnabled'],
          )}
          onChange={(e) =>
            setNotificationsPreferencesState((prevState) => ({
              ...prevState,
              webNotificationsEnabled: e.target.checked,
            }))
          }
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <Checkbox
          label={t('profile.email_notifications')}
          name="emailNotificationsEnabled"
          checked={Boolean(
            notificationsPreferencesState['emailNotificationsEnabled'],
          )}
          onChange={(e) =>
            setNotificationsPreferencesState((prevState) => ({
              ...prevState,
              emailNotificationsEnabled: e.target.checked,
            }))
          }
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <Checkbox
          label={t('profile.sms_notifications')}
          name="smsNotificationsEnabled"
          checked={Boolean(
            notificationsPreferencesState['smsNotificationsEnabled'],
          )}
          onChange={(e) =>
            setNotificationsPreferencesState((prevState) => ({
              ...prevState,
              smsNotificationsEnabled: e.target.checked,
            }))
          }
        />
      </FormFieldContainer>
    </ProfileForm>
  );
};

export default NotificationsPreferences;
