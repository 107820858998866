import { createSelector } from 'reselect';
import { IStoreState } from '../types';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  format,
  parseISO,
} from 'src/modules/utils/dateWrapper';

export const getIssueTrackerData = (state: IStoreState) => state.issueTracker;

export const getIssueTrackerRefreshKey = createSelector(
  getIssueTrackerData,
  (issueTrackerData) => {
    return issueTrackerData.refreshKey;
  },
);

export const getIssuesFromIssueTracker = createSelector(
  getIssueTrackerData,
  (issueTrackerData) => {
    return issueTrackerData.list || [];
  },
);

export const getIssuesFromIssueTrackerForTable = createSelector(
  getIssuesFromIssueTracker,
  (list) => {
    return list.map((item) => ({
      ...item.fields,
      id: Number(item.id),
      status: item.fields.status.name,
      priority: item.fields.priority.name,
      supportCategory: item.fields.labels[0],
      created: composeDate(
        parseISO(item.fields.created),
        format(DATETIME_FORMAT_TO_SHOW),
      ),
    }));
  },
);
