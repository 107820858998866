import React from 'react';

import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { useTranslation } from 'react-i18next';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { AnyObject } from 'src/modules/types';

interface IShiftFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(filters: AnyObject): void;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const ShiftFilter: React.FC<IShiftFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'name',
            labelPrefix: t('shifts.name'),
          })}
          {...getFilterCommonPropsByFilterName('name')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <CheckboxFilter
          label={getLabel({
            filterName: 'overnight',
            labelPrefix: t('shifts.overnight'),
          })}
          {...getFilterCommonPropsByFilterName('overnight')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'delay',
            labelPrefix: t('shifts.delay'),
          })}
          {...getFilterCommonPropsByFilterName('delay')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'desiredEmployeesNumber',
            labelPrefix: t('shifts.desiredEmployeesNumber'),
          })}
          {...getFilterCommonPropsByFilterName('desiredEmployeesNumber')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <CheckboxFilter
          label={getLabel({
            filterName: 'default',
            labelPrefix: t('shifts.default'),
          })}
          {...getFilterCommonPropsByFilterName('default')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('shifts.site_name'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'duration',
            labelPrefix: t('shifts.duration'),
          })}
          {...getFilterCommonPropsByFilterName('duration')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
