import React from 'react';
import { ExceptionFromProductionFiltersNames } from 'src/modules/types/exceptionsFromProduction';
import {
  FilterValueDescriptor,
  IHeadCellOrderDetails,
  IHeadCellWithOrderConfig,
} from 'src/modules/types/table';

type IUseMapFilterNameToFilterFieldsConfigurationReturnType = {
  [key in ExceptionFromProductionFiltersNames]: FilterValueDescriptor;
};

const emptyFilterFieldsConfiguration = {};

export const useMapFilterNameToFilterFieldsConfiguration = (
  filters?: Array<ExceptionFromProductionFiltersNames>,
): IUseMapFilterNameToFilterFieldsConfigurationReturnType => {
  return React.useMemo(() => {
    if (!filters) {
      return emptyFilterFieldsConfiguration as IUseMapFilterNameToFilterFieldsConfigurationReturnType;
    }

    const configuration = {} as IUseMapFilterNameToFilterFieldsConfigurationReturnType;

    filters.forEach((filter) => {
      if (filter === 'date') {
        configuration.date = {
          value: null,
          property: 'date',
          operator: 'eq' as const,
        };
      }
    });

    return configuration;
  }, [filters]);
};

interface IUseExceptionsFromProductionHeadCellsDataArgs {
  list: Array<Record<string, string | number>>;
  headCellsToOmit?: Array<string>;
}

export const useExceptionsFromProductionHeadCellsData = ({
  list,
  headCellsToOmit,
}: IUseExceptionsFromProductionHeadCellsDataArgs) => {
  return React.useMemo(() => {
    const listItem = list[0];

    if (!listItem) {
      return {
        headCells: [],
        headCellsOrderDetails: [],
      };
    }

    return Object.keys(listItem).reduce<{
      headCells: IHeadCellWithOrderConfig[];
      headCellsOrderDetails: IHeadCellOrderDetails[];
    }>(
      (acc, headCell) => {
        if (headCellsToOmit?.includes(headCell)) {
          return acc;
        }

        acc.headCells.push({
          id: headCell,
          label: headCell,
          orderConfig: { orderBy: headCell },
        });
        acc.headCellsOrderDetails.push({
          id: headCell,
          orderBy: headCell,
        });

        return acc;
      },
      { headCells: [], headCellsOrderDetails: [] },
    );
  }, [headCellsToOmit, list]);
};
