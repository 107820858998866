import { JSONSchemaType } from 'ajv';
import { IUpdatePricing, PricingSettingsConfig } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import {
  generateCreatePricingSingleScheme,
  createPricingRangeItemScheme,
} from './create-pricing.scheme';

export const generateUpdatePricingSingleScheme = (
  settings: PricingSettingsConfig,
): JSONSchemaType<IUpdatePricing[]> => {
  const createPricingScheme = generateCreatePricingSingleScheme(settings);

  return {
    type: 'array',
    items: {
      ...createPricingScheme,
      type: 'object',
      required: createPricingScheme.required,
      properties: {
        ...createPricingScheme.properties,
        id: {
          type: 'number',
          isNotEmpty: true,
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
          },
        },
      },
    },
  };
};

export const updatePricingRangeScheme = {
  type: 'array',
  items: {
    ...createPricingRangeItemScheme,
    type: 'object',
    required: createPricingRangeItemScheme.required,
    properties: {
      ...createPricingRangeItemScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};

export const generateUpdatePricingSingleRecordScheme = (
  settings: PricingSettingsConfig,
): JSONSchemaType<IUpdatePricing> => {
  const createPricingScheme = generateCreatePricingSingleScheme(settings);

  return {
    ...createPricingScheme,
    type: 'object',
    required: createPricingScheme.required,
    properties: {
      ...createPricingScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  };
};
