import React from 'react';
import { Sheet, SheetProps } from '@mui/joy';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SearchLgSvg } from 'src/components/svgIcons';

export interface INoDataProps {
  title?: string;
  description?: string;
  containerSheetProps?: Omit<SheetProps, 'sx'>;
  childSheetProps?: Omit<SheetProps, 'sx'>;
}

export const NoData = ({
  title,
  description,
  childSheetProps,
  containerSheetProps,
}: INoDataProps) => {
  const { t } = useTranslation();

  const titleToUse = title ?? t('common.no_data');
  const descriptionToUse =
    description ??
    t(
      'common.you_have_to_change_the_query_to_get_the_search_results_to_appear',
    );

  return (
    <Sheet
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: {
          xs: 280,
          md: 480,
        },
        height: {
          xs: 280,
          md: 480,
        },
        backgroundImage: 'url(/images/background_pattern_decorative_md.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        bgcolor: 'colors.foreground.fg_white',
      }}
      {...containerSheetProps}
    >
      <Sheet
        sx={{
          backgroundColor: 'transparent',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          bgcolor: 'transparent',
        }}
        {...childSheetProps}
      >
        <Sheet
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 1.5,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: '#EAECF0',
            borderStyle: 'solid',
            bgcolor: 'transparent',
          }}
        >
          <SearchLgSvg />
        </Sheet>

        <Sheet
          sx={{
            position: 'absolute',
            top: '70px',
            backgroundColor: 'transparent',
            textAlign: 'center',
          }}
        >
          <Typography level="text_md" fontWeight="semi_bold">
            {titleToUse}
          </Typography>
          <Typography level="text_sm" fontWeight="regular" sx={{ width: 350 }}>
            {descriptionToUse}
          </Typography>
        </Sheet>
      </Sheet>
    </Sheet>
  );
};
