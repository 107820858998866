import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useValidate } from 'src/modules/utils';
import { SubmitButton } from 'src/components/Form/SubmitButton';
import { createChecklistRequest } from 'src/modules/actions';
import { createChecklistScheme } from 'src/modules/schemes/checklist';
import { FormActionsContainer } from 'src/components/Form/FormActionsContainer';
import { useChecklistFormFields } from 'src/modules/utils/hooks/checklist';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { Box } from '@mui/material';

interface ICreateChecklistProps {
  // checklistData: ICreateChecklistTaskData;
  // onClose: () => void;
  onSubmit: (data: any) => void;
}

export const CreateChecklist: React.FC<ICreateChecklistProps> = ({
  onSubmit,
  // onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validate = useValidate(createChecklistScheme);

  const sites = useSelector(getSitesComboboxList);
  const preselectedSiteId = sites[0]?.id;

  const initialValues = React.useMemo(
    () => ({
      title: '',
      siteId: preselectedSiteId,
      userId: NaN,
      shiftId: NaN,
    }),
    [preselectedSiteId],
  );

  const formik = useFormik({
    validate,
    initialValues,
    enableReinitialize: true,
    onSubmit: (data) => {
      onSubmit(data);

      dispatch(createChecklistRequest(data));
    },
  });

  const fieldsForCreateChecklistForm = useChecklistFormFields(formik);

  return (
    <Box
      component="form"
      noValidate
      sx={{ minWidth: '100%' }}
      onSubmit={formik.handleSubmit}
    >
      {fieldsForCreateChecklistForm}
      <FormActionsContainer>
        <SubmitButton>{t('common.create')}</SubmitButton>
      </FormActionsContainer>
    </Box>
  );
};
