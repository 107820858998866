import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { restoreEmployeeRequest } from 'src/modules/actions';
import { useValidate } from '../../../../../modules/utils/hooks';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';
import FormikCombobox from '../../../../../components/Formik/FormikCombobox';
import FormikCheckbox from '../../../../../components/Formik/FormikCheckbox';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import { getRemovedEmployeeListForForm } from 'src/modules/selectors/employee';
import FormikDatepicker from '../../../../../components/Formik/FormikDatepicker';
import { restoreEmployeeScheme } from '../../../../../modules/schemes/employees';
import { getShiftsComboboxListBySiteId } from '../../../../../modules/selectors/shift';
import { getPositionsComboboxListBySiteId } from '../../../../../modules/selectors/position';
import { getDepartmentsComboboxListBySiteId } from '../../../../../modules/selectors/department';
import { getStaffingProvidersComboboxList } from '../../../../../modules/selectors/staffingProvider';
import { getTerminationReasonComboboxListBySiteId } from '../../../../../modules/selectors/terminationReason';
import SupervisorComboBox from '../../../../../components/Formik/comboboxes-with-entities/SupervisorComboBox';
import {
  IRestoreEntityFormModalProps,
  RestoreEntityFormModal,
} from '../../../common/RestoreEntityFormModal';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { FormFieldContainer } from '../../../../../components/Form/FormFieldContainer';

interface IRestoreEmployeeFormProps
  extends Omit<IRestoreEntityFormModalProps, 'title' | 'children'> {
  employeeId: number;
}

export const RestoreEmployeeForm: React.FC<IRestoreEmployeeFormProps> = ({
  employeeId,
  onClose,
  ...restoreEntityFormModalProps
}) => {
  const dispatch = useDispatch();
  const employees = useSelector(getRemovedEmployeeListForForm);

  const employeeDetails = employees.find(({ id }) => id === employeeId)!;

  const { t } = useTranslation();

  const validate = useValidate(restoreEmployeeScheme);

  const formik = useFormik({
    initialValues: employeeDetails,
    validate,
    onSubmit: (employee) => {
      dispatch(
        restoreEmployeeRequest({
          ...employee,
          deletedAt: null,
        }),
      );

      onClose && onClose();
    },
  });

  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  // fetch termination reasons
  const terminationReasonsBySiteId = useSelector(
    getTerminationReasonComboboxListBySiteId,
    shallowEqual,
  );

  const positionsBySiteId = useSelector(
    getPositionsComboboxListBySiteId,
    shallowEqual,
  );

  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );

  const shiftsBySiteId = useSelector(
    getShiftsComboboxListBySiteId,
    shallowEqual,
  );

  const departmentsBySiteId = useSelector(
    getDepartmentsComboboxListBySiteId,
    shallowEqual,
  );

  if (!employeeDetails) {
    return null;
  }

  return (
    <RestoreEntityFormModal
      title={`${employeeDetails.firstName} ${employeeDetails.lastName}`}
      onOk={formik.handleSubmit}
      onClose={onClose}
      {...restoreEntityFormModalProps}
    >
      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={'employeeId'}
          label={t('employees.emp_id')}
          name={'employeeId'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={'siteId'}
          label={t('employees.site')}
          placeholder={t('employees.site')}
          options={sites}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={'positionId'}
          label={t('employees.position')}
          placeholder={t('employees.position')}
          options={positionsBySiteId(formik.values.siteId) as ComboBoxOption[]}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={'staffingId'}
          label={t('employees.staffing_provider')}
          placeholder={t('employees.staffing_provider')}
          options={staffingProviders as ComboBoxOption[]}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SupervisorComboBox
          required
          id={'supervisorId'}
          label={t('employees.supervisor')}
          placeholder={t('employees.supervisor')}
          siteId={formik.values.siteId}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={'defaultShiftId'}
          label={t('employees.default_shift')}
          placeholder={t('employees.default_shift')}
          options={shiftsBySiteId(formik.values.siteId)}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={'defaultDepartmentId'}
          label={t('employees.default_department')}
          placeholder={t('employees.default_department')}
          options={departmentsBySiteId(formik.values.siteId)}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={'firstName'}
          label={t('employees.first_name')}
          name={'firstName'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={'lastName'}
          label={t('employees.last_name')}
          name={'lastName'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={'payRate'}
          label={t('employees.pay_rate')}
          name={'payRate'}
          type="number"
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikDatepicker
          required
          fullWidth
          label={t('employees.date_hired')}
          id={'dateHired'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={'badge'}
          label={t('employees.badge')}
          name={'badge'}
          type="number"
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCheckbox
          id={'active'}
          formik={formik}
          errorMode="onFieldChange"
          label={t('employees.active')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={'address'}
          name={'address'}
          label={t('employees.address')}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={'phonenumber'}
          label={t('employees.phone')}
          name={'phonenumber'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikDatepicker
          fullWidth
          label={t('employees.date_entered')}
          id={'dateentered'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={'last4SSN'}
          label={t('employees.4ssn')}
          name={'last4SSN'}
          type="number"
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={'wmsLogin'}
          label={t('employees.wms_login')}
          name={'wmsLogin'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={'emergencyContact'}
          label={t('employees.emergency_contact')}
          name={'emergencyContact'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      {/**@@TODO: fix inputProps */}
      <FormFieldContainer>
        <FormikTextarea
          variant="outlined"
          id={'comments'}
          label={t('employees.comments')}
          name={'comments'}
          formik={formik}
          errorMode="onFieldChange"
          // inputProps={{
          //   maxLength: COMMENT_MAX_LENGTH,
          // }}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikDatepicker
          fullWidth
          label={t('employees.separation_date')}
          id={'separationdate'}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          id={'terminationReasonId'}
          label={t('employees.termination_reason')}
          placeholder={t('employees.termination_reason')}
          options={terminationReasonsBySiteId(formik.values.siteId)}
          formik={formik}
          errorMode="onFieldChange"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCheckbox
          id={'canRehire'}
          formik={formik}
          errorMode="onFieldChange"
          label={t('employees.can_rehire')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCheckbox
          id={'wotc'}
          formik={formik}
          errorMode="onFieldChange"
          label={t('employees.wotc')}
        />
      </FormFieldContainer>
    </RestoreEntityFormModal>
  );
};
