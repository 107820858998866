import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateNgroupBillingDetails,
  IdsArray,
  IFilter,
  INgroupBillingDetailsModel,
  IUpdateNgroupBillingDetails,
  PayloadWithNavigateFunc,
} from '../types';

// Flush actions
export const flushNgroupBillingDetailsState = createAction(
  'Flush NgroupBillingDetails data in store',
);

// Creation actions
export const createNgroupBillingDetailsRequest = createAction<
  PayloadWithNavigateFunc<ICreateNgroupBillingDetails>
>('Make request to create a new NgroupBillingDetails');
export const createNgroupBillingDetailsSuccess = createAction<
  INgroupBillingDetailsModel
>('New NgroupBillingDetails has been created successfully');
export const createNgroupBillingDetailsFailed = createAction(
  'Cannot create a new NgroupBillingDetails',
);
// Bulk Creation actions
export const createBulkNgroupBillingDetailsRequest = createAction<
  PayloadWithNavigateFunc<ICreateNgroupBillingDetails[]>
>('Make request to create a new NgroupBillingsDetails');
export const createBulkNgroupBillingDetailsSuccess = createAction<
  INgroupBillingDetailsModel
>('New NgroupBillingsDetails has been created successfully');
export const createBulkNgroupBillingDetailsFailed = createAction(
  'Cannot create a new NgroupBillingsDetails',
);

// Fetch NgroupBillingDetails actions
export const getNgroupBillingDetailsListRequest = createAction<IFilter>(
  'Make request to fetch NgroupBillingDetails list',
);
export const getNgroupBillingDetailsListSuccess = createAction<
  INgroupBillingDetailsModel[]
>('NgroupBillingDetails list has been fetched successfully');
export const getNgroupBillingDetailsListFailed = createAction(
  'Cannot fetch NgroupBillingDetails list',
);

// get NgroupBillingDetails count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getNgroupBillingDetailsCountRequest = createAction<IFilter>(
  'Make request to get NgroupBillingDetails count',
);
export const getNgroupBillingDetailsCountSuccess = createAction<ICountResponse>(
  'NgroupBillingDetails count has been fetched successfully',
);
export const getNgroupBillingDetailsCountFailed = createAction(
  'Cannot get NgroupBillingDetails count',
);

// delete actions
export const deleteNgroupBillingDetailsRequest = createAction<IdsArray>(
  'Make request to delete NgroupBillingDetails',
);
export const deleteNgroupBillingDetailsFailed = createAction(
  'Cannot delete NgroupBillingDetails',
);

// Updating actions
export const updateNgroupBillingDetailsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateNgroupBillingDetails[]>
>('Make request to update NgroupBillingDetails');
export const updateNgroupBillingDetailsFailed = createAction(
  'Cannot update NgroupBillingDetails',
);
