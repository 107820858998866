/**
 * ExportReducerCreator will be used for generating reducers stuff for export
 */
export class ExportReducerCreator {
  /**
   * Generates export handlers
   */
  takeHandlers<State>() {
    const exportRequest = (state: State) => ({
      ...state,
      is_data_requested: true,
    });
    const exportSuccess = (state: State) => ({
      ...state,
      is_data_requested: false,
    });

    const exportFailed = (state: State) => ({
      ...state,
      is_data_requested: false,
    });

    return {
      exportRequest,
      exportSuccess,
      exportFailed,
    };
  }
}
