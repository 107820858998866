import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IWhere,
  IExportParams,
  ListWithSummary,
  ITurnoverAddedModel,
  ITurnoverLostModel,
  ITurnoverActiveModel,
  ITurnoverTerminatedModel,
} from '../types';

// Fetch turnover added list actions
export const getTurnoverAddedListRequest = createAction<IFilter>(
  'Make request to fetch turnover added list',
);
export const getTurnoverAddedListSuccess = createAction<
  ListWithSummary<ITurnoverAddedModel>
>('Turnover added list has been fetched successfully');
export const getTurnoverAddedListFailed = createAction(
  'Cannot fetch turnover added list',
);

// get turnover added list count actions
export const getTurnoverAddedCountRequest = createAction<IWhere>(
  'Make request to get turnover added list count',
);
export const getTurnoverAddedCountSuccess = createAction<ICountResponse>(
  'Turnover added list count has been fetched successfully',
);
export const getTurnoverAddedCountFailed = createAction(
  'Cannot get turnover added list count',
);

// Fetch turnover lost list actions
export const getTurnoverLostListRequest = createAction<IFilter>(
  'Make request to fetch turnover lost list',
);
export const getTurnoverLostListSuccess = createAction<
  ListWithSummary<ITurnoverLostModel>
>('Turnover lost list has been fetched successfully');
export const getTurnoverLostListFailed = createAction(
  'Cannot fetch turnover lost list',
);

// get turnover lost list count actions
export const getTurnoverLostCountRequest = createAction<IWhere>(
  'Make request to get turnover lost list count',
);
export const getTurnoverLostCountSuccess = createAction<ICountResponse>(
  'Turnover lost list count has been fetched successfully',
);
export const getTurnoverLostCountFailed = createAction(
  'Cannot get turnover lost list count',
);

// Fetch turnover active list actions
export const getTurnoverActiveListRequest = createAction<IFilter>(
  'Make request to fetch turnover active list',
);
export const getTurnoverActiveListSuccess = createAction<
  ListWithSummary<ITurnoverActiveModel>
>('Turnover active list has been fetched successfully');
export const getTurnoverActiveListFailed = createAction(
  'Cannot fetch turnover active list',
);

// get turnover active list count actions
export const getTurnoverActiveCountRequest = createAction<IWhere>(
  'Make request to get turnover active list count',
);
export const getTurnoverActiveCountSuccess = createAction<ICountResponse>(
  'Turnover active list count has been fetched successfully',
);
export const getTurnoverActiveCountFailed = createAction(
  'Cannot get turnover active list count',
);

// Fetch turnover terminated list actions
export const getTurnoverTerminatedListRequest = createAction<IFilter>(
  'Make request to fetch turnover terminated list',
);
export const getTurnoverTerminatedListSuccess = createAction<
  ListWithSummary<ITurnoverTerminatedModel>
>('Turnover terminated list has been fetched successfully');
export const getTurnoverTerminatedListFailed = createAction(
  'Cannot fetch turnover terminated list',
);

// get turnover terminated list count actions
export const getTurnoverTerminatedCountRequest = createAction<IWhere>(
  'Make request to get turnover terminated list count',
);
export const getTurnoverTerminatedCountSuccess = createAction<ICountResponse>(
  'Turnover terminated list count has been fetched successfully',
);
export const getTurnoverTerminatedCountFailed = createAction(
  'Cannot get turnover terminated list count',
);

// export actions
export const exportTurnoverAddedRequest = createAction<IExportParams>(
  'TurnoverAdded export request',
);
export const exportTurnoverLostRequest = createAction<IExportParams>(
  'TurnoverLost export request',
);
export const exportTurnoverActiveRequest = createAction<IExportParams>(
  'TurnoverActive export request',
);
export const exportTurnoverTerminatedRequest = createAction<IExportParams>(
  'TurnoverTerminated export request',
);

export const exportTurnoverSuccess = createAction('Turnover export success');
export const exportTurnoverFailed = createAction('Turnover export fail');
