import React from 'react';
import { Box, useTheme } from '@mui/joy';

interface IChartsDashboardChartsContainer {
  children: React.ReactNode;
  disableGutters?: boolean;
}

export const ChartsDashboardChartsContainer = ({
  children,
  disableGutters,
}: React.PropsWithChildren<IChartsDashboardChartsContainer>) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        height: '100%',
        ...(!disableGutters && {
          '&:first-of-type': {
            mr: 0.5,
          },
          '&:last-of-type': {
            ml: 0.5,
          },
        }),
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      }}
    >
      {children}
    </Box>
  );
};
