import React from 'react';
import { IApplicantModel } from '../../../modules/types';
import { useTranslation } from 'react-i18next';
import AccordionView, {
  AccordionViewHeader,
} from '../../../components/AccordionView';
import { NoIcon, YesIcon } from '../../../components/Icons';

interface IProps {
  applicant: IApplicantModel;
}

const ApplicantsView = (props: IProps) => {
  const { applicant } = props;

  const { t } = useTranslation();

  const headers: AccordionViewHeader[] = [
    {
      id: 'id',
      label: t('applicant.id'),
    },
    {
      id: 'firstName',
      label: t('applicant.firstName'),
    },
    {
      id: 'lastName',
      label: t('applicant.lastName'),
    },
    {
      id: 'openPosition.id',
      label: t('applicant.openPositionId'),
      renderValue: (model) => model.openPosition.position.name,
    },
    {
      id: 'payRate',
      label: t('applicant.payRate'),
    },
    {
      id: 'dateInterview',
      label: t('applicant.dateInterview'),
    },
    {
      id: 'hire',
      label: t('applicant.hire'),
      renderValue: (model) =>
        model.hire ? <YesIcon color="primary" /> : <NoIcon color="primary" />,
    },
    {
      id: 'dateStart',
      label: t('applicant.dateStart'),
    },
    {
      id: 'badge',
      label: t('applicant.badge'),
    },
    {
      id: 'supervisor.id',
      label: t('applicant.supervisorId'),
      renderValue: (model) =>
        `${model.supervisor.firstName} ${model.supervisor.lastName}`,
    },
    {
      id: 'site.name',
      label: t('applicant.siteId'),
    },
    {
      id: 'I9',
      label: t('applicant.I9'),
      renderValue: (model) =>
        model.I9 ? <YesIcon color="primary" /> : <NoIcon color="primary" />,
    },
    {
      id: 'bgCheck',
      label: t('applicant.bgCheck'),
      renderValue: (model) =>
        model.bgCheck ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
    },
    {
      id: 'site.interviewNotes',
      label: t('applicant.interviewNotes'),
    },
    {
      id: 'site.phonenumber',
      label: t('applicant.phonenumber'),
    },
    {
      id: 'site.address',
      label: t('applicant.address'),
    },
    {
      id: 'staffing.staffingProvider',
      label: t('applicant.staffingId'),
    },
  ];

  return (
    <AccordionView
      title={t('applicant.details_title', { id: applicant.id })}
      model={applicant}
      headers={headers}
    />
  );
};

export default ApplicantsView;
