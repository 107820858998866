import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  CostPlusTypes,
  ICountResponse,
  ICostPlusSettingsModel,
  ICostPlusSettingsState,
} from '../types';

// default state
const defaultState: ICostPlusSettingsState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  isActive: false,
  type: 'department',
  description: '',
  list: [],
  costPlusTypes: [],
  count: 0,
  is_cost_plus_settings_list_loading: false,
  is_cost_plus_settings_count_loading: false,
};

/**
 * Flush actions
 */
const flushCostPlusSettingsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createCostPlusSettingsRequest = (state: ICostPlusSettingsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createCostPlusSettingsSuccess = (
  state: ICostPlusSettingsState,
  payload: ICostPlusSettingsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createCostPlusSettingsFailed = (
  state: ICostPlusSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getCostPlusSettingsListRequest = (state: ICostPlusSettingsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_cost_plus_settings_list_loading: true,
});

const getCostPlusSettingsListSuccess = (
  state: ICostPlusSettingsState,
  payload: ICostPlusSettingsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
  is_cost_plus_settings_list_loading: false,
});

const getCostPlusSettingsListFailed = (
  state: ICostPlusSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  is_cost_plus_settings_list_loading: false,
});

/**
 * Get count actions
 */
const getCostPlusSettingsCountRequest = (state: ICostPlusSettingsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_cost_plus_settings_count_loading: true,
});

const getCostPlusSettingsCountSuccess = (
  state: ICostPlusSettingsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  is_cost_plus_settings_count_loading: false,
});

const getCostPlusSettingsCountFailed = (
  state: ICostPlusSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  is_cost_plus_settings_count_loading: false,
});

/**
 * Deletion actions
 */
const deleteCostPlusSettingsRequest = (state: ICostPlusSettingsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteCostPlusSettingsFailed = (
  state: ICostPlusSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateCostPlusSettingsRequest = (state: ICostPlusSettingsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateCostPlusSettingsFailed = (
  state: ICostPlusSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get Types actions
 */
const getCostPlusSettingsTypesRequest = (state: ICostPlusSettingsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getCostPlusSettingsTypesSuccess = (
  state: ICostPlusSettingsState,
  payload: CostPlusTypes,
) => ({
  ...state,
  costPlusTypes: payload,
  error: false,
  is_data_requested: false,
});

const getCostPlusSettingsTypesFailed = (
  state: ICostPlusSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * CostPlusSettings reducer
 */
export const costPlusSettings = createReducer<ICostPlusSettingsState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushCostPlusSettingsState, flushCostPlusSettingsState)
  // creation actions
  .on(actions.createCostPlusSettingsRequest, createCostPlusSettingsRequest)
  .on(actions.createCostPlusSettingsSuccess, createCostPlusSettingsSuccess)
  .on(actions.createCostPlusSettingsFailed, createCostPlusSettingsFailed)
  // get list actions
  .on(actions.getCostPlusSettingsListRequest, getCostPlusSettingsListRequest)
  .on(actions.getCostPlusSettingsListSuccess, getCostPlusSettingsListSuccess)
  .on(actions.getCostPlusSettingsListFailed, getCostPlusSettingsListFailed)
  // get count actions
  .on(actions.getCostPlusSettingsCountRequest, getCostPlusSettingsCountRequest)
  .on(actions.getCostPlusSettingsCountSuccess, getCostPlusSettingsCountSuccess)
  .on(actions.getCostPlusSettingsCountFailed, getCostPlusSettingsCountFailed)
  // deletion actions
  .on(actions.deleteCostPlusSettingsRequest, deleteCostPlusSettingsRequest)
  .on(actions.deleteCostPlusSettingsFailed, deleteCostPlusSettingsFailed)
  // updating actions
  .on(actions.updateCostPlusSettingsRequest, updateCostPlusSettingsRequest)
  .on(actions.updateCostPlusSettingsFailed, updateCostPlusSettingsFailed)
  // get Types actions
  .on(actions.getCostPlusSettingsTypesRequest, getCostPlusSettingsTypesRequest)
  .on(actions.getCostPlusSettingsTypesSuccess, getCostPlusSettingsTypesSuccess)
  .on(actions.getCostPlusSettingsTypesFailed, getCostPlusSettingsTypesFailed);

export default costPlusSettings;
