import React from 'react';
import ReactPlayer from 'react-player';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Theme } from '@mui/material/styles';

interface IPlayerProps {
  url: string;
  title: string;
}

const WIDTH = '100%';
const HEIGHT = '100%';

export const Player: React.FC<IPlayerProps> = ({ url, title }) => {
  return (
    <Card elevation={2}>
      <ReactPlayer width={WIDTH} height={HEIGHT} controls url={url} />
      <CardContent
        sx={{ color: (theme: Theme) => theme.palette.text.secondary }}
      >
        {title}
      </CardContent>
    </Card>
  );
};
