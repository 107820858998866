import { ComboBoxOption } from 'src/components/ComboBox';

export enum DepartmentIncentive {
  none = 'none',
  costPlus = 'costPlus',
  indirect = 'indirect',
  variable = 'variable',
}

export const departmentIncentiveOptions = Object.values(
  DepartmentIncentive,
).map(
  (di) =>
    (({
      id: di,
      name: di,
    } as unknown) as ComboBoxOption),
);
