import { filter, reduce } from 'lodash';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addProcessStatus,
  approveLogtimesRequest,
  exportLogtimeUnapprovedRequest,
  getLogtimeUnapprovedCountRequest,
  getLogtimeUnapprovedListRequest,
  moveToMissedLogtimesRequest,
} from '../../../../../modules/actions';
import {
  getLogtimeRefreshKey,
  getLogtimeUnapprovedCount,
  getLogtimeUnapprovedTable,
  isLogtimesDataLoading,
} from '../../../../../modules/selectors/logtime';
import { IdsArray } from '../../../../../modules/types';
import { TimePunchesTable } from '../../TimePunchesTable';
import { CheckSquareSvg, MinusSquareSvg } from 'src/components/svgIcons';
import { useHasUserAccessToAction } from 'src/config';
import { ICustomAction } from 'src/components/EnhancedTable/EnhancedTableSelectedItemsActions';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { DeleteConfirmation } from 'src/components/DeleteConfirmation';

const HEAD_CELLS_HIDDEN_BY_DEFAULT = ['editUser', 'updatedAt'];

export const TimePunchesUnapprovedComponent = () => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<string>('');
  const dispatcher = useDispatch();

  const [
    movedToMissedConfirmationData,
    setMovedToMissedConfirmationData,
  ] = React.useState({ isOpened: false, ids: [] as IdsArray });

  const hasUserAccessToApproveTimePunch = useHasUserAccessToAction(
    manageEntitiesConfig.logtime.update.id,
  );

  const hasUserAccessToMoveToMissedPunch = useHasUserAccessToAction(
    manageEntitiesConfig.logtime.moved_to_missed.id,
  );

  // fetch logtime list
  const list = useSelector(getLogtimeUnapprovedTable, shallowEqual);

  const handleApprove = (ids: IdsArray) => {
    const init: {
      allowList: number[];
      denyList: number[];
    } = {
      allowList: [],
      denyList: [],
    };
    // filter list of logtimes entities by selected ids
    const filteredList = filter(list, (i) => ids.includes(i.id));
    // determine entities that don't have timeOut filled
    const prepared = reduce(
      filteredList,
      (acc, cur) => {
        if (cur.timeOut) {
          acc.allowList.push(cur.id);
        } else {
          acc.denyList.push(cur.id);
        }
        return acc;
      },
      init,
    );

    if (prepared.denyList.length) {
      const message = t('logtimes.alert_cant_approve', {
        id: prepared.denyList.join(', '),
      });
      setAlert(message);
    } else {
      // dispatch an action to approve logtimes
      dispatcher(approveLogtimesRequest(prepared.allowList));
    }
  };

  const handleMoveToMissed = (ids: IdsArray) => {
    dispatcher(moveToMissedLogtimesRequest(ids));
  };

  React.useEffect(() => {
    const precessKey = 'approvePunch';

    if (alert) {
      dispatcher(
        addProcessStatus({
          variant: 'error',
          title: t('time_keeping.approve_punch_failed'),
          message: alert,
          precessKey,
        }),
      );

      setTimeout(() => setAlert(''), 1000);
    } else {
    }
  }, [alert, dispatcher, t]);

  const onMoveToMissedConfirm = () => {
    handleMoveToMissed(movedToMissedConfirmationData.ids);
    setMovedToMissedConfirmationData({ isOpened: false, ids: [] });
  };
  const onMoveToMissedDecline = () => {
    setMovedToMissedConfirmationData({ isOpened: false, ids: [] });
  };

  const customActions: Array<ICustomAction> = [];
  if (hasUserAccessToApproveTimePunch) {
    customActions.push({
      title: t('common.approve'),
      icon: <CheckSquareSvg />,
      onClick: handleApprove,
    });
  }
  if (hasUserAccessToMoveToMissedPunch) {
    customActions.push({
      title: t('common.move_to_missed'),
      icon: <MinusSquareSvg />,
      onClick: (ids) =>
        setMovedToMissedConfirmationData({ isOpened: true, ids }),
    });
  }

  return (
    <>
      <TimePunchesTable
        listAction={getLogtimeUnapprovedListRequest}
        countAction={getLogtimeUnapprovedCountRequest}
        countSelector={getLogtimeUnapprovedCount}
        exportFunction={exportLogtimeUnapprovedRequest}
        tableSelector={getLogtimeUnapprovedTable}
        isTableDataLoadingSelector={isLogtimesDataLoading}
        tableName={t('logtimes.tabs.unapproved.title')}
        hiddenColumns={HEAD_CELLS_HIDDEN_BY_DEFAULT}
        customActions={customActions}
      />

      <DeleteConfirmation
        type="warning"
        confirmText={t('common.confirm')}
        open={movedToMissedConfirmationData.isOpened}
        onCancel={onMoveToMissedDecline}
        onOk={onMoveToMissedConfirm}
      >
        {t('logtimes.move_to_missed_warning')}
      </DeleteConfirmation>
    </>
  );
};

export const TimePunchesUnapproved = () => {
  return (
    <TimePunchesUnapprovedComponent key={useSelector(getLogtimeRefreshKey)} />
  );
};
