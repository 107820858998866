import { omit, reduce, map, memoize } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  IOpenPositionModel,
  IStoreState,
  IUpdateOpenPosition,
  OpenPositionComboboxOption,
} from '../types';
import {
  parse,
  format,
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
} from '../../modules/utils/dateWrapper';
import { filterListBySiteId } from '../utils/helpers/common';

export const getOpenPositionRefreshKey = ({ openPosition }: IStoreState) =>
  openPosition.refreshKey;

/**
 * Get openPosition list
 * @param openPosition - State openPosition
 */
export const getOpenPositionList = ({ openPosition }: IStoreState) =>
  openPosition.list;

/**
 * Get open position count
 * @param openPosition - State logtime
 */
export const getOpenPositionCount = ({ openPosition }: IStoreState) =>
  openPosition.count;

/**
 * Get open position view list
 */
export const getOpenPositionViewList = createSelector(
  getOpenPositionList,
  (openPositions) => (ids: IdsArray) =>
    reduce(
      openPositions,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push({
            ...cur,
            dateStart: composeDate(
              cur.dateStart,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
          });
        }
        return acc;
      },
      [] as IOpenPositionModel[],
    ),
);

/**
 * Get openPositions by array of ids
 * @param openPosition - State openPosition
 */
export const getOpenPositionsByIds = createSelector(
  getOpenPositionList,
  (openPositions) => (ids: IdsArray) =>
    reduce(
      openPositions,
      (acc, cur) => {
        if ((ids ?? []).includes(`${cur.id}`)) {
          acc.push({
            ...omit(cur, [
              'position',
              'shift',
              'site',
              'department',
              'openPositionSkills',
              'closed',
              'editUserId',
            ]),
            dateStart: composeDate(
              cur.dateStart,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
            skills: map(cur.openPositionSkills, 'skillId'),
          });
        }
        return acc;
      },
      [] as IUpdateOpenPosition[],
    ),
);

/**
 * Get combobox list
 */
export const getOpenPositionsComboboxList = ({ openPosition }: IStoreState) =>
  map(openPosition.comboboxList, (item) => ({
    id: item['id'],
    name: `${item.position?.name} - ${item.site?.name} - ${item.shift?.name} (${item.numberNeeded} needed)`,
    siteId: item.siteId,
    closed: item.closed,
  })) as OpenPositionComboboxOption[];

export const getOpenPositionsComboboxListWithNameAsId = ({
  openPosition,
}: IStoreState) =>
  map(openPosition.comboboxList, (item) => ({
    id: item['name'],
    name: `${item.position?.name} - ${item.site?.name} - ${item.shift?.name} (${item.numberNeeded} needed)`,
    siteId: item.siteId,
    closed: item.closed,
  }));

/**
 * Get open positions related to passed siteId
 * Or nothing if siteId is NaN
 */
export const getOpenPositionsComboboxListBySiteId = createSelector(
  getOpenPositionsComboboxList,
  (openPositions) =>
    memoize((siteId: number) => filterListBySiteId(openPositions, siteId)),
);
