import React, { useEffect } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useTranslation } from 'react-i18next';
import {
  useAccountingCalendarGeneratorForTarget,
  useFetchSitesCombobox,
  useMonthOptions,
  useValidate,
} from '../../../../../../modules/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { createMonthBasedInvoiceScheme } from '../../../../../../modules/schemes/invoice';
import { createMonthInvoiceRequest } from '../../../../../../modules/actions';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { getSitesComboboxList } from '../../../../../../modules/selectors/site';
import { getWeek } from 'date-fns';
import FormikCombobox from '../../../../../../components/Formik/FormikCombobox';
import { get } from 'lodash';
import { clientKeysWithMonthInvoiceEnabled } from '../../../../../../modules/constants';
import {
  IFilterData,
  IWhere,
  SiteComboboxOption,
} from '../../../../../../modules/types';

const initialValues = {
  siteId: NaN,
  yearNumber: NaN, // 2022
  monthNumber: NaN, // 0-11
};

interface ICreateInvoice {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}
export const CreateMonthlyInvoice = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateInvoice) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const validate = useValidate(createMonthBasedInvoiceScheme);
  const generateCalendar = useAccountingCalendarGeneratorForTarget();
  const monthOptions = useMonthOptions();

  const fetchSitesCombobox = useFetchSitesCombobox();
  useEffect(() => {
    fetchSitesCombobox();
  }, [fetchSitesCombobox]);
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const sitesComboBoxOptions = sites
    .filter((site) =>
      clientKeysWithMonthInvoiceEnabled.includes(site.client.clientKey),
    )
    .map(
      (site) =>
        (({
          id: site.id,
          name: `${site.name} (${get(site, 'client.name')})`,
        } as unknown) as SiteComboboxOption),
    );

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: ({ siteId, monthNumber, yearNumber }) => {
      // To have proper weeks number we are using calendar
      // that has configuration the same as in Accounting calendar
      // but in UI user will use regular Gregorian calendar, so
      // we need to match data from UI (Gregorian calendar) to Accounting calendar
      let yearNumberForCalendar = yearNumber;
      let monthNumberForCalendar = monthNumber - 1;

      const isFirstMonthOfAYearSelected = monthNumber === 0;
      if (isFirstMonthOfAYearSelected) {
        yearNumberForCalendar = yearNumberForCalendar - 1;
        monthNumberForCalendar = 11;
      }

      const calendar = generateCalendar(yearNumberForCalendar);

      const weeksNumber = calendar.months[
        monthNumberForCalendar
      ].weeks.map((week) => getWeek(week.gregorianStartDate));

      dispatcher(
        createMonthInvoiceRequest({
          data: {
            siteId,
            weeksNumber,
            monthNumber,
            yearNumber,
          },
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('invoice.create_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikCombobox
              id="siteId"
              placeholder={t('employees.site')}
              formik={formik}
              options={sitesComboBoxOptions}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              fullWidth
              type="number"
              id="yearNumber"
              name="yearNumber"
              formik={formik}
              variant="outlined"
              label={t('invoice.year_number')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              required
              formik={formik}
              id="monthNumber"
              placeholder={t('common.month')}
              options={monthOptions}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
