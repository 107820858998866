import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IProjectionVsActualTotalsMonthlyResponseItem,
  IProjectionVsActualTotalsWeeklyResponseItem,
  IProjectionVsActualTotalsYearlyResponseItem,
} from 'src/modules/types/projection-vs-actual-dashboard';
import {
  financialColor,
  projectionColor,
} from 'src/pages/ExecutiveSummary/panels/FinancialDashboard/FinancialDashboardTotalsChart/constants';

export const useTotalsChartCategoryToValueKeyMapper = (): {
  [key: string]: {
    financial: keyof IProjectionVsActualTotalsWeeklyResponseItem;
    projection: keyof IProjectionVsActualTotalsWeeklyResponseItem;
  };
} => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return {
      [`${t('common.gross_revenue')}, $`]: {
        financial: 'revenue',
        projection: 'projectionRevenue',
      },
      [`${t('common.gross_margin')}, $`]: {
        financial: 'grossMargin',
        projection: 'projectionGrossMargin',
      },
      [`${t('common.gross_margin')}, %`]: {
        financial: 'grossMarginPercentage',
        projection: 'projectionGrossMarginPercentage',
      },
    };
  }, [t]);
};

export const useTotalsChartCategories = () => {
  const totalsChartCategoryToValueKeyMapper = useTotalsChartCategoryToValueKeyMapper();

  return React.useMemo(() => {
    return Object.keys(totalsChartCategoryToValueKeyMapper);
  }, [totalsChartCategoryToValueKeyMapper]);
};

export const useGeneratorsForTotalsChartSeries = () => {
  const totalsChartCategoryToValueKeyMapper = useTotalsChartCategoryToValueKeyMapper();

  const generateWeeklySeries = React.useCallback(
    (weeklyData: IProjectionVsActualTotalsWeeklyResponseItem) => {
      const valueKeys = Object.values(totalsChartCategoryToValueKeyMapper);
      return [
        {
          name: 'Financial $',
          type: 'column' as const,
          color: financialColor,
          data: valueKeys.map((key) =>
            key.financial !== 'grossMarginPercentage'
              ? weeklyData[key.financial]
              : 0,
          ),
        },
        {
          name: 'Projection $',
          type: 'column' as const,
          color: projectionColor,
          data: valueKeys.map((key) =>
            key.projection !== 'projectionGrossMarginPercentage'
              ? weeklyData[key.projection]
              : 0,
          ),
        },
        {
          name: 'Financial %',
          type: 'column' as const,
          color: financialColor,
          yAxis: 1,
          data: valueKeys.map((key) =>
            key.financial === 'grossMarginPercentage'
              ? weeklyData[key.financial]
              : 0,
          ),
        },
        {
          name: 'Projection %',
          type: 'column' as const,
          color: projectionColor,
          yAxis: 1,
          data: valueKeys.map((key) =>
            key.projection === 'projectionGrossMarginPercentage'
              ? weeklyData[key.projection]
              : 0,
          ),
        },
      ];
    },
    [totalsChartCategoryToValueKeyMapper],
  );

  const generateMonthlySeries = React.useCallback(
    (weeklyData: IProjectionVsActualTotalsMonthlyResponseItem) => {
      const valueKeys = Object.values(totalsChartCategoryToValueKeyMapper);
      return [
        {
          name: 'Financial $',
          type: 'column' as const,
          color: financialColor,
          data: valueKeys.map((key) =>
            key.financial !== 'grossMarginPercentage'
              ? weeklyData[key.financial]
              : 0,
          ),
        },
        {
          name: 'Projection $',
          type: 'column' as const,
          color: projectionColor,
          data: valueKeys.map((key) =>
            key.projection !== 'projectionGrossMarginPercentage'
              ? weeklyData[key.projection]
              : 0,
          ),
        },
        {
          name: 'Financial %',
          type: 'column' as const,
          color: financialColor,
          yAxis: 1,
          data: valueKeys.map((key) =>
            key.financial === 'grossMarginPercentage'
              ? weeklyData[key.financial]
              : 0,
          ),
        },
        {
          name: 'Projection %',
          type: 'column' as const,
          color: projectionColor,
          yAxis: 1,
          data: valueKeys.map((key) =>
            key.projection === 'projectionGrossMarginPercentage'
              ? weeklyData[key.projection]
              : 0,
          ),
        },
      ];
    },
    [totalsChartCategoryToValueKeyMapper],
  );
  const generateYearlySeries = React.useCallback(
    (weeklyData: IProjectionVsActualTotalsYearlyResponseItem) => {
      const valueKeys = Object.values(totalsChartCategoryToValueKeyMapper);
      return [
        {
          name: 'Financial $',
          type: 'column' as const,
          color: financialColor,
          data: valueKeys.map((key) =>
            key.financial !== 'grossMarginPercentage'
              ? weeklyData[key.financial]
              : 0,
          ),
        },
        {
          name: 'Projection $',
          type: 'column' as const,
          color: projectionColor,
          data: valueKeys.map((key) =>
            key.projection !== 'projectionGrossMarginPercentage'
              ? weeklyData[key.projection]
              : 0,
          ),
        },
        {
          name: 'Financial %',
          type: 'column' as const,
          color: financialColor,
          yAxis: 1,
          data: valueKeys.map((key) =>
            key.financial === 'grossMarginPercentage'
              ? weeklyData[key.financial]
              : 0,
          ),
        },
        {
          name: 'Projection %',
          type: 'column' as const,
          color: projectionColor,
          yAxis: 1,
          data: valueKeys.map((key) =>
            key.projection === 'projectionGrossMarginPercentage'
              ? weeklyData[key.projection]
              : 0,
          ),
        },
      ];
    },
    [totalsChartCategoryToValueKeyMapper],
  );

  return React.useMemo(
    () => ({
      generateWeeklySeries,
      generateMonthlySeries,
      generateYearlySeries,
    }),
    [generateMonthlySeries, generateWeeklySeries, generateYearlySeries],
  );
};
