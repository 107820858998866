import React from 'react';

import { ReportTable } from 'src/components/ReportPage/ReportTable';
import {
  useWeeklyHoursDashboard,
  useWeeklyHoursDashboardComposeWhere,
} from 'src/modules/utils/hooks/weekly-hours-dashboard.hooks';

const reportUrl = '/payboard/weekly-hours-summary-dashboard-report';

interface IWeeklyHoursDashboardTableProps {
  siteId?: number;
  department?: string;
  yearWeek?: { from: number; to: number };
}

export const WeeklyHoursDashboardTable: React.FC<IWeeklyHoursDashboardTableProps> = ({
  siteId,
  department,
  yearWeek,
}) => {
  const where = useWeeklyHoursDashboardComposeWhere({
    siteId,
    yearWeek,
    department,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useWeeklyHoursDashboard(reportUrl, where);

  return (
    <ReportTable
      sx={{ minHeight: '200px' }}
      data={data.items as any}
      summaryData={data.summary}
      count={countData.count}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey={reportUrl}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
