import React from 'react';
import { IFilter, IFilterData } from '../../types';
import {
  PAGE_DEFAULT,
  ROWS_PER_PAGE_ALL,
  ROWS_PER_PAGE_DEFAULT,
} from '../../../components/EnhancedTable';
import { isEmpty, omit } from 'lodash';
import { useQueryParams } from './useQueryParams';

export const getParsedTableFilterData = (filterData: IFilterData) => {
  const limit =
    filterData.limit === ROWS_PER_PAGE_ALL
      ? undefined
      : filterData.limit ?? ROWS_PER_PAGE_DEFAULT;

  return {
    ...filterData,
    limit,
    offset: filterData.offset ?? PAGE_DEFAULT,
  };
};

export const applyDefaultFilters = (filterData: IFilterData) => {
  const parsedFilterData = getParsedTableFilterData(filterData);

  return {
    filter: {
      ...parsedFilterData,
    },
  };
};

/**
 * A custom hook to compose default filters on page load
 */
export const useFilter = (filterData?: IFilterData) => {
  const filterInUrl: IFilter = useQueryParams();

  return React.useMemo(() => {
    if (!isEmpty(filterInUrl)) {
      const restFilterInUrl = omit(filterInUrl.filter, ['limit', 'offset']);

      return {
        filterList: filterInUrl,
        filterCount: { filter: restFilterInUrl },
      };
    }
    const filter = applyDefaultFilters(filterData || {});
    const restFilter = omit(filter.filter, ['limit', 'offset']);

    return {
      filterList: filter,
      filterCount: { filter: restFilter },
    };
  }, [filterData, filterInUrl]);
};
