import React from 'react';
import { roles, weekStartDayTranslations } from '../../config';
import { createSelector } from 'reselect';
import { i18n, useSiteComboboxFilter } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteComboboxListRequest } from '../actions';
import { ComboBoxOption } from '../../components/ComboBox';
import { filter, isEmpty, map, omit, reduce, get, find } from 'lodash';
import {
  IdsArray,
  IFilterData,
  IStoreState,
  IUpdateSite,
  SiteComboboxOption,
} from '../types';
import { ClientKey } from '../constants';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const getSiteRefreshKey = (state: IStoreState) => state.site.refreshKey;

export const getSiteData = (state: IStoreState) => state.site;

/**
 * Get server error
 * @param site - State site
 */
export const getServerError = ({ site }: IStoreState) => site.error;

/**
 * Get site list
 * @param site - State site
 */
export const getSiteList = ({ site }: IStoreState) => site.list;

/**
 * Get site ids for the current user
 */
export const getSiteIds = (store: IStoreState) => {
  // for GM we it does not matter what id we get, since this logic is handled on server-side
  // but we should respond with at least one stub id because it's required parameter for most functions
  const currentRole = store.auth.role;

  if (currentRole === roles.GM) {
    return [1];
  } else if ([roles.SUPER_ADMIN, roles.ADMIN].includes(currentRole as string)) {
    return map(store.site.list, (s) => s.id);
  } else {
    return [];
  }
};

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param site - State site
 */
export const getSiteTable = createSelector(getSiteList, (site) =>
  map(filter(site, (s) => !isEmpty(s.client))),
);

export const getSiteTableWithParsedRows = createSelector(
  getSiteTable,
  (sites) => {
    return sites.map((item) => {
      return {
        ...item,
        lunchAdj: item.lunchAdj ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        financialAutomated: item.financialAutomated ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        pricingType: item.pricingType.replace(/^./, (firstLetter) =>
          firstLetter.toUpperCase(),
        ),
        startWeekDay: weekStartDayTranslations[item.startWeekDay],
        paymentTerms: i18n.t('sites.paymentTerms', {
          paymentTerms: item.paymentTerms,
        }),
        baselineType:
          item.baselineType === 'cpu_based' ? 'CPU based' : 'Area Based',
      };
    });
  },
);

/**
 * Get sites by array of ids
 * @param site - State site
 */
export const getSitesByIds = createSelector(
  getSiteList,
  (sites) => (ids: IdsArray) => {
    const initial: IUpdateSite[] = [];
    return reduce(
      sites,
      (acc, cur) => {
        if (ids?.includes(`${cur.id}`) || ids?.includes(cur.id)) {
          acc.push({
            ...omit(cur, ['client', 'uuid']),
            lunchAdj: !!cur.lunchAdj,
            archived: !!cur.archived,
            financialAutomated: !!cur.financialAutomated,
          });
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get site count
 * @param site - State site
 */
export const getSiteCount = ({ site }: IStoreState) => site.count;

/**
 * Get combobox list
 */
export const getSitesComboboxList = createSelector(
  getSiteData,
  (site) => site.comboboxList as SiteComboboxOption[],
);

/**
 * Get combobox list without test sites
 */
export const getNonTestSitesComboboxList = createSelector(
  getSiteData,
  (site) =>
    site.comboboxList.filter((site) =>
      /test/.test(site.name!),
    ) as SiteComboboxOption[],
);

export const getSitesWithIncentiveSettingsComboboxList = createSelector(
  getSiteData,
  (site) =>
    site.comboboxList.filter(
      (site) =>
        !isEmpty(site.incentiveSettings) &&
        !!get(site, ['incentiveSettings', 0, 'id']),
    ) as SiteComboboxOption[],
);

export const getSitesComboboxListWithClient = createSelector(
  getSitesComboboxList,
  (sites): ComboBoxOption[] =>
    map(sites, (siteItem) => ({
      id: siteItem.id as number,
      name: `${siteItem.name} (${get(siteItem, 'client.name')})`,
    })),
);

export const getSitesComboboxListWithClientAndUUIDAsId = createSelector(
  getSitesComboboxList,
  (sites): ComboBoxOption[] =>
    map(sites, (siteItem) => ({
      id: siteItem.uuid as number,
      name: `${siteItem.name} (${get(siteItem, 'client.name')})`,
    })),
);

export const ifSiteHasLunch = createSelector(
  getSiteList,
  (list) => (siteId: number) => {
    const site = find(list, (i) => i.id === siteId);
    return !!site?.lunchAdj ?? false;
  },
);
export const getSitesForGlobalDropdown = createSelector(getSiteData, (site) => {
  return site.globalDropdownList;
});

export const allSiteOptionIndex = -1;
export const getSitesForGlobalDropdownOptions = createSelector(
  getSitesForGlobalDropdown,
  (sitesGlobalDropdownList) => {
    return sitesGlobalDropdownList as (ComboBoxOption & {
      client?: { name: string; id: number };
    })[];
  },
);

export const getSiteIdsOfSitesGlobalDropdown = createSelector(
  getSitesForGlobalDropdown,
  (sitesForGlobalDropdown) => {
    return sitesForGlobalDropdown.map(({ id }) => id);
  },
);

export const getGloballySelectedSites = createSelector(
  getSiteData,
  (site) => site.globallySelected,
);

export const isSiteByClientBySelected = createSelector(
  getSitesForGlobalDropdown,
  getGloballySelectedSites,
  (sitesGlobalDropdownList, selected: number[]) => (clientKey: string) =>
    !isEmpty(
      filter(
        sitesGlobalDropdownList,
        (site) =>
          selected.length === 1 &&
          site.client.clientKey === clientKey &&
          selected.includes(site.id),
      ),
    ),
);

export const isSiteByClient = createSelector(
  getSitesForGlobalDropdown,
  (sitesGlobalDropdownList) => (clientKey: string, siteId?: number) =>
    !isEmpty(
      filter(
        sitesGlobalDropdownList,
        (site) => site.id === siteId && site.client.clientKey === clientKey,
      ),
    ),
);

export const getSitesListFilteredByGloballySelectedSites = createSelector(
  getSiteList,
  getGloballySelectedSites,
  (sites, globallySelectedSites) => {
    return sites.filter((site) => globallySelectedSites.includes(site.id));
  },
);

export const getIsGloballySelectedSitesInitialized = createSelector(
  getSiteData,
  (site) => site.isGloballySelectedSitesInitialized,
);

interface IUseSitesComboBoxOptionsArgs {
  requestFilter?: IFilterData;
  propertyAsId?: 'id' | 'uuid';
}

export const useSitesComboBoxOptions = ({
  requestFilter,
  propertyAsId = 'id',
}: IUseSitesComboBoxOptionsArgs) => {
  const dispatch = useDispatch();
  const defaultFilter = useSiteComboboxFilter();

  const sitesComboboxOptions = useSelector(
    propertyAsId === 'id'
      ? getSitesComboboxListWithClient
      : getSitesComboboxListWithClientAndUUIDAsId,
  );

  React.useEffect(() => {
    const filter = requestFilter ? { filter: requestFilter } : defaultFilter;

    dispatch(getSiteComboboxListRequest(filter));
  }, [defaultFilter, dispatch, requestFilter]);
  return sitesComboboxOptions;
};

interface IUseSitesByClientComboBoxOptionsArgs {
  clientKey: ClientKey;
  requestFilter?: IFilterData;
  propertyAsId?: 'id' | 'uuid';
}

export const useSitesByClientComboBoxOptions = ({
  clientKey,
  requestFilter,
  propertyAsId = 'id',
}: IUseSitesByClientComboBoxOptionsArgs) => {
  const dispatch = useDispatch();
  const defaultFilter = useSiteComboboxFilter();

  const sitesComboboxList = useSelector(getSitesComboboxList);

  const sitesOptions = React.useMemo(() => {
    return sitesComboboxList.reduce<Array<{ id: number; name: string }>>(
      (options, site) => {
        if (site.client.clientKey === clientKey) {
          options.push({
            id: (propertyAsId === 'id' ? site.id : site.uuid) as number,
            name: `${site.name} (${get(site, 'client.name')})`,
          });
        }

        return options;
      },
      [],
    );
  }, [clientKey, propertyAsId, sitesComboboxList]);

  React.useEffect(() => {
    const filter = requestFilter ? { filter: requestFilter } : defaultFilter;

    dispatch(getSiteComboboxListRequest(filter));
  }, [defaultFilter, dispatch, requestFilter]);
  return sitesOptions;
};

export const getIsSiteDataLoading = createSelector(
  getSiteData,
  (site) => site.isSitesDataLoading || site.isSiteDataProcessing,
);
