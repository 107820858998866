import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getSiteImportingSettingsClientKeysRequest,
  getSiteImportingSettingsListRequest,
} from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import {
  getClientKeys,
  getSiteImportingSettingsByIds,
} from '../../selectors/site-importing-settings.selector';

/**
 * A custom hook to fetch siteImportingSettings from store if they exist otherwise to make a request to fetch needed siteImportingSettings from
 * the server
 */
export const useFetchSiteImportingSettingsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch siteImportingSettings list from store
  const siteImportingSettings = useSelector(
    (state) => getSiteImportingSettingsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(siteImportingSettings)) {
      dispatcher(
        getSiteImportingSettingsListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch siteImportingSettings from store if they exist otherwise to make a request to fetch needed siteImportingSettings from
 * the server
 */
export const useFetchClientKeys = () => {
  const dispatcher = useDispatch();
  // fetch clientKeys list from store
  const clientKeys = useSelector(getClientKeys, shallowEqual);
  return () => {
    if (isEmpty(clientKeys)) {
      dispatcher(getSiteImportingSettingsClientKeysRequest());
    }
  };
};

export const useSiteImportingSettingsDefaultFilters = () => {
  return {
    order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
    include: [
      {
        relation: 'site',
      },
    ],
  };
};
