import React, { useContext } from 'react';
import { MultiRowFormContext } from '../MultiRowFormContext';
import { Box, BoxProps, Theme } from '@mui/material';

type IMultiRowFormRowProps = BoxProps;

export const MultiRowFormRow: React.FC<IMultiRowFormRowProps> = ({
  children,
  className,
  ...boxProps
}) => {
  const { headerLabels, columnDirectionMaxWidth } = useContext(
    MultiRowFormContext,
  );

  return (
    <Box
      sx={(theme: Theme) => ({
        display: 'grid',
        gridTemplateColumns: `repeat(${headerLabels.length}, 1fr) min-content`,

        border: '1px solid rgb(230, 230, 230)',
        borderRadius: '4px',
        padding: 0.5,

        [theme.breakpoints.down(Number(columnDirectionMaxWidth))]: {
          gridTemplateColumns: 'unset',
          gridTemplateRows: `repeat(${headerLabels.length}, 1fr) min-content`,
          padding: 1,
        },
      })}
      className={className}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
