import { SagaIterator } from 'redux-saga';

import { call, put, select } from 'redux-saga/effects';

import { Api } from '../utils';
import * as actions from '../actions';
import { mergeFilters } from '../utils/helpers/filter';
import { getComboboxSitesImportingList } from '../selectors/site-importing-settings.selector';

import {
  ICountResponse,
  ICreateSiteImportingSettings,
  IdsArray,
  IFilter,
  ISiteImportingSettingsModel,
  ISagaAction,
  ISagaActionBind,
  ClientKeys,
  PayloadWithFilters,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createSiteImportingSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<
      PayloadWithFilters<ICreateSiteImportingSettings>
    >): SagaIterator {
      try {
        const response: ISiteImportingSettingsModel = yield call(
          Api.SiteImportingSettings.create,
          data,
        );
        yield put(actions.createSiteImportingSettingsSuccess(response));

        yield put(
          actions.getSiteImportingSettingsListRequest({ filter: filters.list }),
        );
        yield put(
          actions.getSiteImportingSettingsCountRequest({
            filter: filters.count,
          }),
        );

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
      } catch (e) {
        yield put(actions.createSiteImportingSettingsFailed());
      }
    },
  },
  {
    action: actions.getSiteImportingSettingsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ISiteImportingSettingsModel[] = yield call(
          Api.SiteImportingSettings.list,
          action.payload,
        );
        yield put(actions.getSiteImportingSettingsListSuccess(response));
      } catch (e) {
        yield put(actions.getSiteImportingSettingsListFailed());
      }
    },
  },
  {
    action: actions.getSiteImportingSettingsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.SiteImportingSettings.count,
          action?.payload,
        );
        yield put(actions.getSiteImportingSettingsCountSuccess(response));
      } catch (e) {
        yield put(actions.getSiteImportingSettingsCountFailed());
      }
    },
  },
  {
    action: actions.deleteSiteImportingSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
      try {
        yield call(Api.SiteImportingSettings.delete, {
          where: { id: { inq: data } },
        });

        yield put(
          actions.getSiteImportingSettingsListRequest({ filter: filters.list }),
        );
        yield put(
          actions.getSiteImportingSettingsCountRequest({
            filter: filters.count,
          }),
        );

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        yield put(actions.deleteSiteImportingSettingsSuccess());
      } catch (e) {
        yield put(actions.deleteSiteImportingSettingsFailed());
      }
    },
  },
  {
    action: actions.updateSiteImportingSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<
      PayloadWithFilters<ISiteImportingSettingsModel[]>
    >): SagaIterator {
      try {
        yield call(Api.SiteImportingSettings.bulkUpdate, data);

        yield put(
          actions.getSiteImportingSettingsListRequest({ filter: filters.list }),
        );
        yield put(
          actions.getSiteImportingSettingsCountRequest({
            filter: filters.count,
          }),
        );

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        yield put(actions.updateSiteImportingSettingsSuccess());
      } catch (e) {
        yield put(actions.updateSiteImportingSettingsFailed());
      }
    },
  },
  {
    action: actions.getSiteImportingSettingsComboboxListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: Partial<ISiteImportingSettingsModel>[] = yield call(
          Api.SiteImportingSettings.list,
          action.payload,
        );
        yield put(
          actions.getSiteImportingSettingsComboboxListSuccess(response),
        );
      } catch (e) {
        yield put(actions.getSiteImportingSettingsComboboxListFailed());
      }
    },
  },
  {
    action: actions.getSiteImportingSettingsClientKeysRequest,
    saga: function* (): SagaIterator {
      try {
        const response: ClientKeys = yield call(
          Api.SiteImportingSettings.clientKeys,
        );
        yield put(actions.getSiteImportingSettingsClientKeysSuccess(response));
      } catch (e) {
        yield put(actions.getSiteImportingSettingsClientKeysFailed());
      }
    },
  },
  {
    action: actions.getSiteImportingComboboxListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      const combobox = yield select(getComboboxSitesImportingList);
      if (!combobox.length) {
        try {
          const filter = yield call(mergeFilters, action.payload, {
            filter: {
              include: [
                {
                  relation: 'importingSettings',
                },
              ],
            },
          });
          const sites = yield call(Api.Site.list, filter);
          yield put(actions.getSiteImportingComboboxListSuccess(sites));
        } catch (e) {
          yield put(actions.getSiteImportingComboboxListFailed());
        }
      }
    },
  },
];
