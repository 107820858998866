import { IStoreState } from '../types';

export const getAuthState = (state: IStoreState) => state.auth;

/**
 * Get server error
 * @param auth - State auth
 */
export const getServerError = ({ auth }: IStoreState) => auth.error;

/**
 * Check if data is requested
 * @param auth - State auth
 */
export const isDataRequested = ({ auth }: IStoreState) =>
  auth.is_data_requested;

/**
 * Check if a auth is logged in
 * @param auth - State auth
 */
export const isLoggedIn = ({ auth }: IStoreState) => auth.is_logged_in;

/**
 * Get current user role
 * @param auth - State auth
 */
export const getCurrentRole = ({ auth }: IStoreState): string =>
  String(auth.role);

/**
 * Fetch a message after email was sent successfully to restore auth password
 * @param auth - State auth
 */
export const getForgotPasswordMessage = ({ auth }: IStoreState) =>
  auth.forgotPasswordMessage;

/**
 * Fetch a status of restoring auth password
 * @param auth - State auth
 */
export const getPasswordUpdatedStatus = ({ auth }: IStoreState) =>
  auth.passwordUpdatedStatus;

/**
 * Fetch a message after email was sent successfully to complete registration
 * @param auth - State auth
 */
export const getRegisterUserMessage = ({ auth }: IStoreState) =>
  auth.registerUserMessage;

/**
 * Fetch a status of registration process
 * @param auth - State auth
 */
export const getRegistrationStatus = ({ auth }: IStoreState) =>
  auth.registrationStatus;

/**
 * Fetch expires_at
 * @param auth - State auth
 */
export const getExpiresAt = ({ auth }: IStoreState) => {
  return auth.expires_at;
};
