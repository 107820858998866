import React from 'react';
import Container from '@mui/joy/Container';
import { MUI_SIZE_3 } from '../../config';
import { Theme } from '@mui/joy';

export interface IContentLayoutProps {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  sx?: any;
}
export const ContentLayout = ({
  maxWidth,
  children,
  sx,
}: React.PropsWithChildren<IContentLayoutProps>) => {
  const mWidth = maxWidth ?? 'xl';

  if (!children) {
    return null;
  }

  return (
    <Container
      maxWidth={mWidth}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flexGrow: 1,
          alignItems: 'center',
          paddingTop: (theme: Theme) => theme.spacing(MUI_SIZE_3),
          paddingBottom: (theme: Theme) => theme.spacing(MUI_SIZE_3),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Container>
  );
};
