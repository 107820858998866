import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  IEmployeesActiveButNotPresentModel,
  IFilterWhere,
} from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';

export const useJobAbandonmentTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      firstName: {
        value: '',
        property: 'firstName',
        operator: 'like' as const,
      },
      lastName: {
        value: '',
        property: 'lastName',
        operator: 'like' as const,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'like' as const,
      },
      supervisorId: {
        value: '',
        property: 'supervisorId',
        operator: 'eq' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      staffingProvider: {
        value: '',
        property: 'staffingProvider',
        operator: 'like' as const,
      },
    };
  }, []);
};

export const useJobAbandonmentComposeWhere = (
  where: Partial<IEmployeesActiveButNotPresentModel>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      ...(where.firstName
        ? {
            firstName: {
              like: `%${where.firstName}%`,
            },
          }
        : {}),
      ...(where.lastName
        ? {
            lastName: {
              like: `%${where.lastName}%`,
            },
          }
        : {}),
      ...(where.badge
        ? {
            badge: {
              like: `%${where.badge}%`,
            },
          }
        : {}),
      ...(where.employeeId
        ? {
            employeeId: {
              like: `%${where.employeeId}%`,
            },
          }
        : {}),
      ...(where.staffingProvider
        ? {
            staffingProvider: {
              like: `%${where.staffingProvider}%`,
            },
          }
        : {}),
      ...(where.supervisorId
        ? {
            supervisorId: where.supervisorId,
          }
        : {}),
    }),
    [
      selectedSites,
      where.firstName,
      where.lastName,
      where.badge,
      where.employeeId,
      where.staffingProvider,
      where.supervisorId,
      where.siteId,
    ],
  );
};

export const useJobAbandonment = (reportUrl: string, where: IFilterWhere) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('employees.active_but_not_present.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('employees.active_but_not_present.last_name'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('employees.active_but_not_present.badge'),
      },
      {
        id: 'employeeId',
        orderConfig: { orderBy: 'employeeId' },
        label: t('employees.active_but_not_present.emp_id'),
      },
      {
        id: 'supervisorName',
        orderConfig: { orderBy: 'supervisorName' },
        label: t('employees.active_but_not_present.supervisorName'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('employees.active_but_not_present.site'),
      },
      {
        id: 'staffingProvider',
        orderConfig: { orderBy: 'staffingProvider' },
        label: t('employees.active_but_not_present.staffing_provider'),
      },
      {
        id: 'shiftName',
        orderConfig: { orderBy: 'shiftName' },
        label: t('employees.active_but_not_present.shift'),
      },
      {
        id: 'maxLoggedTime',
        orderConfig: { orderBy: 'maxLoggedTime' },
        label: t('employees.active_but_not_present.max_logged_time'),
      },
    ],
    [t],
  );

  return useBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'asc',
    defOrderBy: 'firstName',
  });
};
