import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import { ISagaActionBind, PolicyConfig } from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getResourcesRequest,
    saga: function* (): SagaIterator {
      try {
        const response: PolicyConfig[] = yield call(Api.Resources.list);
        yield put(actions.getResourcesSuccess(response));
      } catch (e) {
        yield put(actions.getResourcesFailed());
      }
    },
  },
];
