import React, { useEffect, useMemo } from 'react';
import { filter } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createOpenPositionRequest } from '../../modules/actions';
import CreateFormLayout, {
  CreateFormHandleSubmit,
} from '../../components/Layout/CreateFormLayout';
import { roles } from '../../config';
import {
  useBrowserHistoryFunctions,
  useFetchActiveDepartmentsCombobox,
  useFetchPositionsCombobox,
  useFetchShiftsCombobox,
  useFetchSkillsCombobox,
  useValidate,
} from '../../modules/utils/hooks';
import { getCurrentRole } from '../../modules/selectors/auth';
import { ComboBoxOption } from '../../components/ComboBox';
import { ICreateOpenPosition } from '../../modules/types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import FormikTextField from '../../components/Formik/FormikTextField';
import { createOpenPositionScheme } from '../../modules/schemes/open-position';
import {
  getShiftsComboboxListBySiteId,
  getShiftsComboboxMap,
} from '../../modules/selectors/shift';
import { getPositionsComboboxListBySiteId } from '../../modules/selectors/position';
import FormikDatepicker from '../../components/Formik/FormikDatepicker';
import FormikMultipleSelect from '../../components/Formik/FormikMultipleSelect';
import { getSkillsComboboxList } from '../../modules/selectors/skill';
import { getDepartmentOptionsByGloballySites } from '../../modules/selectors/department';
import { FormActions } from '../../components/Form/FormActions';
import { useOpenPositionCreateFormTourConfig } from '../../config/tours/openPosition';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { MultipleSelectOption } from 'src/components/Formik/FormikMultipleSelectWithSearch';

const OpenPositionCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  const initialValues: ICreateOpenPosition = {
    positionId: 0,
    currentDuration: 0,
    numberNeeded: 0,
    shiftId: 0,
    dateStart: '',
    description: '',
    siteId: 0,
    requisitionNumber: '',
    departmentId: 0,
    skills: [],
  };

  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchPositionsCombobox = useFetchPositionsCombobox();
  const fetchSkillsCombobox = useFetchSkillsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  const validate = useValidate<ICreateOpenPosition>(createOpenPositionScheme);

  // create dispatcher
  const dispatcher = useDispatch();

  const formik = useFormik<ICreateOpenPosition>({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createOpenPositionRequest({ data, navigate }));
    },
  });

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchPositionsCombobox();
    fetchSkillsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // fetch sites list from store
  const shifts = useSelector(
    getShiftsComboboxListBySiteId,
    shallowEqual,
  )(formik.values.siteId);
  const shiftsMap = useSelector(getShiftsComboboxMap, shallowEqual);

  const restSelectors = {
    skills: useSelector(getSkillsComboboxList),
    departments: useSelector(getDepartmentOptionsByGloballySites, shallowEqual),
  };

  // fetch positions
  const positions = useSelector(
    getPositionsComboboxListBySiteId,
    shallowEqual,
  )(formik.values.siteId);

  const skills = useMemo(
    () =>
      filter(restSelectors.skills, (s) => s.siteId === formik.values.siteId),
    [formik.values.siteId, restSelectors.skills],
  );

  const departments = useMemo(
    () =>
      filter(
        restSelectors.departments,
        (i) => i.siteId === formik.values.siteId,
      ),
    [restSelectors.departments, formik.values.siteId],
  );

  const openPositionTourConfig = useOpenPositionCreateFormTourConfig();

  const onSelectSite = () => {
    formik.setFieldValue('skills', initialValues.skills);
  };

  return (
    <CreateFormLayout
      cardTitle={t('open_position.create_form_card_title')}
      handleFormSubmit={formik.handleSubmit as CreateFormHandleSubmit}
      fields={formik.values}
      isFormValid={formik.isValid}
    >
      <SitesComboBox
        id="siteId"
        required={true}
        formik={formik}
        placeholder={t('open_position.siteId')}
        data-tour={openPositionTourConfig.tour.site.target}
        onChange={onSelectSite}
      />
      <FormikTextField
        id="requisitionNumber"
        name="requisitionNumber"
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('open_position.requisitionNumber')}
        data-tour={openPositionTourConfig.tour.requisitionNumber.target}
      />
      <FormikCombobox
        id="positionId"
        required={true}
        formik={formik}
        placeholder={t('open_position.positionId')}
        options={positions as ComboBoxOption[]}
        data-tour={openPositionTourConfig.tour.position.target}
      />

      <FormikCombobox
        id="departmentId"
        required={true}
        formik={formik}
        placeholder={t('open_position.departmentId')}
        options={departments as ComboBoxOption[]}
        data-tour={openPositionTourConfig.tour.department.target}
      />

      <FormikTextField
        id="numberNeeded"
        name="numberNeeded"
        required={true}
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('open_position.numberNeeded')}
        type="number"
        data-tour={openPositionTourConfig.tour.numberNeeded.target}
      />

      <FormikCombobox
        id="shiftId"
        required={true}
        formik={formik}
        placeholder={t('open_position.shiftId')}
        value={shiftsMap[formik.values.siteId] || null}
        options={shifts as ComboBoxOption[]}
        data-tour={openPositionTourConfig.tour.shift.target}
      />

      {![roles.CUSTOMER].includes(currentRole as string) && (
        <FormikTextarea
          id="description"
          name="description"
          formik={formik}
          variant="outlined"
          label={t('open_position.description')}
          data-tour={openPositionTourConfig.tour.description.target}
        />
      )}

      <FormikDatepicker
        id="dateStart"
        formik={formik}
        required={true}
        label={t('open_position.dateStart')}
        fullWidth={true}
        data-tour={openPositionTourConfig.tour.dateStart.target}
      />

      <FormikMultipleSelect
        id="skills"
        title={t('open_position.skills')}
        formik={formik}
        required={true}
        options={(skills as unknown) as MultipleSelectOption[]}
        data-tour={openPositionTourConfig.tour.skills.target}
      />

      <FormActions submitBtnTitle={t('common.create')} />
    </CreateFormLayout>
  );
};

export default OpenPositionCreate;
