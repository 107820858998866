import React from 'react';
import { AnyObject } from 'src/modules/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';

interface IMultiRowFormDeleteRowProps {
  formik: AnyObject;
  index: number;
}

export const MultiRowFormDeleteRow: React.FC<IMultiRowFormDeleteRowProps> = ({
  formik,
  index,
}) => {
  return (
    <Box display="flex" justifyContent="center">
      <IconButton
        style={{ maxWidth: 'max-content' }}
        onClick={() => {
          formik.setValues(
            formik.values.filter(
              (_: any, i: number) => Number(i) !== Number(index),
            ),
          );
        }}
        size="large"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};
