import React from 'react';
import { IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import ModalDialog from 'src/components/ModalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

interface IFilterModal {
  onFilterSubmit: () => void;
  onModalClose: () => void;
}

export const FilterModal: React.FC<IFilterModal> = ({
  children,
  onModalClose,
  onFilterSubmit,
}) => {
  const { t } = useTranslation();

  const [isModalOpened, setIsModalOpened] = React.useState(false);

  const onClose = () => {
    setIsModalOpened(false);
    onModalClose();
  };

  const handleModalOpen = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    setIsModalOpened(true);
  };

  const onOk = () => {
    setIsModalOpened(false);
    onFilterSubmit();
  };

  return (
    <>
      <IconButton
        onClick={handleModalOpen}
        title={t('common.filter_button_title')}
        size="lg"
      >
        <FontAwesomeIcon fontSize={16} icon={faFilter} />
      </IconButton>
      <ModalDialog
        onOk={onOk}
        onClose={onClose}
        open={isModalOpened}
        title={t('common.filter')}
      >
        {children}
      </ModalDialog>
    </>
  );
};
