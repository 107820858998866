import React from 'react';
import { Sheet, CircularProgress, Typography, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export const MainContentLoader = () => {
  const { t } = useTranslation();
  return (
    <Sheet
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 'modal',
        bgcolor: 'rgba(255,255,255, 0.5)',
      }}
    >
      <Box>
        <CircularProgress size="md" />
        <Box sx={{ mt: 2 }}>
          <Typography
            level="text_sm"
            fontWeight="medium"
            textColor="colors.text.text_secondary"
          >
            {t('common.loading')}...
          </Typography>
        </Box>
      </Box>
    </Sheet>
  );
};
