import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/joy';
import { MUI_SIZE_1, MUI_SIZE_12, MUI_SIZE_6 } from '../../config';
import { useTranslation } from 'react-i18next';
import { TextField } from '../_ui-kit/TextField';

export interface IRangeValue {
  from: string;
  to: string;
}

export interface IRangeError {
  from: string;
  to: string;
}

export interface IRangeProps {
  id: string;
  label: string;
  value?: IRangeValue;
  onChange(values: IRangeValue): void;
  errors?: IRangeError;
}

const Range: React.FC<IRangeProps> = (props) => {
  const { t } = useTranslation();

  const [values, setValues] = useState<IRangeValue>({
    from: '',
    to: '',
  });

  useEffect(() => {
    setValues(
      props.value ?? {
        from: '',
        to: '',
      },
    );
  }, [props.value]);

  const handleChange = (type: keyof IRangeValue) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValues = {
      ...values,
      [type]: event.target.value,
    };
    setValues(newValues);
    props.onChange(newValues);
  };

  return (
    <Grid container justifyContent="center" spacing={MUI_SIZE_1}>
      <Grid md={MUI_SIZE_6} xs={MUI_SIZE_12}>
        <TextField
          variant="outlined"
          fullWidth={true}
          id={`${props.id}_from`}
          label={t('common.range.from', { label: props.label })}
          name={`${props.id}_from`}
          value={values.from}
          onChange={handleChange('from')}
          helperTextProps={{
            title: props.errors?.from,
          }}
        />
      </Grid>
      <Grid md={MUI_SIZE_6} xs={MUI_SIZE_12}>
        <TextField
          variant="outlined"
          fullWidth={true}
          id={`${props.id}_to`}
          label={t('common.range.to', { label: props.label })}
          name={`${props.id}_to`}
          value={values.to}
          onChange={handleChange('to')}
          helperTextProps={{
            title: props.errors?.to,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Range;
