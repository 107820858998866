import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { IFilterWhere, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';
import { ICostPlusWeeklyModel } from '../../types/CostPlusWeeklyTypes';

export const useCostPlusWeeklyTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      shift: {
        value: '',
        property: 'shift',
        operator: 'like' as const,
      },
      department: {
        value: '',
        property: 'department',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      year: {
        value: '',
        property: 'year',
        operator: 'eq' as const,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const useCostPlusWeeklyComposeWhere = (
  where: Partial<ICostPlusWeeklyModel>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      ...(where.shift
        ? {
            shift: {
              like: `%${where.shift}%`,
            },
          }
        : {}),
      ...(where.department
        ? {
            department: {
              like: `%${where.department}%`,
            },
          }
        : {}),
      ...(where.year
        ? {
            year: {
              eq: where.year,
            },
          }
        : {}),
      ...(where.week
        ? {
            week: {
              eq: where.week,
            },
          }
        : {}),
    }),
    [
      selectedSites,
      where.siteId,
      where.shift,
      where.department,
      where.year,
      where.week,
    ],
  );
};

export const useCostPlusWeekly = (reportUrl: string, where: IFilterWhere) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('cost_plus_weekly.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('cost_plus_weekly.last_name'),
      },
      {
        id: 'payRate',
        orderConfig: { orderBy: 'payRate' },
        label: t('cost_plus_weekly.payrate'),
      },
      {
        id: 'shift',
        orderConfig: { orderBy: 'shift' },
        label: t('cost_plus_weekly.shift'),
      },
      {
        id: 'department',
        orderConfig: { orderBy: 'department' },
        label: t('cost_plus_weekly.department'),
      },
      {
        id: 'site',
        orderConfig: { orderBy: 'site' },
        label: t('cost_plus_weekly.site'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('cost_plus_weekly.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('cost_plus_weekly.week'),
      },
      {
        id: 'client',
        orderConfig: { orderBy: 'client' },
        label: t('cost_plus_weekly.client'),
      },
      {
        id: 'sumTimeTotal',
        orderConfig: { orderBy: 'sumTimeTotal' },
        label: t('cost_plus_weekly.time_total'),
      },
      {
        id: 'regularHoursDec',
        orderConfig: { orderBy: 'regularHoursDec' },
        label: t('cost_plus_weekly.reg_hrs'),
      },
      {
        id: 'overtimeHoursDec',
        orderConfig: { orderBy: 'overtimeHoursDec' },
        label: t('cost_plus_weekly.ot_hrs'),
      },
      {
        id: 'nGroupCostFmt',
        disableSorting: true,
        orderConfig: { orderBy: 'nGroupCostFmt' },
        label: t('cost_plus_weekly.cost_plus'),
      },
    ],
    [t],
  );

  return useBaseReport<IListWithSummary<ICostPlusWeeklyModel>>({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'asc',
    defOrderBy: 'firstName',
    initialData: {
      items: [],
      summary: [],
    },
  });
};
