import { createAction } from 'redux-act';

import {
  AnyObject,
  IArea,
  IDeleteAreaRequestData,
  IFilter,
  IUpdateAreaRequestData,
  PayloadWithFilters,
} from '../types';

export const getAreaList = createAction<number | null>('Get Areas list');

export const getAreaRawListRequest = createAction<IFilter | void>(
  'Get Areas raw list',
);
export const getAreaRawListSuccess = createAction<Array<IArea>>(
  'Get Areas raw list success',
);
export const getAreaRawListFailed = createAction('Get Areas raw list failed');

export const setTimeStamp = createAction<number>('Set time stamp');

export const getAllowedProperties = createAction<number>(
  'Get allowed properties for Areas',
);

export const setAreaMap = createAction<IArea[]>('Set Areas list to store');

export const setAllowedPropertiesOptions = createAction<
  { id: string; name: string }[]
>("Set Area's allowed properties as  options for Combobox");

export const updateArea = createAction<
  PayloadWithFilters<IUpdateAreaRequestData>
>('Update Area');

export const deleteArea = createAction<
  PayloadWithFilters<IDeleteAreaRequestData>
>('Delete Area');

export const createArea = createAction<PayloadWithFilters<AnyObject>>(
  'Create new Area',
);

export const toggleAreaLoading = createAction<boolean>('Toggle Area loading');
