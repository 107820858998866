import React, { ChangeEvent } from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ITextFieldProps, TextField } from 'src/components/_ui-kit/TextField';
import { ErrorMode } from './types';

export type IFormikTextFieldProps = Omit<ITextFieldProps, 'onChange'> & {
  id: string;
  name: string;
  formik: AnyObject;
  errorMode?: ErrorMode;
  onChange?(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: any,
  ): void;
};

function FormikTextField(props: IFormikTextFieldProps) {
  const {
    id,
    name,
    formik,
    label,
    errorMode = 'onFormSubmit',
    ...restProps
  } = props;

  const option = get(formik.values, id);
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  const handleValue = React.useCallback(
    (v) => {
      if (v === null || v === undefined) {
        return v;
      }

      return restProps.type === 'number'
        ? v === ''
          ? NaN
          : +v
        : v === ''
        ? null
        : v;
    },
    [restProps.type],
  );

  React.useEffect(() => {
    // in update entity forms we remove error manually since
    // validateOnChange false by default for all update forms
    const value = get(formik.values, id);

    if (errorMode === 'onFieldChange') {
      const error = get(formik.errors, id);

      if (Boolean(value) && Boolean(error)) {
        formik.setFieldError(id, undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMode, get(formik.values, id)]);

  const value = React.useMemo(() => {
    switch (props?.type) {
      case 'number':
        if (option === 0) {
          return '0';
        } else {
          return option || '';
        }
      default:
        return option || '';
    }
  }, [option, props?.type]);

  // const value =
  //   props?.type === 'number' && !option && option !== 0 ? '' : option || '';

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, handleValue(e.target.value));
  };

  // if type === 'number' blur() will prevent change value on scroll
  const onWheelHandler =
    props?.type === 'number'
      ? (event: React.WheelEvent<HTMLDivElement>) =>
          (event.target as HTMLElement).blur()
      : undefined;

  const onChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = handleValue(e.target.value);

      e.target.value = value;

      formik.handleChange(e);

      restProps.onChange && restProps.onChange(e, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleValue],
  );

  return (
    <TextField
      {...restProps}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      error={showError}
      helperText={showError && error}
      onWheel={onWheelHandler}
      helperTextProps={{
        sx: {
          color: 'colors.text.text_error_primary.main',
          ...restProps.helperTextProps?.sx,
        },
      }}
    />
  );
}

export default React.memo(FormikTextField, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    prev.label === cur.label &&
    prev.disabled === cur.disabled &&
    prev.required === cur.required &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
