import React, { useMemo } from 'react';
import { get } from 'lodash';
import { FormikHandlers, FormikHelpers, FormikState } from 'formik/dist/types';
import { AnyObject } from '../../modules/types';
import { StandardTextFieldProps } from '@mui/material';
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from '@mui/material/TextField/TextField';
import { ErrorMode } from './types';
import UploadImages from '../UploadImages';

type IFormikImagesUpload<Values> = (
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps
) & {
  id: string;
  name: string;
  formik: FormikHelpers<Values> & FormikState<Values> & FormikHandlers;
  errorMode?: ErrorMode;
};

export default function FormikImagesUpload<Values extends AnyObject>({
  name,
  formik,
  errorMode = 'onFormSubmit',
  ...restProps
}: IFormikImagesUpload<Values>) {
  const handleChange: any = (files: Array<File>) => {
    formik.setFieldError(name, undefined);
    formik.setFieldValue(name, files);
  };

  const error = useMemo(() => get(formik.errors, name), [formik.errors, name]);
  const touched = useMemo(() => get(formik.touched, name), [
    formik.touched,
    name,
  ]);

  const showError = useMemo<boolean>(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  return (
    <UploadImages
      onChange={handleChange}
      error={showError}
      images={formik.values[name]}
      helperText={showError && error}
      {...restProps}
    />
  );
}
