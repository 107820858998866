import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { IProductionDailyUphBase } from '../../types/productionUphReports';

const actionCreator = new ListWithSummaryActionCreator('OptoroDailyReport');

// get list actions
export const {
  getListRequest: getOptoroDailyReportListRequest,
  getListSuccess: getOptoroDailyReportListSuccess,
  getListFailed: getOptoroDailyReportListFailed,
} = actionCreator.takeListActions<IProductionDailyUphBase>();

// get count actions
export const {
  getCountRequest: getOptoroDailyReportCountRequest,
  getCountSuccess: getOptoroDailyReportCountSuccess,
  getCountFailed: getOptoroDailyReportCountFailed,
} = actionCreator.takeCountActions();
