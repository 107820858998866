import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICreateIssueInIssueTracker,
  IFilter,
  IIssueTrackerIssueModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../../types';

/**
 * Create a new issue
 */
export const createIssueInIssueTrackerRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateIssueInIssueTracker>
>): SagaIterator {
  try {
    const response: IIssueTrackerIssueModel = yield call(
      Api.IssueTracker.create,
      data,
    );
    yield put(actions.flushIssueTrackerState());
    yield put(actions.createIssueInIssueTrackerSuccess(response));
    yield call(navigate, { pathname: '/issue-tracker' });
  } catch (e) {
    yield put(actions.createIssueInIssueTrackerFailed());
  }
};

/**
 * Get issue list
 */
export const getIssuesFromIssueTrackerListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IIssueTrackerIssueModel[] = yield call(
      Api.IssueTracker.list,
      action.payload,
    );
    yield put(actions.getIssuesFromIssueTrackerListSuccess(response));
  } catch (e) {
    yield put(actions.getIssuesFromIssueTrackerListFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createIssueInIssueTrackerRequest,
    saga: createIssueInIssueTrackerRequestSaga,
  },
  {
    action: actions.getIssuesFromIssueTrackerListRequest,
    saga: getIssuesFromIssueTrackerListRequestSaga,
  },
];
