import { JSONSchemaType } from 'ajv';
import { ICreateApplicant } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createApplicantScheme: JSONSchemaType<ICreateApplicant> = {
  type: 'object',
  required: [
    'firstName',
    'lastName',
    'openPositionId',
    'payRate',
    'dateStart',
    'badge',
    'supervisorId',
    'siteId',
    'staffingId',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    firstName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lastName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    openPositionId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    payRate: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    dateInterview: {
      type: 'string',
      nullable: true,
      // default: '',
      default: null,
      errorMessage: {
        format: i18n.t('error.validation.date'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    dateStart: {
      type: 'string',
      format: 'date',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        format: i18n.t('error.validation.date'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    badge: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    supervisorId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    staffingId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    I9: {
      type: 'boolean',
      nullable: true,
    },
    bgCheck: {
      type: 'boolean',
      nullable: true,
    },
    interviewNotes: {
      type: 'string',
      nullable: true,
    },
    phonenumber: {
      type: 'string',
      nullable: true,
    },
    address: {
      type: 'string',
      nullable: true,
    },
  },

  // if user enter Date of Interview, it should be validated
  if: {
    properties: {
      dateInterview: { not: { const: '' } },
    },
  },
  then: {
    properties: {
      dateInterview: {
        type: 'string',
        nullable: true,
        format: 'date',
        errorMessage: {
          format: i18n.t('error.validation.date'),
        },
      },
    },
  },
};
