import React from 'react';
import { Box, Typography } from '@mui/joy';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';

interface IProfileFormProps {
  children: React.ReactNode;
  onApply: () => void;
  onReset: () => void;
  title: string;
}

export const ProfileForm = ({
  children,
  onApply,
  onReset,
  title,
}: IProfileFormProps) => {
  return (
    <Box sx={{ maxWidth: 370 }}>
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography level="text_xl" textColor="colors.text.text_primary.main">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          borderColor: 'colors.border.border_primary',
          borderWidth: 1,
          borderRadius: 8,
          borderStyle: 'solid',
          bgcolor: 'common.white',
        }}
      >
        <Box sx={{ py: 3, ml: -1, mr: -1 }}>{children}</Box>
        <Box
          sx={{
            borderTopColor: 'colors.border.border_tertiary',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            py: 2,
            px: 1,
          }}
        >
          <ActionsBar
            onReset={onReset}
            onApply={onApply}
            applyButtonType="submit"
          />
        </Box>
      </Box>
    </Box>
  );
};
