import React from 'react';
import { filter } from 'lodash';

import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';

export interface IEntityBySiteComboboxOption extends ComboBoxOption {
  sites: number[];
}

interface ISupervisorComboboxProps extends IFormikComboboxProps {
  options: IEntityBySiteComboboxOption[];
  // it's assumed form should have site combobox for filtering entities
  // path in formik values to get site id
  siteIdPath?: string;
}

const EntitiesBySiteComboBox: React.FC<ISupervisorComboboxProps> = ({
  options,
  siteIdPath = '',
  ...props
}) => {
  const entities = React.useMemo(() => {
    if (!props.formik.values[siteIdPath]) {
      return options;
    }

    return filter(options, (i) => {
      return i.sites.includes(props.formik.values[siteIdPath]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formik.values[siteIdPath], siteIdPath, options]);

  return (
    <FormikCombobox
      {...props}
      required={true}
      formik={props.formik}
      placeholder={props.placeholder}
      options={entities}
      error={props.error}
    />
  );
};

export default EntitiesBySiteComboBox;
