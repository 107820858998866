import React, { FC } from 'react';
import { Editor } from 'slate';

import { TableUtil } from '../../utils/table';

import { Button, MaterialIcon } from '../../components';

const InTable: FC<{ editor: Editor }> = ({ editor }) => {
  const table = new TableUtil(editor);

  const handleButtonClick = (action: string) => {
    switch (action) {
      case 'row':
        table.insertRow();
        break;
      case 'column':
        table.insertColumn();
        break;
      case 'remove':
        table.removeTable();
        break;
      default:
        return;
    }
  };
  return (
    <>
      <Button format="insert row" onClick={() => handleButtonClick('row')}>
        <MaterialIcon icon="row" style={{ transform: 'rotate(90deg)' }} />
      </Button>
      <Button
        format="insert column"
        onClick={() => handleButtonClick('column')}
      >
        <MaterialIcon icon="column" />
      </Button>
      <Button format="remove table" onClick={() => handleButtonClick('remove')}>
        <MaterialIcon icon="removeTable" />
      </Button>
    </>
  );
};

export default InTable;
