import React from 'react';
import { TextFieldProps } from '@mui/material';
import { getISOWeek, getYear, parseISO, subYears } from 'date-fns';
import { IConcatenateYearWeekArgs } from 'src/modules/utils/helpers/formatters';

import { AutocompleteProps } from '@mui/joy';
import ComboBox, { ComboBoxOption } from '../../ComboBox';

interface IYearWeekComboBoxProps {
  id: string;
  optionsToYear?: number;
  optionsToWeek?: number;
  optionsFromYear?: number;
  optionsFromWeek?: number;
  optionsOrder?: 'desc' | 'asc';
  label?: string;
  value?: ComboBoxOption['id'] | null;
  onChange: (
    e: React.SyntheticEvent<Element, Event>,
    option: ComboBoxOption | null,
  ) => void;
  formatOptionId?: (args: IConcatenateYearWeekArgs) => string;
  formatOptionName?: (args: IConcatenateYearWeekArgs) => string;
  autocompleteProps?: Partial<AutocompleteProps<any, any, any, any>>;
  textFiledProps?: TextFieldProps;
}

const FIRST_WEEK = 1;
const currentYear = getYear(new Date());
const currentWeek = getISOWeek(new Date());
const yearsAgoYear = getYear(subYears(new Date(), 10));

function formatOption({ year, week }: IConcatenateYearWeekArgs) {
  return `${year}-W${String(week).padStart(2, '0')}`;
}

export const YearWeekCombobox: React.FC<IYearWeekComboBoxProps> = ({
  id,
  optionsFromYear = Number(yearsAgoYear),
  optionsFromWeek = FIRST_WEEK,
  optionsToYear = currentYear,
  optionsToWeek = currentWeek,
  optionsOrder = 'desc',
  label,
  value,
  onChange,
}) => {
  const generateYearWeeks = (): Array<ComboBoxOption> => {
    const options: Array<ComboBoxOption> = [];

    for (let year = optionsFromYear; year <= optionsToYear; year++) {
      const lastWeekDate = parseISO(`${year}-12-28`);
      const lastWeek = getISOWeek(lastWeekDate);

      let weekToCompareWith = lastWeek;
      if (year === optionsToYear) {
        weekToCompareWith = optionsToWeek;
      }

      let week = year === optionsFromYear ? optionsFromWeek : FIRST_WEEK;
      while (week <= weekToCompareWith) {
        options.push(({
          id: +`${year}${String(week).padStart(2, '0')}`,
          name: formatOption({ year, week }),
        } as unknown) as ComboBoxOption);

        week++;
      }
    }

    return options;
  };

  const options =
    optionsOrder === 'asc'
      ? generateYearWeeks()
      : generateYearWeeks().reverse();

  const v = React.useMemo(() => options.find((o) => o.id === value), [
    options,
    value,
  ]);

  return (
    <ComboBox
      id={id}
      placeholder={label}
      value={v}
      options={options}
      onChange={onChange}
    />
  );
};
