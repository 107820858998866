import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IPointTypeModel, IPointTypeState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IPointTypeState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  reason: '',
  point: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  lifetime: 0,
  isGetPointTypeComboboxListRequestInProgress: false,
};

/**
 * Flush actions
 */
const flushPointTypeState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createPointTypeRequest = (state: IPointTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createPointTypeSuccess = (
  state: IPointTypeState,
  payload: IPointTypeModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createPointTypeFailed = (state: IPointTypeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getPointTypeListRequest = (state: IPointTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPointTypeListSuccess = (
  state: IPointTypeState,
  payload: IPointTypeModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getPointTypeListFailed = (state: IPointTypeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getPointTypeCountRequest = (state: IPointTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPointTypeCountSuccess = (
  state: IPointTypeState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getPointTypeCountFailed = (state: IPointTypeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deletePointTypeRequest = (state: IPointTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deletePointTypeFailed = (state: IPointTypeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updatePointTypesRequest = (state: IPointTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePointTypesFailed = (state: IPointTypeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getPointTypeComboboxListRequest = (state: IPointTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isGetPointTypeComboboxListRequestInProgress: true,
});

const getPointTypeComboboxListSuccess = (
  state: IPointTypeState,
  payload: Partial<IPointTypeModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
  isGetPointTypeComboboxListRequestInProgress: false,
});

const getPointTypeComboboxListFailed = (
  state: IPointTypeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isGetPointTypeComboboxListRequestInProgress: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IPointTypeState>();

const exportPointTypeRequest = exportRequest;
const exportPointTypeSuccess = exportSuccess;
const exportPointTypeFailed = exportFailed;

/**
 * PointType reducer
 */
export const pointType = createReducer<IPointTypeState>({}, defaultState)
  // flush actions
  .on(actions.flushPointTypeState, flushPointTypeState)
  // creation actions
  .on(actions.createPointTypeRequest, createPointTypeRequest)
  .on(actions.createPointTypeSuccess, createPointTypeSuccess)
  .on(actions.createPointTypeFailed, createPointTypeFailed)
  // get list actions
  .on(actions.getPointTypeListRequest, getPointTypeListRequest)
  .on(actions.getPointTypeListSuccess, getPointTypeListSuccess)
  .on(actions.getPointTypeListFailed, getPointTypeListFailed)
  // get count actions
  .on(actions.getPointTypeCountRequest, getPointTypeCountRequest)
  .on(actions.getPointTypeCountSuccess, getPointTypeCountSuccess)
  .on(actions.getPointTypeCountFailed, getPointTypeCountFailed)
  // deletion actions
  .on(actions.deletePointTypeRequest, deletePointTypeRequest)
  .on(actions.deletePointTypeFailed, deletePointTypeFailed)
  // updating actions
  .on(actions.updatePointTypesRequest, updatePointTypesRequest)
  .on(actions.updatePointTypesFailed, updatePointTypesFailed)
  // get combobox list actions
  .on(actions.getPointTypeComboboxListRequest, getPointTypeComboboxListRequest)
  .on(actions.getPointTypeComboboxListSuccess, getPointTypeComboboxListSuccess)
  .on(actions.getPointTypeComboboxListFailed, getPointTypeComboboxListFailed)
  // export actions
  .on(actions.exportPointTypeRequest, exportPointTypeRequest)
  .on(actions.exportPointTypeSuccess, exportPointTypeSuccess)
  .on(actions.exportPointTypeFailed, exportPointTypeFailed);

export default pointType;
