import { ISagaActionBind } from '../../types';
import { IProductionWeeklyCpuModel } from '../../types/productionCpuReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyCpuListRequest,
    saga: createListSaga<IProductionWeeklyCpuModel>({
      apiCall: Api.CardinalHealthWeeklyCpu.list,
      actionSuccess: actions.getCardinalHealthWeeklyCpuListSuccess,
      actionFailed: actions.getCardinalHealthWeeklyCpuListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyCpuCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyCpu.count,
      actionSuccess: actions.getCardinalHealthWeeklyCpuCountSuccess,
      actionFailed: actions.getCardinalHealthWeeklyCpuCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyCpuRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyCpu.export,
      actionSuccess: actions.exportCardinalHealthWeeklyCpuSuccess,
      actionFailed: actions.exportCardinalHealthWeeklyCpuFailed,
    }),
  },
];
