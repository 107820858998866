import React from 'react';
import { useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import {
  useChecklistConstants,
  useChecklistTableDefaultFilter,
  useChecklistTaskDefaultFilter,
  useDefaultTaskData,
  useFetchChecklistComboboxDataEffect,
  useFetchChecklistsEffect,
  useFetchChecklistTasksEffect,
} from 'src/modules/utils/hooks/checklist';
import {
  getChecklistTaskRefreshKey,
  getTasksAsEvents,
} from 'src/modules/selectors/checklist.selector';
import { CreateTask, UpdateTask } from './forms';
import {
  IActiveElementData,
  ICreateChecklistTaskData,
  IWhere,
  IUpdateChecklistTaskData,
} from 'src/modules/types';
import {
  composeDate,
  DATETIME_FORMAT_TO_PASS,
  format,
} from 'src/modules/utils/dateWrapper';
import { TaskViewToolbar } from './TaskViewToolbar';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useSearch,
} from 'src/modules/utils';
import { Box } from '@mui/material';

export const TaskView = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const [datesRangeInCalendar, setDateRangeInCalendar] = React.useState<
    [] | [Date, Date]
  >([]);
  const checklistTasksDefaultFilter = useChecklistTaskDefaultFilter();
  const checklistTableDefaultFilter = useChecklistTableDefaultFilter();

  const [appliedFilters, setAppliedFilters] = React.useState<IWhere>();
  const [activeTaskData, setActiveTaskData] = React.useState<any | null>(null);
  const [activeEl, setActiveEl] = React.useState<IActiveElementData | null>(
    null,
  );

  const {
    height,
    slotDuration,
    headerToolbar,
    eventMinHeight,
    defaultTimedEventDuration,
  } = useChecklistConstants();
  const defaultTaskData = useDefaultTaskData();

  const queryParams = useQueryParams();
  const createQueryParams = useSearch();

  const tasksAsEvents = useSelector(getTasksAsEvents(datesRangeInCalendar));

  const isEditing = Boolean(activeTaskData?.title);
  const isStartTimeForCreateTaskSet = Boolean(activeTaskData?.startTime);

  // We need to make sure that startTime exists in activeTaskData
  // before opening Popover, because child components rely on it during different calculations
  const isPopoverOpen = Boolean(
    // When editing startTime presented immediately
    (activeEl && isEditing) ||
      // When creating need to wait till fullCalendar paste startTime from the clicked brick
      (activeEl && isStartTimeForCreateTaskSet),
  );

  const eventClickHandler: CalendarOptions['eventClick'] = ({
    event,
    jsEvent,
    el,
  }) => {
    jsEvent.preventDefault();
    jsEvent.stopPropagation();
    const activeTaskDetails = tasksAsEvents.find(
      (task) => task.id === event.id,
    );
    setActiveEl({
      selector: el,
      top: jsEvent.y,
      left: jsEvent.x,
    });

    setActiveTaskData(activeTaskDetails);
  };

  const dateClickHandler: CalendarOptions['dateClick'] = ({
    jsEvent,
    date,
  }) => {
    if (!jsEvent.target) {
      return;
    }

    setActiveEl({
      selector: jsEvent.target as Element,
      top: jsEvent.y,
      left: jsEvent.x,
    });

    setActiveTaskData({
      ...defaultTaskData,
      startTime: composeDate(date, format(DATETIME_FORMAT_TO_PASS)),
    });
  };

  const onPopoverClose = () => {
    setActiveEl(null);
  };

  useFetchChecklistsEffect(checklistTableDefaultFilter);
  useFetchChecklistTasksEffect(appliedFilters);
  useFetchChecklistComboboxDataEffect();

  React.useEffect(() => {
    setAppliedFilters({ filter: checklistTasksDefaultFilter });
  }, [checklistTasksDefaultFilter]);

  return (
    <Paper
      sx={(theme) => ({
        padding: theme.spacing(1, 2, 10, 2),
        height: '100%',
      })}
    >
      <TaskViewToolbar
        onSubmitFilters={setAppliedFilters}
        onResetFilters={setAppliedFilters}
      />

      <Box
        sx={(theme) => ({
          width: '100%',
          height: '100%',

          [theme.breakpoints.down('md')]: {
            '& .fc .fc-toolbar.fc-header-toolbar': {
              display: 'flex',
              flexDirection: 'column',
            },
            '& .fc-toolbar-chunk': {
              display: 'block',
              width: '100%',
            },
            '& .fc-toolbar-chunk:first-of-type': {
              marginBottom: '1em',
            },
          },
        })}
      >
        <FullCalendar
          nowIndicator
          plugins={[
            listPlugin,
            timeGridPlugin,
            dayGridPlugin,
            interactionPlugin,
          ]}
          height={height}
          slotDuration={slotDuration}
          headerToolbar={headerToolbar}
          events={tasksAsEvents}
          eventMinHeight={eventMinHeight}
          defaultTimedEventDuration={defaultTimedEventDuration}
          eventClick={eventClickHandler}
          dateClick={dateClickHandler}
          datesSet={({ start, end, view }) => {
            pushToHistory({
              search: createQueryParams({
                ...queryParams,
                initialView: view.type,
              }),
            });
            setDateRangeInCalendar([start, end]);
          }}
          initialView={(queryParams.initialView as string) ?? 'timeGridWeek'}
        />

        <Popover
          anchorReference="anchorPosition"
          open={isPopoverOpen}
          anchorEl={activeEl?.selector}
          onClose={onPopoverClose}
          anchorPosition={{
            top: activeEl?.top ?? 0,
            left: activeEl?.left ?? 0,
          }}
        >
          {isEditing ? (
            <UpdateTask
              onClose={onPopoverClose}
              onSubmit={onPopoverClose}
              onDelete={onPopoverClose}
              taskData={activeTaskData as IUpdateChecklistTaskData}
            />
          ) : (
            <CreateTask
              onClose={onPopoverClose}
              onSubmit={onPopoverClose}
              taskData={activeTaskData as ICreateChecklistTaskData}
            />
          )}
        </Popover>
      </Box>
    </Paper>
  );
};

export default function TaskViewRefreshable() {
  return <TaskView key={useSelector(getChecklistTaskRefreshKey)} />;
}
