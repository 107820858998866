import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateEmployeeSkillAttachment,
  IdsArray,
  IEmployeeSkillAttachmentModel,
  IEmployeeSkillModel,
  IFilter,
  IUpdateEmployeeSkill,
  PayloadWithFilters,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushEmployeeSkillState = createAction(
  'Flush employeeSkill data in store',
);

// Fetch employeeSkills actions
export const getEmployeeSkillListRequest = createAction<IFilter>(
  'Make request to fetch employeeSkill list',
);
export const getEmployeeSkillListSuccess = createAction<IEmployeeSkillModel[]>(
  'EmployeeSkills list has been fetched successfully',
);
export const getEmployeeSkillListFailed = createAction(
  'Cannot fetch employeeSkill list',
);

// get employeeSkills count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getEmployeeSkillCountRequest = createAction<IFilter>(
  'Make request to get employeeSkill count',
);
export const getEmployeeSkillCountSuccess = createAction<ICountResponse>(
  'EmployeeSkills count has been fetched successfully',
);
export const getEmployeeSkillCountFailed = createAction(
  'Cannot get employeeSkill count',
);

// Updating actions
export const updateEmployeeSkillsRequest = createAction<
  PayloadWithFilters<IUpdateEmployeeSkill[]>
>('Make request to update employeeSkills');
export const updateEmployeeSkillsFailed = createAction(
  'Cannot update employeeSkills',
);

// Fetch combobox list actions
export const getEmployeeSkillComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of employeeSkills',
);
export const getEmployeeSkillComboboxListSuccess = createAction<
  Partial<IEmployeeSkillModel>[]
>('Combobox list of employeeSkills has been fetched successfully');
export const getEmployeeSkillComboboxListFailed = createAction(
  'Cannot fetch combobox employeeSkills list',
);

// export actions
export const {
  exportReportRequest: exportEmployeeSkillRequest,
  exportReportSuccess: exportEmployeeSkillSuccess,
  exportReportFailed: exportEmployeeSkillFailed,
} = new ExportActionCreator('EmployeeSkill').takeExportActions();

// create attachments
export const createEmployeeSkillAttachmentRequest = createAction<
  ICreateEmployeeSkillAttachment
>('Make request to create a new employee skill attachment');
export const createEmployeeSkillAttachmentSuccess = createAction<
  IEmployeeSkillAttachmentModel
>('New employee skill attachment has been created successfully');
export const createEmployeeSkillAttachmentFailed = createAction(
  'Cannot create a new employee skill attachment',
);

// delete attachments
export const deleteEmployeeSkillAttachmentRequest = createAction<IdsArray>(
  'Make request to delete employee skill attachment',
);
export const deleteEmployeeSkillAttachmentSuccess = createAction<IdsArray>(
  'Employee skill attachment has been removed successfully',
);
export const deleteEmployeeSkillAttachmentFailed = createAction(
  'Cannot delete employee skill attachment',
);
