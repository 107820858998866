import { JSONSchemaType } from 'ajv';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { IFinancialTrackerCreate } from '../../types/financial-tracker';

export const createFinancialTrackerScheme: JSONSchemaType<IFinancialTrackerCreate> = {
  type: 'object',
  required: [
    'siteId',
    'yearNumber',
    'weekNumber',
    'revenue',
    'staffingCost',
    'internalCost',
    'otherCosts',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    weekNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    yearNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    revenue: {
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
      },
    },
    staffingCost: {
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
      },
    },
    internalCost: {
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
      },
    },
    otherCosts: {
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
      },
    },
  },
};
