import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateSiteTaktExportingSettingsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { getSiteTaktExportingSettingsList } from 'src/modules/selectors/site-takt-exporting-settings.selector';
import { updateSiteTaktExportingSettingScheme } from 'src/modules/schemes';

const initialValues = {
  // Required properties
  siteId: NaN,
  apiKey: '',
  apiKeySecret: '',
  employeesEndpoint: '',
  punchesEndpoint: '',
};

interface ITaktSettingsUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  entitiesToUpdateIds: IdsArray;
}

export const TaktSettingsUpdate = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  entitiesToUpdateIds,
}: ITaktSettingsUpdateProps) => {
  const { t } = useTranslation();

  const allEntities = useSelector(getSiteTaktExportingSettingsList);

  const entitiesToUpdateFullData = allEntities.filter((entity) =>
    entitiesToUpdateIds.includes(entity.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updateSiteTaktExportingSettingScheme);

  const formik = useFormik({
    initialValues: entitiesToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateSiteTaktExportingSettingsRequest({
          data: data.map((item) => ({
            id: item.id,
            siteId: item.siteId,
            apiKey: item.apiKey,
            apiKeySecret: item.apiKeySecret,
            punchesEndpoint: item.punchesEndpoint,
            employeesEndpoint: item.employeesEndpoint,
          })),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('site-takt-exporting-settings.update_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {formik.values.length === entitiesToUpdateFullData.length
              ? entitiesToUpdateFullData.map((entity, index) => (
                  <DrawerBodySectionContent key={entity.id}>
                    <DrawerBodySectionTitle>{index + 1}</DrawerBodySectionTitle>
                    <FormFieldContainer>
                      <SitesComboBox
                        id={`${index}.siteId`}
                        required={true}
                        formik={formik}
                        placeholder={t('site-takt-exporting-settings.siteUuid')}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        id={`${index}.apiKey`}
                        variant="outlined"
                        fullWidth
                        label={t(`site-takt-exporting-settings.api_key`)}
                        name={`${index}.apiKey`}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        id={`${index}.apiKeySecret`}
                        variant="outlined"
                        fullWidth
                        label={t(`site-takt-exporting-settings.api_key_secret`)}
                        name={`${index}.apiKeySecret`}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        id={`${index}.employeesEndpoint`}
                        variant="outlined"
                        fullWidth
                        label={t(
                          `site-takt-exporting-settings.employees_endpoint`,
                        )}
                        name={`${index}.employeesEndpoint`}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        id={`${index}.punchesEndpoint`}
                        variant="outlined"
                        fullWidth
                        label={t(
                          `site-takt-exporting-settings.punches_endpoint`,
                        )}
                        name={`${index}.punchesEndpoint`}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>
                  </DrawerBodySectionContent>
                ))
              : null}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
