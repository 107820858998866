import { JSONSchemaType } from 'ajv';
import { IUpdateBaseline } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { generateCreateBaselineScheme } from './create-baseline.scheme';

export const generateUpdateBaselineScheme = (
  metatags: string[],
): JSONSchemaType<IUpdateBaseline[]> => {
  const createBaselinesScheme = generateCreateBaselineScheme(metatags);

  return {
    type: 'array',
    items: {
      ...createBaselinesScheme,
      type: 'object',
      required: createBaselinesScheme.required,
      properties: {
        ...createBaselinesScheme.properties,
        id: {
          type: 'number',
          isNotEmpty: true,
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
          },
        },
      },
    },
  };
};
