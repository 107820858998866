import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  IFilter,
  ISagaAction,
  IWhere,
  TurnoverType,
  ISagaActionBind,
  ListWithSummary,
  ITurnoverAddedModel,
  ITurnoverLostModel,
  ITurnoverActiveModel,
  ITurnoverTerminatedModel,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';

/**
 * Get turnover added list
 */
export const getTurnoverAddedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<ITurnoverAddedModel> = yield call(
      Api.Turnover.addedList,
      action.payload,
    );
    yield put(actions.getTurnoverAddedListSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverAddedListFailed());
  }
};

/**
 * Get turnover added list count
 */
export const getTurnoverAddedCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Turnover.addedCount,
      action?.payload,
    );
    yield put(actions.getTurnoverAddedCountSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverAddedCountFailed());
  }
};

/**
 * Get turnover lost list
 */
export const getTurnoverLostListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<ITurnoverLostModel> = yield call(
      Api.Turnover.lostList,
      action.payload,
    );
    yield put(actions.getTurnoverLostListSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverLostListFailed());
  }
};

/**
 * Get turnover lost list count
 */
export const getTurnoverLostCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Turnover.lostCount,
      action?.payload,
    );
    yield put(actions.getTurnoverLostCountSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverLostCountFailed());
  }
};

/**
 * Get turnover active list
 */
export const getTurnoverActiveListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<ITurnoverActiveModel> = yield call(
      Api.Turnover.activeList,
      action.payload,
    );
    yield put(actions.getTurnoverActiveListSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverActiveListFailed());
  }
};

/**
 * Get turnover active list count
 */
export const getTurnoverActiveCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Turnover.activeCount,
      action?.payload,
    );
    yield put(actions.getTurnoverActiveCountSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverActiveCountFailed());
  }
};

/**
 * Get turnover terminated list
 */
export const getTurnoverTerminatedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<ITurnoverTerminatedModel> = yield call(
      Api.Turnover.terminatedList,
      action.payload,
    );
    yield put(actions.getTurnoverTerminatedListSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverTerminatedListFailed());
  }
};

/**
 * Get turnover terminated list count
 */
export const getTurnoverTerminatedCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Turnover.terminatedCount,
      action?.payload,
    );
    yield put(actions.getTurnoverTerminatedCountSuccess(response));
  } catch (e) {
    yield put(actions.getTurnoverTerminatedCountFailed());
  }
};

const createTurnoverExportSaga = (type: TurnoverType) =>
  createExportSaga({
    apiCall: (params) => Api.Turnover.export(type, params),
    actionSuccess: actions.exportTurnoverSuccess,
    actionFailed: actions.exportTurnoverFailed,
  });

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportTurnoverAddedRequest,
    saga: createTurnoverExportSaga('added'),
  },
  {
    action: actions.exportTurnoverLostRequest,
    saga: createTurnoverExportSaga('lost'),
  },
  {
    action: actions.exportTurnoverActiveRequest,
    saga: createTurnoverExportSaga('active'),
  },
  {
    action: actions.exportTurnoverTerminatedRequest,
    saga: createTurnoverExportSaga('terminated'),
  },
  // get turnover added list
  {
    action: actions.getTurnoverAddedListRequest,
    saga: getTurnoverAddedListRequestSaga,
  },
  // get turnover added list count
  {
    action: actions.getTurnoverAddedCountRequest,
    saga: getTurnoverAddedCountRequestSaga,
  },
  // get turnover lost list
  {
    action: actions.getTurnoverLostListRequest,
    saga: getTurnoverLostListRequestSaga,
  },
  // get turnover lost list count
  {
    action: actions.getTurnoverLostCountRequest,
    saga: getTurnoverLostCountRequestSaga,
  },
  // get turnover active list
  {
    action: actions.getTurnoverActiveListRequest,
    saga: getTurnoverActiveListRequestSaga,
  },
  // get turnover active list count
  {
    action: actions.getTurnoverActiveCountRequest,
    saga: getTurnoverActiveCountRequestSaga,
  },
  // get turnover terminated list
  {
    action: actions.getTurnoverTerminatedListRequest,
    saga: getTurnoverTerminatedListRequestSaga,
  },
  // get turnover terminated list count
  {
    action: actions.getTurnoverTerminatedCountRequest,
    saga: getTurnoverTerminatedCountRequestSaga,
  },
];
