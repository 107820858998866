import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimePunchesApproved } from './panels/TimePunchesApproved';
import { TimePunchesUnapproved } from './panels/TimePunchesUnapproved';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { useBrowserHistoryFunctions } from 'src/modules/utils';
import { TimePunchesMissed } from './panels/TimePunchesMissed';

export const TimePunches = () => {
  const { t } = useTranslation();
  const { resetFiltersInQueryParams } = useBrowserHistoryFunctions();

  const hasUserAccessToApprovedPunchesTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PUNCHES_APPROVED_TAB,
  );

  const hasUserAccessToUnapprovedPunchesTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PUNCHES_APPROVED_TAB,
  );

  const hasUserAccessToMissedPunchesTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PUNCHES_MISSED_TAB,
  );

  const timePunchesTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('common.approved'),
          hasUserAccess: hasUserAccessToApprovedPunchesTab,
          component: <TimePunchesApproved />,
        },
        {
          title: t('common.unapproved'),
          hasUserAccess: hasUserAccessToUnapprovedPunchesTab,
          component: <TimePunchesUnapproved />,
        },
        {
          title: t('common.missed'),
          hasUserAccess: hasUserAccessToMissedPunchesTab,
          component: <TimePunchesMissed />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToApprovedPunchesTab,
      hasUserAccessToUnapprovedPunchesTab,
      hasUserAccessToMissedPunchesTab,
      t,
    ],
  );

  return (
    <TabsNgroup
      config={timePunchesTabsConfig}
      onTabChange={resetFiltersInQueryParams}
    />
  );
};
