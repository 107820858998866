import { memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  IStoreState,
  IUpdateQuestionCategoryRequest,
  QuestionCategoryComboboxOption,
} from '../types';
import { getQuestionList } from './question';

export const getQuestionCategoryRefreshKey = (state: IStoreState) =>
  state.questionCategory.refreshKey;

export const getQuestionCategoryData = (state: IStoreState) =>
  state.questionCategory;

export const getQuestionCategoryList = createSelector(
  getQuestionCategoryData,
  (data) => data.list,
);

export const getQuestionCategoryCount = createSelector(
  getQuestionCategoryData,
  (data) => data.count,
);

export const getQuestionCategoriesComboboxList = createSelector(
  getQuestionCategoryData,
  (data) => data.comboboxList as QuestionCategoryComboboxOption[],
);

export const getQuestionCategoriesByIds = createSelector(
  getQuestionCategoryList,
  (questionCategories) =>
    memoize((ids: IdsArray) => {
      const initial: IUpdateQuestionCategoryRequest[] = [];
      return reduce(
        questionCategories,
        (acc, cur) => {
          if (ids.includes(`${cur.id}`)) {
            // omit columns we should not request with
            const questionCategory = omit(cur, ['site']);
            acc.push(questionCategory);
          }
          return acc;
        },
        initial,
      );
    }),
);

export const getQuestionCategoriesWithQuestions = createSelector(
  getQuestionCategoryList,
  getQuestionList,
  (questionCategories, questions) => {
    return questionCategories.reduce(
      (questionCategoriesWithQuestions, questionCategory) => {
        questionCategoriesWithQuestions[questionCategory.id] = questions.filter(
          (question) => question.questionCategoryId === questionCategory.id,
        );

        return questionCategoriesWithQuestions;
      },
      {},
    );
  },
);

export const getIsQuestionCategoriesDataLoading = createSelector(
  getQuestionCategoryData,
  ({ is_data_requested }) => is_data_requested,
);
