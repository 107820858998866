import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateIssueType,
  IdsArray,
  IFilter,
  IIssueTypeModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new issueType
 */
export const createIssueTypeRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ICreateIssueType>>): SagaIterator {
  try {
    const response: IIssueTypeModel = yield call(Api.IssueType.create, data);

    yield put(actions.getIssueTypeListRequest({ filter: filters.list }));
    yield put(actions.getIssueTypeCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createIssueTypeSuccess(response));
  } catch (e) {
    yield put(actions.createIssueTypeFailed());
  }
};

/**
 * Get issueType list
 */
export const getIssueTypeListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IIssueTypeModel[] = yield call(
      Api.IssueType.list,
      action.payload,
    );
    yield put(actions.getIssueTypeListSuccess(response));
  } catch (e) {
    yield put(actions.getIssueTypeListFailed());
  }
};

/**
 * Get issueType count
 */
export const getIssueTypeCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.IssueType.count,
      action?.payload,
    );
    yield put(actions.getIssueTypeCountSuccess(response));
  } catch (e) {
    yield put(actions.getIssueTypeCountFailed());
  }
};

/**
 * Delete issueTypes
 */
export const deleteIssueTypeRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.IssueType.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getIssueTypeListRequest({ filter: filters.list }));
    yield put(actions.getIssueTypeCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteIssueTypeSuccess());
  } catch (e) {
    yield put(actions.deleteIssueTypeFailed());
  }
};

/**
 * Bulk update issueTypes
 */
export const updateIssueTypesRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IIssueTypeModel[]>>): SagaIterator {
  try {
    yield call(Api.IssueType.bulkUpdate, data);

    yield put(actions.getIssueTypeListRequest({ filter: filters.list }));
    yield put(actions.getIssueTypeCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateIssueTypesSuccess());
  } catch (e) {
    yield put(actions.updateIssueTypesFailed());
  }
};

/**
 * Get combobox list
 */
export const getIssueTypeComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IIssueTypeModel>[] = yield call(
      Api.IssueType.list,
      action.payload,
    );
    yield put(actions.getIssueTypeComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getIssueTypeComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.IssueType.export,
  actionFailed: actions.exportIssueTypeFailed,
  actionSuccess: actions.exportIssueTypeSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportIssueTypeRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createIssueTypeRequest, saga: createIssueTypeRequestSaga },
  {
    action: actions.getIssueTypeListRequest,
    saga: getIssueTypeListRequestSaga,
  },
  {
    action: actions.getIssueTypeCountRequest,
    saga: getIssueTypeCountRequestSaga,
  },
  { action: actions.deleteIssueTypeRequest, saga: deleteIssueTypeRequestSaga },
  // bulk updating issueTypes
  {
    action: actions.updateIssueTypesRequest,
    saga: updateIssueTypesRequestSaga,
  },
  {
    action: actions.getIssueTypeComboboxListRequest,
    saga: getIssueTypeComboboxListRequestSaga,
  },
  { action: actions.exportIssueTypeRequest, saga: exportIssueTypeRequestSaga },
];
