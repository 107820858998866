import React from 'react';
import { isEmpty } from 'lodash';

import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import { IDailyHrsChart } from 'src/modules/types';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from '../../../../../../components/Charts/ChartContainer';

interface ISumByDepartmentChartProps {
  chartHeight: string;
  data: IDailyHrsChart;
  isLoading?: boolean;
  onToggleFullScreen: () => void;
  onExport: (fileName: string) => void;
}

export const SumByDepartmentChart = React.forwardRef<
  Highcharts.Chart,
  ISumByDepartmentChartProps
>(({ data, chartHeight, isLoading, onToggleFullScreen, onExport }, ref) => {
  return (
    <ChartContainer>
      <ChartContainerHeader title={data.title}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={onToggleFullScreen}
            onExport={() => onExport(`daily_hours_chart_pay`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isLoading}
        showNoData={!data || isEmpty(data)}
      >
        <ColumnChart
          ref={ref}
          height={chartHeight}
          categories={data.categories}
          series={data.series}
          yAxis={[
            {
              min: 0,
              title: {
                enabled: false,
              } as Highcharts.YAxisTitleOptions,
            },
            {
              min: 0,
              allowDecimals: false,
              opposite: true,
              title: {
                enabled: false,
              } as Highcharts.YAxisTitleOptions,
            },
          ]}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
});
