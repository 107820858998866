import { ICompleteCreationRequest } from '../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../utils/i18n';
import { JSONSchemaType } from 'ajv/lib/types/json-schema';

export const completeProfileScheme: JSONSchemaType<
  Omit<ICompleteCreationRequest, 'activateToken'> & {
    confirmPassword: { $data: string } | string;
  }
> = {
  type: 'object',
  required: ['password', 'firstName', 'lastName'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    firstName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    lastName: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    password: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    confirmPassword: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      const: {
        $data: '1/password',
      },
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        const: i18n.t('error.validation.confirm_password_not_match'),
      },
    },
  },
};
