import React from 'react';
import { Sheet, Typography } from '@mui/joy';

interface IDrawerBodySectionTitleProps {
  children: React.ReactNode;
}

export const DrawerBodySectionTitle = ({
  children,
}: IDrawerBodySectionTitleProps) => {
  return (
    <Sheet
      sx={{
        my: 3,
        px: 4,
        py: 1,
        borderRadius: 'md',
        bgcolor: 'colors.background.bg_secondary',
      }}
    >
      <Typography
        level="text_sm"
        fontWeight="medium"
        textColor="colors.text.text_tertiary.main"
      >
        {children}
      </Typography>
    </Sheet>
  );
};
