import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '@mui/material/Button';
import { useBrowserHistoryFunctions } from 'src/modules/utils';

interface ICancelButtonProps extends ButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
}

export const CancelButton: React.FC<ICancelButtonProps> = ({
  disabled,
  fullWidth,
  children,
  onClick,
  ...rest
}) => {
  const { historyGo } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  const _onClick = () => {
    historyGo(-1);
  };

  const title = children ?? t('common.cancel');

  return (
    <Button
      color="primary"
      variant="outlined"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick ?? _onClick}
      {...rest}
    >
      {title}
    </Button>
  );
};
