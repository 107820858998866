import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deleteOptiturnCredentialsRequest,
  getOptiturnCredentialsCountRequest,
  getOptiturnCredentialsListRequest,
} from '../../modules/actions';
import {
  getOptiturnCredentialsCount,
  getOptiturnCredentialsRefreshKey,
  getOptiturnCredentialsTable,
} from '../../modules/selectors/optiturn-credentials.selector';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useSearch,
  useFilter,
  useFetchSitesCombobox,
  useBrowserHistoryFunctions,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, paths, useHasUserAccessToAction } from '../../config';
import { useOptiturnCredentialsDefaultFilters } from 'src/modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const OptiturnCredentialsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const defaultFilter = useOptiturnCredentialsDefaultFilters();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.optiturnCredentials.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.optiturnCredentials.update.id,
  );

  const fetchSitesCombobox = useFetchSitesCombobox();

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch OptiturnCredentials list
  const list = useSelector(getOptiturnCredentialsTable, shallowEqual);
  const count = useSelector(getOptiturnCredentialsCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'login',
      disablePadding: false,
      label: t('optiturn-credentials.login'),
    },
    {
      id: 'password',
      disablePadding: false,
      label: t('optiturn-credentials.password'),
    },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('optiturn-credentials.site'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'login',
      label: t('optiturn-credentials.login'),
      operator: 'like',
    },
    {
      name: 'password',
      label: t('optiturn-credentials.password'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('optiturn-credentials.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
  ];

  // make request to fetch optiturnCredentials when component is mounted
  useEffect(() => {
    dispatcher(getOptiturnCredentialsListRequest(filterList));
    // get total count
    dispatcher(getOptiturnCredentialsCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getOptiturnCredentialsListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getOptiturnCredentialsCountRequest({
        filter: { where, include: newInclude },
      }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteOptiturnCredentialsRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: paths.OPTITURN_CREDENTIALS_UPDATE, search });
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={list}
          count={count}
          selectIndex="id"
          tableName={t('optiturn-credentials.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          include={defaultFilter.include}
          deleteModelName={ModelsToDelete.OptiturnCredentials}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function OptiturnCredentialsListRefreshable() {
  return (
    <OptiturnCredentialsList
      key={useSelector(getOptiturnCredentialsRefreshKey)}
    />
  );
}
