import { useTranslation } from 'react-i18next';

export const useLogtimeCreateFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      employee: {
        target: 'employee',
        content: t('guide.logTime.employee'),
      },
      login: {
        target: 'login',
        content: t('guide.logTime.loginTime'),
      },
      logout: {
        target: 'logout',
        content: t('guide.logTime.logoutTime'),
      },
      department: {
        target: 'department',
        content: t('guide.logTime.department'),
      },
      staffing: {
        target: 'staffing',
        content: t('guide.logTime.staffing'),
      },
      shift: {
        target: 'shift',
        content: t('guide.logTime.shift'),
      },
    },
  };
};

export const useLogtimeUpdateFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      department: {
        target: 'department',
        content: t('guide.logTime.department'),
      },
      shift: {
        target: 'shift',
        content: t('guide.logTime.shift'),
      },
      login: {
        target: 'login',
        content: t('guide.logTime.loginTime'),
      },
      logout: {
        target: 'logout',
        content: t('guide.logTime.logoutTime'),
      },
    },
  };
};
