import React from 'react';
import { Sheet } from '@mui/joy';

interface IFormFiledButtonsGroupItemContainerProps {
  children: React.ReactNode;
}

export const FormFiledButtonsGroupItemContainer = ({
  children,
}: IFormFiledButtonsGroupItemContainerProps) => {
  return (
    <Sheet sx={{ bgcolor: 'inherit', '&:not(:last-child)': { mb: 2 } }}>
      {children}
    </Sheet>
  );
};
