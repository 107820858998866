import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateOpenPosition,
  IdsArray,
  IFilter,
  IOpenPositionDeleteRequest,
  IOpenPositionModel,
  IUpdateOpenPosition,
  PayloadWithNavigateFunc,
} from '../../types';

// Creation actions
export const createOpenPositionRequest = createAction<
  PayloadWithNavigateFunc<ICreateOpenPosition>
>('Make request to create a new open position');
export const createOpenPositionSuccess = createAction<IOpenPositionModel>(
  'New open position has been created successfully',
);
export const createOpenPositionFailed = createAction(
  'Cannot create a new open position',
);

// delete actions
export const deleteOpenPositionRequest = createAction<
  IOpenPositionDeleteRequest
>('Make request to delete open positions');
export const deleteOpenPositionSuccess = createAction(
  'Open position has been deleted successfully',
);
export const deleteOpenPositionFailed = createAction(
  'Cannot delete open positions',
);

// Updating actions
export const updateOpenPositionsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateOpenPosition[]>
>('Make request to update open positions');
export const updateOpenPositionSuccess = createAction(
  'Open position has been updated successfully',
);
export const updateOpenPositionsFailed = createAction(
  'Cannot update open positions',
);

// Fetch open positions actions
export const getOpenPositionListRequest = createAction<IFilter>(
  'Make request to fetch open position list',
);
export const getOpenPositionListSuccess = createAction<IOpenPositionModel[]>(
  'OpenPositions list has been fetched successfully',
);
export const getOpenPositionListFailed = createAction(
  'Cannot fetch open position list',
);

export const getOpenPositionCountRequest = createAction<IFilter>(
  'Make request to get open position count',
);
export const getOpenPositionCountSuccess = createAction<ICountResponse>(
  'Open position count has been fetched successfully',
);
export const getOpenPositionCountFailed = createAction(
  'Cannot get open position count',
);

// Fetch combobox list actions
export const getOpenPositionComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of open positions',
);
export const getOpenPositionComboboxListSuccess = createAction<
  Partial<IOpenPositionModel>[]
>('Combobox list of open positions has been fetched successfully');
export const getOpenPositionComboboxListFailed = createAction(
  'Cannot fetch combobox open positions list',
);

// Close open position actions
export const closeOpenPositionRequest = createAction<IdsArray>(
  'Make request to close open position',
);
export const closeOpenPositionSuccess = createAction(
  'Open positions are closed successfully',
);
export const closeOpenPositionFailed = createAction(
  'Cannot close open positions',
);

// Flush actions
export const flushOpenPositionState = createAction(
  'Flush open position state data in store',
);
