import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { LineChart } from 'src/components/Charts/2d/LineChart';
import { fetchHRChangesActiveBySiteAndStaffingProvider } from 'src/modules/utils/Api/hrChanges';
import { getLastNDaysStarEndDates } from 'src/modules/utils/dateWrapper';
import {
  calculateTopNSites,
  generateSumByEntity,
  useActiveTopSitesOptions,
} from '../hrChanges.helpers';
import { SelectForChart } from 'src/components/SelectForChart';
import { OptionForChart } from 'src/components/SelectForChart/OptionForChart';

interface IHRChangesActiveBySiteAndStaffingProviderProps {
  siteId?: number;
}

export const HRChangesActiveBySiteAndStaffingProvider = ({
  siteId,
}: IHRChangesActiveBySiteAndStaffingProviderProps) => {
  const { t } = useTranslation();

  const activeTopSitesOptions = useActiveTopSitesOptions();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const [activeTopSites, setActiveTopSites] = React.useState<
    'top3' | 'top10' | 'all'
  >(activeTopSitesOptions[0].id);

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRChangesActiveBySiteAndStaffingProvider,
    {
      initialData: [],
    },
  );

  const last30Days = getLastNDaysStarEndDates(30);
  const last7Days = getLastNDaysStarEndDates(7);

  const sumByStaffingProviderArray = generateSumByEntity(
    data,
    'staffingProvider',
    'total',
  );

  const categories = React.useMemo(() => {
    const categoriesSet = new Set(data.map((item) => item.site));

    return [...categoriesSet];
  }, [data]);

  const series = React.useMemo(() => {
    const sitesWithZeroValues = categories.reduce((categoriesObj, category) => {
      categoriesObj[category] = 0;

      return categoriesObj;
    }, {});

    const dataObject = data.reduce<{
      [staffingProvider: string]: { [site: string]: number | null };
    }>((acc, item) => {
      if (!acc[item.staffingProvider]) {
        acc[item.staffingProvider] = { ...sitesWithZeroValues };
      }

      acc[item.staffingProvider][item.site] = item.total;

      return acc;
    }, {});

    let entriesToUse = Object.entries(dataObject);
    if (activeTopSites === 'top3') {
      const top3Sites = calculateTopNSites(sumByStaffingProviderArray, 3);

      entriesToUse = entriesToUse.filter((entry) =>
        top3Sites.includes(entry[0]),
      );
    }
    if (activeTopSites === 'top10') {
      const top10Sites = calculateTopNSites(sumByStaffingProviderArray, 10);

      entriesToUse = entriesToUse.filter((entry) =>
        top10Sites.includes(entry[0]),
      );
    }

    return entriesToUse.map(
      ([terminationReason, terminationReasonDataObj]) => ({
        name: terminationReason,
        type: 'line' as const,
        data: Object.values(terminationReasonDataObj),
        marker: {
          symbol: 'circle',
          radius: 2,
        },
      }),
    );
  }, [activeTopSites, categories, data, sumByStaffingProviderArray]);

  React.useEffect(() => {
    getData({ siteId }, ['site asc']);
  }, [
    getData,
    siteId,
    last30Days.endDateFormatted,
    last30Days.startDateFormatted,
    last7Days.endDateFormatted,
    last7Days.startDateFormatted,
  ]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('dashboard.active_by_site_and_staffing_provider')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('active_by_site_and_staffing_provider')}
          />

          <SelectForChart
            value={activeTopSites}
            onChange={(_, value) => {
              if (value) {
                setActiveTopSites(value);
              }
            }}
          >
            {activeTopSitesOptions.map((option) => (
              <OptionForChart key={option.id} value={option.id}>
                {option.label}
              </OptionForChart>
            ))}
          </SelectForChart>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <LineChart
          fullscreenTitle={t('dashboard.active_by_site_and_staffing_provider')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
