import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DeleteConfirmation } from 'src/components/DeleteConfirmation';
import { getDeleteRelations } from 'src/modules/selectors/deleteRelations';
import { IStoreState } from 'src/modules/types';
import { roles } from 'src/config';
import { useFetchDeleteRelations } from 'src/modules/utils';
import { getUserRoles } from 'src/modules/selectors/user';

interface ITableWithActionsDeleteModalProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  deleteModelName?: string;
}

export const TableWithActionsDeleteModal: React.FC<ITableWithActionsDeleteModalProps> = ({
  onOk,
  onCancel,
  deleteModelName,
  open,
}) => {
  const { t } = useTranslation();

  const userRoles = useSelector(getUserRoles);
  const deleteRelations = useSelector((state) =>
    getDeleteRelations(state as IStoreState)(deleteModelName || ''),
  );

  const fetchDeleteRelations = useFetchDeleteRelations(deleteModelName || '');

  React.useEffect(() => {
    if (
      !isEmpty(userRoles) &&
      userRoles!.includes(roles.SUPER_ADMIN) &&
      deleteModelName
    ) {
      fetchDeleteRelations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteMsg = React.useMemo(
    () =>
      deleteModelName &&
      deleteRelations &&
      !isEmpty(deleteRelations[deleteModelName])
        ? t('common.confirm_remove_with_details', {
            models: deleteRelations[deleteModelName].join(', '),
          })
        : t('common.confirm_remove'),
    [deleteModelName, deleteRelations, t],
  );

  return (
    <DeleteConfirmation open={open} onCancel={onCancel} onOk={onOk}>
      {deleteMsg}
    </DeleteConfirmation>
  );
};
