import React from 'react';
import {
  format,
  DATE_FORMAT,
  tryToGenerateDateFromAnything,
  DATE_FORMATS,
} from '../../../../../../../../modules/utils/dateWrapper';
import { ICommonFilterProps } from '../../types';
import { TableContext } from 'src/components/Table/TableContext';
import { FilterValue } from 'src/modules/types/table';
import Datepicker, {
  IDatePickerProps,
} from 'src/components/_ui-kit/Combobox/Pickers/Datepicker';

type IDatePickerFilterProps = Omit<
  IDatePickerProps,
  'value' | 'id' | 'onChange'
> &
  ICommonFilterProps & {
    label: string;
    name: string;
    onChange?: (date: Date | null, value: string | null) => void;
    value?: FilterValue;
    required?: boolean;
    fullWidth?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
    format?: DATE_FORMATS;
  };

export const DatePickerFilter: React.FC<IDatePickerFilterProps> = ({
  value,
  onChange,
  label,
  name,
  fullWidth = true,
  format: dateFormat = DATE_FORMAT,
  ...rest
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const dateString = getFilterByName(name) ?? value;

  const handleChange = (date: string) => {
    const value = date ? tryToGenerateDateFromAnything(date) : null;

    const parsedValue = value ? format(dateFormat)(value) : null;

    setFilter(name, parsedValue);

    onChange && onChange(value, parsedValue);
  };

  useInitFilterEffect(name, value);

  return (
    <Datepicker
      label={label}
      id={name}
      fullWidth={fullWidth}
      onChange={handleChange}
      value={dateString ? dateString.toString() : null}
      format={dateFormat}
      {...rest}
    />
  );
};
