import React from 'react';
import { useDispatch } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { createOptiturnCredentialsRequest } from '../../modules/actions';
import { useBrowserHistoryFunctions, useValidate } from '../../modules/utils';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';

import { useFormik } from 'formik';
import { createOptiturnCredentialsScheme } from '../../modules/schemes';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const initialValues = {
  // Required properties
  siteId: NaN,
  login: '',
  password: '',
};

const OptiturnCredentialsCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  // create dispatcher
  const dispatcher = useDispatch();

  const validate = useValidate(createOptiturnCredentialsScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createOptiturnCredentialsRequest({ data, navigate }));
    },
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('optiturn-credentials.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              id="login"
              label={t('optiturn-credentials.login')}
              name="login"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              id="password"
              label={t('optiturn-credentials.password')}
              name="password"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('optiturn-credentials.site')}
              placeholder={t('optiturn-credentials.site')}
            />
          </FormFieldContainer>

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default OptiturnCredentialsCreate;
