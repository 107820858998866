import { filter, isEmpty, map, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdatePoint } from '../types';

export const getPointRefreshKey = (state: IStoreState) =>
  state.point.refreshKey;

export const getPointData = (state: IStoreState) => state.point;

/**
 * Get server error
 * @param point - State point
 */
export const getServerError = ({ point }: IStoreState) => point.error;

/**
 * Get point list
 * @param point - State point
 */
export const getPointList = ({ point }: IStoreState) => point.list;
export const getPointReport = ({ point }: IStoreState) => point.pointsReport;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param point - State point
 */
export const getPointsTable = createSelector(getPointList, (points) =>
  filter(points, (s) => !isEmpty(s.employee) && !isEmpty(s.pointType)),
);

/**
 * Get points by array of ids
 * @param point - State point
 */
export const getPointsByIds = createSelector(
  getPointList,
  (points) => (ids: IdsArray) => {
    const initial: IUpdatePoint[] = [];
    return reduce(
      points,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          const pointData = omit(cur, ['pointType', 'employee', 'editUserId']);
          acc.push(pointData);
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get point count
 * @param point - State point
 */
export const getPointCount = ({ point }: IStoreState) => point.count;
export const getPointsReportCount = ({ point }: IStoreState) =>
  point.pointsReportCount;

/**
 * Get combobox list
 */
export const getPointsComboboxList = ({ point }: IStoreState) =>
  map(point.comboboxList, (pointItem) => ({
    id: pointItem.id,
    name: `${pointItem.employee!.firstName} ${pointItem.employee!.lastName}`,
  }));

export const getIsPointsDataLoading = createSelector(
  getPointData,
  ({ is_data_requested }) => is_data_requested,
);

export const getIsPointComboboxListRequestInProgress = createSelector(
  getPointData,
  ({ isPointComboboxListRequestInProgress }) =>
    isPointComboboxListRequestInProgress,
);

export const getIsPointsListRequestInProgress = createSelector(
  getPointData,
  ({ isPointsListRequestInProgress }) => isPointsListRequestInProgress,
);
