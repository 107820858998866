import React from 'react';
import { ByEmployeeDetailsHeader } from 'src/components/ReportPage/ByEmployeeDetailsReport/components/ByEmployeeDetailsHeader';
import { FilterActionsTriggers } from 'src/components/Table/components/TableActions/components/Filter/_components';
import EnhancedTableFilter, {
  ITableFilter,
} from 'src/components/EnhancedTable/EnhancedTableFilter';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import {
  ByEmployeeDetailsDailyChartReport,
  ByEmployeeDetailsTableReport,
} from 'src/components/ReportPage/ByEmployeeDetailsReport';
import { IHeadCell } from 'src/modules/types/table';
import { useTranslation } from 'react-i18next';
import {
  composeDate,
  DATE_FORMAT,
  format,
  sub,
} from 'src/modules/utils/dateWrapper';
import { AnyObject } from 'src/modules/types';

const reportUrl = '/expeditors/daily-uph/by-site-and-employee';

interface IDailyUphBySiteAndEmployeeDetailsProps {
  employeeName: string | null;
  headCells: Array<IHeadCell>;
  onBackClick: () => void;
  siteId?: number | null;
}

export const DailyUphBySiteAndEmployeeDetails = ({
  employeeName,
  onBackClick,
  headCells,
  siteId,
}: IDailyUphBySiteAndEmployeeDetailsProps) => {
  const { t } = useTranslation();

  const FOURTEEN_DAYS_AGO_DATE = composeDate(
    new Date(),
    sub({ days: 14 }),
    format(DATE_FORMAT),
  );

  const now = composeDate(new Date(), format(DATE_FORMAT));

  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);

  const [where, setWhere] = React.useState<{
    where: { date?: AnyObject; siteId: AnyObject; employee?: AnyObject };
  }>({
    where: {
      date: { between: [FOURTEEN_DAYS_AGO_DATE, now] },
      siteId: { inq: [siteId] },
      employee: employeeName ? { like: `%${employeeName}%` } : undefined,
    },
  });

  const filters: ITableFilter[] = [
    {
      name: 'date',
      label: t('productions_uph_reports.date'),
      type: 'daterange',
      operator: 'between',
    },
  ];

  React.useEffect(() => {
    setWhere({
      where: {
        date: { between: [FOURTEEN_DAYS_AGO_DATE, now] },
        siteId: { inq: [siteId] },
        employee: employeeName ? { like: `%${employeeName}%` } : undefined,
      },
    });
  }, [FOURTEEN_DAYS_AGO_DATE, employeeName, now, siteId]);

  if (!employeeName || !siteId) {
    return null;
  }

  return (
    <>
      <ByEmployeeDetailsHeader
        employeeName={employeeName}
        onBackClick={onBackClick}
      >
        <FilterActionsTriggers
          handleFilterIconClick={() => setIsFiltersOpen((prev) => !prev)}
        />

        <EnhancedTableFilter
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          filterProps={{
            filters,
            initialFilterData: where.where,
            onSubmit: (data) =>
              setWhere((prevWhere) => ({
                ...prevWhere,
                where: {
                  ...prevWhere.where,
                  ...data,
                },
              })),
            onFilterReset: () =>
              setWhere((prevWhere) => ({
                ...prevWhere,
                where: {
                  ...prevWhere.where,
                  date: undefined,
                },
              })),
          }}
        />
      </ByEmployeeDetailsHeader>
      <ReportPageItemContainer fullHeight={true}>
        <ByEmployeeDetailsDailyChartReport
          where={where}
          reportUrl={reportUrl}
        />
      </ReportPageItemContainer>

      <ReportPageItemContainer fullHeight={true}>
        <ByEmployeeDetailsTableReport
          reportUrl={reportUrl}
          headCells={headCells}
          where={where}
          tableUniqueName="expeditors_daily_uph_by_site_and_employee"
        />
      </ReportPageItemContainer>
    </>
  );
};
