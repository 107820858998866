import React from 'react';
import { useTranslation } from 'react-i18next';
import { WeekInvoicesList } from './panels/WeekInvoicesList';
import { MonthInvoicesList } from './panels/MonthInvoicesList';
import { tabsPaths, useHasUserAccessToViewTab } from '../../../config';
import { TabsNgroup } from '../../../components/TabsNgroup';

export const InvoiceList = () => {
  const { t } = useTranslation();

  const hasUserAccessToWeeklyInvoicesTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_WEEKLY_TAB,
  );
  const hasUserAccessToMonthlyInvoicesTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_MONTHLY_TAB,
  );

  const invoicesTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('invoice.week_table_name'),
          hasUserAccess: hasUserAccessToWeeklyInvoicesTab,
          component: <WeekInvoicesList />,
        },
        {
          title: t('invoice.month_table_name'),
          hasUserAccess: hasUserAccessToMonthlyInvoicesTab,
          component: <MonthInvoicesList />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [t, hasUserAccessToWeeklyInvoicesTab, hasUserAccessToMonthlyInvoicesTab],
  );

  return <TabsNgroup config={invoicesTabsConfig} />;
};
