import React from 'react';
import { Provider } from 'react-redux';
import store from './modules/store';
import { BrowserRouter } from 'react-router-dom';
import { i18n } from './modules/utils/i18n';
import {
  ProcessStatus,
  snackbarProviderProps,
} from './components/ProcessStatus';
import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from 'react-query/devtools';
import { initActionsDispatcher } from './modules/utils/helpers/actionsDispatcher';

// Module with global styles
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import NotificationProvider from './components/NotificationProvider';
import { theme } from './themes/mui';
import CssBaseline from '@mui/joy/CssBaseline';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider } from '@mui/joy/styles';

initActionsDispatcher(store);

const materialTheme = materialExtendTheme();

/**
 * Root App component
 */

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = ({ children }) => {
  // @@TODO: persist preferred theme in User table,

  const render = () => (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <NotificationProvider>
          <MaterialCssVarsProvider
            theme={{ [MATERIAL_THEME_ID]: materialTheme }}
          >
            <CssVarsProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider {...snackbarProviderProps}>
                <BrowserRouter>{children}</BrowserRouter>
                <ProcessStatus />
              </SnackbarProvider>
            </CssVarsProvider>
          </MaterialCssVarsProvider>
        </NotificationProvider>
      </Provider>
      <ReactQueryDevtools
        panelProps={{ style: { display: 'none' } }}
        toggleButtonProps={{ style: { display: 'none' } }}
        closeButtonProps={{ style: { display: 'none' } }}
        initialIsOpen={false}
      />
    </QueryClientProvider>
  );

  React.useEffect(() => {
    i18n.on('languageChanged', () => render());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return render();
};

export default App;
