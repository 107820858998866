import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import theme from '../../assets/css/theme';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

exporting(Highcharts);

interface IProps {
  categories: string[];
  series: SeriesOptionsType[];
  title?: string;
  subtitle?: string;
  tooltipSuffix?: string;
}

const HorizontalBarChart = (props: IProps) => {
  const [series, setSeries] = useState<SeriesOptionsType[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  // we need to keep data in the local state in order to to be able to update the chart when we fetch data from the server
  useEffect(() => {
    setSeries(props.series);
    setCategories(props.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.series, props.categories]);

  React.useEffect(() => {
    // TODO: chart has incorrect width on initial render, resize fixes it
    // find out how to fix it without hack below
    window.dispatchEvent(new Event('resize'));
  }, []);

  const options: Highcharts.Options = {
    colors: theme.chartColorScheme,
    chart: {
      type: 'bar',
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: props.title ?? undefined,
    },
    subtitle: {
      text: props.subtitle ?? undefined,
    },
    xAxis: {
      categories,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        align: 'high',
      },
      labels: {
        overflow: 'justify',
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: props.tooltipSuffix ?? '',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: series,
    exporting: {
      enabled: true,
    },
  };

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Box>
  );
};

export default HorizontalBarChart;
