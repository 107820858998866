import React from 'react';
import Prompt, { IPromptProps } from '../Prompt';
import ErrorSharp from '@mui/icons-material/ErrorSharp';
import { MUI_SIZE_1 } from '../../config';
import { useTranslation } from 'react-i18next';
import { useBrowserHistoryFunctions } from 'src/modules/utils';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { Box, Theme } from '@mui/material';

interface IConfirmPageLeave
  extends Partial<Pick<IPromptProps, 'message' | 'title'>> {
  isActive: boolean;
}
export const ConfirmPageLeave: React.FC<IConfirmPageLeave> = ({
  isActive,
  title,
  message,
}) => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { navigator } = React.useContext(UNSAFE_NavigationContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [locationWithSearch, setLocationWithSearch] = React.useState('');

  const unblockRef = React.useRef<() => void>();

  const onBeforeUnload = React.useCallback(
    (e: BeforeUnloadEvent) => {
      if (!isActive) {
        return;
      }

      e.preventDefault();
      return (e.returnValue = 'Changes you made may not be saved.');
    },
    [isActive],
  );

  const onOk = () => {
    unblockRef.current && unblockRef.current();

    pushToHistory(locationWithSearch);

    setIsOpen(false);
  };
  ConfirmPageLeave;

  const onCancel = () => {
    setIsOpen(false);
  };
  React.useLayoutEffect(() => {
    // we are blocking history changes by default
    // and unblocking it in case ConfirmPageLeave is not active
    // or user confirms page leave
    unblockRef.current = (navigator as any).block((transition: any) => {
      const autoUnblockingTx = {
        ...transition,
        retry() {
          unblockRef.current && unblockRef.current();
          transition.retry();
        },
      };

      if (!isActive) {
        autoUnblockingTx.retry();
        return;
      }

      setIsOpen(true);
      setLocationWithSearch(
        transition.location.pathname + transition.location.search,
      );

      return false;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [isActive, navigator]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);

  return (
    <Prompt
      open={isOpen}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      message={message ?? <DefaultMessage />}
    />
  );
};

function DefaultMessage() {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ErrorSharp
        sx={{
          color: (theme: Theme) => theme.palette.warning.main,
          marginRight: (theme: Theme) => theme.spacing(MUI_SIZE_1),
        }}
      />
      {t('common.modal.changes_you_made_will_not_be_saved')}
    </Box>
  );
}
