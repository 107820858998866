import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
} from '@mui/material';
import { reduce, has, get } from 'lodash';
import { AnyObject } from '../../modules/types';

export interface AccordionViewHeader {
  // can be in dot notation like a[0].b.c[1]
  // NOTE! id path must present in the model otherwise it won't be shown upon rendering
  id: string;
  label: string;
  // custom render for a field
  renderValue?: (
    model: AnyObject,
  ) => React.ReactElement | JSX.Element | string | number;
}

interface IProps {
  title: string;
  model: AnyObject;
  headers: AccordionViewHeader[];
}

const TableCellHead = styled(TableCell)(() => ({
  fontWeight: 'bold',
  verticalAlign: 'top',
}));

const AccordionView = (props: IProps) => {
  const { model, title, headers } = props;
  const rows = useMemo(
    () =>
      reduce(
        headers,
        (acc, header) => {
          if (has(model, header.id)) {
            acc.push(
              <TableRow hover={true} key={header.id}>
                <TableCellHead>{header.label}</TableCellHead>
                <TableCell>
                  {header.renderValue
                    ? header.renderValue(model)
                    : get(model, header.id)}
                </TableCell>
              </TableRow>,
            );
          }
          return acc;
        },
        [] as React.ReactElement[],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model, headers],
  );

  return (
    <TableContainer>
      <Table aria-label={title}>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccordionView;
