import React from 'react';
import { Option } from '@mui/joy';
import { IFilterPanelOption } from 'src/components/FilterPanel';
import { FilterPanelTextField } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelTextField';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { useReportsProps } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportPageContext } from 'src/components/ReportPage/ReportPageContext';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelSelect } from 'src/components/FilterPanel/FilterPanelFilters';
import { FilterPanelSelectOptionLabel } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSelect/FilterPanelSelectOptionLabel';
import { FilterPanelYearWeekRange } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanleYearWeekRange';
import { useTranslation } from 'react-i18next';

export interface IExecutivesReportsRequiredFilters {
  activeReportId: string;
  activeReportTypeId: string;
}

export interface IExecutivesReportsFilterPanelFilters {
  yearNumber?: number;
  weekNumber?: number;
  siteId?: number;
  yearWeekRange?: {
    from?: number;
    to?: number;
  };
}

interface IExecutivesReportsFilterPanelProps {
  onApplyFilters: (
    appliedFilters: IExecutivesReportsFilterPanelFilters,
  ) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const ExecutivesReportsFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IExecutivesReportsFilterPanelProps) => {
  const { t } = useTranslation();
  const reportProps = useReportsProps();

  const {
    setActiveReportId,
    setActiveReportTypeId,
    reportTypesList,
    reportsListByReportType,
  } = React.useContext(ReportPageContext);

  const [filters, setFilters] = React.useState<
    IExecutivesReportsFilterPanelFilters & IExecutivesReportsRequiredFilters
  >({
    activeReportId: reportProps.weeklyExecutiveGrossMargin.reportId,
    activeReportTypeId: reportProps.weeklyExecutiveGrossMargin.reportTypeId,
    yearNumber: undefined,
    yearWeekRange: {
      from: undefined,
      to: undefined,
    },
  });

  const reportsList = React.useMemo(() => {
    return filters.activeReportTypeId
      ? reportsListByReportType[filters.activeReportTypeId]
      : [];
  }, [filters.activeReportTypeId, reportsListByReportType]);

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<{
      requiredFilters: IExecutivesReportsRequiredFilters;
      otherFilters: IExecutivesReportsFilterPanelFilters;
    }>(
      (obj, filterId) => {
        if (
          filterId === 'activeReportId' ||
          filterId === 'activeReportTypeId'
        ) {
          obj.requiredFilters[filterId] = filters[filterId];
        } else {
          obj.otherFilters[filterId] = filters[filterId];
        }

        return obj;
      },
      {
        requiredFilters: {} as IExecutivesReportsRequiredFilters,
        otherFilters: {} as IExecutivesReportsFilterPanelFilters,
      },
    );

    setActiveReportId(appliedFiltersData.requiredFilters.activeReportId);
    setActiveReportTypeId(
      appliedFiltersData.requiredFilters.activeReportTypeId,
    );

    onApplyFilters(appliedFiltersData.otherFilters);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    if (filter.id === 'activeReportTypeId') {
      return (
        <FilterPanelSelect
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters.activeReportTypeId ?? ''}
          onChange={(_, value) => {
            if (value) {
              setFilters((prev) => ({
                ...prev,
                activeReportTypeId: value,
              }));
            }
          }}
        >
          {reportTypesList.map((item) => (
            <Option key={item.reportTypeId} value={item.reportTypeId}>
              <FilterPanelSelectOptionLabel>
                {item.reportTypeLabel}
              </FilterPanelSelectOptionLabel>
            </Option>
          ))}
        </FilterPanelSelect>
      );
    }

    if (filter.id === 'activeReportId') {
      return (
        <FilterPanelSelect
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters.activeReportId ?? ''}
          onChange={(_, value) => {
            if (value) {
              setFilters((prev: any) => ({
                ...prev,
                activeReportId: value,
              }));
            }
          }}
        >
          {reportsList.map((item) => (
            <Option key={item.reportId} value={item.reportId}>
              <FilterPanelSelectOptionLabel>
                {item.reportLabel}
              </FilterPanelSelectOptionLabel>
            </Option>
          ))}
        </FilterPanelSelect>
      );
    }

    if (filter.id === 'yearNumber' || filter.id === 'weekNumber') {
      return (
        <FilterPanelTextField
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters[filter.id] ?? ''}
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: e.target.value,
            }));
          }}
        />
      );
    }

    if (filter.id === 'siteId') {
      return (
        <FilterPanelSitesCombobox
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters[filter.id] ?? null}
          onChange={(_, value) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: value?.id,
            }));
          }}
        />
      );
    }

    if (filter.id === 'yearWeekRange') {
      return (
        <FilterPanelYearWeekRange
          filterId={filter.id}
          fromStartDecorator={`${t('common.from_year_week')}:`}
          toStartDecorator={`${t('common.to_year_week')}:`}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: { from: value?.from?.id, to: value?.to?.id },
            }));
          }}
        />
      );
    }

    return null;
  };

  React.useEffect(() => {
    setActiveReportId(filters.activeReportId);
    setActiveReportTypeId(filters.activeReportTypeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReportPageFilterPanel
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
