import React from 'react';
import { MainLayoutContextProvider } from './MainLayoutContext';
import { MainLayoutComponent } from './MainLayoutComponent';

interface IMainLayoutProps {
  children: React.ReactNode;
}

export default function MainLayout({ children }: IMainLayoutProps) {
  return (
    <MainLayoutContextProvider>
      <MainLayoutComponent>{children}</MainLayoutComponent>
    </MainLayoutContextProvider>
  );
}
