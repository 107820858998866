import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import * as _ from 'lodash';
import { devModeLogger } from '../helpers/common';

type RequestConfigOrNone = AxiosRequestConfig | undefined | null | false | void;

export type IRequestConfig = AxiosRequestConfig;

interface IHTTPClientConfig extends AxiosRequestConfig {
  readonly baseURL: string;
  readonly withCredentials?: boolean;
  readonly paramsSerializer?: (params?: Record<string, unknown>) => string;

  /**
   * Called before every request.
   * This hook could be useful if you want perform additional work before request,
   * or override request config.
   */
  readonly onBeforeRequest?: (
    this: HTTPClient,
    requestConfig: AxiosRequestConfig,
  ) => RequestConfigOrNone | Promise<RequestConfigOrNone> | void;

  /**
   * Called after every request.
   * This hook could be useful if you want perform additional work after request,
   * or override pure server response.
   */
  readonly onAfterRequest?: (
    this: HTTPClient,
    response: AxiosResponse,
  ) => AxiosResponse | Promise<AxiosResponse> | void;

  /**
   * Called after onAfterRequest hook.
   * This hook could be useful if you want
   * extract server response in some, specific way.
   */
  readonly transformResponse?: (
    this: HTTPClient,
    response: AxiosResponse,
  ) => Record<string, unknown> | void;
  /**
   * Called on network error.
   * This hook could be useful if you want
   * to catch and do smth with network error object.
   */
  readonly onCatchNetworkError?: (
    response: AxiosError,
  ) => Promise<Record<string, unknown> | void>;
}

/**
 * HTTP Client created as wrapper of axios library.
 * Can be used to performs post/get/put/delete http methods.
 * It has few hooks:
 * `onBeforeRequest`
 * `onAfterRequest`
 * `transformResponse`
 * `onCatchNetworkError`
 * that would be called on every request.
 * This hooks should be passed in constructor.
 * All of the hooks is optional.
 */
export class HTTPClient {
  private static getNormalizedNetworkError(networkError: AxiosError) {
    // response can be undefined on broken connection
    const { message, response } = networkError;

    return {
      code: response?.status,
      message: (response as any)?.data?.message || message,
      statusText: response?.statusText,
    };
  }

  private readonly config: IHTTPClientConfig;
  private readonly HttpClient: AxiosInstance;

  public get interceptors() {
    return this.HttpClient.interceptors;
  }

  constructor(config: IHTTPClientConfig) {
    this.config = config;

    _.bindAll(this, [
      'onBeforeRequest',
      'onAfterRequest',
      'transformResponse',
      'onCatchNetworkError',
    ]);

    this.HttpClient = axios.create(this.config);
  }

  /**
   * Override default config for current instance.
   */
  public extendDefaults(config: AxiosRequestConfig) {
    _.merge(this.HttpClient.defaults, config);

    return this;
  }

  /**
   * Performs pure request without calling any hooks.
   */
  public request(requestConfig: AxiosRequestConfig): Promise<any> {
    return this.HttpClient.request(requestConfig);
  }

  /**
   * Performs `get` http method with call of all existing hooks.
   */
  public get<T>(url: string, params?: T, requestConfig?: AxiosRequestConfig) {
    // Uncomment console below to setup page view configuration
    devModeLogger(`%cGET URL => ${url}`, 'color: green; font-weight: bold;');
    return this.makeRequest({ params, url, ...requestConfig });
  }

  public async getFile(requestConfig?: AxiosRequestConfig) {
    return this.request({
      ...requestConfig,
      responseType: 'blob',
    })
      .then(({ data, headers }) => {
        return {
          content: data,
          fileName: headers['x-file-name'],
        };
      })
      .catch(this.onCatchNetworkError);
  }

  /**
   * Performs `delete` http method with call of all existing hooks.
   */
  public delete<T>(
    url: string,
    params?: T,
    requestConfig?: AxiosRequestConfig,
  ) {
    devModeLogger(`%cDELETE URL => ${url}`, 'color: red; font-weight: bold;');

    return this.makeRequest({
      method: 'delete',
      params,
      url,
      ...requestConfig,
    });
  }

  /**
   * Performs `post` http method with call of all existing hooks.
   */
  public post<T>(url: string, params?: T, requestConfig?: AxiosRequestConfig) {
    devModeLogger(`%cPOST URL => ${url}`, 'color: green; font-weight: bold;');

    return this.makeRequest({
      data: params,
      method: 'post',
      url,
      ...requestConfig,
    });
  }

  /**
   * Performs `patch` http method with call of all existing hooks.
   */
  public patch<T>(url: string, params?: T, requestConfig?: AxiosRequestConfig) {
    devModeLogger(`%cPATCH URL => ${url}`, 'color: green; font-weight: bold;');

    return this.makeRequest({
      data: params,
      method: 'patch',
      url,
      ...requestConfig,
    });
  }

  /**
   * Performs `put` http method with call of all existing hooks.
   */
  public put<T>(url: string, params?: T, requestConfig?: AxiosRequestConfig) {
    devModeLogger(`%cPUT URL => ${url}`, 'color: green; font-weight: bold;');

    return this.makeRequest({
      data: params,
      method: 'put',
      url,
      ...requestConfig,
    });
  }

  /**
   * Performs request with call of all existing hooks.
   */
  public async makeRequest(requestConfig: AxiosRequestConfig): Promise<any> {
    const config = await this.onBeforeRequest(requestConfig);

    return this.HttpClient.request({
      ...requestConfig,
      ...config,
    })
      .then(this.onAfterRequest)
      .then(this.transformResponse)
      .catch(this.onCatchNetworkError);
  }

  private async onBeforeRequest(
    requestConfig: AxiosRequestConfig,
  ): Promise<RequestConfigOrNone> {
    const { onBeforeRequest } = this.config;

    if (_.isFunction(onBeforeRequest)) {
      return onBeforeRequest.call(this, requestConfig);
    }
  }

  private async onAfterRequest(
    response: AxiosResponse,
  ): Promise<AxiosResponse> {
    const { onAfterRequest } = this.config;

    if (_.isFunction(onAfterRequest)) {
      return onAfterRequest.call(this, response) || response;
    }

    return response;
  }

  private async transformResponse(
    response: AxiosResponse,
  ): Promise<Record<string, unknown>> {
    const { transformResponse } = this.config;

    if (_.isFunction(transformResponse)) {
      return transformResponse.call(this, response) || response.data;
    }

    return response.data;
  }

  private async onCatchNetworkError(
    networkError: AxiosError,
  ): Promise<Record<string, unknown> | void> {
    const error = HTTPClient.getNormalizedNetworkError(networkError);
    const { onCatchNetworkError } = this.config;

    if (_.isFunction(onCatchNetworkError)) {
      return onCatchNetworkError.call(this, networkError) || error;
    }

    return error;
  }
}

export default HTTPClient;
