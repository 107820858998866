import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreatePointCollectionRequest,
  IdsArray,
  IFilter,
  IPointCollectionModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new pointCollection
 */
export const createPointCollectionRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<
  PayloadWithFilters<ICreatePointCollectionRequest>
>): SagaIterator {
  try {
    const response: IPointCollectionModel = yield call(
      Api.PointCollection.create,
      data,
    );

    yield put(actions.getPointCollectionListRequest({ filter: list }));

    yield put(actions.getPointCollectionCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createPointCollectionSuccess(response));
  } catch (e) {
    yield put(actions.createPointCollectionFailed());
  }
};

/**
 * Get pointCollection list
 */
export const getPointCollectionListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IPointCollectionModel[] = yield call(
      Api.PointCollection.list,
      action.payload,
    );
    yield put(actions.getPointCollectionListSuccess(response));
  } catch (e) {
    yield put(actions.getPointCollectionListFailed());
  }
};

/**
 * Get pointCollection count
 */
export const getPointCollectionCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.PointCollection.count,
      action?.payload,
    );
    yield put(actions.getPointCollectionCountSuccess(response));
  } catch (e) {
    yield put(actions.getPointCollectionCountFailed());
  }
};

/**
 * Delete pointCollections
 */
export const deletePointCollectionRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.PointCollection.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getPointCollectionListRequest({ filter: list }));

    yield put(actions.getPointCollectionCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deletePointCollectionSuccess());
  } catch (e) {
    yield put(actions.deletePointCollectionFailed());
  }
};

/**
 * Bulk update pointCollections
 */
export const updatePointCollectionsRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IPointCollectionModel[]>>): SagaIterator {
  try {
    yield call(Api.PointCollection.bulkUpdate, data);

    yield put(actions.getPointCollectionListRequest({ filter: list }));

    yield put(actions.getPointCollectionCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updatePointCollectionsSuccess());
  } catch (e) {
    yield put(actions.updatePointCollectionsFailed());
  }
};

/**
 * Get combobox list
 */
export const getPointCollectionComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IPointCollectionModel>[] = yield call(
      Api.PointCollection.list,
      action.payload,
    );
    yield put(actions.getPointCollectionComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getPointCollectionComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.PointCollection.export,
  actionFailed: actions.exportPointCollectionFailed,
  actionSuccess: actions.exportPointCollectionSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportPointCollectionRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createPointCollectionRequest,
    saga: createPointCollectionRequestSaga,
  },
  {
    action: actions.getPointCollectionListRequest,
    saga: getPointCollectionListRequestSaga,
  },
  {
    action: actions.getPointCollectionCountRequest,
    saga: getPointCollectionCountRequestSaga,
  },
  {
    action: actions.deletePointCollectionRequest,
    saga: deletePointCollectionRequestSaga,
  },
  // bulk updating pointCollections
  {
    action: actions.updatePointCollectionsRequest,
    saga: updatePointCollectionsRequestSaga,
  },
  {
    action: actions.getPointCollectionComboboxListRequest,
    saga: getPointCollectionComboboxListRequestSaga,
  },
  {
    action: actions.exportPointCollectionRequest,
    saga: exportPointCollectionRequestSaga,
  },
];
