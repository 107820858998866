import React from 'react';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { fetchHRDashboardTerminationReasonsBySiteAndShift } from 'src/modules/utils/Api/hrDashboard';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { HorizontalBarChart } from 'src/components/Charts/2d/HorizontalBarChart';

interface IHRDashboardTerminationReasonProps {
  siteId?: number;
  shiftId?: number;
}

export const HRDashboardTerminationReason = ({
  siteId,
  shiftId,
}: IHRDashboardTerminationReasonProps) => {
  const { t } = useTranslation();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const {
    data: reasonsBySite,
    getData: getReasonsBySite,
    isLoading: isReasonsBySiteLoading,
  } = useGetDataForCharts(fetchHRDashboardTerminationReasonsBySiteAndShift, {
    initialData: [],
  });

  const categories = React.useMemo(() => {
    const reasonsSet = new Set(
      reasonsBySite.map((reason) => reason.terminationReason),
    );
    return [...reasonsSet];
  }, [reasonsBySite]);

  const series = React.useMemo(() => {
    let data = reasonsBySite
      .filter((reason) => reason.shiftId === shiftId)
      .map((reason) => reason.employeesCount);

    // If shiftId filter not provided
    // we sum up employees quantity by site and termination reason
    if (!shiftId) {
      const totalCountBySiteData = reasonsBySite.reduce<
        Array<{ siteId: number; count: number; terminationReason: string }>
      >((all, reasonDetails) => {
        const existedDataIndex = all.findIndex(
          (item) =>
            item.siteId === reasonDetails.siteId &&
            item.terminationReason === reasonDetails.terminationReason,
        );

        if (existedDataIndex !== -1) {
          all[existedDataIndex].count =
            all[existedDataIndex].count + reasonDetails.employeesCount;
        } else {
          all.push({
            siteId: reasonDetails.siteId,
            count: reasonDetails.employeesCount,
            terminationReason: reasonDetails.terminationReason,
          });
        }

        return all;
      }, []);

      data = totalCountBySiteData.map((item) => item.count);
    }

    return [
      {
        data,
        name: t('dashboard.employees_count'),
        type: 'bar' as const,
      },
    ];
  }, [reasonsBySite, shiftId, t]);

  React.useEffect(() => {
    getReasonsBySite({ siteId, shiftId });
  }, [getReasonsBySite, shiftId, siteId]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('dashboard.termination_reasons')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`termination_reasons`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isReasonsBySiteLoading}
        showNoData={!reasonsBySite.length}
      >
        <HorizontalBarChart
          fullscreenTitle={t('dashboard.termination_reasons')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
          legend={{ enabled: false }}
          yAxis={{
            allowDecimals: false,
            title: {
              text: '',
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
