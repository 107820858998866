import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreatePoint,
  IdsArray,
  IFilter,
  IPointModel,
  IUpdatePoint,
  IPointReport,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushPointState = createAction('Flush point data in store');

// Creation actions
export const createPointRequest = createAction<
  PayloadWithFilters<ICreatePoint>
>('Make request to create a new point');
export const createPointSuccess = createAction<IPointModel>(
  'New point has been created successfully',
);
export const createPointFailed = createAction('Cannot create a new point');

// Fetch points actions
export const getPointListRequest = createAction<IFilter>(
  'Make request to fetch point list',
);
export const getPointListSuccess = createAction<IPointModel[]>(
  'Points list has been fetched successfully',
);
export const getPointListFailed = createAction('Cannot fetch point list');

// get points count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPointCountRequest = createAction<IFilter>(
  'Make request to get point count',
);
export const getPointCountSuccess = createAction<ICountResponse>(
  'Points count has been fetched successfully',
);
export const getPointCountFailed = createAction('Cannot get point count');

// delete actions
export const deletePointRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete points',
);
export const deletePointFailed = createAction('Cannot delete points');

// Updating actions
export const updatePointsRequest = createAction<
  PayloadWithFilters<IUpdatePoint[]>
>('Make request to update points');
export const updatePointsFailed = createAction('Cannot update points');

export const removePointsRequest = createAction<
  PayloadWithFilters<Array<FormData>>
>('Make request to remove points');
export const removePointsFailed = createAction('Cannot remove points');

// Fetch combobox list actions
export const getPointComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of points',
);
export const getPointComboboxListSuccess = createAction<Partial<IPointModel>[]>(
  'Combobox list of points has been fetched successfully',
);
export const getPointComboboxListFailed = createAction(
  'Cannot fetch combobox points list',
);

// Points report actions
export const getPointsReportRequest = createAction<IFilter>(
  'Make request to fetch points report',
);
export const getPointsReportSuccess = createAction<IPointReport[]>(
  'Points report has been fetched successfully',
);
export const getPointsReportFailed = createAction('Cannot fetch point report');
export const getPointsReportCountRequest = createAction<IFilter>(
  'Make request to get points report count',
);
export const getPointsReportCountSuccess = createAction<ICountResponse>(
  'Points report count has been fetched successfully',
);
export const getPointsReportCountFailed = createAction(
  'Cannot get points report count',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Point').takeExportActions();

export const exportPointRequest = exportReportRequest;
export const exportPointSuccess = exportReportSuccess;
export const exportPointFailed = exportReportFailed;

export const {
  exportReportRequest: exportPointsReportRequest,
  exportReportSuccess: exportPointsReportSuccess,
  exportReportFailed: exportPointsReportFailed,
} = new ExportActionCreator('Points Report').takeExportActions();
