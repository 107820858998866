import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { ISagaAction, ISagaActionBind } from '../../types';

/**
 * Get delete relations
 */
export const getDeleteRelationsRequestSaga = function* (
  action: ISagaAction<string>,
): SagaIterator {
  try {
    const response: string[] = yield call(
      Api.SoftDelete.getRelations,
      action.payload,
    );
    yield put(
      actions.getDeleteRelationsSuccess({
        modelName: action.payload,
        relations: response,
      }),
    );
  } catch (e) {
    yield put(actions.getDeleteRelationsFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getDeleteRelationsRequest,
    saga: getDeleteRelationsRequestSaga,
  },
];
