import React from 'react';
import { get } from 'lodash';
import { AnyObject } from 'src/modules/types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'src/components/_ui-kit/ButtonGroup';

interface IQuestionnaireRadioButtonProps {
  id: string;
  label: string;
  formik: AnyObject;
}

export const questionnaireRadioButtonValues = {
  no: 'false',
  yes: 'true',
  notApplicable: 'Not Applicable',
};

export const QuestionnaireRadioButton = ({
  formik,
  id,
  label,
}: IQuestionnaireRadioButtonProps) => {
  const { t } = useTranslation();

  const buttonsConfig = React.useMemo(() => {
    return [
      { value: 'false' as const, label: t('common.no') },
      { value: 'true' as const, label: t('common.yes') },
      { value: 'Not Applicable' as const, label: t('common.not_applicable') },
    ];
  }, [t]);

  const value = get(formik.values, id) ?? null;

  return (
    <ButtonGroup
      label={label}
      buttons={buttonsConfig}
      activeButtonValue={value}
      onButtonClick={(value) => formik.setFieldValue(id, value)}
    />
  );
};
