import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { clients } from 'src/modules/constants';
import { MUI_SIZE_12, paths } from 'src/config';
import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
  PulseSummary,
} from 'src/modules/types';
import { isSiteByClientBySelected } from 'src/modules/selectors/site';
import { Grid } from '@mui/material';
import PulseDetails from './PulseDetails';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ModalDrillDown } from 'src/components/_ui-kit/ModalDrillDown';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { Alert } from '@mui/joy';

interface IProps {
  siteId?: number;
  categoryName?: string;
}

export const OptoroPulseSummary: React.FC<IProps> = ({
  siteId,
  categoryName,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'timestamp',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      categoryName,
    }),
    [siteId, categoryName],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(paths.PULSE_SUMMARY, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${paths.PULSE_SUMMARY}/count`, {
    count: 0,
  });
  const computedItems = React.useMemo(
    () =>
      data.items.map((item) => ({
        ...item,
        timestamp: composeDate(
          item.timestamp,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
      })),
    [data.items],
  );

  const isSiteSelected = useSelector(
    isSiteByClientBySelected,
    shallowEqual,
  )(clients.optoro);

  const showData = isSiteSelected && siteId;

  const [selectedRow, setSelectedRow] = useState<PulseSummary>();
  const [weekModal, setWeekModal] = useState<boolean>(false);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('pulse_summary.siteName'),
      },
      {
        id: 'categoryName',
        orderConfig: { orderBy: 'categoryName' },
        label: t('pulse_summary.categoryName'),
      },
      {
        id: 'timestamp',
        orderConfig: { orderBy: 'timestamp' },
        label: t('pulse_summary.timestamp'),
      },
      {
        id: 'diff',
        orderConfig: { orderBy: 'diff' },
        label: t('pulse_summary.diff'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const handleTableRowClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: PulseSummary,
  ) => {
    setSelectedRow(row);
    setWeekModal(true);
  };

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <>
      {!showData && (
        <Grid container={true}>
          <Grid item={true} xs={MUI_SIZE_12}>
            <Alert variant="soft">
              {t('reports.select_global_site_by_client')}
            </Alert>
          </Grid>
        </Grid>
      )}
      {showData && (
        <>
          <ModalDrillDown
            title={t('pulse_detailed.modal_title', {
              category: selectedRow?.categoryName,
              timestamp: selectedRow?.timestamp,
            })}
            open={weekModal}
            onClose={() => setWeekModal(false)}
          >
            <PulseDetails summaryRow={selectedRow} />
          </ModalDrillDown>
          <ReportTable
            data={computedItems as any}
            count={countData.count}
            summaryData={data.summary}
            isDataLoading={isDataLoading || isCountDataLoading}
            tableUniqueKey={paths.PULSE_SUMMARY}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onTableRowClick={handleTableRowClick}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        </>
      )}
    </>
  );
};
