import { IProductionDailyUphBySiteModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionDailyUphBySite',
);

// get list actions
export const {
  getListRequest: getProductionDailyUphBySiteListRequest,
  getListSuccess: getProductionDailyUphBySiteListSuccess,
  getListFailed: getProductionDailyUphBySiteListFailed,
} = actionCreator.takeListActions<IProductionDailyUphBySiteModel>();

// get count actions
export const {
  getCountRequest: getProductionDailyUphBySiteCountRequest,
  getCountSuccess: getProductionDailyUphBySiteCountSuccess,
  getCountFailed: getProductionDailyUphBySiteCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionDailyUphBySiteRequest,
  exportReportSuccess: exportProductionDailyUphBySiteSuccess,
  exportReportFailed: exportProductionDailyUphBySiteFailed,
} = new ExportActionCreator('ProductionDailyUphBySite').takeExportActions();
