import { IdsArray, IStoreState, IUpdatePolicy } from '../types';
import { createSelector } from 'reselect';
import { reduce, pick } from 'lodash';

export const getPolicyRefreshKey = (state: IStoreState) =>
  state.policy.refreshKey;

export const getPolicyData = (state: IStoreState) => state.policy;

/**
 * Get server error
 * @param policy - State policy
 */
export const getServerError = ({ policy }: IStoreState) => policy.error;

/**
 * Get policy list
 * @param policy - State policy
 */
export const getPolicyList = ({ policy }: IStoreState) => policy.list;

/**
 * Get policies by array of ids
 * @param policy - State policy
 */
export const getPoliciesByIds = createSelector(
  getPolicyList,
  (policies) => (ids: IdsArray) =>
    reduce(
      policies,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push(pick(cur, ['id', 'v0', 'v1', 'v2', 'v3']));
        }
        return acc;
      },
      [] as IUpdatePolicy[],
    ),
);

/**
 * Get policy count
 * @param policy - State policy
 */
export const getPolicyCount = ({ policy }: IStoreState) => policy.count;

export const getIsPoliciesCreateByConfigInProgress = createSelector(
  getPolicyData,
  (data) => data.isUpdatePoliciesCreateInProgress,
);
