import React from 'react';
import { ICommonFilterProps } from '../../types';
import { TableContext } from '../../../../../../TableContext';
import { Checkbox } from 'src/components/_ui-kit/Checkbox';
import { CheckboxProps } from '@mui/joy';

type ICheckboxFilterProps = Omit<CheckboxProps, 'value' | 'onChange'> &
  ICommonFilterProps;

export const CheckboxFilter: React.FC<ICheckboxFilterProps> = ({
  name,
  value,
  label,
  ...textFiledProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const checkboxFilterValue = getFilterByName(name) ?? (value as any) ?? '';

  const handleChange = (e: any) => {
    const { checked: currentValue } = e.target;

    setFilter(name, currentValue);
  };

  useInitFilterEffect(name, value);

  return (
    <Checkbox
      name={name}
      label={label as string}
      value={checkboxFilterValue}
      onChange={handleChange}
      sx={{ pr: '12px' }}
      formControlProps={{
        sx: {
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'flex-end',
          flexDirection: 'row-reverse',
        },
      }}
      {...textFiledProps}
    />
  );
};
