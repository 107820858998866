import React from 'react';
import {
  IUseEmployeesInComboBox,
  useEmployeesInComboBox,
} from 'src/modules/utils';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { TableContext } from 'src/components/Table/TableContext';
import { ICommonFilterProps } from '../../types';

interface IEmployeesComboBoxFilterProps
  extends Omit<IComboboxProps, 'value' | 'options' | 'name'>,
    ICommonFilterProps,
    IUseEmployeesInComboBox {}

export const EmployeesComboBoxFilter: React.FC<IEmployeesComboBoxFilterProps> = ({
  value,
  name,
  onlyActive,
  propertyAsID,
  ...restComboBoxProps
}) => {
  const employeesOptions = useEmployeesInComboBox({ onlyActive, propertyAsID });

  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue =
    employeesOptions.find((option) => option.id === Number(valueId)) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={employeesOptions}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
