import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/joy';
import { HeadCell } from '../../components/EnhancedTable';
import { ScorecardReport } from 'src/components/Reports/ScorecardReport';
import {
  useAddReportTableDataToReportExportStateEffect,
  useReportExportProps,
} from 'src/modules/utils/hooks/reports.hooks';
import { useScorecardReportDataEffect } from 'src/modules/utils/hooks/scorecard.hooks';
import { clients } from '../../modules/constants';
import { TableContext } from '../../components/Table/TableContext';
import { get } from 'lodash';

const mcEntireScorecardBaseUrl = '/mc-entire/scorecard';

interface IProps {
  siteId?: number;
  year?: number;
  week?: number;
}

export const McEntireScorecard: React.FC<IProps> = ({ siteId, year, week }) => {
  const { t } = useTranslation();

  const {
    scorecardData,
    headCells,
    filterAsWhere,
    headCellsForExport,
    isRequestInProgress,
  } = useScorecardReportDataEffect(
    mcEntireScorecardBaseUrl,
    siteId,
    year,
    week,
  );

  const exportProps = useReportExportProps(mcEntireScorecardBaseUrl);

  const composeExportFilters = {
    where: filterAsWhere,
    order: 'asc' as const,
    orderBy: 'id',
    headCells: headCellsForExport as HeadCell[],
    page: 0,
    rowsPerPage: 0,
  };

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit: composeExportFilters.rowsPerPage,
        where: composeExportFilters.where,
        offset: composeExportFilters.page,
        order: [
          `${composeExportFilters.orderBy} ${composeExportFilters.order}`,
        ],
      }),
      [
        composeExportFilters.order,
        composeExportFilters.orderBy,
        composeExportFilters.page,
        composeExportFilters.rowsPerPage,
        composeExportFilters.where,
      ],
    ),
    localization: React.useMemo(
      () =>
        scorecardData.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [scorecardData, t],
    ),
  });

  const { setHeadCells } = React.useContext(TableContext);

  React.useEffect(
    () =>
      setHeadCells(
        headCells.map((hc) => ({
          ...hc,
          label: (() => {
            switch (hc.id) {
              case 'rowName':
                return ' ';
              case 'weekTotal':
                return 'Week';
              default:
                return get(hc, ['label', 'props', 'date'], '');
            }
          })(),
        })),
      ),
    [headCells, setHeadCells],
  );

  return (
    <>
      {(!siteId || !week || !year) && (
        <Alert variant="soft">
          {t('mc-entire_reports.select_filters_message')}
        </Alert>
      )}
      {siteId && week && year && (
        <ScorecardReport
          isDataLoading={isRequestInProgress}
          clientKey={clients.mcEntire}
          tableName={t('mc-entire_reports.table_names.scorecard')}
          data={scorecardData}
          headCells={headCells}
          siteId={siteId}
          week={week}
          year={year}
          exportProps={{
            props: exportProps,
            filters: composeExportFilters,
          }}
        />
      )}
    </>
  );
};
