import { JSONSchemaType } from 'ajv';
import { IExportDailyPunches } from '../../types';
import { i18n } from '../../utils/i18n';

export const sendDailyPunchesScheme: JSONSchemaType<IExportDailyPunches> = {
  type: 'object',
  required: ['siteId', 'date'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    date: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      format: 'date',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
