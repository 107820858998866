import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { FinancialDashboardTotalsChartWeekly } from './FinancialDashboardTotalsChartWeekly';
import { FinancialDashboardTotalsChartMonthly } from './FinancialDashboardTotalsChartMonthly';
import { FinancialDashboardTotalsChartYearly } from './FinancialDashboardTotalsChartYearly';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import {
  generateMonthYearList,
  generateYearWeekList,
} from 'src/modules/utils/dateWrapper';
import { Period } from 'src/modules/types';
import { format, getISOWeek, subWeeks, subYears } from 'date-fns';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import {
  fetchProjectionVsActualTotalsMonthlyChartData,
  fetchProjectionVsActualTotalsWeeklyChartData,
  fetchProjectionVsActualTotalsYearlyChartData,
} from 'src/modules/utils/Api/projectionVsActualChartsDashboardDataRequests';
import { useTranslation } from 'react-i18next';
import { useChartRequiredUtils } from 'src/components/Charts/common';

interface IPeriodOption {
  id: Period;
  label: string;
}

interface IYearOption {
  id: number;
  label: string;
}

interface IWeekOption {
  week: number;
  year: number;
  label: string;
}

interface IMonthOption {
  month: number;
  year: number;
  label: string;
}

const monthsOptions = generateMonthYearList();
const weeksOptions = generateYearWeekList({
  tillWeek: getISOWeek(subWeeks(new Date(), 1)),
});
const periodOptions: Array<IPeriodOption> = [
  { label: 'Weekly', id: 'weekly' },
  { label: 'Monthly', id: 'monthly' },
  { label: 'Yearly', id: 'yearly' },
];
const yearsOptions: Array<IYearOption> = new Array(6)
  .fill(null)
  .map((_, i) => ({
    label: format(subYears(new Date(), i), 'yyyy'),
    id: Number(format(subYears(new Date(), i), 'yyyy')),
  }));

const initialData = {
  weekly: [],
  monthly: [],
  yearly: [],
};

export const FinancialDashboardTotalsChart = () => {
  const { t } = useTranslation();

  const [period, setPeriod] = React.useState<IPeriodOption>(periodOptions[0]);

  const [year, setYear] = React.useState<IYearOption | undefined>(undefined);
  const [month, setMonth] = React.useState<IMonthOption | undefined>(undefined);
  const [week, setWeek] = React.useState<IWeekOption | undefined>(
    weeksOptions[0],
  );

  const {
    chartRef: weeklyChartRef,
    chartHeight: weeklyChartHeight,
    exportChart: exportWeeklyChart,
    toggleFullScreen: weeklyToggleFullScreen,
  } = useChartRequiredUtils();

  const {
    chartRef: monthlyChartRef,
    chartHeight: monthlyChartHeight,
    exportChart: exportMonthlyChart,
    toggleFullScreen: monthlyToggleFullScreen,
  } = useChartRequiredUtils();

  const {
    chartRef: yearlyChartRef,
    chartHeight: yearlyChartHeight,
    exportChart: exportYearlyChart,
    toggleFullScreen: yearlyToggleFullScreen,
  } = useChartRequiredUtils();

  const {
    data: weeklyData,
    getData: getWeeklyData,
    setData: setWeeklyData,
    isLoading: isWeeklyLoading,
  } = useGetDataForCharts(fetchProjectionVsActualTotalsWeeklyChartData, {
    initialData: initialData.weekly,
  });

  const {
    data: monthlyData,
    getData: getMonthlyData,
    setData: setMonthlyData,
    isLoading: isMonthlyLoading,
  } = useGetDataForCharts(fetchProjectionVsActualTotalsMonthlyChartData, {
    initialData: initialData.monthly,
  });

  const {
    data: yearlyData,
    getData: getYearlyData,
    setData: setYearlyData,
    isLoading: isYearlyLoading,
  } = useGetDataForCharts(fetchProjectionVsActualTotalsYearlyChartData, {
    initialData: initialData.yearly,
  });

  const showNoData = React.useMemo(() => {
    if (period.id === 'monthly') {
      return !monthlyData.length;
    }
    if (period.id === 'yearly') {
      return !yearlyData.length;
    }
    if (period.id === 'weekly') {
      return !weeklyData.length;
    }

    return false;
  }, [monthlyData.length, period.id, weeklyData.length, yearlyData.length]);

  const onPeriodChange = (_: any, value: IPeriodOption) => {
    setPeriod(value);

    setMonth(undefined);
    setWeek(undefined);
    setYear(undefined);

    setWeeklyData(initialData.weekly);
    setMonthlyData(initialData.monthly);
    setYearlyData(initialData.yearly);
  };

  const onWeekChange = (_: any, value: IWeekOption) => {
    setWeek(value);
  };
  const onMonthChange = (_: any, value: IMonthOption) => {
    setMonth(value);
  };
  const onYearChange = (_: any, value: IYearOption) => {
    setYear(value);
  };

  React.useEffect(() => {
    if (week) {
      getWeeklyData({ week: week.week, year: week.year });
    }
  }, [getWeeklyData, week]);

  React.useEffect(() => {
    if (month) {
      getMonthlyData({ month: month.month, year: month.year });
    }
  }, [getMonthlyData, month]);

  React.useEffect(() => {
    if (year) {
      getYearlyData({ year: year.id });
    }
  }, [getYearlyData, year]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t(
          'financial_reporting_dashboard.chart_title.financial_vs_projections',
        )}
      >
        <ChartContainerToolbar>
          {period.id === 'weekly' ? (
            <ChartContainerUtils
              onToggleFullScreen={weeklyToggleFullScreen}
              onExport={() =>
                exportWeeklyChart(`weekly_financial_vs_projections`)
              }
            />
          ) : null}
          {period.id === 'monthly' ? (
            <ChartContainerUtils
              onToggleFullScreen={monthlyToggleFullScreen}
              onExport={() =>
                exportMonthlyChart(`monthly_financial_vs_projections`)
              }
            />
          ) : null}
          {period.id === 'yearly' ? (
            <ChartContainerUtils
              onToggleFullScreen={yearlyToggleFullScreen}
              onExport={() =>
                exportYearlyChart(`yearly_financial_vs_projections`)
              }
            />
          ) : null}

          <DoubledCombobox>
            <LeftCombobox
              disableClearable
              options={periodOptions}
              value={period}
              onChange={onPeriodChange}
            />
            <ComboboxSeparator />
            {period.id === 'weekly' ? (
              <RightCombobox
                disableClearable
                options={weeksOptions}
                value={week}
                onChange={onWeekChange}
              />
            ) : null}
            {period.id === 'monthly' ? (
              <RightCombobox
                disableClearable
                options={monthsOptions}
                value={month}
                onChange={onMonthChange}
              />
            ) : null}
            {period.id === 'yearly' ? (
              <RightCombobox
                disableClearable
                options={yearsOptions}
                value={year}
                onChange={onYearChange}
              />
            ) : null}
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isWeeklyLoading || isMonthlyLoading || isYearlyLoading}
        showNoData={showNoData}
      >
        {period.id === 'weekly' && (
          <FinancialDashboardTotalsChartWeekly
            ref={weeklyChartRef}
            chartHeight={weeklyChartHeight}
            data={weeklyData[0]}
          />
        )}
        {period.id === 'monthly' && (
          <FinancialDashboardTotalsChartMonthly
            ref={monthlyChartRef}
            chartHeight={monthlyChartHeight}
            data={monthlyData[0]}
          />
        )}
        {period.id === 'yearly' && (
          <FinancialDashboardTotalsChartYearly
            ref={yearlyChartRef}
            chartHeight={yearlyChartHeight}
            data={yearlyData[0]}
          />
        )}
      </ChartContainerBody>
    </ChartContainer>
  );
};
