import { isEmpty } from 'lodash';
import { NewPayboardWeek, SummaryInfo } from '../../types';

export const formatAsCurrency = (amount: number, currencyType = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyType,
  }).format(amount);
};

export const formatAsPercent = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount / 100);
};

export interface IConcatenateYearWeekArgs {
  year: number;
  week: number;
}
export const concatenateYearWeek = ({
  year,
  week,
}: IConcatenateYearWeekArgs) => {
  return `${year}${String(week).padStart(2, '0')}`;
};

export const formatKronosPayboardItems = (items: NewPayboardWeek[]) => {
  return items.map((item) => ({
    ...item,
    eBaseRate: formatAsCurrency(item.eBaseRate),
    edtAmount: !isEmpty(item.edtAmount)
      ? formatAsCurrency(item.edtAmount)
      : item.edtAmount,
    totalPay: item.totalPay ? formatAsCurrency(item.totalPay) : undefined,
  }));
};

export const formatKronosPayboardSummary = (summary: SummaryInfo[]) => {
  return summary.map((summaryItem: SummaryInfo) => ({
    title: summaryItem.title,
    values: {
      ...summaryItem.values,
      edtAmount: formatAsCurrency(Number(summaryItem.values.edtAmount)),
      totalPay: formatAsCurrency(Number(summaryItem.values.totalPay)),
    },
  }));
};

export const concatenateYearWeekAsNumber = ({
  year,
  week,
}: IConcatenateYearWeekArgs) => {
  return Number(`${year}${String(week).padStart(2, '0')}`);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
