import React, { useMemo } from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { IUploadFilesProps, UploadFiles } from '../UploadFiles';

type IFormikFilesUpload = Omit<IUploadFilesProps, 'onChange' | 'filesData'> & {
  name: string;
  formik: AnyObject;
};

export const FormikFilesUpload = ({
  formik,
  name,
  ...restProps
}: IFormikFilesUpload) => {
  const handleChange = React.useCallback(
    (file: IUploadFilesProps['filesData']) => {
      formik.setFieldError(name, undefined);
      formik.setFieldValue(name, file);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  );

  const option = useMemo(() => get(formik.values, name), [formik.values, name]);

  return (
    <UploadFiles filesData={option} onChange={handleChange} {...restProps} />
  );
};
