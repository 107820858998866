import React from 'react';
import {
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import { Box } from '@mui/joy';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';

interface IPricingUpdateRangeRecordsProps {
  formik: any;
  pricingMetatags: React.ReactNode;
}

type RowProps = {
  formik: any;
  i: number;
};

const fieldSlotProps = {
  input: {
    sx: {
      width: 32,
    },
  },
  root: {
    sx: {
      width: 48,
      height: 24,
      p: 0.5,
    },
  },
};

const helperTextProps = {
  sx: { fontSize: '12px' },
};

const Row = ({ formik, i }: RowProps) => {
  const shouldMaxBeDisabled = (length: number, index: number): boolean => {
    return index === length - 1;
  };

  const minId = (index: number): string =>
    index > 0 ? `[${index - 1}].maxUph` : `[${index}].minUph`;

  return (
    <Box
      sx={(theme) => ({
        height: 80,
        alignItems: 'end',
        padding: `0 ${theme.spacing(2)}`,
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 5fr)',
        gridColumnGap: '16px',
      })}
      key={i}
    >
      <FormikTextField
        required
        id={`[${i}].price`}
        formik={formik}
        name={`[${i}].price`}
        label={''}
        slotProps={fieldSlotProps}
        placeholder="0"
        variant="soft"
        helperTextProps={helperTextProps}
      />
      <FormikTextField
        required
        id={`[${i}].incentivePrice`}
        formik={formik}
        name={`[${i}].incentivePrice`}
        label={''}
        slotProps={fieldSlotProps}
        placeholder="0"
        variant="soft"
        helperTextProps={helperTextProps}
      />
      <FormikTextField
        required
        id={`[${i}].overtimePrice`}
        formik={formik}
        name={`[${i}].overtimePrice`}
        label={''}
        slotProps={fieldSlotProps}
        placeholder="0"
        variant="soft"
        helperTextProps={helperTextProps}
      />
      <FormikTextField
        required
        disabled
        id={minId(i)}
        formik={formik}
        name={`[${i}].minUph`}
        label={''}
        autoComplete="minUph"
        slotProps={fieldSlotProps}
        placeholder="0"
        variant="soft"
        helperTextProps={helperTextProps}
      />
      <FormikTextField
        required
        disabled={shouldMaxBeDisabled(formik.values.length, i)}
        id={`[${i}].maxUph`}
        formik={formik}
        name={`[${i}].maxUph`}
        label={''}
        autoComplete="maxUph"
        slotProps={fieldSlotProps}
        placeholder="0"
        variant="soft"
        helperTextProps={helperTextProps}
      />
    </Box>
  );
};

export const PricingUpdateRangeRecords = ({
  formik,
  pricingMetatags,
}: IPricingUpdateRangeRecordsProps) => {
  const { t } = useTranslation();

  const renderFormRows = () => (
    <FormFieldContainer>
      <Box
        sx={{
          borderRadius: 8,
          overflow: 'hidden',
          borderColor: 'colors.border.border_primary',
          borderWidth: 1,
          borderStyle: 'solid',
          paddingBottom: 2,
        }}
      >
        <Box
          sx={{
            height: 48,
            alignItems: 'center',
            backgroundColor: '#fafafa',
            paddingLeft: 2,
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 5fr)',
            gridColumnGap: '8px',
          }}
        >
          <Box>{t('pricing.price')}*</Box>
          <Box>{t('pricing.incentivePrice')}*</Box>
          <Box>{t('pricing.overtimePrice')}*</Box>
          <Box>{t('pricing.minUph')}*</Box>
          <Box>{t('pricing.maxUph')}*</Box>
        </Box>
        {formik.values.map((_: any, index: number) => {
          return <Row formik={formik} key={index} i={index} />;
        })}
      </Box>
    </FormFieldContainer>
  );

  return (
    <>
      <DrawerBodySectionContent>
        <DrawerBodySectionTitle>
          {t('pricing.meta_tags')}
        </DrawerBodySectionTitle>

        {pricingMetatags}
      </DrawerBodySectionContent>

      <DrawerBodySectionContent>
        <DrawerBodySectionTitle>
          {t('common.form_fields')}
        </DrawerBodySectionTitle>

        {renderFormRows()}
      </DrawerBodySectionContent>
    </>
  );
};
