/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyObject, IFilterData } from '../../../modules/types';
import {
  NavigateOptions,
  To,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSearch } from './useSearch';
import qs from 'qs';
import { DEFAULT_ARRAY_LIMIT } from './useQueryParams';
import { QS_PARSER_DEFAULT_DEPTH } from '../../../config';

export interface IPushToHistoryFunction {
  (to: To, options?: NavigateOptions): void;
}

export const getQueryParams = (
  search: string,
  arrayLimit = DEFAULT_ARRAY_LIMIT,
) => {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    arrayLimit,
    allowDots: true,
    depth: QS_PARSER_DEFAULT_DEPTH,
  });
};

export const useBrowserHistoryFunctions = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const mapObjectToQueryParams = useSearch();

  const pushToHistory: IPushToHistoryFunction = (...args) => {
    navigate(...args);
  };

  const pushSearchString = (searchString: string) => {
    setSearchParams(searchString);
  };

  const pushSearchObject = (search: AnyObject) => {
    pushSearchString(mapObjectToQueryParams(search));
  };

  const pushFilterDataObjectToQueryParams = (filterData: IFilterData | any) => {
    const { filter, ...restQueryParams } = getQueryParams(
      searchParams.toString(),
    );
    pushSearchObject({ filter: filterData, ...restQueryParams });
  };

  const resetFiltersInQueryParams = () => {
    const { filter, ...restQueryParams } = getQueryParams(
      searchParams.toString(),
    );

    pushSearchObject(restQueryParams);
  };

  const resetFilterInQueryParamsByName = (filterName: string) => {
    const queryParams = getQueryParams(searchParams.toString());

    queryParams?.filter && delete queryParams.filter[filterName];

    pushSearchObject(queryParams);
  };

  const updateQueryParams = (search: AnyObject) => {
    const existedQueryParams = getQueryParams(searchParams.toString());

    pushSearchObject({
      ...existedQueryParams,
      ...search,
    });
  };

  const updateFilterDataInQueryParams = (filterData: AnyObject) => {
    const { filter, ...restQueryParams } = getQueryParams(
      searchParams.toString(),
    );

    pushSearchObject({
      filter: Object.assign({}, filter, filterData),
      ...restQueryParams,
    });
  };

  const getQueryParamsObject = () => {
    return getQueryParams(searchParams.toString());
  };

  const historyGo = (stepsNum: number) => {
    navigate(stepsNum);
  };

  return {
    historyGo,
    pushToHistory,
    pushSearchString,
    pushSearchObject,
    updateQueryParams,
    getQueryParamsObject,
    resetFiltersInQueryParams,
    updateFilterDataInQueryParams,
    resetFilterInQueryParamsByName,
    pushFilterDataObjectToQueryParams,
  };
};
