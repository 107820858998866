import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert, Button, Grid } from '@mui/joy';
import { omit, map } from 'lodash';
import {
  FilterValueDescriptor,
  IHeadCellWithOrderConfig,
  IInclusionObject,
} from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
  IPayboardIncentivePaymentResponseItem,
} from 'src/modules/types';
import {
  TableToolbar,
  TableTitle,
  TableActions,
  TableContext,
} from 'src/components/Table';
import { MUI_SIZE_12 } from 'src/config';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { EntitiesBySiteComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/EntitiesBySiteComboBoxFilter';
import { getEmployeesComboboxListBySiteId } from 'src/modules/selectors/employee';
import {
  Api,
  useFetchEmployeesCombobox,
  useFetchSitesWithIncentiveSettingsCombobox,
  useFetchStaffingProvidersCombobox,
  useIncentivePermissions,
} from 'src/modules/utils';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { PayboardInceitiveCreate } from './PayboardInceitiveCreate';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { TableWithActions } from 'src/components/TableWithActions';
import { ITableSelectedItemsActionsItemProps } from 'src/components/Table/components/TableSelectedItemsActions/TableSelectedItemsActionsItem';
import {
  ChartMixedUpCircleDollarLight,
  CheckSquareSvg,
  XSquareSvg,
} from 'src/components/svgIcons';
import { PayboardIncentiveUpdateTotalPayment } from './PayboardIncentiveUpdateTotalPayment';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { getSitesWithIncentiveSettingsComboboxList } from '../../../../../../modules/selectors/site';
import { SitesComboBoxFilter } from '../../../../../../components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { CheckboxFilter } from '../../../../../../components/Table/components/TableActions/components/Filter/components/CheckboxFilter';

const incentivePaymentOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

type IPayboardIncentivePaymentResponse = IListWithSummary<
  IPayboardIncentivePaymentResponseItem
>;

interface IPayboardIncentivePaymentBaseProps {
  headCellsConfig: IHeadCellWithOrderConfig[];
  incentivePaymentDataUrl: string;
  filterFieldsConfiguration: Record<string, FilterValueDescriptor>;
  title: string;
  shouldHaveIncentiveSettings?: boolean;
  isActionsDisabled?: boolean;
  inclusionObject?: IInclusionObject;
}

export const PayboardIncentivePaymentBase: React.FC<IPayboardIncentivePaymentBaseProps> = ({
  headCellsConfig,
  incentivePaymentDataUrl,
  filterFieldsConfiguration,
  title,
  shouldHaveIncentiveSettings,
  isActionsDisabled = false,
  inclusionObject,
}) => {
  const { t } = useTranslation();

  const {
    allowedToCreate,
    allowedToApprove,
    allowedToUnapprove,
    allowedToUpdateTotalPayment,
  } = useIncentivePermissions();

  const { filters } = React.useContext(TableContext);

  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState<boolean>(
    false,
  );
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState<boolean>(
    false,
  );
  const [selectedItems, setSelectedItems] = React.useState<AnyObject[]>([]);
  const [actionsConfiguration, setActionsConfiguration] = React.useState<
    Array<ITableSelectedItemsActionsItemProps>
  >([]);

  const fetchEmployeesCombobox = useFetchEmployeesCombobox();
  const fetchSitesCombobox = useFetchSitesWithIncentiveSettingsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();

  const sites = useSelector(getSitesWithIncentiveSettingsComboboxList);

  const exportProps = useReportExportProps(incentivePaymentDataUrl, true);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IPayboardIncentivePaymentResponse
  >(incentivePaymentDataUrl, {
    items: [],
    summary: [],
  });

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${incentivePaymentDataUrl}/count`,
    {
      count: 0,
    },
  );

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({ filterFieldsConfiguration });

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const employeesWithSite = useSelector(getEmployeesComboboxListBySiteId)(
    filters.siteId as number,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: incentivePaymentOrderDetails.order,
    defaultOrderBy: incentivePaymentOrderDetails.orderBy,
  });

  let filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
    inclusionObj: inclusionObject,
  });

  filter = React.useMemo(() => {
    if (
      (filter.where as any)?.totalPayment &&
      (filter.where as any)?.totalPayment?.gt === true
    ) {
      return {
        ...filter,
        where: { ...filter?.where, totalPayment: { gt: 0 } },
      };
    }
    if (
      (filter.where as any)?.totalPayment &&
      (filter.where as any)?.totalPayment?.gt === false
    ) {
      return { ...filter, where: { ...omit(filter?.where, 'totalPayment') } };
    }

    return filter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.where]);

  const isRequiredFiltersSelected = React.useMemo(() => {
    return (
      (filter.where as any)?.siteId &&
      (filter.where as any)?.year &&
      (filter.where as any)?.week
    );
  }, [filter.where]);

  const missingRequiredFilterWarning = React.useMemo(() => {
    const missingFilters: Array<string> = [];
    if (!(filter.where as any)?.siteId) {
      missingFilters.push(t('lower_case.site_name'));
    }
    if (!(filter.where as any)?.year) {
      missingFilters.push(t('lower_case.year'));
    }
    if (!(filter.where as any)?.week) {
      missingFilters.push(t('lower_case.week'));
    }

    if (missingFilters.length) {
      return t('common.select_appropriate_filters', {
        filtersNames: missingFilters.join(' / '),
      });
    }

    return null;
  }, [filter.where, t]);

  const summaryLocalization = React.useMemo(
    () =>
      data.summary.reduce((acc, item) => {
        acc[item.title] = t(item.title);
        return acc;
      }, {}),
    [data.summary, t],
  );

  const fetchIncentive = React.useCallback(() => {
    if (isRequiredFiltersSelected) {
      fetchData({ filter });
      fetchCountData({ filter: { where: filter.where } });
    }
  }, [fetchCountData, fetchData, filter, isRequiredFiltersSelected]);

  React.useEffect(() => {
    fetchEmployeesCombobox();

    if (shouldHaveIncentiveSettings) {
      fetchSitesCombobox();
    }

    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchIncentive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, fetchCountData, filter, isRequiredFiltersSelected]);

  const handleCreateStart = async () => {
    setIsCreateFormVisible(false);
    setIsCreating(true);
  };

  const handleCreateFinish = async () => {
    fetchIncentive();
    setIsCreating(false);
  };

  const computedList = useMemo(
    () =>
      map(data.items, (item) => {
        return {
          ...item,
          _approved: item.approved ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          approvedBy: item.approvedUser?.id
            ? `${item.approvedUser.firstName} ${item.approvedUser.lastName}`
            : '',
        };
      }),
    [data.items],
  );

  const onTableRowCheckboxClick = (items: Array<AnyObject>) => {
    setSelectedItems(items);
  };

  const handleApprove = async () => {
    await Api.Incentive.approve(selectedItems.map((i) => i.id));
    fetchIncentive();
  };

  const handleUnapprove = async () => {
    await Api.Incentive.unapprove(selectedItems.map((i) => i.id));
    fetchIncentive();
  };

  React.useEffect(() => {
    const ac: Array<ITableSelectedItemsActionsItemProps> = [];
    if (allowedToApprove && !isActionsDisabled) {
      ac.push({
        tooltip: t('common.approve'),
        icon: <CheckSquareSvg />,
        onClick: handleApprove,
      });
    }

    if (allowedToUnapprove && !isActionsDisabled) {
      ac.push({
        tooltip: t('common.unapprove'),
        icon: <XSquareSvg />,
        onClick: handleUnapprove,
      });

      if (allowedToUpdateTotalPayment && !isActionsDisabled) {
        ac.push({
          tooltip: t('payboard.incentive.update_total_payment'),
          icon: <ChartMixedUpCircleDollarLight />,
          onClick: () => setIsUpdateFormVisible(true),
        });
      }
    }

    setActionsConfiguration(ac);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedToApprove, allowedToUnapprove, t, selectedItems]);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where: filter.where,
    fetchData,
  });

  const handleUpdate = () => {
    setIsUpdateFormVisible(false);
    fetchIncentive();
  };

  React.useEffect(() => {
    if (selectedItems.length) {
      setSelectedItems((prev) =>
        prev.reduce<AnyObject[]>((acc, item) => {
          const updatedItem = data.items.find(
            (nestedItem) => (nestedItem as any).id === item.id,
          );

          if (updatedItem) {
            acc.push(updatedItem);
          }
          return acc;
        }, []),
      );
    }
  }, [data.items, selectedItems.length]);

  return (
    <>
      <TableToolbar>
        <TableTitle>{title}</TableTitle>

        <TableActions>
          <Filter onSubmit={onFiltersFormSubmit}>
            <FormFieldContainer>
              {!shouldHaveIncentiveSettings && (
                <SitesComboBoxFilter
                  label={getLabel({
                    filterName: 'siteId',
                    labelPrefix: t('payboard.site_name'),
                  })}
                  {...getFilterCommonPropsByFilterName('siteId')}
                />
              )}
              {shouldHaveIncentiveSettings && (
                <ComboboxTableFilter
                  options={sites}
                  label={getLabel({
                    filterName: 'siteId',
                    labelPrefix: t('payboard.site_name'),
                  })}
                  {...getFilterCommonPropsByFilterName('siteId')}
                />
              )}
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'year',
                  labelPrefix: t('common.year'),
                })}
                {...getFilterCommonPropsByFilterName('year')}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'week',
                  labelPrefix: t('common.week'),
                })}
                {...getFilterCommonPropsByFilterName('week')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <EntitiesBySiteComboBoxFilter
                options={employeesWithSite}
                label={getLabel({
                  filterName: 'extEmployeeId',
                  labelPrefix: t('payboard.employee'),
                })}
                {...getFilterCommonPropsByFilterName('extEmployeeId')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <CheckboxFilter
                label={`${t('payboard.total_payment')} > 0`}
                {...getFilterCommonPropsByFilterName('totalPayment')}
              />
            </FormFieldContainer>
          </Filter>

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={filter}
            exportBtnContainerProps={{ ml: 3 }}
            summaryLocalization={summaryLocalization}
            {...exportProps}
          />

          {!missingRequiredFilterWarning &&
            allowedToCreate &&
            !isActionsDisabled && (
              <ReportPageActionsGroupItem>
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormVisible(true)}
                >
                  {t('payboard.incentive.create')}
                </Button>
              </ReportPageActionsGroupItem>
            )}
        </TableActions>
      </TableToolbar>

      {missingRequiredFilterWarning && (
        <Grid container={true} sx={{ flexGrow: 1 }}>
          <Grid xs={MUI_SIZE_12}>
            <Alert variant="soft">{missingRequiredFilterWarning}</Alert>
          </Grid>
        </Grid>
      )}

      {!missingRequiredFilterWarning && (
        <>
          <TableWithActions
            heightWithTabs
            selectedIndex="id"
            data={computedList as any}
            count={countData.count}
            isDataLoading={isDataLoading || isCountDataLoading || isCreating}
            tableUniqueKey={incentivePaymentDataUrl}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            limit={limit}
            page={page}
            onSort={handleSort}
            selectedItems={selectedItems}
            onRowCheckboxClick={onTableRowCheckboxClick}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(page) => handlePageChange(page)}
            actionsConfiguration={actionsConfiguration}
          />
          <PayboardInceitiveCreate
            shouldHaveIncentiveSettings={shouldHaveIncentiveSettings}
            isOpen={isCreateFormVisible}
            onClose={() => setIsCreateFormVisible(false)}
            onCreateStart={handleCreateStart}
            onCreateFinish={handleCreateFinish}
          />
          <PayboardIncentiveUpdateTotalPayment
            isOpen={isUpdateFormVisible}
            rows={selectedItems}
            onClose={() => setIsUpdateFormVisible(false)}
            onUpdate={handleUpdate}
          />
        </>
      )}
    </>
  );
};
