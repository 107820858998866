import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';

import { Api } from '../utils';
import * as actions from '../actions';

import {
  ICountResponse,
  ICreateNgroupBillingDetails,
  IdsArray,
  IFilter,
  INgroupBillingDetailsModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createNgroupBillingDetailsRequest,
    saga: function* ({
      payload: { data },
    }: ISagaAction<
      PayloadWithNavigateFunc<ICreateNgroupBillingDetails>
    >): SagaIterator {
      try {
        const response: INgroupBillingDetailsModel = yield call(
          Api.NgroupBillingDetails.create,
          data,
        );
        yield put(actions.flushNgroupBillingDetailsState());
        yield put(actions.createNgroupBillingDetailsSuccess(response));
      } catch (e) {
        yield put(actions.createNgroupBillingDetailsFailed());
      }
    },
  },
  {
    action: actions.createBulkNgroupBillingDetailsRequest,
    saga: function* ({
      payload: { data },
    }: ISagaAction<
      PayloadWithNavigateFunc<ICreateNgroupBillingDetails[]>
    >): SagaIterator {
      try {
        const response: INgroupBillingDetailsModel = yield call(
          Api.NgroupBillingDetails.createBulk,
          data,
        );
        yield put(actions.flushNgroupBillingDetailsState());
        yield put(actions.createNgroupBillingDetailsSuccess(response));
      } catch (e) {
        yield put(actions.createNgroupBillingDetailsFailed());
      }
    },
  },
  {
    action: actions.getNgroupBillingDetailsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: INgroupBillingDetailsModel[] = yield call(
          Api.NgroupBillingDetails.list,
          action.payload,
        );
        yield put(actions.getNgroupBillingDetailsListSuccess(response));
      } catch (e) {
        yield put(actions.getNgroupBillingDetailsListFailed());
      }
    },
  },
  {
    action: actions.getNgroupBillingDetailsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.NgroupBillingDetails.count,
          action?.payload,
        );
        yield put(actions.getNgroupBillingDetailsCountSuccess(response));
      } catch (e) {
        yield put(actions.getNgroupBillingDetailsCountFailed());
      }
    },
  },
  {
    action: actions.deleteNgroupBillingDetailsRequest,
    saga: function* (action: ISagaAction<IdsArray>): SagaIterator {
      try {
        yield call(Api.NgroupBillingDetails.delete, {
          where: { id: { inq: action.payload } },
        });
        yield put(actions.flushNgroupBillingDetailsState());
      } catch (e) {
        yield put(actions.deleteNgroupBillingDetailsFailed());
      }
    },
  },
  {
    action: actions.updateNgroupBillingDetailsRequest,
    saga: function* ({
      payload: { data },
    }: ISagaAction<
      PayloadWithNavigateFunc<INgroupBillingDetailsModel[]>
    >): SagaIterator {
      try {
        yield call(Api.NgroupBillingDetails.bulkUpdate, data);
        yield put(actions.flushNgroupBillingDetailsState());
      } catch (e) {
        yield put(actions.updateNgroupBillingDetailsFailed());
      }
    },
  },
];
