import { externalUrls, paths } from '.';
import { IMenuItem } from '../modules/types';
import { useTranslation } from 'react-i18next';
import {
  BoxSvg,
  ChartMixedUpCircleDollarLight,
  CheckDone02Svg,
  ClockSvg,
  Database02Svg,
  Dataflow01Svg,
  DotPoints02Svg,
  File06Svg,
  PhoneCall02Svg,
  SettingsSvg,
  Users01Svg,
} from 'src/components/svgIcons';
import { sortBy } from 'lodash';

export const useMenuItems = (): IMenuItem[] => {
  const { t } = useTranslation();

  const setupSubItems: IMenuItem[] = [
    {
      searchBy: t('main_menu.area.title'),
      name: 'main_menu.area.title',
      url: paths.AREA_LIST,
    },
    {
      searchBy: t('main_menu.clients'),
      name: 'main_menu.clients',
      url: paths.CLIENTS,
    },
    {
      searchBy: t('main_menu.sites'),
      name: 'main_menu.sites',
      url: paths.SITES,
      pageType: 'withTabs',
    },
    {
      searchBy: t('automaticPointsRejectReason.menu_title'),
      name: 'automaticPointsRejectReason.menu_title',
      url: paths.AUTOMATIC_POINTS_REJECTION_REASON,
    },
    {
      searchBy: t('main_menu.exporting_settings.takt_list'),
      name: 'main_menu.exporting_settings.takt_list',
      url: paths.SITE_TAKT_EXPORTING_SETTINGS,
    },
    {
      searchBy: t('main_menu.departments'),
      name: 'main_menu.departments',
      url: paths.DEPARTMENTS,
    },
    {
      searchBy: t('main_menu.positions'),
      name: 'main_menu.positions',
      subitems: [
        {
          searchBy: t('main_menu.positions_list'),
          name: 'main_menu.positions_list',
          url: paths.POSITIONS,
          matchedUrls: [/\/positions\/update/],
        },
        {
          searchBy: t('main_menu.positions_create'),
          name: 'main_menu.positions_create',
          url: paths.POSITIONS_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.shifts'),
      name: 'main_menu.shifts',
      url: paths.SHIFTS,
    },
    {
      searchBy: t('main_menu.staff_providers'),
      name: 'main_menu.staff_providers',
      subitems: [
        {
          searchBy: t('main_menu.staff_providers_list'),
          name: 'main_menu.staff_providers_list',
          url: paths.STAFFING_PROVIDERS,
          matchedUrls: [/\/staffing-providers\/update/],
        },
        {
          searchBy: t('main_menu.staff_providers_create'),
          name: 'main_menu.staff_providers_create',
          url: paths.STAFFING_PROVIDERS_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.skills'),
      name: 'main_menu.skills',
      subitems: [
        {
          searchBy: t('main_menu.skills_list'),
          name: 'main_menu.skills_list',
          url: paths.SKILLS,
          matchedUrls: [/\/skills\/update/],
        },
        {
          searchBy: t('main_menu.skills_create'),
          name: 'main_menu.skills_create',
          url: paths.SKILLS_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.roles'),
      name: 'main_menu.roles',
      subitems: [
        {
          searchBy: t('main_menu.roles_list'),
          name: 'main_menu.roles_list',
          url: paths.ROLES,
          matchedUrls: [/\/roles\/update/],
        },
        {
          searchBy: t('main_menu.roles_create'),
          name: 'main_menu.roles_create',
          url: paths.ROLES_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.standards'),
      name: 'main_menu.standards',
      subitems: [
        {
          searchBy: t('main_menu.standards_list'),
          name: 'main_menu.standards_list',
          url: paths.STANDARDS,
          matchedUrls: [/\/standards\/update/],
        },
        {
          searchBy: t('main_menu.standards_create'),
          name: 'main_menu.standards_create',
          url: paths.STANDARDS_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.standards_tags.main_item'),
      name: 'main_menu.standards_tags.main_item',
      url: paths.METATAGS,
    },
    {
      searchBy: t('main_menu.incentive_payment_settings'),
      name: 'main_menu.incentive_payment_settings',
      url: paths.INCENTIVE_PAYMENT_SETTINGS,
    },
    {
      searchBy: t('main_menu.kpi_settings'),
      name: 'main_menu.kpi_settings',
      url: paths.KPI_SETTINGS,
    },
    {
      searchBy: t('main_menu.pricing'),
      name: 'main_menu.pricing',
      pageType: 'withTabs',
      url: paths.PRICING,
    },
    {
      searchBy: t('main_menu.invoice_settings'),
      name: 'main_menu.invoice_settings',
      url: paths.INVOICE_SETTINGS,
      pageType: 'withTabs',
    },
    {
      searchBy: t('main_menu.invoices'),
      name: 'main_menu.invoices',
      url: paths.INVOICE,
      pageType: 'withTabs',
    },
    {
      searchBy: t('main_menu.issues_types.main_item'),
      name: 'main_menu.issues_types.main_item',
      url: paths.ISSUE_TYPES,
    },
    {
      searchBy: t('main_menu.points_types.main_item'),
      name: 'main_menu.points_types.main_item',
      subitems: [
        {
          searchBy: t('main_menu.points_types.list'),
          name: 'main_menu.points_types.list',
          url: paths.POINT_TYPES,
          matchedUrls: [/\/point-types\/update/],
        },
        {
          searchBy: t('main_menu.points_types.create'),
          name: 'main_menu.points_types.create',
          url: paths.POINT_TYPES_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.points_collection.main_item'),
      name: 'main_menu.points_collection.main_item',
      url: paths.POINT_COLLECTIONS,
    },
    {
      searchBy: t('main_menu.production.production.main'),
      name: 'main_menu.production.production.main',
      subitems: [
        {
          searchBy: t('main_menu.production.bom.main'),
          name: 'main_menu.production.bom.main',
          subitems: [
            {
              searchBy: t('main_menu.production.bom.list'),
              name: 'main_menu.production.bom.list',
              url: paths.BOMS,
              matchedUrls: [/\/boms\/update/],
            },
            {
              searchBy: t('main_menu.production.bom.create'),
              name: 'main_menu.production.bom.create',
              url: paths.BOMS_CREATE,
            },
          ],
        },
        {
          searchBy: t('main_menu.production.production_settings.main'),
          name: 'main_menu.production.production_settings.main',
          url: paths.PRODUCTION_SETTINGS,
        },
      ],
    },
    {
      searchBy: t('main_menu.users'),
      name: 'main_menu.users',
      subitems: [
        {
          searchBy: t('main_menu.users_list'),
          name: 'main_menu.users_list',
          url: paths.USERS,
          matchedUrls: [/\/users\/update/],
        },
        {
          searchBy: t('main_menu.users_create'),
          name: 'main_menu.users_create',
          url: paths.USERS_CREATE,
        },
      ],
    },
    // @TODO: This tab contains outdated data
    // {
    //   searchBy: t('main_menu.knowledge_setup.main_item'),
    //   name: 'main_menu.knowledge_setup.main_item',
    //   subitems: [
    //     {
    //       searchBy: t('main_menu.knowledge_setup.pages_list'),
    //       name: 'main_menu.knowledge_setup.pages_list',
    //       url: paths.WIKI_SETUP_LIST,
    //       matchedUrls: [
    //         /\/knowledge-setup\/update/,
    //         /\/knowledge-setup\/detail/,
    //       ],
    //     },
    //     {
    //       searchBy: t('main_menu.knowledge_setup.create_page'),
    //       name: 'main_menu.knowledge_setup.create_page',
    //       url: paths.WIKI_SETUP_CREATE,
    //     },
    //   ],
    // },
    {
      searchBy: t('main_menu.optiturn-credentials.main'),
      name: 'main_menu.optiturn-credentials.main',
      subitems: [
        {
          searchBy: t('main_menu.optiturn-credentials.list'),
          name: 'main_menu.optiturn-credentials.list',
          url: paths.OPTITURN_CREDENTIALS,
          matchedUrls: [/\/optiturn-credentials\/update/],
        },
        {
          searchBy: t('main_menu.optiturn-credentials.create'),
          name: 'main_menu.optiturn-credentials.create',
          url: paths.OPTITURN_CREDENTIALS_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.restore.restore_entities'),
      name: 'main_menu.restore.restore_entities',
      url: paths.RESTORE,
    },
    {
      searchBy: t('main_menu.page_setting.title'),
      name: 'main_menu.page_setting.title',
      url: paths.PAGE_SETTING,
    },
    {
      searchBy: t('main_menu.termination_reasons'),
      name: 'main_menu.termination_reasons',
      subitems: [
        {
          searchBy: t('main_menu.termination_reasons_list'),
          name: 'main_menu.termination_reasons_list',
          url: paths.TERMINATION_REASONS,
          matchedUrls: [/\/termination-reasons\/update/],
        },
        {
          searchBy: t('main_menu.termination_reasons_create'),
          name: 'main_menu.termination_reasons_create',
          url: paths.TERMINATION_REASONS_CREATE,
        },
      ],
    },
  ];

  return [
    {
      searchBy: t('main_menu.executive_reports.summary'),
      name: 'main_menu.executive_reports.summary',
      Icon: ChartMixedUpCircleDollarLight,
      url: paths.EXECUTIVE_SUMMARY,
      pageType: 'withTabs',
    },
    {
      searchBy: t('main_menu.dashboard'),
      name: 'main_menu.dashboard',
      Icon: Dataflow01Svg,
      url: paths.DASHBOARD,
      pageType: 'withTabs',
    },
    {
      searchBy: t('main_menu.employees'),
      name: 'main_menu.employees',
      Icon: Users01Svg,
      subitems: [
        {
          searchBy: t('main_menu.employees_list'),
          name: 'main_menu.employees_list',
          url: paths.EMPLOYEES,
        },
        {
          searchBy: t('main_menu.skillflex.list'),
          name: 'main_menu.skillflex.list',

          url: paths.EMPLOYEE_SKILL_FLEX,
        },
        {
          searchBy: t('main_menu.points'),
          name: 'main_menu.points',
          url: paths.EMPLOYEES_POINTS,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.employees_questions'),
          name: 'main_menu.employees_questions',
          url: paths.EMPLOYEES_QUESTIONS,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.employees_reviews'),
          name: 'main_menu.employees_reviews',
          url: paths.QUESTIONNAIRE_HISTORY,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.employee_create'),
          name: 'main_menu.employee_create',
          url: paths.EMPLOYEES_CREATE_WITHOUT_PAYMENT_INFO,
        },
      ],
    },
    {
      searchBy: t('main_menu.time_keeping.main'),
      name: 'main_menu.time_keeping.main',
      Icon: ClockSvg,
      subitems: [
        {
          searchBy: t('main_menu.time_keeping.time_punches'),
          name: 'main_menu.time_keeping.time_punches',
          url: paths.TIME_KEEPING_PUNCHES,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.time_keeping.supervisor_view'),
          name: 'main_menu.time_keeping.supervisor_view',
          url: paths.TIME_KEEPING_SUPERVISOR_VIEW,
        },
        {
          searchBy: t('main_menu.payboard'),
          name: 'main_menu.payboard',
          url: paths.PAYBOARD,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.payboard_historical'),
          name: 'main_menu.payboard_historical',
          url: paths.PAYBOARD_HISTORICAL,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.incentive'),
          name: 'main_menu.incentive',
          url: paths.INCENTIVE,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.chart_reports.daily_hours'),
          name: 'main_menu.chart_reports.daily_hours',
          url: paths.REPORTS_DAILY_HOURS,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.chart_reports.weekly_hours_dashboard'),
          name: 'main_menu.chart_reports.weekly_hours_dashboard',
          url: paths.REPORTS_WEEKLY_HOURS_DASHBOARD,
        },
      ],
    },
    {
      searchBy: t('main_menu.chart_reports.title'),
      name: 'main_menu.chart_reports.title',
      Icon: File06Svg,
      subitems: [
        {
          searchBy: t('main_menu.executive_reports.main'),
          name: 'main_menu.executive_reports.main',
          url: paths.EXECUTIVE_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.production.production.list'),
          name: 'main_menu.production.production.list',
          url: paths.PRODUCTIONS,
        },
        {
          searchBy: t('main_menu.kpi'),
          name: 'main_menu.kpi',
          url: paths.KPI,
        },
        {
          searchBy: t('main_menu.kpi_report'),
          name: 'main_menu.kpi_report',
          url: paths.KPI_REPORT,
        },
        {
          searchBy: t('main_menu.issues'),
          name: 'main_menu.issues',
          url: paths.ISSUES,
          matchedUrls: [/\/issues\/update/],
        },
        // {
        //   searchBy: t('main_menu.barrett.main'),
        //   name: 'main_menu.barrett.main',
        //   subitems: [
        //     {
        //       searchBy: t('main_menu.production.reports.main'),
        //       name: 'main_menu.reports.barrett_main',
        //       url: paths.BARRETT_REPORTS,
        //       type: 'reports',
        //     },
        //   ],
        // },
        {
          searchBy: t('main_menu.carparts.main'),
          name: 'main_menu.carparts.main',
          url: paths.CARPARTS_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.optoro.main'),
          name: 'main_menu.optoro.main',
          url: paths.OPTORO_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.target.main'),
          name: 'main_menu.target.main',
          url: paths.TARGET_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.nfi.main'),
          name: 'main_menu.nfi.main',
          url: paths.NFI_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.sony.main'),
          name: 'main_menu.sony.main',
          url: paths.SONY_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.reports.williams_sonoma_main'),
          name: 'main_menu.reports.williams_sonoma_main',
          url: paths.WILLIAMS_SONOMA_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.taylor_farms.main'),
          name: 'main_menu.taylor_farms.main',
          url: paths.TAYLOR_FARMS_REPORTS,
          type: 'reports',
        },
        // {
        //   searchBy: t('main_menu.cardinal_health.main'),
        //   name: 'main_menu.cardinal_health.main',
        //   subitems: [
        //     {
        //       searchBy: t('main_menu.production.reports.main'),
        //       name: 'main_menu.reports.cardinal_health_main',
        //       url: paths.CARDINAL_HEALTH_REPORTS,
        //       type: 'reports',
        //     },
        //     {
        //       searchBy: t('main_menu.cardinal_health.upload'),
        //       name: 'main_menu.cardinal_health.upload',
        //       url: paths.IMPORT_UPLOAD_DATA('cardinal-health'),
        //     },
        //   ],
        // },
        // {
        //   searchBy: t('main_menu.dorman.main'),
        //   name: 'main_menu.dorman.main',
        //   subitems: [
        //     {
        //       searchBy: t('main_menu.production.reports.main'),
        //       name: 'main_menu.reports.dorman_main',
        //       url: paths.DORMAN_REPORTS,
        //       type: 'reports',
        //     },
        //     {
        //       searchBy: t('main_menu.dorman.upload'),
        //       name: 'main_menu.dorman.upload',
        //       url: paths.IMPORT_UPLOAD_DATA('dorman'),
        //     },
        //   ],
        // },
        // {
        //   searchBy: t('main_menu.levis.main'),
        //   name: 'main_menu.levis.main',
        //   subitems: [
        //     {
        //       searchBy: t('main_menu.production.reports.main'),
        //       name: 'main_menu.reports.levis_main',
        //       url: paths.LEVIS_REPORTS,
        //       type: 'reports',
        //     },
        //     {
        //       searchBy: t('main_menu.levis.upload'),
        //       name: 'main_menu.levis.upload',
        //       url: paths.IMPORT_UPLOAD_DATA('levis'),
        //     },
        //   ],
        // },
        // {
        //   searchBy: t('main_menu.brookfield.main'),
        //   name: 'main_menu.brookfield.main',
        //   subitems: [
        //     {
        //       searchBy: t('main_menu.production.reports.main'),
        //       name: 'main_menu.reports.brookfield_main',
        //       url: paths.BROOKFIELD_REPORTS,
        //       type: 'reports',
        //     },
        //   ],
        // },
        {
          searchBy: t('main_menu.expeditors.main'),
          name: 'main_menu.expeditors.main',
          url: paths.EXPEDITORS_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.mc-entire.main'),
          name: 'main_menu.mc-entire.main',
          url: paths.MCENTIRE_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.perry-ellis.main'),
          name: 'main_menu.perry-ellis.main',
          url: paths.PERRY_ELLIS_REPORTS,
          type: 'reports',
        },
        {
          searchBy: t('main_menu.turnover_dashboard'),
          name: 'main_menu.turnover_dashboard',
          url: paths.REPORTS_TURNOVER_DASHBOARD,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.employees_active_but_not_present_list'),
          name: 'main_menu.employees_active_but_not_present_list',
          url: paths.EMPLOYEES_ACTIVE_BUT_NOT_PRESENT,
        },
        {
          searchBy: t('main_menu.employees_terminated_list'),
          name: 'main_menu.employees_terminated_list',
          url: paths.EMPLOYEES_TERMINATED,
        },
        {
          searchBy: t('main_menu.absentee'),
          name: 'main_menu.absentee',
          url: paths.HOOKY_FOLKS,
        },
        {
          searchBy: t('main_menu.chart_reports.filled_associates'),
          name: 'main_menu.chart_reports.filled_associates',
          url: paths.FILLED_ASSOCIATES,
        },
        {
          searchBy: t('main_menu.payboard_costs.main_item'),
          name: 'main_menu.payboard_costs.main_item',
          url: paths.REPORTS_COSTS,
          pageType: 'withTabs',
        },
        {
          searchBy: t('main_menu.customer_reports.cost_plus_weekly'),
          name: 'main_menu.customer_reports.cost_plus_weekly',
          url: paths.CUSTOMER_REPORTS_COST_PLUS_WEEKLY,
        },
      ],
    },
    // {
    //   searchBy: t('main_menu.recruiting_dashboard.main_item'),
    //   name: 'main_menu.recruiting_dashboard.main_item',
    //   Icon: FieSearch03Svg,
    //   subitems: [
    //     {
    //       searchBy: t('main_menu.recruiting_dashboard.summary'),
    //       name: 'main_menu.recruiting_dashboard.summary',
    //       url: paths.RECRUITING_DASHBOARD_SUMMARY,
    //       matchedUrls: [
    //         /\/recruiting-dashboard\/\d+/,
    //         /\/recruiting-dashboard\/open-position\/\d+/,
    //         /\/recruiting-dashboard\/open-position\/\d+\/details/,
    //       ],
    //     },
    //     {
    //       searchBy: t('main_menu.recruiting_dashboard.report'),
    //       name: 'main_menu.recruiting_dashboard.report',
    //       url: paths.RECRUITING_REPORT,
    //     },
    //     {
    //       searchBy: t('main_menu.recruiting_dashboard.closed_positions'),
    //       name: 'main_menu.recruiting_dashboard.closed_positions',
    //       url: paths.RECRUITING_DASHBOARD_CLOSED_POSITIONS,
    //     },
    //     {
    //       searchBy: t('main_menu.recruiting_dashboard.create_open_position'),
    //       name: 'main_menu.recruiting_dashboard.create_open_position',
    //       url: paths.RECRUITING_DASHBOARD_CREATE_OPEN_POSITION,
    //     },
    //     {
    //       searchBy: t('main_menu.recruiting_dashboard.applicants'),
    //       name: 'main_menu.recruiting_dashboard.applicants',
    //       subitems: [
    //         {
    //           searchBy: t('main_menu.recruiting_dashboard.applicants_list'),
    //           name: 'main_menu.recruiting_dashboard.applicants_list',
    //           url: paths.RECRUITING_DASHBOARD_LIST_APPLICANTS,
    //           matchedUrls: [/\/recruiting-dashboard\/applicants\/update/],
    //         },
    //         {
    //           searchBy: t('main_menu.recruiting_dashboard.applicants_create'),
    //           name: 'main_menu.recruiting_dashboard.applicants_create',
    //           url: paths.RECRUITING_DASHBOARD_CREATE_APPLICANT,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      searchBy: t('main_menu.knowledge.main_item'),
      name: 'main_menu.knowledge.main_item',
      Icon: Database02Svg,
      subitems: [
        // @TODO: We do not use it now, but might need in the future
        // {
        //   searchBy: t('main_menu.knowledge.faqs'),
        //   name: 'main_menu.knowledge.faqs',
        //   url: paths.KNOWLEDGE_BASE_FAQ,
        // },
        {
          searchBy: t('main_menu.guide'),
          name: 'main_menu.guide',
          url: externalUrls.guide,
          external: true,
        },
        {
          searchBy: t('main_menu.training_videos'),
          name: 'main_menu.training_videos',
          url: paths.TRAINING_VIDEOS,
        },
        // @TODO: This tab contains outdated data
        // {
        //   searchBy: t('main_menu.knowledge.documentation_pages'),
        //   name: 'main_menu.knowledge.documentation_pages',
        //   url: paths.KNOWLEDGE_BASE_DOCUMENTATION_PAGES,
        // },
      ],
    },
    {
      searchBy: t('main_menu.knowledge.support_request.main'),
      name: 'main_menu.knowledge.support_request.main',
      Icon: PhoneCall02Svg,
      subitems: [
        {
          searchBy: t('main_menu.knowledge.support_request.list'),
          name: 'main_menu.knowledge.support_request.list',
          url: paths.ISSUE_TRACKER_LIST,
          matchedUrls: [/\/issue-tracker\/details\/[0-9]+/],
        },
        {
          searchBy: t('main_menu.knowledge.support_request.create'),
          name: 'main_menu.knowledge.support_request.create',
          url: paths.ISSUE_TRACKER_CREATE,
        },
      ],
    },
    {
      searchBy: t('main_menu.policy.main_item'),
      name: 'main_menu.policy.main_item',
      Icon: DotPoints02Svg,
      subitems: [
        {
          searchBy: t('main_menu.policy.list'),
          name: 'main_menu.policy.list',
          url: paths.POLICIES,
          matchedUrls: [/\/policies\/update/],
        },
        {
          searchBy: t('main_menu.policy.create'),
          name: 'main_menu.policy.create',
          url: paths.POLICIES_CREATE,
        },
        {
          searchBy: t('policies.create_form_by_page_title'),
          name: 'policies.create_form_by_page_title',
          url: paths.POLICIES_CREATE_BY_PAGE,
        },
      ],
    },
    {
      searchBy: t('main_menu.checklist'),
      name: 'main_menu.checklist',
      url: paths.CHECKLIST,
      Icon: CheckDone02Svg,
    },
    {
      searchBy: t('main_menu.changelog.main'),
      name: 'main_menu.changelog.main',
      Icon: BoxSvg,
      subitems: [
        {
          searchBy: t('main_menu.changelog.log'),
          name: 'main_menu.changelog.log',
          url: paths.CHANGELOG,
        },
        {
          searchBy: t('main_menu.changelog.history.employee.title'),
          name: 'main_menu.changelog.history.employee.title',
          url: paths.CHANGELOG_HISTORY_EMPLOYEE,
        },
      ],
    },
    {
      searchBy: t('main_menu.setup.title'),
      name: 'main_menu.setup.title',
      Icon: SettingsSvg,
      subitems: sortBy(setupSubItems, (i) => t(i.name)),
    },
  ];
};
