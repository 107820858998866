import React from 'react';
import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from '../FilterPanelCombobox';
import { useSelector } from 'react-redux';
import { useFetchEmployeesCombobox } from 'src/modules/utils';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { map } from 'lodash';
import { ComboBoxOption } from 'src/components/ComboBox';
import { getEmployeesComboboxList } from '../../../../modules/selectors/employee';

type IFilterPanelEmployeesComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  filterId: string;
  siteId?: number;
  onRemoveFilter?: (filterId: string) => void;
  fieldAsId?: 'id' | 'name' | 'employeeId';
  value?: number | null;
};

export const FilterPanelEmployeesCombobox = ({
  filterId,
  fieldAsId = 'id',
  value,
  siteId,
  ...props
}: IFilterPanelEmployeesComboboxProps) => {
  const currentRole = useSelector(getCurrentRole);

  const fetchEmployeesCombobox = useFetchEmployeesCombobox(siteId);

  React.useEffect(() => {
    fetchEmployeesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole, siteId]);

  const employees = useSelector(getEmployeesComboboxList);

  const options = (React.useMemo(
    () =>
      map(employees, (employee) => ({
        id: employee[fieldAsId],
        name: employee.name,
      })),
    [fieldAsId, employees],
  ) as unknown) as ComboBoxOption[];

  const comboboxValue = options.find(({ id }) => id === value);

  return (
    <FilterPanelCombobox
      value={comboboxValue ?? null}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
