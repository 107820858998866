import React from 'react';
import { Sheet } from '@mui/joy';
import { AnyObject } from 'src/modules/types';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SupervisorComboBox from 'src/components/Formik/comboboxes-with-entities/SupervisorComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { useSelector } from 'react-redux';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { getShiftsComboboxListBySiteId } from 'src/modules/selectors/shift';
import { getDepartmentsComboboxListBySiteId } from 'src/modules/selectors/department';
import { getPositionsComboboxListBySiteId } from 'src/modules/selectors/position';
import { ComboBoxOption } from 'src/components/ComboBox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import { useEmployeeFormTourConfig } from 'src/config/tours/employees';

interface ICreateEmployeeRequireFieldsProps {
  formik: AnyObject;
}

export const CreateEmployeeRequireFields = ({
  formik,
}: ICreateEmployeeRequireFieldsProps) => {
  const { t } = useTranslation();

  const employeeFormTourConfig = useEmployeeFormTourConfig();

  const selectedSiteId = formik.values.siteId;

  const staffingProviders = useSelector(getStaffingProvidersComboboxList);
  const shifts = useSelector(getShiftsComboboxListBySiteId)(selectedSiteId);
  const departments = useSelector(getDepartmentsComboboxListBySiteId)(
    selectedSiteId,
  );
  const positions = useSelector(getPositionsComboboxListBySiteId)(
    selectedSiteId,
  );

  return (
    <Sheet sx={{ bgcolor: 'inherit' }}>
      <FormFieldContainer>
        <SitesComboBox
          id="siteId"
          required={true}
          formik={formik}
          label={t('employees.site')}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.site.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          required
          fullWidth
          id="firstName"
          label={t('employees.first_name')}
          name="firstName"
          //
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.firstName.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id="lastName"
          label={t('employees.last_name')}
          name="lastName"
          //
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.lastName.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id="employeeId"
          label={t('employees.emp_id')}
          name="employeeId"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.employeeId.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id="badge"
          label={t('employees.badge')}
          name="badge"
          type="number"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.badge.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id="positionId"
          label={t('employees.position')}
          options={positions as ComboBoxOption[]}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.position.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id="staffingId"
          label={t('employees.staffing_provider')}
          options={staffingProviders as ComboBoxOption[]}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.staffingProvider.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SupervisorComboBox
          required
          activeOnly
          id="supervisorId"
          siteIdPathInFormik="siteId"
          label={t('employees.supervisor')}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.supervisor.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id="payRate"
          label={t('employees.pay_rate')}
          name="payRate"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.payRate.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikDatepicker
          required
          fullWidth
          label={t('employees.date_hired')}
          id="dateHired"
          formik={formik as any}
          data-tour={employeeFormTourConfig.tour.dateHired.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id="defaultShiftId"
          label={t('employees.default_shift')}
          options={shifts}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.defaultShift.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id="defaultDepartmentId"
          label={t('employees.default_department')}
          options={departments}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.defaultDepartment.target}
        />
      </FormFieldContainer>
    </Sheet>
  );
};
