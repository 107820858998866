import React, { useCallback, useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getBaselinesByIds } from '../../modules/selectors/baseline.selector';
import {
  useFetchMetatagsByIds,
  useFetchSitesCombobox,
  useQueryParams,
} from '../../modules/utils/hooks';
import { IdsArray, IStoreState, IUpdateBaseline } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchBaselines } from '../../modules/utils/hooks';
import { updateBaselinesRequest } from '../../modules/actions';
import { getCurrentRole } from '../../modules/selectors/auth';
import { getMetatagsNames } from '../../modules/selectors/metatag';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { useValidate } from '../../modules/utils';
import { generateUpdateBaselineScheme } from '../../modules/schemes/baseline';
import { useBrowserHistoryFunctions } from '../../modules/utils';
import Metatags from '../Productions/components/Metatags';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IUpdateBaselineProps {
  baselines: IUpdateBaseline[];
  onSubmit: (baselines: IUpdateBaseline[]) => void;
}

const BaselineUpdateForm = ({ onSubmit, baselines }: IUpdateBaselineProps) => {
  const { t } = useTranslation();

  const metatagNames = useSelector(getMetatagsNames, shallowEqual);

  const sitesIdSet = new Set<number>();
  baselines.forEach(({ siteId }) => sitesIdSet.add(siteId));
  const sitesId = [...sitesIdSet.values()];
  const metatags = sitesId.reduce<string[]>((all, siteId) => {
    if (metatagNames[siteId]) {
      return [...all, ...metatagNames[siteId]];
    }
    return all;
  }, []);
  const updateBaselinesScheme = generateUpdateBaselineScheme(metatags);
  const validate = useValidate(updateBaselinesScheme);

  const formik = useFormikInUpdateForm({
    initialValues: baselines,
    validate,
    onSubmit,
  });
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchMetatags = useFetchMetatagsByIds();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch clients and baselines from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    fetchMetatags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // get metatags names by site id
  const getMetatagsBySite = useCallback(
    (siteId: number) => metatagNames[siteId] ?? [],
    [metatagNames],
  );

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (baseline, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('baseline.update_form_title', baseline)}
          key={baseline.id}
        >
          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('baseline.baseline')}
              id={`${index}.baseline`}
              name={`${index}.baseline`}
              autoComplete="baseline"
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('baseline.min_cpu')}
              id={`${index}.minCpu`}
              name={`${index}.minCpu`}
              autoComplete="minCpu"
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('baseline.max_cpu')}
              id={`${index}.maxCpu`}
              name={`${index}.maxCpu`}
              autoComplete="maxCpu"
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          {map(getMetatagsBySite(baseline.siteId), (metatag: string) => {
            return (
              <Metatags
                name={metatag}
                formik={formik}
                index={index}
                key={metatag}
              />
            );
          })}
        </CreateForm>
      ))}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const BaselineUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids, siteId } = (useQueryParams() as unknown) as {
    ids: IdsArray;
    siteId: number;
  };

  const fetchBaselines = useFetchBaselines(siteId, ids);

  // fetch baselines list from store
  const baselines = useSelector(
    (state) => getBaselinesByIds(state as IStoreState)(siteId, ids),
    shallowEqual,
  );

  // make request to fetch baselines from the server if we don't have them in the store
  useEffect(() => {
    fetchBaselines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated baselines
   */
  const handleFormSubmit = (data: IUpdateBaseline[]) => {
    dispatcher(updateBaselinesRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <BaselineUpdateForm baselines={baselines} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default BaselineUpdate;
