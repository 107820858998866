import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useFetchSitesPricingTypes, useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  ICreatePricingRangeApiRequest,
  ICreatePricingRangeRequest,
  ICreatePricingSingleRequest,
  PricingSettingsConfig,
  PricingType,
  PricingTypeObject,
} from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { addProcessStatus } from 'src/modules/actions';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { get, noop, reduce, set } from 'lodash';
import * as Yup from 'yup';
import { PricingCreateSingleRecord } from './PricingCreateSingleRecord';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import { PricingCreateRangeRecords } from './PricingCreateRangeRecords';
import {
  generateCreatePricingRangeScheme,
  generateCreatePricingSingleScheme,
} from 'src/modules/schemes/pricing';
import { usePricingSettings } from 'src/modules/utils/hooks/pricing-settings';
import PricingMetatags from 'src/pages/Pricing/PricingMetatags';

const initialValues = {
  siteId: NaN,
};

const generateSingleRecordInitialValues = (
  settings: PricingSettingsConfig,
  siteId: number,
) => {
  const meta = reduce<AnyObject, AnyObject>(
    settings,
    (acc, cur) => {
      if (!cur.disabled) {
        if (cur.type === 'range') {
          set(acc, ['metadata', cur.name, 'min'], '');
          set(acc, ['metadata', cur.name, 'max'], '');
        } else {
          acc[cur.name] = undefined;
        }
      }

      return acc;
    },
    {},
  );

  return {
    price: NaN,
    incentivePrice: NaN,
    overtimePrice: NaN,
    minUph: '-1000',
    maxUph: NaN,
    siteId,
    description: '',
    ...meta,
  };
};

const generateRangeRecordsInitialValues = (
  metatags: string[],
  siteId: number,
) => {
  const meta = metatags.reduce((all, metatag) => {
    all[metatag] = undefined;

    return all;
  }, {});

  const initials: ICreatePricingRangeRequest = {
    siteId: siteId,
    bulks: [
      {
        price: '',
        incentivePrice: '',
        overtimePrice: '',
        minUph: '-1000',
        maxUph: NaN,
      },
      {
        price: '',
        incentivePrice: '',
        overtimePrice: '',
        minUph: '0',
        maxUph: NaN,
      },
      {
        price: '',
        incentivePrice: '',
        overtimePrice: '',
        minUph: '0',
        maxUph: '1000',
      },
    ],
    ...meta,
  };

  return initials;
};

interface IPricingCreateRecordProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (siteId: number) => void;
}

export const PricingCreateRecord = ({
  isOpen,
  onClose,
  onSuccess,
}: IPricingCreateRecordProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const metatagNames = useSelector(getMetatagsNames);

  const validationSchema = Yup.object({
    siteId: Yup.string().required(t('error.validation.required')),
  });

  const [
    pricingTypesList,
    setPricingTypesList,
  ] = React.useState<PricingTypeObject | null>();
  const [pricingType, setPricingType] = React.useState<PricingType | null>(
    null,
  );

  const fetchPricingTypes = useFetchSitesPricingTypes();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: noop,
  });

  const siteId = formik.values.siteId;

  const { settings } = usePricingSettings(siteId);

  const metatags = React.useMemo(
    () =>
      (siteId && settings && metatagNames[siteId]
        ? metatagNames[siteId]
        : []
      ).filter((mt) => !get(settings, [mt, 'disabled'])),
    [siteId, settings, metatagNames],
  );

  const singleRecordFormik = useFormik({
    enableReinitialize: true,
    validate: useValidate(generateCreatePricingSingleScheme(settings)),
    initialValues: generateSingleRecordInitialValues(settings, siteId),
    onSubmit: async (data) => {
      try {
        await Api.Pricing.createSingle({
          ...data,
          price: Number(data.price),
          incentivePrice: Number(data.incentivePrice),
          overtimePrice: Number(data.overtimePrice),
          minUph: Number(data.minUph),
          maxUph: Number(data.maxUph),
        });

        onSuccess(data.siteId);

        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        formik.resetForm();
        singleRecordFormik.resetForm();
      } catch {}
    },
  });

  const rangeRecordsFormik = useFormik({
    // enableReinitialize: true,
    initialValues: generateRangeRecordsInitialValues(metatags, siteId),
    validate: useValidate(generateCreatePricingRangeScheme(settings)),
    onSubmit: async (data) => {
      const { bulks, siteId, ...metatags } = data;
      const payload: ICreatePricingRangeApiRequest = bulks.reduce(
        (acc, cur, index, array): ICreatePricingRangeApiRequest => {
          const {
            price,
            incentivePrice,
            overtimePrice,
            minUph,
            maxUph,
            description,
          } = cur;

          const item: ICreatePricingSingleRequest = {
            siteId,
            price: +price,
            incentivePrice: +incentivePrice,
            overtimePrice: +overtimePrice,
            minUph: Number(index === 0 ? minUph : array[index - 1].maxUph),
            maxUph: Number(maxUph),
            ...metatags,
          };
          if (description) {
            item.description = description;
          }
          acc.push(item);
          return acc;
        },
        ([] as unknown) as ICreatePricingRangeApiRequest,
      );

      try {
        await Api.Pricing.createRange(payload);

        onSuccess(data.siteId);

        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        formik.resetForm();
        rangeRecordsFormik.resetForm();
      } catch {}
    },
  });

  const pricingMetatags = (
    <PricingMetatags
      settings={settings}
      formik={
        pricingType === 'single' ? singleRecordFormik : rangeRecordsFormik
      }
      siteId={siteId}
    />
  );

  const resetAllForms = () => {
    formik.resetForm();
    singleRecordFormik.resetForm();
    rangeRecordsFormik.resetForm();
  };

  const onReset = () => {
    // singleRecordFormik.resetForm();
    resetAllForms();
  };

  const onApply = () => {
    if (pricingType === 'single') {
      singleRecordFormik.handleSubmit();
    }
    if (pricingType === 'range') {
      rangeRecordsFormik.handleSubmit();
    }
  };

  React.useEffect(() => {
    singleRecordFormik.setValues({ ...singleRecordFormik.values, siteId });
    rangeRecordsFormik.setValues({ ...rangeRecordsFormik.values, siteId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  React.useEffect(() => {
    const getPricingTypes = async () => {
      const pricingTypes = await fetchPricingTypes();
      setPricingTypesList(pricingTypes);
    };

    getPricingTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setPricingType(
      siteId && pricingTypesList ? pricingTypesList[siteId] : null,
    );
  }, [siteId, pricingTypesList]);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        slotProps={{ content: { sx: { minWidth: 600 } } }}
      >
        <DrawerHeader onCloseClick={onClose}>
          {t('pricing.create_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <SitesComboBox
                id="siteId"
                required={true}
                formik={formik}
                label={t('employees.site')}
                placeholder={t('employees.site')}
              />
            </FormFieldContainer>

            {pricingType === 'single' && formik.values.siteId && (
              <PricingCreateSingleRecord
                metatags={metatags}
                formik={singleRecordFormik}
                pricingMetatags={pricingMetatags}
              />
            )}
            {pricingType === 'range' && formik.values.siteId && (
              <PricingCreateRangeRecords
                formik={rangeRecordsFormik}
                pricingMetatags={pricingMetatags}
              />
            )}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={onApply}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
