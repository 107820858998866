import { has, reduce, findIndex, sortBy, isEmpty, get } from 'lodash';
import { createSelector } from 'reselect';
import {
  IDailyHoursReportItem,
  IPayboardPieChartClient,
  IPayboardPieChartsPreparedData,
  IPayboardState,
  IStoreState,
  PayboardDetailsRequest,
} from '../types';
import {
  IColumnWithStackingAndGroupingChartConfigArgs,
  ISeriesSet,
} from '../../components/Charts/3d/ColumnWithStackingAndGroupingChart';
import {
  formatKronosPayboardItems,
  formatKronosPayboardSummary,
} from '../utils/helpers/formatters';
import {
  parse,
  composeDate,
  DATETIME_TIMEZONE,
  isSameSecond,
  isBefore,
  DATE_FORMAT,
  format,
  getUnixTime,
} from '../../modules/utils/dateWrapper';
import {
  SeriesAreaOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
} from 'highcharts';
import { MILLISECONDS_NUMBER_IN_SECOND } from '../utils/dateWrapper/constants';
import theme from '../../assets/css/theme';
import Highcharts from 'highcharts';
import { priceToNumber } from '../utils/helpers/common';

export const getPayboardData = (state: IStoreState) => state.payboard;

/**
 * Get server error
 * @param payboard - State payboard
 */
export const getServerError = ({ payboard }: IStoreState) => payboard.error;

/**
 * Get payboard list
 * @param payboard - State payboard
 */
export const getPayboardList = ({ payboard }: IStoreState) => payboard.list;

/**
 * Get payboard count
 * @param payboard - State payboard
 */
export const getPayboardCount = ({ payboard }: IStoreState) => payboard.count;

/**
 * Get payboard detailed list
 * @param payboard - State payboardDEtailed
 */
export const getPayboardDetailedList = ({ payboard }: IStoreState) =>
  payboard.listDetailed;

/**
 * Get payboard count
 * @param payboard - State payboard
 */
export const getPayboardDetailedCount = ({ payboard }: IStoreState) =>
  payboard.countDetailed;

/**
 * Get weekly payboard detailed list
 * @param payboard - State payboardDEtailed
 */
export const getPayboardWeeklyDetailedList = ({ payboard }: IStoreState) =>
  payboard.listWeeklyDetailed;

/**
 * Get weekly payboard detailed count
 * @param payboard
 */
export const getPayboardWeeklyDetailedCount = ({ payboard }: IStoreState) =>
  payboard.countWeeklyDetailed;

/**
 * Get payboard details
 * @param payboard - State payboard
 */
export const getPayboardDetails = ({ payboard }: IStoreState) =>
  payboard.details;

/**
 * Get payboard totals
 * @param payboard - State payboard
 */
export const getPayboardDetailsData = createSelector(
  getPayboardDetails,
  (weeks) => (payload: PayboardDetailsRequest) =>
    has(weeks, [payload.year, payload.week, payload.badge])
      ? weeks[payload.year][payload.week][payload.badge]
      : {
          items: [],
          total: 0,
          count: 0,
        },
);

/**
 * Get payboard weeks
 * @param payboard - State payboard
 */
export const getPayboardWeeks = ({ payboard }: IStoreState) => payboard.weeks;

export const getPayboardDays = ({ payboard }: IStoreState) => payboard.days;

export const getNewPayboardWeeks = createSelector(
  getPayboardData,
  ({ newWeeks }) => {
    const { summary: rawSummary, items: rawItems } = newWeeks;

    const items = formatKronosPayboardItems(rawItems);

    const summary = formatKronosPayboardSummary(rawSummary);

    return { summary, items };
  },
);

export const getCostsByDepartmentSeries = createSelector(
  getPayboardData,
  ({ costs: { sumByDepartment } }) => {
    const { chartColorScheme } = theme;
    /**
     * Decided to sort data because of specific pie chart data matching
     * totals sector with boundaries of weeks sectors
     */
    const sortedItems = sortBy(sumByDepartment.items, ['client', 'site']);

    /**
     * Part 1
     * Collecting data in order to have count of departments for each of site
     * to have ability to determine brightness offset later for chart colors
     */
    const selectingData = reduce(
      sortedItems,
      (acc, cur) => {
        // fill clients
        let clientIndex = findIndex(acc, (i) => i.name === cur.client);
        if (clientIndex === -1) {
          acc.push({
            name: cur.client,
            sites: [],
          });
          clientIndex = acc.length - 1;
        }

        // fill sites of certain client
        let siteIndex = findIndex(
          acc[clientIndex].sites,
          (i) => i.name === cur.site,
        );
        if (siteIndex === -1) {
          acc[clientIndex].sites.push({
            name: cur.site,
            departments: [],
            data: [],
            totalsData: [],
            weeksData: [],
          });
          siteIndex = acc[clientIndex].sites.length - 1;
        }
        const departmentIndex = findIndex(
          acc[clientIndex].sites[siteIndex].departments,
          (i) => i === cur.department,
        );
        if (departmentIndex === -1) {
          acc[clientIndex].sites[siteIndex].departments.push(cur.department);
        }

        let dataIndex = findIndex(
          acc[clientIndex].sites[siteIndex].data,
          (i: IPayboardPieChartsPreparedData) =>
            i.drilldown.name === cur.department,
        );
        if (dataIndex === -1) {
          acc[clientIndex].sites[siteIndex].data.push({
            y: 0,
            color: '',
            drilldown: {
              name: cur.department,
              categories: [],
              data: [],
            },
          });
          dataIndex = acc[clientIndex].sites[siteIndex].data.length - 1;
        }

        /**
         * IPayboardPieChartsPreparedData.y
         * y: 0, <================================
         * color: '',
         * drilldown: {
         *      name: cur.department,
         *      categories: [],
         *      data: [],
         *    },
         */
        acc[clientIndex].sites[siteIndex].data[dataIndex].y += cur.payment;

        /**
         * IPayboardPieChartsPreparedData.color
         * y: 0,
         * color: '', <================================
         * drilldown: {
         *      name: cur.department,
         *      categories: [],
         *      data: [],
         *    },
         */
        if (isEmpty(acc[clientIndex].sites[siteIndex].data[dataIndex].color)) {
          const colorIndex =
            acc[clientIndex].sites[siteIndex].departments.length %
            chartColorScheme.length;
          acc[clientIndex].sites[siteIndex].data[dataIndex].color =
            chartColorScheme[colorIndex];
        }

        /**
         * IPayboardPieChartsPreparedData.drilldown.categories[]
         * y: 0,
         * color: '',
         * drilldown: {
         *      name: cur.department,
         *      categories: [], <================================
         *      data: [],
         *    },
         */
        acc[clientIndex].sites[siteIndex].data[
          dataIndex
        ].drilldown.categories.push(`Week ${cur.week}(${cur.year})`);

        /**
         * IPayboardPieChartsPreparedData.drilldown.data[]
         * y: 0,
         * color: '',
         * drilldown: {
         *      name: cur.department,
         *      categories: [],
         *      data: [], <================================
         *    },
         */
        acc[clientIndex].sites[siteIndex].data[dataIndex].drilldown.data.push(
          cur.payment,
        );

        return acc;
      },
      ([] as unknown) as IPayboardPieChartClient[],
    );

    /**
     * Part 2
     * Fill the data for pie chart series with appropriate colors
     */
    selectingData.forEach((clien) => {
      clien.sites.forEach((site) => {
        site.data.forEach((data) => {
          /**
           * Fill IPayboardPieChartSite.totalsData
           * name: cur.site,
           * departments: [],
           * data: [],
           * totalsData: [], <================================
           * weeksData: [],
           */
          site.totalsData.push({
            name: data.drilldown.name,
            y: +data.y.toFixed(2),
            color: data.color,
          });

          /**
           * Fill IPayboardPieChartSite.weeksData
           * name: cur.site,
           * departments: [],
           * data: [],
           * totalsData: [],
           * weeksData: [], <================================
           */
          data.drilldown.categories.forEach(
            (category, categoryIndex, categoryArray) => {
              // magic numbers 0.2 and 5 were taken from https://jsfiddle.net/3acoLtz5
              const brightness = 0.2 - categoryIndex / categoryArray.length / 5;
              site.weeksData.push({
                name: category,
                y: +data.drilldown.data[categoryIndex].toFixed(2),
                color: Highcharts.color(data.color).brighten(brightness).get(),
              });
            },
          );
        });
      });
    });
    return selectingData;
  },
);

export const getCostsByDaySeries = createSelector(
  getPayboardData,
  ({ costs: { sumByDay } }) =>
    reduce(
      sumByDay.items,
      (acc, cur) => {
        let index = findIndex(acc, (i) => i.name === cur.site);
        if (index === -1) {
          acc.push({
            type: 'line',
            name: cur.site,
            data: [],
          });
          index = acc.length - 1;
        }
        acc[index].data!.push({
          x:
            composeDate(cur.date, parse(DATE_FORMAT), getUnixTime()) *
            MILLISECONDS_NUMBER_IN_SECOND,
          y: cur.payment,
        });
        return acc;
      },
      [] as SeriesLineOptions[],
    ),
);

export const getCostsByDayAndDepartment = createSelector(
  getPayboardData,
  ({
    payboard: {
      costs: { sumByDayAndDepartment },
    },
  }: {
    payboard: IPayboardState;
  }) =>
    reduce(
      sumByDayAndDepartment.items,
      (acc, cur) => {
        acc[cur.site] = acc[cur.site] ?? {
          categories: new Set(),
          series: [],
        };

        let departmentIndex = findIndex(
          acc[cur.site].series,
          (i) => i.name === cur.department,
        );

        acc[cur.site].categories.add(
          composeDate(cur.date, parse(DATE_FORMAT), format(DATE_FORMAT)),
        );

        if (departmentIndex === -1) {
          acc[cur.site].series.push({
            type: 'area',
            name: cur.department,
            data: [],
          });
          departmentIndex = acc[cur.site].series.length - 1;
        }
        acc[cur.site].series[departmentIndex].data!.push(cur.payment);
        return acc;
      },
      {} as {
        [site: string]: {
          categories: Set<string>;
          series: SeriesAreaOptions[];
        };
      },
    ),
  (state, sites) =>
    reduce(
      sites,
      (acc, cur, site) => {
        acc[site] = {
          ...cur,
          categories: Array.from(cur.categories),
        };
        return acc;
      },
      {},
    ),
);

/**
 * Get payboard weekly data count
 * @param payboard - State payboard
 */
export const getPayboardWeekCount = ({ payboard }: IStoreState) =>
  payboard.count;

export const getPayboardDayCount = ({ payboard }: IStoreState) =>
  payboard.count;

export const getDailyHoursSummaryReportDataCount = createSelector(
  getPayboardData,
  ({ count }) => count,
);

export const getDailyHoursSummaryReportData = ({ payboard }: IStoreState) =>
  payboard.dailyHoursSummaryData;

export const getComputedDailyHoursSummaryReportData = createSelector(
  getDailyHoursSummaryReportData,
  (dailyHoursSummaryData) => ({
    summary: {
      ...dailyHoursSummaryData.summary,
    },
    items: [...dailyHoursSummaryData.items],
  }),
);

export const getDailyHoursSummaryReportDataForCharts = createSelector(
  getComputedDailyHoursSummaryReportData,
  (dailyHoursSummaryData) =>
    [...dailyHoursSummaryData.items].sort((a, b) => {
      const aDate = composeDate(a.date, parse(DATETIME_TIMEZONE));
      const bDate = composeDate(b.date, parse(DATETIME_TIMEZONE));

      if (isSameSecond(aDate, bDate)) {
        return 0;
      }

      return isBefore(aDate, bDate) ? -1 : 1;
    }),
);

const getDailyHoursSummaryReportDataForChartsConverted = createSelector(
  getDailyHoursSummaryReportDataForCharts,
  (items) => {
    return items.reduce<IDailyHoursReportItem[]>((acc, cur) => {
      const totals = ['pay', 'stdMarkup', 'costPlus'].reduce((acc, index) => {
        acc[index] = priceToNumber(get(cur, index));
        return acc;
      }, {} as Pick<IDailyHoursReportItem, 'pay' | 'stdMarkup' | 'costPlus'>);

      acc.push({
        ...cur,
        ...totals,
      });

      return acc;
    }, []);
  },
);

const getTotalsGroupedByDatesAndDepartments = createSelector(
  getDailyHoursSummaryReportDataForChartsConverted,
  (items) => {
    const totalsObj = items.reduce<{
      [key: string]: {
        [key: string]: {
          totalPay: number;
          totalStdMarkup: number;
          totalCostPlus: number;
          totalEmployees: number;
        };
      };
    }>((obj, item) => {
      if (!obj[item.date]) {
        obj[item.date] = {};
      }
      if (!obj[item.date][item.department]) {
        obj[item.date][item.department] = {
          totalPay: item.pay,
          totalStdMarkup: item.stdMarkup,
          totalCostPlus: item.costPlus,
          totalEmployees: 1,
        };
      } else {
        obj[item.date] = {
          ...obj[item.date],
          [item.department]: {
            ...obj[item.date][item.department],
            totalPay: obj[item.date][item.department]['totalPay'] + item.pay,
            totalStdMarkup:
              obj[item.date][item.department]['totalStdMarkup'] +
              item.stdMarkup,
            totalCostPlus:
              obj[item.date][item.department]['totalCostPlus'] + item.costPlus,
            totalEmployees:
              obj[item.date][item.department]['totalEmployees'] + 1,
          },
        };
      }

      return obj;
    }, {});

    return totalsObj;
  },
);

const getDistinctDepartments = createSelector(
  getDailyHoursSummaryReportData,
  ({ items }) => {
    const departmentsSet = new Set<string>();

    items.forEach((item) => departmentsSet.add(item.department));

    return Array.from(departmentsSet.values());
  },
);

export const getPayboardPaymentsSumChartsData = createSelector(
  getTotalsGroupedByDatesAndDepartments,
  getDistinctDepartments,
  (totals, departments) => {
    const dates = Object.keys(totals);

    const departmentsDataPerDate = Object.values(totals) as any;

    const series = departments.reduce<{
      paySeries: SeriesColumnOptions[];
      stdMarkupSeries: SeriesColumnOptions[];
      costPlusSeries: SeriesColumnOptions[];
    }>(
      (chartSeries, departmentName) => {
        const payData = departmentsDataPerDate.map(
          (departmentData: any) =>
            +departmentData[departmentName]?.totalPay.toFixed(2) ?? null,
        );
        const stdMarkupData = departmentsDataPerDate.map(
          (departmentData: any) =>
            +departmentData[departmentName]?.totalStdMarkup.toFixed(2) ?? null,
        );
        const totalCostPlusData = departmentsDataPerDate.map(
          (departmentData: any) =>
            +departmentData[departmentName]?.totalCostPlus.toFixed(2) ?? null,
        );

        chartSeries.paySeries.push({
          type: 'column',
          name: departmentName,
          stack: departmentName,
          data: payData,
        });

        chartSeries.stdMarkupSeries.push({
          type: 'column',
          name: departmentName,
          stack: departmentName,
          data: stdMarkupData,
        });

        chartSeries.costPlusSeries.push({
          type: 'column',
          name: departmentName,
          stack: departmentName,
          data: totalCostPlusData,
        });

        return chartSeries;
      },
      {
        paySeries: [],
        stdMarkupSeries: [],
        costPlusSeries: [],
      },
    );

    return {
      series,
      categories: dates,
    };
  },
);

export const getPayboardDepartmentsChartsData = createSelector(
  getDailyHoursSummaryReportDataForChartsConverted,
  getDistinctDepartments,
  (items, departments) => {
    const datesSet = new Set<string>();
    items.forEach((item) => datesSet.add(item.date));
    const dates = Array.from(datesSet.values());

    return departments.reduce<{
      [department: string]: {
        categories: IColumnWithStackingAndGroupingChartConfigArgs['categories'];
        paySeries: ISeriesSet[];
        stdMarkupSeries: ISeriesSet[];
        costPlusSeries: ISeriesSet[];
      };
    }>((chartsData, departmentName) => {
      const employeesInDepartment = items.filter(
        (item) => item.department === departmentName,
      );

      const employeesPaymentData = employeesInDepartment.reduce<{
        [employeeName: string]: {
          pay: number[];
          stdMarkup: number[];
          costPlus: number[];
        };
      }>((paymentData, employee) => {
        const employeeFullName = `${employee.firstName} ${employee.lastName}`;

        const dataIndex = dates.findIndex((date) =>
          isSameSecond(
            composeDate(date, parse(DATE_FORMAT)),
            composeDate(employee.date, parse(DATE_FORMAT)),
          ),
        );

        if (!paymentData[employeeFullName]) {
          paymentData[employeeFullName] = {
            pay: Array(dates.length).fill(null),
            stdMarkup: Array(dates.length).fill(null),
            costPlus: Array(dates.length).fill(null),
          };
        }

        paymentData[employeeFullName].pay[dataIndex] = employee.pay;
        paymentData[employeeFullName].stdMarkup[dataIndex] = employee.stdMarkup;
        paymentData[employeeFullName].costPlus[dataIndex] = employee.costPlus;

        return paymentData;
      }, {});

      const seriesByPaymentType = Object.keys(employeesPaymentData).reduce<{
        paySeries: ISeriesSet[];
        stdMarkupSeries: ISeriesSet[];
        costPlusSeries: ISeriesSet[];
      }>(
        (series, employeeName) => {
          series.paySeries.push({
            name: employeeName,
            stack: 'pay',
            data: employeesPaymentData[employeeName].pay,
          });
          series.stdMarkupSeries.push({
            name: employeeName,
            stack: 'stdMarkup',
            data: employeesPaymentData[employeeName].stdMarkup,
          });
          series.costPlusSeries.push({
            name: employeeName,
            stack: 'costPlus',
            data: employeesPaymentData[employeeName].costPlus,
          });

          return series;
        },
        {
          paySeries: [],
          stdMarkupSeries: [],
          costPlusSeries: [],
        },
      );
      return {
        ...chartsData,
        [departmentName]: {
          categories: dates,
          ...seriesByPaymentType,
        },
      };
    }, {});
  },
);

export const getIsNewPayboardWeekDataRequestInProgress = createSelector(
  getPayboardData,
  (data) =>
    data.isNewPayboardWeekListRequestInProgress ||
    data.isNewPayboardWeekCountRequestInProgress,
);

export const getIsDailyHrsLoading = createSelector(
  getPayboardData,
  ({
    is_daily_hrs_data_requested,
    is_data_requested,
    is_summary_count_loading,
  }) =>
    is_daily_hrs_data_requested ||
    is_data_requested ||
    is_summary_count_loading,
);

export const getIsPayboardSummaryDataLoading = createSelector(
  getPayboardData,
  ({ is_summary_list_loading, is_summary_count_loading, is_data_requested }) =>
    is_summary_list_loading || is_summary_count_loading || is_data_requested,
);

export const getIsPayboardDetailedDataLoading = createSelector(
  getPayboardData,
  ({
    is_detailed_list_loading,
    is_detailed_count_loading,
    is_data_requested,
  }) =>
    is_detailed_list_loading || is_detailed_count_loading || is_data_requested,
);

export const getIsPayboardByWeekDataLoading = createSelector(
  getPayboardData,
  ({ is_by_week_list_loading, is_by_week_count_loading, is_data_requested }) =>
    is_by_week_list_loading || is_by_week_count_loading || is_data_requested,
);

export const getIsPayboardByDayDataLoading = createSelector(
  getPayboardData,
  ({ is_by_day_list_loading, is_by_day_count_loading, is_data_requested }) =>
    is_by_day_list_loading || is_by_day_count_loading || is_data_requested,
);

export const getIsDetailsViewDataLoading = createSelector(
  getPayboardData,
  ({ is_details_view_loading, is_data_requested }) =>
    is_details_view_loading || is_data_requested,
);

export const getPayboardDailyHoursDashboardInReportData = createSelector(
  getPayboardData,
  ({ dailyHoursDashboardInReportData }) => dailyHoursDashboardInReportData,
);
export const getPayboardDailyHoursDashboardInReportCount = createSelector(
  getPayboardData,
  ({ dailyHoursDashboardInCount }) => dailyHoursDashboardInCount,
);
export const getPayboardDailyHoursDashboardInDataLoading = createSelector(
  getPayboardData,
  ({ is_dashboard_in_requested, is_dashboard_in_count_requested }) =>
    is_dashboard_in_requested || is_dashboard_in_count_requested,
);
