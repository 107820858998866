import { JSONSchemaType } from 'ajv';
import { ICreateWeekBasedInvoice } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createWeekBasedInvoiceScheme: JSONSchemaType<ICreateWeekBasedInvoice> = {
  type: 'object',
  required: ['siteId', 'weekNumber', 'yearNumber'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    weekNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    yearNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
