import React from 'react';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  Typography,
  useTheme,
} from '@mui/joy';
import { ComboBoxOption } from 'src/components/ComboBox';
import { FilterPanelContext } from '../../FilterPanelContext';
import { ChevronDownSvg } from 'src/components/svgIcons';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

type IFilterPanelYearWeekRangeAutocompleteProps = AutocompleteProps<
  ComboBoxOption,
  undefined,
  undefined,
  undefined
> & {
  isAutocompleteInEditingMode: boolean;
};

export const FilterPanelYearWeekRangeAutocomplete = ({
  placeholder,
  isAutocompleteInEditingMode,
  ...rest
}: IFilterPanelYearWeekRangeAutocompleteProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const getOptionLabel = (option: ComboBoxOption) => option.name || '';

  const getOptionSelected = (option: ComboBoxOption, value: ComboBoxOption) =>
    isEqual(option, value);

  const { elementsHeight } = React.useContext(FilterPanelContext);

  const handleOpen = () => {
    // we need it because when user clicks Add filter button
    // or edit any filter UI of all filters are changed
    // and dropdowns shifted too much from their required positions
    if (!isAutocompleteInEditingMode) {
      setTimeout(() => setOpen((prev) => !prev), 300);
    } else {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Autocomplete
      // open={true}
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      freeSolo={(isAutocompleteInEditingMode ? false : true) as any}
      disableClearable={(isAutocompleteInEditingMode ? false : true) as any}
      placeholder={placeholder ?? t('common.select')}
      popupIcon={isAutocompleteInEditingMode ? <ChevronDownSvg /> : null}
      endDecorator={null}
      getOptionLabel={getOptionLabel}
      variant="plain"
      renderOption={(props, option) => {
        return (
          <AutocompleteOption
            {...props}
            key={props.id ?? option.id}
            sx={{ py: '9px', px: '10px' }}
          >
            <Typography
              // noWrap
              level="text_sm"
              fontWeight="medium"
              textColor="colors.text.text_secondary.hover"
            >
              {option.name}
            </Typography>
          </AutocompleteOption>
        );
      }}
      isOptionEqualToValue={getOptionSelected}
      openText={`autocomplete-trigger-for-${rest.id}`}
      closeText={`autocomplete-trigger-for-${rest.id}`}
      slotProps={{
        root: {
          sx: {
            fontSize: '0.75rem',
            '& li.MuiOption-root': {
              fontSize: '0.875rem',
            },
            borderRadius: 0,
            margin: 0,
            height: elementsHeight,
            bgcolor: 'transparent',
            '&:focus-within': {
              outline: 'none',
            },
          },
        },
        input: {
          sx: {
            '&::placeholder': {
              fontWeight: theme.fontWeight.regular,
              color: theme.palette.colors.text.text_placeholder,
              ...theme.typography.text_xs,
            },
          },
        },
      }}
      {...rest}
    />
  );
};
