import React from 'react';

import { useWikiDetailByTypeView } from 'src/modules/utils/hooks/wiki-setup.hooks';

import DetailWikiView from 'src/pages/Wiki/DetailWikiView';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';

const DetailWikiByTypeView: React.FC = () => {
  const { data: page, isLoading, isError } = useWikiDetailByTypeView();

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <DetailWikiView page={page} loading={isLoading} error={isError} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default DetailWikiByTypeView;
