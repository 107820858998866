import {
  IProductionWeeklyUphBySiteSkuAndEmployeeModel,
  IProductionWeeklyUphBySiteSkuAndEmployeeState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionWeeklyUphBySiteSkuAndEmployeeState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionWeeklyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getProductionWeeklyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getProductionWeeklyUphBySiteSkuAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  IProductionWeeklyUphBySiteSkuAndEmployeeState,
  IProductionWeeklyUphBySiteSkuAndEmployeeModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionWeeklyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteSkuAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<
  IProductionWeeklyUphBySiteSkuAndEmployeeState
>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionWeeklyUphBySiteSkuAndEmployeeRequest,
  exportSuccess: exportProductionWeeklyUphBySiteSkuAndEmployeeSuccess,
  exportFailed: exportProductionWeeklyUphBySiteSkuAndEmployeeFailed,
} = new ExportReducerCreator().takeHandlers<
  IProductionWeeklyUphBySiteSkuAndEmployeeState
>();

/**
 * actually Reducer
 */
export const productionWeeklyUphBySiteSkuAndEmployee = createReducer<
  IProductionWeeklyUphBySiteSkuAndEmployeeState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionWeeklyUphBySiteSkuAndEmployeeListRequest,
    getProductionWeeklyUphBySiteSkuAndEmployeeListRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteSkuAndEmployeeListSuccess,
    getProductionWeeklyUphBySiteSkuAndEmployeeListSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteSkuAndEmployeeListFailed,
    getProductionWeeklyUphBySiteSkuAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getProductionWeeklyUphBySiteSkuAndEmployeeCountRequest,
    getProductionWeeklyUphBySiteSkuAndEmployeeCountRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteSkuAndEmployeeCountSuccess,
    getProductionWeeklyUphBySiteSkuAndEmployeeCountSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteSkuAndEmployeeCountFailed,
    getProductionWeeklyUphBySiteSkuAndEmployeeCountFailed,
  )
  // export action
  .on(
    actions.exportProductionWeeklyUphBySiteSkuAndEmployeeRequest,
    exportProductionWeeklyUphBySiteSkuAndEmployeeRequest,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteSkuAndEmployeeSuccess,
    exportProductionWeeklyUphBySiteSkuAndEmployeeSuccess,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteSkuAndEmployeeFailed,
    exportProductionWeeklyUphBySiteSkuAndEmployeeFailed,
  );

export default productionWeeklyUphBySiteSkuAndEmployee;
