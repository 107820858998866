import React from 'react';
import { PageContent } from '../PageContent';
import Tabs, { TabsProps } from '@mui/joy/Tabs';

interface IPageContentTabsProps extends TabsProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}

export const PageContentTabs = ({
  children,
  fullHeight = true,
  ...props
}: IPageContentTabsProps) => {
  return (
    <PageContent hideCopyright fullHeight={fullHeight}>
      <Tabs
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: '100%',
        }}
        {...props}
      >
        {children}
      </Tabs>
    </PageContent>
  );
};
