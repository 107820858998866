import React, { useEffect } from 'react';
import { Grid, Button, IconButton, Box } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface IImageData {
  preview: string;
  file?: File;
}

export interface IImageDataWithKey extends IImageData {
  key?: string | number;
}

interface IUploadImageProps {
  id: string;
  onChange: (files: Array<IImageData>) => void;
  onRemove?: (imageData: IImageData) => void;
  images?: Array<IImageData>;
  btnLabel?: string;
  accept?: string;
  isImagesFromPropsSourceOfTruth?: boolean;
  skipChangeOnRemove?: boolean;
  onlyRecentlyUploadedFileOnChange?: boolean;
}

export default function UploadImages({
  id,
  onChange,
  onRemove,
  images: _images,
  accept,
  btnLabel,
  isImagesFromPropsSourceOfTruth,
  skipChangeOnRemove,
  onlyRecentlyUploadedFileOnChange,
}: IUploadImageProps) {
  const { t } = useTranslation();

  const [images, setImages] = React.useState<Array<IImageDataWithKey>>([]);

  useEffect(() => {
    if (_images && isImagesFromPropsSourceOfTruth) {
      setImages(_images.map((image) => ({ ...image })));
    }
  }, [_images, isImagesFromPropsSourceOfTruth]);

  const handleRemove = (preview: string, index: number) => {
    const { removedImage, remainedImages } = images.reduce<{
      removedImage: IImageData | null;
      remainedImages: Array<IImageData>;
    }>(
      (acc, image, i) => {
        if (i === index) {
          acc.removedImage = image;
        } else {
          acc.remainedImages.push(image);
        }
        return acc;
      },
      {
        removedImage: null,
        remainedImages: [],
      },
    );

    setImages(remainedImages);

    if (removedImage && onRemove) {
      onRemove(removedImage);
    }

    if (!skipChangeOnRemove) {
      onChange(remainedImages);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader: FileReader = new FileReader();
    const [file] = [...event.target.files!];

    fileReader.onload = () => {
      const base64Image = fileReader.result as string;

      setImages((prev) => {
        const isImageAlreadyUploaded = prev.some(
          (file) => file.preview === base64Image,
        );
        // Do not allow images duplicates
        if (isImageAlreadyUploaded) {
          const newFiles = [...prev];
          onChange(newFiles);

          return [...prev];
        }

        const newFile = {
          file: file,
          preview: base64Image,
        };

        const newFiles = [...prev, newFile];

        onChange(onlyRecentlyUploadedFileOnChange ? [newFile] : newFiles);

        return newFiles;
      });
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <Grid container={true}>
      <Grid
        sx={{
          '& > *': {
            display: 'inline-block',
            marginTop: 1,
          },
        }}
        item={true}
      >
        {images.map(({ preview, key }, i) => (
          <Box key={key ?? preview} sx={{ display: 'flex', maxWidth: '100%' }}>
            <Box
              sx={{
                position: 'relative',
                maxWidth: '100%',
                '& > img': {
                  maxWidth: '100%',
                  borderRadius: 4,
                },
              }}
            >
              <IconButton
                onClick={() => handleRemove(preview, i)}
                aria-label="delete"
                size="medium"
                sx={{
                  position: 'absolute',
                  top: 5,
                  left: 5,
                  padding: 1.5,
                  border: '1px solid transparent',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                  },
                }}
              >
                <Delete color="error" fontSize="inherit" />
              </IconButton>
              <img width="100%" src={preview} alt="preview" />
            </Box>
          </Box>
        ))}
        <Box>
          <Box
            component="input"
            value=""
            type="file"
            onChange={handleChange}
            id={id}
            accept={accept ?? 'image/*'}
            sx={{ display: 'none' }}
          />
          <label htmlFor={id}>
            <Button variant="contained" color="primary" component="span">
              {btnLabel ?? t('common.upload_media')}
            </Button>
          </label>
        </Box>
      </Grid>
    </Grid>
  );
}
