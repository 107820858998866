import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateEmployee,
  IdsArray,
  IEmployeeModel,
  IEmployeesActiveButNotPresentModel,
  IEmployeesTerminatedModel,
  IFilter,
  IHistoricalHookyFolksModel,
  IHookyFolksModel,
  IUpdateEmployee,
  PayloadWithFilters,
} from '../../types';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushEmployeeState = createAction('Flush employee data in store');

// Creation actions
export const createEmployeeRequest = createAction<
  PayloadWithFilters<ICreateEmployee>
>('Make request to create a new employee');
export const createEmployeeSuccess = createAction<IEmployeeModel>(
  'New employee has been created successfully',
);
export const createEmployeeFailed = createAction(
  'Cannot create a new employee',
);

// Fetch employees actions
export const getEmployeeListRequest = createAction<IFilter>(
  'Make request to fetch employee list',
);
export const getEmployeeListSuccess = createAction<IEmployeeModel[]>(
  'Employees list has been fetched successfully',
);
export const getEmployeeListFailed = createAction('Cannot fetch employee list');

// get employees count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getEmployeeCountRequest = createAction<IFilter>(
  'Make request to get employee count',
);
export const getEmployeeCountSuccess = createAction<ICountResponse>(
  'Employees count has been fetched successfully',
);
export const getEmployeeCountFailed = createAction('Cannot get employee count');

// delete actions
export const deleteEmployeeRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete employees',
);
export const deleteEmployeeFailed = createAction('Cannot delete employees');

// Updating actions
export const updateEmployeesRequest = createAction<
  PayloadWithFilters<IUpdateEmployee[]>
>('Make request to update employees');
export const updateEmployeesFailed = createAction('Cannot update employees');

// Fetch combobox list actions
export const getEmployeeComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of employees',
);
export const getEmployeeComboboxListSuccess = createAction<
  Partial<IEmployeeModel>[]
>('Combobox list of employees has been fetched successfully');
export const getEmployeeComboboxListFailed = createAction(
  'Cannot fetch combobox employees list',
);

// Fetch active but not present employees actions
export const getEmployeeActiveButNotPresentListRequest = createAction<IFilter>(
  'Make request to fetch active but not present employees list',
);
export const getEmployeeActiveButNotPresentListSuccess = createAction<
  IEmployeesActiveButNotPresentModel[]
>('Active but not present employees list has been fetched successfully');
export const getEmployeeActiveButNotPresentListFailed = createAction(
  'Cannot fetch active but not present employees list',
);
// Fetch count of active but not present employees actions
export const getEmployeeActiveButNotPresentCountRequest = createAction<IFilter>(
  'Make request to get count of active but not present employees count',
);
export const getEmployeeActiveButNotPresentCountSuccess = createAction<
  ICountResponse
>('Count of active but not present employees has been fetched successfully');
export const getEmployeeActiveButNotPresentCountFailed = createAction(
  'Cannot get count of active but not present employees',
);

// Fetch terminated employees actions
export const getEmployeeTerminatedListRequest = createAction<IFilter>(
  'Make request to fetch terminated employees list',
);
export const getEmployeeTerminatedListSuccess = createAction<
  IEmployeesTerminatedModel[]
>('Terminated employees list has been fetched successfully');
export const getEmployeeTerminatedListFailed = createAction(
  'Cannot fetch terminated employees list',
);
// Fetch terminated employees count actions
export const getEmployeeTerminatedCountRequest = createAction<IFilter>(
  'Make request to fetch terminated employees count',
);
export const getEmployeeTerminatedCountSuccess = createAction<ICountResponse>(
  'Terminated employees count has been fetched successfully',
);
export const getEmployeeTerminatedCountFailed = createAction(
  'Cannot fetch terminated employees count',
);

// Fetch HookyFolks actions
export const getHookyFolksListRequest = createAction<IFilter>(
  'Make request to fetch HookyFolks list',
);
export const getHookyFolksListSuccess = createAction<IHookyFolksModel[]>(
  'HookyFolks list has been fetched successfully',
);
export const getHookyFolksListFailed = createAction(
  'Cannot fetch HookyFolks list',
);
// Fetch terminated employees count actions
export const getHookyFolksCountRequest = createAction<IFilter>(
  'Make request to fetch HookyFolks count',
);
export const getHookyFolksCountSuccess = createAction<ICountResponse>(
  'HookyFolks count has been fetched successfully',
);
export const getHookyFolksCountFailed = createAction(
  'Cannot fetch HookyFolks count',
);

// Fetch HistoricalHookyFolks actions
export const getHistoricalHookyFolksListRequest = createAction<IFilter>(
  'Make request to fetch HistoricalHookyFolks list',
);
export const getHistoricalHookyFolksListSuccess = createAction<
  IHistoricalHookyFolksModel[]
>('HistoricalHookyFolks list has been fetched successfully');
export const getHistoricalHookyFolksListFailed = createAction(
  'Cannot fetch HistoricalHookyFolks list',
);
// Fetch terminated employees count actions
export const getHistoricalHookyFolksCountRequest = createAction<IFilter>(
  'Make request to fetch HistoricalHookyFolks count',
);
export const getHistoricalHookyFolksCountSuccess = createAction<ICountResponse>(
  'HistoricalHookyFolks count has been fetched successfully',
);
export const getHistoricalHookyFolksCountFailed = createAction(
  'Cannot fetch HistoricalHookyFolks count',
);

// Fetch removed employees actions
export const getRemovedEmployeeListRequest = createAction<IFilter>(
  'Make request to fetch removed employee list',
);
export const getRemovedEmployeeListSuccess = createAction<IEmployeeModel[]>(
  'Removed employees list has been fetched successfully',
);
export const getRemovedEmployeeListFailed = createAction(
  'Cannot fetch removed employee list',
);

// Fetch removed employees count
export const getRemovedEmployeeCountRequest = createAction<IFilter>(
  'Make request to get removed employee count',
);
export const getRemovedEmployeeCountSuccess = createAction<ICountResponse>(
  'Removed employees count has been fetched successfully',
);
export const getRemovedEmployeeCountFailed = createAction(
  'Cannot get removed employee count',
);

// Restore employee action
export const restoreEmployeeRequest = createAction<
  IUpdateEmployee & { deletedAt: string | null }
>('Make request to restore employee');
export const restoreEmployeeSuccess = createAction<number>(
  'Employee restored successfully',
);
export const restoreEmployeeFailed = createAction('Cannot restore employee');

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Employees').takeExportActions();

const {
  exportReportFailed: exportActiveButNotPresentFailed,
  exportReportRequest: exportActiveButNotPresentRequest,
  exportReportSuccess: exportActiveButNotPresentSuccess,
} = new ExportActionCreator(
  'Active but not present employees',
).takeExportActions();

export const {
  exportReportRequest: exportEmployeeTerminatedRequest,
  exportReportSuccess: exportEmployeeTerminatedSuccess,
  exportReportFailed: exportEmployeeTerminatedFailed,
} = new ExportActionCreator('Terminated employees').takeExportActions();

export const {
  exportReportRequest: exportHookyFolksRequest,
  exportReportSuccess: exportHookyFolksSuccess,
  exportReportFailed: exportHookyFolksFailed,
} = new ExportActionCreator('HookyFolks').takeExportActions();

export const {
  exportReportRequest: exportHistoricalHookyFolksRequest,
  exportReportSuccess: exportHistoricalHookyFolksSuccess,
  exportReportFailed: exportHistoricalHookyFolksFailed,
} = new ExportActionCreator('HistoricalHookyFolks').takeExportActions();

export const exportEmployeeRequest = exportReportRequest;
export const exportEmployeeSuccess = exportReportSuccess;
export const exportEmployeeFailed = exportReportFailed;

export const exportEmployeeActiveButNotPresentRequest = exportActiveButNotPresentRequest;
export const exportEmployeeActiveButNotPresentSuccess = exportActiveButNotPresentSuccess;
export const exportEmployeeActiveButNotPresentFailed = exportActiveButNotPresentFailed;
