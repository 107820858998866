import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deleteTerminationReasonRequest,
  exportTerminationReasonRequest,
  getTerminationReasonCountRequest,
  getTerminationReasonListRequest,
} from '../../modules/actions';
import {
  getTerminationReasonCount,
  getTerminationReasonRefreshKey,
  getTerminationReasonTable,
} from '../../modules/selectors/terminationReason';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useSearch,
  useCreateExportProps,
  useFilter,
  useTerminationReasonsDefaultFilters,
  useBrowserHistoryFunctions,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, paths, useHasUserAccessToAction } from '../../config';
import { map } from 'lodash';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const TerminationReasonsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const defaultFilter = useTerminationReasonsDefaultFilters();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.terminationReason.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.terminationReason.update.id,
  );

  // fetch Supervisor list
  const list = useSelector(getTerminationReasonTable, shallowEqual);
  const exportProps = useCreateExportProps(exportTerminationReasonRequest);
  const count = useSelector(getTerminationReasonCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);
  const computedList = useMemo(
    () =>
      map(list, (item) => ({
        ...item,
        voluntary: item.voluntary
          ? t('terminationReasons.voluntary_yes')
          : t('terminationReasons.voluntary_no'),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );
  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('terminationReasons.name') },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('terminationReasons.site'),
    },
    {
      id: 'voluntary',
      disablePadding: false,
      label: t('terminationReasons.voluntary'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('common.name'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('terminationReasons.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'voluntary',
      label: t('terminationReasons.voluntary'),
      operator: 'eq',
      type: 'checkbox',
    },
  ];

  // make request to fetch terminationReasons when component is mounted
  useEffect(() => {
    dispatcher(getTerminationReasonListRequest(filterList));
    // get total count
    dispatcher(getTerminationReasonCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getTerminationReasonListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getTerminationReasonCountRequest({
        filter: { where, include: newInclude },
      }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteTerminationReasonRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: paths.TERMINATION_REASONS_UPDATE, search });
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={computedList}
          count={count}
          selectIndex="id"
          tableName={t('terminationReasons.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          include={defaultFilter.include}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.TerminationReason}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function TerminationReasonsListRefreshable() {
  return (
    <TerminationReasonsList key={useSelector(getTerminationReasonRefreshKey)} />
  );
}
