import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getBomsByIds } from '../../modules/selectors/bom.selector';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useValidate,
} from '../../modules/utils';
import { IdsArray, IUpdateBom } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchBomsByIds } from '../../modules/utils';
import { updateBomsRequest } from '../../modules/actions';
import { useTranslation } from 'react-i18next';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { updateBomScheme } from '../../modules/schemes';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IUpdateBomsProps {
  boms: IUpdateBom[];
  onSubmit: (boms: IUpdateBom[]) => void;
}

const BomUpdateForm = ({ boms, onSubmit }: IUpdateBomsProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateBomScheme);

  const formik = useFormikInUpdateForm({
    initialValues: boms,
    validate,
    onSubmit,
  });

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (bom, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('boms.update_title', { id: bom.id })}
          key={bom.id}
        >
          <FormFieldContainer>
            <FormikTextField
              id={`${index}.name`}
              variant="outlined"
              required
              fullWidth
              label={t('boms.name')}
              name={`${index}.name`}
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id={`${index}.siteId`}
              required={true}
              formik={formik}
              label={t('boms.site')}
              placeholder={t('boms.site')}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>
        </CreateForm>
      ))}
      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const BomsUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchBoms = useFetchBomsByIds(ids);

  // fetch boms list from store
  const boms = useSelector(getBomsByIds)(ids);

  // make request to fetch boms from the server if we don't have them in the store
  useEffect(() => {
    fetchBoms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated boms
   */
  const handleFormSubmit = (data: IUpdateBom[]) => {
    dispatcher(updateBomsRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <BomUpdateForm boms={boms} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default BomsUpdate;
