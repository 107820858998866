import * as React from 'react';
import Option from '@mui/joy/Option';
import { useTranslation } from 'react-i18next';
import { Select } from 'src/components/_ui-kit/Select';
import { Sheet, Typography, useTheme } from '@mui/joy';

export const commonRowsPerPage = [10, 25, 50, 100];

type ChangeEvent =
  | React.MouseEvent<Element, MouseEvent>
  | React.KeyboardEvent<Element>
  | React.FocusEvent<Element, Element>
  | null;

export interface IRowsPerPageProps {
  count: number;
  rowsPerPage: number;
  onRowsPerPageChange: (rowsCount: number, e: ChangeEvent) => void;
}

export const RowsPerPage = ({
  count,
  rowsPerPage,
  onRowsPerPageChange,
}: IRowsPerPageProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const rowsPerPageConfig = React.useMemo(
    () => [
      ...commonRowsPerPage.map((num) => ({
        value: String(num),
        label: String(num),
      })),
      {
        value: String(commonRowsPerPage.includes(count ?? 0))
          ? Math.max(...commonRowsPerPage) + 1
          : count ?? 0,
        label: t('common.all'),
      },
    ],
    [count, t],
  );

  const handleChange = (e: ChangeEvent, value: string | null) => {
    if (value) {
      onRowsPerPageChange(Number(value), e);
    }
  };

  return (
    <Sheet sx={{ display: 'flex', alignItems: 'center', bgcolor: 'inherit' }}>
      <Sheet sx={{ mr: '4px' }}>
        <Typography level="text_xs" fontWeight="regular" textColor="gray.600">
          {t('common.rows_per_page')}:
        </Typography>
      </Sheet>
      <Select
        size="sm"
        required
        value={String(rowsPerPage)}
        onChange={handleChange}
        slotProps={{
          button: {
            sx: {
              ...theme.typography.text_sm,
              fontWeight: 'regular',
            },
          },
        }}
      >
        {rowsPerPageConfig.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Sheet>
  );
};
