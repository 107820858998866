import { filter, isEmpty, map, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateProduction } from '../types';
import {
  parse,
  format,
  composeDate,
  DATE_FORMAT,
} from '../../modules/utils/dateWrapper';
export const getProductionRefreshKey = (state: IStoreState) =>
  state.production.refreshKey;

export const getProductionData = (state: IStoreState) => state.production;

/**
 * Get server error
 * @param production - State production
 */
export const getServerError = ({ production }: IStoreState) => production.error;

/**
 * Get production list
 * @param production - State production
 */
export const getProductionList = ({ production }: IStoreState) =>
  production.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param production - State production
 */
export const getProductionsTable = createSelector(
  getProductionList,
  (productions) => map(filter(productions, (s) => !isEmpty(s.site))),
);

/**
 * Get productions by array of ids
 * @param production - State production
 */
export const getProductionsByIds = createSelector(
  getProductionList,
  (productions) => (ids: IdsArray) => {
    const initial: IUpdateProduction[] = [];
    return reduce(
      productions,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          acc.push({
            ...omit(cur, ['bom', 'shift', 'site', 'elapsedTime', 'employees']),
            date: composeDate(
              cur.date,
              parse(DATE_FORMAT),
              format(DATE_FORMAT),
            ),
            employees: map(cur.employees, 'id'),
          });
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get production count
 * @param production - State production
 */
export const getProductionCount = ({ production }: IStoreState) =>
  production.count;

/**
 * Get combobox list
 */
export const getProductionsComboboxList = ({ production }: IStoreState) =>
  map(production.comboboxList, (productionItem) => ({
    id: productionItem.id,
    name: `${productionItem.date}`,
  }));
