import React, { useEffect } from 'react';
import { map, omit } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPoliciesByIds } from '../../modules/selectors/policy';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useValidate,
} from '../../modules/utils/hooks';
import { IdsArray, IStoreState, IUpdatePolicy } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchPolicies } from '../../modules/utils/hooks';
import { updatePolicyRequest } from '../../modules/actions/policy';
import { useTranslation } from 'react-i18next';
import { updatePoliciesScheme } from '../../modules/schemes';
import { CreateFormHandleSubmit } from '../../components/Layout/CreateFormLayout';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import { FormActions } from '../../components/Form/FormActions';
import ResourcesWithActionsComboBox from '../../components/Formik/comboboxes-with-entities/ResourcesWithActionsComboBox';
import ClientKeysComboBox from '../../components/Formik/comboboxes-with-entities/ClientKeysCombobox';
import { Box } from '@mui/material';
import { PageContentWithTopToolbar } from 'src/components/PageContent';

interface IProps {
  policies: IUpdatePolicy[];
  onSubmit: (policies: IUpdatePolicy[]) => void;
}

const PoliciesUpdateForm = (props: IProps) => {
  const { t } = useTranslation();

  const { policies, onSubmit } = props;

  const validate = useValidate<IUpdatePolicy[]>(updatePoliciesScheme);

  const policiesWithUuid = policies.map((policy) => ({
    ...policy,
    uuid: `${policy.v1}_${policy.v2}`,
  }));

  const formik = useFormikInUpdateForm({
    initialValues: policiesWithUuid,
    validate,
    onSubmit: (data) => onSubmit(data.map((item) => omit(item, 'uuid'))),
  });

  return (
    <PageContentWithTopToolbar>
      <UpdateFormLayout
        onSubmit={formik.handleSubmit as CreateFormHandleSubmit}
        fields={formik.values}
        isFormValid={formik.isValid}
      >
        <Box sx={{ width: '70%', maxWidth: 700, minWidth: 500 }}>
          {map(formik.values, (policy, index) => (
            <CreateForm
              defaultExpanded={index === 0}
              cardTitle={t('policies.update_title', { id: policy.id })}
              key={policy.id}
            >
              <ResourcesWithActionsComboBox
                id={`${index}.uuid`}
                action={`${index}.v2`}
                resource={`${index}.v1`}
                required={true}
                formik={formik}
                placeholder={t('policies.v1')}
                errorMode="onFieldChange"
              />

              <ClientKeysComboBox
                id={`${index}.v3`}
                required={true}
                formik={formik}
                placeholder={t('policies.v3')}
                errorMode="onFieldChange"
              />
            </CreateForm>
          ))}
        </Box>

        <FormActions submitBtnTitle={t('common.update')} />
      </UpdateFormLayout>
    </PageContentWithTopToolbar>
  );
};

const PoliciesUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchPolicies = useFetchPolicies(ids);

  // fetch policies list from store
  const policies = useSelector(
    (state) => getPoliciesByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch policies from the server if we don't have them in the store
  useEffect(() => {
    fetchPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated policies
   */
  const handleFormSubmit = (data: IUpdatePolicy[]) => {
    dispatcher(updatePolicyRequest({ data, navigate }));
  };

  return <PoliciesUpdateForm policies={policies} onSubmit={handleFormSubmit} />;
};

export default PoliciesUpdate;
