import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import { chain, cloneDeep, noop, set } from 'lodash';
import ComboBox, { ComboBoxOption } from '../../../../../components/ComboBox';
import { PricingSettingsConfig } from '../../../../../modules/types';
import { usePricingSettingsForm } from '../../../../../modules/utils/hooks/pricing-settings';
import { FormFieldContainer } from '../../../../../components/Form/FormFieldContainer';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { useFormik } from 'formik';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Textarea } from 'src/components/_ui-kit/Textarea';
import { TextField } from 'src/components/_ui-kit/TextField';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addProcessStatus } from 'src/modules/actions';
import { Checkbox } from '../../../../../components/_ui-kit/Checkbox';

interface IPricingCreateSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialData = {
  siteId: NaN,
};

export const PricingCreateSettings = ({
  isOpen,
  onClose,
}: IPricingCreateSettingsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validationSchemaForSiteId = Yup.object().shape({
    siteId: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required')),
  });

  const formikForSiteId = useFormik({
    initialValues: initialData,
    validationSchema: validationSchemaForSiteId,
    onSubmit: noop,
  });

  const { settings, sendToServer } = usePricingSettingsForm(
    formikForSiteId.values.siteId,
  );

  const [pricingSettings, setPricingSettings] = React.useState<
    PricingSettingsConfig
  >(settings);

  const handleTitleChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPricingSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value);
      return value;
    });
  };

  const typesOptions = React.useMemo(
    () =>
      ([
        {
          id: 'string',
          name: 'string',
        },
        {
          id: 'range',
          name: 'range',
        },
        {
          id: 'combobox',
          name: 'combobox',
        },
      ] as unknown) as ComboBoxOption[],
    [],
  );

  const getOption = React.useCallback(
    (type: string) => typesOptions.find((i) => `${i.id}` === type),
    [typesOptions],
  );

  const handleTypeChange = (id: string) => (
    e: React.SyntheticEvent<Element, Event>,
    v: ComboBoxOption | null,
  ) => {
    setPricingSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, v?.id);
      return value;
    });
  };

  const handleCheckboxChange = (id: string) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPricingSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, e.target.checked);
      return value;
    });
  };

  const handleOptionsChange = (id: string) => (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPricingSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value.split('\n'));
      return value;
    });
  };

  const handleSubmit = () => {
    formikForSiteId.handleSubmit();

    if (formikForSiteId.isValid) {
      sendToServer(pricingSettings);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );

      onClose();

      formikForSiteId.resetForm();
    }
  };

  const onReset = () => {
    formikForSiteId.resetForm();
  };

  React.useEffect(() => setPricingSettings(settings), [settings]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('pricing.create_settings_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              formik={formikForSiteId}
              label={t('employees.site')}
              placeholder={t('employees.site')}
            />
          </FormFieldContainer>

          {formikForSiteId.values.siteId
            ? chain(pricingSettings)
                .map(({ title, type, disabled, options }, name) => (
                  <React.Fragment key={name}>
                    <FormFieldContainer>
                      <Typography>{name}</Typography>
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <ComboBox
                        size="md"
                        label={t('pricing.settings.type')}
                        placeholder={t('pricing.settings.type')}
                        options={typesOptions}
                        value={getOption(type)}
                        onChange={handleTypeChange(`${name}.type`)}
                      />
                    </FormFieldContainer>
                    <FormFieldContainer>
                      <TextField
                        label={t('pricing.settings.title')}
                        value={title}
                        onChange={handleTitleChange(`${name}.title`)}
                      />
                    </FormFieldContainer>
                    <FormFieldContainer>
                      <Checkbox
                        label={t('pricing.settings.disabled')}
                        checked={disabled}
                        onChange={handleCheckboxChange(`${name}.disabled`)}
                      />
                    </FormFieldContainer>
                    {type === 'combobox' && (
                      <FormFieldContainer>
                        <Textarea
                          label={t('pricing.settings.options')}
                          value={options?.join('\n')}
                          onChange={handleOptionsChange(`${name}.options`)}
                        />
                      </FormFieldContainer>
                    )}
                  </React.Fragment>
                ))
                .value()
            : null}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onCancel={onClose}
          applyButtonType="submit"
          onApply={handleSubmit}
        />
      </DrawerFooter>
    </Drawer>
  );
};
