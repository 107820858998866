import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';
import { getItemFromLocalStorage } from 'src/modules/utils/localStorageWrapper';

import {
  ICountResponse,
  ICreateSiteRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  ISetArchiveStatusArgs,
  ISiteModel,
  IUpdateSite,
  PayloadWithFilters,
} from '../../types';
import { filterForGlobalSitesDropdown } from 'src/modules/utils/helpers/filter';

/**
 * Create a new site
 */
export const createSiteRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreateSiteRequest>>): SagaIterator {
  try {
    const payloadData = {
      ...data,
      managementCost: Number(data.managementCost),
    };
    const response: ISiteModel = yield call(Api.Site.create, payloadData);

    yield put(
      actions.getSiteForGlobalDropdownRequest({
        filter: filterForGlobalSitesDropdown,
      }),
    );

    yield put(actions.getSiteListRequest({ filter: list }));

    yield put(actions.getSiteCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createSiteSuccess(response));
  } catch (e) {
    yield put(actions.createSiteFailed());
  }
};

/**
 * Get site list
 */
export const getSiteListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ISiteModel[] = yield call(Api.Site.list, action.payload);
    yield put(actions.getSiteListSuccess(response));
  } catch (e) {
    yield put(actions.getSiteListFailed());
  }
};

/**
 * Get site count
 */
export const getSiteCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Site.count,
      action?.payload,
    );
    yield put(actions.getSiteCountSuccess(response));
  } catch (e) {
    yield put(actions.getSiteCountFailed());
  }
};

/**
 * Delete sites
 */
export const deleteSiteRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Site.delete, {
      where: { id: { inq: data } },
    });

    // refech all sites
    yield put(
      actions.getSiteForGlobalDropdownRequest({
        filter: filterForGlobalSitesDropdown,
      }),
    );

    yield put(actions.getSiteListRequest({ filter: list }));

    yield put(actions.getSiteCountRequest({ filter: count }));

    yield put(actions.deleteSiteSuccess());

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.deleteSiteFailed());
  }
};

/**
 * Bulk update sites
 */
export const updateSitesRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ISiteModel[]>>): SagaIterator {
  try {
    const payloadData = data.map((site) => ({
      ...site,
      managementCost: Number(site.managementCost),
    }));
    yield call(Api.Site.bulkUpdate, payloadData);

    // refech all sites
    yield put(
      actions.getSiteForGlobalDropdownRequest({
        filter: filterForGlobalSitesDropdown,
      }),
    );
    yield put(actions.getSiteListRequest({ filter: list }));

    yield put(actions.getSiteCountRequest({ filter: count }));

    yield put(actions.updateSitesSuccess());

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.updateSitesFailed());
  }
};

/**
 * Get combobox list
 */
export const getSiteComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<ISiteModel>[] = yield call(
      Api.Site.list,
      action.payload,
    );
    yield put(actions.getSiteComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getSiteComboboxListFailed());
  }
};

export const getSiteForGlobalDropdownRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ISiteModel[] = yield call(Api.Site.list, action.payload);
    const sitesInLocalStorage = getItemFromLocalStorage<number[]>(
      'globallySelectedSites',
    );

    const globallySelectedSitesIds = sitesInLocalStorage
      ? response
          .filter((site) => sitesInLocalStorage.includes(site.id))
          .map((site) => site.id)
      : response.map((site) => site.id);

    const globalDropdownList = response;
    const globallySelected: number[] = globallySelectedSitesIds;

    yield put(
      actions.getSiteForGlobalDropdownSuccess({
        globalDropdownList,
        globallySelected,
      }),
    );
  } catch (e) {
    yield put(actions.getSiteForGlobalDropdownFailed());
  }
};

export const updateSitesDesiredEmployeesNumberSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<
    Pick<IUpdateSite, 'id' | 'requiredAssociates' | 'absenteeism'>[]
  >
>): SagaIterator {
  try {
    yield call(Api.Site.updateDesiredEmployeesNumber, data);

    // refech all sites
    yield put(
      actions.getSiteForGlobalDropdownRequest({
        filter: filterForGlobalSitesDropdown,
      }),
    );

    yield put(actions.getSiteListRequest({ filter: filters.list }));
    yield put(actions.getSiteCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateSitesDesiredEmployeesNumberSuccess());
  } catch (e) {
    yield put(actions.updateSitesDesiredEmployeesNumberFailed());
  }
};

export const setArchivedStatusOfSiteRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<Array<ISetArchiveStatusArgs>>
>): SagaIterator {
  try {
    yield call(Api.Site.bulkArchive, data);
    yield put(
      actions.getSiteForGlobalDropdownRequest({
        filter: filterForGlobalSitesDropdown,
      }),
    );
    yield put(actions.setArchivedStatusOfSiteSuccess(data));

    yield put(actions.getSiteListRequest({ filter: filters.list }));
    yield put(actions.getSiteCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.setArchivedStatusOfSiteFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Site.export,
  actionFailed: actions.exportSiteFailed,
  actionSuccess: actions.exportSiteSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportSiteRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createSiteRequest, saga: createSiteRequestSaga },
  { action: actions.getSiteListRequest, saga: getSiteListRequestSaga },
  { action: actions.getSiteCountRequest, saga: getSiteCountRequestSaga },
  { action: actions.deleteSiteRequest, saga: deleteSiteRequestSaga },
  // bulk updating sites
  { action: actions.updateSitesRequest, saga: updateSitesRequestSaga },
  {
    action: actions.updateSitesDesiredEmployeesNumberRequest,
    saga: updateSitesDesiredEmployeesNumberSaga,
  },
  {
    action: actions.setArchivedStatusOfSiteRequest,
    saga: setArchivedStatusOfSiteRequestSaga,
  },
  {
    action: actions.getSiteComboboxListRequest,
    saga: getSiteComboboxListRequestSaga,
  },
  { action: actions.exportSiteRequest, saga: exportSiteRequestSaga },
  {
    action: actions.getSiteForGlobalDropdownRequest,
    saga: getSiteForGlobalDropdownRequestSaga,
  },
];
