import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthWeeklyUphBySite',
);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyUphBySiteListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteListFailed,
} = actionCreator.takeListActions<ICardinalHealthWeeklyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyUphBySiteRequest,
  exportReportSuccess: exportCardinalHealthWeeklyUphBySiteSuccess,
  exportReportFailed: exportCardinalHealthWeeklyUphBySiteFailed,
} = new ExportActionCreator(
  'CardinalHealthWeeklyUphBySite',
).takeExportActions();
