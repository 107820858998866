import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageContentTab,
  PageContentTabPanel,
  PageContentTabs,
  PageContentTabsList,
} from 'src/components/PageContent';
import { FinancialReports } from './panels/FinancialReports';
import { FilterPanelContextProvider } from 'src/components/FilterPanel/FilterPanelContext';
import { HRDashboard } from './panels/HRDashboard';
import { HRChanges } from './panels/HRChanges';
import { RecruitingReport } from './panels/Recruiting';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { DashboardTurnoverByReason } from './panels/DashboardTurnoverByReason';

export const Dashboard = () => {
  const { t } = useTranslation();

  const hasUserAccessToViewFinancialTab = useHasUserAccessToViewTab(
    tabsPaths.DASHBOARD_FINANCIAL_REPORT_TAB,
  );
  const hasUserAccessToViewHrDashboardTab = useHasUserAccessToViewTab(
    tabsPaths.DASHBOARD_HR_DASHBOARD_TAB,
  );
  const hasUserAccessToViewHrChangesTab = useHasUserAccessToViewTab(
    tabsPaths.DASHBOARD_HR_CHANGES_TAB,
  );
  const hasUserAccessToViewRecruitingTab = useHasUserAccessToViewTab(
    tabsPaths.DASHBOARD_RECRUITING_TAB,
  );
  const hasUserAccessToByReasonTab = useHasUserAccessToViewTab(
    tabsPaths.DASHBOARD_TURNOVER_BY_REASON_TAB,
  );

  const defaultActiveTab = React.useMemo(() => {
    if (hasUserAccessToViewFinancialTab) {
      return 0;
    }
    if (hasUserAccessToViewHrDashboardTab) {
      return 1;
    }
    if (hasUserAccessToViewHrChangesTab) {
      return 2;
    }
    if (hasUserAccessToByReasonTab) {
      return 3;
    }
    if (hasUserAccessToViewRecruitingTab) {
      return 4;
    }
    return null;
  }, [
    hasUserAccessToByReasonTab,
    hasUserAccessToViewFinancialTab,
    hasUserAccessToViewHrChangesTab,
    hasUserAccessToViewHrDashboardTab,
    hasUserAccessToViewRecruitingTab,
  ]);

  const [activeTab, setActiveTab] = React.useState<number | null>(
    defaultActiveTab,
  );

  React.useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return (
    <PageContentTabs
      value={activeTab}
      onChange={(_, value) =>
        setActiveTab(value !== null ? Number(value) : value)
      }
    >
      <PageContentTabsList>
        {hasUserAccessToViewFinancialTab && (
          <PageContentTab value={0}>
            {t('main_menu.financial_tracker.main')}
          </PageContentTab>
        )}
        {hasUserAccessToViewHrDashboardTab && (
          <PageContentTab value={1}>
            {t('dashboard.hr_dashboard')}
          </PageContentTab>
        )}
        {hasUserAccessToViewHrChangesTab && (
          <PageContentTab value={2}>{t('dashboard.hr_changes')}</PageContentTab>
        )}
        {hasUserAccessToByReasonTab && (
          <PageContentTab value={3}>
            {t('main_menu.turnover_by_reason_details')}
          </PageContentTab>
        )}
        {hasUserAccessToViewRecruitingTab && (
          <PageContentTab value={4}>{t('dashboard.recruiting')}</PageContentTab>
        )}
      </PageContentTabsList>

      {activeTab === 0 && hasUserAccessToViewFinancialTab && (
        <PageContentTabPanel value={0}>
          <FilterPanelContextProvider>
            <FinancialReports />
          </FilterPanelContextProvider>
        </PageContentTabPanel>
      )}
      {activeTab === 1 && hasUserAccessToViewHrDashboardTab && (
        <PageContentTabPanel sx={{ pt: 7 }} value={1}>
          <HRDashboard />
        </PageContentTabPanel>
      )}
      {activeTab === 2 && hasUserAccessToViewHrChangesTab && (
        <PageContentTabPanel sx={{ pt: 7 }} value={2}>
          <HRChanges />
        </PageContentTabPanel>
      )}
      {activeTab === 3 && hasUserAccessToByReasonTab && (
        <PageContentTabPanel sx={{ pt: 7 }} value={3}>
          <DashboardTurnoverByReason />
        </PageContentTabPanel>
      )}
      {activeTab === 4 && hasUserAccessToViewRecruitingTab && (
        <PageContentTabPanel value={4}>
          <RecruitingReport />
        </PageContentTabPanel>
      )}
      {/* {activeTab === 3 && (
        <PageContentTabPanel value={3}>
          <DashboardLeadSupervisor />
        </PageContentTabPanel>
      )} */}
    </PageContentTabs>
  );
};
