import { subWeeks } from 'date-fns';
import React from 'react';
import StackedAreaChart from 'src/components/Charts/2d/StackedAreaChart';
import { YEAR_WEEK_ISO, format } from 'src/modules/utils/dateWrapper';
import { concatenateYearWeek } from 'src/modules/utils/helpers/formatters';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { SeriesAreaOptions } from 'highcharts';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { IWeeklyGrossMarginChartItem } from 'src/modules/types/executive-reports';

interface IWeeklyGrossMarginChartProps {
  from?: number;
  to?: number;
}

export const WeeklyGrossMarginChart = ({
  from,
  to,
}: IWeeklyGrossMarginChartProps) => {
  const today = new Date();
  const toDate = subWeeks(today, 1);
  const toYearWeek = format(YEAR_WEEK_ISO)(toDate);

  const fromDate = subWeeks(toDate, 13);
  const fromYearWeek = format(YEAR_WEEK_ISO)(fromDate);

  const [toYear, toWeek] = toYearWeek.split('-W');
  const [fromYear, fromWeek] = fromYearWeek.split('-W');

  const { fetchData, data, isDataLoading } = useDataFetcherWithData<
    Array<IWeeklyGrossMarginChartItem>
  >('/gross-margin/executive/weekly/chart', []);

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const categories = React.useMemo(() => {
    const categoriesSet = new Set(
      (data as any).map((item: any) => generateCategory(item)),
    );

    return Array.from(categoriesSet);
  }, [data]);

  const series = React.useMemo(() => {
    const dataObject = data.reduce<{ [key: string]: SeriesAreaOptions }>(
      (acc, item) => {
        const currentProperty = acc[item.site.name];
        const valueIndex = categories.findIndex(
          (category) => category === generateCategory(item),
        );

        if (currentProperty) {
          if (currentProperty?.data) {
            currentProperty.data[valueIndex] = item.grossMarginValue;
          }
        } else {
          acc[item.site.name] = {
            type: 'area' as const,
            name: item.site.name,
            data: categories.map((_, i) =>
              i === valueIndex ? item.grossMarginValue : null,
            ),
          };
        }

        return acc;
      },
      {},
    );

    return Object.values(dataObject);
  }, [categories, data]);

  function generateCategory({
    yearNumber,
    weekNumber,
  }: {
    yearNumber: number;
    weekNumber: number;
  }) {
    return `${yearNumber}-W${weekNumber}`;
  }

  React.useEffect(() => {
    // We have next states of from/to:
    // 1. from = undefined and to = undefined => user does not touch filter, we should use default from/to
    // 2. from = "some value" and to = "some value" => user selected yearWeek, we use user selection
    // 3. from = null || to = null, user select then remove a value, we should not use any value
    const and = [];
    if (from !== null) {
      and.push({
        yearWeek: {
          gte:
            from ??
            Number(
              concatenateYearWeek({
                year: Number(fromYear),
                week: Number(fromWeek),
              }),
            ),
        },
      });
    }
    if (to !== null) {
      and.push({
        yearWeek: {
          lt:
            to ??
            Number(
              concatenateYearWeek({
                year: Number(toYear),
                week: Number(toWeek),
              }),
            ),
        },
      });
    }

    fetchData({
      filter: {
        order: ['yearWeek asc'],
        where: { and },
        include: [
          {
            relation: 'site',
          },
        ],
      },
    });
  }, [fetchData, from, fromWeek, fromYear, to, toWeek, toYear]);

  return (
    <ChartContainer fullHeigh={false}>
      <ChartContainerHeader>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`quarterly_analysis`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isDataLoading}>
        <StackedAreaChart
          ref={chartRef}
          height={chartHeight}
          series={series as any}
          categories={categories as any}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
