import React from 'react';
import { SeriesLineOptions } from 'highcharts';
import { find, findIndex, map, reduce, set, sortBy, uniq } from 'lodash';
import {
  AnyObject,
  ITurnoverActiveModel,
  ITurnoverAddedModel,
  ITurnoverByReasonItem,
  ITurnoverFilterConfig,
  ITurnoverFilterData,
  ITurnoverLostModel,
  ITurnoverTerminatedModel,
  ListWithSummary,
} from 'src/modules/types';
import {
  useDataFetcherWithData,
  useReportDefaultFilter,
} from './common/reports';
import {
  ITurnoverDashboardFilterPanelFilters,
  TurnoverDashboardPeriod,
} from '../../../pages/TurnoverDashboard/components/TurnoverDashboardFilterPanel';
import { getGloballySelectedSites } from '../../selectors/site';
import { shallowEqual, useSelector } from 'react-redux';
import { DATE_FORMAT, useMonthsByYear } from '../dateWrapper';
import { format, subDays } from 'date-fns';

export const useTurnoverFilter = (
  filterData: Partial<ITurnoverFilterData>,
  filterConfig: ITurnoverFilterConfig,
) => {
  const defaultFilter = useReportDefaultFilter();

  return React.useMemo(() => {
    const appliedFilterWhere = addOperatorsToFilter(filterData, filterConfig);

    return {
      ...defaultFilter,
      where: {
        ...defaultFilter.where,
        ...appliedFilterWhere,
      },
    };
  }, [defaultFilter, filterConfig, filterData]);
};

function addOperatorsToFilter(
  filter: Partial<ITurnoverFilterData>,
  operatorsConfig: ITurnoverFilterConfig,
) {
  return Object.keys(operatorsConfig).reduce((filters, fieldName) => {
    const operator = operatorsConfig[fieldName];

    filters[fieldName] = {
      [operator]:
        typeof filter[fieldName] === 'object'
          ? filter[fieldName].id
          : filter[fieldName],
    };

    return filters;
  }, {});
}

const urls = {
  turnoverAdded: '/turnover/added',
  turnoverActive: '/turnover/active',
  turnoverLost: '/turnover/lost',
  turnoverTerminated: '/turnover/terminated',
  turnoverByReason: '/turnover/by-reason',
};

export const useTurnoverAddedData = ({
  siteId,
  date,
  staffingProvider,
  week,
  year,
  period,
}: ITurnoverDashboardFilterPanelFilters) => {
  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<ITurnoverAddedModel>
  >(urls.turnoverAdded, {
    items: [],
    summary: [],
  });

  const between = React.useMemo(() => {
    const now = new Date();
    const sevenDaysAgo = subDays(now, TurnoverDashboardPeriod.last7Days);
    const thirtyDaysAgo = subDays(now, TurnoverDashboardPeriod.last30Days);

    switch (period) {
      case TurnoverDashboardPeriod.last7Days:
        return [format(sevenDaysAgo, DATE_FORMAT), format(now, DATE_FORMAT)];
      case TurnoverDashboardPeriod.last30Days:
        return [format(thirtyDaysAgo, DATE_FORMAT), format(now, DATE_FORMAT)];
    }

    return undefined;
  }, [period]);

  const where = React.useMemo(
    () => ({
      siteId,
      date:
        period && between
          ? {
              between,
            }
          : date,
      staffingProvider,
      week,
      year,
    }),
    [between, date, period, siteId, staffingProvider, week, year],
  );

  React.useEffect(() => {
    fetchData({ filter: { where } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const chartData = React.useMemo(() => {
    // sort by date
    const sorted = sortBy(data.items, ['date', 'site']);
    // pick only unique dates
    const categories = uniq(map(sorted, (i) => i.date));
    // initial state
    const init = {
      categories,
      series: [],
    } as { categories: Array<string>; series: Array<SeriesLineOptions> };
    // compose series data
    return reduce(
      sorted,
      (acc, cur) => {
        // current date index
        const curAxiosIndex = findIndex(categories, (c) => c === cur.date);
        // avoid duplicates
        if (!find(acc.series, (item) => item.name === cur.site)) {
          acc.series.push({
            name: cur.site,
            data: new Array(acc.categories.length).fill(0), // firstly initialize an array filled with nulls
            type: 'line',
          });
        }
        // get current index of data
        const curDataIndex =
          acc.series.length - 1 < 0 ? 0 : acc.series.length - 1;
        // set data by date
        set(acc.series, [curDataIndex, 'data', curAxiosIndex], cur.countAdded);
        return acc;
      },
      init,
    );
  }, [data]);

  return {
    ...chartData,
    isDataLoading,
  };
};

export const useTurnoverActiveData = ({
  siteId,
  staffingProvider,
}: ITurnoverDashboardFilterPanelFilters) => {
  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<ITurnoverActiveModel>
  >(urls.turnoverActive, {
    items: [],
    summary: [],
  });

  const where = React.useMemo(
    () => ({
      siteId,
      staffingProvider,
    }),
    [siteId, staffingProvider],
  );

  React.useEffect(() => {
    fetchData({ filter: { where } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const chartData = React.useMemo(() => {
    // sort by site and staffing provider
    const sorted = sortBy(data.items, ['site', 'staffingProvider']);
    // pick only unique sites
    const categories = uniq(map(sorted, (i) => i.site));
    // initial state
    const init = {
      categories,
      series: [],
    } as { categories: Array<string>; series: Array<SeriesLineOptions> };
    // compose series data
    return reduce(
      sorted,
      (acc, cur) => {
        // current xaxios index
        const curAxiosIndex = findIndex(categories, (c) => c === cur.site);
        // avoid duplicates
        if (!find(acc.series, (item) => item?.name === cur.staffingProvider)) {
          acc.series.push({
            name: cur.staffingProvider,
            data: new Array(acc.categories.length).fill(0), // firstly initialize an array filled with nulls
            type: 'line',
          });
        }
        // get current index of data
        const curDataIndex = findIndex(
          acc.series,
          (i) => (i as AnyObject).name === cur.staffingProvider,
        );
        // set data by date
        set(acc.series, [curDataIndex, 'data', curAxiosIndex], cur.total);
        return acc;
      },
      init,
    );
  }, [data]);

  return {
    ...chartData,
    isDataLoading,
  };
};

export const useTurnoverLostData = ({
  siteId,
  date,
  staffingProvider,
  week,
  year,
  period,
}: ITurnoverDashboardFilterPanelFilters) => {
  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<ITurnoverLostModel>
  >(urls.turnoverLost, {
    items: [],
    summary: [],
  });

  const between = React.useMemo(() => {
    const now = new Date();
    const sevenDaysAgo = subDays(now, TurnoverDashboardPeriod.last7Days);
    const thirtyDaysAgo = subDays(now, TurnoverDashboardPeriod.last30Days);

    switch (period) {
      case TurnoverDashboardPeriod.last7Days:
        return [format(sevenDaysAgo, DATE_FORMAT), format(now, DATE_FORMAT)];
      case TurnoverDashboardPeriod.last30Days:
        return [format(thirtyDaysAgo, DATE_FORMAT), format(now, DATE_FORMAT)];
    }

    return undefined;
  }, [period]);

  const where = React.useMemo(
    () => ({
      siteId,
      date:
        period && between
          ? {
              between,
            }
          : date,
      staffingProvider,
      week,
      year,
    }),
    [between, date, period, siteId, staffingProvider, week, year],
  );

  React.useEffect(() => {
    fetchData({ filter: { where } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const chartData = React.useMemo(() => {
    // sort by date
    const sorted = sortBy(data.items, ['date', 'site']);
    // pick only unique dates
    const categories = uniq(map(sorted, (i) => i.date));
    // initial state
    const init = {
      categories,
      series: [],
    } as { categories: Array<string>; series: Array<SeriesLineOptions> };
    // compose series data
    return reduce(
      sorted,
      (acc, cur) => {
        // current date index
        const curAxiosIndex = findIndex(categories, (c) => c === cur.date);
        // avoid duplicates
        if (!find(acc.series, (item) => item.name === cur.site)) {
          acc.series.push({
            name: cur.site,
            data: new Array(acc.categories.length).fill(0), // firstly initialize an array filled with nulls
            type: 'line',
          });
        }
        // get current index of data
        const curDataIndex =
          acc.series.length - 1 < 0 ? 0 : acc.series.length - 1;
        // set data by date
        set(acc.series, [curDataIndex, 'data', curAxiosIndex], cur.countLost);
        return acc;
      },
      init,
    );
  }, [data]);

  return {
    ...chartData,
    isDataLoading,
  };
};

export const useTurnoverTerminatedData = ({
  siteId,
  terminationReason,
}: ITurnoverDashboardFilterPanelFilters) => {
  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ListWithSummary<ITurnoverTerminatedModel>
  >(urls.turnoverTerminated, {
    items: [],
    summary: [],
  });

  const where = React.useMemo(
    () => ({
      siteId,
      terminationReason,
    }),
    [siteId, terminationReason],
  );

  React.useEffect(() => {
    fetchData({ filter: { where } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const chartData = React.useMemo(() => {
    // sort by site and terminationReason
    const sorted = sortBy(data.items, ['site', 'terminationReason']);
    // pick only unique sites
    const categories = uniq(map(sorted, (i) => i.site));
    // initial state
    const init = {
      categories,
      series: [],
    } as { categories: Array<string>; series: Array<SeriesLineOptions> };
    // compose series data
    return reduce(
      sorted,
      (acc, cur) => {
        // current xaxios index
        const curAxiosIndex = findIndex(categories, (c) => c === cur.site);
        // avoid duplicates
        if (!find(acc.series, (item) => item?.name === cur.terminationReason)) {
          acc.series.push({
            name: cur.terminationReason,
            data: new Array(acc.categories.length).fill(0), // firstly initialize an array filled with nulls
            type: 'line',
          });
        }
        // get current index of data
        const curDataIndex = findIndex(
          acc.series,
          (i) => (i as SeriesLineOptions).name === cur.terminationReason,
        );
        // set data by date
        set(acc.series, [curDataIndex, 'data', curAxiosIndex], cur.total);
        return acc;
      },
      init,
    );
  }, [data]);

  return {
    ...chartData,
    isDataLoading,
  };
};

export const useTurnoverByReason = ({
  siteId,
  year,
}: ITurnoverDashboardFilterPanelFilters) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    ITurnoverByReasonItem[]
  >(urls.turnoverByReason, []);

  const categories = useMonthsByYear(year ?? new Date().getFullYear());

  const where = React.useMemo(
    () => ({
      ...(selectedSites.length || siteId
        ? {
            siteId: {
              inq: siteId
                ? [siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      separationYear: year ?? new Date().getFullYear(),
    }),
    [selectedSites, siteId, year],
  );

  React.useEffect(() => {
    fetchData({ filter: { where } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [where]);

  const rawDataBySites = React.useMemo(
    () =>
      data.reduce<{
        [siteId: number]: Array<ITurnoverByReasonItem>;
      }>((acc, item) => {
        if (!acc[item.siteId]) {
          acc[item.siteId] = [item];
        } else {
          acc[item.siteId].push(item);
        }

        return acc;
      }, {}),
    [data],
  );

  const parsedData = React.useMemo(
    () =>
      Object.values(rawDataBySites).map((rawDataItem) => {
        const reasonsSet = new Set(
          rawDataItem.map((item) => item.terminationReason),
        );
        const reasons = [...reasonsSet];

        const data = reasons.reduce<
          Array<{ type: 'column'; data: Array<number>; name: string }>
        >((acc, reason) => {
          const reasonData = categories.map((monthName) => {
            const monthData = rawDataItem.find(
              (item) =>
                `${item.separationMonthName} ${item.separationYear}` ===
                  monthName && reason === item.terminationReason,
            );

            return monthData?.total ?? 0;
          });

          acc.push({ type: 'column' as const, data: reasonData, name: reason });

          return acc;
        }, []);

        return {
          series: data,
          categories,
          siteName: rawDataItem[0].site,
        };
      }),
    [categories, rawDataBySites],
  );

  return {
    data: parsedData,
    isDataLoading,
  };
};
