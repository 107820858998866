import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreatePolicyRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  IPolicyModel,
  IUpdatePolicy,
  ISagaActionBind,
  PayloadWithNavigateFunc,
  ICreatePolicyByPageRequest,
  ICreatePolicyByOperationRequest,
} from '../../types';
import { paths } from '../../../config';

/**
 * Create a new policy
 */
export const createPolicyRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreatePolicyRequest>>): SagaIterator {
  try {
    const response: IPolicyModel = yield call(Api.Policy.create, data);
    yield put(actions.flushPolicyState());
    yield put(actions.createPolicySuccess(response));
    yield call(navigate, { pathname: paths.POLICIES });
  } catch (e) {
    yield put(actions.createPolicyFailed());
  }
};

/**
 * Create a new policy by page
 */
export const createPolicyByPageRequestSaga = function* ({
  payload,
}: ISagaAction<Array<ICreatePolicyByPageRequest>>): SagaIterator {
  try {
    const response: IPolicyModel[] = yield call(
      Api.Policy.createByPage,
      payload,
    );
    yield put(actions.flushPolicyState());
    yield put(actions.createPolicyByPageSuccess(response));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'Success',
        message: 'Policies successfully added',
      }),
    );
  } catch (e) {
    yield put(actions.createPolicyByPageFailed());
  }
};

export const createPolicyByOperationRequestSaga = function* ({
  payload,
}: ISagaAction<Array<ICreatePolicyByOperationRequest>>): SagaIterator {
  try {
    const response: IPolicyModel[] = yield call(
      Api.Policy.createByOperation,
      payload,
    );

    yield put(actions.flushPolicyState());
    yield put(actions.createPolicyByPageSuccess(response));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'Success',
        message: 'Policies successfully added',
      }),
    );
  } catch (e) {
    yield put(actions.createPolicyByPageFailed());
  }
};

/**
 * Get policy list
 */
export const getPolicyListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IPolicyModel[] = yield call(
      Api.Policy.list,
      action.payload,
    );
    yield put(actions.getPolicyListSuccess(response));
  } catch (e) {
    yield put(actions.getPolicyListFailed());
  }
};

/**
 * Get policy count
 */
export const getPolicyCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Policy.count,
      action?.payload,
    );
    yield put(actions.getPolicyCountSuccess(response));
  } catch (e) {
    yield put(actions.getPolicyCountFailed());
  }
};

/**
 * Delete policies
 */
export const deletePolicyRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Policy.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushPolicyState());
  } catch (e) {
    yield put(actions.deletePolicyFailed());
  }
};

/**
 * Bulk update policies
 */
export const updatePolicyRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IUpdatePolicy[]>>): SagaIterator {
  try {
    yield call(Api.Policy.bulkUpdate, data);
    yield put(actions.flushPolicyState());
    yield call(navigate, { pathname: '/policies' });
  } catch (e) {
    yield put(actions.updatePolicyFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createPolicyRequest, saga: createPolicyRequestSaga },
  {
    action: actions.createPolicyByPageRequest,
    saga: createPolicyByPageRequestSaga,
  },
  {
    action: actions.createPolicyByOperationRequest,
    saga: createPolicyByOperationRequestSaga,
  },
  { action: actions.getPolicyListRequest, saga: getPolicyListRequestSaga },
  { action: actions.getPolicyCountRequest, saga: getPolicyCountRequestSaga },
  { action: actions.deletePolicyRequest, saga: deletePolicyRequestSaga },
  // bulk updating policies
  { action: actions.updatePolicyRequest, saga: updatePolicyRequestSaga },
];
