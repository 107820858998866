import { JSONSchemaType } from 'ajv';
import { ICreatePoint } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createPointScheme: JSONSchemaType<ICreatePoint> = {
  type: 'object',
  required: ['datetime', 'employeeId', 'pointTypeId'],
  additionalProperties: true,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    employeeId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    pointTypeId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    datetime: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    file: {
      nullable: true,
      type: 'object',
      required: [],
    },
  },
};

export const createPointsScheme: JSONSchemaType<Array<ICreatePoint>> = {
  type: 'array',
  items: {
    ...createPointScheme,
    type: 'object',
    required: ['datetime', 'employeeId', 'pointTypeId'],
    properties: {
      employeeId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
          type: i18n.t('error.validation.required'),
        },
      },
      pointTypeId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
          type: i18n.t('error.validation.required'),
        },
      },
      datetime: {
        default: '',
        isNotEmpty: true,
        type: 'string',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      file: {
        nullable: true,
        type: 'object',
        required: [],
      },
    },
  },
};
