import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { MainContentLoader } from '../../components/Layout/components/PageTour/MainContentLoader';

import { AnyObject, ICountResponse } from '../../modules/types';
import {
  formatAsCurrency,
  formatAsPercent,
} from '../../modules/utils/helpers/formatters';
import { ClientKey } from '../../modules/constants';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import { IWeeklyGrossMargin } from '../../modules/types/gross-margin';
import { paths } from '../../config';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import { ReportTable } from '../ReportPage/ReportTable';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  clientKey: ClientKey;
  tableName?: string;
  name?: string;
  siteId?: number;
  weekNumber?: number;
  yearNumber?: number;
}

export const WeeklyGrossMarginDetailedReport: React.FC<IProps> = ({
  clientKey,
  name,
  siteId,
  weekNumber,
  yearNumber,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'asc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      name,
      siteId,
      weekNumber,
      yearNumber,
    }),
    [name, siteId, weekNumber, yearNumber],
  );

  const include = React.useMemo(
    () => [
      {
        relation: 'site',
        relationType: 'left',
        scope: {
          where: {
            archived: false,
          },
          include: [
            {
              relation: 'client',
              relationType: 'left',
              scope: {
                where: {
                  clientKey,
                },
              },
            },
          ],
        },
      },
    ],
    [clientKey],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IWeeklyGrossMargin[]
  >(paths.WEEKLY_GROSS_MARGIN_DETAILED, []);
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${paths.WEEKLY_GROSS_MARGIN_DETAILED}/count`,
    { count: 0 },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'site.name',
        orderConfig: { orderBy: 'site.name' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'weekNumber',
        orderConfig: { orderBy: 'weekNumber' },
        label: t('charts.week_number'),
      },
      {
        id: 'yearNumber',
        orderConfig: { orderBy: 'yearNumber' },
        label: t('charts.year_number'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year_week'),
      },
      {
        id: 'revenue',
        orderConfig: { orderBy: 'revenue' },
        label: t('reports.headers.revenue'),
      },
      {
        id: 'totalCost',
        orderConfig: { orderBy: 'totalCost' },
        label: t('reports.headers.total_cost'),
      },
      {
        id: 'grossMarginValue',
        orderConfig: { orderBy: 'grossMarginValue' },
        label: t('reports.headers.gross_margin_detailed_value'),
      },
      {
        id: 'grossMarginPercent',
        orderConfig: { orderBy: 'grossMarginPercent' },
        label: t('reports.headers.gross_margin_detailed_percent'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    include,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    include,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect(null);

  if (isDataLoading) {
    return (
      <Box
        sx={{
          height: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MainContentLoader />
      </Box>
    );
  }

  const computedItems = data.map(
    ({
      revenue,
      totalCost,
      grossMarginValue,
      grossMarginPercent,
      ...rest
    }) => ({
      ...rest,
      revenue: formatAsCurrency(revenue),
      totalCost: formatAsCurrency(totalCost),
      grossMarginValue: formatAsCurrency(grossMarginValue),
      grossMarginPercent: formatAsPercent(grossMarginPercent),
    }),
  ) as AnyObject[];

  return (
    <ReportTable
      data={computedItems as any}
      count={countData.count}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey={paths.WEEKLY_GROSS_MARGIN}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};

export default WeeklyGrossMarginDetailedReport;
