import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  ISiteBillingDetailsModel,
  ISiteBillingDetailsState,
} from '../types';

// default state
const defaultState: ISiteBillingDetailsState = {
  refreshKey: 0,
  list: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushSiteBillingDetailsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createSiteBillingDetailsRequest = (state: ISiteBillingDetailsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createSiteBillingDetailsSuccess = (
  state: ISiteBillingDetailsState,
  payload: ISiteBillingDetailsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createSiteBillingDetailsFailed = (
  state: ISiteBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Creation actions
 */
const createBulkSiteBillingDetailsRequest = (
  state: ISiteBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createBulkSiteBillingDetailsSuccess = (
  state: ISiteBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const createBulkSiteBillingDetailsFailed = (
  state: ISiteBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getSiteBillingDetailsListRequest = (state: ISiteBillingDetailsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSiteBillingDetailsListSuccess = (
  state: ISiteBillingDetailsState,
  payload: ISiteBillingDetailsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getSiteBillingDetailsListFailed = (
  state: ISiteBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getSiteBillingDetailsCountRequest = (
  state: ISiteBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSiteBillingDetailsCountSuccess = (
  state: ISiteBillingDetailsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getSiteBillingDetailsCountFailed = (
  state: ISiteBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteSiteBillingDetailsRequest = (state: ISiteBillingDetailsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteSiteBillingDetailsFailed = (
  state: ISiteBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateSiteBillingDetailsRequest = (state: ISiteBillingDetailsState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateSiteBillingDetailsFailed = (
  state: ISiteBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * SiteBillingDetails reducer
 */
export const siteBillingDetails = createReducer<ISiteBillingDetailsState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushSiteBillingDetailsState, flushSiteBillingDetailsState)
  // creation actions
  .on(actions.createSiteBillingDetailsRequest, createSiteBillingDetailsRequest)
  .on(actions.createSiteBillingDetailsSuccess, createSiteBillingDetailsSuccess)
  .on(actions.createSiteBillingDetailsFailed, createSiteBillingDetailsFailed)
  // bulk creation actions
  .on(
    actions.createBulkSiteBillingDetailsRequest,
    createBulkSiteBillingDetailsRequest,
  )
  .on(
    actions.createBulkSiteBillingDetailsSuccess,
    createBulkSiteBillingDetailsSuccess,
  )
  .on(
    actions.createBulkSiteBillingDetailsFailed,
    createBulkSiteBillingDetailsFailed,
  )
  // get list actions
  .on(
    actions.getSiteBillingDetailsListRequest,
    getSiteBillingDetailsListRequest,
  )
  .on(
    actions.getSiteBillingDetailsListSuccess,
    getSiteBillingDetailsListSuccess,
  )
  .on(actions.getSiteBillingDetailsListFailed, getSiteBillingDetailsListFailed)
  // get count actions
  .on(
    actions.getSiteBillingDetailsCountRequest,
    getSiteBillingDetailsCountRequest,
  )
  .on(
    actions.getSiteBillingDetailsCountSuccess,
    getSiteBillingDetailsCountSuccess,
  )
  .on(
    actions.getSiteBillingDetailsCountFailed,
    getSiteBillingDetailsCountFailed,
  )
  // deletion actions
  .on(actions.deleteSiteBillingDetailsRequest, deleteSiteBillingDetailsRequest)
  .on(actions.deleteSiteBillingDetailsFailed, deleteSiteBillingDetailsFailed)
  // updating actions
  .on(actions.updateSiteBillingDetailsRequest, updateSiteBillingDetailsRequest)
  .on(actions.updateSiteBillingDetailsFailed, updateSiteBillingDetailsFailed);

export default siteBillingDetails;
