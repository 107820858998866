import React from 'react';
import { Sheet } from '@mui/joy';
import { isEmpty } from 'lodash';

import { ModalDrillDown } from 'src/components/_ui-kit/ModalDrillDown';
import { AnyObject } from 'src/modules/types';
import {
  TableActions,
  TableContextProvider,
  TableToolbar,
} from 'src/components/Table';
import { subWeeksByNumber } from 'src/modules/utils/dateWrapper';
import { useKpiDataGrid } from 'src/modules/utils';

import { PageContentChildContainer } from 'src/components/PageContent';
import HistoricalKpiFilter from './HistoricalKpiFilter';
import { KpiTable } from 'src/pages/Kpi/components/KpiTable';

interface IHistoricalKpiProps {
  siteId: number;
  yearWeek: number;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const weeksAgo = 7;

export const HistoricalKpi: React.FC<IHistoricalKpiProps> = ({
  isOpen,
  yearWeek,
  siteId,
  title,
  onClose,
}) => {
  const startYearWeek = React.useMemo(
    () => subWeeksByNumber(yearWeek, weeksAgo),
    [yearWeek],
  );

  const endYearWeek = React.useMemo(() => yearWeek, [yearWeek]);

  const [filters, setFilters] = React.useState<AnyObject>({
    yearWeek: [],
    siteId,
    basis: 'week',
  });

  React.useEffect(() => {
    if (startYearWeek && endYearWeek) {
      setFilters({
        yearWeek: [startYearWeek, endYearWeek],
        siteId,
        basis: 'week',
      });
    }
  }, [startYearWeek, endYearWeek, siteId]);

  const { columns, rows, loading } = useKpiDataGrid(
    siteId,
    'week',
    filters.yearWeek,
    true,
  );

  const showTable = Boolean(siteId && !isEmpty(filters.yearWeek));

  const handleClose = () => {
    // clearFilterFieldsData();
    onClose();
  };

  return (
    <ModalDrillDown title={title} open={isOpen} onClose={handleClose}>
      <Sheet
        sx={{
          paddingX: '40px',
        }}
      >
        <TableContextProvider>
          <TableToolbar sx={{ justifyContent: 'flex-end' }}>
            <TableActions>
              <HistoricalKpiFilter
                filters={filters}
                onSubmit={(data) => setFilters(data)}
              />
            </TableActions>
          </TableToolbar>

          <PageContentChildContainer fullHeight={false}>
            {showTable && (
              <KpiTable
                autoHeight
                isCellEditable={() => false}
                showCellVerticalBorder
                disableRowSelectionOnClick
                loading={loading}
                rows={rows}
                columns={columns}
              />
            )}
          </PageContentChildContainer>
        </TableContextProvider>
      </Sheet>
    </ModalDrillDown>
  );
};
