import React from 'react';
import { useTranslation } from 'react-i18next';

import { clients } from '../../modules/constants';
import WeeklyGrossMarginReport from '../../components/Reports/WeeklyGrossMarginReport';

interface IProps {
  siteId?: number;
  week?: number;
}

export const WeeklyGrossMargin: React.FC<IProps> = ({ siteId, week }) => {
  const { t } = useTranslation();

  return (
    <WeeklyGrossMarginReport
      clientKey={clients.taylorFarms}
      tableName={t('taylor_farms_reports.table_names.weekly_gm')}
      siteId={siteId}
      weekNumber={week}
    />
  );
};

export default WeeklyGrossMargin;
