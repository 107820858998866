import {
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useBrowserHistoryFunctions } from './browserHistory.hooks';
import { paths } from '../../../config';
import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';
import { useQueryParams } from './useQueryParams';
import { useSearch } from './useSearch';

import {
  IWikiPage,
  IWikiPaginationCursorDirection,
  IWikiResponse,
} from '../../types/wiki-setup';
import { AnyObject, IdsArray } from '../../types';
import { WikiPageTypeEnum } from '../../../config/wiki-pages-types';

export const WikiTypeOptionList = Object.entries(
  WikiPageTypeEnum,
).map(([id, name]) => ({ id, name }));

const getFormDataList = (pageQueries: any[]) => {
  const responseDataList: any[] = [];
  pageQueries.forEach(({ data }) => {
    if (!data) {
      return;
    }
    responseDataList.push(data);
  });

  return responseDataList;
};

// TODO: move to common helper
export const sendMedia = async (file: File) => {
  let link = '';
  try {
    link = await Api.Media.send(file);
  } catch (e) {
    console.error('-Error-on upload image--', e);
  }

  return link;
};

// Hooks
export const useWikiForm = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const queryClient = useQueryClient();
  const query = useQueryParams();

  const createPage = async (data: IWikiPage) => {
    await Api.KnowledgeSetup.create(data);
    await queryClient.invalidateQueries({
      queryKey: getQueryKey('/knowledge/pages/list', query),
    });
    await navigate(paths.WIKI_SETUP_LIST);
  };

  const updatePage = (pageId: string) => async (data: IWikiPage) => {
    await Api.KnowledgeSetup.update(pageId, data);
    await queryClient.invalidateQueries(
      getQueryKey(`/knowledge/pages/${pageId}`),
    );
    await queryClient.invalidateQueries(getQueryKey('/knowledge/pages/list'));
    await queryClient.invalidateQueries(
      getQueryKey('/knowledge/pages/list', query),
    );
    await navigate(paths.WIKI_SETUP_LIST);
  };

  return {
    createPage,
    updatePage,
  };
};

export const useWikiList = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const query = useQueryParams() as AnyObject;
  const composeSearch = useSearch();
  const queryClient = useQueryClient();
  const { data: wikiList, isLoading } = useQuery<IWikiResponse<IWikiPage>>(
    getQueryKey('/knowledge/pages/list', query),
    () =>
      Api.KnowledgeSetup.list(
        query?.filter,
        query?.cursor,
        query?.direction,
      ).catch((e) => console.log('-Error-on -/knowledge/pages/list-', e)),
  );

  const onUpdate = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    navigate({ pathname: paths.WIKI_SETUP_UPDATE, search });
  };

  const onDelete = async (ids: IdsArray) => {
    await Promise.all(ids.map((id) => Api.KnowledgeSetup.delete(id)));
    await queryClient.invalidateQueries(
      getQueryKey('/knowledge/pages/list', query),
    );
  };

  const redirectToDetailView = React.useCallback(
    (pageId: string) => (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(paths.WIKI_PAGE_BY_ID(pageId));
    },
    [navigate],
  );

  return {
    wikiList,
    isLoading,
    onUpdate,
    onDelete,
    redirectToDetailView,
  };
};

export const useWikiUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { ids = [] } = useQueryParams() as { ids: IdsArray };

  const pageQueries: UseQueryResult<AnyObject>[] = useQueries(
    ids.map((id) => ({
      queryKey: getQueryKey(`/knowledge/pages/${id}`),
      queryFn: () => Api.KnowledgeSetup.getById(id.toString()),
    })),
  );

  if (!ids.length) {
    navigate(paths.WIKI_SETUP_LIST);
  }

  return getFormDataList(pageQueries);
};

export const useWikiViewPageById = (pageId?: string) => {
  return useQuery<IWikiPage>(
    getQueryKey(`/knowledge/pages/${pageId}`),
    () => Api.KnowledgeSetup.getById(pageId ?? ''),
    { retry: false, enabled: !!pageId },
  );
};

export const useWikiDetailByIdView = () => {
  const { pageId } = useParams() as { pageId: string };

  const { data, isLoading, isError } = useQuery<IWikiPage>(
    getQueryKey(`/knowledge/pages/${pageId}`),
    () => Api.KnowledgeSetup.getById(pageId.toString()),
    { retry: false },
  );

  return { data, isLoading, isError };
};

export const useWikiDetailByTypeView = () => {
  const { pathname } = useLocation();
  const pageType = pathname.split('/').pop();

  const { data, isLoading, isError } = useQuery<IWikiPage>(
    getQueryKey(`/knowledge/pages/public/${pageType}`),
    () => Api.KnowledgeSetup.getByType(pageType as WikiPageTypeEnum).catch(),
    { retry: false },
  );

  return { data, isLoading, isError };
};

export const useWikiPagination = () => {
  const search = useQueryParams();
  const composeSearch = useSearch();
  const { pushToHistory } = useBrowserHistoryFunctions();

  return (cursor: string, direction: IWikiPaginationCursorDirection) => {
    // save filter to query string
    pushToHistory({
      search: composeSearch({
        ...search,
        cursor,
        direction,
      }),
    });
  };
};
