import {
  ICardinalHealthWeeklyReportModel,
  ICardinalHealthWeeklyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthWeeklyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyUphBySiteAndEmployeeListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteAndEmployeeListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthWeeklyReportState,
  ICardinalHealthWeeklyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthWeeklyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyUphBySiteAndEmployee = createReducer<
  ICardinalHealthWeeklyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndEmployeeListRequest,
    getCardinalHealthWeeklyUphBySiteAndEmployeeListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndEmployeeListSuccess,
    getCardinalHealthWeeklyUphBySiteAndEmployeeListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndEmployeeListFailed,
    getCardinalHealthWeeklyUphBySiteAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndEmployeeCountRequest,
    getCardinalHealthWeeklyUphBySiteAndEmployeeCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndEmployeeCountSuccess,
    getCardinalHealthWeeklyUphBySiteAndEmployeeCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndEmployeeCountFailed,
    getCardinalHealthWeeklyUphBySiteAndEmployeeCountFailed,
  );

export default cardinalHealthWeeklyUphBySiteAndEmployee;
