import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IUpdateWorkdays,
  IWorkdaysModel,
  PayloadWithNavigateFunc,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushWorkdaysState = createAction('Flush workdays data in store');

// Fetch workdays actions
export const getWorkdaysListRequest = createAction<IFilter>(
  'Make request to fetch workdays list',
);
export const getWorkdaysListSuccess = createAction<IWorkdaysModel[]>(
  'Workdays list has been fetched successfully',
);
export const getWorkdaysListFailed = createAction('Cannot fetch workdays list');

// get workdays count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getWorkdaysCountRequest = createAction<IFilter>(
  'Make request to get workdays count',
);
export const getWorkdaysCountSuccess = createAction<ICountResponse>(
  'Workdays count has been fetched successfully',
);
export const getWorkdaysCountFailed = createAction('Cannot get workdays count');

// Updating actions
export const updateWorkdaysRequest = createAction<
  PayloadWithNavigateFunc<IUpdateWorkdays[]>
>('Make request to update workdays');
export const updateWorkdaysFailed = createAction('Cannot update workdays');

// Fetch combobox list actions
export const getWorkdaysComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of workdays',
);
export const getWorkdaysComboboxListSuccess = createAction<
  Partial<IWorkdaysModel>[]
>('Combobox list of workdays has been fetched successfully');
export const getWorkdaysComboboxListFailed = createAction(
  'Cannot fetch combobox workdays list',
);

// export actions
export const {
  exportReportRequest: exportWorkdaysRequest,
  exportReportSuccess: exportWorkdaysSuccess,
  exportReportFailed: exportWorkdaysFailed,
} = new ExportActionCreator('Workdays').takeExportActions();
