import { IdsArray, IStoreState } from '../types';
import { createSelector } from 'reselect';
import { filter } from 'lodash';

export const getClientRefreshKey = (state: IStoreState) =>
  state.client.refreshKey;

export const getClientData = (state: IStoreState) => state.client;

/**
 * Get server error
 * @param client - State client
 */
export const getServerError = ({ client }: IStoreState) => client.error;

/**
 * Get client list
 * @param client - State client
 */
export const getClientList = ({ client }: IStoreState) => client.list;

/**
 * Get clients by array of ids
 * @param client - State client
 */
export const getClientsByIds = createSelector(
  getClientList,
  (clients) => (ids: IdsArray) =>
    filter(clients, (client) => ids.includes(`${client.id}`)),
);

/**
 * Get client count
 * @param client - State client
 */
export const getClientCount = ({ client }: IStoreState) => client.count;

/**
 * Get combobox list
 */
export const getClientsComboboxList = ({ client }: IStoreState) =>
  client.comboboxList;

export const getIsClientsTableDataRequestInProgress = createSelector(
  getClientData,
  (data) =>
    data.isClientsListRequestInProgress ||
    data.isClientsCountRequestInProgress ||
    data.isClientCreateRequestInProgress ||
    data.isClientUpdateRequestInProgress ||
    data.isClientDeleteRequestInProgress,
);
