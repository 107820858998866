import React, { useEffect } from 'react';
import { Drawer } from '@mui/joy';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ComboBoxOption } from 'src/components/ComboBox';
import { createIssueRequest } from 'src/modules/actions/issue';
import { ICreateIssue } from 'src/modules/types';
import {
  useFetchActiveDepartmentsCombobox,
  useFetchIssueTypesCombobox,
  useValidate,
} from 'src/modules/utils/hooks';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { getIssueTypesWithSitesComboboxList } from 'src/modules/selectors/issueType';
import { getDepartmentOptionsByGloballySites } from 'src/modules/selectors/department';
import { useTranslation } from 'react-i18next';
import { createIssueScheme } from 'src/modules/schemes/issues';
import { useFormik } from 'formik';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import FormikImageUpload from 'src/components/Formik/FormikImageUpload';

import { generateFormDataFields } from 'src/modules/utils/helpers/form';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';

const initialValues = {
  // Required properties
  downTime: '',
  issueTypeId: NaN,
  departmentId: NaN,
};

interface IDowntimeCreateProps {
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}

export const DowntimeCreate: React.FC<IDowntimeCreateProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const { t } = useTranslation();

  // create dispatcher
  const dispatcher = useDispatch();

  const validate = useValidate(createIssueScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      const formData = generateFormDataFields(data);

      dispatcher(
        createIssueRequest({
          data: (formData as unknown) as ICreateIssue,
          func: onCreate,
        }),
      );
    },
  });

  const fetchIssueTypesCombobox = useFetchIssueTypesCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  useEffect(() => {
    fetchIssueTypesCombobox();
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // fetch issueTypes list from store
  const issueTypes = useSelector(getIssueTypesWithSitesComboboxList);
  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('issues.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikDatetimepicker
              required
              label={t('issues.downtime')}
              id="downTime"
              fullWidth
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              required
              id="issueTypeId"
              placeholder={t('issues.type')}
              options={issueTypes as ComboBoxOption[]}
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCombobox
              required
              id="departmentId"
              placeholder={t('issues.department')}
              options={departments as ComboBoxOption[]}
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="duration"
              label={t('issues.duration')}
              name="duration"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextarea
              variant="outlined"
              id="description"
              label={t('issues.description')}
              name="description"
              formik={formik}
              minRows={3}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikImageUpload name="file" formik={formik} />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
