import React from 'react';
import { Sheet } from '@mui/joy';

interface IDrawerBodyStepperProps {
  children: React.ReactNode;
}

export const DrawerBodyStepper = ({ children }: IDrawerBodyStepperProps) => {
  return (
    <Sheet sx={{ pl: 2, mt: 2, mb: 3, bgcolor: 'inherit' }}>{children}</Sheet>
  );
};
