import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  IFilterData,
  IFilterInclude,
  IFilterWhere,
  IIssueModel,
} from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export interface IDowntimeFilterPanelFilters {
  downTime?: string;
  issueTypeId?: number;
  departmentId?: number;
  description?: string;
  duration?: number;
}

export const useDowntimeInclusion = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => [
      {
        relation: 'issueType',
      },
      {
        relation: 'department',
        scope: {
          where: {
            siteId: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    ],
    [globallySelectedSiteIds],
  );
};

export const useDowntimeTableInclusionObject = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);
  return React.useMemo(
    () => ({
      issueType: {
        relationType: 'inner',
      },
      department: {
        relationType: 'inner',
        scope: {
          where: {
            siteId: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

export const useDowntimeTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      downTime: {
        value: '',
        property: 'downTime',
        operator: 'gte' as const,
      },
      issueTypeId: {
        value: '',
        property: 'issueTypeId',
        operator: 'eq' as const,
      },
      departmentId: {
        value: '',
        property: 'departmentId',
        operator: 'eq' as const,
      },
      description: {
        value: '',
        property: 'description',
        operator: 'like' as const,
      },
      duration: {
        value: '',
        property: 'duration',
        operator: 'like' as const,
      },
    };
  }, []);
};

export const useDowntimeComposeWhere = (where: Partial<IIssueModel>) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.downTime
        ? {
            downTime: where.downTime,
          }
        : {}),
      ...(where.issueTypeId
        ? {
            issueTypeId: where.issueTypeId,
          }
        : {}),
      ...(where.departmentId
        ? {
            departmentId: where.departmentId,
          }
        : {}),
      ...(where.description
        ? {
            description: {
              like: `%${where.description}%`,
            },
          }
        : {}),
      ...(where.duration
        ? {
            duration: {
              like: `%${where.duration}%`,
            },
          }
        : {}),
    }),
    [
      where.downTime,
      where.issueTypeId,
      where.departmentId,
      where.description,
      where.duration,
    ],
  );
};

export const useDowntime = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();
  const inclusionObj = useDowntimeTableInclusionObject();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'downTime',
        orderConfig: { orderBy: 'downTime' },
        label: t('issues.downtime'),
      },
      {
        id: 'issueType.name',
        orderConfig: { orderBy: 'issueType.name' },
        label: t('issues.type'),
      },
      {
        id: 'department.name',
        orderConfig: { orderBy: 'department.name' },
        label: t('issues.department'),
      },
      {
        id: 'attachment',
        orderConfig: { orderBy: 'picture' },
        label: t('issues.picture'),
      },
      {
        id: 'description',
        orderConfig: { orderBy: 'description' },
        label: t('issues.description'),
      },
      {
        id: 'duration',
        orderConfig: { orderBy: 'duration' },
        label: t('issues.duration'),
      },
    ],
    [t],
  );

  return useBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
  });
};

export const useDowntimePermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.downtime.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.downtime.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.downtime.delete.id,
  );

  return { allowedToCreate, allowedToUpdate, allowedToDelete };
};

export const useDowntimeFilter = (
  appliedFilters: IDowntimeFilterPanelFilters,
) => {
  const _include = useDowntimeInclusion();
  const _where = useDowntimeComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};
