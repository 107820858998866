import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthDailyUphBySite',
);

// get list actions
export const {
  getListRequest: getCardinalHealthDailyUphBySiteListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteListFailed,
} = actionCreator.takeListActions<ICardinalHealthDailyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthDailyUphBySiteCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthDailyUphBySiteRequest,
  exportReportSuccess: exportCardinalHealthDailyUphBySiteSuccess,
  exportReportFailed: exportCardinalHealthDailyUphBySiteFailed,
} = new ExportActionCreator('CardinalHealthDailyUphBySite').takeExportActions();
