import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IUpdateLogtime } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createLogtimeScheme } from './create-logtime.scheme';

export const updateLogtimesScheme: JSONSchemaType<IUpdateLogtime[]> = {
  type: 'array',
  items: {
    ...createLogtimeScheme,
    type: 'object',
    required: ['badge'],
    additionalProperties: true,
    properties: {
      ...createLogtimeScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};

export const updateLogtimeBulksScheme: JSONSchemaType<{
  bulks: IUpdateLogtime[];
}> = {
  type: 'object',
  required: ['bulks'],
  properties: {
    bulks: updateLogtimesScheme,
  },
};
