import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ContentLayout } from 'src/components/Layout/ContentLayout';

interface IPageWithTabsConfiguration {
  tabLabel: string;
  component: React.ReactElement;
}
interface IPageWithTabsProps {
  configuration: IPageWithTabsConfiguration[];
  rightSideComponent?: React.ReactElement;
  indexOfActiveTab?: number;
  onActiveTabChange?: (activeTabIndex: number) => void;
}

export const PageWithTabs: React.FC<IPageWithTabsProps> = ({
  configuration,
  rightSideComponent,
  onActiveTabChange,
  indexOfActiveTab = 0,
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(indexOfActiveTab);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    if (onActiveTabChange) {
      onActiveTabChange(newValue);
    }

    setActiveTabIndex(newValue);
  };

  const { tabs, components } = React.useMemo(() => {
    return configuration.reduce<{
      tabs: string[];
      components: React.ReactElement[];
    }>(
      (result, item) => {
        result.components.push(item.component);
        result.tabs.push(item.tabLabel);

        return result;
      },
      {
        tabs: [],
        components: [],
      },
    );
  }, [configuration]);

  const activeComponent = components.find((_, i) => activeTabIndex === i);

  React.useEffect(() => {
    if (indexOfActiveTab) {
      setActiveTabIndex(indexOfActiveTab);
    }
  }, [indexOfActiveTab]);

  return (
    <ContentLayout>
      <AppBar
        component="div"
        color="inherit"
        position="static"
        sx={(theme) => ({
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: theme.shadows[1],
          marginBottom: theme.spacing(2),
        })}
      >
        <Tabs
          value={activeTabIndex}
          onChange={handleChange}
          aria-label="page with tabs"
        >
          {tabs.map((tab) => (
            <Tab key={tab} label={tab} />
          ))}
        </Tabs>
        <Box>{rightSideComponent}</Box>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: '100%',
          width: '100%',
        }}
      >
        {activeComponent}
      </Box>
    </ContentLayout>
  );
};
