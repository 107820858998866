import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import { PayboardIncentivePaymentBase } from './components/PayboardIncentivePaymentBase';
import { TableContextProvider } from 'src/components/Table';

export const PayboardIncentiveDetailedPayment = () => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        label: t('payboard.year'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'week',
        label: t('payboard.week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'yearWeek',
        label: t('payboard.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'extEmployeeId',
        label: t('employees.emp_id'),
        orderConfig: { orderBy: 'extEmployeeId' },
      },
      {
        id: 'firstName',
        label: t('employees.first_name'),
        orderConfig: { orderBy: 'firstName' },
      },
      {
        id: 'lastName',
        label: t('employees.last_name'),
        orderConfig: { orderBy: 'lastName' },
      },
      {
        id: 'timeTotal',
        label: t('payboard.time_total'),
        orderConfig: { orderBy: 'sumTimeTotal' },
      },
      {
        id: 'payRate',
        label: t('payboard.payrate'),
        orderConfig: { orderBy: 'payRate' },
      },
      {
        id: 'totalPayment',
        label: t('payboard.total_payment'),
        orderConfig: { orderBy: 'totalPay' },
      },
      {
        id: 'bonusPayment',
        label: t('payboard.bonus_payment'),
        orderConfig: { orderBy: 'incentivePrice' },
      },
      {
        id: 'markupCost',
        label: t('payboard.markup_cost'),
        orderConfig: { orderBy: 'markupCost' },
      },
      {
        id: 'clientName',
        label: t('users.client_name'),
      },
      {
        id: 'siteName',
        label: t('payboard.site_name'),
      },
      {
        id: 'staffingProvider',
        label: t('payboard.staffing_provider'),
        orderConfig: { orderBy: 'staffingProvider' },
      },
      {
        id: 'weekEndingDate',
        label: t('payboard.week_ending_date'),
      },
      {
        id: 'area',
        label: t('payboard.incentive.area'),
      },
      {
        id: 'departmentName',
        label: t('common.department'),
      },
      {
        id: 'incentiveType',
        label: t('sites.incentiveType'),
      },
    ],
    [t],
  );

  const filterFieldsConfiguration = React.useMemo(() => {
    return {
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
      year: {
        value: '',
        property: 'year',
        operator: 'eq' as const,
      },
      extEmployeeId: {
        value: '',
        property: 'extEmployeeId',
        operator: 'eq' as const,
      },
      staffingProvider: {
        value: '',
        property: 'staffingProvider',
        operator: 'like' as const,
      },
      totalPayment: {
        value: 0,
        property: 'totalPayment',
        operator: 'gt' as const,
      },
    };
  }, []);

  return (
    <TableContextProvider>
      <PayboardIncentivePaymentBase
        isActionsDisabled
        title={t('payboard.incentive_detailed_payment.title')}
        headCellsConfig={headCellsConfig}
        incentivePaymentDataUrl="/incentive/detailed"
        filterFieldsConfiguration={filterFieldsConfiguration}
      />
    </TableContextProvider>
  );
};
