import React from 'react';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { useTranslation } from 'react-i18next';

const supportCategoriesOptions = [
  { id: 'Technical_Issue', name: 'Technical Issue' },
  { id: 'Process_Question', name: 'Process Question' },
  { id: 'Feature_Request', name: 'Feature Request' },
  { id: 'General_Question', name: 'General Question' },
];

interface ISupportCategoryProps extends Pick<IComboboxProps, 'onChange'> {
  value: string;
  errorMessage?: string;
}

export const SupportCategory: React.FC<ISupportCategoryProps> = ({
  value,
  onChange,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <ComboBox
      required
      placeholder={t('issue_tracker.fields.support_category')}
      options={(supportCategoriesOptions as unknown) as ComboBoxOption[]}
      value={
        ((supportCategoriesOptions.find(
          (supportCategory) => supportCategory.id === value,
        ) as unknown) as ComboBoxOption) || null
      }
      onChange={onChange}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
    />
  );
};
