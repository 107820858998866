import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import {
  IPageSettingModel,
  IPageSettingViewConfig,
  PolicyConfig,
} from '../../types';

export const usePageSettingHelpers = () => {
  const pageSettingViewConfigToPolicyConfig = (
    config: IPageSettingViewConfig,
  ) =>
    reduce(
      config,
      (acc, cur) => {
        acc[cur.route] = cur.policies.map((p) =>
          omit(omitBy(p, isNil), ['id']),
        );
        return acc;
      },
      {},
    );

  const policyConfigToPageSettingViewConfig = (config: PolicyConfig) =>
    reduce<IPageSettingModel['config'], IPageSettingViewConfig>(
      config,
      (acc, cur, index) => {
        acc.push({
          route: index,
          policies: cur.map((p) => ({
            ...p,
            id: `${p.resource}_${p.action}`,
          })),
        });
        return acc;
      },
      [],
    );

  return {
    pageSettingViewConfigToPolicyConfig,
    policyConfigToPageSettingViewConfig,
  };
};
