import React from 'react';
import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from '../FilterPanelCombobox';
import { useSelector } from 'react-redux';
import { useFetchShiftsCombobox } from 'src/modules/utils';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';

type IFilterPanelShiftsComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
  value?: number | null;
  siteId?: number;
};

export const FilterPanelShiftsCombobox = ({
  filterId,
  value,
  siteId,
  ...props
}: IFilterPanelShiftsComboboxProps) => {
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  React.useEffect(() => {
    fetchShiftsCombobox();
  }, [fetchShiftsCombobox]);

  const shifts = useSelector(getShiftsComboboxList);

  const options = React.useMemo(() => {
    if (siteId) {
      return shifts.filter((shift) => shift.siteId === siteId);
    }

    return shifts.map((shift) => ({
      ...shift,
      name: `${shift.name} / ${(shift as any).site.name}`,
    }));
  }, [shifts, siteId]);

  const comboboxValue = options.find((site) => site.id === value);

  return (
    <FilterPanelCombobox
      value={comboboxValue}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
