import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ISagaAction,
  ISagaActionBind,
  INotificationData,
  IFilter,
  PayloadWithFilters,
  INotificationModel,
} from '../../types';

export const getNotificationsRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const notifications = yield call(Api.Notification.list, action.payload);
    yield put(actions.getNotificationsListSuccess(notifications));
  } catch (e) {
    yield put(actions.getNotificationsListFailed());
  }
};

export const getMoreNotificationsRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const notifications = yield call(Api.Notification.list, action.payload);
    yield put(actions.getMoreNotificationsListSuccess(notifications));
  } catch (e) {
    yield put(actions.getMoreNotificationsListFailed());
  }
};

export const changeNotificationStatusRequestSaga = function* (
  action: ISagaAction<INotificationData>,
): SagaIterator {
  try {
    yield call(Api.Notification.changeNotificationStatus, action.payload);
    yield put(actions.changeNotificationStatusSuccess(action.payload));
  } catch (e) {
    yield put(actions.changeNotificationStatusFailed());
  }
};

export const changeAllNotificationsStatusRequestSaga = function* (
  action: ISagaAction<Pick<INotificationModel, 'read'>>,
): SagaIterator {
  try {
    yield call(Api.Notification.changeAllNotificationsStatus, action.payload);
    yield put(actions.changeAllNotificationsStatusSuccess(action.payload));
  } catch (e) {
    yield put(actions.changeAllNotificationsStatusFailed());
  }
};

export const deleteNotificationRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<number>>): SagaIterator {
  try {
    yield call(Api.Notification.deleteNotification, data);

    yield put(actions.getNotificationsListRequest({ filter: filters.list }));

    yield put(actions.deleteNotificationSuccess(data));
  } catch (e) {
    yield put(actions.deleteNotificationFailed());
  }
};

export const deleteAllNotificationsRequestSaga = function* (): SagaIterator {
  try {
    yield call(Api.Notification.deleteAllNotifications);
    yield put(actions.deleteAllNotificationsSuccess());
  } catch (e) {
    yield put(actions.deleteAllNotificationsFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getNotificationsListRequest,
    saga: getNotificationsRequestSaga,
  },
  {
    action: actions.getMoreNotificationsListRequest,
    saga: getMoreNotificationsRequestSaga,
  },
  // update notification status
  {
    action: actions.changeNotificationStatusRequest,
    saga: changeNotificationStatusRequestSaga,
  },
  {
    action: actions.changeAllNotificationsStatusRequest,
    saga: changeAllNotificationsStatusRequestSaga,
  },
  {
    action: actions.deleteNotificationRequest,
    saga: deleteNotificationRequestSaga,
  },
  {
    action: actions.deleteAllNotificationsRequest,
    saga: deleteAllNotificationsRequestSaga,
  },
];
