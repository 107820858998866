import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box } from '@mui/material';
// import {
//   convertToHTML,
//   convertFromHTML,
// } from 'draft-convert';

import { WikiTypeOptionList } from '../../modules/utils/hooks/wiki-setup.hooks';
import { useFetchSitesCombobox, useValidate } from '../../modules/utils';
import { IWikiPage } from '../../modules/types/wiki-setup';
import { createWikiPageScheme } from '../../modules/schemes/knowledge-setup/create-knowledge-setup.scheme';

import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import { CancelButton } from '../../components/Form/CancelButton';
import { SubmitButton } from '../../components/Form/SubmitButton';

// import QuillEditor from './QuillEditor/Editor';
// import SlateEditor from './SlateEditor/Editor';
import SlateEditor from './Slate';

import FormikMultipleSelect from '../../components/Formik/FormikMultipleSelect';
import { getSitesComboboxList } from '../../modules/selectors/site';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';
import { MultipleSelectOption } from 'src/components/Formik/FormikMultipleSelectWithSearch';

type WikiPageFormProps = {
  initialValues: IWikiPage;
  onSubmitHandler: (values: IWikiPage) => void;
  initialContent?: string;
};

const WikiPageForm: React.FC<WikiPageFormProps> = ({
  initialValues,
  onSubmitHandler,
  initialContent,
}) => {
  const { t } = useTranslation();

  // const [editorState, setEditorState] = React.useState(() =>
  //   EditorState.createEmpty(),
  // );

  // React.useEffect(() => {
  //   const contentBlock = convertFromHTML(initialContent);
  //   if (contentBlock) {
  //     const editorState = EditorState.createWithContent(contentBlock);
  //     setEditorState(editorState);
  //   }
  // }, [initialContent]);

  const [content, setContent] = React.useState<string | undefined>(
    initialContent,
  );

  const isUpdateForm = Boolean(initialValues.id);

  const fetchSitesCombobox = useFetchSitesCombobox();

  // make request to fetch sites from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const validate = useValidate(createWikiPageScheme);
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      // confluence accepts ONLY self-closing tage with trailing slashes (xhtml specification)
      // so before sending html to the back-end we need to convert it to xhtml
      const doc = new DOMParser().parseFromString(content!, 'text/html');
      const html = new XMLSerializer().serializeToString(doc);
      onSubmitHandler({ ...data, body: { storage: { value: html || '' } } });
    },
  });

  // const updateTextDescription = async (state: any) => {
  //   await setEditorState(state);
  //   const html = convertToHTML(editorState.getCurrentContent());
  //   setContent(html);
  // };
  const title = isUpdateForm
    ? t('knowledge_setup.update_title')
    : t('knowledge_setup.create_title');
  const submitButtonName = isUpdateForm
    ? t('common.update')
    : t('common.create');

  return (
    <CreateFormLayout
      cardTitle={title}
      handleFormSubmit={formik.handleSubmit as any}
      fields={formik.values}
      isFormValid={formik.isValid}
      maxWidth="md"
      sx={{ width: '80%' }}
    >
      <FormFieldContainer>
        <FormikMultipleSelect
          id="metadata.properties.meta.value.sites"
          label={t('knowledge_setup.sites')}
          title={t('knowledge_setup.sites')}
          formik={formik}
          options={(sites as unknown) as MultipleSelectOption[]}
          disabled={isUpdateForm}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          id="metadata.properties.meta.value.pageType"
          label={t('knowledge_setup.type')}
          placeholder={t('knowledge_setup.type')}
          options={WikiTypeOptionList as any}
          formik={formik}
          disabled={isUpdateForm}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          id="title"
          variant="outlined"
          fullWidth
          label={t('knowledge_setup.page_title')}
          name="title"
          formik={formik}
        />
      </FormFieldContainer>

      {/*<QuillEditor content={content} setContent={setContent} />*/}

      <SlateEditor
        content={content}
        setContent={setContent}
        initialContent={initialContent}
      />

      {/*<Editor*/}
      {/*  editorState={editorState}*/}
      {/*  editorClassName={classes.textEditor}*/}
      {/*  onEditorStateChange={updateTextDescription}*/}
      {/*  toolbar={{*/}
      {/*    image: {*/}
      {/*      uploadEnabled: true,*/}
      {/*      previewImage: true,*/}
      {/*      uploadCallback: async (file: File) => {*/}
      {/*        const link = await sendMedia(file);*/}
      {/*        return { data: { link } };*/}
      {/*      },*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}

      <Box sx={{ padding: '0 32px', display: 'flex', marginTop: 3 }}>
        <CancelButton sx={{ marginRight: 2 }}></CancelButton>
        <SubmitButton>{submitButtonName}</SubmitButton>
      </Box>
    </CreateFormLayout>
  );
};

export default WikiPageForm;
