import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import {
  useBrowserHistoryFunctions,
  useCreateUserValidationScheme,
  useFetchActiveDepartmentsCombobox,
  useFetchClientsCombobox,
  useFetchShiftsCombobox,
  useFetchSitesCombobox,
  useFetchStaffingProvidersCombobox,
  useGenerateInitialValues,
  usePrepareUserWithAttributes,
  useValidate,
} from '../../modules/utils';
import { useTranslation } from 'react-i18next';
import { FormActions } from '../../components/Form/FormActions';

import { useFormik } from 'formik';
import RolesCombobox from '../../components/Formik/comboboxes-with-entities/RolesCombobox';
import { getSitesComboboxList } from '../../modules/selectors/site';
import { getShiftsComboboxListBySites } from '../../modules/selectors/shift';
import { getDepartmentsComboboxListBySites } from '../../modules/selectors/department';
import { AnyObject, ICreateUserBaseFields } from '../../modules/types';
import { roles as rolesConfig } from '../../config';
import { ComboBoxOption } from '../../components/ComboBox';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import { getClientsComboboxList } from '../../modules/selectors/client';
import { createUserRequest } from '../../modules/actions';
import { useRenderUserOtherFields } from '../../modules/utils/hooks/useRenderUserOtherFields';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const UsersCreate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  // create dispatcher
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatcher = useDispatch();

  const initialRole = rolesConfig.GM;

  const [role, setRole] = React.useState<string>(initialRole);

  const initialValues = useGenerateInitialValues(role);

  const getSchema = useCreateUserValidationScheme();

  const prepareRequestData = usePrepareUserWithAttributes();

  const validate = useValidate<ICreateUserBaseFields>(getSchema(role));

  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchStaffingProviderCombobox = useFetchStaffingProvidersCombobox();
  const fetchClientsCombobox = useFetchClientsCombobox();

  // make request to fetch sites from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchStaffingProviderCombobox();
    fetchClientsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  ) as ComboBoxOption[];

  const onRoleChange = (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    if (value?.id) {
      setRole((value.id as unknown) as string);
    }
  };

  const clients = useSelector(
    getClientsComboboxList,
    shallowEqual,
  ) as ComboBoxOption[];

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (data) => {
      dispatcher(
        createUserRequest({
          data: prepareRequestData(data, {
            siteId: true,
            clientId: true,
            staffingId: true,
          }),
          navigate,
        }),
      );
    },
  });

  // fetch shifts from store
  const shifts = useSelector(
    getShiftsComboboxListBySites,
    shallowEqual,
  )(formik.values.sites ?? []);

  const departments = useSelector(
    getDepartmentsComboboxListBySites,
    shallowEqual,
  )(formik.values.sites ?? []);

  const getOtherFields = useRenderUserOtherFields({
    formik,
    sites,
    shifts,
    departments,
    staffingProviders,
    clients,
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('users.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <RolesCombobox
              id="role"
              name="role"
              required
              onChange={onRoleChange}
              label={t('common.role')}
              placeholder={t('common.role')}
              fieldAsId="key"
              formik={formik}
            />
          </FormFieldContainer>

          {getOtherFields()}

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default UsersCreate;
