import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getDowntimeChartData,
  getIssuesByIds,
  getIssuesComboboxList,
} from '../../selectors/issue';
import { getDowntimeChartDataRequest } from '../../actions';
import { IdsArray, IFilter, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getIssueComboboxListRequest,
  getIssueListRequest,
} from '../../actions/issue';
import { defaultOrderDetails } from '../helpers/filter';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export const issueInclusions = [
  {
    relation: 'department',
  },
  {
    relation: 'issueType',
  },
];

export const useIssuesInclusion = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => [
      {
        relation: 'issueType',
      },
      {
        relation: 'department',
        scope: {
          where: {
            siteId: {
              inq: globallySelectedSiteIds,
            },
          },
        },
      },
    ],
    [globallySelectedSiteIds],
  );
};

export const issueComboboxFilter = {
  filter: {
    fields: {
      id: true,
      downTime: true,
    },
    include: issueInclusions,
  },
};

const useIssueComboboxFilter = () => {
  const issuesInclusion = useIssuesInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          downTime: true,
        },
        include: issuesInclusion,
      },
    }),
    [issuesInclusion],
  );
};
/**
 * A custom hook to fetch issues from store if they exist otherwise to make a request to fetch needed issues from
 * the server
 */
export const useFetchIssuesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch issues list from store
  const issues = useSelector(
    (state) => getIssuesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(issues)) {
      dispatcher(
        getIssueListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: issueInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch issue combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchIssuesCombobox = () => {
  const dispatcher = useDispatch();
  const issueComboboxFilter = useIssueComboboxFilter();

  const dispatchGetIssuesComboboxListRequest = React.useCallback(() => {
    dispatcher(getIssueComboboxListRequest(issueComboboxFilter));
  }, [issueComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetIssuesComboboxListRequest();
  }, [dispatchGetIssuesComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getIssuesComboboxList, shallowEqual);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetIssuesComboboxListRequest();
    }
  };
};

export const useFetchDowntimeChartData = () => {
  const dispatcher = useDispatch();
  const { categories } = useSelector(getDowntimeChartData);

  return React.useCallback(
    (filter?: IFilter, { force } = { force: false }) => {
      if (isEmpty(categories) || force) {
        dispatcher(getDowntimeChartDataRequest(filter ?? {}));
      }
    },
    [categories, dispatcher],
  );
};

export const useIssuesDefaultFilters = () => {
  const include = useIssuesInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};
