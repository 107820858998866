import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateShiftRequest,
  IdsArray,
  IFilter,
  IShiftModel,
  PayloadWithNavigateFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushShiftState = createAction('Flush shift data in store');

// Creation actions
export const createShiftRequest = createAction<
  PayloadWithNavigateFunc<ICreateShiftRequest>
>('Make request to create a new shift');
export const createShiftSuccess = createAction<IShiftModel>(
  'New shift has been created successfully',
);
export const createShiftFailed = createAction('Cannot create a new shift');

// Fetch shifts actions
export const getShiftListRequest = createAction<IFilter>(
  'Make request to fetch shift list',
);
export const getShiftListSuccess = createAction<IShiftModel[]>(
  'Shifts list has been fetched successfully',
);
export const getShiftListFailed = createAction('Cannot fetch shift list');

// get shifts count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getShiftCountRequest = createAction<IFilter>(
  'Make request to get shift count',
);
export const getShiftCountSuccess = createAction<ICountResponse>(
  'Shifts count has been fetched successfully',
);
export const getShiftCountFailed = createAction('Cannot get shift count');

// delete actions
export const deleteShiftRequest = createAction<IdsArray>(
  'Make request to delete shifts',
);
export const deleteShiftFailed = createAction('Cannot delete shifts');

// Updating actions
export const updateShiftsRequest = createAction<
  PayloadWithNavigateFunc<IShiftModel[]>
>('Make request to update shifts');
export const updateShiftsFailed = createAction('Cannot update shifts');

// Fetch combobox list actions
export const getShiftComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of shifts',
);
export const getShiftComboboxListSuccess = createAction<Partial<IShiftModel>[]>(
  'Combobox list of shifts has been fetched successfully',
);
export const getShiftComboboxListFailed = createAction(
  'Cannot fetch combobox shifts list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Shift').takeExportActions();

export const exportShiftRequest = exportReportRequest;
export const exportShiftSuccess = exportReportSuccess;
export const exportShiftFailed = exportReportFailed;
