import React from 'react';
import { Sheet } from '@mui/joy';

interface IFormFieldContainerProps {
  children: React.ReactNode;
}

export const FormFieldContainer = ({ children }: IFormFieldContainerProps) => {
  return (
    <Sheet sx={{ px: 4, bgcolor: 'inherit', '&:not(:last-child)': { mb: 2 } }}>
      {children}
    </Sheet>
  );
};
