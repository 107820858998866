import React from 'react';
import { get, map } from 'lodash';
import { Grid } from '@mui/joy';

import { DrawerBodySectionContent } from 'src/components/_ui-kit/Drawer';
import { MUI_SIZE_2, MUI_SIZE_5 } from 'src/config';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { AnyObject } from 'src/modules/types';
import { useIncentivePricingSettingsForm } from 'src/modules/utils/hooks/incentive-pricing-settings';

export interface IIncentiveMetaTagsProps {
  formik: AnyObject;
  index?: number;
}

export const IncentiveMetaTags: React.FC<IIncentiveMetaTagsProps> = ({
  formik,
  index,
}) => {
  const composeName = React.useCallback(
    (name: string) => (index !== undefined ? `${index}.${name}` : name),
    [index],
  );

  const siteId = React.useMemo(
    () => get(formik.values, composeName('siteId')),
    [composeName, formik.values],
  );

  const { settings = [] } = useIncentivePricingSettingsForm(siteId);

  React.useEffect(() => {
    for (const i in settings) {
      formik.setFieldValue(composeName(`metadata.${i}.key`), settings[i].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, siteId]);

  return (
    <DrawerBodySectionContent>
      {map(get(formik.values, composeName('metadata')), (v, k) => (
        <Grid container mb={MUI_SIZE_2} key={k}>
          <Grid xs={MUI_SIZE_5}>
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                label={get(settings, [k, 'title'])}
                id={composeName(`metadata.${k}.value`)}
                name={composeName(`metadata.${k}.value`)}
                formik={formik}
              />
            </FormFieldContainer>
          </Grid>
        </Grid>
      ))}
    </DrawerBodySectionContent>
  );
};
