import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from '../../../../../components/Charts/ChartContainer';
import { useChartRequiredUtils } from '../../../../../components/Charts/common';
import { LineChart } from '../../../../../components/Charts/2d/LineChart';
import { executiveSummaryChartHeight } from '../executiveSummary.constants';
import { fetchExecutiveSummaryReportWeeklyRevenueAndCostChartData } from '../../../../../modules/utils/Api/executivesSummaryReport';
import { useGetDataForCharts } from '../../../../../modules/utils/hooks/common/request';

interface IExecutiveSummaryRevenueCostChartProps {
  siteId?: number;
}

export const ExecutiveSummaryRevenueCostChart = ({
  siteId,
}: IExecutiveSummaryRevenueCostChartProps) => {
  const { t } = useTranslation();

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const {
    data,
    getData,
    isLoading,
  } = useGetDataForCharts(
    fetchExecutiveSummaryReportWeeklyRevenueAndCostChartData,
    { initialData: { series: [], categories: [] } },
  );

  const series = React.useMemo(() => {
    return data.series
      ? data.series.map((item: any) => ({
          ...item,
          unit: '$',
          title: {
            align: 'left',
            text: t(`financial_tracker.${item.name}`),
            style: {
              fontSize: '12px',
              fontWeight: '400',
            },
          },
        }))
      : [];
  }, [data, t]);

  React.useEffect(() => {
    getData({ siteId });
  }, [getData, siteId]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('executive_summary.revenue_cost_of_sites')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('revenue_cost_of_sites')}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isLoading}
        showNoData={!isLoading && !data.series.length}
      >
        <LineChart
          ref={chartRef}
          height={executiveSummaryChartHeight}
          categories={data.categories}
          series={series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
