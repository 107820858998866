import React from 'react';
import { Box, useTheme } from '@mui/joy';

interface IChartsDashboardBodyBody {
  children: React.ReactNode;
}

export const ChartsDashboardBody = ({
  children,
}: React.PropsWithChildren<IChartsDashboardBodyBody>) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        minWidth: '100%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      }}
    >
      {children}
    </Box>
  );
};
