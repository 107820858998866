import { filter, isEmpty, map, omit, reduce, reject } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateEmployeeSkill } from '../types';

export const getEmployeeSkillRefreshKey = (state: IStoreState) =>
  state.employeeSkill.refreshKey;

export const getEmployeeSkillData = (state: IStoreState) => state.employeeSkill;

/**
 * Get server error
 * @param employeeSkill - State employeeSkill
 */
export const getServerError = ({ employeeSkill }: IStoreState) =>
  employeeSkill.error;

/**
 * Get employeeSkill list
 * @param employeeSkill - State employeeSkill
 */
export const getEmployeeSkillList = ({ employeeSkill }: IStoreState) =>
  employeeSkill.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param employeeSkill - State employeeSkill
 */
export const getEmployeeSkillTable = createSelector(
  getEmployeeSkillList,
  (employeeSkill) =>
    map(
      filter(employeeSkill, (s) => !isEmpty(s.employee) && !isEmpty(s.skill)),
    ),
);

/**
 * Get employeeSkills by array of ids
 * @param employeeSkill - State employeeSkill
 */
export const getEmployeeSkillsByIds = createSelector(
  getEmployeeSkillList,
  (employeeSkills) => (ids: IdsArray) => {
    const initial: IUpdateEmployeeSkill[] = [];
    return reduce(
      employeeSkills,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push({
            ...omit(cur, ['employee', 'warning']),
            attachments: reject(cur.attachments, ['id', null]),
          });
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get employeeSkill count
 * @param employeeSkill - State employeeSkill
 */
export const getEmployeeSkillCount = ({ employeeSkill }: IStoreState) =>
  employeeSkill.count;

/**
 * Get combobox list
 */
export const getEmployeeSkillsList = ({ employeeSkill }: IStoreState) =>
  filter(
    employeeSkill.comboboxList,
    (employeeSkillItem) =>
      !isEmpty(employeeSkillItem.skill) && !isEmpty(employeeSkillItem.employee),
  );

/**
 * Prepare combobox list
 */
export const getEmployeeSkillsComboboxList = createSelector(
  getEmployeeSkillsList,
  (employeeSkills) =>
    map(employeeSkills, (employeeSkill) => ({
      id: employeeSkill.id,
      name: `${employeeSkill.employee!.firstName} ${
        employeeSkill.employee!.lastName
      } - ${employeeSkill.skill!.name}`,
    })),
);

export const getIsEmployeeSkillsDataLoading = createSelector(
  getEmployeeSkillData,
  ({ is_list_loading, is_count_loading, is_data_requested }) =>
    is_list_loading || is_count_loading || is_data_requested,
);

export const getIsEmployeeSkillsComboboxDataLoading = createSelector(
  getEmployeeSkillData,
  ({ is_combobox_loading }) => is_combobox_loading,
);
