import { IProductionDailyUphBySiteSkuAndEmployeeModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionDailyUphBySiteSkuAndEmployee',
);

// get list actions
export const {
  getListRequest: getProductionDailyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getProductionDailyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getProductionDailyUphBySiteSkuAndEmployeeListFailed,
} = actionCreator.takeListActions<
  IProductionDailyUphBySiteSkuAndEmployeeModel
>();

// get count actions
export const {
  getCountRequest: getProductionDailyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getProductionDailyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getProductionDailyUphBySiteSkuAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionDailyUphBySiteSkuAndEmployeeRequest,
  exportReportSuccess: exportProductionDailyUphBySiteSkuAndEmployeeSuccess,
  exportReportFailed: exportProductionDailyUphBySiteSkuAndEmployeeFailed,
} = new ExportActionCreator(
  'ProductionDailyUphBySiteSkuAndEmployee',
).takeExportActions();
