import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep, get, set, map, reduce, omit } from 'lodash';
import { JSONSchemaType } from 'ajv';

import { updateProductionsRequest } from '../../actions';
import {
  useBrowserHistoryFunctions,
  useProductionSettings,
  useProductionSettingsFields,
} from '.';
import { useReportDefaultFilter } from './common/reports';

import {
  IFilterData,
  IUpdateProduction,
  ProductionSettingConfig,
} from '../../types';
import { i18n } from '../i18n';
import { IHeadCellWithOrderConfig, InclusionPath } from '../../types/table';
import { useGenerateHeadCellsData } from './table';
import { composeDate, DATE_FORMAT, format, parse } from '../dateWrapper';
import { useHasUserAccessToAction } from '../../../config';
import { manageEntitiesConfig } from '../../../config/manageEntitiesConfig';
import { IProductionFilterPanelFilters } from '../../../pages/Productions/components/ProductionFilterPanel';

export const useProductionsInclusion = (siteId: number) => {
  return React.useMemo(
    () => [
      {
        relation: 'bom',
        relationType: 'left',
      },
      {
        relation: 'shift',
      },
      {
        relation: 'site',
        scope: {
          where: {
            id: {
              eq: siteId,
            },
          },
        },
      },
      {
        relation: 'productionEmployees',
        relationType: 'left',
        scope: {
          include: [
            {
              relation: 'employee',
              relationType: 'left',
            },
          ],
        },
      },
    ],
    [siteId],
  );
};

export const useProductionDailyReportsDefaultFilters = (
  defaultOrderBy = 'date',
) => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderBy} desc`],
      ...reportDefaultFilter,
    }),
    [defaultOrderBy, reportDefaultFilter],
  );
};

export const useProductionWeeklyReportsDefaultFilters = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`year desc`, `week desc`],
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};

export const useProductionHistoricalWeeklyReportsDefaultFilters = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`yearWeek desc`],
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};

export const useProductionMetatagColumns = <T = any>(siteId: number) => {
  const { metatags, fields } = useProductionSettingsFields(siteId);

  const dynamicColumns = (map(metatags, ({ name, title }) => ({
    id: name as string,
    disablePadding: false,
    orderConfig: { orderBy: name },
    label: title as string,
  })) as unknown) as T[];

  return React.useMemo(
    () => ({ dynamicColumns, fields }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteId, metatags],
  );
};

export const useProductionsTableInclusionObject = (siteId: number) => {
  return React.useMemo(
    () => ({
      bom: {
        relationType: 'left',
      },
      shift: {
        relationType: 'inner',
      },
      site: {
        relationType: 'inner',
        scope: {
          where: {
            id: {
              eq: siteId,
            },
          },
        },
      },
      productionEmployees: {
        relationType: 'left',
        scope: {
          include: {
            employee: {
              relationType: 'left',
            },
          },
        },
      },
    }),
    [siteId],
  );
};

export const useProductionsTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      date: {
        value: '',
        property: 'date',
        operator: 'eq' as const,
      },
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      shiftId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['shift', 'scope'] as InclusionPath,
      },
      departmentId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['department', 'scope'] as InclusionPath,
      },
      bomId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['bom', 'scope'] as InclusionPath,
      },
      container: {
        value: '',
        property: 'container',
        operator: 'like' as const,
      },
      lineOrDoor: {
        value: '',
        property: 'lineOrDoor',
        operator: 'like' as const,
      },
      comment: {
        value: '',
        property: 'comment',
        operator: 'like' as const,
      },
    };
  }, []);
};

export const useProductionsList = (siteId: number) => {
  const { t } = useTranslation();
  const { dynamicColumns, fields } = useProductionMetatagColumns<
    IHeadCellWithOrderConfig
  >(siteId);

  const adjustName = (name: string) => {
    switch (name) {
      case 'shift':
        return 'shiftName';
      case 'employee':
        return 'employees';
      case 'bom':
        return 'bomName';
      default:
        return name;
    }
  };

  const heads = React.useMemo(
    () =>
      reduce<ProductionSettingConfig['fields'], IHeadCellWithOrderConfig[]>(
        fields,
        (acc, { title, enabled }, name) => {
          if (enabled) {
            acc.push({
              id: adjustName(name),
              orderConfig: { orderBy: adjustName(name) },
              label: title,
            });
          }
          return acc;
        },
        [],
      ),
    [fields],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      ...heads,
      ...dynamicColumns,
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('pulse_summary.siteName'),
      },
      {
        id: 'elapsedTime',
        orderConfig: { orderBy: 'elapsedTime' },
        label: t('productions.elapsedTime'),
      },
    ],
    [t, heads, dynamicColumns],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return { headCells, headCellsOrderDetails };
};

export const useProductionPermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.production.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.production.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.production.delete.id,
  );

  return { allowedToCreate, allowedToUpdate, allowedToDelete };
};

export const useProductionFilter = (
  appliedFilters?: IProductionFilterPanelFilters,
) => {
  const inclusions = useProductionsInclusion(appliedFilters?.siteId ?? NaN);

  const { where, include } = React.useMemo<IFilterData>(
    () => ({
      where: {
        siteId: appliedFilters?.siteId,
        date: appliedFilters?.date,
        shiftId: appliedFilters?.shiftId,
        departmentId: appliedFilters?.departmentId,
        bomId: appliedFilters?.bomId,
        ...(appliedFilters?.container
          ? {
              container: {
                like: `%${appliedFilters?.container}%`,
              },
            }
          : {}),
        ...(appliedFilters?.lineOrDoor
          ? {
              lineOrDoor: {
                like: `%${appliedFilters?.lineOrDoor}%`,
              },
            }
          : {}),
        ...(appliedFilters?.comment
          ? {
              comment: {
                like: `%${appliedFilters?.comment}%`,
              },
            }
          : {}),
      },
      include: inclusions,
    }),
    [
      appliedFilters?.siteId,
      appliedFilters?.date,
      appliedFilters?.shiftId,
      appliedFilters?.departmentId,
      appliedFilters?.bomId,
      appliedFilters?.container,
      appliedFilters?.lineOrDoor,
      appliedFilters?.comment,
      inclusions,
    ],
  );

  return { where, include };
};

export const useProductionUpdate = (
  siteId: number,
  items: IUpdateProduction[],
) => {
  const { metatags, fields } = useProductionSettingsFields(siteId);

  const dispatcher = useDispatch();
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { isLoading } = useProductionSettings(siteId);

  const pr = React.useMemo<IUpdateProduction[]>(
    () =>
      items.map<IUpdateProduction>(
        (i) =>
          ({
            ...omit(i, ['bom', 'shift', 'site', 'elapsedTime', 'employees']),
            date: composeDate(i.date, parse(DATE_FORMAT), format(DATE_FORMAT)),
            employees: i.employeeIds?.split(',').map((id) => +id),
          } as IUpdateProduction),
      ),
    [items],
  );

  const productions = React.useMemo(() => {
    const prod: IUpdateProduction[] = [];
    for (const i in pr) {
      prod[i] = pr[i];
      for (const j in pr[i]) {
        if (get(metatags, [j, 'type']) === 'checkbox') {
          prod[i][j] = pr[i][j] === 'true';
        }
      }
    }
    return prod;
  }, [metatags, pr]);

  const handleFormSubmit = (data: IUpdateProduction[]) => {
    const prod: IUpdateProduction[] = [];
    for (const i in data) {
      prod[i] = data[i];
      for (const j in data[i]) {
        if (get(metatags, [j, 'type']) === 'checkbox') {
          prod[i][j] = data[i][j] ? 'true' : 'false';
        }
      }
    }
    dispatcher(updateProductionsRequest({ data: prod, navigate }));
  };

  // preloader flag for correct render form
  const loading = !productions.length || isLoading;

  return {
    productions,
    handleFormSubmit,
    metatags,
    fields,
    loading,
  };
};

export const getProductionWithMetatags = <T>(
  metatags: ProductionSettingConfig['metatags'],
  initialScheme: JSONSchemaType<T>,
) => {
  const required = [];
  const properties = {};
  const scheme = cloneDeep(initialScheme);

  for (const i in metatags) {
    required.push(metatags[i].name);

    switch (metatags[i].type) {
      case 'checkbox':
        properties[metatags[i].name] = {
          type: 'boolean',
          default: false,
        };
        break;
      default:
        properties[metatags[i].name] = {
          type: ['string', 'number'],
          isNotEmpty: true,
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
            type: i18n.t('error.validation.required'),
          },
        };
        break;
    }
  }

  const requiredPath = ['required'];
  const propertiesPath = ['properties'];

  if (scheme.type === 'array') {
    requiredPath.unshift('items');
    propertiesPath.unshift('items');
  }

  const schemeRequired = get(scheme, requiredPath);
  const schemeProperties = get(scheme, propertiesPath);

  set(scheme, requiredPath, [...schemeRequired, ...required]);
  set(scheme, propertiesPath, { ...schemeProperties, ...properties });

  return scheme;
};
