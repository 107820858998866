import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';
import { ArrowDownSvg } from 'src/components/svgIcons';
import { useTranslation } from 'react-i18next';

export interface IByEmployeeDetailsHeaderProps {
  onBackClick: () => void;
  employeeName: string;
  children?: React.ReactNode;
}

export const ByEmployeeDetailsHeader = ({
  onBackClick,
  employeeName,
  children,
}: IByEmployeeDetailsHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tooltip title={t('common.back')}>
          <IconButton onClick={onBackClick} sx={{ mr: 1 }}>
            <Box sx={{ transform: 'rotate(90deg)' }}>
              <ArrowDownSvg />
            </Box>
          </IconButton>
        </Tooltip>

        <Typography>{employeeName}</Typography>
      </Box>

      <Box>{children}</Box>
    </Box>
  );
};
