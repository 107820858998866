import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import {
  Api,
  logtimeInitial,
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICreateLogtimeRequest,
  IFilterData,
  IPunchIntersectionRequest,
  IPunchIntersectionResponse,
  IWhere,
} from 'src/modules/types';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { createLogtimeBulkRequest } from 'src/modules/actions';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import { EmployeesComboBox } from 'src/components/Formik/comboboxes-with-entities/EmployeesComboBox';
import { createLogtimesScheme } from 'src/modules/schemes/logtimes';
import { useLogtimeCreateFormTourConfig } from 'src/config/tours/logtime';
import { DrawerSectionWithTitle } from 'src/components/_ui-kit/Drawer/DrawerSectionWithTitle';
import { PunchTypeComboBox } from '../PunchTypeComboBox';
import { get, isEmpty, noop, pick, reduce } from 'lodash';
import { DrawerSectionWithTitleItem } from 'src/components/_ui-kit/Drawer/DrawerSectionWithTitleItem';
import { Box, IconButton, Typography } from '@mui/joy';
import { PlusSvg } from 'src/components/svgIcons';
import { getDepartmentsComboboxList } from 'src/modules/selectors/department';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';
import {
  DATETIME_FORMAT_TO_SHOW,
  DATE_FORMAT,
  composeDate,
  format,
  guessTimezone,
  parseISO,
} from 'src/modules/utils/dateWrapper';
import { ActionConfirmation } from 'src/components/ActionConfirmation';
import FormikTextarea from 'src/components/Formik/FormikTextarea';

interface ITimePunchesInsertPunchFormProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const TimePunchesInsertPunchForm = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ITimePunchesInsertPunchFormProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [siteIdByPunchIndexObj, setSiteIdByPunchIndexObj] = React.useState({});

  const [valuesToConfirm, setValuesToConfirm] = React.useState<
    ICreateLogtimeRequest[]
  >();
  const [intersection, setIntersection] = React.useState<
    IPunchIntersectionResponse
  >();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  const shifts = useSelector(getShiftsComboboxList);
  const departments = useSelector(getDepartmentsComboboxList);

  const validate = useValidate(createLogtimesScheme);

  const globalFieldsFormik = useFormik({
    initialValues: {
      timeIn: null,
      timeOut: null,
      punchType: 'regular',
    },
    onSubmit: noop,
  });

  const formik = useFormik({
    initialValues: [logtimeInitial],
    enableReinitialize: true,
    validate,
    onSubmit: async (data) => {
      const checkIntersectionValues: IPunchIntersectionRequest[] = data.reduce<
        IPunchIntersectionRequest[]
      >((acc, cur) => {
        acc.push(
          pick(cur, [
            'employeeId',
            'departmentId',
            'shiftId',
            'timeIn',
            'timeOut',
          ]),
        );

        return acc;
      }, []);

      const response = await Api.Logtime.checkIntersection(
        checkIntersectionValues,
      );

      const valuesToSubmit = data.map((item) => ({
        ...item,
        punchType: item.punchType === 'regular' ? null : item.punchType || null,
      }));

      if (isEmpty(response)) {
        dispatch(
          createLogtimeBulkRequest({
            data: valuesToSubmit,
            filters: { list: filterList, count: filterCount },
          }),
        );

        globalFieldsFormik.resetForm();
        formik.setValues([logtimeInitial]);
        formik.resetForm();
        onClose();
      } else {
        setIntersection(response);
        setValuesToConfirm(valuesToSubmit);
      }
    },
  });

  const logtimeCreateConfigTour = useLogtimeCreateFormTourConfig();

  const filterEntitiesBySiteId = (entities: Array<any>, siteId: any) => {
    return entities.filter((entity) => entity.siteId === siteId);
  };

  const actionConfirmationMessage = reduce(
    valuesToConfirm as any,
    (acc, cur, index) => {
      const date = composeDate(
        parseISO(cur.timeIn as string),
        format(DATE_FORMAT),
      );

      const intersectionItem = get(intersection, [
        `${cur.employeeId || cur.employee.id}`,
        date,
      ]);

      if (intersectionItem) {
        const timeIn = composeDate(
          parseISO(intersectionItem.timeIn as string),
          format(DATETIME_FORMAT_TO_SHOW),
        );
        const timeOut = composeDate(
          parseISO(intersectionItem.timeOut as string),
          format(DATETIME_FORMAT_TO_SHOW),
        );
        acc.push(
          <li key={index}>
            {t('logtimes.punch_intersection_employee', {
              employee: `${intersectionItem.firstName} ${intersectionItem.lastName}`,
              hours: intersectionItem.timeTotal,
              timeIn,
              timeOut,
            })}
          </li>,
        );
      }

      return acc;
    },
    [] as JSX.Element[],
  );

  const onAddPunch = () => {
    formik.setValues([
      ...formik.values,
      {
        badge: NaN,
        timeIn: globalFieldsFormik.values.timeIn ?? null,
        timeOut: globalFieldsFormik.values.timeOut ?? null,
        shiftId: undefined,
        departmentId: undefined,
        timezone: guessTimezone(),
        punchType: (globalFieldsFormik.values.punchType ?? 'regular') as any,
      },
    ]);
  };

  const onApply = () => {
    const formikFilteredValues = formik.values.filter((value) =>
      Object.keys(value)
        .filter((key) => key !== 'timezone' && key !== 'punchType')
        .some((key) => Boolean(value[key])),
    );

    formik.setValues(
      formikFilteredValues.length ? formikFilteredValues : [logtimeInitial],
    );

    formik.handleSubmit();
  };

  const onConfirmSubmit = () => {
    setIntersection(undefined);
    valuesToConfirm &&
      dispatch(
        createLogtimeBulkRequest({
          data: valuesToConfirm,
          filters: { list: filterList, count: filterCount },
        }),
      );
    setValuesToConfirm(undefined);

    globalFieldsFormik.resetForm();
    formik.setValues([logtimeInitial]);
    formik.resetForm();
    onClose();
  };

  const onReset = () => {
    formik.resetForm();
    globalFieldsFormik.resetForm();
  };

  React.useEffect(() => {
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    formik.values.forEach((value, i) => {
      formik.setFieldValue(`${i}.timeIn`, globalFieldsFormik.values.timeIn);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFieldsFormik.values.timeIn]);
  React.useEffect(() => {
    formik.values.forEach((value, i) => {
      formik.setFieldValue(`${i}.timeOut`, globalFieldsFormik.values.timeOut);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFieldsFormik.values.timeOut]);
  React.useEffect(() => {
    formik.values.forEach((value, i) => {
      formik.setFieldValue(
        `${i}.punchType`,
        globalFieldsFormik.values.punchType,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFieldsFormik.values.punchType]);

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('time_keeping.insert_punch')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <DrawerSectionWithTitle
              withTopOffset
              title={t('logtimes.globally_applied_fields')}
              containerSx={{ bgcolor: 'colors.background.bg_tertiary' }}
            >
              <FormFieldContainer>
                <FormikDatetimepicker
                  required
                  fullWidth
                  id="timeIn"
                  formik={globalFieldsFormik}
                  label={t('logtimes.login_datetime')}
                  data-tour={logtimeCreateConfigTour.tour.login.target}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikDatetimepicker
                  fullWidth
                  id="timeOut"
                  formik={globalFieldsFormik}
                  label={t('logtimes.logout_datetime')}
                  data-tour={logtimeCreateConfigTour.tour.logout.target}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <PunchTypeComboBox
                  formik={globalFieldsFormik}
                  id="punchType"
                  label={t('logtimes.punch_type')}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>
            </DrawerSectionWithTitle>

            <DrawerSectionWithTitle title={t('time_keeping.insert_punch')}>
              {formik.values.map((_, i) => (
                <DrawerSectionWithTitleItem key={i}>
                  <FormFieldContainer>
                    <EmployeesComboBox
                      required
                      onlyActive
                      id={generateFullIdInMultiEntitiesForm('badge', i)}
                      propertyAsID="badge"
                      formik={formik}
                      label={t('logtimes.employee')}
                      placeholder={t('common.select')}
                      onChange={(_, value) => {
                        // setSiteId(value?.entity?.siteId ?? NaN)
                        formik.setFieldValue(
                          `${i}.employeeId`,
                          value?.entity?.id,
                        );
                        setSiteIdByPunchIndexObj((prev) => ({
                          ...prev,
                          [i]: value?.entity?.siteId,
                        }));
                      }}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikDatetimepicker
                      required
                      fullWidth
                      id={generateFullIdInMultiEntitiesForm('timeIn', i)}
                      formik={formik}
                      label={t('logtimes.login_datetime')}
                      data-tour={logtimeCreateConfigTour.tour.login.target}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikDatetimepicker
                      fullWidth
                      id={generateFullIdInMultiEntitiesForm('timeOut', i)}
                      formik={formik}
                      label={t('logtimes.logout_datetime')}
                      data-tour={logtimeCreateConfigTour.tour.logout.target}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikCombobox
                      setToUndefinedOnFieldClear
                      id={generateFullIdInMultiEntitiesForm('departmentId', i)}
                      formik={formik}
                      label={t('logtimes.department')}
                      placeholder={t('common.select')}
                      options={
                        siteIdByPunchIndexObj[i]
                          ? filterEntitiesBySiteId(
                              departments,
                              siteIdByPunchIndexObj[i],
                            )
                          : []
                      }
                      data-tour={logtimeCreateConfigTour.tour.department.target}
                      data-testid="department"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikCombobox
                      setToUndefinedOnFieldClear
                      id={generateFullIdInMultiEntitiesForm('shiftId', i)}
                      formik={formik}
                      label={t('logtimes.shift')}
                      placeholder={t('common.select')}
                      options={
                        siteIdByPunchIndexObj[i]
                          ? filterEntitiesBySiteId(
                              shifts,
                              siteIdByPunchIndexObj[i],
                            )
                          : []
                      }
                      data-tour={logtimeCreateConfigTour.tour.shift.target}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <PunchTypeComboBox
                      formik={formik}
                      id={generateFullIdInMultiEntitiesForm('punchType', i)}
                      label={t('logtimes.punch_type')}
                      placeholder={t('common.select')}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextarea
                      id={generateFullIdInMultiEntitiesForm('comment', i)}
                      name={generateFullIdInMultiEntitiesForm('comment', i)}
                      label={t('logtimes.comment')}
                      formik={formik}
                      minRows={2}
                    />
                  </FormFieldContainer>
                </DrawerSectionWithTitleItem>
              ))}

              <Box sx={{ pl: 4 }}>
                <IconButton onClick={onAddPunch}>
                  <PlusSvg />
                  <Box sx={{ pl: 0.5 }}>
                    <Typography>{t('time_keeping.add_punch')}</Typography>
                  </Box>
                </IconButton>
              </Box>
            </DrawerSectionWithTitle>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={onApply}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>

      <ActionConfirmation
        open={Boolean(intersection)}
        onOk={onConfirmSubmit}
        onCancel={() => setIntersection(undefined)}
        okBtnText={t('common.yes')}
        noBtnText={t('common.no')}
        title={
          formik.values.length > 1
            ? t('time_keeping.punches_intersection_warning')
            : t('time_keeping.punch_intersection_warning')
        }
      >
        {actionConfirmationMessage}
      </ActionConfirmation>
    </>
  );
};
