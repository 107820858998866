import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Option,
  SelectOption,
  Sheet,
  Typography,
  useTheme,
} from '@mui/joy';
import { PlusSvg, Trash04Svg, XCloseSvg } from '../svgIcons';
import { useTranslation } from 'react-i18next';
import { FilterPanelContainer } from './FilterPanelActions/FilterPanelActionContainer';
import { Select } from '../_ui-kit/Select';
import {
  FilterPanelContext,
  FilterPanelContextProvider,
  FilterPanelModes,
} from './FilterPanelContext';
import { FilterPanelSelectOptionLabel } from './FilterPanelFilters/FilterPanelSelect/FilterPanelSelectOptionLabel';
import { FilterPanelDivider } from './FilterPanelDivider';
import { DeleteConfirmation } from '../DeleteConfirmation';

export interface IFilterPanelOption {
  id: string;
  label: string;
  // If "isPreselected" then this filter will appear by default inside filters panel
  // it might have a value but in is not mandatory
  isPreselected?: boolean;
  // If "isPermanent" then this filter won't appear in filters selector
  // and won't have remove icon near it (we need it at least for reports pages)
  isPermanent?: boolean;
}

export type IExtendedFilterPanelOption = IFilterPanelOption;

type SelectedOption = IFilterPanelOption['id'];

export interface IFilterPanelProps {
  filterOptionsConfig: Array<IFilterPanelOption>;
  children?: React.ReactElement;
  renderFilter: (filterOption: IFilterPanelOption) => React.ReactNode;
  renderOption?: (filterOption: IFilterPanelOption) => React.ReactNode;
  onSelectedOptionsChange?: (
    selectedOptions: Array<IFilterPanelOption>,
  ) => void;
  onRemoveAllOptions?: () => void;
  onReset?: () => void;
  onApplySelectedFilters?: (
    appliedOptionsIdsIds: Array<SelectedOption>,
  ) => void;
}

export const FilterPanelComponent = ({
  filterOptionsConfig,
  renderFilter,
  renderOption,
  onApplySelectedFilters,
  onRemoveAllOptions,
  onSelectedOptionsChange,
  children,
  onReset,
}: IFilterPanelProps) => {
  const theme = useTheme();

  const isSmallDevice = window.innerWidth <= theme.breakpoints.values.sm;

  const { t } = useTranslation();

  const {
    filterPanelMode,
    setFilterPanelMode,
    appliedOptionsIds,
    editingFiltersIds,
    setAppliedOptionsIds,
    setEditingFiltersIds,
    setFiltersConfig,
    elementsHeight,
  } = React.useContext(FilterPanelContext);

  const [
    isRemoveFiltersConfirmationOpen,
    setIsRemoveFilterConfirmationOpen,
  ] = React.useState(false);

  const defaultSelectedOptionsIds = React.useMemo(() => {
    return filterOptionsConfig
      .filter((option) => option.isPreselected || option.isPermanent)
      .map(({ id }) => id);
  }, [filterOptionsConfig]);

  const onConfirmRemoveFilters = () => {
    const permanentOptionsIds = filterOptionsConfig
      .filter((item) => item.isPermanent)
      .map(({ id }) => id);

    setAppliedOptionsIds(permanentOptionsIds);
    setEditingFiltersIds(permanentOptionsIds);

    onRemoveAllOptions && onRemoveAllOptions();

    setIsRemoveFilterConfirmationOpen(false);
  };

  const renderOptionDefault = (filterOption: IFilterPanelOption) => {
    return (
      <Option key={filterOption.id} value={filterOption.id}>
        <Checkbox
          checked={editingFiltersIds.some(
            (selectedOptionId) => selectedOptionId === filterOption.id,
          )}
        />
        <FilterPanelSelectOptionLabel>
          {filterOption.label}
        </FilterPanelSelectOptionLabel>
      </Option>
    );
  };

  const renderValue = (
    data: SelectOption<any> | SelectOption<any>[] | null,
  ) => {
    if (Array.isArray(data)) {
      return (
        <Typography level="text_xs">
          {data.map((item) => item.label).join(', ')}
        </Typography>
      );
    }
    return null;
  };

  const containerSxForSelectMode = {
    borderRadius: 8,
    overflow: 'hidden',
    bgcolor: 'colors.background.bg_secondary',
  };

  React.useEffect(() => {
    setAppliedOptionsIds(defaultSelectedOptionsIds);
    setEditingFiltersIds(defaultSelectedOptionsIds);
  }, [defaultSelectedOptionsIds, setAppliedOptionsIds, setEditingFiltersIds]);

  React.useEffect(() => {
    setFiltersConfig(filterOptionsConfig);
  }, [filterOptionsConfig, setFiltersConfig]);

  return (
    <Sheet
      sx={{
        px: isSmallDevice ? 0.5 : 2,
        py: 0.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: 52,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        borderColor: 'colors.border.border_primary',
        bgcolor: 'colors.foreground.fg_white',
        flexWrap: 'wrap',
      }}
    >
      <Sheet
        sx={{
          width: isSmallDevice ? '100%' : 'auto',
          display: 'flex',
          alignItems: isSmallDevice ? 'flex-start' : 'center',
          bgcolor: 'inherit',
          flexDirection: isSmallDevice ? 'column' : 'row',
        }}
      >
        {!isSmallDevice && (
          <Sheet sx={{ pr: 1, bgcolor: 'inherit' }}>
            <Typography
              level="text_xs"
              textColor="colors.text.text_primary.main"
            >
              {t('common.filters')}:
            </Typography>
          </Sheet>
        )}

        <Sheet
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...(filterPanelMode === FilterPanelModes.setup &&
              containerSxForSelectMode),
          }}
        >
          <Sheet
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'inherit',
              flexWrap: 'wrap',
            }}
          >
            {filterPanelMode === FilterPanelModes.setup &&
              filterOptionsConfig.length > 1 && (
                <>
                  <Select
                    multiple
                    variant="plain"
                    sx={{
                      borderRadius: 0,
                      bgcolor: 'inherit',
                      fontSize: '0.75rem',
                      height: elementsHeight,
                    }}
                    placeholder={t('common.select')}
                    renderValue={renderValue}
                    value={editingFiltersIds}
                    onChange={(e, selectedOptions) => {
                      if (!e) {
                        return;
                      }

                      setEditingFiltersIds(selectedOptions);

                      onSelectedOptionsChange &&
                        onSelectedOptionsChange(selectedOptions);
                    }}
                    slotProps={{
                      listbox: {
                        placement: 'bottom-start',
                      },
                    }}
                  >
                    {filterOptionsConfig
                      .filter((option) => !option.isPermanent)
                      .map((option) => {
                        return renderOption
                          ? renderOption(option)
                          : renderOptionDefault(option);
                      })}
                  </Select>
                  {editingFiltersIds.length ? <FilterPanelDivider /> : null}
                </>
              )}
            {editingFiltersIds
              .map((selectedOptionId, i) => {
                const selectedOptionFullData = filterOptionsConfig.find(
                  (option) => option.id === selectedOptionId,
                );

                if (!selectedOptionFullData) {
                  return undefined;
                }

                const filter = renderFilter(selectedOptionFullData);

                return (
                  <Sheet
                    sx={{ display: 'flex', alignItems: 'center' }}
                    key={selectedOptionId}
                  >
                    {filter}

                    {filter &&
                      !selectedOptionFullData.isPermanent &&
                      filterPanelMode !== FilterPanelModes.ready && (
                        <IconButton
                          sx={{ borderRadius: 0 }}
                          onClick={() => {
                            const newEditingFiltersIds = editingFiltersIds.filter(
                              (item) => item !== selectedOptionId,
                            );

                            setEditingFiltersIds(newEditingFiltersIds);

                            onSelectedOptionsChange &&
                              onSelectedOptionsChange(
                                filterOptionsConfig.filter((option) =>
                                  newEditingFiltersIds.includes(option.id),
                                ),
                              );
                          }}
                        >
                          <XCloseSvg />
                        </IconButton>
                      )}
                    {i !== editingFiltersIds.length - 1 &&
                      filterPanelMode !== FilterPanelModes.ready && (
                        <FilterPanelDivider />
                      )}
                  </Sheet>
                );
              })
              .filter(Boolean)}
          </Sheet>
        </Sheet>

        {filterPanelMode === FilterPanelModes.ready &&
          filterOptionsConfig.length > 1 && (
            <IconButton
              onClick={() => setFilterPanelMode(FilterPanelModes.setup)}
            >
              <PlusSvg />
              <Typography
                level="text_sm"
                fontWeight="semi_bold"
                textColor="colors.buttons.tertiary.button_tertiary_fg"
              >
                {t('common.add_filter')}
              </Typography>
            </IconButton>
          )}
      </Sheet>
      <Sheet
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'transparent',
          py: 0.5,
        }}
      >
        {filterPanelMode === FilterPanelModes.setup && (
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
            <Button
              variant="plain"
              color="neutral"
              sx={{ mr: 0.5 }}
              onClick={() => {
                setFilterPanelMode(FilterPanelModes.ready);

                setEditingFiltersIds(appliedOptionsIds);

                onReset && onReset();
              }}
            >
              {t('common.reset')}
            </Button>
            <Button
              onClick={() => {
                setAppliedOptionsIds(editingFiltersIds);

                setFilterPanelMode(FilterPanelModes.ready);

                onApplySelectedFilters &&
                  onApplySelectedFilters(editingFiltersIds);
              }}
            >
              {t('common.apply')}
            </Button>
          </Box>
        )}
        {filterPanelMode === FilterPanelModes.ready && (
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
            {children}

            <FilterPanelContainer>
              <IconButton
                onClick={() => {
                  setIsRemoveFilterConfirmationOpen(true);
                }}
              >
                <Trash04Svg
                  fill={theme.palette.colors.foreground.fg_error_primary}
                />
              </IconButton>
            </FilterPanelContainer>
          </Box>
        )}
      </Sheet>

      <DeleteConfirmation
        open={isRemoveFiltersConfirmationOpen}
        onOk={onConfirmRemoveFilters}
        onCancel={() => setIsRemoveFilterConfirmationOpen(false)}
      >
        {t('filter_panel.confirm_removing_filters')}
      </DeleteConfirmation>
    </Sheet>
  );
};

export const FilterPanel = (props: IFilterPanelProps) => {
  return (
    <FilterPanelContextProvider>
      <FilterPanelComponent {...props} />
    </FilterPanelContextProvider>
  );
};
