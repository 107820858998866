import React from 'react';
import { Box } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface IDrawerSectionWithTitleItemProps {
  children: React.ReactNode;
  containerSx?: SxProps;
}

export const DrawerSectionWithTitleItem = ({
  children,
  containerSx,
}: IDrawerSectionWithTitleItemProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',

        '&:after': {
          content: '""',
          mx: 4,
          my: 2,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: 'colors.border.border_primary',
        },
        ...containerSx,
      }}
    >
      {children}
    </Box>
  );
};
