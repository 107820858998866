import React from 'react';
import { filter, map, omit } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  useFetchBomsCombobox,
  useFetchEmployeesComboboxWithBadgeAsId,
  useFetchShiftsCombobox,
  useProductionUpdate,
  useValidate,
  getProductionWithMetatags,
} from '../../modules/utils';
import { getCurrentRole } from '../../modules/selectors/auth';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { updateProductionScheme } from '../../modules/schemes';
import { getBomsComboboxList } from '../../modules/selectors/bom.selector';
import { getEmployeesComboboxList } from '../../modules/selectors/employee';
import { getShiftOptionsByGloballySites } from '../../modules/selectors/shift';

import { ComboBoxOption } from '../../components/ComboBox';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import FormikDatepicker from '../../components/Formik/FormikDatepicker';
import FormikDatetimepicker from '../../components/Formik/FormikDatetimepicker';
import FormikMultipleSelectWithSearch, {
  MultipleSelectOption,
} from '../../components/Formik/FormikMultipleSelectWithSearch';

import {
  IUpdateProduction,
  ProductionSettingConfig,
} from '../../modules/types';
import ProductionMetatagsFields from './ProductionMetatagsFields';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerHeader,
} from '../../components/_ui-kit/Drawer';
import { Box, Typography } from '@mui/joy';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';

interface IUpdateProductionsProps {
  productions: IUpdateProduction[];
  onSubmit: (productions: IUpdateProduction[]) => void;
  fields: ProductionSettingConfig['fields'];
  metatags: ProductionSettingConfig['metatags'];
}

const ProductionUpdateForm = ({
  productions,
  onSubmit,
  fields,
  metatags,
}: IUpdateProductionsProps) => {
  const { t } = useTranslation();

  const scheme = getProductionWithMetatags(
    metatags as ProductionSettingConfig['metatags'],
    updateProductionScheme,
  );
  const validate = useValidate(scheme);

  const formik = useFormikInUpdateForm({
    initialValues: productions,
    validate,
    onSubmit,
  });

  const fetchBomsCombobox = useFetchBomsCombobox();
  const fetchEmployeesCombobox = useFetchEmployeesComboboxWithBadgeAsId();
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  React.useEffect(() => {
    fetchBomsCombobox();
    fetchEmployeesCombobox();
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const comboboxes = {
    boms: useSelector(getBomsComboboxList),
    employees: useSelector(getEmployeesComboboxList),
    shifts: useSelector(getShiftOptionsByGloballySites, shallowEqual),
  };

  const getBoms = React.useCallback(
    (siteId) => filter(comboboxes.boms, (bom) => bom.siteId === siteId),
    [comboboxes.boms],
  );
  const getEmployees = React.useCallback(
    (siteId) =>
      filter(
        comboboxes.employees,
        (employee) => employee.entity.siteId === siteId,
      ),
    [comboboxes.employees],
  );
  const getShifts = React.useCallback(
    (siteId) => filter(comboboxes.shifts, (shift) => shift.siteId === siteId),
    [comboboxes.shifts],
  );

  const shouldRender = React.useCallback((name) => fields[name].enabled, [
    fields,
  ]);

  // const updateDateTimeFormatIfNeeded = (format: string) => {
  //   if (format.indexOf('T') !== -1) {
  //     return format;
  //   }

  //   return format.split(' ').join('T');
  // };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {map(formik.values, (production, index) => (
        <Box key={`pr_${index}`} sx={{ mb: 2 }}>
          <FormFieldContainer>
            <Typography level="text_xs" textColor="gray.600">
              {t('productions.update_title', { id: production.id })}
            </Typography>
          </FormFieldContainer>
          {shouldRender('employee') && (
            <FormFieldContainer>
              <FormikMultipleSelectWithSearch
                id={`${index}.employees`}
                title={fields.employee.title}
                formik={formik}
                fullWidth={true}
                options={
                  getEmployees(production.siteId) as MultipleSelectOption[]
                }
                errorMode="onFieldChange"
                required={false}
              />
            </FormFieldContainer>
          )}
          {shouldRender('shift') && (
            <FormFieldContainer>
              <FormikCombobox
                required
                id={`${index}.shiftId`}
                placeholder={fields.shift.title}
                options={getShifts(production.siteId) as ComboBoxOption[]}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('bom') && (
            <FormFieldContainer>
              <FormikCombobox
                id={`${index}.bomId`}
                placeholder={fields.bom.title}
                options={getBoms(production.siteId) as ComboBoxOption[]}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('date') && (
            <FormFieldContainer>
              <FormikDatepicker
                required
                fullWidth
                label={fields.date.title}
                id={`${index}.date`}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          <ProductionMetatagsFields
            metatags={metatags}
            index={index}
            formik={formik}
          />
          {shouldRender('qty') && (
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                id={`${index}.qty`}
                label={fields.qty.title}
                name={`${index}.qty`}
                type="number"
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('container') && (
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id={`${index}.container`}
                label={fields.container.title}
                name={`${index}.container`}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('isPartialContainer') && (
            <FormFieldContainer>
              <FormikCheckbox
                id={`${index}.isPartialContainerBoolean`}
                label={fields.isPartialContainer.title}
                name={`${index}.isPartialContainerBoolean`}
                formik={formik}
              />
            </FormFieldContainer>
          )}
          {shouldRender('lineOrDoor') && (
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id={`${index}.lineOrDoor`}
                label={fields.lineOrDoor.title}
                name={`${index}.lineOrDoor`}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('comment') && (
            <FormFieldContainer>
              <FormikTextarea
                variant="outlined"
                id={`${index}.comment`}
                label={fields.comment.title}
                name={`${index}.comment`}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('startTime') && (
            <FormFieldContainer>
              <FormikDatetimepicker
                fullWidth
                id={`${index}.startTime`}
                formik={formik}
                label={fields.startTime.title}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
          {shouldRender('endTime') && (
            <FormFieldContainer>
              <FormikDatetimepicker
                fullWidth
                id={`${index}.endTime`}
                formik={formik}
                label={fields.endTime.title}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          )}
        </Box>
      ))}
      <FormActions submitBtnTitle={t('common.update')} />
    </form>
  );
};

interface IProductionsUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  siteId: number;
  productions: IUpdateProduction[];
}

export const ProductionsUpdate: React.FC<IProductionsUpdateProps> = ({
  productions: items,
  onClose,
  siteId,
  isOpen,
}) => {
  const { t } = useTranslation();

  const {
    productions,
    metatags,
    fields,
    handleFormSubmit,
    loading,
  } = useProductionUpdate(siteId, items);

  if (loading) {
    // TODO: replace to spinner
    return null;
  }

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('productions.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent component="div">
          <ProductionUpdateForm
            productions={productions}
            onSubmit={(data) =>
              handleFormSubmit(
                data.map((item) => ({
                  ...omit(item, 'isPartialContainerBoolean'),
                  isPartialContainer: (item as any).isPartialContainerBoolean,
                })),
              )
            }
            fields={fields}
            metatags={metatags}
          />
        </DrawerBodyContent>
      </DrawerBody>
    </Drawer>
  );
};
