import { ISagaActionBind } from '../../types';
import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthDailyReportListRequest,
    saga: createListSaga<ICardinalHealthDailyReportModel>({
      apiCall: Api.CardinalHealthDailyReport.list,
      actionSuccess: actions.getCardinalHealthDailyReportListSuccess,
      actionFailed: actions.getCardinalHealthDailyReportListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthDailyReportCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthDailyReport.count,
      actionSuccess: actions.getCardinalHealthDailyReportCountSuccess,
      actionFailed: actions.getCardinalHealthDailyReportCountFailed,
    }),
  },
];
