import * as React from 'react';
import { styled } from '@mui/joy/styles';
import { Popper as BasePopper } from '@mui/base/Popper';
import {
  Button,
  Chip,
  ChipDelete,
  List,
  Sheet,
  Typography,
  useTheme,
} from '@mui/joy';
import { HEADER_HEIGHT, useMainLayoutContext } from '../../MainLayoutContext';
import { ChevronCompactUpSvg } from 'src/components/svgIcons';
import { ListItemWithCheckbox } from 'src/components/_ui-kit/ListItem';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useSearch,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGloballySelectedSites,
  getSitesForGlobalDropdownOptions,
} from 'src/modules/selectors/site';
import { setItemInLocalStorage } from 'src/modules/utils/localStorageWrapper';
import { setGloballySelectedSites } from 'src/modules/actions';
import { useTranslation } from 'react-i18next';
import { SideBarGlobalSitesDropdownSearch } from './SiteBarGlobalSitesDropdownSearch';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';

const allSiteOptionIndex = -1;

const siteIdKeyPart = 'siteId:';

const popperOffset = 56 + HEADER_HEIGHT;

const Popper = styled(BasePopper, {
  shouldForwardProp(prop) {
    return prop !== 'width';
  },
})(({ width }) => {
  return `
  z-index: 1;
  width: ${width}px;
  background-color: transparent;
  transform: translate3d(0px, ${popperOffset}px, 0px) !important;
  height: calc(100% - ${popperOffset}px);
`;
});

export const SideBarGlobalSitesDropdown = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const ref = React.useRef<HTMLAnchorElement>(null);

  const queryParams = useQueryParams();
  const generateUrlSearch = useSearch();
  const { pushToHistory } = useBrowserHistoryFunctions();

  const { sideBar, toggleSideBar, createTransition } = useMainLayoutContext();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedSitesObject, setSelectedSitesObject] = React.useState<{
    [siteId: number]: { name: string; isChecked: boolean; siteId: number };
  }>({});

  const selectedSites = useSelector(getGloballySelectedSites);
  const sitesOptions = useSelector(getSitesForGlobalDropdownOptions);

  const getFirstSelectedSiteData = React.useCallback(() => {
    const firstSelectedSiteDetails = sitesOptions.find((site) =>
      selectedSites.includes(site.id),
    );

    return firstSelectedSiteDetails;
  }, [selectedSites, sitesOptions]);

  const buttonContent = React.useMemo(() => {
    const selectedSitesQuantity = selectedSites.length;

    let text = '';
    if (selectedSitesQuantity === 0) {
      text = t('common.no_selected_sites');
    } else if (selectedSitesQuantity === sitesOptions.length) {
      text = t('common.all_sites');
    } else if (selectedSitesQuantity === 1) {
      const siteDetails = sitesOptions.find(
        (site) => site.id === selectedSites[0],
      );
      text = siteDetails?.name ?? '';
    } else {
      const firstSelectedSiteDetails = getFirstSelectedSiteData();
      text = firstSelectedSiteDetails?.name ?? '';
    }

    const sitesNumber =
      selectedSitesQuantity > 1 && selectedSitesQuantity < sitesOptions.length
        ? `+${selectedSitesQuantity - 1}`
        : '';

    return {
      text,
      sitesNumber,
    };
  }, [getFirstSelectedSiteData, selectedSites, sitesOptions, t]);

  const isAllSitesSelected = React.useMemo(() => {
    return Object.values(selectedSitesObject).every(
      ({ isChecked }) => isChecked,
    );
  }, [selectedSitesObject]);
  const isAllSiteListItemIndeterminate = React.useMemo(() => {
    return (
      Object.values(selectedSitesObject).some(({ isChecked }) => isChecked) &&
      Object.values(selectedSitesObject).some(({ isChecked }) => !isChecked)
    );
  }, [selectedSitesObject]);

  const chipCommonStyles = {
    borderColor: 'gray.700',
    backgroundColor: 'gray.950',
    opacity: sideBar.state === 'collapsed' ? 0 : 1,
    borderWidth: sideBar.state === 'collapsed' ? 0 : '1px',
    paddingInline: sideBar.state === 'collapsed' ? 0 : 'auto',
    transition: `margin 100ms ease-in, border 100ms ease-in, padding 100ms ease-in, width 100ms ease-in, opacity ${
      sideBar.state === 'expanded' ? 200 : 50
    }ms ease-in`,
  };

  const initSelectedSitesInLocalState = React.useCallback(() => {
    setSelectedSitesObject((prev) => ({
      ...prev,
      ...sitesOptions.reduce((all, site) => {
        all = {
          ...all,
          [generateKey(site.id)]: {
            name: site.name,
            siteId: site.id,
            isChecked: selectedSites.includes(site.id),
          },
        };
        return all;
      }, {}),
    }));
  }, [selectedSites, sitesOptions]);

  const onSearchTermChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);

    const normalisedPattern = newSearchTerm.replaceAll(/\W/g, '');
    const regex = new RegExp(normalisedPattern, 'i');

    setSelectedSitesObject(
      sitesOptions.reduce((all, site) => {
        if (regex.test(site.name)) {
          all = {
            ...all,
            [generateKey(site.id)]: {
              name: site.name,
              siteId: site.id,
              isChecked: selectedSites.includes(site.id),
            },
          };
        }
        return all;
      }, {}),
    );
  };

  const onButtonClick = () => {
    if (sideBar.state === 'collapsed') {
      toggleSideBar();
    }

    setIsPopoverOpen((isOpen) => !isOpen);
  };

  const onChipDelete = (e: any) => {
    e.stopPropagation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filter, ...rest } = queryParams;

    pushToHistory({ search: generateUrlSearch(rest) });

    const firstSelectedSiteDetails = getFirstSelectedSiteData();

    const filteredSelectedSites = selectedSites.filter(
      (siteId) => siteId !== firstSelectedSiteDetails?.id,
    );

    setItemInLocalStorage('globallySelectedSites', filteredSelectedSites);

    dispatch(setGloballySelectedSites(filteredSelectedSites));

    if (firstSelectedSiteDetails) {
      const key = generateKey(Number(firstSelectedSiteDetails.id));

      setSelectedSitesObject((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          isChecked: false,
        },
      }));
    }
  };

  const onListItemClick = (isChecked: boolean, siteId: number | string) => {
    const key = generateKey(Number(siteId));

    setSelectedSitesObject((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        isChecked,
      },
    }));
  };

  const onSelectAllClick = () => {
    setSelectedSitesObject(
      Object.values(selectedSitesObject).reduce((all, siteDetails) => {
        all[generateKey(siteDetails.siteId)] = {
          ...siteDetails,
          isChecked: !isAllSitesSelected,
        };

        return all;
      }, {}),
    );
  };

  const onCancel = () => {
    setIsPopoverOpen(false);
  };

  const onReset = () => {
    initSelectedSitesInLocalState();
  };

  const submitSitesSelection = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filter, ...rest } = queryParams;

    pushToHistory({ search: generateUrlSearch(rest) });

    const sites = Object.values(selectedSitesObject).reduce<Array<number>>(
      (all, siteDetails) => {
        if (siteDetails.isChecked) {
          all = [...all, Number(siteDetails.siteId)];
        }

        return all;
      },
      [],
    );

    setItemInLocalStorage('globallySelectedSites', sites);

    dispatch(setGloballySelectedSites(sites));

    setIsPopoverOpen(false);
  };

  function generateKey(siteId: number) {
    return `${siteIdKeyPart}${siteId}`;
  }
  // @TODO: we initialzing it in PackagesInitializer component, so
  // if you see any issues use code below
  // useFetchSitesForDropdownEffect();

  React.useEffect(() => {
    initSelectedSitesInLocalState();
  }, [initSelectedSitesInLocalState]);

  return (
    <>
      <Sheet
        sx={{
          position: 'relative',
          backgroundColor: 'inherit',
          py: 1.5,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
          borderBottomWidth: 1,
          borderStyle: 'solid',
          borderColor: 'gray.700',
        }}
      >
        <Sheet
          sx={{
            width: '100%',
            px: `${sideBar.padding}px`,
            backgroundColor: 'inherit',
            transition: createTransition('padding'),
          }}
        >
          <Button
            ref={ref}
            size="lg"
            variant="outlined"
            onClick={onButtonClick}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 1.5,
              width: '100%',
              backgroundColor: 'gray.950',
              borderColor: 'gray.700',
              boxShadow: 'xs',
              '&:hover': {
                backgroundColor: 'gray.900',
              },
            }}
            slotProps={{
              endDecorator: {
                style: {
                  marginLeft: 0,
                },
              },
            }}
            endDecorator={
              <Sheet
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'inherit',
                  transform: `rotate(${isPopoverOpen ? '0' : '180deg'})`,
                  transition: `transform .1s ease-in-out`,
                }}
              >
                <ChevronCompactUpSvg fill={theme.palette.gray[500]} />
              </Sheet>
            }
          >
            <Chip
              endDecorator={
                Boolean(
                  selectedSites.length &&
                    selectedSites.length !== sitesOptions.length &&
                    selectedSites.length > 1,
                ) && (
                  <ChipDelete
                    sx={{
                      color: 'gray.500',
                      background: 'inherit',
                      '&:hover': { backgroundColor: 'inherit' },
                    }}
                    onDelete={onChipDelete}
                  />
                )
              }
              sx={{
                ...chipCommonStyles,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: sideBar.state === 'collapsed' ? 0 : 'auto',
              }}
            >
              <Typography
                noWrap
                level="text_xs"
                fontWeight="medium"
                textColor="gray.300"
              >
                {buttonContent.text}
              </Typography>
            </Chip>

            {buttonContent.sitesNumber && (
              <Chip
                sx={{
                  ...chipCommonStyles,
                  width: sideBar.state === 'collapsed' ? 0 : 72,
                  marginRight: sideBar.state === 'collapsed' ? 0 : 1,
                  marginLeft: sideBar.state === 'collapsed' ? 0 : '2px',
                }}
              >
                <Typography
                  level="text_xs"
                  fontWeight="medium"
                  textColor="gray.300"
                  textTransform="lowercase"
                >
                  {buttonContent.sitesNumber} {t('common.more')}
                </Typography>
              </Chip>
            )}
          </Button>
        </Sheet>
      </Sheet>
      <Popper
        transition
        width={sideBar.width}
        anchorEl={ref.current}
        open={isPopoverOpen}
        sx={{ zIndex: theme.zIndex.snackbar + 1 }}
      >
        <Sheet
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <SideBarGlobalSitesDropdownSearch
            value={searchTerm}
            onSearchTermChange={onSearchTermChange}
          />
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: 'transparent',
              overflowY: 'auto',
            }}
            component="ul"
          >
            <Sheet sx={{ ml: 2 }}>
              <ListItemWithCheckbox
                underline
                id={allSiteOptionIndex}
                isChecked={isAllSitesSelected}
                isIndeterminate={isAllSiteListItemIndeterminate}
                onClick={onSelectAllClick}
              >
                <Typography noWrap fontWeight="medium" level="text_sm">
                  {t('common.select_all')}
                </Typography>
              </ListItemWithCheckbox>
            </Sheet>
            {Object.values(selectedSitesObject).map((siteDetails) => (
              <Sheet key={siteDetails.siteId} sx={{ ml: 2 }}>
                <ListItemWithCheckbox
                  id={siteDetails.siteId}
                  isChecked={siteDetails.isChecked}
                  onClick={onListItemClick}
                >
                  <Typography noWrap fontWeight="medium" level="text_sm">
                    {siteDetails.name}
                  </Typography>
                </ListItemWithCheckbox>
              </Sheet>
            ))}
          </List>
          <Sheet
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: `${sideBar.padding}px`,
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 1,
              borderStyle: 'solid',
              borderColor: 'gray.200',
            }}
          >
            <ActionsBar
              onReset={onReset}
              onCancel={onCancel}
              onApply={submitSitesSelection}
            />
          </Sheet>
        </Sheet>
      </Popper>
    </>
  );
};
