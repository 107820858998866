import React, { ChangeEvent, useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSkillsByIds } from '../../modules/selectors/skill';
import { useQueryParams, useValidate } from '../../modules/utils/hooks';
import {
  IdsArray,
  IStoreState,
  IUpdateSkill,
  SkillTerm,
} from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchSkillsByIds } from '../../modules/utils/hooks';
import { updateSkillsRequest } from '../../modules/actions/skill';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { updateSkillsScheme } from '../../modules/schemes/skills';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { useBrowserHistoryFunctions } from '../../modules/utils/hooks';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { isExpiration, isPermanent } from './SkillsCreate';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface ISkillUpdateProps {
  skills: IUpdateSkill[];
  onSubmit: (skills: IUpdateSkill[]) => void;
}

const SkillUpdateForm = ({ onSubmit, skills }: ISkillUpdateProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateSkillsScheme);

  const formik = useFormikInUpdateForm({
    initialValues: skills,
    validate,
    onSubmit,
  });

  // skills.skillTerm = Boolean(skills.hasExpiration) ? 'expiration' : 'permanent'
  const handleRadioChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    formik.handleChange(e);
  };

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (skill, index) => {
        // skill.skillTerm =
        //   formik.values[index].skillTerm || Boolean(skill.hasExpiration)
        //     ? 'expiration'
        //     : 'permanent';

        // const skillTerm = Boolean(skill.hasExpiration)
        //   ? 'expiration'
        //   : 'permanent';
        return (
          <CreateForm
            defaultExpanded={index === 0}
            cardTitle={t('skills.update_title', skill)}
            key={skill.id}
          >
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id={`${index}.name`}
                label={t('skills.name')}
                name={`${index}.name`}
                autoComplete="name"
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SitesComboBox
                id={`${index}.siteId`}
                required={true}
                formik={formik}
                errorMode="onFieldChange"
                label={t('skills.site')}
                placeholder={t('skills.site')}
              />
            </FormFieldContainer>

            <Box pt={3}>
              <FormFieldContainer>
                <RadioGroup
                  aria-label="skillTerm"
                  // id={`${index}.skillTerm`}
                  name={`[${index}].skillTerm`}
                  value={formik.values[index].skillTerm || 'expiration'}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value={`expiration`}
                    control={<Radio />}
                    label={t('emp_skills.has_expiration_date')}
                  />
                  <FormControlLabel
                    value={`permanent`}
                    control={<Radio />}
                    label={t('emp_skills.permanent')}
                  />
                </RadioGroup>
              </FormFieldContainer>
            </Box>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id={`${index}.expirationPeriod`}
                name={`${index}.expirationPeriod`}
                label={t('emp_skills.expiration_period')}
                formik={formik}
                type="number"
                required={isExpiration(formik.values[index].skillTerm)}
                disabled={isPermanent(formik.values[index].skillTerm)}
              />
            </FormFieldContainer>
          </CreateForm>
        );
      })}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const SkillsUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchSkills = useFetchSkillsByIds(ids);

  // fetch skills list from store
  const skills = useSelector(
    (state) => getSkillsByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch skills from the server if we don't have them in the store
  useEffect(() => {
    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated skills
   */
  const handleFormSubmit = (data: IUpdateSkill[]) => {
    const sendData = data.map(
      ({ id, expirationPeriod, name, siteId, skillTerm }) => ({
        id,
        expirationPeriod,
        name,
        siteId,
        hasExpiration: isExpiration(skillTerm),
        isPermanent: isPermanent(skillTerm),
      }),
    );
    dispatcher(updateSkillsRequest({ data: sendData, navigate }));
  };

  const d = skills.map((skill) => ({
    ...skill,
    skillTerm: (skill.isPermanent ? 'permanent' : 'expiration') as SkillTerm,
  }));

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <SkillUpdateForm skills={d} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
  // return <SkillUpdateForm skills={skills} onSubmit={handleFormSubmit} />;
};

export default SkillsUpdate;
