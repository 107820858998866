import React from 'react';
import { Checkbox } from '@mui/joy';
import { map } from 'lodash';
import { HeadCellWithVisibilityFlag, TableOrder } from './index';
import addHeadCellOrderByGetter from './helpers/addHeadCellOrderByGetter';
import { Th, Tr } from '../TableUiComponents';

/**
 * Table header props
 */
interface EnhancedTableHeadProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  order: TableOrder;
  orderBy: string;
  rowCount: number;
  headCells: HeadCellWithVisibilityFlag[];
  disableSelection?: boolean;
}

/**
 * Table Header component
 */
function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <Tr>
      {!props.disableSelection && (
        <Th>
          <Checkbox
            size="sm"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(e) => onSelectAllClick(e, e.target.checked)}
          />
        </Th>
      )}
      {map(props.headCells, (headCellWithVisibility) => {
        const headCell = addHeadCellOrderByGetter(headCellWithVisibility);
        return (
          <Th
            key={headCell.id}
            sortDirection={orderBy === headCell.orderBy ? order : undefined}
            onClick={
              !headCell.disableSorting
                ? createSortHandler(headCell.orderBy)
                : undefined
            }
          >
            {headCell.label}
          </Th>
        );
      })}
    </Tr>
  );
}

export default EnhancedTableHead;
