import React from 'react';
import { IconButton, Sheet, Typography } from '@mui/joy';
import { XCloseSvg } from 'src/components/svgIcons';

interface IDrawerHeaderProps {
  onCloseClick: () => void;
  children?: React.ReactNode;
}

export const DrawerHeader = ({
  onCloseClick,
  children,
}: IDrawerHeaderProps) => {
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 3,
        pt: 3,
        pb: 1.5,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'colors.border.border_primary',
        overflow: 'hidden',
        bgcolor: 'base.white',
      }}
    >
      <Typography level="text_xl" textColor="colors.text.text_primary.main">
        {children}
      </Typography>
      <IconButton onClick={onCloseClick}>
        <XCloseSvg />
      </IconButton>
    </Sheet>
  );
};
