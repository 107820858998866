import React from 'react';
import { get } from 'lodash';
import { SxProps } from '@mui/joy/styles/types';

import { ITableHeadProps } from 'src/components/Table/components/TableHead';
import { Tr } from 'src/components/TableUiComponents';
import { Td } from 'src/components/TableUiComponents/Td';
import { Checkbox } from 'src/components/_ui-kit/Checkbox';

export interface ITableBodyItem {
  id: number;
}

export interface ITableBodyProps<T extends ITableBodyItem>
  extends Pick<ITableHeadProps, 'headCells'> {
  data: (T & { sx?: SxProps; className?: string })[];
  itemUniqueKey?: keyof T;
  selectedRows?: number[];
  onCheckboxClick?: (id: number, checked: boolean) => void;
  onTableRowClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: T,
  ) => void;
}

export const TableBody = <P extends ITableBodyItem>({
  data,
  headCells,
  onCheckboxClick,
  selectedRows,
  onTableRowClick,
  itemUniqueKey = 'id',
  children,
  ...tableRowProps
}: React.PropsWithChildren<ITableBodyProps<P>>) => {
  const getIsCheckboxChecked = (id: number) => {
    return selectedRows?.some((entityId) => entityId === id);
  };

  const createCheckboxClickHandler = (id: number) => () => {
    const isClickedCheckboxActive = getIsCheckboxChecked(id);

    onCheckboxClick && onCheckboxClick(id, !isClickedCheckboxActive);
  };

  return (
    <tbody>
      {data.map((row, index) => (
        <Tr
          {...(row?.className ? { className: row?.className } : {})}
          sx={{
            cursor: Boolean(onTableRowClick) ? 'pointer' : 'default',
            ...row?.sx,
          }}
          key={`${row[itemUniqueKey]}${index}`}
          onClick={onTableRowClick ? (e) => onTableRowClick(e, row) : undefined}
          {...tableRowProps}
        >
          {onCheckboxClick && (
            <Td sx={{ width: 20 }}>
              <Checkbox
                checked={getIsCheckboxChecked(row.id)}
                onChange={createCheckboxClickHandler(row.id)}
              />
            </Td>
          )}
          {headCells.map((headCell) => (
            <Td key={headCell.id}>{get(row, headCell.id) as string}</Td>
          ))}
        </Tr>
      ))}
      {children}
    </tbody>
  );
};
