import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  INotificationState,
  INotificationModel,
  INotificationData,
  DataWithCount,
} from '../../types';

const defaultState: INotificationState = {
  list: [],
  count: 0,
  countUnread: 0,
  isDeleteNotificationInProgress: false,
  isGetMoreNotificationsRequestProcessing: false,
};

const getNotificationsListRequest = (state: INotificationState) => ({
  ...state,
  is_data_requested: true,
});

const getNotificationsListSuccess = (
  state: INotificationState,
  {
    data,
    count,
    countUnread,
  }: DataWithCount<INotificationModel> & { countUnread: number },
) => ({
  ...state,
  list: data,
  count,
  countUnread,
  is_data_requested: false,
});

const getRecentNotificationSuccess = (
  state: INotificationState,
  notification: INotificationModel,
) => ({
  ...state,
  list: [notification, ...state.list],
  count: +state.count + 1,
  countUnread: +state.countUnread + 1,
  is_data_requested: false,
});

const getNotificationsListFailed = (state: INotificationState) => ({
  ...state,
  is_data_requested: false,
});

const getMoreNotificationsListRequest = (state: INotificationState) => ({
  ...state,
  isGetMoreNotificationsRequestProcessing: true,
});

const getMoreNotificationsListSuccess = (
  state: INotificationState,
  {
    data,
    count,
    countUnread,
  }: DataWithCount<INotificationModel> & { countUnread: number },
) => ({
  ...state,
  list: [...state.list, ...data],
  count,
  countUnread,
  isGetMoreNotificationsRequestProcessing: false,
});

const getMoreNotificationsListFailed = (state: INotificationState) => ({
  ...state,
  isGetMoreNotificationsRequestProcessing: false,
});

const changeAllNotificationsStatusRequest = (state: INotificationState) => ({
  ...state,
});

const changeAllNotificationsStatusSuccess = (
  state: INotificationState,
  payload: Pick<INotificationModel, 'read'>,
) => ({
  ...state,
  list: state.list.map((item) => ({ ...item, read: payload.read })),
  countUnread: payload.read ? 0 : state.count,
});

const changeAllNotificationsStatusFailed = (state: INotificationState) => ({
  ...state,
});

const changeNotificationStatusRequest = (state: INotificationState) => ({
  ...state,
  is_data_requested: true,
});

const changeNotificationStatusSuccess = (
  state: INotificationState,
  notificationData: INotificationData,
) => ({
  ...state,
  is_data_requested: false,
  countUnread: notificationData.read
    ? state.countUnread - 1
    : state.countUnread + 1,
  list: state.list.map((notification) =>
    notificationData.ids.includes(notification.id)
      ? { ...notification, read: notificationData.read }
      : notification,
  ),
});

const deleteNotificationFailed = (state: INotificationState) => ({
  ...state,
  isDeleteNotificationInProgress: false,
});

const deleteNotificationRequest = (state: INotificationState) => ({
  ...state,
  isDeleteNotificationInProgress: true,
});

const deleteNotificationSuccess = (state: INotificationState) => {
  return {
    ...state,
    isDeleteNotificationInProgress: false,
  };
};

const deleteAllNotificationsRequest = (state: INotificationState) => ({
  ...state,
  is_data_requested: true,
});

const deleteAllNotificationsSuccess = (state: INotificationState) => ({
  ...state,
  is_data_requested: false,
  list: [],
  count: 0,
  countUnread: 0,
});

const deleteAllNotificationsFailed = (state: INotificationState) => ({
  ...state,
  is_data_requested: true,
});

const changeNotificationStatusFailed = (state: INotificationState) => ({
  ...state,
  is_data_requested: false,
});

export const notification = createReducer<INotificationState>({}, defaultState)
  .on(actions.changeNotificationStatusRequest, changeNotificationStatusRequest)
  .on(actions.changeNotificationStatusSuccess, changeNotificationStatusSuccess)
  .on(actions.changeNotificationStatusFailed, changeNotificationStatusFailed)

  .on(actions.getNotificationsListRequest, getNotificationsListRequest)
  .on(actions.getNotificationsListSuccess, getNotificationsListSuccess)
  .on(actions.getRecentNotificationSuccess, getRecentNotificationSuccess)
  .on(actions.getNotificationsListFailed, getNotificationsListFailed)

  .on(actions.getMoreNotificationsListRequest, getMoreNotificationsListRequest)
  .on(actions.getMoreNotificationsListSuccess, getMoreNotificationsListSuccess)
  .on(actions.getMoreNotificationsListFailed, getMoreNotificationsListFailed)

  .on(
    actions.changeAllNotificationsStatusRequest,
    changeAllNotificationsStatusRequest,
  )
  .on(
    actions.changeAllNotificationsStatusSuccess,
    changeAllNotificationsStatusSuccess,
  )
  .on(
    actions.changeAllNotificationsStatusFailed,
    changeAllNotificationsStatusFailed,
  )

  .on(actions.deleteNotificationRequest, deleteNotificationRequest)
  .on(actions.deleteNotificationSuccess, deleteNotificationSuccess)
  .on(actions.deleteNotificationFailed, deleteNotificationFailed)

  .on(actions.deleteAllNotificationsRequest, deleteAllNotificationsRequest)
  .on(actions.deleteAllNotificationsSuccess, deleteAllNotificationsSuccess)
  .on(actions.deleteAllNotificationsFailed, deleteAllNotificationsFailed);

export default notification;
