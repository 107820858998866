import React from 'react';
import { Sheet } from '@mui/joy';

interface IFilterPanelContainerProps {
  children: React.ReactNode;
}

export const FilterPanelContainer = ({
  children,
}: IFilterPanelContainerProps) => {
  return <Sheet sx={{ ml: 0.5, bgcolor: 'inherit' }}>{children}</Sheet>;
};
