import { noop } from 'lodash';
import React from 'react';
import { AnyObject } from 'src/modules/types';
import { FilterValue, IHeadCell } from 'src/modules/types/table';
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from 'src/modules/utils/localStorageWrapper';

interface ITableContext {
  filters: Record<string, unknown>;
  resetFilters: () => void;
  getFilterByName: (name: string) => FilterValue;
  setFilter: (filterName: string, value: FilterValue) => void;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  useInitFilterEffect: (filterName: string, value: FilterValue) => void;
  headCells: Array<IHeadCell & { isVisible?: boolean }>;
  visibleHeadCells: Array<IHeadCell & { isVisible?: boolean }>;
  setHeadCells: (headCells: IHeadCell[]) => void;
  tableUniqueKey: string;
  setTableUniqueKey: (key: string) => void;
}

export const TableContext = React.createContext<ITableContext>({
  filters: {},
  headCells: [],
  visibleHeadCells: [],
  resetFilters: noop,
  getFilterByName: noop,
  setFilter: noop,
  setFilters: noop,
  useInitFilterEffect: noop,
  setHeadCells: noop,
  tableUniqueKey: '',
  setTableUniqueKey: noop,
} as ITableContext);

export const TableContextProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = React.useState({});
  const [tableUniqueKey, _setTableUniqueKey] = React.useState('');
  const [headCells, _setHeadCells] = React.useState<IHeadCell[]>([]);

  const visibleHeadCells = React.useMemo(
    () => headCells.filter((cell) => cell.isVisible),
    [headCells],
  );

  const setFilter = React.useCallback(
    (filterName: string, value: FilterValue) => {
      setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    },
    [],
  );

  const setHeadCells = React.useCallback(
    (headCells: IHeadCell[]) => {
      const existedTablesConfiguration = getItemFromLocalStorage(
        'tablesConfiguration',
      ) as AnyObject;

      setItemInLocalStorage('tablesConfiguration', {
        ...existedTablesConfiguration,
        [tableUniqueKey]: headCells,
      });
      _setHeadCells(headCells);
    },
    [tableUniqueKey],
  );

  const setTableUniqueKey = React.useCallback((key: string) => {
    _setTableUniqueKey(key);
  }, []);

  const getFilterByName = React.useCallback(
    (name: string) => {
      return filters[name];
    },
    [filters],
  );

  const resetFilters = React.useCallback(() => {
    setFilters({});
  }, []);

  const useInitFilterEffect = (filterName: string, value: FilterValue) => {
    React.useEffect(() => {
      setFilter(filterName, value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

  return (
    <TableContext.Provider
      value={{
        filters,
        headCells,
        visibleHeadCells,
        tableUniqueKey,
        setFilters,
        setFilter,
        setHeadCells,
        getFilterByName,
        resetFilters,
        setTableUniqueKey,
        useInitFilterEffect,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
