import React from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ListIcon from '@mui/icons-material/List';
import LinkIcon from '@mui/icons-material/Link';
import ImageIcon from '@mui/icons-material/Image';
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const iconList = {
  bold: FormatBoldIcon,
  italic: FormatItalicIcon,
  strikethrough: StrikethroughSIcon,
  underline: FormatUnderlinedIcon,
  // headingOne: <BsTypeH1 size={20} />,
  // headingTwo: <BsTypeH2 size={20} />,
  // headingThree: <BsTypeH3 size={20} />,
  //
  // blockquote: <MdFormatQuote size={20} />,
  // superscript: <FaSuperscript size={15} />,
  // subscript: <FaSubscript size={15} />,
  alignLeft: FormatAlignLeftIcon,
  alignCenter: FormatAlignCenterIcon,
  alignRight: FormatAlignRightIcon,
  orderedList: FormatListNumberedIcon,
  unorderedList: ListIcon,
  link: LinkIcon,
  image: ImageIcon,
  // video: <MdVideoLibrary size={20} />,
  // add: <MdAdd size={20} />,
  table: TableChartIcon,
  row: ViewColumnIcon,
  column: ViewColumnIcon,
  removeTable: DeleteOutlineIcon,
};

export const MaterialIcon = (props: any) => {
  const { icon, active, ...rest } = props;
  const Icon = iconList[icon];

  return <Icon color={active ? 'primary' : 'secondary'} {...rest} />;
};
