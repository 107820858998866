import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/joy';

import { IHeadCellWithOrderConfig, Order } from 'src/modules/types/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { ICountResponse, IListWithSummary, AnyObject } from 'src/modules/types';
import {
  Table,
  Pagination,
  TableBottom,
  TableContent,
} from 'src/components/Table';
import { useMainLayoutContext } from 'src/components/Layout/MainLayout/MainLayoutContext';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  IFinancialReportTableDataItem,
  IFinancialReportTableDataSummary,
} from 'src/modules/types/financial-report';
import { IExecutiveSummaryFinancialTableResponseData } from 'src/modules/types/executives-summary';
import { TableCellWithColor } from 'src/components/Table/components/TableCellWithColor';
import { TableSummary } from 'src/components/Table/components/TableSummary';
import { useBrowserHistoryFunctions } from 'src/modules/utils';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { HistoricalKpi } from './components/HistoricalKpi';

const financialReportsUrl = '/new-executive-dashboard/financial-tracker-data';

const weeklyGrossMarginDefaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

export type ITableData = IListWithSummary<{
  items: Array<IFinancialReportTableDataItem>;
  summary: Array<IFinancialReportTableDataSummary>;
}>;

interface IExecutiveSummaryFinancialTableProps {
  siteId?: number;
  yearWeek?: number;
}

export const ExecutiveSummaryFinancialTable = ({
  siteId,
  yearWeek,
}: IExecutiveSummaryFinancialTableProps) => {
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = React.useState<AnyObject>({});
  const [displayHistorical, setDisplayHistorical] = React.useState<boolean>(
    false,
  );

  const theme = useTheme();

  const globallySelectedSites = useSelector(getGloballySelectedSites);

  const { pushToHistory: navigate } = useBrowserHistoryFunctions();

  const { pageWithCopyrightContentHeight } = useMainLayoutContext();

  const {
    data: tableDataResponse,
    fetchData: fetchTableData,
    isDataLoading: isTableDataLoading,
  } = useDataFetcherWithData<IExecutiveSummaryFinancialTableResponseData>(
    financialReportsUrl,
    {
      items: [],
      summary: [],
    },
  );

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<Array<ICountResponse>>(
    `${financialReportsUrl}/count`,
    [
      {
        count: 0,
      },
    ],
  );

  const colorNameToColorMap = React.useMemo(
    () => ({
      red: theme.palette.colors.utility.error.utility_error_200,
      yellow: theme.palette.colors.utility.warning.utility_warning_200,
      green: theme.palette.colors.utility.success.utility_success_200,
      gray: '#B5C0D0',
    }),
    [
      theme.palette.colors.utility.error.utility_error_200,
      theme.palette.colors.utility.success.utility_success_200,
      theme.palette.colors.utility.warning.utility_warning_200,
    ],
  );

  const tableData = React.useMemo(() => {
    return tableDataResponse.items.map((item) => {
      const handleClick = () => {
        setSelectedRow(item);
        setDisplayHistorical(true);
      };

      const operationalKpiComponent = (
        <Box
          onClick={handleClick}
          sx={{
            minHeight: 24,
            minWidth: 100,
            borderRadius: 4,
            cursor: 'pointer',
            backgroundColor: colorNameToColorMap[item.color],
          }}
        />
      );

      const safetyComponent = (
        <TableCellWithColor color={colorNameToColorMap['green']} />
      );

      const lostOpportunityComponent = (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 0.5,
            color: item.lostOpportunity >= 0 ? 'red' : 'green',
          }}
        >
          {item.lostOpportunity.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })}
        </Typography>
      );

      return {
        ...item,
        revenue: item.revenue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }),
        cost: item.cost.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }),
        gm: item.gm.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }),
        lostOpportunity: lostOpportunityComponent,
        gmPercentage: `${item.gmPercentage}%`,
        fcGmPercentage: `${item.fcGmPercentage}%`,
        color: operationalKpiComponent,
        safetyColor: safetyComponent,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorNameToColorMap, tableDataResponse, navigate]);

  const summary = tableDataResponse.summary.map((item) => ({
    ...item,
    values: {
      revenue: item.values.revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
      cost: item.values.cost.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
      gm: item.values.gm.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }),
      lostOpportunity: (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 0.5,
            color: Number(item.values.lostOpportunity) >= 0 ? 'red' : 'green',
          }}
        >
          {item.values.lostOpportunity.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })}
        </Typography>
      ),
      gmPercentage: `${item.values.gmPercentage}%`,
    },
  }));

  const where = React.useMemo(
    () => ({
      siteId: siteId
        ? siteId
        : globallySelectedSites
        ? { inq: globallySelectedSites }
        : undefined,
      yearWeek: yearWeek ? yearWeek : undefined,
    }),
    [globallySelectedSites, siteId, yearWeek],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        label: t('users.site_name'),
        orderConfig: { orderBy: 'siteName' },
      },
      {
        id: 'yearWeek',
        label: t('productions_uph_reports.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'revenue',
        label: t('reports.headers.revenue'),
        orderConfig: { orderBy: 'revenue' },
      },

      {
        id: 'cost',
        label: t('financial_tracker.cost'),
        orderConfig: { orderBy: 'cost' },
      },
      {
        id: 'gm',
        label: `${t('dashboard.gm')} $`,
        orderConfig: { orderBy: 'gm' },
      },
      {
        id: 'gmPercentage',
        label: `${t('dashboard.gm')} %`,
        orderConfig: { orderBy: 'gmPercentage' },
      },
      {
        id: 'fcGmPercentage',
        label: `${t('executive_summary.fc_gm')} %`,
        orderConfig: { orderBy: 'fcGmPercentage' },
      },
      {
        id: 'lostOpportunity',
        label: t('executive_summary.lost_opportunity'),
        orderConfig: { orderBy: 'lostOpportunity' },
      },
      {
        id: 'color',
        label: t('executive_summary.operational_kpi'),
        orderConfig: { orderBy: 'color' },
      },
      {
        id: 'safetyColor',
        label: t('executive_summary.safety'),
        // orderConfig: { orderBy: 'safetyColor' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: weeklyGrossMarginDefaultOrderDetails.order,
    defaultOrderBy: weeklyGrossMarginDefaultOrderDetails.orderBy,
  });

  const handleChangeRowsPerPage = (limit: number) => {
    setLimit(limit);

    setPage(DEFAULT_PAGE);

    fetchTableData({
      filter: {
        where,
        offset: DEFAULT_PAGE * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handleSort = (orderBy: string, order: Order) => {
    setOrderBy(orderBy);
    setOrder(order);

    fetchTableData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);

    fetchTableData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  useFetchReportTableDataAndCountEffect({
    fetchData: fetchTableData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? weeklyGrossMarginDefaultOrderDetails.order,
    orderBy: orderBy ?? weeklyGrossMarginDefaultOrderDetails.orderBy,
  });

  return (
    <Box sx={{ maxHeight: '780px', display: 'flex' }}>
      <Table
        tableUniqueKey="executiveSummary/financialTableData"
        sx={{
          height: isTableDataLoading ? pageWithCopyrightContentHeight : 'auto',
        }}
      >
        {isTableDataLoading && <MainContentLoader />}

        <TableContent
          data={tableData as any}
          headCells={headCells}
          isDataLoading={isTableDataLoading || isCountDataLoading}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
        >
          {summary.map((item) => (
            <TableSummary key={item.title} {...item} />
          ))}
        </TableContent>

        <TableBottom>
          <Pagination
            count={countData[0].count}
            rowsPerPage={limit}
            currentPageNumber={page}
            onPageChange={(_, page) => handlePageChange(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableBottom>
      </Table>

      <HistoricalKpi
        siteId={selectedRow?.siteId}
        title={selectedRow?.siteName}
        yearWeek={selectedRow?.yearWeek}
        isOpen={displayHistorical}
        onClose={() => setDisplayHistorical(false)}
      />
    </Box>
  );
};
