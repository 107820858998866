import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Alert, Box } from '@mui/joy';

import {
  usePayboardSummaryByWorkAndSupervisorWeek,
  usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration,
} from 'src/modules/utils';
import { IFilterWhere } from 'src/modules/types';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { PageContentChildContainer } from 'src/components/PageContent';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { MUI_SIZE_12 } from 'src/config';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { PayboardSummaryByWorkAndSupervisorWeekFilter } from 'src/pages/TimeKeeping/DailyHours/panels/PayboardSummaryByWorkAndSupervisorWeek/components/PayboardSummaryByWorkAndSupervisorWeekFilter';
import { ReportPage } from '../../../../../components/ReportPage';

const reportUrl = '/payboard/daily-summary-by-work-week-and-supervisor';

export const PayboardSummaryByWorkAndSupervisorWeek: React.FC = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl, true);

  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  const selectedSite = React.useMemo(
    () => (selectedSites.length === 1 ? selectedSites[0] : undefined),
    [selectedSites],
  );

  const filterFieldsConfiguration = usePayboardSummaryByWorkWeekAndSupervisorTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = React.useMemo<IFilterWhere>(
    () => ({
      siteId: selectedSite,
      ...(filterFields?.firstName?.value
        ? {
            firstName: {
              like: `%${filterFields?.firstName?.value}%`,
            },
          }
        : {}),
      ...(filterFields?.lastName?.value
        ? {
            lastName: {
              like: `%${filterFields?.lastName?.value}%`,
            },
          }
        : {}),
      ...(filterFields?.supervisorId?.value
        ? {
            supervisorId: filterFields?.supervisorId?.value,
          }
        : {}),
    }),
    [
      selectedSite,
      filterFields.firstName,
      filterFields.lastName,
      filterFields.supervisorId,
    ],
  );

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = usePayboardSummaryByWorkAndSupervisorWeek(where);

  return (
    <Box sx={{ flexGrow: data.length ? 0 : 1, mx: -2 }}>
      <ReportPage hideNoData>
        <PageContentChildContainer sx={{ pb: 1, px: 0 }} fullHeight={false}>
          <TableToolbar>
            <TableTitle>
              {t('main_menu.payboard_summary_by_work_week_and_supervisor')}
            </TableTitle>

            <TableActions>
              <PayboardSummaryByWorkAndSupervisorWeekFilter
                siteId={selectedSite}
                getLabel={getLabel}
                onFiltersFormSubmit={onFiltersFormSubmit}
                getFilterCommonPropsByFilterName={
                  getFilterCommonPropsByFilterName
                }
              />

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={requestFilters}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>

        <>
          {!selectedSite && (
            <Grid container={true}>
              <Grid item={true} xs={MUI_SIZE_12}>
                <Alert variant="soft">
                  {t(
                    'payboard.summary_by_work_and_supervisor.select_only_one_site',
                  )}
                </Alert>
              </Grid>
            </Grid>
          )}
          {selectedSite && (
            <ReportTable
              disablePagination
              data={data as any}
              count={data.length}
              isDataLoading={isDataLoading}
              tableUniqueKey={reportUrl}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              rowsPerPage={limit}
              currentPageNumber={page}
              onSort={handleSort}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={(_, page) => handlePageChange(page)}
            />
          )}
        </>
      </ReportPage>
    </Box>
  );
};
