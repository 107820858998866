import React from 'react';
import { Alert, Grid } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { MUI_SIZE_12 } from 'src/config';

export const SelectSiteAlert = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid xs={MUI_SIZE_12}>
        <Alert variant="soft">{t('reports.select_site_from_filter')}</Alert>
      </Grid>
    </Grid>
  );
};
