import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  AnyObject,
  ICountResponse,
  IFilterData,
  IFilterInclude,
  IFilterWhere,
  IIncentivePriceModel,
} from '../../types';
import { getGloballySelectedSites } from '../../selectors/site';
import { IHeadCellWithOrderConfig } from '../../types/table';
import { useDynamicBaseReport } from './reports.hooks';
import { useDataFetcherWithData } from './common/reports';
import { useGenerateHeadCellsData } from './table';
import { useIncentivePricingSettingsForm } from './incentive-pricing-settings';

export const useIncentivePermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.incentive.create.id,
  );
  const allowedToApprove = useHasUserAccessToAction(
    manageEntitiesConfig.incentive.approve.id,
  );
  const allowedToUnapprove = useHasUserAccessToAction(
    manageEntitiesConfig.incentive.approve.id,
  );
  const allowedToUpdateTotalPayment = useHasUserAccessToAction(
    manageEntitiesConfig.incentive.updateTotalPayment.id,
  );

  return {
    allowedToCreate,
    allowedToApprove,
    allowedToUnapprove,
    allowedToUpdateTotalPayment,
  };
};

export interface IIncentivePriceFilterPanelFilters {
  siteId?: number;
}

export const useIncentivePricePermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.incentivePrice.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.incentivePrice.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.incentivePrice.delete.id,
  );
  const allowedToCreateSettings = useHasUserAccessToAction(
    manageEntitiesConfig.incentivePrice.create_settings.id,
  );

  return {
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
    allowedToCreateSettings,
  };
};

export const useIncentivePriceTableFiltersConfiguration = (
  metaTagsColumns?: string[],
) =>
  React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      ...metaTagsColumns?.reduce((acc, cur) => {
        acc[cur] = {
          value: '',
          property: cur,
          operator: 'eq' as const,
        };

        return acc;
      }, {}),
    }),
    [metaTagsColumns],
  );

export const useIncentivePriceInclusion = () => {
  return React.useMemo(
    () => [
      {
        relation: 'site',
      },
    ],
    [],
  );
};

export const useIncentivePriceComposeWhere = (
  where: Partial<IIncentivePriceModel>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  metaTagsColumns?: string[],
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      // ...metaTagsColumns?.reduce((acc, cur) => {
      //   acc[cur] = where[cur];
      //   return acc;
      // }, {}),
    }),
    [selectedSites, where.siteId],
  );
};

export const useIncentivePriceFilter = (
  appliedFilters: IIncentivePriceFilterPanelFilters,
  metaTagsColumns?: string[],
) => {
  const _include = useIncentivePriceInclusion();
  const _where = useIncentivePriceComposeWhere(appliedFilters, metaTagsColumns);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};

export const useIncentivePriceTableInclusionObject = () => {
  return React.useMemo(
    () => ({
      site: {
        relationType: 'inner',
      },
    }),
    [],
  );
};

export const useIncentivePrice = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
  siteId?: number,
) => {
  const { t } = useTranslation();
  const inclusionObj = useIncentivePriceTableInclusionObject();

  const { settings = [] } = useIncentivePricingSettingsForm(siteId);

  const initialData: AnyObject[] = [];

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'site.name',
        orderConfig: { orderBy: 'site.name' },
        label: t('incentivePrice.site'),
      },
      {
        id: 'price',
        orderConfig: { orderBy: 'price' },
        label: t('incentivePrice.price'),
      },
      ...settings.map(({ name, title }) => ({
        id: name,
        orderConfig: { orderBy: name },
        label: title,
      })),
    ],
    [settings, t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return useDynamicBaseReport({
    reportUrl,
    ignoreInitialRequest: !siteId,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });
};
