import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  IFilter,
  IWorkdaysModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../types';
import { ExportSagaCreator } from '../utils/helpers/creators/export';

/**
 * Get workdays list
 */
export const getWorkdaysListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IWorkdaysModel[] = yield call(
      Api.Workdays.list,
      action.payload,
    );
    yield put(actions.getWorkdaysListSuccess(response));
  } catch (e) {
    yield put(actions.getWorkdaysListFailed());
  }
};

/**
 * Get workdays count
 */
export const getWorkdaysCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Workdays.count,
      action?.payload,
    );
    yield put(actions.getWorkdaysCountSuccess(response));
  } catch (e) {
    yield put(actions.getWorkdaysCountFailed());
  }
};

/**
 * Bulk update workdays
 */
export const updateWorkdaysRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IWorkdaysModel[]>>): SagaIterator {
  try {
    yield call(Api.Workdays.bulkUpdate, data);
    yield put(actions.flushWorkdaysState());
    yield call(navigate, { pathname: '/workdays' });
  } catch (e) {
    yield put(actions.updateWorkdaysFailed());
  }
};

/**
 * Get combobox list
 */
export const getWorkdaysComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IWorkdaysModel>[] = yield call(
      Api.Workdays.list,
      action.payload,
    );
    yield put(actions.getWorkdaysComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getWorkdaysComboboxListFailed());
  }
};

/**
 * Export
 */
export const {
  exportRequestSaga: exportWorkdaysRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Workdays.export,
  actionFailed: actions.exportWorkdaysFailed,
  actionSuccess: actions.exportWorkdaysSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.getWorkdaysListRequest, saga: getWorkdaysListRequestSaga },
  {
    action: actions.getWorkdaysCountRequest,
    saga: getWorkdaysCountRequestSaga,
  },
  // bulk updating workdays
  { action: actions.updateWorkdaysRequest, saga: updateWorkdaysRequestSaga },
  {
    action: actions.getWorkdaysComboboxListRequest,
    saga: getWorkdaysComboboxListRequestSaga,
  },
  { action: actions.exportWorkdaysRequest, saga: exportWorkdaysRequestSaga },
];
