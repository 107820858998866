import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthDailyUphBySiteAndEmployee',
);

// get list actions
export const {
  getListRequest: getCardinalHealthDailyUphBySiteAndEmployeeListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteAndEmployeeListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteAndEmployeeListFailed,
} = actionCreator.takeListActions<ICardinalHealthDailyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthDailyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthDailyUphBySiteAndEmployeeRequest,
  exportReportSuccess: exportCardinalHealthDailyUphBySiteAndEmployeeSuccess,
  exportReportFailed: exportCardinalHealthDailyUphBySiteAndEmployeeFailed,
} = new ExportActionCreator(
  'CardinalHealthDailyUphBySiteAndEmployee',
).takeExportActions();
