import React from 'react';
import { Sheet } from '@mui/joy';

interface IDrawerBodyContentProps {
  children: React.ReactNode;
  component?: 'form' | 'div';
}

export const DrawerBodyContent = ({
  children,
  component,
}: IDrawerBodyContentProps) => {
  return (
    <Sheet
      sx={{ pt: 5, pb: 3, bgcolor: 'inherit' }}
      component={component ?? 'form'}
    >
      {children}
    </Sheet>
  );
};
