import React from 'react';
import { Box } from '@mui/joy';

interface IActiveItemIndicator {
  color?: string;
}

export const ActiveItemIndicator = ({
  color = 'success.600',
}: IActiveItemIndicator) => {
  return (
    <Box
      sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color,
      }}
    />
  );
};
