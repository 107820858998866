import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSyncImportingData } from '../../modules/utils/hooks/cardinalHealth.hooks';
import { useSitesByClientCombobox } from '../../modules/utils/hooks/common/forms';
import FormikDatepicker from '../Formik/FormikDatepicker';
import FormikMultipleSelect from '../Formik/FormikMultipleSelect';
import { ClientKey } from 'src/modules/constants';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from '../_ui-kit/Drawer';
import { FormFieldContainer } from '../Form/FormFieldContainer';
import { ActionsBar } from '../_ui-kit/ActionsBar';
import { MultipleSelectOption } from '../Formik/FormikMultipleSelectWithSearch';

interface ISyncModalProps {
  client?: ClientKey;
  isOpen: boolean;
  onClose: () => void;
  onSynchronized: () => void;
}

export const ReportSyncDrawer = ({
  client,
  isOpen,
  onClose,
  onSynchronized,
}: ISyncModalProps) => {
  const { t } = useTranslation();
  const { formik } = useSyncImportingData();
  const options = useSitesByClientCombobox('uuid', client);

  const onApply = () => {
    onSynchronized();

    onClose();

    formik.handleSubmit();
  };

  const onReset = () => {
    formik.setValues({
      dateStart: '',
      dateEnd: '',
      siteUuid: [],
    });
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('cardinal_health_reports.sync_importing.form_header')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikDatepicker
              key="dateStart"
              fullWidth
              id="dateStart"
              label={t('cardinal_health_reports.sync_importing.start_date')}
              formik={formik}
              required
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikDatepicker
              key="dateEnd"
              fullWidth
              id="dateEnd"
              label={t('cardinal_health_reports.sync_importing.end_date')}
              formik={formik}
              required
            />
          </FormFieldContainer>
          <FormFieldContainer>
            <FormikMultipleSelect
              id="siteUuid"
              title={t('cardinal_health_reports.sync_importing.select_sites')}
              formik={formik}
              required
              options={options as MultipleSelectOption[]}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={onApply}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
