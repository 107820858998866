import React, { useEffect, useState } from 'react';
import { Button, IconButton, Box, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Trash04Svg, UploadToCloudSvg } from '../svgIcons';
import { CSV_MIME_TYPE } from '../../config';

interface IUploadFileProps {
  onChange: (file: File | null) => void;
  onRemove?: () => void;
  fileName?: string;
  file?: Partial<File> | null;
  btnLabel?: string;
  accept?: string;
  // @@@TODO: we might need to do this prop required
  inputId?: string;
}

export default function UploadFile({
  onChange,
  onRemove,
  fileName,
  accept,
  btnLabel,
  inputId,
}: IUploadFileProps) {
  const { t } = useTranslation();

  const inputIdToUse = inputId ?? 'contained-button-file';

  const [filePreview, setFilePreview] = useState<string>();
  const [uploadedFileDetails, setUploadedFileDetails] = useState<Partial<
    File
  > | null>(null);

  // here we initialize the image preview from props
  useEffect(() => {
    setFilePreview(fileName);
  }, [fileName]);

  // handle removing image preview
  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }
    setFilePreview(undefined);
    setUploadedFileDetails(null);
    onChange(null);
  };

  // handle uploading image
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = [...event.target.files!];
    setFilePreview(file?.name);
    setUploadedFileDetails(file);
    onChange(file);
    event.target.value = '';
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'colors.background.bg_primary',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'colors.border.border_secondary',
          borderRadius: 12,
          px: 3,
          py: 2,

          '&:hover': {
            borderColor: 'colors.border.border_brand',
          },
        }}
      >
        <UploadToCloudSvg />

        <Box sx={{ pt: 1.5 }}>
          <Box
            component="input"
            accept={accept ?? CSV_MIME_TYPE}
            sx={{ display: 'none' }}
            id={inputIdToUse}
            multiple={false}
            type="file"
            onChange={handleChange}
          />
          <label htmlFor={inputIdToUse}>
            <Button
              variant="plain"
              component="span"
              size="md"
              sx={{ '&:hover': { bgcolor: 'transparent' } }}
            >
              <Typography
                textColor="colors.buttons.tertiary_colors.button_tertiary_color_fg"
                level="text_sm"
                fontWeight="semi_bold"
              >
                {btnLabel ?? t('common.click_to_upload')}
              </Typography>
            </Button>
          </label>
        </Box>
      </Box>

      {filePreview && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            bgcolor: 'colors.background.bg_primary',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'colors.border.border_secondary',
            borderRadius: 12,
            mt: 1,
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ pl: 1 }}>
              {uploadedFileDetails?.name && (
                <Box>
                  <Typography
                    level="text_sm"
                    textColor="colors.text.text_secondary"
                  >
                    {uploadedFileDetails.name}
                  </Typography>
                </Box>
              )}
              {uploadedFileDetails?.size && (
                <Box>
                  <Typography
                    level="text_sm"
                    textColor="colors.text.text_tertiary"
                  >
                    {(uploadedFileDetails.size / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignSelf: 'start' }}>
            <IconButton
              onClick={handleRemove}
              sx={{ minWidth: 0, minHeight: 0 }}
            >
              <Trash04Svg />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}
