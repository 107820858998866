import React from 'react';
import { Navigate } from 'react-router';
import { AuthLayout } from '../components/Layout/AuthLayout';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MUI_SIZE_12 } from '../config';
import {
  getForgotPasswordMessage,
  isLoggedIn,
} from '../modules/selectors/auth';
import { isDataRequested } from '../modules/selectors/auth';
import { forgotPasswordRequest } from '../modules/actions/auth';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../components/Formik/FormikTextField';
import { SubmitButton } from '../components/Form/SubmitButton';
import { useValidate } from '../modules/utils';
import { useFormik } from 'formik';
import { forgotPasswordScheme } from '../modules/schemes/forgot-password.scheme';
import { FormActionsContainer } from '../components/Form/FormActionsContainer';
import { Box } from '@mui/material';

const initialValues = { email: '' };

export default function ForgotPassword() {
  const validate = useValidate(forgotPasswordScheme);

  const dispatcher = useDispatch();

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(forgotPasswordRequest(data));
    },
  });

  const { t } = useTranslation();

  // check if a user is logged in
  const is_logged_in = useSelector(isLoggedIn, shallowEqual);

  // check if a user is logged in
  const forgot_password_message = useSelector(
    getForgotPasswordMessage,
    shallowEqual,
  );

  // check if data is requested to show preloader
  const isLoading = useSelector(isDataRequested, shallowEqual);

  if (is_logged_in) {
    return <Navigate to="/" />;
  }

  const output = forgot_password_message ? (
    <Alert severity="success" sx={{ marginTop: 4 }}>
      <AlertTitle>{t('common.success')}</AlertTitle>
      {forgot_password_message}
    </Alert>
  ) : (
    <Box
      component="form"
      sx={{
        width: '100%', // Fix IE 11 issue.
        marginTop: 1,
      }}
      noValidate={true}
      onSubmit={formik.handleSubmit}
    >
      <FormikTextField
        variant="outlined"
        required
        fullWidth
        id="email"
        label={t('auth.email')}
        name="email"
        autoComplete="email"
        autoFocus
        formik={formik}
      />

      <FormActionsContainer>
        <SubmitButton fullWidth isLoading={isLoading}>
          {t('auth.send_reset_password')}
        </SubmitButton>
      </FormActionsContainer>

      <Grid container>
        <Grid item xs={MUI_SIZE_12}>
          <Link href="/login" variant="body2">
            {t('auth.sign_in')}
          </Link>
        </Grid>
        <Grid item>
          <Link href="/register" variant="body2">
            {t('auth.suggest_signup')}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );

  return <AuthLayout>{output}</AuthLayout>;
}
