import React from 'react';
import { Step } from 'react-joyride';

interface IUseGenerateConfigArgs {
  [key: string]: Step;
}

export const useGenerateSteps = (args: IUseGenerateConfigArgs) => {
  return React.useMemo(
    () =>
      Object.values(args).map((step) => ({
        disableBeacon: true,
        placement: 'top-start' as const,
        ...step,
        target: `[data-tour=${step.target}]`,
      })),
    [args],
  );
};
