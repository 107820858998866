import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IQuestionModel, IQuestionState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

const defaultState: IQuestionState = {
  refreshKey: 0,
  list: [],
  comboboxList: [],
  count: 0,
};

const flushQuestionState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

const createQuestionRequest = (state: IQuestionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const createQuestionSuccess = (
  state: IQuestionState,
  payload: IQuestionModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const createQuestionFailed = (state: IQuestionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getQuestionListRequest = (state: IQuestionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getQuestionListSuccess = (
  state: IQuestionState,
  payload: IQuestionModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});
const getQuestionListFailed = (state: IQuestionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getQuestionCountRequest = (state: IQuestionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getQuestionCountSuccess = (
  state: IQuestionState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const getQuestionCountFailed = (state: IQuestionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const deleteQuestionRequest = (state: IQuestionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteQuestionSuccess = (state: IQuestionState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});
const deleteQuestionFailed = (state: IQuestionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updateQuestionsRequest = (state: IQuestionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateQuestionsFailed = (state: IQuestionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IQuestionState>();

const exportQuestionRequest = exportRequest;
const exportQuestionSuccess = exportSuccess;
const exportQuestionFailed = exportFailed;

/**
 * Question reducer
 */
export const question = createReducer<IQuestionState>({}, defaultState)
  // flush actions
  .on(actions.flushQuestionState, flushQuestionState)
  // creation actions
  .on(actions.createQuestionRequest, createQuestionRequest)
  .on(actions.createQuestionSuccess, createQuestionSuccess)
  .on(actions.createQuestionFailed, createQuestionFailed)
  // get list actions
  .on(actions.getQuestionListRequest, getQuestionListRequest)
  .on(actions.getQuestionListSuccess, getQuestionListSuccess)
  .on(actions.getQuestionListFailed, getQuestionListFailed)
  // get count actions
  .on(actions.getQuestionCountRequest, getQuestionCountRequest)
  .on(actions.getQuestionCountSuccess, getQuestionCountSuccess)
  .on(actions.getQuestionCountFailed, getQuestionCountFailed)
  // deletion actions
  .on(actions.deleteQuestionRequest, deleteQuestionRequest)
  .on(actions.deleteQuestionSuccess, deleteQuestionSuccess)
  .on(actions.deleteQuestionFailed, deleteQuestionFailed)
  // updating actions
  .on(actions.updateQuestionsRequest, updateQuestionsRequest)
  .on(actions.updateQuestionsFailed, updateQuestionsFailed)
  // export actions
  .on(actions.exportQuestionRequest, exportQuestionRequest)
  .on(actions.exportQuestionSuccess, exportQuestionSuccess)
  .on(actions.exportQuestionFailed, exportQuestionFailed);

export default question;
