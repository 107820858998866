import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { useHasUserAccessToAction } from 'src/config';
import {
  getOverdueQuestionnairesCountRequest,
  getOverdueQuestionnairesRequest,
} from 'src/modules/actions';
import { getQuestionCategoriesComboboxList } from 'src/modules/selectors/questionCategory';
import {
  getIsOverdueQuestionnairesInProgress,
  getOverdueEmployeesReview,
  getOverdueEmployeesReviewCount,
} from 'src/modules/selectors/questionEmployee';
import { IdsArray } from 'src/modules/types';
import { useFilter } from 'src/modules/utils';
import { UpdateOverdueReviews } from './UpdateOverdueReviews';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useEmployeesReviewDefaultFilters } from 'src/modules/utils/hooks/employees-review.hooks';

export const OverdueReviews = () => {
  const { t } = useTranslation();

  const [
    selectedOverdueReviewsIds,
    setSelectedOverdueReviewsIds,
  ] = React.useState<IdsArray>([]);
  const [
    isUpdateOverdueReviewsFormVisible,
    setIsUpdateOverdueReviewsFormVisible,
  ] = React.useState(false);

  const isDataLoading = useSelector(getIsOverdueQuestionnairesInProgress);

  const defaultFilter = useEmployeesReviewDefaultFilters();

  const allowedToUpdateAnswers = useHasUserAccessToAction(
    manageEntitiesConfig.employee_questions.update.id,
  );

  const dispatch = useDispatch();

  const { filterCount, filterList } = useFilter(defaultFilter);

  const list = useSelector(getOverdueEmployeesReview);
  const count = useSelector(getOverdueEmployeesReviewCount);

  const questionCategoryComboboxList = useSelector(
    getQuestionCategoriesComboboxList,
  );

  const filters: ITableFilter[] = [
    {
      name: 'reviewedAt',
      label: t('overdue_questionnaire.answer_date'),
      operator: 'like',
      type: 'date',
    },
    {
      name: 'employeeId',
      label: t('overdue_questionnaire.employee'),
      operator: 'eq',
      type: 'comboboxEmployee',
    },
    {
      name: 'questionCategoryId',
      label: t('overdue_questionnaire.question_category'),
      operator: 'eq',
      type: 'combobox',
      options: questionCategoryComboboxList,
    },
  ];

  const headCells: HeadCell[] = [
    {
      id: 'employee.firstName',
      disablePadding: false,
      label: t('emp_skills.emp_first_name'),
    },
    {
      id: 'employee.lastName',
      disablePadding: false,
      label: t('emp_skills.emp_last_name'),
    },
    {
      id: 'questionCategory.name',
      disablePadding: false,
      label: t('questionnaire_history.question_category'),
    },
    {
      id: 'reviewedAt',
      disablePadding: false,
      label: t('questionnaire_history.answer_date'),
    },
    {
      id: 'nextReviewAt',
      disablePadding: false,
      label: t('overdue_questionnaire.next_answer_date'),
    },
  ];

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;

    dispatch(
      getOverdueQuestionnairesRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );

    dispatch(
      getOverdueQuestionnairesCountRequest({
        filter: { where, include: newInclude },
      }),
    );
  };

  const handleUpdating = (ids: IdsArray) => {
    setSelectedOverdueReviewsIds(ids);
    setIsUpdateOverdueReviewsFormVisible(true);
  };

  React.useEffect(() => {
    dispatch(getOverdueQuestionnairesRequest(filterList));
    dispatch(getOverdueQuestionnairesCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EnhancedTable
        disableQsFilters
        isTableDataLoading={isDataLoading}
        data={list}
        count={count}
        selectIndex="id"
        tableName={t('overdue_questionnaire.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onUpdate={allowedToUpdateAnswers ? handleUpdating : undefined}
        defaultOrder="desc"
        defaultOrderBy="reviewedAt"
        defaultWhere={defaultFilter.where}
      />

      <UpdateOverdueReviews
        reviewsIds={selectedOverdueReviewsIds}
        isOpen={isUpdateOverdueReviewsFormVisible}
        onClose={() => setIsUpdateOverdueReviewsFormVisible(false)}
        filterList={filterList.filter ?? {}}
        filterCount={filterCount.filter}
      />
    </>
  );
};
