import { IUpdateWorkdays } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';

export const updateWorkdaysScheme: PartialSchema<IUpdateWorkdays[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: ['shiftId'],
    properties: {
      id: {
        type: 'number',
        default: NaN,
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      shiftId: {
        type: 'number',
        default: NaN,
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      mon: {
        nullable: true,
        type: 'boolean',
      },
      tue: {
        nullable: true,
        type: 'boolean',
      },
      wed: {
        nullable: true,
        type: 'boolean',
      },
      thu: {
        nullable: true,
        type: 'boolean',
      },
      fri: {
        nullable: true,
        type: 'boolean',
      },
      sat: {
        nullable: true,
        type: 'boolean',
      },
      sun: {
        nullable: true,
        type: 'boolean',
      },
    },
  },
};
