import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  IChecklistTaskModel,
  IFilter,
  IFilterData,
  IUpdateChecklistTaskData,
} from 'src/modules/types';
import {
  getChecklistComboboxDataRequest,
  getChecklistsRequest,
  getChecklistTasksRequest,
} from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { useTranslation } from 'react-i18next';
import { getChecklistCombobox } from 'src/modules/selectors/checklist.selector';
import { FormikComputedProps, FormikHelpers, FormikState } from 'formik';
import {
  useFetchShiftsCombobox,
  useFetchSitesCombobox,
  useFetchUsersCombobox,
  useSiteRelationInclusion,
} from '.';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { getSupervisorsAndGMsComboboxList } from 'src/modules/selectors/user';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { ComboBoxOption } from 'src/components/ComboBox';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import {
  createDailyRecurrence,
  createEveryWeekdayRecurrence,
  createRRuleFromStr,
  createWeeklyInCurrentDateRecurrence,
  rruleToName,
  updateRRuleStartDate,
} from '../rRuleWrapper';
import { concatDatesPartials } from '../helpers/recurringTasks';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import { CustomRecurrence } from 'src/pages/Checklist/components/forms/CustomRecurrence/CustomRecurrence';
import {
  parse,
  composeDate,
  parseISO,
  DATE_FORMAT,
  zonedTimeToUTCString,
} from 'src/modules/utils/dateWrapper';
import { Tooltip } from '@mui/material';
import {
  useChecklistFormTourConfig,
  useChecklistTaskFormTourConfig,
} from 'src/config/tours/checklist/task';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';

export const useChecklistTableInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => {
    // IMPORTANT!!!!!!! Don't change order of relations otherwise you might not get all you need
    return [
      {
        relation: 'user',
      },
      {
        relation: 'shift',
      },
      siteRelationInclusion,
    ];
  }, [siteRelationInclusion]);
};

export const useChecklistTaskInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => {
    return [
      { relation: 'recurringTasks', relationType: 'left' },
      {
        relation: 'checklist',
        scope: {
          include: [
            {
              ...siteRelationInclusion,
            },
          ],
        },
      },
    ];
  }, [siteRelationInclusion]);
};

export const useChecklistTableDefaultFilter = () => {
  const checklistTableInclusion = useChecklistTableInclusion();

  return React.useMemo(
    () => ({
      include: checklistTableInclusion,
      order: [`id desc`],
    }),
    [checklistTableInclusion],
  );
};

export const useChecklistTaskDefaultFilter = () => {
  const checklistTaskInclusion = useChecklistTaskInclusion();

  return React.useMemo(
    () => ({
      include: checklistTaskInclusion,
    }),
    [checklistTaskInclusion],
  );
};

export const useChecklistConstants = () => {
  return {
    height: '100%',
    eventMinHeight: 20,
    slotDuration: '00:30:00',
    defaultTimedEventDuration: '00:10:00',
    headerToolbar: {
      left: 'title',
      right: 'prev,next today dayGridMonth timeGridWeek timeGridDay list',
    },
  };
};
const defaultTaskData: Omit<IChecklistTaskModel, 'id' | 'recurringTasks'> = {
  title: '',
  startTime: '',
  endTime: '',
  done: false,
  checklistId: NaN,
};
export const useDefaultTaskData = () => {
  return defaultTaskData;
};

export const useFetchChecklistsEffect = (filter: IFilterData) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getChecklistsRequest({ filter }));
  }, [filter, dispatch]);
};

export const useFetchChecklistComboboxDataEffect = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getChecklistComboboxDataRequest({}));
  }, [dispatch]);
};

const defaultChecklistTasksFilter = {
  filter: { include: [{ relation: 'recurringTasks', relationType: 'left' }] },
};

export const useFetchChecklistTasksEffect = (
  filter: IFilter = defaultChecklistTasksFilter,
) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getChecklistTasksRequest(filter));
  }, [filter, dispatch]);
};

const useRecurrenceCombobox = <T extends AnyObject>(
  formik: FormikHelpers<T> & FormikState<T> & FormikComputedProps<T>,
) => {
  const { t } = useTranslation();

  const taskFormTourConfig = useChecklistTaskFormTourConfig();

  const [recurrencesList, setRecurrencesList] = React.useState<any>([]);

  const [
    isCustomRecurrenceModalOpen,
    setIsCustomRecurrenceModalOpen,
  ] = React.useState(false);

  const { startTime, endTime, recurrenceStartDate, recurrence } = formik.values;

  const startTimeUTCStr =
    startTime && zonedTimeToUTCString(parseISO(startTime));
  const endTimeUTCStr = endTime && zonedTimeToUTCString(parseISO(endTime));

  const CUSTOM_OPTION_INDEX = -1;

  const taskStartDate = parseISO(startTime);
  const calculatedRecurrenceStartDate = recurrenceStartDate
    ? composeDate(recurrenceStartDate, parse(DATE_FORMAT))
    : taskStartDate;

  const onCustomRecurrenceSubmit = (
    newRecurrenceComboboxOption: ComboBoxOption,
  ) => {
    formik.setFieldValue('recurrence', newRecurrenceComboboxOption.id);
    setRecurrencesList((existedList: any) => [
      ...existedList,
      newRecurrenceComboboxOption,
    ]);
    setIsCustomRecurrenceModalOpen(false);
  };

  const generateDefaultRecurrenceOptions = React.useCallback(() => {
    // Start date will be present in recurrenceStartDate when user update task and
    // it will be present in startTime when user create task
    const recurrenceStartDateTimeUTCStr = concatDatesPartials(
      recurrenceStartDate ?? startTimeUTCStr,
      startTimeUTCStr,
    );

    const customRecurrenceOption = { id: CUSTOM_OPTION_INDEX, name: 'custom' };

    return [
      createDailyRecurrence(recurrenceStartDateTimeUTCStr),
      createEveryWeekdayRecurrence(recurrenceStartDateTimeUTCStr),
      createWeeklyInCurrentDateRecurrence(recurrenceStartDateTimeUTCStr),
      customRecurrenceOption,
    ];
  }, [recurrenceStartDate, startTimeUTCStr, CUSTOM_OPTION_INDEX]);

  // Initialize recurrenceList
  React.useEffect(() => {
    const options = generateDefaultRecurrenceOptions();

    setRecurrencesList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Open custom recurrence window after appropriate option is selected
  React.useEffect(() => {
    if (recurrence === -1) {
      setIsCustomRecurrenceModalOpen(true);
    }
  }, [recurrence]);

  const onCustomRecurrenceClose = () => {
    setIsCustomRecurrenceModalOpen(false);
    // Insert initial value in recurrence field after user closed CustomRecurrence form
    formik.setFieldValue('recurrence', formik.initialValues.recurrence);
  };

  // Update recurrence options after fields it depends on have been changed
  React.useEffect(() => {
    // Options should not been changed after user has cleared recurrence field
    if (recurrence === null) {
      return;
    }

    const predefinedRecurrences = generateDefaultRecurrenceOptions();

    let recurrences: any = predefinedRecurrences;

    if (
      // When a task is updating and its recurrence custom one
      // we need to generate appropriate option in recurrence combobox
      recurrence &&
      recurrence !== CUSTOM_OPTION_INDEX &&
      !predefinedRecurrences.some(({ id }) => id === recurrence)
    ) {
      const rrule = createRRuleFromStr(recurrence);

      recurrences = [
        ...recurrences,
        { id: rrule.toString(), name: rruleToName(rrule) },
      ];
    }

    setRecurrencesList(recurrences);
  }, [
    recurrence,
    endTimeUTCStr,
    generateDefaultRecurrenceOptions,
    CUSTOM_OPTION_INDEX,
  ]);

  // Recalculate existed recurrence string after recurrence start date is updated
  React.useEffect(() => {
    const { recurrenceStartDate, recurrence } = formik.values;
    if (!recurrenceStartDate || !recurrence) {
      return;
    }

    const updatedRecurrence = updateRRuleStartDate(
      recurrence,
      composeDate(
        concatDatesPartials(recurrenceStartDate, startTimeUTCStr),
        parseISO,
      ),
    );

    formik.setFieldValue('recurrence', updatedRecurrence.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.recurrenceStartDate, startTimeUTCStr]);

  const selectedOption = recurrencesList.find(
    (listItem: any) => listItem.id === formik.values.recurrence,
  );
  const tooltip = selectedOption ? selectedOption.name : '';

  return (
    <>
      <Tooltip arrow title={tooltip}>
        <div>
          <FormikCombobox
            id="recurrence"
            formik={formik}
            options={recurrencesList as any}
            placeholder={t('common.repeat')}
            data-tour={taskFormTourConfig.tour.recurrence.target}
          />
        </div>
      </Tooltip>
      <CustomRecurrence
        taskStartDate={taskStartDate}
        recurrenceStartDate={calculatedRecurrenceStartDate}
        existedRecurrence={recurrence}
        isOpen={isCustomRecurrenceModalOpen}
        onClose={onCustomRecurrenceClose}
        customRecurrenceOptionIndex={CUSTOM_OPTION_INDEX}
        onSubmit={onCustomRecurrenceSubmit}
      />
    </>
  );
};

export const useTaskFormFields = <T extends AnyObject>(
  formik: FormikHelpers<T> & FormikState<T> & FormikComputedProps<T>,
  taskData?: IUpdateChecklistTaskData,
) => {
  const { t } = useTranslation();

  const checklists = useSelector(getChecklistCombobox);

  const recurrenceCombobox = useRecurrenceCombobox(formik);

  const taskFormTourConfig = useChecklistTaskFormTourConfig();

  return (
    <>
      <FormikTextField
        required
        fullWidth
        id="title"
        name="title"
        formik={formik}
        variant="outlined"
        label={t('checklist.task_title')}
        data-tour={taskFormTourConfig.tour.taskName.target}
      />
      <FormikCombobox
        required
        id="checklistId"
        formik={formik}
        options={checklists}
        placeholder={t('checklist.checklist_title')}
        data-tour={taskFormTourConfig.tour.checklistDropdown.target}
      />
      <FormikTextField
        fullWidth
        id="text"
        name="text"
        formik={formik}
        variant="outlined"
        label={t('checklist.task.text')}
        data-tour={taskFormTourConfig.tour.taskDescription.target}
      />
      <FormikDatetimepicker
        required
        fullWidth
        id="startTime"
        formik={formik}
        label={t('checklist.task.start_time')}
        data-tour={taskFormTourConfig.tour.taskStartTime.target}
      />
      <FormikDatetimepicker
        required
        fullWidth
        id="endTime"
        formik={formik}
        label={t('checklist.task.end_time')}
        data-tour={taskFormTourConfig.tour.taskEndTime.target}
      />
      {/* We can't use here formik.values.recurrence since this field will be hidden when user clears it*/}
      {taskData?.recurrence && (
        <FormikDatepicker
          fullWidth
          id="recurrenceStartDate"
          formik={formik}
          label={t('checklist.task.recurrence_start_date')}
          data-tour={taskFormTourConfig.tour.recurrenceStartDate.target}
        />
      )}

      {recurrenceCombobox}

      <FormikCheckbox
        id="done"
        name="done"
        formik={formik}
        label={t('common.done')}
        data-tour={taskFormTourConfig.tour.taskStatus.target}
      />
    </>
  );
};

export const useChecklistFormFields = <T extends AnyObject>(
  formik: FormikHelpers<T> & FormikState<T>,
) => {
  const { t } = useTranslation();

  const checklistFormTourConfig = useChecklistFormTourConfig();

  const fetchUsersCombobox = useFetchUsersCombobox();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  const supervisorsAnGms = useSelector(getSupervisorsAndGMsComboboxList);
  const shifts = useSelector(getShiftOptionsByGloballySites);

  const filteredSupervisorsAndGms = React.useMemo(() => {
    return supervisorsAnGms.filter((user) =>
      user.sites.includes(formik.values.siteId),
    );
  }, [supervisorsAnGms, formik.values.siteId]);

  const filteredShifts = React.useMemo(() => {
    return shifts.filter((user) => user.siteId === formik.values.siteId);
  }, [shifts, formik.values.siteId]);

  React.useEffect(() => {
    fetchShiftsCombobox();
    fetchSitesCombobox();
    fetchUsersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikTextField
        required
        fullWidth
        id="title"
        name="title"
        formik={formik}
        variant="outlined"
        label={t('common.title')}
        data-tour={checklistFormTourConfig.tour.checklistName.target}
      />
      <FormikCombobox
        required
        id="userId"
        formik={formik}
        options={filteredSupervisorsAndGms}
        placeholder={t('common.user')}
        data-tour={checklistFormTourConfig.tour.userDropdown.target}
      />
      <SitesComboBox
        id="siteId"
        required={true}
        formik={formik}
        placeholder={t('employees.site')}
        data-tour={checklistFormTourConfig.tour.siteDropdown.target}
      />
      <FormikCombobox
        required
        id="shiftId"
        formik={formik}
        options={filteredShifts}
        placeholder={t('common.shift')}
        data-tour={checklistFormTourConfig.tour.shiftDropdown.target}
      />
    </>
  );
};

export const useChecklistTableHeadCells = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () => [
      { id: 'title', disablePadding: false, label: t('common.title') },
      {
        id: 'user.firstName',
        disablePadding: false,
        label: t('checklist.user_first_name'),
      },
      {
        id: 'user.lastName',
        disablePadding: false,
        label: t('checklist.user_last_name'),
      },
      { id: 'site.name', disablePadding: false, label: t('common.site') },
      {
        id: 'shift.name',
        disablePadding: false,
        label: t('common.shift'),
      },
      {
        id: 'done',
        disablePadding: false,
        label: t('common.done'),
      },
    ],
    [t],
  );
};

export const useChecklistTableFilters = (): ITableFilter[] => {
  const { t } = useTranslation();

  const fetchUsersCombobox = useFetchUsersCombobox();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  const sites = useSelector(getSitesComboboxList);
  const supervisorsAnGms = useSelector(getSupervisorsAndGMsComboboxList);
  const shifts = useSelector(getShiftOptionsByGloballySites);

  React.useEffect(() => {
    fetchShiftsCombobox();
    fetchSitesCombobox();
    fetchUsersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    {
      name: 'title',
      label: t('common.title'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('common.site'),
      operator: 'eq',
      type: 'combobox',
      options: sites as ComboBoxOption[],
    },
    {
      name: 'user.id',
      label: t('common.user'),
      operator: 'eq',
      type: 'combobox',
      options: supervisorsAnGms as ComboBoxOption[],
    },
    {
      name: 'shift.id',
      label: t('common.shift'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'done',
      label: t('common.done'),
      operator: 'eq',
      type: 'checkbox',
    },
  ];
};
export const useChecklistTasksViewFilters = (): ITableFilter[] => {
  const { t } = useTranslation();
  const checklists = useSelector(getChecklistCombobox);

  return [
    {
      name: 'title',
      label: t('common.title'),
      operator: 'like',
    },
    {
      name: 'checklistId',
      label: t('common.site'),
      operator: 'eq',
      type: 'combobox',
      options: checklists as ComboBoxOption[],
    },
    {
      name: 'startTime',
      label: t('checklist.task.start_time'),
      operator: 'gte',
      type: 'datetime',
    },
    {
      name: 'endTime',
      label: t('checklist.task.end_time'),
      operator: 'lte',
      type: 'datetime',
    },
    {
      name: 'done',
      label: t('common.done'),
      operator: 'eq',
      type: 'checkbox',
    },
  ];
};
