import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api, i18n } from '../../utils';
import * as actions from '../../actions';
import {
  ISagaAction,
  ILoginRequest,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  IConfirmForgotPasswordRequest,
  IConfirmForgotPasswordResponse,
  IRegisterRequest,
  IRegisterResponse,
  IConfirmRegistrationRequest,
  AnyObject,
  ILocalStorageUser,
  ISagaActionBind,
} from '../../types';
import { clearUserFromStorage, saveUserToStorage } from '../../utils/user';
import {
  getNow,
  addSeconds,
  format,
  composeDate,
  EXPIRES_AT_FORMAT,
} from '../../utils/dateWrapper';
import { removeItemFromLocalStorage } from '../../utils/localStorageWrapper';

/**
 * Prepare user object to store to localStorage
 * @param response
 */
const prepareUserToStore = (response: AnyObject) => {
  const expires_at = composeDate(
    getNow(),
    addSeconds(Number(response.expiresIn)),
    format(EXPIRES_AT_FORMAT),
  );

  return {
    id: response.id,
    name: response.name,
    role: response.role,
    token: response.token,
    expires_in: response.expires_in,
    expires_at,
  };
};

/**
 * Login a user
 */
export const loginRequestSaga = function* (
  action: ISagaAction<ILoginRequest>,
): SagaIterator {
  try {
    const response: AnyObject = yield call(Api.Auth.login, action.payload);
    const user_to_store: ILocalStorageUser = yield call(
      prepareUserToStore,
      response,
    );
    // save user data to localStore
    yield call(saveUserToStorage, { auth: user_to_store });
    yield put(actions.loginSuccess(user_to_store));
    // if a user is logged in successfully, try to fetch his profile data
    yield put(actions.meRequest());
    yield put(actions.myPolicyRequest());
    yield put(actions.getPageSettingRequest());
  } catch (e) {
    yield put(actions.loginFailed());
  }
};

/**
 * Logout a user
 */
export const logoutRequestSaga = function* (): SagaIterator {
  yield put(actions.logoutSuccess());
  yield call(clearUserFromStorage);
  yield call(removeItemFromLocalStorage, 'pageSetting');
};

/**
 * Send an email to restore user password
 */
export const forgotPasswordRequestSaga = function* (
  action: ISagaAction<IForgotPasswordRequest>,
): SagaIterator {
  try {
    const response: IForgotPasswordResponse = yield call(
      Api.Auth.forgotPassword,
      action.payload,
    );
    yield put(actions.forgotPasswordSuccess(response));
  } catch (e) {
    yield put(
      actions.forgotPasswordFailed(i18n.et('auth', (e as any).message)),
    );
  }
};

/**
 * Confirm restoring password
 */
export const confirmForgotPasswordRequestSaga = function* (
  action: ISagaAction<IConfirmForgotPasswordRequest>,
): SagaIterator {
  try {
    const response: IConfirmForgotPasswordResponse = yield call(
      Api.Auth.confirmForgotPassword,
      action.payload,
    );
    yield put(actions.confirmForgotPasswordSuccess(response));
  } catch (e) {
    yield put(actions.confirmForgotPasswordFailed());
  }
};

/**
 * Register a new user
 */
export const registerRequestSaga = function* (
  action: ISagaAction<IRegisterRequest>,
): SagaIterator {
  try {
    const response: IRegisterResponse = yield call(
      Api.Auth.register,
      action.payload,
    );
    yield put(actions.registerSuccess(response));
  } catch (e) {
    yield put(actions.registerFailed());
  }
};

/**
 * Confirm registration
 */
export const confirmRegistrationRequestSaga = function* (
  action: ISagaAction<IConfirmRegistrationRequest>,
): SagaIterator {
  try {
    const response: IConfirmForgotPasswordResponse = yield call(
      Api.Auth.confirmRegistration,
      action.payload,
    );
    yield put(actions.confirmRegistrationSuccess(response));
  } catch (e) {
    yield put(actions.confirmRegistrationFailed());
  }
};

/**
 * Extend current session
 */
export const extendSessionRequestSaga = function* (): SagaIterator {
  try {
    const response: AnyObject = yield call(Api.User.extendSession);
    const user_to_store: ILocalStorageUser = prepareUserToStore(response);
    // save user data to localStore
    saveUserToStorage({ auth: user_to_store });
    yield put(actions.loginSuccess(user_to_store));
    yield put(actions.extendSessionSuccess(user_to_store));
  } catch (e) {
    yield put(
      actions.extendSessionFailed(
        i18n.t('error.auth.' + (e as any).message, (e as any).message),
      ),
    );
  }
};

export const autobind: ISagaActionBind<any>[] = [
  // login a user
  { action: actions.loginRequest, saga: loginRequestSaga },
  // logout a user
  { action: actions.logoutRequest, saga: logoutRequestSaga },
  // send an email to restore a password
  { action: actions.forgotPasswordRequest, saga: forgotPasswordRequestSaga },
  // confirm restoring password
  {
    action: actions.confirmForgotPasswordRequest,
    saga: confirmForgotPasswordRequestSaga,
  },
  // register a new user
  { action: actions.registerRequest, saga: registerRequestSaga },
  // confirm registration
  {
    action: actions.confirmRegistrationRequest,
    saga: confirmRegistrationRequestSaga,
  },
  // extend current session
  { action: actions.extendSessionRequest, saga: extendSessionRequestSaga },
];
