import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getStaffingProvidersByIds,
  getStaffingProvidersComboboxList,
} from '../../selectors/staffingProvider';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getStaffingProviderComboboxListRequest,
  getStaffingProviderListRequest,
} from '../../actions/staffingProvider';

export const staffingProviderComboboxFilter = {
  filter: {
    fields: {
      id: true,
      staffingProvider: true,
    },
  },
};

/**
 * A custom hook to fetch staffingProviders from store if they exist otherwise to make a request to fetch needed staffingProviders from
 * the server
 */
export const useFetchStaffingProviders = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch staffingProviders list from store
  const staffingProviders = useSelector(
    (state) => getStaffingProvidersByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(staffingProviders)) {
      dispatcher(
        getStaffingProviderListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch staffingProvider combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchStaffingProvidersCombobox = () => {
  const dispatcher = useDispatch();
  // fetch combobox list from store
  const combobox = useSelector(getStaffingProvidersComboboxList, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatcher(
        getStaffingProviderComboboxListRequest(staffingProviderComboboxFilter),
      );
    }
  };
};

export const useStaffingProvidersCombobox = () => {
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  useEffect(() => {
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch staffing providers list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );

  return React.useMemo(
    () => ({
      staffingProviders,
    }),
    [staffingProviders],
  );
};
