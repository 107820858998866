import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getPointTypesByIds,
  getPointTypesComboboxList,
} from '../../selectors/pointType';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getPointTypeComboboxListRequest,
  getPointTypeListRequest,
} from '../../actions/pointType';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';

export const pointTypeInclusions = [
  {
    relation: 'site',
  },
];

export const pointTypeComboboxFilter = {
  filter: {
    fields: {
      id: true,
      reason: true,
    },
    include: pointTypeInclusions,
  },
};

const usePointTypesComboboxFilter = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          reason: true,
        },
        include: [siteInclusion],
      },
    }),
    [siteInclusion],
  );
};

/**
 * A custom hook to fetch pointTypes from store if they exist otherwise to make a request to fetch needed pointTypes from
 * the server
 */
export const useFetchPointTypesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch pointTypes list from store
  const pointTypes = useSelector(
    (state) => getPointTypesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(pointTypes)) {
      dispatcher(
        getPointTypeListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: pointTypeInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch pointType combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchPointTypesCombobox = () => {
  const dispatcher = useDispatch();
  const pointTypeComboboxFilter = usePointTypesComboboxFilter();

  const dispatchGetPointTypesComboboxListRequest = React.useCallback(() => {
    dispatcher(getPointTypeComboboxListRequest(pointTypeComboboxFilter));
  }, [pointTypeComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetPointTypesComboboxListRequest();
  }, [dispatchGetPointTypesComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getPointTypesComboboxList, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatcher(getPointTypeComboboxListRequest(pointTypeComboboxFilter));
    }
  };
};

export const usePointTypesDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteInclusion],
    }),
    [siteInclusion],
  );
};
