import React, { useEffect, useState } from 'react';
import Highcharts, { LegendOptions, SeriesColumnOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

exporting(Highcharts);

interface IProps {
  categories: string[];
  data: SeriesColumnOptions[];
  title?: string;
  subtitle?: string;
  yAxis?: string;
  enableDataLabels?: boolean;
  legend?: LegendOptions;
}

const ColumnChart = (props: IProps) => {
  const [data, setData] = useState<SeriesColumnOptions[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  // we need to keep data in the local state in order to to be able to update the chart when we fetch data from the server
  useEffect(() => {
    setData(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    setCategories(props.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categories]);

  const options: Highcharts.Options = {
    chart: {
      type: 'spline',
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: props.title ?? undefined,
    },
    subtitle: {
      text: props.subtitle ?? undefined,
    },
    yAxis: {
      min: 0,
      title: {
        text: props.yAxis ?? undefined,
      },
      allowDecimals: false,
    },
    xAxis: {
      categories,
    },
    legend: props.legend ?? {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: props.enableDataLabels ?? true,
        },
      },
    },
    series: data,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 768,
          },
          chartOptions: {
            legend: {
              align: 'left',
              verticalAlign: 'top',
              layout: 'horizontal',
            },
          },
        },
      ],
    },
    exporting: {
      enabled: true,
    },
  };

  return (
    <Box sx={{ minWidth: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Box>
  );
};

export default ColumnChart;
