import { IUpdateShift } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { createShiftScheme } from './create-shift.scheme';

export const updateShiftsScheme: PartialSchema<IUpdateShift[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createShiftScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createShiftScheme.properties,
    },
  },
};
