import React from 'react';
import Grid from '@mui/material/Grid';

import { Api } from '../../../modules/utils';
import { Player } from './Player';
import { IVideoItem } from '../../../modules/types/guide';

export const VideoList = () => {
  const [videoList, setVideList] = React.useState<Array<IVideoItem>>([]);

  React.useEffect(() => {
    Api.Guide.videoUrlList().then((videoData) => {
      setVideList(videoData);
    });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        '& video': {
          display: 'block',
        },
      }}
    >
      {videoList.map(({ url, title }) => (
        <Grid key={url} item xl={3} lg={4} md={6} xs={12}>
          <Player title={title} url={url} />
        </Grid>
      ))}
    </Grid>
  );
};
