import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';

export const updateDesiredEmployeesScheme: JSONSchemaType<
  { id: number; desiredEmployeesNumber: number }[]
> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: ['id', 'desiredEmployeesNumber'],
    properties: {
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      desiredEmployeesNumber: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
