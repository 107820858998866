import React from 'react';
import { Box, Button, Typography, TypographyProps } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface FilesPreviewCarouseImageWithFallbackProps {
  src: string;
  alt?: string;
  typographyProps?: TypographyProps;
}

export const FilesPreviewCarouseImageWithFallback = ({
  src,
  alt = 'Image preview',
  typographyProps,
}: FilesPreviewCarouseImageWithFallbackProps) => {
  const { t } = useTranslation();

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const handleDownload = (url: string) => {
    if (iframeRef.current) {
      iframeRef.current.src = url;
    }
  };

  const isImage = (url: string) => /\.(jpg|jpeg|png|gif)$/i.test(url);

  const isPDF = (url: string) => /\.pdf$/i.test(url);

  const isWord = (url: string) => /\.(doc|docx)$/i.test(url);

  const isExcel = (url: string) => /\.(xls|xlsx)$/i.test(url);

  if (isImage(src)) {
    return (
      <Box
        component="img"
        alt={alt}
        src={src}
        sx={{
          maxWidth: window.innerWidth - 300,
          maxHeight: window.innerHeight - 300,
          objectFit: 'contain',
        }}
      />
    );
  }

  if (isPDF(src)) {
    return (
      <Box
        component="iframe"
        src={src}
        style={{ width: '100%', height: '500px' }}
        title={`pdf-preview`}
      />
    );
  }

  if (isWord(src) || isExcel(src)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography level="text_md" {...typographyProps}>
          {t('questionnaire_history.file_preview_not_available_with_download')}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button onClick={() => handleDownload(src)}>
            {t('common.download')}
          </Button>
        </Box>

        <iframe ref={iframeRef} style={{ display: 'none' }} />
      </Box>
    );
  }

  return (
    <Typography level="text_md" {...typographyProps}>
      {t('questionnaire_history.file_preview_not_available')}
    </Typography>
  );
};
