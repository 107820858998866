import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  exportRoleRequest,
  getRoleCountRequest,
  getRoleListRequest,
} from '../../modules/actions';
import {
  getRoleCount,
  getRoleRefreshKey,
  getRoleTable,
} from '../../modules/selectors/role';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useRolesDefaultFilters,
  useSearch,
  useCreateExportProps,
  useFilter,
  useBrowserHistoryFunctions,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, useHasUserAccessToAction } from '../../config';
import { NoIcon, YesIcon } from '../../components/Icons';
import { additionalUserProperties } from '../../config/userFields';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const RolesList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.role.update.id,
  );

  const defaultFilter = useRolesDefaultFilters();
  // fetch Supervisor list
  const list = useSelector(getRoleTable, shallowEqual);

  const exportProps = useCreateExportProps(exportRoleRequest);
  const count = useSelector(getRoleCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('roles.name') },
    { id: 'key', disablePadding: false, label: t('roles.key') },
    { id: 'isBase', disablePadding: false, label: t('roles.isBase') },
    { id: 'level', disablePadding: false, label: t('roles.level') },
    ...additionalUserProperties.map((i) => ({
      id: i,
      disablePadding: false,
      label: t(`roles.${i}`),
    })),
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('common.name'),
      operator: 'like',
    },
    {
      name: 'key',
      label: t('roles.key'),
      operator: 'like',
    },
    {
      name: 'isBase',
      label: t('roles.isBase'),
      type: 'checkbox',
      operator: 'eq',
    },
  ];

  // make request to fetch roles when component is mounted
  useEffect(() => {
    dispatcher(getRoleListRequest(filterList));
    // get total count
    dispatcher(getRoleCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getRoleListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getRoleCountRequest({ filter: { where } }));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: '/roles/update', search });
  };

  const computedList = useMemo(
    () =>
      list.map((item) => ({
        ...item,
        ...['isBase', ...additionalUserProperties].reduce((acc, cur) => {
          acc[cur] = !!item[cur] ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          );
          return acc;
        }, {}),
      })),
    // eslint-disable-next-line
    [additionalUserProperties, list],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={computedList}
          count={count}
          selectIndex="id"
          tableName={t('roles.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.Role}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function RolesListRefreshable() {
  return <RolesList key={useSelector(getRoleRefreshKey)} />;
}
