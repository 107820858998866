import { filter, isEmpty, map, memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateOptiturnCredentials } from '../types';

export const getOptiturnCredentialsRefreshKey = (state: IStoreState) =>
  state.optiturnCredentials.refreshKey;

export const getOptiturnCredentialsData = (state: IStoreState) =>
  state.optiturnCredentials;

/**
 * Get server error
 * @param optiturnCredentials - State optiturnCredentials
 */
export const getServerError = ({ optiturnCredentials }: IStoreState) =>
  optiturnCredentials.error;

/**
 * Get optiturnCredentials list
 * @param optiturnCredentials - State optiturnCredentials
 */
export const getOptiturnCredentialsList = ({
  optiturnCredentials,
}: IStoreState) => optiturnCredentials.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param optiturnCredentials - State optiturnCredentials
 */
export const getOptiturnCredentialsTable = createSelector(
  getOptiturnCredentialsList,
  (optiturnCredentials) =>
    map(filter(optiturnCredentials, (s) => !isEmpty(s.site))),
);

/**
 * Get optiturnCredentials by array of ids
 * @param optiturnCredentials - State optiturnCredentials
 */
export const getOptiturnCredentialsByIds = createSelector(
  getOptiturnCredentialsList,
  (optiturnCredentials) =>
    memoize((ids: IdsArray) => {
      const initial: IUpdateOptiturnCredentials[] = [];
      return reduce(
        optiturnCredentials,
        (acc, cur) => {
          if (ids.includes(`${cur.id}`)) {
            // omit columns we should not request with
            const optiturnCredentialsData = omit(cur, ['site']);
            acc.push(optiturnCredentialsData);
          }
          return acc;
        },
        initial,
      );
    }),
);

/**
 * Get optiturnCredentials count
 * @param optiturnCredentials - State optiturnCredentials
 */
export const getOptiturnCredentialsCount = ({
  optiturnCredentials,
}: IStoreState) => optiturnCredentials.count;
