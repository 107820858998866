import React from 'react';

import { ExceptionsFromProductionReport } from 'src/components/Reports/ExceptionsFromProductionReport';
import { clients } from 'src/modules/constants';

const headCellsToOmit = ['id', 'siteId', 'shiftId', 'date', 'endDate'];

interface IProps {
  siteId?: number;
}

export const OptoroExceptionsFromProduction: React.FC<IProps> = ({
  siteId,
}) => (
  <ExceptionsFromProductionReport
    siteId={siteId}
    client={clients.optoro}
    headCellsToOmit={headCellsToOmit}
  />
);
