import { IWhere } from 'src/modules/types';
import Api from '.';
import {
  IActualVsNeededChartResponseDataItem,
  IHRDashboardEmployeeTurnover,
  ITerminationReasonBySiteAndShiftResponseDataItem,
  ITerminationReasonBySiteResponseDataItem,
} from 'src/modules/types/dashboard';

export const fetchHRDashboardActualVsNeededChartData = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<IActualVsNeededChartResponseDataItem>
  >(`recruiting-dashboard/active-vs-needed`, { filter: { where } });

  return response;
};

export const fetchHRDashboardTerminationReasonsBySite = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<ITerminationReasonBySiteResponseDataItem>
  >(`employees/termination-reason-by-site`, { filter: { where } });

  return response;
};

export const fetchHRDashboardTerminationReasonsBySiteAndShift = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<ITerminationReasonBySiteAndShiftResponseDataItem>
  >(`employees/termination-reason-by-site-and-shift`, { filter: { where } });

  return response;
};

export const fetchHRDashboardEmployeeTurnover = async (where?: IWhere) => {
  const response = await Api.Request.get<Array<IHRDashboardEmployeeTurnover>>(
    `turnover/by-site-and-shift`,
    { filter: { where } },
  );

  return response;
};
