import React from 'react';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { useFilter } from './useFilter';
import * as actions from '../../actions';
import { i18n } from '../i18n';
import { useValidate } from './useValidate';
import { syncImportingScheme } from '../../schemes/cardinal-health-importing-sync.scheme';
import Api from '../Api';

import { ITableSyncProps } from '../../../components/EnhancedTable';
import { EnhancedTableToolbarProps } from '../../../components/EnhancedTable/EnhancedTableToolbar';

import { ISyncImporting } from '../../types/cardinal-health';
import { IProductionDailyUphBase } from '../../types/productionUphReports';
import {
  ExportType,
  ICountResponse,
  IExportColumnsData,
  IExportParams,
  IExportResponse,
  IFilter,
  IFilterData,
  IWhere,
  ListWithSummary,
} from '../../types';

type UseFetchReportDataParameters = {
  apiCall: {
    list: (
      filter: IFilter,
    ) => Promise<ListWithSummary<IProductionDailyUphBase>>;
    count: (filter: IWhere) => Promise<ICountResponse>;
    export: (exportData: IExportParams) => Promise<IExportResponse>;
  };
  defaultFilter: IFilterData;
};

type UseFetchReportDataResult = {
  list: ListWithSummary<IProductionDailyUphBase>;
  count: ICountResponse;
  loading: boolean;
  onSync: (props: ITableSyncProps) => void;
  exportProps: EnhancedTableToolbarProps['exportProps'];
};

const initialFetchedTableData = {
  list: {
    items: [],
    summary: [],
  },
  count: { count: 0 },
  loading: true,
};

const FUNCTION_NAME = 0;
const FILE_TYPE = 1;
const exportFileTypes: [string, ExportType][] = [
  ['onExportCSV', 'csv'],
  ['onExportPDF', 'pdf'],
  ['onExportExcel', 'xls'],
  ['onExportEmail', 'email'],
];

export const useFetchReportData = ({
  apiCall,
  defaultFilter,
}: UseFetchReportDataParameters): UseFetchReportDataResult => {
  const dispatcher = useDispatch();
  const [fetchedTableData, setFetchedTableData] = React.useState<{
    list: ListWithSummary<IProductionDailyUphBase>;
    count: ICountResponse;
    loading: boolean;
  }>(initialFetchedTableData);

  const { filterList, filterCount } = useFilter(defaultFilter);

  const fetchReport = React.useCallback(
    async (listFilter: IFilter, countFilter: IWhere) => {
      try {
        const [list, count] = await Promise.all([
          apiCall.list(listFilter),
          apiCall.count(countFilter),
        ]);

        setFetchedTableData({ list, count, loading: false });
      } catch (e) {
        setFetchedTableData((current) => ({ ...current, loading: false }));
        console.error('Error on report fetch', (e as any).message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    fetchReport(filterList, filterCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    fetchReport(
      {
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      },
      { filter: { where } },
    );
  };

  const exportProps = () =>
    exportFileTypes.reduce((acc, exportType) => {
      acc[exportType[FUNCTION_NAME]] = async (
        columnsData: IExportColumnsData,
      ) => {
        const { content, fileName }: IExportResponse = await apiCall.export({
          exportType: exportType[FILE_TYPE],
          ...columnsData,
        });

        if (fileName) {
          fileDownload(content, fileName);
        }

        if (exportType[FILE_TYPE] === 'email') {
          dispatcher(
            actions.addProcessStatus({
              variant: 'success',
              message: i18n.t('export.email.success'),
            }),
          );
        }
      };
      return acc;
    }, {});

  return {
    ...fetchedTableData,
    onSync,
    exportProps: React.useMemo(exportProps, [apiCall, dispatcher]),
  };
};

const syncImportingInitialValues: ISyncImporting = {
  siteUuid: [],
  dateStart: '',
  dateEnd: '',
};

export const useSyncImportingData = () => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleModal = React.useCallback(
    () => setOpen((current) => !current),
    [],
  );

  const validate = useValidate<ISyncImporting>(syncImportingScheme);
  const formik = useFormik<ISyncImporting>({
    initialValues: syncImportingInitialValues,
    validate,
    onSubmit: (data: ISyncImporting) => {
      Api.CardinalHealthImportSynchronization(data).catch((e) =>
        console.error((e as any).message),
      );
      handleModal();
    },
  });

  return {
    open,
    formik,
    handleModal,
  };
};
