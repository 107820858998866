import { createAction } from 'redux-act';
import {
  ICountResponse,
  IRecruitingDashboardDetailsModel,
  IRecruitingDashboardDetailsRequest,
} from '../../types';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Fetch recruiting dashboards details actions
export const getRecruitingDashboardDetailsListRequest = createAction<
  IRecruitingDashboardDetailsRequest
>('Make request to fetch recruiting dashboard details list');
export const getRecruitingDashboardDetailsListSuccess = createAction<
  IRecruitingDashboardDetailsModel[]
>('Recruiting dashboards details list has been fetched successfully');
export const getRecruitingDashboardDetailsListFailed = createAction(
  'Cannot fetch recruiting dashboard details list',
);

// Counting recruiting dashboards details actions
export const getRecruitingDashboardDetailsCountRequest = createAction<
  IRecruitingDashboardDetailsRequest
>('Make request to get recruiting dashboard details count');
export const getRecruitingDashboardDetailsCountSuccess = createAction<
  ICountResponse
>('Recruiting dashboard details count has been fetched successfully');
export const getRecruitingDashboardDetailsCountFailed = createAction(
  'Cannot get recruiting dashboard details count',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('RecruitingDashboardDetails').takeExportActions();

export const exportRecruitingDashboardDetailsRequest = exportReportRequest;
export const exportRecruitingDashboardDetailsSuccess = exportReportSuccess;
export const exportRecruitingDashboardDetailsFailed = exportReportFailed;
