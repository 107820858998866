import { ISagaActionBind } from '../../types';
import { IProductionWeeklyCpuDetailedModel } from '../../types/productionCpuReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyCpuDetailedListRequest,
    saga: createListSaga<IProductionWeeklyCpuDetailedModel>({
      apiCall: Api.CardinalHealthWeeklyCpuDetailed.list,
      actionSuccess: actions.getCardinalHealthWeeklyCpuDetailedListSuccess,
      actionFailed: actions.getCardinalHealthWeeklyCpuDetailedListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyCpuDetailedCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyCpuDetailed.count,
      actionSuccess: actions.getCardinalHealthWeeklyCpuDetailedCountSuccess,
      actionFailed: actions.getCardinalHealthWeeklyCpuDetailedCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyCpuDetailedRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyCpuDetailed.export,
      actionSuccess: actions.exportCardinalHealthWeeklyCpuDetailedSuccess,
      actionFailed: actions.exportCardinalHealthWeeklyCpuDetailedFailed,
    }),
  },
];
