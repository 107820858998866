import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreatePoint,
  IdsArray,
  IFilter,
  IPointModel,
  IRemovePoint,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new point
 */
export const createPointRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ICreatePoint>>): SagaIterator {
  try {
    const response: IPointModel = yield call(Api.Point.create, data);
    yield put(actions.createPointSuccess(response));

    yield put(actions.getPointListRequest({ filter: filters.list }));
    yield put(actions.getPointCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'points.point_successfully_created',
      }),
    );
  } catch (e) {
    yield put(actions.createPointFailed());
  }
};

/**
 * Get point list
 */
export const getPointListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IPointModel[] = yield call(Api.Point.list, action.payload);
    yield put(actions.getPointListSuccess(response));
  } catch (e) {
    yield put(actions.getPointListFailed());
  }
};

/**
 * Get point count
 */
export const getPointCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Point.count,
      action?.payload,
    );
    yield put(actions.getPointCountSuccess(response));
  } catch (e) {
    yield put(actions.getPointCountFailed());
  }
};

/**
 * Delete points
 */
export const deletePointRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Point.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getPointListRequest({ filter: filters.list }));
    yield put(actions.getPointCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'points.points_successfully_deleted'
            : 'points.point_successfully_deleted',
      }),
    );
  } catch (e) {
    yield put(actions.deletePointFailed());
  }
};

export const removePointsRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<Array<IRemovePoint[]>>>): SagaIterator {
  try {
    for (const formData of data) {
      yield call(Api.Point.remove, formData);
    }

    yield put(actions.getPointListRequest({ filter: filters.list }));
    yield put(actions.getPointCountRequest({ filter: filters.count }));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'points.points_successfully_removed'
            : 'points.point_successfully_removed',
      }),
    );
  } catch (e) {
    yield put(actions.removePointsFailed());
  }
};

/**
 * Bulk update points
 */
export const updatePointsRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<Array<IPointModel>>>): SagaIterator {
  try {
    yield call(Api.Point.bulkUpdate, data);

    yield put(actions.getPointListRequest({ filter: filters.list }));
    yield put(actions.getPointCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'points.points_successfully_updated'
            : 'points.point_successfully_updated',
      }),
    );
  } catch (e) {
    yield put(actions.updatePointsFailed());
  }
};

/**
 * Get combobox list
 */
export const getPointComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IPointModel>[] = yield call(
      Api.Point.list,
      action.payload,
    );
    yield put(actions.getPointComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getPointComboboxListFailed());
  }
};

export const getPointsReportSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const { items } = yield call(Api.Point.pointsReport, action.payload);
    yield put(actions.getPointsReportSuccess(items));
  } catch (e) {
    yield put(actions.getPointsReportCountFailed());
  }
};

export const getPointsReportCountSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response = yield call(Api.Point.pointsReportCount, action.payload);
    yield put(actions.getPointsReportCountSuccess(response));
  } catch (e) {
    yield put(actions.getPointsReportCountFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Point.export,
  actionFailed: actions.exportPointFailed,
  actionSuccess: actions.exportPointSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportPointRequestSaga = exportRequestSaga;

const { exportRequestSaga: exportRequestReportSaga } = new ExportSagaCreator({
  apiCall: Api.Point.pointsReportExport,
  actionFailed: actions.exportPointFailed,
  actionSuccess: actions.exportPointSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportPointsReportRequestSaga = exportRequestReportSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createPointRequest, saga: createPointRequestSaga },
  { action: actions.getPointListRequest, saga: getPointListRequestSaga },
  { action: actions.getPointCountRequest, saga: getPointCountRequestSaga },
  { action: actions.deletePointRequest, saga: deletePointRequestSaga },
  { action: actions.removePointsRequest, saga: removePointsRequestSaga },
  // bulk updating points
  { action: actions.updatePointsRequest, saga: updatePointsRequestSaga },
  {
    action: actions.getPointComboboxListRequest,
    saga: getPointComboboxListRequestSaga,
  },
  { action: actions.exportPointRequest, saga: exportRequestSaga },
  { action: actions.exportPointsReportRequest, saga: exportRequestReportSaga },
  { action: actions.getPointsReportRequest, saga: getPointsReportSaga },
  {
    action: actions.getPointsReportCountRequest,
    saga: getPointsReportCountSaga,
  },
];
