import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  addProcessStatus,
  getSiteImportingSettingsListRequest,
} from 'src/modules/actions';
import { Api, useValidate } from '../../modules/utils';
import { IUploadProductionData } from '../../modules/types';
import { FormActions } from '../Form/FormActions';
import { getSiteImportingSettingsTable } from 'src/modules/selectors/site-importing-settings.selector';

import CreateFormLayout from '../Layout/CreateFormLayout';
import ProductionDataUploader from 'src/components/ProductionDataUploader';
import { uploadProductionDataScheme } from 'src/modules/schemes/productions/upload-production-data.scheme';
import SitesByClientComboBox from '../Formik/comboboxes-with-entities/SitesByClientCombobox';
import { ClientKey } from '../../modules/constants';

const initialValues = {
  siteId: NaN,
  file: null,
};

export const ProductionDataFileUploader: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const clientKey = React.useMemo(() => pathname?.split('/')[1] as ClientKey, [
    pathname,
  ]);

  const validate = useValidate(uploadProductionDataScheme);
  const importingSettingsList = useSelector(getSiteImportingSettingsTable);

  const [isDataProcessing, setIsDataProcessing] = React.useState(false);

  const formik = useFormik<IUploadProductionData>({
    initialValues,
    validate,
    onSubmit: async (data) => {
      const formData = new FormData();

      formData.append('file', data.file as File);
      formData.append('siteId', String(data.siteId));

      setIsDataProcessing(true);
      try {
        await Api.Production.uploadProduction(formData);

        setIsDataProcessing(false);

        formik.resetForm();

        dispatch(
          addProcessStatus({
            variant: 'success',
            message: t('productions.file_upload_started'),
          }),
        );
      } catch (err) {
        setIsDataProcessing(false);
      }
    },
  });

  const onUploadFile = (file: File | null) => {
    formik.setFieldValue('file', file);
  };

  React.useEffect(() => {
    dispatch(getSiteImportingSettingsListRequest({}));
  }, [dispatch]);

  if (!importingSettingsList.length) {
    return null;
  }

  return (
    <>
      <CreateFormLayout
        cardTitle={t('productions.upload_production_data_title')}
        handleFormSubmit={formik.handleSubmit as any}
        fields={formik.values}
        isFormValid={formik.isValid}
      >
        <SitesByClientComboBox
          id="siteId"
          required={true}
          formik={formik}
          clientKey={clientKey}
          placeholder={t('productions.site.name')}
        />
        <ProductionDataUploader
          onChange={onUploadFile}
          file={formik.values.file}
          errorMessage={formik.touched.file ? formik.errors.file : undefined}
        />

        <FormActions
          isLoading={isDataProcessing}
          submitBtnTitle={t('common.upload')}
        />
      </CreateFormLayout>
    </>
  );
};
