import React from 'react';
import { Sheet, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useMainLayoutContext } from '../../../MainLayoutContext';

interface ISideBarMainMenuBannerProps {
  text: string;
  title?: string;
}

export const SideBarMainMenuBanner = ({
  text,
  title,
}: ISideBarMainMenuBannerProps) => {
  const { t } = useTranslation();

  const { sideBar } = useMainLayoutContext();

  return (
    <Sheet sx={{ px: `${sideBar.padding}px`, backgroundColor: 'inherit' }}>
      <Sheet
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          px: 1,
          py: 2.5,
          borderRadius: 8,
          backgroundColor: 'gray.700',
        }}
      >
        <Typography fontWeight="medium" textColor="base.white" level="text_sm">
          {title ?? t('common.no_data')}
        </Typography>
        <Typography
          textColor="gray.200"
          level="text_sm"
          textAlign="center"
          fontWeight="regular"
        >
          {text}
        </Typography>
      </Sheet>
    </Sheet>
  );
};
