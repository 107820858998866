import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';
import {
  AnyObject,
  IKpiSettings,
  KpiBasisOptions,
  KpiSettingsConfig,
} from '../../types';
import { paths } from '../../../config';

const getSettingsFetchParameters = (siteId?: number) => {
  const filter = { filter: { where: { siteId: { eq: siteId } } } };
  const queryKey = getQueryKey(paths.KPI_SETTINGS, filter);

  return { filter, queryKey };
};

export const useKpiSettingsData = (siteId?: number) => {
  const { filter, queryKey } = getSettingsFetchParameters(siteId);

  const { data, isLoading, error } = useQuery(
    queryKey,
    () => Api.Kpi.getSettings(filter),
    { enabled: Boolean(siteId), retry: false, cacheTime: 0 },
  );

  const config = React.useMemo<IKpiSettings>(
    () => data ?? ({} as IKpiSettings),
    [data],
  );

  return {
    config,
    isLoading,
    error,
  };
};

export const useKpiSettings = (siteId?: number) => {
  const { config, isLoading } = useKpiSettingsData(siteId);

  return {
    kpiSettingsId: config.id,
    isLoading,
    settings: config.settings,
    week: config.week,
    month: config.month,
    weekCount: config.weekCount,
    monthCount: config.monthCount,
  };
};

export const useKpiSettingsForm = (siteId?: number) => {
  const { queryKey } = getSettingsFetchParameters(siteId);
  const queryClient = useQueryClient();

  // get data
  const {
    kpiSettingsId,
    settings,
    isLoading,
    week,
    weekCount,
    monthCount,
    month,
  } = useKpiSettings(siteId);

  // get mutation functions for sending data to server
  const { mutate: createSettings, isLoading: createLoading } = useMutation(
    (data: AnyObject) => Api.Kpi.createSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
  const { mutate: updateSettings, isLoading: updateLoading } = useMutation(
    (data: AnyObject) => Api.Kpi.updateSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  // submit function
  const sendToServer = (
    settings: KpiSettingsConfig,
    kpiBasisOptions?: KpiBasisOptions,
  ) => {
    if (kpiSettingsId) {
      const data = {
        id: kpiSettingsId,
        settings,
        ...kpiBasisOptions,
      };

      updateSettings(data);
    } else {
      const data = {
        siteId,
        settings,
        ...kpiBasisOptions,
      };

      createSettings(data);
    }
  };

  const sendLoading = createLoading || updateLoading;

  return {
    settings,
    isLoading,
    sendToServer,
    sendLoading,
    week,
    weekCount,
    monthCount,
    month,
  };
};
