import React, { useEffect, useMemo, useState } from 'react';

import { map, get, find, isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getBaselineList,
  getBaselineRefreshKey,
} from '../../modules/selectors/baseline.selector';
import {
  deleteBaselineRequest,
  exportBaselineRequest,
  getBaselineListRequest,
} from '../../modules/actions';
import EnhancedTable, { ITableSyncProps } from '../../components/EnhancedTable';
import {
  baselinesInclusions,
  useFetchMetatagsByIds,
  useSearch,
  useCreateExportProps,
} from '../../modules/utils/hooks';
import { Grid } from '@mui/material';
import {
  ModelsToDelete,
  MUI_SIZE_12,
  MUI_SIZE_2,
  paths,
  useHasUserAccessToAction,
} from '../../config';
import { getSitesComboboxList } from '../../modules/selectors/site';
import { AnyObject, IdsArray, IWhere } from '../../modules/types';
import { getMetatagsNames } from '../../modules/selectors/metatag';
import { useTranslation } from 'react-i18next';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { Alert } from '../../components/Alert';
import {
  useQueryParams,
  useBrowserHistoryFunctions,
  useFetchSitesCombobox,
} from '../../modules/utils';
import FilterBySites from 'src/components/filtersByEntity/FilterBySites';
import { PageContentChildContainer } from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const DEFAULT_ORDER_BY = 'id';
const DEFAULT_ORDER = 'desc';

const BaselineList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchMetatags = useFetchMetatagsByIds();
  const { siteId: siteIdDetails, filter } = useQueryParams();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.baseline.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.baseline.update.id,
  );

  const { pushSearchObject } = useBrowserHistoryFunctions();

  const [currentSiteId, setCurrentSiteId] = useState<number | null>(
    Number((siteIdDetails as AnyObject)?.eq) ?? null,
  );
  const [filterValues, setFilterValues] = React.useState<IWhere>();
  // fetch list
  const list = useSelector(getBaselineList, shallowEqual);

  const exportProps = useCreateExportProps(exportBaselineRequest);

  // make request to fetch clients and baselines from the server if we don't have them in the store
  useEffect(() => {
    fetchMetatags();
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  // fetch meta tags names list from store
  const metatagNames = useSelector(getMetatagsNames, shallowEqual);

  // get baselines table title
  const tableName = useMemo(
    () =>
      get(
        find(sites, (s) => +s.id === (currentSiteId as number)),
        'name',
        '',
      ),
    [sites, currentSiteId],
  );

  // get baselines columns names for the site
  const baselineColumns = useMemo(
    () => [
      {
        id: 'id',
        disablePadding: false,
        label: t('common.id'),
      },
      {
        id: 'baseline',
        disablePadding: false,
        label: t('baseline.main'),
      },
      {
        id: 'minCpu',
        disablePadding: false,
        label: t('baseline.min_cpu'),
      },
      {
        id: 'maxCpu',
        disablePadding: false,
        label: t('baseline.max_cpu'),
      },
      ...map(metatagNames[currentSiteId as number] ?? [], (field) => ({
        id: field,
        disablePadding: false,
        label: field,
      })),
    ],
    [t, metatagNames, currentSiteId],
  );

  // get baselines filters
  const baselineFilters = useMemo(
    () => [
      {
        name: 'id',
        label: t('baseline.id'),
        operator: 'eq',
      },
      {
        name: 'baseline',
        label: t('baseline.baseline'),
        operator: 'eq',
      },
      {
        name: 'baseTime',
        label: t('baseline.base_time'),
        operator: 'eq',
      },
      ...map(metatagNames[currentSiteId as number] ?? [], (field) => ({
        name: field,
        label: field,
        operator: 'eq',
      })),
    ],
    [currentSiteId, metatagNames, t],
  );

  const baselinesList = useMemo(
    () => get(list, [currentSiteId as number, 'data'], []),
    [currentSiteId, list],
  );

  const baselinesCount = useMemo(
    () => get(list, [currentSiteId as number, 'count'], 0),
    [currentSiteId, list],
  );

  const initialFilterData = useMemo(
    () => ({ siteId: { eq: currentSiteId as number } }),
    [currentSiteId],
  );

  // create dispatcher
  const dispatcher = useDispatch();

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclusion } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getBaselineListRequest({
        siteId: currentSiteId as number,
        filter: {
          filter: {
            limit: rowsPerPage,
            offset,
            order,
            where,
            include: newInclusion,
          },
        },
      }),
    );
    setFilterValues(props.where);
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(
      deleteBaselineRequest({
        siteId: currentSiteId as number,
        ids,
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids, siteId: currentSiteId as number });
    pushToHistory({ pathname: paths.BASELINE_UPDATE, search });
  };

  const handleSiteChoosing = ({ id: entityId }: { id: number | null }) => {
    if (!entityId) {
      return;
    }

    setCurrentSiteId(entityId);
    pushSearchObject({ siteId: { eq: entityId } });
    setFilterValues(
      Object.keys(filterValues ?? {}).reduce((res, key) => {
        res[key] = { eq: key === 'siteId' ? entityId : undefined };
        return res;
      }, {}),
    );
    dispatcher(
      getBaselineListRequest({
        siteId: entityId,
        filter: {
          filter: {
            limit: 25,
            offset: 0,
            order: [`${DEFAULT_ORDER_BY} ${DEFAULT_ORDER}`],
            include: baselinesInclusions,
          },
        },
      }),
    );
  };

  // Fetch data on component mount based on data in query params
  // and initialize filterValues for import
  React.useEffect(() => {
    const _filter = ((filter as AnyObject) ? { filter } : {}) as AnyObject;

    if (currentSiteId) {
      dispatcher(
        getBaselineListRequest({
          siteId: currentSiteId,
          filter: _filter,
        }),
      );
    }
    if ((filter as AnyObject)?.where) {
      setFilterValues((filter as AnyObject).where);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentChildContainer fullHeight={false}>
      <FilterBySites entityId={currentSiteId} onSelect={handleSiteChoosing}>
        {!currentSiteId && isEmpty(baselinesList) ? (
          <Grid container={true}>
            <Grid item={true} xs={MUI_SIZE_12}>
              <Alert variant="outlined" severity="info">
                {t('baseline.site_filter_alert')}
              </Alert>
            </Grid>
          </Grid>
        ) : (
          <Grid container={true} spacing={MUI_SIZE_2}>
            <Grid item={true} xs={MUI_SIZE_12}>
              <EnhancedTable
                data={baselinesList}
                count={baselinesCount}
                selectIndex="id"
                defaultOrder={DEFAULT_ORDER}
                defaultOrderBy={DEFAULT_ORDER_BY}
                tableName={tableName}
                headCells={baselineColumns}
                filters={baselineFilters as ITableFilter[]}
                initialFilterData={initialFilterData}
                onSync={onSync}
                onDelete={allowedToDelete ? handleDeletion : undefined}
                onUpdate={allowedToUpdate ? handleUpdating : undefined}
                include={baselinesInclusions}
                // disableQsFilters={true}
                exportProps={exportProps}
                deleteModelName={ModelsToDelete.Baseline}
                additionalWhereForExport={{
                  siteId: { eq: currentSiteId },
                  ...filterValues,
                }}
                createEntityBtnProps={{
                  title: t('common.create'),
                  onClick: () => pushToHistory('/baseline/create'),
                }}
              />
            </Grid>
          </Grid>
        )}
      </FilterBySites>
    </PageContentChildContainer>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function BaselinesListRefreshable() {
  return <BaselineList key={useSelector(getBaselineRefreshKey)} />;
}
