import React from 'react';
import { useSelector } from 'react-redux';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  IFilterObject,
  IHeadCellWithOrderConfig,
  IInclusionObject,
  InclusionPath,
} from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  addFilterFieldsValuesToFilterObject,
  addOrderDataToFilterObject,
  mapIncludeObjToIncludeForRequest,
  useGenerateHeadCellsData,
} from './table';
import { useTranslation } from 'react-i18next';
import { defaultOrderDetails } from '../helpers/filter';
import { cloneDeep } from 'lodash';

export const useSupervisorViewEmployeesTableHeadCellsConfiguration = () => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'employee.firstName',
        label: t('supervisor_view.emp_first_name'),
        orderConfig: {
          orderBy: 'firstName',
          orderByInclusionPath: ['employee', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'employee.lastName',
        label: t('supervisor_view.emp_last_name'),
        orderConfig: {
          orderBy: 'lastName',
          orderByInclusionPath: ['employee', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'badge',
        disablePadding: false,
        label: t('supervisor_view.badge'),
        orderConfig: { orderBy: 'badge' },
      },
      {
        id: 'supervisor',
        label: t('supervisor_view.supervisor'),
      },
      {
        id: 'employee.site.name',
        label: t('supervisor_view.site'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: [
            'employee',
            'scope',
            'include',
            'site',
            'scope',
          ] as InclusionPath,
        },
      },
      {
        id: 'department.name',
        label: t('supervisor_view.department'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['department', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'paidTimeIn',
        label: t('supervisor_view.time_in_paid'),
        orderConfig: { orderBy: 'paidTimeIn' },
      },
      {
        id: 'total',
        label: t('supervisor_view.hours'),
      },
      {
        id: 'inShiftTimeTotal',
        label: t('supervisor_view.total_shift_hours'),
      },
      {
        id: 'shift.name',
        label: t('supervisor_view.shift'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['shift', 'scope'] as InclusionPath,
        },
      },
    ],
    [t],
  );

  return headCellsConfig;
};

export const useSupervisorViewEmployeesTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
        inclusionPath: ['employee', 'scope'] as InclusionPath,
      },
      employeeId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['employee', 'scope'] as InclusionPath,
      },
      employeeSupervisorId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: [
          'employee',
          'scope',
          'include',
          'supervisor',
          'scope',
        ] as InclusionPath,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
      paidTimeIn: {
        value: '',
        property: 'paidTimeIn',
        operator: 'gte' as const,
      },
      departmentId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['department', 'scope'] as InclusionPath,
      },
      shiftId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['shift', 'scope'] as InclusionPath,
      },
    };
  }, []);
};

export const useSupervisorViewEmployeesTableInclusionObject = (): IInclusionObject => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      department: {
        relationType: 'inner',
      },
      shift: {
        relationType: 'inner',
      },
      staffing: {
        relationType: 'inner',
      },
      employee: {
        relationType: 'inner',
        scope: {
          include: {
            supervisor: {
              relationType: 'inner',
            },
            site: {
              relationType: 'inner',
              scope: {
                include: {
                  client: {
                    relationType: 'inner',
                  },
                },
                where: {
                  id: {
                    inq: globallySelectedSiteIds,
                  },
                },
              },
            },
          },
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

export const useSupervisorViewEmployeesTableDefaultWhere = () => {
  return React.useMemo(
    () => ({
      timeOut: null,
    }),
    [],
  );
};

export const useSupervisorViewTableDefaultFilter = () => {
  const headCellsConfig = useSupervisorViewEmployeesTableHeadCellsConfiguration();

  const { headCellsOrderDetails } = useGenerateHeadCellsData(headCellsConfig);
  const supervisorViewEmployeesTableDefaultWhere = useSupervisorViewEmployeesTableDefaultWhere();
  const employeesTableFiltersConfiguration = useSupervisorViewEmployeesTableFiltersConfiguration();
  const supervisorViewEmployeesTableInclusionObj = useSupervisorViewEmployeesTableInclusionObject();
  const supervisorViewEmployeesTableFiltersConfiguration = useSupervisorViewEmployeesTableFiltersConfiguration();

  const defaultFilter = React.useMemo(() => {
    const filterObj: IFilterObject = {
      limit: DEFAULT_LIMIT,
      offset: 0,
      where: supervisorViewEmployeesTableDefaultWhere,
      include: supervisorViewEmployeesTableInclusionObj,
    };

    addFilterFieldsValuesToFilterObject({
      filterObj,
      filterFields: employeesTableFiltersConfiguration,
      filterFieldsConfiguration: supervisorViewEmployeesTableFiltersConfiguration,
    });

    addOrderDataToFilterObject({
      order: defaultOrderDetails.order,
      orderBy: 'paidTimeIn',
      filterObj,
      headCellsOrderDetails,
    });

    const include = mapIncludeObjToIncludeForRequest(
      cloneDeep(supervisorViewEmployeesTableInclusionObj),
    );

    return {
      ...filterObj,
      include,
    };
  }, [
    employeesTableFiltersConfiguration,
    headCellsOrderDetails,
    supervisorViewEmployeesTableDefaultWhere,
    supervisorViewEmployeesTableFiltersConfiguration,
    supervisorViewEmployeesTableInclusionObj,
  ]);

  return defaultFilter;
};
