import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import {
  exportPayboardDailyHoursRequest,
  getPayboardDailyHoursDashboardReportDataRequest,
  getPayboardDailyHoursSummaryReportDataCountRequest,
} from 'src/modules/actions';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import {
  useCreateExportProps,
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useFetchStaffingProvidersCombobox,
  useFilter,
  usePayboardDailyHoursDashboardDefaultFilter,
} from 'src/modules/utils/hooks';
import {
  getDailyHoursSummaryReportData,
  getDailyHoursSummaryReportDataCount,
  getIsDailyHrsLoading,
} from 'src/modules/selectors/payboard';
import { ComboBoxOption } from 'src/components/ComboBox';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { getDepartmentOptionsByGloballySites } from 'src/modules/selectors/department';

const defaultOrder = 'desc' as const;
const defaultOrderBy = 'date' as const;

export const DailyHrsDashboardTable = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const defaultFilter = usePayboardDailyHoursDashboardDefaultFilter();

  const sites = useSelector(getSitesComboboxList, shallowEqual);
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  const companies = useSelector(getStaffingProvidersComboboxList, shallowEqual);
  const exportProps = useCreateExportProps(exportPayboardDailyHoursRequest);

  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();

  const { items, summary } = useSelector(getDailyHoursSummaryReportData);
  const count = useSelector(getDailyHoursSummaryReportDataCount);

  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );

  const inProgress = useSelector(getIsDailyHrsLoading);

  const { filterCount, filterList } = useFilter(defaultFilter);

  const headCells: HeadCell[] = [
    { id: 'date', disablePadding: false, label: t('payboard.date') },
    {
      id: 'department',
      disablePadding: false,
      label: t('payboard.department'),
    },
    { id: 'site', disablePadding: false, label: t('payboard.site') },
    { id: 'week', disablePadding: false, label: t('payboard.week') },
    { id: 'year', disablePadding: false, label: t('payboard.year') },
    { id: 'badge', disablePadding: false, label: t('payboard.badge') },
    { id: 'firstName', disablePadding: false, label: t('payboard.first_name') },
    { id: 'lastName', disablePadding: false, label: t('payboard.last_name') },
    {
      id: 'employeeId',
      disablePadding: false,
      label: t('payboard.staffing_id'),
    },
    {
      id: 'supervisor',
      disablePadding: false,
      label: t('payboard.supervisor'),
    },
    { id: 'shift', disablePadding: false, label: t('payboard.shift') },
    { id: 'staffing', disablePadding: false, label: t('payboard.company') },
    {
      id: 'dailyHrs',
      disablePadding: false,
      label: t('payboard.daily_hrs'),
    },
    {
      id: 'costPlus',
      disablePadding: false,
      label: t('payboard.hourly'),
    },
  ];

  const filters: ITableFilter[] = [
    {
      name: 'date',
      label: t('payboard.date'),
      operator: 'eq',
      type: 'date',
    },
    {
      name: 'department',
      label: t('payboard.department'),
      operator: 'eq',
      type: 'combobox',
      options: (React.useMemo(
        () =>
          departments.map((department) => ({
            id: department.name,
            name: department.label,
          })),
        [departments],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'site',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(sites, (site) => ({
            id: site.name,
            name: site.name,
          })),
        [sites],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'week',
      label: t('payboard.week'),
      operator: 'eq',
    },
    {
      name: 'year',
      label: t('payboard.year'),
      operator: 'eq',
    },
    {
      name: 'badge',
      label: t('payboard.badge'),
      operator: 'like',
    },
    {
      name: 'employeeId',
      label: t('payboard.staffing_id'),
      operator: 'like',
    },
    {
      name: 'supervisor',
      label: t('payboard.supervisor'),
      operator: 'like',
    },
    {
      name: 'shift',
      label: t('payboard.shift'),
      operator: 'like',
      type: 'combobox',
      options: (React.useMemo(
        () =>
          shifts.map((shift) => ({
            id: shift.name,
            name: shift.name,
          })),
        [shifts],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'staffing',
      label: t('payboard.company'),
      operator: 'like',
      type: 'combobox',
      options: (React.useMemo(
        () =>
          companies.map((company) => ({
            id: company.name,
            name: company.name,
          })),
        [companies],
      ) as unknown) as ComboBoxOption[],
    },
  ];

  React.useEffect(() => {
    // fetch data for table
    dispatch(getPayboardDailyHoursDashboardReportDataRequest(filterList));
    dispatch(getPayboardDailyHoursSummaryReportDataCountRequest(filterCount));

    // fetch data for dropdowns in filter modal window
    fetchDepartmentsCombobox();
    fetchShiftsCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  // handle table synchronization
  const onSync = ({
    order,
    page,
    rowsPerPage,
    where: rawWhere,
  }: ITableSyncProps) => {
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    const filter = {
      limit: rowsPerPage,
      offset,
      where,
      order,
    };

    dispatch(
      getPayboardDailyHoursDashboardReportDataRequest({
        filter,
      }),
    );
    dispatch(
      getPayboardDailyHoursSummaryReportDataCountRequest({ filter: { where } }),
    );
  };

  return (
    <EnhancedTable
      disableSelection
      data={items}
      count={count}
      summaryRows={summary}
      selectIndex=""
      disableQsFilters
      tableName={t('payboard.daily_hours_dashboard_table_name')}
      headCells={headCells}
      filters={filters}
      onSync={onSync}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      isTableDataLoading={inProgress}
      exportProps={exportProps}
      additionalWhereForExport={defaultFilter.where}
      initialFilterData={defaultFilter.where}
    />
  );
};
