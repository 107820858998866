import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateDepartmentRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createDepartmentScheme } from './create-department.scheme';

export const updateDepartmentsScheme: JSONSchemaType<
  Pick<
    ICreateDepartmentRequest,
    | 'siteId'
    | 'name'
    | 'costPlus'
    | 'staticCostPlus'
    | 'parentId'
    | 'active'
    | 'indirect'
    | 'incentive'
    | 'incentiveSettings'
    | 'archived'
    | 'includeToUph'
    | 'includeToCostPlus'
  >[]
> = {
  type: 'array',
  items: {
    ...createDepartmentScheme,
    properties: {
      ...createDepartmentScheme.properties,
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
