import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchActiveDepartmentsCombobox } from '../../modules/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { getDepartmentsComboboxListBySiteId } from '../../modules/selectors/department';
import FormikCombobox from '../Formik/FormikCombobox';
import { ComboBoxOption } from '../ComboBox';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from '../Formik/types';

interface IDepartmentProps {
  formik: AnyObject;
  id: string;
  siteId: number;
  placeholder?: string;
  errorMode?: ErrorMode;
}

const DepartmentComboBox: React.FC<IDepartmentProps> = ({
  formik,
  id,
  siteId,
  placeholder,
  errorMode,
}) => {
  const { t } = useTranslation();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => fetchDepartmentsCombobox(), []);

  const departments = useSelector(
    getDepartmentsComboboxListBySiteId,
    shallowEqual,
  )(siteId);

  const departmentOptions = React.useMemo(
    () => departments.map(({ name }) => ({ id: name, name })),
    [departments],
  );

  return (
    <FormikCombobox
      id={id}
      placeholder={
        placeholder
          ? t(`productions.${placeholder}`)
          : t('productions.department')
      }
      options={(departmentOptions as unknown) as ComboBoxOption[]}
      formik={formik}
      required
      errorMode={errorMode}
    />
  );
};

export default DepartmentComboBox;
