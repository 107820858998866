import React from 'react';
import { isEqual } from 'lodash';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Sheet,
  Typography,
  useTheme,
} from '@mui/joy';
import { ChevronDownSvg } from '../svgIcons';
import { useTranslation } from 'react-i18next';

export type ComboBoxOption<T = number> = {
  id: T;
  name: string;
};

export type ComboBoxOptionWithEntity<T> = ComboBoxOption & {
  entity: T;
};

export interface IComboboxPartialProps {
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: boolean;
  options: ComboBoxOption[];
}

export type IComboboxProps = IComboboxPartialProps &
  Pick<InputProps, 'variant'> &
  Partial<
    AutocompleteProps<ComboBoxOption, undefined, undefined, undefined>
  > & {
    helperText?: string;
  };

export default function ComboBox({
  error,
  placeholder,
  required,
  size,
  variant = 'outlined',
  helperText,
  label,
  ...autocompleteProps
}: IComboboxProps) {
  const { t } = useTranslation();

  const theme = useTheme();

  const getOptionLabel = (option: ComboBoxOption) => option.name || '';

  const getOptionSelected = (option: ComboBoxOption, value: ComboBoxOption) =>
    isEqual(option, value);

  return (
    <Sheet sx={{ width: '100%', bgcolor: 'inherit' }}>
      <FormControl>
        <FormLabel>
          <Typography
            level="text_sm"
            fontWeight="medium"
            textColor="colors.text.text_secondary.main"
          >
            {label}
          </Typography>
        </FormLabel>
        <Autocomplete
          error={error}
          {...autocompleteProps}
          popupIcon={
            <ChevronDownSvg
              fill={theme.palette.colors.foreground.fg_quinary}
              width={20}
              height={20}
            />
          }
          size={size}
          required={required}
          getOptionLabel={getOptionLabel}
          placeholder={placeholder ?? t('common.type_to_search')}
          variant={variant}
          renderOption={(props, option) => {
            return (
              <AutocompleteOption
                {...props}
                key={props.id ?? option.id}
                sx={{ py: '9px', px: '10px' }}
              >
                <Typography
                  // noWrap
                  level="text_sm"
                  fontWeight="medium"
                  textColor="colors.text.text_secondary.hover"
                >
                  {option.name}
                </Typography>
              </AutocompleteOption>
            );
          }}
          isOptionEqualToValue={getOptionSelected}
          openText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          closeText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          slotProps={{
            input: {
              sx: {
                '::placeholder': {
                  fontWeight: theme.fontWeight.regular,
                  color: theme.palette.colors.text.text_placeholder,
                  ...theme.typography.text_md,
                },
                ...(autocompleteProps.slotProps?.input as any)?.sx,
              },
            },
          }}
        />
        {error && helperText && (
          <FormHelperText>
            <Typography
              level="text_sm"
              fontWeight="regular"
              sx={{ color: theme.palette.colors.text.text_error_primary.main }}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    </Sheet>
  );
}
