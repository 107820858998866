import { JSONSchemaType } from 'ajv';
import { ICreateClientRequest } from '../../types';
import { i18n } from '../../utils/i18n';
// don't make this shorter since builder might face with circular dependencies
import { createClientScheme } from './create-client.scheme';

export const updateClientScheme: JSONSchemaType<
  (ICreateClientRequest & { id: number })[]
> = {
  type: 'array',
  items: {
    ...createClientScheme,
    type: 'object',
    required: ['name'],
    additionalProperties: false,
    properties: {
      ...createClientScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
