import React from 'react';
import { Sheet, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { IProcessStatusModel } from 'src/modules/types';

type ISnackbarMessageProps = Pick<IProcessStatusModel, 'message' | 'title'>;

export const SnackbarMessage = ({ message, title }: ISnackbarMessageProps) => {
  const { t } = useTranslation();

  return (
    <Sheet sx={{ bgcolor: 'inherit' }}>
      {title && (
        <Typography
          level="text_sm"
          fontWeight="semi_bold"
          textColor="colors.foreground.fg_primary"
        >
          {t(title)}
        </Typography>
      )}
      {message && (
        <Typography
          level="text_sm"
          fontWeight="regular"
          textColor="colors.foreground.fg_secondary"
        >
          {t(message)}
        </Typography>
      )}
    </Sheet>
  );
};
