import React from 'react';
import { ILogtimeSupervisorViewModel } from '../../../modules/types';
import { IHeadCell, Order } from 'src/modules/types/table';
import { Pagination, TableComponent, TableContent } from 'src/components/Table';
import { DEFAULT_PAGE } from 'src/modules/utils/hooks/table';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { Box, useTheme } from '@mui/joy';
import { map } from 'lodash';
import {
  DATETIME_FORMAT_TO_PASS,
  DATETIME_FORMAT_TO_SHOW,
  composeDate,
  format,
  getNow,
  parse,
} from 'src/modules/utils/dateWrapper';
import { differenceInMilliseconds } from 'date-fns';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { useTranslation } from 'react-i18next';
import { UserMinus01Svg, UserRight01Svg } from 'src/components/svgIcons';

interface IEmployeesTableProps {
  onPageChange: (page: number) => void;
  onLimitChange: (limit: number) => void;
  onOrderChange: (order: Order) => void;
  onOrderByChange: (order: string) => void;
  onRowCheckboxClick: (
    employeesIds: Array<ILogtimeSupervisorViewModel>,
  ) => void;
  selectedEmployees: Array<ILogtimeSupervisorViewModel>;
  onMoveToAnotherDepartmentClick?: () => void;
  onLogoutEmployeesClick?: () => void;
  tableData: Array<ILogtimeSupervisorViewModel>;
  tableDataCount: number;
  isDataLoading: boolean;
  limit: number;
  page: number;
  headCells: IHeadCell[];
  order?: Order;
  orderBy?: string;
}

const EIGHT_HOURS_IN_MILLISECONDS = 28800000;
const TEN_HOURS_IN_MILLISECONDS = 36000000;

export const EmployeesTable = ({
  onLimitChange,
  onPageChange,
  onOrderChange,
  onOrderByChange,
  onRowCheckboxClick,
  order,
  limit,
  page,
  orderBy,
  headCells,
  selectedEmployees,
  tableData,
  tableDataCount,
  onMoveToAnotherDepartmentClick,
  isDataLoading,
  onLogoutEmployeesClick,
}: IEmployeesTableProps) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const computedList = React.useMemo(
    () =>
      map(tableData, (item) => {
        let sx = {};

        const start = composeDate(
          item.timeIn as string,
          parse(DATETIME_FORMAT_TO_PASS),
        );
        const end = getNow();
        const diffInMilliseconds = differenceInMilliseconds(end, start);

        if (diffInMilliseconds > TEN_HOURS_IN_MILLISECONDS) {
          sx = {
            backgroundColor: `${theme.palette.colors.utility.error.utility_error_100} !important`,
          };
        } else if (diffInMilliseconds > EIGHT_HOURS_IN_MILLISECONDS) {
          sx = {
            backgroundColor: `${theme.palette.colors.utility.warning.utility_warning_100} !important`,
          };
        }
        const supervisor = item.employee?.supervisor;
        return {
          ...item,
          timeIn: composeDate(
            item.timeIn as string,
            parse(DATETIME_FORMAT_TO_PASS),
            format(DATETIME_FORMAT_TO_SHOW),
          ),
          paidTimeIn: item.paidTimeIn
            ? composeDate(
                item.paidTimeIn as string,
                parse(DATETIME_FORMAT_TO_PASS),
                format(DATETIME_FORMAT_TO_SHOW),
              )
            : composeDate(getNow(), format(DATETIME_FORMAT_TO_SHOW)),
          total: item.totalFmt,
          supervisor: supervisor
            ? `${supervisor.firstName} ${supervisor.lastName}`
            : '',
          sx,
        };
      }),
    [
      tableData,
      theme.palette.colors.utility.error.utility_error_100,
      theme.palette.colors.utility.warning.utility_warning_100,
    ],
  );

  const handleChangeRowsPerPage = (limit: number) => {
    onLimitChange(limit);

    onPageChange(DEFAULT_PAGE);
  };

  const handleSort = (orderBy: string, order: Order) => {
    onOrderChange(order);
    onOrderByChange(orderBy);
  };

  const handlePageChange = (_: any, page: number) => {
    onPageChange(page);
  };

  const handleRowCheckboxClick = (employeesIds: Array<number>) => {
    onRowCheckboxClick(
      tableData.filter((item) => employeesIds.includes(item.id)),
    );
  };

  const actionsConfiguration = [];
  if (onLogoutEmployeesClick) {
    actionsConfiguration.push({
      tooltip: t('common.logout'),
      onClick: onLogoutEmployeesClick,
      icon: <UserMinus01Svg />,
    });
  }
  if (onMoveToAnotherDepartmentClick) {
    actionsConfiguration.push({
      tooltip: t('supervisor_view.move_to_another_dep'),
      onClick: onMoveToAnotherDepartmentClick,
      icon: <UserRight01Svg />,
    });
  }

  return (
    <TableComponent
      tableUniqueKey="timeKeeping/supervisorView/employees"
      sx={{
        height: isDataLoading || !computedList.length ? '100%' : 'auto',
      }}
    >
      {isDataLoading && <MainContentLoader />}

      <TableContent
        data={computedList}
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onSort={handleSort}
        onRowCheckboxClick={handleRowCheckboxClick}
        isDataLoading={isDataLoading}
        selectedRows={selectedEmployees.map((employee) => employee.id)}
      />

      {Boolean(selectedEmployees.length) && (
        <TableSelectedItemsActions
          selectedItemsQuantity={selectedEmployees.length}
          actionsConfiguration={actionsConfiguration}
        />
      )}

      <Box sx={{ width: '100%' }}>
        <Pagination
          currentPageNumber={page}
          count={tableDataCount}
          rowsPerPage={limit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </TableComponent>
  );
};
