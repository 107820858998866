import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { omit } from 'lodash';
import { AuthLayout } from '../components/Layout/AuthLayout';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MUI_SIZE_12 } from '../config';
import { isDataRequested, isLoggedIn } from '../modules/selectors/auth';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useParams } from 'react-router-dom';
import {
  completeCreationUserRequest,
  getUserPrefilledDataRequest,
} from '../modules/actions/user';
import {
  getCompleteCreationStatus,
  getUserPrefilledData,
} from '../modules/selectors/user';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../components/Formik/FormikTextField';
import { SubmitButton } from '../components/Form/SubmitButton';
import { useValidate } from '../modules/utils';
import { useFormik } from 'formik';
import { completeProfileScheme } from '../modules/schemes/complete-profile.scheme';
import { FormActionsContainer } from '../components/Form/FormActionsContainer';
import { Box } from '@mui/material';

const initialValues = {
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
};

export default function CompleteProfile() {
  const dispatcher = useDispatch();

  const { activateToken } = useParams<{ activateToken: string }>();

  const validate = useValidate(completeProfileScheme);

  // fetch user prefilled data
  const prefilledData = useSelector(getUserPrefilledData, shallowEqual);

  // make request to fetch prefilled data
  useEffect(() => {
    activateToken && dispatcher(getUserPrefilledDataRequest(activateToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      if (!activateToken) {
        return;
      }

      dispatcher(
        completeCreationUserRequest({
          ...omit(data, ['confirmPassword']),
          activateToken,
        }),
      );
    },
  });

  useEffect(() => {
    formik.setValues({
      ...(prefilledData as typeof initialValues),
      password: '',
      confirmPassword: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledData]);

  const { t } = useTranslation();

  // check if a user is logged in
  const is_logged_in = useSelector(isLoggedIn, shallowEqual);

  // check if an email has been sent to complete registration
  const status = useSelector(getCompleteCreationStatus, shallowEqual);

  // check if data is requested to show preloader
  const isLoading = useSelector(isDataRequested, shallowEqual);

  if (is_logged_in) {
    return <Navigate to="/" />;
  }

  const output = status ? (
    <Alert severity="success" sx={{ marginTop: 4 }}>
      <AlertTitle>{t('auth.register.success')}</AlertTitle>
      {t('auth.register.thanks_completing_profile')}
    </Alert>
  ) : (
    <Box
      component="form"
      sx={{
        width: '100%',
        marginTop: 1,
      }}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <FormikTextField
        variant="outlined"
        required
        fullWidth
        id="firstName"
        label={t('auth.register.first_name')}
        name="firstName"
        formik={formik}
      />

      <FormikTextField
        variant="outlined"
        required
        fullWidth
        id="lastName"
        label={t('auth.register.last_name')}
        name="lastName"
        autoComplete="lastName"
        formik={formik}
      />

      <FormikTextField
        variant="outlined"
        required
        fullWidth
        id="password"
        type="password"
        label={t('auth.register.password')}
        name="password"
        autoComplete="password"
        formik={formik}
      />

      <FormikTextField
        variant="outlined"
        required
        fullWidth
        id="confirmPassword"
        type="password"
        label={t('auth.register.confirm_password')}
        name="confirmPassword"
        autoComplete="confirmPassword"
        formik={formik}
      />

      <FormActionsContainer>
        <SubmitButton fullWidth isLoading={isLoading}>
          {t('common.submit')}
        </SubmitButton>
      </FormActionsContainer>

      <Grid container>
        <Grid item xs={MUI_SIZE_12}>
          <Link href="/login" variant="body2">
            {t('auth.sign_in')}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );

  return <AuthLayout>{output}</AuthLayout>;
}
