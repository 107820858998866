import React from 'react';
import {
  ChartsDashboard,
  ChartsDashboardBody,
  ChartsDashboardChart,
  ChartsDashboardChartsContainer,
} from '../../../../components/ChartsDashboard';
import { FinancialReportTable, ITableData } from './FinancialReportTable';
import { ReportPageItemContainer } from '../../../../components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from '../../../../components/ReportPage/ReportTableToolbar';
import { Box, Button } from '@mui/joy';
import {
  FinancialReportsFilterPanel,
  IFinancialReportsFilterPanelFilters,
} from './FinancialReportsFilterPanel';
import { useTranslation } from 'react-i18next';
import { PageContentChildContainer } from 'src/components/PageContent';
import { FinancialReportSynchronizedChart } from './FinancialReportSynchronizedChart';
import { FinancialReportsWeeklyGrossMarginBySiteMoney } from './FinancialReportsWeeklyGrossMarginBySiteMoney';
import { FinancialReportsWeeklyGrossMarginBySitePercentage } from './FinancialReportsWeeklyGrossMarginBySitePercentage';
import { FinancialReportsWeeklyActiveInactiveHired } from './FinancialReportsWeeklyActiveInactiveHired';
import { FinancialReportsCreateRecordForm } from './FinancialReportsCreateRecordForm';
import {
  useFinancialTrackerCreate,
  useFinancialTrackerUpdate,
} from 'src/modules/utils';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../modules/actions';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { FinancialReportsUpdateRecordForm } from './FinancialReportsUpdateRecordForm';
import { useHasUserAccessToResource } from 'src/config';
import { apiEndpoints } from 'src/config/apiEndpoints';

const executiveReportsDefaultFilters: IFinancialReportsFilterPanelFilters = {
  yearNumber: undefined,
  weekNumber: undefined,
  siteId: undefined,
  yearWeekRange: {
    from: undefined,
    to: undefined,
  },
};

export const FinancialReports = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allowedToCreateRecord = useHasUserAccessToResource(
    apiEndpoints.FINANCIAL_TRACKER,
    'create',
  );

  const [
    isCreateRecordFormVisible,
    setIsCreateRecordFormVisible,
  ] = React.useState(false);
  const [
    isUpdateRecordFormVisible,
    setIsUpdateRecordFormVisible,
  ] = React.useState(false);

  const {
    data: tableData,
    fetchData: fetchTableData,
    isDataLoading: isTableDataLoading,
  } = useDataFetcherWithData<ITableData>(apiEndpoints.FINANCIAL_TRACKER, {
    items: [],
    summary: [],
  });

  const [selectedRecordsIds, setSelectedRecordsIds] = React.useState<
    Array<number>
  >([]);

  const {
    updateFinancialTracker,
    isLoading: isUpdatingLoading,
    isSuccess: isUpdatingSuccess,
    isError: isUpdatingError,
  } = useFinancialTrackerUpdate();

  const {
    createFinancialTracker,
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useFinancialTrackerCreate();

  // This config should contain filters from all tables/charts
  // for the current report
  const filtersOptionsConfig: Array<{
    id: keyof IFinancialReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('productions_uph_reports.site.name') },
      { id: 'yearNumber', label: t('productions_uph_reports.year') },
      { id: 'weekNumber', label: t('productions_uph_reports.week') },
    ],
    [t],
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IFinancialReportsFilterPanelFilters
  >(executiveReportsDefaultFilters);

  React.useEffect(() => {
    if (!isCreateLoading) {
      if (isCreateSuccess) {
        dispatch(
          actions.addProcessStatus({
            variant: 'success',
            message: t('common.entity_was_created'),
          }),
        );
      }
      if (isCreateError) {
        dispatch(
          actions.addProcessStatus({
            variant: 'error',
            message: t('common.failed'),
          }),
        );
      }
    }
  }, [dispatch, isCreateError, isCreateLoading, isCreateSuccess, t]);

  React.useEffect(() => {
    if (!isUpdatingLoading) {
      if (isUpdatingSuccess) {
        dispatch(
          actions.addProcessStatus({
            variant: 'success',
            message: t('common.entities_were_updated'),
          }),
        );
      }
      if (isUpdatingError) {
        dispatch(
          actions.addProcessStatus({
            variant: 'error',
            message: t('common.failed'),
          }),
        );
      }
    }
  }, [dispatch, isUpdatingError, isUpdatingLoading, isUpdatingSuccess, t]);

  return (
    <>
      <Box
        sx={{
          mx: -2,
          flexGrow: 1,
        }}
      >
        {(isCreateLoading || isUpdatingLoading) && <MainContentLoader />}
        {!isCreateLoading && !isUpdatingLoading && (
          <>
            <PageContentChildContainer sx={{ pt: 2 }} fullHeight={false}>
              <ReportPageItemContainer>
                <ReportTableToolbar reportName=" ">
                  {allowedToCreateRecord && (
                    <Button
                      variant="solid"
                      onClick={() => setIsCreateRecordFormVisible(true)}
                    >
                      {t('common.create_record')}
                    </Button>
                  )}
                </ReportTableToolbar>
              </ReportPageItemContainer>

              <FinancialReportsFilterPanel
                hideSyncBtn
                filtersOptionsConfig={filtersOptionsConfig}
                onApplyFilters={(filtersToApply) => {
                  setAppliedFilters(filtersToApply);
                }}
                onRemoveAllFilters={() =>
                  setAppliedFilters(executiveReportsDefaultFilters)
                }
              />
            </PageContentChildContainer>

            <PageContentChildContainer
              sx={{ display: 'flex', flexGrow: 1 }}
              fullHeight={false}
            >
              <ChartsDashboard>
                <ChartsDashboardBody>
                  <ChartsDashboardChartsContainer>
                    <ChartsDashboardChart>
                      <FinancialReportSynchronizedChart />
                    </ChartsDashboardChart>
                  </ChartsDashboardChartsContainer>
                  <ChartsDashboardChartsContainer>
                    <ChartsDashboardChart>
                      <FinancialReportsWeeklyGrossMarginBySiteMoney />
                    </ChartsDashboardChart>
                    <ChartsDashboardChart>
                      <FinancialReportsWeeklyGrossMarginBySitePercentage />
                    </ChartsDashboardChart>
                    <ChartsDashboardChart>
                      <FinancialReportsWeeklyActiveInactiveHired />
                    </ChartsDashboardChart>
                  </ChartsDashboardChartsContainer>
                </ChartsDashboardBody>
              </ChartsDashboard>
            </PageContentChildContainer>
            <PageContentChildContainer sx={{ pt: 1 }} fullHeight={false}>
              <FinancialReportTable
                {...appliedFilters}
                data={tableData}
                fetchData={fetchTableData}
                isTableDataLoading={isTableDataLoading}
                selectedRecordsIds={selectedRecordsIds}
                onItemsSelectionChange={setSelectedRecordsIds}
                onUpdateClick={() => setIsUpdateRecordFormVisible(true)}
              />
            </PageContentChildContainer>
          </>
        )}
      </Box>

      <FinancialReportsCreateRecordForm
        onCreate={createFinancialTracker}
        isOpen={isCreateRecordFormVisible}
        onClose={() => setIsCreateRecordFormVisible(false)}
      />

      <FinancialReportsUpdateRecordForm
        onUpdate={updateFinancialTracker}
        isOpen={isUpdateRecordFormVisible}
        onClose={() => setIsUpdateRecordFormVisible(false)}
        itemsToUpdate={(tableData.items as any).filter((item: any) =>
          selectedRecordsIds.includes(item.id),
        )}
      />
    </>
  );
};
