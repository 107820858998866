import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from 'src/modules/utils/Api';
import {
  AnyObject,
  IIncentivePricingSettings,
  IncentivePriceSettingsOptions,
} from 'src/modules/types';

const getIncentivePricingSettingsFetchParameters = (siteId?: number) => {
  const filter = { filter: { where: { siteId: { eq: siteId } } } };
  const queryKey = getQueryKey('/incentive-price-settings', filter);

  return { filter, queryKey };
};

export const useIncentivePricingSettingsData = (siteId?: number) => {
  const { filter, queryKey } = getIncentivePricingSettingsFetchParameters(
    siteId,
  );
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => Api.IncentivePrice.getSettings(filter),
    { enabled: Boolean(siteId), retry: false, cacheTime: 0 },
  );

  const config = React.useMemo<IIncentivePricingSettings>(
    () => data ?? ({} as IIncentivePricingSettings),
    [data],
  );

  return {
    config,
    isLoading,
    error,
  };
};

export const useIncentivePricingSettings = (siteId?: number) => {
  const { config, isLoading } = useIncentivePricingSettingsData(siteId);

  return {
    incentivePricingSettingsId: config.id,
    isLoading,
    settings: config.settings,
  };
};

export const useIncentivePricingSettingsForm = (siteId?: number) => {
  const { queryKey } = getIncentivePricingSettingsFetchParameters(siteId);
  const queryClient = useQueryClient();

  // get data
  const {
    incentivePricingSettingsId,
    settings,
    isLoading,
  } = useIncentivePricingSettings(siteId);

  // get mutation functions for sending data to server
  const { mutate: createSettings, isLoading: createLoading } = useMutation(
    (data: AnyObject) => Api.IncentivePrice.createSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
  const { mutate: updateSettings, isLoading: updateLoading } = useMutation(
    (data: AnyObject) => Api.IncentivePrice.updateSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  // submit function
  const sendToServer = (settings: IncentivePriceSettingsOptions[]) => {
    // update Pricing Settings
    if (incentivePricingSettingsId) {
      const data = {
        id: incentivePricingSettingsId,
        settings,
      };

      updateSettings(data);
    }
    // create new Pricing Settings
    else {
      const data = {
        siteId,
        settings,
      };

      createSettings(data);
    }
  };

  const sendLoading = createLoading || updateLoading;

  return { settings, isLoading, sendToServer, sendLoading };
};
