import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  ICreateOptiturnCredentials,
  IdsArray,
  IFilter,
  IOptiturnCredentialsModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../types';
import { paths } from '../../config';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createOptiturnCredentialsRequest,
    saga: function* ({
      payload: { data, navigate },
    }: ISagaAction<
      PayloadWithNavigateFunc<ICreateOptiturnCredentials>
    >): SagaIterator {
      try {
        const response: IOptiturnCredentialsModel = yield call(
          Api.OptiturnCredentials.create,
          data,
        );
        yield put(actions.flushOptiturnCredentialsState());
        yield put(actions.createOptiturnCredentialsSuccess(response));
        yield call(navigate, { pathname: paths.OPTITURN_CREDENTIALS });
      } catch (e) {
        yield put(actions.createOptiturnCredentialsFailed());
      }
    },
  },
  {
    action: actions.getOptiturnCredentialsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: IOptiturnCredentialsModel[] = yield call(
          Api.OptiturnCredentials.list,
          action.payload,
        );
        yield put(actions.getOptiturnCredentialsListSuccess(response));
      } catch (e) {
        yield put(actions.getOptiturnCredentialsListFailed());
      }
    },
  },
  {
    action: actions.getOptiturnCredentialsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.OptiturnCredentials.count,
          action?.payload,
        );
        yield put(actions.getOptiturnCredentialsCountSuccess(response));
      } catch (e) {
        yield put(actions.getOptiturnCredentialsCountFailed());
      }
    },
  },
  {
    action: actions.deleteOptiturnCredentialsRequest,
    saga: function* (action: ISagaAction<IdsArray>): SagaIterator {
      try {
        yield call(Api.OptiturnCredentials.delete, {
          where: { id: { inq: action.payload } },
        });
        yield put(actions.flushOptiturnCredentialsState());
      } catch (e) {
        yield put(actions.deleteOptiturnCredentialsFailed());
      }
    },
  },
  {
    action: actions.updateOptiturnCredentialsRequest,
    saga: function* ({
      payload: { data, navigate },
    }: ISagaAction<
      PayloadWithNavigateFunc<IOptiturnCredentialsModel[]>
    >): SagaIterator {
      try {
        yield call(Api.OptiturnCredentials.bulkUpdate, data);
        yield put(actions.flushOptiturnCredentialsState());
        yield call(navigate, { pathname: paths.OPTITURN_CREDENTIALS });
      } catch (e) {
        yield put(actions.updateOptiturnCredentialsFailed());
      }
    },
  },
  {
    action: actions.getOptiturnCredentialsComboboxListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: Partial<IOptiturnCredentialsModel>[] = yield call(
          Api.OptiturnCredentials.list,
          action.payload,
        );
        yield put(actions.getOptiturnCredentialsComboboxListSuccess(response));
      } catch (e) {
        yield put(actions.getOptiturnCredentialsComboboxListFailed());
      }
    },
  },
];
