import React from 'react';
import { addYears, getISOWeek, getYear } from 'date-fns';
import {
  formatYearWeekOptionName,
  formatYearWeekOptionValue,
} from '../../helpers/common';
import { ComboBoxOption } from '../../../../components/ComboBox';

export const useGetYearWeeksOptionsForAdditionalFee = (
  dateStartFrom: Date,
  yearsAhead = 1,
) => {
  const datesYear = getYear(dateStartFrom);
  const datesWeek = getISOWeek(dateStartFrom);
  const startYearWeekMemoizeKey = formatYearWeekOptionValue({
    year: datesYear,
    week: datesWeek,
  });

  return React.useMemo(() => {
    let comboBoxOptions: ComboBoxOption<number>[] = [];

    const lastYearNumber = getYear(addYears(dateStartFrom, yearsAhead));

    for (
      let yearNumber = datesYear;
      yearNumber <= lastYearNumber;
      yearNumber++
    ) {
      const startWeekNumber = yearNumber === datesYear ? datesWeek : 1;
      const finishWeekNumber = getISOWeek(new Date(yearNumber, 11, 28));
      const countOfWeeks = finishWeekNumber - startWeekNumber + 1;

      const result = Array.from({ length: countOfWeeks }, (_, index) => {
        return {
          id: formatYearWeekOptionValue({
            year: yearNumber,
            week: index + startWeekNumber,
          }),
          name: formatYearWeekOptionName({
            year: yearNumber,
            week: index + startWeekNumber,
          }),
        };
      });

      comboBoxOptions = [...comboBoxOptions, ...result];
    }

    return comboBoxOptions;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startYearWeekMemoizeKey, yearsAhead]);
};
