import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { createPositionRequest } from '../../modules/actions';
import { useFormik } from 'formik';
import { FormActions } from '../../components/Form/FormActions';
import FormikTextField from '../../components/Formik/FormikTextField';
import { createPositionScheme } from '../../modules/schemes/positions';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { useBrowserHistoryFunctions, useValidate } from '../../modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const initialValues = {
  siteId: NaN,
  name: '',
};

const PositionsCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  const dispatcher = useDispatch();

  const validate = useValidate(createPositionScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createPositionRequest({ data, navigate }));
    },
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('positions.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth={true}
              id="name"
              label={t('positions.name')}
              name="name"
              autoComplete="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('positions.site')}
              placeholder={t('positions.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              type="number"
              id="desiredEmployeesNumber"
              label={t('departments.desiredEmployeesNumber')}
              name="desiredEmployeesNumber"
              autoComplete="desiredEmployeesNumber"
              formik={formik}
            />
          </FormFieldContainer>

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default PositionsCreate;
