import { Box, Typography } from '@mui/joy';
import React from 'react';
import { PageContentWithTopToolbar } from 'src/components/PageContent';

export const AppVersion = () => {
  const version = process.env.REACT_APP_VERSION;

  return (
    <PageContentWithTopToolbar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'colors.border.border_primary',
            px: 2,
            py: 1,
          }}
        >
          <Typography fontSize={'1.4em'} component="h2">
            Client version: {version}
          </Typography>
        </Box>
      </Box>
    </PageContentWithTopToolbar>
  );
};
