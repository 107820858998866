import { JSONSchemaType } from 'ajv';
import { IImageData } from 'src/components/UploadImages';
import { ICreateIssueInIssueTracker } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createIssueInIssueTrackerScheme: JSONSchemaType<
  Pick<
    ICreateIssueInIssueTracker,
    'siteId' | 'summary' | 'description' | 'label' | 'priorityId'
  > & { files: Array<IImageData> }
> = {
  type: 'object',
  required: ['siteId', 'summary', 'priorityId', 'label'],
  additionalProperties: true,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    summary: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    priorityId: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    label: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    description: {
      nullable: true,
      type: 'string',
    },
    files: {
      type: 'array',
      items: {
        required: ['preview'],
        type: 'object',
        properties: {
          file: {
            nullable: true,
            required: [],
            type: 'object',
          },
          preview: {
            type: 'string',
            isNotEmpty: true,
          },
        },
      },
    },
  },
};
