import React from 'react';
import noop from 'lodash/noop';
import JoyRide, { Props } from 'react-joyride';
import { useGenerateSteps } from './hooks';

interface ITourProps extends Omit<Props, 'steps'> {
  tour: Parameters<typeof useGenerateSteps>[0];
}

export const Tour: React.FC<ITourProps> = ({
  tour,
  callback = noop,
  ...rest
}) => {
  const steps = useGenerateSteps(tour);

  return (
    <JoyRide
      continuous
      showProgress
      disableOverlay
      disableScrollParentFix
      steps={steps}
      scrollOffset={400}
      callback={callback}
      spotlightPadding={-20}
      styles={{
        options: {
          primaryColor: 'black',
          zIndex: 1202,
        },
      }}
      {...rest}
    />
  );
};
