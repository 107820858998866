import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { ICountResponse, IWorkdaysModel, IWorkdaysState } from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IWorkdaysState = {
  refreshKey: 0,
  id: NaN,
  shiftId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushWorkdaysState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Get list actions
 */
const getWorkdaysListRequest = (state: IWorkdaysState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getWorkdaysListSuccess = (
  state: IWorkdaysState,
  payload: IWorkdaysModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getWorkdaysListFailed = (state: IWorkdaysState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getWorkdaysCountRequest = (state: IWorkdaysState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getWorkdaysCountSuccess = (
  state: IWorkdaysState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getWorkdaysCountFailed = (state: IWorkdaysState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateWorkdaysRequest = (state: IWorkdaysState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateWorkdaysFailed = (state: IWorkdaysState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getWorkdaysComboboxListRequest = (state: IWorkdaysState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getWorkdaysComboboxListSuccess = (
  state: IWorkdaysState,
  payload: Partial<IWorkdaysModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getWorkdaysComboboxListFailed = (
  state: IWorkdaysState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IWorkdaysState>();

/**
 * Workdays reducer
 */
export const workdays = createReducer<IWorkdaysState>({}, defaultState)
  // flush actions
  .on(actions.flushWorkdaysState, flushWorkdaysState)
  // get list actions
  .on(actions.getWorkdaysListRequest, getWorkdaysListRequest)
  .on(actions.getWorkdaysListSuccess, getWorkdaysListSuccess)
  .on(actions.getWorkdaysListFailed, getWorkdaysListFailed)
  // get count actions
  .on(actions.getWorkdaysCountRequest, getWorkdaysCountRequest)
  .on(actions.getWorkdaysCountSuccess, getWorkdaysCountSuccess)
  .on(actions.getWorkdaysCountFailed, getWorkdaysCountFailed)
  // updating actions
  .on(actions.updateWorkdaysRequest, updateWorkdaysRequest)
  .on(actions.updateWorkdaysFailed, updateWorkdaysFailed)
  // get combobox list actions
  .on(actions.getWorkdaysComboboxListRequest, getWorkdaysComboboxListRequest)
  .on(actions.getWorkdaysComboboxListSuccess, getWorkdaysComboboxListSuccess)
  .on(actions.getWorkdaysComboboxListFailed, getWorkdaysComboboxListFailed)
  // export actions
  .on(actions.exportWorkdaysRequest, exportRequest)
  .on(actions.exportWorkdaysSuccess, exportSuccess)
  .on(actions.exportWorkdaysFailed, exportFailed);

export default workdays;
