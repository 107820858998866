import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Box, Typography, Link } from '@mui/joy';
import { Trash04Svg, UploadToCloudSvg } from '../svgIcons';

export interface IAttachmentData extends Partial<File> {
  attachment: string;
  id?: string | number;
  mime?: string;
  name?: string;
}

export interface IUploadedFileWithPreview extends File {
  attachment: string | ArrayBuffer | null;
}

interface IUploadAttachmentProps {
  id: string;
  onRemove: (attachmentIndex: number) => void;
  onChange: (uploadedFile: IUploadedFileWithPreview) => void;
  attachments?: Array<IAttachmentData>;
  btnLabel?: string;
  accept?: string;
}

export default function UploadAttachments({
  id,
  onChange,
  onRemove,
  attachments = [],
  accept,
  btnLabel,
}: IUploadAttachmentProps) {
  const { t } = useTranslation();

  const inputIdToUse = id ?? 'contained-button-file';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader: FileReader = new FileReader();
    const [file] = [...event.target.files!];

    fileReader.onload = () => {
      onChange(Object.assign(file, { attachment: fileReader.result }));
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  const parseAttachmentType = (attachment: Partial<IAttachmentData>) => {
    if (attachment.type) {
      return attachment.type;
    }

    return attachment.mime;
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'colors.background.bg_primary',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'colors.border.border_secondary',
          borderRadius: 12,
          px: 3,
          py: 2,

          '&:hover': {
            borderColor: 'colors.border.border_brand',
          },
        }}
      >
        <UploadToCloudSvg />

        <Box sx={{ pt: 1.5 }}>
          <Box
            component="input"
            value=""
            type="file"
            multiple
            onChange={handleChange}
            id={id}
            sx={{
              display: 'none',
            }}
            accept={accept ?? 'attachment/*'}
          />
          <label htmlFor={inputIdToUse}>
            <Button
              variant="plain"
              component="span"
              size="md"
              sx={{ '&:hover': { bgcolor: 'transparent' } }}
            >
              <Typography
                textColor="colors.buttons.tertiary_colors.button_tertiary_color_fg"
                level="text_sm"
                fontWeight="semi_bold"
              >
                {btnLabel ?? t('common.click_to_upload')}
              </Typography>
            </Button>
          </label>
        </Box>
      </Box>

      {Boolean(attachments.length) &&
        attachments.map(({ id, mime, name, size, type, attachment }, i) => (
          <Box
            key={id ?? i}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              bgcolor: 'colors.background.bg_primary',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'colors.border.border_secondary',
              borderRadius: 12,
              mt: 1,
              p: 2,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box>
                {parseAttachmentType({ type, mime })?.includes('image') ? (
                  <Box
                    component="img"
                    sx={{ maxWidth: 60, maxHeight: 60, borderRadius: '12px' }}
                    src={attachment}
                    alt="preview"
                  />
                ) : (
                  <Box>
                    <Link target="_blank" href={attachment}>
                      {attachment}
                    </Link>
                  </Box>
                )}
              </Box>

              <Box sx={{ pl: 1 }}>
                {name && (
                  <Box>
                    <Typography
                      level="text_sm"
                      textColor="colors.text.text_secondary"
                    >
                      {name}
                    </Typography>
                  </Box>
                )}
                {size && (
                  <Box>
                    <Typography
                      level="text_sm"
                      textColor="colors.text.text_tertiary"
                    >
                      {(size / 1024).toFixed(2)} KB
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignSelf: 'start' }}>
              <IconButton
                onClick={() => onRemove(i)}
                sx={{ minWidth: 0, minHeight: 0 }}
              >
                <Trash04Svg />
              </IconButton>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
