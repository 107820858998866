import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IWhere,
  ListWithSummary,
} from '../../../../types';

export class ListWithSummaryActionCreator {
  private readonly prefix: string = '';

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  takeListActions<T>() {
    const getListRequest = createAction<IFilter>(
      `Make request to fetch ${this.prefix} list`,
    );
    const getListSuccess = createAction<ListWithSummary<T>>(
      `${this.prefix} list has been fetched successfully`,
    );
    const getListFailed = createAction(`Cannot fetch ${this.prefix} list`);

    return {
      getListRequest,
      getListSuccess,
      getListFailed,
    };
  }

  takeCountActions() {
    const getCountRequest = createAction<IWhere>(
      `Make request to get ${this.prefix} count`,
    );
    const getCountSuccess = createAction<ICountResponse>(
      `${this.prefix} count has been fetched successfully`,
    );
    const getCountFailed = createAction(`Cannot fetch ${this.prefix} count`);

    return {
      getCountRequest,
      getCountSuccess,
      getCountFailed,
    };
  }
}
