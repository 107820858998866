import React from 'react';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import { IProjectionVsActualTotalsYearlyResponseItem } from 'src/modules/types/projection-vs-actual-dashboard';
import {
  useGeneratorsForTotalsChartSeries,
  useTotalsChartCategories,
} from 'src/modules/utils/hooks/projectionVsActualChartsDashboard.hooks';

interface IFinancialDashboardTotalsChartYearlyProps {
  chartHeight: string;
  data: IProjectionVsActualTotalsYearlyResponseItem;
}

export const FinancialDashboardTotalsChartYearly = React.forwardRef<
  Highcharts.Chart,
  IFinancialDashboardTotalsChartYearlyProps
>(({ data, chartHeight }, ref) => {
  const categories = useTotalsChartCategories();
  const { generateYearlySeries } = useGeneratorsForTotalsChartSeries();

  const series = data ? generateYearlySeries(data) : [];

  return (
    <ColumnChart
      ref={ref}
      height={chartHeight}
      categories={categories}
      series={series}
      yAxis={[
        {
          min: 0,
          title: {
            enabled: false,
          } as Highcharts.YAxisTitleOptions,
        },
        {
          min: 0,
          allowDecimals: false,
          opposite: true,
          title: {
            enabled: false,
          } as Highcharts.YAxisTitleOptions,
        },
      ]}
    />
  );
});
