import React from 'react';
import { Box } from '@mui/joy';
import { TableContext } from 'src/components/Table/TableContext';
import DateRangePicker, {
  IDateRangePickerValue,
} from 'src/components/_ui-kit/Combobox/Pickers/Daterangepicker';

type DatetimeRangePickerValue = [
  string | undefined | null,
  string | undefined | null,
];

export interface IDatetimeRangePickerProps {
  name: string;
  label?: string;
  value?: DatetimeRangePickerValue;
  onChange?: (datetimeRangeObject: DatetimeRangePickerValue) => void;
}

export const DatetimeRangePicker: React.FC<IDatetimeRangePickerProps> = ({
  name,
  value,
  label,
  onChange,
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const dateRange = getFilterByName(name) ?? value;

  const handleDatetimeRangePicker = (value: IDateRangePickerValue) => {
    const requestValue: DatetimeRangePickerValue = [value.start, value.end];

    setFilter(name, requestValue);
    onChange && onChange(requestValue);
  };

  const valueOfDateRangePickerComponent = React.useMemo(() => {
    return {
      start: dateRange ? dateRange[0] : undefined,
      end: dateRange ? dateRange[1] : undefined,
    };
  }, [dateRange]);

  useInitFilterEffect(name, value);

  return (
    <Box>
      <DateRangePicker
        id={name}
        label={label}
        value={valueOfDateRangePickerComponent}
        onChange={handleDatetimeRangePicker}
      />
    </Box>
  );
};
