import React from 'react';
import {
  PageContentChildContainer,
  PageContentTab,
  PageContentTabPanel,
  PageContentTabs,
  PageContentTabsList,
} from 'src/components/PageContent';
import { ChartsDashboardHeader } from 'src/components/ChartsDashboard';
import { Box } from '@mui/joy';
import { MainLayoutContext } from 'src/components/Layout/MainLayout/MainLayoutContext';
import { PoliciesCreateByPage } from './PoliciesCreateByPage';
import { PoliciesCreateByOperation } from './PolicesCreateByOpeartion';

const PolicesCreateFromPageSettingsConfig = () => {
  const { headerHeight } = React.useContext(MainLayoutContext);

  const [activeTab, setActiveTab] = React.useState<number | null>(0);

  const getPageTitleByActiveTabNumber = (activeTabNumber: number | null) => {
    if (activeTabNumber === 0) {
      return 'By page';
    }
    if (activeTabNumber === 1) {
      return 'By operation';
    }

    return '';
  };

  return (
    <PageContentTabs
      value={activeTab}
      onChange={(_, value) =>
        setActiveTab(value !== null ? Number(value) : value)
      }
    >
      <PageContentTabsList>
        <PageContentTab>By pages (view)</PageContentTab>
        <PageContentTab>By entities operations</PageContentTab>
      </PageContentTabsList>

      <Box sx={{ height: `calc(100vh - ${headerHeight}px)`, overflow: 'auto' }}>
        <PageContentChildContainer sx={{ pt: 2 }} fullHeight={false}>
          <ChartsDashboardHeader>
            {getPageTitleByActiveTabNumber(activeTab)}
          </ChartsDashboardHeader>
        </PageContentChildContainer>

        {activeTab === 0 && (
          <PageContentTabPanel value={0}>
            <PoliciesCreateByPage />
          </PageContentTabPanel>
        )}
        {activeTab === 1 && (
          <PageContentTabPanel sx={{ flexGrow: 1 }} value={1}>
            <PoliciesCreateByOperation />
          </PageContentTabPanel>
        )}
      </Box>
    </PageContentTabs>
  );
};

export default PolicesCreateFromPageSettingsConfig;
