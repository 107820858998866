import { JSONSchemaType } from 'ajv';
import { IUpdateTotalPayment } from '../../types/incentive';

export const updateIncentiveTotalPaymentScheme: JSONSchemaType<IUpdateTotalPayment> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: true,
    required: ['id', 'totalPayment'],
    properties: {
      id: {
        type: 'number',
      },
      totalPayment: {
        type: 'number',
      },
      firstName: {
        type: 'string',
        nullable: true,
      },
      lastName: {
        type: 'string',
        nullable: true,
      },
      approved: {
        type: 'boolean',
        nullable: true,
      },
    },
  },
};
