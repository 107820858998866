import { JSONSchemaType } from 'ajv';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from 'src/modules/utils/i18n';
import { IIncentivePriceCreateRequest } from 'src/modules/types/incentive';

export const createIncentivePriceScheme: JSONSchemaType<IIncentivePriceCreateRequest> = {
  type: 'object',
  required: ['siteId', 'price'],
  additionalProperties: true,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      type: 'number',
    },
    price: {
      type: 'number',
    },
    metadata: {
      type: 'array',
      nullable: true,
      items: {
        type: 'object',
        required: ['key', 'value'],
        properties: {
          key: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
      },
    },
  },
};
