import { ISagaActionBind } from '../../types';
import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListRequest,
    saga: createListSaga<ICardinalHealthWeeklyReportModel>({
      apiCall: Api.CardinalHealthWeeklyUphBySiteSkuAndEmployee.list,
      actionSuccess:
        actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListSuccess,
      actionFailed:
        actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteSkuAndEmployee.count,
      actionSuccess:
        actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountSuccess,
      actionFailed:
        actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyUphBySiteSkuAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteSkuAndEmployee.export,
      actionSuccess:
        actions.exportCardinalHealthWeeklyUphBySiteSkuAndEmployeeSuccess,
      actionFailed:
        actions.exportCardinalHealthWeeklyUphBySiteSkuAndEmployeeFailed,
    }),
  },
];
