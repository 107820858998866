import React from 'react';
import Filter, { IFilterItem, IFilterItemData, IFilterProps } from '../Filter';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerHeader } from '../_ui-kit/Drawer';

export type ITableFilter = IFilterItem;
export type ITableFilterData = IFilterItemData;

interface IEnhancedTableFilterProps {
  filterProps: IFilterProps;
  isOpen: boolean;
  onClose: () => void;
}

const EnhancedTableFilter = ({
  filterProps,
  isOpen,
  onClose,
}: IEnhancedTableFilterProps) => {
  const { t } = useTranslation();

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>{t('common.filters')}</DrawerHeader>
      <Filter {...filterProps} onClose={onClose} />
    </Drawer>
  );
};

export default EnhancedTableFilter;
