import React from 'react';
import {
  IUseEmployeesInComboBox,
  useEmployeesInComboBox,
} from 'src/modules/utils';
import { find, get } from 'lodash';
import ComboBox, { ComboBoxOption, IComboboxProps } from '../../../ComboBox';
import { AnyObject } from '../../../../modules/types';
import { ErrorMode } from '../../types';

interface IEmployeesComboBoxProps
  extends Omit<IComboboxProps, 'options'>,
    IUseEmployeesInComboBox {
  id: string;
  formik: AnyObject;
  siteFieldName?: string;
  siteAs?: 'name' | 'id';
  errorMode?: ErrorMode;
  onChange?: (
    event: React.ChangeEvent<AnyObject>,
    value: (ComboBoxOption & { entity?: AnyObject }) | null,
  ) => void;
}

export const EmployeesComboBox: React.FC<IEmployeesComboBoxProps> = ({
  id,
  onlyActive,
  propertyAsID,
  siteFieldName,
  siteAs,
  formik,
  onChange,
  errorMode,
  ...restProps
}) => {
  let employees = useEmployeesInComboBox({ onlyActive, propertyAsID });

  const site = get(formik, ['values', siteFieldName ?? '']);

  if (siteAs && siteFieldName && site) {
    employees = employees.filter((e) => {
      switch (siteAs) {
        case 'id':
          return e.entity.siteId === site;
        case 'name':
          return e.entity?.site?.name === site;
      }
    });
  }

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: (ComboBoxOption & { entity?: AnyObject }) | null,
  ) => {
    const v = propertyAsID
      ? get(value, ['entity', propertyAsID], value?.id)
      : value?.id;
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, value ? v : null);

    if (onChange) {
      onChange(event, value ?? null);
    }
  };
  const option =
    find(
      employees,
      (i) =>
        (propertyAsID ? i.entity[propertyAsID] : i.id) ===
        get(formik.values, id),
    ) || null;

  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  return (
    <ComboBox
      {...restProps}
      id={id}
      options={employees}
      value={option}
      onChange={handleChange}
      error={showError}
      helperText={showError && error}
    />
  );
};
