import { JSONSchemaType } from 'ajv';
import reduce from 'lodash/reduce';
import {
  AnyObject,
  ICreatePricingRangeItemRequest,
  ICreatePricingRangeRequest,
  ICreatePricingSingleRequest,
  IPricingMeta,
  PricingSettingsConfig,
} from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { get, set } from 'lodash';

// export const pricingRegex = '^[+-]?([0-9]+\\.?[0-9]*|\\.[0-9]+)$';
export const pricingRegex = '^[+-]?\\d{0,16}(\\.\\d{1,6})?$';

export const generateMetatags = (settings: PricingSettingsConfig) =>
  reduce<AnyObject, AnyObject>(
    settings,
    (acc, cur) => {
      if (!cur.disabled) {
        !acc.required.includes(cur.name) && acc.required.push(cur.name);
        if (cur.type === 'range') {
          !acc.required.includes('metadata') && acc.required.push('metadata');

          set(
            acc,
            ['metadata', 'required'],
            [...get(acc, ['metadata', 'required'], []), cur.name],
          );
          set(acc, ['metadata', 'type'], 'object');
          set(acc, ['metadata', 'properties', cur.name], {
            type: 'object',
            required: ['min', 'max'],
            properties: {
              min: {
                type: 'number',
                errorMessage: {
                  type: i18n.t('error.validation.required'),
                },
              },
              max: {
                type: 'number',
                errorMessage: {
                  type: i18n.t('error.validation.required'),
                },
              },
            },
          });
        }

        acc.props[cur.name] = {
          isNotEmpty: true,
          default: '',
          type: 'string',
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
          },
        };
      }

      return acc;
    },
    { metadata: {}, props: {}, required: [] },
  );

export const generateCreatePricingSingleScheme = (
  settings: PricingSettingsConfig,
): JSONSchemaType<ICreatePricingSingleRequest> => {
  const props = generateMetatags(settings);

  return ({
    type: 'object',
    required: [
      'siteId',
      'price',
      'incentivePrice',
      'overtimePrice',
      'minUph',
      'maxUph',
      'description',
      ...props.required,
    ],
    additionalProperties: true,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      siteId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      price: {
        type: 'string',
        pattern: pricingRegex,
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
          pattern: i18n.t('error.validation.price'),
        },
      },
      incentivePrice: {
        type: 'string',
        pattern: pricingRegex,
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
          pattern: i18n.t('error.validation.price'),
        },
      },
      overtimePrice: {
        type: 'string',
        pattern: pricingRegex,
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
          pattern: i18n.t('error.validation.price'),
        },
      },
      minUph: {
        default: NaN,
        isNotEmpty: true,
        type: 'string',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      maxUph: {
        default: NaN,
        isNotEmpty: true,
        type: 'string',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      description: {
        default: '',
        nullable: true,
        type: 'string',
      },
      metadata: props.metadata,
      ...props.props,
    },
  } as unknown) as JSONSchemaType<ICreatePricingSingleRequest>;
};

export const createPricingRangeItemScheme: JSONSchemaType<ICreatePricingRangeItemRequest> = {
  type: 'object',
  required: ['price', 'incentivePrice', 'overtimePrice', 'maxUph'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required_short'),
  },
  properties: {
    price: {
      type: 'string',
      pattern: pricingRegex,
      errorMessage: {
        type: i18n.t('error.validation.required_short'),
        isNotEmpty: i18n.t('error.validation.required_short'),
        pattern: i18n.t('error.validation.price'),
      },
    },
    incentivePrice: {
      type: 'string',
      pattern: pricingRegex,
      errorMessage: {
        type: i18n.t('error.validation.required_short'),
        isNotEmpty: i18n.t('error.validation.required_short'),
        pattern: i18n.t('error.validation.price'),
      },
    },
    overtimePrice: {
      type: 'string',
      pattern: pricingRegex,
      errorMessage: {
        type: i18n.t('error.validation.required_short'),
        isNotEmpty: i18n.t('error.validation.required_short'),
        pattern: i18n.t('error.validation.price'),
      },
    },
    minUph: {
      default: NaN,
      type: 'string',
      errorMessage: {
        type: i18n.t('error.validation.required_short'),
      },
    },
    maxUph: {
      default: NaN,
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        type: i18n.t('error.validation.required_short'),
        isNotEmpty: i18n.t('error.validation.required_short'),
      },
    },
    description: {
      default: '',
      type: 'string',
      nullable: true,
    },
    metadata: {
      type: 'object',
      nullable: true,
      required: [],
    },
  },
};

export const generateMetatagsScheme = (
  settings: PricingSettingsConfig,
): JSONSchemaType<IPricingMeta> => {
  const { metadata, props, required } = generateMetatags(settings);
  return {
    type: 'object',
    required: required,
    additionalProperties: false,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      ...props,
      metadata,
    },
  };
};

export const generateCreatePricingRangeScheme = (
  settings: PricingSettingsConfig,
): JSONSchemaType<ICreatePricingRangeRequest> => {
  const { metadata, props, required } = generateMetatags(settings);
  return {
    type: 'object',
    required: ['siteId', 'bulks', ...required],
    additionalProperties: false,
    properties: {
      siteId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      bulks: {
        type: 'array',
        items: createPricingRangeItemScheme,
      },
      ...props,
      metadata,
    },
  };
};
