import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getShiftOptionsByGloballySites } from '../../selectors/shift';
import {
  AnyObject,
  ICountResponse,
  IFilterData,
  IFilterInclude,
  IFilterWhere,
  IShiftModel,
} from '../../types';
import { get, isEmpty, map } from 'lodash';
import { getShiftComboboxListRequest } from '../../actions/shift';
import { useSiteRelationInclusion } from '.';
import { useTranslation } from 'react-i18next';
import { useDataFetcherWithData } from './common/reports';
import { IHeadCellWithOrderConfig } from '../../types/table';
import { useGenerateHeadCellsData } from './table';
import { useDynamicBaseReport } from './reports.hooks';
import { useHasUserAccessToAction } from '../../../config';
import { manageEntitiesConfig } from '../../../config/manageEntitiesConfig';
import { getGloballySelectedSites } from '../../selectors/site';
import { NoIcon, YesIcon } from '../../../components/Icons';

const useShiftsComboboxFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          name: true,
          siteId: true,
        },
        include: [siteRelationInclusion],
        order: ['name ASC'],
      },
    }),
    [siteRelationInclusion],
  );
};

/**
 * A custom hook to fetch shift combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchShiftsCombobox = () => {
  const dispatcher = useDispatch();
  const shiftComboboxFilter = useShiftsComboboxFilter();

  const dispatchGetShiftsComboboxListRequest = React.useCallback(() => {
    dispatcher(getShiftComboboxListRequest(shiftComboboxFilter));
  }, [shiftComboboxFilter, dispatcher]);

  // fetch combobox list from store
  const combobox = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatchGetShiftsComboboxListRequest();
    }
  };
};

export const useShiftsPermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.shift.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.shift.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.shift.delete.id,
  );
  const allowedToCreateSettings = useHasUserAccessToAction(
    manageEntitiesConfig.shift.create_settings.id,
  );

  return {
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
    allowedToCreateSettings,
  };
};

export interface IShiftsFilterPanelFilters {
  name?: string;
  startTime?: string;
  endTime?: string;
  overnight?: boolean;
  desiredEmployeesNumber?: number;
  default?: boolean;
  siteId?: number;
  duration?: number;
}

export const useShiftTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      startTime: {
        value: '',
        property: 'startTime',
        operator: 'gte' as const,
      },
      endTime: {
        value: '',
        property: 'endTime',
        operator: 'lte' as const,
      },
      overnight: {
        value: '',
        property: 'overnight',
        operator: 'eq' as const,
      },
      desiredEmployeesNumber: {
        value: '',
        property: 'desiredEmployeesNumber',
        operator: 'eq' as const,
      },
      default: {
        value: '',
        property: 'default',
        operator: 'eq' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      duration: {
        value: '',
        property: 'duration',
        operator: 'eq' as const,
      },
    }),
    [],
  );

export const useShiftInclusion = () => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo(
    () => [
      {
        relation: 'site',
        scope: {
          ...(!isEmpty(selectedSites)
            ? {
                where: {
                  id: {
                    inq: selectedSites,
                  },
                },
              }
            : {}),
        },
      },
    ],
    [selectedSites],
  );
};

export const useShiftComposeWhere = (where: Partial<IShiftModel>) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.name
        ? {
            name: {
              like: `%${where.name}%`,
            },
          }
        : {}),
      ...(where.overnight
        ? {
            overnight: {
              eq: where.overnight,
            },
          }
        : {}),
      ...(where.delay
        ? {
            delay: {
              eq: where.delay,
            },
          }
        : {}),
      ...(where.desiredEmployeesNumber
        ? {
            desiredEmployeesNumber: {
              eq: where.desiredEmployeesNumber,
            },
          }
        : {}),
      ...(where.default
        ? {
            default: {
              eq: where.default,
            },
          }
        : {}),
      ...(where.duration
        ? {
            duration: {
              eq: where.duration,
            },
          }
        : {}),
      ...(where.siteId
        ? {
            siteId: {
              eq: where.siteId,
            },
          }
        : {}),
    }),
    [
      where.default,
      where.delay,
      where.desiredEmployeesNumber,
      where.duration,
      where.name,
      where.overnight,
      where.siteId,
    ],
  );
};

export const useShiftFilter = (appliedFilters: IShiftsFilterPanelFilters) => {
  const _include = useShiftInclusion();
  const _where = useShiftComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};

export const useShiftTableInclusionObject = () => {
  return React.useMemo(
    () => ({
      site: {
        relationType: 'inner',
      },
    }),
    [],
  );
};

export const useShift = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();
  const inclusionObj = useShiftTableInclusionObject();

  const initialData: AnyObject[] = [];

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'id',
        orderConfig: { orderBy: 'id' },
        label: t('shifts.id'),
      },
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('shifts.name'),
      },
      {
        id: 'startTime',
        orderConfig: { orderBy: 'startTime' },
        label: t('shifts.start_time'),
      },
      {
        id: 'endTime',
        orderConfig: { orderBy: 'endTime' },
        label: t('shifts.end_time'),
      },
      {
        id: 'overnight',
        orderConfig: { orderBy: 'overnight' },
        label: t('shifts.overnight'),
      },
      {
        id: 'delay',
        orderConfig: { orderBy: 'delay' },
        label: t('shifts.delay'),
      },
      {
        id: 'desiredEmployeesNumber',
        orderConfig: { orderBy: 'desiredEmployeesNumber' },
        label: t('shifts.desiredEmployeesNumber'),
      },
      {
        id: 'lunchMin',
        orderConfig: { orderBy: 'lunchMin' },
        label: t('shifts.lunchMin'),
      },
      {
        id: 'lunchAdj',
        orderConfig: { orderBy: 'lunchAdj' },
        label: t('shifts.lunchAdj'),
      },
      {
        id: 'default',
        orderConfig: { orderBy: 'default' },
        label: t('shifts.default'),
      },
      {
        id: 'site.name',
        orderConfig: { orderBy: 'site.name' },
        label: t('shifts.site_name'),
      },
      {
        id: 'duration',
        orderConfig: { orderBy: 'duration' },
        label: t('shifts.duration'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const report = useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(report.data, (item) => {
        return {
          ...item,
          overnight: item.overnight ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          default: item.default ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          lunchMin: !!get(item, 'site.lunchAdj') ? (
            item.lunchMin
          ) : (
            <NoIcon color="primary" />
          ),
          lunchAdj: !!get(item, 'site.lunchAdj') ? (
            item.lunchAdj
          ) : (
            <NoIcon color="primary" />
          ),
          desiredEmployeesNumber: item.desiredEmployeesNumber
            ? item.desiredEmployeesNumber
            : '',
        };
      }),
    [report.data],
  );

  return {
    ...report,
    data: computedList,
  };
};
