import React from 'react';
import { AnyObject } from 'src/modules/types';
import EnhancedTable, { IEnhancedTableProps } from '.';
import Accordion from '../Accordion';

type ITableInPageWithTabsProps<O> = IEnhancedTableProps<O> & {
  renderInAccordion?: boolean;
};

export const TableInPageWithTabs = <T extends AnyObject>({
  tableName,
  renderInAccordion = false,
  ...props
}: React.PropsWithChildren<ITableInPageWithTabsProps<T>>) => {
  if (!renderInAccordion) {
    return (
      <EnhancedTable
        tableName={tableName}
        sxOfTableContainer={{ maxHeight: `calc(100vh - 300px)` }}
        {...props}
      />
    );
  }

  return (
    <Accordion cardTitle={tableName} fullWidth>
      <EnhancedTable
        hideTableName
        paperEvaluation={0}
        tableName={tableName}
        sxOfTableContainer={{ maxHeight: `calc(100vh - 300px)` }}
        {...props}
      />
    </Accordion>
  );
};
