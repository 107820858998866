import {
  ICardinalHealthDailyReportModel,
  ICardinalHealthDailyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthDailyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthDailyUphBySiteAndSkuListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteAndSkuListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteAndSkuListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthDailyReportState,
  ICardinalHealthDailyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthDailyUphBySiteAndSkuCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteAndSkuCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteAndSkuCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthDailyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthDailyUphBySiteAndSku = createReducer<
  ICardinalHealthDailyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthDailyUphBySiteAndSkuListRequest,
    getCardinalHealthDailyUphBySiteAndSkuListRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndSkuListSuccess,
    getCardinalHealthDailyUphBySiteAndSkuListSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndSkuListFailed,
    getCardinalHealthDailyUphBySiteAndSkuListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthDailyUphBySiteAndSkuCountRequest,
    getCardinalHealthDailyUphBySiteAndSkuCountRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndSkuCountSuccess,
    getCardinalHealthDailyUphBySiteAndSkuCountSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndSkuCountFailed,
    getCardinalHealthDailyUphBySiteAndSkuCountFailed,
  );

export default cardinalHealthDailyUphBySiteAndSku;
