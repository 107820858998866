import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { YearWeekRangePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/YearWeekRangePickerFilter';
import { AnyObject } from 'src/modules/types';

interface IProps {
  onSubmit(data: AnyObject): void;
  filters: AnyObject;
}

const HistoricalKpiFilter: React.FC<IProps> = ({ onSubmit, filters }) => {
  const { t } = useTranslation();

  const filtersConfiguration = React.useMemo(
    () => ({
      yearWeek: {
        value: filters.yearWeek ?? [],
        property: 'yearWeek',
        operator: 'between' as const,
      },
    }),
    [filters.yearWeek],
  );

  const { getLabel, getFilterCommonPropsByFilterName } = useFilterFieldsData({
    filterFieldsConfiguration: filtersConfiguration,
  });

  return (
    <Filter onSubmit={onSubmit}>
      <FormFieldContainer>
        <YearWeekRangePickerFilter
          label={getLabel({
            filterName: 'yearWeek',
            labelPrefix: t('kpi.yearWeek'),
          })}
          {...getFilterCommonPropsByFilterName('yearWeek')}
        />
      </FormFieldContainer>
    </Filter>
  );
};

export default HistoricalKpiFilter;
