import React from 'react';
import get from 'lodash/get';
import pullAt from 'lodash/pullAt';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, CardActions, Typography } from '@mui/material';

import ResourcesWithActionsComboBox from '../../../components/Formik/comboboxes-with-entities/ResourcesWithActionsComboBox';
import ClientKeysComboBox from '../../../components/Formik/comboboxes-with-entities/ClientKeysCombobox';
import { AnyObject } from '../../../modules/types';
import { MUI_SIZE_1, MUI_SIZE_2 } from '../../../config';

interface IPageSettingPolicyProps {
  formikCardIndex: number;
  policyIndex: number;
  formik: AnyObject;
}

const PageSettingPolicy: React.FC<IPageSettingPolicyProps> = ({
  formikCardIndex,
  formik,
  policyIndex,
}) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    const value = cloneDeep(get(formik.values, formikCardIndex));
    pullAt(value.policies, policyIndex);
    formik.setFieldValue(formikCardIndex, value);
  };

  return (
    <React.Fragment>
      <Card variant="outlined" sx={{ marginBottom: MUI_SIZE_2 }}>
        <CardContent>
          <Typography variant="body1" component="div" pb={MUI_SIZE_1}>
            {t('page_settings.policy_number', { index: policyIndex + 1 })}
          </Typography>

          <ResourcesWithActionsComboBox
            id={`${formikCardIndex}.policies.${policyIndex}.id`}
            action={`${formikCardIndex}.policies.${policyIndex}.action`}
            resource={`${formikCardIndex}.policies.${policyIndex}.resource`}
            required={true}
            formik={formik}
            placeholder={t('policies.v1')}
          />

          <ClientKeysComboBox
            id={`${formikCardIndex}.policies.${policyIndex}.clientKey`}
            formik={formik}
            placeholder={t('policies.v3')}
          />
        </CardContent>
        <CardActions>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            {t('common.remove')}
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default PageSettingPolicy;
