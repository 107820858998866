import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateQuestionCategoryRequest,
  IdsArray,
  IFilter,
  IQuestionCategoryModel,
  ISagaAction,
  ISagaActionBind,
  IUpdateQuestionCategoryRequest,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

export const createQuestionCategoryRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<ICreateQuestionCategoryRequest>
>): SagaIterator {
  try {
    const response: IQuestionCategoryModel = yield call(
      Api.QuestionCategory.create,
      data,
    );
    yield put(actions.createQuestionCategorySuccess(response));

    yield put(actions.getQuestionCategoryListRequest({ filter: filters.list }));
    yield put(
      actions.getQuestionCategoryCountRequest({ filter: filters.count }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'question_category.successfully_created',
      }),
    );
  } catch (e) {
    yield put(actions.createQuestionCategoryFailed());
  }
};

export const getQuestionCategoryListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IQuestionCategoryModel[] = yield call(
      Api.QuestionCategory.list,
      action.payload,
    );
    yield put(actions.getQuestionCategoryListSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionCategoryListFailed());
  }
};

export const getQuestionCategoryCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.QuestionCategory.count,
      action?.payload,
    );
    yield put(actions.getQuestionCategoryCountSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionCategoryCountFailed());
  }
};

export const deleteQuestionCategoryRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.QuestionCategory.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getQuestionCategoryListRequest({ filter: filters.list }));
    yield put(
      actions.getQuestionCategoryCountRequest({ filter: filters.count }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'question_category.successfully_deleted_many'
            : 'question_category.successfully_deleted',
      }),
    );
  } catch (e) {
    yield put(actions.deleteQuestionCategoryFailed());
  }
};

export const updateQuestionCategoriesRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<IUpdateQuestionCategoryRequest[]>
>): SagaIterator {
  try {
    yield call(Api.QuestionCategory.bulkUpdate, data);

    yield put(actions.getQuestionCategoryListRequest({ filter: filters.list }));
    yield put(
      actions.getQuestionCategoryCountRequest({ filter: filters.count }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'question_category.successfully_updated_many'
            : 'question_category.successfully_updated',
      }),
    );
  } catch (e) {
    yield put(actions.updateQuestionCategoriesFailed());
  }
};

export const getQuestionCategoryComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IQuestionCategoryModel>[] = yield call(
      Api.QuestionCategory.list,
      action.payload,
    );
    yield put(actions.getQuestionCategoryComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionCategoryComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.QuestionCategory.export,
  actionFailed: actions.exportQuestionCategoryFailed,
  actionSuccess: actions.exportQuestionCategorySuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportQuestionCategoryRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createQuestionCategoryRequest,
    saga: createQuestionCategoryRequestSaga,
  },
  {
    action: actions.getQuestionCategoryListRequest,
    saga: getQuestionCategoryListRequestSaga,
  },
  {
    action: actions.getQuestionCategoryCountRequest,
    saga: getQuestionCategoryCountRequestSaga,
  },
  {
    action: actions.deleteQuestionCategoryRequest,
    saga: deleteQuestionCategoryRequestSaga,
  },

  {
    action: actions.updateQuestionCategoriesRequest,
    saga: updateQuestionCategoriesRequestSaga,
  },
  {
    action: actions.getQuestionCategoryComboboxListRequest,
    saga: getQuestionCategoryComboboxListRequestSaga,
  },
  {
    action: actions.exportQuestionCategoryRequest,
    saga: exportQuestionCategoryRequestSaga,
  },
];
