import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  ICreateEmployeeSkillAttachment,
  IdsArray,
  IEmployeeSkillAttachmentModel,
  IEmployeeSkillModel,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../types';
import { ExportSagaCreator } from '../utils/helpers/creators/export';

/**
 * Get employeeSkill list
 */
export const getEmployeeSkillListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IEmployeeSkillModel[] = yield call(
      Api.EmployeeSkill.list,
      action.payload,
    );
    yield put(actions.getEmployeeSkillListSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeSkillListFailed());
  }
};

/**
 * Get employeeSkill count
 */
export const getEmployeeSkillCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.EmployeeSkill.count,
      action?.payload,
    );
    yield put(actions.getEmployeeSkillCountSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeSkillCountFailed());
  }
};

/**
 * Bulk update employeeSkills
 */
export const updateEmployeeSkillsRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IEmployeeSkillModel[]>>): SagaIterator {
  try {
    yield call(Api.EmployeeSkill.bulkUpdate, data);

    yield put(actions.getEmployeeSkillListRequest({ filter: filters.list }));
    yield put(actions.getEmployeeSkillCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'emp_skills.skill_successfully_updated',
      }),
    );
  } catch (e) {
    yield put(actions.updateEmployeeSkillsFailed());
  }
};

/**
 * Get combobox list
 */
export const getEmployeeSkillComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IEmployeeSkillModel>[] = yield call(
      Api.EmployeeSkill.list,
      action.payload,
    );
    yield put(actions.getEmployeeSkillComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getEmployeeSkillComboboxListFailed());
  }
};

/**
 * Export
 */
export const {
  exportRequestSaga: exportEmployeeSkillRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.EmployeeSkill.export,
  actionFailed: actions.exportEmployeeSkillFailed,
  actionSuccess: actions.exportEmployeeSkillSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

/**
 * Create attachment
 */
export const createEmployeeSkillAttachmentRequestSaga = function* ({
  payload,
}: ISagaAction<ICreateEmployeeSkillAttachment>): SagaIterator {
  try {
    const response: IEmployeeSkillAttachmentModel = yield call(
      Api.EmployeeSkill.createAttachment,
      payload,
    );
    yield put(actions.createEmployeeSkillAttachmentSuccess(response));
  } catch (e) {
    yield put(actions.createEmployeeSkillAttachmentFailed());
  }
};

export const deleteEmployeeSkillAttachmentRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.EmployeeSkill.deleteAttachment, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.deleteEmployeeSkillAttachmentSuccess(action.payload));
  } catch (e) {
    yield put(actions.deleteEmployeeSkillAttachmentFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getEmployeeSkillListRequest,
    saga: getEmployeeSkillListRequestSaga,
  },
  {
    action: actions.getEmployeeSkillCountRequest,
    saga: getEmployeeSkillCountRequestSaga,
  },
  {
    action: actions.updateEmployeeSkillsRequest,
    saga: updateEmployeeSkillsRequestSaga,
  },
  {
    action: actions.getEmployeeSkillComboboxListRequest,
    saga: getEmployeeSkillComboboxListRequestSaga,
  },
  {
    action: actions.exportEmployeeSkillRequest,
    saga: exportEmployeeSkillRequestSaga,
  },
  {
    action: actions.createEmployeeSkillAttachmentRequest,
    saga: createEmployeeSkillAttachmentRequestSaga,
  },
  {
    action: actions.deleteEmployeeSkillAttachmentRequest,
    saga: deleteEmployeeSkillAttachmentRequestSaga,
  },
];
