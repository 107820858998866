import React from 'react';
import {
  FilterPanel,
  IFilterPanelOption,
  IFilterPanelProps,
} from 'src/components/FilterPanel';
import { FilterPanelSync } from 'src/components/FilterPanel/FilterPanelActions';
import { ClientKey } from 'src/modules/constants';
import { useHasUserAccessToAction } from '../../../config';
import { manageEntitiesConfig } from '../../../config/manageEntitiesConfig';
import { get } from 'lodash';

export interface IReportPageFilterPanelProps
  extends Pick<
    IFilterPanelProps,
    'renderFilter' | 'onApplySelectedFilters' | 'onRemoveAllOptions'
  > {
  client?: ClientKey;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const ReportPageFilterPanel = ({
  hideSyncBtn,
  renderFilter,
  filtersOptionsConfig,
  client,
  ...restFilterPanelProps
}: IReportPageFilterPanelProps) => {
  const key = get(manageEntitiesConfig, [client ?? '', 'sync', 'id'], '');

  const allowedToSync = useHasUserAccessToAction(key);

  if (!filtersOptionsConfig) {
    return null;
  }

  return (
    <FilterPanel
      renderFilter={renderFilter}
      filterOptionsConfig={filtersOptionsConfig}
      {...restFilterPanelProps}
    >
      {hideSyncBtn || !allowedToSync ? undefined : (
        <FilterPanelSync client={client} />
      )}
    </FilterPanel>
  );
};
