import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import { getOtherCostsListRequest } from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import { getOtherCostsByIds } from '../../selectors/other-costs.selector';
import { useSiteRelationInclusion } from './sites';

export const useOtherCostsInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => [siteRelationInclusion], [siteRelationInclusion]);
};

/**
 * A custom hook to fetch otherCosts from store if they exist otherwise to make a request to fetch needed otherCosts from
 * the server
 */
export const useFetchOtherCostsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch otherCosts list from store
  const otherCosts = useSelector(
    (state) => getOtherCostsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  const otherCostsInclusions = useOtherCostsInclusion();

  return () => {
    if (isEmpty(otherCosts)) {
      dispatcher(
        getOtherCostsListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: otherCostsInclusions,
          },
        }),
      );
    }
  };
};

export const useOtherCostsDefaultFilters = () => {
  const otherCostsInclusions = useOtherCostsInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: otherCostsInclusions,
    }),
    [otherCostsInclusions],
  );
};
