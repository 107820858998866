import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  IdsArray,
  IFilterData,
  IUpdateOtherCosts,
  IWhere,
} from '../../../../../../modules/types';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { updateOtherCostsRequest } from '../../../../../../modules/actions';
import { Sheet } from '@mui/joy';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { updateOtherCostsScheme } from '../../../../../../modules/schemes';
import { map } from 'lodash';
import { getOtherCostsByIds } from '../../../../../../modules/selectors/other-costs.selector';
import { formFieldsTranslationDictionary } from './CreateOtherCosts';
import FormikNumberField from '../../../../../../components/Formik/FormikNumberField';
import FormikCheckbox from '../../../../../../components/Formik/FormikCheckbox';

interface IUpdateOtherCostsProps {
  isOpen: boolean;
  onClose: () => void;
  idsToUpdate: IdsArray;
  filterList: IFilterData;
  filterCount: IWhere;
}

const UpdateOtherCosts = ({
  isOpen,
  onClose,
  idsToUpdate,
  filterList,
  filterCount,
}: IUpdateOtherCostsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(updateOtherCostsScheme);

  const otherCosts = useSelector(getOtherCostsByIds)(idsToUpdate);

  const formik = useFormikInUpdateForm({
    initialValues: otherCosts.map((otherCostsItem) => ({
      ...otherCostsItem,
      value: String(otherCostsItem.value),
    })),
    validate,
    onSubmit: (data: IUpdateOtherCosts[]) => {
      dispatch(
        updateOtherCostsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('other-costs.update_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {formik.values.map((otherCosts, index) => (
            <DrawerBodySectionContent key={otherCosts.id}>
              <DrawerBodySectionTitle>
                {t('other-costs.update_title', { id: otherCosts.id })}
              </DrawerBodySectionTitle>
              <Sheet sx={{ bgcolor: 'inherit' }}>
                <FormFieldContainer>
                  <SitesComboBox
                    id={`[${index}].siteId`}
                    formik={formik}
                    placeholder={t('payboard.site')}
                    required
                  />
                </FormFieldContainer>

                {map(['yearNumber', 'weekNumber'], (key) => (
                  <FormFieldContainer key={key}>
                    <FormikNumberField
                      id={`${index}.${key}`}
                      variant="outlined"
                      required={['yearNumber', 'weekNumber'].includes(key)}
                      label={t(
                        formFieldsTranslationDictionary[`other-costs.${key}`],
                      )}
                      name={`${index}.${key}`}
                      formik={formik}
                    />
                  </FormFieldContainer>
                ))}

                <FormFieldContainer>
                  <FormikCheckbox
                    required
                    id={`${index}.isRepeatable`}
                    label={t('productions_uph_reports.isRepeatable')}
                    formik={formik}
                  />
                </FormFieldContainer>

                {map(['value', 'description'], (key) => (
                  <FormFieldContainer key={key}>
                    <FormikTextField
                      id={`${index}.${key}`}
                      variant="outlined"
                      required={['value', 'description'].includes(key)}
                      fullWidth
                      label={t(
                        formFieldsTranslationDictionary[`other-costs.${key}`],
                      )}
                      name={`${index}.${key}`}
                      formik={formik}
                    />
                  </FormFieldContainer>
                ))}
              </Sheet>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default UpdateOtherCosts;
