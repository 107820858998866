import { RolesType } from '../../types';
import { DEFAULT_ROUTE, paths, roles } from '../../../config';

export const getLandingRouteBasedOnRole = (role: RolesType | ''): string => {
  switch (role) {
    case roles.EXECUTIVE:
      return paths.EXECUTIVE_SUMMARY;
    default:
      return DEFAULT_ROUTE;
  }
};
