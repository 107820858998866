import { JSONSchemaType } from 'ajv';
import { ICreateOpenPosition } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { TFunction } from 'i18next';

export const createOpenPositionScheme: JSONSchemaType<ICreateOpenPosition> = {
  type: 'object',
  required: [
    'positionId',
    'numberNeeded',
    'shiftId',
    'dateStart',
    'siteId',
    'departmentId',
    'skills',
  ],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    positionId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    currentDuration: {
      default: NaN,
      type: 'number',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.number'),
      },
    },
    numberNeeded: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    shiftId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    departmentId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    dateStart: {
      type: 'string',
      format: 'date',
      default: '',
      isNotEmpty: true,
      errorMessage: {
        format: i18n.t('error.validation.date'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    description: {
      type: 'string',
      nullable: true,
      errorMessage: {
        format: i18n.t('error.validation.string'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    requisitionNumber: {
      type: 'string',
      nullable: true,
      maxLength: 10,
      errorMessage: {
        isNotEmpty: (i18n.t as TFunction)('error.validation.maxLength', {
          number: 10,
        }),
      },
    },
    skills: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'number',
      },
      errorMessage: {
        minItems: i18n.t('error.validation.at_least_one'),
      },
    },
  },
};
