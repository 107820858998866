import React, { Dispatch, SetStateAction } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { fontFamilyMap, sizeMap } from './utils/SlateUtilityFunctions';
import withLinks from './plugins/withLinks';
import withTables from './plugins/withTable';
import withEmbeds from './plugins/withEmbeds';
import { deserializeFromHtml, serializeToHtml } from './utils/helpers';

import Toolbar from './Toolbar/Toolbar';
import Link from './Elements/Link/Link';
import Image from './Elements/Image/Image';
import Video from './Elements/Video/Video';

import styles from './Editor.module.css';

const initialValue = [{ type: 'paragraph', children: [{ text: '' }] }];

const Element = (props: any) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'headingOne':
      return <h1 {...attributes}>{children}</h1>;
    case 'headingTwo':
      return <h2 {...attributes}>{children}</h2>;
    case 'headingThree':
      return <h3 {...attributes}>{children}</h3>;
    case 'blockquote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'alignLeft':
      return (
        <div
          style={{ textAlign: 'left', listStylePosition: 'inside' }}
          {...attributes}
        >
          {children}
        </div>
      );
    case 'alignCenter':
      return (
        <div
          style={{ textAlign: 'center', listStylePosition: 'inside' }}
          {...attributes}
        >
          {children}
        </div>
      );
    case 'alignRight':
      return (
        <div
          style={{ textAlign: 'right', listStylePosition: 'inside' }}
          {...attributes}
        >
          {children}
        </div>
      );
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'orderedList':
      return (
        <ol type="1" {...attributes}>
          {children}
        </ol>
      );
    case 'unorderedList':
      return <ul {...attributes}>{children}</ul>;
    case 'link':
      return <Link {...props} />;

    case 'table':
      return (
        <table className="slate-custom-table">
          {children}
          {/*<tbody {...attributes}>{children}</tbody>*/}
        </table>
      );

    case 'table-row':
      return <tr {...attributes}>{children}</tr>;
    case 'table-cell':
      return <td {...attributes}>{children}</td>;
    case 'image':
      return <Image {...props} />;
    case 'video':
      return <Video {...props} />;
    case 'block':
      return <div {...props}>{children}</div>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    children = (
      <span style={{ textDecoration: 'line-through' }}>{children}</span>
    );
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }
  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }
  if (leaf.bgColor) {
    children = (
      <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
    );
  }
  if (leaf.fontSize) {
    const size = sizeMap[leaf.fontSize];
    children = <span style={{ fontSize: size }}>{children}</span>;
  }
  if (leaf.fontFamily) {
    const family = fontFamilyMap[leaf.fontFamily];
    children = <span style={{ fontFamily: family }}>{children}</span>;
  }
  return <span {...attributes}>{children}</span>;
};

const getEditorValue = (html: string | undefined) => {
  if (!html) {
    return null;
  }

  const document = new DOMParser().parseFromString(html, 'text/html');
  return deserializeFromHtml(document.body);
};

const SlateEditor = ({
  content,
  setContent,
  initialContent,
}: {
  setContent: Dispatch<SetStateAction<string | undefined>>;
  content?: string;
  initialContent?: string;
}) => {
  const editor = React.useMemo(
    () =>
      withHistory(withEmbeds(withTables(withLinks(withReact(createEditor()))))),
    [],
  );
  const editorValue = getEditorValue(initialContent) ?? initialValue;
  const [value, setValue] = React.useState(editorValue);

  React.useEffect(() => {
    if (!content) {
      return;
    }
    setValue(editorValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialContent]);

  const renderElement = React.useCallback(
    (props) => <Element {...props} />,
    [],
  );

  const renderLeaf = React.useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const html = value.map((v: any) => serializeToHtml(v)).join('');
  React.useEffect(() => {
    setContent(html);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  return (
    <Slate
      editor={editor as any}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    >
      <Toolbar />
      <div
        className={styles['editor-wrapper']}
        style={{ border: '1px solid #f3f3f3', padding: '0 10px' }}
      >
        <Editable
          placeholder="Write something..."
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </div>
    </Slate>
  );
};

export default SlateEditor;
