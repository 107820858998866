import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray, IUpdatePoint } from 'src/modules/types';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFormDataFieldsForUpdateEntityForm } from 'src/modules/utils/helpers/form';
import { updatePointsScheme } from 'src/modules/schemes/points';
import { updatePointsRequest } from 'src/modules/actions';
import { getPointTypesComboboxList } from 'src/modules/selectors/pointType';
import { getEmployeesComboboxList } from 'src/modules/selectors/employee';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import { EmployeesComboBox } from 'src/components/Formik/comboboxes-with-entities/EmployeesComboBox';
import FormikImageUpload from 'src/components/Formik/FormikImageUpload';
import { getPointsTable } from 'src/modules/selectors/point';

interface IUpdatePointsProps {
  pointsIds: IdsArray;
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const UpdatePoints = ({
  isOpen,
  onClose,
  filterCount,
  filterList,
  pointsIds,
}: IUpdatePointsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(updatePointsScheme);

  const pointsIdsNumber = pointsIds.map(Number);

  const allPoints = useSelector(getPointsTable);

  const points = allPoints.filter((point) =>
    pointsIdsNumber.includes(point.id),
  );

  const formik = useFormik({
    initialValues: points,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      const formData = generateFormDataFieldsForUpdateEntityForm(
        data.map((item) => {
          return {
            id: item.id,
            employeeId: item.employeeId,
            datetime: item.datetime,
            pointTypeId: item.pointTypeId,
            file: item.attachment,
          };
        }),
        ['attachment'],
      );

      dispatch(
        updatePointsRequest({
          data: (formData as unknown) as IUpdatePoint[],
          filters: {
            list: filterList,
            count: filterCount,
          },
        }),
      );

      onClose();
    },
  });

  const pointTypes = useSelector(getPointTypesComboboxList);

  const employeesCombobox = useSelector(getEmployeesComboboxList);

  const generatePointTypesOptionsBySiteId = React.useCallback(
    (index) => {
      const selectedEmployeeDetails = employeesCombobox.find(
        (employee) => employee.id === points[index].employeeId,
      );

      if (!selectedEmployeeDetails) {
        return [];
      }

      return pointTypes.filter(
        (pointType) =>
          pointType.siteId === selectedEmployeeDetails.entity.siteId,
      );
    },
    [employeesCombobox, points, pointTypes],
  );

  const getAttachmentName = (attachment: string | File) => {
    if (attachment instanceof File) {
      return attachment.name;
    }

    const attachmentUrlArray = (attachment ?? '').split('/');

    return attachmentUrlArray[attachmentUrlArray.length - 1];
  };

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>{t('points.update')}</DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {points.map((point, index) => (
            <DrawerBodySectionContent key={point.id}>
              <DrawerBodySectionTitle>
                {`${point.employee?.firstName} ${point.employee?.lastName} (${point.pointType?.reason})`}
              </DrawerBodySectionTitle>
              <FormFieldContainer>
                <FormikDatetimepicker
                  required
                  fullWidth
                  label={t('points.datetime')}
                  id={`${index}.datetime`}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <EmployeesComboBox
                  required
                  onlyActive
                  label={t('points.employee')}
                  placeholder={t('common.select')}
                  id={`${index}.employeeId`}
                  formik={formik}
                  propertyAsID="id"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id={`${index}.pointTypeId`}
                  label={t('points.type')}
                  placeholder={t('common.select')}
                  options={
                    generatePointTypesOptionsBySiteId(index) as ComboBoxOption[]
                  }
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikImageUpload
                  formik={formik}
                  name={`${index}.attachment`}
                  isImageFromPropsSourceOfTruth
                  image={formik.values[index]?.attachment}
                  file={{
                    name: formik.values[index]?.attachment
                      ? getAttachmentName(formik.values[index].attachment!)
                      : undefined,
                  }}
                  inputId={`create_point_attachment_${point.employee?.id}_${point.datetime}_${point.pointType?.id}`}
                />
              </FormFieldContainer>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
