import Api from '.';
import { IFilterOrder, IWhere } from 'src/modules/types';
import {
  IHRChangesActiveBySiteAndStaffingProviderDataItem,
  IHRChangesMainIndicatorsData,
  IHrChangesAddedBySiteDataItem,
  IHrChangesLostBySiteDataItem,
  IhHRChangesTerminatedBySiteAndReasonDataItem,
} from 'src/modules/types/dashboard';

export const fetchHRChangesMainIndicators = async (where?: IWhere) => {
  const response = await Api.Request.get<IHRChangesMainIndicatorsData>(
    `recruiting-dashboard/hr-changes-statistic`,
    { filter: { where } },
  );

  return response;
};

export const fetchHRChangesAddedBySite = async (
  where?: IWhere,
  order?: IFilterOrder,
) => {
  const response = await Api.Request.get<{
    items: Array<IHrChangesAddedBySiteDataItem>;
  }>(`turnover/added`, { filter: { where, order } });

  return response.items;
};

export const fetchHRChangesLostBySite = async (
  where?: IWhere,
  order?: IFilterOrder,
) => {
  const response = await Api.Request.get<{
    items: Array<IHrChangesLostBySiteDataItem>;
  }>(`turnover/lost`, { filter: { where, order } });

  return response.items;
};

export const fetchHRChangesTerminatedBySiteAndReason = async (
  where?: IWhere,
  order?: IFilterOrder,
) => {
  const response = await Api.Request.get<{
    items: Array<IhHRChangesTerminatedBySiteAndReasonDataItem>;
  }>(`turnover/terminated`, { filter: { where, order } });

  return response.items;
};

export const fetchHRChangesActiveBySiteAndStaffingProvider = async (
  where?: IWhere,
  order?: IFilterOrder,
) => {
  const response = await Api.Request.get<{
    items: Array<IHRChangesActiveBySiteAndStaffingProviderDataItem>;
  }>(`turnover/active`, { filter: { where, order } });

  return response.items;
};
