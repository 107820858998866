import React, { useEffect } from 'react';
import { map, reduce, set } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getUsersByIds } from '../../modules/selectors/user';
import {
  AnyObject,
  IdsArray,
  IStoreState,
  IUpdateUser,
  IUpdateUserWithAttributes,
} from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import {
  useFetchClientsCombobox,
  useFetchStaffingProvidersCombobox,
  useFetchUsers,
  usePrepareUserWithAttributes,
  useQueryParams,
  useBrowserHistoryFunctions,
  useFetchShiftsCombobox,
  useFetchActiveDepartmentsCombobox,
  useUpdateUserValidationScheme,
  useFetchUsersSitesCombobox,
} from '../../modules/utils/hooks';
import { getCurrentRole } from '../../modules/selectors/auth';
import { useTranslation } from 'react-i18next';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import { FormActions } from '../../components/Form/FormActions';
import { useValidate } from '../../modules/utils';
import { ComboBoxOption } from '../../components/ComboBox';
import RolesCombobox from '../../components/Formik/comboboxes-with-entities/RolesCombobox';
import { useRenderUserOtherFields } from '../../modules/utils/hooks/useRenderUserOtherFields';
import { getSitesComboboxList } from '../../modules/selectors/site';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import { getClientsComboboxList } from '../../modules/selectors/client';
import { updateUsersRequest } from '../../modules/actions';
import { getShiftsComboboxListBySites } from '../../modules/selectors/shift';
import { getDepartmentsComboboxListBySites } from '../../modules/selectors/department';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IUpdateUserProps {
  users: IUpdateUser[];
  onSubmit: (users: IUpdateUser[]) => void;
}

const UserUpdateForm = ({ users, onSubmit }: IUpdateUserProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line
  const [roles, setRoles] = React.useState<string[]>([]);

  const schema = useUpdateUserValidationScheme(roles);

  const validate = useValidate(schema);

  const formik = useFormik({
    initialValues: users,
    enableReinitialize: true,
    validate,
    onSubmit,
  });

  // fetch comboboxes lists
  const fetchSitesCombobox = useFetchUsersSitesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchStaffingProviderCombobox = useFetchStaffingProvidersCombobox();
  const fetchClientsCombobox = useFetchClientsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    setRoles(formik.initialValues.map((u) => u.role!));
  }, [formik.initialValues]);

  // make request to fetch staffing providers from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchStaffingProviderCombobox();
    fetchClientsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // fetch sites list from store
  const clients = useSelector(
    getClientsComboboxList,
    shallowEqual,
  ) as ComboBoxOption[];
  const sites = useSelector(getSitesComboboxList, shallowEqual);
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  ) as ComboBoxOption[];

  const siteIds = React.useMemo(
    () =>
      reduce<IUpdateUser & { sites?: number[] }, number[]>(
        formik.values,
        (acc, cur) => {
          acc = cur.sites ? [...acc, ...cur.sites] : [...acc];
          return acc;
        },
        [],
      ),
    [formik.values],
  );

  // fetch shifts from store
  const shifts = useSelector(
    getShiftsComboboxListBySites,
    shallowEqual,
  )(siteIds);

  const departments = useSelector(
    getDepartmentsComboboxListBySites,
    shallowEqual,
  )(siteIds);

  const getOtherFields = useRenderUserOtherFields({
    formik,
    sites,
    shifts,
    departments,
    staffingProviders,
    clients,
  });

  const onRoleChange = (index: number) => (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    if (value?.id) {
      setRoles((prev) => {
        const newRoles = [...prev];
        set(newRoles, [index], value.id);
        return newRoles;
      });
    }
  };

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (user, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('users.update_title', user)}
          key={user.id}
        >
          <FormFieldContainer>
            <RolesCombobox
              required
              id={`${index}.role`}
              name={`${index}.role`}
              onChange={onRoleChange(index)}
              label={t('common.role')}
              placeholder={t('common.role')}
              fieldAsId="key"
              formik={formik}
            />
          </FormFieldContainer>

          {getOtherFields(index)}
        </CreateForm>
      ))}
      {formik.values.some((user) => user.role) && (
        <FormActions submitBtnTitle={t('common.update')} />
      )}
    </UpdateFormLayout>
  );
};

const UsersUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const prepareRequestData = usePrepareUserWithAttributes<
    IUpdateUserWithAttributes
  >();

  const fetchUsers = useFetchUsers(ids);

  // fetch users list from store
  const users = useSelector(
    (state) => getUsersByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch users from the server if we don't have them in the store
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated users
   */
  const handleFormSubmit = (data: IUpdateUser[]) => {
    dispatcher(
      updateUsersRequest({
        data: data.map((i) =>
          prepareRequestData(i, {
            siteId: true,
            clientId: true,
            staffingId: true,
          }),
        ),
        navigate,
      }),
    );
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <UserUpdateForm users={users} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default UsersUpdate;
