import React from 'react';
import { IdsArray, IStoreState } from '../types';
import { createSelector } from 'reselect';
import { filter, memoize } from 'lodash';
import { getGloballySelectedSites } from './site';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const getInvoiceRefreshKey = (state: IStoreState) =>
  state.invoice.refreshKey;

export const getInvoiceData = (state: IStoreState) => state.invoice;

export const getInvoiceList = ({ invoice }: IStoreState) => invoice.list;

export const getMonthInvoicesList = createSelector(
  getInvoiceData,
  (data) => data.monthList,
);

export const getInvoiceListBySelectedSites = createSelector(
  getInvoiceList,
  getGloballySelectedSites,
  (invoiceList, selectedSites) =>
    invoiceList.filter(({ siteId }) => selectedSites.includes(siteId)),
);

export const getMonthInvoiceListBySelectedSites = createSelector(
  getMonthInvoicesList,
  getGloballySelectedSites,
  (invoiceList, selectedSites) =>
    invoiceList.filter(({ siteId }) => selectedSites.includes(siteId)),
);

export const getInvoiceListForTable = createSelector(
  getInvoiceListBySelectedSites,
  (list) => {
    return list.map((item) => ({
      ...item,
      invoiceDate: item.invoiceDate,
      weekEndingDate: item.weekEndingDate,
      isUnchangeable: item.isUnchangeable ? <YesIcon /> : <NoIcon />,
      dueDate: item.dueDate ? item.dueDate : null,
    }));
  },
);
export const getMonthInvoiceListForTable = createSelector(
  getMonthInvoiceListBySelectedSites,
  (list) => {
    return list.map((item) => ({
      ...item,
      isUnchangeable: item.isUnchangeable ? <YesIcon /> : <NoIcon />,
      dueDate: item.dueDate ? item.dueDate : null,
    }));
  },
);

export const getInvoicesByIds = createSelector(getInvoiceList, (invoices) =>
  memoize((ids: IdsArray) =>
    filter(invoices, (invoice) => ids.includes(invoice.id)),
  ),
);

export const getMonthInvoicesByIds = createSelector(
  getMonthInvoicesList,
  (invoices) =>
    memoize((ids: IdsArray) =>
      filter(invoices, (invoice) => ids.includes(invoice.monthInvoiceId)),
    ),
);

export const getInvoiceCount = ({ invoice }: IStoreState) => invoice.count;

export const getMonthInvoiceCount = ({ invoice }: IStoreState) =>
  invoice.monthCount;

export const getInvoiceDetails = ({ invoice }: IStoreState) =>
  invoice.invoiceDetails;

export const getIsWeeklyInvoicesDataLoading = createSelector(
  getInvoiceData,
  ({
    is_weekly_invoices_list_loading,
    is_weekly_invoices_count_loading,
    is_weekly_invoices_managing,
  }) =>
    is_weekly_invoices_list_loading ||
    is_weekly_invoices_count_loading ||
    is_weekly_invoices_managing,
);

export const getIsMonthlyInvoicesDataLoading = createSelector(
  getInvoiceData,
  ({
    is_monthly_invoices_list_loading,
    is_monthly_invoices_count_loading,
    is_monthly_invoices_managing,
  }) =>
    is_monthly_invoices_list_loading ||
    is_monthly_invoices_count_loading ||
    is_monthly_invoices_managing,
);
