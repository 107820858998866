import { i18n } from '../modules/utils/i18n';
import { UserConfigField, UserConfigFieldWithKey } from '../modules/types';

export const additionalUserProperties = [
  'hasSites',
  'hasWmsLogin',
  'hasShift',
  'hasDepartment',
  'hasSupervisor',
  'hasClients',
  'hasStaffing',
];

export const commonUserFields: UserConfigField[] = [
  {
    name: 'firstName',
    validationKey: 'firstName',
    label: i18n.t('users.first_name'),
    type: 'text',
    required: true,
  },
  {
    name: 'lastName',
    validationKey: 'lastName',
    label: i18n.t('users.last_name'),
    type: 'text',
    required: true,
  },
  {
    name: 'email',
    validationKey: 'email',
    label: i18n.t('users.email'),
    type: 'email',
    required: true,
  },
  {
    name: 'phoneNumber',
    validationKey: 'phoneNumber',
    label: i18n.t('users.phoneNumber'),
    type: 'text',
  },
  {
    name: 'lang',
    validationKey: 'lang',
    label: i18n.t('common.language'),
    type: 'lang',
    required: true,
  },
];

export const additionalUserFields: UserConfigFieldWithKey[] = [
  {
    key: 'hasWmsLogin',
    name: 'wmsLogin',
    validationKey: 'wmsLogin',
    label: i18n.t('users.wmsLogin'),
    type: 'text',
  },
  {
    key: 'hasShift',
    name: 'shiftId',
    validationKey: 'shiftId',
    label: i18n.t('users.shiftId'),
    type: 'shift',
  },
  {
    key: 'hasDepartment',
    name: 'departmentId',
    validationKey: 'departmentId',
    label: i18n.t('users.departmentId'),
    type: 'department',
  },
  {
    key: 'hasSupervisor',
    name: 'supervisorId',
    validationKey: 'supervisorId',
    label: i18n.t('users.supervisorId'),
    type: 'supervisor',
  },
  {
    key: 'hasSites',
    name: 'sites',
    validationKey: 'sites',
    required: true,
    label: i18n.t('users.site'),
    type: 'sites',
  },
  {
    key: 'hasStaffing',
    name: 'staffings[0]',
    validationKey: 'staffings',
    required: true,
    label: i18n.t('users.staff_prov'),
    type: 'staffings',
  },
  {
    key: 'hasClients',
    name: 'clients[0]',
    required: true,
    validationKey: 'clients',
    label: i18n.t('users.client'),
    type: 'clients',
  },
];
