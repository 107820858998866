import { JSONSchemaType } from 'ajv';
import { ICreateStandardRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const generateCreateStandardScheme = (
  metatags: string[],
): JSONSchemaType<ICreateStandardRequest> => {
  const metatagsScheme = metatags.reduce((scheme, key) => {
    scheme[key] = {
      isNotEmpty: true,
      default: '',
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    };

    return scheme;
  }, {});

  return {
    type: 'object',
    required: ['siteId', 'standard', 'baseTime', ...metatags],
    additionalProperties: false,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      siteId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      standard: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      baseTime: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...metatagsScheme,
    },
  };
};
