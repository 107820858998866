import React, { useCallback, useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStandardsByIds } from '../../modules/selectors/standard';
import {
  useFetchMetatagsByIds,
  useFetchSitesCombobox,
  useQueryParams,
} from '../../modules/utils/hooks';
import { IdsArray, IStoreState, IUpdateStandard } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchStandards } from '../../modules/utils/hooks';
import { updateStandardsRequest } from '../../modules/actions';
import { getCurrentRole } from '../../modules/selectors/auth';
import { getMetatagsNames } from '../../modules/selectors/metatag';
import { useTranslation } from 'react-i18next';
import {} from '../../modules/actions';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';

import { useValidate } from '../../modules/utils';
import { generateUpdateStandardScheme } from '../../modules/schemes/standards';
import { useBrowserHistoryFunctions } from '../../modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IUpdateStandardProps {
  standards: IUpdateStandard[];
  onSubmit: (standards: IUpdateStandard[]) => void;
}

const StandardUpdateForm = ({ onSubmit, standards }: IUpdateStandardProps) => {
  const { t } = useTranslation();

  const metatagNames = useSelector(getMetatagsNames, shallowEqual);

  const sitesIdSet = new Set<number>();
  standards.forEach(({ siteId }) => sitesIdSet.add(siteId));
  const sitesId = [...sitesIdSet.values()];
  const metatags = sitesId.reduce<string[]>((all, siteId) => {
    if (metatagNames[siteId]) {
      return [...all, ...metatagNames[siteId]];
    }
    return all;
  }, []);
  const updateStandardsScheme = generateUpdateStandardScheme(metatags);
  const validate = useValidate(updateStandardsScheme);

  const formik = useFormikInUpdateForm({
    initialValues: standards,
    validate,
    onSubmit,
  });
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchMetatags = useFetchMetatagsByIds();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch clients and standards from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesCombobox();
    fetchMetatags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // get metatags names by site id
  const getMetatagsBySite = useCallback(
    (siteId: number) => metatagNames[siteId] ?? [],
    [metatagNames],
  );

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (standard, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('standards.update_title', standard)}
          key={standard.id}
        >
          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('standards.standard')}
              id={`${index}.standard`}
              name={`${index}.standard`}
              autoComplete="standard"
              formik={formik}
              type="number"
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('standards.base_time')}
              id={`${index}.baseTime`}
              name={`${index}.baseTime`}
              autoComplete="baseTime"
              formik={formik}
              type="number"
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          {map(getMetatagsBySite(standard.siteId), (metatag: string) => (
            <FormFieldContainer key={`metatag-${metatag}`}>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                label={metatag}
                id={`${index}.${metatag}`}
                name={`${index}.${metatag}`}
                autoComplete={metatag}
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          ))}
        </CreateForm>
      ))}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const StandardsUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids, siteId } = (useQueryParams() as unknown) as {
    ids: IdsArray;
    siteId: number;
  };

  const fetchStandards = useFetchStandards(siteId, ids);

  // fetch standards list from store
  const standards = useSelector(
    (state) => getStandardsByIds(state as IStoreState)(siteId, ids),
    shallowEqual,
  );

  // make request to fetch standards from the server if we don't have them in the store
  useEffect(() => {
    fetchStandards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated standards
   */
  const handleFormSubmit = (data: IUpdateStandard[]) => {
    dispatcher(updateStandardsRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <StandardUpdateForm standards={standards} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default StandardsUpdate;
