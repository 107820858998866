import React from 'react';

import {
  useBrowserHistoryFunctions,
  useFilter,
  useProductionDailyReportsDefaultFilters,
  useProductionWeeklyReportsDefaultFilters,
  useSearch,
} from '../../modules/utils';
import {
  FetchedData,
  useReportWithOwnState,
} from '../../modules/utils/hooks/common/reports';
import { useReportExportProps } from '../../modules/utils/hooks/reports.hooks';
import { roles } from '../../config';

import EnhancedTable, {
  IEnhancedTableProps,
  ITableSyncProps,
} from '../EnhancedTable';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserRoles } from '../../modules/selectors/user';
import { IFilterData, IdsArray } from '../../modules/types';

interface Props<O>
  extends Omit<
    IEnhancedTableProps<O>,
    | 'onSync'
    | 'data'
    | 'count'
    | 'summaryRows'
    | 'selectIndex'
    | 'defaultOrderBy'
  > {
  reportUrl: string;
  reportType?: 'daily' | 'weekly';
  defaultOrderBy?: Extract<keyof O, string>;
  defaultFilterData?: IFilterData;
  disableSelectionInTable?: boolean;
  disableDeletionInTable?: boolean;
  enableUpdationInTable?: boolean;
  ignoreGlobalSites?: boolean;
  parseData?: (items: FetchedData<O>) => FetchedData<O>;
}

const allowedDeleteRoles = [roles.SUPER_ADMIN];

function ReportWithOwnState<T>({
  reportUrl,
  defaultOrderBy,
  defaultOrder = 'desc',
  reportType = 'daily',
  defaultFilterData,
  disableSelectionInTable,
  disableDeletionInTable,
  enableUpdationInTable,
  parseData,
  ignoreGlobalSites,
  ...restEnhancedTableProps
}: Props<T>) {
  const { data, fetchData, deleteRows } = useReportWithOwnState<T>(reportUrl);
  const exportProps = useReportExportProps(reportUrl, ignoreGlobalSites);
  const composeSearch = useSearch();
  const { pushToHistory } = useBrowserHistoryFunctions();
  // const { items, count, summary } = data;

  // for Daily and Weekly reports different hooks
  const getDefaultFilterHook =
    reportType === 'weekly'
      ? useProductionWeeklyReportsDefaultFilters
      : useProductionDailyReportsDefaultFilters;
  const defaultFilter =
    defaultFilterData ?? getDefaultFilterHook(defaultOrderBy);

  const { filterList, filterCount } = useFilter(defaultFilter);

  // define is user can delete rows
  let selectIndex = '';
  let disableSelection = true;
  const userRoles = useSelector(getUserRoles, shallowEqual) ?? [];
  const hasUserPermission = userRoles.some((role) =>
    allowedDeleteRoles.includes(role),
  );
  if (hasUserPermission) {
    selectIndex = 'id'; // define index for selection
    disableSelection = false; // enable row selection
  }
  const handleDelete = React.useCallback(
    async (ids: IdsArray) => {
      await deleteRows(ids);
      await fetchData(filterList, filterCount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterList, filterCount],
  );

  const handleUpdate = React.useCallback(
    async (ids: IdsArray) => {
      // handle updating
      const search = composeSearch({ ids });
      pushToHistory({ pathname: `${reportUrl}/update`, search });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportUrl],
  );

  // fetch report on mount
  React.useEffect(() => {
    fetchData(filterList, filterCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    fetchData(
      {
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      },
      { filter: { where } },
    );
  };

  const { items, count, summary } = React.useMemo(
    () => (parseData ? parseData(data) : data),
    [data, parseData],
  );

  return (
    <EnhancedTable
      data={items}
      count={count}
      summaryRows={summary}
      selectIndex={selectIndex}
      defaultOrder={defaultOrder}
      defaultOrderBy={defaultOrderBy}
      disableSelection={disableSelectionInTable ?? disableSelection}
      onSync={onSync}
      onDelete={disableDeletionInTable ? undefined : handleDelete}
      onUpdate={enableUpdationInTable ? handleUpdate : undefined}
      exportProps={exportProps}
      additionalWhereForExport={defaultFilter.where}
      {...restEnhancedTableProps}
    />
  );
}

export default ReportWithOwnState;
