import React from 'react';
import {
  AutocompleteRenderOptionState,
  Box,
  Typography,
  useTheme,
} from '@mui/joy';

export const getSlotProps = ({
  width = 'auto',
  minWidth,
  theme,
}: {
  minWidth: number;
  theme: ReturnType<typeof useTheme>;
  width?: number | string;
}) => {
  return {
    root: {
      sx: {
        width,
        minWidth,
        borderWidth: 0,
        boxShadow: 'none',
        bgcolor: 'transparent',
        borderRadius: 0,
        '&:focus-within': {
          outlineWidth: 0,
        },
      },
    },
    input: {
      sx: {
        ...theme.typography.text_xs,
        color: theme.palette.colors.text.text_primary.main,
      },
    },
    popupIndicator: {
      sx: {
        '&:hover': {
          bgcolor: 'transparent',
        },
      },
    },
  };
};

export const renderOption = (
  props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
  option: any,
  state: AutocompleteRenderOptionState,
) => {
  return (
    <Box
      component="li"
      {...props}
      sx={{
        p: 1,
        mx: 1,
        borderRadius: '6px',
        cursor: 'pointer',
        bgcolor: state.selected ? 'colors.background.bg_active' : 'transparent',
        '&:hover': {
          bgcolor: 'colors.background.bg_active',
        },
        '&:not(:last-of-type)': {
          mb: '1px',
        },
      }}
    >
      <Typography level="text_sm" textColor="colors.text.text_primary.main">
        {option.label}
      </Typography>
    </Box>
  );
};

export const size = 'sm';
