import React from 'react';
import Highcharts, { SeriesPieOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/joy';
import { highchartsReactComponentProps } from '../common';

exporting(Highcharts);

interface IPieChartProps {
  height: string;
  fullscreenTitle?: string;
  series: SeriesPieOptions[];
  chart?: Highcharts.ChartOptions;
  yAxis?: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions>;
  plotOptions?: Highcharts.PlotOptions;
  xAxis?: Omit<Highcharts.XAxisOptions, 'categories'>;
}

export const PieChart = React.forwardRef<Highcharts.Chart, IPieChartProps>(
  (
    { yAxis, xAxis, plotOptions, series, height, chart, fullscreenTitle },
    chartRef,
  ) => {
    const options: Highcharts.Options = {
      chart: {
        type: 'pie',
        ...(fullscreenTitle
          ? {
              events: {
                fullscreenOpen: function () {
                  (this as any).update({
                    title: {
                      text: fullscreenTitle,
                      align: 'left',
                    },
                  });
                },
                fullscreenClose: function () {
                  (this as any).update({
                    title: {
                      text: '',
                    },
                  });
                },
              },
            }
          : {}),
        ...chart,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      yAxis: yAxis
        ? yAxis
        : {
            title: {
              enabled: false,
            } as Highcharts.YAxisTitleOptions,
          },
      xAxis,

      credits: {
        enabled: false,
      },
      plotOptions: {
        ...plotOptions,
      },
      legend: {
        enabled: true,
      },
      series,

      exporting: {
        enabled: false,
      },
    };

    return (
      <Box sx={{ minWidth: '100%', height }}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
          containerProps={highchartsReactComponentProps.containerProps}
        />
      </Box>
    );
  },
);
