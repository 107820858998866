import React, { useEffect } from 'react';
import { map, pick } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getRolesByIds } from '../../modules/selectors/role';
import { useQueryParams, useValidate } from '../../modules/utils/hooks';
import { IdsArray, IStoreState, IUpdateRole } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchRolesByIds } from '../../modules/utils/hooks';
import { updateRolesRequest } from '../../modules/actions/role';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { updateRolesScheme } from '../../modules/schemes/roles';
import { useBrowserHistoryFunctions } from '../../modules/utils/hooks';
import { additionalUserProperties } from '../../config/userFields';
import FormikCheckbox from '../../components/Formik/FormikCheckbox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IRoleUpdateProps {
  roles: IUpdateRole[];
  onSubmit: (roles: IUpdateRole[]) => void;
}

const RoleUpdateForm = ({ onSubmit, roles }: IRoleUpdateProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateRolesScheme);

  const formik = useFormikInUpdateForm({
    initialValues: roles,
    validate,
    onSubmit,
  });

  const getCheckboxes = React.useCallback(
    (index: number) =>
      additionalUserProperties.map((i) => (
        <FormFieldContainer key={`${index}.${i}`}>
          <FormikCheckbox
            id={`${index}.${i}`}
            formik={formik}
            label={t(`roles.${i}`)}
          />
        </FormFieldContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, t],
  );

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (role, index) => {
        return (
          <CreateForm
            defaultExpanded={index === 0}
            cardTitle={t('roles.update_title', role)}
            key={role.id}
          >
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id={`${index}.name`}
                label={t('roles.name')}
                name={`${index}.name`}
                autoComplete="name"
                formik={formik}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            {getCheckboxes(index)}
          </CreateForm>
        );
      })}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const RolesUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchRoles = useFetchRolesByIds(ids);

  // fetch roles list from store
  const roles = useSelector(
    (state) => getRolesByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch roles from the server if we don't have them in the store
  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated roles
   */
  const handleFormSubmit = (data: IUpdateRole[]) => {
    dispatcher(
      updateRolesRequest({
        data: data.map(
          (i) =>
            pick(i, ['id', 'name', ...additionalUserProperties]) as IUpdateRole,
        ),
        navigate,
      }),
    );
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <RoleUpdateForm roles={roles} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default RolesUpdate;
