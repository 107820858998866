import {
  IProductionWeeklyUphBySiteAndSkuModel,
  IProductionWeeklyUphBySiteAndSkuState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionWeeklyUphBySiteAndSkuState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionWeeklyUphBySiteAndSkuListRequest,
  getListSuccess: getProductionWeeklyUphBySiteAndSkuListSuccess,
  getListFailed: getProductionWeeklyUphBySiteAndSkuListFailed,
} = reducerCreator.takeListHandlers<
  IProductionWeeklyUphBySiteAndSkuState,
  IProductionWeeklyUphBySiteAndSkuModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionWeeklyUphBySiteAndSkuCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteAndSkuCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteAndSkuCountFailed,
} = reducerCreator.takeCountHandlers<IProductionWeeklyUphBySiteAndSkuState>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionWeeklyUphBySiteAndSkuRequest,
  exportSuccess: exportProductionWeeklyUphBySiteAndSkuSuccess,
  exportFailed: exportProductionWeeklyUphBySiteAndSkuFailed,
} = new ExportReducerCreator().takeHandlers<
  IProductionWeeklyUphBySiteAndSkuState
>();

/**
 * actually Reducer
 */
export const productionWeeklyUphBySiteAndSku = createReducer<
  IProductionWeeklyUphBySiteAndSkuState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionWeeklyUphBySiteAndSkuListRequest,
    getProductionWeeklyUphBySiteAndSkuListRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndSkuListSuccess,
    getProductionWeeklyUphBySiteAndSkuListSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndSkuListFailed,
    getProductionWeeklyUphBySiteAndSkuListFailed,
  )
  // get count actions
  .on(
    actions.getProductionWeeklyUphBySiteAndSkuCountRequest,
    getProductionWeeklyUphBySiteAndSkuCountRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndSkuCountSuccess,
    getProductionWeeklyUphBySiteAndSkuCountSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndSkuCountFailed,
    getProductionWeeklyUphBySiteAndSkuCountFailed,
  )
  // export action
  .on(
    actions.exportProductionWeeklyUphBySiteAndSkuRequest,
    exportProductionWeeklyUphBySiteAndSkuRequest,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteAndSkuSuccess,
    exportProductionWeeklyUphBySiteAndSkuSuccess,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteAndSkuFailed,
    exportProductionWeeklyUphBySiteAndSkuFailed,
  );

export default productionWeeklyUphBySiteAndSku;
