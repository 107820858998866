import React from 'react';
import { isEqual } from 'lodash';
import {
  Autocomplete,
  AutocompleteOption,
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Sheet,
  Typography,
  useTheme,
} from '@mui/joy';

import { ChevronDownSvg } from '../svgIcons';
import { useTranslation } from 'react-i18next';

export type MultiselectOption<T = string | number> = {
  id: T;
  name: string;
};

export type MultiselectOptionWithEntity<T> = MultiselectOption & {
  entity: T;
};

export interface IMultiselectPartialProps {
  id: string;
  options: MultiselectOption[];
  onChange?: (values: MultiselectOption[]) => void;
  values?: MultiselectOption['id'][];
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
}

export type IMultiselect = IMultiselectPartialProps &
  Pick<InputProps, 'variant'> & {
    helperText?: string;
    minHeight?: number;
  };

export default function Multiselect({
  error,
  placeholder,
  required,
  variant = 'outlined',
  helperText,
  label,
  minHeight = 40,
  values: _values,
  options,
  onChange,
  ...autocompleteProps
}: IMultiselect) {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const [chipWidth, setChipWidth] = React.useState(0);

  const { t } = useTranslation();

  const theme = useTheme();

  const getOptionLabel = (option: MultiselectOption) => option.name;

  const getOptionSelected = (
    option: MultiselectOption,
    value: MultiselectOption,
  ) => isEqual(option, value);

  const values = React.useMemo(() => {
    return options.filter((o) => _values?.includes(o.id));
  }, [_values, options]);

  React.useEffect(() => {
    if (containerRef.current) {
      setChipWidth(containerRef.current.offsetWidth - 75);
    }
  }, []);

  return (
    <Sheet sx={{ width: '100%', bgcolor: 'inherit' }}>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Autocomplete
          multiple
          disableClearable
          options={options}
          value={values}
          error={error}
          onChange={(_, values) => onChange && onChange(values)}
          {...autocompleteProps}
          popupIcon={
            <ChevronDownSvg
              fill={theme.palette.colors.foreground.fg_quinary}
              width={20}
              height={20}
            />
          }
          required={required}
          getOptionLabel={getOptionLabel}
          placeholder={placeholder ?? t('common.type_to_search')}
          variant={variant}
          renderOption={(props, option) => {
            return (
              <AutocompleteOption
                {...props}
                key={props.id ?? option.id}
                sx={{ py: '9px', px: '10px' }}
              >
                <Typography
                  // noWrap
                  level="text_sm"
                  fontWeight="medium"
                  textColor="colors.text.text_secondary.hover"
                >
                  {option.name}
                </Typography>
              </AutocompleteOption>
            );
          }}
          isOptionEqualToValue={getOptionSelected}
          openText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          closeText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          slotProps={{
            root: {
              ref: containerRef,
              sx: {
                minHeight,
                height: 'auto',
                '& .MuiChip-label': {
                  maxWidth: chipWidth,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              },
            },
            input: {
              sx: {
                display: 'block',
                '::placeholder': {
                  fontWeight: theme.fontWeight.regular,
                  color: theme.palette.colors.text.text_placeholder,
                  ...theme.typography.text_md,
                },
              },
            },
          }}
        />
        {error && helperText && (
          <FormHelperText>
            <Typography
              level="text_sm"
              fontWeight="regular"
              sx={{ color: theme.palette.colors.text.text_error_primary.main }}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    </Sheet>
  );
}
