import { IProductionWeeklyUphBySiteAndSkuModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionWeeklyUphBySiteAndSku',
);

// get list actions
export const {
  getListRequest: getProductionWeeklyUphBySiteAndSkuListRequest,
  getListSuccess: getProductionWeeklyUphBySiteAndSkuListSuccess,
  getListFailed: getProductionWeeklyUphBySiteAndSkuListFailed,
} = actionCreator.takeListActions<IProductionWeeklyUphBySiteAndSkuModel>();

// get count actions
export const {
  getCountRequest: getProductionWeeklyUphBySiteAndSkuCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteAndSkuCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteAndSkuCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionWeeklyUphBySiteAndSkuRequest,
  exportReportSuccess: exportProductionWeeklyUphBySiteAndSkuSuccess,
  exportReportFailed: exportProductionWeeklyUphBySiteAndSkuFailed,
} = new ExportActionCreator(
  'ProductionWeeklyUphBySiteAndSku',
).takeExportActions();
