import React from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Edit02Svg, EyeSvg, Trash04Svg } from 'src/components/svgIcons';
import { IStoreState, IdsArray } from 'src/modules/types';
import { EnhancedTableDeleteEntitiesConfirmation } from '../EnhancedTableDeleteEntitiesConfiramtion';
import { useSelector } from 'react-redux';
import { getDeleteRelations } from 'src/modules/selectors/deleteRelations';
import { isEmpty } from 'lodash';
import { useFetchDeleteRelations } from 'src/modules/utils';
import { roles } from 'src/config';
import { getUserRoles } from 'src/modules/selectors/user';
import { SxProps } from '@mui/joy/styles/types';

export interface ICustomAction {
  title: string;
  icon: React.ReactElement;
  onClick: (ids: IdsArray) => void;
  positionIndex?: number;
  resetSelected?: boolean;
  containerSx?: SxProps;
}
interface IEnhancedTableSelectedItemsActionsProps {
  selectedItemsIds: Array<string>;
  onUpdate?: (ids: IdsArray) => void;
  onDelete?: (ids: IdsArray) => void;
  onFillInQuestionnaire?: (ids: IdsArray) => void;
  deleteModelName?: string;
  customAction?: ICustomAction;
  customActions?: Array<ICustomAction>;
}

export const EnhancedTableSelectedItemsActions = ({
  selectedItemsIds,
  onUpdate,
  onDelete,
  deleteModelName,
  onFillInQuestionnaire,
  customAction,
  customActions,
}: IEnhancedTableSelectedItemsActionsProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isSmallDevice = window.innerWidth <= theme.breakpoints.values.sm;

  const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(
    false,
  );

  const selectedItemsQuantity = React.useMemo(() => selectedItemsIds.length, [
    selectedItemsIds.length,
  ]);

  const fetchDeleteRelations = useFetchDeleteRelations(deleteModelName || '');

  const actions: Array<any> = [];
  if (customAction) {
    const actionDetails = {
      id: customAction.title,
      tooltip: customAction.title,
      icon: customAction.icon,
      containerSx: customAction.containerSx,
      onClick: () => customAction.onClick(selectedItemsIds),
    };
    if (customAction.positionIndex) {
      actions.splice(customAction.positionIndex, 0, actionDetails);
    } else {
      actions.push(actionDetails);
    }
  }
  if (customActions) {
    customActions.forEach((action) => {
      const actionDetails = {
        id: action.title,
        tooltip: action.title,
        icon: action.icon,
        containerSx: action.containerSx,
        onClick: () => action.onClick(selectedItemsIds),
      };
      if (action.positionIndex) {
        actions.splice(action.positionIndex, 0, actionDetails);
      } else {
        actions.push(actionDetails);
      }
    });
  }
  if (onUpdate) {
    actions.push({
      id: 'update',
      tooltip: t('common.update'),
      icon: <Edit02Svg />,
      onClick: () => onUpdate(selectedItemsIds),
    });
  }
  if (onFillInQuestionnaire) {
    actions.push({
      id: 'fillInQuestionnaire',
      tooltip: t('common.fill_in_questionnaire'),
      icon: <EyeSvg />,
      onClick: () => onFillInQuestionnaire(selectedItemsIds),
    });
  }
  if (onDelete) {
    actions.push({
      id: 'delete',
      tooltip: t('common.delete'),
      icon: <Trash04Svg />,
      onClick: () => setShowConfirmDialog(true),
    });
  }

  const userRoles = useSelector(getUserRoles);
  const deleteRelations = useSelector((state) =>
    getDeleteRelations(state as IStoreState)(deleteModelName || ''),
  );

  const deleteMsg = React.useMemo(
    () =>
      deleteModelName &&
      deleteRelations &&
      !isEmpty(deleteRelations[deleteModelName])
        ? t('common.confirm_remove_with_details', {
            models: deleteRelations[deleteModelName].join(', '),
          })
        : t('common.confirm_remove'),
    [deleteModelName, deleteRelations, t],
  );

  const handleDeleteConfirmation = () => {
    if (onDelete) {
      setShowConfirmDialog(false);
      onDelete(selectedItemsIds);
    }
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  React.useEffect(() => {
    if (
      !isEmpty(userRoles) &&
      userRoles!.includes(roles.SUPER_ADMIN) &&
      deleteModelName
    ) {
      fetchDeleteRelations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedItemsQuantity) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 52,
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {!isSmallDevice && (
          <Typography
            level="text_xs"
            fontWeight="regular"
            textColor="colors.text.text_primary.main"
          >
            {t('common.selected_items')}:{' '}
            <Typography fontWeight="semi_bold">
              {selectedItemsQuantity}
            </Typography>
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        {actions.map((action) => (
          <Box key={action.id} sx={{ ml: 1, ...action.containerSx }}>
            <Tooltip title={action.tooltip}>
              <IconButton onClick={action.onClick}>{action.icon}</IconButton>
            </Tooltip>
          </Box>
        ))}
      </Box>

      <EnhancedTableDeleteEntitiesConfirmation
        open={showConfirmDialog}
        onOk={handleDeleteConfirmation}
        onCancel={onCancel}
      >
        {deleteMsg}
      </EnhancedTableDeleteEntitiesConfirmation>
    </Box>
  );
};
