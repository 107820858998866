import React, { ChangeEvent } from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import { ITextareaProps, Textarea } from '../_ui-kit/Textarea';

type IFormikTextareaProps = Omit<ITextareaProps, 'onChange'> & {
  id: string;
  name: string;
  formik: AnyObject;
  errorMode?: ErrorMode;
  onChange?(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string | number,
  ): void;
};

function FormikTextarea(props: IFormikTextareaProps) {
  const {
    id,
    name,
    formik,
    label,
    errorMode = 'onFormSubmit',
    ...restProps
  } = props;

  const option = get(formik.values, id) || '';
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  React.useEffect(() => {
    // in update entity forms we remove error manually since
    // validateOnChange false by default for all update forms
    const value = get(formik.values, id);

    if (errorMode === 'onFieldChange') {
      const error = get(formik.errors, id);

      if (Boolean(value) && Boolean(error)) {
        formik.setFieldError(id, undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMode, get(formik.values, id)]);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, e.target.value);
  };

  const onChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.handleChange(e);
    },
    [formik],
  );

  return (
    <Textarea
      {...restProps}
      id={id}
      label={label}
      name={name}
      value={option}
      onChange={onChange}
      onBlur={handleBlur}
      error={showError}
      helperText={showError && error}
      helperTextProps={{
        sx: { color: 'colors.text.text_error_primary.main' },
      }}
    />
  );
}

export default React.memo(FormikTextarea, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    prev.label === cur.label &&
    prev.disabled === cur.disabled &&
    prev.required === cur.required &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
