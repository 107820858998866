import React, { useCallback, useMemo } from 'react';
import {
  AnyObject,
  IFilter,
  IFilterData,
  IFilterWhere,
  IWhere,
} from '../../types';
import {
  reduce,
  find,
  get,
  set,
  omit,
  head,
  keys,
  has,
  merge,
  isEmpty,
} from 'lodash';
import { IRangeValue } from '../../../components/Range/Range';
import { IDateRangePickerValue } from '../../../components/_ui-kit/Combobox/Pickers/Daterangepicker';
import {
  IFilterItem,
  IFilterItemData,
  OPERATORS,
} from '../../../components/Filter';
import { JSONSchemaType } from 'ajv';
import { useQueryParams } from './useQueryParams';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentRole } from 'src/modules/selectors/auth';
import {
  useFetchSitesCombobox,
  useFetchTaktExportSitesCombobox,
} from './sites';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { IUseFetchDataByFilterInQueryParamsEffectArgs } from 'src/modules/types/filter';
import { useFilter } from './useFilter';

export interface IDataToIWhere<T> {
  filters?: IFilterItem<T>[];
  initialFilterData?: IFilterItemData;
  isFilterBeingUsed?: boolean;
}

export type IFilterType =
  | 'text'
  | 'number'
  | 'date'
  | 'datetime'
  | 'daterange'
  | 'datetimerange'
  | 'range'
  | 'checkbox'
  | 'checkboxGroup'
  | 'time'
  | 'comboboxEmployee'
  | 'comboboxSites'
  | 'comboboxClientKeys'
  | 'comboboxEntitiesBySite'
  | 'comboboxYearWeek'
  | 'comboboxYearWeekRange'
  | 'comboboxYearMonthRange'
  | 'combobox'
  | 'buttonGroup';

export interface IUseGlobalFilter<T = AnyObject> {
  initialValues: T;
  validationScheme: JSONSchemaType<T>;
  getInitValueByType(
    type: IFilterType,
  ): string | null | boolean | number | undefined;
  dataToIWhere(data: T): IWhere;
  decodeName(name: string): string;
  encodeName(name: string): string;
}

export interface IUseQsFilterObject {
  getQsFiltersObject(): AnyObject;
}

/**
 * Convert query string filter data to plain object
 */
export const useQsFilterObject = (): IUseQsFilterObject => {
  const rawFilters = useQueryParams() as IFilter;
  const filterToObject = (
    filter: IFilter['filter'] = {},
    data: AnyObject = {},
    path: string[] = [],
  ) => {
    const queryParamObjectToFilterObject = (
      queryParamObject?: AnyObject,
      _path = path,
    ) => {
      return reduce(
        queryParamObject,
        (acc, cur, index) => {
          const operator = head(keys(cur));
          const value = get(cur, [operator!]);
          let presetValue: string | number | boolean = value;

          if (
            ['like', 'nlike', 'ilike', 'nilike'].includes(operator!) &&
            presetValue
          ) {
            presetValue = (presetValue as string).replaceAll('%', '');
          }

          const fieldName = isEmpty(_path)
            ? index
            : `${_path.join('.')}.${index}`;

          // since qs keeps data as string, we should cast types explicitly
          switch (true) {
            case (presetValue as string) === 'true':
              presetValue = true;
              break;
            case (presetValue as string) === 'false':
              presetValue = false;
              break;
            case !isNaN(parseInt(presetValue as string) as number):
              presetValue = +presetValue;
              break;
          }

          acc[fieldName] = presetValue;
          path = [];

          return acc;
        },
        {},
      );
    };

    const { include, where, ...rest } = filter;

    if (include) {
      for (const fItem of filter!.include!) {
        if (has(fItem, ['scope'])) {
          path.push(fItem.relation);
          filterToObject(fItem.scope!, data, path);
          path.pop();
        }
      }
    }

    if (where) {
      data = merge(
        data,
        queryParamObjectToFilterObject(get(filter, ['where']), path),
      );
    }
    if (rest) {
      data = merge(data, queryParamObjectToFilterObject(rest));
    }

    return data;
  };

  return {
    getQsFiltersObject: useCallback(() => {
      const parsedFilters = filterToObject(get(rawFilters, ['filter']));

      return parsedFilters;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawFilters]),
  };
};

/**
 * Custom hook for converting data to IWhere object
 * @param filters
 * @param initialFilterData
 */
export const useGlobalFilter = <T extends AnyObject>({
  filters,
  initialFilterData,
}: IDataToIWhere<T>): IUseGlobalFilter => {
  const ENCODE_PATH_SYMBOL = ',';
  const rawFilters = useQueryParams();
  const { getQsFiltersObject } = useQsFilterObject();

  const qsFilters = getQsFiltersObject();

  /**
   * Get initial value based on field type
   * @param type
   */
  const getInitValueByType = (type: IFilterType) => {
    switch (type) {
      case 'daterange':
      case 'datetimerange':
      case 'range':
        return undefined;
      case 'buttonGroup':
        return undefined;
      case 'number':
        return NaN;
      case 'checkbox':
        return false;
      case 'combobox':
      case 'comboboxEmployee':
      case 'comboboxYearWeek':
      case 'comboboxYearWeekRange':
      case 'comboboxYearMonthRange':
      case 'text':
      case 'date':
      case 'datetime':
      case 'time':
      default:
        return '';
    }
  };

  /**
   * Encode filter name to prevent dot notation handling
   * @param name
   */
  const encodeName = (name: string) => name.replace(/\./g, ENCODE_PATH_SYMBOL);

  /**
   * Decode filter name from encoded state
   * @param name
   */
  const decodeName = (name: string) =>
    name.replace(new RegExp(`${ENCODE_PATH_SYMBOL}`, 'g'), '.');

  /**
   * Get initial filter values based on passed ones
   */
  const getInitialFilterItem = useCallback(
    (filterItem: IFilterItem) => {
      // the highest priority have the filters from query string
      // than the ones that are in initialFilterData
      // and than default ones
      const presetValue =
        get(qsFilters, [filterItem.name], false) ||
        get(initialFilterData, [filterItem.name]);
      // The code above fixing behavior on employees page
      // where we need to have "active = true", default filter, the
      // problem is that the filter does not applied in UI but applied during query,
      // use code below if you see any issues with filters on other pages
      // head(values(get(initialFilterData, [filterItem.name])));
      const initValue = getInitValueByType(filterItem.type!);

      switch (filterItem.type) {
        case 'checkbox':
          return Boolean(presetValue || initValue);
        case 'range':
          return presetValue
            ? { from: presetValue[0], to: presetValue[1] }
            : initValue;
        case 'daterange':
        case 'datetimerange':
          const value =
            (rawFilters?.filter as any)?.where &&
            (rawFilters.filter as any).where[filterItem.name];
          if (Array.isArray(presetValue)) {
            return { start: presetValue[0], end: presetValue[1] };
          } else if (value?.gte) {
            return { start: presetValue };
          } else if (value?.lte) {
            return { end: presetValue };
          }

          return presetValue;
        default:
          return presetValue || initValue;
      }
    },
    [initialFilterData, qsFilters, rawFilters],
  );

  /**
   * Get initial filters values based on query string data and default data
   */
  const initialValues = useMemo(
    () =>
      reduce(
        filters,
        (acc, cur) => {
          set(acc, [encodeName(cur.name)], getInitialFilterItem(cur));
          return acc;
        },
        {},
      ),
    [filters, getInitialFilterItem],
  );

  /**
   * Compose validation scheme
   */
  const validationScheme = useMemo(
    () =>
      reduce(
        filters,
        (acc, cur) => {
          if (cur.validate) {
            if (cur.validate.required) {
              acc.required.push(encodeName(cur.name));
            }
            acc.properties[encodeName(cur.name)] = omit(cur.validate, [
              'required',
            ]);
          }
          return acc;
        },
        {
          type: 'object',
          additionalProperties: true,
          required: [],
          properties: {},
        } as JSONSchemaType<AnyObject>,
      ),
    [filters],
  );

  const isValueToSetAlreadyHasOperator = (valueToSet: any) => {
    if (typeof valueToSet !== 'object') {
      return false;
    }

    const operator = Object.keys(valueToSet)[0];

    const isOperator = Boolean(OPERATORS[operator]);

    return isOperator;
  };

  /**
   * Convert plain object data to IWhere data
   */
  const dataToIWhere = (data: AnyObject): IWhere =>
    reduce(
      data,
      (acc, cur, index) => {
        const decodedName = decodeName(index);
        const filterItem = find(filters, (f) => f.name === decodedName);

        // For buttons group we may have true/false/undefined values
        // so need to handle it in a separate if to not break existed logic
        // but some day we need to refactor all this code :)
        if (filterItem?.type === 'buttonGroup') {
          acc[decodedName] = cur;
        } else if (cur) {
          let valueToSet = cur;

          if (
            ['like', 'nlike', 'ilike', 'nilike'].includes(filterItem!.operator)
          ) {
            valueToSet = `%${cur}%`;
          }
          acc[decodedName] = acc[decodedName] || {};

          // if it's any kind of ranges there are always to values
          switch (filterItem!.type) {
            case 'range':
              acc[decodedName][filterItem!.operator] = [
                (valueToSet as IRangeValue).from,
                (valueToSet as IRangeValue).to,
              ];
              break;
            case 'daterange':
            case 'datetimerange':
              if (
                (valueToSet as IDateRangePickerValue).start &&
                !(valueToSet as IDateRangePickerValue).end
              ) {
                acc[
                  decodedName
                ].gte = (valueToSet as IDateRangePickerValue).start;
              } else if (
                !(valueToSet as IDateRangePickerValue).start &&
                (valueToSet as IDateRangePickerValue).end
              ) {
                acc[
                  decodedName
                ].lte = (valueToSet as IDateRangePickerValue).end;
              } else if (valueToSet.between) {
                acc[decodedName] = valueToSet;
              } else {
                acc[decodedName][filterItem!.operator] = [
                  (valueToSet as IDateRangePickerValue).start,
                  (valueToSet as IDateRangePickerValue).end,
                ];
              }
              break;
            default:
              // If component specifies initialFilterData it already has operator
              // and we do not need to duplicate it
              if (isValueToSetAlreadyHasOperator(valueToSet)) {
                acc[decodedName] = valueToSet;
              } else {
                acc[decodedName][filterItem!.operator] = valueToSet;
              }
              break;
          }
        } else {
          acc[decodedName] = null;
        }
        return acc;
      },
      {},
    );

  return {
    getInitValueByType,
    dataToIWhere,
    decodeName,
    encodeName,
    initialValues,
    validationScheme,
  };
};

export const useSitesOptions = () => {
  const currentRole = useSelector(getCurrentRole);
  const fetchSitesCombobox = useFetchSitesCombobox();

  // make request to fetch clients from the server if we don't have them in the store
  React.useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const sites = useSelector(getSitesComboboxList);

  return React.useMemo(
    () =>
      sites.map((site) => ({
        id: site.id,
        name: `${site.name} (${site.client.name})`,
      })),

    [sites],
  );
};

export const useTaktExportSitesOptions = () => {
  const currentRole = useSelector(getCurrentRole);
  const fetchTaktExportSitesCombobox = useFetchTaktExportSitesCombobox();

  // make request to fetch clients from the server if we don't have them in the store
  React.useEffect(() => {
    fetchTaktExportSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const sites = useSelector(getSitesComboboxList);

  return React.useMemo(
    () =>
      sites.map((site) => ({
        id: site.id,
        name: `${site.name} (${site.client.name})`,
      })),

    [sites],
  );
};

export const useFetchDataOnQueryParamsChangeEffect = ({
  fetchFunc,
  defaultFilter,
}: IUseFetchDataByFilterInQueryParamsEffectArgs) => {
  const dispatch = useDispatch();

  const { filterList } = useFilter(defaultFilter);

  React.useEffect(() => {
    dispatch(fetchFunc(filterList));
  }, [dispatch, fetchFunc, filterList]);
};

export const useFilterHelpers = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dataToFilter = (data: IFilterWhere): IFilterData => {
    return {};
  };

  const filterToData = (
    filter: IFilterData,
    path: string[] = [],
  ): IFilterWhere => {
    let data: IFilterWhere = {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { where, include, ...rest } = filter;

    if (!isEmpty(where)) {
      data = {
        ...data,
        ...reduce(
          where,
          (acc, propValue, propName) => {
            const pName = [...path, propName].join('.');
            acc[pName] = propValue;
            return acc;
          },
          {},
        ),
      };
    }

    if (include && !isEmpty(include)) {
      data = {
        ...data,
        ...reduce(
          include,
          (acc, cur) => {
            path.push(cur.relation);

            if (cur.scope) {
              acc = {
                ...acc,
                ...filterToData(cur.scope, path),
              };
            }

            path.pop();

            return acc;
          },
          {},
        ),
      };
    }

    return data;
  };

  return {
    dataToFilter,
    filterToData,
  };
};
