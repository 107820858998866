import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateIssue } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createIssueScheme } from './create-issue.scheme';

export const updateIssuesScheme: JSONSchemaType<
  Omit<ICreateIssue, 'picture'>[]
> = {
  type: 'array',
  items: {
    ...createIssueScheme,
    properties: {
      ...createIssueScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
