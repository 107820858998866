import React from 'react';
import { Report, ReportPage } from 'src/components/ReportPage';
import { clients } from 'src/modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import WeeklyGrossMargin from './WeeklyGrossMargin';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useTranslation } from 'react-i18next';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { UploadImportingFile } from 'src/components/Table/components/TableActions/components/UploadImportingFile';
import {
  IPerryEllisFilterPanelFilters,
  PerryEllisFilterPanel,
} from './PerryEllisFilterPanel';
import { DailyUphByDepartment } from './DailyUphByDepartment';
import { WeeklyUphByDepartment } from './WeeklyUphByDepartment';
import { PerryEllisGapsDaily } from './GapsDaily';
import { PerryEllisExceptionsFromEmployees } from './ExceptionFromEmployees';
import { PerryEllisExceptionFromProduction } from './ExceptionFromProduction';
import { WeeklyCpu } from './WeeklyCpu';
import { PerryEllisScorecard } from './Scorecard';

const perryEllisClient = clients.perryEllis;

const reportsDefaultFilters: IPerryEllisFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

export const PerryEllisReports = () => {
  const { t } = useTranslation();

  const reportsProps = useReportsProps(perryEllisClient);

  const hasUserAccessToUploadPerryEllisProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[perryEllisClient].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IPerryEllisFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
        isPermanent: true,
      },
      { id: 'date', label: t('productions_uph_reports.date') },
      { id: 'department', label: t('productions_uph_reports.department') },
      { id: 'employeeId', label: t('productions_uph_reports.employee') },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IPerryEllisFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={perryEllisClient}>
        <ReportPageItemContainer>
          <ReportTableToolbar
            reportName={t('main_menu.reports.perry_ellis_main')}
          >
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>
            </ReportPageActionsGroupContainer>

            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <ReportPageExport reportProps={reportsProps} />
              </ReportPageActionsGroupItem>

              {hasUserAccessToUploadPerryEllisProductionData && (
                <ReportPageActionsGroupItem>
                  <UploadImportingFile clientKey={perryEllisClient} />
                </ReportPageActionsGroupItem>
              )}
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
        </ReportPageItemContainer>
        <ReportPageItemContainer>
          <PerryEllisFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>
        {/*<Report {...reportsProps.dailyReport}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyReport*/}
        {/*      fetchUrl={paths.PERRY_ELLIS_DAILY_REPORT}*/}
        {/*      clientKey={clients.perryEllis}*/}{' '}
        {/*      filterTitle="perry_ellis_reports.table_names.daily_report"*/}
        {/*      tableTitle="perry_ellis_reports.table_names.daily_report"*/}
        {/*    />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyUphByRfProgram}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyUphByArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyLphByRfProgram}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyLphByArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyUphByReplenishmentArea}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyUphByReplenishmentArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyLphByReplenishmentArea}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyLphByReplenishmentArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyUphPickPackSummary}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyUphPickPackSummary />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyLphPickPackSummary}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyLphPickPackSummary />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyUphByRfProgram}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyUphByArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyLphByRfProgram}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyLphByArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyUphByReplenishmentArea}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyUphByReplenishmentArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyUphPickPackSummary}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyUphPickPackSummary />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyUphPickPackDetailsSummary}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyUphPickPackDetailsSummary />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyLphPickPackSummary}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyLphPickPackSummary />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyLphByReplenishmentArea}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyLphByReplenishmentArea />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        <Report {...reportsProps.dailyUphBySiteAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <DailyUphByDepartment
              date={appliedFilters.date}
              department={appliedFilters.department}
              siteId={appliedFilters.siteId}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.weeklyUphBySiteAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphByDepartment
              year={appliedFilters.year}
              week={appliedFilters.week}
              siteId={appliedFilters.siteId}
              department={appliedFilters.department}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.dailyGaps}>
          <ReportPageItemContainer fullHeight>
            <PerryEllisGapsDaily
              siteId={appliedFilters.siteId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.exceptionsFromEmployees}>
          <ReportPageItemContainer fullHeight>
            <PerryEllisExceptionsFromEmployees
              siteId={appliedFilters.siteId}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.exceptionsFromProduction}>
          <ReportPageItemContainer fullHeight>
            <PerryEllisExceptionFromProduction siteId={appliedFilters.siteId} />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.weeklyGrossMargin}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.weeklyCpu}>
          <ReportPageItemContainer fullHeight>
            <WeeklyCpu
              year={appliedFilters.year}
              week={appliedFilters.week}
              siteId={appliedFilters.siteId}
            />
          </ReportPageItemContainer>
        </Report>
        <Report {...reportsProps.scorecard}>
          <ReportPageItemContainer fullHeight>
            <PerryEllisScorecard
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
