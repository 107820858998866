import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { addProcessStatus } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { AnyObject, ShiftAutomaticPointsSettings } from 'src/modules/types';
import { ComboBoxOption } from 'src/components/ComboBox';
import { useShiftAutomaticPointsSettingsForm } from 'src/modules/utils/hooks/shift-points-settings';
import ShiftsComboBox from 'src/components/Formik/comboboxes-with-entities/ShiftsCombobox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';

interface IShiftPointsSettingsCreateProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialData: ShiftAutomaticPointsSettings = {
  shiftId: NaN,
};

export const ShiftPointsSettingsCreate = ({
  isOpen,
  onClose,
}: IShiftPointsSettingsCreateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [shiftId, setShiftId] = React.useState<number>(NaN);

  const validationSchemaForShiftId = Yup.object().shape({
    shiftId: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required')),
  });

  const { data, sendToServer } = useShiftAutomaticPointsSettingsForm(shiftId);

  const formik = useFormik({
    initialValues: data ? { ...data, shiftId } : initialData,
    validationSchema: validationSchemaForShiftId,
    onSubmit: () => {
      sendToServer(formik.values);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );

      setShiftId(NaN);
      formik.resetForm();

      onClose();
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onReset = () => {
    formik.resetForm();
  };

  const changeShift = (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    setShiftId(+value!.id);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('shiftAutomaticPointsSettings.create_settings_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <ShiftsComboBox
              id="shiftId"
              formik={formik}
              label={t('shiftAutomaticPointsSettings.shiftId')}
              placeholder={t('shiftAutomaticPointsSettings.shiftId')}
              onChange={changeShift}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="enabled"
              formik={formik}
              label={t('shiftAutomaticPointsSettings.enabled')}
              placeholder={t('shiftAutomaticPointsSettings.enabled')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              label={t('shiftAutomaticPointsSettings.lateGracePeriodMin')}
              id="lateGracePeriodMin"
              name="lateGracePeriodMin"
              type="number"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              label={t('shiftAutomaticPointsSettings.leaveGracePeriodMin')}
              id="leaveGracePeriodMin"
              name="leaveGracePeriodMin"
              type="number"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="absent"
              formik={formik}
              label={t('shiftAutomaticPointsSettings.absent')}
              placeholder={t('shiftAutomaticPointsSettings.absent')}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onCancel={onClose}
          applyButtonType="submit"
          onApply={formik.handleSubmit}
        />
      </DrawerFooter>
    </Drawer>
  );
};
