import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mui/material';

import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import { XAuthType } from '../../config';
import { useFinancialTrackerChartsData } from '../../modules/utils';
import { PageWithTabs } from '../../components/PageWithTabs';
import { WeeklyGrossMarginBySiteAndWeekChart } from './Splash/WeeklyGrossMarginBySiteAndWeekChart';
import { WeeklySynchronizedCharts } from './Splash/WeeklySynchronizedCharts';
import { WeeklyGrossMarginBySitePercentageAndWeekChart } from './Splash/WeeklyGrossMarginBySitePercentageAndWeekChart';
import { EmployeesStatisticChart } from './Splash/EmployeesStatisticChart';

const FinancialCharts: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();

  // Get Gross Margin data for charts
  const { fetchData, data } = useDataFetcherWithData(
    '/financial-charts/weekly',
    [],
  );

  useEffect(() => {
    fetchData({ token, 'x-auth-type': XAuthType.qsjwt });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    weeklyGrossMarginValue,
    weeklyGrossMarginPercentageValue,
  } = useFinancialTrackerChartsData(data);

  const configuration = React.useMemo(() => {
    return [
      {
        tabLabel: t('common.charts'),
        component: (
          <Box width="100%" height="100%" display="flex" flexDirection="column">
            <Paper style={{ marginBottom: '16px' }}>
              <WeeklyGrossMarginBySiteAndWeekChart
                weeklyGrossMarginValue={weeklyGrossMarginValue}
              />
            </Paper>
            <Paper style={{ marginBottom: '16px' }}>
              <WeeklySynchronizedCharts
                token={token}
                x-auth-type={XAuthType.qsjwt}
                url="/financial-charts/weekly-synchronized"
              />
            </Paper>
            <Paper style={{ marginBottom: '16px' }}>
              <WeeklyGrossMarginBySitePercentageAndWeekChart
                {...weeklyGrossMarginPercentageValue}
              />
            </Paper>
            <Paper style={{ marginBottom: '16px' }}>
              <EmployeesStatisticChart
                token={token}
                x-auth-type={XAuthType.qsjwt}
                url="/financial-charts/statistic/for-executives"
              />
            </Paper>
          </Box>
        ),
      },
    ];
  }, [t, token, weeklyGrossMarginPercentageValue, weeklyGrossMarginValue]);

  return <PageWithTabs configuration={configuration} />;
};

export default FinancialCharts;
