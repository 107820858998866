import React, { useCallback, useEffect } from 'react';
import { map, filter } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getShiftOptionsByGloballySites,
  getShiftsComboboxListBySiteId,
} from '../../modules/selectors/shift';
import { roles } from '../../config';
import {
  useBrowserHistoryFunctions,
  useFetchActiveDepartmentsCombobox,
  useFetchOpenPositionsByIds,
  useFetchPositionsCombobox,
  useFetchShiftsCombobox,
  useFetchSkillsCombobox,
  useQueryParams,
  useValidate,
} from '../../modules/utils/hooks';
import {
  IdsArray,
  IStoreState,
  IUpdateOpenPosition,
  SkillComboboxOption,
} from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { getCurrentRole } from '../../modules/selectors/auth';
import { useTranslation } from 'react-i18next';
import { CreateFormHandleSubmit } from '../../components/Layout/CreateFormLayout';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import FormikTextField from '../../components/Formik/FormikTextField';
import { updateOpenPositionsRequest } from '../../modules/actions';
import { getPositionsComboboxListBySiteId } from '../../modules/selectors/position';
import { getSkillsComboboxList } from '../../modules/selectors/skill';
import { updateOpenPositionsScheme } from '../../modules/schemes/open-position';
import FormikDatepicker from '../../components/Formik/FormikDatepicker';
import FormikMultipleSelect from '../../components/Formik/FormikMultipleSelect';
import { getOpenPositionsByIds } from '../../modules/selectors/openPosition';
import {
  getDepartmentOptionsByGloballySites,
  getDepartmentsComboboxListBySiteId,
} from '../../modules/selectors/department';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import { FormActions } from '../../components/Form/FormActions';
import { useOpenPositionUpdateFormTourConfig } from '../../config/tours/openPosition';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikTextarea from 'src/components/Formik/FormikTextarea';

interface IProps {
  openPositions: IUpdateOpenPosition[];
  onSubmit: (openPositions: IUpdateOpenPosition[]) => void;
}

const OpenPositionUpdateForm = (props: IProps) => {
  const { t } = useTranslation();

  const { openPositions, onSubmit } = props;

  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchPositionsCombobox = useFetchPositionsCombobox();
  const fetchSkillsCombobox = useFetchSkillsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    fetchShiftsCombobox();
    fetchPositionsCombobox();
    fetchSkillsCombobox();
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const restSelectors = {
    shifts: useSelector(getShiftOptionsByGloballySites, shallowEqual),
    skills: useSelector(getSkillsComboboxList),
    departments: useSelector(getDepartmentOptionsByGloballySites, shallowEqual),
  };

  const positionsBySiteId = useSelector(
    getPositionsComboboxListBySiteId,
    shallowEqual,
  );

  const shiftsBySiteId = useSelector(
    getShiftsComboboxListBySiteId,
    shallowEqual,
  );

  const departmentsBySiteId = useSelector(
    getDepartmentsComboboxListBySiteId,
    shallowEqual,
  );

  const getSkills = useCallback(
    (siteId: number) =>
      filter(
        (restSelectors.skills as unknown) as SkillComboboxOption[],
        (s) => s.siteId === siteId,
      ),
    [restSelectors.skills],
  );

  const validate = useValidate<IUpdateOpenPosition[]>(
    updateOpenPositionsScheme,
  );

  const formik = useFormikInUpdateForm({
    initialValues: openPositions,
    validate,
    onSubmit: (data) => onSubmit(data),
  });

  const openPositionUpdateTourConfig = useOpenPositionUpdateFormTourConfig();

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit as CreateFormHandleSubmit}
      fields={formik.values}
      isFormValid={formik.isValid}
    >
      {map(formik.values, (openPositions, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('open_position.update_title', { id: openPositions.id })}
          key={openPositions.id}
        >
          <SitesComboBox
            id={`${index}.siteId`}
            required={true}
            formik={formik}
            placeholder={t('open_position.siteId')}
            data-tour={openPositionUpdateTourConfig.tour.site.target}
            errorMode="onFieldChange"
          />

          <FormikTextField
            id={`${index}.requisitionNumber`}
            name={`${index}.requisitionNumber`}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('open_position.requisitionNumber')}
            data-tour={
              openPositionUpdateTourConfig.tour.requisitionNumber.target
            }
            errorMode="onFieldChange"
          />

          <FormikCombobox
            id={`${index}.positionId`}
            required={true}
            formik={formik}
            placeholder={t('open_position.positionId')}
            options={positionsBySiteId(openPositions.siteId)}
            errorMode="onFieldChange"
            data-tour={openPositionUpdateTourConfig.tour.position.target}
          />

          <FormikCombobox
            id={`${index}.departmentId`}
            required={true}
            formik={formik}
            placeholder={t('open_position.departmentId')}
            options={departmentsBySiteId(openPositions.siteId)}
            errorMode="onFieldChange"
            data-tour={openPositionUpdateTourConfig.tour.department.target}
          />

          <FormikTextField
            id={`${index}.numberNeeded`}
            name={`${index}.numberNeeded`}
            formik={formik}
            required={true}
            variant="outlined"
            fullWidth={true}
            label={t('open_position.numberNeeded')}
            type="number"
            errorMode="onFieldChange"
            data-tour={openPositionUpdateTourConfig.tour.numberNeeded.target}
          />

          <FormikCombobox
            id={`${index}.shiftId`}
            required={true}
            formik={formik}
            placeholder={t('open_position.shiftId')}
            options={shiftsBySiteId(openPositions.siteId)}
            errorMode="onFieldChange"
            data-tour={openPositionUpdateTourConfig.tour.shift.target}
          />

          {![roles.CUSTOMER].includes(currentRole as string) && (
            <FormikTextarea
              id={`${index}.description`}
              name={`${index}.description`}
              formik={formik}
              variant="outlined"
              label={t('open_position.description')}
              errorMode="onFieldChange"
              data-tour={openPositionUpdateTourConfig.tour.description.target}
            />
          )}

          <FormikDatepicker
            id={`${index}.dateStart`}
            formik={formik}
            required={true}
            label={t('open_position.dateStart')}
            fullWidth={true}
            errorMode="onFieldChange"
            data-tour={openPositionUpdateTourConfig.tour.dateStart.target}
          />

          <FormikMultipleSelect
            id={`${index}.skills`}
            title={t('open_position.skills')}
            formik={formik}
            required={true}
            options={getSkills(openPositions.siteId)}
            errorMode="onFieldChange"
            data-tour={openPositionUpdateTourConfig.tour.skills.target}
          />
        </CreateForm>
      ))}
      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const OpenPositionUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchOpenPositions = useFetchOpenPositionsByIds(ids);

  // fetch open positions list from store
  const openPositions = useSelector(
    (state) => getOpenPositionsByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch openPositions from the server if we don't have them in the store
  useEffect(() => {
    fetchOpenPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated openPositions
   */
  const handleFormSubmit = (data: IUpdateOpenPosition[]) => {
    dispatcher(updateOpenPositionsRequest({ data, navigate }));
  };

  return (
    <OpenPositionUpdateForm
      openPositions={openPositions}
      onSubmit={handleFormSubmit}
    />
  );
};

export default OpenPositionUpdate;
