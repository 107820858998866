import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterPanelOption } from 'src/components/FilterPanel';
import { DEFAULT_LIMIT, DEFAULT_PAGE } from './table';
import { IFilter, IFilterInclude, IWhere } from 'src/modules/types';
import { Order } from 'src/modules/types/table';
import { clients } from '../../constants';

export const reportIds = {
  dailyReport: 'dailyReport',
  uphBySite: 'uphBySite',
  weeklyUphBySite: 'weeklyUphBySite',
  uphByPickCartons: 'uphByPickCartons',
  dailyUphByRfProgram: 'dailyUphByRfProgram',
  dailyLphByRfProgram: 'dailyLphByRfProgram',
  dailyLphByReplenishmentArea: 'dailyLphByReplenishmentArea',
  dailyUphByReplenishmentArea: 'dailyUphByReplenishmentArea',
  dailyUphPickPackSummary: 'dailyUphPickPackSummary',
  dailyLphPickPackSummary: 'dailyLphPickPackSummary',
  dailyUphBySite: 'dailyUphBySite',
  dailyUphByLine: 'dailyUphByLine',
  weeklyUphByRfProgram: 'weeklyUphByRfProgram',
  weeklyUphByReplenishmentArea: 'weeklyUphByReplenishmentArea',
  weeklyUphPickPackSummary: 'weeklyUphPickPackSummary',
  weeklyUphPickPackDetailsSummary: 'weeklyUphPickPackDetailsSummary',
  weeklyLphByReplenishmentArea: 'weeklyLphByReplenishmentArea',
  weeklyLphPickPackSummary: 'weeklyLphPickPackSummary',
  weeklyLphByRfProgram: 'weeklyLphByRfProgram',
  weeklyUphByLine: 'weeklyUphByLine',
  lphByPickCartons: 'lphByPickCartons',
  lphByPutAwayHandlingUnit: 'lphByPutAwayHandlingUnit',
  lphByLoadHandlingUnit: 'lphByLoadHandlingUnit',
  uphBySiteAndEmployee: 'uphBySiteAndEmployee',
  weeklyUphBySiteAndEmployee: 'weeklyUphBySiteAndEmployee',
  dailyUphBySiteAndEmployee: 'dailyUphBySiteAndEmployee',
  uphBySiteEmployeeAndArea: 'uphBySiteEmployeeAndArea',
  uphBySiteEmployeeAndDepartment: 'uphBySiteEmployeeAndDepartment',
  weeklyUphBySiteEmployeeAndDepartment: 'weeklyUphBySiteEmployeeAndDepartment',
  dailyUphBySiteEmployeeAndDepartment: 'dailyUphBySiteEmployeeAndDepartment',
  uphBySiteEmployeeAndShift: 'uphBySiteEmployeeAndShift',
  weeklyUphBySiteEmployeeAndShift: 'weeklyUphBySiteEmployeeAndShift',
  dailyUphBySiteEmployeeAndShift: 'dailyUphBySiteEmployeeAndShift',
  uphBySiteEmployeeShiftAndArea: 'uphBySiteEmployeeShiftAndArea',
  uphBySiteEmployeeShiftAndDepartment: 'uphBySiteEmployeeShiftAndDepartment',
  weeklyUphBySiteEmployeeShiftAndDepartment:
    'weeklyUphBySiteEmployeeShiftAndDepartment',
  dailyUphBySiteEmployeeShiftAndDepartment:
    'dailyUphBySiteEmployeeShiftAndDepartment',
  uphBySiteShiftAndArea: 'uphBySiteShiftAndArea',
  uphBySiteShiftAndDepartment: 'uphBySiteShiftAndDepartment',
  weeklyUphBySiteShiftAndDepartment: 'weeklyUphBySiteShiftAndDepartment',
  dailyUphBySiteShiftAndDepartment: 'dailyUphBySiteShiftAndDepartment',
  uphBySiteAndShift: 'uphBySiteAndShift',
  weeklyUphBySiteAndShift: 'weeklyUphBySiteAndShift',
  dailyUphBySiteAndShift: 'dailyUphBySiteAndShift',
  uphBySiteAndDepartment: 'uphBySiteAndDepartment',
  weeklyUphBySiteAndDepartment: 'weeklyUphBySiteAndDepartment',
  dailyUphBySiteAndDepartment: 'dailyUphBySiteAndDepartment',
  lphBySite: 'lphBySite',
  lphBySiteAndShift: 'lphBySiteAndShift',
  dailyLphBySite: 'dailyLphBySite',
  dailyLphBySiteAndShift: 'dailyLphBySiteAndShift',
  weeklyLphBySite: 'weeklyLphBySite',
  weeklyLphBySiteAndShift: 'weeklyLphBySiteAndShift',
  lphBySiteAndDepartment: 'lphBySiteAndDepartment',
  rphBySite: 'rphBySite',
  rphBySiteAndShift: 'rphBySiteAndShift',
  rphBySiteAndDepartment: 'rphBySiteAndDepartment',
  uphBySiteAndMetatag: 'uphBySiteAndMetatag',
  uphBySiteAndArea: 'uphBySiteAndArea',
  uphBySiteSkuAndEmployee: 'uphBySiteSkuAndEmployee',
  lpphBySite: 'lpphBySite',
  lpphBySiteAndEmployee: 'lpphBySiteAndEmployee',
  lpphBySiteAndShift: 'lpphBySiteAndShift',
  lpphBySiteAndSku: 'lpphBySiteAndSku',
  lpphBySiteAndArea: 'lpphBySiteAndArea',
  lpphBySiteSkuAndEmployee: 'lpphBySiteSkuAndEmployee',
  exceptionsFromEmployees: 'exceptionsFromEmployees',
  exceptionsFromProduction: 'exceptionsFromProduction',
  weeklyCpu: 'weeklyCpu',
  weeklyProduction: 'weeklyProduction',
  dailyCpu: 'dailyCpu',
  pulseSummary: 'pulseSummary',
  dailyProduction: 'dailyProduction',
  weeklyCpuDetailed: 'weeklyCpuDetailed',
  weeklyGrossMargin: 'weeklyGrossMargin',
  weeklyGrossMarginDetailed: 'weeklyGrossMarginDetailed',
  weeklyStaticCostPlus: 'weeklyStaticCostPlus',
  monthlyGrossMargin: 'monthlyGrossMargin',
  top5Cpu: 'top5Cpu',
  gaps: 'gaps',
  scorecard: 'scorecard',
  employeesStatistic: 'employeesStatistic',
  paymentStatistic: 'paymentStatistic',
  payboardSummary: 'payboardSummary',
  kronosPayboard: 'kronosPayboard',
  sonyPayboardExport: 'sonyPayboardExport',
  incentiveIndividual: 'incentiveIndividual',
  incentiveIndirect: 'incentiveIndirect',
};

export const reportTypeIds = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  exceptions: 'exceptions',
  cpu: 'cpu',
  pulse: 'pulse',
  gaps: 'gaps',
  scorecard: 'scorecard',
  production: 'production',
  incentive: 'incentive',
};

export const useReportsProps = (client = 'productions/reports') => {
  const { t } = useTranslation();

  return React.useMemo(
    () => ({
      dailyReport: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyReport,
        reportLabel: t('reports.labels.report'),
        resourceUrl: `/${client}/daily-report`,
        client,
      },
      dailyUphBySite: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySite,
        reportLabel: t('reports.labels.uph_by_site'),
        resourceUrl: `/${client}/daily-uph/by-site`,
        client,
      },
      dailyLPHBySite: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lphBySite,
        reportLabel: t('reports.labels.lph_by_site'),
        resourceUrl: `/${client}/daily-lph/by-site`,
        client,
      },
      dailyLPHBySiteAndShift: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lphBySiteAndShift,
        reportLabel: t('reports.labels.lph_by_site_and_shift'),
        resourceUrl: `/${client}/daily-lph/by-site-and-shift`,
        client,
      },
      dailyLPHBySiteAndDepartment: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lphBySiteAndDepartment,
        reportLabel: t('reports.labels.lph_by_site_and_department'),
        resourceUrl: `/${client}/daily-lph/by-site-and-department`,
        client,
      },
      dailyRPHBySite: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.rphBySite,
        reportLabel: t('reports.labels.rph_by_site'),
        resourceUrl: `/${client}/daily-rph/by-site`,
        client,
      },
      dailyRPHBySiteAndShift: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.rphBySiteAndShift,
        reportLabel: t('reports.labels.rph_by_site_and_shift'),
        resourceUrl: `/${client}/daily-rph/by-site-and-shift`,
        client,
      },
      dailyRPHBySiteAndDepartment: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.rphBySiteAndDepartment,
        reportLabel: t('reports.labels.rph_by_site_and_department'),
        resourceUrl: `/${client}/daily-rph/by-site-and-department`,
        client,
      },
      dailyUphByPickCartons: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphByPickCartons,
        reportLabel: t('reports.labels.uph_by_pick_cartons'),
        resourceUrl: `/${client}/daily-uph/by-pick-cartons`,
        client,
      },
      dailyUphByRfProgram: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphByRfProgram,
        reportLabel: t('reports.labels.uph_by_rf_program'),
        resourceUrl: `/${client}/daily-uph/by-area`,
        client,
      },
      dailyLphByRfProgram: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyLphByRfProgram,
        reportLabel: t('reports.labels.lph_by_rf_program'),
        resourceUrl: `/${client}/daily-lph/by-area`,
        client,
      },
      dailyUphByReplenishmentArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphByReplenishmentArea,
        reportLabel: t('reports.labels.uph_by_replenishment_area'),
        resourceUrl: `/${client}/daily-uph/by-replenishment-area`,
        client,
      },
      dailyUphPickPackSummary: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphPickPackSummary,
        reportLabel: t('reports.labels.uph_pick_pack_summary'),
        resourceUrl: `/${client}/daily-uph/by-pick-pack-summary`,
        client,
      },
      dailyLphPickPackSummary: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyLphPickPackSummary,
        reportLabel: t('reports.labels.lph_pick_pack_summary'),
        resourceUrl: `/${client}/daily-lph/by-pick-pack-summary`,
        client,
      },
      dailyLphByReplenishmentArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyLphByReplenishmentArea,
        reportLabel: t('reports.labels.lph_by_replenishment_area'),
        resourceUrl: `/${client}/daily-lph/by-replenishment-area`,
        client,
      },
      weeklyUphByRfProgram: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphByRfProgram,
        reportLabel: t('reports.labels.uph_by_rf_program'),
        resourceUrl: `/${client}/weekly-uph/by-area`,
        client,
      },
      weeklyUphByReplenishmentArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphByReplenishmentArea,
        reportLabel: t('reports.labels.uph_by_replenishment_area'),
        resourceUrl: `/${client}/weekly-uph/by-replenishment-area`,
        client,
      },
      weeklyLphByReplenishmentArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyLphByReplenishmentArea,
        reportLabel: t('reports.labels.lph_by_replenishment_area'),
        resourceUrl: `/${client}/weekly-lph/by-replenishment-area`,
        client,
      },
      weeklyUphPickPackSummary: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphPickPackSummary,
        reportLabel: t('reports.labels.uph_pick_pack_summary'),
        resourceUrl: `/${client}/weekly-uph/by-pick-pack-summary`,
        client,
      },
      weeklyUphPickPackDetailsSummary: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphPickPackDetailsSummary,
        reportLabel: t('reports.labels.uph_pick_pack_details_summary'),
        resourceUrl: `/${client}/weekly-uph/by-pick-pack-details-summary`,
        client,
      },
      weeklyLphPickPackSummary: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyLphPickPackSummary,
        reportLabel: t('reports.labels.lph_pick_pack_summary'),
        resourceUrl: `/${client}/weekly-lph/by-pick-pack-summary`,
        client,
      },
      weeklyLphByRfProgram: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyLphByRfProgram,
        reportLabel: t('reports.labels.lph_by_rf_program'),
        resourceUrl: `/${client}/weekly-lph/by-area`,
        client,
      },
      weeklyLPHBySite: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lphBySite,
        reportLabel: t('reports.labels.lph_by_site'),
        resourceUrl: `/${client}/weekly-lph/by-site`,
        client,
      },
      weeklyLPHBySiteAndShift: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lphBySiteAndShift,
        reportLabel: t('reports.labels.lph_by_site_and_shift'),
        resourceUrl: `/${client}/weekly-lph/by-site-and-shift`,
        client,
      },
      weeklyLPHBySiteAndDepartment: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lphBySiteAndDepartment,
        reportLabel: t('reports.labels.lph_by_site_and_department'),
        resourceUrl: `/${client}/weekly-lph/by-site-and-department`,
        client,
      },
      weeklyRPHBySite: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.rphBySite,
        reportLabel: t('reports.labels.rph_by_site'),
        resourceUrl: `/${client}/weekly-rph/by-site`,
        client,
      },
      weeklyRPHBySiteAndShift: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.rphBySiteAndShift,
        reportLabel: t('reports.labels.rph_by_site_and_shift'),
        resourceUrl: `/${client}/weekly-rph/by-site-and-shift`,
        client,
      },
      weeklyRPHBySiteAndDepartment: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.rphBySiteAndDepartment,
        reportLabel: t('reports.labels.rph_by_site_and_department'),
        resourceUrl: `/${client}/weekly-rph/by-site-and-department`,
        client,
      },
      dailyLphByPickCartons: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lphByPickCartons,
        reportLabel: t('reports.labels.lph_by_pick_cartons'),
        resourceUrl: `/${client}/daily-lph/by-pick-cartons`,
        client,
      },
      dailyLphByPutAwayHandlingUnit: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lphByPutAwayHandlingUnit,
        reportLabel: t('reports.labels.lph_by_put_away_handling_unit'),
        resourceUrl: `/${client}/daily-lph/by-putaway`,
        client,
      },
      dailyLphByLoadHandlingUnit: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lphByLoadHandlingUnit,
        reportLabel: t('reports.labels.lhp_by_load_handling_unit'),
        resourceUrl: `/${client}/daily-lph/by-load`,
        client,
      },
      dailyUphBySiteAndEmployee: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteAndEmployee,
        reportLabel: t('reports.labels.uph_by_site_and_employee'),
        resourceUrl: `/${client}/daily-uph/by-site-and-employee`,
        client,
      },
      dailyUphBySiteEmployeeAndArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphBySiteEmployeeAndArea,
        reportLabel: t('reports.labels.uph_by_site_employee_and_area'),
        resourceUrl: `/${client}/daily-uph/by-site-employee-and-area`,
        client,
      },
      dailyUphBySiteEmployeeAndDepartment: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteEmployeeAndDepartment,
        reportLabel: t('reports.labels.uph_by_site_employee_and_department'),
        resourceUrl: `/${client}/daily-uph/by-site-employee-and-department`,
        client,
      },
      dailyUphBySiteEmployeeAndShift: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteEmployeeAndShift,
        reportLabel: t('reports.labels.uph_by_site_employee_and_shift'),
        resourceUrl: `/${client}/daily-uph/by-site-employee-and-shift`,
        client,
      },
      dailyUphBySiteEmployeeShiftAndArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphBySiteEmployeeShiftAndArea,
        reportLabel: t('reports.labels.uph_by_site_employee_shift_and_area'),
        resourceUrl: `/${client}/daily-uph/by-site-employee-shift-and-area`,
        client,
      },
      dailyUphBySiteEmployeeShiftAndDepartment: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteEmployeeShiftAndDepartment,
        reportLabel: t(
          'reports.labels.uph_by_site_employee_shift_and_department',
        ),
        resourceUrl: `/${client}/daily-uph/by-site-employee-shift-and-department`,
        client,
      },
      dailyUphBySiteShiftAndArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphBySiteShiftAndArea,
        reportLabel: t('reports.labels.uph_by_site_shift_and_area'),
        resourceUrl: `/${client}/daily-uph/by-site-shift-and-area`,
        client,
      },
      dailyUphBySiteShiftAndDepartment: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteShiftAndDepartment,
        reportLabel: t('reports.labels.uph_by_site_shift_and_department'),
        resourceUrl: `/${client}/daily-uph/by-site-shift-and-department`,
        client,
      },
      dailyUphBySiteAndArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphBySiteAndArea,
        reportLabel: t('reports.labels.uph_by_site_and_area'),
        resourceUrl: `/${client}/daily-uph/by-site-and-area`,
        client,
      },
      dailyUphBySiteAndShift: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteAndShift,
        reportLabel: t('reports.labels.uph_by_site_and_shift'),
        resourceUrl: `/${client}/daily-uph/by-site-and-shift`,
        client,
      },
      dailyUphBySiteAndMetatag: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphBySiteAndMetatag,
        reportLabel: t('reports.labels.uph_by_site_and_metatag'),
        resourceUrl: `/${client}/daily-uph/by-site-and-metatag`,
        client,
      },
      dailyUphBySiteSkuAndEmployee: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.uphBySiteSkuAndEmployee,
        reportLabel: t('reports.labels.uph_by_site_sku_and_employee'),
        resourceUrl: `/${client}/daily-uph/by-site-sku-and-employee`,
        client,
      },
      dailyLpphBySite: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lpphBySite,
        reportLabel: t('reports.labels.lpph_by_site'),
        resourceUrl: `/${client}/daily-uph/by-site`,
        client,
      },
      dailyLpphBySiteAndEmployee: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lpphBySiteAndEmployee,
        reportLabel: t('reports.labels.lpph_by_site_and_employee'),
        resourceUrl: `/${client}/daily-uph/by-site-and-employee`,
        client,
      },
      dailyLpphBySiteAndArea: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lpphBySiteAndArea,
        reportLabel: t('reports.labels.lpph_by_site_and_area'),
        resourceUrl: `/${client}/daily-uph/by-site-and-area`,
        client,
      },
      dailyLpphBySiteAndShift: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lpphBySiteAndShift,
        reportLabel: t('reports.labels.lpph_by_site_and_shift'),
        resourceUrl: `/${client}/daily-uph/by-site-and-shift`,
        client,
      },
      dailyLpphBySiteAndSku: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lpphBySiteAndSku,
        reportLabel: t('reports.labels.lpph_by_site_and_sku'),
        resourceUrl: `/${client}/daily-uph/by-site-and-sku`,
        client,
      },
      dailyLpphBySiteSkuAndEmployee: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.lpphBySiteSkuAndEmployee,
        reportLabel: t('reports.labels.lpph_by_site_sku_and_employee'),
        resourceUrl: `/${client}/daily-uph/by-site-sku-and-employee`,
        client,
      },
      dailyLphBySite: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyLphBySite,
        reportLabel: t('reports.labels.lph_by_site'),
        resourceUrl: `/${client}/daily-uph/by-site`,
        client,
      },
      dailyLphBySiteAndShift: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyLphBySiteAndShift,
        reportLabel: t('reports.labels.lph_by_site_and_shift'),
        resourceUrl: `/${client}/daily-uph/by-site-and-shift`,
        client,
      },
      dailyUphBySiteAndDepartment: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphBySiteAndDepartment,
        reportLabel: t('reports.labels.uph_by_site_and_department'),
        resourceUrl: `/${client}/daily-uph/by-site-and-department`,
        client,
      },
      dailyUphByLine: {
        reportTypeId: reportTypeIds.daily,
        reportTypeLabel: t('reports.type_labels.daily'),
        reportId: reportIds.dailyUphByLine,
        reportLabel: t('reports.labels.uph_by_line'),
        resourceUrl: `/${client}/daily-uph/by-line`,
        client,
      },
      exceptionsFromEmployees: {
        reportTypeId: reportTypeIds.exceptions,
        reportTypeLabel: t('reports.type_labels.exceptions'),
        reportId: reportIds.exceptionsFromEmployees,
        reportLabel: t('reports.labels.exceptions_from_employees'),
        resourceUrl: `/${client}/exceptions/production/from-employee`,
        isOnlyDataUrlExists: true,
        client,
      },
      exceptionsFromProduction: {
        reportTypeId: reportTypeIds.exceptions,
        reportTypeLabel: t('reports.type_labels.exceptions'),
        reportId: reportIds.exceptionsFromProduction,
        reportLabel: t('reports.labels.exceptions_from_production'),
        resourceUrl: `/${client}/exceptions/production/from-production`,
        isOnlyDataUrlExists: true,
        client,
      },
      weeklyCpu: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.weeklyCpu,
        reportLabel: t('reports.labels.weekly_cpu'),
        resourceUrl: `/${client}/weekly-cpu`,
        client,
      },
      weeklyProduction: {
        reportTypeId: reportTypeIds.production,
        reportTypeLabel: t('reports.type_labels.production'),
        reportId: reportIds.weeklyProduction,
        reportLabel: t('reports.labels.weekly_production'),
        resourceUrl: `/${client}/weekly-production`,
        client,
      },
      dailyCpu: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.dailyCpu,
        reportLabel: t('reports.labels.daily_cpu'),
        resourceUrl: `/${client}/daily-cpu`,
        client,
      },
      dailyProduction: {
        reportTypeId: reportTypeIds.production,
        reportTypeLabel: t('reports.type_labels.production'),
        reportId: reportIds.dailyCpu,
        reportLabel: t('reports.labels.daily_production'),
        resourceUrl: `/${client}/daily-production`,
        client,
      },
      weeklyCpuDetailed: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.weeklyCpuDetailed,
        reportLabel: t('reports.labels.weekly_cpu_detailed'),
        resourceUrl: `/${client}/weekly-cpu/by-site-sku-and-employee`,
        client,
      },
      weeklyGrossMargin: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.weeklyGrossMargin,
        reportLabel: t('reports.labels.weekly_gross_margin'),
        resourceUrl: `/gross-margin/weekly`,
        client,
      },
      payboardSummary: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.payboardSummary,
        reportLabel: t('reports.labels.payboard_summary'),
        resourceUrl: `/${client}/payboard-summary`,
        client,
      },
      kronosPayboard: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.kronosPayboard,
        reportLabel: t('reports.labels.kronos_payboard'),
        resourceUrl: `/${client}/kronos-payboard`,
        client,
      },
      weeklyGrossMarginDetailed: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.weeklyGrossMarginDetailed,
        reportLabel: t('reports.labels.weekly_gross_margin_detailed'),
        resourceUrl: `/gross-margin`,
        client,
      },
      weeklyStaticCostPlus: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.weeklyStaticCostPlus,
        reportLabel: t('reports.labels.weekly_static_cost_plus'),
        resourceUrl: `/static-cost-plus/client/${client}`,
        policyUrl: '/static-cost-plus/client/{clientKey}',
        client,
      },
      weeklyUphBySite: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySite,
        reportLabel: t('reports.labels.uph_by_site'),
        resourceUrl: `/${client}/weekly-uph/by-site`,
        client,
      },
      weeklyUphByLine: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphByLine,
        reportLabel: t('reports.labels.uph_by_line'),
        resourceUrl: `/${client}/weekly-uph/by-line`,
        client,
      },
      weeklyUphBySiteEmployeeAndArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphBySiteEmployeeAndArea,
        reportLabel: t('reports.labels.uph_by_site_employee_and_area'),
        resourceUrl: `/${client}/weekly-uph/by-site-employee-and-area`,
        client,
      },
      weeklyUphBySiteEmployeeAndDepartment: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteEmployeeAndDepartment,
        reportLabel: t('reports.labels.uph_by_site_employee_and_department'),
        resourceUrl: `/${client}/weekly-uph/by-site-employee-and-department`,
        client,
      },
      weeklyUphBySiteEmployeeAndShift: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteEmployeeAndShift,
        reportLabel: t('reports.labels.uph_by_site_employee_and_shift'),
        resourceUrl: `/${client}/weekly-uph/by-site-employee-and-shift`,
        client,
      },
      weeklyUphBySiteShiftAndArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphBySiteShiftAndArea,
        reportLabel: t('reports.labels.uph_by_site_shift_and_area'),
        resourceUrl: `/${client}/weekly-uph/by-site-shift-and-area`,
        client,
      },
      weeklyUphBySiteShiftAndDepartment: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteShiftAndDepartment,
        reportLabel: t('reports.labels.uph_by_site_shift_and_department'),
        resourceUrl: `/${client}/weekly-uph/by-site-shift-and-department`,
        client,
      },
      weeklyUphByPickCartons: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphByPickCartons,
        reportLabel: t('reports.labels.uph_by_pick_cartons'),
        resourceUrl: `/${client}/weekly-uph/by-pick-cartons`,
        client,
      },
      weeklyLphByPickCartons: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lphByPickCartons,
        reportLabel: t('reports.labels.lph_by_pick_cartons'),
        resourceUrl: `/${client}/weekly-lph/by-pick-cartons`,
        client,
      },
      weeklyLphByPutAwayHandlingUnit: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lphByPutAwayHandlingUnit,
        reportLabel: t('reports.labels.lph_by_put_away_handling_unit'),
        resourceUrl: `/${client}/weekly-lph/by-putaway`,
        client,
      },
      weeklyLphByLoadHandlingUnit: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lphByLoadHandlingUnit,
        reportLabel: t('reports.labels.lhp_by_load_handling_unit'),
        resourceUrl: `/${client}/weekly-lph/by-load`,
        client,
      },
      weeklyUphBySiteAndEmployee: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteAndEmployee,
        reportLabel: t('reports.labels.uph_by_site_and_employee'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-employee`,
        client,
      },
      weeklyUphBySiteAndArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphBySiteAndArea,
        reportLabel: t('reports.labels.uph_by_site_and_area'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-area`,
        client,
      },
      weeklyUphBySiteEmployeeShiftAndArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphBySiteEmployeeShiftAndArea,
        reportLabel: t('reports.labels.uph_by_site_employee_shift_and_area'),
        resourceUrl: `/${client}/weekly-uph/by-site-employee-shift-and-area`,
        client,
      },
      weeklyUphBySiteEmployeeShiftAndDepartment: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteEmployeeShiftAndDepartment,
        reportLabel: t(
          'reports.labels.uph_by_site_employee_shift_and_department',
        ),
        resourceUrl: `/${client}/weekly-uph/by-site-employee-shift-and-department`,
        client,
      },
      weeklyUphBySiteSkuAndEmployee: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphBySiteSkuAndEmployee,
        reportLabel: t('reports.labels.uph_by_site_sku_and_employee'),
        resourceUrl: `/${client}/weekly-uph/by-site-sku-and-employee`,
        client,
      },
      weeklyUphBySiteAndSku: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.uphBySiteAndMetatag,
        reportLabel: t('reports.labels.uph_by_site_and_sku'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-sku`,
        client,
      },
      weeklyUphBySiteAndShift: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteAndShift,
        reportLabel: t('reports.labels.uph_by_site_and_shift'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-shift`,
        client,
      },
      weeklyLpphBySite: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lpphBySite,
        reportLabel: t('reports.labels.lpph_by_site'),
        resourceUrl: `/${client}/weekly-uph/by-site`,
        client,
      },
      weeklyLpphBySiteAndEmployee: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lpphBySiteAndEmployee,
        reportLabel: t('reports.labels.lpph_by_site_and_employee'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-employee`,
        client,
      },
      weeklyLpphBySiteAndArea: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lpphBySiteAndArea,
        reportLabel: t('reports.labels.lpph_by_site_and_area'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-area`,
        client,
      },
      weeklyLpphBySiteSkuAndEmployee: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lpphBySiteSkuAndEmployee,
        reportLabel: t('reports.labels.lpph_by_site_sku_and_employee'),
        resourceUrl: `/${client}/weekly-uph/by-site-sku-and-employee`,
        client,
      },
      weeklyLpphBySiteAndSku: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lpphBySiteAndSku,
        reportLabel: t('reports.labels.lpph_by_site_and_sku'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-sku`,
        client,
      },
      weeklyLpphBySiteAndShift: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.lpphBySiteAndShift,
        reportLabel: t('reports.labels.lpph_by_site_and_shift'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-shift`,
        client,
      },
      weeklyLphBySiteAndShift: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyLphBySiteAndShift,
        reportLabel: t('reports.labels.lph_by_site_and_shift'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-shift`,
        client,
      },
      weeklyLphBySite: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyLphBySite,
        reportLabel: t('reports.labels.lph_by_site'),
        resourceUrl: `/${client}/weekly-uph/by-site`,
        client,
      },
      weeklyUphBySiteAndDepartment: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyUphBySiteAndDepartment,
        reportLabel: t('reports.labels.uph_by_site_and_department'),
        resourceUrl: `/${client}/weekly-uph/by-site-and-department`,
        client,
      },
      dailyGaps: {
        reportTypeId: reportTypeIds.gaps,
        reportTypeLabel: t('reports.type_labels.gaps'),
        reportId: reportIds.gaps,
        reportLabel: t('reports.labels.report'),
        resourceUrl: `/${client}/gaps/daily`,
        client,
      },
      scorecard: {
        reportTypeId: reportTypeIds.scorecard,
        reportTypeLabel: t('reports.type_labels.scorecard'),
        reportId: reportIds.scorecard,
        reportLabel: t('reports.labels.report'),
        resourceUrl: `/${client}/scorecard`,
        isOnlyDataUrlExists: true,
        client,
      },
      weeklyExecutiveGrossMargin: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.weeklyGrossMargin,
        reportLabel: t('reports.labels.weekly_gross_margin'),
        resourceUrl: `/gross-margin/executive/weekly`,
        client,
      },
      monthlyExecutiveGrossMargin: {
        reportTypeId: reportTypeIds.monthly,
        reportTypeLabel: t('reports.type_labels.monthly'),
        reportId: reportIds.monthlyGrossMargin,
        reportLabel: t('reports.labels.monthly_gross_margin'),
        resourceUrl: `/gross-margin/executive/monthly`,
        client,
      },
      top5Cpu: {
        reportTypeId: reportTypeIds.cpu,
        reportTypeLabel: t('reports.type_labels.cpu'),
        reportId: reportIds.top5Cpu,
        reportLabel: t('reports.labels.top_5_cpu'),
        resourceUrl: `/${client}/top5-cpu`,
        client,
      },
      pulseSummary: {
        reportTypeId: reportTypeIds.pulse,
        reportTypeLabel: t('reports.type_labels.pulse'),
        reportId: reportIds.pulseSummary,
        reportLabel: t('reports.labels.pulse_summary'),
        resourceUrl: `/optiturn/pulse/summary`,
        client,
      },
      incentiveIndividual: {
        reportTypeId: reportTypeIds.incentive,
        reportTypeLabel: t('reports.type_labels.incentive'),
        reportId: reportIds.incentiveIndividual,
        reportLabel: t('reports.labels.incentive_individual'),
        resourceUrl: `/${client}/incentive/individual`,
        client,
      },
      incentiveIndirect: {
        reportTypeId: reportTypeIds.incentive,
        reportTypeLabel: t('reports.type_labels.incentive'),
        reportId: reportIds.incentiveIndirect,
        reportLabel: t('reports.labels.incentive_indirect'),
        resourceUrl: `/${client}/incentive/indirect`,
        client,
      },
      employeesStatistic: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.employeesStatistic,
        reportLabel: t('reports.labels.employees_statistic'),
        resourceUrl: `/employees/statistic/for-executives`,
        client,
      },
      paymentStatistic: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.paymentStatistic,
        reportLabel: t('reports.labels.payment_statistic'),
        resourceUrl: `/invoice/statistic/for-executives`,
        client,
      },
      sonyPayboardExport: {
        reportTypeId: reportTypeIds.weekly,
        reportTypeLabel: t('reports.type_labels.weekly'),
        reportId: reportIds.sonyPayboardExport,
        reportLabel: t('reports.labels.sony_payboard_export'),
        resourceUrl: `/${clients.sony}/payboard/weekly-kronos`,
        client: clients.sony,
      },
    }),
    [client, t],
  );
};

// export const mapReportConfigToReportPolicy = (
//   reportConfig: {
//     reportTypeId: string;
//     reportTypeLabel: string;
//     reportId: string;
//     reportLabel: string;
//     resourceUrl: string;
//   },
//   client,
// ) => {
//   return React.useMemo(() => {
//     return [
//       {
//         resource: resourceUrl,
//         action: SCOPES.VIEW,
//         clientKey: client,
//       },
//     ];
//   }, []);
// };

export const useCreateReportFiltersConfigWithDefaultOptions = (
  config: Array<IFilterPanelOption>,
) => {
  const { t } = useTranslation();

  return React.useMemo(
    () => [
      {
        id: 'activeReportTypeId',
        label: t('reports.types'),
        isPreselected: true,
        isPermanent: true,
      },
      {
        id: 'activeReportId',
        label: t('reports.reports'),
        isPreselected: true,
        isPermanent: true,
      },
      ...config,
    ],
    [config, t],
  );
};

interface IUseFetchReportDataEffectArgs {
  setPage: (page: number) => void;
  defaultPage?: number;
  limit?: number;
  fetchData?: (filter: IFilter) => void;
  fetchCountData?: (filter: IFilter) => void;
  where?: IWhere;
  include?: IFilterInclude[];
  order?: Order;
  orderBy?: string;
}

// !!! DEPRECATED HOOK !!! Don't use it
// @@@TODO: get rid of it
export const useFetchReportTableDataAndCountEffect = ({
  fetchData,
  fetchCountData,
  setPage,
  where,
  include,
  order,
  orderBy,
  defaultPage = DEFAULT_PAGE,
  limit = DEFAULT_LIMIT,
}: IUseFetchReportDataEffectArgs) => {
  // This hook fetch data after component is mounted,
  // then after filters in filter panel are changed
  // it will refetch data with current limit, but page number will be set to default (1st)
  React.useEffect(() => {
    setPage(defaultPage);

    const currentLimit = limit;

    if (fetchData) {
      fetchData({
        filter: {
          where,
          ...(currentLimit > 0
            ? {
                offset: defaultPage * currentLimit,
                limit: currentLimit,
              }
            : {}),
          order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
          ...(include ? { include } : {}),
        },
      });
    }
    // We do not need refetch data when limit is changed
    // because we have handleChangeRowsPerPage that will fetch data
    // on limit change, this hook responsible only for data fetching
    // on filters in filter panel change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, setPage, where, include]);

  // This hook fetch items count after component is mounted,
  // then after filters in filter panel are changed
  // it will refetch data
  React.useEffect(() => {
    if (fetchCountData) {
      fetchCountData({ filter: { where, ...(include ? { include } : {}) } });
    }
  }, [fetchCountData, where, include]);
};
