import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { IFilterData, IWhere } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createSiteTaktExportingSettingsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { createSiteTaktExportingSettingScheme } from 'src/modules/schemes';

const initialValues = {
  siteId: NaN,
  apiKey: '',
  apiKeySecret: '',
  employeesEndpoint: '',
  punchesEndpoint: '',
};

interface ITaktSettingsCreateProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const TaktSettingsCreate = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ITaktSettingsCreateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createSiteTaktExportingSettingScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createSiteTaktExportingSettingsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('site-takt-exporting-settings.create_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <SitesComboBox
                id="siteId"
                required={true}
                formik={formik}
                placeholder="Site"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                id="apiKey"
                label={t(`site-takt-exporting-settings.api_key`)}
                name="apiKey"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required
                fullWidth
                id="apiKeySecret"
                label={t(`site-takt-exporting-settings.api_key_secret`)}
                name="apiKeySecret"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="employeesEndpoint"
                label={t(`site-takt-exporting-settings.employees_endpoint`)}
                name="employeesEndpoint"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="punchesEndpoint"
                label={t(`site-takt-exporting-settings.punches_endpoint`)}
                name="punchesEndpoint"
                formik={formik}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
