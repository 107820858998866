import { noop, find } from 'lodash';
import React from 'react';
import { IFilterPanelOption } from '../FilterPanel';
import { IFilterObject } from 'src/modules/types/table';

interface IReportItemData {
  reportId: string;
  reportLabel: string;
}
interface IReportTypeItemData {
  reportTypeId: string;
  reportTypeLabel: string;
}

export interface IActiveReportExportData {
  filter?: IFilterObject;
  // Seems like we use it only for table summary localization
  localization?: Record<string, string>;
}

export type ReportShape = 'table' | 'chart';

export interface IFiltersConfig {
  [concatenationOfActiveReportIdAndActiveReportTypeId: string]: Array<
    IFilterPanelOption
  >;
}

export interface IReportPageContext {
  activeReportTypeId: string | null;
  reportTypesList: Array<IReportTypeItemData>;
  activeReportId: string | null;
  reportsListByReportType: {
    [reportTypeId: string]: Array<IReportItemData>;
  };
  filtersConfig: Array<IFilterPanelOption>;
  activeReportExportData: IActiveReportExportData | null;

  setActiveReportId: React.Dispatch<
    React.SetStateAction<IReportPageContext['activeReportId']>
  >;
  setActiveReportTypeId: React.Dispatch<
    React.SetStateAction<IReportPageContext['activeReportTypeId']>
  >;
  setReportTypesList: React.Dispatch<
    React.SetStateAction<IReportPageContext['reportTypesList']>
  >;
  addReportToReportsListByReportType: (
    reportTypeId: string,
    report: IReportItemData,
  ) => void;
  addReportTypeToReportTypesList: (reportType: IReportTypeItemData) => void;
  addFiltersConfig: (filtersConfig: Array<IFilterPanelOption>) => void;
  addActiveReportExportData: (
    exportData: IActiveReportExportData | null,
  ) => void;
}

export const DIVIDER_OF_REPORT_TYPE_ID_AND_REPORT_ID = ':::';

export const generateKeyForFilterConfigItem = ({
  reportId,
  reportTypeId,
}: {
  reportId: string;
  reportTypeId: string;
}) => {
  return `${reportTypeId}${DIVIDER_OF_REPORT_TYPE_ID_AND_REPORT_ID}${reportId}`;
};

export const ReportPageContext = React.createContext<IReportPageContext>({
  activeReportTypeId: null,
  reportTypesList: [],
  activeReportId: null,
  reportsListByReportType: {},
  filtersConfig: [],
  activeReportExportData: {
    filter: undefined,
    localization: undefined,
  },

  setActiveReportId: noop,
  setReportTypesList: noop,
  setActiveReportTypeId: noop,
  addReportToReportsListByReportType: noop,
  addReportTypeToReportTypesList: noop,
  addFiltersConfig: noop,
  addActiveReportExportData: noop,
});

export const ReportPageContextProvider: React.FC = ({ children }) => {
  const [activeReportTypeId, setActiveReportTypeId] = React.useState<
    IReportPageContext['activeReportTypeId']
  >(null);
  const [reportTypesList, setReportTypesList] = React.useState<
    IReportPageContext['reportTypesList']
  >([]);

  const [reportsListByReportType, setReportsListByReportType] = React.useState<
    IReportPageContext['reportsListByReportType']
  >({});
  const [activeReportId, setActiveReportId] = React.useState<
    IReportPageContext['activeReportId']
  >(null);
  const [filtersConfig, setFiltersConfig] = React.useState<
    Array<IFilterPanelOption>
  >([]);

  const [
    activeReportExportData,
    setActiveReportExportDataList,
  ] = React.useState<IReportPageContext['activeReportExportData']>(null);

  const addReportToReportsListByReportType = React.useCallback(
    (reportTypeId: string, report: IReportItemData) => {
      const existedReport = find(
        reportsListByReportType[reportTypeId],
        (existedReport) => {
          return existedReport.reportId === report.reportId;
        },
      );
      if (existedReport) {
        return;
      }

      setReportsListByReportType((prev) => {
        return {
          ...prev,
          [reportTypeId]: prev[reportTypeId]
            ? [...prev[reportTypeId], report]
            : [report],
        };
      });
    },
    [reportsListByReportType],
  );
  const addReportTypeToReportTypesList = React.useCallback(
    (reportTypeData: IReportTypeItemData) => {
      setReportTypesList((prev) => {
        const alreadyExists = prev.some(
          (reportType) =>
            reportType.reportTypeId === reportTypeData.reportTypeId,
        );

        if (alreadyExists) {
          return [...prev];
        }
        return [...prev, reportTypeData];
      });
    },
    [],
  );

  const addFiltersConfig = React.useCallback(
    (filtersConfig: Array<IFilterPanelOption>) => {
      setFiltersConfig(filtersConfig);
    },
    [],
  );

  const addActiveReportExportData = React.useCallback(
    (exportData: IActiveReportExportData | null) => {
      setActiveReportExportDataList(exportData);
    },
    [],
  );

  return (
    <ReportPageContext.Provider
      value={{
        activeReportId,
        activeReportTypeId,
        reportTypesList,
        reportsListByReportType,
        filtersConfig,
        activeReportExportData,

        setActiveReportId,
        setReportTypesList,
        setActiveReportTypeId,
        addReportTypeToReportTypesList,
        addReportToReportsListByReportType,
        addFiltersConfig,
        addActiveReportExportData,
      }}
    >
      {children}
    </ReportPageContext.Provider>
  );
};
