import React from 'react';
import { AnyObject } from 'src/modules/types';
import { ErrorMode } from './types';
import { get } from 'lodash';
import { ButtonGroup } from '../_ui-kit/ButtonGroup';

export type FormikButtonGroupConfig = Array<{
  value: string | number | boolean;
  label: string;
}>;

interface IFormikButtonGroupProps {
  id: string;
  formik: AnyObject;
  config: FormikButtonGroupConfig;
  label?: string;
  disabled?: boolean;
  errorMode?: ErrorMode;
  required?: boolean;
}

export const FormikButtonGroup = ({
  id,
  formik,
  disabled,
  config,
  label,
}: IFormikButtonGroupProps) => {
  const option = get(formik.values, id);

  return (
    <ButtonGroup
      label={label}
      disabled={disabled}
      buttons={config}
      activeButtonValue={option}
      onButtonClick={(value) => {
        if (disabled) {
          return;
        }

        formik.setFieldError(id, undefined);
        formik.setFieldValue(id, value);
      }}
    />
  );
};
