import React from 'react';
import { Box } from '@mui/joy';

interface IChartsDashboardChartChart {
  children: React.ReactNode;
}

export const ChartsDashboardChart = ({
  children,
}: React.PropsWithChildren<IChartsDashboardChartChart>) => {
  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        pt: 1,
      }}
    >
      {children}
    </Box>
  );
};
