import React from 'react';
import { useTranslation } from 'react-i18next';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import { useReportsProps } from 'src/modules/utils/hooks/reportPage.hooks';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { YEAR_WEEK_ISO, format } from 'src/modules/utils/dateWrapper';
import { subWeeks } from 'date-fns';
import { concatenateYearWeek } from 'src/modules/utils/helpers/formatters';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { IListWithSummary } from 'src/modules/types';
import { IEmployeesStatisticResponseItem } from 'src/modules/types/executive-reports';
interface IEmployeesStatisticChartProps {
  from?: number;
  to?: number;
}

export const EmployeesStatisticChart: React.FC<IEmployeesStatisticChartProps> = ({
  from,
  to,
}) => {
  const { t } = useTranslation();
  const toDate = new Date();
  const toYearWeek = format(YEAR_WEEK_ISO)(toDate);

  const fromDate = subWeeks(toDate, 52);
  const fromYearWeek = format(YEAR_WEEK_ISO)(fromDate);

  const [toYear, toWeek] = toYearWeek.split('-W');
  const [fromYear, fromWeek] = fromYearWeek.split('-W');

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const reportProps = useReportsProps();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<IEmployeesStatisticResponseItem>
  >(reportProps.employeesStatistic.resourceUrl, {
    items: [],
    summary: [],
  });

  const categories = React.useMemo(
    () => data.items.map((item) => `${item.year}-W${item.week}`),
    [data.items],
  );
  const hiredHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.hired_headcount'),
      data: data.items.map((item) => item.hiredHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );
  const inactivatedHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.inactivated_headcount'),
      data: data.items.map((item) => item.inactivatedHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );
  const withApprovedHoursHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.active'),
      data: data.items.map((item) => item.withApprovedHoursHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );

  React.useEffect(() => {
    // We have next states of from/to:
    // 1. from = undefined and to = undefined => user does not touch filter, we should use default from/to
    // 2. from = "some value" and to = "some value" => user selected yearWeek, we use user selection
    // 3. from = null || to = null, user select then remove a value, we should not use any value
    const and = [];
    if (from !== null) {
      and.push({
        yearWeek: {
          gte:
            from ??
            Number(
              concatenateYearWeek({
                year: Number(fromYear),
                week: Number(fromWeek),
              }),
            ),
        },
      });
    }
    if (to !== null) {
      and.push({
        yearWeek: {
          lt:
            to ??
            Number(
              concatenateYearWeek({
                year: Number(toYear),
                week: Number(toWeek),
              }),
            ),
        },
      });
    }

    fetchData({
      filter: {
        order: ['yearWeek asc'],
        where: { and },
      },
    });
  }, [fetchData, from, fromWeek, fromYear, to, toWeek, toYear]);

  return (
    <>
      <ChartContainer fullHeigh={false}>
        <ChartContainerHeader>
          <ChartContainerToolbar>
            <ChartContainerUtils
              onToggleFullScreen={toggleFullScreen}
              onExport={() => exportChart(`quarterly_analysis`)}
            />
          </ChartContainerToolbar>
        </ChartContainerHeader>
        <ChartContainerBody isLoading={isDataLoading}>
          <ColumnChart
            ref={chartRef}
            height={chartHeight}
            categories={categories}
            series={[
              hiredHeadcount,
              inactivatedHeadcount,
              withApprovedHoursHeadcount,
            ]}
          />
        </ChartContainerBody>
      </ChartContainer>
    </>
  );
};
