import { Box } from '@mui/material';
import React from 'react';
import { IWhere, IFilter } from 'src/modules/types';

interface ITaskViewToolbarProps {
  onSubmitFilters: (where?: IWhere) => void;
  onResetFilters: (defaultFilter: IFilter) => void;
}

export const TaskViewToolbar: React.FC<ITaskViewToolbarProps> = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        paddingBottom: 2,
      }}
    >
      {/**@@TODO: fix filters */}
      {/* <Filter
        filters={filters}
        onSubmit={submitFiltersHandler}
        onFilterReset={resetFiltersHandler}
      /> */}
    </Box>
  );
};
