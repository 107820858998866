import * as React from 'react';
import JoyModal, { ModalProps } from '@mui/joy/Modal';
import ModalOverflow from '@mui/joy/ModalOverflow';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';

export const ModalDrillDown = ({ children, title, ...rest }: ModalProps) => {
  return (
    <JoyModal {...rest}>
      <ModalOverflow>
        <ModalDialog
          aria-labelledby="modal-dialog-overflow"
          layout="fullscreen"
          sx={{
            paddingX: '0px',
            paddingTop: 3,
          }}
        >
          <ModalClose sx={{ padding: 2.5 }} />
          <DialogTitle
            sx={{
              paddingX: 3,
              paddingBottom: 2.25,
              fontSize: '1.25rem',
              lineHeight: '30px',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: 'colors.border.border_primary',
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            sx={{
              paddingTop: 2,
            }}
          >
            {children}
          </DialogContent>
        </ModalDialog>
      </ModalOverflow>
    </JoyModal>
  );
};
