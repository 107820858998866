import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Switch, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { chain, cloneDeep, set } from 'lodash';
import {
  useSitesOptions,
  useProductionSettingsForm,
} from '../../modules/utils';
import { FormActions } from '../../components/Form/FormActions';
import ComboBox, { ComboBoxOption } from '../../components/ComboBox';
import { ProductionSettingConfig } from '../../modules/types';
import { MUI_SIZE_12, MUI_SIZE_2, MUI_SIZE_4 } from '../../config';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IProductionSettingsForm {
  siteId: number;
  buttonTitle: string;
}

const ProductionSettingsForm: React.FC<IProductionSettingsForm> = ({
  siteId,
  buttonTitle,
}) => {
  const { t } = useTranslation();

  const {
    fields,
    metatags,
    isLoading,
    sendToServer,
    sendLoading,
  } = useProductionSettingsForm(siteId);

  const [productionSettings, setProductionSettings] = React.useState<
    ProductionSettingConfig
  >({
    fields,
    metatags,
  });

  React.useEffect(() => setProductionSettings({ fields, metatags }), [
    metatags,
    fields,
  ]);

  const handleTitleChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value);
      return value;
    });
  };

  const handleMetatagOptionsChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value.split('\n'));
      return value;
    });
  };

  const handleSwitchChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.checked);
      return value;
    });
  };

  const handleMetatagTypeChange = (id: string) => (
    e: React.SyntheticEvent<Element, Event>,
    v: ComboBoxOption | null,
  ) => {
    setProductionSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, v?.id);
      return value;
    });
  };

  const metatagTypesOptions = React.useMemo(
    () =>
      ([
        {
          id: 'text',
          name: 'text',
        },
        {
          id: 'combobox',
          name: 'combobox',
        },
        {
          id: 'checkbox',
          name: 'checkbox',
        },
      ] as unknown) as ComboBoxOption[],
    [],
  );

  const getOption = React.useCallback(
    (type: string) => metatagTypesOptions.find((i) => `${i.id}` === type),
    [metatagTypesOptions],
  );

  const formFields: JSX.Element[] = React.useMemo(() => {
    return chain(productionSettings.fields)
      .map(({ title, enabled }, fieldName) => (
        <FormFieldContainer key={fieldName}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <TextField
              label={t('production_settings.field_title')}
              value={title}
              disabled={!enabled}
              onChange={handleTitleChange(`fields.${fieldName}.title`)}
            />
            <Switch
              checked={enabled}
              onChange={handleSwitchChange(`fields.${fieldName}.enabled`)}
            />
          </Box>
        </FormFieldContainer>
      ))
      .value();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionSettings.fields]);

  const formMetatags: JSX.Element[] = React.useMemo(() => {
    return chain(productionSettings.metatags)
      .map(({ title, name, type, options }) => (
        <React.Fragment key={name}>
          <Typography variant="h6" sx={{ marginBottom: 4 }}>
            {name}
          </Typography>
          <Grid container spacing={MUI_SIZE_2} alignItems="flex-start">
            <Grid item md={MUI_SIZE_4} xs={MUI_SIZE_12}>
              <FormFieldContainer>
                <ComboBox
                  label={t('production_settings.metatag.type')}
                  placeholder={t('production_settings.metatag.type')}
                  options={metatagTypesOptions}
                  value={getOption(type)}
                  onChange={handleMetatagTypeChange(`metatags.${name}.type`)}
                />
              </FormFieldContainer>
            </Grid>
            <Grid item md={MUI_SIZE_4} xs={MUI_SIZE_12}>
              <FormFieldContainer>
                <TextField
                  fullWidth
                  label={t('production_settings.metatag.title')}
                  value={title}
                  onChange={handleTitleChange(`metatags.${name}.title`)}
                />
              </FormFieldContainer>
            </Grid>
            <Grid item md={MUI_SIZE_4} xs={MUI_SIZE_12}>
              {type === 'combobox' && (
                <FormFieldContainer>
                  <TextField
                    fullWidth
                    multiline
                    label={t('production_settings.metatag.options')}
                    value={options?.join('\n')}
                    onChange={handleMetatagOptionsChange(
                      `metatags.${name}.options`,
                    )}
                  />
                </FormFieldContainer>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      ))
      .value();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionSettings.metatags]);

  const handleSubmit = () => {
    sendToServer(productionSettings);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: 4 }}>
        {t('production_settings.form_fields_title')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {formFields}
      </Box>

      <Typography variant="h5" sx={{ marginBottom: 4 }}>
        {t('production_settings.metatags_fields_title')}
      </Typography>
      <Box>{formMetatags}</Box>

      <FormActions
        onClick={handleSubmit}
        submitBtnTitle={buttonTitle}
        isLoading={sendLoading}
      />
    </>
  );
};

const ProductionSettings: React.FC = () => {
  const { t } = useTranslation();
  const [site, setSite] = React.useState<ComboBoxOption>({
    id: NaN,
    name: '',
  });

  const siteOptions = useSitesOptions();

  const handleSiteChange = React.useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: ComboBoxOption | null,
    ) => value && setSite(value as ComboBoxOption),
    [setSite],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <Paper
          sx={(theme) => ({
            width: '100%',
            maxWidth: 945,
            padding: '32px 64px',
            minHeight: 610,

            [theme.breakpoints.down('md')]: {
              padding: 1,
            },
          })}
        >
          <Typography variant="h5">
            {t('production_settings.form_title')}
          </Typography>

          <Box sx={{ margin: '64px 0' }}>
            <ComboBox
              id="site"
              placeholder={t('productions.site.name')}
              options={siteOptions}
              value={site}
              onChange={handleSiteChange}
            />
          </Box>

          <Box>
            {Boolean(site?.id) && (
              <>
                <ProductionSettingsForm
                  siteId={site.id}
                  buttonTitle={t('common.save')}
                />
              </>
            )}
          </Box>
        </Paper>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default ProductionSettings;
