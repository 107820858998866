import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/joy';
import Datepicker from '../Datepicker';
import { DATE_FORMATS } from '../../../../../modules/utils/dateWrapper';
import { Backdrop } from '@mui/material';
import { XCloseSvg } from 'src/components/svgIcons';

export interface IDateRangePickerValue {
  start?: string | null;
  end?: string | null;
}

export interface IDateRangePickerProps {
  id: string;
  label?: string;
  value?: IDateRangePickerValue;
  format?: DATE_FORMATS;
  onChange(dates: IDateRangePickerValue): void;
}

const DateRangePicker: React.FC<IDateRangePickerProps> = (props) => {
  const [isLeftOpen, setIsLeftOpen] = React.useState(false);
  const [isRightOpen, setIsRightOpen] = React.useState(false);

  const [dates, setDates] = useState<IDateRangePickerValue | undefined>(
    undefined,
  );

  useEffect(() => {
    if (props.value) {
      setDates(props.value);
    }
  }, [props]);

  // daterange handler
  const handleDaterangePicker = (position: keyof IDateRangePickerValue) => (
    datetime: string,
  ) => {
    const updatedDates = {
      ...dates,
      [position]: datetime,
    } as IDateRangePickerValue;

    setDates(updatedDates);
    props.onChange(updatedDates);
    setIsLeftOpen(false);
    setIsRightOpen(false);
  };

  const onClear = () => {
    const dates = {
      start: undefined,
      end: undefined,
    };

    setDates(dates);
    props.onChange(dates);
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Backdrop
        sx={{ backgroundColor: 'transparent', zIndex: 9 }}
        open={isLeftOpen || isRightOpen}
        onClick={() => {
          setIsLeftOpen(false);
          setIsRightOpen(false);
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <Datepicker
            id={`${props.id}_from`}
            label={props.label ?? ''}
            fullWidth={true}
            maxDate={dates?.end ? new Date(Date.parse(dates.end)) : new Date()}
            value={dates?.start}
            onChange={handleDaterangePicker('start')}
            open={isLeftOpen}
            onOpen={() => setIsLeftOpen(true)}
            onClose={() => setIsLeftOpen(false)}
          />
          <Box
            sx={{
              mb: 2.5,
              mx: '4px',
              minWidth: '8px',
              minHeight: '1px',
              bgcolor: 'colors.foreground.fg_quarterary',
            }}
          />
          <Datepicker
            id={`${props.id}_to`}
            label={''}
            fullWidth={true}
            value={dates?.end}
            minDate={
              dates?.start ? new Date(Date.parse(dates.start)) : new Date()
            }
            onChange={handleDaterangePicker('end')}
            open={isRightOpen}
            onOpen={() => setIsRightOpen(true)}
            onClose={() => setIsRightOpen(false)}
          />
        </Box>
        <Box sx={{ ml: 0.5 }}>
          <IconButton sx={{ height: 40 }} variant="outlined" onClick={onClear}>
            <XCloseSvg />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DateRangePicker;
