import React, { useEffect } from 'react';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import {
  format,
  composeDate,
  TIME_FORMAT,
  tryToGenerateDateFromAnything,
} from '../../modules/utils/dateWrapper';
import { TextFieldProps } from '@mui/material';

export interface ITimepickerProps
  extends Omit<TimePickerProps<Date | string | null>, 'onChange'> {
  id: string;
  label: string;
  onChange: (date: string) => void;
  value?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  variant?: TextFieldProps['variant'];
  helperText?: React.ReactNode;
  disabled?: boolean;
  margin?: TextFieldProps['margin'];
  size?: TextFieldProps['size'];
}

export default function Timepicker({
  value,
  onChange,
  label,
  helperText,
  fullWidth,
  required,
  error,
  variant,
  id,
  margin = 'normal',
  size = 'small',
  slotProps,
  ...rest
}: ITimepickerProps) {
  const [selectedTime, setSelectedTime] = React.useState<Date | null>(null);

  // init state from props
  useEffect(() => {
    const timeObj = tryToGenerateDateFromAnything(value);

    if (timeObj) {
      setSelectedTime(timeObj);
    }
  }, [value]);

  const handleDateChange = (time: Date | string | null | undefined) => {
    const timeObj = tryToGenerateDateFromAnything(time);

    if (!timeObj) {
      return;
    }

    const formattedTimeToPass = composeDate(timeObj, format(TIME_FORMAT));

    setSelectedTime(timeObj);
    onChange(formattedTimeToPass);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        {...rest}
        value={selectedTime}
        format={TIME_FORMAT}
        label={label}
        slotProps={{
          ...slotProps,
          textField: {
            ...slotProps?.textField,
            helperText,
            fullWidth,
            required,
            error,
            variant,
            margin,
            id,
            size,
          },
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
}
