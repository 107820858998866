import { JSONSchemaType } from 'ajv';
import { IUpdateCpuBasedBaseline } from '../../types';
import { i18n } from '../../utils/i18n';

export const updateCpuBasedBaselineScheme: JSONSchemaType<
  IUpdateCpuBasedBaseline[]
> = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id', 'minCPU', 'maxCPU', 'siteId', 'baseline'],
    additionalProperties: false,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      siteId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      minCPU: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      maxCPU: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      baseline: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
