import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/joy';

import { isSiteByClient } from '../../modules/selectors/site';
import { clients } from '../../modules/constants';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
} from '../../modules/types';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import { ReportTable } from '../../components/ReportPage/ReportTable';

interface IProps {
  siteId?: number;
  year?: number;
  week?: number;
}

export const WeeklyCpu: React.FC<IProps> = ({ siteId, year, week }) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      year,
      week,
    }),
    [siteId, year, week],
  );

  const showTable = useSelector(isSiteByClient, shallowEqual)(
    clients.carparts,
    siteId,
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >('/carparts/weekly-cpu', {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`/carparts/weekly-cpu/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('productions_uph_reports.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('productions_uph_reports.week'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year_week'),
      },
      {
        id: 'weekDay',
        orderConfig: { orderBy: 'weekDay' },
        label: t('productions_uph_reports.week_day'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'cpuBucket',
        orderConfig: { orderBy: 'cpuBucket' },
        label: t('productions_uph_reports.cpuBucket'),
      },
      {
        id: 'priceMultiplier',
        orderConfig: { orderBy: 'priceMultiplier' },
        label: t('productions_uph_reports.priceMultiplier'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        disableSorting: true,
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'price',
        orderConfig: { orderBy: 'price' },
        disableSorting: true,
        label: t('productions_uph_reports.price'),
      },
      {
        id: 'cpu',
        orderConfig: { orderBy: 'cpu' },
        disableSorting: true,
        label: t('productions_uph_reports.cpu'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData: showTable ? fetchData : undefined,
    fetchCountData: showTable ? fetchCountData : undefined,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  return (
    <>
      {!showTable && (
        <Alert variant="soft">{t('common.select_filters_message')}</Alert>
      )}
      {showTable && (
        <ReportTable
          data={data.items as any}
          count={countData.count}
          summaryData={data.summary}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey="/carparts/weekly-cpu"
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
