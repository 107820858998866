import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { AnyObject } from '../../modules/types';

const SparkChart: React.FC<{
  weeks: number[];
  percents: number[];
  type: 'area' | 'column';
}> = ({ weeks, percents, type }) => {
  weeks = weeks ?? [];
  percents = percents ?? [];
  type = type ?? 'area';
  const data = percents
    .map((percent, i) => [weeks[i].toString(), percent])
    .reverse();

  const options = {
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type,
      margin: [2, 0, 2, 0],
      width: 140,
      height: 40,
      style: {
        overflow: 'visible',
      },
      skipClone: true,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tickPositions: [0],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      borderWidth: 0.5,
      borderRadius: 0,
      shadow: false,
      outside: true,
      percentageDecimals: 2,
      backgroundColor: 'rgba(255,255,255,1)',
      formatter: function (): string {
        return `Week: ${(this as AnyObject).point.options.name} 
          <br />
          <b>
            ${(this as AnyObject).y} %
          </b>`;
      },
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2,
            },
          },
        },
        fillOpacity: 0.25,
      },
      column: {
        negativeColor: '#910000',
        borderColor: 'silver',
      },
    },

    series: [
      {
        data,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        updateArgs={[true, true, true]}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default SparkChart;
