import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { profileData } from '../../../modules/selectors/user';
import { useTranslation } from 'react-i18next';
import { Lang } from '../../../modules/utils';
import FormikTextField from '../../../components/Formik/FormikTextField';
import FormikLangCombobox from '../../../components/Formik/FormikLangCombobox';
import { useValidate } from '../../../modules/utils';
import { updateProfileScheme } from '../../../modules/schemes/profile/update-profile.scheme';
import { useFormik } from 'formik';
import { meUpdateRequest } from 'src/modules/actions';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ProfileForm } from '../ProfileForm';

const UpdateProfile = () => {
  const { t } = useTranslation();
  const userProfile = useSelector(profileData, shallowEqual);
  const validate = useValidate(updateProfileScheme);

  const dispatch = useDispatch();

  const initialValues = {
    lang: Lang.EN,
    ...userProfile,
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(meUpdateRequest(data));
    },
  });

  const onReset = () => {
    formik.setValues({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      lang: '',
    });
  };

  return (
    <ProfileForm
      onReset={onReset}
      onApply={formik.handleSubmit}
      title={t('profile.profile_settings')}
    >
      <FormFieldContainer>
        <FormikTextField
          required
          variant="outlined"
          fullWidth
          id="firstName"
          label={t('profile.first_name')}
          name="firstName"
          autoComplete="firstName"
          formik={formik}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          required
          variant="outlined"
          fullWidth
          id="lastName"
          label={t('profile.last_name')}
          name="lastName"
          autoComplete="lastName"
          formik={formik}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="phoneNumber"
          label={t('profile.phone_number')}
          name="phoneNumber"
          autoComplete="phoneNumber"
          type="tel"
          formik={formik}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikLangCombobox required id="lang" formik={formik} />
      </FormFieldContainer>
    </ProfileForm>
  );
};

export default UpdateProfile;
