import { ISagaActionBind } from '../../types';
import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthDailyUphBySiteListRequest,
    saga: createListSaga<ICardinalHealthDailyReportModel>({
      apiCall: Api.CardinalHealthDailyUphBySite.list,
      actionSuccess: actions.getCardinalHealthDailyUphBySiteListSuccess,
      actionFailed: actions.getCardinalHealthDailyUphBySiteListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthDailyUphBySiteCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthDailyUphBySite.count,
      actionSuccess: actions.getCardinalHealthDailyUphBySiteCountSuccess,
      actionFailed: actions.getCardinalHealthDailyUphBySiteCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthDailyUphBySiteRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthDailyUphBySite.export,
      actionSuccess: actions.exportCardinalHealthDailyUphBySiteSuccess,
      actionFailed: actions.exportCardinalHealthDailyUphBySiteFailed,
    }),
  },
];
