import React from 'react';
import { ICommonFilterProps } from '../../types';
import { TableContext } from '../../../../../../TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { useFetchClientKeys } from 'src/modules/utils/hooks/site-importing-settings.hooks';
import { useSelector } from 'react-redux';
import { getClientKeys } from 'src/modules/selectors/site-importing-settings.selector';
import { map } from 'lodash';

interface IClientKeysComboBoxFilterProps
  extends Omit<IComboboxProps, 'value' | 'options' | 'name'>,
    ICommonFilterProps {}

export const ClientKeysComboBoxFilter: React.FC<IClientKeysComboBoxFilterProps> = ({
  value,
  name,
  onChange,
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const fetchClientKeys = useFetchClientKeys();

  React.useEffect(() => {
    fetchClientKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientKeys = useSelector(getClientKeys);

  const clientsOptions = (React.useMemo(
    () =>
      map(clientKeys, (clientKey) => ({
        id: clientKey,
        name: clientKey,
      })),
    [clientKeys],
  ) as unknown) as ComboBoxOption[];

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue =
    clientsOptions.find((option) => option.id === valueId) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);

    if (onChange) {
      onChange(e, value, 'selectOption');
    }
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={clientsOptions}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
