import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageWithTabs } from 'src/components/PageWithTabs';
import {
  getRecruitingDashboardSummaryCountRequest,
  getRecruitingDashboardSummaryListRequest,
} from 'src/modules/actions';
import {
  useFetchSitesCombobox,
  useFilter,
  useRecruitingDashboardSummaryDefaultFilters,
} from 'src/modules/utils';
import { RecruitingDashboardSummaryCharts } from './RecruitingDashboardSummaryCharts';
import { RecruitingDashboardSummaryTable } from './RecruitingDashboardSummaryTable';

export const Turnover = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchSitesCombobox = useFetchSitesCombobox();

  const defaultFilter = useRecruitingDashboardSummaryDefaultFilters();
  const { filterCount, filterList } = useFilter(defaultFilter);

  const configuration = React.useMemo(() => {
    return [
      {
        tabLabel: t('common.charts'),
        component: <RecruitingDashboardSummaryCharts />,
      },
      {
        tabLabel: t('common.table'),
        component: <RecruitingDashboardSummaryTable />,
      },
    ];
  }, [t]);

  React.useEffect(() => {
    fetchSitesCombobox();
    dispatch(getRecruitingDashboardSummaryListRequest(filterList));
    // get total count
    dispatch(getRecruitingDashboardSummaryCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  return <PageWithTabs configuration={configuration} />;
};

export default Turnover;
