import { createAction } from 'redux-act';
import {
  ICreatePricingSingleRequest,
  ICreatePricingRangeRequest,
  IFilter,
  IPricingDeleteRequest,
  IPricingListResponse,
  IPricingModel,
  IUpdatePricing,
  IUpdatePricingRangeRequestAction,
  IPricingRangeDeleteRequest,
  PricingTypeObject,
  IMetatagModel,
  IWhere,
  PayloadWithNavigateFunc,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushPricingState = createAction('Flush pricing data in store');

// Creation actions
export const createPricingSingleRequest = createAction<
  PayloadWithNavigateFunc<ICreatePricingSingleRequest>
>('Make request to create a new single pricing');
export const createPricingSingleSuccess = createAction<IPricingModel>(
  'New single pricing has been created successfully',
);
export const createPricingSingleFailed = createAction(
  'Cannot create a new single pricing',
);

export const createPricingRangeRequest = createAction<
  PayloadWithNavigateFunc<ICreatePricingRangeRequest>
>('Make request to create a new single pricing');
export const createPricingRangeSuccess = createAction<IPricingModel[]>(
  'New single pricing has been created successfully',
);
export const createPricingRangeFailed = createAction(
  'Cannot create a new single pricing',
);

// Fetch pricing actions
export const getPricingListRequest = createAction<{
  siteId: number;
  filter: IFilter;
}>('Make request to fetch pricing list');
export const getPricingListSuccess = createAction<IPricingListResponse>(
  'Pricing list has been fetched successfully',
);
export const getPricingListFailed = createAction('Cannot fetch pricing list');

// delete actions
export const deletePricingRequest = createAction<IPricingDeleteRequest>(
  'Make request to delete pricing',
);
export const deletePricingFailed = createAction('Cannot delete pricing');

export const deletePricingRangeRequest = createAction<
  IPricingRangeDeleteRequest
>('Make request to delete pricing range');
export const deletePricingRangeFailed = createAction(
  'Cannot delete pricing range',
);

// Updating actions
export const updatePricingSingleRequest = createAction<
  PayloadWithNavigateFunc<IUpdatePricing[]>
>('Make request to update pricing single type');
export const updatePricingSingleFailed = createAction(
  'Cannot update pricing single type',
);

// Updating actions
export const updatePricingRangeRequest = createAction<
  PayloadWithNavigateFunc<IUpdatePricingRangeRequestAction>
>('Make request to update pricing range type');
export const updatePricingRangeFailed = createAction(
  'Cannot update pricing range type',
);

// Fetch combobox list actions
export const getPricingComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of pricing',
);
export const getPricingComboboxListSuccess = createAction<
  Partial<IPricingModel>[]
>('Combobox list of pricing has been fetched successfully');
export const getPricingComboboxListFailed = createAction(
  'Cannot fetch combobox pricing list',
);

export const initPricingFetch = createAction<{
  siteId: number;
  where?: IWhere;
}>('Fetch init data for Pricing page');

export const initPricingMetaTagsFetch = createAction<number>(
  'Fetch init data for Pricing page',
);

export const setPricingTypesList = createAction<PricingTypeObject>(
  'Set Pricing types list',
);

export const setPricingMetaTags = createAction<IMetatagModel[]>(
  'Set Pricing meta tags',
);

export const handleSiteChoosing = createAction<PayloadWithNavigateFunc<number>>(
  'Select site id on Pricing page',
);

export const clearPricingListById = createAction<
  PayloadWithNavigateFunc<number>
>('Clear Pricing list by id');

// export actions
export const {
  exportReportRequest: exportPricingRequest,
  exportReportSuccess: exportPricingSuccess,
  exportReportFailed: exportPricingFailed,
} = new ExportActionCreator('Pricing').takeExportActions();
