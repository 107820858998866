import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Api,
  useEmployeeComboboxesDataFetchersList,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addProcessStatus } from 'src/modules/actions';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { PageContentScrollableChildContainer } from 'src/components/PageContent/PageContentScrollableChildContainer';
import { Box, Button, Typography } from '@mui/joy';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { ComboBoxOption } from 'src/components/ComboBox';
import SupervisorComboBox from 'src/components/Formik/comboboxes-with-entities/SupervisorComboBox';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import { createEmployeeWithoutPaymentInfoScheme } from 'src/modules/schemes/employees/create-employee-without-payment-info.scheme';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { getShiftsComboboxListBySiteId } from 'src/modules/selectors/shift';
import { getDepartmentsComboboxListBySiteId } from 'src/modules/selectors/department';
import { getPositionsComboboxListBySiteId } from 'src/modules/selectors/position';
import FormikInputPhoneNumber from 'src/components/Formik/FormikInputPhoneNumber';
import { ButtonGroupsContainer } from 'src/components/_ui-kit/ButtonGroup';
import { FormFiledButtonsGroupItemContainer } from 'src/components/Form/FormFiledButtonsGroupItemContainer';
import { FormikButtonGroup } from 'src/components/Formik/FormikButtonGroup';

const initialValues = {
  employeeId: '',
  siteId: NaN,
  positionId: NaN,
  staffingId: NaN,
  supervisorId: NaN,
  defaultShiftId: NaN,
  defaultDepartmentId: NaN,
  firstName: '',
  lastName: '',
  dateHired: null,
  badge: NaN,

  // Optional properties
  active: true,
  address: '',
  phonenumber: '',
  dateentered: null,
  last4SSN: undefined,
  wmsLogin: '',
  taktId: '',
  canRehire: false,
  wotc: false,
  emergencyContact: '',
  comments: '',
};

export const EmployeesCreateWithoutPaymentInfo = () => {
  const { t } = useTranslation();

  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const dispatch = useDispatch();

  const comboboxesDataFetchersList = useEmployeeComboboxesDataFetchersList();

  const validate = useValidate(createEmployeeWithoutPaymentInfoScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (data) => {
      try {
        setIsRequestInProgress(true);

        await Api.Employee.createWithoutPaymentInfo(data);

        setIsRequestInProgress(false);

        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
      } catch {
        setIsRequestInProgress(false);
      }

      formik.resetForm();
    },
  });

  const selectedSiteId = formik.values.siteId;

  const staffingProviders = useSelector(getStaffingProvidersComboboxList);
  const shifts = useSelector(getShiftsComboboxListBySiteId)(selectedSiteId);
  const departments = useSelector(getDepartmentsComboboxListBySiteId)(
    selectedSiteId,
  );
  const positions = useSelector(getPositionsComboboxListBySiteId)(
    selectedSiteId,
  );

  const onReset = () => {
    formik.resetForm();
  };

  React.useEffect(() => {
    comboboxesDataFetchersList.forEach((func) => func());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentWithTopToolbar>
      <PageContentScrollableChildContainer>
        <Box sx={{ maxWidth: 600 }}>
          <Box sx={{ pt: 2, pb: 1 }}>
            <Typography
              level="text_xl"
              textColor="colors.text.text_primary.main"
            >
              {t('main_menu.employee_create')}
            </Typography>
          </Box>
          <Box
            sx={{
              borderColor: 'colors.border.border_primary',
              borderWidth: 1,
              borderRadius: 8,
              borderStyle: 'solid',
              bgcolor: 'common.white',
            }}
          >
            <Box
              sx={{
                py: 3,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <FormFieldContainer>
                <SitesComboBox
                  id="siteId"
                  required={true}
                  formik={formik}
                  label={t('employees.site')}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  required
                  fullWidth
                  id="firstName"
                  label={t('employees.first_name')}
                  name="firstName"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label={t('employees.last_name')}
                  name="lastName"
                  //
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  required
                  fullWidth
                  id="employeeId"
                  label={t('employees.emp_id')}
                  name="employeeId"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  required
                  fullWidth
                  id="badge"
                  label={t('employees.badge')}
                  name="badge"
                  type="number"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id="positionId"
                  label={t('employees.position')}
                  options={positions as ComboBoxOption[]}
                  formik={formik}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id="staffingId"
                  label={t('employees.staffing_provider')}
                  options={staffingProviders as ComboBoxOption[]}
                  formik={formik}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <SupervisorComboBox
                  required
                  id="supervisorId"
                  siteIdPathInFormik="siteId"
                  label={t('employees.supervisor')}
                  formik={formik}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikDatepicker
                  required
                  fullWidth
                  label={t('employees.date_hired')}
                  id="dateHired"
                  formik={formik as any}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id="defaultShiftId"
                  label={t('employees.default_shift')}
                  options={shifts}
                  formik={formik}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id="defaultDepartmentId"
                  label={t('employees.default_department')}
                  options={departments}
                  formik={formik}
                  placeholder={t('common.select')}
                />
              </FormFieldContainer>

              {/** Optional fields */}
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id="emergencyContact"
                  label={t('employees.emergency_contact')}
                  name="emergencyContact"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikInputPhoneNumber
                  id={'phonenumber'}
                  name={'phonenumber'}
                  label={t('employees.phone')}
                  formik={formik}
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label={t('employees.email')}
                  name="email"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id="wmsLogin"
                  label={t('employees.wms_login')}
                  name="wmsLogin"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id="taktId"
                  label={t('employees.taktId')}
                  name="taktId"
                  formik={formik}
                  errorMode="onFieldChange"
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id="comments"
                  label={t('employees.comments')}
                  name="comments"
                  formik={formik}
                  placeholder={t('common.add_your_text_here')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <ButtonGroupsContainer>
                  <FormFiledButtonsGroupItemContainer>
                    <FormikButtonGroup
                      id="active"
                      label={t('employees.active')}
                      formik={formik}
                      config={[
                        { label: t('common.active'), value: true },
                        { label: t('common.unactive'), value: false },
                      ]}
                    />
                  </FormFiledButtonsGroupItemContainer>
                  <FormFiledButtonsGroupItemContainer>
                    <FormikButtonGroup
                      id="wotc"
                      label={t('employees.wotc')}
                      formik={formik}
                      config={[
                        { label: t('common.yes'), value: true },
                        { label: t('common.no'), value: false },
                      ]}
                    />
                  </FormFiledButtonsGroupItemContainer>
                </ButtonGroupsContainer>
              </FormFieldContainer>
            </Box>

            <Box
              sx={{
                borderTopColor: 'colors.border.border_tertiary',
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                py: 2,
                px: 1,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Box>
                  <Button variant="outlined" onClick={onReset}>
                    {t('common.reset')}
                  </Button>
                  <Button
                    type="submit"
                    variant="solid"
                    sx={{ ml: 1 }}
                    loading={isRequestInProgress}
                    onClick={formik.handleSubmit as any}
                  >
                    {t('common.save')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageContentScrollableChildContainer>
    </PageContentWithTopToolbar>
  );
};
