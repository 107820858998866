import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateDepartmentRequest,
  IDepartmentModel,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new department
 */
export const createDepartmentRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreateDepartmentRequest>>): SagaIterator {
  const payloadData = {
    ...data,
    staticCostPlus: Number(data.staticCostPlus),
    costPlus: Number(data.costPlus),
  };
  try {
    const response: IDepartmentModel = yield call(
      Api.Department.create,
      payloadData,
    );

    yield put(actions.getDepartmentListRequest({ filter: list }));

    yield put(actions.getDepartmentCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createDepartmentSuccess(response));
  } catch (e) {
    yield put(actions.createDepartmentFailed());
  }
};

/**
 * Get department list
 */
export const getDepartmentListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IDepartmentModel[] = yield call(
      Api.Department.list,
      action.payload,
    );
    yield put(actions.getDepartmentListSuccess(response));
  } catch (e) {
    yield put(actions.getDepartmentListFailed());
  }
};

/**
 * Get department count
 */
export const getDepartmentCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Department.count,
      action?.payload,
    );
    yield put(actions.getDepartmentCountSuccess(response));
  } catch (e) {
    yield put(actions.getDepartmentCountFailed());
  }
};

/**
 * Delete departments
 */
export const deleteDepartmentRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Department.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getDepartmentListRequest({ filter: list }));

    yield put(actions.getDepartmentCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteDepartmentSuccess());
  } catch (e) {
    yield put(actions.deleteDepartmentFailed());
  }
};

/**
 * Bulk update departments
 */
export const updateDepartmentsRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IDepartmentModel[]>>): SagaIterator {
  const payloadData = data.map((item) => ({
    ...item,
    staticCostPlus: Number(item.staticCostPlus),
    costPlus: Number(item.costPlus),
  }));
  try {
    yield call(Api.Department.bulkUpdate, payloadData);

    yield put(actions.getDepartmentListRequest({ filter: list }));

    yield put(actions.getDepartmentCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateDepartmentsSuccess());
  } catch (e) {
    yield put(actions.updateDepartmentsFailed());
  }
};

export const updateDepartmentsDesiredEmployeesNumberSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<Pick<IDepartmentModel, 'id' | 'desiredEmployeesNumber'>[]>
>): SagaIterator {
  try {
    yield call(Api.Department.updateDepartmentsDesiredEmployeesNumber, data);

    yield put(actions.getDepartmentListRequest({ filter: filters.list }));
    yield put(actions.getDepartmentCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
    yield put(actions.updateDepartmentsDesiredEmployeesNumberSuccess());
  } catch (e) {
    yield put(actions.updateDepartmentsDesiredEmployeesNumberFailed());
  }
};

/**
 * Get combobox list
 */
export const getDepartmentComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IDepartmentModel>[] = yield call(
      Api.Department.list,
      action.payload,
    );
    yield put(actions.getDepartmentComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getDepartmentComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Department.export,
  actionFailed: actions.exportDepartmentFailed,
  actionSuccess: actions.exportDepartmentSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportDepartmentRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createDepartmentRequest,
    saga: createDepartmentRequestSaga,
  },
  {
    action: actions.getDepartmentListRequest,
    saga: getDepartmentListRequestSaga,
  },
  {
    action: actions.getDepartmentCountRequest,
    saga: getDepartmentCountRequestSaga,
  },
  {
    action: actions.deleteDepartmentRequest,
    saga: deleteDepartmentRequestSaga,
  },
  // bulk updating departments
  {
    action: actions.updateDepartmentsRequest,
    saga: updateDepartmentsRequestSaga,
  },
  {
    action: actions.getDepartmentComboboxListRequest,
    saga: getDepartmentComboboxListRequestSaga,
  },
  {
    action: actions.exportDepartmentRequest,
    saga: exportDepartmentRequestSaga,
  },
  {
    action: actions.updateDepartmentsDesiredEmployeesNumberRequest,
    saga: updateDepartmentsDesiredEmployeesNumberSaga,
  },
];
