import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

import { ISagaActionBind } from '../../types';
import { IProductionDailyUphBase } from '../../types/productionUphReports';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getOptoroDailyReportListRequest,
    saga: createListSaga<IProductionDailyUphBase>({
      apiCall: Api.OptoroDailyReport.list,
      actionSuccess: actions.getOptoroDailyReportListSuccess,
      actionFailed: actions.getOptoroDailyReportListFailed,
    }),
  },
  {
    action: actions.getOptoroDailyReportCountRequest,
    saga: createCountSaga({
      apiCall: Api.OptoroDailyReport.count,
      actionSuccess: actions.getOptoroDailyReportCountSuccess,
      actionFailed: actions.getOptoroDailyReportCountFailed,
    }),
  },
];
