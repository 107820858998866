import React from 'react';
import { ICommonFilterProps } from '../../types';
import { TableContext } from '../../../../../../TableContext';
import { ITextFieldProps, TextField } from 'src/components/_ui-kit/TextField';

type ITextFieldFilterProps = Omit<ITextFieldProps, 'value' | 'onChange'> &
  ICommonFilterProps;

export const TextFieldFilter: React.FC<ITextFieldFilterProps> = ({
  name,
  value,
  ...textFiledProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const textFieldFilterValue = getFilterByName(name) ?? (value as any) ?? '';

  const handleChange = (e: any) => {
    const { value: currentValue } = e.target;

    setFilter(name, currentValue);
  };

  useInitFilterEffect(name, value);

  return (
    <TextField
      name={name}
      value={textFieldFilterValue}
      onChange={handleChange}
      {...textFiledProps}
    />
  );
};
