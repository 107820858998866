import React from 'react';
import Collapsible from 'react-collapsible';
import { Box, Typography } from '@mui/joy';
import { IMenuIcon } from 'src/modules/types';
import { useTranslation } from 'react-i18next';
import { ChevronCompactUpSvg } from 'src/components/svgIcons';
import { useMainLayoutContext } from '../../MainLayoutContext';

const MainMenuItemContainer = Box;
const MainMenuTriggerContainer = Box;
const MainMenuIconContainer = Box;
const MainMenuTextContainer = Box;
const MainMenuChevronContainer = Box;

export const transitionSpeed = 50;

interface IMainMenuItemProps {
  name: string;
  nesting: number;
  isActive: boolean;
  isExpanded: boolean;
  onClick: (name: string) => void;
  Icon?: IMenuIcon;
}

export const MainMenuItem = ({
  name,
  onClick,
  children,
  isExpanded,
  isActive,
  Icon,
  nesting,
}: React.PropsWithChildren<IMainMenuItemProps>) => {
  const { t } = useTranslation();

  const {
    sideBar,

    sideBarColors,
    sideBarMenuItemHeight,
    sideBarTextOffsetStep,
    sideBarIconOffsetStep,
  } = useMainLayoutContext();

  const title = t(name);

  // If this is top level tab (nesting === 1) then we calculate
  // its color based on isExpanded prop in other case we inherit it
  // from the parent
  let color = 'inherit';
  if (nesting === 1) {
    color = isExpanded ? sideBarColors.textActive : sideBarColors.text;
  }

  return (
    <MainMenuItemContainer
      sx={{
        color,
        backgroundColor: 'inherit',
        transition: `color ${transitionSpeed}ms`,
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Collapsible
        open={isExpanded}
        transitionTime={200}
        easing="ease-in-out"
        handleTriggerClick={() => onClick(name)}
        trigger={
          <MainMenuTriggerContainer
            sx={{
              height: `${sideBarMenuItemHeight}px`,
              display: 'flex',
              alignItems: 'center',
              color: 'inherit',
              backgroundColor: 'inherit',
              position: 'relative',
              width: '100%',
            }}
          >
            {Icon && (
              <MainMenuIconContainer
                sx={{
                  position: 'absolute',
                  backgroundColor: 'inherit',
                  left: `${sideBarIconOffsetStep}px`,
                }}
              >
                <Icon
                  fill={
                    isActive ? sideBarColors.textActive : sideBarColors.text
                  }
                  style={{
                    transition: `fill ${transitionSpeed}ms`,
                  }}
                />
              </MainMenuIconContainer>
            )}
            <MainMenuTextContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft:
                  nesting === 1
                    ? `${sideBarTextOffsetStep * 2}px`
                    : `${sideBarTextOffsetStep * nesting}px`,
                backgroundColor: 'inherit',
                color: 'inherit',
                opacity: sideBar.state === 'expanded' ? 1 : 0,
                transition: 'opacity .1s',
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: 'medium',
                  lineHeight: '20px',
                  fontSize: '14px',
                  color: 'inherit',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {title}
              </Typography>
              <MainMenuChevronContainer
                sx={{
                  mr: '12px',
                  display: 'flex',
                  transform: `rotate(${isExpanded ? '0' : '180deg'})`,
                  transition: `transform .1s ease-in-out`,
                  backgroundColor: 'inherit',
                }}
              >
                <ChevronCompactUpSvg
                  fill={
                    isExpanded ? sideBarColors.textActive : sideBarColors.text
                  }
                  style={{ transition: `fill ${transitionSpeed}` }}
                />
              </MainMenuChevronContainer>
            </MainMenuTextContainer>
          </MainMenuTriggerContainer>
        }
      >
        {children}
      </Collapsible>
    </MainMenuItemContainer>
  );
};
