import { JSONSchemaType } from 'ajv';
import { ICreateProduction } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { TFunction } from 'react-i18next';

export const createProductionScheme: JSONSchemaType<ICreateProduction> = {
  type: 'object',
  required: ['siteId', 'shiftId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    bomId: {
      default: null,
      type: 'number',
      nullable: true,
    },
    date: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.date'),
      },
    },
    qty: {
      default: NaN,
      type: 'number',
      nullable: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    container: {
      default: null,
      nullable: true,
      type: 'string',
    },
    lineOrDoor: {
      default: null,
      nullable: true,
      type: 'string',
    },
    comment: {
      default: null,
      nullable: true,
      type: 'string',
    },
    startTime: {
      default: null,
      nullable: true,
      type: 'string',
      format: 'date-time',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    endTime: {
      default: null,
      nullable: true,
      type: 'string',
      format: 'date-time',
      formatMinimum: {
        $data: '1/startTime',
      },
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        formatMinimum: (i18n.t as TFunction)(
          'error.validation.date_greater_than',
          {
            otherDate: 'Start Time',
          },
        ),
      },
    },
    shiftId: {
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        // as far as FormikCombobox set as value null
        type: i18n.t('error.validation.required'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    employees: {
      type: 'array',
      items: {
        nullable: true,
        type: 'number',
      },
    },
  },
};

export const createProductionSchemeArray: JSONSchemaType<Array<
  ICreateProduction
>> = {
  type: 'array',
  items: { ...createProductionScheme },
};
