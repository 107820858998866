import Ajv, { JSONSchemaType, Options } from 'ajv';
import addFormats from 'ajv-formats';
import ajvErrors from 'ajv-errors';
import ajvKeywords from 'ajv-keywords';
import { useCallback } from 'react';
import {
  reduce,
  merge,
  set,
  isEmpty,
  isObject,
  isArray,
  forEach,
} from 'lodash';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { KeywordDefinition } from 'ajv';
import { AnyObject } from '../../../types';
import { compareDatetimeWithTZ, isNotEmpty } from './keywords';
import { isAfter } from 'date-fns';

export type IUseValidate<T = AnyObject> = (values: T) => AnyObject;

export interface ICompareDatetimeWithTZ {
  descFieldName: string;
  action: 'isAfter' | 'isBefore' | 'isEqual';
}

export type IUseValidateParams<T> = Parameters<
  (
    schema: JSONSchemaType<T> | PartialSchema<T>,
    options?: Options,
  ) => IUseValidate<T>
>;

const customKeywords: KeywordDefinition[] = [
  {
    keyword: 'isNotEmpty',
    type: ['number', 'string'],
    schemaType: ['boolean'],
    validate: isNotEmpty,
  },
  {
    keyword: 'compareDatetimeWithTZ',
    type: ['string'],
    validate: compareDatetimeWithTZ,
  },
  {
    keyword: 'dateLessThen',
    validate: function (schema: any, data: string) {
      const fieldDate = new Date(data);

      return isAfter(schema.date, fieldDate);
    },
  },
];

/**
 * A custom hook to prepare a json validator based on AJV
 * @param schema
 * @param options
 */
export const useValidate = <T = AnyObject>(
  schema: IUseValidateParams<T>[0],
  options?: IUseValidateParams<T>[1],
): IUseValidate<T> => {
  const validate = useCallback(
    (values: T) => {
      const ajv = new Ajv(
        merge(
          {
            $data: true,
            allErrors: true,
            validateFormats: true,
            verbose: true,
            useDefaults: 'empty',
          },
          options,
        ),
      );
      // using middleware to use different validation formats
      addFormats(ajv);
      // use a middleware to use custom error messages
      ajvErrors(ajv);
      // use a middleware with different keywords
      ajvKeywords(ajv);
      // adding custom keywords
      forEach(customKeywords, (kw) => ajv.addKeyword(kw));

      const compiler = ajv.compile(schema);
      compiler(values);
      return reduce(
        compiler.errors,
        (acc, cur) => {
          const instancePath = cur.instancePath.replace(/^\//, '').split('/');
          if (!isEmpty(instancePath)) {
            // we should skip all redundant and dime dozen errors messages
            if (
              !(
                isObject(cur.data) &&
                !isArray(cur.data) &&
                instancePath.length === 1
              )
            ) {
              set(acc, instancePath, cur.message);
            }
          }
          return acc;
        },
        {} as AnyObject,
      );
    },
    [options, schema],
  );

  return (values: T) => validate(values);
};
