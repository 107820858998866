import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthDailyUphBySiteAndSku',
);

// get list actions
export const {
  getListRequest: getCardinalHealthDailyUphBySiteAndSkuListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteAndSkuListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteAndSkuListFailed,
} = actionCreator.takeListActions<ICardinalHealthDailyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthDailyUphBySiteAndSkuCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteAndSkuCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteAndSkuCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthDailyUphBySiteAndSkuRequest,
  exportReportSuccess: exportCardinalHealthDailyUphBySiteAndSkuSuccess,
  exportReportFailed: exportCardinalHealthDailyUphBySiteAndSkuFailed,
} = new ExportActionCreator(
  'CardinalHealthDailyUphBySiteAndSku',
).takeExportActions();
