import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreatePositionRequest,
  IdsArray,
  IFilter,
  IPositionModel,
  ISagaAction,
  ISagaActionBind,
  IUpdatePositionRequest,
  PayloadWithFilters,
  PayloadWithNavigateFunc,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new position
 */
export const createPositionRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreatePositionRequest>>): SagaIterator {
  try {
    const response: IPositionModel = yield call(Api.Position.create, data);
    yield put(actions.flushPositionState());
    yield put(actions.createPositionSuccess(response));
    yield call(navigate, { pathname: '/positions' });
  } catch (e) {
    yield put(actions.createPositionFailed());
  }
};

/**
 * Get position list
 */
export const getPositionListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IPositionModel[] = yield call(
      Api.Position.list,
      action.payload,
    );
    yield put(actions.getPositionListSuccess(response));
  } catch (e) {
    yield put(actions.getPositionListFailed());
  }
};

/**
 * Get position count
 */
export const getPositionCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Position.count,
      action?.payload,
    );
    yield put(actions.getPositionCountSuccess(response));
  } catch (e) {
    yield put(actions.getPositionCountFailed());
  }
};

/**
 * Delete positions
 */
export const deletePositionRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Position.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushPositionState());
  } catch (e) {
    yield put(actions.deletePositionFailed());
  }
};

/**
 * Bulk update positions
 */
export const updatePositionsRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IUpdatePositionRequest[]>
>): SagaIterator {
  try {
    yield call(Api.Position.bulkUpdate, data);
    yield put(actions.flushPositionState());
    yield call(navigate, { pathname: '/positions' });
  } catch (e) {
    yield put(actions.updatePositionsFailed());
  }
};

export const updatePositionsDesiredEmployeesNumberSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<
    Pick<IUpdatePositionRequest, 'id' | 'desiredEmployeesNumber'>[]
  >
>): SagaIterator {
  try {
    yield call(Api.Position.updateDesiredEmployeesNumber, data);
    yield put(actions.flushPositionState());

    yield put(actions.getPositionListRequest({ filter: filters.list }));
    yield put(actions.getPositionCountRequest({ filter: filters.count }));
  } catch (e) {
    yield put(actions.updatePositionsDesiredEmployeesNumberFailed());
  }
};

/**
 * Get combobox list
 */
export const getPositionComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IPositionModel>[] = yield call(
      Api.Position.list,
      action.payload,
    );
    yield put(actions.getPositionComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getPositionComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Position.export,
  actionFailed: actions.exportPositionFailed,
  actionSuccess: actions.exportPositionSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportPositionRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createPositionRequest, saga: createPositionRequestSaga },
  { action: actions.getPositionListRequest, saga: getPositionListRequestSaga },
  {
    action: actions.getPositionCountRequest,
    saga: getPositionCountRequestSaga,
  },
  { action: actions.deletePositionRequest, saga: deletePositionRequestSaga },
  // bulk updating positions
  { action: actions.updatePositionsRequest, saga: updatePositionsRequestSaga },
  {
    action: actions.updatePositionsDesiredEmployeesNumberRequest,
    saga: updatePositionsDesiredEmployeesNumberSaga,
  },
  {
    action: actions.getPositionComboboxListRequest,
    saga: getPositionComboboxListRequestSaga,
  },
  { action: actions.exportPositionRequest, saga: exportPositionRequestSaga },
];
