import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IWhere,
  ListWithSummary,
  IFilledAssociatesModel,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Fetch Filled Associates list actions
export const getFilledAssociatesListRequest = createAction<IFilter>(
  'Make request to fetch Filled Associates list',
);
export const getFilledAssociatesListSuccess = createAction<
  ListWithSummary<IFilledAssociatesModel>
>('Filled Associates list has been fetched successfully');
export const getFilledAssociatesListFailed = createAction(
  'Cannot fetch Filled Associates list',
);

// get Filled Associates list count actions
export const getFilledAssociatesCountRequest = createAction<IWhere>(
  'Make request to get Filled Associates list count',
);
export const getFilledAssociatesCountSuccess = createAction<ICountResponse>(
  'Filled Associates list count has been fetched successfully',
);
export const getFilledAssociatesCountFailed = createAction(
  'Cannot get Filled Associates list count',
);

// export actions
export const {
  exportReportRequest: exportFilledAssociatesRequest,
  exportReportSuccess: exportFilledAssociatesSuccess,
  exportReportFailed: exportFilledAssociatesFailed,
} = new ExportActionCreator('FilledAssociates').takeExportActions();
