import React from 'react';
import { useTranslation } from 'react-i18next';

import { clients } from '../../modules/constants';
import WeeklyGrossMarginDetailedReport from '../../components/Reports/WeeklyGrossMarginDetailsReport';

interface IWeeklyGrossMarginDetailedProps {
  siteId?: number;
  year?: number;
  week?: number;
}

export const WeeklyGrossMarginDetailed = ({
  siteId,
  year,
  week,
}: IWeeklyGrossMarginDetailedProps) => {
  const { t } = useTranslation();

  return (
    <WeeklyGrossMarginDetailedReport
      clientKey={clients.williamsSonoma}
      tableName={t('williams_sonoma_reports.table_names.weekly_gm_detailed')}
      siteId={siteId}
      yearNumber={year}
      weekNumber={week}
    />
  );
};

export default WeeklyGrossMarginDetailed;
