import React from 'react';
import { filter, isEmpty, map, memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  IEmployeeModel,
  IEmployeeState,
  IStoreState,
  IUpdateEmployee,
} from '../types';
import { ComboBoxOptionWithEntity } from '../../components/ComboBox';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { Tooltip } from '@mui/material';
import { filterListBySiteId } from '../utils/helpers/common';

export const getEmployeeRefreshKey = (state: IStoreState) =>
  state.employee.refreshKey;

export const getEmployeeData = (state: IStoreState) => state.employee;

/**
 * Get server error
 * @param employee - State employee
 */
export const getServerError = ({ employee }: IStoreState) => employee.error;

/**
 * Get employee raw list
 * @param employee - State employee
 */
export const getEmployeeRawList = ({ employee }: IStoreState) => employee.list;
/**
 * Get employee list
 * @param employee - State employee
 */
export const getEmployeeList = createSelector(getEmployeeRawList, (employees) =>
  map(employees, (employee) => ({
    ...employee,
    active: !!employee.active,
    canRehire: !!employee.canRehire,
    wotc: !!employee.wotc,
    isKronosAudited: !!employee.isKronosAudited,
    isYMSAudited: !!employee.isYMSAudited,
    isOtherAudited: !!employee.isOtherAudited,
  })),
);

export const getRemovedEmployeeList = createSelector(
  getEmployeeData,
  ({ removedList }) =>
    map(removedList, (employee) => ({
      ...employee,
      active: !!employee.active,
      canRehire: !!employee.canRehire,
      wotc: !!employee.wotc,
    })),
);

export const getRemovedEmployeeListForForm = createSelector(
  getRemovedEmployeeList,
  (employees) => {
    return employees.map((employee) => {
      return Object.keys(employee).reduce((employeesForForm, field) => {
        const value = employee[field];

        if (typeof value !== 'object' || value === null) {
          if (field === 'dateentered') {
            employeesForForm.dateentered = value || null;
          } else if (field === 'separationdate') {
            employeesForForm.separationdate = value || null;
          } else if (field === 'dateHired') {
            employeesForForm.dateHired = value || null;
          } else {
            employeesForForm[field] = value;
          }
        }

        return employeesForForm;
      }, {} as IUpdateEmployee);
    });
  },
);

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param employee - State employee
 */
export const getEmployeesTable = createSelector(
  getEmployeeList,
  (employees) => {
    return map(
      filter(
        employees,
        (s) =>
          !isEmpty(s.staffing) &&
          !isEmpty(s.position) &&
          !isEmpty(s.supervisor) &&
          !isEmpty(s.defaultShift) &&
          !isEmpty(s.defaultDepartment) &&
          !isEmpty(s.site),
      ),
    );
  },
);

/**
 * Get employees by array of ids
 * @param employee - State employee
 */
export const getEmployeesByIds = createSelector(getEmployeeList, (employees) =>
  memoize((ids: IdsArray) => {
    const initial: IUpdateEmployee[] = [];
    return reduce(
      employees,
      (acc, cur) => {
        if (ids.includes(Number(cur.id))) {
          // omit columns we should not request with
          const employeeData = omit(cur, [
            'position',
            'defaultShift',
            'defaultDepartment',
            'terminationReason',
            'staffing',
            'supervisor',
            'logtimes',
            'site',
          ]);
          employeeData.dateentered = cur.dateentered || null;
          employeeData.separationdate = cur.separationdate || null;
          employeeData.dateHired = cur.dateHired || null;
          acc.push(employeeData);
        }
        return acc;
      },
      initial,
    );
  }),
);

/**
 * Get employee count
 * @param employee - State employee
 */
export const getEmployeeCount = createSelector(
  getEmployeeData,
  ({ count }) => count,
);

export const getRemovedEmployeeCount = createSelector(
  getEmployeeData,
  ({ removedCount }) => removedCount,
);

/**
 * Get active but not present employees list
 * @param employee - State employee
 */
export const getActiveButNotPresentEmployeeList = createSelector(
  getEmployeeData,
  (employees) => employees.activeButNotPresent.list,
);

/**
 * Get count of active but not present employees
 * @param employee - State employee
 */
export const getActiveButNotPresentEmployeeCount = ({
  employee,
}: IStoreState) => employee.activeButNotPresent.count;

/**
 * Get terminated employees list
 * @param employee - State employee
 */
export const getTerminatedEmployeeList = createSelector(
  getEmployeeData,
  (employee: IEmployeeState) => employee.terminated.list,
);

/**
 * Get count of terminated employees
 * @param employee - State employee
 */
export const getTerminatedEmployeeCount = ({ employee }: IStoreState) =>
  employee.terminated.count;

export const getHookyFolksList = createSelector(
  getEmployeeData,
  (employee: IEmployeeState) => employee.hookyFolks.list,
);

export const getHookyFolksCount = ({ employee }: IStoreState) =>
  employee.hookyFolks.count;

export const getHistoricalHookyFolksList = createSelector(
  getEmployeeData,
  (employee: IEmployeeState) => employee.historicalHookyFolks.list,
);

export const getHistoricalHookyFolksCount = ({ employee }: IStoreState) =>
  employee.historicalHookyFolks.count;

/**
 * Get combobox list
 */
export const getEmployeesComboboxList = createSelector(
  getEmployeeData,
  (employee) =>
    map(
      employee.comboboxList,
      (employeeItem) =>
        ({
          id: employeeItem.id,
          name: `${employeeItem.firstName} ${employeeItem.lastName} (${employeeItem.badge})`,
          entity: employeeItem,
        } as ComboBoxOptionWithEntity<IEmployeeModel>),
    ),
);

export const getEmployeesComboboxListWithBadgeAsId = createSelector(
  getEmployeeData,
  (employee) =>
    map(
      employee.comboboxList,
      (employeeItem) =>
        ({
          id: employeeItem.badge,
          name: `${employeeItem.firstName} ${employeeItem.lastName} (${employeeItem.badge})`,
          entity: employeeItem,
        } as ComboBoxOptionWithEntity<IEmployeeModel>),
    ),
);

export const getEmployeesComboboxListWithEmployeeIdAsId = createSelector(
  getEmployeeData,
  (employee) =>
    map(
      employee.comboboxList,
      (employeeItem) =>
        ({
          id: (employeeItem.employeeId as unknown) as number,
          name: `${employeeItem.firstName} ${employeeItem.lastName} (${employeeItem.badge})`,
          siteId: employeeItem.siteId,
          entity: employeeItem,
          sites: employeeItem.site ? [employeeItem.site.id] : [],
        } as ComboBoxOptionWithEntity<IEmployeeModel> & {
          siteId: number;
          sites: number[];
        }),
    ),
);

const SHORT_COMMENT_LENGTH = 50;

export const selectRemovedEmployeesForTable = createSelector(
  getRemovedEmployeeList,
  (list) => {
    return list.map((employee) => ({
      ...employee,
      wotc: employee.wotc ? (
        <YesIcon color="primary" />
      ) : (
        <NoIcon color="primary" />
      ),
      active: employee.active ? (
        <YesIcon color="primary" />
      ) : (
        <NoIcon color="primary" />
      ),
      canRehire: employee.canRehire ? (
        <YesIcon color="primary" />
      ) : (
        <NoIcon color="primary" />
      ),
      comments:
        employee.comments &&
        employee.comments?.length > SHORT_COMMENT_LENGTH ? (
          <Tooltip title={employee.comments}>
            <span>
              {employee.comments.substring(0, SHORT_COMMENT_LENGTH) + '...'}
            </span>
          </Tooltip>
        ) : (
          employee.comments
        ),
    }));
  },
);

export const getIsEmployeesDataLoading = createSelector(
  getEmployeeData,
  ({ isTableDataRequestInProgress }) => isTableDataRequestInProgress,
);

export const getIsEmployeesComboboxListRequestInProgress = createSelector(
  getEmployeeData,
  ({ isEmployeesComboboxListRequestInProgress }) =>
    isEmployeesComboboxListRequestInProgress,
);

export const getEmployeesComboboxListBySiteId = createSelector(
  getEmployeesComboboxListWithEmployeeIdAsId,
  (employees) =>
    memoize((siteId?: number) =>
      siteId ? filterListBySiteId(employees, siteId) : employees,
    ),
);
