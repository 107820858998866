import toNumber from 'lodash/toNumber';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import reduce from 'lodash/reduce';

import { useProductionDailyReportsDefaultFilters } from './productions.hooks';
import { useFilter } from './useFilter';
import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';
import { AnyObject } from '../../types';
import { set } from 'lodash';

const chartOptions: AnyObject = {
  title: {
    text: '',
  },
  chart: {
    type: 'bar',
  },
  xAxis: {
    categories: [],
    gridLineWidth: 1,
    lineWidth: 0,
    scrollbar: {
      enabled: true,
    },
    tickLength: 0,
  },
  yAxis: {
    min: 0,
    title: {
      text: 'CPU ($)',
      align: 'high',
    },
    labels: {
      overflow: 'justify',
    },
    gridLineWidth: 0,
  },
  tooltip: {
    valuePrefix: '$',
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormatter: function () {
      return `<tr><td style="color:${this.series.color};padding:0">${
        this.series.name
      }:</td>
        <td style="padding:0"><b>${this.y.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}</b></td></tr>`;
    },
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
        formatter: function () {
          return `${
            (this as AnyObject).series.name
          }: ${(this as AnyObject).y.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`;
        },
      },
      groupPadding: 0.1,
    },
  },
  series: [],
};

export const useTop5CpuChartQuery = (
  url: string,
  year?: number,
  month?: number,
) => {
  const { t } = useTranslation();

  const defaultFilter = useProductionDailyReportsDefaultFilters('yearMonth');
  const { filterList } = useFilter(defaultFilter);

  if (year) {
    set(filterList, ['filter', 'where', 'year'], year);
  }

  if (month) {
    set(filterList, ['filter', 'where', 'month'], month);
  }

  const title = t('target_reports.chart.title');

  const { data } = useQuery(getQueryKey(url, filterList), async () => {
    const { items } = await Api.Report.list(url, filterList);
    return items;
  });

  const { series, categories } = reduce<
    AnyObject,
    {
      series: Map<string, { name: string; data: number[] }>;
      categories: string[];
    }
  >(
    data!,
    (acc, cur) => {
      const cpu = toNumber(cur.cpu.replace(',', ''));
      const category = `${cur.month} ${cur.year}`;
      if (!acc.categories.includes(category)) {
        acc.categories.push(category);
      }
      if (!acc.series.has(cur.area)) {
        acc.series.set(cur.area, {
          name: cur.area,
          data: [cpu],
        });
      } else {
        acc.series.set(cur.area, {
          name: cur.area,
          data: [...acc.series.get(cur.area)!.data, cpu],
        });
      }

      return acc;
    },
    {
      series: new Map<string, { name: string; data: number[] }>(),
      categories: [],
    },
  );

  chartOptions.series = Array.from(series.values());
  chartOptions.xAxis.categories = categories;

  return { options: chartOptions, title };
};
