import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions/expeditors/dailyReport.actions';
import { IProductionDailyUphBase } from 'src/modules/types/productionUphReports';
import { IDailyRawReportState } from 'src/modules/types/reports';

const defaultState: IDailyRawReportState = {
  list: [],
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getExpeditorsDailyReportListRequest,
  getListSuccess: getExpeditorsDailyReportListSuccess,
  getListFailed: getExpeditorsDailyReportListFailed,
} = reducerCreator.takeListHandlers<
  IDailyRawReportState,
  IProductionDailyUphBase
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getExpeditorsDailyReportCountRequest,
  getCountSuccess: getExpeditorsDailyReportCountSuccess,
  getCountFailed: getExpeditorsDailyReportCountFailed,
} = reducerCreator.takeCountHandlers<IDailyRawReportState>();

/**
 * actually Reducer
 */
export const expeditorsDailyReport = createReducer<IDailyRawReportState>(
  {},
  defaultState,
)
  // get list actions
  .on(
    actions.getExpeditorsDailyReportListRequest,
    getExpeditorsDailyReportListRequest,
  )
  .on(
    actions.getExpeditorsDailyReportListSuccess,
    getExpeditorsDailyReportListSuccess,
  )
  .on(
    actions.getExpeditorsDailyReportListFailed,
    getExpeditorsDailyReportListFailed,
  )
  // get count actions
  .on(
    actions.getExpeditorsDailyReportCountRequest,
    getExpeditorsDailyReportCountRequest,
  )
  .on(
    actions.getExpeditorsDailyReportCountSuccess,
    getExpeditorsDailyReportCountSuccess,
  )
  .on(
    actions.getExpeditorsDailyReportCountFailed,
    getExpeditorsDailyReportCountFailed,
  );

export default expeditorsDailyReport;
