import React from 'react';
import { useTranslation } from 'react-i18next';

import { Report, ReportPage } from 'src/components/ReportPage';
import { clients } from '../../modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from '../../modules/utils/hooks/reportPage.hooks';
import { NfiWeeklyCpu } from './WeeklyCpu';
import WeeklyGrossMargin from './WeeklyGrossMargin';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
import {
  INfiReportsFilterPanelFilters,
  NfiReportsFilterPanel,
} from '../Nfi/NfiReportsFilterPanel';
import { PageContentWithTopToolbar } from '../../components/PageContent';
import { ReportPageItemContainer } from '../../components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from '../../components/ReportPage/ReportTableToolbar';
import { SelectHeadCells } from '../../components/Table/components/TableActions/components/SelectHeadCells';
import { useHasUserAccessToAction } from '../../config';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';
import { ReportPageActionsGroupContainer } from '../../components/ReportPage/ReportPageActionsGroupContainer';
import { UploadImportingFile } from '../../components/Table/components/TableActions/components/UploadImportingFile';

const client = clients.nfi;

const reportsDefaultFilters: INfiReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

export const NfiReports = () => {
  const { t } = useTranslation();

  const reportsProps = useReportsProps(client);

  const hasUserAccessToUploadNfiProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[clients.nfi].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof INfiReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('productions_uph_reports.site.name') },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
      { id: 'category', label: t('productions_uph_reports.category') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    INfiReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={client}>
        <ReportPageItemContainer>
          <ReportTableToolbar reportName={t('main_menu.nfi.main')}>
            <ReportPageActionsGroupContainer>
              <SelectHeadCells />
            </ReportPageActionsGroupContainer>

            {hasUserAccessToUploadNfiProductionData && (
              <ReportPageActionsGroupContainer>
                <UploadImportingFile clientKey={clients.nfi} />
              </ReportPageActionsGroupContainer>
            )}
          </ReportTableToolbar>
        </ReportPageItemContainer>
        <ReportPageItemContainer>
          <NfiReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportsProps.weeklyCpu}>
          <ReportPageItemContainer>
            <NfiWeeklyCpu
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
              year={appliedFilters.year}
              category={appliedFilters.category}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMargin}>
          <ReportPageItemContainer>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer>
            <WeeklyGrossMarginDetailed
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
