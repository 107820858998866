import { ICountResponse, ListWithSummary } from '../../../../types';

export class ListWithSummaryReducerCreator {
  /**
   * Generate list handlers
   */
  takeListHandlers<State, Model>() {
    const getListRequest = (state: State) => ({
      ...state,
      error: false,
      is_data_requested: true,
    });

    const getListSuccess = (state: State, payload: ListWithSummary<Model>) => ({
      ...state,
      list: payload,
      error: false,
      is_data_requested: false,
    });

    const getListFailed = (state: State, payload: string) => ({
      ...state,
      error: payload,
      is_data_requested: false,
    });

    return {
      getListRequest,
      getListSuccess,
      getListFailed,
    };
  }
  /**
   * Generate count handlers
   */
  takeCountHandlers<State>() {
    const getCountRequest = (state: State) => ({
      ...state,
      error: false,
      is_data_requested: true,
    });

    const getCountSuccess = (state: State, payload: ICountResponse) => ({
      ...state,
      ...payload,
      error: false,
      is_data_requested: false,
    });

    const getCountFailed = (state: State, payload: string) => ({
      ...state,
      error: payload,
      is_data_requested: false,
    });

    return {
      getCountRequest,
      getCountSuccess,
      getCountFailed,
    };
  }
}
