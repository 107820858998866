import { JSONSchemaType } from 'ajv';
import { IUpdateInvoice, IUpdateMonthInvoice } from '../../types';
import { i18n } from '../../utils/i18n';

export const updateInvoiceScheme: JSONSchemaType<IUpdateInvoice[]> = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id'],
    additionalProperties: false,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      purchaseOrder: {
        type: 'string',
        nullable: true,
      },
      paymentTerms: {
        type: 'string',
        nullable: true,
      },
      dueDate: {
        type: 'string',
        nullable: true,
      },
    },
  },
};

export const updateMonthInvoiceScheme: JSONSchemaType<IUpdateMonthInvoice[]> = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id'],
    additionalProperties: false,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      id: {
        type: 'string',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      purchaseOrder: {
        type: 'string',
        nullable: true,
      },
      paymentTerms: {
        type: 'string',
        nullable: true,
      },
      dueDate: {
        type: 'string',
        nullable: true,
      },
    },
  },
};
