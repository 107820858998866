import React from 'react';
import { Sheet } from '@mui/joy';

interface IMainIndicatorsWidgetProps {
  children: React.ReactNode;
}

export const MainIndicatorsWidget = ({
  children,
}: IMainIndicatorsWidgetProps) => {
  return <Sheet sx={{ display: 'flex' }}>{children}</Sheet>;
};
