import { createAction } from 'redux-act';
import {
  IIssueTrackerIssueModel,
  ICreateIssueInIssueTracker,
  IFilter,
  PayloadWithNavigateFunc,
} from '../../types';

// Flush actions
export const flushIssueTrackerState = createAction(
  'Flush issueTracker data in store',
);

// Creation actions
export const createIssueInIssueTrackerRequest = createAction<
  PayloadWithNavigateFunc<ICreateIssueInIssueTracker>
>('Make request to create a new issue in issueTracker');
export const createIssueInIssueTrackerSuccess = createAction<
  IIssueTrackerIssueModel
>('New issue in issueTracker has been created successfully');
export const createIssueInIssueTrackerFailed = createAction(
  'Cannot create a new issue in issueTracker',
);

// Fetch issueTrackers actions
export const getIssuesFromIssueTrackerListRequest = createAction<IFilter>(
  'Make request to fetch issues from issueTracker',
);
export const getIssuesFromIssueTrackerListSuccess = createAction<
  IIssueTrackerIssueModel[]
>('Issues from IssueTrackers has been fetched successfully');
export const getIssuesFromIssueTrackerListFailed = createAction(
  'Cannot fetch issues from issueTracker',
);
