import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreatePositionRequest,
  IdsArray,
  IFilter,
  IPositionModel,
  IUpdatePositionRequest,
  PayloadWithFilters,
  PayloadWithNavigateFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushPositionState = createAction('Flush position data in store');

// Creation actions
export const createPositionRequest = createAction<
  PayloadWithNavigateFunc<ICreatePositionRequest>
>('Make request to create a new position');
export const createPositionSuccess = createAction<IPositionModel>(
  'New position has been created successfully',
);
export const createPositionFailed = createAction(
  'Cannot create a new position',
);

// Fetch positions actions
export const getPositionListRequest = createAction<IFilter>(
  'Make request to fetch position list',
);
export const getPositionListSuccess = createAction<IPositionModel[]>(
  'Positions list has been fetched successfully',
);
export const getPositionListFailed = createAction('Cannot fetch position list');

// get positions count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPositionCountRequest = createAction<IFilter>(
  'Make request to get position count',
);
export const getPositionCountSuccess = createAction<ICountResponse>(
  'Positions count has been fetched successfully',
);
export const getPositionCountFailed = createAction('Cannot get position count');

// delete actions
export const deletePositionRequest = createAction<IdsArray>(
  'Make request to delete positions',
);
export const deletePositionFailed = createAction('Cannot delete positions');

// Updating actions
export const updatePositionsRequest = createAction<
  PayloadWithNavigateFunc<IUpdatePositionRequest[]>
>('Make request to update positions');
export const updatePositionsFailed = createAction('Cannot update positions');

// Updating actions
export const updatePositionsDesiredEmployeesNumberRequest = createAction<
  PayloadWithFilters<
    Pick<IUpdatePositionRequest, 'id' | 'desiredEmployeesNumber'>[]
  >
>('Make request to update positions desired employees number');
export const updatePositionsDesiredEmployeesNumberFailed = createAction(
  'Cannot update positions desired employees number',
);

// Fetch combobox list actions
export const getPositionComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of positions',
);
export const getPositionComboboxListSuccess = createAction<
  Partial<IPositionModel>[]
>('Combobox list of positions has been fetched successfully');
export const getPositionComboboxListFailed = createAction(
  'Cannot fetch combobox positions list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Position').takeExportActions();

export const exportPositionRequest = exportReportRequest;
export const exportPositionSuccess = exportReportSuccess;
export const exportPositionFailed = exportReportFailed;
