import { ISagaActionBind } from '../../types';
import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyUphBySiteAndEmployeeListRequest,
    saga: createListSaga<ICardinalHealthWeeklyReportModel>({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndEmployee.list,
      actionSuccess:
        actions.getCardinalHealthWeeklyUphBySiteAndEmployeeListSuccess,
      actionFailed:
        actions.getCardinalHealthWeeklyUphBySiteAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyUphBySiteAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndEmployee.count,
      actionSuccess:
        actions.getCardinalHealthWeeklyUphBySiteAndEmployeeCountSuccess,
      actionFailed:
        actions.getCardinalHealthWeeklyUphBySiteAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyUphBySiteAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndEmployee.export,
      actionSuccess:
        actions.exportCardinalHealthWeeklyUphBySiteAndEmployeeSuccess,
      actionFailed:
        actions.exportCardinalHealthWeeklyUphBySiteAndEmployeeFailed,
    }),
  },
];
