import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deletePolicyRequest,
  getPolicyCountRequest,
  getPolicyListRequest,
} from '../../modules/actions';
import {
  getPolicyCount,
  getPolicyList,
  getPolicyRefreshKey,
} from '../../modules/selectors/policy';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useSearch,
  useFilter,
  useBrowserHistoryFunctions,
  useFetchRolesCombobox,
} from '../../modules/utils/hooks';
import { ComboBoxOption } from '../../components/ComboBox';
import { map, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, paths, SCOPES } from '../../config';
import { getRolesComboboxListByFieldId } from '../../modules/selectors/role';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';

const DEFAULT_ORDER_BY = 'id';
const DEFAULT_ORDER = 'desc';

const defaultFilter = {
  order: [`${DEFAULT_ORDER_BY} ${DEFAULT_ORDER}`],
};

const PoliciesList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  // fetch Policy list
  const list = useSelector(getPolicyList, shallowEqual);
  const count = useSelector(getPolicyCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  const fetchRolesCombobox = useFetchRolesCombobox();

  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchRolesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roles = useSelector(getRolesComboboxListByFieldId, shallowEqual)('key');

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'v0', disablePadding: false, label: t('policies.v0') },
    { id: 'v1', disablePadding: false, label: t('policies.v1') },
    { id: 'v2', disablePadding: false, label: t('policies.v2') },
    { id: 'v3', disablePadding: false, label: t('policies.v3') },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'v0',
      label: t('policies.v0'),
      operator: 'eq',
      type: 'combobox',
      options: roles,
    },
    {
      name: 'v1',
      label: t('policies.v1'),
      operator: 'like',
    },
    {
      name: 'v2',
      label: t('policies.v2'),
      operator: 'like',
      type: 'combobox',
      options: map(
        values(SCOPES),
        (scope) =>
          (({
            id: scope,
            name: scope,
          } as unknown) as ComboBoxOption),
      ),
    },
    {
      name: 'v3',
      label: t('policies.v3'),
      operator: 'like',
    },
  ];

  // make request to fetch policies when component is mounted
  useEffect(() => {
    dispatcher(getPolicyListRequest(filterList));
    // get total count
    dispatcher(getPolicyCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getPolicyListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getPolicyCountRequest({ filter: { where } }));
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deletePolicyRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: paths.POLICIES_UPDATE, search });
  };
  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={list}
          count={count}
          selectIndex="id"
          defaultOrder={DEFAULT_ORDER}
          defaultOrderBy={DEFAULT_ORDER_BY}
          tableName={t('policies.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={handleDeletion}
          onUpdate={handleUpdating}
          deleteModelName={ModelsToDelete.Policy}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function PoliciesListRefreshable() {
  return <PoliciesList key={useSelector(getPolicyRefreshKey)} />;
}
