import { IProductionDailyUphBase } from 'src/modules/types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';

const actionCreator = new ListWithSummaryActionCreator('Expeditors');

// get list actions
export const {
  getListRequest: getExpeditorsDailyReportListRequest,
  getListSuccess: getExpeditorsDailyReportListSuccess,
  getListFailed: getExpeditorsDailyReportListFailed,
} = actionCreator.takeListActions<IProductionDailyUphBase>();

// get count actions
export const {
  getCountRequest: getExpeditorsDailyReportCountRequest,
  getCountSuccess: getExpeditorsDailyReportCountSuccess,
  getCountFailed: getExpeditorsDailyReportCountFailed,
} = actionCreator.takeCountActions();
