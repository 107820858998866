import React from 'react';
import { useTranslation } from 'react-i18next';

import { clients } from '../../modules/constants';
import WeeklyGrossMarginDetailedReport from '../../components/Reports/WeeklyGrossMarginDetailsReport';

export const WeeklyGrossMarginDetailed = () => {
  const { t } = useTranslation();

  return (
    <WeeklyGrossMarginDetailedReport
      clientKey={clients.sony}
      tableName={t('sony_reports.table_names.weekly_gm_detailed')}
    />
  );
};

export default WeeklyGrossMarginDetailed;
