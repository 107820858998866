import { createAction } from 'redux-act';
import { IDeleteRelationPayload } from '../../types';

// Fetch delete relations actions
export const getDeleteRelationsRequest = createAction<string>(
  'Make request to fetch delete relations',
);
export const getDeleteRelationsSuccess = createAction<IDeleteRelationPayload>(
  'Delete relations has been fetched successfully',
);
export const getDeleteRelationsFailed = createAction(
  'Cannot fetch delete relations',
);
