import React from 'react';
import TaskView from './components/TaskView';
import ChecklistView from './components/ChecklistView';
import './styleOverrides.css';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from '../../config';
import { TabsNgroup } from '../../components/TabsNgroup';

export const Checklist = () => {
  const { t } = useTranslation();

  const hasUserAccessToTasksTab = useHasUserAccessToViewTab(
    tabsPaths.TASKS_TAB,
  );
  const hasUserAccessToChecklistsTab = useHasUserAccessToViewTab(
    tabsPaths.CHECKLISTS_TAB,
  );

  const checklistTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('checklist.tasks_title'),
          hasUserAccess: hasUserAccessToTasksTab,
          component: <TaskView />,
        },
        {
          title: t('checklist.checklists_title'),
          hasUserAccess: hasUserAccessToChecklistsTab,
          component: <ChecklistView />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [hasUserAccessToTasksTab, hasUserAccessToChecklistsTab, t],
  );

  return <TabsNgroup config={checklistTabsConfig} />;
};
