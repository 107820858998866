import React from 'react';
import { Box, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { InvoiceWeekToolbar } from './InvoiceWeekToolbar';
import { InvoiceHeader } from './InvoiceHeader';
import { InvoiceGeneralInfoTable } from './InvoiceGeneralInfoTable';
import { InvoiceBackupDetailsTable } from './InvoiceBackupDetailsTable';
import { useDispatch } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { InvoiceParticipant } from './InvoiceParticipant';
import { IInvoiceDetails } from 'src/modules/types';
import { Api } from 'src/modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../../components/PageContent';
import { PageContentScrollableChildContainer } from '../../../components/PageContent/PageContentScrollableChildContainer';

export const InvoiceWeekDetails = () => {
  const [
    invoiceWeekDetails,
    setInvoiceWeekDetails,
  ] = React.useState<null | IInvoiceDetails>(null);
  const dispatch = useDispatch();

  const { invoiceId } = useParams<{ invoiceId: string }>();

  const [isHovered, setIsHovered] = React.useState(false);

  const billTo = React.useMemo(() => {
    if (!invoiceWeekDetails) {
      return null;
    }

    return invoiceWeekDetails.billTo.headers.map((header) => (
      <Box key={header}>{invoiceWeekDetails.billTo.itemsObject[header]}</Box>
    ));
  }, [invoiceWeekDetails]);

  const questions = React.useMemo(() => {
    if (!invoiceWeekDetails || !invoiceWeekDetails.questions) {
      return null;
    }

    return invoiceWeekDetails.questions.headers.map((header) => (
      <Box key={header}>
        <span>{invoiceWeekDetails.questions.itemsObject[header]}</span>
      </Box>
    ));
  }, [invoiceWeekDetails]);

  const remitTo = React.useMemo(() => {
    if (!invoiceWeekDetails) {
      return null;
    }

    return invoiceWeekDetails.remitTo.headers.map((header) => (
      <Box key={header}>{invoiceWeekDetails.remitTo.itemsObject[header]}</Box>
    ));
  }, [invoiceWeekDetails]);

  React.useEffect(() => {
    if (!invoiceId) {
      return;
    }

    fetchInvoiceWeekDetailsById(Number(invoiceId));

    async function fetchInvoiceWeekDetailsById(id: number) {
      const response = await Api.Invoice.byId(id);

      setInvoiceWeekDetails(response);
    }
  }, [dispatch, invoiceId]);

  if (!invoiceWeekDetails) {
    return <MainContentLoader />;
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <PageContentScrollableChildContainer sx={{ mt: 2 }} fullHeight>
          <Paper
            sx={{
              position: 'relative',
              width: '900px',
              minHeight: '90vh',
              margin: '0 auto',
              mb: 1,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <InvoiceWeekToolbar
              isVisible={isHovered}
              invoiceId={Number(invoiceId)}
            />

            <Box sx={{ padding: 1 }}>
              <InvoiceHeader
                invoiceNumber={invoiceWeekDetails.backupInvoiceNumber}
                siteName={invoiceWeekDetails.siteName}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 0.5,
                }}
              >
                {billTo && (
                  <Box style={{ padding: '0 4px' }}>
                    <InvoiceParticipant title="Bill To:">
                      {billTo}
                    </InvoiceParticipant>
                  </Box>
                )}

                {questions && (
                  <InvoiceParticipant title="Questions:">
                    {questions}
                  </InvoiceParticipant>
                )}

                {remitTo && (
                  <InvoiceParticipant title="Remit To:">
                    {remitTo}
                  </InvoiceParticipant>
                )}
              </Box>
              <Box sx={{ marginTop: 0.5 }}>
                <InvoiceGeneralInfoTable
                  weekEndingDate={invoiceWeekDetails.weekEndingDate}
                  paymentTerms={invoiceWeekDetails.paymentTerms}
                  invoiceDate={invoiceWeekDetails.invoiceDate}
                  purchaseOrder={invoiceWeekDetails.purchaseOrder}
                  dueDate={invoiceWeekDetails.dueDate}
                  weekNumber={invoiceWeekDetails.weekNumber}
                />
              </Box>
              <Box sx={{ marginTop: 0.5 }}>
                <InvoiceBackupDetailsTable
                  headers={invoiceWeekDetails.backupDetails.headers}
                  items={invoiceWeekDetails.backupDetails.items}
                  total={invoiceWeekDetails.total}
                />
              </Box>
            </Box>
          </Paper>
        </PageContentScrollableChildContainer>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
