import {
  ICardinalHealthDailyReportModel,
  ICardinalHealthDailyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthDailyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthDailyUphBySiteAndShiftListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteAndShiftListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteAndShiftListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthDailyReportState,
  ICardinalHealthDailyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthDailyUphBySiteAndShiftCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteAndShiftCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteAndShiftCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthDailyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthDailyUphBySiteAndShift = createReducer<
  ICardinalHealthDailyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthDailyUphBySiteAndShiftListRequest,
    getCardinalHealthDailyUphBySiteAndShiftListRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndShiftListSuccess,
    getCardinalHealthDailyUphBySiteAndShiftListSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndShiftListFailed,
    getCardinalHealthDailyUphBySiteAndShiftListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthDailyUphBySiteAndShiftCountRequest,
    getCardinalHealthDailyUphBySiteAndShiftCountRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndShiftCountSuccess,
    getCardinalHealthDailyUphBySiteAndShiftCountSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndShiftCountFailed,
    getCardinalHealthDailyUphBySiteAndShiftCountFailed,
  );

export default cardinalHealthDailyUphBySiteAndShift;
