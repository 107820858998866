import {
  IProductionWeeklyCpuModel,
  IProductionWeeklyCpuState,
} from '../../types/productionCpuReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionWeeklyCpuState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyCpuListRequest,
  getListSuccess: getCardinalHealthWeeklyCpuListSuccess,
  getListFailed: getCardinalHealthWeeklyCpuListFailed,
} = reducerCreator.takeListHandlers<
  IProductionWeeklyCpuState,
  IProductionWeeklyCpuModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyCpuCountRequest,
  getCountSuccess: getCardinalHealthWeeklyCpuCountSuccess,
  getCountFailed: getCardinalHealthWeeklyCpuCountFailed,
} = reducerCreator.takeCountHandlers<IProductionWeeklyCpuState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyCpu = createReducer<IProductionWeeklyCpuState>(
  {},
  defaultState,
)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyCpuListRequest,
    getCardinalHealthWeeklyCpuListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuListSuccess,
    getCardinalHealthWeeklyCpuListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuListFailed,
    getCardinalHealthWeeklyCpuListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyCpuCountRequest,
    getCardinalHealthWeeklyCpuCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuCountSuccess,
    getCardinalHealthWeeklyCpuCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyCpuCountFailed,
    getCardinalHealthWeeklyCpuCountFailed,
  );

export default cardinalHealthWeeklyCpu;
