import React, { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { ContentLayout } from 'src/components/Layout/ContentLayout';
import {
  IIssueTrackerIssueFromData,
  IIssueTrackerIssueResponse,
} from 'src/modules/types';
import { Api, useBrowserHistoryFunctions } from 'src/modules/utils';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  format,
  parseISO,
} from 'src/modules/utils/dateWrapper';
import { Section } from './Section';
import { addProcessStatus, setIsDataRequested } from 'src/modules/actions';
import { useDispatch } from 'react-redux';
import { ComboBoxOption } from 'src/components/ComboBox';
import { PriorityDropdown } from '../dropdowns/PriorityDropdown';
import { SupportCategory } from '../dropdowns/SupportCategoryDropdown';
import { paths } from '../../../config';
import UploadImages, { IImageData } from 'src/components/UploadImages';
import { useFetchJiraAttachmentsByIds } from 'src/modules/utils/hooks/issue-tracker';

export const IssueTrackerIssueDetails = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();

  const [
    rawIssue,
    setRawIssue,
  ] = React.useState<IIssueTrackerIssueResponse | null>(null);
  const [issue, setIssue] = React.useState<
    Pick<
      IIssueTrackerIssueFromData,
      'description' | 'summary' | 'label' | 'priorityId'
    >
  >({
    summary: {
      oldValue: '',
      value: '',
    },
    description: {
      oldValue: '',
      value: '',
    },
    label: {
      oldValue: '',
      value: '',
    },
    priorityId: {
      oldValue: '',
      value: '',
    },
  });
  const [comment, setComment] = React.useState('');
  const [comments, setComments] = React.useState<
    IIssueTrackerIssueFromData['comments']
  >([]);
  const [filesData, setFilesData] = React.useState<Array<IImageData>>([]);

  const attachmentsIds = React.useMemo(() => {
    if (!rawIssue) {
      return [];
    }

    return rawIssue.fields.attachment.map(({ id }) => id);
  }, [rawIssue]);

  const { attachments } = useFetchJiraAttachmentsByIds(attachmentsIds);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { issueId } = useParams();

  const sortedComments = React.useMemo(() => {
    comments.sort(function (a, b) {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    });

    return comments;
  }, [comments]);

  const initializeIssue = React.useCallback(
    async (issueId: string) => {
      try {
        dispatch(setIsDataRequested(true));
        const issueDetails = await Api.IssueTracker.byId(issueId);
        dispatch(setIsDataRequested(false));

        setRawIssue(issueDetails);
        updateIssueFormData(issueDetails);
      } catch {
        dispatch(setIsDataRequested(false));
      }
    },
    [dispatch],
  );

  const onChangeComment = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setComment(e.target.value);
  };

  const onAddComment = async () => {
    if (!issueId || !comment) {
      return;
    }

    try {
      dispatch(setIsDataRequested(true));
      await Api.IssueTracker.addComment(issueId, comment);

      const issueDetails = await Api.IssueTracker.byId(issueId);
      dispatch(setIsDataRequested(false));

      setComment('');
      updateIssueFormData(issueDetails);
      dispatch(
        addProcessStatus({ message: t('common.success'), variant: 'success' }),
      );
    } catch (e) {
      dispatch(setIsDataRequested(false));
    }
  };

  const onSummaryChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setIssue((prev) => ({
      ...prev,
      summary: {
        ...prev.summary,
        value: e.target.value,
      },
    }));
  };

  const onDescriptionChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setIssue((prev) => ({
      ...prev,
      description: {
        ...prev.description,
        value: e.target.value,
      },
    }));
  };

  const onPriorityChange = (_: any, option: ComboBoxOption | null) => {
    if (option) {
      setIssue((prev) => ({
        ...prev,
        priorityId: {
          ...prev.priorityId,
          value: (option.id as unknown) as string,
        },
      }));
    }
  };

  const onSupportCategoryChange = (_: any, option: ComboBoxOption | null) => {
    if (option) {
      setIssue((prev) => ({
        ...prev,
        label: {
          ...prev.label,
          value: (option.id as unknown) as string,
        },
      }));
    }
  };

  const resetFormData = () => {
    setIssue((prev) => ({
      summary: {
        ...prev.summary,
        value: prev.summary.oldValue,
      },
      description: {
        ...prev.description,
        value: prev.description.oldValue,
      },
      priorityId: {
        ...prev.priorityId,
        value: prev.priorityId.oldValue,
      },
      label: {
        ...prev.label,
        value: prev.label.oldValue,
      },
    }));

    setFilesData(attachments);
  };

  const onSubmit = async () => {
    if (!issueId || !rawIssue) {
      return;
    }
    const filesToDelete = attachments.filter(
      (existedAttachment) =>
        !filesData.find(
          (fileData) => fileData.preview === existedAttachment.preview,
        ),
    );
    const newFilesData = filesData.filter(
      (newFileData) =>
        newFileData.file !== undefined &&
        !attachments.find(
          (attachment) => attachment.preview === newFileData.preview,
        ),
    ) as Array<IImageData & { file: File }>;

    try {
      dispatch(setIsDataRequested(true));

      await Api.IssueTracker.update(issueId, {
        label: issue.label.value,
        summary: issue.summary.value,
        priorityId: issue.priorityId.value,
        description: issue.description.value,
        files: newFilesData.map((fileData) => fileData.file),
        filesToDeleteId: JSON.stringify(filesToDelete.map((file) => file.id)),
      });

      await initializeIssue(issueId);

      dispatch(setIsDataRequested(false));

      dispatch(
        addProcessStatus({ message: t('common.success'), variant: 'success' }),
      );

      pushToHistory(paths.ISSUE_TRACKER_LIST);
    } catch {
      dispatch(setIsDataRequested(false));
    }
  };

  const handleFilesChange = (files: Array<IImageData>) => {
    setFilesData(files);
  };

  const isSomeFieldDataChanged = Object.values(issue).some(
    (field) =>
      field.value !== field.oldValue ||
      filesData.some((fileData) => fileData.file) ||
      filesData.length !== attachments.length,
  );

  function updateIssueFormData(issueDetails: IIssueTrackerIssueResponse) {
    const {
      summary,
      description,
      comment,
      priority,
      labels,
    } = issueDetails.fields;
    setIssue({
      summary: {
        oldValue: summary,
        value: summary,
      },
      description: {
        oldValue: description ?? '',
        value: description ?? '',
      },
      label: {
        oldValue: labels[0],
        value: labels[0],
      },
      priorityId: {
        oldValue: priority.id,
        value: priority.id,
      },
    });

    setComments(comment.comments);
  }
  React.useEffect(() => {
    if (issueId) {
      initializeIssue(issueId);
    }
  }, [dispatch, initializeIssue, issueId]);

  React.useEffect(() => {
    if (attachments.length) {
      setFilesData(attachments);
    }
  }, [attachments]);

  return (
    <ContentLayout>
      <Box
        sx={(theme) => ({
          width: '70%',
          maxWidth: 900,
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        })}
      >
        <Paper
          elevation={0}
          sx={{
            padding: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Section title={t('issue_tracker.issue_details')}>
            {rawIssue?.key && (
              <Typography sx={{ marginBottom: 2 }}>{rawIssue.key}</Typography>
            )}

            <Box sx={{ marginBottom: 1, width: '100%' }}>
              <TextField
                fullWidth
                id="summary"
                name="summary"
                variant="outlined"
                value={issue.summary.value}
                onChange={onSummaryChange}
                label={t('issue_tracker.subject')}
              />
            </Box>

            <Box sx={{ marginBottom: 0.5, width: '100%' }}>
              <PriorityDropdown
                value={issue.priorityId.value}
                onChange={onPriorityChange}
              />
            </Box>
            <Box sx={{ marginBottom: 0.5, width: '100%' }}>
              <SupportCategory
                value={issue.label.value}
                onChange={onSupportCategoryChange}
              />
            </Box>

            <Box sx={{ marginTop: '12px', marginBottom: 0.5, width: '100%' }}>
              <TextField
                fullWidth
                multiline
                id="description"
                name="description"
                variant="outlined"
                label={t('issue_tracker.description')}
                minRows={5}
                value={issue.description.value}
                onChange={onDescriptionChange}
              />
            </Box>

            <UploadImages
              isImagesFromPropsSourceOfTruth
              images={filesData}
              onChange={handleFilesChange}
              id="issue-tracker-upload-images"
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '64px',
              }}
            >
              <Button
                variant="outlined"
                disabled={!isSomeFieldDataChanged}
                onClick={resetFormData}
              >
                {t('common.restore')}
              </Button>
              <Button
                sx={{ marginLeft: 2 }}
                disabled={!isSomeFieldDataChanged}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {t('common.save')}
              </Button>
            </Box>
          </Section>

          <Section title={t('issue_tracker.comments')}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: 4,
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="New Comment"
                value={comment}
                onChange={onChangeComment}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={onAddComment}
              >
                {t('common.add')}
              </Button>
            </Box>
            {sortedComments.map((comment) => (
              <Box
                key={comment.id}
                sx={{
                  whiteSpace: 'pre-line',
                  border: '1px solid rgb(220, 220, 220)',
                  borderRadius: '8px',
                  padding: 1,
                  marginTop: 2,
                }}
              >
                <Typography sx={{ fontSize: '0.9em', textAlign: 'right' }}>
                  {composeDate(
                    parseISO(comment.created),
                    format(DATETIME_FORMAT_TO_SHOW),
                  )}
                </Typography>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {comment.body}
                </ReactMarkdown>
              </Box>
            ))}
          </Section>
        </Paper>
      </Box>
    </ContentLayout>
  );
};
