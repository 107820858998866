import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from '../../../../../components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from '../../../../../modules/utils/hooks/common/request';
import { useChartRequiredUtils } from '../../../../../components/Charts/common';
import { LineChart } from '../../../../../components/Charts/2d/LineChart';
import { executiveSummaryChartHeight } from '../executiveSummary.constants';
import { fetchExecutiveSummaryReportWeeklyGrossMarginChartData } from '../../../../../modules/utils/Api/executivesSummaryReport';
import { IExecutiveSummaryReportWeeklyGrossMarginChartResponseDataItem } from '../../../../../modules/types/executives-summary';
import { useSelector } from 'react-redux';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';

interface IExecutiveSummaryWeeklyGrossMarginChartProps {
  siteId?: number;
}

export const ExecutiveSummaryWeeklyGrossMarginChart = ({
  siteId,
}: IExecutiveSummaryWeeklyGrossMarginChartProps) => {
  const { t } = useTranslation();

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchExecutiveSummaryReportWeeklyGrossMarginChartData,
    {
      initialData: [],
    },
  );

  const sites = useSelector(getSitesComboboxList);

  const selectedSiteName = React.useMemo(() => {
    const siteFullData = sites.find(({ id }) => Number(id) === Number(siteId));

    if (siteFullData) {
      return siteFullData.name;
    }

    return t('executive_summary.sum_of_all_sites');
  }, [siteId, sites, t]);

  const mapDataItemToSeriesItem = React.useCallback(
    (item: IExecutiveSummaryReportWeeklyGrossMarginChartResponseDataItem) => ({
      type: 'line' as const,
      name: selectedSiteName,
      data: item.grouped_value.map((item) => (item ? item : 0)),
    }),
    [selectedSiteName],
  );

  const categories = React.useMemo(() => {
    return data.length ? data[0].grouped_week_formatted : [];
  }, [data]);
  const series = React.useMemo(() => {
    return data.map(mapDataItemToSeriesItem);
  }, [data, mapDataItemToSeriesItem]);

  React.useEffect(() => {
    getData({ siteId });
  }, [getData, siteId]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('financial_reports.weekly_gross_margin_money')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('weekly_gross_margin')}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <LineChart
          ref={chartRef}
          height={executiveSummaryChartHeight}
          categories={categories}
          series={series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
