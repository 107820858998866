import React from 'react';
import { isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getPointsByIds } from 'src/modules/selectors/point';
import {
  IdsArray,
  IStoreState,
  RemovalReasons,
  ServerSideRemovalReason,
} from 'src/modules/types';
import { getPointListRequest } from 'src/modules/actions/point';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { defaultOrderDetails } from 'src/modules/utils/helpers/filter';
import { IInclusionObject } from 'src/modules/types/table';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';

export const pointInclusions = [
  {
    relation: 'employee',
  },
  {
    relation: 'pointType',
  },
];

export const pointComboboxFilter = {
  filter: {
    fields: {
      id: true,
      employeeId: true,
      pointTypeId: true,
    },
    include: pointInclusions,
  },
};

export const usePointsInclusion = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => [
      {
        relation: 'pointType',
      },
      {
        relation: 'editedByUser',
        relationType: 'left',
        scope: {
          fields: { firstName: true, lastName: true },
        },
      },
      {
        relation: 'approvedOrRejectedBy',
        alias: 'approvedOrRejectedBy',
        relationType: 'left',
      },
      {
        relation: 'rejectReason',
        relationType: 'left',
      },
      {
        relation: 'employee',
        scope: {
          where: {
            active: {
              eq: true,
            },
            siteId: {
              inq: globallySelectedSiteIds,
            },
          },
          include: [
            {
              relation: 'site',
            },
            {
              relation: 'supervisor',
              alias: 'supervisor',
              relationType: 'left',
              scope: {
                fields: {
                  firstName: true,
                  lastName: true,
                },
              },
            },
          ],
        },
      },
    ],
    [globallySelectedSiteIds],
  );
};

/**
 * A custom hook to fetch points from store if they exist otherwise to make a request to fetch needed points from
 * the server
 */
export const useFetchPointsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch points list from store
  const points = useSelector(
    (state) => getPointsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(points)) {
      dispatcher(
        getPointListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: pointInclusions,
          },
        }),
      );
    }
  };
};

export const usePointsDefaultFilters = () => {
  const include = usePointsInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      where: {
        isRemoved: {
          eq: false,
        },
      },
      include,
    }),
    [include],
  );
};

export const usePointsSummaryDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);
  return React.useMemo(
    () => ({
      order: [`points ${defaultOrderDetails.order}`],
      where: {
        active: {
          eq: true,
        },
        siteId: {
          inq: globallySelectedSiteIds,
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

const removalReasons: Array<RemovalReasons> = [
  'excused',
  'doctorsNote',
  'addedByMistake',
  'juryDuty',
  'militaryDuty',
  'leaveOfAbsence',
  'disciplinaryAction',
  'workersCompInjury',
  'subpoenasToCourts',
  'bereavement',
];

export const removalReasonsToLocationKeyMapper: Record<
  RemovalReasons & ServerSideRemovalReason,
  string
> = {
  doctorsNote: 'doctors_note',
  addedByMistake: 'added_by_mistake',
  excused: 'excused',
  archived: 'archived',
  juryDuty: 'jury_duty',
  militaryDuty: 'military_duty',
  leaveOfAbsence: 'leave_of_absence',
  disciplinaryAction: 'disciplinary_action',
  workersCompInjury: 'workers_comp_injury',
  subpoenasToCourts: 'subpoenas_to_courts',
  bereavement: 'bereavement',
};

export const useRemovalReasonsOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () =>
      removalReasons.map((id) => ({
        id,
        name: t(`points.${removalReasonsToLocationKeyMapper[id]}`),
      })),
    [t],
  );
};

export interface IAutomaticPointsFilters {
  employeeId?: number;
  datetime?: string;
  pointTypeId?: number;
}

export const useAutomaticPointsPermissions = () => {
  const allowedToApprove = useHasUserAccessToAction(
    manageEntitiesConfig.automaticPoint.approve.id,
  );
  const allowedToReject = useHasUserAccessToAction(
    manageEntitiesConfig.automaticPoint.reject.id,
  );

  return {
    allowedToApprove,
    allowedToReject,
  };
};

export const useAutomaticPointsTableInclusionObject = (): IInclusionObject => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      employee: {
        relationType: 'inner',
        scope: {
          include: {
            supervisor: {
              relationType: 'inner',
            },
            site: {
              relationType: 'inner',
              scope: {
                where: {
                  id: {
                    inq: globallySelectedSiteIds,
                  },
                },
              },
            },
          },
        },
      },
      pointType: {
        relationType: 'inner',
      },
    }),
    [globallySelectedSiteIds],
  );
};
