import React from 'react';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';

Exporting(Highcharts);
OfflineExporting(Highcharts);

export const highchartsReactComponentProps = {
  containerProps: { style: { width: '100%', height: '100%' } },
};

export const plotOptionsSeries = {
  groupPadding: 0.12,
};

export const useChartRequiredUtils = () => {
  const [chartHeight, setChartHeight] = React.useState('100%');
  const chartRef = React.useRef<Highcharts.Chart | null>(null);

  React.useEffect(() => {
    if (chartRef.current) {
      setChartHeight(
        (chartRef.current as any).chart.container.offsetHeight + 'px',
      );
    }
  }, []);

  return React.useMemo(
    () => ({
      chartRef,
      chartHeight,
      toggleFullScreen: () =>
        (chartRef.current as any).chart.fullscreen.toggle(),
      exportChart: (filename?: string) => {
        (chartRef.current as any).chart.exportChart({ filename });
      },
    }),
    [chartHeight],
  );
};

export const useSynchronizedChartRequiredUtils = () => {
  const exportCharts = (
    charts: Array<Highcharts.Chart>,
    _options?: Highcharts.ExportingOptions,
  ) => {
    const options = Highcharts.merge(
      Highcharts.getOptions().exporting,
      _options,
    );
    (Highcharts as any).getSVG(charts, options, function (svg: any) {
      (Highcharts as any).downloadSVGLocal(svg, options, function () {
        console.log('Failed to export on client side');
      });
    });
  };

  React.useEffect(() => {
    (Highcharts as any).getSVG = function (
      charts: Array<any>,
      options: Highcharts.ExportingOptions,
      callback: any,
    ) {
      let top = 0,
        width = 0;

      const svgArr: Array<string> = [];

      const addSVG = function (svgres: any) {
        // Grab width/height from exported chart
        const svgWidth = +svgres.match(
            /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/,
          )[1],
          svgHeight = +svgres.match(
            /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/,
          )[1];

        // Offset the position of this chart in the final SVG
        let svg = svgres.replace('<svg', `<g transform="translate(0,${top})" `);
        svg = svg.replace('</svg>', '</g>');
        top += svgHeight;
        width = Math.max(width, svgWidth);
        svgArr.push(svg);
      };

      const exportChart = function (i: any) {
        if (i === charts.length) {
          return callback(
            `<svg 
                version="1.1" 
                width="${width}" 
                height="${top}"
                viewBox="0 0 ${width} ${top}"
                xmlns="http://www.w3.org/2000/svg">
                  ${svgArr.join('')}
              </svg>`,
          );
        }
        charts[i].getSVGForLocalExport(
          options,
          {},
          function () {
            console.log('Failed to get SVG');
          },
          function (svg: string) {
            addSVG(svg);
            // Export next only when this SVG is received
            return exportChart(i + 1);
          },
        );
      };
      exportChart(0);
    };
  }, []);

  const toggleFullScreen = (
    charts: Array<Highcharts.Chart>,
    container: HTMLDivElement,
  ) => {
    if (!document.fullscreenElement && container.requestFullscreen) {
      container
        .requestFullscreen()
        .then(() => {
          // Reflow each chart after entering full screen
          charts.forEach((chart) => {
            if (chart) {
              chart.reflow();
            }
          });
        })
        .catch((err) => {
          console.error(
            `Error attempting to enable full-screen mode: ${err.message}`,
          );
        });
    } else if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => {
          // Also reflow each chart after exiting full screen
          charts.forEach((chart) => {
            if (chart) {
              chart.reflow();
            }
          });
        })
        .catch((err) => {
          console.error(
            `Error attempting to exit full-screen mode: ${err.message}`,
          );
        });
    }
  };

  return { exportCharts, toggleFullScreen };
};
