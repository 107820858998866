import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchReport } from 'src/modules/utils/hooks/common/reports';
import { subWeeks } from 'date-fns';
import { YEAR_WEEK_ISO, format } from 'src/modules/utils/dateWrapper';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import { concatenateYearWeek } from 'src/modules/utils/helpers/formatters';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { chartHeight } from '../financialReports.constants';

export const FinancialReportsWeeklyActiveInactiveHired = () => {
  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const toDate = new Date();
  const toYearWeek = format(YEAR_WEEK_ISO)(toDate);

  const fromDate = subWeeks(toDate, 13);
  const fromYearWeek = format(YEAR_WEEK_ISO)(fromDate);

  const [toYear, toWeek] = toYearWeek.split('-W');
  const [fromYear, fromWeek] = fromYearWeek.split('-W');

  const { fetchData, data, isDataLoading } = useFetchReport<{
    year: number;
    week: number;
    hiredHeadcount: number;
    inactivatedHeadcount: number;
    withApprovedHoursHeadcount: number;
  }>('/employees/statistic/for-executives');

  const { t } = useTranslation();

  const categories = React.useMemo(
    () => data.items.map((item) => `${item.year}-W${item.week}`),
    [data.items],
  );
  const hiredHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.hired_headcount'),
      data: data.items.map((item) => item.hiredHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );
  const inactivatedHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.inactivated_headcount'),
      data: data.items.map((item) => item.inactivatedHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );
  const withApprovedHoursHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.active'),
      data: data.items.map((item) => item.withApprovedHoursHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );

  React.useEffect(() => {
    fetchData(
      {
        filter: {
          order: ['yearWeek asc'],
          where: {
            and: [
              {
                yearWeek: {
                  gte: concatenateYearWeek({
                    year: Number(fromYear),
                    week: Number(fromWeek),
                  }),
                },
              },
              {
                yearWeek: {
                  lt: concatenateYearWeek({
                    year: Number(toYear),
                    week: Number(toWeek),
                  }),
                },
              },
            ],
          },
        },
      },
      {},
    );
  }, [fetchData, fromWeek, fromYear, toWeek, toYear]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('financial_reports.weekly_active_inactive_hired')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('weekly_active_inactive_hired')}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isDataLoading}
        showNoData={
          !hiredHeadcount.data.length &&
          !inactivatedHeadcount.data.length &&
          !withApprovedHoursHeadcount.data.length
        }
      >
        <ColumnChart
          fullscreenTitle={t('financial_reports.weekly_active_inactive_hired')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={[
            hiredHeadcount,
            inactivatedHeadcount,
            withApprovedHoursHeadcount,
          ]}
          yAxis={t('turnover.number_of_employees')}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
