import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType, XAxisOptions, YAxisOptions } from 'highcharts';
import more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import theme from '../../assets/css/theme';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

more(Highcharts);
exporting(Highcharts);

interface IProps {
  title: string;
  categories: string[];
  series: SeriesOptionsType[];
  colors?: string[];
}

const RadialBarChart = (props: IProps) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [series, setSeries] = useState<SeriesOptionsType[]>([]);

  // we need to keep data in the local state in order to to be able to update the chart when we fetch data from the server
  useEffect(() => {
    setCategories(props.categories);
    setSeries(props.series);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categories, props.series]);

  React.useEffect(() => {
    // TODO: chart has incorrect width on initial render, resize fixes it
    // find out how to fix it without hack below
    window.dispatchEvent(new Event('resize'));
  }, []);

  const options: Highcharts.Options = {
    colors: props.colors || theme.chartColorScheme,
    chart: {
      type: 'column',
      inverted: true,
      polar: true,
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: props.title ?? undefined,
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: 'right',
        useHTML: true,
        allowOverlap: true,
        step: 1,
        y: 3,
        style: {
          fontSize: '13px',
        },
      },
      lineWidth: 0,
      categories,
    } as XAxisOptions,
    tooltip: {
      outside: true,
    },
    pane: {
      size: '85%',
      innerSize: '20%',
      endAngle: 270,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      crosshair: {
        enabled: true,
        color: '#333',
      },
      lineWidth: 0,
      tickInterval: 25,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
    } as YAxisOptions,
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
      },
    },
    series: series,
    exporting: {
      enabled: true,
    },
  };

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Box>
  );
};

export default RadialBarChart;
