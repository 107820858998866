import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MUI_SIZE_12, useHasUserAccessToAction } from '../../../../../config';
import * as actions from '../../../../../modules/actions';
import {
  getAdditionalFeeCategoryList,
  getIsAdditionalFeeCategoryDataLoading,
} from '../../../../../modules/selectors/additionalFee.selector';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { IFilterData, IdsArray } from '../../../../../modules/types';
import { CreateAdditionalFeeCategory } from './Forms/CreateAdditionalFeeCaregory';
import UpdateAdditionalFeeCategory from './Forms/UpdateAdditionalFeeCategory';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { Alert, Grid } from '@mui/joy';
import { useAdditionalFeeCategoryListDefaultFilter } from 'src/modules/utils/hooks/additional-fee-category.hooks';

const AdditionalFeeCategoryList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const siteIdRef = React.useRef<null | number>(null);

  const [appliedFilters, setAppliedFilters] = React.useState<IFilterData>({});

  const isSiteSelected = Boolean(siteIdRef.current);

  const isTableDataLoading = useSelector(getIsAdditionalFeeCategoryDataLoading);

  const hasUserAccessToCreateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.additional_fee_category.create.id,
  );
  const hasUserAccessToUpdateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.additional_fee_category.update.id,
  );
  const hasUserAccessToDeleteEntity = useHasUserAccessToAction(
    manageEntitiesConfig.additional_fee_category.delete.id,
  );

  const defaultFilter = useAdditionalFeeCategoryListDefaultFilter();

  const { list, count } = useSelector(getAdditionalFeeCategoryList);

  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState<boolean>(
    false,
  );
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState<boolean>(
    false,
  );

  const [selectedIds, setSelectedIds] = React.useState<IdsArray>([]);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('additional-fee.name') },
    {
      id: 'description',
      disablePadding: false,
      label: t('additional-fee.description'),
    },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('common.site'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'site.id',
      label: t('common.site'),
      operator: 'eq',
      type: 'comboboxSites',
      onChange: (_: any, value: any) => {
        siteIdRef.current = value;
      },
    },
    {
      name: 'name',
      label: t('additional-fee.name'),
      operator: 'like',
      type: 'text',
    },
    {
      name: 'description',
      label: t('additional-fee.description'),
      operator: 'like',
      type: 'text',
    },
  ];

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include } = props;
    const offset = page * rowsPerPage;

    const filter = {
      limit: rowsPerPage,
      offset,
      where,
      order,
      include,
    };

    dispatch(
      actions.fetchAdditionalFeeCategoryListRequest({
        filter,
      }),
    );

    // update count accordingly to applied filters
    dispatch(
      actions.fetchAdditionalFeeCategoriesCountRequest({
        filter: { where, include },
      }),
    );

    setAppliedFilters(filter);
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatch(
      actions.deleteAdditionalFeeCategoryRequest({
        data: ids,
        filters: {
          list: appliedFilters,
          count: {
            where: appliedFilters.where,
            include: appliedFilters.include,
          },
        },
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setSelectedIds(ids);
    setIsUpdateFormVisible(true);
  };

  return (
    <>
      <EnhancedTable
        data={list}
        count={count}
        selectIndex="id"
        disableQsFilters
        hideTable={!isSiteSelected}
        isTableDataLoading={isTableDataLoading}
        tableName={t('additional-fee.fee_category_table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={hasUserAccessToDeleteEntity ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdateEntity ? handleUpdating : undefined}
        include={defaultFilter.include}
        createEntityBtnProps={
          hasUserAccessToCreateEntity
            ? {
                title: t('common.create'),
                onClick: () =>
                  setIsCreateFormVisible((prevState) => !prevState),
              }
            : undefined
        }
        infoMessage={
          !isSiteSelected ? (
            <Grid container={true}>
              <Grid xs={MUI_SIZE_12}>
                <Alert variant="soft">
                  {t('reports.select_site_from_filter')}
                </Alert>
              </Grid>
            </Grid>
          ) : null
        }
      />
      <>
        <CreateAdditionalFeeCategory
          isOpen={isCreateFormVisible}
          onClose={() => setIsCreateFormVisible(false)}
          filterList={appliedFilters}
          filterCount={{
            where: appliedFilters.where,
            include: defaultFilter.include,
          }}
        />
        <UpdateAdditionalFeeCategory
          isOpen={isUpdateFormVisible}
          onClose={() => setIsUpdateFormVisible(false)}
          idsToUpdate={selectedIds}
          filterList={appliedFilters}
          filterCount={{
            where: appliedFilters.where,
            include: defaultFilter.include,
          }}
        />
      </>
    </>
  );
};

export default AdditionalFeeCategoryList;
