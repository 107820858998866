import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useFetchSitesPricingTypes, useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  IPricingWithMeta,
  PricingType,
  PricingTypeObject,
} from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { addProcessStatus } from 'src/modules/actions';
import { get, omit, pick } from 'lodash';
import { PricingUpdateSingleRecords } from './PricingUpdateSingleRecords';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import { PricingUpdateRangeRecords } from './PricingUpdateRangeRecords';
import {
  generateUpdatePricingSingleRecordScheme,
  updatePricingRangeScheme,
} from 'src/modules/schemes/pricing';
import { usePricingSettings } from 'src/modules/utils/hooks/pricing-settings';
import { Box, Typography } from '@mui/joy';

interface IPricingUpdateRecordsProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (siteId: number) => void;
  clickedRowData: AnyObject;
  data: IPricingWithMeta['data'];
}

export const PricingUpdateRecordsComponent = ({
  onClose,
  onSuccess,
  clickedRowData,
  data,
}: IPricingUpdateRecordsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const siteId = clickedRowData.siteId;

  const metatagNames = useSelector(getMetatagsNames);

  const [
    pricingTypesList,
    setPricingTypesList,
  ] = React.useState<PricingTypeObject | null>();
  const [pricingType, setPricingType] = React.useState<PricingType | null>(
    null,
  );

  const fetchPricingTypes = useFetchSitesPricingTypes();

  const { settings } = usePricingSettings(siteId);

  const metatags = React.useMemo(
    () =>
      (siteId && settings && metatagNames[siteId]
        ? metatagNames[siteId]
        : []
      ).filter((mt) => !get(settings, [mt, 'disabled'])),
    [siteId, settings, metatagNames],
  );

  const clickedRowMetatagsData = metatags.map((key) => ({
    [key]: clickedRowData[key],
  }));

  const entities = data
    .filter((item) =>
      clickedRowMetatagsData.every((metatagData) =>
        Object.entries(metatagData).every(
          ([key, value]) => item[key] === value,
        ),
      ),
    )
    .map((item) => ({
      ...(omit(item, 'deletedAt') as any),
      price: String(item.price),
      incentivePrice: String(item.incentivePrice),
      overtimePrice: String(item.overtimePrice),
      minUph: String(item.minUph),
      maxUph: String(item.maxUph),
    }));

  const singleRecordFormik = useFormik({
    validateOnMount: false,
    enableReinitialize: true,
    validate: useValidate(generateUpdatePricingSingleRecordScheme(settings)),
    initialValues: {
      ...clickedRowData,
      siteId,
      id: clickedRowData.id,
      price: String(clickedRowData.price),
      incentivePrice: String(clickedRowData.incentivePrice),
      overtimePrice: String(clickedRowData.overtimePrice),
      minUph: String(clickedRowData.minUph),
      maxUph: String(clickedRowData.maxUph),
    },
    onSubmit: async (data) => {
      try {
        await Api.Pricing.bulkUpdate([
          {
            ...data,
            id: data.id,
            price: Number(data.price),
            incentivePrice: Number(data.incentivePrice),
            overtimePrice: Number(data.overtimePrice),
            minUph: Number(data.minUph),
            maxUph: Number(data.maxUph),
          },
        ]);

        onSuccess(siteId);

        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        singleRecordFormik.resetForm();
      } catch {}
    },
  });

  const singleRecordFormikErrors = React.useMemo(
    () => pick(singleRecordFormik.errors, Object.keys(settings)),
    [singleRecordFormik.errors, settings],
  );
  const singleRecordFormikValues = React.useMemo(
    () => pick(singleRecordFormik.values, Object.keys(settings)),
    [singleRecordFormik.values, settings],
  );

  const rangeRecordsFormik = useFormik({
    initialValues: entities.map((item) => ({
      ...item,
      price: String(item.price),
      incentivePrice: String(item.incentivePrice),
      overtimePrice: String(item.overtimePrice),
    })),
    validate: useValidate(updatePricingRangeScheme),
    onSubmit: async (data) => {
      try {
        await Api.Pricing.updateRange(
          data.map((item, index) => ({
            ...item,
            siteId: Number(siteId),
            price: Number(item.price),
            incentivePrice: Number(item.incentivePrice),
            overtimePrice: Number(item.overtimePrice),
            minUph: Number(index === 0 ? item.minUph : data[index - 1].maxUph),
            maxUph: Number(item.maxUph),
          })),
        );

        onSuccess(siteId);

        dispatch(
          addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );

        rangeRecordsFormik.resetForm();
      } catch {}
    },
  });

  const rangeRecordsFormikErrors = React.useMemo(
    () => pick(rangeRecordsFormik.errors, Object.keys(settings)),
    [rangeRecordsFormik.errors, settings],
  );
  const rangeRecordsFormikValues = React.useMemo(
    () => pick(rangeRecordsFormik.values, Object.keys(settings)),
    [rangeRecordsFormik.values, settings],
  );

  const pricingMetatags = React.useMemo(
    () =>
      Object.values(settings).map(
        (valueDescriptor) =>
          !valueDescriptor.disabled && (
            <FormFieldContainer key={valueDescriptor.name}>
              <Box>
                <Box sx={{ pb: '6px' }}>
                  <Typography
                    level="text_sm"
                    fontWeight="medium"
                    textColor="colors.text.text_secondary.main"
                  >
                    {valueDescriptor.name}
                  </Typography>
                </Box>
                <Box
                  sx={(theme) => ({
                    height: 40,
                    fontWeight: theme.fontWeight.medium,
                    borderRadius: theme.radius.md,
                    color: theme.palette.colors.text.text_primary.main,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.colors.border.border_primary,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    display: 'flex',
                    alignItems: 'center',
                    pl: 1.5,
                  })}
                >
                  <Typography level="text_sm">
                    {clickedRowData[valueDescriptor.name]}
                  </Typography>
                </Box>
              </Box>
            </FormFieldContainer>
          ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      siteId,
      settings,
      pricingType,
      singleRecordFormikErrors,
      singleRecordFormikValues,
      rangeRecordsFormikErrors,
      rangeRecordsFormikValues,
    ],
  );

  const resetAllForms = () => {
    singleRecordFormik.resetForm();
    rangeRecordsFormik.resetForm();
  };

  const onReset = () => {
    resetAllForms();
  };

  const onApply = () => {
    if (pricingType === 'single') {
      singleRecordFormik.handleSubmit();
    }
    if (pricingType === 'range') {
      rangeRecordsFormik.handleSubmit();
    }
  };

  const onDelete = async () => {
    if (pricingType === 'single') {
      await Api.Pricing.delete({
        where: { id: { inq: [entities.map((e) => e.id)] } },
      });

      onSuccess(siteId);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );

      singleRecordFormik.resetForm();
    }

    if (pricingType === 'range') {
      await Api.Pricing.deleteRange(
        siteId,
        metatags.reduce((acc, key) => {
          acc[key] = { eq: clickedRowData[key] };
          return acc;
        }, {}),
      );

      onSuccess(siteId);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );

      rangeRecordsFormik.resetForm();
    }
  };

  React.useEffect(() => {
    const getPricingTypes = async () => {
      const pricingTypes = await fetchPricingTypes();
      setPricingTypesList(pricingTypes);
    };

    getPricingTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setPricingType(
      siteId && pricingTypesList ? pricingTypesList[siteId] : null,
    );
  }, [siteId, pricingTypesList]);

  return (
    <>
      <DrawerHeader onCloseClick={onClose}>
        {t('pricing.update_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {pricingType === 'single' && (
            <PricingUpdateSingleRecords
              formik={singleRecordFormik}
              pricingMetatags={pricingMetatags}
            />
          )}
          {pricingType === 'range' && (
            <PricingUpdateRangeRecords
              formik={rangeRecordsFormik}
              pricingMetatags={pricingMetatags}
            />
          )}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={onApply}
          onCancel={onClose}
          onDelete={onDelete}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </>
  );
};

export const PricingUpdateRecords = ({
  clickedRowData,
  ...props
}: Omit<IPricingUpdateRecordsProps, 'clickedRowData'> & {
  clickedRowData: AnyObject | null;
}) => {
  return (
    <Drawer
      open={props.isOpen}
      onClose={props.onClose}
      anchor="right"
      slotProps={{ content: { sx: { minWidth: 600 } } }}
    >
      {clickedRowData ? (
        <PricingUpdateRecordsComponent
          clickedRowData={clickedRowData}
          {...props}
        />
      ) : null}
    </Drawer>
  );
};
