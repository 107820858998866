import { createAction } from 'redux-act';
import {
  IOverdueQuestionnaire,
  IQuestionEmployeeHistoryItem,
} from 'src/modules/types/question-employee';
import {
  ICountResponse,
  IFilter,
  IFilterData,
  IQuestionCategoryModel,
  PayloadWithFilters,
} from '../../types';

export const flushQuestionEmployeeState = createAction(
  'Flush question employee data in store',
);

export const fillInQuestionnaireRequest = createAction<FormData>(
  'Make request to fill in questionnaire',
);
export const fillInQuestionnaireSuccess = createAction<IQuestionCategoryModel>(
  'Questionnaire filled in successfully',
);
export const fillInQuestionnaireFailed = createAction(
  'Cannot fill in questionnaire',
);

export const updateQuestionnaireAnswersRequest = createAction<
  PayloadWithFilters<FormData>
>('Make request to update questionnaire answers');
export const updateQuestionnaireAnswersSuccess = createAction(
  'Questionnaire answers updated successfully',
);
export const updateQuestionnaireAnswersFailed = createAction(
  'Cannot update questionnaire answers',
);

export const getQuestionEmployeeHistoryDataRequest = createAction<IFilter>(
  'Make request to get question employee history data',
);
export const getQuestionEmployeeHistoryDataSuccess = createAction<
  IQuestionEmployeeHistoryItem[]
>('Question employee history data fetched successfully');
export const getQuestionEmployeeHistoryDataFailed = createAction(
  'Cannot get question employee history data',
);

export const getQuestionEmployeeHistoryDataCountRequest = createAction<IFilter>(
  'Make request to get question employee history data count',
);
export const getQuestionEmployeeHistoryDataCountSuccess = createAction<
  ICountResponse
>('Question employee history data count has been fetched successfully');
export const getQuestionEmployeeHistoryDataCountFailed = createAction(
  'Cannot get employee history data count',
);

export const getOverdueQuestionnairesRequest = createAction<IFilter>(
  'Make request to get overdue questionnaires data',
);
export const getOverdueQuestionnairesSuccess = createAction<
  IOverdueQuestionnaire[]
>('Overdue questionnaires has been fetched successfully');
export const getOverdueQuestionnairesFailed = createAction(
  'Cannot get overdue questionnaires',
);

export const getOverdueQuestionnairesCountRequest = createAction<IFilter>(
  'Make request to get overdue questionnaires count',
);
export const getOverdueQuestionnairesCountSuccess = createAction<
  ICountResponse
>('Overdue questionnaires count has been fetched successfully');
export const getOverdueQuestionnairesCountFailed = createAction(
  'Cannot get overdue questionnaires count',
);

export const deleteQuestionEmployeeRequest = createAction<IFilterData>(
  'Make request to delete question employee',
);
export const deleteQuestionEmployeeSuccess = createAction(
  'Question employee removed successfully',
);
export const deleteQuestionEmployeeFailed = createAction(
  'Cannot delete question employee',
);
