import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useWeeklyHoursDashboardChart } from 'src/modules/utils/hooks/weekly-hours-dashboard.hooks';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';

interface IWeeklyHoursDashboardChartProps {
  siteId?: number;
  department?: string;
  yearWeek?: { from: number; to: number };
}

export const WeeklyHoursDashboardChart: React.FC<IWeeklyHoursDashboardChartProps> = ({
  siteId,
  yearWeek,
}) => {
  const { t } = useTranslation();

  const { series, isDataLoading, categories } = useWeeklyHoursDashboardChart({
    siteId,
    yearWeek,
  });

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('charts.weekly_hours')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`downtime`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isDataLoading} showNoData={!!0}>
        <ColumnChart
          ref={chartRef}
          height={chartHeight}
          series={series}
          categories={categories}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
