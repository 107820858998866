import React, { useEffect } from 'react';
import { useDataFetcherWithData } from '../../../modules/utils/hooks/common/reports';
import { PageWithTabs } from 'src/components/PageWithTabs';
import { useFinancialTrackerChartsData } from '../../../modules/utils';

const Splash = () => {
  // Get Gross Margin data for charts
  const { fetchData, data } = useDataFetcherWithData(
    '/financial-tracker/weekly',
    [] as any,
  );

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { configuration } = useFinancialTrackerChartsData(data);
  // const categories = React.useMemo(() => {
  //   const [firstItem] = data;
  //   return firstItem?.grouped_week_formatted as string[];
  // }, [data]);

  // const grossMarginValueSeries = React.useMemo(
  //   () =>
  //     data.map((item: any) => ({
  //       type: 'area' as const,
  //       name: (item?.name ?? '') as string,
  //       data: (item.grouped_value as unknown) as number[],
  //     })),
  //   [data],
  // );
  // const grossMarginPercentageValueSeries = React.useMemo(
  //   () =>
  //     data.map((item: any) => ({
  //       name: (item?.name ?? '') as string,
  //       data: (item.grouped_percent as unknown) as number[],
  //     })),
  //   [data],
  // );

  // const weeklyGrossMarginValue: IWeeklyGrossMarginValueChartData = React.useMemo(() => {
  //   return {
  //     categories,
  //     series: grossMarginValueSeries,
  //   };
  // }, [categories, grossMarginValueSeries]);

  // const weeklyGrossMarginPercentageValue = React.useMemo(() => {
  //   return {
  //     categories,
  //     series: grossMarginPercentageValueSeries,
  //   };
  // }, [categories, grossMarginPercentageValueSeries]);

  // const configuration = React.useMemo(() => {
  //   return [
  //     {
  //       tabLabel: t('common.charts'),
  //       component: (
  //         <Box width="100%" height="100%" display="flex" flexDirection="column">
  //           <Paper style={{ marginBottom: '16px' }}>
  //             <WeeklyGrossMarginBySiteAndWeekChart
  //               weeklyGrossMarginValue={weeklyGrossMarginValue}
  //             />
  //           </Paper>
  //           <Paper style={{ marginBottom: '16px' }}>
  //             <WeeklySynchronizedCharts />
  //           </Paper>
  //           <Paper style={{ marginBottom: '16px' }}>
  //             <WeeklyGrossMarginBySitePercentageAndWeekChart
  //               {...weeklyGrossMarginPercentageValue}
  //             />
  //           </Paper>
  //           <Paper style={{ marginBottom: '16px' }}>
  //             <EmployeesStatisticChart />
  //           </Paper>
  //         </Box>
  //       ),
  //     },
  //     {
  //       tabLabel: t('common.tables'),
  //       component: <FinancialTrackerTable />,
  //     },
  //   ];
  // }, [t, weeklyGrossMarginPercentageValue, weeklyGrossMarginValue]);

  return <PageWithTabs configuration={configuration} />;
};

export default Splash;
