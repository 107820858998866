import { createAction } from 'redux-act';
import {
  IChecklistModel,
  IChecklistModelWithRelations,
  IChecklistRecurringTaskModel,
  IChecklistTaskModel,
  ICountResponse,
  ICreateChecklistData,
  ICreateChecklistTaskData,
  IFilter,
  IUpdateChecklistTaskData,
} from '../types';

// Flush checklist
export const flushChecklistState = createAction(
  'Flush checklist data in store',
);
export const flushChecklistTaskState = createAction(
  'Flush checklist task data in store',
);

// checklists
export const getChecklistsRequest = createAction<IFilter>(
  'Make request to fetch checklists',
);
export const getChecklistsSuccess = createAction<
  IChecklistModelWithRelations[]
>('Checklists has been fetched successfully');
export const getChecklistsFailed = createAction('Cannot fetch checklists');
export const getChecklistsCountRequest = createAction<IFilter>(
  'Make request to get checklists count',
);
export const getChecklistsCountSuccess = createAction<ICountResponse>(
  'Checklists count has been fetched successfully',
);
export const getChecklistsCountFailed = createAction(
  'Cannot get checklists count',
);

// Fetch combobox list actions
export const getChecklistComboboxDataRequest = createAction<IFilter>(
  'Make request to fetch combobox list of checklist',
);
export const getChecklistComboboxDataSuccess = createAction<
  IChecklistModelWithRelations[]
>('Combobox list of checklist has been fetched successfully');
export const getChecklistComboboxDataFailed = createAction(
  'Cannot fetch combobox checklist',
);

// checklist tasks
export const getChecklistTasksRequest = createAction<IFilter>(
  'Make request to fetch checklists',
);
export const getChecklistTasksSuccess = createAction<IChecklistTaskModel[]>(
  'ChecklistTasks has been fetched successfully',
);
export const getChecklistTasksFailed = createAction(
  'Cannot fetch checklistTasks',
);

export const createChecklistTaskRequest = createAction<
  ICreateChecklistTaskData
>('Make request to create a new task');
export const createChecklistTaskSuccess = createAction<IChecklistTaskModel>(
  'New task has been created successfully',
);
export const createChecklistTaskFailed = createAction(
  'Cannot create a new task',
);

export const updateChecklistTaskRequest = createAction<
  IUpdateChecklistTaskData
>('Make request to update a new task');
export const updateChecklistTaskSuccess = createAction<
  IChecklistTaskModel & IChecklistRecurringTaskModel
>('Task has been updated successfully');
export const updateChecklistTaskFailed = createAction(
  'Cannot update a new task',
);

export const deleteChecklistTaskRequest = createAction<
  IChecklistTaskModel['id']
>('Make request to delete task');
export const deleteChecklistTaskSuccess = createAction<
  IChecklistTaskModel['id']
>('Task has been deleted successfully');
export const deleteChecklistTaskFailed = createAction(
  'Cannot delete recurring task',
);

export const deleteChecklistRecurringTaskRequest = createAction<
  IChecklistRecurringTaskModel['id']
>('Make request to delete recurring task');
export const deleteChecklistRecurringTaskSuccess = createAction<
  IChecklistRecurringTaskModel['id']
>('Task has been deleted successfully');
export const deleteChecklistRecurringTaskFailed = createAction(
  'Cannot delete recurring task',
);

export const createChecklistRequest = createAction<ICreateChecklistData>(
  'Make request to create a new checklist',
);
export const createChecklistSuccess = createAction<IChecklistModel>(
  'New checklist  has been created successfully',
);
export const createChecklistFailed = createAction(
  'Cannot create a new checklist',
);

export const updateChecklistRequest = createAction<IChecklistModel>(
  'Make request to update a new checklist',
);
export const updateChecklistSuccess = createAction(
  'Checklist has been updated successfully',
);
export const updateChecklistFailed = createAction(
  'Cannot update a new checklist',
);

export const deleteChecklistRequest = createAction<IChecklistModel['id']>(
  'Make request to delete checklist',
);
export const deleteChecklistSuccess = createAction(
  'Checklist has been deleted successfully',
);
export const deleteChecklistFailed = createAction('Cannot delete ');
