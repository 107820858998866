import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { Box, Button, Tooltip } from '@mui/joy';

import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  useDowntime,
  useDowntimeFilter,
  useDowntimePermissions,
  useDowntimeTableFiltersConfiguration,
} from 'src/modules/utils/hooks/downtime.hooks';
import { DowntimeFilter } from 'src/pages/Downtime/panels/components/DowntimeFilter';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import Thumbnail from 'src/components/Thumbnail';
import { IIssueModel } from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { DowntimeCreate } from 'src/pages/Downtime/panels/DowntimeCreate';
import { ModelsToDelete } from 'src/config';
import { TableWithActionsDeleteModal } from 'src/components/TableWithActions/components/TableWithActionsDeleteModal';
import { DowntimeUpdate } from './DowntimeUpdate';

const reportUrl = '/issues';

export const DowntimeList = ({
  onDataChange,
}: {
  onDataChange: () => void;
}) => {
  const { t } = useTranslation();

  const SHORT_DESC_LENGTH = 50;

  const exportProps = useReportExportProps(reportUrl, true);

  const filterFieldsConfiguration = useDowntimeTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const filter = useDowntimeFilter({
    downTime: filterFields.downTime.value,
    issueTypeId: +filterFields.issueTypeId.value,
    departmentId: +filterFields.departmentId.value,
    description: filterFields.description.value,
    duration: +filterFields.description.value,
  });

  const {
    allowedToUpdate,
    allowedToDelete,
    allowedToCreate,
  } = useDowntimePermissions();

  const [selectedItems, setSelectedItems] = React.useState<IIssueModel[]>([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState(false);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
    removeDataByIds,
    fetchData,
  } = useDowntime(reportUrl, filter!.where!, filter!.include!);

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  // computed list with icons
  const computedList = useMemo(
    () =>
      map(data, (item) => ({
        ...item,
        downTime: composeDate(
          item.downTime,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
        attachment: item.picture ? (
          <Thumbnail src={item.picture} alt={item.picture} />
        ) : null,
        description:
          item.description && item.description?.length > SHORT_DESC_LENGTH ? (
            <Tooltip title={item.description}>
              <span>
                {item.description.substring(0, SHORT_DESC_LENGTH) + '...'}
              </span>
            </Tooltip>
          ) : (
            item.description
          ),
      })),
    [data],
  );

  const onTableRowCheckboxClick = (items: Array<IIssueModel>) => {
    setSelectedItems(items);
  };

  const handleDelete = async () => {
    const ids = selectedItems.map((i) => i.id);
    await removeDataByIds(ids);
    await fetchData({
      filter,
    });
    setShowDeleteModal(false);

    onDataChange();
  };

  // handle updating
  const handleUpdating = (items: IIssueModel[]) => {
    setIsUpdateFormVisible(true);
    setSelectedItems(items);
  };

  const handleCreate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsCreateFormVisible(false);

    onDataChange();
  };

  const handleUpdate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsUpdateFormVisible(false);

    onDataChange();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: isDataLoading ? 400 : 'auto',
        height: isDataLoading || !data.length ? '100%' : 'auto',
      }}
    >
      <TableToolbar>
        <TableTitle>{t('issues.list_title')}</TableTitle>

        <TableActions>
          <DowntimeFilter
            getLabel={getLabel}
            onFiltersFormSubmit={onFiltersFormSubmit}
            getFilterCommonPropsByFilterName={getFilterCommonPropsByFilterName}
          />

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={requestFilters}
            exportBtnContainerProps={{ ml: 3 }}
            {...exportProps}
          />

          {allowedToCreate && (
            <ReportPageActionsGroupItem>
              <Button onClick={() => setIsCreateFormVisible(true)}>
                {t('issues.create_form_title')}
              </Button>
            </ReportPageActionsGroupItem>
          )}
        </TableActions>
      </TableToolbar>

      <TableWithActionsDeleteModal
        open={showDeleteModal}
        onOk={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        deleteModelName={ModelsToDelete.Issue}
      />

      <TableWithActions<IIssueModel>
        heightWithTabs
        selectedIndex="id"
        data={computedList as any}
        count={countData.count}
        isDataLoading={isDataLoading || isCountDataLoading}
        tableUniqueKey={reportUrl}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        limit={limit}
        page={page}
        onSort={handleSort}
        selectedItems={selectedItems}
        onRowCheckboxClick={onTableRowCheckboxClick}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(page) => handlePageChange(page)}
        {...(allowedToUpdate
          ? {
              updateActionConfiguration: {
                onClick: () => {
                  handleUpdating(selectedItems);
                },
              },
            }
          : {})}
        {...(allowedToDelete
          ? {
              deleteActionConfiguration: {
                onClick: () => setShowDeleteModal(true),
              },
            }
          : {})}
      />

      <DowntimeCreate
        isOpen={isCreateFormVisible}
        onClose={() => setIsCreateFormVisible(false)}
        onCreate={handleCreate}
      />

      <DowntimeUpdate
        issues={selectedItems}
        isOpen={isUpdateFormVisible}
        onUpdate={handleUpdate}
        onClose={() => setIsUpdateFormVisible(false)}
      />
    </Box>
  );
};
