import React, { useContext } from 'react';
import { MultiRowFormContext } from '../MultiRowFormContext';
import { Box, Theme, useMediaQuery } from '@mui/material';

const MARGIN_MULTIPLIER = 2;

interface IMultiRowFormFieldProps {
  fieldId: string;
  fieldLabel: string;
  renderField: (args: { id: string; label: string }) => React.ReactElement;
}

export const MultiRowFormField: React.FC<IMultiRowFormFieldProps> = ({
  renderField,
  fieldLabel,
  fieldId,
}) => {
  const { addHeaderLabelByIndex, columnDirectionMaxWidth } = useContext(
    MultiRowFormContext,
  );

  const isBigScreen = useMediaQuery(`(min-width:${columnDirectionMaxWidth}px)`);

  React.useEffect(() => {
    addHeaderLabelByIndex(fieldLabel);
  }, [addHeaderLabelByIndex, fieldLabel]);

  return (
    <Box
      sx={(theme: Theme) => ({
        marginBottom: MARGIN_MULTIPLIER,

        [theme.breakpoints.up(Number(columnDirectionMaxWidth))]: {
          marginRight: MARGIN_MULTIPLIER,
          marginBottom: 0,

          '&:last-child': {
            marginRight: 0,
          },
        },
      })}
    >
      {renderField({ id: fieldId, label: isBigScreen ? '' : fieldLabel })}
    </Box>
  );
};
