import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  IAuth,
  IAuthState,
  IConfirmForgotPasswordResponse,
  IConfirmRegistrationResponse,
  IForgotPasswordResponse,
  IRegisterResponse,
} from '../../types';
import { getUserObjectFromStorage } from '../../utils/user';

// get token from localStorage
const {
  auth: { token = '', role = '', expires_at = '' } = {},
} = getUserObjectFromStorage();

// default state
const defaultState: IAuthState = {
  is_logged_in: !!token,
  token,
  expires_at,
};

// if we can fetch current user role from localStore we put it to state
// don't worry about security that we store role in localStorage. We anyway have an appropriate mechanism
// to work with authorization on server side and even if you try to update current role manually in client-side
// you will never do an action you are not authorized for.
if (role) {
  defaultState.role = role;
}

/**
 * Login actions
 */
const loginRequest = (state: IAuthState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const loginSuccess = (state: IAuthState, payload: IAuth) => ({
  ...state,
  ...payload,
  is_logged_in: true,
  error: false,
  is_data_requested: false,
});

const loginFailed = (state: IAuthState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Logout actions
 */
const logoutSuccess = (state: IAuthState) => ({
  ...state,
  is_logged_in: false,
  token: '',
});

/**
 * Forgot password actions
 */
const forgotPasswordRequest = (state: IAuthState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const forgotPasswordSuccess = (
  state: IAuthState,
  payload: IForgotPasswordResponse,
) => ({
  ...state,
  forgotPasswordMessage: payload.message,
  error: false,
  is_data_requested: false,
});

const forgotPasswordFailed = (state: IAuthState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Confirm restoring password actions
 */
const confirmForgotPasswordRequest = (state: IAuthState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const confirmForgotPasswordSuccess = (
  state: IAuthState,
  payload: IConfirmForgotPasswordResponse,
) => ({
  ...state,
  error: false,
  passwordUpdatedStatus: payload.status,
  is_data_requested: false,
});

const confirmForgotPasswordFailed = (state: IAuthState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Register a new user actions
 */
const registerRequest = (state: IAuthState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const registerSuccess = (state: IAuthState, payload: IRegisterResponse) => ({
  ...state,
  registerUserMessage: payload.message,
  error: false,
  is_data_requested: false,
});

const registerFailed = (state: IAuthState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Confirm registration actions
 */
const confirmRegistrationRequest = (state: IAuthState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const confirmRegistrationSuccess = (
  state: IAuthState,
  payload: IConfirmRegistrationResponse,
) => ({
  ...state,
  error: false,
  registrationStatus: payload.status,
  is_data_requested: false,
});

const confirmRegistrationFailed = (state: IAuthState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Extend session actions
 */
const extendSessionRequest = (state: IAuthState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const extendSessionSuccess = (state: IAuthState, payload: IAuth) => ({
  ...state,
  ...payload,
  is_logged_in: true,
  error: false,
  is_data_requested: false,
});

const extendSessionFailed = (state: IAuthState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Auth reducer
 */
export const auth = createReducer<IAuthState>({}, defaultState)
  // login actions
  .on(actions.loginRequest, loginRequest)
  .on(actions.loginSuccess, loginSuccess)
  .on(actions.loginFailed, loginFailed)
  // logout actions
  .on(actions.logoutSuccess, logoutSuccess)
  // forgot password actions
  .on(actions.forgotPasswordRequest, forgotPasswordRequest)
  .on(actions.forgotPasswordSuccess, forgotPasswordSuccess)
  .on(actions.forgotPasswordFailed, forgotPasswordFailed)
  // confirm password actions
  .on(actions.confirmForgotPasswordRequest, confirmForgotPasswordRequest)
  .on(actions.confirmForgotPasswordSuccess, confirmForgotPasswordSuccess)
  .on(actions.confirmForgotPasswordFailed, confirmForgotPasswordFailed)
  // register a new user actions
  .on(actions.registerRequest, registerRequest)
  .on(actions.registerSuccess, registerSuccess)
  .on(actions.registerFailed, registerFailed)
  // confirm registration actions
  .on(actions.confirmRegistrationRequest, confirmRegistrationRequest)
  .on(actions.confirmRegistrationSuccess, confirmRegistrationSuccess)
  .on(actions.confirmRegistrationFailed, confirmRegistrationFailed)
  // extend session actions
  .on(actions.extendSessionRequest, extendSessionRequest)
  .on(actions.extendSessionSuccess, extendSessionSuccess)
  .on(actions.extendSessionFailed, extendSessionFailed);

export default auth;
