import React from 'react';
import { Box } from '@mui/material';
import {
  parseISO,
  format,
  composeDate,
  DATE_FORMAT,
  DAY_OF_WEEK,
} from 'src/modules/utils/dateWrapper';

export interface IScorecardReportHeadCell {
  date: string;
}
export const ScorecardReportHeadCell: React.FC<IScorecardReportHeadCell> = ({
  date,
}) => {
  const dateObject = parseISO(date);

  if (!dateObject) {
    return null;
  }

  const dayOfWeek = composeDate(dateObject, format(DAY_OF_WEEK));
  const dateFormatted = composeDate(dateObject, format(DATE_FORMAT));

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>{dayOfWeek}</Box>
        <Box sx={{ whiteSpace: 'nowrap' }}>{dateFormatted}</Box>
      </Box>
    </Box>
  );
};
