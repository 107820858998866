import React, { useMemo } from 'react';
import { get } from 'lodash';
import { FormikHandlers, FormikHelpers, FormikState } from 'formik/dist/types';
import { AnyObject } from '../../modules/types';
import { StandardTextFieldProps } from '@mui/material';
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from '@mui/material/TextField/TextField';
import { ErrorMode } from './types';
import UploadImage from '../UploadImage';

type IFormikImageUpload<Values> = (
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps
) & {
  name: string;
  formik: FormikHelpers<Values> & FormikState<Values> & FormikHandlers;
  errorMode?: ErrorMode;
  image?: string;
  file?: Partial<File> | null;
  isImageFromPropsSourceOfTruth?: boolean;
  // @@@TODO: we might need to do this prop required
  // because if we have multiple FormikImageUpload on one
  // page uploaded file will be duplicated in all components
  inputId?: string;
};

export default function FormikImageUpload<Values extends AnyObject>({
  name,
  formik,
  image,
  errorMode = 'onFormSubmit',
  ...restProps
}: IFormikImageUpload<Values>) {
  const handleChange: any = (file: File) => {
    formik.setFieldError(name, undefined);
    formik.setFieldValue(name, file);
  };

  const option = useMemo(() => get(formik.values, name), [formik.values, name]);
  const error = useMemo(() => get(formik.errors, name), [formik.errors, name]);
  const touched = useMemo(() => get(formik.touched, name), [
    formik.touched,
    name,
  ]);
  const showError = useMemo<boolean>(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  return (
    <UploadImage
      value={option || ''}
      onChange={handleChange}
      error={showError}
      image={image}
      helperText={showError && error}
      {...restProps}
    />
  );
}
