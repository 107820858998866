import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual, isNumber } from 'lodash';
import { Alert } from '@mui/joy';

import {
  useFetchUsersSitesCombobox,
  useKpiCreateOrUpdate,
  useKpiDataGrid,
  useQueryParams,
} from '../../modules/utils';
import { AnyObject, IKpiDataGridRow } from '../../modules/types';
import KpiFilter from './components/KpiFilter';
import { shallowEqual, useSelector } from 'react-redux';
import { getSitesComboboxList } from '../../modules/selectors/site';
import { getCurrentRole } from '../../modules/selectors/auth';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { TableActions, TableTitle, TableToolbar } from '../../components/Table';
import { TableContextProvider } from 'src/components/Table/TableContext';
import { KpiTable } from './components/KpiTable';

const KpiComponent: React.FC = () => {
  const { t } = useTranslation();

  const queryParams = useQueryParams();

  const [filters, setFilters] = React.useState<AnyObject>({
    yearWeek: queryParams?.yearWeek
      ? JSON.parse(queryParams.yearWeek as string)
      : [],
    yearMonth: queryParams?.yearMonth
      ? JSON.parse(queryParams.yearMonth as string)
      : [],
    siteId: queryParams?.siteId ?? '',
    basis: queryParams?.basis ?? null,
  });

  const { siteId, basis, yearWeek, yearMonth } = (filters ?? {}) as any;

  const showTable = Boolean(
    siteId && basis && (!isEmpty(yearMonth) || !isEmpty(yearWeek)),
  );

  const { columns, rows, loading } = useKpiDataGrid(
    siteId,
    basis,
    basis === 'week' ? yearWeek : yearMonth,
  );

  const { sendToServer } = useKpiCreateOrUpdate(siteId, basis);

  const [isLoading, setIsLoading] = React.useState<boolean>();

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const fetchSitesCombobox = useFetchUsersSitesCombobox();
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const site = React.useMemo(() => sites.find((s) => +s.id === +siteId), [
    siteId,
    sites,
  ]);

  const title = React.useMemo(
    () =>
      site ? t('kpi.create_for', { siteName: site.name }) : t('kpi.create'),
    [site, t],
  );

  const handleRowUpdate = async (
    updatedRow: IKpiDataGridRow,
    originRow: IKpiDataGridRow,
  ) => {
    if (!isEqual(updatedRow, originRow)) {
      const dataToSend = Object.entries(updatedRow).reduce(
        (acc, [name, value]) => {
          if (isNumber(value)) {
            acc[name] = value;
          }

          return acc;
        },
      );
      try {
        setIsLoading(true);

        const { id } = await sendToServer(dataToSend as any);

        setIsLoading(false);

        return {
          ...updatedRow,
          serverId: id!,
        };
      } catch {
        setIsLoading(false);

        return {
          ...originRow,
        };
      }
    }

    return updatedRow;
  };

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer fullHeight={false}>
          <TableToolbar>
            <TableTitle>{title}</TableTitle>
            <TableActions>
              <KpiFilter
                filters={filters}
                onSubmit={(data) => {
                  setFilters(data);
                }}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>
        <PageContentChildContainer fullHeight={false}>
          {!showTable && (
            <Alert variant="soft">{t('kpi.select_filters_message')}</Alert>
          )}

          {showTable && (
            <KpiTable
              isLoading={isLoading}
              rows={rows}
              columns={columns}
              handleRowUpdate={handleRowUpdate}
            />
          )}
        </PageContentChildContainer>
      </PageContentWithTopToolbar>
    </>
  );
};

export default () => {
  return (
    <TableContextProvider>
      <KpiComponent />
    </TableContextProvider>
  );
};
