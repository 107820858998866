import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICountResponse,
  IRecruitingDashboardSummaryModel,
  IRecruitingDashboardSummaryState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IRecruitingDashboardSummaryState = {
  siteId: NaN,
  siteName: '',
  openPositions: NaN,
  totalActivePosition: NaN,
  timeDiff: NaN,
  fillPercent: NaN,
  totalLost: NaN,
  totalAdded: NaN,
  list: [],
  count: 0,
};

/**
 * Get list actions
 */
const getRecruitingDashboardSummaryListRequest = (
  state: IRecruitingDashboardSummaryState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRecruitingDashboardSummaryListSuccess = (
  state: IRecruitingDashboardSummaryState,
  payload: IRecruitingDashboardSummaryModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getRecruitingDashboardSummaryListFailed = (
  state: IRecruitingDashboardSummaryState,
) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getRecruitingDashboardSummaryCountRequest = (
  state: IRecruitingDashboardSummaryState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRecruitingDashboardSummaryCountSuccess = (
  state: IRecruitingDashboardSummaryState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getRecruitingDashboardSummaryCountFailed = (
  state: IRecruitingDashboardSummaryState,
) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IRecruitingDashboardSummaryState>();

const exportRecruitingDashboardSummaryRequest = exportRequest;
const exportRecruitingDashboardSummarySuccess = exportSuccess;
const exportRecruitingDashboardSummaryFailed = exportFailed;

/**
 * RecruitingDashboardSummary reducer
 */
export const recruitingDashboardSummary = createReducer<
  IRecruitingDashboardSummaryState
>({}, defaultState)
  // get list actions
  .on(
    actions.getRecruitingDashboardSummaryListRequest,
    getRecruitingDashboardSummaryListRequest,
  )
  .on(
    actions.getRecruitingDashboardSummaryListSuccess,
    getRecruitingDashboardSummaryListSuccess,
  )
  .on(
    actions.getRecruitingDashboardSummaryListFailed,
    getRecruitingDashboardSummaryListFailed,
  )
  // get count actions
  .on(
    actions.getRecruitingDashboardSummaryCountRequest,
    getRecruitingDashboardSummaryCountRequest,
  )
  .on(
    actions.getRecruitingDashboardSummaryCountSuccess,
    getRecruitingDashboardSummaryCountSuccess,
  )
  .on(
    actions.getRecruitingDashboardSummaryCountFailed,
    getRecruitingDashboardSummaryCountFailed,
  )
  // export actions
  .on(
    actions.exportRecruitingDashboardSummaryRequest,
    exportRecruitingDashboardSummaryRequest,
  )
  .on(
    actions.exportRecruitingDashboardSummarySuccess,
    exportRecruitingDashboardSummarySuccess,
  )
  .on(
    actions.exportRecruitingDashboardSummaryFailed,
    exportRecruitingDashboardSummaryFailed,
  );

export default recruitingDashboardSummary;
