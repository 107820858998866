import { JSONSchemaType } from 'ajv';

// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { WikiPageFormType } from '../../types/wiki-setup';

export const createWikiPageScheme: JSONSchemaType<WikiPageFormType> = {
  type: 'object',
  required: ['title', 'body'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    title: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    body: {
      type: 'object',
      required: ['storage'],
      properties: {
        storage: {
          type: 'object',
          required: ['value'],
          properties: {
            value: {
              type: 'string',
            },
          },
        },
      },
    },
    metadata: {
      nullable: true,
      type: 'object',
      required: ['properties'],
      properties: {
        properties: {
          type: 'object',
          required: ['meta'],
          properties: {
            meta: {
              type: 'object',
              required: ['value'],
              properties: {
                value: {
                  type: 'object',
                  properties: {
                    sites: {
                      nullable: true,
                      type: 'array',
                      items: {
                        type: 'number',
                      },
                    },
                    author: {
                      nullable: true,
                      type: 'object',
                    },
                    pageType: {
                      nullable: true,
                      type: 'string',
                    },
                    siteObjects: {
                      nullable: true,
                      type: 'array',
                      items: {
                        required: ['id', 'name'],
                        type: 'object',
                        properties: {
                          id: {
                            type: 'number',
                          },
                          name: {
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
