import Highcharts from 'highcharts';
import theme from 'src/assets/css/theme';

const fontSize = '10px';
const lineColor = '#F1F2F4';

const axisCommonSettings = {
  gridLineWidth: 1,
  lineColor: lineColor,
  gridLineColor: lineColor,
  labels: {
    style: {
      fontSize,
    },
  },
};

interface IInitHighchartsThemeArgs {
  chartsBackground: string;
}

export const initHighchartsTheme = ({
  chartsBackground,
}: IInitHighchartsThemeArgs) => {
  Highcharts.setOptions({
    chart: {
      backgroundColor: chartsBackground,
    },
    xAxis: {
      ...axisCommonSettings,
    },
    yAxis: {
      ...axisCommonSettings,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      symbolRadius: 3,
      itemStyle: {
        fontSize,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        borderWidth: 0,
      },
      column: {
        borderRadius: 0,
        borderWidth: 0,
      },
    },

    colors: theme.chartColorScheme,
  });
};
