import React from 'react';
import { Drawer as JoyDrawer, DrawerProps } from '@mui/joy';

export * from './DrawerHeader';
export * from './DrawerBody';
export * from './DrawerBodyContent';
export * from './DrawerBodyStepper';
export * from './DrawerBodyStepperContent';
export * from './DrawerBodySectionTitle';
export * from './DrawerBodySectionContent';
export * from './DrawerFooter';

interface IDrawerProps extends DrawerProps {
  children: React.ReactNode;
}

export const Drawer = ({ children, ...rest }: IDrawerProps) => {
  return (
    <JoyDrawer
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
          },
        },
        content: {
          sx: { bgcolor: 'base.white' },
        },
      }}
      {...rest}
    >
      {children}
    </JoyDrawer>
  );
};
