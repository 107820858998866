import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deleteApplicantRequest,
  exportApplicantRequest,
  getApplicantCountRequest,
  getApplicantListRequest,
  hireApplicantRequest,
} from '../../modules/actions';
import {
  getApplicantCount,
  getApplicantList,
  getApplicantRefreshKey,
} from '../../modules/selectors/applicant';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useApplicantsDefaultFilters,
  useSearch,
  useFetchOpenPositionsCombobox,
  useFetchStaffingProvidersCombobox,
  useFilter,
  useCreateExportProps,
  useBrowserHistoryFunctions,
  useSupervisorCombobox,
} from '../../modules/utils/hooks';
import { ComboBoxOption } from '../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import { ContentLayout } from '../../components/Layout/ContentLayout';
import { map } from 'lodash';
import { NoIcon, YesIcon } from '../../components/Icons';
import { paths } from '../../config';
import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { getUsersComboboxList } from '../../modules/selectors/user';
import { getOpenPositionsComboboxList } from '../../modules/selectors/openPosition';
import { CloseActionMenuItemType } from '../../components/EnhancedTable/EnhancedTableToolbarMenu';
import Prompt from '../../components/Prompt';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import {
  parse,
  format,
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
} from '../../modules/utils/dateWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const ApplicantsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const defaultFilter = useApplicantsDefaultFilters();

  const SHORT_COMMENT_LENGTH = 50;

  const list = useSelector(getApplicantList, shallowEqual);
  const exportProps = useCreateExportProps(exportApplicantRequest);
  const [modal, setModal] = useState<JSX.Element | null>(null);

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(list, (item) => ({
        ...item,
        hire: item.hire ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        I9: item.I9 ? <YesIcon color="primary" /> : <NoIcon color="primary" />,
        bgCheck: item.bgCheck ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        dateInterview: item.dateInterview
          ? composeDate(
              item.dateInterview,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            )
          : '',
        dateStart: item.dateStart
          ? composeDate(
              item.dateStart,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            )
          : '',
        interviewNotes:
          item.interviewNotes &&
          item.interviewNotes?.length > SHORT_COMMENT_LENGTH ? (
            <Tooltip title={item.interviewNotes}>
              <span>
                {item.interviewNotes.substring(0, SHORT_COMMENT_LENGTH) + '...'}
              </span>
            </Tooltip>
          ) : (
            item.interviewNotes
          ),
        openPosition: {
          position: {
            name: t('applicant.openPositionTitle', {
              position: item.openPosition?.position?.name,
              numberNeeded: item.openPosition?.numberNeeded,
            }),
          },
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  const fetchOpenPositionCombobox = useFetchOpenPositionsCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();

  // make request to fetch comboboxes
  useEffect(() => {
    fetchSupervisorsCombobox();
    fetchOpenPositionCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch sites list from store
  const supervisors = useSelector(getUsersComboboxList, shallowEqual);
  const openPositions = useSelector(getOpenPositionsComboboxList);
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  const count = useSelector(getApplicantCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'firstName', disablePadding: false, label: t('applicant.firstName') },
    { id: 'lastName', disablePadding: false, label: t('applicant.lastName') },
    {
      id: 'openPosition.position.name',
      disablePadding: false,
      label: t('applicant.openPositionId'),
    },
    { id: 'payRate', disablePadding: false, label: t('applicant.payRate') },
    {
      id: 'dateInterview',
      disablePadding: false,
      label: t('applicant.dateInterview'),
    },
    { id: 'hire', disablePadding: false, label: t('applicant.hire') },
    { id: 'dateStart', disablePadding: false, label: t('applicant.dateStart') },
    { id: 'badge', disablePadding: false, label: t('applicant.badge') },
    {
      id: 'supervisor.firstName',
      disablePadding: false,
      label: t('applicant.supervisorFirstName'),
    },
    {
      id: 'supervisor.lastName',
      disablePadding: false,
      label: t('applicant.supervisorLastName'),
    },
    { id: 'site.name', disablePadding: false, label: t('applicant.siteId') },
    { id: 'I9', disablePadding: false, label: t('applicant.I9') },
    { id: 'bgCheck', disablePadding: false, label: t('applicant.bgCheck') },
    {
      id: 'interviewNotes',
      disablePadding: false,
      label: t('applicant.interviewNotes'),
    },
    {
      id: 'phonenumber',
      disablePadding: false,
      label: t('applicant.phonenumber'),
    },
    { id: 'address', disablePadding: false, label: t('applicant.address') },
    {
      id: 'staffing.staffingProvider',
      disablePadding: false,
      label: t('applicant.staffingId'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'firstName',
      label: t('applicant.firstName'),
      operator: 'like',
    },
    {
      name: 'lastName',
      label: t('applicant.lastName'),
      operator: 'like',
    },
    {
      name: 'openPositionId',
      label: t('applicant.openPositionId'),
      operator: 'eq',
      type: 'combobox',
      options: openPositions as ComboBoxOption[],
    },
    {
      name: 'payRate',
      label: t('applicant.payRate'),
      operator: 'like',
    },
    {
      name: 'dateInterview',
      label: t('applicant.dateInterview'),
      operator: 'gte',
      type: 'date',
    },
    {
      name: 'hire',
      label: t('applicant.hire'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'dateStart',
      label: t('applicant.dateStart'),
      operator: 'gte',
      type: 'date',
    },
    {
      name: 'badge',
      label: t('applicant.badge'),
      operator: 'like',
    },
    {
      name: 'supervisorId',
      label: t('applicant.supervisorId'),
      operator: 'eq',
      type: 'combobox',
      options: supervisors as ComboBoxOption[],
    },
    {
      name: 'siteId',
      label: t('applicant.siteId'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'I9',
      label: t('applicant.I9'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'bgCheck',
      label: t('applicant.bgCheck'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'interviewNotes',
      label: t('applicant.interviewNotes'),
      operator: 'like',
    },
    {
      name: 'phonenumber',
      label: t('applicant.phonenumber'),
      operator: 'like',
    },
    {
      name: 'address',
      label: t('applicant.address'),
      operator: 'like',
    },
    {
      name: 'staffingId',
      label: t('applicant.staffingId'),
      operator: 'eq',
      type: 'combobox',
      options: staffingProviders as ComboBoxOption[],
    },
  ];

  // make request to fetch applicants when component is mounted
  useEffect(() => {
    dispatcher(getApplicantListRequest(filterList));
    // get total count
    dispatcher(getApplicantCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getApplicantListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getApplicantCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteApplicantRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({
      pathname: paths.RECRUITING_DASHBOARD_UPDATE_APPLICANT,
      search,
    });
  };

  const renderAdditionalActions = (
    ids: IdsArray,
    closeCallback: CloseActionMenuItemType,
  ): React.ReactElement<MenuItemProps>[] => {
    // handle view
    const handleView = () => {
      const search = composeSearch({ ids });
      pushToHistory({
        pathname: paths.RECRUITING_DASHBOARD_VIEW_APPLICANTS,
        search,
      });
      closeCallback();
    };

    // handle hire
    const handleHire = () => {
      setModal(
        <Prompt
          message={
            <>
              <p>{t('applicant.confirm_hiring')}</p>
            </>
          }
          onOk={() => {
            setModal(null);
            dispatcher(hireApplicantRequest(ids));
          }}
          onCancel={() => setModal(null)}
        />,
      );
      closeCallback();
    };

    return [
      <MenuItem key="applicant-menu-item-view" onClick={handleView}>
        <ListItemIcon>
          <FontAwesomeIcon color="black" icon={faEye} />
        </ListItemIcon>
        <Typography variant="inherit" color="primary">
          {t('applicant.view_details')}
        </Typography>
      </MenuItem>,
      <MenuItem key="applicant-menu-item-hire" onClick={handleHire}>
        <ListItemIcon>
          <FontAwesomeIcon color="black" icon={faThumbsUp} />
        </ListItemIcon>
        <Typography variant="inherit" color="primary">
          {t('applicant.hire_action')}
        </Typography>
      </MenuItem>,
    ].filter((elm) => !!elm) as React.ReactElement<MenuItemProps>[];
  };

  return (
    <ContentLayout>
      {modal}
      <EnhancedTable
        data={computedList}
        count={count}
        selectIndex="id"
        tableName={t('applicant.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={handleDeletion}
        onUpdate={handleUpdating}
        include={defaultFilter.include}
        exportProps={exportProps}
        renderActions={renderAdditionalActions}
      />
    </ContentLayout>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function ApplicantsListRefreshable() {
  return <ApplicantsList key={useSelector(getApplicantRefreshKey)} />;
}
