import React from 'react';
import { Api } from 'src/modules/utils';
import { useTranslation } from 'react-i18next';
import { IIssueTrackerIssuePriority } from 'src/modules/types';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';

interface IPriorityDropdownProps extends Pick<IComboboxProps, 'onChange'> {
  value: string;
  errorMessage?: string;
}

export const PriorityDropdown: React.FC<IPriorityDropdownProps> = ({
  value,
  onChange,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const [priorities, setPriorities] = React.useState<
    Array<IIssueTrackerIssuePriority>
  >([]);

  React.useEffect(() => {
    initializePriorities();

    async function initializePriorities() {
      const response = await Api.IssueTracker.priorities();

      setPriorities(response.values);
    }
  }, []);

  return (
    <ComboBox
      required
      placeholder={t('issue_tracker.fields.priority')}
      options={(priorities as unknown) as ComboBoxOption[]}
      value={
        ((priorities.find(
          (priority) => priority.id === value,
        ) as unknown) as ComboBoxOption) || null
      }
      onChange={onChange}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
    />
  );
};
