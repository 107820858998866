import { SchemaValidateFunction } from 'ajv';
import { get, isFunction } from 'lodash';

import * as dateWrapper from '../../../dateWrapper';

export const compareDatetimeWithTZ = (
  ...param: Parameters<SchemaValidateFunction>
): boolean => {
  const [schema, data, , dataCxt] = param;
  const parentData = get(dataCxt, ['parentData']);

  const srcDatetimeStr = data;
  const descDatetimeStr = get(parentData, [schema.descFieldName]);

  const srcDatetime = dateWrapper.composeDate(
    srcDatetimeStr,
    dateWrapper.parseISO,
  );

  const descDatetime = dateWrapper.composeDate(
    descDatetimeStr,
    dateWrapper.parseISO,
  );
  // reset seconds
  srcDatetime.setSeconds(0, 0);
  descDatetime?.setSeconds(0, 0);

  return !(
    srcDatetimeStr &&
    descDatetimeStr &&
    isFunction(dateWrapper[schema.action]) &&
    !dateWrapper[schema.action](srcDatetime, descDatetime)
  );
};
