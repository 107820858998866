import { ISagaActionBind } from '../../types';
import { IProductionWeeklyUphBySiteModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionWeeklyUphBySiteListRequest,
    saga: createListSaga<IProductionWeeklyUphBySiteModel>({
      apiCall: Api.ProductionWeeklyUphBySite.list,
      actionSuccess: actions.getProductionWeeklyUphBySiteListSuccess,
      actionFailed: actions.getProductionWeeklyUphBySiteListFailed,
    }),
  },
  {
    action: actions.getProductionWeeklyUphBySiteCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionWeeklyUphBySite.count,
      actionSuccess: actions.getProductionWeeklyUphBySiteCountSuccess,
      actionFailed: actions.getProductionWeeklyUphBySiteCountFailed,
    }),
  },
  {
    action: actions.exportProductionWeeklyUphBySiteRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionWeeklyUphBySite.export,
      actionSuccess: actions.exportProductionWeeklyUphBySiteSuccess,
      actionFailed: actions.exportProductionWeeklyUphBySiteFailed,
    }),
  },
];
