import React from 'react';
import { Box, Tooltip, useTheme } from '@mui/joy';
import { AlertTriangleSvg, CheckCircleSvg } from 'src/components/svgIcons';

interface IPolicesStatusAlert {
  hasPolices: boolean;
}

export const PolicesStatusAlert = ({ hasPolices }: IPolicesStatusAlert) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={hasPolices ? 'At least one policy is set' : 'Missing policies'}
    >
      <Box sx={{ display: 'flex', pr: 1 }}>
        {!hasPolices && (
          <AlertTriangleSvg
            fill={theme.palette.colors.icons.featured_icon_light_fg_warning}
          />
        )}

        {hasPolices && (
          <CheckCircleSvg
            fill={theme.palette.colors.icons.featured_icon_light_fg_success}
          />
        )}
      </Box>
    </Tooltip>
  );
};
