/**
 * Default theme settings
 */
// @@ TODO: get rid of this file
import { darken, lighten } from '@mui/material';

/**
 * Color scheme
 */

// theme colors
const colors = {
  color_0: '#1F2428',
  color_1: '#242939',
  color_2: '#494E60',
  color_3: '#72778A',
  color_4: '#9DA2B6',
  color_5: '#CACFE4',
  color_6: '#F6F9FF',
  color_7: '#f5f8fe',
  color_8: '#FFF7D6',
  color_9: '#FFFFFF',
};

// breakpoints
const sizes = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '960px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const chartBaseColors = [
  '#116ab8',
  '#6f68c2',
  '#ac61bd',
  '#dd58aa',
  '#ff578b',
  '#ff6767',
  '#ff843e',
  '#ffa600',
];

const colorChangeCoefficient = 0.2;

export const theme = {
  // list of colors
  chartColorScheme: [
    ...chartBaseColors,
    ...chartBaseColors.map((color) => darken(color, colorChangeCoefficient)),
    ...chartBaseColors.map((color) => lighten(color, colorChangeCoefficient)),
  ],

  // here will be component colors
  backgroundColor: colors.color_9,

  palette: {
    primary: {
      main: colors.color_0,
    },
    secondary: {
      main: colors.color_4,
    },
  },

  // font size
  htmlFontSize: 16,

  // media sizes
  media: {
    mobile: `(min-width: ${sizes.mobile})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    tabletL: `(min-width: ${sizes.tabletL})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  },
};

export default theme;
