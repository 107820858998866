import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { map, get } from 'lodash';

import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { useFetchShiftsCombobox } from 'src/modules/utils';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';

interface IShiftsComboboxProps extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  fieldAsId?: 'id' | 'name';
}

const ShiftsComboBox: React.FC<IShiftsComboboxProps> = ({
  fieldAsId = 'id',
  ...props
}) => {
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  useEffect(() => {
    fetchShiftsCombobox();
  }, [fetchShiftsCombobox]);

  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);

  const options = (useMemo(
    () =>
      map(shifts, (shift) => ({
        id: shift[fieldAsId],
        name: `${shift.name} (${get(shift, 'site.name')})`,
      })),
    [fieldAsId, shifts],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default ShiftsComboBox;
