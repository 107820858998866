import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  IAdditionalFeeCategoryModel,
  IdsArray,
  IFilterData,
  IStoreState,
  IWhere,
} from '../../../../../../modules/types';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { sendForUpdateAdditionalFeeCategoriesRequest } from '../../../../../../modules/actions';
import { Sheet } from '@mui/joy';
import { updateAdditionalFeeCategoryScheme } from '../../../../../../modules/schemes';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import { getAdditionalFeeCategoriesByIds } from '../../../../../../modules/selectors/additionalFee.selector';
import { omit } from 'lodash';

interface IUpdateAdditionalFeeCategoryProps {
  isOpen: boolean;
  onClose: () => void;
  idsToUpdate: IdsArray;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const UpdateAdditionalFeeCategory = ({
  isOpen,
  onClose,
  idsToUpdate,
  filterList,
  filterCount,
}: IUpdateAdditionalFeeCategoryProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(updateAdditionalFeeCategoryScheme);

  const additionalFeeCategories = useSelector(
    (state) =>
      getAdditionalFeeCategoriesByIds(state as IStoreState)(idsToUpdate),
    shallowEqual,
  );

  const formik = useFormikInUpdateForm({
    initialValues: additionalFeeCategories,
    validate,
    enableReinitialize: true,
    onSubmit: (data: IAdditionalFeeCategoryModel[]) => {
      dispatch(
        sendForUpdateAdditionalFeeCategoriesRequest({
          data: data.map((item) => omit(item, 'site')),
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('additional-fee.update_fee_category')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {additionalFeeCategories.map((additionalFeeCategory, index) => (
            <DrawerBodySectionContent key={additionalFeeCategory.id}>
              <DrawerBodySectionTitle>
                {`${t('additional-fee.update_fee_category')}: ${
                  additionalFeeCategory.name
                }`}
              </DrawerBodySectionTitle>
              <Sheet sx={{ bgcolor: 'inherit' }}>
                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.name`}
                    variant="outlined"
                    label={t('additional-fee.name')}
                    name={`${index}.name`}
                    formik={formik}
                    errorMode="onFieldChange"
                    fullWidth
                    required
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.description`}
                    variant="outlined"
                    label={t('additional-fee.description')}
                    name={`${index}.description`}
                    formik={formik}
                    errorMode="onFieldChange"
                    fullWidth
                  />
                </FormFieldContainer>
              </Sheet>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default UpdateAdditionalFeeCategory;
