import React from 'react';
import { Sheet, SheetProps } from '@mui/joy';

interface IPageContentChildrenContainerProps extends SheetProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}

export const PageContentScrollableChildContainer = ({
  children,
  sx,
  ...rest
}: IPageContentChildrenContainerProps) => {
  return (
    <Sheet
      sx={{
        height: '100%',
        width: '100%',
        pl: 2,
        pr: 1,
        flexGrow: 1,
        overflow: 'auto',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Sheet>
  );
};
