import React from 'react';
import { Box, Button, ButtonProps } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/joy/styles/types';

export interface ITableActionsExportButtonProps extends ButtonProps {
  containerSx?: SxProps;
}

export const TableActionsExportButton = ({
  containerSx,
  ...buttonProps
}: ITableActionsExportButtonProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...containerSx }}>
      <Button color="neutral" variant="outlined" {...buttonProps}>
        {t('common.export')}
      </Button>
    </Box>
  );
};
