import React from 'react';
import Accordion from '../Accordion';

interface IProps {
  cardTitle?: string;
  cardTitleContent?: React.ReactElement;
  children: any;
  defaultExpanded?: boolean;
}

const CreateForm = (props: IProps) => <Accordion {...props} />;
export default CreateForm;
