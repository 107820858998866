import React from 'react';
import { IOpenPositionModel } from '../../../modules/types';
import { Chip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import AccordionView, {
  AccordionViewHeader,
} from '../../../components/AccordionView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  openPosition: IOpenPositionModel;
}

const ChipStyled = styled(Chip)(() => ({
  marginRight: 15,
}));

const OpenPositionView = (props: IProps) => {
  const { openPosition } = props;

  const { t } = useTranslation();

  const skills = map(
    openPosition.openPositionSkills,
    (openPositionSkill, index) => (
      <ChipStyled
        key={index}
        label={openPositionSkill.skill?.name}
        onDelete={() => undefined}
        deleteIcon={<FontAwesomeIcon icon={faCheck} />}
      />
    ),
  );

  const headers: AccordionViewHeader[] = [
    {
      id: 'position.name',
      label: t('open_position.positionId'),
    },
    {
      id: 'currentDuration',
      label: t('open_position.currentDuration'),
    },
    {
      id: 'numberNeeded',
      label: t('open_position.numberNeeded'),
    },
    {
      id: 'shift.name',
      label: t('open_position.shiftId'),
    },
    {
      id: 'department.name',
      label: t('open_position.departmentId'),
    },
    {
      id: 'requisitionNumber',
      label: t('open_position.requisitionNumber'),
    },
    {
      id: 'dateStart',
      label: t('open_position.dateStart'),
    },
    {
      id: 'description',
      label: t('open_position.description'),
    },
    {
      id: 'site.name',
      label: t('open_position.siteId'),
    },
    {
      id: 'openPositionSkills[0].skill.name',
      label: t('open_position.skills'),
      renderValue: () => <>{skills}</>,
    },
  ];

  return (
    <AccordionView
      title={t('open_position.details_title', { id: openPosition.id })}
      model={openPosition}
      headers={headers}
    />
  );
};

export default OpenPositionView;
