import { createAction } from 'redux-act';
import {
  IAuth,
  IConfirmForgotPasswordRequest,
  IConfirmForgotPasswordResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  ILoginRequest,
  IRegisterRequest,
  IRegisterResponse,
} from '../../types';

// Login actions
export const loginRequest = createAction<ILoginRequest>(
  'Make request to authenticate a user',
);
export const loginSuccess = createAction<IAuth>(
  'User has been authenticated successfully',
);
export const loginFailed = createAction('Cannot authenticate a user');

// Logging actions
export const logoutRequest = createAction('Make a request to logout a user');
export const logoutSuccess = createAction('User was logged out successfully');

// Forgot password actions
export const forgotPasswordRequest = createAction<IForgotPasswordRequest>(
  'Make request to restore user password',
);
export const forgotPasswordSuccess = createAction<IForgotPasswordResponse>(
  'En email has been sent to a user to restore password',
);
export const forgotPasswordFailed = createAction<string>(
  'Cannot send an email to restore a password',
);

// Confirm restoring password actions
export const confirmForgotPasswordRequest = createAction<
  IConfirmForgotPasswordRequest
>('Make request to complete restoring user password');
export const confirmForgotPasswordSuccess = createAction<
  IConfirmForgotPasswordResponse
>('Password has been updated successfully');
export const confirmForgotPasswordFailed = createAction(
  'Cannot update a password',
);

// Register a new user actions
export const registerRequest = createAction<IRegisterRequest>(
  'Make a request to register a new user',
);
export const registerSuccess = createAction<IRegisterResponse>(
  'User has been created successfully',
);
export const registerFailed = createAction('Cannot create a user');

// Confirm password actions
export const confirmRegistrationRequest = createAction<
  IConfirmRegistrationRequest
>('Make request to complete registration');
export const confirmRegistrationSuccess = createAction<
  IConfirmRegistrationResponse
>('Registration has been completed successfully');
export const confirmRegistrationFailed = createAction(
  'Cannot complete registration',
);

// Extend current session actions
export const extendSessionRequest = createAction(
  'Make request to extend user session',
);
export const extendSessionSuccess = createAction<IAuth>(
  'Current session has been extended successfully',
);
export const extendSessionFailed = createAction<string>(
  'Cannot extend current session',
);
