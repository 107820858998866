import React, { useState } from 'react';
import { Typography } from '@mui/joy';
import { ArrowDownSvg } from '../../svgIcons';
import { Avatar, Box, useTheme, Button } from '@mui/joy';
import { getFileExtensionFromFileName } from 'src/modules/utils/helpers/common';
import { FilesPreviewCarouseImageWithFallback } from './FilesPreviewCarouseImageWithFallback';

const maxPreviewNumber = 5;

interface IFilesPreviewCarouselProps {
  filesUrls: string[];
}

export const FilesPreviewCarousel = ({
  filesUrls,
}: IFilesPreviewCarouselProps) => {
  const theme = useTheme();

  const [activePreview, setActivePreview] = React.useState(filesUrls[0]);
  const [startIndex, setStartIndex] = useState(0);
  const visibleCount =
    filesUrls.length > maxPreviewNumber ? maxPreviewNumber : filesUrls.length;

  const handlePrevClick = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setStartIndex((prevIndex) =>
      Math.min(prevIndex + 1, filesUrls.length - visibleCount),
    );
  };

  const showButtons = filesUrls.length > maxPreviewNumber;

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: '#ffffffab',
        width: '100%',
        height: '100%',
        borderRadius: 16,
        flexDirection: 'column',
        p: 1,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          mb: 2,
          p: 1,
        }}
      >
        {showButtons && (
          <Button
            variant="outlined"
            onClick={handlePrevClick}
            disabled={startIndex === 0}
            sx={{ mr: '16px' }}
          >
            <Box sx={{ display: 'flex', transform: 'rotate(90deg)' }}>
              <ArrowDownSvg />
            </Box>
          </Button>
        )}
        <Box
          sx={{
            display: 'flex',
            overflow: 'hidden',
            width: `${visibleCount * 98}px`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transition: 'transform 0.5s ease-in-out',
              transform: `translateX(-${startIndex * 98}px)`,
            }}
          >
            {filesUrls.map((url, index) => (
              <Box
                onClick={() => setActivePreview(url)}
                key={index}
                sx={{
                  cursor: 'pointer',
                  height: 'auto',
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor:
                    activePreview === url
                      ? theme.palette.brand[400]
                      : 'transparent',
                }}
              >
                <Avatar
                  src={url}
                  slotProps={{
                    root: {
                      sx: {
                        width: 80,
                        height: 'auto',
                        minHeight: 40,
                        maxHeight: 80,
                        borderRadius: 8,
                        bgcolor: 'colors.background.bg_secondary',
                      },
                    },
                  }}
                >
                  <Typography
                    level="text_xs"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    {getFileExtensionFromFileName(url)}
                  </Typography>
                </Avatar>
              </Box>
            ))}
          </Box>
        </Box>
        {showButtons && (
          <Button
            variant="outlined"
            onClick={handleNextClick}
            disabled={startIndex + visibleCount >= filesUrls.length}
            sx={{ ml: '16px' }}
          >
            <Box
              sx={{
                display: 'flex',
                transform: 'rotate(-90deg)',
              }}
            >
              <ArrowDownSvg />
            </Box>
          </Button>
        )}
      </Box>
      <Box
        sx={{
          alignSelf: 'center',
          p: 1,
        }}
      >
        <FilesPreviewCarouseImageWithFallback src={activePreview} />
      </Box>
    </Box>
  );
};
