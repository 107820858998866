import React from 'react';
import { useDispatch } from 'react-redux';
import { createTerminationReasonRequest } from '../../modules/actions';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { useBrowserHistoryFunctions, useValidate } from '../../modules/utils';
import { useFormik } from 'formik';
import { createTerminationReasonScheme } from '../../modules/schemes/termination-reason';
import FormikCheckbox from '../../components/Formik/FormikCheckbox';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const initialValues = {
  name: '',
  siteId: NaN,
  voluntary: false,
};

const TerminationReasonsCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const validate = useValidate(createTerminationReasonScheme);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createTerminationReasonRequest({ data, navigate }));
    },
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('terminationReasons.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id="name"
              label={t('terminationReasons.name')}
              name="name"
              autoComplete="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              required
              id="voluntary"
              label={t('terminationReasons.voluntary')}
              name="voluntary"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('terminationReasons.site')}
              placeholder={t('terminationReasons.site')}
            />
          </FormFieldContainer>
          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default TerminationReasonsCreate;
