import {
  IdsArray,
  IStoreState,
  IUpdatePositionRequest,
  PositionComboboxOption,
} from '../types';
import { createSelector } from 'reselect';
import { map, memoize, omit, reduce } from 'lodash';
import { filterListBySiteId } from '../utils/helpers/common';

export const getPositionRefreshKey = (state: IStoreState) =>
  state.position.refreshKey;

export const getPositionData = (state: IStoreState) => state.position;

/**
 * Get server error
 * @param position - State position
 */
export const getServerError = ({ position }: IStoreState) => position.error;

/**
 * Get position list
 * @param position - State position
 */
export const getPositionList = ({ position }: IStoreState) => position.list;

/**
 * Get positions by array of ids
 * @param position - State position
 */
export const getPositionsByIds = createSelector(getPositionList, (positions) =>
  memoize((ids: IdsArray) => {
    const initial: IUpdatePositionRequest[] = [];
    return reduce(
      positions,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`) || ids.includes(cur.id)) {
          // omit columns we should not request with
          const bomData = omit(cur, ['site']);
          acc.push(bomData);
        }
        return acc;
      },
      initial,
    );
  }),
);

/**
 * Get position count
 * @param position - State position
 */
export const getPositionCount = ({ position }: IStoreState) => position.count;

/**
 * Get combobox list
 */
export const getPositionsComboboxList = ({ position }: IStoreState) =>
  position.comboboxList as PositionComboboxOption[];

/**
 * Get combobox list by id
 */
export const getPositionsComboboxListById = ({ position }: IStoreState) =>
  map(position.comboboxList, (positionItem) => ({
    id: positionItem.id,
    name: positionItem.name,
    siteId: positionItem.siteId,
  }));

export const getPositionsComboboxListByIdWithNameAsId = ({
  position,
}: IStoreState) =>
  map(position.comboboxList, (positionItem) => ({
    id: positionItem['name'] as any,
    name: positionItem.name,
    siteId: positionItem.siteId,
  }));

/**
 * Get combobox options by siteId
 */
export const getPositionsComboboxListBySiteId = createSelector(
  getPositionsComboboxList,
  (positions) =>
    memoize((siteId: number) => filterListBySiteId(positions, siteId)),
);
