import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from '../../../config';
import { TabsNgroup } from '../../../components/TabsNgroup';
import AdditionalFeeList from './panels/AdditionalFee/AdditionalFeeList';
import AdditionalFeeCategoryList from './panels/AdditionalFeeCategory/AdditionalFeeCategoryList';
import CostPlusSettingsListRefreshable from './panels/CostPlusSettings/CostPlusSettingsList';
import { SiteBillingDetails } from './panels/InvoiceBillingDetails/SiteBillingDetails';
import { NgroupBillingDetails } from './panels/InvoiceBillingDetails/NgroupBillingDetails';
import OtherCostsListRefreshable from './panels/OtherCosts/OtherCostsList';

export const InvoiceSettings = () => {
  const { t } = useTranslation();

  const hasUserAccessToHourlySettingsTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_SETTINGS_HOURLY_SETTINGS,
  );
  const hasUserAccessToAdditionalFeeTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_SETTINGS_ADDITIONAL_FEE,
  );
  const hasUserAccessToAdditionalFeeCategoryTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_SETTINGS_ADDITIONAL_FEE_CATEGORY,
  );
  const hasUserAccessToSiteBillingDetailsTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_SETTINGS_SITE_BILLING_DETAILS,
  );
  const hasUserAccessToNgroupBillingDetailsTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_SETTINGS_NGROUP_BILLING_DETAILS,
  );
  const hasUserAccessToOtherCostsTab = useHasUserAccessToViewTab(
    tabsPaths.INVOICE_SETTINGS_OTHER_COSTS,
  );

  const invoicesTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.cost_plus_settings.main_item'),
          hasUserAccess: hasUserAccessToHourlySettingsTab,
          component: <CostPlusSettingsListRefreshable />,
        },
        {
          title: t('main_menu.other_costs.header_item'),
          hasUserAccess: hasUserAccessToOtherCostsTab,
          component: <OtherCostsListRefreshable />,
        },
        {
          title: t('main_menu.additional_fee_category.header_item'),
          hasUserAccess: hasUserAccessToAdditionalFeeCategoryTab,
          component: <AdditionalFeeCategoryList />,
        },
        {
          title: t('main_menu.additional_fee.header_item'),
          hasUserAccess: hasUserAccessToAdditionalFeeTab,
          component: <AdditionalFeeList />,
        },
        {
          title: t('main_menu.site_billing_details.main_item'),
          hasUserAccess: hasUserAccessToSiteBillingDetailsTab,
          component: <SiteBillingDetails />,
        },
        {
          title: t('main_menu.ngroup_billing_details.main_item'),
          hasUserAccess: hasUserAccessToNgroupBillingDetailsTab,
          component: <NgroupBillingDetails />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      t,
      hasUserAccessToOtherCostsTab,
      hasUserAccessToAdditionalFeeTab,
      hasUserAccessToHourlySettingsTab,
      hasUserAccessToSiteBillingDetailsTab,
      hasUserAccessToNgroupBillingDetailsTab,
      hasUserAccessToAdditionalFeeCategoryTab,
    ],
  );

  return <TabsNgroup config={invoicesTabsConfig} />;
};
