import React, { useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPositionsByIds } from '../../modules/selectors/position';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useValidate,
} from '../../modules/utils/hooks';
import {
  IdsArray,
  IStoreState,
  IUpdatePositionRequest,
} from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchPositionsByIds } from '../../modules/utils/hooks';
import { updatePositionsRequest } from '../../modules/actions/position';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import { updatePositionsScheme } from '../../modules/schemes/positions';
import { FormActions } from '../../components/Form/FormActions';
import FormikTextField from '../../components/Formik/FormikTextField';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IPositionUpdateProps {
  positions: IUpdatePositionRequest[];
  onSubmit: (positions: IUpdatePositionRequest[]) => void;
}

const PositionUpdateForm = ({ positions, onSubmit }: IPositionUpdateProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updatePositionsScheme);

  const formik = useFormikInUpdateForm({
    initialValues: positions,
    validate,
    onSubmit,
  });

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
      formStyle={{ minWidth: 400 }}
    >
      {map(formik.values, (position, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('positions.update_title', position)}
          key={position.id}
        >
          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth={true}
              id={`${index}.name`}
              label={t('positions.name')}
              name={`${index}.name`}
              autoComplete="name"
              errorMode="onFieldChange"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id={`${index}.siteId`}
              required={true}
              formik={formik}
              errorMode="onFieldChange"
              label={t('positions.site')}
              placeholder={t('positions.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id={`${index}.desiredEmployeesNumber`}
              name={`${index}.desiredEmployeesNumber`}
              label={t('departments.desiredEmployeesNumber')}
              autoComplete="desiredEmployeesNumber"
              formik={formik}
              type="number"
              errorMode="onFieldChange"
            />
          </FormFieldContainer>
        </CreateForm>
      ))}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const PositionsUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchPositions = useFetchPositionsByIds(ids);

  // fetch positions list from store
  const positions = useSelector(
    (state) => getPositionsByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch positions from the server if we don't have them in the store
  useEffect(() => {
    fetchPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated positions
   */
  const handleFormSubmit = (data: IUpdatePositionRequest[]) => {
    dispatcher(updatePositionsRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <PositionUpdateForm positions={positions} onSubmit={handleFormSubmit} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default PositionsUpdate;
