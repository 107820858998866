import { createAction } from 'redux-act';
import {
  ICreateApplicant,
  IdsArray,
  IFilter,
  IApplicantModel,
  IUpdateApplicant,
  ICountResponse,
  PayloadWithNavigateFunc,
} from '../../types';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Creation actions
export const createApplicantRequest = createAction<
  PayloadWithNavigateFunc<ICreateApplicant>
>('Make request to create a new applicant');
export const createApplicantSuccess = createAction<IApplicantModel>(
  'New applicant has been created successfully',
);
export const createApplicantFailed = createAction(
  'Cannot create a new applicant',
);

// delete actions
export const deleteApplicantRequest = createAction<IdsArray>(
  'Make request to delete applicants',
);
export const deleteApplicantSuccess = createAction(
  'Open position has been deleted successfully',
);
export const deleteApplicantFailed = createAction('Cannot delete applicants');

// Updating actions
export const updateApplicantsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateApplicant[]>
>('Make request to update applicants');
export const updateApplicantSuccess = createAction(
  'Open position has been updated successfully',
);
export const updateApplicantsFailed = createAction('Cannot update applicants');

// Fetch applicants actions
export const getApplicantListRequest = createAction<IFilter>(
  'Make request to fetch applicant list',
);
export const getApplicantListSuccess = createAction<IApplicantModel[]>(
  'Applicants list has been fetched successfully',
);
export const getApplicantListFailed = createAction(
  'Cannot fetch applicant list',
);

// Fetch combobox list actions
export const getApplicantComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of applicants',
);
export const getApplicantComboboxListSuccess = createAction<
  Partial<IApplicantModel>[]
>('Combobox list of applicants has been fetched successfully');
export const getApplicantComboboxListFailed = createAction(
  'Cannot fetch combobox applicants list',
);

export const getApplicantCountRequest = createAction<IFilter>(
  'Make request to get applicant count',
);
export const getApplicantCountSuccess = createAction<ICountResponse>(
  'Applicants count has been fetched successfully',
);
export const getApplicantCountFailed = createAction(
  'Cannot get applicant count',
);

// Hire applicant actions
export const hireApplicantRequest = createAction<IdsArray>(
  'Make request to hire applicant',
);
export const hireApplicantSuccess = createAction(
  'Applicants are hired successfully',
);
export const hireApplicantFailed = createAction('Cannot hire applicants');

// Flush actions
export const flushApplicantState = createAction(
  'Flush applicant state data in store',
);

export const {
  exportReportRequest: exportApplicantRequest,
  exportReportSuccess: exportApplicantSuccess,
  exportReportFailed: exportApplicantFailed,
} = new ExportActionCreator('Applicant').takeExportActions();
