import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  ITurnoverState,
  ListWithSummary,
  ITurnoverAddedModel,
  ITurnoverLostModel,
  ITurnoverActiveModel,
  ITurnoverTerminatedModel,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: ITurnoverState = {
  // base fields
  staffingProvider: '',
  site: '',
  date: '',
  year: NaN,
  week: NaN,
  weekStart: '',
  weekEnd: '',
  // turnover added list data
  addedList: [],
  addedSummary: [],
  countAdded: 0,
  addedCount: 0,
  // turnover lost list data
  lostList: [],
  lostSummary: [],
  countLost: 0,
  lostCount: 0,
  // turnover active list data
  activeList: [],
  activeSummary: [],
  activeCount: 0,
  countActive: 0,
  total: 0,
  // turnover terminated list data
  terminatedList: [],
  terminatedSummary: [],
  terminatedCount: 0,
};

/**
 * Common actions for all turnover types
 */
const getTurnoverListRequest = (state: ITurnoverState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getTurnoverListFailed = (state: ITurnoverState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getTurnoverCountRequest = (state: ITurnoverState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getTurnoverCountFailed = (state: ITurnoverState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get added list actions
 */
const getTurnoverAddedListSuccess = (
  state: ITurnoverState,
  payload: ListWithSummary<ITurnoverAddedModel>,
) => ({
  ...state,
  addedList: payload.items,
  addedSummary: payload.summary,
  error: false,
  is_data_requested: false,
});

/**
 * Get added count actions
 */
const getTurnoverAddedCountSuccess = (
  state: ITurnoverState,
  payload: ICountResponse,
) => ({
  ...state,
  addedCount: payload.count,
  error: false,
  is_data_requested: false,
});

/**
 * Get lost list actions
 */
const getTurnoverLostListSuccess = (
  state: ITurnoverState,
  payload: ListWithSummary<ITurnoverLostModel>,
) => ({
  ...state,
  lostList: payload.items,
  lostSummary: payload.summary,
  error: false,
  is_data_requested: false,
});

/**
 * Get lost count actions
 */
const getTurnoverLostCountSuccess = (
  state: ITurnoverState,
  payload: ICountResponse,
) => ({
  ...state,
  lostCount: payload.count,
  error: false,
  is_data_requested: false,
});

/**
 * Get active list actions
 */
const getTurnoverActiveListSuccess = (
  state: ITurnoverState,
  payload: ListWithSummary<ITurnoverActiveModel>,
) => ({
  ...state,
  activeList: payload.items,
  activeSummary: payload.summary,
  error: false,
  is_data_requested: false,
});

/**
 * Get active count actions
 */
const getTurnoverActiveCountSuccess = (
  state: ITurnoverState,
  payload: ICountResponse,
) => ({
  ...state,
  activeCount: payload.count,
  error: false,
  is_data_requested: false,
});

/**
 * Get terminated list actions
 */
const getTurnoverTerminatedListSuccess = (
  state: ITurnoverState,
  payload: ListWithSummary<ITurnoverTerminatedModel>,
) => ({
  ...state,
  terminatedList: payload.items,
  terminatedSummary: payload.summary,
  error: false,
  is_data_requested: false,
});

/**
 * Get terminated count actions
 */
const getTurnoverTerminatedCountSuccess = (
  state: ITurnoverState,
  payload: ICountResponse,
) => ({
  ...state,
  terminatedCount: payload.count,
  error: false,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<ITurnoverState>();

/**
 * Turnover reducer
 */
export const turnover = createReducer<ITurnoverState>({}, defaultState)
  // get added list actions
  .on(actions.getTurnoverAddedListRequest, getTurnoverListRequest)
  .on(actions.getTurnoverAddedListSuccess, getTurnoverAddedListSuccess)
  .on(actions.getTurnoverAddedListFailed, getTurnoverListFailed)
  // get added count actions
  .on(actions.getTurnoverAddedCountRequest, getTurnoverCountRequest)
  .on(actions.getTurnoverAddedCountSuccess, getTurnoverAddedCountSuccess)
  .on(actions.getTurnoverAddedCountFailed, getTurnoverCountFailed)
  // get lost list actions
  .on(actions.getTurnoverLostListRequest, getTurnoverListRequest)
  .on(actions.getTurnoverLostListSuccess, getTurnoverLostListSuccess)
  .on(actions.getTurnoverLostListFailed, getTurnoverListFailed)
  // get lost count actions
  .on(actions.getTurnoverLostCountRequest, getTurnoverCountRequest)
  .on(actions.getTurnoverLostCountSuccess, getTurnoverLostCountSuccess)
  .on(actions.getTurnoverLostCountFailed, getTurnoverCountFailed)
  // get active list actions
  .on(actions.getTurnoverActiveListRequest, getTurnoverListRequest)
  .on(actions.getTurnoverActiveListSuccess, getTurnoverActiveListSuccess)
  .on(actions.getTurnoverActiveListFailed, getTurnoverListFailed)
  // get active count actions
  .on(actions.getTurnoverActiveCountRequest, getTurnoverCountRequest)
  .on(actions.getTurnoverActiveCountSuccess, getTurnoverActiveCountSuccess)
  .on(actions.getTurnoverActiveCountFailed, getTurnoverCountFailed)
  // get terminated list actions
  .on(actions.getTurnoverTerminatedListRequest, getTurnoverListRequest)
  .on(
    actions.getTurnoverTerminatedListSuccess,
    getTurnoverTerminatedListSuccess,
  )
  .on(actions.getTurnoverTerminatedListFailed, getTurnoverListFailed)
  // get terminated count actions
  .on(actions.getTurnoverTerminatedCountRequest, getTurnoverCountRequest)
  .on(
    actions.getTurnoverTerminatedCountSuccess,
    getTurnoverTerminatedCountSuccess,
  )
  .on(actions.getTurnoverTerminatedCountFailed, getTurnoverCountFailed)
  // export actions
  .on(actions.exportTurnoverAddedRequest, exportRequest)
  .on(actions.exportTurnoverLostRequest, exportRequest)
  .on(actions.exportTurnoverActiveRequest, exportRequest)
  .on(actions.exportTurnoverTerminatedRequest, exportRequest)
  .on(actions.exportTurnoverSuccess, exportSuccess)
  .on(actions.exportTurnoverFailed, exportFailed);

export default turnover;
