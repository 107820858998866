import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterPanel, IFilterPanelOption } from 'src/components/FilterPanel';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelTextField } from '../../../../../components/FilterPanel/FilterPanelFilters/FilterPanelTextField';
import { FilterPanelCombobox } from '../../../../../components/FilterPanel/FilterPanelFilters/FilterPanelCombobox';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { TurnoverDashboardPeriod } from '../../../../TurnoverDashboard/components/TurnoverDashboardFilterPanel';

export interface IDashboardTurnoverByReasonFilters {
  siteId?: number;
  year?: number;
}

type IDashboardTurnoverByReasonFilterPanelProps = {
  filterInitialData: IDashboardTurnoverByReasonFilters;
  onApplyFilters: (filters: IDashboardTurnoverByReasonFilters) => void;
  onRemoveFilters: () => void;
};

export const DashboardTurnoverByReasonFilterPanel = ({
  onApplyFilters,
  onRemoveFilters,
  filterInitialData,
}: IDashboardTurnoverByReasonFilterPanelProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = React.useState<
    IDashboardTurnoverByReasonFilters
  >(filterInitialData);

  const filterOptionsConfig: Array<IFilterPanelOption> = React.useMemo(
    () => [
      {
        id: 'siteId' as const,
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'year' as const,
        label: t('productions_uph_reports.year'),
        isPermanent: true,
      },
    ],
    [t],
  );

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<
      IDashboardTurnoverByReasonFilters
    >((obj, filterId) => {
      obj[filterId] = filters[filterId];

      return obj;
    }, {});

    onApplyFilters(appliedFiltersData);
  };

  const onRemoveAllOptions = () => {
    setFilters(filterInitialData);

    onRemoveFilters();
  };

  const periodOptions: ComboBoxOption[] = [
    {
      id: TurnoverDashboardPeriod.last7Days,
      name: 'Last 7 Days',
    },
    {
      id: TurnoverDashboardPeriod.last30Days,
      name: 'Last 30 Days',
    },
  ];

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'period':
        return (
          <FilterPanelCombobox
            filterId={filter.id}
            options={periodOptions}
            value={
              periodOptions.find((p) => p.id === filters[filter.id]) ??
              periodOptions[1]
            }
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'siteId':
        return (
          <FilterPanelSitesCombobox
            excludeTestSites
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters.siteId ?? null}
            onChange={(_, value) => {
              setFilters((prev: any) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'year':
        return (
          <FilterPanelTextField
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? ''}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: e.target.value,
              }));
            }}
          />
        );
    }

    return null;
  };

  React.useEffect(() => {
    setFilters(filterInitialData);
  }, [filterInitialData]);

  return (
    <FilterPanel
      renderFilter={renderFilter}
      filterOptionsConfig={filterOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllOptions}
    />
  );
};
