import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreatePointType,
  IdsArray,
  IFilter,
  IPointTypeModel,
  IUpdatePointType,
  PayloadWithNavigateFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushPointTypeState = createAction(
  'Flush pointType data in store',
);

// Creation actions
export const createPointTypeRequest = createAction<
  PayloadWithNavigateFunc<ICreatePointType>
>('Make request to create a new pointType');
export const createPointTypeSuccess = createAction<IPointTypeModel>(
  'New pointType has been created successfully',
);
export const createPointTypeFailed = createAction(
  'Cannot create a new pointType',
);

// Fetch pointTypes actions
export const getPointTypeListRequest = createAction<IFilter>(
  'Make request to fetch pointType list',
);
export const getPointTypeListSuccess = createAction<IPointTypeModel[]>(
  'PointTypes list has been fetched successfully',
);
export const getPointTypeListFailed = createAction(
  'Cannot fetch pointType list',
);

// get pointTypes count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPointTypeCountRequest = createAction<IFilter>(
  'Make request to get pointType count',
);
export const getPointTypeCountSuccess = createAction<ICountResponse>(
  'PointTypes count has been fetched successfully',
);
export const getPointTypeCountFailed = createAction(
  'Cannot get pointType count',
);

// delete actions
export const deletePointTypeRequest = createAction<IdsArray>(
  'Make request to delete pointTypes',
);
export const deletePointTypeFailed = createAction('Cannot delete pointTypes');

// Updating actions
export const updatePointTypesRequest = createAction<
  PayloadWithNavigateFunc<IUpdatePointType[]>
>('Make request to update pointTypes');
export const updatePointTypesFailed = createAction('Cannot update pointTypes');

// Fetch combobox list actions
export const getPointTypeComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of pointTypes',
);
export const getPointTypeComboboxListSuccess = createAction<
  Partial<IPointTypeModel>[]
>('Combobox list of pointTypes has been fetched successfully');
export const getPointTypeComboboxListFailed = createAction(
  'Cannot fetch combobox pointTypes list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('PointType').takeExportActions();

export const exportPointTypeRequest = exportReportRequest;
export const exportPointTypeSuccess = exportReportSuccess;
export const exportPointTypeFailed = exportReportFailed;
