import React from 'react';
import { MUI_SIZE_3, MUI_SIZE_0, MUI_SIZE_2 } from '../../config';
import { Box, Theme } from '@mui/material';

export const FormActionsContainer: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        margin: (theme: Theme) =>
          theme.spacing(MUI_SIZE_3, MUI_SIZE_0, MUI_SIZE_2),
      }}
    >
      {children}
    </Box>
  );
};
