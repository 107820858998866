import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/joy';

import {
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
// import { useFilterFieldsData } from 'src/modules/utils/hooks/table';

import { IIncentivePriceModel } from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { ModelsToDelete } from 'src/config';
import { TableWithActionsDeleteModal } from 'src/components/TableWithActions/components/TableWithActionsDeleteModal';
import {
  useIncentivePrice,
  useIncentivePriceFilter,
  useIncentivePricePermissions,
  // useIncentivePriceTableFiltersConfiguration,
} from 'src/modules/utils';
// import { IncentivePriceFilter } from './components/IncentivePriceFilter';
import { PageContentChildContainer } from 'src/components/PageContent';
import { SelectSiteAlert } from 'src/components/SelectSiteAlert';
import { IncentivePriceCreate } from './IncentivePriceCreate';
import { IncentivePriceUpdate } from './IncentivePriceUpdate';
import {
  IPricingFilterPanelFilters,
  PricingFilterPanel,
} from '../../PricingFilterPanel';
import { IncentivePriceSettingsCreate } from './components/IncentivePriceSettingsCreate';

const reportUrl = '/incentive-prices';

export const IncentivePriceList = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl, true);

  const [siteId, setSiteId] = React.useState<number | undefined>(undefined);

  const {
    allowedToUpdate,
    allowedToDelete,
    allowedToCreate,
    allowedToCreateSettings,
  } = useIncentivePricePermissions();

  const siteFilterConfig: Array<{
    id: keyof IPricingFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('incentivePrice.site'),
        isPreselected: true,
        isPermanent: true,
      },
    ],
    [t],
  );

  const [selectedItems, setSelectedItems] = React.useState<
    IIncentivePriceModel[]
  >([]);

  const [
    isIncentivePriceSettingsCreateOpen,
    setIncentivePriceSettingsCreateOpen,
  ] = React.useState(false);

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState(false);

  const filter = useIncentivePriceFilter({
    siteId: siteId,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
    removeDataByIds,
    fetchData,
    // metaTagsColumns,
  } = useIncentivePrice(reportUrl, filter!.where!, filter!.include!, siteId);

  // const filterFieldsConfiguration = useIncentivePriceTableFiltersConfiguration(
  //   metaTagsColumns,
  // );

  // const {
  //   getLabel,
  //   onFiltersFormSubmit,
  //   getFilterCommonPropsByFilterName,
  // } = useFilterFieldsData({
  //   filterFieldsConfiguration,
  // });

  const onTableRowCheckboxClick = (items: Array<IIncentivePriceModel>) => {
    setSelectedItems(items);
  };

  const handleDelete = async () => {
    const ids = selectedItems.map((i) => +i.id!);
    await removeDataByIds(ids);
    await fetchData({
      filter,
    });
    setShowDeleteModal(false);
  };

  // handle updating
  const handleUpdating = (items: IIncentivePriceModel[]) => {
    setIsUpdateFormVisible(true);
    setSelectedItems(items);
  };

  const handleCreate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsCreateFormVisible(false);
  };

  const handleUpdate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsUpdateFormVisible(false);

    // onDataChange();
  };

  const ids = React.useMemo(() => selectedItems.map((i) => +i.id!), [
    selectedItems,
  ]);

  return (
    <TableContextProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: isDataLoading ? 400 : 'auto',
          height: isDataLoading || !data.length ? '100%' : 'auto',
        }}
      >
        <TableToolbar>
          <TableTitle>{t('pricing.incentive')}</TableTitle>

          <TableActions>
            {/*<IncentivePriceFilter*/}
            {/*  metaTagsColumns={metaTagsColumns}*/}
            {/*  getLabel={getLabel}*/}
            {/*  onFiltersFormSubmit={onFiltersFormSubmit}*/}
            {/*  getFilterCommonPropsByFilterName={*/}
            {/*    getFilterCommonPropsByFilterName*/}
            {/*  }*/}
            {/*/>*/}

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={requestFilters}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />

            {allowedToCreateSettings && (
              <Button
                sx={{ ml: 1 }}
                onClick={() => setIncentivePriceSettingsCreateOpen(true)}
              >
                {t('pricing.create_settings_btn')}
              </Button>
            )}

            {allowedToCreate && (
              <ReportPageActionsGroupItem>
                <Button onClick={() => setIsCreateFormVisible(true)}>
                  {t('incentivePrice.create_form_title')}
                </Button>
              </ReportPageActionsGroupItem>
            )}
          </TableActions>
        </TableToolbar>

        <PageContentChildContainer fullHeight={false} sx={{ px: 0, pb: 1 }}>
          <PricingFilterPanel
            onApplyFilters={({ siteId }) => setSiteId(siteId)}
            onRemoveAllFilters={() => setSiteId(undefined)}
            filtersOptionsConfig={siteFilterConfig}
            defaultFilters={{ siteId }}
          />
        </PageContentChildContainer>

        {!siteId && (
          <PageContentChildContainer sx={{ px: 0 }}>
            <SelectSiteAlert />
          </PageContentChildContainer>
        )}

        {siteId && (
          <>
            <TableWithActionsDeleteModal
              open={showDeleteModal}
              onOk={handleDelete}
              onCancel={() => setShowDeleteModal(false)}
              deleteModelName={ModelsToDelete.IncentivePrice}
            />

            <TableWithActions<IIncentivePriceModel>
              heightWithTabs
              selectedIndex="id"
              data={data as any}
              count={countData.count}
              isDataLoading={isDataLoading || isCountDataLoading}
              tableUniqueKey={reportUrl}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              limit={limit}
              page={page}
              onSort={handleSort}
              selectedItems={selectedItems}
              onRowCheckboxClick={onTableRowCheckboxClick}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={(page) => handlePageChange(page)}
              {...(allowedToUpdate
                ? {
                    updateActionConfiguration: {
                      onClick: () => {
                        handleUpdating(selectedItems);
                      },
                    },
                  }
                : {})}
              {...(allowedToDelete
                ? {
                    deleteActionConfiguration: {
                      onClick: () => setShowDeleteModal(true),
                    },
                  }
                : {})}
            />
          </>
        )}

        <IncentivePriceCreate
          isOpen={isCreateFormVisible}
          onClose={() => setIsCreateFormVisible(false)}
          onCreate={handleCreate}
        />

        <IncentivePriceUpdate
          ids={ids}
          isOpen={isUpdateFormVisible}
          onUpdate={handleUpdate}
          onClose={() => setIsUpdateFormVisible(false)}
        />

        <IncentivePriceSettingsCreate
          isOpen={isIncentivePriceSettingsCreateOpen}
          onClose={() => setIncentivePriceSettingsCreateOpen(false)}
        />
      </Box>
    </TableContextProvider>
  );
};
