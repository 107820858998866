import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getRolesByIds, getRolesComboboxList } from '../../selectors/role';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getRoleComboboxListRequest,
  getRoleListRequest,
} from '../../actions/role';
import { defaultOrderDetails } from '../helpers/filter';

const useRolesComboboxFilter = () => {
  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          name: true,
          key: true,
          hasSites: true,
          hasWmsLogin: true,
          hasShift: true,
          hasDepartment: true,
          hasSupervisor: true,
          hasClients: true,
          hasStaffing: true,
        },
        order: ['name asc'],
      },
    }),
    [],
  );
};

/**
 * A custom hook to fetch roles from store if they exist otherwise to make a request to fetch needed roles from
 * the server
 */
export const useFetchRolesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch roles list from store
  const roles = useSelector(
    (state) => getRolesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(roles)) {
      dispatcher(
        getRoleListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch roles combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchRolesCombobox = () => {
  const dispatcher = useDispatch();
  const rolesComboboxFilter = useRolesComboboxFilter();

  const dispatchGetRolesComboboxListRequest = React.useCallback(() => {
    dispatcher(getRoleComboboxListRequest(rolesComboboxFilter));
  }, [rolesComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetRolesComboboxListRequest();
  }, [dispatchGetRolesComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getRolesComboboxList);
  return () => {
    if (isEmpty(combobox)) {
      dispatchGetRolesComboboxListRequest();
    }
  };
};

export const useRolesDefaultFilters = () => {
  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
    }),
    [],
  );
};
