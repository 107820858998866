import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../../../../modules/types';
import { useFilter } from '../../../../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import {
  ModelsToDelete,
  useHasUserAccessToAction,
} from '../../../../../config';
import { useCostPlusSettingsDefaultFilters } from '../../../../../modules/utils/hooks/cost-plus-settings.hooks';
import {
  getCostPlusSettingsCount,
  getCostPlusSettingsRefreshKey,
  getCostPlusSettingsList,
  getIsCostPlusSettingsDataLoading,
} from '../../../../../modules/selectors/cost-plus-settings.selector';
import {
  deleteCostPlusSettingsRequest,
  getCostPlusSettingsCountRequest,
  getCostPlusSettingsListRequest,
} from '../../../../../modules/actions';
import UpdateCostPlusSettings from './Forms/UpdateCostPlusSettings';
import CreateCostPlusSettings from './Forms/CreateCostPlusSettings';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { NoIcon, YesIcon } from 'src/components/Icons';

const CostPlusSettingsList = () => {
  const { t } = useTranslation();

  const hasUserAccessToCreateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.hourly_setting.create.id,
  );
  const hasUserAccessToUpdateEntity = useHasUserAccessToAction(
    manageEntitiesConfig.hourly_setting.update.id,
  );
  const hasUserAccessToDeleteEntity = useHasUserAccessToAction(
    manageEntitiesConfig.hourly_setting.delete.id,
  );

  const defaultFilter = useCostPlusSettingsDefaultFilters();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // fetch CostPlusSetting list
  const list = useSelector(getCostPlusSettingsList, shallowEqual);
  const count = useSelector(getCostPlusSettingsCount, shallowEqual);
  const isTableDataLoading = useSelector(getIsCostPlusSettingsDataLoading);

  const listWithIcons = React.useMemo(() => {
    return list.map((item) => ({
      ...item,
      isActive: item.isActive ? <YesIcon /> : <NoIcon />,
    }));
  }, [list]);

  // create dispatcher
  const dispatcher = useDispatch();

  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState<boolean>(
    false,
  );
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState<boolean>(
    false,
  );

  const [selectedIds, setSelectedIds] = React.useState<IdsArray>([]);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'site.name', disablePadding: false, label: t('shifts.site_name') },
    {
      id: 'type',
      disablePadding: false,
      label: t('cost-plus-settings.type'),
    },
    {
      id: 'isActive',
      disablePadding: false,
      label: t('cost-plus-settings.active'),
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('cost-plus-settings.description'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('shifts.site_name'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'type',
      label: t('cost-plus-settings.type'),
      operator: 'like',
    },
    {
      name: 'isActive',
      label: t('cost-plus-settings.active'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'description',
      label: t('issues.description'),
      operator: 'like',
    },
  ];

  // make request to fetch siteImportingSettings when component is mounted
  useEffect(() => {
    dispatcher(getCostPlusSettingsListRequest(filterList));
    // get total count
    dispatcher(getCostPlusSettingsCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (args: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include } = args;
    const offset = page * rowsPerPage;
    dispatcher(
      getCostPlusSettingsListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getCostPlusSettingsCountRequest({
        filter: { where, include },
      }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(
      deleteCostPlusSettingsRequest({
        data: { ids },
        filters: {
          list: filterList.filter ?? {},
          count: filterCount.filter ?? {},
        },
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    setSelectedIds(ids);
    setIsUpdateFormVisible(true);
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={listWithIcons}
        count={count}
        isTableDataLoading={isTableDataLoading}
        selectIndex="id"
        tableName={t('cost-plus-settings.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={hasUserAccessToDeleteEntity ? handleDeletion : undefined}
        onUpdate={hasUserAccessToUpdateEntity ? handleUpdating : undefined}
        deleteModelName={ModelsToDelete.CostPlusSettings}
        include={defaultFilter.include}
        createEntityBtnProps={
          hasUserAccessToCreateEntity
            ? {
                title: t('cost-plus-settings.create'),
                onClick: () =>
                  setIsCreateFormVisible((prevState) => !prevState),
              }
            : undefined
        }
      />
      <>
        <CreateCostPlusSettings
          isOpen={isCreateFormVisible}
          onClose={() => setIsCreateFormVisible(false)}
        />
        <UpdateCostPlusSettings
          isOpen={isUpdateFormVisible}
          onClose={() => setIsUpdateFormVisible(false)}
          idsToUpdate={selectedIds}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter ?? {}}
        />
      </>
    </>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function CostPlusSettingsListRefreshable() {
  return (
    <CostPlusSettingsList key={useSelector(getCostPlusSettingsRefreshKey)} />
  );
}
