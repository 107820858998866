import React from 'react';
import { useTranslation } from 'react-i18next';

import { clients } from '../../modules/constants';
import WeeklyGrossMarginReport from '../../components/Reports/WeeklyGrossMarginReport';

interface IWeeklyGrossMarginProps {
  siteId?: number;
  week?: number;
}

export const WeeklyGrossMargin = ({
  siteId,
  week,
}: IWeeklyGrossMarginProps) => {
  const { t } = useTranslation();

  return (
    <WeeklyGrossMarginReport
      clientKey={clients.williamsSonoma}
      tableName={t('williams_sonoma_reports.table_names.weekly_gm')}
      siteId={siteId}
      weekNumber={week}
    />
  );
};

export default WeeklyGrossMargin;
