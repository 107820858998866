import React, { useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTerminationReasonsByIds } from '../../modules/selectors/terminationReason';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
} from '../../modules/utils';
import {
  IdsArray,
  IStoreState,
  IUpdateTerminationReason,
} from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchTerminationReasonsByIds } from '../../modules/utils';
import { updateTerminationReasonsRequest } from '../../modules/actions';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { useValidate } from '../../modules/utils';
import { updateTerminationReasonsScheme } from '../../modules/schemes/termination-reason';
import FormikCheckbox from '../../components/Formik/FormikCheckbox';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface ITerminationReasonProps {
  terminationReasons: IUpdateTerminationReason[];
  onSubmit: (terminationReasons: IUpdateTerminationReason[]) => void;
}

const TerminationReasonUpdateForm = ({
  terminationReasons,
  onSubmit,
}: ITerminationReasonProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateTerminationReasonsScheme);

  const formik = useFormikInUpdateForm({
    initialValues: terminationReasons,
    validate,
    onSubmit,
  });

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      fields={formik.values}
      isFormValid={formik.isValid}
    >
      {map(formik.values, (terminationReason, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('terminationReasons.update_title', terminationReason)}
          key={terminationReason.id}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id={`${index}.name`}
              label={t('terminationReasons.name')}
              name={`${index}.name`}
              autoComplete="name"
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              required
              id={`${index}.voluntary`}
              label={t('terminationReasons.voluntary')}
              name={`${index}.voluntary`}
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id={`${index}.siteId`}
              required={true}
              formik={formik}
              errorMode="onFieldChange"
              label={t('terminationReasons.site')}
              placeholder={t('terminationReasons.site')}
            />
          </FormFieldContainer>
        </CreateForm>
      ))}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const TerminationReasonsUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchTerminationReasons = useFetchTerminationReasonsByIds(ids);

  // fetch terminationReasons list from store
  const terminationReasons = useSelector(
    (state) => getTerminationReasonsByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch terminationReasons from the server if we don't have them in the store
  useEffect(() => {
    fetchTerminationReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated terminationReasons
   */
  const handleFormSubmit = (data: IUpdateTerminationReason[]) => {
    dispatcher(updateTerminationReasonsRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <TerminationReasonUpdateForm
          terminationReasons={terminationReasons}
          onSubmit={handleFormSubmit}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default TerminationReasonsUpdate;
