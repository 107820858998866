import dateFnsFormat from 'date-fns/format';
import dateFnsGetUnixTime from 'date-fns/getUnixTime';
import dateFnsParse from 'date-fns/parse';
import dateFnsParseISO from 'date-fns/parseISO';
import dateFnsAdd from 'date-fns/add';
import dateFnsSub from 'date-fns/sub';
import dateFnsIsSameSecond from 'date-fns/isSameSecond';
import dateFnsIsSameDay from 'date-fns/isSameDay';
import dateFnsIsBefore from 'date-fns/isBefore';
import dateFnsIsAfter from 'date-fns/isAfter';
import dateFnsAddSeconds from 'date-fns/addSeconds';
import dateFnsGetISOWeek from 'date-fns/getISOWeek';
import dateFnsGetWeek from 'date-fns/getWeek';
import dateFnsGetYear from 'date-fns/getYear';
import dateFnsDifferenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import {
  Locale,
  getMonth,
  getYear,
  addMonths,
  format as dfsFormat,
  subDays,
  subWeeks,
  subYears,
  getISOWeekYear,
  addDays,
} from 'date-fns';
import dateAddMilliseconds from 'date-fns/addMilliseconds';
import dateFnsGetDate from 'date-fns/getDate';
import dateFnsGetWeekOfMonth from 'date-fns/getWeekOfMonth';
import dateFnsGetDay from 'date-fns/getDay';
import {
  zonedTimeToUtc as dateFnsZonedTimeToUTC,
  utcToZonedTime as dateFnsUtcToZonedTime,
} from 'date-fns-tz';
import flow from 'lodash/fp/flow';
// since date-fns does not provide tz names moment-timezone is used for the purpose
import { tz } from 'moment-timezone';

import { DATE_FORMAT, DATE_FORMATS } from './formats';
import {
  MILLISECONDS_NUMBER_IN_MINUTE,
  MILLISECONDS_NUMBER_IN_HOUR,
  MILLISECONDS_NUMBER_IN_DAY,
} from './constants';
import React from 'react';
import { isDate } from 'lodash';

export type DateType = Date | number;
export interface GetWeekOptions {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
}
export interface DateWrapper {
  getUnixTime(): (date: DateType) => number;
  format(format: DATE_FORMATS): (date: DateType) => string;
  parse(format: DATE_FORMATS, referenceDate?: Date): (date: string) => Date;
  parseISO(date: string): Date;
  add(duration: Duration): (date: DateType) => Date;
  sub(duration: Duration): (date: DateType) => Date;
  addSeconds(seconds: number): (date: DateType) => Date;
  addMilliseconds(ms: number): (date: DateType) => Date;
  isSameSecond: typeof isSameSecond;
  isBefore(firstDate: DateType): typeof isBefore;
  isAfter(firstDate: DateType): typeof isAfter;
  getISOWeek(date: DateType): number;
  getWeekNumber(options?: GetWeekOptions): (date: DateType) => number;
  getWeek(date: DateType, options?: GetWeekOptions): number;
  getISOYear(): (date: DateType) => number;
  getNow(): Date;
  guessTimezone(): string;
  composeDate(
    date: DateType | string | null,
    ...funcs: Parameters<typeof flow>
  ): any;
  getTimezones(): { id: string; name: string }[];
  differenceInMilliseconds: typeof differenceInMilliseconds;
  calculateDaysFromMilliseconds(ms: number): number;
  calculateHoursFromMilliseconds(ms: number): number;
  calculateMinutesFromMilliseconds(ms: number): number;
  calculateSecondsFromMilliseconds(ms: number): number;
  formatTimeUnit(ms: number): string;
  parseAnythingAsDate(date: any): string | null;
  tryToGenerateDateFromAnything(date: any): Date | null;
  getDate(date: Date): number;
  getWeekOfMonth(date: Date): number;
  getDay(date: Date): number;
  getDayWithMonAsFirstDay(date: Date): number;
  utcToZonedTime(date: Date): Date;
  zonedTimeToUTC(date: Date): Date;
  zonedTimeToUTCString(date: Date): string;
  getMonths(): Array<string>;
}

export const format: DateWrapper['format'] = (format: DATE_FORMATS) => (
  date: DateType,
) => {
  return dateFnsFormat(date, format);
};

export const getUnixTime: DateWrapper['getUnixTime'] = () => (
  date: DateType,
) => {
  return dateFnsGetUnixTime(date);
};

export const parse: DateWrapper['parse'] = (
  format: DATE_FORMATS,
  referenceDate = new Date(),
) => (date: string) => {
  return dateFnsParse(date, format, referenceDate);
};

export const parseISO: DateWrapper['parseISO'] = (date: string) => {
  return dateFnsParseISO(date);
};

export const addSeconds: DateWrapper['addSeconds'] = (seconds: number) => (
  date: DateType,
) => {
  return dateFnsAddSeconds(date, seconds);
};

export const addMilliSeconds: DateWrapper['addMilliseconds'] = (ms: number) => (
  date: DateType,
) => {
  return dateAddMilliseconds(date, ms);
};

export const add: DateWrapper['add'] = (duration: Duration) => (
  date: DateType,
) => {
  return dateFnsAdd(date, duration);
};

export const sub: DateWrapper['sub'] = (duration: Duration) => (
  date: DateType,
) => {
  return dateFnsSub(date, duration);
};

// overloads definitions
export function isSameSecond(
  firstDate: DateType,
): (secondDate: DateType) => boolean;
export function isSameSecond(
  firstDate: DateType,
  secondDate: DateType,
): boolean;
// implementation
export function isSameSecond(firstDate: DateType, secondDate?: DateType) {
  if (!secondDate) {
    return (secondDate_: DateType) =>
      dateFnsIsSameSecond(firstDate, secondDate_);
  }

  return dateFnsIsSameSecond(firstDate, secondDate);
}

// type definition for differenceInMilliseconds function overload
export function differenceInMilliseconds(
  firstDate: DateType,
): (secondDate: DateType) => number;
export function differenceInMilliseconds(
  firstDate: DateType,
  secondDate: DateType,
): number;

// implementation of differenceInMilliseconds function
export function differenceInMilliseconds(
  firstDate: DateType,
  secondDate?: DateType,
) {
  if (!secondDate) {
    return (secondDate_: DateType) =>
      dateFnsDifferenceInMilliseconds(firstDate, secondDate_);
  }

  return dateFnsDifferenceInMilliseconds(firstDate, secondDate);
}

// type definition for isBefore function overload
export function isBefore(
  firstDate: DateType,
): (compareToDate: DateType) => boolean;
export function isBefore(firstDate: DateType, compareToDate: DateType): boolean;

// implementation of isBefore function
export function isBefore(date: DateType, compareToDate?: DateType) {
  if (!compareToDate) {
    return (compareToDate_: DateType) => dateFnsIsBefore(date, compareToDate_);
  }

  return dateFnsIsBefore(date, compareToDate);
}

// type definition for isAfter function overload
export function isAfter(
  firstDate: DateType,
): (compareToDate: DateType) => boolean;
export function isAfter(firstDate: DateType, compareToDate: DateType): boolean;

// implementation of isAfter function
export function isAfter(date: DateType, compareToDate?: DateType) {
  if (!compareToDate) {
    return (compareToDate_: DateType) => dateFnsIsAfter(date, compareToDate_);
  }

  return dateFnsIsAfter(date, compareToDate);
}

export const getISOWeek: DateWrapper['getISOWeek'] = (date: DateType) =>
  dateFnsGetISOWeek(date);

export const getWeekNumber: DateWrapper['getWeekNumber'] = (
  options?: GetWeekOptions,
) => (date: DateType) => {
  return dateFnsGetWeek(date, options);
};

export const getWeek: DateWrapper['getWeek'] = (
  date: DateType,
  options?: GetWeekOptions,
) => dateFnsGetWeek(date, options);

export const getISOYear: DateWrapper['getISOYear'] = () => (date: DateType) => {
  return dateFnsGetYear(date);
};

export const getNow = () => new Date();

export const guessTimezone: DateWrapper['guessTimezone'] = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const composeDate: DateWrapper['composeDate'] = (
  date,
  ...funcs: Parameters<typeof flow>
) => (date ? flow(...funcs)(date) : null);

const timezones = tz.names().map((name) => ({ id: name, name }));
export const getTimezones: DateWrapper['getTimezones'] = () => timezones;

export const formatTimeUnit = (timeUnit: number) => {
  const strTimeUnit = String(timeUnit);

  if (strTimeUnit.length === 1) {
    return `0${strTimeUnit}`;
  }

  return strTimeUnit;
};

export const calculateDaysFromMilliseconds: DateWrapper['calculateDaysFromMilliseconds'] = (
  ms: number,
) => {
  return Math.floor(ms / MILLISECONDS_NUMBER_IN_DAY);
};

export const calculateHoursFromMilliseconds: DateWrapper['calculateHoursFromMilliseconds'] = (
  ms: number,
) => {
  return Math.floor(ms / MILLISECONDS_NUMBER_IN_HOUR) % 24;
};

export const calculateMinutesFromMilliseconds: DateWrapper['calculateMinutesFromMilliseconds'] = (
  ms: number,
) => {
  return Math.floor(ms / MILLISECONDS_NUMBER_IN_MINUTE) % 60;
};

export const calculateSecondsFromMilliseconds: DateWrapper['calculateSecondsFromMilliseconds'] = (
  ms: number,
) => {
  const daysInMs =
    calculateDaysFromMilliseconds(ms) * MILLISECONDS_NUMBER_IN_DAY;
  const hoursInMs =
    calculateHoursFromMilliseconds(ms) * MILLISECONDS_NUMBER_IN_HOUR;
  const minutesInMs =
    calculateMinutesFromMilliseconds(ms) * MILLISECONDS_NUMBER_IN_MINUTE;

  return Math.floor(((ms - daysInMs - hoursInMs - minutesInMs) / 1000) % 60);
};

export const parseAnythingAsDate: DateWrapper['parseAnythingAsDate'] = (
  date: any,
) => {
  const dateInMs = Date.parse(date);

  if (Number.isNaN(dateInMs)) {
    return null;
  }

  return date;
};

export const tryToGenerateDateFromAnything: DateWrapper['tryToGenerateDateFromAnything'] = (
  date: any,
) => {
  const dateInMs = Date.parse(date);

  if (Number.isNaN(dateInMs)) {
    return null;
  }

  return new Date(dateInMs);
};

export const getDate: DateWrapper['getDate'] = (date: Date) => {
  return dateFnsGetDate(date);
};

export const getWeekOfMonth: DateWrapper['getWeekOfMonth'] = (date: Date) => {
  return dateFnsGetWeekOfMonth(date);
};

export const getDay: DateWrapper['getDay'] = (date: Date) => {
  return dateFnsGetDay(date);
};

export const getDayWithMonAsFirstDay: DateWrapper['getDayWithMonAsFirstDay'] = (
  date: Date,
) => {
  const day = dateFnsGetDay(date);

  if (!day) {
    return 6;
  }

  return day - 1;
};

export const utcToZonedTime: DateWrapper['utcToZonedTime'] = (date: Date) => {
  const timezone = guessTimezone();

  return dateFnsUtcToZonedTime(date, timezone);
};

export const zonedTimeToUTC: DateWrapper['zonedTimeToUTC'] = (date: Date) => {
  const timezone = guessTimezone();
  return dateFnsZonedTimeToUTC(date, timezone);
};

export const zonedTimeToUTCString: DateWrapper['zonedTimeToUTCString'] = (
  date: Date,
) => {
  return zonedTimeToUTC(date).toISOString();
};

export const getMonths = () => {
  function getMonthName(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString('en-US', {
      month: 'long',
      year: 'numeric',
    });
  }

  return new Array(12).fill(null).map((_, i) => getMonthName(i));
};

export const useMonthsByYear = (
  yearNumber: number = new Date().getFullYear(),
) =>
  React.useMemo(() => {
    const months: string[] = [];
    let startDate = new Date(yearNumber, 0, 1);
    const endDate = new Date(yearNumber, 12, 1);

    while (isBefore(startDate, endDate)) {
      months.push(dfsFormat(startDate, 'MMMM yyyy'));
      startDate = addMonths(startDate, 1);
    }

    return months;
  }, [yearNumber]);

export const generateMonthYearList = ({
  fromYear = getYear(subYears(new Date(), 5)),
  tillYear = getYear(new Date()),
  tillMonth = getMonth(new Date()),
  order = 'desc',
}: {
  fromYear?: number;
  tillYear?: number;
  tillMonth?: number;
  order?: 'desc' | 'asc';
} = {}) => {
  const months: Array<{ month: number; year: number; label: string }> = [];

  for (let year = fromYear; year <= tillYear; year++) {
    months.push(
      ...new Array(year === tillYear ? tillMonth : 11)
        .fill(null)
        .map((_, i) => {
          const date = parseISO(`${year}-01-01`);
          date.setMonth(i + 1);

          return {
            year,
            month: Number(
              date.toLocaleString('en-US', {
                month: 'numeric',
              }),
            ),
            label: date.toLocaleString('en-US', {
              month: 'long',
              year: 'numeric',
            }),
          };
        }),
    );
  }

  return order === 'asc' ? months : months.reverse();
};

export const generateYearWeekList = ({
  fromYear = getYear(subYears(new Date(), 5)),
  tillYear = getYear(new Date()),
  tillWeek = getISOWeek(new Date()),
  order = 'desc',
}: {
  fromYear?: number;
  tillYear?: number;
  tillWeek?: number;
  order?: 'desc' | 'asc';
} = {}): Array<{ week: number; year: number; label: string }> => {
  const FIRST_WEEK = 1;

  function format({ year, week }: { year: number; week: number }) {
    return `W${String(week).padStart(2, '0')}-${year}`;
  }
  const weeks: Array<{ week: number; year: number; label: string }> = [];

  for (let year = fromYear; year <= tillYear; year++) {
    const lastWeekDate = parseISO(`${year}-12-28`);
    const lastWeek = getISOWeek(lastWeekDate);

    let weekToCompareWith = lastWeek;
    if (year === tillYear) {
      weekToCompareWith = tillWeek;
    }

    let week = year === fromYear ? fromYear : FIRST_WEEK;
    while (week <= weekToCompareWith) {
      weeks.push({
        year,
        week,
        label: format({ year, week }),
      });

      week++;
    }
  }

  return order === 'asc' ? weeks : weeks.reverse();
};

export const getYDTYearWeekRange = (
  { subWeeksFromEndYearWeek = 0 } = { subWeeksFromEndYearWeek: 0 },
) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentWeekNumber = getISOWeek(
    subWeeks(today, subWeeksFromEndYearWeek),
  );

  const startYearWeek = `${currentYear}01`;
  const endYearWeek = `${currentYear}${String(currentWeekNumber).padStart(
    2,
    '0',
  )}`;

  return {
    startYearWeek,
    endYearWeek,
  };
};

export const getYDTStarEndDates = (
  { suDaysFromEndDate = 0 } = { suDaysFromEndDate: 0 },
) => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const startDate = parse(DATE_FORMAT)(`${currentYear}-01-01`);
  const endDate = subDays(today, suDaysFromEndDate);

  return {
    startDate,
    endDate,
    startDateFormatted: format(DATE_FORMAT)(startDate),
    endDateFormatted: format(DATE_FORMAT)(endDate),
  };
};

export const getLastNDaysStarEndDates = (daysNumber: number) => {
  const today = new Date();

  const startDate = subDays(today, daysNumber);
  const endDate = subDays(today, 1);

  return {
    startDate,
    endDate,
    startDateFormatted: format(DATE_FORMAT)(startDate),
    endDateFormatted: format(DATE_FORMAT)(endDate),
  };
};

export const generateYearWeekRange = (
  startYearWeek: number,
  endYearWeek: number,
) => {
  const yearWeeks: number[] = [];
  let startDate = dateFnsParse(`${startYearWeek}`, 'YYYYww', new Date(), {
    useAdditionalWeekYearTokens: true,
    useAdditionalDayOfYearTokens: true,
    weekStartsOn: 0,
  });
  const endDate = dateFnsParse(`${endYearWeek}`, 'YYYYww', new Date(), {
    useAdditionalWeekYearTokens: true,
    useAdditionalDayOfYearTokens: true,
    weekStartsOn: 0,
  });

  while (
    dateFnsIsBefore(startDate, endDate) ||
    dateFnsIsSameDay(startDate, endDate)
  ) {
    yearWeeks.push(
      +dateFnsFormat(startDate, 'YYYYww', {
        useAdditionalWeekYearTokens: true,
        useAdditionalDayOfYearTokens: true,
        weekStartsOn: 0,
      }),
    );
    startDate = dateFnsAdd(startDate, {
      weeks: 1,
    });
  }
  return yearWeeks;
};

export const generateYearMonthRange = (
  startYearMonth: number,
  endYearMonth: number,
) => {
  const yearWeeks: number[] = [];
  let startDate = dateFnsParse(`${startYearMonth}`, 'yyyyMM', new Date());
  const endDate = dateFnsParse(`${endYearMonth}`, 'yyyyMM', new Date());

  while (
    dateFnsIsBefore(startDate, endDate) ||
    dateFnsIsSameDay(startDate, endDate)
  ) {
    yearWeeks.push(+dateFnsFormat(startDate, 'yyyyMM'));
    startDate = dateFnsAdd(startDate, {
      months: 1,
    });
  }

  return yearWeeks;
};

export const getYearWeekNumberForNWeeksAgoFromNow = (nWeeksAgo: number) => {
  const currentDate = new Date();

  const twoWeeksAgo = subWeeks(currentDate, nWeeksAgo);

  const isoWeek = getISOWeek(twoWeeksAgo);
  const isoWeekYear = getISOWeekYear(twoWeeksAgo);

  const concatenatedYearWeek = `${isoWeekYear}${isoWeek
    .toString()
    .padStart(2, '0')}`;

  return Number(concatenatedYearWeek);
};

export const getDatesRange = (
  dateStart: Date | string,
  dateEnd: Date | string,
) => {
  const dates = [];

  let start: Date = isDate(dateStart)
    ? dateStart
    : (tryToGenerateDateFromAnything(dateStart) as Date);
  const end: Date = isDate(dateEnd)
    ? dateEnd
    : (tryToGenerateDateFromAnything(dateEnd) as Date);

  while (start < end) {
    dates.push(dateFnsFormat(start, DATE_FORMAT));
    start = addDays(start, 1);
  }

  return dates;
};

export const subWeeksByNumber = (
  yearWeek: number,
  weeksAgo: number,
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
) => {
  if (!yearWeek) {
    return null;
  }

  const weeksAgoDate = dateFnsParse(`${yearWeek}`, 'RI', new Date(), {
    weekStartsOn,
  });

  return +dateFnsFormat(dateFnsSub(weeksAgoDate, { weeks: weeksAgo }), 'RI');
};
