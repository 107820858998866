import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IUpdateMetatag } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createMetatagScheme } from './create-metatag.scheme';

export const updateMetatagsScheme: JSONSchemaType<IUpdateMetatag[]> = {
  type: 'array',
  items: {
    ...createMetatagScheme,
    type: 'object',
    required: ['siteId', 'name', 'id'],
    properties: {
      ...createMetatagScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
