import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreatePointType,
  IdsArray,
  IFilter,
  IPointTypeModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new pointType
 */
export const createPointTypeRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreatePointType>>): SagaIterator {
  try {
    const response: IPointTypeModel = yield call(Api.PointType.create, data);
    yield put(actions.flushPointTypeState());
    yield put(actions.createPointTypeSuccess(response));
    yield call(navigate, { pathname: '/point-types' });
  } catch (e) {
    yield put(actions.createPointTypeFailed());
  }
};

/**
 * Get pointType list
 */
export const getPointTypeListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IPointTypeModel[] = yield call(
      Api.PointType.list,
      action.payload,
    );
    yield put(actions.getPointTypeListSuccess(response));
  } catch (e) {
    yield put(actions.getPointTypeListFailed());
  }
};

/**
 * Get pointType count
 */
export const getPointTypeCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.PointType.count,
      action?.payload,
    );
    yield put(actions.getPointTypeCountSuccess(response));
  } catch (e) {
    yield put(actions.getPointTypeCountFailed());
  }
};

/**
 * Delete pointTypes
 */
export const deletePointTypeRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.PointType.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushPointTypeState());
  } catch (e) {
    yield put(actions.deletePointTypeFailed());
  }
};

/**
 * Bulk update pointTypes
 */
export const updatePointTypesRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IPointTypeModel[]>>): SagaIterator {
  try {
    yield call(Api.PointType.bulkUpdate, data);
    yield put(actions.flushPointTypeState());
    yield call(navigate, { pathname: '/point-types' });
  } catch (e) {
    yield put(actions.updatePointTypesFailed());
  }
};

/**
 * Get combobox list
 */
export const getPointTypeComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IPointTypeModel>[] = yield call(
      Api.PointType.list,
      action.payload,
    );
    yield put(actions.getPointTypeComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getPointTypeComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.PointType.export,
  actionFailed: actions.exportPointTypeFailed,
  actionSuccess: actions.exportPointTypeSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportPointTypeRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createPointTypeRequest, saga: createPointTypeRequestSaga },
  {
    action: actions.getPointTypeListRequest,
    saga: getPointTypeListRequestSaga,
  },
  {
    action: actions.getPointTypeCountRequest,
    saga: getPointTypeCountRequestSaga,
  },
  { action: actions.deletePointTypeRequest, saga: deletePointTypeRequestSaga },
  // bulk updating pointTypes
  {
    action: actions.updatePointTypesRequest,
    saga: updatePointTypesRequestSaga,
  },
  {
    action: actions.getPointTypeComboboxListRequest,
    saga: getPointTypeComboboxListRequestSaga,
  },
  { action: actions.exportPointTypeRequest, saga: exportPointTypeRequestSaga },
];
