import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IUpdateChecklistData } from '../../types';
import { createChecklistScheme } from './create-checklist.scheme';

export const updateChecklistScheme: JSONSchemaType<IUpdateChecklistData> = {
  type: 'object',
  required: ['title', 'siteId', 'shiftId', 'userId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    ...createChecklistScheme.properties,
    id: {
      type: 'number',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
