import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getDepartmentOptionsByGloballySites,
  getDepartmentsByIds,
} from '../../selectors/department';
import { IdsArray, IStoreState, IWhere } from '../../types';
import { isEmpty } from 'lodash';
import {
  getDepartmentComboboxListRequest,
  getDepartmentListRequest,
} from '../../actions/department';
import React from 'react';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';

const parentRelationInclusion = {
  relation: 'parent',
  relationType: 'left',
  alias: 'parent',
};

/**
 * A custom hook to fetch departments from store if they exist otherwise to make a request to fetch needed departments from
 * the server
 */
export const useFetchDepartmentsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  const siteRelationInclusion = useSiteRelationInclusion();

  // fetch departments list from store
  const departments = useSelector(
    (state) => getDepartmentsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(departments)) {
      dispatcher(
        getDepartmentListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: [parentRelationInclusion, siteRelationInclusion],
          },
        }),
      );
    }
  };
};

export const useBaseFetchDepartmentsCombobox = (where?: IWhere) => {
  const dispatcher = useDispatch();
  const siteRelationInclusion = useSiteRelationInclusion();

  const dispatchGetDepartmentComboboxListRequest = React.useCallback(() => {
    dispatcher(
      getDepartmentComboboxListRequest({
        filter: {
          fields: {
            id: true,
            name: true,
            siteId: true,
          },
          where,
          include: [siteRelationInclusion],
          order: ['name ASC'],
        },
      }),
    );
  }, [dispatcher, where, siteRelationInclusion]);

  // fetch combobox list from store
  const combobox = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetDepartmentComboboxListRequest();
    }
  };
};

export const useFetchActiveDepartmentsCombobox = () =>
  useBaseFetchDepartmentsCombobox({ active: true });

export const useFetchNotArchivedDepartmentsCombobox = () =>
  useBaseFetchDepartmentsCombobox({ archived: false });

export const useFetchArchivedDepartmentsCombobox = () =>
  useBaseFetchDepartmentsCombobox({ archived: true });

export const useDepartmentsDefaultFilters = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [parentRelationInclusion, siteRelationInclusion],
    }),
    [siteRelationInclusion],
  );
};
