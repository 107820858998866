import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useValidate } from '../../../../modules/utils';
import {
  updateChecklistTaskRequest,
  deleteChecklistTaskRequest,
  deleteChecklistRecurringTaskRequest,
} from '../../../../modules/actions';
import { IUpdateChecklistTaskData } from 'src/modules/types';
import { FormActionsContainer } from 'src/components/Form/FormActionsContainer';
import { SubmitButton } from 'src/components/Form/SubmitButton';
import { Box, IconButton } from '@mui/material';
import { DeleteForever, Close } from '@mui/icons-material';
import { useTaskFormFields } from 'src/modules/utils/hooks/checklist';
import { updateChecklistTaskScheme } from 'src/modules/schemes/checklist';
import {
  composeDate,
  parse,
  parseISO,
  format,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
} from 'src/modules/utils/dateWrapper';
import {
  DeleteRecurringTaskModal,
  DeletionType,
} from './DeleteRecurringTaskModal';
import { excludeDateFromRecurrence } from 'src/modules/utils/rRuleWrapper';
import { TaskFormTour } from '../tours/TaskFormTour';

interface IUpdateTaskProps {
  taskData: IUpdateChecklistTaskData;
  onClose: () => void;
  onSubmit: (data: IUpdateChecklistTaskData) => void;
  onDelete: () => void;
}

export const UpdateTask: React.FC<IUpdateTaskProps> = ({
  taskData,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = React.useState(
    false,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const parsedTaskData = {
    ...taskData,
    recurrenceStartDate:
      taskData.recurrenceStartDate ??
      composeDate(
        taskData.startTime,
        parse(DATETIME_TIMEZONE),
        format(DATE_FORMAT),
      ),
    done: Boolean(taskData.done),
  };

  const validate = useValidate(updateChecklistTaskScheme, {
    allowUnionTypes: true,
  });

  const formik = useFormik({
    validate,
    enableReinitialize: true,
    initialValues: parsedTaskData,
    onSubmit: (data) => {
      onSubmit(data);
      dispatch(
        updateChecklistTaskRequest({
          id: Number(data.id),
          title: data.title,
          text: data.text,
          done: data.done,
          startTime: data.startTime,
          endTime: data.endTime,
          checklistId: data.checklistId,
          recurrence: data.recurrence,
          checklistTaskId: data.checklistTaskId,
          recurrenceStartDate: data.recurrenceStartDate,
        }),
      );
    },
  });

  const onTaskDelete = () => {
    if (taskData.recurrence) {
      setIsDeleteTaskModalOpen(true);
      return;
    }

    dispatch(deleteChecklistTaskRequest(Number(taskData.id)));

    onDelete();
  };

  const onRecurringTaskDelete = (deletionType: DeletionType) => {
    if (deletionType === 'all') {
      dispatch(deleteChecklistTaskRequest(Number(taskData.checklistTaskId)));
    }

    if (deletionType === 'current' && taskData.recurrence) {
      const updatedRecurrence = excludeDateFromRecurrence(
        taskData.recurrence,
        parseISO(taskData.startTime),
      );

      dispatch(
        updateChecklistTaskRequest({
          id: Number(taskData.id),
          title: taskData.title,
          text: taskData.text,
          done: taskData.done,
          startTime: taskData.startTime,
          endTime: taskData.endTime,
          checklistId: taskData.checklistId,
          recurrence: updatedRecurrence,
          checklistTaskId: taskData.checklistTaskId,
          recurrenceStartDate: taskData.recurrenceStartDate,
          hasRecurrenceBeenRemoved: true,
        }),
      );
      // If recurring task already exists in DB we need to remove it
      if (Number(taskData.id)) {
        dispatch(deleteChecklistRecurringTaskRequest(Number(taskData.id)));
      }
    }

    setIsDeleteTaskModalOpen(false);
    onDelete();
  };

  const fieldsForUpdateTaskForm = useTaskFormFields(formik, taskData);

  return (
    <>
      <Box
        component="form"
        noValidate
        sx={{ maxWidth: 450, padding: 2 }}
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 0.5,
          }}
        >
          <TaskFormTour />
          <Box>
            <IconButton onClick={onTaskDelete} size="large">
              <DeleteForever color="error" />
            </IconButton>
            <IconButton onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Box>
        </Box>

        {fieldsForUpdateTaskForm}

        <FormActionsContainer>
          <SubmitButton>{t('common.update')}</SubmitButton>
        </FormActionsContainer>
      </Box>
      <DeleteRecurringTaskModal
        isOpen={isDeleteTaskModalOpen}
        onClose={() => setIsDeleteTaskModalOpen(false)}
        onConfirmDeletion={onRecurringTaskDelete}
      />
    </>
  );
};
