import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { commonRowsPerPage } from '../../../components/EnhancedTable';
import { QS_PARSER_DEFAULT_DEPTH } from '../../../config';
import React from 'react';

// There is a limitation of max elements in array up to 20 according to "qs" documentation
export const DEFAULT_ARRAY_LIMIT =
  commonRowsPerPage[commonRowsPerPage.length - 1];

/**
 * A custom hook to fetch query string data from current url
 */
export const useQueryParams = (limit?: number) => {
  const { search } = useLocation();
  let arrayLimit = limit ?? DEFAULT_ARRAY_LIMIT;
  const searchLength = search.split('&').length;

  /**
   * so far as we don't need limitArray, but by 'qs' default it equals 20,
   * we need to define limit according search params
   */
  if (searchLength && searchLength > arrayLimit) {
    arrayLimit = searchLength;
  }

  return React.useMemo(() => {
    return qs.parse(search, {
      ignoreQueryPrefix: true,
      arrayLimit,
      allowDots: true,
      depth: QS_PARSER_DEFAULT_DEPTH,
      decoder(str, decoder, charset) {
        const strWithoutPlus = str.replace(/\+/g, ' ');
        if (charset === 'iso-8859-1') {
          // unescape never throws, no try...catch needed:
          return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, decodeURI);
        }

        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined,
        };

        if (str in keywords) {
          return keywords[str];
        }

        // utf-8
        try {
          return decodeURIComponent(strWithoutPlus);
        } catch (e) {
          return strWithoutPlus;
        }
      },
    });
  }, [arrayLimit, search]);
};
