import React from 'react';
// import { useTranslation } from 'react-i18next';

// import {
// usePayboardSummaryByWorkWeek,
// useQueryParams,
// } from 'src/modules/utils';
// import { IFilter } from 'src/modules/types';

// import BasePayboardSummaryByWorkWeek from './BasePayboardSummaryByWorkWeek';

const PayboardSummaryByWorkWeek: React.FC = () => {
  // const { t } = useTranslation();
  // const filter = useQueryParams() as IFilter;
  // const { reportData, tableHeadersConfig } = usePayboardSummaryByWorkWeek(
  //   filter,
  // );
  return null;

  // return (
  //   <BasePayboardSummaryByWorkWeek
  //     url="/payboard/daily-summary-by-work-week"
  //     data={reportData}
  //     headCellsConfig={tableHeadersConfig}
  //     filter={filter}
  //     tableName={t('payboard.summary_by_work_week.title')}
  //   />
  // );
};

export default PayboardSummaryByWorkWeek;
