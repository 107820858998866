import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { getOptiturnCredentialsByIds } from '../../selectors/optiturn-credentials.selector';
import { IdsArray, IStoreState } from '../../types';
import { getOptiturnCredentialsListRequest } from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import { useSiteRelationInclusion } from '.';

export const optiturnCredentialsInclusions = [
  {
    relation: 'site',
  },
];

/**
 * A custom hook to fetch optiturnCredentials from store if they exist otherwise to make a request to fetch needed optiturnCredentials from
 * the server
 */
export const useFetchOptiturnCredentialsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch optiturnCredentials list from store
  const optiturnCredentials = useSelector(
    (state) => getOptiturnCredentialsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(optiturnCredentials)) {
      dispatcher(
        getOptiturnCredentialsListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: optiturnCredentialsInclusions,
          },
        }),
      );
    }
  };
};

export const useOptiturnCredentialsDefaultFilters = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteRelationInclusion],
    }),
    [siteRelationInclusion],
  );
};
