import React from 'react';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  dynamicPathsWithRegExp,
  headerTooltipToPageTitleMapper,
} from '../../../config';
import { privateRoutes } from '../../../config/privateRoutes';

export const useGetPageTitle = () => {
  const { t } = useTranslation();
  const { pathname: rawPathName } = useLocation();

  const dynamicPathWithRegexp = dynamicPathsWithRegExp.find((pathObj) =>
    pathObj.regExp.test(rawPathName),
  );

  const pathname = dynamicPathWithRegexp
    ? dynamicPathWithRegexp.path
    : rawPathName;
  const pageTitleKey = get(privateRoutes, [pathname, 'title'], '');

  if (pageTitleKey) {
    return t(pageTitleKey);
  }

  return '';
};

export const useGetPageTooltip = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pageTitleKey = headerTooltipToPageTitleMapper[pathname];

  if (pageTitleKey) {
    return t(pageTitleKey);
  }

  return '';
};

export const useFormLayoutData = (
  isFormValid: boolean,
  handleFormSubmit: (event: React.FormEvent) => void,
) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onFormSubmit = (e: React.FormEvent) => {
    setIsSubmitting(true);
    handleFormSubmit(e);
  };

  React.useCallback(() => {
    if (!isFormValid && isSubmitting) {
      setIsSubmitting(false);
    }
  }, [isFormValid, setIsSubmitting, isSubmitting]);

  return { onFormSubmit, isSubmitting };
};
