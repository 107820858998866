import React from 'react';
import { PageContentChildContainer } from 'src/components/PageContent';
import {
  ExecutiveSummaryFilterPanel,
  IExecutiveSummaryFilters,
  executiveSummaryFilterInitialData,
} from './ExecutiveSummaryFilterPanel';
import { Box } from '@mui/joy';
import { ExecutiveSummaryWeeklyGrossMarginChart } from './ExecutiveSummaryWeeklyGrossMarginChart';
import { ExecutiveSummaryRevenueCostChart } from './ExecutiveSummaryRevenueCostChart';
import { ExecutiveSummaryFinancialTable } from './ExecutiveSummaryFinancialTable';
import {
  ChartsDashboard,
  ChartsDashboardBody,
  ChartsDashboardChart,
  ChartsDashboardChartsContainer,
} from '../../../../components/ChartsDashboard';
import { ExecutiveSummaryWeeklyActiveInactiveHired } from './ExecutiveSummaryWeeklyActiveInactiveHired';
import { ExecutiveSummaryRecruitingReportTable } from './ExecutiveSummaryRecruitingReportTable';
import { TableContextProvider } from '../../../../components/Table/TableContext';

export const ExecutiveDashboard = () => {
  const [pageFilters, setPageFilters] = React.useState<
    IExecutiveSummaryFilters
  >(executiveSummaryFilterInitialData);

  return (
    <Box
      sx={{
        mx: -2,
        flexGrow: 1,
      }}
    >
      <PageContentChildContainer fullHeight={false}>
        <ExecutiveSummaryFilterPanel
          onApplyFilters={(filters) => {
            setPageFilters(filters);
          }}
          onRemoveAllFilters={() =>
            setPageFilters({
              ...executiveSummaryFilterInitialData,
              yearWeek: undefined,
            })
          }
        />
      </PageContentChildContainer>

      <PageContentChildContainer fullHeight={false} sx={{ pt: 1 }}>
        <ExecutiveSummaryFinancialTable
          siteId={pageFilters.siteId}
          yearWeek={pageFilters.yearWeek}
        />
      </PageContentChildContainer>

      <PageContentChildContainer fullHeight={false}>
        <ChartsDashboard>
          <ChartsDashboardBody>
            <ChartsDashboardChartsContainer>
              <ChartsDashboardChart>
                <ExecutiveSummaryWeeklyGrossMarginChart
                  siteId={pageFilters.siteId}
                />
              </ChartsDashboardChart>
            </ChartsDashboardChartsContainer>
            <ChartsDashboardChartsContainer>
              <ChartsDashboardChart>
                <ExecutiveSummaryRevenueCostChart siteId={pageFilters.siteId} />
              </ChartsDashboardChart>
            </ChartsDashboardChartsContainer>
          </ChartsDashboardBody>

          <ChartsDashboardChart>
            <ExecutiveSummaryWeeklyActiveInactiveHired
              siteId={pageFilters.siteId}
            />
          </ChartsDashboardChart>
        </ChartsDashboard>
      </PageContentChildContainer>

      <PageContentChildContainer fullHeight={false} sx={{ pt: 1 }}>
        <TableContextProvider>
          <ExecutiveSummaryRecruitingReportTable />
        </TableContextProvider>
      </PageContentChildContainer>
    </Box>
  );
};
