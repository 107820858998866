import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deletePointTypeRequest,
  exportPointTypeRequest,
  getPointTypeCountRequest,
  getPointTypeListRequest,
} from '../../modules/actions';
import {
  getPointTypeCount,
  getPointTypeRefreshKey,
  getPointTypesTable,
} from '../../modules/selectors/pointType';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useSearch,
  useCreateExportProps,
  useFilter,
  usePointTypesDefaultFilters,
  useBrowserHistoryFunctions,
} from '../../modules/utils/hooks';
import { map } from 'lodash';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, useHasUserAccessToAction } from '../../config';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const SHORT_REASON_LENGTH = 50;

const PointTypesList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.pointType.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.pointType.update.id,
  );

  const defaultFilter = usePointTypesDefaultFilters();

  // fetch PointType list
  const list = useSelector(getPointTypesTable, shallowEqual);
  const exportProps = useCreateExportProps(exportPointTypeRequest);

  // computed list with icons
  const computedList = useMemo(
    () =>
      map(list, (item) => ({
        ...item,
        reason:
          item.reason && item.reason?.length > SHORT_REASON_LENGTH ? (
            <Tooltip title={item.reason}>
              <span>
                {item.reason.substring(0, SHORT_REASON_LENGTH) + '...'}
              </span>
            </Tooltip>
          ) : (
            item.reason
          ),
      })),
    [list],
  );

  const count = useSelector(getPointTypeCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'reason', disablePadding: false, label: t('point_types.reason') },
    { id: 'site.name', disablePadding: false, label: t('point_types.site') },
    { id: 'point', disablePadding: false, label: t('point_types.point') },
    {
      id: 'lifetime',
      disablePadding: false,
      label: t('point_types.points_lifetime'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'reason',
      label: t('point_types.reason'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('point_types.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'point',
      label: t('point_types.point'),
      operator: 'like',
    },
  ];

  // make request to fetch pointTypes when component is mounted
  useEffect(() => {
    dispatcher(getPointTypeListRequest(filterList));
    // get total count
    dispatcher(getPointTypeCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getPointTypeListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getPointTypeCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deletePointTypeRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: '/point-types/update', search });
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={computedList}
          count={count}
          selectIndex="id"
          tableName={t('point_types.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          include={defaultFilter.include}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.PointType}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function PointTypesListRefreshable() {
  return <PointTypesList key={useSelector(getPointTypeRefreshKey)} />;
}
