import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IRemovePoint } from '../../types';

export const removePointScheme: JSONSchemaType<IRemovePoint> = {
  type: 'object',
  required: ['id', 'removalReason'],
  properties: {
    id: {
      type: 'number',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    removalReason: {
      type: 'string',
      isNotEmpty: true,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    removalReasonAttachments: {
      nullable: true,
      type: 'array',
      items: {
        type: 'object',
        required: ['preview'],
        properties: {
          preview: {
            type: 'string',
            isNotEmpty: true,
          },
          file: {
            nullable: true,
            required: [],
            type: 'object',
          },
        },
      },
    },
  },
};

export const removePointsSchema: JSONSchemaType<Array<IRemovePoint>> = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id', 'removalReason'],
    properties: {
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      removalReason: {
        type: 'string',
        isNotEmpty: true,
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      removalReasonAttachments: {
        nullable: true,
        type: 'array',
        items: {
          type: 'object',
          required: ['preview'],
          properties: {
            preview: {
              type: 'string',
              isNotEmpty: true,
            },
            file: {
              nullable: true,
              required: [],
              type: 'object',
            },
          },
        },
      },
    },
  },
};
