import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import { getDeleteRelationsRequest } from '../../actions/deleteRelations';
import { getDeleteRelations } from '../../selectors/deleteRelations';

/**
 * A custom hook to fetch delete relations from store if they exist
 * otherwise to make a request to fetch delete relations
 * for particular model from the server
 */
export const useFetchDeleteRelations = (modelName: string) => {
  const dispatcher = useDispatch();
  // fetch delete relations from store
  const relations = useSelector(
    (state) => getDeleteRelations(state as IStoreState)(modelName),
    shallowEqual,
  );

  return () => {
    if (isEmpty(relations) && !isEmpty(modelName)) {
      dispatcher(getDeleteRelationsRequest(modelName));
    }
  };
};
