import React from 'react';
import { Navigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash';
import { AuthLayout } from '../components/Layout/AuthLayout';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MUI_SIZE_12 } from '../config';
import {
  getPasswordUpdatedStatus,
  isLoggedIn,
} from '../modules/selectors/auth';
import { isDataRequested } from '../modules/selectors/auth';
import { confirmForgotPasswordRequest } from '../modules/actions/auth';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../components/Formik/FormikTextField';
import { SubmitButton } from '../components/Form/SubmitButton';
import { useValidate } from '../modules/utils';
import { useFormik } from 'formik';
import { confirmForgotPasswordScheme } from '../modules/schemes/confirm-forgot-password.scheme';
import { FormActionsContainer } from '../components/Form/FormActionsContainer';
import { Box } from '@mui/material';

const initialValues = {
  email: '',
  newPassword: '',
  confirmNewPassword: '',
};

export default function ConfirmForgotPassword() {
  const { resetToken } = useParams<{ resetToken: string }>();

  const validate = useValidate(confirmForgotPasswordScheme);

  const dispatcher = useDispatch();

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      if (!resetToken) {
        return;
      }

      dispatcher(
        confirmForgotPasswordRequest({
          ...omit(data, ['confirmNewPassword']),
          resetToken,
        }),
      );
    },
  });

  const { t } = useTranslation();

  // check if a user is logged in
  const is_logged_in = useSelector(isLoggedIn, shallowEqual);

  // check if password has been restored successfully
  const password_updated_status = useSelector(
    getPasswordUpdatedStatus,
    shallowEqual,
  );

  // check if data is requested to show preloader
  const isLoading = useSelector(isDataRequested, shallowEqual);

  if (is_logged_in) {
    return <Navigate to="/" />;
  }

  const output = password_updated_status ? (
    <Alert severity="success" sx={{ marginTop: 4 }}>
      <AlertTitle>Success</AlertTitle>
      New password is set successfully. Now you can{' '}
      <Link href="/login" variant="body2">
        {t('auth.sign_in')}
      </Link>
    </Alert>
  ) : (
    <Box
      component="form"
      sx={{
        width: '100%', // Fix IE 11 issue.
        marginTop: 1,
      }}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <FormikTextField
        variant="outlined"
        required
        fullWidth
        id="email"
        label={t('auth.forgot.email')}
        name="email"
        autoComplete="email"
        autoFocus
        formik={formik}
      />
      <FormikTextField
        variant="outlined"
        required
        fullWidth
        name="newPassword"
        label={t('auth.forgot.new_password')}
        type="password"
        id="newPassword"
        autoComplete="current-password"
        formik={formik}
      />
      <FormikTextField
        variant="outlined"
        required
        fullWidth
        name="confirmNewPassword"
        label={t('auth.forgot.confirm_new_password')}
        type="password"
        id="confirmNewPassword"
        autoComplete="current-password"
        formik={formik}
      />

      <FormActionsContainer>
        <SubmitButton fullWidth isLoading={isLoading}>
          {t('common.submit')}
        </SubmitButton>
      </FormActionsContainer>
      <Grid container>
        <Grid item xs={MUI_SIZE_12}>
          <Link href="/login" variant="body2">
            {t('auth.sign_in')}
          </Link>
        </Grid>
        <Grid item>
          <Link href="/register" variant="body2">
            {t('auth.suggest_signup')}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );

  return <AuthLayout>{output}</AuthLayout>;
}
