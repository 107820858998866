import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getEmployeesByIds,
  getEmployeesComboboxList,
  getEmployeesComboboxListWithBadgeAsId,
  getEmployeesComboboxListWithEmployeeIdAsId,
} from '../../selectors/employee';
import { IdsArray, IEmployeeModel, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getEmployeeComboboxListRequest,
  getEmployeeListRequest,
} from '../../actions/employee';
import { defaultOrderDetails } from '../helpers/filter';
import { useSiteRelationInclusion, useSupervisorCombobox } from '.';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import * as actions from '../../actions';
import { useTranslation } from 'react-i18next';
import {
  IHeadCellWithOrderConfig,
  IInclusionObject,
  InclusionPath,
} from 'src/modules/types/table';
import { useFetchPositionsCombobox } from './positions';
import { useFetchStaffingProvidersCombobox } from './staffingProvider';
import { useFetchShiftsCombobox } from './shifts';
import { useFetchActiveDepartmentsCombobox } from './departments';
import { useFetchSitesCombobox } from './sites';
import { useFetchTerminationReasonsCombobox } from './terminationReasons';
import { ComboBoxOptionWithEntity } from '../../../components/ComboBox';

export const useEmployeesInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => [
      {
        relation: 'position',
      },
      {
        relation: 'supervisor',
      },
      {
        relation: 'staffing',
      },
      {
        relation: 'defaultDepartment',
      },
      {
        relation: 'defaultShift',
      },
      {
        relation: 'terminationReason',
        relationType: 'left',
      },
      siteRelationInclusion,
    ],
    [siteRelationInclusion],
  );
};

const useEmployeeComboboxFilter = (onlyActive = true, siteId?: number) => {
  const employeeInclusions = useEmployeesInclusion();

  return React.useMemo(
    () => ({
      filter: {
        fields: {
          id: true,
          firstName: true,
          lastName: true,
          badge: true,
          siteId: true,
          employeeId: true,
          defaultShiftId: true,
          defaultDepartmentId: true,
        },
        where: {
          active: onlyActive ? true : { in: [true, false] },
          ...(siteId ? { siteId } : {}),
        },
        include: employeeInclusions,
        order: ['lastName ASC'],
      },
    }),
    [employeeInclusions, onlyActive, siteId],
  );
};

/**
 * A custom hook to fetch employees from store if they exist otherwise make a request to fetch needed employees from
 * the server
 */
export const useFetchEmployeesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch employees list from store
  const employees = useSelector(
    (state) => getEmployeesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  const employeeInclusions = useEmployeesInclusion();

  return () => {
    if (isEmpty(employees)) {
      dispatcher(
        getEmployeeListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: employeeInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch employees combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchEmployeesCombobox = (siteId?: number) => {
  const dispatcher = useDispatch();
  const employeeComboboxFilter = useEmployeeComboboxFilter(true, siteId);

  const dispatchGetEmployeeComboboxListRequest = React.useCallback(() => {
    dispatcher(getEmployeeComboboxListRequest(employeeComboboxFilter));
  }, [employeeComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetEmployeeComboboxListRequest();
  }, [dispatchGetEmployeeComboboxListRequest]);

  // fetch combobox list from store
  const combobox = useSelector(getEmployeesComboboxList);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetEmployeeComboboxListRequest();
    }
  };
};
export const useFetchEmployeesComboboxWithBadgeAsId = () => {
  const dispatcher = useDispatch();
  const employeeComboboxFilter = useEmployeeComboboxFilter();
  // fetch combobox list from store
  const combobox = useSelector(getEmployeesComboboxListWithBadgeAsId);

  const dispatchGetEmployeeComboboxListRequest = React.useCallback(() => {
    dispatcher(getEmployeeComboboxListRequest(employeeComboboxFilter));
  }, [employeeComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetEmployeeComboboxListRequest();
  }, [dispatchGetEmployeeComboboxListRequest]);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetEmployeeComboboxListRequest();
    }
  };
};
export interface IUseGenerateEmployeeFilterArgs {
  onlyActive?: boolean;
}

export interface IUseEmployeesInComboBox
  extends IUseGenerateEmployeeFilterArgs {
  propertyAsID?: 'badge' | 'id' | 'employeeId' | string;
}
export const useEmployeesInComboBox = ({
  propertyAsID = 'id',
  onlyActive,
}: IUseEmployeesInComboBox) => {
  const dispatcher = useDispatch();

  const filter = useEmployeeComboboxFilter(onlyActive);
  let selector: any;

  switch (propertyAsID) {
    case 'id':
      selector = getEmployeesComboboxList;
      break;
    case 'badge':
      selector = getEmployeesComboboxListWithBadgeAsId;
      break;
    case 'employeeId':
      selector = getEmployeesComboboxListWithEmployeeIdAsId;
      break;
  }

  const employees = useSelector(selector) as ComboBoxOptionWithEntity<
    IEmployeeModel
  >[];

  React.useEffect(() => {
    dispatcher(actions.getEmployeeComboboxListRequest(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return employees;
};

export const useEmployeesDefaultFilters = () => {
  const include = useEmployeesInclusion();

  return React.useMemo(
    () => ({
      where: {
        active: true,
      },
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};

export const useActiveButNotPresentEmployeesDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      where: {
        siteId: {
          inq: globallySelectedSiteIds,
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};
export const useTerminatedEmployeesDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      where: {
        siteId: {
          inq: globallySelectedSiteIds,
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

export const useHookyFolksDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      order: [`date ${defaultOrderDetails.order}`],
      where: {
        siteId: {
          inq: globallySelectedSiteIds,
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

export const useHistoricalHookyFolksDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      order: [`date ${defaultOrderDetails.order}`],
      where: {
        siteId: {
          inq: globallySelectedSiteIds,
        },
      },
    }),
    [globallySelectedSiteIds],
  );
};

export const useEmployeesHeadCellsConfig = (): IHeadCellWithOrderConfig[] => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return [
      {
        id: 'employeeId',
        label: t('employees.emp_id'),
        orderConfig: { orderBy: 'employeeId' },
      },
      {
        id: 'site.name',
        label: t('employees.site'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'position.name',
        label: t('employees.position'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['position', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'staffing.staffingProvider',
        label: t('employees.staffing_provider'),
        orderConfig: {
          orderBy: 'staffingProvider',
          orderByInclusionPath: ['staffing', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'supervisor.firstName',
        label: t('employees.supervisor_fname'),
        orderConfig: {
          orderBy: 'firstName',
          orderByInclusionPath: ['supervisor', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'supervisor.lastName',
        label: t('employees.supervisor_lname'),
        orderConfig: {
          orderBy: 'lastName',
          orderByInclusionPath: ['supervisor', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'firstName',
        label: t('employees.first_name'),
        orderConfig: {
          orderBy: 'firstName',
        },
      },
      {
        id: 'lastName',
        label: t('employees.last_name'),
        orderConfig: {
          orderBy: 'lastName',
        },
      },
      {
        id: 'payRate',
        label: t('employees.pay_rate'),
        orderConfig: {
          orderBy: 'payRate',
        },
      },
      {
        id: 'dateHired',
        label: t('employees.date_hired'),
        orderConfig: {
          orderBy: 'dateHired',
        },
      },
      {
        id: 'badge',
        label: t('employees.badge'),
        orderConfig: {
          orderBy: 'badge',
        },
      },
      {
        id: 'separationdate',
        label: t('employees.separation_date'),
        orderConfig: {
          orderBy: 'separationdate',
        },
      },
      {
        id: 'defaultShift.name',
        label: t('employees.default_shift'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['defaultShift', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'defaultDepartment.name',
        label: t('employees.default_department'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['defaultDepartment', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'active',
        label: t('employees.active'),
        orderConfig: {
          orderBy: 'active',
        },
      },
      {
        id: 'address',
        label: t('employees.address'),
        orderConfig: {
          orderBy: 'address',
        },
      },
      {
        id: 'phonenumber',
        label: t('employees.phone'),
        orderConfig: {
          orderBy: 'phonenumber',
        },
      },
      {
        id: 'dateentered',
        label: t('employees.date_entered'),
        orderConfig: {
          orderBy: 'dateentered',
        },
      },
      {
        id: 'last4SSN',
        label: t('employees.4ssn'),
        orderConfig: {
          orderBy: 'last4SSN',
        },
      },
      {
        id: 'wmsLogin',
        label: t('employees.wms_login'),
        orderConfig: {
          orderBy: 'wmsLogin',
        },
      },
      {
        id: 'terminationReason.name',
        label: t('employees.termination_reason'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['terminationReason', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'canRehire',
        label: t('employees.can_rehire'),
        orderConfig: {
          orderBy: 'canRehire',
        },
      },
      {
        id: 'wotc',
        label: t('employees.wotc'),
        orderConfig: {
          orderBy: 'wotc',
        },
      },
      {
        id: 'emergencyContact',
        label: t('employees.emergency_contact'),
        orderConfig: {
          orderBy: 'emergencyContact',
        },
      },
      {
        id: 'comments',
        label: t('employees.comments'),
        orderConfig: {
          orderBy: 'comments',
        },
      },
    ];
  }, [t]);
};

export const useEmployeesFilterFieldsConfiguration = () => {
  return React.useMemo(() => {
    return {
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      positionId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['position', 'scope'] as InclusionPath,
      },
      firstName: {
        value: '',
        property: 'firstName',
        operator: 'like' as const,
      },
      lastName: {
        value: '',
        property: 'lastName',
        operator: 'like' as const,
      },
      payRate: {
        value: '',
        property: 'payRate',
        operator: 'like' as const,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
    };
  }, []);
};

interface IUseEmployeesInclusionObjectArgs {
  onlyRemovedEntities?: boolean;
}
export const useEmployeesInclusionObject = (
  { onlyRemovedEntities }: IUseEmployeesInclusionObjectArgs = {
    onlyRemovedEntities: false,
  },
): IInclusionObject => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const relationType = onlyRemovedEntities ? 'left' : 'inner';

  return React.useMemo(() => {
    return {
      position: {
        relationType,
      },
      supervisor: {
        relationType,
      },
      staffing: {
        relationType,
      },
      defaultDepartment: {
        relationType,
      },
      defaultShift: {
        relationType,
      },
      terminationReason: {
        relationType: 'left',
      },
      site: {
        relationType,
        scope: onlyRemovedEntities
          ? undefined
          : {
              where: {
                id: {
                  inq: globallySelectedSiteIds,
                },
              },
            },
      },
    };
  }, [relationType, onlyRemovedEntities, globallySelectedSiteIds]);
};

export const useEmployeesDefaultWhereForRemovedEntities = () => {
  return React.useMemo(
    () => ({
      deletedAt: {
        notnull: true,
      },
    }),
    [],
  );
};

export const useEmployeeComboboxesDataFetchersList = () => {
  const fetchPositionsCombobox = useFetchPositionsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchTerminationReasonCombobox = useFetchTerminationReasonsCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();

  return [
    fetchDepartmentsCombobox,
    fetchPositionsCombobox,
    fetchShiftsCombobox,
    fetchSitesCombobox,
    fetchStaffingProvidersCombobox,
    fetchTerminationReasonCombobox,
    fetchSupervisorsCombobox,
  ];
};

export const useFetchEmployeesComboBoxDataEffect = () => {
  const fetchPositionsCombobox = useFetchPositionsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchTerminationReasonCombobox = useFetchTerminationReasonsCombobox();

  React.useEffect(() => {
    fetchPositionsCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchTerminationReasonCombobox();
    fetchSitesCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
