import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@mui/joy';
import { FilterFunnel02Svg } from 'src/components/svgIcons';

export interface IFilterActionsTriggers {
  handleFilterIconClick: () => void;
  handleClearFiltersIconClick?: () => void;
}
export const FilterActionsTriggers: React.FC<IFilterActionsTriggers> = ({
  handleFilterIconClick,
}) => {
  const { t } = useTranslation();

  return (
    <IconButton sx={{ mr: 1 }} onClick={handleFilterIconClick}>
      <FilterFunnel02Svg />
      <Typography
        sx={{ ml: 0.5 }}
        level="text_sm"
        fontWeight="semi_bold"
        textColor="colors.buttons.tertiary.button_tertiary_fg"
      >
        {t('common.filters')}
      </Typography>
    </IconButton>
  );
};
