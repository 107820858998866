import React from 'react';
import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { getISOWeek, getYear, parseISO, subYears } from 'date-fns';
import { IConcatenateYearWeekArgs } from '../../../../modules/utils/helpers/formatters';

interface IYearWeekComboboxProps extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  optionsToYear?: number;
  optionsToWeek?: number;
  optionsFromYear?: number;
  optionsFromWeek?: number;
  optionsOrder?: 'desc' | 'asc';
}

const formatOptionName = ({ year, week }: IConcatenateYearWeekArgs) => {
  return `${year}-W${String(week).padStart(2, '0')}`;
};

const formatOptionId = ({ year, week }: IConcatenateYearWeekArgs) => {
  return Number(`${year}${String(week).padStart(2, '0')}`);
};

const FIRST_WEEK = 1;
const currentYear = getYear(new Date());
const currentWeek = getISOWeek(new Date());
const tenYearsAgo = getYear(subYears(new Date(), 10));

const generateYearWeeks = ({
  optionsFromYear = Number(tenYearsAgo),
  optionsFromWeek = FIRST_WEEK,
  optionsToYear = currentYear,
  optionsToWeek = currentWeek,
}): Array<ComboBoxOption<number>> => {
  const options: Array<ComboBoxOption<number>> = [];

  for (let year = optionsFromYear; year <= optionsToYear; year++) {
    const lastWeekDate = parseISO(`${year}-12-28`);
    const lastWeek = getISOWeek(lastWeekDate);

    let weekToCompareWith = lastWeek;
    if (year === optionsToYear) {
      weekToCompareWith = optionsToWeek;
    }

    let week = year === optionsFromYear ? optionsFromWeek : FIRST_WEEK;
    while (week <= weekToCompareWith) {
      options.push({
        id: formatOptionId({ year, week }),
        name: formatOptionName({ year, week }),
      });

      week++;
    }
  }

  return options;
};

const YearWeekFilterComboBox: React.FC<IYearWeekComboboxProps> = ({
  optionsFromYear = Number(tenYearsAgo),
  optionsFromWeek = FIRST_WEEK,
  optionsToYear = currentYear,
  optionsToWeek = currentWeek,
  optionsOrder = 'desc',
  ...props
}) => {
  const options = React.useMemo(() => {
    return optionsOrder === 'asc'
      ? generateYearWeeks({
          optionsFromYear,
          optionsFromWeek,
          optionsToYear,
          optionsToWeek,
        })
      : generateYearWeeks({
          optionsFromYear,
          optionsFromWeek,
          optionsToYear,
          optionsToWeek,
        }).reverse();
  }, [
    optionsFromWeek,
    optionsFromYear,
    optionsOrder,
    optionsToWeek,
    optionsToYear,
  ]);

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
      slotProps={{
        input: {
          sx: {
            width: '100%',
          },
        },
      }}
    />
  );
};

export default YearWeekFilterComboBox;
