import React from 'react';
import { Checkbox, ICheckboxProps } from 'src/components/_ui-kit/Checkbox';

export type ISelectAllCheckboxCellProps = ICheckboxProps;

export const SelectAllCheckboxCell: React.FC<ISelectAllCheckboxCellProps> = ({
  checked,
  onChange,
  indeterminate,
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      indeterminate={indeterminate}
      slotProps={{
        input: { 'aria-label': 'select all rows' },
      }}
    />
  );
};
