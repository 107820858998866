import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IApplicantModel, IApplicantState, ICountResponse } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IApplicantState = {
  refreshKey: 0,
  id: NaN,
  firstName: '',
  lastName: '',
  openPositionId: NaN,
  payRate: NaN,
  dateInterview: '',
  hire: false,
  dateStart: '',
  badge: NaN,
  supervisorId: NaN,
  siteId: NaN,
  staffingId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushApplicantState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createApplicantRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createApplicantSuccess = (
  state: IApplicantState,
  payload: IApplicantModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createApplicantFailed = (state: IApplicantState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteApplicantRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteApplicantSuccess = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const deleteApplicantFailed = (state: IApplicantState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateApplicantsRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateApplicantSuccess = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const updateApplicantsFailed = (state: IApplicantState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getApplicantListRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getApplicantListSuccess = (
  state: IApplicantState,
  payload: IApplicantModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getApplicantListFailed = (state: IApplicantState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getApplicantCountRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getApplicantCountSuccess = (
  state: IApplicantState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getApplicantCountFailed = (state: IApplicantState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getApplicantComboboxListRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getApplicantComboboxListSuccess = (
  state: IApplicantState,
  payload: Partial<IApplicantModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getApplicantComboboxListFailed = (
  state: IApplicantState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Hire applicants actions
 */
const hireApplicantRequest = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const hireApplicantSuccess = (state: IApplicantState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const hireApplicantFailed = (state: IApplicantState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */
const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IApplicantState>();

/**
 * Applicant reducer
 */
export const applicant = createReducer<IApplicantState>({}, defaultState)
  // flush actions
  .on(actions.flushApplicantState, flushApplicantState)
  // creation actions
  .on(actions.createApplicantRequest, createApplicantRequest)
  .on(actions.createApplicantSuccess, createApplicantSuccess)
  .on(actions.createApplicantFailed, createApplicantFailed)
  // deletion actions
  .on(actions.deleteApplicantRequest, deleteApplicantRequest)
  .on(actions.deleteApplicantSuccess, deleteApplicantSuccess)
  .on(actions.deleteApplicantFailed, deleteApplicantFailed)
  // updating actions
  .on(actions.updateApplicantsRequest, updateApplicantsRequest)
  .on(actions.updateApplicantSuccess, updateApplicantSuccess)
  .on(actions.updateApplicantsFailed, updateApplicantsFailed)
  // get list actions
  .on(actions.getApplicantListRequest, getApplicantListRequest)
  .on(actions.getApplicantListSuccess, getApplicantListSuccess)
  .on(actions.getApplicantListFailed, getApplicantListFailed)
  // get count actions
  .on(actions.getApplicantCountRequest, getApplicantCountRequest)
  .on(actions.getApplicantCountSuccess, getApplicantCountSuccess)
  .on(actions.getApplicantCountFailed, getApplicantCountFailed)
  // hire actions
  .on(actions.hireApplicantRequest, hireApplicantRequest)
  .on(actions.hireApplicantSuccess, hireApplicantSuccess)
  .on(actions.hireApplicantFailed, hireApplicantFailed)
  // get combobox list actions
  .on(actions.getApplicantComboboxListRequest, getApplicantComboboxListRequest)
  .on(actions.getApplicantComboboxListSuccess, getApplicantComboboxListSuccess)
  .on(actions.getApplicantComboboxListFailed, getApplicantComboboxListFailed)
  // export actions
  .on(actions.exportApplicantRequest, exportRequest)
  .on(actions.exportApplicantSuccess, exportSuccess)
  .on(actions.exportApplicantFailed, exportFailed);
export default applicant;
