import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deleteStaffingProviderRequest,
  exportStaffingProviderRequest,
  getStaffingProviderCountRequest,
  getStaffingProviderListRequest,
} from '../../modules/actions';
import {
  getStaffingProviderCount,
  getStaffingProviderList,
  getStaffingProviderRefreshKey,
} from '../../modules/selectors/staffingProvider';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import { useTranslation } from 'react-i18next';
import {
  useSearch,
  useCreateExportProps,
  useFilter,
  useBrowserHistoryFunctions,
} from '../../modules/utils';
import { ModelsToDelete, useHasUserAccessToAction } from '../../config';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const DEFAULT_ORDER_BY = 'id';
const DEFAULT_ORDER = 'desc';

const defaultFilter = {
  order: [`${DEFAULT_ORDER_BY} ${DEFAULT_ORDER}`],
};

const StaffingProvidersList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.staffingProvider.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.staffingProvider.update.id,
  );

  // fetch StaffingProvider list
  const list = useSelector(getStaffingProviderList, shallowEqual);
  const exportProps = useCreateExportProps(exportStaffingProviderRequest);
  const count = useSelector(getStaffingProviderCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'staffingProvider',
      disablePadding: false,
      label: t('staffing_prov.name'),
    },
    { id: 'markup', disablePadding: false, label: t('staffing_prov.markup') },
    {
      id: 'ngroupMarkup',
      disablePadding: false,
      label: t('staffing_prov.ng_markup'),
    },
    {
      id: 'ptoMarkup',
      disablePadding: false,
      label: t('staffing_prov.pto_markup'),
    },
    {
      id: 'holidayMarkup',
      disablePadding: false,
      label: t('staffing_prov.holiday_markup'),
    },
    {
      id: 'etoMarkup',
      disablePadding: false,
      label: t('staffing_prov.eto_markup'),
    },
    {
      id: 'securityName',
      disablePadding: false,
      label: t('staffing_prov.sec_name'),
    },
    {
      id: 'otMarkup',
      disablePadding: false,
      label: t('staffing_prov.ot_markup'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'staffingProvider',
      label: t('staffing_prov.name'),
      operator: 'like',
    },
    {
      name: 'markup',
      label: t('staffing_prov.markup'),
      operator: 'like',
    },
    {
      name: 'ngroupMarkup',
      label: t('staffing_prov.ng_markup'),
      operator: 'like',
    },
    {
      name: 'ptoMarkup',
      label: t('staffing_prov.pto_markup'),
      operator: 'like',
    },
    {
      name: 'holidayMarkup',
      label: t('staffing_prov.holiday_markup'),
      operator: 'like',
    },
    {
      name: 'etoMarkup',
      label: t('staffing_prov.eto_markup'),
      operator: 'like',
    },
    {
      name: 'securityName',
      label: t('staffing_prov.sec_name'),
      operator: 'like',
    },
    {
      name: 'otMarkup',
      label: t('staffing_prov.ot_markup'),
      operator: 'like',
    },
  ];

  // make request to fetch staffingProviders when component is mounted
  useEffect(() => {
    dispatcher(getStaffingProviderListRequest(filterList));
    // get total count
    dispatcher(getStaffingProviderCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getStaffingProviderListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(getStaffingProviderCountRequest({ filter: { where } }));
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteStaffingProviderRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: '/staffing-providers/update', search });
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={list}
          count={count}
          selectIndex="id"
          defaultOrder={DEFAULT_ORDER}
          defaultOrderBy={DEFAULT_ORDER_BY}
          tableName={t('staffing_prov.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.StaffingProvider}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function StaffingProvidersListRefreshable() {
  return (
    <StaffingProvidersList key={useSelector(getStaffingProviderRefreshKey)} />
  );
}
