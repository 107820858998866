import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { IFilter } from '../../types';
import { getRecruitingDashboardSummaryList } from '../../selectors/recruitingDashboardSummary';
import { getRecruitingDashboardSummaryListRequest } from '../../actions';
import React from 'react';
import { useReportDefaultFilter } from './common/reports';

/**
 * A custom hook to fetch recruiting dashboard summary data list from store if it exists otherwise to make a request to fetch needed that
 * information from the server
 */
export const useFetchRecruitingDashboardSummaryAdded = (filter?: IFilter) => {
  const dispatcher = useDispatch();
  // fetch turnover added list from store
  const list = useSelector(getRecruitingDashboardSummaryList, shallowEqual);
  return () => {
    if (isEmpty(list)) {
      dispatcher(getRecruitingDashboardSummaryListRequest(filter ?? {}));
    }
  };
};

export const useRecruitingDashboardSummaryDefaultFilters = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`siteId desc`],
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};
