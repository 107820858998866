import React from 'react';
import JoyBadge from '@mui/joy/Badge';
import { Bell01Svg } from 'src/components/svgIcons';

import './badge.css';

interface IBadgeProps {
  unreadNotificationsQuantity: number;
  isShakeEnabled: boolean;
}

export const Badge: React.FC<IBadgeProps> = ({
  unreadNotificationsQuantity,
  isShakeEnabled,
}) => {
  return (
    <JoyBadge size="sm" badgeContent={unreadNotificationsQuantity}>
      <Bell01Svg className={isShakeEnabled ? 'shake' : ''} />
    </JoyBadge>
  );
};
