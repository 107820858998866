import React from 'react';
import { get } from 'lodash';
import { AnyObject, WhereOperator } from '../../modules/types';
import { ErrorMode } from './types';
import DateRangePicker, {
  IDateRangePickerProps,
  IDateRangePickerValue,
} from '../_ui-kit/Combobox/Pickers/Daterangepicker';

interface IProps extends Omit<IDateRangePickerProps, 'onChange'> {
  formik: AnyObject;
  errorMode?: ErrorMode;
  operator?: WhereOperator;
}

function FormikDateRangePicker(props: IProps) {
  const {
    id,
    formik,
    errorMode = 'onFormSubmit',
    operator,
    ...restProps
  } = props;

  const option = get(formik.values, id);
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);

  const parsedOption = React.useMemo(() => {
    // Filters of Enhanced Table returns initial data in format: { between: [<start>, <end>]},
    // but date range understands this format only: {start: <date>, end: <date>}
    if (option && operator === 'between') {
      return {
        ...option,
        start: option.between[0],
        end: option.between[1],
      };
    }

    return option || '';
  }, [operator, option]);

  // TODO: should implement errors
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  const handleChange = (values: IDateRangePickerValue) => {
    let parsedValues: any = { ...values };
    if (operator === 'between') {
      parsedValues = { ...values, between: [values.start, values.end] };
    }
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, parsedValues);
  };

  React.useEffect(() => {
    // in update entity forms we remove error manually since
    // validateOnChange false by default for all update forms
    if (errorMode === 'onFieldChange') {
      const value = get(formik.values, id);
      const error = get(formik.errors, id);

      if (Boolean(value) && Boolean(error)) {
        formik.setFieldError(id, undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMode, get(formik.values, id)]);

  return (
    <DateRangePicker
      {...restProps}
      id={id}
      value={parsedOption}
      onChange={handleChange}
    />
  );
}

export default React.memo(FormikDateRangePicker, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
