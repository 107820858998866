import React, { SetStateAction } from 'react';
import { cloneDeep, get, isEmpty, update } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SimpleActionCreator } from 'redux-act';
import { useDispatch, useSelector } from 'react-redux';
import { operatorToLabelMap } from 'src/components/Table/constants';
import {
  IFilter,
  IFilterData,
  IStoreState,
  IFilterInclude,
  IWhere,
  WhereFilter,
} from 'src/modules/types';
import {
  Order,
  IHeadCell,
  FilterValue,
  IFilterObject,
  IInclusionObject,
  IHeadCellOrderDetails,
  FilterValueDescriptor,
  IHeadCellWithOrderConfig,
} from 'src/modules/types/table';
import { useBrowserHistoryFunctions } from './browserHistory.hooks';
import { useQueryParams } from './useQueryParams';
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from '../localStorageWrapper';
import { HeadCell } from '../../../components/EnhancedTable';
import { TableContext } from 'src/components/Table/TableContext';

const allRowsPerPageMockValue = NaN;
const commonRowsPerPage = [10, 25, 50, 100, allRowsPerPageMockValue];

export const DEFAULT_PAGE = 0;
export const DEFAULT_LIMIT = commonRowsPerPage[1];

const queryParamsKeys = {
  page: 'page',
  limit: 'limit',
  order: 'order',
  orderBy: 'orderBy',
};

export const useRowsPerPageOptions = (totalCount: number) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return commonRowsPerPage.map((value) => {
      if (isNaN(value)) {
        const value = commonRowsPerPage.includes(totalCount)
          ? Math.max(...commonRowsPerPage) + 1
          : totalCount;
        return {
          value,
          label: t('common.all'),
        };
      }

      return {
        value,
        label: String(value),
      };
    });
  }, [t, totalCount]);
};

interface IUseTableCommonHandlers {
  page: number;
  limit: number;
  where?: IWhere;
  include?: IFilterInclude[];
  order?: Order;
  orderBy?: string;
  setPage: (page: number) => void;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: string) => void;
  setLimit: (limit: number) => void;
  fetchData: (filter: IFilter) => void;
}

export const useTableFilter = ({
  where,
  orderBy,
  order,
  limit,
  include,
}: Pick<
  IUseTableCommonHandlers,
  'where' | 'orderBy' | 'order' | 'limit' | 'include'
>): IFilter => {
  return React.useMemo(
    () => ({
      filter: {
        where,
        offset: DEFAULT_PAGE * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
        ...(include ? { include } : {}),
      },
    }),
    [include, limit, order, orderBy, where],
  );
};

export const useTableCommonHandlers = ({
  fetchData,
  setPage,
  setLimit,
  where,
  include,
  orderBy,
  order,
  page,
  limit,
  setOrderBy,
  setOrder,
}: IUseTableCommonHandlers) => {
  const filter = useTableFilter({ where, include, orderBy, order, limit });

  const handleChangeRowsPerPage = (limit: number) => {
    setLimit(limit);

    setPage(DEFAULT_PAGE);

    fetchData(filter);
  };

  const handleSort = (orderBy: string, order: Order) => {
    setOrderBy(orderBy);
    setOrder(order);

    fetchData({
      filter: {
        where,
        ...(limit > 0
          ? {
              offset: page * limit,
              limit,
            }
          : {}),
        order: [`${orderBy} ${order}`],
        ...(include ? { include } : {}),
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);

    fetchData({
      filter: {
        where,
        ...(limit > 0
          ? {
              offset: page * limit,
              limit,
            }
          : {}),
        order: [`${orderBy} ${order}`],
        ...(include ? { include } : {}),
      },
    });
  };

  return { handleChangeRowsPerPage, handleSort, handlePageChange };
};

export const useGenerateHeadCellsData = (
  tableCellsWithOrderConfig: IHeadCellWithOrderConfig[],
) => {
  return React.useMemo(() => {
    return tableCellsWithOrderConfig.reduce<{
      headCells: IHeadCell[];
      headCellsOrderDetails: IHeadCellOrderDetails[];
    }>(
      (result, tableCellWithOrderConfig) => {
        const { orderConfig, ...tableCell } = tableCellWithOrderConfig;
        if (orderConfig) {
          result.headCellsOrderDetails.push({
            id: tableCell.id,
            ...orderConfig,
          });
        }

        if (tableCell.label !== undefined) {
          result.headCells.push({
            ...(tableCell as any),
            disableSorting: !orderConfig,
          });
        }

        return result;
      },
      {
        headCells: [],
        headCellsOrderDetails: [],
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCellsWithOrderConfig]);
};
interface IUseTableDataArgs {
  headCellsOrderDetails: IHeadCellOrderDetails[];
  defaultLimit?: number;
  defaultPage?: number;
  defaultOrder?: Order;
  defaultOrderBy?: string;
}
export const useTableData = ({
  headCellsOrderDetails,
  defaultOrderBy,
  defaultOrder,
  defaultLimit = DEFAULT_LIMIT,
  defaultPage = DEFAULT_PAGE,
}: IUseTableDataArgs) => {
  const [page, setPage] = React.useState(defaultPage);
  const [limit, setLimit] = React.useState(defaultLimit);
  const [order, setOrder] = React.useState(defaultOrder);
  const [
    orderByDetails,
    setOrderByDetails,
  ] = React.useState<IHeadCellOrderDetails | null>(
    headCellsOrderDetails.find(({ id }) => id === defaultOrderBy) ?? null,
  );
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  const onOrderByChange = (orderById?: string) => {
    const orderByDetails = headCellsOrderDetails.find(
      ({ id }) => id === orderById,
    );

    orderByDetails && setOrderByDetails(orderByDetails);
  };

  const clearTableData = React.useCallback(() => {
    const orderByDetails =
      headCellsOrderDetails.find(({ id }) => id === defaultOrderBy) ?? null;

    setOrder(defaultOrder);
    setOrderByDetails(orderByDetails);
    setPage(defaultPage ?? DEFAULT_PAGE);
    setLimit(defaultLimit ?? DEFAULT_LIMIT);
  }, [
    headCellsOrderDetails,
    defaultOrder,
    defaultPage,
    defaultLimit,
    defaultOrderBy,
  ]);

  const isSomeTableDataChanged =
    order !== defaultOrder ||
    orderByDetails?.id !== defaultOrderBy ||
    (page !== defaultPage && page !== DEFAULT_PAGE) ||
    (limit !== defaultLimit && limit !== DEFAULT_LIMIT);

  React.useEffect(() => {
    const orderByDetails = headCellsOrderDetails.find(
      ({ id }) => id === defaultOrderBy,
    );

    setOrder(defaultOrder);

    orderByDetails && setOrderByDetails(orderByDetails);
  }, [defaultOrder, defaultOrderBy, headCellsOrderDetails]);

  React.useEffect(() => {
    setLimit(defaultLimit);
    setPage(defaultPage);
  }, [defaultLimit, defaultPage]);

  return {
    selectedRows,
    setSelectedRows,
    order,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    clearTableData,
    isSomeTableDataChanged,
    orderBy: orderByDetails?.id,
    setOrderBy: onOrderByChange,
  };
};

interface IUseFilterFieldsData<
  T extends Record<string, FilterValueDescriptor>
> {
  filterFieldsConfiguration: T;
}
export const useFilterFieldsData = <
  T extends Record<string, FilterValueDescriptor>
>({
  filterFieldsConfiguration,
}: IUseFilterFieldsData<T>) => {
  const [filterFields, setFilterFields] = React.useState(
    filterFieldsConfiguration,
  );

  const {
    filters: filterFieldsValues,
    setFilter,
    setFilters,
  } = React.useContext(TableContext);

  const getFilterCommonPropsByFilterName = React.useCallback(
    (filterName: keyof T) => {
      // Current value of a filter is stored in filters of context and
      // used to render current value in UI, but we need to trigger an action like server request
      // only after all necessary filters are set, for this purpose we update values in filterFields state
      // only after submit action
      const value: any = filterFieldsValues[filterName as any];

      return {
        value,
        name: filterName,
      };
    },
    [filterFieldsValues],
  );

  const getLabel = React.useCallback(
    ({
      filterName,
      labelPrefix,
    }: {
      filterName: keyof T;
      labelPrefix: string;
    }) => {
      const { operator } = get(filterFields, [filterName], { operator: 'eq' });

      return `${labelPrefix} ${operatorToLabelMap[operator]}`;
    },
    [filterFields],
  );

  const onFiltersFormSubmit = React.useCallback(
    (filtersData: Record<string, FilterValue>) => {
      const updatedFilters = Object.keys(filtersData).reduce<
        Record<string, FilterValueDescriptor>
      >((filtersObj, filterName) => {
        const filterFullData = filterFields[filterName];

        filtersObj[filterName] = {
          ...filterFullData,
          value: filtersData[filterName],
        };

        return filtersObj;
      }, {});

      setFilterFields(updatedFilters as SetStateAction<T>);

      return updatedFilters;
    },
    [filterFields],
  );

  const clearFilterFieldsData = React.useCallback(() => {
    setFilterFields(filterFieldsConfiguration);
  }, [setFilterFields, filterFieldsConfiguration]);

  const isSomeFilterApplied = Object.keys(filterFields).some((filterName) => {
    const currentValue = filterFields[filterName].value;
    const initialValue = filterFieldsConfiguration[filterName].value;

    return currentValue !== initialValue;
  });

  const setFilterFieldByFilterName = React.useCallback(
    ({ filterName, value }: { filterName: keyof T; value: any }) => {
      setFilter(filterName as any, value);
    },
    [setFilter],
  );

  React.useEffect(() => {
    if (filterFieldsConfiguration) {
      setFilterFields(filterFieldsConfiguration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // If filterFieldsConfiguration items contains default values
    // they will be added to UI in this useEffect
    const filterInitialValues = Object.entries(
      filterFieldsConfiguration,
    ).reduce((acc, [key, value]) => {
      if (value.value) {
        acc[key] = value.value;
      } else {
        acc[key] = undefined;
      }

      return acc;
    }, {});

    setFilters(filterInitialValues);
  }, [filterFieldsConfiguration, setFilters]);

  return {
    getLabel,
    filterFields,
    setFilterFields,
    filterFieldsValues,
    isSomeFilterApplied,
    onFiltersFormSubmit,
    clearFilterFieldsData,
    setFilterFieldByFilterName,
    getFilterCommonPropsByFilterName,
  };
};
const useGetFilterFromQueryParams = () => {
  const { filter: filterFromParams = {} } = useQueryParams() as {
    filter: IFilterData;
  };
  const filter = filterFromParams.where ?? filterFromParams; // filter can be with where

  return typeof filter === 'object' && !Array.isArray(filter) ? filter : null;
};

const useFilterFieldsConfigurationWithQueryParamsSynchronization = <
  T extends Record<string, FilterValueDescriptor>
>({
  filterFieldsConfiguration,
}: IUseFilterFieldsData<T>) => {
  const filter = useGetFilterFromQueryParams();

  return React.useMemo(() => {
    if (filter) {
      return Object.keys(filterFieldsConfiguration).reduce((res, fieldName) => {
        const valueInQueryParams = filter[fieldName];

        res[fieldName] = {
          ...filterFieldsConfiguration[fieldName],
          value:
            valueInQueryParams !== undefined
              ? valueInQueryParams
              : filterFieldsConfiguration[fieldName].value,
        };

        return res;
      }, {}) as T;
    }

    return filterFieldsConfiguration;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFieldsConfiguration]);
};

export const useFilterFieldsDataWithQueryParamsSynchronization = <
  T extends Record<string, FilterValueDescriptor>
>({
  filterFieldsConfiguration,
}: IUseFilterFieldsData<T>) => {
  const {
    updateFilterDataInQueryParams,
    resetFiltersInQueryParams,
  } = useBrowserHistoryFunctions();

  const configuration = useFilterFieldsConfigurationWithQueryParamsSynchronization(
    { filterFieldsConfiguration },
  );

  const {
    filterFields,
    isSomeFilterApplied,
    clearFilterFieldsData,
    onFiltersFormSubmit: _onFiltersFormSubmit,
    ...restFilterFieldsData
  } = useFilterFieldsData({ filterFieldsConfiguration: configuration });

  const onFiltersFormSubmit = React.useCallback(
    (filter: { [key: string]: FilterValue }) => {
      updateFilterDataInQueryParams(filter);
      _onFiltersFormSubmit(filter);
    },
    [_onFiltersFormSubmit, updateFilterDataInQueryParams],
  );

  const clearFilters = () => {
    clearFilterFieldsData();
    resetFiltersInQueryParams();
  };

  return {
    filterFields,
    clearFilters,
    onFiltersFormSubmit,
    isSomeFilterApplied,
    ...restFilterFieldsData,
  };
};

const useTableInitialDataWithQueryParamsSynchronization = ({
  defaultLimit,
  defaultPage,
  defaultOrder,
  defaultOrderBy,
}: Pick<
  IUseTableDataArgs,
  'defaultLimit' | 'defaultPage' | 'defaultOrder' | 'defaultOrderBy'
>) => {
  const filter = useGetFilterFromQueryParams() || {};

  return {
    defaultOrder: filter[queryParamsKeys.order] ?? defaultOrder,
    defaultOrderBy: filter[queryParamsKeys.orderBy] ?? defaultOrderBy,
    defaultLimit: filter[queryParamsKeys.limit]
      ? Number(filter[queryParamsKeys.limit])
      : defaultLimit,
    defaultPage: filter[queryParamsKeys.page]
      ? Number(filter[queryParamsKeys.page])
      : defaultPage,
  } as Pick<
    IUseTableDataArgs,
    'defaultLimit' | 'defaultPage' | 'defaultOrder' | 'defaultOrderBy'
  >;
};

export const useTableDataWithQueryParamsSynchronization = ({
  headCellsOrderDetails,
  defaultLimit,
  defaultPage,
  defaultOrder,
  defaultOrderBy,
}: IUseTableDataArgs) => {
  const { updateFilterDataInQueryParams } = useBrowserHistoryFunctions();

  const tableInitialData = useTableInitialDataWithQueryParamsSynchronization({
    defaultLimit,
    defaultPage,
    defaultOrder,
    defaultOrderBy,
  });

  const {
    setSelectedRows,
    setPage: _setPage,
    setLimit: _setLimit,
    setOrder: _setOrder,
    setOrderBy: _setOrderBy,
    page,
    limit,
    order,
    orderBy,
    selectedRows,
    isSomeTableDataChanged,
  } = useTableData({
    ...tableInitialData,
    headCellsOrderDetails,
  });

  const setOrder = React.useCallback(
    (order: Order) => {
      updateFilterDataInQueryParams({ [queryParamsKeys.order]: order });
      _setOrder(order);
    },
    [_setOrder, updateFilterDataInQueryParams],
  );

  const setOrderBy = React.useCallback(
    (orderBy: string) => {
      updateFilterDataInQueryParams({ [queryParamsKeys.orderBy]: orderBy });
      _setOrderBy(orderBy);
    },
    [_setOrderBy, updateFilterDataInQueryParams],
  );

  const setLimit = React.useCallback(
    (value: number) => {
      const limit = Number(value);

      updateFilterDataInQueryParams({ [queryParamsKeys.limit]: limit });
      _setLimit(limit);
    },
    [_setLimit, updateFilterDataInQueryParams],
  );

  const setPage = React.useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number,
    ) => {
      updateFilterDataInQueryParams({ [queryParamsKeys.page]: page });
      _setPage(page);
    },
    [_setPage, updateFilterDataInQueryParams],
  );

  const _clearTableData = React.useCallback(() => {
    _setOrder(defaultOrder);
    _setOrderBy(defaultOrderBy);
    _setPage(defaultPage ?? DEFAULT_PAGE);
    _setLimit(defaultLimit ?? DEFAULT_LIMIT);
  }, [
    _setLimit,
    _setOrder,
    _setOrderBy,
    _setPage,
    defaultLimit,
    defaultOrder,
    defaultOrderBy,
    defaultPage,
  ]);

  return {
    setPage,
    setOrder,
    setLimit,
    setOrderBy,
    setSelectedRows,
    clearTableData: _clearTableData,
    page,
    limit,
    order,
    orderBy,
    selectedRows,
    isSomeTableDataChanged,
  };
};

export const mapIncludeObjToIncludeForRequest = (
  includeObj: IInclusionObject,
): IFilterData['include'] => {
  return Object.keys(includeObj).map((inclusionEntityName) => {
    const { scope, ...restInclusionData } = includeObj[inclusionEntityName];

    const includeForRequest = {
      relation: inclusionEntityName,
      ...restInclusionData,
    } as IFilterInclude;

    if (!scope) {
      return includeForRequest;
    }

    const { include: nestedInclude, ...restScope } = scope;

    if (restScope) {
      includeForRequest.scope = { ...restScope };
    }

    if (nestedInclude) {
      includeForRequest.scope = {
        ...includeForRequest.scope,
        include: mapIncludeObjToIncludeForRequest(nestedInclude),
      };
    }

    return includeForRequest;
  });
};

const mapValueDescriptorToRequestValue = (
  valueDescriptor: FilterValueDescriptor,
) => {
  switch (valueDescriptor.operator) {
    case 'like':
      return { like: `%${valueDescriptor.value}%` };
    case 'between':
      if (!valueDescriptor.value) {
        return undefined;
      }
      if (!valueDescriptor.value[0] && !valueDescriptor.value[1]) {
        return undefined;
      }
      if (valueDescriptor.value[0] && !valueDescriptor.value[1]) {
        return { qte: valueDescriptor.value[0] };
      }
      if (!valueDescriptor.value[0] && valueDescriptor.value[1]) {
        return { lte: valueDescriptor.value[1] };
      }

      return { [valueDescriptor.operator]: valueDescriptor.value };
    default:
      return { [valueDescriptor.operator]: valueDescriptor.value };
  }
};

interface IUseAddFilterFieldsValuesToFilterObjectArgs {
  filterFieldsConfiguration: Record<string, FilterValueDescriptor>;
  filterFields: Record<string, FilterValueDescriptor>;
  filterObj: { include?: IFilterObject['include'] };
}
export const addFilterFieldsValuesToFilterObject = ({
  filterObj,
  filterFields,
  filterFieldsConfiguration,
}: IUseAddFilterFieldsValuesToFilterObjectArgs) => {
  Object.entries(filterFields).forEach(([fieldName, fieldDetails]) => {
    const initialFiledDetails = filterFieldsConfiguration[fieldName];
    // If value of filter has not changed or it is "undefined" we do not use its value
    if (
      fieldDetails.value === undefined ||
      fieldDetails.value === initialFiledDetails.value
    ) {
      return;
    }

    const { inclusionPath, property } = fieldDetails;
    const value = mapValueDescriptorToRequestValue(fieldDetails);

    // Update value of inclusion if field of filter belongs to included entity
    const inclusionPathString =
      filterObj.include && inclusionPath
        ? `include.${inclusionPath.join('.')}.where`
        : 'where';

    update(filterObj, inclusionPathString, (where) => ({
      ...where,
      [property]: value,
    }));
  });
};

interface IUseAddOrderDataToFilterObjectArgs {
  filterObj: Omit<IFilterObject, 'limit' | 'offset'>;
  headCellsOrderDetails: IHeadCellOrderDetails[];
  orderBy?: string;
  order?: Order;
}
export const addOrderDataToFilterObject = ({
  order,
  orderBy,
  filterObj,
  headCellsOrderDetails,
}: IUseAddOrderDataToFilterObjectArgs) => {
  const orderByConfig = headCellsOrderDetails.find(({ id }) => id === orderBy);
  if (!order || !orderByConfig) {
    return;
  }

  const parsedOrder = [`${orderByConfig.orderBy} ${order}`];

  if (orderByConfig.orderByInclusionPath) {
    update(
      filterObj,
      `include.${orderByConfig.orderByInclusionPath.join('.')}`,
      (data) => ({ ...data, order: parsedOrder }),
    );
  } else {
    filterObj.order = parsedOrder;
  }

  return filterObj;
};
interface IUseGenerateRequestFilterArgs
  extends Partial<
    Pick<
      IUseAddFilterFieldsValuesToFilterObjectArgs,
      'filterFieldsConfiguration' | 'filterFields'
    >
  > {
  limit?: number;
  page?: number;
  order?: Order;
  orderBy?: string;
  defaultWhere?: WhereFilter;
  inclusionObj?: IInclusionObject;
  headCellsOrderDetails: IHeadCellOrderDetails[];
}
export interface IRequestFilters extends Omit<IFilterObject, 'include'> {
  include: IFilterInclude[] | undefined;
}
export const useGenerateRequestFilter = ({
  page,
  limit,
  order,
  orderBy,
  filterFields,
  inclusionObj,
  defaultWhere,
  headCellsOrderDetails,
  filterFieldsConfiguration,
}: IUseGenerateRequestFilterArgs): IRequestFilters => {
  return React.useMemo(() => {
    const filterObj: IFilterObject = {
      where: defaultWhere,
      include: !isEmpty(inclusionObj) ? cloneDeep(inclusionObj) : undefined,
    };

    if (limit) {
      filterObj.limit = limit;
    }
    if (page && limit) {
      filterObj.offset = page * limit;
    }

    addFilterFieldsValuesToFilterObject({
      filterObj,
      filterFields: filterFields ?? {},
      filterFieldsConfiguration: filterFieldsConfiguration ?? {},
    });

    addOrderDataToFilterObject({
      order,
      orderBy,
      filterObj,
      headCellsOrderDetails,
    });

    const include = filterObj.include
      ? mapIncludeObjToIncludeForRequest(filterObj.include)
      : undefined;

    return {
      ...filterObj,
      include,
    };
  }, [
    defaultWhere,
    filterFields,
    filterFieldsConfiguration,
    headCellsOrderDetails,
    inclusionObj,
    limit,
    order,
    orderBy,
    page,
  ]);
};

interface IUseFetchTableDataEffectArgs<T, P> {
  filter: IFilterData;
  selectData: (state: IStoreState) => T;
  selectCount: (state: IStoreState) => P;
  getEntitiesRequest: SimpleActionCreator<IFilter, Record<string, unknown>>;
  getEntitiesCountRequest: SimpleActionCreator<
    IFilter,
    Record<string, unknown>
  >;
}
export const useTableContentEffect = <T, P>({
  filter,
  selectData,
  selectCount,
  getEntitiesRequest,
  getEntitiesCountRequest,
}: IUseFetchTableDataEffectArgs<T, P>) => {
  const dispatch = useDispatch();

  const data = useSelector(selectData);
  const count = useSelector(selectCount);

  React.useEffect(() => {
    const { limit, offset, ...countFilterData } = filter;

    dispatch(
      getEntitiesRequest({ filter: { limit, offset, ...countFilterData } }),
    );
    dispatch(getEntitiesCountRequest({ filter: countFilterData }));
  }, [dispatch, filter, getEntitiesCountRequest, getEntitiesRequest]);

  return {
    data,
    count,
  };
};

const getPersistedCellsState = () =>
  getItemFromLocalStorage<string[]>('tablesConfiguration') || {};

export const setToLocalStorageVisibleHeadCells = (
  tableName: string,
  visibleHeadCellIdList: string[],
): void => {
  const persistedCellsState = getPersistedCellsState();

  setItemInLocalStorage('tablesConfiguration', {
    ...persistedCellsState,
    [tableName]: visibleHeadCellIdList,
  });
};

const getPersistVisibleHeadCells = (
  headCells: HeadCell[],
  excludeHeadCellsList: string[],
): string[] => {
  if (!headCells.length || !excludeHeadCellsList.length) {
    return [];
  }

  return headCells.reduce((acc, headCell) => {
    if (!excludeHeadCellsList.includes(headCell.id)) {
      acc.push(headCell.id);
    }
    return acc;
  }, [] as string[]);
};

interface IUseDefaultHiddenHeadCells {
  headCells: HeadCell[];
  excludeHeadCellsList: string[];
  tableName: string;
}

/**
 * Hide columns in excludeHeadCellsList by default
 * @param headCells
 * @param excludeHeadCellsList
 * @param tableName
 */
export const useDefaultHiddenHeadCells = ({
  headCells,
  excludeHeadCellsList,
  tableName,
}: IUseDefaultHiddenHeadCells): void => {
  const persistedCellsState = getPersistedCellsState();

  React.useEffect(() => {
    if (persistedCellsState[tableName]) {
      return;
    }

    const visibleHeadCellIdList = getPersistVisibleHeadCells(
      headCells,
      excludeHeadCellsList,
    );
    setToLocalStorageVisibleHeadCells(tableName, visibleHeadCellIdList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// export const useTablePaginationData = () => {
//   const [currentPage, setCurrentPage] = React.useState(PAGE_DEFAULT);
//   const [rowsPerPage, setRowsPerPage] = React.useState(ROWS_PER_PAGE_DEFAULT);

//   const onRowsPerPageChange = React.useCallback((rowsPerPage: number) => {
//     setRowsPerPage(rowsPerPage);
//   }, []);

//   const onCurrentPageChange = React.useCallback((page: number) => {
//     setCurrentPage(page);
//   }, []);

//   return { currentPage, onCurrentPageChange, rowsPerPage, onRowsPerPageChange };
// };

// // export const useTableOrderData = () => {
// //   const [order, setOrder]
// // }
