// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';

export const forgotPasswordScheme: PartialSchema<{ email: string }> = {
  type: 'object',
  required: ['email'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    email: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      format: 'email',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        format: i18n.t('error.validation.email'),
      },
    },
  },
};
