import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IIssueTypeModel, IIssueTypeState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IIssueTypeState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  siteId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  isIssueTypeListInProgress: false,
  isIssueTypeCountInProgress: false,
  isManageIssueTypeOperationInProgress: false,
};

/**
 * Flush actions
 */
const flushIssueTypeState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createIssueTypeRequest = (state: IIssueTypeState) => ({
  ...state,
  error: false,
  isManageIssueTypeOperationInProgress: true,
});

const createIssueTypeSuccess = (
  state: IIssueTypeState,
  payload: IIssueTypeModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isManageIssueTypeOperationInProgress: false,
});

const createIssueTypeFailed = (state: IIssueTypeState, payload: string) => ({
  ...state,
  error: payload,
  isManageIssueTypeOperationInProgress: false,
});

/**
 * Get list actions
 */
const getIssueTypeListRequest = (state: IIssueTypeState) => ({
  ...state,
  error: false,
  isIssueTypeListInProgress: true,
});

const getIssueTypeListSuccess = (
  state: IIssueTypeState,
  payload: IIssueTypeModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isIssueTypeListInProgress: false,
});

const getIssueTypeListFailed = (state: IIssueTypeState, payload: string) => ({
  ...state,
  error: payload,
  isIssueTypeListInProgress: false,
});

/**
 * Get count actions
 */
const getIssueTypeCountRequest = (state: IIssueTypeState) => ({
  ...state,
  error: false,
  isIssueTypeCountInProgress: true,
});

const getIssueTypeCountSuccess = (
  state: IIssueTypeState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isIssueTypeCountInProgress: false,
});

const getIssueTypeCountFailed = (state: IIssueTypeState, payload: string) => ({
  ...state,
  error: payload,
  isIssueTypeCountInProgress: false,
});

/**
 * Deletion actions
 */
const deleteIssueTypeRequest = (state: IIssueTypeState) => ({
  ...state,
  error: false,
  isManageIssueTypeOperationInProgress: true,
});

const deleteIssueTypeSuccess = (state: IIssueTypeState) => ({
  ...state,
  isManageIssueTypeOperationInProgress: false,
});

const deleteIssueTypeFailed = (state: IIssueTypeState, payload: string) => ({
  ...state,
  error: payload,
  isManageIssueTypeOperationInProgress: false,
});

/**
 * Updating actions
 */
const updateIssueTypesRequest = (state: IIssueTypeState) => ({
  ...state,
  error: false,
  isManageIssueTypeOperationInProgress: true,
});

const updateIssueTypesSuccess = (state: IIssueTypeState) => ({
  ...state,
  isManageIssueTypeOperationInProgress: false,
});

const updateIssueTypesFailed = (state: IIssueTypeState, payload: string) => ({
  ...state,
  error: payload,
  isManageIssueTypeOperationInProgress: false,
});

/**
 * Get combobox list actions
 */
const getIssueTypeComboboxListRequest = (state: IIssueTypeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getIssueTypeComboboxListSuccess = (
  state: IIssueTypeState,
  payload: Partial<IIssueTypeModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getIssueTypeComboboxListFailed = (
  state: IIssueTypeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IIssueTypeState>();

const exportIssueTypeRequest = exportRequest;
const exportIssueTypeSuccess = exportSuccess;
const exportIssueTypeFailed = exportFailed;

/**
 * IssueType reducer
 */
export const issueType = createReducer<IIssueTypeState>({}, defaultState)
  // flush actions
  .on(actions.flushIssueTypeState, flushIssueTypeState)
  // creation actions
  .on(actions.createIssueTypeRequest, createIssueTypeRequest)
  .on(actions.createIssueTypeSuccess, createIssueTypeSuccess)
  .on(actions.createIssueTypeFailed, createIssueTypeFailed)
  // get list actions
  .on(actions.getIssueTypeListRequest, getIssueTypeListRequest)
  .on(actions.getIssueTypeListSuccess, getIssueTypeListSuccess)
  .on(actions.getIssueTypeListFailed, getIssueTypeListFailed)
  // get count actions
  .on(actions.getIssueTypeCountRequest, getIssueTypeCountRequest)
  .on(actions.getIssueTypeCountSuccess, getIssueTypeCountSuccess)
  .on(actions.getIssueTypeCountFailed, getIssueTypeCountFailed)
  // deletion actions
  .on(actions.deleteIssueTypeRequest, deleteIssueTypeRequest)
  .on(actions.deleteIssueTypeSuccess, deleteIssueTypeSuccess)
  .on(actions.deleteIssueTypeFailed, deleteIssueTypeFailed)
  // updating actions
  .on(actions.updateIssueTypesRequest, updateIssueTypesRequest)
  .on(actions.updateIssueTypesSuccess, updateIssueTypesSuccess)
  .on(actions.updateIssueTypesFailed, updateIssueTypesFailed)
  // get combobox list actions
  .on(actions.getIssueTypeComboboxListRequest, getIssueTypeComboboxListRequest)
  .on(actions.getIssueTypeComboboxListSuccess, getIssueTypeComboboxListSuccess)
  .on(actions.getIssueTypeComboboxListFailed, getIssueTypeComboboxListFailed)
  // export actions
  .on(actions.exportIssueTypeRequest, exportIssueTypeRequest)
  .on(actions.exportIssueTypeSuccess, exportIssueTypeSuccess)
  .on(actions.exportIssueTypeFailed, exportIssueTypeFailed);

export default issueType;
