import React, { useEffect, useMemo } from 'react';
import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentRole } from '../../../../modules/selectors/auth';
import { map } from 'lodash';
import { useFetchClientKeys } from '../../../../modules/utils/hooks/site-importing-settings.hooks';
import { getClientKeys } from '../../../../modules/selectors/site-importing-settings.selector';

interface IClientKeysComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
}

const ClientKeysComboBox: React.FC<IClientKeysComboboxProps> = (props) => {
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const fetchClientKeys = useFetchClientKeys();

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchClientKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const clientKeys = useSelector(getClientKeys, shallowEqual);

  const options = (useMemo(
    () =>
      map(clientKeys, (clientKey) => ({
        id: clientKey,
        name: clientKey,
      })),
    [clientKeys],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default ClientKeysComboBox;
