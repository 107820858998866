import React from 'react';
import { Box } from '@mui/joy';

interface IChartsDashboard {
  children: React.ReactNode;
}

export const ChartsDashboard = ({
  children,
}: React.PropsWithChildren<IChartsDashboard>) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};
