import React from 'react';
import { Typography, TypographyProps } from '@mui/joy';

type IFilterPanelSelectOptionProps = TypographyProps;

export const FilterPanelSelectOptionLabel = ({
  children,
  ...props
}: IFilterPanelSelectOptionProps) => {
  return (
    <Typography level="text_sm" {...props}>
      {children}
    </Typography>
  );
};
