import { DUMMY_ID } from '../../../config';
import omit from 'lodash/omit';
import { isEmpty, chain, capitalize, toNumber } from 'lodash';

import { AnyObject } from '../../types';
import { HeadCell } from '../../../components/EnhancedTable';
import { IConcatenateYearWeekArgs } from './formatters';
import { COMPOSITE_OBJECT_KEY_DIVIDER } from 'src/constants';
import { format, parse } from 'date-fns';

export const delay = (ms: number): Promise<NodeJS.Timeout> =>
  new Promise((res: any) => setTimeout(res, ms));

export function filterListBySiteId<T extends { siteId: number }>(
  list: T[],
  siteId: number,
  callback?: (elm: T) => boolean,
): T[] {
  // no siteId - no data
  if (isNaN(siteId)) {
    return [];
  }

  // sometimes siteId is unknown on the front-end
  // in this case return all list
  // TODO send real siteId to FE and pass it here instead of dummy value
  if (siteId === DUMMY_ID) {
    return list;
  }

  // else return only elements that have specified siteId
  return list.filter(callback ? callback : (elm) => elm.siteId === siteId);
}

export const getDynamicTableHeaders = (
  dataRecord: AnyObject,
  disablePadding = false,
): HeadCell[] =>
  Object.keys(dataRecord).map((fieldName) => ({
    id: fieldName,
    label: fieldName,
    disablePadding,
  }));

export const getReportHeaders = (dataRecord: AnyObject): HeadCell[] => {
  // remove unused columns
  const normalizedData = omit(dataRecord, [
    'id',
    'siteId',
    'shiftId',
    'date',
    'endDate',
  ]);

  if (isEmpty(normalizedData)) {
    return [];
  }

  return Object.keys(normalizedData).map((fieldName) => ({
    id: fieldName,
    label: fieldName === 'startDate' ? 'date' : fieldName,
    disablePadding: false,
  }));
};

export const transformToCapitalize = (str: string): string => {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }

  return chain(str)
    .toLower()
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')
    .value();
};

export const formatYearWeekOptionName = ({
  year,
  week,
}: IConcatenateYearWeekArgs) => {
  return `${year}-W${String(week).padStart(2, '0')}`;
};
export const formatYearMonthOptionName = ({
  year,
  week: month,
}: IConcatenateYearWeekArgs) => {
  return `${year}-${format(parse(`${month}`, 'M', new Date()), 'MMM')}`;
};

export const formatYearWeekOptionValue = ({
  year,
  week,
}: IConcatenateYearWeekArgs) => {
  return Number(`${year}${String(week).padStart(2, '0')}`);
};

export const priceToNumber = (price: string) =>
  toNumber(price.replace(/\$|,/gi, ''));

export const composeObjectKey = (...args: Array<string>) => {
  return args.join(COMPOSITE_OBJECT_KEY_DIVIDER);
};

export const decomposeObjectKey = (key: string) => {
  return key.split(COMPOSITE_OBJECT_KEY_DIVIDER);
};

export const devModeLogger = (...args: Parameters<typeof console.log>) => {
  if (!Boolean(Number(process.env.REACT_APP_ENABLE_LOGGER))) {
    return;
  }

  console.log(...args);
};

export const getFileExtensionFromFileName = (fileName: string) => {
  return fileName.split('.').pop();
};

const dataUrlToBlob = (dataUrl: string) => {
  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};
export const dataUrlToFile = (dataUrl: string, fileName: string) => {
  const blob = dataUrlToBlob(dataUrl);
  return new File([blob], fileName, { type: blob.type });
};

export const makeArrayOfUniqueObjectsByField = <
  T extends Record<K, any>,
  K extends keyof T
>(
  arr: Array<T>,
  field: K,
): Array<T> => {
  const seenFields = new Set<any>();
  return arr.filter((obj) => {
    const value = obj[field];
    if (seenFields.has(value)) {
      return false;
    } else {
      seenFields.add(value);
      return true;
    }
  });
};
