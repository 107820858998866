import * as React from 'react';
import styled from 'styled-components';
import UIButton, { ButtonProps as UIButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const PRELOADER_SIZE = 20;

export interface IButtonProps extends UIButtonProps {
  isLoading?: number;
  external?: boolean;
  to?: string;
}

const CircularProgressWrapper = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CircularProgressStyled = styled(CircularProgress)(() => ({}));

const baseStyles = ({ isLoading }: IButtonProps) => `
  ${
    isLoading
      ? `
      pointer-events: none;
    `
      : ''
  }
`;

const ExternalLink = styled.a`
  && {
    ${baseStyles};
  }
`;

const ButtonStyled = styled(UIButton)`
  ${baseStyles};
`;

export const Button: React.FC<IButtonProps> = ({
  children,
  isLoading,
  disabled,
  external,
  to,
  ...rest
}) =>
  external ? (
    <ExternalLink target="_blank" href={to}>
      {children}
      {isLoading && (
        <CircularProgressWrapper>
          <CircularProgressStyled size={PRELOADER_SIZE} />
        </CircularProgressWrapper>
      )}
    </ExternalLink>
  ) : (
    <ButtonStyled {...rest} disabled={!!isLoading || disabled}>
      {children}
      {!!isLoading && (
        <CircularProgressWrapper>
          <CircularProgressStyled size={PRELOADER_SIZE} />
        </CircularProgressWrapper>
      )}
    </ButtonStyled>
  );
