import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateLogtimeRequest,
  IdsArray,
  IFilter,
  ILogtimeModel,
  IUpdateLogtime,
  ILogtimeSupervisorViewModel,
  PayloadWithNavigateFunc,
  PayloadWithFilters,
} from '../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushLogtimeState = createAction('Flush logtime data in store');

// Creation actions
export const createLogtimeRequest = createAction<
  PayloadWithNavigateFunc<ICreateLogtimeRequest>
>('Make request to create a new logtime');
export const createLogtimeBulkRequest = createAction<
  Partial<PayloadWithFilters<ICreateLogtimeRequest[]>>
>('Make request to create a bulk logtime');
export const createLogtimeSuccess = createAction<ILogtimeModel>(
  'New logtime has been created successfully',
);
export const createLogtimeFailed = createAction('Cannot create a new logtime');

// Fetch logtimes actions
export const getLogtimeListRequest = createAction<IFilter>(
  'Make request to fetch logtime list',
);
export const getLogtimeListSuccess = createAction<ILogtimeModel[]>(
  'Logtimes list has been fetched successfully',
);
export const getLogtimeListFailed = createAction('Cannot fetch logtime list');

// Fetch logtimes for supervisor view actions
export const getLogtimeListForSupervisorViewRequest = createAction<IFilter>(
  'Make request to fetch logtime list for supervisor view',
);
export const getLogtimeListForSupervisorViewSuccess = createAction<
  ILogtimeSupervisorViewModel[]
>('Logtimes list for supervisor view has been fetched successfully');
export const getLogtimeListForSupervisorViewFailed = createAction(
  'Cannot fetch logtime list for supervisor view',
);

// get logtimes count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getLogtimeCountRequest = createAction<IFilter>(
  'Make request to get logtime count',
);
export const getLogtimeCountSuccess = createAction<ICountResponse>(
  'Logtimes count has been fetched successfully',
);
export const getLogtimeCountFailed = createAction('Cannot get logtime count');

// delete actions
export const deleteLogtimeRequest = createAction<IdsArray>(
  'Make request to delete logtimes',
);
export const deleteLogtimeFailed = createAction('Cannot delete logtimes');

// Updating actions
export const updateLogtimesRequest = createAction<
  PayloadWithFilters<IUpdateLogtime[]>
>('Make request to update logtimes');
export const updateLogtimesFailed = createAction('Cannot update logtimes');

// Fetch combobox list actions
export const getLogtimeComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of logtimes',
);
export const getLogtimeComboboxListSuccess = createAction<
  Partial<ILogtimeModel>[]
>('Combobox list of logtimes has been fetched successfully');
export const getLogtimeComboboxListFailed = createAction(
  'Cannot fetch combobox logtimes list',
);

// Approve logtime entities actions
export const approveLogtimesRequest = createAction<IdsArray>(
  'Make request to approve logtimes',
);
export const approveLogtimesSuccess = createAction<IdsArray>(
  'Logtimes are approved successfully',
);
export const approveLogtimesFailed = createAction('Cannot approve logtimes');

export const unapproveLogtimesRequest = createAction<IdsArray>(
  'Make request to unapprove logtimes',
);
export const unapproveLogtimesSuccess = createAction<IdsArray>(
  'Logtimes are unapproved successfully',
);
export const unapproveLogtimesFailed = createAction(
  'Cannot unapprove logtimes',
);

export const moveToMissedLogtimesRequest = createAction<IdsArray>(
  'Make request to move to missed logtimes',
);
export const moveToMissedLogtimesSuccess = createAction<IdsArray>(
  'Logtimes are move to missed successfully',
);
export const moveToMissedLogtimesFailed = createAction(
  'Cannot move to missed logtimes',
);

export const {
  exportReportRequest: exportLogtimeRequest,
  exportReportSuccess: exportLogtimeSuccess,
  exportReportFailed: exportLogtimeFailed,
} = new ExportActionCreator('Logtime').takeExportActions();

export const {
  exportReportRequest: exportSupervisorViewRequest,
  exportReportSuccess: exportSupervisorViewSuccess,
  exportReportFailed: exportSupervisorViewFailed,
} = new ExportActionCreator('SupervisorView').takeExportActions();

export const getLogtimeApprovedListRequest = createAction<IFilter>(
  'Make request to fetch approved logtime list',
);
export const getLogtimeApprovedListSuccess = createAction<ILogtimeModel[]>(
  'Approved logtimes list has been fetched successfully',
);
export const getLogtimeApprovedListFailed = createAction(
  'Cannot fetch approved logtime list',
);
export const getLogtimeApprovedCountRequest = createAction<IFilter>(
  'Make request to get approved logtime count',
);
export const getLogtimeApprovedCountSuccess = createAction<ICountResponse>(
  'Approved logtimes count has been fetched successfully',
);
export const getLogtimeApprovedCountFailed = createAction(
  'Cannot get approved logtime count',
);
export const {
  exportReportRequest: exportLogtimeApprovedRequest,
  exportReportSuccess: exportLogtimeApprovedSuccess,
  exportReportFailed: exportLogtimeApprovedFailed,
} = new ExportActionCreator('LogtimeApproved').takeExportActions();

export const getLogtimeUnapprovedListRequest = createAction<IFilter>(
  'Make request to fetch unapproved logtime list',
);
export const getLogtimeUnapprovedListSuccess = createAction<ILogtimeModel[]>(
  'Unapproved logtimes list has been fetched successfully',
);
export const getLogtimeUnapprovedListFailed = createAction(
  'Cannot fetch unapproved logtime list',
);
export const getLogtimeMissedListRequest = createAction<IFilter>(
  'Make request to fetch Missed logtime list',
);
export const getLogtimeMissedListSuccess = createAction<ILogtimeModel[]>(
  'Missed logtimes list has been fetched successfully',
);
export const getLogtimeMissedListFailed = createAction(
  'Cannot fetch Missed logtime list',
);
export const getLogtimeUnapprovedCountRequest = createAction<IFilter>(
  'Make request to get unapproved logtime count',
);
export const getLogtimeUnapprovedCountSuccess = createAction<ICountResponse>(
  'Unapproved logtimes count has been fetched successfully',
);
export const getLogtimeUnapprovedCountFailed = createAction(
  'Cannot get unapproved logtime count',
);
export const getLogtimeMissedCountRequest = createAction<IFilter>(
  'Make request to get Missed logtime count',
);
export const getLogtimeMissedCountSuccess = createAction<ICountResponse>(
  'Missed logtimes count has been fetched successfully',
);
export const getLogtimeMissedCountFailed = createAction(
  'Cannot get unapproved logtime count',
);
export const {
  exportReportRequest: exportLogtimeUnapprovedRequest,
  exportReportSuccess: exportLogtimeUnapprovedSuccess,
  exportReportFailed: exportLogtimeUnapprovedFailed,
} = new ExportActionCreator('LogtimeUnapproved').takeExportActions();
export const {
  exportReportRequest: exportLogtimeMissedRequest,
  exportReportSuccess: exportLogtimeMissedSuccess,
  exportReportFailed: exportLogtimeMissedFailed,
} = new ExportActionCreator('LogtimeMissed').takeExportActions();
