import React, { useMemo } from 'react';
import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { map } from 'lodash';
import { HoursOptions } from '../../../../config';

interface IHoursOptionsComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
}

const HoursOptionsComboBox: React.FC<IHoursOptionsComboboxProps> = (props) => {
  const options = (useMemo(
    () =>
      map(HoursOptions, (option) => ({
        id: option,
        name: option,
      })),
    [],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default HoursOptionsComboBox;
