import React from 'react';
import ModalDialog from '../ModalDialog';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IFilterContainerProps {
  isModalOpen: boolean;
  isResetButtonShown: boolean;

  onOk: () => void;
  onClose: () => void;
  onFilterIconClick: (event: React.MouseEvent<HTMLButtonElement>) => void;

  onReset?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterContainer: React.FC<IFilterContainerProps> = ({
  isModalOpen,
  isResetButtonShown,
  onOk,
  onClose,
  onReset,
  onFilterIconClick,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex' }}>
      {isResetButtonShown && (
        <>
          <Tooltip title={t('common.reset_filters') as string}>
            <IconButton onClick={onReset}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <>
        <IconButton
          aria-label="filter list"
          onClick={onFilterIconClick}
          title={t('common.filter_button_title')}
          size="large"
        >
          <FontAwesomeIcon fontSize={16} icon={faFilter} />
        </IconButton>
        <ModalDialog
          open={isModalOpen}
          title={t('common.filter')}
          onOk={onOk}
          onClose={onClose}
        >
          {children}
        </ModalDialog>
      </>
    </Box>
  );
};

export default FilterContainer;
