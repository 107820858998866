import React from 'react';
import {
  useSnackbar,
  SnackbarContent as NotistackSnackbarContent,
  CustomContentProps,
} from 'notistack';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import { CardContent, Sheet } from '@mui/joy';
import {
  FeaturedIconErrorSvg,
  FeaturedIconSuccessSvg,
  XCloseSvg,
} from '../svgIcons';

type SnackbarContentProps = CustomContentProps;

const SnackbarContent = React.forwardRef<HTMLDivElement, SnackbarContentProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <NotistackSnackbarContent
        ref={ref}
        style={{ backgroundColor: 'transparent', width: 400 }}
      >
        <Card
          variant="outlined"
          sx={{
            width: '100%',
            position: 'relative',
            p: 2,
            bgcolor: 'base.white',
            borderRadius: 'lg',
            boxShadow: 'xl',
          }}
        >
          <CardContent sx={{ position: 'relative', bgcolor: 'inherit' }}>
            <Sheet
              sx={{
                bgcolor: 'inherit',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Sheet sx={{ bgcolor: 'inherit', display: 'flex' }}>
                <Sheet
                  sx={{
                    bgcolor: 'inherit',
                    position: 'relative',
                    left: -10,
                    top: -10,
                  }}
                >
                  {props.variant === 'error' ? (
                    <FeaturedIconErrorSvg />
                  ) : (
                    <FeaturedIconSuccessSvg />
                  )}
                </Sheet>

                {props.message}
              </Sheet>

              <Sheet sx={{ bgcolor: 'inherit' }}>
                <IconButton
                  onClick={() => closeSnackbar(id)}
                  sx={{ position: 'relative', right: -8, top: -8 }}
                >
                  <XCloseSvg />
                </IconButton>
              </Sheet>
            </Sheet>
          </CardContent>
        </Card>
      </NotistackSnackbarContent>
    );
  },
);

SnackbarContent.displayName = 'SnackbarContent';

export default SnackbarContent;
