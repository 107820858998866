import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { ACTIONS, CallBackProps } from 'react-joyride';
import { Tour } from 'src/components/Tour';
import { useChecklistTaskFormTourConfig } from 'src/config/tours/checklist/task';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const styles = {
  options: {
    zIndex: 9999,
    primaryColor: 'black',
  },
};

export const TaskFormTour = () => {
  const taskFormTourConfig = useChecklistTaskFormTourConfig();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleTourAction = ({ action }: CallBackProps) => {
    if (
      action === ACTIONS.RESET ||
      action === ACTIONS.CLOSE ||
      action === ACTIONS.STOP
    ) {
      setIsOpen(false);
    }
  };

  const onClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <Tooltip title="Take create new task tour" arrow>
        <IconButton
          sx={{
            fontSize: 20,
            marginTop: '10px',
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'pointer',
          }}
          onClick={onClick}
          size="large"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </IconButton>
      </Tooltip>
      <Tour
        styles={styles}
        run={isOpen}
        tour={taskFormTourConfig.tour}
        callback={handleTourAction}
      />
    </>
  );
};
