import React from 'react';
import { Box, Collapse } from '@mui/material';
import { useDispatch } from 'react-redux';
import { exportMonthInvoiceDetailsRequest } from 'src/modules/actions';
import { ExcelSvgIcon } from 'src/components/svgIcons/excel';
import { PdfSvgIcon } from 'src/components/svgIcons/pdf';

interface IInvoiceMonthToolbarProps {
  invoiceId: string;
  isVisible: boolean;
}

export const InvoiceMonthToolbar: React.FC<IInvoiceMonthToolbarProps> = ({
  invoiceId,
  isVisible,
}) => {
  const dispatch = useDispatch();

  const onExportToPdf = () => {
    dispatch(
      exportMonthInvoiceDetailsRequest({ id: invoiceId, format: 'pdf' }),
    );
  };
  const onExportToExcel = () => {
    dispatch(
      exportMonthInvoiceDetailsRequest({ id: invoiceId, format: 'excel' }),
    );
  };

  return (
    <Box sx={{ height: 48 }}>
      <Collapse in={isVisible} collapsedSize={16}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 16px',
            width: '100%',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            backgroundColor: 'rgba(31,36,40,0.7)',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;',
          }}
        >
          <Box
            onClick={onExportToExcel}
            sx={{ cursor: 'pointer', margin: '0 8px' }}
          >
            <ExcelSvgIcon />
          </Box>
          <Box
            onClick={onExportToPdf}
            sx={{ cursor: 'pointer', margin: '0 8px' }}
          >
            <PdfSvgIcon />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
