import React from 'react';
import { Box, Sheet } from '@mui/joy';
import { useMainLayoutContext } from '../../MainLayoutContext';

const logoWidth = {
  small: 40,
  regular: 120,
};

export const SideBarLogo = () => {
  const { sideBar, headerHeight, createTransition } = useMainLayoutContext();
  const [width, setWidth] = React.useState(
    sideBar.state === 'collapsed' ? logoWidth.small : logoWidth.regular,
  );

  React.useEffect(() => {
    setWidth(
      sideBar.state === 'collapsed' ? logoWidth.small : logoWidth.regular,
    );
  }, [sideBar.state]);

  return (
    <Sheet
      sx={{
        height: headerHeight,
        minHeight: headerHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit',
        px: `${sideBar.padding}px`,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray.700',
        transition: createTransition('padding'),
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <svg
          width={width}
          height="52"
          viewBox="0 0 180 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_331)">
            <path
              d="M26.6298 25.242C25.1991 30.8906 21.9271 34.7426 18.6562 34.7426C16.152 34.7426 14.4653 32.4834 14.4653 29.0419C14.4653 26.9879 15.334 24.1116 17.2763 19.9008C18.6049 16.922 19.1672 15.3302 19.1672 14.3023C19.1672 13.533 18.6562 12.9157 18.0427 12.9157C14.4653 12.9157 9.40487 25.9609 8.74066 27.8095L6.54266 34.3319H1.63627L6.64491 17.6926C7.1049 16.2546 7.36037 14.868 7.36037 14.2004C7.36037 13.3779 7.15614 13.0195 6.64491 13.0195C5.36719 13.0195 3.47623 15.6384 1.63627 20.0035L0 19.952C1.27772 16.8192 3.52642 11.2728 7.30912 11.2728C9.66034 11.2728 11.2449 13.4818 11.2449 16.8192C11.2449 17.2304 11.1935 17.8978 11.0913 18.7194C12.5226 15.9978 14.874 11.324 18.1962 11.324C20.9049 11.324 23.1034 14.2004 23.1034 17.7951C23.1034 20.3627 22.1834 23.2897 19.9849 27.5014C18.7585 29.8638 18.2472 31.1979 18.2472 32.021C18.2472 32.5854 18.7072 33.0475 19.3207 33.0475C20.1384 33.0475 22.8992 31.8661 25.0456 25.242L25.6596 22.4993L26.6298 25.242Z"
              fill="white"
            />
            <path
              d="M56.0404 33.1148C50.4045 34.5539 46.2012 34.6503 43.145 34.6503C34.2609 34.6503 23.9924 30.1392 23.9924 17.4714C23.9924 4.84973 32.8285 0.00320435 45.0557 0.00320435C47.2523 0.00320435 51.8846 0.24255 55.1329 1.49006L54.416 8.78516C51.6464 7.15272 48.3989 6.62553 46.2973 6.62553C39.0855 6.62553 33.1623 9.6485 33.1623 17.4714C33.1623 24.0452 37.7951 28.0281 44.2905 28.0281C45.4849 28.0281 46.6315 27.8361 47.4428 27.4523V20.8297H42.2378V14.4947H56.0404V33.1148Z"
              fill="white"
            />
            <path
              d="M68.4812 14.4953H70.8217C73.6873 14.4953 77.3167 14.3993 77.3167 10.6084C77.3167 7.48886 74.2122 6.91337 70.8217 6.91337H68.4812V14.4953ZM59.5975 0.57869H75.4547C81.3764 0.57869 86.2014 3.26558 86.2014 9.02505C86.2014 13.4875 84.099 16.4632 79.8003 17.4709V17.5666C82.6659 18.335 82.8572 20.8782 84.5286 24.9574L87.7768 34.0741H78.5109L76.6005 28.0286C74.7854 22.2696 73.6389 20.8302 70.3445 20.8302H68.4812V34.0741H59.5975V0.57869Z"
              fill="white"
            />
            <path
              d="M103.23 28.0286C108.626 28.0286 110.776 22.8451 110.776 17.3267C110.776 11.5203 108.626 6.62498 103.23 6.62498C97.8314 6.62498 95.6828 11.5203 95.6828 17.3267C95.6828 22.8451 97.8314 28.0286 103.23 28.0286ZM103.23 0.00265121C114.214 0.00265121 119.946 6.57723 119.946 17.3267C119.946 27.9806 114.119 34.6501 103.23 34.6501C92.3393 34.6501 86.5122 27.9806 86.5122 17.3267C86.5122 6.57723 92.244 0.00265121 103.23 0.00265121Z"
              fill="white"
            />
            <path
              d="M151.061 21.3581C151.061 30.5238 145.14 34.6503 136.447 34.6503C127.754 34.6503 121.832 30.5238 121.832 21.3581V0.579247H130.715V19.8701C130.715 24.2851 131.909 28.0281 136.447 28.0281C140.983 28.0281 142.178 24.2851 142.178 19.8701V0.579247H151.061V21.3581Z"
              fill="white"
            />
            <path
              d="M163.334 16.0309H165.626C168.682 16.0309 171.118 14.9276 171.118 11.4235C171.118 8.01716 168.682 6.91282 165.626 6.91282H163.334V16.0309ZM154.45 0.57869H165.053C175.226 0.57869 180.001 4.03378 180.001 11.5679C180.001 18.3345 175.083 22.3659 166.772 22.3659H163.334V34.0741H154.45V0.57869Z"
              fill="white"
            />
            <path
              d="M5.52123 44.352C3.27739 44.352 2.49581 46.7673 2.49581 48.6398C2.49581 50.5116 3.27739 52.9269 5.52123 52.9269C7.92505 52.9269 8.54609 50.6322 8.54609 48.6398C8.54609 46.6467 7.92505 44.352 5.52123 44.352ZM1.23401 43.5063H2.49581L2.41596 45.3789H2.45561C2.77639 44.815 3.55797 43.265 5.72169 43.265C8.72663 43.265 9.92852 45.5198 9.92852 48.6398C9.92852 51.6791 8.5264 54.0139 5.72169 54.0139C4.11859 54.0139 3.15733 53.3498 2.53601 52.0215H2.49581V58H1.23401V43.5063Z"
              fill="white"
            />
            <path
              d="M18.22 47.9148C18.22 46.1235 17.4988 44.352 15.6157 44.352C13.7526 44.352 12.6108 46.2238 12.6108 47.9148H18.22ZM18.8411 53.4506C17.9596 53.8125 16.8176 54.0139 15.8761 54.0139C12.4902 54.0139 11.2284 51.7193 11.2284 48.6399C11.2284 45.4995 12.9513 43.265 15.5356 43.265C18.4207 43.265 19.6021 45.6003 19.6021 48.358V49.0018H12.6108C12.6108 51.1758 13.7725 52.9269 15.9761 52.9269C16.898 52.9269 18.2403 52.5447 18.8411 52.1625V53.4506Z"
              fill="white"
            />
            <path
              d="M21.8035 45.8017C21.8035 44.6741 21.8035 44.2512 21.7234 43.5063H22.9858V45.4791H23.026C23.4865 44.3317 24.3482 43.265 25.6502 43.265C25.9507 43.265 26.3112 43.3255 26.5316 43.3856V44.7147C26.2715 44.634 25.9308 44.5933 25.61 44.5933C23.6068 44.5933 23.0659 46.8481 23.0659 48.7V53.7726H21.8035V45.8017Z"
              fill="white"
            />
            <path
              d="M29.3347 44.5933H27.2714V43.5063H29.3347V42.7616C29.3347 40.608 29.4149 38.434 32.0391 38.434C32.44 38.434 32.9809 38.4941 33.2213 38.6351L33.1412 39.7424C32.8806 39.6015 32.5001 39.521 32.1395 39.521C30.4166 39.521 30.5968 41.3932 30.5968 42.6207V43.5063H32.9008V44.5933H30.5968V53.7726H29.3347V44.5933Z"
              fill="white"
            />
            <path
              d="M38.3287 52.9269C40.5323 52.9269 41.7145 51.015 41.7145 48.6399C41.7145 46.2645 40.5323 44.352 38.3287 44.352C36.1248 44.352 34.9429 46.2645 34.9429 48.6399C34.9429 51.015 36.1248 52.9269 38.3287 52.9269ZM38.3287 43.265C41.6341 43.265 43.0967 45.8419 43.0967 48.6399C43.0967 51.4373 41.6341 54.0139 38.3287 54.0139C35.023 54.0139 33.5604 51.4373 33.5604 48.6399C33.5604 45.8419 35.023 43.265 38.3287 43.265Z"
              fill="white"
            />
            <path
              d="M45.2776 45.8017C45.2776 44.6741 45.2776 44.2512 45.1975 43.5063H46.4599V45.4791H46.4998C46.9606 44.3317 47.822 43.265 49.1243 43.265C49.4246 43.265 49.7856 43.3255 50.0057 43.3856V44.7147C49.7454 44.634 49.4049 44.5933 49.0841 44.5933C47.0809 44.5933 46.54 46.8481 46.54 48.7V53.7726H45.2776V45.8017Z"
              fill="white"
            />
            <path
              d="M64.0879 53.7726V46.9489C64.0879 45.4995 63.547 44.352 61.9242 44.352C60.5418 44.352 59.1796 45.5393 59.1796 48.0963V53.7726H57.9173V46.9489C57.9173 45.4995 57.3763 44.352 55.7536 44.352C54.3711 44.352 53.0093 45.5393 53.0093 48.0963V53.7726H51.7469V45.9224C51.7469 45.1368 51.7469 44.3317 51.6665 43.5063H52.8887V45.2379L52.9289 45.2582C53.3295 44.5534 53.9306 43.265 56.1146 43.265C57.0159 43.265 58.4984 43.6475 58.8189 45.439H58.8588C59.3199 44.2317 60.5218 43.265 62.1246 43.265C64.188 43.265 65.3499 44.7147 65.3499 46.7071V53.7726H64.0879Z"
              fill="white"
            />
            <path
              d="M73.9808 48.5992H73.6196C71.4364 48.5992 68.8315 48.8207 68.8315 51.0755C68.8315 52.424 69.7933 52.9269 70.955 52.9269C73.92 52.9269 73.9808 50.3305 73.9808 49.2232V48.5992ZM74.1009 52.1218H74.0602C73.4994 53.3498 72.0773 54.0139 70.8148 54.0139C67.9103 54.0139 67.4495 52.0418 67.4495 51.1153C67.4495 47.6735 71.0952 47.5122 73.7404 47.5122H73.9808V46.989C73.9808 45.2379 73.3592 44.352 71.6567 44.352C70.5945 44.352 69.593 44.5933 68.6516 45.1973V43.9698C69.4328 43.5871 70.7547 43.265 71.6567 43.265C74.1811 43.265 75.2426 44.4125 75.2426 47.0899V51.619C75.2426 52.4444 75.2426 53.0678 75.3427 53.7726H74.1009V52.1218Z"
              fill="white"
            />
            <path
              d="M78.0447 45.9224C78.0447 45.1368 78.0447 44.3317 77.9646 43.5063H79.1871V45.3382H79.2271C79.6477 44.4125 80.4088 43.265 82.5326 43.265C85.057 43.265 86.0184 44.956 86.0184 47.2105V53.7726H84.7566V47.5325C84.7566 45.6003 84.0749 44.352 82.3323 44.352C80.0283 44.352 79.3072 46.3851 79.3072 48.0963V53.7726H78.0447V45.9224Z"
              fill="white"
            />
            <path
              d="M95.6323 44.8752C94.9514 44.5738 94.1295 44.352 93.4485 44.352C90.9842 44.352 89.6022 46.1438 89.6022 48.6399C89.6022 50.9744 91.0042 52.9269 93.3083 52.9269C94.1101 52.9269 94.8512 52.7458 95.6123 52.4639L95.7325 53.652C94.8705 53.9534 94.0694 54.0139 93.108 54.0139C89.8225 54.0139 88.2195 51.4983 88.2195 48.6399C88.2195 45.4791 90.2431 43.265 93.2682 43.265C94.4907 43.265 95.372 43.547 95.7325 43.6679L95.6323 44.8752Z"
              fill="white"
            />
            <path
              d="M103.904 47.9148C103.904 46.1235 103.183 44.352 101.3 44.352C99.4365 44.352 98.2941 46.2238 98.2941 47.9148H103.904ZM104.525 53.4506C103.643 53.8125 102.502 54.0139 101.56 54.0139C98.1746 54.0139 96.9121 51.7193 96.9121 48.6399C96.9121 45.4995 98.6353 43.265 101.22 43.265C104.104 43.265 105.286 45.6003 105.286 48.358V49.0018H98.2941C98.2941 51.1758 99.4565 52.9269 101.66 52.9269C102.582 52.9269 103.924 52.5447 104.525 52.1625V53.4506Z"
              fill="white"
            />
            <path
              d="M117.023 44.352C114.779 44.352 113.998 46.7673 113.998 48.6398C113.998 50.5116 114.779 52.9269 117.023 52.9269C119.426 52.9269 120.047 50.6322 120.047 48.6398C120.047 46.6467 119.426 44.352 117.023 44.352ZM112.735 43.5063H113.998L113.917 45.3789H113.957C114.277 44.815 115.059 43.265 117.223 43.265C120.228 43.265 121.429 45.5198 121.429 48.6398C121.429 51.6791 120.027 54.0139 117.223 54.0139C115.62 54.0139 114.659 53.3498 114.037 52.0215H113.998V58H112.735V43.5063Z"
              fill="white"
            />
            <path
              d="M129.241 48.5992H128.88C126.696 48.5992 124.092 48.8207 124.092 51.0755C124.092 52.424 125.053 52.9269 126.216 52.9269C129.181 52.9269 129.241 50.3305 129.241 49.2232V48.5992ZM129.361 52.1218H129.321C128.76 53.3498 127.338 54.0139 126.075 54.0139C123.17 54.0139 122.71 52.0418 122.71 51.1153C122.71 47.6735 126.356 47.5122 129 47.5122H129.241V46.989C129.241 45.2379 128.62 44.352 126.917 44.352C125.856 44.352 124.853 44.5933 123.911 45.1973V43.9698C124.693 43.5871 126.015 43.265 126.917 43.265C129.441 43.265 130.503 44.4125 130.503 47.0899V51.619C130.503 52.4444 130.503 53.0678 130.603 53.7726H129.361V52.1218Z"
              fill="white"
            />
            <path
              d="M133.306 45.8017C133.306 44.6741 133.306 44.2512 133.226 43.5063H134.488V45.4791H134.527C134.988 44.3317 135.85 43.265 137.152 43.265C137.453 43.265 137.813 43.3255 138.033 43.3856V44.7147C137.772 44.634 137.432 44.5933 137.111 44.5933C135.108 44.5933 134.568 46.8481 134.568 48.7V53.7726H133.306V45.8017Z"
              fill="white"
            />
            <path
              d="M144.182 44.5933H141.859V51.2359C141.859 52.1625 142.199 52.9269 143.22 52.9269C143.701 52.9269 144.022 52.8266 144.383 52.6856L144.463 53.7328C144.162 53.8531 143.541 54.0139 142.92 54.0139C140.676 54.0139 140.596 52.4639 140.596 50.5921V44.5933H138.593V43.5063H140.596V41.0309L141.859 40.5877V43.5063H144.182V44.5933Z"
              fill="white"
            />
            <path
              d="M146.245 45.9224C146.245 45.1368 146.245 44.3317 146.165 43.5063H147.386V45.3382H147.426C147.847 44.4125 148.609 43.265 150.732 43.265C153.256 43.265 154.218 44.956 154.218 47.2105V53.7726H152.956V47.5325C152.956 45.6003 152.275 44.352 150.532 44.352C148.228 44.352 147.506 46.3851 147.506 48.0963V53.7726H146.245V45.9224Z"
              fill="white"
            />
            <path
              d="M163.411 47.9148C163.411 46.1235 162.69 44.352 160.807 44.352C158.943 44.352 157.801 46.2238 157.801 47.9148H163.411ZM164.032 53.4506C163.151 53.8125 162.008 54.0139 161.067 54.0139C157.681 54.0139 156.419 51.7193 156.419 48.6399C156.419 45.4995 158.142 43.265 160.726 43.265C163.611 43.265 164.794 45.6003 164.794 48.358V49.0018H157.801C157.801 51.1758 158.963 52.9269 161.167 52.9269C162.089 52.9269 163.431 52.5447 164.032 52.1625V53.4506Z"
              fill="white"
            />
            <path
              d="M166.995 45.8017C166.995 44.6741 166.995 44.2512 166.915 43.5063H168.177V45.4791H168.217C168.678 44.3317 169.539 43.265 170.841 43.265C171.142 43.265 171.502 43.3255 171.722 43.3856V44.7147C171.462 44.634 171.122 44.5933 170.801 44.5933C168.798 44.5933 168.257 46.8481 168.257 48.7V53.7726H166.995V45.8017Z"
              fill="white"
            />
            <path
              d="M172.682 52.2831C173.444 52.6653 174.366 52.9269 175.348 52.9269C176.549 52.9269 177.611 52.2628 177.611 51.095C177.611 48.6594 172.703 49.0424 172.703 46.0633C172.703 44.0303 174.346 43.265 176.029 43.265C176.57 43.265 177.652 43.3856 178.552 43.728L178.433 44.8354C177.772 44.5331 176.87 44.352 176.168 44.352C174.867 44.352 173.965 44.7549 173.965 46.0633C173.965 47.9749 178.994 47.7337 178.994 51.095C178.994 53.269 176.97 54.0139 175.427 54.0139C174.446 54.0139 173.464 53.8933 172.563 53.5314L172.682 52.2831Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_331">
              <rect width="180" height="58" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </Sheet>
  );
};
