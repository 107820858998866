import { createSelector } from 'reselect';

import { IStoreState } from '../types';
import { ComboBoxOptionWithEntity } from '../../components/ComboBox';
import { externalUrls } from 'src/config';

export const getResourcesList = ({ resources }: IStoreState) => resources.list;

export const getResourcesOptions = createSelector(getResourcesList, (list) => {
  const apiResources = list.map(
    (resource) =>
      (({
        id: `${resource.url}_${resource.action}`,
        name: `${resource.url} - ${resource.action}`,
        entity: resource.action,
        resourceUrl: resource.url,
      } as unknown) as ComboBoxOptionWithEntity<string>),
  );

  const externalResources = Object.entries(externalUrls).map(
    ([name, url]) =>
      (({
        id: `${url}_view`,
        name: `${name} (${url}) - navigate`,
        entity: 'view',
        resourceUrl: url,
      } as unknown) as ComboBoxOptionWithEntity<string>),
  );

  return [...apiResources, ...externalResources];
});
