import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { IHeadCellWithOrderConfig, Order } from 'src/modules/types/table';
import {
  useFetchReportTableDataAndCountEffect,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { ICountResponse, IListWithSummary } from 'src/modules/types';
import { IWeeklyGrossMargin } from 'src/modules/types/gross-margin';

const weeklyGrossMarginDefaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

interface IWeeklyGrossMarginProps {
  siteId?: number;
  yearNumber?: number;
  weekNumber?: number;
}

export const WeeklyGrossMargin = ({
  siteId,
  yearNumber,
  weekNumber,
}: IWeeklyGrossMarginProps) => {
  const { t } = useTranslation();

  const reportProps = useReportsProps();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<IWeeklyGrossMargin>
  >(reportProps.weeklyExecutiveGrossMargin.resourceUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${reportProps.weeklyExecutiveGrossMargin.resourceUrl}/count`,
    { count: 0 },
  );

  const where = React.useMemo(
    () => ({
      siteId: siteId ? siteId : undefined,
      yearNumber: yearNumber ? yearNumber : undefined,
      weekNumber: weekNumber ? weekNumber : undefined,
    }),
    [siteId, weekNumber, yearNumber],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'yearNumber',
        label: t('productions_uph_reports.year'),
        orderConfig: { orderBy: 'yearNumber' },
      },
      {
        id: 'weekNumber',
        label: t('productions_uph_reports.week'),
        orderConfig: { orderBy: 'weekNumber' },
      },
      {
        id: 'yearWeek',
        label: t('productions_uph_reports.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'site.name',
        label: t('productions_uph_reports.site.name'),
        // @TODO: find out why sort by site name does not work
        // orderConfig: {
        //   orderBy: 'name',
        //   orderByInclusionPath: ['site', 'scope'] as InclusionPath,
        // },
      },
      {
        id: 'revenue',
        label: t('reports.headers.revenue'),
        orderConfig: { orderBy: 'revenue' },
      },

      {
        id: 'staffingCost',
        label: t('reports.headers.staffing_cost'),
        orderConfig: { orderBy: 'staffingCost' },
      },
      {
        id: 'internalCost',
        label: t('reports.headers.internal_cost'),
        orderConfig: { orderBy: 'internalCost' },
      },
      {
        id: 'otherCosts',
        label: t('reports.headers.other_costs'),
        orderConfig: { orderBy: 'otherCosts' },
      },
      {
        id: 'grossMarginValue',
        label: t('reports.headers.gross_margin_value'),
        orderConfig: { orderBy: 'grossMarginValue' },
      },
      {
        id: 'grossMarginPercent',
        label: t('reports.headers.gross_margin_percent'),
        orderConfig: { orderBy: 'grossMarginPercentage' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: weeklyGrossMarginDefaultOrderDetails.order,
    defaultOrderBy: weeklyGrossMarginDefaultOrderDetails.orderBy,
  });

  const handleChangeRowsPerPage = (limit: number) => {
    setLimit(limit);

    setPage(DEFAULT_PAGE);

    fetchData({
      filter: {
        where,
        offset: DEFAULT_PAGE * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handleSort = (orderBy: string, order: Order) => {
    setOrderBy(orderBy);
    setOrder(order);

    fetchData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);

    fetchData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? weeklyGrossMarginDefaultOrderDetails.order,
    orderBy: orderBy ?? weeklyGrossMarginDefaultOrderDetails.orderBy,
  });

  return (
    <ReportTable
      data={data.items}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey="executivesReports/weeklyGrossMargin"
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
