import React from 'react';
import { useFetchReport } from 'src/modules/utils/hooks/common/reports';
import {
  ITableBodyItem,
  Pagination,
  TableBottom,
  TableComponent,
  TableContent,
} from 'src/components/Table';
import { IHeadCell } from 'src/modules/types/table';
import { TableSummary } from 'src/components/Table/components/TableSummary';
import { IFilterItemData } from 'src/components/Filter';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { ByEmployeeDetailsFilterContext } from '../ByEmployeeDetailsFilterContext';

interface IByEmployeeDetailsTableReportProps {
  headCells: IHeadCell[];
  reportUrl: string;
  tableUniqueName: string;
  where?: IFilterItemData;
}

export const ByEmployeeDetailsTableReport: React.FC<IByEmployeeDetailsTableReportProps> = ({
  headCells,
  reportUrl,
  tableUniqueName,
  where,
}) => {
  const {
    orderDetails,
    setOrderDetails,
    tablePaginationData,
    setTablePaginationData,
  } = React.useContext(ByEmployeeDetailsFilterContext);

  const { data, fetchData, isDataLoading } = useFetchReport<ITableBodyItem>(
    reportUrl,
  );

  const setPage = (_: any, page: number) => {
    setTablePaginationData((prev) => ({
      ...prev,
      page,
    }));
  };

  const setLimit = (value: number) => {
    setTablePaginationData((prev) => ({
      ...prev,
      limit: Number(value),
    }));
  };

  React.useEffect(() => {
    const listFilter = {
      filter: {
        ...where,
        offset: tablePaginationData.page * tablePaginationData.limit,
        limit: tablePaginationData.limit,
        order: orderDetails
          ? [`${orderDetails.orderBy}`, `${orderDetails.order}`]
          : undefined,
      },
    };

    const countFilter = { filter: { ...where } };

    fetchData(listFilter, countFilter);
  }, [
    fetchData,
    orderDetails,
    tablePaginationData.limit,
    tablePaginationData.page,
    where,
  ]);

  return (
    <TableComponent
      sx={{
        height: isDataLoading || !data.items.length ? '600px' : 'auto',
        flexGrow: 1,
      }}
      tableUniqueKey={tableUniqueName}
    >
      {isDataLoading && <MainContentLoader />}

      <TableContent
        data={data.items}
        isDataLoading={isDataLoading}
        order={orderDetails?.order}
        orderBy={orderDetails?.orderBy}
        headCells={headCells}
        onSort={(orderBy, order) => {
          setOrderDetails((prev) => ({ ...prev, order }));
          setOrderDetails((prev) => ({ ...prev, orderBy }));
        }}
      >
        {data.summary &&
          data.summary.map((item) => (
            <TableSummary key={item.title} {...item} />
          ))}
      </TableContent>

      <TableBottom>
        <Pagination
          currentPageNumber={tablePaginationData.page}
          count={data.count}
          rowsPerPage={tablePaginationData.limit}
          onPageChange={setPage}
          onRowsPerPageChange={setLimit}
        />
      </TableBottom>
    </TableComponent>
  );
};
