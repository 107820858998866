import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sheet, Typography, useTheme } from '@mui/joy';
import { useMainLayoutContext } from '../../MainLayoutContext';
import { DoubleChevronRightSvg } from 'src/components/svgIcons';

export const SideBarToggleWidthBtn = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    sideBar,
    toggleSideBar,
    createTransition,
    sideBarTextOffsetStep,
  } = useMainLayoutContext();

  return (
    <Sheet
      sx={{
        flexGrow: 0,
        alignItems: 'flex-end',
        backgroundColor: 'transparent',
        marginLeft: 'auto',
        marginRight: 3,
      }}
    >
      <Button
        variant="plain"
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:hover': { backgroundColor: 'transparent' },
          '&:active': { backgroundColor: 'transparent' },
          paddingLeft: `${sideBarTextOffsetStep}px`,
        }}
        onClick={toggleSideBar}
      >
        <Sheet
          sx={{
            transform:
              sideBar.state === 'collapsed'
                ? 'rotateY(0deg)'
                : 'rotateY(180deg)',
            backgroundColor: 'transparent',
            transition: createTransition('transform'),
            color: sideBar.state === 'collapsed' ? 'base.white' : 'gray.500',
          }}
        >
          <DoubleChevronRightSvg
            fill={
              sideBar.state === 'collapsed'
                ? theme.vars.palette.base.white
                : theme.vars.palette.gray[500]
            }
          />
        </Sheet>
        <Typography
          level="text_sm"
          fontWeight="semi_bold"
          sx={{
            marginLeft: 1,
            color: sideBar.state === 'collapsed' ? 'transparent' : 'gray.400',
            backgroundColor: 'transparent',
            transition: createTransition(['margin', 'color']),
          }}
        >
          {t('common.collapse')}
        </Typography>
      </Button>
    </Sheet>
  );
};
