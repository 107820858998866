import React from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import { TextField, ITextFieldProps } from '../_ui-kit/TextField';
import { useTranslation } from 'react-i18next';

export type IFormikNumberFieldProps = Omit<ITextFieldProps, 'onChange'> & {
  id: string;
  name: string;
  formik: AnyObject;
  errorMode?: ErrorMode;
};

function FormikNumberField({
  id,
  name,
  formik,
  label,
  placeholder,
  ...restProps
}: IFormikNumberFieldProps) {
  const { t } = useTranslation();

  const option = get(formik.values, id);
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  const showError = touched && Boolean(error);

  // if type === 'number' blur() will prevent change value on scroll
  const onWheelHandler = (event: React.WheelEvent<HTMLDivElement>) =>
    (event.target as HTMLElement).blur();

  return (
    <TextField
      {...restProps}
      placeholder={placeholder ?? t('common.add_your_value_here')}
      id={id}
      label={label}
      name={name}
      value={isNaN(option) || option === null ? '' : option}
      onChange={formik.handleChange}
      error={showError}
      helperText={showError && error}
      onWheel={onWheelHandler}
      type="number"
    />
  );
}

export default React.memo(FormikNumberField, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    prev.label === cur.label &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
