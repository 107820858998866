import React from 'react';
import { Box, BoxProps } from '@mui/joy';
import { Typography } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface ITdProps extends BoxProps {
  children: React.ReactNode;
  noWrap?: boolean;
  colSpan?: number;
  sx?: SxProps;
}

export const Td = ({ children, noWrap, sx, ...rest }: ITdProps) => {
  return (
    <Box
      component="td"
      sx={{
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: 40,
          px: 3,
          py: 1,
          width: '100%',
        }}
      >
        <Typography
          noWrap={noWrap}
          level="text_sm"
          fontWeight="regular"
          textColor="colors.text.text_tertiary.main"
          sx={{ width: '100%' }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};
