import { ComboBoxOption } from 'src/components/ComboBox';
import { i18n } from 'src/modules/utils/i18n';

export enum PaymentTerms {
  net7 = '7',
  net14 = '14',
  net15 = '15',
  net30 = '30',
  net45 = '45',
  net60 = '60',
}

export const paymentTermsOptions = Object.values(PaymentTerms).map(
  (paymentTerms) =>
    (({
      id: paymentTerms,
      name: i18n.t('sites.paymentTerms', { paymentTerms }),
    } as unknown) as ComboBoxOption),
);
