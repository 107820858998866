import React from 'react';
import { noop } from 'lodash';
import { IFilterItemData } from 'src/components/Filter';
import { Order } from 'src/modules/types/table';

const defaultActiveReportTab = 'chart';

const defaultTablePaginationData = {
  limit: 10,
  page: 0,
};

interface IOrderDetails {
  order?: Order;
  orderBy?: string;
}

type ActiveReportType = 'chart' | 'table';

interface IPaginationData {
  limit: number;
  page: number;
}

interface IByEmployeeDetailsFilterContext {
  where: IFilterItemData | null;
  setWhere: React.Dispatch<React.SetStateAction<IFilterItemData | null>>;

  chartWhere: IFilterItemData;
  tableWhere: IFilterItemData;

  orderDetails: IOrderDetails | null;
  setOrderDetails: React.Dispatch<React.SetStateAction<IOrderDetails | null>>;

  employeeName: string | null;
  setEmployeeName: React.Dispatch<React.SetStateAction<string | null>>;

  activeReportType: ActiveReportType;
  setActiveReportType: React.Dispatch<React.SetStateAction<ActiveReportType>>;

  reportUrl: string;
  setReportUrl: React.Dispatch<React.SetStateAction<string>>;

  tablePaginationData: IPaginationData;
  setTablePaginationData: React.Dispatch<React.SetStateAction<IPaginationData>>;
}
export const ByEmployeeDetailsFilterContext = React.createContext<
  IByEmployeeDetailsFilterContext
>({
  where: null,
  setWhere: noop,
  orderDetails: null,
  setOrderDetails: noop,
  chartWhere: {},
  tableWhere: {},
  employeeName: '',
  setEmployeeName: noop,
  activeReportType: defaultActiveReportTab,
  setActiveReportType: noop,
  reportUrl: '',
  setReportUrl: noop,
  tablePaginationData: defaultTablePaginationData,
  setTablePaginationData: noop,
});

export interface IByEmployeeDetailsFilterContextProviderProps {
  reportUrl: string;
  employeeName: string | null;
  defaultChartWhere?: IFilterItemData;
  defaultTableWhere?: IFilterItemData;
}

export const ByEmployeeDetailsFilterContextProvider: React.FC<IByEmployeeDetailsFilterContextProviderProps> = ({
  children,
  employeeName: _employeeName,
  reportUrl: _reportUrl,
  defaultChartWhere: _defaultChartWhere,
  defaultTableWhere: _defaultTableWhere,
}) => {
  const [reportUrl, setReportUrl] = React.useState<string>(_reportUrl);
  const [where, setWhere] = React.useState<IFilterItemData | null>(null);
  const [tablePaginationData, setTablePaginationData] = React.useState(
    defaultTablePaginationData,
  );
  const [employeeName, setEmployeeName] = React.useState<string | null>(
    _employeeName,
  );
  const [activeReportType, setActiveReportType] = React.useState<
    ActiveReportType
  >(defaultActiveReportTab);
  const [orderDetails, setOrderDetails] = React.useState<IOrderDetails | null>(
    null,
  );
  const [
    defaultTableWhere,
    setDefaultTableWhere,
  ] = React.useState<IFilterItemData | null>(_defaultTableWhere ?? null);
  const [
    defaultChartWhere,
    setDefaultChartWhere,
  ] = React.useState<IFilterItemData | null>(_defaultChartWhere ?? null);

  React.useEffect(() => {
    setOrderDetails(null);
    setReportUrl(_reportUrl);
    setEmployeeName(_employeeName);
    setActiveReportType(defaultActiveReportTab);
    setDefaultChartWhere(_defaultChartWhere ?? null);
    setDefaultTableWhere(_defaultTableWhere ?? null);
    setTablePaginationData(defaultTablePaginationData);
    setWhere(null);
  }, [_defaultChartWhere, _defaultTableWhere, _employeeName, _reportUrl]);

  const chartWhere = React.useMemo(
    () => ({
      where: {
        ...defaultChartWhere,
        ...where,
        employee: employeeName ? { like: `${employeeName}` } : undefined,
      },
    }),
    [defaultChartWhere, employeeName, where],
  );
  const tableWhere = React.useMemo(
    () => ({
      where: {
        ...defaultTableWhere,
        ...where,
        employee: employeeName ? { like: `${employeeName}` } : undefined,
      },
    }),
    [defaultTableWhere, employeeName, where],
  );

  return (
    <ByEmployeeDetailsFilterContext.Provider
      value={{
        where,
        setWhere,
        orderDetails,
        setOrderDetails,
        chartWhere,
        tableWhere,
        employeeName,
        setEmployeeName,
        activeReportType,
        setActiveReportType,
        reportUrl,
        setReportUrl,
        tablePaginationData,
        setTablePaginationData,
      }}
    >
      {children}
    </ByEmployeeDetailsFilterContext.Provider>
  );
};
