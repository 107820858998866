import React from 'react';
import StackedAreaChart from '../../../components/Charts/StackedAreaChart';
import { useTranslation } from 'react-i18next';
import { SeriesAreaOptions } from 'highcharts';

export interface IWeeklyGrossMarginValueChartData {
  categories: string[];
  series: SeriesAreaOptions[];
}

interface IWeeklyGrossMarginBySiteAndWeekChartProps {
  weeklyGrossMarginValue: IWeeklyGrossMarginValueChartData;
}

export const WeeklyGrossMarginBySiteAndWeekChart: React.FC<IWeeklyGrossMarginBySiteAndWeekChartProps> = ({
  weeklyGrossMarginValue,
}) => {
  const { t } = useTranslation();

  return (
    <StackedAreaChart
      key={t('financial_tracker.weekly_gm$_by_site')}
      title={t('financial_tracker.weekly_gm$_by_site')}
      categories={weeklyGrossMarginValue.categories}
      series={weeklyGrossMarginValue.series}
    />
  );
};
