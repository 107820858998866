import React from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowsFullscreenSvg, DownloadSvg } from 'src/components/svgIcons';

interface IChartContainerUtils {
  onToggleFullScreen: () => void;
  onExport: () => void;
}

export const ChartContainerUtils = ({
  onExport,
  onToggleFullScreen,
}: IChartContainerUtils) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 2,
        marginRight: 2,
      }}
    >
      <IconButton onClick={onExport}>
        <DownloadSvg />
      </IconButton>
      <IconButton onClick={onToggleFullScreen}>
        <ArrowsFullscreenSvg />
      </IconButton>
    </Box>
  );
};
