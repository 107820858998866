import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
  IRecruitingReport,
} from '../../../../../modules/types';
import { useFetchReportTableDataAndCountEffect } from '../../../../../modules/utils/hooks/reportPage.hooks';
import { paths } from '../../../../../config';
import { ReportTable } from '../../../../../components/ReportPage/ReportTable';
import { useDataFetcherWithData } from '../../../../../modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from '../../../../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../../../../modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from '../../../../../modules/utils/hooks/reports.hooks';
import { ITableBodyItem } from '../../../../../components/Table';
import { Box } from '@mui/joy';
import {
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  composeDate,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import { HistoricalRecruiting } from './components/HistoricalRecruiting';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export const ExecutiveSummaryRecruitingReportTable: React.FC = () => {
  const { t } = useTranslation();
  const globallySelectedSites = useSelector(getGloballySelectedSites);

  const where = React.useMemo(
    () => ({
      siteId: globallySelectedSites
        ? { inq: globallySelectedSites }
        : undefined,
    }),
    [globallySelectedSites],
  );

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'siteName',
  };

  const [selectedRow, setSelectedRow] = React.useState<AnyObject>({});
  const [displayHistorical, setDisplayHistorical] = React.useState<boolean>(
    false,
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(paths.RECRUITING_REPORT, {
    items: [],
    summary: [],
  });

  const items = React.useMemo(() => {
    return data.items.map((item) => {
      const recruitmentColumnsModifiedBy = item.recruitmentColumnsModifiedBy;
      const recruitmentColumnsModifiedAt = item.recruitmentColumnsModifiedAt
        ? composeDate(
            item.recruitmentColumnsModifiedAt,
            parse(DATETIME_TIMEZONE),
            format(DATETIME_FORMAT_TO_SHOW),
          )
        : null;

      return {
        ...item,
        recruitmentColumnsModifiedBy,
        recruitmentColumnsModifiedAt,
      };
    });
  }, [data.items]);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${paths.RECRUITING_REPORT}/count`,
    {
      count: 0,
    },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('recruiting_dashboards_report.siteName'),
      },
      {
        id: 'desiredHeadCount',
        orderConfig: { orderBy: 'desiredHeadCount' },
        label: t('recruiting_dashboards_report.desiredHeadCount'),
      },
      {
        id: 'absenteeism',
        orderConfig: { orderBy: 'absenteeism' },
        label: t('recruiting_dashboards_report.absenteeism'),
      },
      {
        id: 'optimalHeadCount',
        orderConfig: { orderBy: 'optimalHeadCount' },
        label: t('recruiting_dashboards_report.optimalHeadCount'),
      },
      {
        id: 'countOfActiveEmployees',
        orderConfig: { orderBy: 'countOfActiveEmployees' },
        label: t('recruiting_dashboards_report.countOfActiveEmployees'),
      },
      {
        id: 'openOrders',
        orderConfig: { orderBy: 'openOrders' },
        label: t('recruiting_dashboards_report.openOrders'),
      },
      {
        id: 'recruitmentColumnsModifiedBy',
        orderConfig: { orderBy: 'recruitmentColumnsModifiedBy' },
        label: t('logtimes.editUser'),
      },
      {
        id: 'recruitmentColumnsModifiedAt',
        orderConfig: { orderBy: 'recruitmentColumnsModifiedAt' },
        label: t('logtimes.updatedAt'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [limit, order, orderBy, page],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  const handleRowClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: AnyObject,
  ) => {
    setSelectedRow(row);
    setDisplayHistorical(true);
  };

  return (
    <Box>
      <ReportTable<IRecruitingReport & ITableBodyItem>
        data={items as any}
        count={countData.count}
        summaryData={data.summary}
        isDataLoading={isDataLoading || isCountDataLoading}
        tableUniqueKey={paths.RECRUITING_REPORT}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        rowsPerPage={limit}
        currentPageNumber={page}
        onSort={handleSort}
        onTableRowClick={handleRowClick}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(_, page) => handlePageChange(page)}
      />

      <HistoricalRecruiting
        title={t('recruiting_dashboards_report.modal_title', selectedRow)}
        siteId={selectedRow?.siteId}
        isOpen={displayHistorical}
        onClose={() => setDisplayHistorical(false)}
      />
    </Box>
  );
};
