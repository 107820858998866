import React from 'react';
import { useDispatch } from 'react-redux';
import { ExportType, IExportColumnsData } from '../../types/export';

export type ExportFunction = (
  args: { exportType: ExportType } & IExportColumnsData,
) => any;

export const useCreateExportProps = (exportFunc: ExportFunction) => {
  const dispatch = useDispatch();

  const onExportCSV = React.useCallback(
    (columnsData: IExportColumnsData) => {
      dispatch(
        exportFunc({
          exportType: 'csv',
          ...columnsData,
        }),
      );
    },
    [dispatch, exportFunc],
  );

  const onExportPDF = React.useCallback(
    (columnsData: IExportColumnsData) => {
      dispatch(
        exportFunc({
          exportType: 'pdf',
          ...columnsData,
        }),
      );
    },
    [dispatch, exportFunc],
  );

  const onExportExcel = React.useCallback(
    (columnsData: IExportColumnsData) => {
      dispatch(
        exportFunc({
          exportType: 'xls',
          ...columnsData,
        }),
      );
    },
    [dispatch, exportFunc],
  );

  const onExportEmail = React.useCallback(
    (columnsData: IExportColumnsData) => {
      dispatch(
        exportFunc({
          exportType: 'email',
          ...columnsData,
        }),
      );
    },
    [dispatch, exportFunc],
  );

  return React.useMemo(
    () => ({
      onExportCSV,
      onExportPDF,
      onExportExcel,
      onExportEmail,
    }),
    [onExportCSV, onExportEmail, onExportExcel, onExportPDF],
  );
};
