import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SplineSymbolsChart,
  SplineSymbolsChartProps,
  SplineSymbolsChartSeries,
} from 'src/components/Charts/SplineSymbolsChart';

export interface IWeeklyGrossMarginValueChartData {
  categories: string[];
  series: SplineSymbolsChartSeries;
}

type IWeeklyGrossMarginBySitePercentageAndWeekChartProps = SplineSymbolsChartProps;

export const WeeklyGrossMarginBySitePercentageAndWeekChart: React.FC<IWeeklyGrossMarginBySitePercentageAndWeekChartProps> = ({
  categories,
  series,
}) => {
  const { t } = useTranslation();

  return (
    <SplineSymbolsChart
      title={t('financial_tracker.weekly_gm%_by_site')}
      categories={categories}
      series={series}
      yAxisTitleFormat={'{value} %'}
    />
  );
};
