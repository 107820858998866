import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateOptiturnCredentials } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createOptiturnCredentialsScheme } from './create-optiturn-credentials.scheme';

export const updateOptiturnCredentialsScheme: JSONSchemaType<
  ICreateOptiturnCredentials[]
> = {
  type: 'array',
  items: {
    ...createOptiturnCredentialsScheme,
    properties: {
      ...createOptiturnCredentialsScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
