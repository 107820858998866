// Use it as a single source of truth in API requests
// and whenever you deal with permission's,
// basically it is resources urls
export const apiEndpoints = {
  POINTS: '/points',
  POINTS_REMOVE: '/points/remove',
  POINTS_COUNT: '/points/count',
  POINTS_TOTAL_EARNED: '/points/total-earned',
  POINTS_TOTAL_EARNED_COUNT: '/points/total-earned/count',
  AUTOMATIC_POINTS_TO_APPROVE: '/automatic-points-to-approve',

  QUESTIONS: '/question',
  QUESTIONS_COUNT: '/question/count',
  QUESTION_CATEGORY: '/question-category',
  QUESTION_CATEGORY_COUNT: '/question-category/count',

  FINANCIAL_TRACKER: '/financial-tracker',

  LOGTIMES: '/logtimes',
  LOGTIMES_BULK: '/logtimes/bulk',
  LOGTIMES_COUNT: '/logtimes/count',
  LOGTIMES_APPROVED: '/logtimes/approved',
  LOGTIMES_APPROVED_COUNT: '/logtimes/approved/count',
  LOGTIMES_UNAPPROVED: '/logtimes/unapproved',
  LOGTIMES_UNAPPROVED_COUNT: '/logtimes/unapproved/count',
  LOGTIMES_SUPERVISOR_VIEW: '/logtimes/supervisor-view',
  LOGTIMES_SUPERVISOR_VIEW_COUNT: '/logtimes/count',
  LOGTIMES_MISSED: '/logtimes/missed',
  LOGTIMES_MISSED_COUNT: '/logtimes/missed/count',

  INVOICE: '/invoice',
  INVOICE_BULK: '/invoice/bulk',
  INVOICE_COUNT: '/invoice/count',
  INVOICE_MONTHLY: '/invoice/month',
  INVOICE_MONTHL_BULK: '/invoice/month/bulk',
  INVOICE_MONTHLY_COUNT: 'invoice/month/count',

  DAILY_HOURS: '/payboard/daily-hours-summary-report',
  DAILY_HOURS_COUNT: '/payboard/daily-hours-summary-report/count',

  INCENTIVE_PAYMENT_SETTINGS: '/incentive-settings',
};
