import React from 'react';
import { HeadCell } from './components/HeadCell';
import { IHeadCell, ISortDetails, Order } from 'src/modules/types/table';
import { SelectAllCheckboxCell } from './components/SelectAllCheckboxCell';
import { Th, Tr } from 'src/components/TableUiComponents';
import { Box } from '@mui/joy';

export interface ITableHeadProps extends ISortDetails {
  headCells: IHeadCell[];
  onSelectAllCheckboxClick?: () => void;
  rowsQuantity?: number;
  selectedRowsQuantity?: number;
  onSort?: (orderBy: string, order: Order) => void;
}

export const TableHead: React.FC<ITableHeadProps> = ({
  headCells,
  onSelectAllCheckboxClick,
  rowsQuantity,
  selectedRowsQuantity,
  order,
  orderBy,
  onSort,
}) => {
  const checked =
    Boolean(rowsQuantity) && rowsQuantity === selectedRowsQuantity;
  const intermediate =
    Boolean(selectedRowsQuantity) &&
    Number(selectedRowsQuantity) < Number(rowsQuantity);

  const handleSort = (newOrderBy: string) => {
    const isAsc = orderBy === newOrderBy && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    onSort && onSort(newOrderBy, newOrder);
  };

  const handleSortFunc = onSort ? handleSort : undefined;

  return (
    <Box component="thead">
      <Tr>
        {onSelectAllCheckboxClick && (
          <Th>
            <SelectAllCheckboxCell
              checked={checked}
              indeterminate={intermediate}
              onChange={onSelectAllCheckboxClick}
            />
          </Th>
        )}

        {headCells.map((headCellProps) => (
          <HeadCell
            key={headCellProps.id}
            handleSort={handleSortFunc}
            order={order}
            orderBy={orderBy}
            {...headCellProps}
          />
        ))}
      </Tr>
    </Box>
  );
};
