import { Options, RRule, RRuleSet, rrulestr } from 'rrule';
import {
  getDayWithMonAsFirstDay,
  parseISO,
  zonedTimeToUTC,
} from '../dateWrapper';

export const createRRuleRecurrence = (options: Partial<Options>) => {
  const rruleSet = new RRuleSet();

  rruleSet.rrule(new RRule(options));

  return rruleSet;
};

export const rruleToName = (rruleSet: RRuleSet) => {
  const text = rruleSet.rrules()[0].toText();

  return text.replace(/at [0-9]+/, '');
};

export const createDailyRecurrence = (
  startDate: string,
  options?: Partial<Options>,
) => {
  const { dtstart } = parseStartEndDays({ startDate });

  const dailyRecurrence = createRRuleRecurrence({
    dtstart,
    freq: RRule.DAILY,
    byhour: dtstart.getUTCHours(),
    byminute: dtstart.getUTCMinutes(),
    ...options,
  });

  let minutes = String(dtstart.getMinutes());

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }

  return {
    id: dailyRecurrence.toString(),
    name: rruleToName(dailyRecurrence),
    rruleObj: dailyRecurrence,
  };
};

export const createEveryWeekdayRecurrence = (
  startDate: string,
  options?: Partial<Options>,
) => {
  const { dtstart } = parseStartEndDays({ startDate });

  const everyWeekdayRecurrence = createRRuleRecurrence({
    freq: RRule.DAILY,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
    dtstart,
    byhour: dtstart.getUTCHours(),
    byminute: dtstart.getUTCMinutes(),
    ...options,
  });

  return {
    id: everyWeekdayRecurrence.toString(),
    name: rruleToName(everyWeekdayRecurrence),
    rruleObj: everyWeekdayRecurrence,
  };
};

export const createWeeklyInCurrentDateRecurrence = (
  startDate: string,
  options?: Partial<Options>,
) => {
  const { dtstart } = parseStartEndDays({ startDate });

  const day = getDayWithMonAsFirstDay(dtstart);

  const weeklyInCurrentDateRecurrence = createRRuleRecurrence({
    freq: RRule.WEEKLY,
    byweekday: [day],
    dtstart,
    byhour: dtstart.getUTCHours(),
    byminute: dtstart.getUTCMinutes(),
    ...options,
  });

  return {
    id: weeklyInCurrentDateRecurrence.toString(),
    name: rruleToName(weeklyInCurrentDateRecurrence),
    rruleObj: weeklyInCurrentDateRecurrence,
  };
};

export const createRRuleFromStr = (str: string) => {
  const rruleSet = rrulestr(str, { forceset: true }) as RRuleSet;

  return rruleSet;
};

export const getAllOccurrencesBetween = (
  recurrenceStr: string,
  [start, end]: [Date, Date],
) => {
  const rule = createRRuleFromStr(recurrenceStr);

  return rule.between(start, end, true);
};

export const excludeDateFromRecurrence = (recurrence: string, date: Date) => {
  const rruleSet = createRRuleFromStr(recurrence);

  rruleSet.exdate(zonedTimeToUTC(date));

  return rruleSet.toString();
};

export const updateRRuleStartDate = (rruleStr: string, startDate: Date) => {
  const rruleSet = createRRuleFromStr(rruleStr);

  const newRRuleSet = new RRuleSet();

  rruleSet.rrules().forEach((rule) => {
    newRRuleSet.rrule(
      new RRule({
        ...rule.origOptions,
        dtstart: startDate,
        byhour: startDate.getUTCHours(),
        byminute: startDate.getUTCMinutes(),
      }),
    );
  });

  return newRRuleSet;
};

interface IParseStartEndDaysArgs {
  startDate: string;
  endDate?: string;
}

function parseStartEndDays({ startDate, endDate }: IParseStartEndDaysArgs) {
  return {
    dtstart: parseISO(startDate),
    until: endDate ? parseISO(endDate) : undefined,
  };
}
