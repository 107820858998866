import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateRoleRequest,
  IFilter,
  IRoleModel,
  IUpdateRole,
  PayloadWithNavigateFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushRoleState = createAction('Flush role data in store');

// Creation actions
export const createRoleRequest = createAction<
  PayloadWithNavigateFunc<Partial<ICreateRoleRequest>>
>('Make request to create a new role');
export const createRoleSuccess = createAction<IRoleModel>(
  'New role has been created successfully',
);
export const createRoleFailed = createAction('Cannot create a new role');

// Fetch roles actions
export const getRoleListRequest = createAction<IFilter>(
  'Make request to fetch role list',
);
export const getRoleListSuccess = createAction<IRoleModel[]>(
  'Roles list has been fetched successfully',
);
export const getRoleListFailed = createAction('Cannot fetch role list');

// get roles count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getRoleCountRequest = createAction<IFilter>(
  'Make request to get role count',
);
export const getRoleCountSuccess = createAction<ICountResponse>(
  'Roles count has been fetched successfully',
);
export const getRoleCountFailed = createAction('Cannot get role count');

// Updating actions
export const updateRolesRequest = createAction<
  PayloadWithNavigateFunc<Omit<IUpdateRole, 'roleTerm'>[]>
>('Make request to update roles');
export const updateRolesFailed = createAction('Cannot update roles');

// Fetch combobox list actions
export const getRoleComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of roles',
);
export const getRoleComboboxListSuccess = createAction<Partial<IRoleModel>[]>(
  'Combobox list of roles has been fetched successfully',
);
export const getRoleComboboxListFailed = createAction(
  'Cannot fetch combobox roles list',
);

// export actions
export const {
  exportReportRequest: exportRoleRequest,
  exportReportSuccess: exportRoleSuccess,
  exportReportFailed: exportRoleFailed,
} = new ExportActionCreator('Role').takeExportActions();
