import React from 'react';
import { IconButton } from '@mui/joy';
import { ThreeDotsMenuDot } from './ThreeDotsMenuDot';
import { SxProps } from '@mui/joy/styles/types';

interface IThreeDotsMenuProps {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  sx?: SxProps;
}

export const ThreeDotsMenu = ({ onClick, sx }: IThreeDotsMenuProps) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ...sx,
      }}
    >
      <ThreeDotsMenuDot />
      <ThreeDotsMenuDot />
      <ThreeDotsMenuDot />
    </IconButton>
  );
};
