import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IHeadCellWithOrderConfig,
  IInclusionObject,
  InclusionPath,
} from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { ICountResponse, IFilter, IListWithSummary } from 'src/modules/types';
import {
  Table,
  Pagination,
  TableBottom,
  TableContent,
} from 'src/components/Table';
import { useMainLayoutContext } from 'src/components/Layout/MainLayout/MainLayoutContext';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { TableSummary } from 'src/components/Table/components/TableSummary';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { IFinancialReportTableDataItem } from 'src/modules/types/financial-report';
import { useHasUserAccessToResource } from 'src/config';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { Typography } from '@mui/joy';

const financialReportsUrl = '/financial-tracker';

const weeklyGrossMarginDefaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

const inclusionObj: IInclusionObject = {
  site: {
    relationType: 'left',
  },
};

export type ITableData = IListWithSummary<IFinancialReportTableDataItem>;

interface IFinancialReportTableProps {
  siteId?: number;
  yearNumber?: number;
  weekNumber?: number;
  data: ITableData;
  isTableDataLoading: boolean;
  fetchData: (filterList: IFilter) => Promise<void>;
  selectedRecordsIds: Array<number>;
  onItemsSelectionChange: (selectedEntitiesId: number[]) => void;
  onUpdateClick: () => void;
}

export const FinancialReportTable = ({
  siteId,
  yearNumber,
  weekNumber,
  fetchData,
  data,
  isTableDataLoading,
  selectedRecordsIds,
  onItemsSelectionChange,
  onUpdateClick,
}: IFinancialReportTableProps) => {
  const { t } = useTranslation();

  const allowedToUpdateRecords = useHasUserAccessToResource(
    apiEndpoints.FINANCIAL_TRACKER,
    'update',
  );

  const { pageWithCopyrightContentHeight } = useMainLayoutContext();

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${financialReportsUrl}/count`, {
    count: 0,
  });

  const where = React.useMemo(
    () => ({
      siteId: siteId ? siteId : undefined,
      yearNumber: yearNumber ? yearNumber : undefined,
      weekNumber: weekNumber ? weekNumber : undefined,
    }),
    [siteId, weekNumber, yearNumber],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'yearNumber',
        label: t('productions_uph_reports.year'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'weekNumber',
        label: t('productions_uph_reports.week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'yearWeek',
        label: t('productions_uph_reports.year_week'),
        orderConfig: { orderBy: 'yearWeek' },
      },
      {
        id: 'site.name',
        label: t('productions_uph_reports.site.name'),
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'] as InclusionPath,
        },
      },
      {
        id: 'revenue',
        label: t('reports.headers.revenue'),
        orderConfig: { orderBy: 'revenue' },
      },

      {
        id: 'staffingCost',
        label: t('reports.headers.staffing_cost'),
        orderConfig: { orderBy: 'staffingCost' },
      },
      {
        id: 'internalCost',
        label: t('reports.headers.internal_cost'),
        orderConfig: { orderBy: 'internalCost' },
      },
      {
        id: 'otherCosts',
        label: t('reports.headers.other_costs'),
        orderConfig: { orderBy: 'otherCosts' },
      },
      {
        id: 'grossMarginValue',
        label: t('reports.headers.gross_margin_value'),
        orderConfig: { orderBy: 'grossMarginValue' },
      },
      {
        id: 'grossMarginPercent',
        label: t('reports.headers.gross_margin_percent'),
        orderConfig: { orderBy: 'grossMarginPercent' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: weeklyGrossMarginDefaultOrderDetails.order,
    defaultOrderBy: weeklyGrossMarginDefaultOrderDetails.orderBy,
  });

  const tableData = React.useMemo(() => {
    return data.items.map((item: IFinancialReportTableDataItem) => {
      const grossMarginValue = Number(
        (item.grossMarginValue as string).replace(/\$|,/g, ''),
      );
      const grossMarginValueComponent = (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 0.5,
            color: grossMarginValue < 0 ? 'red' : 'inherit',
          }}
        >
          {item.grossMarginValue}
        </Typography>
      );

      const grossMarginPercentComponent = (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 0.5,
            color: Number(item.grossMarginPercent) < 0 ? 'red' : 'inherit',
          }}
        >
          {item.grossMarginPercent}
        </Typography>
      );
      return {
        ...item,
        grossMarginValue: grossMarginValueComponent,
        grossMarginPercent: grossMarginPercentComponent,
      };
    });
  }, [data.items]);

  const listFilter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    inclusionObj,
    defaultWhere: where,
    headCellsOrderDetails,
  });
  const countFilter = useGenerateRequestFilter({
    inclusionObj,
    defaultWhere: where,
    headCellsOrderDetails,
  });

  React.useEffect(() => {
    fetchData({ filter: listFilter });
  }, [fetchData, listFilter]);

  React.useEffect(() => {
    fetchCountData({ filter: countFilter });
  }, [countFilter, fetchCountData]);

  return (
    <Table
      tableUniqueKey="financialReports/table"
      sx={{
        height: isTableDataLoading ? pageWithCopyrightContentHeight : 'auto',
      }}
    >
      {isTableDataLoading && <MainContentLoader />}

      <TableContent
        data={tableData as any}
        headCells={headCells}
        isDataLoading={isTableDataLoading || isCountDataLoading}
        order={order}
        orderBy={orderBy}
        selectedRows={selectedRecordsIds}
        onSort={(orderBy, order) => {
          setOrder(order);
          setOrderBy(orderBy);
        }}
        onRowCheckboxClick={
          allowedToUpdateRecords ? onItemsSelectionChange : undefined
        }
      >
        {data.summary.map((item) => (
          <TableSummary
            additionalColumnsQuantityToSpan={Number(allowedToUpdateRecords)}
            key={item.title}
            {...item}
          />
        ))}
      </TableContent>

      {Boolean(selectedRecordsIds.length) && (
        <TableSelectedItemsActions
          selectedItemsQuantity={selectedRecordsIds.length}
          updateActionConfiguration={{ onClick: onUpdateClick }}
        />
      )}

      <TableBottom>
        <Pagination
          count={countData.count}
          rowsPerPage={limit}
          currentPageNumber={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={(limit) => {
            setLimit(limit);
            setPage(DEFAULT_PAGE);
          }}
        />
      </TableBottom>
    </Table>
  );
};
