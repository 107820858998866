/**
 * Materials UI grid system constants
 */

export const MUI_SIZE_25 = 25;
export const MUI_SIZE_12 = 12;
export const MUI_SIZE_11 = 11;
export const MUI_SIZE_10 = 10;
export const MUI_SIZE_9 = 9;
export const MUI_SIZE_8 = 8;
export const MUI_SIZE_7 = 7;
export const MUI_SIZE_6 = 6;
export const MUI_SIZE_5 = 5;
export const MUI_SIZE_4 = 4;
export const MUI_SIZE_3 = 3;
export const MUI_SIZE_2 = 2;
export const MUI_SIZE_1 = 1;
export const MUI_SIZE_0 = 0;
