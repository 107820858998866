import React from 'react';
import { useTranslation } from 'react-i18next';

import { clients } from '../../modules/constants';
import WeeklyGrossMarginDetailedReport from '../../components/Reports/WeeklyGrossMarginDetailsReport';

interface IProps {
  siteId?: number;
  year?: number;
  week?: number;
}

export const WeeklyGrossMarginDetailed: React.FC<IProps> = ({
  siteId,
  week,
  year,
}) => {
  const { t } = useTranslation();

  return (
    <WeeklyGrossMarginDetailedReport
      clientKey={clients.target}
      siteId={siteId}
      weekNumber={week}
      yearNumber={year}
      tableName={t('target_reports.table_names.weekly_gm_detailed')}
    />
  );
};

export default WeeklyGrossMarginDetailed;
