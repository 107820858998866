import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  IStandardListResponse,
  IStandardModel,
  IStandardState,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IStandardState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  standard: NaN,
  baseTime: NaN,
  list: {},
  comboboxList: [],
};

/**
 * Flush actions
 */
const flushStandardState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createStandardRequest = (state: IStandardState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createStandardSuccess = (
  state: IStandardState,
  payload: IStandardModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createStandardFailed = (state: IStandardState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getStandardListRequest = (state: IStandardState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getStandardListSuccess = (
  state: IStandardState,
  payload: IStandardListResponse,
) => ({
  ...state,
  list: {
    ...state.list,
    [payload.siteId]: payload.data,
  },
  error: false,
  is_data_requested: false,
});

const getStandardListFailed = (state: IStandardState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteStandardRequest = (state: IStandardState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteStandardFailed = (state: IStandardState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateStandardsRequest = (state: IStandardState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateStandardsFailed = (state: IStandardState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getStandardComboboxListRequest = (state: IStandardState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getStandardComboboxListSuccess = (
  state: IStandardState,
  payload: Partial<IStandardModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getStandardComboboxListFailed = (
  state: IStandardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IStandardState>();

/**
 * Standard reducer
 */
export const standard = createReducer<IStandardState>({}, defaultState)
  // flush actions
  .on(actions.flushStandardState, flushStandardState)
  // creation actions
  .on(actions.createStandardRequest, createStandardRequest)
  .on(actions.createStandardSuccess, createStandardSuccess)
  .on(actions.createStandardFailed, createStandardFailed)
  // get list actions
  .on(actions.getStandardListRequest, getStandardListRequest)
  .on(actions.getStandardListSuccess, getStandardListSuccess)
  .on(actions.getStandardListFailed, getStandardListFailed)
  // deletion actions
  .on(actions.deleteStandardRequest, deleteStandardRequest)
  .on(actions.deleteStandardFailed, deleteStandardFailed)
  // updating actions
  .on(actions.updateStandardsRequest, updateStandardsRequest)
  .on(actions.updateStandardsFailed, updateStandardsFailed)
  // get combobox list actions
  .on(actions.getStandardComboboxListRequest, getStandardComboboxListRequest)
  .on(actions.getStandardComboboxListSuccess, getStandardComboboxListSuccess)
  .on(actions.getStandardComboboxListFailed, getStandardComboboxListFailed)
  // export actions
  .on(actions.exportStandardRequest, exportRequest)
  .on(actions.exportStandardSuccess, exportSuccess)
  .on(actions.exportStandardFailed, exportFailed);

export default standard;
