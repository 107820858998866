import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  IQuestionCategoryModel,
  IQuestionCategoryState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

const defaultState: IQuestionCategoryState = {
  refreshKey: 0,
  list: [],
  comboboxList: [],
  count: 0,
};

const flushQuestionCategoryState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

const createQuestionCategoryRequest = (state: IQuestionCategoryState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const createQuestionCategorySuccess = (
  state: IQuestionCategoryState,
  payload: IQuestionCategoryModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const createQuestionCategoryFailed = (
  state: IQuestionCategoryState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getQuestionCategoryListRequest = (state: IQuestionCategoryState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getQuestionCategoryListSuccess = (
  state: IQuestionCategoryState,
  payload: IQuestionCategoryModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});
const getQuestionCategoryListFailed = (
  state: IQuestionCategoryState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getQuestionCategoryCountRequest = (state: IQuestionCategoryState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getQuestionCategoryCountSuccess = (
  state: IQuestionCategoryState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const getQuestionCategoryCountFailed = (
  state: IQuestionCategoryState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const deleteQuestionCategoryRequest = (state: IQuestionCategoryState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteQuestionCategoryFailed = (
  state: IQuestionCategoryState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updateQuestionCategoriesRequest = (state: IQuestionCategoryState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateQuestionCategoriesFailed = (
  state: IQuestionCategoryState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getQuestionCategoryComboboxListRequest = (
  state: IQuestionCategoryState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getQuestionCategoryComboboxListSuccess = (
  state: IQuestionCategoryState,
  payload: Partial<IQuestionCategoryModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});
const getQuestionCategoryComboboxListFailed = (
  state: IQuestionCategoryState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IQuestionCategoryState>();

const exportQuestionCategoryRequest = exportRequest;
const exportQuestionCategorySuccess = exportSuccess;
const exportQuestionCategoryFailed = exportFailed;

/**
 * QuestionCategory reducer
 */
export const questionCategory = createReducer<IQuestionCategoryState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushQuestionCategoryState, flushQuestionCategoryState)
  // creation actions
  .on(actions.createQuestionCategoryRequest, createQuestionCategoryRequest)
  .on(actions.createQuestionCategorySuccess, createQuestionCategorySuccess)
  .on(actions.createQuestionCategoryFailed, createQuestionCategoryFailed)
  // get list actions
  .on(actions.getQuestionCategoryListRequest, getQuestionCategoryListRequest)
  .on(actions.getQuestionCategoryListSuccess, getQuestionCategoryListSuccess)
  .on(actions.getQuestionCategoryListFailed, getQuestionCategoryListFailed)
  // get count actions
  .on(actions.getQuestionCategoryCountRequest, getQuestionCategoryCountRequest)
  .on(actions.getQuestionCategoryCountSuccess, getQuestionCategoryCountSuccess)
  .on(actions.getQuestionCategoryCountFailed, getQuestionCategoryCountFailed)
  // deletion actions
  .on(actions.deleteQuestionCategoryRequest, deleteQuestionCategoryRequest)
  .on(actions.deleteQuestionCategoryFailed, deleteQuestionCategoryFailed)
  // updating actions
  .on(actions.updateQuestionCategoriesRequest, updateQuestionCategoriesRequest)
  .on(actions.updateQuestionCategoriesFailed, updateQuestionCategoriesFailed)
  // get combobox list actions
  .on(
    actions.getQuestionCategoryComboboxListRequest,
    getQuestionCategoryComboboxListRequest,
  )
  .on(
    actions.getQuestionCategoryComboboxListSuccess,
    getQuestionCategoryComboboxListSuccess,
  )
  .on(
    actions.getQuestionCategoryComboboxListFailed,
    getQuestionCategoryComboboxListFailed,
  )
  // export actions
  .on(actions.exportQuestionCategoryRequest, exportQuestionCategoryRequest)
  .on(actions.exportQuestionCategorySuccess, exportQuestionCategorySuccess)
  .on(actions.exportQuestionCategoryFailed, exportQuestionCategoryFailed);

export default questionCategory;
