import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { map } from 'lodash';
import { createFinancialTrackerScheme } from 'src/modules/schemes';
import FormikNumberField from 'src/components/Formik/FormikNumberField';

export const formFieldsTranslationDictionary = {
  'financial-tracker.yearNumber': 'productions_uph_reports.year',
  'financial-tracker.weekNumber': 'productions_uph_reports.week',
  'financial-tracker.revenue': 'reports.headers.revenue',
  'financial-tracker.staffingCost': 'reports.headers.staffing_cost',
  'financial-tracker.internalCost': 'reports.headers.internal_cost',
  'financial-tracker.otherCosts': 'reports.headers.other_costs',
};

const initialValues = {
  // Required properties
  siteId: NaN,
  yearNumber: NaN,
  weekNumber: NaN,
  revenue: NaN,
  staffingCost: NaN,
  internalCost: NaN,
  otherCosts: NaN,
};

interface IFinancialReportsCreateRecordFormProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (data: typeof initialValues) => void;
}

export const FinancialReportsCreateRecordForm = ({
  isOpen,
  onClose,
  onCreate,
}: IFinancialReportsCreateRecordFormProps) => {
  const { t } = useTranslation();

  const validate = useValidate(createFinancialTrackerScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      onCreate(data);
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('financial_reports.crate_financial_record')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('payboard.site')}
              placeholder={t('common.select')}
            />
          </FormFieldContainer>

          {map(['yearNumber', 'weekNumber'], (key) => (
            <FormFieldContainer key={key}>
              <FormikNumberField
                required={['yearNumber', 'weekNumber'].includes(key)}
                fullWidth
                id={key}
                label={t(
                  formFieldsTranslationDictionary[`financial-tracker.${key}`],
                )}
                name={key}
                formik={formik}
              />
            </FormFieldContainer>
          ))}

          {map(
            ['revenue', 'staffingCost', 'internalCost', 'otherCosts'],
            (key) => (
              <FormFieldContainer key={key}>
                <FormikNumberField
                  variant="outlined"
                  required={[
                    'revenue',
                    'staffingCost',
                    'internalCost',
                    'otherCosts',
                  ].includes(key)}
                  fullWidth
                  id={key}
                  label={t(
                    formFieldsTranslationDictionary[`financial-tracker.${key}`],
                  )}
                  name={key}
                  formik={formik}
                />
              </FormFieldContainer>
            ),
          )}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
