import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ICommonFilterProps } from 'src/components/Table/components/TableActions/components/Filter/types';
import { TableContext } from 'src/components/Table/TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { useFetchShiftsCombobox } from 'src/modules/utils';

type IShiftsComboBoxFilterProps = Omit<
  IComboboxProps,
  'value' | 'options' | 'name'
> &
  ICommonFilterProps & {
    propertyAsId?: 'id' | 'name';
  };

export const ShiftsComboBoxFilter: React.FC<IShiftsComboBoxFilterProps> = ({
  value,
  name,
  propertyAsId = 'id',
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const fetchShiftsCombobox = useFetchShiftsCombobox();

  const options = useSelector(getShiftOptionsByGloballySites);

  useEffect(() => {
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue =
    options.find(
      (option) =>
        option[propertyAsId] ===
        (propertyAsId === 'id' ? Number(valueId) : valueId),
    ) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={options}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
