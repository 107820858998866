import React from 'react';
import { Box, useTheme } from '@mui/joy';
import LogoBlack from '../assets/images/logo-black.png';
import { PageContentWithTopToolbar } from 'src/components/PageContent';

const Landing = () => {
  const theme = useTheme();

  return (
    <PageContentWithTopToolbar>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          sx={{
            width: '100%',
            maxWidth: 350,
            marginTop: '-54px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: 200,
            },
          }}
          alt="Logo"
          src={LogoBlack}
        />
      </Box>
    </PageContentWithTopToolbar>
  );
};

export default Landing;
