import React, { useState } from 'react';
import {
  Modal,
  Avatar,
  Box,
  AvatarProps,
  AvatarGroup,
  Typography,
} from '@mui/joy';
import { getFileExtensionFromFileName } from 'src/modules/utils/helpers/common';
import { XCircleSvg } from '../svgIcons';
import { ThumbnailImageWithFallback } from './ThumbnailImageWithFallback';

const Thumbnail = (props: AvatarProps) => {
  const { src, alt, ...rest } = props;

  const [open, setOpen] = useState<boolean>(false);

  const onImageClick = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <AvatarGroup
        onClick={onImageClick}
        sx={{
          cursor: 'pointer',
          '--AvatarGroup-gap': '-12px',
          '--Avatar-size': '36px',
          '--Avatar-ringSize': '2px',
        }}
      >
        <Avatar
          src={src}
          alt={alt}
          {...rest}
          sx={{ cursor: 'pointer' }}
          slotProps={{
            root: {
              sx: {
                borderRadius: 8,
              },
            },
          }}
          variant="outlined"
        >
          <Typography
            level="text_xs"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {getFileExtensionFromFileName(src ?? '')}
          </Typography>
        </Avatar>
      </AvatarGroup>

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          onClick={onClose}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: '64px',
          }}
        >
          <Box
            onClick={onClose}
            sx={{ position: 'absolute', right: 24, top: 24, cursor: 'pointer' }}
          >
            <XCircleSvg />
          </Box>

          {src && <ThumbnailImageWithFallback src={src} />}
        </Box>
        {/* </Box> */}
      </Modal>
    </>
  );
};

export default Thumbnail;
