import React from 'react';
import { isEmpty } from 'lodash';
import { Alert, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '../../../../../modules/types';
import { AlertTriangleSvg } from 'src/components/svgIcons';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import { FormFieldContainer } from '../../../../../components/Form/FormFieldContainer';
import {
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
} from 'src/components/_ui-kit/Drawer';

export interface IPricingCreateSingleRecord {
  metatags: string[];
  formik: AnyObject;
  pricingMetatags: React.ReactNode;
}

export const PricingCreateSingleRecord = ({
  metatags,
  formik,
  pricingMetatags,
}: IPricingCreateSingleRecord) => {
  const { t } = useTranslation();

  if (isEmpty(metatags)) {
    return (
      <FormFieldContainer>
        <Alert startDecorator={<AlertTriangleSvg />}>
          {t('pricing.site_metetags_alert')}
        </Alert>
      </FormFieldContainer>
    );
  }

  return (
    <>
      <Box
        sx={{
          maxWidth: 600,
        }}
      >
        <DrawerBodySectionContent>
          <DrawerBodySectionTitle>
            {t('pricing.meta_tags')}
          </DrawerBodySectionTitle>

          {pricingMetatags}
        </DrawerBodySectionContent>

        <DrawerBodySectionContent>
          <DrawerBodySectionTitle>
            {t('common.form_fields')}
          </DrawerBodySectionTitle>
          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('pricing.price')}
              id="price"
              name="price"
              autoComplete="price"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('pricing.incentivePrice')}
              id="incentivePrice"
              name="incentivePrice"
              autoComplete="incentivePrice"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('pricing.overtimePrice')}
              id="overtimePrice"
              name="overtimePrice"
              autoComplete="overtimePrice"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              disabled
              fullWidth
              label={t('pricing.minUph')}
              id="minUph"
              name="minUph"
              autoComplete="minUph"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('pricing.maxUph')}
              id="maxUph"
              name="maxUph"
              autoComplete="maxUph"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              label={t('issues.description')}
              id="description"
              name="description"
              autoComplete="description"
              formik={formik}
            />
          </FormFieldContainer>
        </DrawerBodySectionContent>
      </Box>
    </>
  );
};
