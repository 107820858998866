import React from 'react';
import { TextFieldProps } from '@mui/material';
import { format, getMonth, getYear, parse, parseISO, subYears } from 'date-fns';
import { IConcatenateYearWeekArgs } from 'src/modules/utils/helpers/formatters';

import { AutocompleteProps } from '@mui/joy';
import ComboBox, { ComboBoxOption } from '../../ComboBox';

interface IYearMonthComboBoxProps {
  id: string;
  optionsToYear?: number;
  optionsToMonth?: number;
  optionsFromYear?: number;
  optionsFromMonth?: number;
  optionsOrder?: 'desc' | 'asc';
  label?: string;
  value?: ComboBoxOption['id'] | null;
  onChange: (
    e: React.SyntheticEvent<Element, Event>,
    option: ComboBoxOption | null,
  ) => void;
  autocompleteProps?: Partial<AutocompleteProps<any, any, any, any>>;
  textFiledProps?: TextFieldProps;
}

const formatOptionName = ({ year, week: month }: IConcatenateYearWeekArgs) => {
  return `${year}-${format(parse(`${month}`, 'M', new Date()), 'MMM')}`;
};

const formatOptionId = ({ year, week: month }: IConcatenateYearWeekArgs) => {
  return Number(`${year}${String(month).padStart(2, '0')}`);
};

const FIRST_MONTH = 1;
const currentYear = getYear(new Date());
const currentMonth = getMonth(new Date()) + 1;
const thirtyYearsAgoYear = getYear(subYears(new Date(), 30));

const generateYearMonths = ({
  optionsFromYear = Number(thirtyYearsAgoYear),
  optionsFromMonth = FIRST_MONTH,
  optionsToYear = currentYear,
  optionsToMonth = currentMonth,
}): Array<ComboBoxOption<number>> => {
  const options: Array<ComboBoxOption<number>> = [];

  for (let year = optionsFromYear; year <= optionsToYear; year++) {
    const lastMonthDate = parseISO(`${year}-12-01`);
    const lastMonth = getMonth(lastMonthDate) + 1;

    let monthToCompareWith = lastMonth;
    if (year === optionsToYear) {
      monthToCompareWith = optionsToMonth;
    }

    let month = year === optionsFromYear ? optionsFromMonth : FIRST_MONTH;
    while (month <= monthToCompareWith) {
      options.push({
        id: formatOptionId({ year, week: month }),
        name: formatOptionName({ year, week: month }),
      });

      month++;
    }
  }

  return options;
};

export const YearMonthCombobox: React.FC<IYearMonthComboBoxProps> = ({
  id,
  optionsFromYear = Number(thirtyYearsAgoYear),
  optionsFromMonth = FIRST_MONTH,
  optionsToYear = currentYear,
  optionsToMonth = currentMonth,
  optionsOrder = 'desc',
  label,
  value,
  onChange,
}) => {
  const options =
    optionsOrder === 'asc'
      ? generateYearMonths({
          optionsFromYear,
          optionsFromMonth,
          optionsToYear,
          optionsToMonth,
        })
      : generateYearMonths({
          optionsFromYear,
          optionsFromMonth,
          optionsToYear,
          optionsToMonth,
        }).reverse();

  const v = React.useMemo(() => options.find((o) => o.id === value), [
    options,
    value,
  ]);

  return (
    <ComboBox
      id={id}
      placeholder={label}
      value={v}
      options={options}
      onChange={onChange}
    />
  );
};
