import { ISagaActionBind } from '../../types';
import { Api } from '../../utils';
import * as actions from '../../actions/expeditors/dailyReport.actions';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';
import { IProductionDailyUphBase } from 'src/modules/types/productionUphReports';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getExpeditorsDailyReportListRequest,
    saga: createListSaga<IProductionDailyUphBase>({
      apiCall: Api.ExpeditorsDaily.list,
      actionSuccess: actions.getExpeditorsDailyReportListSuccess,
      actionFailed: actions.getExpeditorsDailyReportListFailed,
    }),
  },
  {
    action: actions.getExpeditorsDailyReportCountRequest,
    saga: createCountSaga({
      apiCall: Api.ExpeditorsDaily.count,
      actionSuccess: actions.getExpeditorsDailyReportCountSuccess,
      actionFailed: actions.getExpeditorsDailyReportCountFailed,
    }),
  },
];
