import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateOtherCosts,
  IdsArray,
  IFilter,
  IOtherCostsModel,
  IUpdateOtherCosts,
  PayloadWithFilters,
} from '../types';

// Flush actions
export const flushOtherCostsState = createAction(
  'Flush otherCosts data in store',
);

// Creation actions
export const createOtherCostsRequest = createAction<
  PayloadWithFilters<ICreateOtherCosts>
>('Make request to create a new otherCosts');
export const createOtherCostsSuccess = createAction<IOtherCostsModel>(
  'New otherCosts has been created successfully',
);
export const createOtherCostsFailed = createAction(
  'Cannot create a new otherCosts',
);

// Fetch otherCosts actions
export const getOtherCostsListRequest = createAction<IFilter>(
  'Make request to fetch otherCosts list',
);
export const getOtherCostsListSuccess = createAction<IOtherCostsModel[]>(
  'OtherCosts list has been fetched successfully',
);
export const getOtherCostsListFailed = createAction(
  'Cannot fetch otherCosts list',
);

// get otherCosts count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getOtherCostsCountRequest = createAction<IFilter>(
  'Make request to get otherCosts count',
);
export const getOtherCostsCountSuccess = createAction<ICountResponse>(
  'OtherCosts count has been fetched successfully',
);
export const getOtherCostsCountFailed = createAction(
  'Cannot get otherCosts count',
);

// delete actions
export const deleteOtherCostsRequest = createAction<
  PayloadWithFilters<{ ids: IdsArray }>
>('Make request to delete otherCosts');
export const deleteOtherCostsFailed = createAction('Cannot delete otherCosts');

// Updating actions
export const updateOtherCostsRequest = createAction<
  PayloadWithFilters<IUpdateOtherCosts[]>
>('Make request to update otherCosts');
export const updateOtherCostsFailed = createAction('Cannot update otherCosts');
