import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ClientKeys,
  ICountResponse,
  ISiteImportingSettingsModel,
  ISiteImportingSettingsState,
  ISiteModel,
} from '../types';

// default state
const defaultState: ISiteImportingSettingsState = {
  refreshKey: 0,
  id: NaN,
  clientKey: '',
  siteUuid: '',
  productionRepositoryKey: '',
  costPlusByExternalHoursServiceKey: '',
  weeklyCpuKey: '',
  transformerKey: '',
  aggregationKey: '',
  fileRegex: '',
  transformOnSync: true,
  bucket: '',
  tableType: 'production',
  list: [],
  comboboxList: [],
  clientKeys: [],
  count: 0,
  comboboxSitesImportingList: [],
  isDataLoading: false,
  isDataManaging: false,
};

/**
 * Flush actions
 */
const flushSiteImportingSettingsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createSiteImportingSettingsRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  isDataManaging: true,
});

const createSiteImportingSettingsSuccess = (
  state: ISiteImportingSettingsState,
  payload: ISiteImportingSettingsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isDataManaging: false,
});

const createSiteImportingSettingsFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isDataManaging: false,
});

/**
 * Get list actions
 */
const getSiteImportingSettingsListRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  isDataLoading: true,
});

const getSiteImportingSettingsListSuccess = (
  state: ISiteImportingSettingsState,
  payload: ISiteImportingSettingsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isDataLoading: false,
});

const getSiteImportingSettingsListFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isDataLoading: false,
});

/**
 * Get count actions
 */
const getSiteImportingSettingsCountRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  isDataLoading: true,
});

const getSiteImportingSettingsCountSuccess = (
  state: ISiteImportingSettingsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isDataLoading: false,
});

const getSiteImportingSettingsCountFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isDataLoading: false,
});

/**
 * Deletion actions
 */
const deleteSiteImportingSettingsRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  isDataManaging: true,
});

const deleteSiteImportingSettingsSuccess = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  isDataManaging: false,
});

const deleteSiteImportingSettingsFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isDataManaging: false,
});

/**
 * Updating actions
 */
const updateSiteImportingSettingsRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  isDataManaging: true,
});

const updateSiteImportingSettingsSuccess = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  isDataManaging: false,
});

const updateSiteImportingSettingsFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isDataManaging: false,
});

/**
 * Get combobox list actions
 */
const getSiteImportingSettingsComboboxListRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSiteImportingSettingsComboboxListSuccess = (
  state: ISiteImportingSettingsState,
  payload: Partial<ISiteImportingSettingsModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getSiteImportingSettingsComboboxListFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get clientKeys actions
 */
const getSiteImportingSettingsClientKeysRequest = (
  state: ISiteImportingSettingsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getSiteImportingSettingsClientKeysSuccess = (
  state: ISiteImportingSettingsState,
  payload: ClientKeys,
) => ({
  ...state,
  clientKeys: payload,
  error: false,
  is_data_requested: false,
});

const getSiteImportingSettingsClientKeysFailed = (
  state: ISiteImportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getSiteImportingComboboxListSuccess = (
  state: ISiteImportingSettingsState,
  payload: ISiteModel[],
) => ({
  ...state,
  comboboxSitesImportingList: payload,
});

/**
 * SiteImportingSettings reducer
 */
export const siteImportingSettings = createReducer<ISiteImportingSettingsState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushSiteImportingSettingsState, flushSiteImportingSettingsState)
  // creation actions
  .on(
    actions.createSiteImportingSettingsRequest,
    createSiteImportingSettingsRequest,
  )
  .on(
    actions.createSiteImportingSettingsSuccess,
    createSiteImportingSettingsSuccess,
  )
  .on(
    actions.createSiteImportingSettingsFailed,
    createSiteImportingSettingsFailed,
  )
  // get list actions
  .on(
    actions.getSiteImportingSettingsListRequest,
    getSiteImportingSettingsListRequest,
  )
  .on(
    actions.getSiteImportingSettingsListSuccess,
    getSiteImportingSettingsListSuccess,
  )
  .on(
    actions.getSiteImportingSettingsListFailed,
    getSiteImportingSettingsListFailed,
  )
  // get count actions
  .on(
    actions.getSiteImportingSettingsCountRequest,
    getSiteImportingSettingsCountRequest,
  )
  .on(
    actions.getSiteImportingSettingsCountSuccess,
    getSiteImportingSettingsCountSuccess,
  )
  .on(
    actions.getSiteImportingSettingsCountFailed,
    getSiteImportingSettingsCountFailed,
  )
  // deletion actions
  .on(
    actions.deleteSiteImportingSettingsRequest,
    deleteSiteImportingSettingsRequest,
  )
  .on(
    actions.deleteSiteImportingSettingsSuccess,
    deleteSiteImportingSettingsSuccess,
  )
  .on(
    actions.deleteSiteImportingSettingsFailed,
    deleteSiteImportingSettingsFailed,
  )
  // updating actions
  .on(
    actions.updateSiteImportingSettingsRequest,
    updateSiteImportingSettingsRequest,
  )
  .on(
    actions.updateSiteImportingSettingsSuccess,
    updateSiteImportingSettingsSuccess,
  )
  .on(
    actions.updateSiteImportingSettingsFailed,
    updateSiteImportingSettingsFailed,
  )
  // get combobox list actions
  .on(
    actions.getSiteImportingSettingsComboboxListRequest,
    getSiteImportingSettingsComboboxListRequest,
  )
  .on(
    actions.getSiteImportingSettingsComboboxListSuccess,
    getSiteImportingSettingsComboboxListSuccess,
  )
  .on(
    actions.getSiteImportingSettingsComboboxListFailed,
    getSiteImportingSettingsComboboxListFailed,
  )
  // get clientKeys actions
  .on(
    actions.getSiteImportingSettingsClientKeysRequest,
    getSiteImportingSettingsClientKeysRequest,
  )
  .on(
    actions.getSiteImportingSettingsClientKeysSuccess,
    getSiteImportingSettingsClientKeysSuccess,
  )
  .on(
    actions.getSiteImportingSettingsClientKeysFailed,
    getSiteImportingSettingsClientKeysFailed,
  )
  // get importing sites combobox list
  .on(
    actions.getSiteImportingComboboxListSuccess,
    getSiteImportingComboboxListSuccess,
  );

export default siteImportingSettings;
