import React from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Sheet } from '@mui/joy';

import { MUI_SIZE_12, MUI_SIZE_6 } from 'src/config';
import { TableContext } from '../../../../../../TableContext';
import { YearMonthCombobox } from 'src/components/_ui-kit/YearMonthComboBox';
import { ComboBoxOption } from '../../../../../../../ComboBox';

const PopoverGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export interface IYearMonthRangePickerFilterValue {
  start?: string | null;
  end?: string | null;
}

export interface IYearMonthRangePickerFilterProps {
  name: string;
  label?: string;
  value?: IYearMonthRangePickerFilterValue;
  fromMonth?: number;
  toMonth?: number;
  fromYear?: number;
  toYear?: number;
}

export const YearMonthRangePickerFilter: React.FC<IYearMonthRangePickerFilterProps> = ({
  name,
  value,
  label,
  fromMonth,
  fromYear,
  toMonth,
  toYear,
}) => {
  const { t } = useTranslation();

  const { setFilter, filters } = React.useContext(TableContext);

  const handleYearMonthRangePickerFilter = (
    position: keyof IYearMonthRangePickerFilterValue,
  ) => (
    e: React.SyntheticEvent<Element, Event>,
    option: ComboBoxOption | null,
  ) => {
    const v: [string | number | null, string | number | null] = [
      get(filters, [name, 0], null),
      get(filters, [name, 1], null),
    ];

    set(v, [position === 'start' ? 0 : 1], option?.id);

    setFilter(name, v);
  };

  return (
    <Sheet sx={{ width: '100%', bgcolor: 'inherit' }}>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <PopoverGrid container justifyContent="center" spacing={3}>
          <Grid item md={MUI_SIZE_6} xs={MUI_SIZE_12}>
            <YearMonthCombobox
              id={`${name}_from`}
              label={t('common.from')}
              value={(value?.start as unknown) as ComboBoxOption['id']}
              optionsFromMonth={fromMonth}
              optionsToMonth={toMonth}
              optionsFromYear={fromYear}
              optionsToYear={toYear}
              onChange={handleYearMonthRangePickerFilter('start')}
            />
          </Grid>
          <Grid item md={MUI_SIZE_6} xs={MUI_SIZE_12}>
            <YearMonthCombobox
              id={`${name}_to`}
              label={t('common.to')}
              value={(value?.end as unknown) as ComboBoxOption['id']}
              optionsFromMonth={fromMonth}
              optionsToMonth={toMonth}
              optionsFromYear={fromYear}
              optionsToYear={toYear}
              onChange={handleYearMonthRangePickerFilter('end')}
            />
          </Grid>
        </PopoverGrid>
      </FormControl>
    </Sheet>
  );
};
