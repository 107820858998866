import { JSONSchemaType } from 'ajv';
import { TFunction } from 'i18next';
import { ICreateChecklistTaskData } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createChecklistTaskScheme: JSONSchemaType<ICreateChecklistTaskData> = {
  type: 'object',
  required: ['title', 'checklistId', 'startTime', 'endTime'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    title: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    checklistId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    startTime: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      format: 'date-time',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    endTime: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      format: 'date-time',
      formatMinimum: {
        $data: '1/startTime',
      },
      errorMessage: {
        formatMinimum: (i18n.t as TFunction)(
          'error.validation.date_greater_than',
          {
            otherDate: 'Start time',
          },
        ),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    text: {
      nullable: true,
      type: 'string',
    },
    recurrence: {
      nullable: true,
      type: 'string',
    },
    done: {
      nullable: true,
      type: 'boolean',
    },
  },
};
