import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { Shift } from './panels/Shift';
import { Workdays } from './panels/Workdays';

export const ShiftPage = () => {
  const { t } = useTranslation();

  const hasUserAccessToShiftTab = useHasUserAccessToViewTab(
    tabsPaths.SHIFT_TAB,
  );

  const hasUserAccessToShiftWorkdaysTab = useHasUserAccessToViewTab(
    tabsPaths.SHIFT_WORKDAYS_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.shifts'),
          hasUserAccess: hasUserAccessToShiftTab,
          component: <Shift />,
        },
        {
          title: t('main_menu.workdays'),
          hasUserAccess: hasUserAccessToShiftWorkdaysTab,
          component: <Workdays />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [t, hasUserAccessToShiftTab, hasUserAccessToShiftWorkdaysTab],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
