import {
  IProductionWeeklyUphBySiteAndEmployeeModel,
  IProductionWeeklyUphBySiteAndEmployeeState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionWeeklyUphBySiteAndEmployeeState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionWeeklyUphBySiteAndEmployeeListRequest,
  getListSuccess: getProductionWeeklyUphBySiteAndEmployeeListSuccess,
  getListFailed: getProductionWeeklyUphBySiteAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  IProductionWeeklyUphBySiteAndEmployeeState,
  IProductionWeeklyUphBySiteAndEmployeeModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionWeeklyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<
  IProductionWeeklyUphBySiteAndEmployeeState
>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionWeeklyUphBySiteAndEmployeeRequest,
  exportSuccess: exportProductionWeeklyUphBySiteAndEmployeeSuccess,
  exportFailed: exportProductionWeeklyUphBySiteAndEmployeeFailed,
} = new ExportReducerCreator().takeHandlers<
  IProductionWeeklyUphBySiteAndEmployeeState
>();

/**
 * actually Reducer
 */
export const productionWeeklyUphBySiteAndEmployee = createReducer<
  IProductionWeeklyUphBySiteAndEmployeeState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionWeeklyUphBySiteAndEmployeeListRequest,
    getProductionWeeklyUphBySiteAndEmployeeListRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndEmployeeListSuccess,
    getProductionWeeklyUphBySiteAndEmployeeListSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndEmployeeListFailed,
    getProductionWeeklyUphBySiteAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getProductionWeeklyUphBySiteAndEmployeeCountRequest,
    getProductionWeeklyUphBySiteAndEmployeeCountRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndEmployeeCountSuccess,
    getProductionWeeklyUphBySiteAndEmployeeCountSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteAndEmployeeCountFailed,
    getProductionWeeklyUphBySiteAndEmployeeCountFailed,
  )
  // export action
  .on(
    actions.exportProductionWeeklyUphBySiteAndEmployeeRequest,
    exportProductionWeeklyUphBySiteAndEmployeeRequest,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteAndEmployeeSuccess,
    exportProductionWeeklyUphBySiteAndEmployeeSuccess,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteAndEmployeeFailed,
    exportProductionWeeklyUphBySiteAndEmployeeFailed,
  );

export default productionWeeklyUphBySiteAndEmployee;
