import React from 'react';
import { Sheet } from '@mui/joy';
import { SideBar } from './SideBar';

interface IMainLayoutProps {
  serverError?: string | boolean;
}

export const MainLayoutComponent = ({
  children,
}: React.PropsWithChildren<IMainLayoutProps>) => {
  return (
    <Sheet
      sx={{
        height: '100%',
        display: 'flex',
        backgroundColor: 'inherit',
      }}
    >
      <SideBar />

      {children}
    </Sheet>
  );
};
