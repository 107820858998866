import React from 'react';
import { Box } from '@mui/material';
import { IMonthBasedInvoiceModel } from 'src/modules/types';

type IInvoiceMonthGeneralInfoTableProps = Pick<
  IMonthBasedInvoiceModel,
  | 'purchaseOrder'
  | 'dueDate'
  | 'monthEndingDate'
  | 'paymentTerms'
  | 'invoiceDate'
  | 'monthName'
>;

export const InvoiceMonthGeneralInfoTable: React.FC<IInvoiceMonthGeneralInfoTableProps> = ({
  purchaseOrder,
  dueDate,
  monthEndingDate,
  paymentTerms,
  invoiceDate,
  monthName,
}) => {
  return (
    <Box>
      <Box
        component="table"
        sx={{
          width: '100%',
          '& td': {
            padding: 0.5,
          },
          ' & th': {
            padding: 1,
            backgroundColor: '#bac3fd',
          },
        }}
      >
        <tr>
          <th>PO</th>
          <th>Month Ending Day</th>
          <th>Payment Terms</th>
          <th>Month</th>
          <th>Invoice Date</th>
          <th>Due Date</th>
        </tr>
        <tr>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {purchaseOrder}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {monthEndingDate}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {paymentTerms}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {monthName}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {invoiceDate}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {dueDate}
          </Box>
        </tr>
      </Box>
    </Box>
  );
};
