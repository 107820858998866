import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { createStaffingProviderRequest } from '../../modules/actions';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';

import { useBrowserHistoryFunctions, useValidate } from '../../modules/utils';
import { useFormik } from 'formik';
import { createStaffingProviderScheme } from '../../modules/schemes/staffing-providers';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const initialValues = {
  staffingProvider: '',
  markup: NaN,
  ngroupMarkup: NaN,
  ptoMarkup: null,
  holidayMarkup: null,
  etoMarkup: null,
  securityName: '',
  otMarkup: null,
};

const StaffingProvidersCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  // create dispatcher
  const dispatcher = useDispatch();

  const validate = useValidate(createStaffingProviderScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createStaffingProviderRequest({ data, navigate }));
    },
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('staffing_prov.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id="staffingProvider"
              label={t('staffing_prov.name')}
              name="staffingProvider"
              autoComplete="staffingProvider"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id="markup"
              label={t('staffing_prov.markup')}
              name="markup"
              autoComplete="markup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id="ngroupMarkup"
              label={t('staffing_prov.ng_markup')}
              name="ngroupMarkup"
              autoComplete="ngroupMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="ptoMarkup"
              label={t('staffing_prov.pto_markup')}
              name="ptoMarkup"
              autoComplete="ptoMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="holidayMarkup"
              label={t('staffing_prov.holiday_markup')}
              name="holidayMarkup"
              autoComplete="holidayMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="etoMarkup"
              label={t('staffing_prov.eto_markup')}
              name="etoMarkup"
              autoComplete="etoMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="securityName"
              label={t('staffing_prov.sec_name')}
              name="securityName"
              autoComplete="securityName"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="otMarkup"
              label={t('staffing_prov.ot_markup')}
              name="otMarkup"
              autoComplete="otMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default StaffingProvidersCreate;
