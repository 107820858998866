import { JSONSchemaType } from 'ajv';
import { IUserUpdatePasswordRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const updatePasswordScheme: JSONSchemaType<Omit<
  IUserUpdatePasswordRequest,
  'clear'
>> = {
  type: 'object',
  required: ['oldPassword', 'newPassword'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    oldPassword: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    newPassword: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    confirmPassword: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      const: {
        $data: '1/newPassword',
      } as any,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        const: i18n.t('error.validation.confirm_password_not_match'),
      },
    },
  },
};
