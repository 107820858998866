import { JSONSchemaType } from 'ajv';
import { ICreateSiteTaktExportingSettings } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createSiteTaktExportingSettingScheme: JSONSchemaType<ICreateSiteTaktExportingSettings> = {
  type: 'object',
  required: ['siteId', 'apiKey', 'apiKeySecret'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    apiKey: {
      type: 'string',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    apiKeySecret: {
      type: 'string',
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    employeesEndpoint: {
      type: 'string',
      nullable: true,
    },
    punchesEndpoint: {
      type: 'string',
      nullable: true,
    },
  },
};
