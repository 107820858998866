import React from 'react';
import { IdsArray, IStoreState } from '../types';
import { createSelector } from 'reselect';
import { filter, map } from 'lodash';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const getPointCollectionRefreshKey = (state: IStoreState) =>
  state.pointCollection.refreshKey;

export const getPointCollectionData = (state: IStoreState) =>
  state.pointCollection;

/**
 * Get server error
 * @param pointCollection - State pointCollection
 */
export const getServerError = ({ pointCollection }: IStoreState) =>
  pointCollection.error;

/**
 * Get pointCollection raw list
 * @param pointCollection - State pointCollection
 */
export const getPointCollectionRawList = ({ pointCollection }: IStoreState) =>
  pointCollection.list;

/**
 * Get pointCollection list
 * @param pointCollection - State pointCollection
 */
export const getPointCollectionList = createSelector(
  getPointCollectionRawList,
  (pointCollections) =>
    map(pointCollections, (pointCollection) => ({
      ...pointCollection,
      noReturn: !!pointCollection.noReturn,
      verbalWarning: !!pointCollection.verbalWarning,
      writtenWarning: !!pointCollection.writtenWarning,
    })),
);
export const getPointCollectionTable = createSelector(
  getPointCollectionList,
  (pointCollections) =>
    map(pointCollections, (item) => {
      return {
        ...item,
        noReturn: item.noReturn ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        verbalWarning: item.verbalWarning ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        writtenWarning: item.writtenWarning ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
      };
    }),
);
/**
 * Get pointCollections by array of ids
 * @param pointCollection - State pointCollection
 */
export const getPointCollectionsByIds = createSelector(
  getPointCollectionList,
  (pointCollections) => (ids: IdsArray) =>
    filter(pointCollections, (pointCollection) =>
      ids.includes(`${pointCollection.id}`),
    ),
);

/**
 * Get pointCollection count
 * @param pointCollection - State pointCollection
 */
export const getPointCollectionCount = ({ pointCollection }: IStoreState) =>
  pointCollection.count;

/**
 * Get combobox list
 */
export const getPointCollectionsComboboxList = ({
  pointCollection,
}: IStoreState) =>
  map(pointCollection.comboboxList, (pointCollectionItem) => ({
    id: pointCollectionItem.id,
    name: pointCollectionItem.numberOfPoints,
  }));

export const getPointCollectionsDataLoading = createSelector(
  getPointCollectionData,
  (data) =>
    data.isPointCollectionCountDataLoading ||
    data.isPointCollectionDataLoading ||
    data.isPointCollectionDataProcessing,
);
