import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadCell } from '../../../components/EnhancedTable';
import { ITableFilter } from '../../../components/EnhancedTable/EnhancedTableFilter';
import ReportWithOwnState from '../../../components/Reports/ReportWithOwnState';

const defaultFilterData = {
  order: [`yearWeek desc`],
};

export const FinancialTrackerTable = () => {
  const { t } = useTranslation();

  const headCells: HeadCell[] = [
    {
      id: 'yearNumber',
      disablePadding: false,
      label: t('productions_uph_reports.year'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'weekNumber',
      disablePadding: false,
      label: t('productions_uph_reports.week'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('productions_uph_reports.site.name'),
      orderByAnotherField: 'siteId',
    },
    {
      id: 'revenue',
      disablePadding: false,
      label: t('reports.headers.revenue'),
    },
    {
      id: 'staffingCost',
      disablePadding: false,
      label: t('reports.headers.staffing_cost'),
    },
    {
      id: 'internalCost',
      disablePadding: false,
      label: t('reports.headers.internal_cost'),
    },
    {
      id: 'otherCosts',
      disablePadding: false,
      label: t('reports.headers.other_costs'),
    },
    {
      id: 'grossMarginValue',
      disablePadding: false,
      label: t('reports.headers.gross_margin_detailed_value'),
    },
    {
      id: 'grossMarginPercent',
      disablePadding: false,
      label: t('reports.headers.gross_margin_detailed_percent'),
    },
  ];

  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('productions_uph_reports.site.name'),
      type: 'comboboxSites',
      operator: 'eq',
    },
    {
      name: 'yearNumber',
      label: t('productions_uph_reports.year'),
      operator: 'eq',
    },
    {
      name: 'weekNumber',
      label: t('productions_uph_reports.week'),
      operator: 'eq',
    },
  ];

  return (
    <ReportWithOwnState
      // disableDeletionInTable
      enableUpdationInTable
      reportUrl={'/financial-tracker'}
      tableName={t('reports.labels.financial_tracker')}
      headCells={headCells}
      filters={filters}
      defaultFilterData={defaultFilterData}
      defaultOrderBy="yearWeek"
    />
  );
};
