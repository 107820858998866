import React from 'react';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { DowntimeList } from 'src/pages/Downtime/panels/DowntimeList';
import { DowntimeChart } from 'src/pages/Downtime/panels/DowntimeChart';
import { TableContextProvider } from 'src/components/Table';

export const Downtime = () => {
  const [hasChartDataBeRefetch, setHasChartDataBeRefetch] = React.useState(
    false,
  );

  const onDataChange = React.useCallback(() => {
    setHasChartDataBeRefetch(true);
  }, []);

  const onRefetchEnd = React.useCallback(() => {
    setHasChartDataBeRefetch(false);
  }, []);

  return (
    <TableContextProvider>
      <PageContentWithTopToolbar>
        <PageContentChildContainer sx={{ pb: 1 }} fullHeight={false}>
          <DowntimeList onDataChange={onDataChange} />
        </PageContentChildContainer>

        <PageContentChildContainer>
          <DowntimeChart
            onRefetchEnd={onRefetchEnd}
            hasChartDataBeRefetch={hasChartDataBeRefetch}
          />
        </PageContentChildContainer>
      </PageContentWithTopToolbar>
    </TableContextProvider>
  );
};
