import React, { useMemo, useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { useFetchMetatagsByIds } from '../../modules/utils/hooks';
import { getCurrentRole } from '../../modules/selectors/auth';
import { getMetatagsNames } from '../../modules/selectors/metatag';
import { createStandardRequest } from '../../modules/actions';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { useValidate } from '../../modules/utils';
import { useFormik } from 'formik';
import { generateCreateStandardScheme } from '../../modules/schemes/standards';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { useBrowserHistoryFunctions } from '../../modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const generateInitialValues = (metatags: string[], siteId: number) => {
  const meta = metatags.reduce((all, metatag) => {
    all[metatag] = '';

    return all;
  }, {});

  return {
    standard: NaN,
    baseTime: NaN,
    siteId,
    ...meta,
  };
};

const StandardsCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const [siteId, setSiteId] = React.useState<number>(NaN);

  // fetch meta tags names list from store
  const metatagNames = useSelector(getMetatagsNames, shallowEqual);
  // get metatags for selected site
  const metatags = useMemo(
    () => (siteId && metatagNames[siteId] ? metatagNames[siteId] : []),
    [siteId, metatagNames],
  );
  const createStandardScheme = generateCreateStandardScheme(metatags);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: generateInitialValues(metatags, siteId),
    validate: useValidate(createStandardScheme),
    onSubmit: (data) => {
      dispatcher(createStandardRequest({ data, navigate }));
    },
  });

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const fetchMetatags = useFetchMetatagsByIds();

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchMetatags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);
  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('standards.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              onChange={(_, value) => setSiteId(value?.id ?? NaN)}
              placeholder={t('standards.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('standards.standard')}
              id="standard"
              name="standard"
              autoComplete="standard"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              label={t('standards.base_time')}
              id="baseTime"
              name="baseTime"
              autoComplete="baseTime"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          {map(metatags, (metatag: string) => (
            <FormFieldContainer key={`metatag-${metatag}`}>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                label={metatag}
                id={metatag}
                name={metatag}
                autoComplete={metatag}
                formik={formik}
              />
            </FormFieldContainer>
          ))}

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default StandardsCreate;
