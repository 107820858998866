import React from 'react';
import { Sheet, SheetProps } from '@mui/joy';
import {
  SIDEBAR_WIDTH,
  useMainLayoutContext,
} from '../Layout/MainLayout/MainLayoutContext';
import { PageContentCopyright } from './PageContentCopyright';

interface IPageContentProps extends SheetProps {
  children: React.ReactNode;
  hideCopyright?: boolean;
  fullHeight?: boolean;
}

export const PageContent = ({
  children,
  hideCopyright,
  fullHeight = true,
  ...props
}: IPageContentProps) => {
  const { sideBar, isSmallScreen, createTransition } = useMainLayoutContext();

  const widthForCalculations = isSmallScreen
    ? SIDEBAR_WIDTH.collapsed
    : sideBar.width;

  return (
    <Sheet
      sx={{
        position: isSmallScreen || !fullHeight ? 'relative' : 'fixed',
        height: '100%',
        width: `calc(100% - ${widthForCalculations}px)`,
        display: 'flex',
        flexBasis: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: `${widthForCalculations}px`,
        transition: createTransition(['margin', 'width']),
        backgroundColor: 'inherit',
      }}
      {...props}
    >
      {children}

      {!hideCopyright && <PageContentCopyright />}
    </Sheet>
  );
};
