import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStandardsByIds } from '../../selectors/standard';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import { getStandardListRequest } from '../../actions';
import { ROWS_PER_PAGE_DEFAULT } from '../../../components/EnhancedTable';

export const standardsInclusions = [
  {
    relation: 'site',
  },
  {
    relation: 'standardMetadata',
    scope: {
      include: [
        {
          relation: 'metatag',
        },
      ],
    },
  },
];

export const standardComboboxFilter = {
  filter: {
    fields: {
      id: true,
      name: true,
    },
    include: standardsInclusions,
  },
};

/**
 * A custom hook to fetch standards from store if they exist otherwise to make a request to fetch needed standards from
 * the server
 */
export const useFetchStandards = (siteId: number, ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch standards list from store
  const standards = useSelector(
    (state) => getStandardsByIds(state as IStoreState)(siteId, ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(standards)) {
      dispatcher(
        getStandardListRequest({
          siteId: +siteId,
          filter: {
            filter: {
              where: {
                id: {
                  inq: ids,
                },
              },
              offset: 0,
              limit: ROWS_PER_PAGE_DEFAULT,
              include: standardsInclusions,
            },
          },
        }),
      );
    }
  };
};
