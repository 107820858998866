import { useTheme } from '@mui/joy';

export const useFilterPanelPickersSx = (size: number) => {
  const theme = useTheme();

  return {
    ['& .MuiInputBase-root']: {
      boxShadow: 'none',
      height: size,
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      fontWeight: '500',
      borderRadius: '8px',
      '&:hover fieldset': {
        borderWidth: 0,
      },
    },
    ['& .MuiInputBase-root fieldset legend']: {
      display: 'none',
    },
    ['& .MuiInputBase-root fieldset']: {
      top: 0,
      height: 'inherit',
      borderWidth: 0,
      boxShadow: 'none',
    },
    ['& .MuiInputBase-root.Mui-error fieldset']: {
      borderWidth: 0,
    },
    ['& .MuiInputBase-root.Mui-focused fieldset']: {
      outlineStyle: 'solid',
      outlineWidth: '0px',
      outlineColor: 'transparent',
      borderWidth: 0,
    },
    ['& .MuiOutlinedInput-input']: {
      paddingLeft: '40px',
      paddingTop: 0,
      paddingBottom: 0,
      fontWeight: theme.fontWeight.regular,
      color: theme.palette.colors.text.text_primary.main,
      fontSize: '0.75rem',
      minHeight: size,
      '&::placeholder': {
        fontSize: '0.75rem',
      },
    },
    ['& .MuiIconButton-edgeEnd']: {
      position: 'absolute',
      left: 8,
      cursor: 'pointer',
      borderRadius: '2px',
      padding: 0.5,
    },
    ['& .MuiFormHelperText-root']: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      marginTop: '0.375rem',
      fontWeight: 400,
      marginLeft: 0,
      marginRight: 0,
      color: theme.palette.colors.text.text_error_primary.main,
    },
  };
};
