import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import get from 'lodash/get';
import {
  exportLogtimeMissedRequest,
  getLogtimeMissedCountRequest,
  getLogtimeMissedListRequest,
} from '../../../../../modules/actions';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { AnyObject, ILogtimeModel } from '../../../../../modules/types';
import {
  useCreateExportProps,
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useFetchStaffingProvidersCombobox,
  useSupervisorCombobox,
  useLogtimeSupervisorDefaultFilters,
  usePunchTypesOptions,
} from '../../../../../modules/utils/hooks';
import { map, omit } from 'lodash';
import { getDepartmentOptionsByGloballySites } from '../../../../../modules/selectors/department';
import { getStaffingProvidersComboboxList } from '../../../../../modules/selectors/staffingProvider';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import {
  parse,
  format,
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
} from '../../../../../modules/utils/dateWrapper';
import { getShiftOptionsByGloballySites } from '../../../../../modules/selectors/shift';
import { getUsersComboboxList } from '../../../../../modules/selectors/user';
import { IEntityBySiteComboboxOption } from '../../../../../components/Formik/comboboxes-with-entities/EntitiesBySyteCombobox';
import { useDefaultHiddenHeadCells } from '../../../../../modules/utils/hooks/table';
import {
  getIsMissedPunchesRequestInProgress,
  getLogtimeMissedPunchesCount,
  getLogtimeMissedTable,
  getLogtimeRefreshKey,
} from 'src/modules/selectors/logtime';

export const TimePunchesMissedComponent = () => {
  const { t } = useTranslation();

  const tableName = t('logtimes.tabs.missed.title');

  const getDefaultFilterBySupervisor = useLogtimeSupervisorDefaultFilters();
  const initialFilter = getDefaultFilterBySupervisor();

  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();

  // make request to fetch employees from the server if we don't have them in the store
  useEffect(() => {
    fetchDepartmentsCombobox();
    fetchShiftsCombobox();
    fetchStaffingProvidersCombobox();
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const punchTypesOptions = usePunchTypesOptions();

  const isTableDataLoading = useSelector(getIsMissedPunchesRequestInProgress);
  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );
  // fetch shifts list from store
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  // fetch staffing list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  const supervisors: IEntityBySiteComboboxOption[] = useSelector(
    getUsersComboboxList,
    shallowEqual,
  );
  const exportProps = useCreateExportProps(exportLogtimeMissedRequest);

  // fetch logtime list
  const list = useSelector(getLogtimeMissedTable, shallowEqual) as AnyObject[];

  const getPunchTypeName = React.useCallback(
    (punchType: ILogtimeModel['punchType']) => {
      switch (punchType) {
        case 'holiday':
          return t('logtimes.holiday');
        case 'pto':
          return t('logtimes.pto');
        case 'sick':
          return t('logtimes.sick');
        case 'retroPay':
          return t('logtimes.retro_pay');
        default:
          return t('logtimes.regular');
      }
    },
    [t],
  );

  // computed list with icons
  const computedList = useMemo(
    () =>
      map(list, (item) => ({
        ...item,
        lunchAdj: get(item.shift, 'lunchAdj'),
        timeIn: item.timeIn
          ? composeDate(
              item.timeIn,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        timeOut: item.timeOut
          ? composeDate(
              item.timeOut,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        paidTimeIn: item.paidTimeIn
          ? composeDate(
              item.paidTimeIn,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        timeTotal: item.timeTotal ? item.timeTotal.split('.')[0] : '',
        punchType: getPunchTypeName(item.punchType),
        updatedAt: item.updatedAt
          ? composeDate(
              item.updatedAt,
              parse(DATETIME_TIMEZONE),
              format(DATETIME_FORMAT_TO_SHOW),
            )
          : '',
        editUser: item.editUser?.id
          ? `${item.editUser.firstName} ${item.editUser.lastName} (${item.editUser.email})`
          : '',
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPunchTypeName, list],
  );

  const count = useSelector(getLogtimeMissedPunchesCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();

  // compose table header cells
  const baseHeadCells: HeadCell[] = [
    {
      id: 'employee.firstName',
      disablePadding: false,
      label: t('logtimes.emp_first_name'),
    },
    {
      id: 'employee.lastName',
      disablePadding: false,
      label: t('logtimes.emp_last_name'),
    },
    {
      id: 'employee.supervisor.firstName',
      disablePadding: false,
      label: t('logtimes.supervisor_firstName'),
    },
    {
      id: 'employee.supervisor.lastName',
      disablePadding: false,
      label: t('logtimes.supervisor_lastName'),
    },
    {
      id: 'employee.site.name',
      disablePadding: false,
      label: t('logtimes.site'),
    },
    { id: 'badge', disablePadding: false, label: t('logtimes.badge') },
    {
      id: 'timeIn',
      disablePadding: false,
      label: t('logtimes.login_datetime'),
    },
    {
      id: 'paidTimeIn',
      disablePadding: false,
      label: t('logtimes.paid_time_in'),
    },
    {
      id: 'timeOut',
      disablePadding: false,
      label: t('logtimes.logout_datetime'),
    },
    {
      id: 'shift.lunchAdj',
      disablePadding: false,
      label: t('logtimes.lunchAdj'),
    },
    { id: 'timeTotal', disablePadding: false, label: t('logtimes.total_time') },
    {
      id: 'approved',
      disablePadding: false,
      label: t('logtimes.approved'),
    },
    {
      id: 'department.name',
      disablePadding: false,
      label: t('logtimes.department'),
    },
    { id: 'shift.name', disablePadding: false, label: t('logtimes.shift') },
    {
      id: 'staffing.staffingProvider',
      disablePadding: false,
      label: t('logtimes.staffing_provider'),
    },
    {
      id: 'punchType',
      disablePadding: false,
      label: t('logtimes.punch_type'),
    },
    {
      id: 'comment',
      disablePadding: false,
      label: t('logtimes.comment'),
    },
    {
      id: 'updatedAt',
      disablePadding: false,
      label: t('logtimes.updatedAt'),
    },
    {
      id: 'editUser',
      disablePadding: false,
      disableSorting: true,
      label: t('logtimes.editUser'),
    },
    {
      id: 'isArchived',
      disablePadding: false,
      disableSorting: true,
      label: t('common.archived'),
    },
    {
      id: 'isMissed',
      disablePadding: false,
      disableSorting: true,
      label: t('common.missed'),
    },
  ];

  // Hide some columns by default
  useDefaultHiddenHeadCells({
    headCells: baseHeadCells,
    excludeHeadCellsList: ['editUser', 'updatedAt'],
    tableName,
  });

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'employee.site.id',
      label: t('logtimes.filter.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'employeeId',
      label: t('logtimes.filter.employee'),
      operator: 'eq',
      type: 'comboboxEmployee',
      propertyAsID: 'id',
    },
    {
      name: 'badge',
      label: t('logtimes.filter.badge'),
      operator: 'like',
    },
    {
      name: 'employee.supervisor.id',
      label: t('applicant.supervisorId'),
      operator: 'eq',
      type: 'comboboxEntitiesBySite',
      options: supervisors,
    },
    {
      name: 'timeIn',
      label: t('logtimes.filter.timeIn'),
      operator: 'gte',
      type: 'datetime',
    },
    {
      name: 'timeOut',
      label: t('logtimes.filter.timeOut'),
      operator: 'lte',
      type: 'datetime',
    },
    {
      name: 'department.id',
      label: t('logtimes.filter.department'),
      operator: 'eq',
      type: 'combobox',
      options: departments as ComboBoxOption[],
    },
    {
      name: 'shift.id',
      label: t('logtimes.filter.shift'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'staffing.id',
      label: t('logtimes.filter.staffingProvider'),
      operator: 'eq',
      type: 'combobox',
      options: staffingProviders as ComboBoxOption[],
    },
    {
      name: 'punchType',
      label: t('logtimes.punch_type'),
      operator: 'eq',
      type: 'combobox',
      options: punchTypesOptions as ComboBoxOption[],
    },
    {
      name: 'isArchived',
      label: t('common.archived'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.archived') },
          { value: false, label: t('common.unarchived') },
        ],
      },
    },
  ];

  // make request to fetch logtimes when component is mounted
  useEffect(() => {
    const df = getDefaultFilterBySupervisor();
    dispatcher(getLogtimeMissedListRequest({ filter: df }));
    // get total count
    dispatcher(
      getLogtimeMissedCountRequest({ filter: omit(df, ['limit', 'offset']) }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getLogtimeMissedListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getLogtimeMissedCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  return (
    <>
      <EnhancedTable
        ignoreGlobalSitesOnExport
        data={computedList}
        count={count}
        tableName={tableName}
        headCells={baseHeadCells}
        filters={filters}
        isTableDataLoading={isTableDataLoading}
        onSync={onSync}
        disableSelection
        include={initialFilter?.include}
        exportProps={exportProps}
      />
    </>
  );
};

export const TimePunchesMissed = () => {
  return <TimePunchesMissedComponent key={useSelector(getLogtimeRefreshKey)} />;
};
