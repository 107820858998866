import { createAction } from 'redux-act';
import { IPageSettingModel } from '../types';

export const getPageSettingRequest = createAction(
  'Make request to get page settings',
);
export const getPageSettingSuccess = createAction<IPageSettingModel>(
  'Page setting has been fetched successfully',
);
export const getPageSettingFailed = createAction('Cannot fetch page setting');

export const updatePageSettingRequest = createAction<IPageSettingModel>(
  'Make request to update page setting',
);
export const updatePageSettingSuccess = createAction<IPageSettingModel>(
  'Page setting has been updated successfully',
);
export const updatePageSettingFailed = createAction(
  'Cannot update page setting',
);
