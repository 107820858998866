import React from 'react';
import { useTranslation } from 'react-i18next';

import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TableContextProvider } from 'src/components/Table/TableContext';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { useCreateReportFiltersConfigWithDefaultOptions } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportPage } from 'src/components/ReportPage';
import {
  ITurnoverDashboardFilterPanelFilters,
  TurnoverDashboardFilterPanel,
} from 'src/pages/TurnoverDashboard/components/TurnoverDashboardFilterPanel';
import { TurnoverDashboardPanel } from './panels/TurnoverDashboardPanel';
import { TurnoverByReasonPanel } from './panels/TurnoverByReasonPanel';

const defaultFilters: ITurnoverDashboardFilterPanelFilters = {
  siteId: undefined,
  staffingProvider: undefined,
  terminationReason: undefined,
  date: undefined,
  week: undefined,
  year: undefined,
  period: undefined,
};

export const TurnoverDashboard: React.FC = () => {
  const { t } = useTranslation();

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof ITurnoverDashboardFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('turnover.site') },
      { id: 'staffingProvider', label: t('turnover.staff_prov') },
      { id: 'terminationReason', label: t('turnover.termination_reason') },
      { id: 'date', label: t('turnover.date') },
      { id: 'week', label: t('turnover.week') },
      { id: 'year', label: t('turnover.year') },
      {
        id: 'period',
        label: t('turnover.period'),
        isPreselected: true,
        isPermanent: true,
      },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    ITurnoverDashboardFilterPanelFilters
  >(defaultFilters);

  const hasUserAccessToDashboardTab = useHasUserAccessToViewTab(
    tabsPaths.TURNOVER_DASHBOARD_TAB,
  );

  const hasUserAccessToByReasonTab = useHasUserAccessToViewTab(
    tabsPaths.TURNOVER_BY_REASON_TAB,
  );

  const tabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.turnover_dashbord'),
          hasUserAccess: hasUserAccessToDashboardTab,
          component: (
            <TurnoverDashboardPanel
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
              date={appliedFilters.date}
              year={appliedFilters.year}
              period={appliedFilters.period}
            />
          ),
        },
        {
          title: t('main_menu.turnover_by_reason_details'),
          hasUserAccess: hasUserAccessToByReasonTab,
          component: (
            <TurnoverByReasonPanel
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
            />
          ),
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      appliedFilters.date,
      appliedFilters.siteId,
      appliedFilters.week,
      appliedFilters.year,
      appliedFilters.period,
      hasUserAccessToByReasonTab,
      hasUserAccessToDashboardTab,
      t,
    ],
  );

  return (
    <>
      <TableContextProvider>
        <TabsNgroup
          fullHeight={false}
          config={tabsConfig}
          toolbarComponent={
            <ReportPage hideNoData fullHeight={false}>
              <ReportPageItemContainer fullHeight={false}>
                <TurnoverDashboardFilterPanel
                  hideSyncBtn
                  filtersOptionsConfig={filtersOptionsConfig}
                  onApplyFilters={(filtersToApply) => {
                    setAppliedFilters(filtersToApply);
                  }}
                  onRemoveAllFilters={() => setAppliedFilters(defaultFilters)}
                />
              </ReportPageItemContainer>
            </ReportPage>
          }
        />
      </TableContextProvider>
    </>
  );
};
