import { JSONSchemaType } from 'ajv';
import { ICreateLogtimeRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { TFunction } from 'react-i18next';

export const createLogtimeScheme: JSONSchemaType<ICreateLogtimeRequest> = {
  type: 'object',
  required: ['badge'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    badge: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    employeeId: {
      default: NaN,
      type: 'number',
      nullable: true,
    },
    timeIn: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      format: 'date-time',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    timezone: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    timeOut: {
      nullable: true,
      type: 'string',
      format: 'date-time',
      compareDatetimeWithTZ: {
        descFieldName: 'timeIn',
        action: 'isAfter',
      },
      errorMessage: {
        compareDatetimeWithTZ: (i18n.t as TFunction)(
          'error.validation.date_greater_than',
          {
            otherDate: 'Log In (initial)',
          },
        ),
      },
    },
    shiftId: {
      default: undefined,
      nullable: true,
      type: 'number',
    },
    departmentId: {
      default: undefined,
      nullable: true,
      type: 'number',
    },
    punchType: {
      default: undefined,
      nullable: true,
      type: 'string',
    },
    comment: {
      default: undefined,
      nullable: true,
      type: 'string',
      maxLength: 256,
    },
  },
};

export const createLogtimesScheme: JSONSchemaType<ICreateLogtimeRequest[]> = {
  type: 'array',
  items: {
    ...createLogtimeScheme,
  },
};
