import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { createCostPlusSettingsRequest } from '../../../../../../modules/actions';
import { createCostPlusSettingScheme } from '../../../../../../modules/schemes';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCheckbox from '../../../../../../components/Formik/FormikCheckbox';
import CostPlusTypesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/CostPlusTypesCombobox';

const defaultCostPlusType = 'department' as const;

const initialValues = {
  // Required properties
  siteId: NaN,
  isActive: false,
  type: defaultCostPlusType,
  // Possible properties
  description: '',
};

interface ICreateCostPlusSettings {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateCostPlusSettings = ({
  isOpen,
  onClose,
}: ICreateCostPlusSettings) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(createCostPlusSettingScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(createCostPlusSettingsRequest(data));
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('cost-plus-settings.create_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              placeholder={t('payboard.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="isActive"
              formik={formik}
              label={t('cost-plus-settings.active')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <CostPlusTypesComboBox
              id="type"
              formik={formik}
              placeholder={t('cost-plus-settings.type')}
            />
          </FormFieldContainer>

          {['description'].map((key) => (
            <FormFieldContainer key={key}>
              <FormikTextField
                key={key}
                variant="outlined"
                required={!['siteId', 'active', 'type'].includes(key)}
                fullWidth
                id={key}
                label={t(`cost-plus-settings.${key}`)}
                name={key}
                formik={formik}
              />
            </FormFieldContainer>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default CreateCostPlusSettings;
