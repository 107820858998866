import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormikCombobox, { IFormikComboboxProps } from '../FormikCombobox';
import { ComboBoxOption } from '../../ComboBox';
import {
  privateRoutes,
  privateRoutesTabs,
} from '../../../config/privateRoutes';

interface IPagesComboboxProps extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  name: string;
}

const PagesCombobox: React.FC<IPagesComboboxProps> = (props) => {
  const { t } = useTranslation();

  const options = (useMemo(() => {
    const mappedData = Object.entries({
      ...privateRoutes,
      ...privateRoutesTabs,
    })
      .filter(([, pageConfig]) => !pageConfig.hideInPagesCombobox)
      .map(([page, { title }]) => ({
        id: page,
        name: title ? t(title) : page,
      }));

    mappedData.sort((a, b) => a.name.localeCompare(b.name));

    return mappedData;
  }, [t]) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default PagesCombobox;
