import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { updatePointCollectionsRequest } from 'src/modules/actions';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { getPointCollectionList } from 'src/modules/selectors/pointCollection';
import { updatePointCollectionsScheme } from 'src/modules/schemes/point-collections';

const initialValues = {
  numberOfPoints: NaN,
  noReturn: false,
  verbalWarning: false,
  writtenWarning: false,
};

interface IPointCollectionUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  ids: IdsArray;
}

export const PointCollectionUpdate = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  ids,
}: IPointCollectionUpdateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allEntities = useSelector(getPointCollectionList);

  const entitiesFullData = allEntities.filter((entity) =>
    ids.includes(entity.id),
  );

  const validate = useValidate(updatePointCollectionsScheme);

  const formik = useFormik({
    initialValues: entitiesFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updatePointCollectionsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('point_collections.bulk_update_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {formik.values.length === entitiesFullData.length
              ? entitiesFullData.map((entity, index) => (
                  <DrawerBodySectionContent key={entity.id}>
                    <DrawerBodySectionTitle>{index + 1}</DrawerBodySectionTitle>

                    <FormFieldContainer>
                      <FormikTextField
                        variant="outlined"
                        fullWidth
                        formik={formik}
                        id={`${index}.numberOfPoints`}
                        label={t('point_collections.num_points')}
                        name={`${index}.numberOfPoints`}
                        autoComplete="numberOfPoints"
                        type="number"
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.noReturn`}
                        formik={formik}
                        label={t('point_collections.no_return')}
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.verbalWarning`}
                        formik={formik}
                        label={t('point_collections.verb_warn')}
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.writtenWarning`}
                        formik={formik}
                        label={t('point_collections.writ_warn')}
                      />
                    </FormFieldContainer>
                  </DrawerBodySectionContent>
                ))
              : null}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
