import React from 'react';
import { IdsArray } from '../../types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getOpenPositionsComboboxList } from '../../selectors/openPosition';
import {
  getOpenPositionComboboxListRequest,
  getOpenPositionListRequest,
} from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import { useSiteRelationInclusion } from '.';

export const openPositionInclusions = [
  {
    relation: 'position',
  },
  {
    relation: 'department',
  },
  {
    relation: 'shift',
  },
  {
    relation: 'site',
  },
  {
    relation: 'openPositionSkills',
    scope: {
      include: [
        {
          relation: 'skill',
        },
      ],
    },
  },
];

export const useOpenPositionsInclusion = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => [
      {
        relation: 'position',
      },
      {
        relation: 'department',
      },
      {
        relation: 'shift',
      },
      siteInclusion,
      {
        relation: 'openPositionSkills',
        scope: {
          include: [
            {
              relation: 'skill',
            },
          ],
        },
      },
    ],
    [siteInclusion],
  );
};

const useOpenPositionsComboboxFilter = () => {
  const issuesInclusion = useOpenPositionsInclusion();

  return React.useMemo(
    () => ({
      filter: {
        include: issuesInclusion,
      },
    }),
    [issuesInclusion],
  );
};

export const openPositionComboboxFilter = {
  filter: {
    include: openPositionInclusions,
  },
};

/**
 * A custom hook to fetch openPositions from store if they exist otherwise to make a request to fetch needed openPositions from
 * the server
 */
export const useFetchOpenPositionsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  return () => {
    dispatcher(
      getOpenPositionListRequest({
        filter: {
          where: {
            id: {
              inq: ids,
            },
          },
          include: openPositionInclusions,
        },
      }),
    );
  };
};

/**
 * A custom hook to fetch openPosition combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchOpenPositionsCombobox = () => {
  const dispatcher = useDispatch();
  const openPositionComboboxFilter = useOpenPositionsComboboxFilter();

  const dispatchGetOpenPositionsComboboxListRequest = React.useCallback(() => {
    dispatcher(getOpenPositionComboboxListRequest(openPositionComboboxFilter));
  }, [openPositionComboboxFilter, dispatcher]);

  React.useEffect(() => {
    dispatchGetOpenPositionsComboboxListRequest();
  }, [dispatchGetOpenPositionsComboboxListRequest]);
  // fetch combobox list from store
  const combobox = useSelector(getOpenPositionsComboboxList, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatchGetOpenPositionsComboboxListRequest();
    }
  };
};

export const useOpenPositionsDefaultFilters = () => {
  const include = useOpenPositionsInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};
