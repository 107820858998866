import React from 'react';
import { FilterPanel, IFilterPanelOption } from 'src/components/FilterPanel';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';

export interface IPricingFilterPanelFilters {
  siteId?: number;
}

interface IPricingFilterPanelProps {
  onApplyFilters: (appliedFilters: IPricingFilterPanelFilters) => void;
  onRemoveAllFilters: () => void;
  filtersOptionsConfig: Array<IFilterPanelOption>;
  defaultFilters?: IPricingFilterPanelFilters;
}

export const PricingFilterPanel = ({
  defaultFilters,
  onApplyFilters,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IPricingFilterPanelProps) => {
  const [filters, setFilters] = React.useState<IPricingFilterPanelFilters>({
    ...defaultFilters,
  });

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<
      IPricingFilterPanelFilters
    >((obj, filterId) => {
      obj[filterId] = filters[filterId];

      return obj;
    }, {});

    onApplyFilters(appliedFiltersData);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'siteId':
        return (
          <FilterPanelSitesCombobox
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
    }

    return null;
  };

  React.useEffect(() => {
    setFilters({
      ...defaultFilters,
    });
  }, [defaultFilters]);

  return (
    <FilterPanel
      renderFilter={renderFilter}
      filterOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
