import { JSONSchemaType } from 'ajv';

import { ICreatePolicyByPageRequest } from '../../types';
import { i18n } from '../../utils/i18n';

export const createPolicyByPageScheme: JSONSchemaType<Array<
  ICreatePolicyByPageRequest
>> = {
  type: 'array',
  items: {
    type: 'object',
    required: ['page', 'role'],
    properties: {
      page: {
        default: '',
        isNotEmpty: true,
        type: 'string',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
          required: i18n.t('error.validation.required'),
          type: i18n.t('error.validation.required'),
        },
      },
      role: {
        default: '',
        isNotEmpty: true,
        type: 'string',
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
          required: i18n.t('error.validation.required'),
          type: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
