import React from 'react';
import { Sheet } from '@mui/joy';

interface IDrawerFooterProps {
  children: React.ReactNode;
}

export const DrawerFooter = ({ children }: IDrawerFooterProps) => {
  return (
    <Sheet
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray.200',
        p: 2,
        bgcolor: 'base.white',
      }}
    >
      {children}
    </Sheet>
  );
};
