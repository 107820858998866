import * as React from 'react';
import { AnyObject } from 'src/modules/types';
import { ErrorMode } from './types';
import { get } from 'lodash';
import Multiselect, { IMultiselect, MultiselectOption } from '../Multiselect';

export type IFormikMultipleSelectProps = IMultiselect & {
  id: string;
  formik: AnyObject;
  title?: string;
  errorMode?: ErrorMode;
  disabled?: boolean;
};

export default function FormikMultipleSelect({
  id,
  formik,
  errorMode = 'onFormSubmit',
  title,
  options,
  ...restProps
}: IFormikMultipleSelectProps) {
  const value = React.useMemo(() => get(formik.values, id, []), [
    formik.values,
    id,
  ]);

  React.useEffect(() => {
    formik.setFieldValue(id, value ?? []);
    formik.validateField(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(formik.values, id)]);

  const handleChange = (values: Array<MultiselectOption>) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(
      id,
      values.map((value) => value.id),
    );
  };

  const error = React.useMemo(() => get(formik.errors, id), [
    formik.errors,
    id,
  ]);
  const touched = React.useMemo(() => get(formik.touched, id), [
    formik.touched,
    id,
  ]);
  const showError = React.useMemo<boolean>(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  const values = React.useMemo(() => {
    return get(formik.values, id);
  }, [formik.values, id]);

  return (
    <Multiselect
      id={id}
      options={options}
      label={title}
      onChange={handleChange}
      values={values}
      helperText={showError && error}
      {...restProps}
    />
  );
}
