import { ISagaActionBind } from '../../types';
import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyUphBySiteAndShiftListRequest,
    saga: createListSaga<ICardinalHealthWeeklyReportModel>({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndShift.list,
      actionSuccess:
        actions.getCardinalHealthWeeklyUphBySiteAndShiftListSuccess,
      actionFailed: actions.getCardinalHealthWeeklyUphBySiteAndShiftListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyUphBySiteAndShiftCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndShift.count,
      actionSuccess:
        actions.getCardinalHealthWeeklyUphBySiteAndShiftCountSuccess,
      actionFailed: actions.getCardinalHealthWeeklyUphBySiteAndShiftCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyUphBySiteRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndShift.export,
      actionSuccess: actions.exportCardinalHealthWeeklyUphBySiteSuccess,
      actionFailed: actions.exportCardinalHealthWeeklyUphBySiteFailed,
    }),
  },
];
