import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/joy/Typography';
import { extendSessionRequest, logoutRequest } from '../../modules/actions';
import ModalDialog from '../ModalDialog';
import { getExpiresAt } from '../../modules/selectors/auth';
import { useLocation } from 'react-router-dom';
import Countdown, {
  CountdownTimeDelta,
  CountdownRenderProps,
} from 'react-countdown';
import {
  EXPIRES_AT_FORMAT,
  parse,
  composeDate,
  isSameSecond,
  getNow,
} from '../../modules/utils/dateWrapper';
import { Sheet } from '@mui/joy';

const SHOW_BEFORE_SEC = 10;

export const SessionCountdown = () => {
  const { t } = useTranslation();
  const [shownModal, setShownModal] = useState<boolean>(false);
  const [countDownWidth, setCountdownWidth] = useState(0);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [expired, setExpired] = useState<Date>();

  const logoutRequestDispatcher = useDispatch();
  const extendSessionDispatcher = useDispatch();
  const expiresAt = useSelector(getExpiresAt, shallowEqual);
  const location = useLocation();

  const countDownRef = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    extendSessionDispatcher(extendSessionRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!expiresAt) {
      return;
    }

    const expAt = composeDate(expiresAt, parse(EXPIRES_AT_FORMAT));

    setExpired(expAt);
    setCanceled(false);

    if (composeDate(expAt, isSameSecond(getNow()))) {
      logoutRequestDispatcher(logoutRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresAt]);

  const handleClose = () => {
    setShownModal(false);
    setCanceled(true);
  };

  const handleOk = () => {
    extendSessionDispatcher(extendSessionRequest());
    handleClose();
  };

  const onTick = (delta: CountdownTimeDelta) => {
    const { total } = delta;
    setShownModal(total <= SHOW_BEFORE_SEC * 1000 && !canceled);
  };

  const renderer = (props: CountdownRenderProps) => {
    const {
      hours: intHrs,
      formatted: { minutes, seconds, hours },
    } = props;
    return (
      <Sheet
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: 3,
          backgroundColor: 'inherit !important',
        }}
      >
        <Typography
          noWrap
          component="span"
          level="text_xs"
          fontWeight="regular"
          textColor="colors.text.text_primary.main"
        >
          {t('auth.session_expires_in')}:
        </Typography>
        <Typography
          ref={countDownRef}
          component="div"
          level="text_xs"
          fontWeight="semi_bold"
          textColor="colors.text.text_primary.main"
          sx={{
            ml: '4px',
            width: countDownWidth ? `${countDownWidth}px` : 'auto',
          }}
        >
          {(intHrs > 0 ? `${hours}:` : '') + `${minutes}:${seconds}`}
        </Typography>
      </Sheet>
    );
  };

  const onComplete = () => logoutRequestDispatcher(logoutRequest());

  React.useEffect(() => {
    setTimeout(() => {
      if (countDownRef.current) {
        setCountdownWidth(countDownRef.current.offsetWidth);
      }
    }, 400);
  }, []);

  return (
    <>
      <ModalDialog
        open={shownModal}
        onClose={handleClose}
        onOk={handleOk}
        title={t('auth.session_expires_soon')}
      >
        <Typography>{t('auth.session_wanna_extend')}</Typography>
      </ModalDialog>
      {expired && (
        <Countdown
          date={expired}
          renderer={renderer}
          onComplete={onComplete}
          onTick={onTick}
        />
      )}
    </>
  );
};
