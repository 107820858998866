import React from 'react';
import { Sheet, Theme, Typography, useTheme } from '@mui/joy';
import { TickSvg } from 'src/components/svgIcons';

export type StepStatus = 'incomplete' | 'current' | 'complete' | 'error';
export type StepSize = 'sm' | 'md' | 'lg';

export const STEP_OUTLINE_OFFSET = 4;

export const stepContainerClassName = 'stepContainer';

export const stepDefaultSize = 'sm';

export const stepSizeToSxDataMapper: {
  [key in StepSize]: {
    internalCircleSize: any;
    externalCircleSize: any;
  };
} = {
  sm: {
    internalCircleSize: {
      width: 8,
      height: 8,
    },
    externalCircleSize: {
      width: 24,
      height: 24,
    },
  },
  md: {
    internalCircleSize: {
      width: 10,
      height: 10,
    },
    externalCircleSize: {
      width: 32,
      height: 32,
    },
  },
  lg: {
    internalCircleSize: {
      width: 12,
      height: 12,
    },
    externalCircleSize: {
      width: 40,
      height: 40,
    },
  },
};

const stepSizeToLabelTopOffsetMapper: {
  [key in StepSize]: number;
} = {
  sm: 6,
  md: 10,
  lg: 14,
};
export const stepSizeToLabelBottomMargin: {
  [key in StepSize]: number;
} = {
  sm: 12,
  md: 10,
  lg: 8,
};

const getSxDataByStatus = (status: StepStatus, theme: Theme) => {
  if (status === 'incomplete') {
    return {
      internalCircleStatus: {
        bgcolor: 'colors.foreground.fg_disabled_subtle',
      },
      externalCircleStatus: {
        borderColor: 'colors.border.border_disabled_subtle',
      },
    };
  }
  if (status === 'current') {
    return {
      internalCircleStatus: {
        bgcolor: 'colors.foreground.fg_white',
      },
      externalCircleStatus: {
        borderColor: 'colors.utility.brand.utility_brand_700',
        bgcolor: 'colors.utility.brand.utility_brand_600',
        outlineStyle: 'solid',
        outlineWidth: STEP_OUTLINE_OFFSET,
        outlineColor: theme.palette.colors.utility.brand.utility_brand_100,
        // m: '4px',
      },
    };
  }
  if (status === 'complete') {
    return {
      internalCircleStatus: {
        bgcolor: 'transparent',
      },
      externalCircleStatus: {
        borderColor: 'colors.utility.brand.utility_brand_700',
        bgcolor: 'colors.utility.brand.utility_brand_600',
      },
    };
  }
  if (status === 'error') {
    return {
      internalCircleStatus: {
        bgcolor: 'colors.foreground.fg_white',
      },
      externalCircleStatus: {
        borderColor: 'colors.foreground.fg_error_primary',
        bgcolor: 'colors.utility.error.utility_error_300',
        outlineStyle: 'solid',
        outlineWidth: STEP_OUTLINE_OFFSET,
        outlineColor: theme.palette.colors.utility.error.utility_error_100,
      },
    };
  }

  return { internalCircleStatus: {}, externalCircleStatus: {} };
};

export interface IStepProps {
  id: any;
  onClick: (stepId: any) => void;
  status?: StepStatus;
  size?: StepSize;
  title?: string;
  subtitle?: string;
}

export const Step = ({
  id,
  onClick,
  title,
  subtitle,
  status = 'incomplete',
  size = stepDefaultSize,
}: IStepProps) => {
  const theme = useTheme();

  const { internalCircleSize, externalCircleSize } = stepSizeToSxDataMapper[
    size
  ];

  const { internalCircleStatus, externalCircleStatus } = getSxDataByStatus(
    status,
    theme,
  );

  return (
    <Sheet
      className={stepContainerClassName}
      onClick={() => onClick(id)}
      sx={{
        width: '100%',
        display: 'flex',
        position: 'relative',
        cursor: 'pointer',
        bgcolor: 'inherit',
        mb: title && subtitle ? `${stepSizeToLabelBottomMargin[size]}px` : 0,
      }}
    >
      <Sheet
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Sheet
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            borderWidth: 2,
            borderStyle: 'solid',
            m: `${STEP_OUTLINE_OFFSET}px`,
            ...externalCircleSize,
            ...externalCircleStatus,
          }}
        >
          <Sheet
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              ...internalCircleSize,
              ...internalCircleStatus,
            }}
          >
            {status === 'complete' && <TickSvg />}
          </Sheet>
        </Sheet>
      </Sheet>

      <Sheet
        sx={{
          // width: '100%',
          position: 'absolute',
          top: `${stepSizeToLabelTopOffsetMapper[size]}px`,
          left: `${externalCircleSize.width + 12}px`,
          bgcolor: 'inherit',
        }}
      >
        <Typography
          level="text_sm"
          fontWeight="bold"
          textColor={
            status === 'current'
              ? 'colors.utility.brand.utility_brand_700'
              : 'colors.text.text_secondary.main'
          }
        >
          {title}
        </Typography>
        <Typography
          level="text_sm"
          fontWeight="regular"
          textColor={
            status === 'current'
              ? 'colors.utility.brand.utility_brand_800'
              : 'colors.text.text_secondary.main'
          }
        >
          {subtitle}
        </Typography>
      </Sheet>
    </Sheet>
  );
};
