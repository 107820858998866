import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { IFilterData, IWhere } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { createPointCollectionScheme } from 'src/modules/schemes/point-collections';
import { createPointCollectionRequest } from 'src/modules/actions';

const initialValues = {
  numberOfPoints: NaN,
  noReturn: false,
  verbalWarning: false,
  writtenWarning: false,
};

interface IPointCollectionCreateProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const PointCollectionCreate = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: IPointCollectionCreateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createPointCollectionScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createPointCollectionRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('point_collections.create_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth={true}
                id="numberOfPoints"
                label={t('point_collections.num_points')}
                name="numberOfPoints"
                autoComplete="numberOfPoints"
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="noReturn"
                formik={formik}
                label={t('point_collections.no_return')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="verbalWarning"
                formik={formik}
                label={t('point_collections.verb_warn')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="writtenWarning"
                formik={formik}
                label={t('point_collections.writ_warn')}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
