import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { Pricing } from './panels/Pricing';
import { Baseline } from './panels/Baseline';
import { IncentivePriceList } from './panels/IncentivePrice/IncentivePriceList';

export const PricingPage = () => {
  const { t } = useTranslation();

  const hasUserAccessToPricingTab = useHasUserAccessToViewTab(
    tabsPaths.PRICING_TAB,
  );

  const hasUserAccessToPricingBaselineTab = useHasUserAccessToViewTab(
    tabsPaths.PRICING_BASELINE_TAB,
  );

  const hasUserAccessToPricingIncentiveTab = useHasUserAccessToViewTab(
    tabsPaths.PRICING_INCENTIVE_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('pricing.pricing'),
          hasUserAccess: hasUserAccessToPricingTab,
          component: <Pricing />,
        },
        {
          title: t('pricing.baseline'),
          hasUserAccess: hasUserAccessToPricingBaselineTab,
          component: <Baseline />,
        },
        {
          title: t('pricing.incentive'),
          hasUserAccess: hasUserAccessToPricingIncentiveTab,
          component: <IncentivePriceList />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      t,
      hasUserAccessToPricingTab,
      hasUserAccessToPricingBaselineTab,
      hasUserAccessToPricingIncentiveTab,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
