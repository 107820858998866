import React from 'react';
import { Box, SheetProps } from '@mui/joy';

interface ITrProps extends SheetProps {
  children: React.ReactNode;
}

export const Tr = ({ children, onClick, ...rest }: ITrProps) => {
  return (
    <Box onClick={onClick} component="tr" {...rest}>
      {children}
    </Box>
  );
};
