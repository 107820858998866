import React from 'react';
import { useTranslation } from 'react-i18next';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { AnyObject } from 'src/modules/types';
import { useSitesOptions } from 'src/modules/utils';
import { FilterModal } from '../components/FilterModal';

export interface IFilterBySitesComponent {
  entityId: number | null;
  onSelect: (entity: ComboBoxOption) => void;
}
export const FilterBySitesComponent: React.FC<IFilterBySitesComponent> = ({
  entityId,
  onSelect,
}) => {
  const { t } = useTranslation();
  const sites = useSitesOptions();

  const [
    selectedOption,
    setSelectedOption,
  ] = React.useState<ComboBoxOption | null>(null);
  const onChange = (
    e: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    setSelectedOption(value);
  };

  const onSubmit = () => {
    if (!selectedOption) {
      return;
    }
    onSelect(selectedOption);
  };

  const onModalClose = () => {
    !entityId && setSelectedOption(null);
  };

  React.useEffect(() => {
    setSelectedOption(sites.find((site) => site.id === entityId) ?? null);
  }, [sites, entityId]);

  return (
    <FilterModal onModalClose={onModalClose} onFilterSubmit={onSubmit}>
      <ComboBox
        value={selectedOption}
        options={sites}
        placeholder={`${t('common.site')} = *`}
        onChange={onChange}
      />
    </FilterModal>
  );
};
