import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  IFilterData,
  IPunchIntersectionRequest,
  IPunchIntersectionResponse,
  IUpdateLogtime,
  IWhere,
  IdsArray,
} from 'src/modules/types';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { updateLogtimesRequest } from 'src/modules/actions';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import { EmployeesComboBox } from 'src/components/Formik/comboboxes-with-entities/EmployeesComboBox';
import { createLogtimesScheme } from 'src/modules/schemes/logtimes';
import { useLogtimeCreateFormTourConfig } from 'src/config/tours/logtime';
import {
  getLogtimeApprovedList,
  getLogtimeUnapprovedList,
} from 'src/modules/selectors/logtime';
import { get, isEmpty, reduce } from 'lodash';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';
import { getDepartmentsComboboxList } from 'src/modules/selectors/department';
import {
  DATETIME_FORMAT_TO_SHOW,
  DATE_FORMAT,
  composeDate,
  format,
  parseISO,
} from 'src/modules/utils/dateWrapper';
import { ActionConfirmation } from 'src/components/ActionConfirmation';
import { PunchTypeComboBox } from '../PunchTypeComboBox';
import FormikTextarea from 'src/components/Formik/FormikTextarea';

interface ITimePunchesUpdatePunchFormProps {
  isOpen: boolean;
  onClose: () => void;
  punchesIds: IdsArray;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const TimePunchesUpdatePunchForm = ({
  isOpen,
  onClose,
  punchesIds,
  filterList,
  filterCount,
}: ITimePunchesUpdatePunchFormProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const shifts = useSelector(getShiftsComboboxList);
  const departments = useSelector(getDepartmentsComboboxList);

  const [valuesToConfirm, setValuesToConfirm] = React.useState<
    IUpdateLogtime[]
  >();
  const [sendDataConfirmed, setSendDataConfirmed] = React.useState<
    IUpdateLogtime[]
  >();
  const [intersection, setIntersection] = React.useState<
    IPunchIntersectionResponse
  >();

  const punchesIdsNumber = React.useMemo(() => punchesIds.map(Number), [
    punchesIds,
  ]);

  const allApprovedPunches = useSelector(getLogtimeApprovedList);

  const allUnapprovedPunches = useSelector(getLogtimeUnapprovedList);

  const allPunches = React.useMemo(
    () => [...allApprovedPunches, ...allUnapprovedPunches],
    [allApprovedPunches, allUnapprovedPunches],
  );

  const punches = React.useMemo(
    () => allPunches.filter((punch) => punchesIdsNumber.includes(punch.id)),
    [allPunches, punchesIdsNumber],
  );

  const [siteIdByPunchIndexObj, setSiteIdByPunchIndexObj] = React.useState(
    punches.reduce((acc, punch, i) => {
      acc[i] = punch.employee?.site?.id;

      return acc;
    }, {}),
  );

  const validate = useValidate(createLogtimesScheme);

  const formik = useFormik({
    initialValues: punches,
    enableReinitialize: true,
    validate,
    onSubmit: async (values) => {
      const sendData = values.map(
        ({
          id,
          badge,
          timeIn,
          timeOut,
          timezone,
          shiftId,
          departmentId,
          punchType,
          comment,
        }) => ({
          id,
          badge,
          timeIn,
          timeOut,
          timezone,
          shiftId,
          departmentId,
          comment,
          punchType: punchType === 'regular' ? null : punchType || null,
        }),
      );

      const checkIntersectionValues: IPunchIntersectionRequest[] = values.reduce<
        IPunchIntersectionRequest[]
      >((acc, cur) => {
        if (cur.timeOut) {
          acc.push({
            logtimeId: cur.id,
            employeeId: cur.employee?.id,
            departmentId: cur.departmentId,
            shiftId: cur.shiftId,
            timeIn: cur.timeIn,
            timeOut: cur.timeOut,
          });
        }

        return acc;
      }, []);

      const response = await Api.Logtime.checkIntersection(
        checkIntersectionValues,
      );

      if (isEmpty(response)) {
        dispatch(
          updateLogtimesRequest({
            data: sendData,
            filters: { list: filterList, count: filterCount },
          }),
        );

        onClose();
      } else {
        setValuesToConfirm(values);
        setSendDataConfirmed(sendData);
        setIntersection(response);
      }
    },
  });

  const logtimeCreateConfigTour = useLogtimeCreateFormTourConfig();

  const actionConfirmationMessageSet = reduce(
    valuesToConfirm as any,
    (acc, cur) => {
      const dateIn = composeDate(
        parseISO(cur.timeIn as string),
        format(DATE_FORMAT),
      );

      const dateOut = composeDate(
        parseISO(cur.timeOut as string),
        format(DATE_FORMAT),
      );

      let intersectionItem;

      intersectionItem = get(intersection, [
        `${cur.employeeId || cur.employee.id}`,
        dateIn,
      ]);

      if (!intersectionItem) {
        intersectionItem = get(intersection, [
          `${cur.employeeId || cur.employee.id}`,
          dateOut,
        ]);
      }

      if (intersectionItem) {
        const timeIn = composeDate(
          parseISO(intersectionItem.timeIn as string),
          format(DATETIME_FORMAT_TO_SHOW),
        );
        const timeOut = composeDate(
          parseISO(intersectionItem.timeOut as string),
          format(DATETIME_FORMAT_TO_SHOW),
        );
        acc.add(
          t('logtimes.punch_intersection_employee', {
            employee: `${intersectionItem.firstName} ${intersectionItem.lastName}`,
            hours: intersectionItem.timeTotal,
            timeIn,
            timeOut,
          }),
        );
      }

      return acc;
    },
    new Set() as Set<string>,
  );

  const actionConfirmationMessage = [
    ...actionConfirmationMessageSet,
  ].map((item, index) => <li key={index}>{item}</li>);

  const filterEntitiesBySiteId = (entities: Array<any>, siteId: any) => {
    return entities.filter((entity) => entity.siteId === siteId);
  };

  const onConfirmSubmit = () => {
    setIntersection(undefined);
    sendDataConfirmed &&
      dispatch(
        updateLogtimesRequest({
          data: sendDataConfirmed,
          filters: { list: filterList, count: filterCount },
        }),
      );
    setValuesToConfirm(undefined);
    setSendDataConfirmed(undefined);

    onClose();
  };

  const onReset = () => {
    formik.resetForm();
  };

  React.useEffect(() => {
    setSiteIdByPunchIndexObj(
      punches.reduce((acc, punch, i) => {
        acc[i] = punch.employee?.site?.id;

        return acc;
      }, {}),
    );
  }, [punches]);

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('time_keeping.update_punch')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {punches.map((_, i) => (
              <DrawerBodySectionContent key={i}>
                <DrawerBodySectionTitle>{`${punches[i].employee?.firstName} ${punches[i].employee?.firstName}`}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <EmployeesComboBox
                    required
                    onlyActive
                    id={generateFullIdInMultiEntitiesForm('badge', i)}
                    propertyAsID="badge"
                    formik={formik}
                    label={t('logtimes.employee')}
                    placeholder={t('common.select')}
                    onChange={(_, value) => {
                      formik.setFieldValue(
                        `${i}.employeeId`,
                        value?.entity?.id,
                      );
                      setSiteIdByPunchIndexObj((prev) => ({
                        ...prev,
                        [i]: value?.entity?.siteId,
                      }));
                    }}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikDatetimepicker
                    required
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('timeIn', i)}
                    formik={formik}
                    label={t('logtimes.login_datetime')}
                    data-tour={logtimeCreateConfigTour.tour.login.target}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikDatetimepicker
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('timeOut', i)}
                    formik={formik}
                    label={t('logtimes.logout_datetime')}
                    data-tour={logtimeCreateConfigTour.tour.logout.target}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    setToUndefinedOnFieldClear
                    id={generateFullIdInMultiEntitiesForm('departmentId', i)}
                    formik={formik}
                    label={t('logtimes.department')}
                    placeholder={t('common.select')}
                    options={
                      siteIdByPunchIndexObj[i]
                        ? filterEntitiesBySiteId(
                            departments,
                            siteIdByPunchIndexObj[i],
                          )
                        : []
                    }
                    data-tour={logtimeCreateConfigTour.tour.department.target}
                    data-testid="department"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    setToUndefinedOnFieldClear
                    id={generateFullIdInMultiEntitiesForm('shiftId', i)}
                    formik={formik}
                    label={t('logtimes.shift')}
                    placeholder={t('common.select')}
                    options={
                      siteIdByPunchIndexObj[i]
                        ? filterEntitiesBySiteId(
                            shifts,
                            siteIdByPunchIndexObj[i],
                          )
                        : []
                    }
                    data-tour={logtimeCreateConfigTour.tour.shift.target}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <PunchTypeComboBox
                    formik={formik}
                    id={generateFullIdInMultiEntitiesForm('punchType', i)}
                    label={t('logtimes.punch_type')}
                    placeholder={t('common.select')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextarea
                    id={generateFullIdInMultiEntitiesForm('comment', i)}
                    name={generateFullIdInMultiEntitiesForm('comment', i)}
                    label={t('logtimes.comment')}
                    formik={formik}
                    minRows={2}
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>

      <ActionConfirmation
        open={Boolean(intersection)}
        onOk={onConfirmSubmit}
        onCancel={() => setIntersection(undefined)}
        okBtnText={t('common.yes')}
        noBtnText={t('common.no')}
        title={
          formik.values.length > 1
            ? t('time_keeping.punches_intersection_warning')
            : t('time_keeping.punch_intersection_warning')
        }
      >
        {actionConfirmationMessage}
      </ActionConfirmation>
    </>
  );
};
