import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { Box } from '@mui/joy';

interface IFilesPreviewProps {
  fileUrl: string | null;
  onClose: () => void;
}

export const FilePreview = ({ fileUrl, onClose }: IFilesPreviewProps) => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={Boolean(fileUrl)}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          p: 3,
          width: '100%',
          maxWidth: 800,
          borderRadius: 'md',
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {fileUrl && <Box component="img" src={fileUrl} />}
        </Box>
      </Sheet>
    </Modal>
  );
};
