import React from 'react';
import { supervisorRoles } from 'src/config';
import { useTranslation } from 'react-i18next';
import { useFetchUsersCombobox } from './users';
import { useFetchShiftsCombobox } from './shifts';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFetchPositionsCombobox } from './positions';
import { useEmployeesDefaultFilters } from './employees';
import { ComboBoxOption } from 'src/components/ComboBox';
import { useFetchActiveDepartmentsCombobox } from './departments';
import { getUsersComboboxList } from 'src/modules/selectors/user';
import { getShiftOptionsByGloballySites } from 'src/modules/selectors/shift';
import { useFetchStaffingProvidersCombobox } from './staffingProvider';
import { getPositionsComboboxList } from 'src/modules/selectors/position';
import { getDepartmentOptionsByGloballySites } from 'src/modules/selectors/department';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { getTerminationReasonsComboboxList } from 'src/modules/selectors/terminationReason';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import Api from '../Api';
import { IEmployeeModel, IFilter } from 'src/modules/types';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { Tooltip } from '@mui/material';
import { setIsDataRequested } from 'src/modules/actions';
import { ClientKey } from '../../constants';
import { IHeadCellWithOrderConfig } from '../../types/table';
import { useGenerateHeadCellsData } from './table';

export const useExceptionsFromEmployeesFilters = (
  clientKey?: ClientKey,
): ITableFilter[] => {
  const { t } = useTranslation();
  const defaultFilter = useEmployeesDefaultFilters();

  const fetchPositionsCombobox = useFetchPositionsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchSupervisorsCombobox = useFetchUsersCombobox({
    role: {
      inq: supervisorRoles,
    },
  });
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  // fetch employees positions list from store
  const positions = useSelector(getPositionsComboboxList, shallowEqual);
  // fetch staffing providers list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  // fetch supervisors providers list from store
  const supervisors = useSelector(getUsersComboboxList, shallowEqual);
  // fetch employees shifts list from store
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  // fetch employees departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );
  // fetch termination Reasons list from store
  const terminationReasons = useSelector(getTerminationReasonsComboboxList);

  React.useEffect(() => {
    fetchPositionsCombobox();
    fetchSupervisorsCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  return [
    {
      name: 'site.id',
      label: t('employees.site'),
      operator: 'eq',
      type: 'comboboxSites',
      clientKey,
    },
    {
      name: 'employeeId',
      label: t('employees.emp_id'),
      operator: 'like',
    },
    {
      name: 'position.id',
      label: t('employees.position'),
      operator: 'eq',
      type: 'combobox',
      options: positions as ComboBoxOption[],
    },
    {
      name: 'staffing.id',
      label: t('employees.staffing_provider'),
      operator: 'eq',
      type: 'combobox',
      options: staffingProviders as ComboBoxOption[],
    },
    {
      name: 'supervisor.id',
      label: t('employees.supervisor'),
      operator: 'eq',
      type: 'combobox',
      options: supervisors as ComboBoxOption[],
    },
    {
      name: 'firstName',
      label: t('employees.first_name'),
      operator: 'like',
    },
    {
      name: 'lastName',
      label: t('employees.last_name'),
      operator: 'like',
    },
    {
      name: 'payRate',
      label: t('employees.pay_rate'),
      operator: 'like',
    },
    {
      name: 'dateHired',
      label: t('employees.date_hired'),
      operator: 'gte',
      type: 'date',
    },
    {
      name: 'badge',
      label: t('employees.badge'),
      operator: 'like',
    },
    {
      name: 'separationdate',
      label: t('employees.separation_date'),
      operator: 'gte',
      type: 'date',
    },
    {
      name: 'defaultShift.id',
      label: t('employees.default_shift'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'defaultDepartment.id',
      label: t('employees.default_department'),
      operator: 'eq',
      type: 'combobox',
      options: departments as ComboBoxOption[],
    },
    {
      name: 'active',
      label: t('employees.active'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'address',
      label: t('employees.address'),
      operator: 'like',
    },
    {
      name: 'phonenumber',
      label: t('employees.phone'),
      operator: 'like',
    },
    {
      name: 'dateentered',
      label: t('employees.date_entered'),
      operator: 'gte',
      type: 'date',
    },
    {
      name: 'last4SSN',
      label: t('employees.4ssn'),
      operator: 'like',
    },
    {
      name: 'wmsLogin',
      label: t('employees.wms_login'),
      operator: 'like',
    },
    {
      name: 'terminationReason.id',
      label: t('employees.termination_reason'),
      operator: 'eq',
      type: 'combobox',
      options: terminationReasons as ComboBoxOption[],
    },
    {
      name: 'canRehire',
      label: t('employees.can_rehire'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'wotc',
      label: t('employees.wotc'),
      operator: 'eq',
      type: 'checkbox',
    },
    {
      name: 'emergencyContact',
      label: t('employees.emergency_contact'),
      operator: 'like',
    },
    {
      name: 'comments',
      label: t('employees.comments'),
      operator: 'like',
    },
  ];
};

export const useExceptionsFromEmployeesHeadCells = () => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'employeeId',
        orderConfig: { orderBy: 'employeeId' },
        label: t('employees.emp_id'),
      },
      {
        id: 'site.name',
        orderConfig: { orderBy: 'site.name' },
        label: t('employees.site'),
      },
      {
        id: 'position.name',
        orderConfig: { orderBy: 'position.name' },
        label: t('employees.position'),
      },
      {
        id: 'staffing.staffingProvider',
        orderConfig: { orderBy: 'staffing.staffingProvider' },
        label: t('employees.staffing_provider'),
      },
      {
        id: 'supervisor.firstName',
        orderConfig: { orderBy: 'supervisor.firstName' },
        label: t('employees.supervisor_fname'),
      },
      {
        id: 'supervisor.lastName',
        orderConfig: { orderBy: 'supervisor.lastName' },
        label: t('employees.supervisor_lname'),
      },
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('employees.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('employees.last_name'),
      },
      {
        id: 'payRate',
        orderConfig: { orderBy: 'payRate' },
        label: t('employees.pay_rate'),
      },
      {
        id: 'dateHired',
        orderConfig: { orderBy: 'dateHired' },
        label: t('employees.date_hired'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('employees.badge'),
      },
      {
        id: 'separationdate',
        orderConfig: { orderBy: 'separationdate' },
        label: t('employees.separation_date'),
      },
      {
        id: 'defaultShift.name',
        orderConfig: { orderBy: 'defaultShift.name' },
        label: t('employees.default_shift'),
      },
      {
        id: 'defaultDepartment.name',
        orderConfig: { orderBy: 'defaultDepartment.name' },
        label: t('employees.default_department'),
      },
      {
        id: 'active',
        orderConfig: { orderBy: 'active' },
        label: t('employees.active'),
      },
      {
        id: 'address',
        orderConfig: { orderBy: 'address' },
        label: t('employees.address'),
      },
      {
        id: 'phonenumber',
        orderConfig: { orderBy: 'phonenumber' },
        label: t('employees.phone'),
      },
      {
        id: 'dateentered',
        orderConfig: { orderBy: 'dateentered' },
        label: t('employees.date_entered'),
      },
      {
        id: 'last4SSN',
        orderConfig: { orderBy: 'last4SSN' },
        label: t('employees.4ssn'),
      },
      {
        id: 'wmsLogin',
        orderConfig: { orderBy: 'wmsLogin' },
        label: t('employees.wms_login'),
      },
      {
        id: 'terminationReason.name',
        orderConfig: { orderBy: 'terminationReason.name' },
        label: t('employees.termination_reason'),
      },
      {
        id: 'canRehire',
        orderConfig: { orderBy: 'canRehire' },
        label: t('employees.can_rehire'),
      },
      {
        id: 'wotc',
        orderConfig: { orderBy: 'wotc' },
        label: t('employees.wotc'),
      },
      {
        id: 'emergencyContact',
        orderConfig: { orderBy: 'emergencyContact' },
        label: t('employees.emergency_contact'),
      },
      {
        id: 'comments',
        orderConfig: { orderBy: 'comments' },
        label: t('employees.comments'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return { headCells, headCellsOrderDetails };
};

export const useFetchExceptionsFromEmployeesData = (client: string) => {
  const dispatch = useDispatch();

  const [list, setList] = React.useState<ReturnType<typeof parseList>>([]);
  const [count, setCount] = React.useState(0);

  const SHORT_COMMENT_LENGTH = 50;

  const parseList = (list: IEmployeeModel[]) =>
    list.map((item) => {
      return {
        ...item,
        wotc: item.wotc ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        active: item.active ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        canRehire: item.canRehire ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        comments:
          item.comments && item.comments?.length > SHORT_COMMENT_LENGTH ? (
            <Tooltip title={item.comments}>
              <span>
                {item.comments.substring(0, SHORT_COMMENT_LENGTH) + '...'}
              </span>
            </Tooltip>
          ) : (
            item.comments
          ),
      };
    });

  const fetchExceptionsFromEmployeesData = React.useCallback(
    async (filters?: IFilter) => {
      dispatch(setIsDataRequested(true));

      const { data, count } = await Api.Report.data<IEmployeeModel[]>(
        `/${client}/exceptions/production/from-employee`,
        filters,
      );

      dispatch(setIsDataRequested(false));
      setList(parseList(data));
      setCount(count);
    },
    [client, dispatch],
  );

  return { list, count, fetchExceptionsFromEmployeesData };
};
