import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { createOtherCostsScheme } from '../../../../../../modules/schemes';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { IFilterData, IWhere } from '../../../../../../modules/types';
import { createOtherCostsRequest } from '../../../../../../modules/actions';
import { map } from 'lodash';
import FormikNumberField from '../../../../../../components/Formik/FormikNumberField';
import FormikCheckbox from '../../../../../../components/Formik/FormikCheckbox';

const initialValues = {
  // Required properties
  siteId: NaN,
  yearNumber: NaN,
  weekNumber: NaN,
  isRepeatable: false,
  value: NaN,
  description: '',
};

export const formFieldsTranslationDictionary = {
  'other-costs.yearNumber': 'productions_uph_reports.year',
  'other-costs.weekNumber': 'productions_uph_reports.week',
  'other-costs.value': 'other-costs.value',
  'other-costs.description': 'other-costs.description',
};

interface ICreateOtherCostsProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateOtherCosts = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateOtherCostsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(createOtherCostsScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createOtherCostsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('other-costs.create_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              formik={formik}
              placeholder={t('payboard.site')}
              required
            />
          </FormFieldContainer>

          {map(['yearNumber', 'weekNumber'], (key) => (
            <FormFieldContainer key={key}>
              <FormikNumberField
                variant="outlined"
                required={['yearNumber', 'weekNumber'].includes(key)}
                fullWidth
                id={key}
                label={t(formFieldsTranslationDictionary[`other-costs.${key}`])}
                name={key}
                formik={formik}
              />
            </FormFieldContainer>
          ))}

          <FormFieldContainer>
            <FormikCheckbox
              required
              id="isRepeatable"
              label={t('productions_uph_reports.isRepeatable')}
              formik={formik}
            />
          </FormFieldContainer>

          {map(['value', 'description'], (key) => (
            <FormFieldContainer key={key}>
              <FormikTextField
                variant="outlined"
                required={['value', 'description'].includes(key)}
                fullWidth
                id={key}
                label={t(formFieldsTranslationDictionary[`other-costs.${key}`])}
                name={key}
                formik={formik}
              />
            </FormFieldContainer>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default CreateOtherCosts;
