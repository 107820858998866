import React from 'react';
import {
  Button,
  DialogActions as JoyDialogActions,
  DialogContent as JoyDialogContent,
  Divider,
  Modal,
  ModalDialog as JoyModalDialog,
  DialogTitle as JoyDialogTitle,
  ModalClose,
} from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

export interface IDialogModalProps {
  title: string;
  open?: boolean;
  children: any;
  okDisabled?: boolean;
  onOk?: () => void;
  onClose?: () => void;
  className?: string;
  sxForDialog?: SxProps;
  sxForDialogContent?: SxProps;
  okLabel?: string;
  maxWidth?: string;
}

export default function ModalDialog({
  open = true,
  onClose,
  onOk,
  children,
  title,
  okDisabled,
  sxForDialog,
  sxForDialogContent,
  okLabel,
  maxWidth = 'unset',
}: IDialogModalProps) {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={() => onClose && onClose()}
      sx={sxForDialog}
    >
      <JoyModalDialog
        maxWidth={maxWidth}
        color="warning"
        layout="center"
        size="lg"
        variant="plain"
      >
        <ModalClose />
        <JoyDialogTitle id="responsive-dialog-title">{title}</JoyDialogTitle>
        <Divider />
        <JoyDialogContent sx={sxForDialogContent}>{children}</JoyDialogContent>
        {onClose && onOk && (
          <JoyDialogActions>
            {onOk && (
              <Button
                onClick={onOk}
                disabled={okDisabled}
                variant="outlined"
                color="success"
              >
                {okLabel || 'OK'}
              </Button>
            )}
            {onClose && (
              <Button
                autoFocus={true}
                onClick={() => onClose()}
                variant="solid"
                color="primary"
              >
                Cancel
              </Button>
            )}
          </JoyDialogActions>
        )}
      </JoyModalDialog>
    </Modal>
  );
}
