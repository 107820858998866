import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getClientsByIds,
  getClientsComboboxList,
} from '../../selectors/client';
import { IdsArray, IFilter, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getClientComboboxListRequest,
  getClientListRequest,
} from '../../actions/client';

export const clientComboboxFilter = {
  filter: {
    fields: {
      id: true,
      name: true,
    },
  },
};

/**
 * A custom hook to fetch clients from store if they exist otherwise to make a request to fetch needed clients from
 * the server
 */
export const useFetchClients = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch clients list from store
  const clients = useSelector(
    (state) => getClientsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(clients)) {
      dispatcher(
        getClientListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch client combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchClientsCombobox = (filter?: IFilter) => {
  const dispatcher = useDispatch();
  // fetch combobox list from store
  const combobox = useSelector(getClientsComboboxList, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatcher(getClientComboboxListRequest(filter ?? clientComboboxFilter));
    }
  };
};
