import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import {
  getRemovedEmployeeListRequest,
  getRemovedEmployeeCountRequest,
} from 'src/modules/actions';
import {
  getRemovedEmployeeCount,
  selectRemovedEmployeesForTable,
} from 'src/modules/selectors/employee';
import {
  useBrowserHistoryFunctions,
  useEmployeesInclusionObject,
  useEmployeesHeadCellsConfig,
  useFetchEmployeesComboBoxDataEffect,
  useEmployeesFilterFieldsConfiguration,
  useEmployeesDefaultWhereForRemovedEntities,
} from 'src/modules/utils';
import {
  useTableContentEffect,
  // useRowsPerPageOptions,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsDataWithQueryParamsSynchronization,
} from 'src/modules/utils/hooks/table';
import { RestoreEmployeeForm } from './RestoreEmployeeForm';

export const RestoreEmployees = () => {
  const { t } = useTranslation();
  const { resetFiltersInQueryParams } = useBrowserHistoryFunctions();
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState<
    number | null
  >(null);

  const inclusionObj = useEmployeesInclusionObject({
    onlyRemovedEntities: true,
  });
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    useEmployeesHeadCellsConfig(),
  );
  const filterFieldsConfiguration = useEmployeesFilterFieldsConfiguration();
  const defaultWhere = useEmployeesDefaultWhereForRemovedEntities();

  const {
    page,
    limit,
    order,
    orderBy,
    // isSomeTableDataChanged,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
    clearTableData,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
  });

  const {
    filterFields,
    // isSomeFilterApplied,
    getLabel,
    onFiltersFormSubmit,
    clearFilters,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    defaultWhere,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectCount: getRemovedEmployeeCount,
    selectData: selectRemovedEmployeesForTable,
    getEntitiesRequest: getRemovedEmployeeListRequest,
    getEntitiesCountRequest: getRemovedEmployeeCountRequest,
  });

  // const rowsPerPageOptions = useRowsPerPageOptions(count);

  // const isClearFiltersIconVisible =
  //   isSomeFilterApplied || isSomeTableDataChanged;

  useFetchEmployeesComboBoxDataEffect();

  return (
    <>
      <Table tableUniqueKey="restore_employees">
        <TableToolbar>
          <TableTitle>Restore Employees</TableTitle>
          <TableActions>
            <Filter
              onSubmit={onFiltersFormSubmit}
              onClearFiltersIconClick={() => {
                clearTableData();
                clearFilters();
                resetFiltersInQueryParams();
              }}
            >
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'employeeId',
                  labelPrefix: t('employees.emp_id'),
                })}
                {...getFilterCommonPropsByFilterName('employeeId')}
              />
              <TextFieldFilter
                label={getLabel({
                  filterName: 'firstName',
                  labelPrefix: t('employees.first_name'),
                })}
                {...getFilterCommonPropsByFilterName('firstName')}
              />
              <TextFieldFilter
                label={getLabel({
                  filterName: 'lastName',
                  labelPrefix: t('employees.last_name'),
                })}
                {...getFilterCommonPropsByFilterName('lastName')}
              />
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'payRate',
                  labelPrefix: t('employees.pay_rate'),
                })}
                {...getFilterCommonPropsByFilterName('payRate')}
              />
              <TextFieldFilter
                type="number"
                label={getLabel({
                  filterName: 'badge',
                  labelPrefix: t('employees.badge'),
                })}
                {...getFilterCommonPropsByFilterName('badge')}
              />
            </Filter>
          </TableActions>
        </TableToolbar>
        <TableContent
          data={data}
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          onTableRowClick={(e, row) => setSelectedEmployeeId(row.id)}
          onSort={(orderBy, order) => {
            setOrder(order);
            setOrderBy(orderBy);
          }}
        />

        <TableBottom>
          <Pagination
            currentPageNumber={page}
            count={count}
            rowsPerPage={limit}
            onPageChange={setPage}
            onRowsPerPageChange={setLimit}
          />
        </TableBottom>
      </Table>
      {selectedEmployeeId && (
        <RestoreEmployeeForm
          employeeId={selectedEmployeeId}
          onClose={() => setSelectedEmployeeId(null)}
        />
      )}
    </>
  );
};
