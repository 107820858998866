import React, { useEffect, useState } from 'react';
import { Button, IconButton, Box, Typography } from '@mui/joy';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Trash04Svg, UploadToCloudSvg } from '../svgIcons';

interface IUploadImageProps {
  onChange: (file: File | null) => void;
  onRemove?: () => void;
  image?: string;
  fileName?: string;
  file?: Partial<File> | null;
  btnLabel?: string;
  accept?: string;
  isImageFromPropsSourceOfTruth?: boolean;
  // @@@TODO: we might need to do this prop required
  inputId?: string;
}

export default function UploadImage({
  onChange,
  onRemove,
  image,
  file,
  accept,
  btnLabel,
  isImageFromPropsSourceOfTruth,
  inputId,
}: IUploadImageProps) {
  const { t } = useTranslation();

  const inputIdToUse = inputId ?? 'contained-button-file';

  const [imagePreview, setImagePreview] = useState<string>('');
  const [uploadedFileDetails, setUploadedFileDetails] = useState<Partial<
    File
  > | null>(null);

  // here we initialize the image preview from props
  useEffect(() => {
    if (isImageFromPropsSourceOfTruth) {
      setImagePreview(image ?? '');
      setUploadedFileDetails(file ?? null);

      return;
    }

    if (image && !isEmpty(image)) {
      setImagePreview(image);
    }
  }, [file, image, isImageFromPropsSourceOfTruth]);

  // handle removing image preview
  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }
    setImagePreview('');
    setUploadedFileDetails(null);
    onChange(null);
  };

  // handle uploading image
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader: FileReader = new FileReader();
    const [file] = [...event.target.files!];

    setUploadedFileDetails(file);
    fileReader.onload = () => {
      setImagePreview(fileReader.result as string);
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }

    onChange(file);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'colors.background.bg_primary',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'colors.border.border_secondary',
          borderRadius: 12,
          px: 3,
          py: 2,

          '&:hover': {
            borderColor: 'colors.border.border_brand',
          },
        }}
      >
        <UploadToCloudSvg />

        <Box sx={{ pt: 1.5 }}>
          <Box
            component="input"
            accept={accept ?? 'image/*'}
            sx={{ display: 'none' }}
            id={inputIdToUse}
            multiple={false}
            type="file"
            onChange={handleChange}
          />
          <label htmlFor={inputIdToUse}>
            <Button
              variant="plain"
              component="span"
              size="md"
              sx={{ '&:hover': { bgcolor: 'transparent' } }}
            >
              <Typography
                textColor="colors.buttons.tertiary_colors.button_tertiary_color_fg"
                level="text_sm"
                fontWeight="semi_bold"
              >
                {btnLabel ?? t('common.click_to_upload')}
              </Typography>
            </Button>
          </label>
        </Box>
      </Box>

      {(uploadedFileDetails?.name ||
        uploadedFileDetails?.size ||
        imagePreview) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            bgcolor: 'colors.background.bg_primary',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'colors.border.border_secondary',
            borderRadius: 12,
            mt: 1,
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box>
              {/* <File04Svg /> */}
              <Box
                component="img"
                sx={{ maxWidth: 60, maxHeight: 60, borderRadius: '12px' }}
                src={imagePreview}
                alt="preview"
              />
            </Box>

            <Box sx={{ pl: 1 }}>
              {uploadedFileDetails?.name && (
                <Box>
                  <Typography
                    level="text_sm"
                    textColor="colors.text.text_secondary"
                  >
                    {uploadedFileDetails.name}
                  </Typography>
                </Box>
              )}
              {uploadedFileDetails?.size && (
                <Box>
                  <Typography
                    level="text_sm"
                    textColor="colors.text.text_tertiary"
                  >
                    {(uploadedFileDetails.size / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignSelf: 'start' }}>
            <IconButton
              onClick={handleRemove}
              sx={{ minWidth: 0, minHeight: 0 }}
            >
              <Trash04Svg />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}
