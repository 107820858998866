import { IUpdateApplicant } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { createApplicantScheme } from './create-applicant.scheme';
import { PartialSchema } from 'ajv/lib/types/json-schema';

export const updateApplicantsScheme: PartialSchema<IUpdateApplicant[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createApplicantScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      hire: {
        type: 'boolean',
        default: false,
      },
      ...createApplicantScheme.properties,
    },
  },
};
