import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Box, Grid, styled, Typography } from '@mui/material';

import { MUI_SIZE_12, MUI_SIZE_6 } from '../../config';
import YearMonthComboBox from '../Formik/comboboxes-with-entities/YearMonthComboBox';
import { ComboBoxOption } from '../ComboBox';
import { AnyObject } from '../../modules/types';
import { get } from 'lodash';

const PopoverGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export interface IFormikYearMonthRangeValue {
  start?: ComboBoxOption | null;
  end?: ComboBoxOption | null;
}

export interface IFormikYearMonthRangeProps {
  id: string;
  label?: string;
  value?: IFormikYearMonthRangeValue;
  formik: AnyObject;
}

const FormikYearMonthRange: React.FC<IFormikYearMonthRangeProps> = (props) => {
  const { t } = useTranslation();

  const [isLeftOpen, setIsLeftOpen] = React.useState(false);
  const [isRightOpen, setIsRightOpen] = React.useState(false);

  const [values, setValues] = useState<IFormikYearMonthRangeValue | undefined>(
    undefined,
  );

  const { optionsFromYear, optionsFromMonth } = React.useMemo(() => {
    const optionsFromYear = (
      get(props, ['formik', 'values', props.id, 0], '') || ''
    )
      .toString()
      .substring(0, 4);

    const optionsFromMonth = (
      get(props, ['formik', 'values', props.id, 0], '') || ''
    )
      .toString()
      .substring(4, 6);

    return { optionsFromYear, optionsFromMonth };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(props, ['formik', 'values', props.id, 0])]);

  useEffect(() => {
    if (props.value) {
      setValues(props.value);
    }
  }, [props]);

  const handleYearMonthChange = (
    position: keyof IFormikYearMonthRangeValue,
  ) => (event: React.ChangeEvent<AnyObject>, value: ComboBoxOption | null) => {
    const updatedValues = {
      ...values,
      [position]: value,
    } as IFormikYearMonthRangeValue;

    setValues(updatedValues);
    setIsLeftOpen(false);
    setIsRightOpen(false);
  };

  return (
    <Box
      sx={{
        border: '1px solid #c3c3c3',
        borderRadius: 1,
        padding: '0 12px',
        position: 'relative',
      }}
    >
      <Backdrop
        sx={{ backgroundColor: 'transparent', zIndex: 9 }}
        open={isLeftOpen || isRightOpen}
        onClick={() => {
          setIsLeftOpen(false);
          setIsRightOpen(false);
        }}
      />
      {props.label && (
        <Box
          sx={{
            position: 'absolute',
            top: '-13px',
            left: '8px',
            padding: '4px',
            bgcolor: 'background.paper',
          }}
        >
          <Typography fontSize="0.8rem">{props.label}</Typography>
        </Box>
      )}
      <PopoverGrid container justifyContent="center" spacing={3}>
        <Grid item md={MUI_SIZE_6} xs={MUI_SIZE_12}>
          <YearMonthComboBox
            id={`${props.id}.0`}
            value={values?.start}
            formik={props.formik}
            placeholder={t('common.from')}
            onChange={handleYearMonthChange('start')}
          />
        </Grid>
        <Grid item md={MUI_SIZE_6} xs={MUI_SIZE_12}>
          <YearMonthComboBox
            id={`${props.id}.1`}
            {...(optionsFromYear ? { optionsFromYear } : {})}
            {...(optionsFromMonth ? { optionsFromMonth } : {})}
            value={values?.end}
            formik={props.formik}
            placeholder={t('common.to')}
            onChange={handleYearMonthChange('end')}
          />
        </Grid>
      </PopoverGrid>
    </Box>
  );
};

export default FormikYearMonthRange;
