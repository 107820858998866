import { useTranslation } from 'react-i18next';

export const useOpenPositionCreateFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      site: {
        target: 'site',
        content: t('guide.open_position.site'),
      },
      requisitionNumber: {
        target: 'requisitionNumber',
        content: t('guide.open_position.requisitionNumber'),
      },
      position: {
        target: 'position',
        content: t('guide.open_position.position'),
      },
      department: {
        target: 'department',
        content: t('guide.open_position.department'),
      },
      numberNeeded: {
        target: 'number-needed',
        content: t('guide.open_position.number_needed'),
      },
      shift: {
        target: 'shift',
        content: t('guide.open_position.shift'),
      },
      description: {
        target: 'description',
        content: t('guide.open_position.description'),
      },
      dateStart: {
        target: 'date-start',
        content: t('guide.open_position.date_start'),
      },
      skills: {
        target: 'skills',
        content: t('guide.open_position.skills'),
      },
    },
  };
};

export const useOpenPositionUpdateFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      site: {
        target: 'site',
        content: t('guide.open_position.site'),
      },
      requisitionNumber: {
        target: 'requisitionNumber',
        content: t('guide.open_position.requisitionNumber'),
      },
      position: {
        target: 'position',
        content: t('guide.open_position.position'),
      },
      department: {
        target: 'department',
        content: t('guide.open_position.department'),
      },
      numberNeeded: {
        target: 'number-needed',
        content: t('guide.open_position.number_needed'),
      },
      shift: {
        target: 'shift',
        content: t('guide.open_position.shift'),
      },
      description: {
        target: 'description',
        content: t('guide.open_position.description'),
      },
      dateStart: {
        target: 'date-start',
        content: t('guide.open_position.date_start'),
      },
      skills: {
        target: 'skills',
        content: t('guide.open_position.skills'),
      },
    },
  };
};
