import { isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getPoliciesByIds } from '../../selectors/policy';
import { IdsArray, IStoreState } from '../../types';
import { getPolicyListRequest } from '../../actions/policy';

/**
 * A custom hook to fetch policies from store if they exist otherwise to make a request to fetch needed policies from
 * the server
 */
export const useFetchPolicies = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch policies list from store
  const policies = useSelector(
    (state) => getPoliciesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(policies)) {
      dispatcher(
        getPolicyListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
          },
        }),
      );
    }
  };
};
