import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  IBaselineListResponse,
  IBaselineModel,
  IBaselineState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IBaselineState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  baseline: NaN,
  minCpu: NaN,
  maxCpu: NaN,
  list: {},
  comboboxList: [],
};

/**
 * Flush actions
 */
const flushBaselineState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createBaselineRequest = (state: IBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createBaselineSuccess = (
  state: IBaselineState,
  payload: IBaselineModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createBaselineFailed = (state: IBaselineState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getBaselineListRequest = (state: IBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getBaselineListSuccess = (
  state: IBaselineState,
  payload: IBaselineListResponse,
) => ({
  ...state,
  list: {
    ...state.list,
    [payload.siteId]: payload.data,
  },
  error: false,
  is_data_requested: false,
});

const getBaselineListFailed = (state: IBaselineState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteBaselineRequest = (state: IBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteBaselineFailed = (state: IBaselineState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateBaselinesRequest = (state: IBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateBaselinesFailed = (state: IBaselineState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getBaselineComboboxListRequest = (state: IBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getBaselineComboboxListSuccess = (
  state: IBaselineState,
  payload: Partial<IBaselineModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getBaselineComboboxListFailed = (
  state: IBaselineState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IBaselineState>();

/**
 * Baseline reducer
 */
export const baseline = createReducer<IBaselineState>({}, defaultState)
  // flush actions
  .on(actions.flushBaselineState, flushBaselineState)
  // creation actions
  .on(actions.createBaselineRequest, createBaselineRequest)
  .on(actions.createBaselineSuccess, createBaselineSuccess)
  .on(actions.createBaselineFailed, createBaselineFailed)
  // get list actions
  .on(actions.getBaselineListRequest, getBaselineListRequest)
  .on(actions.getBaselineListSuccess, getBaselineListSuccess)
  .on(actions.getBaselineListFailed, getBaselineListFailed)
  // deletion actions
  .on(actions.deleteBaselineRequest, deleteBaselineRequest)
  .on(actions.deleteBaselineFailed, deleteBaselineFailed)
  // updating actions
  .on(actions.updateBaselinesRequest, updateBaselinesRequest)
  .on(actions.updateBaselinesFailed, updateBaselinesFailed)
  // get combobox list actions
  .on(actions.getBaselineComboboxListRequest, getBaselineComboboxListRequest)
  .on(actions.getBaselineComboboxListSuccess, getBaselineComboboxListSuccess)
  .on(actions.getBaselineComboboxListFailed, getBaselineComboboxListFailed)
  // export actions
  .on(actions.exportBaselineRequest, exportRequest)
  .on(actions.exportBaselineSuccess, exportSuccess)
  .on(actions.exportBaselineFailed, exportFailed);

export default baseline;
