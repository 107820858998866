import React from 'react';
import { Box, BoxProps } from '@mui/joy';

interface IPageContentChildrenContainerProps extends BoxProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}

export const PageContentChildContainer = ({
  children,
  fullHeight = true,
  sx,
  ...rest
}: IPageContentChildrenContainerProps) => {
  return (
    <Box
      sx={{
        height: fullHeight ? '100%' : 'auto',
        width: '100%',
        px: 2,
        flexGrow: fullHeight ? 1 : 0,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
