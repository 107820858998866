import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';
import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthDailyUphBySiteSkuAndEmployee',
);

// get list actions
export const {
  getListRequest: getCardinalHealthDailyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteSkuAndEmployeeListFailed,
} = actionCreator.takeListActions<ICardinalHealthDailyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthDailyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteSkuAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthDailyUphBySiteSkuAndEmployeeRequest,
  exportReportSuccess: exportCardinalHealthDailyUphBySiteSkuAndEmployeeSuccess,
  exportReportFailed: exportCardinalHealthDailyUphBySiteSkuAndEmployeeFailed,
} = new ExportActionCreator(
  'CardinalHealthDailyUphBySiteSkuAndEmployee',
).takeExportActions();
