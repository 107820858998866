import React from 'react';

export const useAdditionalFeeCategoryListDefaultFilter = () => {
  return React.useMemo(
    () => ({
      include: [
        {
          relation: 'site',
          scope: {
            order: ['name ASC'],
          },
        },
      ],
    }),
    [],
  );
};
