import React from 'react';
import { Box, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { InvoiceMonthToolbar } from './InvoiceMonthToolbar';
import { InvoiceHeader } from './InvoiceHeader';
import { InvoiceBackupDetailsTable } from './InvoiceBackupDetailsTable';
import { useDispatch } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { InvoiceParticipant } from './InvoiceParticipant';
import { IInvoiceMonthDetails } from 'src/modules/types';
import { Api } from 'src/modules/utils';
import { InvoiceMonthGeneralInfoTable } from './InvoiceMonthGeneralInfoTable';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../../components/PageContent';
import { PageContentScrollableChildContainer } from '../../../components/PageContent/PageContentScrollableChildContainer';

export const InvoiceMonthDetails = () => {
  const [
    invoiceMonthDetails,
    setInvoiceMonthDetails,
  ] = React.useState<null | IInvoiceMonthDetails>(null);
  const dispatch = useDispatch();

  const { invoiceId } = useParams<{ invoiceId: string }>();

  const [isHovered, setIsHovered] = React.useState(false);

  const billTo = React.useMemo(() => {
    if (!invoiceMonthDetails) {
      return null;
    }

    return invoiceMonthDetails.billTo.headers.map((header) => (
      <Box key={header}>{invoiceMonthDetails.billTo.itemsObject[header]}</Box>
    ));
  }, [invoiceMonthDetails]);

  const questions = React.useMemo(() => {
    if (!invoiceMonthDetails || !invoiceMonthDetails.questions) {
      return null;
    }

    return invoiceMonthDetails.questions.headers.map((header) => (
      <Box key={header}>
        <span>{invoiceMonthDetails.questions.itemsObject[header]}</span>
      </Box>
    ));
  }, [invoiceMonthDetails]);

  const remitTo = React.useMemo(() => {
    if (!invoiceMonthDetails) {
      return null;
    }

    return invoiceMonthDetails.remitTo.headers.map((header) => (
      <Box key={header}>{invoiceMonthDetails.remitTo.itemsObject[header]}</Box>
    ));
  }, [invoiceMonthDetails]);

  React.useEffect(() => {
    if (!invoiceId) {
      return;
    }

    fetchInvoiceMonthDetailsById(invoiceId);

    async function fetchInvoiceMonthDetailsById(id: string) {
      const response = await Api.Invoice.monthById(id);

      setInvoiceMonthDetails(response);
    }
  }, [dispatch, invoiceId]);

  if (!invoiceMonthDetails || !invoiceId) {
    return <MainContentLoader />;
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <PageContentScrollableChildContainer sx={{ mt: 2 }} fullHeight>
          <Paper
            sx={{
              position: 'relative',
              width: '900px',
              minHeight: '90vh',
              margin: '0 auto',
              mb: 1,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <InvoiceMonthToolbar isVisible={isHovered} invoiceId={invoiceId} />

            <Box sx={{ padding: 1 }}>
              <InvoiceHeader
                invoiceNumber={invoiceMonthDetails.backupInvoiceNumber}
                siteName={invoiceMonthDetails.siteName}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 0.5,
                }}
              >
                {billTo && (
                  <Box style={{ padding: '0 4px' }}>
                    <InvoiceParticipant title="Bill To:">
                      {billTo}
                    </InvoiceParticipant>
                  </Box>
                )}

                {questions && (
                  <InvoiceParticipant title="Questions:">
                    {questions}
                  </InvoiceParticipant>
                )}

                {remitTo && (
                  <InvoiceParticipant title="Remit To:">
                    {remitTo}
                  </InvoiceParticipant>
                )}
              </Box>
              <Box sx={{ marginTop: 0.5 }}>
                <InvoiceMonthGeneralInfoTable
                  monthEndingDate={invoiceMonthDetails.monthEndingDate}
                  paymentTerms={invoiceMonthDetails.paymentTerms}
                  invoiceDate={invoiceMonthDetails.invoiceDate}
                  purchaseOrder={invoiceMonthDetails.purchaseOrder}
                  dueDate={invoiceMonthDetails.dueDate}
                  monthName={invoiceMonthDetails.monthName}
                />
              </Box>
              <Box sx={{ marginTop: 0.5 }}>
                <InvoiceBackupDetailsTable
                  headers={invoiceMonthDetails.backupDetails.headers}
                  items={invoiceMonthDetails.backupDetails.items}
                  total={invoiceMonthDetails.total}
                />
              </Box>
            </Box>
          </Paper>
        </PageContentScrollableChildContainer>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
