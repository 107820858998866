import {
  IProductionWeeklyUphBySiteModel,
  IProductionWeeklyUphBySiteState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionWeeklyUphBySiteState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionWeeklyUphBySiteListRequest,
  getListSuccess: getProductionWeeklyUphBySiteListSuccess,
  getListFailed: getProductionWeeklyUphBySiteListFailed,
} = reducerCreator.takeListHandlers<
  IProductionWeeklyUphBySiteState,
  IProductionWeeklyUphBySiteModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionWeeklyUphBySiteCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteCountFailed,
} = reducerCreator.takeCountHandlers<IProductionWeeklyUphBySiteState>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionWeeklyUphBySiteRequest,
  exportSuccess: exportProductionWeeklyUphBySiteSuccess,
  exportFailed: exportProductionWeeklyUphBySiteFailed,
} = new ExportReducerCreator().takeHandlers<IProductionWeeklyUphBySiteState>();

/**
 * actually Reducer
 */
export const productionWeeklyUphBySite = createReducer<
  IProductionWeeklyUphBySiteState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionWeeklyUphBySiteListRequest,
    getProductionWeeklyUphBySiteListRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteListSuccess,
    getProductionWeeklyUphBySiteListSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteListFailed,
    getProductionWeeklyUphBySiteListFailed,
  )
  // get count actions
  .on(
    actions.getProductionWeeklyUphBySiteCountRequest,
    getProductionWeeklyUphBySiteCountRequest,
  )
  .on(
    actions.getProductionWeeklyUphBySiteCountSuccess,
    getProductionWeeklyUphBySiteCountSuccess,
  )
  .on(
    actions.getProductionWeeklyUphBySiteCountFailed,
    getProductionWeeklyUphBySiteCountFailed,
  )
  // export action
  .on(
    actions.exportProductionWeeklyUphBySiteRequest,
    exportProductionWeeklyUphBySiteRequest,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteSuccess,
    exportProductionWeeklyUphBySiteSuccess,
  )
  .on(
    actions.exportProductionWeeklyUphBySiteFailed,
    exportProductionWeeklyUphBySiteFailed,
  );

export default productionWeeklyUphBySite;
