import { JSONSchemaType } from 'ajv';
import { ICreateClientRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createClientScheme: JSONSchemaType<ICreateClientRequest> = {
  type: 'object',
  required: ['name', 'clientKey'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    clientKey: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
