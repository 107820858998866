import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FilterValue, FilterValueDescriptor } from 'src/modules/types/table';
import { ShiftsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ShiftsComboBoxFilter';
import { DepartmentsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DepartmentsComboBoxFilter';

interface IAbsenteeRealtimeFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(
    filtersData: Record<string, FilterValue>,
  ): Record<string, FilterValueDescriptor>;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const AbsenteeRealtimeFilter: React.FC<IAbsenteeRealtimeFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'employeeName',
            labelPrefix: t('hookyFolks.employeeName'),
          })}
          {...getFilterCommonPropsByFilterName('employeeName')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'badge',
            labelPrefix: t('hookyFolks.badge'),
          })}
          {...getFilterCommonPropsByFilterName('badge')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ShiftsComboBoxFilter
          label={getLabel({
            filterName: 'shiftId',
            labelPrefix: t('hookyFolks.employeeShiftName'),
          })}
          {...getFilterCommonPropsByFilterName('shiftId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <DepartmentsComboBoxFilter
          label={getLabel({
            filterName: 'departmentId',
            labelPrefix: t('hookyFolks.departmentName'),
          })}
          {...getFilterCommonPropsByFilterName('departmentId')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
