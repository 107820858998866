import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ICommonFilterProps } from 'src/components/Table/components/TableActions/components/Filter/types';
import { TableContext } from 'src/components/Table/TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { useFetchIssueTypesCombobox } from 'src/modules/utils';
import { getIssueTypesWithSitesComboboxList } from 'src/modules/selectors/issueType';

type IDownTimeTypesComboBoxFilterProps = Omit<
  IComboboxProps,
  'value' | 'options' | 'name'
> &
  ICommonFilterProps;

export const DownTimeTypesComboBoxFilter: React.FC<IDownTimeTypesComboBoxFilterProps> = ({
  value,
  name,
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const fetchDownTimeTypesCombobox = useFetchIssueTypesCombobox();

  const options = useSelector(getIssueTypesWithSitesComboboxList);

  useEffect(() => {
    fetchDownTimeTypesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue =
    options.find((option) => option.id === Number(valueId)) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={options}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
