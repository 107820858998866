import { noop } from 'lodash';
import React from 'react';

const MULTI_ROW_FORM_COLUMN_DIRECTION_MAX_WIDTH = 800;

export type ReportShape = 'table' | 'chart';

export interface IMultiRowFormNullishValue {
  [key: string]: null | undefined | '';
}

export interface IMultiRowFormContext {
  headerLabels: Array<string>;
  addHeaderLabelByIndex: (label: string) => void;

  columnDirectionMaxWidth: number;
  setColumnDirectionMaxWidth: React.Dispatch<
    React.SetStateAction<IMultiRowFormContext['columnDirectionMaxWidth']>
  >;
}

export const MultiRowFormContext = React.createContext<IMultiRowFormContext>({
  headerLabels: [],
  columnDirectionMaxWidth: MULTI_ROW_FORM_COLUMN_DIRECTION_MAX_WIDTH,

  addHeaderLabelByIndex: noop,
  setColumnDirectionMaxWidth: noop,
});

export const MultiRowFormContextProvider: React.FC = ({ children }) => {
  const [headerLabels, setHeaderLabels] = React.useState<
    IMultiRowFormContext['headerLabels']
  >([]);
  const [columnDirectionMaxWidth, setColumnDirectionMaxWidth] = React.useState(
    MULTI_ROW_FORM_COLUMN_DIRECTION_MAX_WIDTH,
  );

  const addHeaderLabelByIndex = React.useCallback((label: string) => {
    setHeaderLabels((prev) => {
      const isLabelAlreadyExists = prev.some(
        (headerLabel) => headerLabel === label,
      );

      if (!isLabelAlreadyExists) {
        return [...prev, label];
      }

      return [...prev];
    });
  }, []);

  return (
    <MultiRowFormContext.Provider
      value={{
        headerLabels,
        addHeaderLabelByIndex,

        columnDirectionMaxWidth,
        setColumnDirectionMaxWidth,
      }}
    >
      {children}
    </MultiRowFormContext.Provider>
  );
};
