import React from 'react';

import { VideoList } from 'src/pages/TrainingVideos/components/VideoList';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';

export const TrainingVideos = () => {
  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight sx={{ pt: 2 }}>
        <VideoList />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
