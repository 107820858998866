import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IFilterData,
  ILoggedInModel,
  ILogtimeModel,
  ISagaAction,
  ISagaActionBind,
  MoveToDepartmentRequestType,
} from '../../types';

/**
 * Get logged in employees list
 */
export const getLoggedInListRequestSaga = function* (): SagaIterator {
  try {
    const response: ILoggedInModel[] = yield call(Api.SupervisorView.loggedIn);
    yield put(actions.getLoggedInListSuccess(response));
  } catch (e) {
    yield put(actions.getLoggedInListFailed());
  }
};

/**
 * Log out employees
 */
export const logOutEmployeeRequestSaga = function* (
  action: ISagaAction<{ data: ILogtimeModel[]; filter: IFilterData }>,
): SagaIterator {
  try {
    yield call(Api.Logtime.bulkUpdate, action.payload.data);
    yield put(actions.flushSupervisorViewState());
    yield put(actions.getLoggedInListRequest());
    yield put(
      actions.getLogtimeListForSupervisorViewRequest({
        filter: action.payload.filter,
      }),
    );
  } catch (e) {
    yield put(actions.logOutEmployeeFailed());
  }
};

/**
 * Move to another department employees
 */
export const moveToDepartmentRequestSaga = function* (
  action: ISagaAction<{
    data: MoveToDepartmentRequestType[];
    filter: IFilterData;
  }>,
): SagaIterator {
  try {
    yield call(Api.Logtime.moveToDepartment, action.payload.data);
    yield put(actions.flushSupervisorViewState());
    yield put(
      actions.getLogtimeListForSupervisorViewRequest({
        filter: action.payload.filter,
      }),
    );
    yield put(actions.getLoggedInListRequest());
  } catch (e) {
    yield put(actions.moveToDepartmentFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  // get logged in employees list
  { action: actions.getLoggedInListRequest, saga: getLoggedInListRequestSaga },
  // log out employees
  { action: actions.logOutEmployeeRequest, saga: logOutEmployeeRequestSaga },
  // move employees to another department
  {
    action: actions.moveToDepartmentRequest,
    saga: moveToDepartmentRequestSaga,
  },
];
