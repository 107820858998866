import React from 'react';
import { isEqual } from 'lodash';
import { SCOPES } from 'src/config';
import { useSelector } from 'react-redux';
import { getUserPolicies } from 'src/modules/selectors/user';
import { ReportPageContext } from './ReportPageContext';
import { PolicyConfigItem } from '../../modules/types';
import { IFilterPanelOption } from '../FilterPanel';

interface IReportContainerProps {
  reportLabel: string;
  reportTypeLabel: string;
  reportId: string;
  reportTypeId: string;
  resourceUrl: string;
  client: string;
  policyUrl?: string;
  isOnlyDataUrlExists?: boolean;
  isChartReport?: boolean;
  polices?: PolicyConfigItem[];
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

// This component responsible for charts/tables rendering and populating Types/Reports dropdowns with appropriate items
// Make sure thats its child table(s) are placed inside TablesContainer component
// and its child chart(s) are placed inside ChartsContainers component
export const Report: React.FC<IReportContainerProps> = ({
  reportId,
  reportLabel,
  reportTypeId,
  reportTypeLabel,
  children,
  resourceUrl,
  policyUrl,
  isOnlyDataUrlExists,
  polices,
  client,
}) => {
  const {
    activeReportId,
    activeReportTypeId,
    addReportTypeToReportTypesList,
    addReportToReportsListByReportType,
  } = React.useContext(ReportPageContext);

  const userPolicies = useSelector(getUserPolicies);

  React.useEffect(() => {
    const requiredPolices = polices ?? [
      {
        resource: policyUrl ?? resourceUrl,
        action: SCOPES.VIEW,
        ...(client && client !== 'productions/reports'
          ? { clientKey: client }
          : {}),
      },
    ];

    if (
      !userPolicies ||
      !userPolicies.length ||
      requiredPolices.every((policy) =>
        userPolicies.some((userPolicy) => isEqual(policy, userPolicy)),
      )
    ) {
      addReportTypeToReportTypesList({ reportTypeId, reportTypeLabel });
      addReportToReportsListByReportType(reportTypeId, {
        reportId,
        reportLabel,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportId,
    reportLabel,
    reportTypeId,
    reportTypeLabel,
    addReportTypeToReportTypesList,
    addReportToReportsListByReportType,
    userPolicies,
    resourceUrl,
    isOnlyDataUrlExists,
  ]);

  // React.useEffect(() => {
  //   if (filtersOptionsConfig) {
  //     addFilterConfigByReport(filtersOptionsConfig, { reportId, reportTypeId });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [addFilterConfigByReport, reportId, reportTypeId]);

  // React.useEffect(() => {
  //   if (
  //     /**
  //      * Note! When you rely on the count of children
  //      * Children.count() and Children.toArray().length have different outputs.
  //      *
  //      * This is because Children.count() is counting the number of slots that children has.
  //      * Whether it be false, null or undefined, it's still a slot
  //      *
  //      * and Children.count() does a good job of counting it. From the docs:
  //      * * number of times that a callback passed to map or forEach would be invoked.
  //      *
  //      * And be careful of using something like this:
  //      * <Wrapper>
  //      *   <Children1></Children1>
  //      *   {' '}
  //      * </Wrapper>
  //      * It will be counted as 2
  //      */
  //     React.Children.count(children) ===
  //     CHILDREN_QUANTITY_WHEN_REPORT_CONTAINS_CHARTS
  //   ) {
  //     addReportToReportsChartsList(reportId);
  //   }
  // }, [addReportToReportsChartsList, children, reportId]);

  if (activeReportTypeId !== reportTypeId || activeReportId !== reportId) {
    return null;
  }

  return <>{children}</>;
};
