import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateWeekBasedInvoice,
  IdsArray,
  IInvoiceModel,
  IFilter,
  IUpdateInvoice,
  // PayloadWithNavigateFunc,
  IInvoiceModelWithRelations,
  IInvoiceDetails,
  IExportInvoice,
  ICreateMonthBasedInvoiceRequest,
  IUpdateMonthInvoice,
  IExportMonthInvoice,
  PayloadWithFilters,
} from '../../types';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushInvoiceState = createAction('Flush Invoice data in store');

// Creation actions
export const createInvoiceRequest = createAction<
  PayloadWithFilters<ICreateWeekBasedInvoice>
>('Make request to create a new Invoice');
export const createInvoiceSuccess = createAction<IInvoiceModel>(
  'New Invoice has been created successfully',
);
export const createInvoiceFailed = createAction('Cannot create a new Invoice');

export const createMonthInvoiceRequest = createAction<
  PayloadWithFilters<ICreateMonthBasedInvoiceRequest>
>('Make request to create a new Invoice');
export const createMonthInvoiceSuccess = createAction<Array<IInvoiceModel>>(
  'New Invoice has been created successfully',
);
export const createMonthInvoiceFailed = createAction(
  'Cannot create a new Invoice',
);

// Fetch Invoices actions
// Week
export const getInvoiceListRequest = createAction<IFilter>(
  'Make request to fetch Invoice list',
);
export const getInvoiceListSuccess = createAction<IInvoiceModelWithRelations[]>(
  'Invoices list has been fetched successfully',
);
export const getInvoiceListFailed = createAction('Cannot fetch Invoice list');

export const getInvoiceCountRequest = createAction<IFilter>(
  'Make request to get Invoice count',
);
export const getInvoiceCountSuccess = createAction<ICountResponse>(
  'Invoices count has been fetched successfully',
);
export const getInvoiceCountFailed = createAction('Cannot get Invoice count');
// Month
export const getMonthInvoiceListRequest = createAction<IFilter>(
  'Make request to fetch Invoice list',
);
export const getMonthInvoiceListSuccess = createAction<
  IInvoiceModelWithRelations[]
>('Invoices list has been fetched successfully');
export const getMonthInvoiceListFailed = createAction(
  'Cannot fetch Invoice list',
);

export const getMonthInvoiceCountRequest = createAction<IFilter>(
  'Make request to get Invoice count',
);
export const getMonthInvoiceCountSuccess = createAction<ICountResponse>(
  'Invoices count has been fetched successfully',
);
export const getMonthInvoiceCountFailed = createAction(
  'Cannot get Invoice count',
);
// By id
export const getInvoiceByIdRequest = createAction<number>(
  'Make request to fetch Invoice by id',
);
export const getInvoiceByIdSuccess = createAction<IInvoiceDetails>(
  'Invoice by id has been fetched successfully',
);
export const getInvoiceByIdFailed = createAction('Cannot fetch Invoice by id');

// delete actions
export const deleteInvoiceRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete Invoices',
);
export const deleteInvoiceSuccess = createAction(
  'Invoices deleted successfully',
);
export const deleteInvoiceFailed = createAction('Cannot delete Invoices');

export const deleteMonthInvoiceRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete Month Invoices');
export const deleteMonthInvoiceSuccess = createAction(
  'Month Invoices successfully',
);
export const deleteMonthInvoiceFailed = createAction(
  'Cannot delete Month Invoices',
);

// Updating actions
export const updateInvoicesRequest = createAction<
  PayloadWithFilters<IUpdateInvoice[]>
>('Make request to update Invoices');
export const updateInvoicesSuccess = createAction(
  'Invoices updated successfully',
);
export const updateInvoicesFailed = createAction('Cannot update Invoices');

export const updateMonthInvoicesRequest = createAction<
  PayloadWithFilters<IUpdateMonthInvoice[]>
>('Make request to update Month Invoices');
export const updateMonthInvoicesSuccess = createAction(
  'Month Invoices successfully updated',
);
export const updateMonthInvoicesFailed = createAction(
  'Cannot update Month Invoices',
);

// Freeze Invoice
export const freezeInvoiceRequest = createAction<number>(
  'Make request to freeze invoice',
);
export const freezeInvoiceSuccess = createAction<number>(
  'Invoice freezed successfully',
);
export const freezeInvoiceFailed = createAction('Cannot freeze invoice');

export const freezeMonthInvoiceRequest = createAction<string>(
  'Make request to freeze month invoice',
);
export const freezeMonthInvoiceSuccess = createAction<string>(
  'Month invoice freezed successfully',
);
export const freezeMonthInvoiceFailed = createAction(
  'Cannot freeze month invoice',
);

// Unfreeze Invoice
export const unfreezeInvoiceRequest = createAction<number>(
  'Make request to unfreeze invoice',
);
export const unfreezeInvoiceSuccess = createAction<number>(
  'Invoice unfreezed successfully',
);
export const unfreezeInvoiceFailed = createAction('Cannot unfreeze invoice');

export const unfreezeMonthInvoiceRequest = createAction<string>(
  'Make request to unfreeze month invoice',
);
export const unfreezeMonthInvoiceSuccess = createAction<string>(
  'Month invoice unfreezed successfully',
);
export const unfreezeMonthInvoiceFailed = createAction(
  'Cannot unfreeze month invoice',
);

export const exportInvoiceDetailsRequest = createAction<IExportInvoice>(
  'Make request to export invoice',
);
export const exportInvoiceDetailsSuccess = createAction(
  'Invoice successfully exported',
);
export const exportInvoiceDetailsFailed = createAction(
  'Unable to export invoice',
);
export const exportMonthInvoiceDetailsRequest = createAction<
  IExportMonthInvoice
>('Make request to export month invoice');
export const exportMonthInvoiceDetailsSuccess = createAction(
  'Month Invoice successfully exported',
);
export const exportMonthInvoiceDetailsFailed = createAction(
  'Unable to export month invoice',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Invoices').takeExportActions();

const {
  exportReportFailed: exportMonthReportFailed,
  exportReportRequest: exportMonthReportRequest,
  exportReportSuccess: exportMonthReportSuccess,
} = new ExportActionCreator('Month Invoices').takeExportActions();

export const exportInvoiceRequest = exportReportRequest;
export const exportInvoiceSuccess = exportReportSuccess;
export const exportInvoiceFailed = exportReportFailed;

export const exportMonthInvoiceRequest = exportMonthReportRequest;
export const exportMonthInvoiceFailed = exportMonthReportFailed;
export const exportMonthInvoiceSuccess = exportMonthReportSuccess;
