import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateIssue,
  IDowntimeChartPointData,
  IdsArray,
  IFilter,
  IIssueModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithCustomFunc,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new issue
 */
export const createIssueRequestSaga = function* ({
  payload: { data, func },
}: ISagaAction<PayloadWithCustomFunc<ICreateIssue, () => void>>): SagaIterator {
  try {
    const response: IIssueModel = yield call(Api.Issue.create, data);
    yield put(actions.flushIssueState());
    yield put(actions.createIssueSuccess(response));
    yield call(func);
  } catch (e) {
    yield put(actions.createIssueFailed());
  }
};

/**
 * Get issue list
 */
export const getIssueListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IIssueModel[] = yield call(Api.Issue.list, action.payload);
    yield put(actions.getIssueListSuccess(response));
  } catch (e) {
    yield put(actions.getIssueListFailed());
  }
};

/**
 * Get issue count
 */
export const getIssueCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Issue.count,
      action?.payload,
    );
    yield put(actions.getIssueCountSuccess(response));
  } catch (e) {
    yield put(actions.getIssueCountFailed());
  }
};

/**
 * Delete issues
 */
export const deleteIssueRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Issue.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushIssueState());
  } catch (e) {
    yield put(actions.deleteIssueFailed());
  }
};

/**
 * Bulk update issues
 */
export const updateIssuesRequestSaga = function* ({
  payload: { data, func },
}: ISagaAction<
  PayloadWithCustomFunc<IIssueModel[], () => void>
>): SagaIterator {
  try {
    yield call(Api.Issue.bulkUpdate, data);
    yield put(actions.flushIssueState());
    yield call(func);
  } catch (e) {
    yield put(actions.updateIssuesFailed());
  }
};

/**
 * Get combobox list
 */
export const getIssueComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IIssueModel>[] = yield call(
      Api.Issue.list,
      action.payload,
    );
    yield put(actions.getIssueComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getIssueComboboxListFailed());
  }
};

/**
 * Get downtime chart data
 */
export const getDowntimeChartDataRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IDowntimeChartPointData[] = yield call(
      Api.Issue.getDowntimeChartData,
      action.payload,
    );
    yield put(actions.getDowntimeChartDataSuccess(response));
  } catch (e) {
    yield put(actions.getDowntimeChartDataFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Issue.export,
  actionFailed: actions.exportIssueFailed,
  actionSuccess: actions.exportIssueSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportIssueRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createIssueRequest, saga: createIssueRequestSaga },
  { action: actions.getIssueListRequest, saga: getIssueListRequestSaga },
  { action: actions.getIssueCountRequest, saga: getIssueCountRequestSaga },
  { action: actions.deleteIssueRequest, saga: deleteIssueRequestSaga },
  // bulk updating issues
  { action: actions.updateIssuesRequest, saga: updateIssuesRequestSaga },
  {
    action: actions.getIssueComboboxListRequest,
    saga: getIssueComboboxListRequestSaga,
  },
  {
    action: actions.getDowntimeChartDataRequest,
    saga: getDowntimeChartDataRequestSaga,
  },
  { action: actions.exportIssueRequest, saga: exportIssueRequestSaga },
];
