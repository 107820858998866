import React from 'react';
import { Option } from '@mui/joy';

import { IFilterPanelOption } from 'src/components/FilterPanel';
import { FilterPanelTextField } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelTextField';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { ReportPageContext } from 'src/components/ReportPage/ReportPageContext';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelSelect } from 'src/components/FilterPanel/FilterPanelFilters';
import { FilterPanelSelectOptionLabel } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSelect/FilterPanelSelectOptionLabel';
import { FilterPanelDatePicker } from '../../../components/FilterPanel/FilterPanelFilters/FilterPanelDatePicker';
import { clients } from '../../../modules/constants';
import { FilterPanelShiftsCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelShiftsCombobox';
import { FilterPanelEmployeesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelEmployeesCombobox';

export interface IExpeditorsReportsRequiredFilters {
  activeReportId: string | undefined;
  activeReportTypeId: string | undefined;
}

export interface IExpeditorsReportsFilterPanelFilters {
  siteId?: number;
  shiftId?: number;
  departmentId?: number;
  employeeId?: number;
  date?: string;
  year?: number;
  week?: number;
}

interface IExpeditorsReportsFilterPanelProps {
  onApplyFilters: (
    appliedFilters: IExpeditorsReportsFilterPanelFilters,
  ) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const ExpeditorsReportsFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IExpeditorsReportsFilterPanelProps) => {
  const {
    setActiveReportId,
    setActiveReportTypeId,
    reportTypesList,
    reportsListByReportType,
  } = React.useContext(ReportPageContext);

  const [filters, setFilters] = React.useState<
    IExpeditorsReportsFilterPanelFilters & IExpeditorsReportsRequiredFilters
  >({
    activeReportId: undefined,
    activeReportTypeId: undefined,
  });

  const reportsList = React.useMemo(() => {
    return filters.activeReportTypeId
      ? reportsListByReportType[filters.activeReportTypeId] ?? []
      : [];
  }, [filters.activeReportTypeId, reportsListByReportType]);

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<{
      requiredFilters: IExpeditorsReportsRequiredFilters;
      otherFilters: IExpeditorsReportsFilterPanelFilters;
    }>(
      (obj, filterId) => {
        if (
          filterId === 'activeReportId' ||
          filterId === 'activeReportTypeId'
        ) {
          obj.requiredFilters[filterId] = filters[filterId];
        } else {
          obj.otherFilters[filterId] = filters[filterId];
        }

        return obj;
      },
      {
        requiredFilters: {} as IExpeditorsReportsRequiredFilters,
        otherFilters: {} as IExpeditorsReportsFilterPanelFilters,
      },
    );

    appliedFiltersData.requiredFilters.activeReportId &&
      setActiveReportId(appliedFiltersData.requiredFilters.activeReportId);
    appliedFiltersData.requiredFilters.activeReportTypeId &&
      setActiveReportTypeId(
        appliedFiltersData.requiredFilters.activeReportTypeId,
      );

    onApplyFilters(appliedFiltersData.otherFilters);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'activeReportTypeId':
        return (
          <FilterPanelSelect
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters.activeReportTypeId ?? ''}
            onChange={(_, value) => {
              if (value) {
                setFilters((prev) => ({
                  ...prev,
                  activeReportTypeId: value,
                }));
              }
            }}
          >
            {reportTypesList.map((item) => (
              <Option key={item.reportTypeId} value={item.reportTypeId}>
                <FilterPanelSelectOptionLabel>
                  {item.reportTypeLabel}
                </FilterPanelSelectOptionLabel>
              </Option>
            ))}
          </FilterPanelSelect>
        );
      case 'activeReportId':
        return (
          <FilterPanelSelect
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters.activeReportId ?? ''}
            onChange={(_, value) => {
              if (value) {
                setFilters((prev: any) => ({
                  ...prev,
                  activeReportId: value,
                }));
              }
            }}
          >
            {reportsList.map((item) => (
              <Option key={item.reportId} value={item.reportId}>
                <FilterPanelSelectOptionLabel>
                  {item.reportLabel}
                </FilterPanelSelectOptionLabel>
              </Option>
            ))}
          </FilterPanelSelect>
        );
      case 'year':
      case 'week':
        // case 'month':
        // case 'area':
        // case 'category':
        // case 'chargeType':
        return (
          <FilterPanelTextField
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? ''}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: e.target.value,
              }));
            }}
          />
        );
      case 'siteId':
        return (
          <FilterPanelSitesCombobox
            filterId={filter.id}
            clientKey={clients.expeditors}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'shiftId':
        return (
          <FilterPanelShiftsCombobox
            filterId={filter.id}
            siteId={filters.siteId}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'employeeId':
        return (
          <FilterPanelEmployeesCombobox
            filterId={filter.id}
            siteId={filters.siteId}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'date':
        return (
          <FilterPanelDatePicker
            filterId={filter.id}
            value={filters[filter.id] ?? null}
            onChange={(date) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: date,
              }));
            }}
          />
        );
    }

    return null;
  };

  return (
    <ReportPageFilterPanel
      client={clients.expeditors}
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
