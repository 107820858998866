import React from 'react';
import { chain, get } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';
import {
  AnyObject,
  IPricingSettings,
  PricingSettingsConfig,
} from '../../types';

const getSettingsFetchParameters = (siteId?: number) => {
  const filter = { filter: { where: { siteId: { eq: siteId } } } };
  const queryKey = getQueryKey('/pricing-settings', filter);

  return { filter, queryKey };
};

export const usePricingSettingsData = (siteId?: number) => {
  const { filter, queryKey } = getSettingsFetchParameters(siteId);
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => Api.Pricing.getSettings(filter),
    { enabled: Boolean(siteId), retry: false, cacheTime: 0 },
  );

  const config = React.useMemo<IPricingSettings>(
    () => data ?? ({} as IPricingSettings),
    [data],
  );

  return {
    config,
    isLoading,
    error,
  };
};

export const usePricingSettings = (siteId?: number) => {
  const { config, isLoading } = usePricingSettingsData(siteId);

  const settings = React.useMemo(
    () =>
      chain(config.metatagColumns)
        .reduce<PricingSettingsConfig>((acc, cur) => {
          const mt = get(config, ['settings', cur.name]);

          if (mt) {
            acc[cur.name] = { ...mt };
          } else {
            acc[cur.name] = {
              type: 'string',
              name: cur.name,
              title: cur.name,
              options: [],
            };
          }

          return acc;
        }, {})
        .value(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.metatagColumns, config.settings],
  );

  return {
    pricingSettingsId: config.id,
    isLoading,
    settings,
  };
};

export const usePricingSettingsForm = (siteId?: number) => {
  const { queryKey } = getSettingsFetchParameters(siteId);
  const queryClient = useQueryClient();

  // get data
  const { pricingSettingsId, settings, isLoading } = usePricingSettings(siteId);

  // get mutation functions for sending data to server
  const { mutate: createSettings, isLoading: createLoading } = useMutation(
    (data: AnyObject) => Api.Pricing.createSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
  const { mutate: updateSettings, isLoading: updateLoading } = useMutation(
    (data: AnyObject) => Api.Pricing.updateSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  // submit function
  const sendToServer = (settings: PricingSettingsConfig) => {
    // update Pricing Settings
    if (pricingSettingsId) {
      const data = {
        id: pricingSettingsId,
        settings,
      };

      updateSettings(data);
    }
    // create new Pricing Settings
    else {
      const data = {
        siteId,
        settings,
      };

      createSettings(data);
    }
  };

  const sendLoading = createLoading || updateLoading;

  return { settings, isLoading, sendToServer, sendLoading };
};
