import React from 'react';
import { Box } from '@mui/material';
import exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesColumnOptions } from 'highcharts';
import { highchartsReactComponentProps, plotOptionsSeries } from '../common';

exporting(Highcharts);

interface IColumnChartProps {
  height: string;
  series: SeriesColumnOptions[];
  fullscreenTitle?: string;
  categories?: string[];
  chart?: Highcharts.ChartOptions;
  yAxis?: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions>;
  tooltip?: Highcharts.TooltipOptions;
  plotOptions?: Highcharts.PlotOptions;
  xAxis?: Omit<Highcharts.XAxisOptions, 'categories'>;
}

const ColumnChart = React.forwardRef<Highcharts.Chart, IColumnChartProps>(
  (
    {
      categories,
      series,
      chart,
      yAxis,
      xAxis,
      plotOptions,
      height,
      fullscreenTitle,
    },
    chartRef,
  ) => {
    const options: Highcharts.Options = {
      chart: {
        type: 'spline',
        ...(fullscreenTitle
          ? {
              events: {
                fullscreenOpen: function () {
                  (this as any).update({
                    title: {
                      text: fullscreenTitle,
                      align: 'left',
                    },
                  });
                },
                fullscreenClose: function () {
                  (this as any).update({
                    title: {
                      text: '',
                    },
                  });
                },
              },
            }
          : {}),
        ...chart,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      yAxis: yAxis
        ? yAxis
        : {
            title: {
              enabled: false,
            } as Highcharts.YAxisTitleOptions,
          },
      xAxis: xAxis
        ? xAxis
        : {
            categories,
          },

      credits: {
        enabled: false,
      },
      plotOptions: {
        ...plotOptions,

        series: {
          ...plotOptions?.series,
          ...plotOptionsSeries,
        } as Highcharts.PlotSeriesOptions,
      },
      legend: {
        enabled: true,
      },
      series,

      exporting: {
        enabled: false,
      },
    };

    return (
      <Box sx={{ minWidth: '100%', height }}>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
          containerProps={highchartsReactComponentProps.containerProps}
        />
      </Box>
    );
  },
);

export default ColumnChart;
