import { IUpdateOpenPosition } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { createOpenPositionScheme } from './create-open-position.scheme';
import { PartialSchema } from 'ajv/lib/types/json-schema';

export const updateOpenPositionsScheme: PartialSchema<IUpdateOpenPosition[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createOpenPositionScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createOpenPositionScheme.properties,
    },
  },
};
