import React from 'react';
import { SnackbarProviderProps, useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { removeProcessStatus } from '../../modules/actions';
import { getProcessStatusList } from '../../modules/selectors/processStatus';
import SnackbarContent from './SnackbarContent';
import { SnackbarMessage } from './SnackbarMessage';

export const snackbarProviderProps: SnackbarProviderProps = {
  maxSnack: 5,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  } as const,
  Components: {
    success: SnackbarContent,
    error: SnackbarContent,
  },
};

const AUTO_HIDE_DURATION = 10000;

let displayed: string[] = [];

export const ProcessStatus = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const processStatusList = useSelector(getProcessStatusList);

  const list = React.useMemo(
    () => processStatusList.map((item) => ({ ...item, dismissed: false })),
    [processStatusList],
  );

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    list.forEach(({ message, key, dismissed, variant, title }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(<SnackbarMessage message={message} title={title} />, {
        key,
        variant,
        autoHideDuration: AUTO_HIDE_DURATION,
        action: (key) => (
          <IconButton
            color="inherit"
            onClick={() => closeSnackbar(key)}
            size="large"
          >
            <CloseIcon color="inherit" />
          </IconButton>
        ),
        onExited: () => {
          // remove this snackbar from redux store
          dispatch(removeProcessStatus(key));
          removeDisplayed(key);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [closeSnackbar, enqueueSnackbar, dispatch, list, t]);

  return null;
};
