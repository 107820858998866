import React from 'react';

import { IFilterPanelOption } from 'src/components/FilterPanel';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelDatePicker } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelDatePicker';
import { FilterPanelShiftsCombobox } from '../../../../components/FilterPanel/FilterPanelFilters/FilterPanelShiftsCombobox';
import { FilterPanelTextField } from '../../../../components/FilterPanel/FilterPanelFilters/FilterPanelTextField';
import { FilterPanelDepartmentsCombobox } from '../../../../components/FilterPanel/FilterPanelFilters/FilterPanelDepartmentsCombobox';

export interface IProductionFilterPanelFilters {
  siteId?: number;
  date?: string;
  shiftId?: number;
  departmentId?: number;
  bomId?: number;
  container?: string;
  lineOrDoor?: string;
  comment?: string;
}

interface IProductionFilterPanelProps {
  onApplyFilters: (appliedFilters: IProductionFilterPanelFilters) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const ProductionFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IProductionFilterPanelProps) => {
  const [filters, setFilters] = React.useState<IProductionFilterPanelFilters>(
    {},
  );

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<{
      otherFilters: IProductionFilterPanelFilters;
    }>(
      (obj, filterId) => {
        obj.otherFilters[filterId] = filters[filterId];
        return obj;
      },
      {
        otherFilters: {} as IProductionFilterPanelFilters,
      },
    );

    onApplyFilters(appliedFiltersData.otherFilters);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'siteId':
        return (
          <FilterPanelSitesCombobox
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'shiftId':
        return (
          <FilterPanelShiftsCombobox
            filterId={filter.id}
            siteId={filters.siteId}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'departmentId':
        return (
          <FilterPanelDepartmentsCombobox
            filterId={filter.id}
            siteId={filters.siteId}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'date':
        return (
          <FilterPanelDatePicker
            filterId={filter.id}
            value={filters[filter.id] ?? null}
            onChange={(date) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: date,
              }));
            }}
          />
        );
      case 'container':
      case 'lineOrDoor':
      case 'comment':
        return (
          <FilterPanelTextField
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? ''}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: e.target.value,
              }));
            }}
          />
        );
    }

    return null;
  };

  return (
    <ReportPageFilterPanel
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
