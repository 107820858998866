import React from 'react';
import { Drawer } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { IIncentivePriceCreateRequest } from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { createIncentivePriceScheme } from 'src/modules/schemes';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { Api } from 'src/modules/utils';
import { IncentiveMetaTags } from './components/IncentivePriceMetaTags';

const initialValues = {
  siteId: NaN,
  price: NaN,
  metadata: undefined,
};

interface IIncentivePriceCreateProps {
  isOpen: boolean;
  onCreate: () => void;
  onClose: () => void;
}

export const IncentivePriceCreate: React.FC<IIncentivePriceCreateProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(createIncentivePriceScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IIncentivePriceCreateRequest) => {
      await Api.IncentivePrice.create(data);
      formik.resetForm();
      onCreate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('incentivePrice.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('incentivePrice.site')}
              placeholder={t('incentivePrice.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="price"
              label={t('incentivePrice.price')}
              name="price"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <IncentiveMetaTags formik={formik} />
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
