import React, { useState } from 'react';
import { Modal, Avatar, Box, AvatarProps, AvatarGroup } from '@mui/joy';
import Thumbnail from '../Thumbnail';
import { Typography } from '@mui/joy';
import { getFileExtensionFromFileName } from 'src/modules/utils/helpers/common';
import { FilesPreviewCarousel } from './FilesPreviewCarousel';
import { XCircleSvg } from '../svgIcons';

interface IFilesPreview {
  urls: Array<string>;
  avatarProps?: AvatarProps;
}

export const FilesPreview = ({ avatarProps, urls }: IFilesPreview) => {
  const [open, setOpen] = useState<boolean>(false);

  const onImageClick = () => setOpen(true);
  const onClose = () => setOpen(false);

  if (urls.length === 0) {
    return null;
  }

  if (urls.length === 1) {
    return <Thumbnail {...avatarProps} src={urls[0]} />;
  }

  return (
    <>
      <AvatarGroup
        onClick={onImageClick}
        sx={{
          '--AvatarGroup-gap': '-8px',
          '--Avatar-size': '36px',
          '--Avatar-ringSize': '2px',
          cursor: 'pointer',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        }}
      >
        <Avatar
          slotProps={{
            root: {
              sx: {
                borderRadius: 8,
              },
            },
          }}
          variant="outlined"
        >
          +{urls.length - 1}
        </Avatar>
        <Avatar
          src={urls[0]}
          {...avatarProps}
          sx={{ cursor: 'pointer' }}
          slotProps={{
            root: {
              sx: {
                borderRadius: 8,
                bgcolor: 'colors.background.bg_secondary',
              },
            },
          }}
          variant="outlined"
        >
          <Typography
            level="text_xs"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {getFileExtensionFromFileName(urls[0])}
          </Typography>
        </Avatar>
      </AvatarGroup>

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          onClick={onClose}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            padding: '64px',
          }}
        >
          <Box
            onClick={onClose}
            sx={{ position: 'absolute', right: 24, top: 24, cursor: 'pointer' }}
          >
            <XCircleSvg />
          </Box>

          <FilesPreviewCarousel filesUrls={urls} />
        </Box>
      </Modal>
    </>
  );
};
