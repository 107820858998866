import React from 'react';
import { Box } from '@mui/joy';
import { MainContentLoader } from '../Layout/components/PageTour/MainContentLoader';

interface IMainIndicatorsWidgetItemProps {
  children: React.ReactNode;
  isDataLoading?: boolean;
}

export const MainIndicatorsWidgetItem = ({
  children,
  isDataLoading,
}: IMainIndicatorsWidgetItemProps) => {
  return (
    <Box
      sx={{
        p: 3,
        flexGrow: 1,
        flexBasis: '100%',
        display: 'flex',
        boxShadow: 'xs',
        borderRadius: 'md',
        minHeight: '140px',
        borderWidth: '1px',
        borderStyle: 'solid',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderColor: 'colors.border.border_secondary',
        position: 'relative',
        bgcolor: 'colors.background.bg_primary',

        '&:not(:last-of-type)': {
          mr: 1,
        },
      }}
    >
      {!isDataLoading && children}

      {isDataLoading && <MainContentLoader />}
    </Box>
  );
};
