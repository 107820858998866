import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { IProductionWeeklyCpuDetailedModel } from '../../types/productionCpuReports';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const prefix = 'CardinalHealthWeeklyCpuDetailed';
const actionCreator = new ListWithSummaryActionCreator(prefix);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyCpuDetailedListRequest,
  getListSuccess: getCardinalHealthWeeklyCpuDetailedListSuccess,
  getListFailed: getCardinalHealthWeeklyCpuDetailedListFailed,
} = actionCreator.takeListActions<IProductionWeeklyCpuDetailedModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyCpuDetailedCountRequest,
  getCountSuccess: getCardinalHealthWeeklyCpuDetailedCountSuccess,
  getCountFailed: getCardinalHealthWeeklyCpuDetailedCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyCpuDetailedRequest,
  exportReportSuccess: exportCardinalHealthWeeklyCpuDetailedSuccess,
  exportReportFailed: exportCardinalHealthWeeklyCpuDetailedFailed,
} = new ExportActionCreator(prefix).takeExportActions();
