import { IFilter } from '../../types';

// create uniq key for react-query
export const getQueryKey = (
  url: string,
  filter?: IFilter,
): string[] | string => {
  if (filter) {
    return [url, JSON.stringify(filter)];
  }

  return url;
};
