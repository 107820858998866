import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { ICountResponse, IListWithSummary } from 'src/modules/types';
import {
  useFetchReportTableDataAndCountEffect,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { IHeadCellWithOrderConfig, Order } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { ReportTable } from 'src/components/ReportPage/ReportTable';

const paymentStatisticDefaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

interface IPaymentStatisticProps {
  siteId?: number;
  year?: number;
  week?: number;
}

export const PaymentStatistic = ({
  year,
  week,
  siteId,
}: IPaymentStatisticProps) => {
  const { t } = useTranslation();

  const reportProps = useReportsProps();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<any>
  >(reportProps.paymentStatistic.resourceUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${reportProps.paymentStatistic.resourceUrl}/count`,
    { count: 0 },
  );

  const where = React.useMemo(
    () => ({
      siteId: siteId ? siteId : undefined,
      year: year ? year : undefined,
      week: week ? week : undefined,
    }),
    [siteId, week, year],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        label: t('productions_uph_reports.year'),
        orderConfig: { orderBy: 'year' },
      },
      {
        id: 'week',
        label: t('productions_uph_reports.week'),
        orderConfig: { orderBy: 'week' },
      },
      {
        id: 'totalMarkupCost',
        label: t('productions_uph_reports.total_markup_cost'),
        orderConfig: { orderBy: 'totalMarkupCost' },
      },
      {
        id: 'totalInvoicedAmount',
        label: t('productions_uph_reports.total_invoiced_amount'),
        orderConfig: { orderBy: 'totalInvoicedAmount' },
      },
      {
        id: 'totalInvoicesQuantity',
        label: t('productions_uph_reports.total_invoices_quantity'),
        orderConfig: { orderBy: 'totalInvoicesQuantity' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: paymentStatisticDefaultOrderDetails.order,
    defaultOrderBy: paymentStatisticDefaultOrderDetails.orderBy,
  });

  const handleChangeRowsPerPage = (limit: number) => {
    setLimit(limit);

    setPage(DEFAULT_PAGE);

    fetchData({
      filter: {
        where,
        offset: DEFAULT_PAGE * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handleSort = (orderBy: string, order: Order) => {
    setOrderBy(orderBy);
    setOrder(order);

    // setPage(DEFAULT_PAGE);

    fetchData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);

    fetchData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? paymentStatisticDefaultOrderDetails.order,
    orderBy: orderBy ?? paymentStatisticDefaultOrderDetails.orderBy,
  });

  return (
    <ReportTable
      data={data.items}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey="executivesReports/paymentStatistic"
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
