import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import { Box, Drawer } from '@mui/joy';
import { getEmployeesReview } from 'src/modules/selectors/questionEmployee';
import { IdsArray } from 'src/modules/types';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Typography } from '@mui/joy';
import { ViewReviewsFilePreview } from './ViewReviewsFilePreview';
import { FilesPreviewCarouseImageWithFallback } from 'src/components/FilesPreview/FilesPreviewCarousel/FilesPreviewCarouseImageWithFallback';

interface IViewReviewsProps {
  reviewsIds: IdsArray;
  isOpen: boolean;
  onClose: () => void;
}

export const ViewReviews = ({
  reviewsIds,
  isOpen,
  onClose,
}: IViewReviewsProps) => {
  const { t } = useTranslation();

  const [clickedFile, setClickedFile] = React.useState<string | null>(null);

  const allReviews = useSelector(getEmployeesReview);

  const reviewsToView = React.useMemo(
    () => allReviews.filter((review) => reviewsIds.includes(review.id)),
    [allReviews, reviewsIds],
  );

  const formik = useFormikInUpdateForm({
    initialValues: reviewsToView,
    onSubmit: () => undefined,
  });

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('questionnaire_history.answers')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {reviewsToView.map((review, index) => (
              <DrawerBodySectionContent key={review.id}>
                <DrawerBodySectionTitle>
                  {`${review.employee.firstName} ${review.employee.lastName} (${review.reviewedAt})`}
                </DrawerBodySectionTitle>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 500,
                  }}
                >
                  {review.questionsEmployees.map((answer, i) => {
                    let value = get(
                      formik.values,
                      `${index}.questionsEmployees.${i}.value`,
                    );
                    if (value === true || value === 'true') {
                      value = t('common.yes');
                    }
                    if (value === false || value === 'false') {
                      value = t('common.no');
                    }
                    return (
                      <FormFieldContainer key={answer.id}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box>
                            <Typography fontSize="sm">
                              {answer.questionName}
                            </Typography>
                          </Box>

                          <Box sx={{ py: 0.5 }}>
                            <Typography fontWeight="bold" fontSize="sm">
                              {value}
                            </Typography>
                          </Box>
                        </Box>
                      </FormFieldContainer>
                    );
                  })}
                </Box>

                {formik.values[index]?.signatureUrl && (
                  <FormFieldContainer>
                    <Box sx={{ py: 2 }}>
                      <Box
                        sx={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderRadius: 8,
                          borderColor: 'colors.border.border_primary',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            maxHeight: 100,
                          }}
                          src={formik.values[index].signatureUrl}
                          alt="Signature"
                        />
                      </Box>
                    </Box>
                  </FormFieldContainer>
                )}

                <FormFieldContainer>
                  {formik.values[index]?.attachments?.map((attachment) => (
                    <Box
                      key={attachment.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'colors.background.bg_primary',
                        mt: 2,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'colors.border.border_secondary',
                        borderRadius: 16,
                        overflow: 'hidden',
                        p: 1,
                        cursor: attachment.mimeType.startsWith('image/')
                          ? 'pointer'
                          : 'default',
                      }}
                      onClick={
                        attachment.mimeType.startsWith('image/')
                          ? () => setClickedFile(attachment.url)
                          : undefined
                      }
                    >
                      <Box sx={{ display: 'flex' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {attachment.mimeType.startsWith('image/') ? (
                            <Box
                              component="img"
                              sx={{
                                maxWidth: 400,
                                maxHeight: 400,
                                borderRadius: '12px',
                              }}
                              src={attachment.url}
                              alt={attachment.fileName}
                            />
                          ) : (
                            <FilesPreviewCarouseImageWithFallback
                              src={attachment.url}
                              typographyProps={{
                                level: 'text_sm',
                                textAlign: 'center',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>

      <ViewReviewsFilePreview
        src={clickedFile ?? ''}
        isOpen={Boolean(clickedFile)}
        onClose={() => setClickedFile(null)}
      />
    </>
  );
};
