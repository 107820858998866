import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthDailyUphBySiteAndShift',
);

// get list actions
export const {
  getListRequest: getCardinalHealthDailyUphBySiteAndShiftListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteAndShiftListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteAndShiftListFailed,
} = actionCreator.takeListActions<ICardinalHealthDailyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthDailyUphBySiteAndShiftCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteAndShiftCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteAndShiftCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthDailyUphBySiteAndShiftRequest,
  exportReportSuccess: exportCardinalHealthDailyUphBySiteAndShiftSuccess,
  exportReportFailed: exportCardinalHealthDailyUphBySiteAndShiftFailed,
} = new ExportActionCreator(
  'CardinalHealthDailyUphBySiteAndShift',
).takeExportActions();
