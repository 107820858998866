import { call, put, select } from 'redux-saga/effects';
import { differenceInMinutes } from 'date-fns';

import * as actions from '../../actions';
import Api from '../../utils/Api';
import {
  getAreaMeta,
  getFetchedTimeStamp,
} from '../../selectors/area.selector';
import { getQueryParams } from '../../utils';

import {
  AnyObject,
  IArea,
  IDeleteAreaRequestData,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IUpdateAreaRequestData,
  PayloadWithFilters,
} from '../../types';
import { SagaIterator } from 'redux-saga';

const convertFormDataForApi = (formValues: AnyObject) => {
  const dataForApi = { map: {} };
  for (const areaName in formValues) {
    dataForApi.map[areaName] = [];

    for (const valueList of formValues[areaName]) {
      const areaData = {};
      for (const { areaName, areaValue } of valueList) {
        areaData[areaName] = areaValue;
      }
      dataForApi.map[areaName].push(areaData);
    }
  }

  return dataForApi;
};

function isStaleData(areaFetchedTimeStamp: number): boolean {
  const currentTameStamp = Date.now();
  const dataTimeRemains = differenceInMinutes(
    currentTameStamp,
    areaFetchedTimeStamp,
  );

  return dataTimeRemains > 10;
}

function* getAreaList({ payload: siteId }: ISagaAction<number | null>) {
  if (!siteId) {
    return;
  }
  const areaFetchedTimeStamp: number = yield select(getFetchedTimeStamp);
  const { siteId: fetchedSiteId } = yield select(getAreaMeta);
  const shouldFetchData: boolean = yield call(
    isStaleData,
    areaFetchedTimeStamp,
  );
  if (!shouldFetchData && siteId === fetchedSiteId) {
    return;
  }
  const filter = getQueryParams(window.location.search.slice(1));
  try {
    yield put(actions.toggleAreaLoading(true));
    yield call(getAllowedProperties, { payload: siteId });
    const areaMap: IArea[] = yield call(Api.Area.getAreaList, filter);
    const newFetchedTimeStamp = Date.now();
    yield put(actions.setTimeStamp(newFetchedTimeStamp));
    yield put(actions.setAreaMap(areaMap));
  } catch (e) {
    yield put(actions.toggleAreaLoading(false));
  }
}

function* getAllowedProperties({ payload }: { payload: number }) {
  try {
    const allowedProperties: string[] = yield call(
      Api.Area.getAllowedProperties,
      payload,
    );
    const options = allowedProperties.map((property) => ({
      id: property,
      name: property,
    }));
    yield put(actions.setAllowedPropertiesOptions(options));
  } catch (e) {}
}

function* updateArea({
  payload: {
    data: { values, ...rest },
    filters: { list },
  },
}: ISagaAction<PayloadWithFilters<AnyObject>>) {
  const sendData: IUpdateAreaRequestData = yield call(
    convertFormDataForApi,
    values,
  );
  try {
    yield put(actions.toggleAreaLoading(true));

    yield call(Api.Area.updateArea, { ...sendData, ...rest });

    yield put(actions.getAreaRawListRequest({ filter: list }));

    // make stale fetched Area
    yield put(actions.setTimeStamp(0));
    yield put(actions.toggleAreaLoading(false));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.toggleAreaLoading(false));
  }
}

function* createArea({
  payload: {
    data: { values, siteId },
    filters: { list },
  },
}: ISagaAction<PayloadWithFilters<AnyObject>>) {
  const sendData: IArea = yield call(convertFormDataForApi, values);

  try {
    yield put(actions.toggleAreaLoading(true));

    yield call(Api.Area.createArea, { ...sendData, siteId });
    yield put(actions.getAreaRawListRequest({ filter: list }));

    yield put(actions.toggleAreaLoading(false));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.toggleAreaLoading(false));
  }
}

function* deleteArea({
  payload: {
    data,
    filters: { list },
  },
}: ISagaAction<PayloadWithFilters<IDeleteAreaRequestData>>) {
  try {
    yield put(actions.toggleAreaLoading(true));

    yield call(Api.Area.deleteArea, data);

    yield put(actions.getAreaRawListRequest({ filter: list }));

    yield put(actions.toggleAreaLoading(false));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.toggleAreaLoading(false));
  }
}

export const getAreaRawListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Array<IArea> = yield call(
      Api.Area.getAreaList,
      action.payload,
    );
    yield put(actions.getAreaRawListSuccess(response));
  } catch (e) {
    yield put(actions.getAreaRawListFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getAreaList,
    saga: getAreaList,
  },
  {
    action: actions.getAllowedProperties,
    saga: getAllowedProperties,
  },
  {
    action: actions.updateArea,
    saga: updateArea,
  },
  {
    action: actions.createArea,
    saga: createArea,
  },
  {
    action: actions.deleteArea,
    saga: deleteArea,
  },
  {
    action: actions.getAreaRawListRequest,
    saga: getAreaRawListRequestSaga,
  },
];
