import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthWeeklyUphBySiteSkuAndEmployee',
);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListFailed,
} = actionCreator.takeListActions<ICardinalHealthWeeklyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyUphBySiteSkuAndEmployeeRequest,
  exportReportSuccess: exportCardinalHealthWeeklyUphBySiteSkuAndEmployeeSuccess,
  exportReportFailed: exportCardinalHealthWeeklyUphBySiteSkuAndEmployeeFailed,
} = new ExportActionCreator(
  'CardinalHealthWeeklyUphBySiteSkuAndEmployee',
).takeExportActions();
