import React, { useEffect, useMemo } from 'react';
import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { shallowEqual, useSelector } from 'react-redux';
import { useFetchSitesCombobox } from '../../../../modules/utils';
import { getSitesComboboxList } from '../../../../modules/selectors/site';
import { map, get } from 'lodash';
import { ClientKey } from '../../../../modules/constants';

interface ISitesComboboxProps extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  useReports?: boolean;
  fieldAsId?: 'id' | 'name' | 'uuid';
  clientKey?: ClientKey;
}

const SitesComboBox: React.FC<ISitesComboboxProps> = ({
  useReports,
  fieldAsId = 'id',
  clientKey,
  ...props
}) => {
  const fetchSitesCombobox = useFetchSitesCombobox(clientKey);

  useEffect(() => {
    fetchSitesCombobox();
  }, [fetchSitesCombobox]);

  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const options = (useMemo(
    () =>
      map(sites, (site) => ({
        id: site[useReports ? 'name' : fieldAsId],
        name: `${site.name} (${get(site, 'client.name')})`,
      })),
    [fieldAsId, sites, useReports],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default SitesComboBox;
