import { createReducer } from 'redux-act';
import { IUIState } from 'src/modules/types/ui';
import * as actions from '../../actions';

const defaultState = {
  is_data_requested: false,
};

const setIsDataRequested = (state: IUIState, payload: boolean) => ({
  ...state,
  is_data_requested: payload,
});

export const requestPreloader = createReducer({}, defaultState).on(
  actions.setIsDataRequested,
  setIsDataRequested,
);

export default requestPreloader;
