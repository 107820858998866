import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { IRecruitingDashboardDetailsRequest } from '../../types';
import { getRecruitingDashboardDetailsList } from '../../selectors/recruitingDashboardDetails';
import { getRecruitingDashboardDetailsListRequest } from '../../actions';
import { useReportDefaultFilter } from './common/reports';
import React from 'react';

/**
 * A custom hook to fetch recruiting dashboard details data list from store if it exists otherwise to make a request to fetch needed that
 * information from the server
 */
export const useFetchRecruitingDashboardDetails = (
  data: IRecruitingDashboardDetailsRequest,
) => {
  const dispatcher = useDispatch();
  // fetch turnover added list from store
  const list = useSelector(getRecruitingDashboardDetailsList, shallowEqual);
  return () => {
    if (isEmpty(list)) {
      dispatcher(getRecruitingDashboardDetailsListRequest(data));
    }
  };
};

export const useRecruitingDashboardDetailsDefaultFilters = () => {
  const reportDefaultFilter = useReportDefaultFilter();

  return React.useMemo(
    () => ({
      order: [`openPositionId desc`],
      ...reportDefaultFilter,
    }),
    [reportDefaultFilter],
  );
};
