import { filter, isEmpty, map, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateIssueType } from '../types';
import { getGloballySelectedSites } from './site';

export const getIssueTypeRefreshKey = (state: IStoreState) =>
  state.issueType.refreshKey;

export const getIssueTypeData = (state: IStoreState) => state.issueType;

/**
 * Get server error
 * @param issueType - State issueType
 */
export const getServerError = ({ issueType }: IStoreState) => issueType.error;

/**
 * Get issueType list
 * @param issueType - State issueType
 */
export const getIssueTypeList = ({ issueType }: IStoreState) => issueType.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param issueType - State issueType
 */
export const getIssueTypesTable = createSelector(
  getIssueTypeList,
  (issueTypes) => map(filter(issueTypes, (s) => !isEmpty(s.site))),
);

/**
 * Get issueTypes by array of ids
 * @param issueType - State issueType
 */
export const getIssueTypesByIds = createSelector(
  getIssueTypeList,
  (issueTypes) => (ids: IdsArray) => {
    const initial: IUpdateIssueType[] = [];
    return reduce(
      issueTypes,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          const issueTypeData = omit(cur, ['site']);
          acc.push(issueTypeData);
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get issueType count
 * @param issueType - State issueType
 */
export const getIssueTypeCount = ({ issueType }: IStoreState) =>
  issueType.count;

/**
 * Get combobox list
 */
export const getIssueTypesComboboxList = ({ issueType }: IStoreState) =>
  map(issueType.comboboxList, (issueTypeItem) => ({
    id: issueTypeItem.id,
    name: issueTypeItem.name,
  }));

/**
 * Get combobox list with site names
 */
export const getIssueTypesWithSitesComboboxList = createSelector(
  getIssueTypeData,
  getGloballySelectedSites,
  (issueType, globallySelectedSites) => {
    const filteredIssueTypes = issueType.comboboxList.filter(
      (issueType) =>
        issueType.site?.id && globallySelectedSites.includes(issueType.site.id),
    );

    return filteredIssueTypes.map((issueType) => ({
      id: issueType.id!,
      name: `${issueType.name} - ${issueType.site?.name}`,
    }));
  },
);

export const getIsIssueTypeDataLoading = createSelector(
  getIssueTypeData,
  (data) =>
    data.isIssueTypeCountInProgress ||
    data.isIssueTypeListInProgress ||
    data.isManageIssueTypeOperationInProgress,
);
