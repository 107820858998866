import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getBomsByIds,
  getBomsComboboxList,
} from '../../selectors/bom.selector';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import { getBomComboboxListRequest, getBomListRequest } from '../../actions';
import React from 'react';
import { defaultOrderDetails } from '../helpers/filter';
import { useSiteRelationInclusion } from '.';

export const bomInclusions = [
  {
    relation: 'site',
  },
];

/**
 * A custom hook to fetch boms from store if they exist otherwise to make a request to fetch needed boms from
 * the server
 */
export const useFetchBomsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch boms list from store
  const boms = useSelector(
    (state) => getBomsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(boms)) {
      dispatcher(
        getBomListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: bomInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch bom combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchBomsCombobox = () => {
  const siteInclusion = useSiteRelationInclusion();
  const dispatcher = useDispatch();
  // fetch combobox list from store
  const combobox = useSelector(getBomsComboboxList, shallowEqual);

  const dispatchGetComboboxListRequest = React.useCallback(() => {
    const bomComboboxFilter = {
      filter: {
        fields: {
          id: true,
          name: true,
          siteId: true,
        },
        include: [siteInclusion],
      },
    };
    dispatcher(getBomComboboxListRequest(bomComboboxFilter));
  }, [siteInclusion, dispatcher]);

  React.useEffect(() => {
    dispatchGetComboboxListRequest();
  }, [dispatchGetComboboxListRequest]);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetComboboxListRequest();
    }
  };
};

export const useBomsDefaultFilters = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteRelationInclusion],
    }),
    [siteRelationInclusion],
  );
};
