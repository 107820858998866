import React from 'react';
import { useTranslation } from 'react-i18next';
import { MainIndicatorsWidget } from 'src/components/MainIndicatorsWidget';
import { MainIndicatorsWidgetItem } from 'src/components/MainIndicatorsWidget/MainIndicatorsWidgetItem';
import { MainIndicatorsWidgetItemText } from 'src/components/MainIndicatorsWidget/MainIndicatorsWidgetItemText';
import { MainIndicatorsWidgetItemValue } from 'src/components/MainIndicatorsWidget/MainIndicatorsWidgetItemValue';
import { IHRChangesMainIndicatorsData } from 'src/modules/types/dashboard';
import { fetchHRChangesMainIndicators } from 'src/modules/utils/Api/hrChanges';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';

export const HRChangesMainIndicatorsWidget = () => {
  const { t } = useTranslation();

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRChangesMainIndicators,
    {
      initialData: {} as IHRChangesMainIndicatorsData,
    },
  );

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <MainIndicatorsWidget>
      <MainIndicatorsWidgetItem isDataLoading={isLoading}>
        <MainIndicatorsWidgetItemText>
          {t('employees.active_associates')}
        </MainIndicatorsWidgetItemText>
        <MainIndicatorsWidgetItemValue value={data.activeAssociatesNumber} />
      </MainIndicatorsWidgetItem>

      <MainIndicatorsWidgetItem isDataLoading={isLoading}>
        <MainIndicatorsWidgetItemText>
          {t('employees.lost_associates_last_n_days', { n: 7 })}
        </MainIndicatorsWidgetItemText>
        <MainIndicatorsWidgetItemValue value={data.lostAssociatesLast7days} />
      </MainIndicatorsWidgetItem>

      <MainIndicatorsWidgetItem isDataLoading={isLoading}>
        <MainIndicatorsWidgetItemText>
          {`${t('common.lost')} / ${t('common.active')}`}
        </MainIndicatorsWidgetItemText>
        <MainIndicatorsWidgetItemValue
          value={data.lostAssociatesLast7daysPercentage}
          units="%"
        />
      </MainIndicatorsWidgetItem>

      <MainIndicatorsWidgetItem isDataLoading={isLoading}>
        <MainIndicatorsWidgetItemText>
          {t('common.active_sites')}
        </MainIndicatorsWidgetItemText>
        <MainIndicatorsWidgetItemValue value={data.activeSitesNumber} />
      </MainIndicatorsWidgetItem>
    </MainIndicatorsWidget>
  );
};
