import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { INotificationModel } from '../../../../modules/types';
import { changeNotificationStatusRequest } from '../../../../modules/actions';
import {
  parse,
  format,
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
} from '../../../../modules/utils/dateWrapper';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { NotificationCardButton } from './NotificationCardButton';

type INotificationCardProps = INotificationModel & {
  onCardClick: () => void;
  onRemove: (id: number) => void;
};

export const NotificationCard: React.FC<INotificationCardProps> = ({
  id,
  message,
  action,
  object,
  subject,
  createdAt: rawCreatedAt,
  onCardClick,
  read,
  onRemove,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const theme = useTheme();

  const handleCardClick = () => {
    onCardClick();
    dispatch(
      changeNotificationStatusRequest({
        read: true,
        ids: [id],
      }),
    );
  };

  const changeReadStatus = () => {
    dispatch(
      changeNotificationStatusRequest({
        read: !read,
        ids: [id],
      }),
    );
  };

  const handleRemove = () => {
    onRemove(id);
  };

  const createdAt = composeDate(
    rawCreatedAt,
    parse(DATETIME_TIMEZONE),
    format(DATETIME_FORMAT_TO_SHOW),
  );

  const generatePathName = () => {
    if (action === 'registration') {
      return `/${subject}`;
    }
    if (action === 'staysLoggingIn') {
      return '/supervisor/view';
    }
    if (
      action === 'newTaskAssigned' ||
      action === 'taskUpdated' ||
      action === 'taskDeleted' ||
      action === 'taskRecurrenceDeleted'
    ) {
      return '/checklist';
    }

    return null;
  };

  const generateAdditionalData = () => {
    if (action === 'taskUpdated' && object) {
      return `\n (new title: "${object}")`;
    }

    if (action === 'taskRecurrenceDeleted' && object) {
      return `\n (${object})`;
    }

    return null;
  };

  const pathname = generatePathName();

  let borderColor: undefined | string;
  if (object === 'success') {
    borderColor = theme.palette.success[500];
  }
  if (object === 'failed') {
    borderColor = theme.palette.error[500];
  }

  const content = (
    <Typography level="text_sm">
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
      {generateAdditionalData()}
    </Typography>
  );

  return (
    <Card
      sx={(theme: Theme) => ({
        margin: theme.spacing(1, 0),
        borderColor,
      })}
    >
      <CardContent sx={{ paddingTop: 0 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Checkbox
            checked={!read}
            onChange={changeReadStatus}
            slotProps={{
              label: {
                sx: {
                  px: 0.5,
                  py: 0.2,
                  fontSize: '0.75rem',
                },
              },
            }}
            variant="soft"
            disableIcon
            label={t(
              read
                ? 'notifications.mark_as_unread'
                : 'notifications.mark_as_read',
            )}
          />

          <NotificationCardButton onClick={handleRemove}>
            {t('common.remove')}
          </NotificationCardButton>
        </Box>
        {pathname ? (
          <Link to={{ pathname }} onClick={handleCardClick}>
            {content}
          </Link>
        ) : (
          <Box>{content}</Box>
        )}

        <Typography
          level="text_sm"
          sx={{
            color: theme.palette.gray[400],
            marginLeft: 'auto',
          }}
        >
          {createdAt}
        </Typography>
      </CardContent>
    </Card>
  );
};
