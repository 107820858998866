import React, { useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import {
  useFetchOpenPositionsByIds,
  useQueryParams,
} from '../../../modules/utils/hooks';
import { IdsArray, IStoreState } from '../../../modules/types';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../components/Accordion';
import { getOpenPositionViewList } from '../../../modules/selectors/openPosition';
import OpenPositionView from './OpenPositionView';
import { ContentLayout } from '../../../components/Layout/ContentLayout';

const OpenPositionViewList = () => {
  const { t } = useTranslation();

  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchOpenPositions = useFetchOpenPositionsByIds(ids);

  // fetch open positions list from store
  const openPositions = useSelector(
    (state) => getOpenPositionViewList(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch openPositions from the server if we don't have them in the store
  useEffect(() => {
    fetchOpenPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentLayout>
      {map(openPositions, (openPosition, index) => (
        <Accordion
          key={index}
          defaultExpanded={index === 0}
          cardTitle={t('open_position.details_title', { id: openPosition.id })}
        >
          <OpenPositionView openPosition={openPosition} />
        </Accordion>
      ))}
    </ContentLayout>
  );
};

export default OpenPositionViewList;
