import React from 'react';
import { Sheet } from '@mui/joy';
import { AnyObject } from 'src/modules/types';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { FormikButtonGroup } from 'src/components/Formik/FormikButtonGroup';
import { ButtonGroupsContainer } from 'src/components/_ui-kit/ButtonGroup';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { FormFiledButtonsGroupItemContainer } from 'src/components/Form/FormFiledButtonsGroupItemContainer';
import { useEmployeeFormTourConfig } from 'src/config/tours/employees';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { useSelector } from 'react-redux';
import { getTerminationReasonComboboxListBySiteId } from 'src/modules/selectors/terminationReason';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import { parse } from 'date-fns';
import { DATE_FORMAT } from 'src/modules/utils/dateWrapper';
import FormikInputPhoneNumber from 'src/components/Formik/FormikInputPhoneNumber';

interface IUpdateEmployeeOptionalFieldsProps {
  formik: AnyObject;
  index: number;
}

export const UpdateEmployeeOptionalFields = ({
  formik,
  index,
}: IUpdateEmployeeOptionalFieldsProps) => {
  const { t } = useTranslation();

  const employeeFormTourConfig = useEmployeeFormTourConfig();

  const terminationReasonsBySiteId = useSelector(
    getTerminationReasonComboboxListBySiteId,
  );

  const parseDate = (date: string) => parse(date, DATE_FORMAT, new Date());

  const generateFullId = (id: string) => {
    if (index !== undefined) {
      return generateFullIdInMultiEntitiesForm(id, index);
    }

    return id;
  };

  return (
    <Sheet sx={{ bgcolor: 'inherit' }}>
      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={generateFullId('emergencyContact')}
          name={generateFullId('emergencyContact')}
          label={t('employees.emergency_contact')}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.emergencyContact.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikInputPhoneNumber
          id={generateFullId('phonenumber')}
          name={generateFullId('phonenumber')}
          label={t('employees.phone')}
          formik={formik}
        />
      </FormFieldContainer>
      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={generateFullId('email')}
          name={generateFullId('email')}
          label={t('employees.email')}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={generateFullId('wmsLogin')}
          name={generateFullId('wmsLogin')}
          label={t('employees.wms_login')}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.wmsLogin.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={generateFullId('taktId')}
          name={generateFullId('taktId')}
          label={t('employees.taktId')}
          formik={formik}
          errorMode="onFieldChange"
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.taktId.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikDatepicker
          fullWidth
          label={t('employees.separation_date')}
          id={generateFullId('separationdate')}
          formik={formik as any}
          errorMode="onFieldChange"
          required={!Boolean(formik.values[index].active)}
          data-tour={employeeFormTourConfig.tour.separationDate.target}
          parseDate={parseDate}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          id={generateFullId('terminationReasonId')}
          placeholder={t('employees.termination_reason')}
          options={terminationReasonsBySiteId(formik.values[index].siteId)}
          formik={formik}
          errorMode="onFieldChange"
          data-tour={employeeFormTourConfig.tour.terminationReason.target}
          required={!Boolean(formik.values[index].active)}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id={generateFullId('comments')}
          name={generateFullId('comments')}
          label={t('employees.comments')}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.comments.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ButtonGroupsContainer>
          <FormFiledButtonsGroupItemContainer>
            <FormikButtonGroup
              id={generateFullId('active')}
              label={t('employees.active')}
              formik={formik}
              config={[
                { label: t('common.active'), value: true },
                { label: t('common.unactive'), value: false },
              ]}
            />
          </FormFiledButtonsGroupItemContainer>
          <FormFiledButtonsGroupItemContainer>
            <FormikButtonGroup
              id={generateFullId('wotc')}
              label={t('employees.wotc')}
              formik={formik}
              config={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
            />
          </FormFiledButtonsGroupItemContainer>
          <FormFiledButtonsGroupItemContainer>
            <FormikButtonGroup
              id={generateFullId('canRehire')}
              label={t('employees.can_rehire')}
              formik={formik}
              config={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
            />
          </FormFiledButtonsGroupItemContainer>
        </ButtonGroupsContainer>
      </FormFieldContainer>
    </Sheet>
  );
};
