import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

import { useValidate } from 'src/modules/utils';
import { ICreateChecklistTaskData } from 'src/modules/types';
import { SubmitButton } from 'src/components/Form/SubmitButton';
import { createChecklistTaskRequest } from 'src/modules/actions';
import { createChecklistTaskScheme } from 'src/modules/schemes/checklist';
import { FormActionsContainer } from 'src/components/Form/FormActionsContainer';
import { useTaskFormFields } from 'src/modules/utils/hooks/checklist';
import { TaskFormTour } from '../tours/TaskFormTour';
import { Box } from '@mui/material';

interface ICreateTaskProps {
  taskData: ICreateChecklistTaskData;
  onClose: () => void;
  onSubmit: (data: ICreateChecklistTaskData) => void;
}

export const CreateTask: React.FC<ICreateTaskProps> = ({
  taskData,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validate = useValidate(createChecklistTaskScheme);

  const formik = useFormik({
    validate,
    enableReinitialize: true,
    initialValues: taskData,
    onSubmit: (data) => {
      onSubmit(data);

      dispatch(
        createChecklistTaskRequest({
          ...data,
          recurrence: data.recurrence ?? undefined,
        }),
      );
    },
  });

  const fieldsForCreateTaskForm = useTaskFormFields(formik);

  return (
    <Box
      component="form"
      noValidate
      sx={{ maxWidth: 450, padding: 2 }}
      onSubmit={formik.handleSubmit}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 0.5,
        }}
      >
        <TaskFormTour />
        <IconButton onClick={onClose} size="large">
          <Close />
        </IconButton>
      </Box>

      {fieldsForCreateTaskForm}

      <FormActionsContainer>
        <SubmitButton>{t('common.create')}</SubmitButton>
      </FormActionsContainer>
    </Box>
  );
};
