import { createReducer } from 'redux-act';
import findIndex from 'lodash/findIndex';
import * as actions from '../actions';
import {
  ICountResponse,
  IdsArray,
  IEmployeeSkillAttachmentModel,
  IEmployeeSkillModel,
  IEmployeeSkillState,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IEmployeeSkillState = {
  refreshKey: 0,
  id: NaN,
  skillId: NaN,
  employeeId: NaN,
  level: '',
  list: [],
  comboboxList: [],
  count: 0,
  is_list_loading: false,
  is_count_loading: false,
  is_combobox_loading: false,
};

/**
 * Flush actions
 */
const flushEmployeeSkillState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

const createEmployeeSkillAttachmentRequest = (state: IEmployeeSkillState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createEmployeeSkillAttachmentSuccess = (
  state: IEmployeeSkillState,
  payload: IEmployeeSkillAttachmentModel,
) => {
  const list = [...state.list];
  const index = findIndex(list, (i) => i.id === payload.employeeSkillId);

  if (index > -1) {
    list[index].attachments = list[index].attachments ?? [];
    list[index].attachments?.push(payload);
  }

  return {
    ...state,
    list,
    error: false,
    is_data_requested: false,
  };
};

const createEmployeeSkillAttachmentFailed = (
  state: IEmployeeSkillState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * delete attachment
 */
const deleteEmployeeSkillAttachmentRequest = (state: IEmployeeSkillState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteEmployeeSkillAttachmentSuccess = (
  state: IEmployeeSkillState,
  payload: IdsArray,
) => {
  const list = [...state.list];

  for (const i in list) {
    for (const j in list[i].attachments) {
      if (payload.map((p) => +p).includes(+list[i].attachments![j].id)) {
        delete list[+i].attachments![+j];
      }
    }
  }

  return {
    ...state,
    list,
    error: false,
    is_data_requested: false,
  };
};

const deleteEmployeeSkillAttachmentFailed = (
  state: IEmployeeSkillState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getEmployeeSkillListRequest = (state: IEmployeeSkillState) => ({
  ...state,
  error: false,
  is_list_loading: true,
});

const getEmployeeSkillListSuccess = (
  state: IEmployeeSkillState,
  payload: IEmployeeSkillModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_list_loading: false,
  is_data_requested: false,
});

const getEmployeeSkillListFailed = (
  state: IEmployeeSkillState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_list_loading: false,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getEmployeeSkillCountRequest = (state: IEmployeeSkillState) => ({
  ...state,
  error: false,
  is_count_loading: true,
});

const getEmployeeSkillCountSuccess = (
  state: IEmployeeSkillState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_count_loading: false,
  is_data_requested: false,
});

const getEmployeeSkillCountFailed = (
  state: IEmployeeSkillState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_count_loading: false,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateEmployeeSkillsRequest = (state: IEmployeeSkillState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateEmployeeSkillsFailed = (
  state: IEmployeeSkillState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getEmployeeSkillComboboxListRequest = (state: IEmployeeSkillState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_combobox_loading: true,
});

const getEmployeeSkillComboboxListSuccess = (
  state: IEmployeeSkillState,
  payload: Partial<IEmployeeSkillModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
  is_combobox_loading: false,
});

const getEmployeeSkillComboboxListFailed = (
  state: IEmployeeSkillState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  is_combobox_loading: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IEmployeeSkillState>();

/**
 * EmployeeSkill reducer
 */
export const employeeSkill = createReducer<IEmployeeSkillState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushEmployeeSkillState, flushEmployeeSkillState)
  // get list actions
  .on(actions.getEmployeeSkillListRequest, getEmployeeSkillListRequest)
  .on(actions.getEmployeeSkillListSuccess, getEmployeeSkillListSuccess)
  .on(actions.getEmployeeSkillListFailed, getEmployeeSkillListFailed)
  // get count actions
  .on(actions.getEmployeeSkillCountRequest, getEmployeeSkillCountRequest)
  .on(actions.getEmployeeSkillCountSuccess, getEmployeeSkillCountSuccess)
  .on(actions.getEmployeeSkillCountFailed, getEmployeeSkillCountFailed)
  // deletion actions
  // updating actions
  .on(actions.updateEmployeeSkillsRequest, updateEmployeeSkillsRequest)
  .on(actions.updateEmployeeSkillsFailed, updateEmployeeSkillsFailed)
  // get combobox list actions
  .on(
    actions.getEmployeeSkillComboboxListRequest,
    getEmployeeSkillComboboxListRequest,
  )
  .on(
    actions.getEmployeeSkillComboboxListSuccess,
    getEmployeeSkillComboboxListSuccess,
  )
  .on(
    actions.getEmployeeSkillComboboxListFailed,
    getEmployeeSkillComboboxListFailed,
  )
  // export actions
  .on(actions.exportEmployeeSkillRequest, exportRequest)
  .on(actions.exportEmployeeSkillSuccess, exportSuccess)
  .on(actions.exportEmployeeSkillFailed, exportFailed)
  // create attachments
  .on(
    actions.createEmployeeSkillAttachmentRequest,
    createEmployeeSkillAttachmentRequest,
  )
  .on(
    actions.createEmployeeSkillAttachmentSuccess,
    createEmployeeSkillAttachmentSuccess,
  )
  .on(
    actions.createEmployeeSkillAttachmentFailed,
    createEmployeeSkillAttachmentFailed,
  )
  // delete attachments
  .on(
    actions.deleteEmployeeSkillAttachmentRequest,
    deleteEmployeeSkillAttachmentRequest,
  )
  .on(
    actions.deleteEmployeeSkillAttachmentSuccess,
    deleteEmployeeSkillAttachmentSuccess,
  )
  .on(
    actions.deleteEmployeeSkillAttachmentFailed,
    deleteEmployeeSkillAttachmentFailed,
  );

export default employeeSkill;
