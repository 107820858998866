import React from 'react';
import { Sheet } from '@mui/joy';

interface IChartContainer {
  children: React.ReactNode;
  fullHeigh?: boolean;
}

export const ChartContainer = ({
  children,
  fullHeigh = true,
}: React.PropsWithChildren<IChartContainer>) => {
  return (
    <Sheet
      sx={{
        boxShadow: 'xs',
        borderRadius: 'md',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: fullHeigh ? '100%' : 'auto',
        bgcolor: 'colors.foreground.fg_white',
      }}
    >
      {children}
    </Sheet>
  );
};
