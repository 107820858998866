import React from 'react';
import { IFilterData } from 'src/modules/types';
import { ICommonFilterProps } from '../../types';
import { TableContext } from '../../../../../../TableContext';
import { useSitesComboBoxOptions } from 'src/modules/selectors/site';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';

interface ISitesComboBoxFilterProps
  extends Omit<IComboboxProps, 'value' | 'options' | 'name'>,
    ICommonFilterProps {
  requestFilter?: IFilterData;
}
export const SitesComboBoxFilter: React.FC<ISitesComboBoxFilterProps> = ({
  value,
  name,
  requestFilter,
  onChange,
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const sitesOptions = useSitesComboBoxOptions({
    requestFilter,
  });

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue =
    sitesOptions.find((option) => option.id === Number(valueId)) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);

    if (onChange) {
      onChange(e, value, 'selectOption');
    }
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={sitesOptions}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
