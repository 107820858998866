import React from 'react';
import { Box, Typography } from '@mui/joy';

interface IChartsDashboardHeader {
  children: React.ReactNode;
}

export const ChartsDashboardHeader = ({
  children,
}: React.PropsWithChildren<IChartsDashboardHeader>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        pb: 1,
      }}
    >
      <Typography level="display_sm" textColor="colors.text.text_primary.main">
        {children}
      </Typography>
    </Box>
  );
};
