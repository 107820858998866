import { createReducer } from 'redux-act';
import * as actions from '../actions';
import { IArea, IAreaState } from '../types';

//Action's default state
const actionDefaultState: IAreaState = {
  list: {
    map: [],
    fetchedTimeStamp: 0,
  },
  rawList: [],
  allowedProperties: [],
  isLoading: false,
};

const toggleLoading = (state: IAreaState, payload: boolean): IAreaState => ({
  ...state,
  isLoading: payload,
});

const setActionList = (state: IAreaState, payload: IArea[]): IAreaState => ({
  ...state,
  list: {
    ...state.list,
    map: payload,
  },
  isLoading: false,
});

const setAllowedProperties = (
  state: IAreaState,
  payload: { id: string; name: string }[],
) => ({
  ...state,
  allowedProperties: payload,
});

const getAreaRawListRequest = (state: IAreaState): IAreaState => ({
  ...state,
  isLoading: true,
});
const getAreaRawListSuccess = (
  state: IAreaState,
  payload: Array<IArea>,
): IAreaState => ({
  ...state,
  rawList: payload,
  isLoading: false,
});
const getAreaRawListFailed = (state: IAreaState): IAreaState => ({
  ...state,
  isLoading: false,
});

export const area = createReducer<IAreaState>({}, actionDefaultState)
  .on(actions.toggleAreaLoading, toggleLoading)
  .on(actions.setAreaMap, setActionList)
  .on(actions.setAllowedPropertiesOptions, setAllowedProperties)

  .on(actions.getAreaRawListRequest, getAreaRawListRequest)
  .on(actions.getAreaRawListSuccess, getAreaRawListSuccess)
  .on(actions.getAreaRawListFailed, getAreaRawListFailed);
