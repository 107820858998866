import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SparkChart from '../../components/Charts/SparkLine';
import { MainContentLoader } from '../../components/Layout/components/PageTour/MainContentLoader';

import { AnyObject, ICountResponse } from '../../modules/types';
import {
  formatAsCurrency,
  formatAsPercent,
} from '../../modules/utils/helpers/formatters';
import { ClientKey } from '../../modules/constants';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import { paths } from '../../config';
import { IWeeklyGrossMargin } from '../../modules/types/gross-margin';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import { ReportTable } from '../ReportPage/ReportTable';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  clientKey: ClientKey;
  tableName?: string;
  name?: string;
  siteId?: number;
  weekNumber?: number;
}

const WeeklyGrossMarginReport: React.FC<IProps> = ({
  clientKey,
  name,
  siteId,
  weekNumber,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'asc' as const,
    orderBy: 'name',
  };

  const where = React.useMemo(
    () => ({
      name,
      siteId,
      weekNumber,
      clientKey,
    }),
    [name, clientKey, siteId, weekNumber],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IWeeklyGrossMargin[]
  >(paths.WEEKLY_GROSS_MARGIN, []);
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${paths.WEEKLY_GROSS_MARGIN}/count`,
    { count: 0 },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'weekNumber',
        orderConfig: { orderBy: 'weekNumber' },
        label: t('charts.week_number'),
      },
      {
        id: 'revenue',
        orderConfig: { orderBy: 'revenue' },
        label: t('reports.headers.revenue'),
      },
      {
        id: 'totalCost',
        orderConfig: { orderBy: 'totalCost' },
        label: t('reports.headers.total_cost'),
      },
      {
        id: 'grossMarginValue',
        orderConfig: { orderBy: 'grossMarginValue' },
        label: t('reports.headers.gross_margin_value'),
      },
      {
        id: 'grossMarginPercent',
        orderConfig: { orderBy: 'grossMarginPercent' },
        label: t('reports.headers.gross_margin_percent'),
      },
      {
        id: 'grouped_week',
        orderConfig: { orderBy: 'grouped_week' },
        label: t('reports.headers.gross_margin'),
      },
      {
        id: 'week_by_week',
        orderConfig: { orderBy: 'week_by_week' },
        label: t('reports.headers.week_by_week'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        (data as any).summary
          ? (data as any).summary.reduce((acc: any, item: any) => {
              acc[item.title] = t(item.title);
              return acc;
            }, {})
          : undefined,
      [data, t],
    ),
  });

  if (isDataLoading) {
    return (
      <Box
        sx={{
          height: 500,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MainContentLoader />
      </Box>
    );
  }

  const computedItems = data.map(
    ({
      revenue,
      totalCost,
      grossMarginValue,
      grossMarginPercent,
      grouped_week,
      grouped_percent,
      week_by_week,
      ...rest
    }) => ({
      ...rest,
      revenue: formatAsCurrency(revenue),
      totalCost: formatAsCurrency(totalCost),
      grossMarginValue: formatAsCurrency(grossMarginValue),
      grossMarginPercent: formatAsPercent(grossMarginPercent),
      grouped_week: (
        <SparkChart
          weeks={grouped_week}
          percents={grouped_percent}
          type="area"
        />
      ),
      week_by_week: (
        <SparkChart
          weeks={grouped_week}
          percents={week_by_week}
          type="column"
        />
      ),
    }),
  ) as AnyObject[];

  return (
    <ReportTable
      data={computedItems as any}
      count={countData.count}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey={paths.WEEKLY_GROSS_MARGIN}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};

export default WeeklyGrossMarginReport;
