import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeadCell } from '../../components/EnhancedTable';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import ReportWithOwnState from '../../components/Reports/ReportWithOwnState';
import { clients } from '../../modules/constants';

const DailyUphBySite = () => {
  const { t } = useTranslation();

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'date',
      disablePadding: false,
      label: t('productions_uph_reports.date'),
    },
    {
      id: 'siteName',
      disablePadding: false,
      label: t('productions_uph_reports.site.name'),
    },
    {
      id: 'qty',
      disablePadding: false,
      label: t('productions_uph_reports.qty'),
    },
    {
      id: 'hrs',
      disablePadding: false,
      label: t('productions_uph_reports.hrs'),
    },
    {
      id: 'uph',
      disablePadding: false,
      label: t('productions_uph_reports.uph'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'date',
      label: t('productions_uph_reports.date'),
      type: 'daterange',
      operator: 'between',
    },
    {
      name: 'siteName',
      label: t('productions_uph_reports.site.name'),
      type: 'comboboxSites',
      operator: 'eq',
      useReports: true,
      clientKey: clients.carparts,
    },
  ];

  // TODO: should be refactored - store data in redux
  return (
    <ReportWithOwnState
      reportUrl="/carparts/daily-uph/by-site"
      tableName={t('carparts_reports.table_names.daily_by_site')}
      headCells={headCells}
      filters={filters}
    />
  );
};

export default DailyUphBySite;
