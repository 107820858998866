import React from 'react';
import { useTheme } from '@mui/joy';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { ITextFieldProps, TextField } from 'src/components/_ui-kit/TextField';
import { Pencil02Svg } from 'src/components/svgIcons';
import { useFilterPanelFieldIsEditingModeState } from 'src/modules/utils/hooks/filterPanel.hooks';
import { FilterPanelFilterContainer } from '../components/FilterPanelFilterContainer';

interface IFilterPanelTextFieldProps extends ITextFieldProps {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
}

export const FilterPanelTextField = ({
  filterId,
  onRemoveFilter,
  ...textFieldProps
}: IFilterPanelTextFieldProps) => {
  const theme = useTheme();

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const {
    isFilterInEditingMode,
    setIsFilterInEditingMode,
  } = useFilterPanelFieldIsEditingModeState();

  const {
    setEditingFiltersIds,
    elementsHeight,
    setFilterPanelMode,
    filterPanelMode,
    isFilterPermanent,
  } = React.useContext(FilterPanelContext);

  const onClick = () => {
    if (inputRef.current && filterPanelMode === FilterPanelModes.ready) {
      inputRef.current.selectionStart = inputRef.current?.value.length;
      inputRef.current.selectionEnd = inputRef.current?.value.length;
      inputRef.current.focus();
    }

    if (!isFilterInEditingMode) {
      setIsFilterInEditingMode(true);

      filterPanelMode !== FilterPanelModes.setup &&
        setFilterPanelMode(FilterPanelModes.setup);
    }
  };

  const onTrashBinClick = () => {
    setEditingFiltersIds((prev) => prev.filter((item) => item !== filterId));

    setFilterPanelMode(FilterPanelModes.setup);

    onRemoveFilter && onRemoveFilter(filterId);
  };

  return (
    <FilterPanelFilterContainer
      onTrashBinClick={onTrashBinClick}
      isPermanentFilter={isFilterPermanent(filterId)}
    >
      <TextField
        {...textFieldProps}
        ref={inputRef}
        variant="plain"
        onClick={onClick}
        endDecorator={isFilterInEditingMode ? undefined : <Pencil02Svg />}
        slotProps={{
          root: {
            sx: {
              fontSize: '0.75rem',
              borderRadius: 0,
              borderWidth: 0,
              bgcolor: 'transparent',
              margin: 0,
              height: elementsHeight,
            },
          },
          input: {
            sx: {
              '&::placeholder': {
                fontWeight: theme.fontWeight.regular,
                color: theme.palette.colors.text.text_placeholder,
                ...theme.typography.text_xs,
              },
            },
          },
        }}
      />
    </FilterPanelFilterContainer>
  );
};
