import React from 'react';
import { TableContext, TableContextProvider } from '../../TableContext';
import { Sheet, SheetProps } from '@mui/joy';
import { useMainLayoutContext } from 'src/components/Layout/MainLayout/MainLayoutContext';

// To save permanent columns user want to view we use tableUniqueKey;
export type ITableProps = {
  tableUniqueKey: string;
  children: React.ReactNode;
  heightWithTabs?: boolean;
} & SheetProps;

export const TableComponent: React.FC<ITableProps> = ({
  children,
  tableUniqueKey,
  sx,
  heightWithTabs,
  ...sheetProps
}) => {
  const { setTableUniqueKey } = React.useContext(TableContext);

  const {
    pageWithCopyrightContentHeight,
    pageWithTabsHeight,
  } = useMainLayoutContext();

  React.useEffect(() => {
    setTableUniqueKey(tableUniqueKey);
  }, [setTableUniqueKey, tableUniqueKey]);

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        borderWidth: 1,
        overflow: 'hidden',
        borderRadius: 'md',
        borderStyle: 'solid',
        borderColor: 'colors.border.border_primary',
        position: 'relative',
        maxHeight: heightWithTabs
          ? pageWithTabsHeight
          : pageWithCopyrightContentHeight,
        ...sx,
      }}
      {...sheetProps}
    >
      {children}
    </Sheet>
  );
};

export const Table = (props: ITableProps) => {
  return (
    <TableContextProvider>
      <TableComponent {...props} />
    </TableContextProvider>
  );
};
