import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreatePointCollectionRequest,
  IdsArray,
  IFilter,
  IPointCollectionModel,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushPointCollectionState = createAction(
  'Flush pointCollection data in store',
);

// Creation actions
export const createPointCollectionRequest = createAction<
  PayloadWithFilters<ICreatePointCollectionRequest>
>('Make request to create a new pointCollection');
export const createPointCollectionSuccess = createAction<IPointCollectionModel>(
  'New pointCollection has been created successfully',
);
export const createPointCollectionFailed = createAction(
  'Cannot create a new pointCollection',
);

// Fetch pointCollections actions
export const getPointCollectionListRequest = createAction<IFilter>(
  'Make request to fetch pointCollection list',
);
export const getPointCollectionListSuccess = createAction<
  IPointCollectionModel[]
>('PointCollections list has been fetched successfully');
export const getPointCollectionListFailed = createAction(
  'Cannot fetch pointCollection list',
);

// get pointCollections count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPointCollectionCountRequest = createAction<IFilter>(
  'Make request to get pointCollection count',
);
export const getPointCollectionCountSuccess = createAction<ICountResponse>(
  'PointCollections count has been fetched successfully',
);
export const getPointCollectionCountFailed = createAction(
  'Cannot get pointCollection count',
);

// delete actions
export const deletePointCollectionRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete pointCollections');
export const deletePointCollectionSuccess = createAction(
  'PointCollections delete success',
);
export const deletePointCollectionFailed = createAction(
  'Cannot delete pointCollections',
);

// Updating actions
export const updatePointCollectionsRequest = createAction<
  PayloadWithFilters<IPointCollectionModel[]>
>('Make request to update pointCollections');
export const updatePointCollectionsSuccess = createAction(
  'Update pointCollections success',
);
export const updatePointCollectionsFailed = createAction(
  'Cannot update pointCollections',
);

// Fetch combobox list actions
export const getPointCollectionComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of pointCollections',
);
export const getPointCollectionComboboxListSuccess = createAction<
  Partial<IPointCollectionModel>[]
>('Combobox list of pointCollections has been fetched successfully');
export const getPointCollectionComboboxListFailed = createAction(
  'Cannot fetch combobox pointCollections list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('PointCollection').takeExportActions();

export const exportPointCollectionRequest = exportReportRequest;
export const exportPointCollectionSuccess = exportReportSuccess;
export const exportPointCollectionFailed = exportReportFailed;
