import React from 'react';
import { Box, Table, Sheet, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { PayboardDetails } from '../../../../modules/types';
import { map } from 'lodash';
import { MainContentLoader } from '../../../../components/Layout/components/PageTour/MainContentLoader';
import { ModalDrillDown } from '../../../../components/_ui-kit/ModalDrillDown';

interface IPayboardDetailsTableProps {
  title: string;
  items: PayboardDetails[] | null;
  total: number;
  count: number;
  isTableDataLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const COL_SPAN_6 = 6;

export const PayboardDetailsTable: React.FC<IPayboardDetailsTableProps> = (
  props,
) => {
  const { t } = useTranslation();
  const isTableDataLoading = props.isTableDataLoading ?? false;

  return (
    <ModalDrillDown
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <Sheet
        sx={{
          paddingX: '40px',
        }}
      >
        {isTableDataLoading && <MainContentLoader />}
        <Table
          hoverRow
          noWrap
          variant="outlined"
          aria-label="Payboard details drill down table"
        >
          <thead>
            <tr>
              <th>
                <Box sx={{ paddingLeft: '24px' }}>{t('payboard.date')}</Box>
              </th>
              <th>{t('payboard.year')}</th>
              <th>{t('payboard.week')}</th>
              <th>{t('payboard.badge')}</th>
              <th>{t('payboard.first_name')}</th>
              <th>{t('payboard.last_name')}</th>
              <th>{t('payboard.department')}</th>
              <th>{t('payboard.total_hours')}</th>
            </tr>
          </thead>
          <tbody>
            {map(props.items, (item, index) => (
              <tr key={index}>
                <td>
                  <Box sx={{ paddingLeft: '24px' }}>{item.date}</Box>
                </td>
                <td>{item.year}</td>
                <td>{item.week}</td>
                <td>{item.badge}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.department}</td>
                <td>{item.totalHoursDec}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={COL_SPAN_6}> </td>
              <td>
                <Typography fontWeight="bold">
                  {t('payboard.count_n', { n: props.count })}
                </Typography>
              </td>
              <td>
                <Typography fontWeight="bold">
                  {t('payboard.total_n', { n: props.total })}
                </Typography>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>
    </ModalDrillDown>
  );
};
