import React from 'react';
import { Box, Typography } from '@mui/material';

interface IInvoiceHeaderProps {
  invoiceNumber?: string;
  siteName: string;
}

export const InvoiceHeader: React.FC<IInvoiceHeaderProps> = ({
  invoiceNumber,
  siteName,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        top: '-16px',
      }}
    >
      <Box
        sx={{
          width: 260,
          height: 90,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/images/logo-color.png)',
        }}
      />
      <Box>
        {invoiceNumber && (
          <>
            <Typography>Backup Invoice Number</Typography>
            <Typography>{invoiceNumber}</Typography>
          </>
        )}
        <Typography>Site:</Typography>
        <Typography>{siteName}</Typography>
      </Box>
    </Box>
  );
};
