import React from 'react';
import {
  PageContentChildContainer,
  PageContentTab,
  PageContentTabPanel,
  PageContentTabs,
  PageContentTabsList,
} from 'src/components/PageContent';
import { ChartsDashboardHeader } from 'src/components/ChartsDashboard';
import { PolicesToViewPages } from './PolicesToView';
import { PolicesToCreateUpdateDelete } from './PolicesToCreateUpdateDelete';
import { Box } from '@mui/joy';
import { MainLayoutContext } from 'src/components/Layout/MainLayout/MainLayoutContext';

const PageSettings = () => {
  const { headerHeight } = React.useContext(MainLayoutContext);

  const [activeTab, setActiveTab] = React.useState<number | null>(0);

  const getPageTitleByActiveTabNumber = (activeTabNumber: number | null) => {
    if (activeTabNumber === 0) {
      return 'Polices by page';
    }
    if (activeTabNumber === 1) {
      return 'Polices by operation';
    }

    return '';
  };

  return (
    <PageContentTabs
      value={activeTab}
      onChange={(_, value) =>
        setActiveTab(value !== null ? Number(value) : value)
      }
    >
      <PageContentTabsList>
        <PageContentTab>View pages</PageContentTab>
        <PageContentTab>Manage entities</PageContentTab>
      </PageContentTabsList>

      <Box sx={{ height: `calc(100vh - ${headerHeight}px)`, overflow: 'auto' }}>
        <PageContentChildContainer sx={{ pt: 2 }} fullHeight={false}>
          <ChartsDashboardHeader>
            {getPageTitleByActiveTabNumber(activeTab)}
          </ChartsDashboardHeader>
        </PageContentChildContainer>

        {activeTab === 0 && (
          <PageContentTabPanel value={0}>
            <Box sx={{ maxWidth: 600 }}>
              <PolicesToViewPages />
            </Box>
          </PageContentTabPanel>
        )}
        {activeTab === 1 && (
          <PageContentTabPanel sx={{ flexGrow: 1 }} value={1}>
            <Box sx={{ maxWidth: 600 }}>
              <PolicesToCreateUpdateDelete />
            </Box>
          </PageContentTabPanel>
        )}
      </Box>
    </PageContentTabs>
  );
};

export default PageSettings;
