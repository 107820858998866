import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import { map } from 'lodash';
import {
  ICountResponse,
  ICreateLogtimeRequest,
  IdsArray,
  IFilter,
  ILogtimeModel,
  ISagaAction,
  ILogtimeSupervisorViewModel,
  ISagaActionBind,
  PayloadWithNavigateFunc,
  PayloadWithFilters,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';

/**
 * Create a new logtime
 */
export const createLogtimeRequestSaga = function* ({
  payload: { data },
}: ISagaAction<PayloadWithNavigateFunc<ICreateLogtimeRequest>>): SagaIterator {
  try {
    const response: ILogtimeModel = yield call(Api.Logtime.create, data);
    yield put(actions.flushLogtimeState());
    yield put(actions.createLogtimeSuccess(response));
    // yield call(navigate, { pathname: paths.LOGTIMES_APPROVED });
  } catch (e) {
    yield put(actions.createLogtimeFailed());
  }
};

/**
 * Create a new bulk logtime
 */
export const createLogtimeBulkRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ICreateLogtimeRequest[]>>): SagaIterator {
  try {
    yield call(Api.Logtime.bulkCreate, data);

    if (filters) {
      yield put(
        actions.getLogtimeUnapprovedListRequest({ filter: filters.list }),
      );
      yield put(
        actions.getLogtimeUnapprovedCountRequest({ filter: filters.count }),
      );
    }

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.createLogtimeFailed());
  }
};

/**
 * Get logtime list
 */
export const getLogtimeListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ILogtimeModel[] = yield call(
      Api.Logtime.list,
      action.payload,
    );
    yield put(actions.getLogtimeListSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeListFailed());
  }
};

/**
 * Get logtime list for supervisor view
 */
export const getLogtimeListForSupervisorViewRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ILogtimeSupervisorViewModel[] = yield call(
      Api.Logtime.supervisorViewList,
      action.payload,
    );
    yield put(actions.getLogtimeListForSupervisorViewSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeListForSupervisorViewFailed());
  }
};

/**
 * Get logtime count
 */
export const getLogtimeCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Logtime.count,
      action?.payload,
    );
    yield put(actions.getLogtimeCountSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeCountFailed());
  }
};

/**
 * Delete logtimes
 */
export const deleteLogtimeRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Logtime.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushLogtimeState());
  } catch (e) {
    yield put(actions.deleteLogtimeFailed());
  }
};

/**
 * Bulk update logtimes
 */
export const updateLogtimesRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ILogtimeModel[]>>): SagaIterator {
  try {
    yield call(Api.Logtime.bulkUpdate, data);

    yield put(actions.getLogtimeApprovedListRequest({ filter: filters.list }));
    yield put(
      actions.getLogtimeApprovedCountRequest({ filter: filters.count }),
    );

    yield put(
      actions.getLogtimeUnapprovedListRequest({ filter: filters.list }),
    );
    yield put(
      actions.getLogtimeUnapprovedCountRequest({ filter: filters.count }),
    );
  } catch (e) {
    yield put(actions.updateLogtimesFailed());
  }
};

/**
 * Get combobox list
 */
export const getLogtimeComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<ILogtimeModel>[] = yield call(
      Api.Logtime.list,
      action.payload,
    );
    yield put(actions.getLogtimeComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeComboboxListFailed());
  }
};

/**
 * Approve logtimes
 */
export const approveLogtimesRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    const dataToUpdate = map(
      action.payload,
      (i) =>
        ({
          id: i,
          approved: true,
        } as ILogtimeModel),
    );
    yield call(Api.Logtime.bulkUpdate, dataToUpdate);
    yield put(actions.flushLogtimeState());

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          action.payload.length > 1
            ? 'time_keeping.time_punches_successfully_approved'
            : 'time_keeping.time_punch_successfully_approved',
      }),
    );
  } catch (e) {
    yield put(actions.approveLogtimesFailed());
  }
};

export const unapproveLogtimesRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    const dataToUpdate = map(
      action.payload,
      (i) =>
        ({
          id: i,
          approved: false,
        } as ILogtimeModel),
    );
    yield call(Api.Logtime.bulkUpdate, dataToUpdate);

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          action.payload.length > 1
            ? 'time_keeping.time_punches_successfully_unapproved'
            : 'time_keeping.time_punch_successfully_unapproved',
      }),
    );
    yield put(actions.flushLogtimeState());
  } catch (e) {
    yield put(actions.unapproveLogtimesFailed());
  }
};

export const moveToMissedLogtimesRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Logtime.moveToMissed, action.payload);

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
    yield put(actions.moveToMissedLogtimesSuccess(action.payload));
  } catch (e) {
    yield put(actions.moveToMissedLogtimesFailed());
  }
};

export const getLogtimeApprovedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ILogtimeModel[] = yield call(
      Api.Logtime.listApproved,
      action.payload,
    );
    yield put(actions.getLogtimeApprovedListSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeApprovedListFailed());
  }
};

export const getLogtimeApprovedCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Logtime.countApproved,
      action?.payload,
    );
    yield put(actions.getLogtimeApprovedCountSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeApprovedCountFailed());
  }
};

export const getLogtimeUnapprovedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ILogtimeModel[] = yield call(
      Api.Logtime.listUnapproved,
      action.payload,
    );
    yield put(actions.getLogtimeUnapprovedListSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeUnapprovedListFailed());
  }
};

export const getLogtimeUnapprovedCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Logtime.countUnapproved,
      action?.payload,
    );
    yield put(actions.getLogtimeUnapprovedCountSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeUnapprovedCountFailed());
  }
};

// Missed
export const getLogtimeMissedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ILogtimeModel[] = yield call(
      Api.Logtime.listMissed,
      action.payload,
    );
    yield put(actions.getLogtimeMissedListSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeMissedListFailed());
  }
};

export const getLogtimeMissedCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Logtime.countMissed,
      action?.payload,
    );
    yield put(actions.getLogtimeMissedCountSuccess(response));
  } catch (e) {
    yield put(actions.getLogtimeMissedCountFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportLogtimeRequest,
    saga: createExportSaga({
      apiCall: Api.Logtime.export,
      actionFailed: actions.exportLogtimeFailed,
      actionSuccess: actions.exportLogtimeSuccess,
    }),
  },
  {
    action: actions.exportSupervisorViewRequest,
    saga: createExportSaga({
      apiCall: Api.Logtime.exportSupervisorView,
      actionSuccess: actions.exportSupervisorViewSuccess,
      actionFailed: actions.exportSupervisorViewFailed,
    }),
  },
  { action: actions.createLogtimeRequest, saga: createLogtimeRequestSaga },
  {
    action: actions.createLogtimeBulkRequest,
    saga: createLogtimeBulkRequestSaga,
  },
  { action: actions.getLogtimeListRequest, saga: getLogtimeListRequestSaga },
  {
    action: actions.getLogtimeListForSupervisorViewRequest,
    saga: getLogtimeListForSupervisorViewRequestSaga,
  },
  { action: actions.getLogtimeCountRequest, saga: getLogtimeCountRequestSaga },
  { action: actions.deleteLogtimeRequest, saga: deleteLogtimeRequestSaga },
  // bulk updating logtimes
  { action: actions.updateLogtimesRequest, saga: updateLogtimesRequestSaga },
  {
    action: actions.getLogtimeComboboxListRequest,
    saga: getLogtimeComboboxListRequestSaga,
  },
  { action: actions.approveLogtimesRequest, saga: approveLogtimesRequestSaga },
  {
    action: actions.unapproveLogtimesRequest,
    saga: unapproveLogtimesRequestSaga,
  },

  {
    action: actions.getLogtimeApprovedListRequest,
    saga: getLogtimeApprovedListRequestSaga,
  },
  {
    action: actions.getLogtimeApprovedCountRequest,
    saga: getLogtimeApprovedCountRequestSaga,
  },
  {
    action: actions.exportLogtimeApprovedRequest,
    saga: createExportSaga({
      apiCall: Api.Logtime.exportApproved,
      actionFailed: actions.exportLogtimeApprovedFailed,
      actionSuccess: actions.exportLogtimeApprovedSuccess,
    }),
  },

  {
    action: actions.getLogtimeUnapprovedListRequest,
    saga: getLogtimeUnapprovedListRequestSaga,
  },
  {
    action: actions.getLogtimeUnapprovedCountRequest,
    saga: getLogtimeUnapprovedCountRequestSaga,
  },
  {
    action: actions.getLogtimeMissedListRequest,
    saga: getLogtimeMissedListRequestSaga,
  },
  {
    action: actions.getLogtimeMissedCountRequest,
    saga: getLogtimeMissedCountRequestSaga,
  },
  {
    action: actions.moveToMissedLogtimesRequest,
    saga: moveToMissedLogtimesRequestSaga,
  },
  {
    action: actions.exportLogtimeUnapprovedRequest,
    saga: createExportSaga({
      apiCall: Api.Logtime.exportUnapproved,
      actionFailed: actions.exportLogtimeUnapprovedFailed,
      actionSuccess: actions.exportLogtimeUnapprovedSuccess,
    }),
  },
  {
    action: actions.exportLogtimeMissedRequest,
    saga: createExportSaga({
      apiCall: Api.Logtime.exportMissed,
      actionFailed: actions.exportLogtimeMissedFailed,
      actionSuccess: actions.exportLogtimeMissedSuccess,
    }),
  },
];
