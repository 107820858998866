import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { PageContentChildContainer } from 'src/components/PageContent';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  useAbsenteeRealtime,
  useAbsenteeRealtimeComposeWhere,
  useAbsenteeRealtimeTableFiltersConfiguration,
} from 'src/modules/utils/hooks/absentee-realtime.hooks';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { AbsenteeRealtimeFilter } from 'src/pages/Absentee/panels/AbsenteeRealtime/components/AbsenteeRealtimeFilter';
import { shallowEqual, useSelector } from 'react-redux';
import { getGloballySelectedSites } from '../../../../modules/selectors/site';
import { Grid } from '@mui/material';
import { MUI_SIZE_12 } from '../../../../config';
import { Alert } from '@mui/joy';

const reportUrl = '/employees/hooky-folks';

export const AbsenteeRealtime: React.FC = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl);

  const filterFieldsConfiguration = useAbsenteeRealtimeTableFiltersConfiguration();

  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  const selectedSite = React.useMemo(
    () => (selectedSites.length === 1 ? selectedSites[0] : undefined),
    [selectedSites],
  );

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useAbsenteeRealtimeComposeWhere({
    siteId: selectedSite ?? undefined,
    employeeName: filterFields.employeeName.value,
    badge: +filterFields.badge.value,
    shiftId: +filterFields.shiftId.value,
    departmentId: +filterFields.departmentId.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useAbsenteeRealtime(reportUrl, where, !selectedSite);

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(data, (item) => ({
        ...item,
        overnight:
          item.overnight === 'YES' ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
        className:
          item.scheduled === 'Absent'
            ? 'error'
            : item.scheduled === 'Not Scheduled'
            ? 'warning'
            : '',
      })),
    [data],
  );

  return (
    <>
      <PageContentChildContainer sx={{ pt: 2, pb: 1 }} fullHeight={false}>
        <TableToolbar>
          <TableTitle>{t('hookyFolks.page_title')}</TableTitle>

          <TableActions>
            <AbsenteeRealtimeFilter
              getLabel={getLabel}
              onFiltersFormSubmit={onFiltersFormSubmit}
              getFilterCommonPropsByFilterName={
                getFilterCommonPropsByFilterName
              }
            />

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={requestFilters}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />
          </TableActions>
        </TableToolbar>
      </PageContentChildContainer>

      <>
        {!selectedSite && (
          <Grid container={true}>
            <Grid item={true} xs={MUI_SIZE_12}>
              <Alert variant="soft">
                {t('historicalHookyFolks.select_only_one_site')}
              </Alert>
            </Grid>
          </Grid>
        )}
        {selectedSite && (
          <ReportTable
            heightWithTabs
            data={computedList as any}
            count={countData.count}
            isDataLoading={isDataLoading || isCountDataLoading}
            tableUniqueKey={reportUrl}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        )}
      </>
    </>
  );
};
