import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/joy';

import {
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';

import { AnyObject, IWorkdaysModel } from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { ModelsToDelete } from 'src/config';
import { TableWithActionsDeleteModal } from 'src/components/TableWithActions/components/TableWithActionsDeleteModal';
import {
  useWorkdays,
  useWorkdaysFilter,
  useWorkdaysPermissions,
  useWorkdaysTableFiltersConfiguration,
} from 'src/modules/utils';
import { WorkdaysFilter } from './components/WorkdaysFilter';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { WorkdaysUpdate } from './WorkdaysUpdate';

const reportUrl = '/workdays';

export const Workdays = () => {
  const { t } = useTranslation();

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});

  const exportProps = useReportExportProps(reportUrl, true);

  const { allowedToUpdate, allowedToDelete } = useWorkdaysPermissions();

  const [selectedItems, setSelectedItems] = React.useState<IWorkdaysModel[]>(
    [],
  );

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState(false);

  const filter = useWorkdaysFilter(appliedFilters);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
    removeDataByIds,
    fetchData,
  } = useWorkdays(reportUrl, filter!.where!, filter!.include!);

  const filterFieldsConfiguration = useWorkdaysTableFiltersConfiguration();

  const { getLabel, getFilterCommonPropsByFilterName } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(filters);
  };

  const onTableRowCheckboxClick = (items: Array<IWorkdaysModel>) => {
    setSelectedItems(items);
  };

  const handleDelete = async () => {
    const ids = selectedItems.map((i) => +i.id!);
    await removeDataByIds(ids);
    await fetchData({
      filter,
    });
    setShowDeleteModal(false);
  };

  // handle updating
  const handleUpdating = (items: IWorkdaysModel[]) => {
    setIsUpdateFormVisible(true);
    setSelectedItems(items);
  };

  const handleUpdate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsUpdateFormVisible(false);
  };

  const ids = React.useMemo(() => selectedItems.map((i) => +i.id!), [
    selectedItems,
  ]);

  return (
    <TableContextProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: isDataLoading ? 400 : 'auto',
          height: isDataLoading || !data.length ? '100%' : 'auto',
        }}
      >
        <TableToolbar>
          <TableTitle>{t('main_menu.workdays')}</TableTitle>

          <TableActions>
            <WorkdaysFilter
              getLabel={getLabel}
              onFiltersFormSubmit={handleFiltersSubmit}
              getFilterCommonPropsByFilterName={
                getFilterCommonPropsByFilterName
              }
            />

            <SelectHeadCells />

            <TableActionsExport
              requestFilters={requestFilters}
              exportBtnContainerProps={{ ml: 3 }}
              {...exportProps}
            />
          </TableActions>
        </TableToolbar>

        <TableWithActionsDeleteModal
          open={showDeleteModal}
          onOk={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          deleteModelName={ModelsToDelete.Workdays}
        />

        <TableWithActions<IWorkdaysModel>
          heightWithTabs
          selectedIndex="id"
          data={data as any}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          limit={limit}
          page={page}
          onSort={handleSort}
          selectedItems={selectedItems}
          onRowCheckboxClick={onTableRowCheckboxClick}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(page) => handlePageChange(page)}
          {...(allowedToUpdate
            ? {
                updateActionConfiguration: {
                  onClick: () => {
                    handleUpdating(selectedItems);
                  },
                },
              }
            : {})}
          {...(allowedToDelete
            ? {
                deleteActionConfiguration: {
                  onClick: () => setShowDeleteModal(true),
                },
              }
            : {})}
        />

        <WorkdaysUpdate
          ids={ids}
          isOpen={isUpdateFormVisible}
          onUpdate={handleUpdate}
          onClose={() => setIsUpdateFormVisible(false)}
        />
      </Box>
    </TableContextProvider>
  );
};
