import React from 'react';
import { Box, Sheet } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useMainLayoutContext } from '../../MainLayoutContext';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { ActiveItemIndicator } from 'src/components/ActiveItemIndicator';

const MainMenuItemLinkContainer = Box;
const MainMenuItemLinkTextContainer = Sheet;

interface MainMenuItemLinkProps {
  nesting: number;
  isActive: boolean;
  to: string;
  onClick?: (primary: string) => void;
  withoutWrapper?: boolean;
  itemName: string;
  external?: boolean;
}

export const MainMenuItemLink = ({
  to,
  withoutWrapper,
  onClick,
  itemName,
  external,
  isActive,
  nesting,
  ...rest
}: MainMenuItemLinkProps) => {
  const { t } = useTranslation();

  const {
    sideBar,
    toggleSideBar,
    isSmallScreen,
    sideBarColors,
    sideBarMenuItemHeight,
    sideBarTextOffsetStep,
  } = useMainLayoutContext();

  const clickHandler = () => {
    onClick && onClick(itemName);
  };

  const externalRedirect = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(to, '_blank');
  };

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink
          to={to}
          ref={ref}
          style={{ textDecoration: 'none' }}
          {...itemProps}
          {...(external
            ? {
                onClick: (e) => {
                  externalRedirect(e);
                  isSmallScreen && toggleSideBar();
                },
              }
            : {
                onClick: () => {
                  isSmallScreen && toggleSideBar();
                },
              })}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [to],
  );

  // If this is top level tab (nesting === 1) then we calculate
  // its color based on isActive prop in other case we inherit it
  // from the parent
  let color = 'inherit';
  if (nesting === 1) {
    color = isActive ? sideBarColors.textActive : sideBarColors.text;
  }
  const listItem = (
    <MainMenuItemLinkContainer
      sx={{
        height: `${sideBarMenuItemHeight}px`,
        marginLeft:
          nesting === 1
            ? `${sideBarTextOffsetStep * 2}px`
            : `${sideBarTextOffsetStep * nesting}px`,
        color,
        backgroundColor: 'inherit',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        opacity: sideBar.state === 'expanded' ? 1 : 0,
        transition: 'opacity .1s, color 50ms',
      }}
      component={renderLink}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          p: 0.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'inherit',
        }}
      >
        <ActiveItemIndicator />
      </Box>
      <MainMenuItemLinkTextContainer
        sx={{
          color: 'inherit',
          textDecoration: 'none',
          flexBasis: '100%',
          backgroundColor: sideBarColors.background,
          marginLeft: isActive ? 2.5 : 0,
          transition: `margin 80ms ease-in-out`,
        }}
        onClick={clickHandler}
        {...rest}
      >
        <Box
          sx={{
            color: 'inherit !important',
            backgroundColor: 'inherit',
            fontWeight: '500',
            lineHeight: '20px',
            fontSize: '14px',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {t(itemName)}
        </Box>
      </MainMenuItemLinkTextContainer>
    </MainMenuItemLinkContainer>
  );

  return withoutWrapper ? (
    listItem
  ) : (
    <li style={{ color: 'inherit' }} onClick={clickHandler} {...rest}>
      {listItem}
    </li>
  );
};
