import React from 'react';

import {
  useWikiForm,
  useWikiUpdate,
} from '../../modules/utils/hooks/wiki-setup.hooks';

import WikiPageForm from './WikiPageForm';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

const UpdateWiki: React.FC = () => {
  const { updatePage } = useWikiForm();
  const initialValuesList = useWikiUpdate();

  return (
    <>
      {initialValuesList.map((page) => (
        <PageContentWithTopToolbar key={page.id}>
          <PageContentChildContainer fullHeight={false}>
            <WikiPageForm
              initialValues={page}
              onSubmitHandler={updatePage(page.id!)}
              initialContent={page.body.storage.value}
            />
          </PageContentChildContainer>
        </PageContentWithTopToolbar>
      ))}
    </>
  );
};

export default UpdateWiki;
