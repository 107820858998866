import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopComponent: React.FC = ({ children }) => {
  const location = useLocation();
  const prevLocationRef = React.useRef<typeof location>();

  React.useEffect(() => {
    if (prevLocationRef.current?.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }

    prevLocationRef.current = location;
  }, [location]);

  return <>{children}</>;
};

export const ScrollToTop = ScrollToTopComponent;

export default ScrollToTop;
