import React, { useMemo } from 'react';
import { find, get } from 'lodash';
import ComboBox, { ComboBoxOption, IComboboxProps } from '../ComboBox';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import { Lang } from '../../modules/utils';
import { useTranslation } from 'react-i18next';

interface IFormikLangComboboxProps<Values>
  extends Omit<IComboboxProps, 'options' | 'placeholder'> {
  id: string;
  formik: FormikHelpers<Values> & FormikState<Values>;
  errorMode?: ErrorMode;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => void;
}

const languages = [Lang.EN, Lang.ES];

const FormikLangCombobox = <T extends AnyObject>({
  id,
  formik,
  onChange,
  errorMode = 'onFormSubmit',
  ...restProps
}: IFormikLangComboboxProps<T>) => {
  const { t } = useTranslation();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, value?.id);

    if (onChange) {
      onChange(event, value);
    }
  };

  const languageOptions = React.useMemo(
    () =>
      languages.map((lang) => ({
        id: lang as any,
        name: t(`lang.${lang}`),
      })),
    [t],
  );

  const option = useMemo(
    () => find(languageOptions, (i) => i.id === get(formik.values, id)) || null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, languageOptions],
  );
  const error = useMemo(() => get(formik.errors, id), [formik.errors, id]);
  const touched = useMemo(() => get(formik.touched, id), [formik.touched, id]);
  const showError = useMemo(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  return (
    <ComboBox
      {...restProps}
      id={id}
      label={t('common.language')}
      options={languageOptions}
      value={option}
      onChange={handleChange}
      error={showError}
      helperText={showError ? (error as string) : undefined}
    />
  );
};

export default FormikLangCombobox;
