import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeadCell } from '../../components/EnhancedTable';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import ReportWithOwnState from '../../components/Reports/ReportWithOwnState';
import { clients } from '../../modules/constants';

const DEFAULT_ORDER_BY = 'yearWeek';

const WeeklyUphBySite = () => {
  const { t } = useTranslation();

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'year',
      disablePadding: false,
      label: t('productions_uph_reports.year'),
    },
    {
      id: 'week',
      disablePadding: false,
      label: t('productions_uph_reports.week'),
    },
    {
      id: 'yearWeek',
      disablePadding: false,
      label: t('productions_uph_reports.year_week'),
    },
    {
      id: 'siteName',
      disablePadding: false,
      label: t('productions_uph_reports.site.name'),
    },
    {
      id: 'qty',
      disablePadding: false,
      label: t('productions_uph_reports.qty'),
    },
    {
      id: 'hrs',
      disablePadding: false,
      label: t('productions_uph_reports.hrs'),
    },
    {
      id: 'uph',
      disablePadding: false,
      label: t('productions_uph_reports.uph'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'year',
      label: t('productions_uph_reports.year'),
      operator: 'eq',
    },
    {
      name: 'week',
      label: t('productions_uph_reports.week'),
      operator: 'eq',
    },
    {
      name: 'siteName',
      label: t('productions_uph_reports.site.name'),
      type: 'comboboxSites',
      operator: 'eq',
      useReports: true,
      clientKey: clients.carparts,
    },
  ];

  return (
    <ReportWithOwnState
      reportUrl="/carparts/weekly-uph/by-site"
      tableName={t('carparts_reports.table_names.weekly_by_site')}
      headCells={headCells}
      filters={filters}
      defaultOrderBy={DEFAULT_ORDER_BY}
    />
  );
};

export default WeeklyUphBySite;
