import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box, Paper } from '@mui/material';

type EntityName = 'employee' | 'position';

export interface IEntity {
  label: string;
  name: EntityName;
}
export const entities: IEntity[] = [
  { label: 'Employee', name: 'employee' },
  { label: 'Position', name: 'position' },
];

interface IEntitiesTabsProps {
  activeEntity: EntityName;
  onActiveEntityChange: (activeEntity: EntityName) => void;
}
export const EntitiesTabs: React.FC<IEntitiesTabsProps> = ({
  activeEntity,
  onActiveEntityChange,
}) => {
  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    index: number,
  ) => {
    onActiveEntityChange(entities[index].name);
  };

  const activeEntityIndex = entities.findIndex(
    (entity) => entity.name === activeEntity,
  );

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(2),
      })}
    >
      <Paper elevation={1}>
        <Tabs
          value={activeEntityIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {entities.map((entity) => (
            <Tab key={entity.name} label={entity.label} id={entity.name} />
          ))}
        </Tabs>
      </Paper>
    </Box>
  );
};
