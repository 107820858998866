import React from 'react';
import { Typography } from '@mui/joy';

interface IFormNoteProps {
  children: React.ReactNode;
}

export const FormNote = ({ children }: IFormNoteProps) => {
  return (
    <Typography
      variant="soft"
      borderRadius={8}
      level="text_sm"
      fontStyle="italic"
      p={0.5}
    >
      {children}
    </Typography>
  );
};
