import {
  ICardinalHealthWeeklyReportModel,
  ICardinalHealthWeeklyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthWeeklyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyUphBySiteAndShiftListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteAndShiftListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteAndShiftListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthWeeklyReportState,
  ICardinalHealthWeeklyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteAndShiftCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteAndShiftCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteAndShiftCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthWeeklyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyUphBySiteAndShift = createReducer<
  ICardinalHealthWeeklyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndShiftListRequest,
    getCardinalHealthWeeklyUphBySiteAndShiftListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndShiftListSuccess,
    getCardinalHealthWeeklyUphBySiteAndShiftListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndShiftListFailed,
    getCardinalHealthWeeklyUphBySiteAndShiftListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndShiftCountRequest,
    getCardinalHealthWeeklyUphBySiteAndShiftCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndShiftCountSuccess,
    getCardinalHealthWeeklyUphBySiteAndShiftCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndShiftCountFailed,
    getCardinalHealthWeeklyUphBySiteAndShiftCountFailed,
  );

export default cardinalHealthWeeklyUphBySiteAndShift;
