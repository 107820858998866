import { ISagaActionBind } from '../../types';
import { IProductionWeeklyUphBySiteAndEmployeeModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionWeeklyUphBySiteAndEmployeeListRequest,
    saga: createListSaga<IProductionWeeklyUphBySiteAndEmployeeModel>({
      apiCall: Api.ProductionWeeklyUphBySiteAndEmployee.list,
      actionSuccess: actions.getProductionWeeklyUphBySiteAndEmployeeListSuccess,
      actionFailed: actions.getProductionWeeklyUphBySiteAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getProductionWeeklyUphBySiteAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionWeeklyUphBySiteAndEmployee.count,
      actionSuccess:
        actions.getProductionWeeklyUphBySiteAndEmployeeCountSuccess,
      actionFailed: actions.getProductionWeeklyUphBySiteAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportProductionWeeklyUphBySiteAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionWeeklyUphBySiteAndEmployee.export,
      actionSuccess: actions.exportProductionWeeklyUphBySiteAndEmployeeSuccess,
      actionFailed: actions.exportProductionWeeklyUphBySiteAndEmployeeFailed,
    }),
  },
];
