import React from 'react';

import { useWikiForm } from '../../modules/utils/hooks/wiki-setup.hooks';

import WikiPageForm from './WikiPageForm';

import { WikiPageFormType } from '../../modules/types/wiki-setup';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

const initialValues: WikiPageFormType = {
  title: '',
  body: {
    storage: {
      value: '',
    },
  },
};

const CreateWiki: React.FC = () => {
  const { createPage } = useWikiForm();

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <WikiPageForm
          initialValues={initialValues}
          onSubmitHandler={createPage}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default CreateWiki;
