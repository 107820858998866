import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { AbsenteeRealtime } from 'src/pages/Absentee/panels/AbsenteeRealtime';
import { AbsenteeHistorical } from 'src/pages/Absentee/panels/AbsenteeHistorical';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { TableContextProvider } from 'src/components/Table/TableContext';

export const Absentee = () => {
  const { t } = useTranslation();

  const hasUserAccessToAbsenteeRealTimeTab = useHasUserAccessToViewTab(
    tabsPaths.ABSENTEE_REALTIME_TAB,
  );

  const hasUserAccessToAbsenteeHistoricalTab = useHasUserAccessToViewTab(
    tabsPaths.ABSENTEE_HISTORICAL_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.employees_hooky_folks'),
          hasUserAccess: hasUserAccessToAbsenteeRealTimeTab,
          component: <AbsenteeRealtime />,
        },
        {
          title: t('main_menu.employees_historical_hooky_folks'),
          hasUserAccess: hasUserAccessToAbsenteeHistoricalTab,
          component: <AbsenteeHistorical />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToAbsenteeHistoricalTab,
      hasUserAccessToAbsenteeRealTimeTab,
      t,
    ],
  );

  return (
    <TableContextProvider>
      <TabsNgroup config={pointsTabsConfig} />
    </TableContextProvider>
  );
};
