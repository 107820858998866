import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/joy';

import { PolicyConfigItem } from '../../../modules/types';
import { useFetchResources } from 'src/modules/utils';
import { useSelector } from 'react-redux';
import { getResourcesOptions } from 'src/modules/selectors/resources.selector';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { Trash04Svg } from 'src/components/svgIcons';
import { useFetchClientKeys } from 'src/modules/utils/hooks/site-importing-settings.hooks';
import { getClientKeys } from 'src/modules/selectors/site-importing-settings.selector';
import { map } from 'lodash';

interface IPolicyFormValue {
  resource: string;
  action: string;
}

interface IPolicesToCreateUpdateDeletePolicyFormProps {
  id: string;
  selectedResources: PolicyConfigItem[];
  label: string;
  selectedResource: IPolicyFormValue | null;
  selectedClientId: number | null;
  onDelete: () => void;
  onPolicyChange: (value: IPolicyFormValue) => void;
  onClientChange: (value: ComboBoxOption | null) => void;
}

export const PolicesToCreateUpdateDeletePolicyForm: React.FC<IPolicesToCreateUpdateDeletePolicyFormProps> = ({
  selectedResource,
  selectedClientId,
  id,
  label,
  onPolicyChange,
  onClientChange,
  onDelete,
  selectedResources,
}) => {
  const theme = useTheme();

  const fetchClientKeys = useFetchClientKeys();

  const clientKeys = useSelector(getClientKeys);

  const clients = (React.useMemo(
    () =>
      map(clientKeys, (clientKey) => ({
        id: clientKey,
        name: clientKey,
      })),
    [clientKeys],
  ) as unknown) as ComboBoxOption[];

  const fetchResources = useFetchResources();

  React.useEffect(() => {
    fetchResources();
    fetchClientKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resources = useSelector(getResourcesOptions);

  const filteredResources = React.useMemo(() => {
    return resources.filter(
      (resource) =>
        !selectedResources
          // We need to remove selectedResource for current form
          // so that it will be used by autocomplete to render value
          .filter(
            (r) =>
              r.action !== selectedResource?.action &&
              r.resource !== selectedResource?.resource,
          )
          // We remove already selected resources from options
          ?.some(
            (selectedResource) =>
              (resource.id as any) ===
              `${selectedResource.resource}_${selectedResource.action}`,
          ),
    );
  }, [
    resources,
    selectedResource?.action,
    selectedResource?.resource,
    selectedResources,
  ]);

  const selectedResourceFullData = React.useMemo(() => {
    if (!selectedResource) {
      return null;
    }

    const selectedResourceFullData = resources.find(
      (resource) =>
        String(resource.id) ===
        `${selectedResource.resource}_${selectedResource.action}`,
    );

    return selectedResourceFullData ?? null;
  }, [resources, selectedResource]);

  const selectedClientFullData = React.useMemo(() => {
    const selectedResource = clients.find(
      (client) => client.id === selectedClientId,
    );

    return selectedResource ?? null;
  }, [clients, selectedClientId]);

  const handlePolicyChange = (_: any, value: any) => {
    onPolicyChange({
      resource: value?.resourceUrl ?? '',
      action: value?.entity ?? '',
    });
  };

  const handleClientChange = (_: any, value: ComboBoxOption | null) => {
    onClientChange(value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        border: '1px solid',
        borderColor: theme.palette.colors.border.border_primary,
        borderRadius: 8,
        p: 1,
        mb: 1,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            mb: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="body-xs" sx={{ ml: 1 }}>
            {label}
          </Typography>

          <Box sx={{ display: 'flex' }}>
            <IconButton color="danger" onClick={onDelete}>
              <Trash04Svg fill="red" />
            </IconButton>
          </Box>
        </Box>

        <ComboBox
          id={`${id}_policy`}
          options={filteredResources}
          value={selectedResourceFullData}
          onChange={handlePolicyChange}
          placeholder={'Policy'}
        />
        <ComboBox
          id={`${id}_client`}
          options={clients}
          value={selectedClientFullData}
          onChange={handleClientChange}
          placeholder={'Client'}
        />
      </Box>
    </Box>
  );
};

export default PolicesToCreateUpdateDeletePolicyForm;
