import React from 'react';
import Accordion from '../../Accordion';
import { Grid } from '@mui/joy';
import Typography from '@mui/joy/Typography';

export interface IFilterByEntityLayoutProps {
  filterComponent: React.ReactElement;
  title?: string;
  expanded?: boolean;
  alwaysExpanded?: boolean;
}

export const FilterByEntityLayout: React.FC<IFilterByEntityLayoutProps> = ({
  expanded,
  children,
  title,
  filterComponent,
  alwaysExpanded,
}) => {
  return (
    // @Todo will need rethinking about accordion coupled with filter
    <Accordion
      fullWidth
      alwaysExpanded={alwaysExpanded}
      defaultExpanded={expanded}
      cardTitleContent={
        <Grid container justifyContent="space-between">
          <Grid>
            <Typography level="h4">{title}</Typography>
          </Grid>
          <Grid>{filterComponent}</Grid>
        </Grid>
      }
    >
      {children}
    </Accordion>
  );
};
