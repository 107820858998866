import { chain } from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import { createSelector } from 'reselect';
import {
  AnyObject,
  IdsArray,
  IPricingModel,
  IStoreState,
  IUpdatePricing,
  IUpdatePricingRangeWithoutMeta,
  PricingTypeObject,
} from '../types';

export const getPricingRefreshKey = (state: IStoreState) =>
  state.pricing.refreshKey;

/**
 * Get server error
 * @param pricing - State pricing
 */
export const getServerError = ({ pricing }: IStoreState) => pricing.error;

/**
 * Get pricing list
 * @param pricing - State pricing
 */
export const getPricingList = ({ pricing }: IStoreState) => pricing.list;

/**
 * Get pricing list and count by siteId
 * @param siteId - number
 */
export const getPricingListBySiteId = createSelector(
  getPricingList,
  (pricingList) => (siteId: number) => {
    const pricingListById = get(pricingList, [
      siteId,
      'data',
    ]) as IPricingModel[];
    const pricingCountById = get(pricingList, [siteId, 'count']);

    return {
      pricingListById,
      pricingCountById,
    };
  },
);

/**
 * Get pricing options for combobox by siteId
 * @param siteId - number
 */
export const getMetaTagsOptionsById = createSelector(
  getPricingList,
  (pricingList) => (siteId: number) => {
    const metaTagsList = chain(get(pricingList, [siteId, 'data'], []))
      .map((pricing) => ({
        ...omit(pricing, [
          'id',
          'price',
          'incentivePrice',
          'overtimePrice',
          'minUph',
          'maxUph',
          'siteId',
          'description',
          'metadata',
          'deletedAt',
        ]),
      }))
      .uniqWith(isEqual)
      .value();

    if (!metaTagsList.length) {
      return [];
    }

    return Object.keys(metaTagsList[0]).map((tag) => {
      const optionsByField = metaTagsList.map((option) => ({
        id: option[tag],
        name: option[tag],
      }));
      return {
        field: tag,
        optionsByField,
      };
    });
  },
);

/**
 * Get pricing by array of ids
 * @param pricing - State pricing
 */
export const getPricingByIds = createSelector(
  getPricingList,
  (pricing) => (siteId?: number, ids?: IdsArray) =>
    reduce(
      get(pricing, [siteId ?? NaN, 'data'], []),
      (acc, cur) => {
        if ((ids ?? []).includes(`${(cur as IUpdatePricing).id}`)) {
          // omit columns we should not request with
          acc.push(
            omit(cur as IUpdatePricing, [
              'site',
              'pricingMetadata',
            ]) as IUpdatePricing,
          );
        }
        return acc;
      },
      [] as IUpdatePricing[],
    ),
);

export const getPrycingByMetatags = createSelector(
  getPricingList,
  (pricing) => (siteId: number, metatags: AnyObject) =>
    reduce(
      get(pricing, [siteId, 'data'], []),
      (acc, cur) => {
        if (
          isEqual(
            metatags,
            omit(cur, [
              'id',
              'deletedAt',
              'siteId',
              'price',
              'incentivePrice',
              'overtimePrice',
              'minUph',
              'maxUph',
              'description',
              'metadata',
            ]),
          )
        ) {
          const pricingItem = pick(cur as IUpdatePricing, [
            'id',
            'price',
            'incentivePrice',
            'overtimePrice',
            'minUph',
            'maxUph',
            'description',
          ]);
          acc.push({
            id: pricingItem.id,
            price: pricingItem.price,
            incentivePrice: pricingItem.incentivePrice,
            overtimePrice: pricingItem.overtimePrice,
            minUph: pricingItem.minUph,
            maxUph: pricingItem.maxUph,
          } as IUpdatePricingRangeWithoutMeta);
        }
        return acc;
      },
      [] as IUpdatePricingRangeWithoutMeta[],
    ),
);

/**
 * Get combobox list
 */
export const getPricingComboboxList = ({ pricing }: IStoreState) =>
  map(pricing.comboboxList, (pricingItem) => ({
    id: pricingItem.id,
    name: `price/incentive/OT: ${pricingItem.price}/${pricingItem.incentivePrice}/${pricingItem.overtimePrice} - min/max: ${pricingItem.minUph}/${pricingItem.maxUph}`,
  }));

/**
 * Get Pricing types list
 */
export const getPricingTypesList = ({
  pricing,
}: IStoreState): PricingTypeObject => pricing.pricingTypesList;

/**
 * Get Pricing meta tags
 */
export const getPricingMetaTags = ({ pricing }: IStoreState) =>
  reduce(
    pricing.metaTags,
    (acc, cur) => {
      acc[cur.siteId] = acc[cur.siteId] ?? [];
      acc[cur.siteId].push(cur.name);
      return acc;
    },
    {} as {
      [id: number]: string[];
    },
  );

export const getPricingMetaTagsBySiteId = createSelector(
  getPricingMetaTags,
  (metaTagNames) => (siteId: number) =>
    siteId && metaTagNames[siteId] ? metaTagNames[siteId] : [],
);

/**
 * Get Pricing loader
 */
export const getPricingLoading = ({ pricing }: IStoreState) =>
  pricing.is_data_requested;
