import React from 'react';
import { useTranslation } from 'react-i18next';
import PayboardSummaryHistorical from './panels/PayboardSummaryHistorical';
import PayboardSummaryHistoricalDetailed from './panels/PayboardSummaryHistoricalDetailed';
import PayboardKronosHistorical from './panels/PayboardKronosHistorical';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';

export const PayboardHistorical = () => {
  const { t } = useTranslation();

  const hasUserAccessToPayboardHistoricalTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_HISTORICAL_TAB,
  );
  const hasUserAccessToPayboardDetailedHistoricalTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_DETAILED_HISTORICAL_TAB,
  );
  const hasUserAccessToPayboardKronosHistoricalTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_PAYBOARD_KRONOS_HISTORICAL_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('payboard.summary_historical'),
          hasUserAccess: hasUserAccessToPayboardHistoricalTab,
          component: <PayboardSummaryHistorical />,
        },
        {
          title: t('payboard.detailed_historical'),
          hasUserAccess: hasUserAccessToPayboardDetailedHistoricalTab,
          component: <PayboardSummaryHistoricalDetailed />,
        },
        {
          title: t('payboard.kronos_historical'),
          hasUserAccess: hasUserAccessToPayboardKronosHistoricalTab,
          component: <PayboardKronosHistorical />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToPayboardDetailedHistoricalTab,
      hasUserAccessToPayboardHistoricalTab,
      hasUserAccessToPayboardKronosHistoricalTab,
      t,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
