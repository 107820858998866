import React from 'react';
import { Card, Typography } from '@mui/joy';

interface IFieldsContainer {
  title: string;
}

export const FieldsContainer: React.FC<IFieldsContainer> = ({
  title,
  children,
}) => {
  return (
    <Card sx={{ flexGrow: 1, marginRight: 0.5, marginLeft: 0.5, padding: 2 }}>
      <Typography level="h4" sx={{ textAlign: 'center' }}>
        {title}
      </Typography>

      {children}
    </Card>
  );
};
