import React from 'react';
import { get } from 'lodash';
import { AnyObject } from '../../modules/types';
import { ErrorMode } from './types';
import Range, { IRangeProps, IRangeValue } from '../Range/Range';

interface IProps extends Omit<IRangeProps, 'onChange'> {
  formik: AnyObject;
  errorMode?: ErrorMode;
}

function FormikRange(props: IProps) {
  const { id, formik, errorMode = 'onFormSubmit', ...restProps } = props;

  const option = get(formik.values, id);
  const error = get(formik.errors, id);
  const touched = get(formik.touched, id);
  // TODO: should implement errors
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showError =
    errorMode === 'onFieldChange' ? !!error : touched && !!error;

  const handleChange = (values: IRangeValue) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, values);
  };

  React.useEffect(() => {
    // in update entity forms we remove error manually since
    // validateOnChange false by default for all update forms
    if (errorMode === 'onFieldChange') {
      const value = get(formik.values, id);
      const error = get(formik.errors, id);

      if (Boolean(value) && Boolean(error)) {
        formik.setFieldError(id, undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMode, get(formik.values, id)]);

  return (
    <Range
      {...restProps}
      id={id}
      value={option || ''}
      onChange={handleChange}
    />
  );
}

export default React.memo(FormikRange, (prev, cur) => {
  return (
    prev.id === cur.id &&
    prev.errorMode === cur.errorMode &&
    get(prev.formik.values, prev.id) === get(cur.formik.values, cur.id) &&
    get(prev.formik.errors, prev.id) === get(cur.formik.errors, cur.id) &&
    get(prev.formik.touched, prev.id) === get(cur.formik.touched, cur.id)
  );
});
