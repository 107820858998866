import React from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { AnyObject } from 'src/modules/types';

interface IAutomaticPointsRejectReasonFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(filters: AnyObject): void;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const AutomaticPointsRejectReasonFilter: React.FC<IAutomaticPointsRejectReasonFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'name',
            labelPrefix: t('automaticPointsRejectReason.name'),
          })}
          {...getFilterCommonPropsByFilterName('name')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('automaticPointsRejectReason.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
