import { ISagaActionBind } from '../../types';
import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthDailyUphBySiteAndShiftListRequest,
    saga: createListSaga<ICardinalHealthDailyReportModel>({
      apiCall: Api.CardinalHealthDailyUphBySiteAndShift.list,
      actionSuccess: actions.getCardinalHealthDailyUphBySiteAndShiftListSuccess,
      actionFailed: actions.getCardinalHealthDailyUphBySiteAndShiftListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthDailyUphBySiteAndShiftCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthDailyUphBySiteAndShift.count,
      actionSuccess:
        actions.getCardinalHealthDailyUphBySiteAndShiftCountSuccess,
      actionFailed: actions.getCardinalHealthDailyUphBySiteAndShiftCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthDailyUphBySiteAndShiftRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthDailyUphBySiteAndShift.export,
      actionSuccess: actions.exportCardinalHealthDailyUphBySiteAndShiftSuccess,
      actionFailed: actions.exportCardinalHealthDailyUphBySiteAndShiftFailed,
    }),
  },
];
