import React, { useState } from 'react';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  useWikiList,
  useWikiPagination,
  useWikiViewPageById,
} from 'src/modules/utils/hooks/wiki-setup.hooks';

import EnhancedTable, {
  HeadCell,
  IEnhancedTableProps,
} from 'src/components/EnhancedTable';

import { IWikiPage } from 'src/modules/types/wiki-setup';
import WikiViewPageDialog from 'src/pages/Wiki/WikiViewPageDialog';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { getQueryParams } from 'src/modules/utils';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';

const WikiViewList: React.FC = () => {
  const { t } = useTranslation();
  const { wikiList, onUpdate, onDelete } = useWikiList();
  const setPagination = useWikiPagination();

  const [viewModal, setViewModal] = useState<boolean>(false);
  const [pageId, setPageId] = useState<string>();

  const { data: selectedPage, isLoading, isError } = useWikiViewPageById(
    pageId,
  );

  const customPagination = React.useMemo<
    IEnhancedTableProps<IWikiPage>['customPagination']
  >(
    () => ({
      hideNextButton: !wikiList?._links?.next,
      hidePrevButton: !wikiList?._links?.prev,
      onPageChange: (event, newPage, item) => {
        const type = item?.type === 'previous' ? 'prev' : 'next';
        const { cursor } = getQueryParams(wikiList?._links[type] || '');

        if (cursor) {
          setPagination(cursor as string, type);
        }
      },
    }),
    [setPagination, wikiList?._links],
  );

  const headCells: HeadCell[] = [
    {
      id: 'title',
      disablePadding: false,
      label: t('common.title'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'title',
      label: t('common.title'),
      operator: 'like',
    },
  ];

  // handle row click
  const hanleRowClick = async (page: Partial<IWikiPage>) => {
    setPageId(page.id);
    setViewModal(true);
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <WikiViewPageDialog
          page={selectedPage}
          isOpen={viewModal}
          isLoading={isLoading}
          isError={isError}
          onClose={setViewModal.bind(null, false)}
        />

        <EnhancedTable
          data={wikiList?.results || []}
          count={wikiList?.size ?? 0}
          selectIndex="id"
          tableName={t('knowledge_setup.table_name')}
          filters={filters}
          headCells={headCells}
          onSync={noop}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onRowClick={hanleRowClick}
          customPagination={customPagination}
          disableSelection
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default WikiViewList;
