import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IUpdateProduction } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createProductionScheme } from './create-production.scheme';

export const updateProductionScheme: JSONSchemaType<IUpdateProduction[]> = {
  type: 'array',
  items: {
    ...createProductionScheme,
    type: createProductionScheme.type,
    required: [...createProductionScheme.required, 'id'],
    properties: {
      ...createProductionScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
