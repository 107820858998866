import { capitalizeFirstLetter } from 'src/modules/utils/helpers/formatters';
import { clients } from '../modules/constants';

export type ManageEntitiesConfigItem = {
  create: {
    title: string;
    id: string;
  };
  update: {
    title: string;
    id: string;
  };
  delete: {
    title: string;
    id: string;
  };
  export: {
    title: string;
    id: string;
  };
};

export const MANAGE_ENTITIES_CONCATENATED_ID_SEPARATOR = '::';

export const generateManageEntitiesConfigItemId = (
  entityKey: string,
  operation:
    | 'create'
    | 'update'
    | 'delete'
    | 'remove'
    | 'move_to_another_department'
    | 'logout'
    | 'export'
    | 'create_record'
    | 'update_record'
    | 'upload'
    | 'sync'
    | 'manage'
    | 'approve'
    | 'unapprove'
    | 'approve_week'
    | 'approve_month'
    | 'reject_week'
    | 'reject_month'
    | 'reject'
    | 'create_week'
    | 'create_month'
    | 'update_week'
    | 'update_month'
    | 'delete_week'
    | 'delete_month'
    | 'updateDesiredEmployeesNumber'
    | 'send_sms_to_employee'
    | 'moved_to_missed'
    | 'export_day'
    | 'export_range'
    | 'create_settings'
    | 'archive'
    | 'preview_week'
    | 'preview_month',
) => {
  return `${entityKey}${MANAGE_ENTITIES_CONCATENATED_ID_SEPARATOR}${operation}`;
};

export const mapEntityKeyToEntityName = (entityKey: string) => {
  const stringWithSpaces = entityKey.replace(/_/g, ' ');

  return capitalizeFirstLetter(stringWithSpaces);
};

const generateManageEntitiesConfigItem = <T extends string>(entityKey: T) => {
  return {
    [entityKey]: {
      create: {
        title: `${mapEntityKeyToEntityName(entityKey)} create`,
        id: generateManageEntitiesConfigItemId(entityKey, 'create'),
      },
      update: {
        title: `${mapEntityKeyToEntityName(entityKey)} update`,
        id: generateManageEntitiesConfigItemId(entityKey, 'update'),
      },
      delete: {
        title: `${mapEntityKeyToEntityName(entityKey)} delete`,
        id: generateManageEntitiesConfigItemId(entityKey, 'delete'),
      },
    },
  } as Record<T, ManageEntitiesConfigItem>;
};

export const manageEntitiesConfig = {
  ...{
    employee: {
      create: {
        title: `${mapEntityKeyToEntityName('employee')} create`,
        id: generateManageEntitiesConfigItemId('employee', 'create'),
      },
      update: {
        title: `${mapEntityKeyToEntityName('employee')} update`,
        id: generateManageEntitiesConfigItemId('employee', 'update'),
      },
      delete: {
        title: `${mapEntityKeyToEntityName('employee')} delete`,
        id: generateManageEntitiesConfigItemId('employee', 'delete'),
      },
      logout: {
        title: `${mapEntityKeyToEntityName('employee')} logout employee`,
        id: generateManageEntitiesConfigItemId('employee', 'logout'),
      },
      export: {
        title: `${mapEntityKeyToEntityName('employee')} export employee`,
        id: generateManageEntitiesConfigItemId('employee', 'export'),
      },
      move_to_another_department: {
        title: `${mapEntityKeyToEntityName(
          'employee',
        )} move to another department`,
        id: generateManageEntitiesConfigItemId(
          'employee',
          'move_to_another_department',
        ),
      },
      send_sms_to_employee: {
        title: `${mapEntityKeyToEntityName('employee')} send sms`,
        id: generateManageEntitiesConfigItemId(
          'employee',
          'send_sms_to_employee',
        ),
      },
    },
  },
  ...generateManageEntitiesConfigItem('employee_skillFlex'),
  ...generateManageEntitiesConfigItem('question'),
  employee_questions: {
    create: {
      title: `${mapEntityKeyToEntityName('employee_questions')} create`,
      id: generateManageEntitiesConfigItemId('employee_questions', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('employee_questions')} update`,
      id: generateManageEntitiesConfigItemId('employee_questions', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('employee_questions')} delete`,
      id: generateManageEntitiesConfigItemId('employee_questions', 'delete'),
    },
  },
  department: {
    create: {
      title: `${mapEntityKeyToEntityName('department')} create`,
      id: generateManageEntitiesConfigItemId('department', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('department')} update`,
      id: generateManageEntitiesConfigItemId('department', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('department')} delete`,
      id: generateManageEntitiesConfigItemId('department', 'delete'),
    },
    updateDesiredEmployeesNumber: {
      title: `${mapEntityKeyToEntityName(
        'department',
      )} update desired employees number`,
      id: generateManageEntitiesConfigItemId(
        'department',
        'updateDesiredEmployeesNumber',
      ),
    },
  },
  takt_settings: {
    create: {
      title: `${mapEntityKeyToEntityName('takt_settings')} create`,
      id: generateManageEntitiesConfigItemId('takt_settings', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('takt_settings')} update`,
      id: generateManageEntitiesConfigItemId('takt_settings', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('takt_settings')} delete`,
      id: generateManageEntitiesConfigItemId('takt_settings', 'delete'),
    },
    export_day: {
      title: `${mapEntityKeyToEntityName('takt_settings')} export day`,
      id: generateManageEntitiesConfigItemId('takt_settings', 'export_day'),
    },
    export_range: {
      title: `${mapEntityKeyToEntityName('takt_settings')} export day`,
      id: generateManageEntitiesConfigItemId('takt_settings', 'export_range'),
    },
  },
  position: {
    create: {
      title: `${mapEntityKeyToEntityName('position')} create`,
      id: generateManageEntitiesConfigItemId('position', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('position')} update`,
      id: generateManageEntitiesConfigItemId('position', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('position')} delete`,
      id: generateManageEntitiesConfigItemId('position', 'delete'),
    },
    updateDesiredEmployeesNumber: {
      title: `${mapEntityKeyToEntityName(
        'position',
      )} update desired employees number`,
      id: generateManageEntitiesConfigItemId(
        'position',
        'updateDesiredEmployeesNumber',
      ),
    },
  },
  ...generateManageEntitiesConfigItem('client'),
  incentivePrice: {
    create: {
      title: `${mapEntityKeyToEntityName('incentivePrice')} create`,
      id: generateManageEntitiesConfigItemId('incentivePrice', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('incentivePrice')} update`,
      id: generateManageEntitiesConfigItemId('incentivePrice', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('incentivePrice')} delete`,
      id: generateManageEntitiesConfigItemId('incentivePrice', 'delete'),
    },
    create_settings: {
      title: `${mapEntityKeyToEntityName('incentivePrice')} create settings`,
      id: generateManageEntitiesConfigItemId(
        'incentivePrice',
        'create_settings',
      ),
    },
  },
  shift: {
    create: {
      title: `${mapEntityKeyToEntityName('shift')} create`,
      id: generateManageEntitiesConfigItemId('shift', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('shift')} update`,
      id: generateManageEntitiesConfigItemId('shift', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('shift')} delete`,
      id: generateManageEntitiesConfigItemId('shift', 'delete'),
    },
    create_settings: {
      title: `${mapEntityKeyToEntityName('shift')} create settings`,
      id: generateManageEntitiesConfigItemId('shift', 'create_settings'),
    },
  },
  automaticPointsRejectReason: {
    create: {
      title: `${mapEntityKeyToEntityName(
        'automaticPointsRejectReason',
      )} create`,
      id: generateManageEntitiesConfigItemId(
        'automaticPointsRejectReason',
        'create',
      ),
    },
    update: {
      title: `${mapEntityKeyToEntityName(
        'automaticPointsRejectReason',
      )} update`,
      id: generateManageEntitiesConfigItemId(
        'automaticPointsRejectReason',
        'update',
      ),
    },
    delete: {
      title: `${mapEntityKeyToEntityName(
        'automaticPointsRejectReason',
      )} delete`,
      id: generateManageEntitiesConfigItemId(
        'automaticPointsRejectReason',
        'delete',
      ),
    },
  },
  ...generateManageEntitiesConfigItem('importing_settings'),
  site: {
    create: {
      title: `${mapEntityKeyToEntityName('site')} create`,
      id: generateManageEntitiesConfigItemId('site', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('site')} update`,
      id: generateManageEntitiesConfigItemId('site', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('site')} delete`,
      id: generateManageEntitiesConfigItemId('site', 'delete'),
    },
    archive: {
      title: `${mapEntityKeyToEntityName('site')} archive`,
      id: generateManageEntitiesConfigItemId('site', 'archive'),
    },
    updateDesiredEmployeesNumber: {
      title: `${mapEntityKeyToEntityName(
        'site',
      )} update desired employees number`,
      id: generateManageEntitiesConfigItemId(
        'site',
        'updateDesiredEmployeesNumber',
      ),
    },
  },
  ...generateManageEntitiesConfigItem('workdays'),
  ...generateManageEntitiesConfigItem('taktExportingSettings'),
  ...generateManageEntitiesConfigItem('staffingProvider'),
  ...generateManageEntitiesConfigItem('skill'),
  ...generateManageEntitiesConfigItem('role'),
  ...generateManageEntitiesConfigItem('standard'),
  ...generateManageEntitiesConfigItem('metaTag'),
  ...generateManageEntitiesConfigItem('baseline'),
  ...generateManageEntitiesConfigItem('areaBaseline'),
  ...generateManageEntitiesConfigItem('cpuBaseline'),
  ...generateManageEntitiesConfigItem('question_category'),
  ...generateManageEntitiesConfigItem('downtimeType'),
  ...generateManageEntitiesConfigItem('pointType'),
  ...generateManageEntitiesConfigItem('pointCollection'),
  ...generateManageEntitiesConfigItem('bom'),
  ...generateManageEntitiesConfigItem('user'),
  ...generateManageEntitiesConfigItem('pages'),
  ...generateManageEntitiesConfigItem('optiturnCredentials'),
  ...generateManageEntitiesConfigItem('terminationReason'),
  ...{
    point: {
      create: {
        title: `${mapEntityKeyToEntityName('point')} create`,
        id: generateManageEntitiesConfigItemId('point', 'create'),
      },
      update: {
        title: `${mapEntityKeyToEntityName('point')} update`,
        id: generateManageEntitiesConfigItemId('point', 'update'),
      },
      delete: {
        title: `${mapEntityKeyToEntityName('point')} delete`,
        id: generateManageEntitiesConfigItemId('point', 'delete'),
      },
      remove: {
        title: `${mapEntityKeyToEntityName('point')} remove`,
        id: generateManageEntitiesConfigItemId('point', 'remove'),
      },
    },
  },
  ...{
    automaticPoint: {
      approve: {
        title: `${mapEntityKeyToEntityName('automaticPoint')} approve`,
        id: generateManageEntitiesConfigItemId('automaticPoint', 'approve'),
      },
      reject: {
        title: `${mapEntityKeyToEntityName('automaticPoint')} reject`,
        id: generateManageEntitiesConfigItemId('automaticPoint', 'reject'),
      },
    },
  },
  ...{
    financial: {
      create_record: {
        title: `${mapEntityKeyToEntityName('financial')} create record`,
        id: generateManageEntitiesConfigItemId('financial', 'create_record'),
      },
      update_record: {
        title: `${mapEntityKeyToEntityName('financial')} update record`,
        id: generateManageEntitiesConfigItemId('financial', 'update_record'),
      },
    },
  },
  ...{
    [clients.target]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.target)} sync`,
        id: generateManageEntitiesConfigItemId(clients.target, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.target)} upload`,
        id: generateManageEntitiesConfigItemId(clients.target, 'upload'),
      },
    },
  },
  ...{
    [clients.expeditors]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.target)} sync`,
        id: generateManageEntitiesConfigItemId(clients.target, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.target)} upload`,
        id: generateManageEntitiesConfigItemId(clients.target, 'upload'),
      },
    },
  },
  ...{
    [clients.optoro]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.optoro)} sync`,
        id: generateManageEntitiesConfigItemId(clients.optoro, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.optoro)} upload`,
        id: generateManageEntitiesConfigItemId(clients.optoro, 'upload'),
      },
    },
  },
  ...{
    [clients.perryEllis]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.perryEllis)} sync`,
        id: generateManageEntitiesConfigItemId(clients.perryEllis, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.perryEllis)} upload`,
        id: generateManageEntitiesConfigItemId(clients.perryEllis, 'upload'),
      },
    },
  },
  ...{
    [clients.mcEntire]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.mcEntire)} sync`,
        id: generateManageEntitiesConfigItemId(clients.mcEntire, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.mcEntire)} upload`,
        id: generateManageEntitiesConfigItemId(clients.mcEntire, 'upload'),
      },
    },
  },
  ...{
    [clients.brookfield]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.brookfield)} sync`,
        id: generateManageEntitiesConfigItemId(clients.brookfield, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.brookfield)} upload`,
        id: generateManageEntitiesConfigItemId(clients.brookfield, 'upload'),
      },
    },
  },
  ...{
    [clients.taylorFarms]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.taylorFarms)} sync`,
        id: generateManageEntitiesConfigItemId(clients.taylorFarms, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.taylorFarms)} upload`,
        id: generateManageEntitiesConfigItemId(clients.taylorFarms, 'upload'),
      },
    },
  },
  ...{
    [clients.williamsSonoma]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.williamsSonoma)} sync`,
        id: generateManageEntitiesConfigItemId(clients.williamsSonoma, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.williamsSonoma)} upload`,
        id: generateManageEntitiesConfigItemId(
          clients.williamsSonoma,
          'upload',
        ),
      },
    },
  },
  ...{
    [clients.sony]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.sony)} sync`,
        id: generateManageEntitiesConfigItemId(clients.sony, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.sony)} upload`,
        id: generateManageEntitiesConfigItemId(clients.sony, 'upload'),
      },
    },
  },
  ...{
    [clients.nfi]: {
      sync: {
        title: `${mapEntityKeyToEntityName(clients.nfi)} sync`,
        id: generateManageEntitiesConfigItemId(clients.nfi, 'sync'),
      },
      upload: {
        title: `${mapEntityKeyToEntityName(clients.nfi)} upload`,
        id: generateManageEntitiesConfigItemId(clients.nfi, 'upload'),
      },
    },
  },
  ...{
    site_billing_details: {
      create: {
        title: `${mapEntityKeyToEntityName('site_billing_details')} create`,
        id: generateManageEntitiesConfigItemId(
          'site_billing_details',
          'create',
        ),
      },
      update: {
        title: `${mapEntityKeyToEntityName('site_billing_details')} update`,
        id: generateManageEntitiesConfigItemId(
          'site_billing_details',
          'update',
        ),
      },
    },
  },
  ...{
    ngroup_billing_details: {
      manage: {
        title: `${mapEntityKeyToEntityName('ngroup_billing_details')} manage`,
        id: generateManageEntitiesConfigItemId(
          'ngroup_billing_details',
          'manage',
        ),
      },
    },
  },
  ...{
    hourly_setting: {
      create: {
        title: `${mapEntityKeyToEntityName('hourly_setting')} create`,
        id: generateManageEntitiesConfigItemId('hourly_setting', 'create'),
      },
      update: {
        title: `${mapEntityKeyToEntityName('hourly_setting')} update`,
        id: generateManageEntitiesConfigItemId('hourly_setting', 'update'),
      },
      delete: {
        title: `${mapEntityKeyToEntityName('hourly_setting')} delete`,
        id: generateManageEntitiesConfigItemId('hourly_setting', 'delete'),
      },
    },
  },
  ...{
    additional_fee: {
      create: {
        title: `${mapEntityKeyToEntityName('additional_fee')} create`,
        id: generateManageEntitiesConfigItemId('additional_fee', 'create'),
      },
      update: {
        title: `${mapEntityKeyToEntityName('additional_fee')} update`,
        id: generateManageEntitiesConfigItemId('additional_fee', 'update'),
      },
      delete: {
        title: `${mapEntityKeyToEntityName('additional_fee')} delete`,
        id: generateManageEntitiesConfigItemId('additional_fee', 'delete'),
      },
    },
  },
  ...{
    additional_fee_category: {
      create: {
        title: `${mapEntityKeyToEntityName('additional_fee_category')} create`,
        id: generateManageEntitiesConfigItemId(
          'additional_fee_category',
          'create',
        ),
      },
      update: {
        title: `${mapEntityKeyToEntityName('additional_fee_category')} update`,
        id: generateManageEntitiesConfigItemId(
          'additional_fee_category',
          'update',
        ),
      },
      delete: {
        title: `${mapEntityKeyToEntityName('additional_fee_category')} delete`,
        id: generateManageEntitiesConfigItemId(
          'additional_fee_category',
          'delete',
        ),
      },
    },
  },
  ...{
    other_costs: {
      create: {
        title: `${mapEntityKeyToEntityName('other_costs')} create`,
        id: generateManageEntitiesConfigItemId('other_costs', 'create'),
      },
      update: {
        title: `${mapEntityKeyToEntityName('other_costs')} update`,
        id: generateManageEntitiesConfigItemId('other_costs', 'update'),
      },
      delete: {
        title: `${mapEntityKeyToEntityName('other_costs')} delete`,
        id: generateManageEntitiesConfigItemId('other_costs', 'delete'),
      },
    },
  },
  ...generateManageEntitiesConfigItem('support_request'),
  ...generateManageEntitiesConfigItem('production'),
  ...generateManageEntitiesConfigItem('employee_review'),
  ...generateManageEntitiesConfigItem('downtime'),
  ...generateManageEntitiesConfigItem('area'),
  ...generateManageEntitiesConfigItem('issue_type'),
  logtime: {
    create: {
      title: `${mapEntityKeyToEntityName('logtime')} create`,
      id: generateManageEntitiesConfigItemId('logtime', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('logtime')} update`,
      id: generateManageEntitiesConfigItemId('logtime', 'update'),
    },
    moved_to_missed: {
      title: `${mapEntityKeyToEntityName('logtime')} moved_to_missed`,
      id: generateManageEntitiesConfigItemId('logtime', 'moved_to_missed'),
    },
  },
  invoice: {
    create_week: {
      title: `${mapEntityKeyToEntityName('invoice')} create_week`,
      id: generateManageEntitiesConfigItemId('invoice', 'create_week'),
    },
    create_month: {
      title: `${mapEntityKeyToEntityName('invoice')} create_month`,
      id: generateManageEntitiesConfigItemId('invoice', 'create_month'),
    },
    update_week: {
      title: `${mapEntityKeyToEntityName('invoice')} update_week`,
      id: generateManageEntitiesConfigItemId('invoice', 'update_week'),
    },
    update_month: {
      title: `${mapEntityKeyToEntityName('invoice')} update_month`,
      id: generateManageEntitiesConfigItemId('invoice', 'update_month'),
    },
    delete_week: {
      title: `${mapEntityKeyToEntityName('invoice')} delete_week`,
      id: generateManageEntitiesConfigItemId('invoice', 'delete_week'),
    },
    delete_month: {
      title: `${mapEntityKeyToEntityName('invoice')} delete_month`,
      id: generateManageEntitiesConfigItemId('invoice', 'delete_month'),
    },
    approve_week: {
      title: `${mapEntityKeyToEntityName('invoice')} approve_week invoice`,
      id: generateManageEntitiesConfigItemId('invoice', 'approve_week'),
    },
    approve_month: {
      title: `${mapEntityKeyToEntityName('invoice')} approve_month invoice`,
      id: generateManageEntitiesConfigItemId('invoice', 'approve_month'),
    },
    reject_week: {
      title: `${mapEntityKeyToEntityName('invoice')} reject_week invoice`,
      id: generateManageEntitiesConfigItemId('invoice', 'reject_week'),
    },
    reject_month: {
      title: `${mapEntityKeyToEntityName('invoice')} reject_month invoice`,
      id: generateManageEntitiesConfigItemId('invoice', 'reject_month'),
    },
    preview_week: {
      title: `${mapEntityKeyToEntityName('invoice')} preview_week invoice`,
      id: generateManageEntitiesConfigItemId('invoice', 'preview_week'),
    },
    preview_month: {
      title: `${mapEntityKeyToEntityName('invoice')} preview_month invoice`,
      id: generateManageEntitiesConfigItemId('invoice', 'preview_month'),
    },
  },
  kpi_settings: {
    create: {
      title: `${mapEntityKeyToEntityName('kpi_settings')} create`,
      id: generateManageEntitiesConfigItemId('kpi_settings', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('kpi_settings')} update `,
      id: generateManageEntitiesConfigItemId('kpi_settings', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('kpi_settings')} delete `,
      id: generateManageEntitiesConfigItemId('kpi_settings', 'delete'),
    },
  },
  kpi: {
    create: {
      title: `${mapEntityKeyToEntityName('kpi')} create`,
      id: generateManageEntitiesConfigItemId('kpi', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('kpi')} update`,
      id: generateManageEntitiesConfigItemId('kpi', 'update'),
    },
  },
  incentive: {
    create: {
      title: `${mapEntityKeyToEntityName('incentive')} create`,
      id: generateManageEntitiesConfigItemId('incentive', 'create'),
    },
    approve: {
      title: `${mapEntityKeyToEntityName('incentive')} approve`,
      id: generateManageEntitiesConfigItemId('incentive', 'approve'),
    },
    unapprove: {
      title: `${mapEntityKeyToEntityName('incentive')} unapprove`,
      id: generateManageEntitiesConfigItemId('incentive', 'unapprove'),
    },
    updateTotalPayment: {
      title: `${mapEntityKeyToEntityName('incentive')} update`,
      id: generateManageEntitiesConfigItemId('incentive', 'update'),
    },
  },
  pricing: {
    create: {
      title: `${mapEntityKeyToEntityName('pricing')} create`,
      id: generateManageEntitiesConfigItemId('pricing', 'create'),
    },
    update: {
      title: `${mapEntityKeyToEntityName('pricing')} update`,
      id: generateManageEntitiesConfigItemId('pricing', 'update'),
    },
    delete: {
      title: `${mapEntityKeyToEntityName('pricing')} delete`,
      id: generateManageEntitiesConfigItemId('site', 'delete'),
    },
    create_settings: {
      title: `${mapEntityKeyToEntityName('pricing')} update settings`,
      id: generateManageEntitiesConfigItemId('pricing', 'create_settings'),
    },
  },
};
