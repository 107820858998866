import { useTranslation } from 'react-i18next';

export const useChecklistTaskFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      taskName: {
        target: 'task-name',
        content: t('guide.checklist.task_name'),
      },
      checklistDropdown: {
        target: 'checklist-dropdown',
        content: t('guide.checklist.checklist_dropdown'),
      },
      taskDescription: {
        target: 'task-description',
        content: t('guide.checklist.task_description'),
      },
      taskStartTime: {
        target: 'task-start-time',
        content: t('guide.checklist.task_start_time'),
      },
      taskEndTime: {
        target: 'task-end-time',
        content: t('guide.checklist.task_end_time'),
      },
      recurrenceStartDate: {
        target: 'recurrence-start-date',
        content: t('guide.checklist.recurrence_start_date'),
      },
      recurrence: {
        target: 'recurrence',
        content: t('guide.checklist.recurrence'),
      },
      taskStatus: {
        target: 'task-status',
        content: t('guide.checklist.task_status'),
      },
    },
  };
};

export const useChecklistFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      checklistName: {
        target: 'checklist-name',
        content: t('guide.checklist.checklist_name'),
      },
      userDropdown: {
        target: 'user-dropdown',
        content: t('guide.checklist.user_dropdown'),
      },
      siteDropdown: {
        target: 'site-dropdown',
        content: t('guide.checklist.site_dropdown'),
      },
      shiftDropdown: {
        target: 'shift-dropdown',
        content: t('guide.checklist.shift_dropdown'),
      },
    },
  };
};
