import React from 'react';
import {
  FilterPanelContext,
  FilterPanelModes,
} from 'src/components/FilterPanel/FilterPanelContext';

export const useFilterPanelFieldIsEditingModeState = () => {
  const { filterPanelMode } = React.useContext(FilterPanelContext);

  const [isFilterInEditingMode, setIsFilterInEditingMode] = React.useState(
    false,
  );

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.ready) {
      setIsFilterInEditingMode(false);
    }
  }, [filterPanelMode]);

  return { isFilterInEditingMode, setIsFilterInEditingMode };
};
