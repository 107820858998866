import React from 'react';
import { get, isNil, map, pick } from 'lodash';
import { AnyObject, PricingSettingsConfig } from '../../modules/types';
import DepartmentComboBox from '../../components/DepartmentComboBox';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import { ComboBoxOption } from '../../components/ComboBox';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';

interface IProps {
  siteId: number;
  index?: number;
  formik: AnyObject;
  settings: PricingSettingsConfig;
}

const PricingMetatags: React.FC<IProps> = ({
  formik,
  siteId,
  index,
  settings,
}) => {
  const getSettingsOptions = React.useCallback(
    (options?: string[]) =>
      (options?.map((i) => ({
        id: i,
        name: i,
      })) as unknown) as ComboBoxOption[],
    [],
  );

  const getInputIdentity = React.useCallback(
    (n: string) => {
      return !isNil(index) ? `${index}.${n}` : n;
    },
    [index],
  );

  React.useEffect(() => {
    Object.keys(get(formik, ['values', 'metadata'], [])).forEach((key) => {
      const range = [
        get(formik, ['values', 'metadata', key, 'min'], ''),
        get(formik, ['values', 'metadata', key, 'max'], ''),
      ];
      formik.setFieldValue(key, range.join('-'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.metadata]);

  const errors = React.useMemo(
    () => pick(formik.errors, Object.keys(settings)),
    [formik.errors, settings],
  );
  const values = React.useMemo(
    () => pick(formik.values, Object.keys(settings)),
    [formik.values, settings],
  );

  const fields = React.useMemo(
    () =>
      map(settings, ({ title, type, options, name, disabled }) => {
        if (disabled) {
          return null;
        }

        if (name.toLowerCase() === 'department') {
          return (
            <FormFieldContainer key={name}>
              <DepartmentComboBox
                key={getInputIdentity(name)}
                formik={formik}
                id={getInputIdentity(name)}
                siteId={siteId}
              />
            </FormFieldContainer>
          );
        }

        switch (type) {
          case 'combobox':
            return (
              <FormFieldContainer key={name}>
                <FormikCombobox
                  key={getInputIdentity(name)}
                  required
                  errorMode="onFormSubmit"
                  id={getInputIdentity(name)}
                  label={title}
                  options={getSettingsOptions(options)}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'range':
            return (
              <>
                <FormFieldContainer>
                  <FormikTextField
                    required
                    variant="outlined"
                    type="number"
                    fullWidth
                    label={`${title} (min)`}
                    id={getInputIdentity(`metadata[${name}].min`)}
                    name={getInputIdentity(`metadata[${name}].min`)}
                    formik={formik}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <FormikTextField
                    required
                    variant="outlined"
                    type="number"
                    fullWidth
                    label={`${title} (max)`}
                    id={getInputIdentity(`metadata[${name}].max`)}
                    name={getInputIdentity(`metadata[${name}].max`)}
                    formik={formik}
                  />
                </FormFieldContainer>
              </>
            );
          case 'string':
          default:
            return (
              <FormFieldContainer>
                <FormikTextField
                  required
                  key={getInputIdentity(name)}
                  variant="outlined"
                  fullWidth
                  label={title}
                  id={getInputIdentity(name)}
                  name={getInputIdentity(name)}
                  formik={formik}
                />
              </FormFieldContainer>
            );
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteId, settings, values, errors],
  );

  return <>{fields}</>;
};

export default PricingMetatags;
