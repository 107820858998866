import React from 'react';
import { Grid } from '@mui/material';
import { Alert } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { useExceptionsFromEmployeesHeadCells } from 'src/modules/utils/hooks/exceptionsFromEmployees.hooks';
import { ClientKey } from 'src/modules/constants';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject } from 'src/modules/types';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { MUI_SIZE_12 } from 'src/config';
import { useEmployeesInclusion } from 'src/modules/utils';

interface IExceptionFromEmployeesReportProps {
  client: ClientKey;
  tableName: string;
  siteId?: number;
  employeeId?: number;
}

export const ExceptionsFromEmployeesReport: React.FC<IExceptionFromEmployeesReportProps> = ({
  client,
  tableName,
  employeeId,
  siteId,
}) => {
  const { t } = useTranslation();

  const reportUrl = React.useMemo(
    () => `/${client}/exceptions/production/from-employee`,
    [client],
  );

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'id',
  };

  const include = useEmployeesInclusion();

  const where = React.useMemo(
    () => ({
      siteId,
      employeeId,
    }),
    [siteId, employeeId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<AnyObject>(
    reportUrl,
    {
      data: [],
      count: 0,
    },
  );

  const isSiteSelected = React.useMemo(() => !!siteId, [siteId]);

  const {
    headCells,
    headCellsOrderDetails,
  } = useExceptionsFromEmployeesHeadCells();

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    include,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    ...(isSiteSelected ? { fetchData } : {}),
    setPage,
    where,
    limit,
    include,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  return (
    <>
      {!isSiteSelected && (
        <Grid container={true}>
          <Grid item={true} xs={MUI_SIZE_12}>
            <Alert variant="soft">{t('reports.select_site_from_filter')}</Alert>
          </Grid>
        </Grid>
      )}
      {isSiteSelected && (
        <ReportTable
          title={tableName}
          data={data.data as any}
          count={data.count as number}
          isDataLoading={isDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
