import { IConfirmForgotPasswordRequest } from '../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';

export const confirmForgotPasswordScheme: PartialSchema<
  Omit<IConfirmForgotPasswordRequest, 'resetToken'> & {
    confirmNewPassword: { $data: string } | string;
  }
> = {
  type: 'object',
  required: ['newPassword', 'confirmNewPassword', 'email'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    email: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      format: 'email',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        format: i18n.t('error.validation.email'),
      },
    },
    newPassword: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    confirmNewPassword: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      const: {
        $data: '1/newPassword',
      },
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        const: i18n.t('error.validation.confirm_password_not_match'),
      },
    },
  },
};
