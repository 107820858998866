import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useReportEmployeeDetailsDailyChartDataEffect } from 'src/modules/utils/hooks/common/reports';
import { Box } from '@mui/joy';
import {
  highchartsReactComponentProps,
  useChartRequiredUtils,
} from 'src/components/Charts/common';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { IFilterItemData } from 'src/components/Filter';

interface IByEmployeeDetailsDailyChartReportProps {
  reportUrl: string;
  where?: IFilterItemData;
}

export const ByEmployeeDetailsDailyChartReport = ({
  reportUrl,
  where,
}: IByEmployeeDetailsDailyChartReportProps) => {
  const {
    chartRef,
    exportChart,
    toggleFullScreen,
    chartHeight,
  } = useChartRequiredUtils();

  const requestData = React.useMemo(() => ({ reportUrl, filter: where }), [
    reportUrl,
    where,
  ]);

  const {
    data: options,
    isDataLoading,
  } = useReportEmployeeDetailsDailyChartDataEffect(requestData);

  if (!options) {
    return null;
  }

  return (
    <ChartContainer>
      <ChartContainerHeader>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() =>
              exportChart('weekly_gross_margin_by_site_percentage')
            }
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isDataLoading}
        showNoData={!options.series?.length && !isDataLoading}
      >
        <Box
          sx={{
            minWidth: '100%',
            height: chartHeight,
          }}
        >
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            containerProps={highchartsReactComponentProps.containerProps}
          />
        </Box>
      </ChartContainerBody>
    </ChartContainer>
  );
};
