import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useTranslation } from 'react-i18next';
import { useValidate } from '../../../../../../modules/utils';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { createAdditionalFeeCategoryScheme } from '../../../../../../modules/schemes';
import { createAdditionalFeeCategoryRequest } from '../../../../../../modules/actions';
import { IFilterData, IWhere } from 'src/modules/types';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';

const initialValues = {
  name: '',
  description: '',
  siteId: NaN,
};

interface ICreateAdditionalFeeCategory {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateAdditionalFeeCategory = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateAdditionalFeeCategory) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(createAdditionalFeeCategoryScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createAdditionalFeeCategoryRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('additional-fee.create_fee_category')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              variant="outlined"
              label={t('common.site')}
              name="siteId"
              formik={formik}
              required
            />
          </FormFieldContainer>
          <FormFieldContainer>
            <FormikTextField
              id="name"
              variant="outlined"
              label={t('additional-fee.name')}
              name="name"
              formik={formik}
              fullWidth
              required
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="description"
              variant="outlined"
              fullWidth
              label={t('additional-fee.description')}
              name="description"
              formik={formik}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default CreateAdditionalFeeCategory;
