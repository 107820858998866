import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalDialog, { IDialogModalProps } from 'src/components/ModalDialog';

export type IRestoreEntityFormModalProps = IDialogModalProps;

export const RestoreEntityFormModal: React.FC<IRestoreEntityFormModalProps> = ({
  children,
  ...dialogModalProps
}) => {
  const { t } = useTranslation();

  return (
    <ModalDialog okLabel={t('common.restore')} {...dialogModalProps}>
      {children}
    </ModalDialog>
  );
};
