import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { IFilterWhere, IHookyFolksModel } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';

export const useAbsenteeRealtimeTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      employeeName: {
        value: '',
        property: 'employeeName',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
      shiftId: {
        value: '',
        property: 'shiftId',
        operator: 'eq' as const,
      },
      departmentId: {
        value: '',
        property: 'departmentId',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const useAbsenteeRealtimeComposeWhere = (
  where: Partial<IHookyFolksModel>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      ...(where.employeeName
        ? {
            employeeName: {
              like: `%${where.employeeName}%`,
            },
          }
        : {}),
      ...(where.badge
        ? {
            badge: {
              like: `%${where.badge}%`,
            },
          }
        : {}),
      ...(where.shiftId
        ? {
            shiftId: where.shiftId,
          }
        : {}),
      ...(where.departmentId
        ? {
            departmentId: where.departmentId,
          }
        : {}),
    }),
    [
      selectedSites,
      where.siteId,
      where.employeeName,
      where.badge,
      where.shiftId,
      where.departmentId,
    ],
  );
};

export const useAbsenteeRealtime = (
  reportUrl: string,
  where: IFilterWhere,
  ignoreInitialRequest: boolean,
) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('hookyFolks.date'),
      },
      {
        id: 'employeeName',
        orderConfig: { orderBy: 'employeeName' },
        label: t('hookyFolks.employeeName'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('hookyFolks.badge'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('hookyFolks.siteName'),
      },
      {
        id: 'employeeShiftName',
        orderConfig: { orderBy: 'employeeShiftName' },
        label: t('hookyFolks.employeeShiftName'),
      },
      {
        id: 'shiftName',
        orderConfig: { orderBy: 'shiftName' },
        label: t('hookyFolks.shiftName'),
      },
      {
        id: 'departmentName',
        orderConfig: { orderBy: 'departmentName' },
        label: t('hookyFolks.departmentName'),
      },
      {
        id: 'active',
        orderConfig: { orderBy: 'active' },
        label: t('hookyFolks.active'),
      },
      {
        id: 'supervisorName',
        orderConfig: { orderBy: 'supervisorName' },
        label: t('hookyFolks.supervisorName'),
      },
      {
        id: 'supervisorEmail',
        orderConfig: { orderBy: 'supervisorEmail' },
        label: t('hookyFolks.supervisorEmail'),
      },
      {
        id: 'overnight',
        orderConfig: { orderBy: 'overnight' },
        label: t('hookyFolks.overnight'),
      },
      {
        id: 'scheduled',
        orderConfig: { orderBy: 'scheduled' },
        label: t('hookyFolks.scheduled'),
      },
    ],
    [t],
  );

  return useBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'date',
    ignoreInitialRequest,
  });
};
