import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createRoleRequest } from '../../modules/actions';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import {
  useBrowserHistoryFunctions,
  useFetchRolesCombobox,
  useValidate,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';

import { createRoleScheme } from '../../modules/schemes/roles';
import { ICreateRoleRequest } from '../../modules/types';
import FormikCheckbox from '../../components/Formik/FormikCheckbox';
import { getRolesComboboxList } from '../../modules/selectors/role';
import { ComboBoxOption } from '../../components/ComboBox';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import { additionalUserProperties } from '../../config/userFields';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const initialValues: ICreateRoleRequest = {
  name: '',
  shouldExtend: false,
};

const RolesCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  // create dispatcher
  const dispatcher = useDispatch();

  const fetchRolesCombobox = useFetchRolesCombobox();

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchRolesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch clients list from store
  const roles = useSelector(getRolesComboboxList, shallowEqual);

  const validate = useValidate(createRoleScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createRoleRequest({ data, navigate }));
    },
  });

  const checkboxes = React.useMemo(
    () =>
      additionalUserProperties.map((i) => (
        <FormFieldContainer key={i}>
          <FormikCheckbox id={i} formik={formik} label={t(`roles.${i}`)} />
        </FormFieldContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, t],
  );

  const showToExtend = React.useMemo(() => !!formik.values.shouldExtend, [
    formik.values.shouldExtend,
  ]);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('roles.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id="name"
              label={t('roles.name')}
              name="name"
              autoComplete="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id="level"
              name="level"
              required={true}
              formik={formik}
              variant="outlined"
              fullWidth={true}
              label={t('roles.level')}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikCheckbox
              id="shouldExtend"
              formik={formik}
              label={t('roles.shouldExtend')}
            />
          </FormFieldContainer>

          {showToExtend && (
            <FormFieldContainer>
              <FormikCombobox
                id="toExtend"
                placeholder={t('roles.toExtend')}
                options={roles as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>
          )}

          <Typography variant="subtitle1">
            {t('roles.additional_fields')}
          </Typography>

          {checkboxes}

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default RolesCreate;
