import { ISagaActionBind } from '../../types';
import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthDailyUphBySiteAndEmployeeListRequest,
    saga: createListSaga<ICardinalHealthDailyReportModel>({
      apiCall: Api.CardinalHealthDailyUphBySiteAndEmployee.list,
      actionSuccess:
        actions.getCardinalHealthDailyUphBySiteAndEmployeeListSuccess,
      actionFailed:
        actions.getCardinalHealthDailyUphBySiteAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthDailyUphBySiteAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthDailyUphBySiteAndEmployee.count,
      actionSuccess:
        actions.getCardinalHealthDailyUphBySiteAndEmployeeCountSuccess,
      actionFailed:
        actions.getCardinalHealthDailyUphBySiteAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthDailyUphBySiteAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthDailyUphBySiteAndEmployee.export,
      actionSuccess:
        actions.exportCardinalHealthDailyUphBySiteAndEmployeeSuccess,
      actionFailed: actions.exportCardinalHealthDailyUphBySiteAndEmployeeFailed,
    }),
  },
];
