import { Transforms, Editor, Range, Element } from 'slate';

const createTableCell = (text: string) => {
  return {
    type: 'table-cell',
    children: [{ text }],
  };
};

const createRow = (cellText: string[]) => {
  const newRow = Array.from(cellText, (value) => createTableCell(value));
  return {
    type: 'table-row',
    children: newRow,
  };
};

const createTableNode = (cellText: string[][]) => {
  const tableChildren = Array.from(cellText, (value) => createRow(value));
  const tableNode = {
    type: 'table',
    children: tableChildren,
  };
  return tableNode;
};

export class TableUtil {
  editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }

  insertTable = (rows: any, columns: any) => {
    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        (n as any).type === 'table',
      mode: 'highest',
    });

    if (tableNode) return;
    if (!rows || !columns) {
      return;
    }
    const cellText = Array.from({ length: rows }, () =>
      Array.from({ length: columns }, () => ''),
    );
    const newTable = createTableNode(cellText);

    Transforms.insertNodes(this.editor, newTable, {
      mode: 'highest',
    });
    Transforms.insertNodes(
      this.editor,
      { type: 'paragraph', children: [{ text: '' }] } as any,
      { mode: 'highest' },
    );
  };

  insertCells = (tableNode: any, path: any, action: any) => {
    let existingText = Array.from(tableNode.children, (rows) =>
      Array.from(
        (rows as any).children,
        (arr) => (arr as any).children[0].text,
      ),
    );
    const columns = existingText[0].length;
    if (action === 'row') {
      existingText.push(Array(columns).fill(''));
    } else {
      existingText = Array.from(existingText, (item) => {
        item.push('');
        return item;
      });
    }
    const newTable = createTableNode(existingText);
    Transforms.insertNodes(this.editor, newTable, {
      at: path,
    });
  };

  removeTable = () => {
    Transforms.removeNodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        (n as any).type === 'table',
      mode: 'highest',
    });
  };

  insertRow = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (n as any).type === 'table',
      });
      if (tableNode) {
        const [oldTable, path] = tableNode;
        this.removeTable();
        this.insertCells(oldTable, path, 'row');
      }
    }
  };

  insertColumn = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (n as any).type === 'table',
      });
      if (tableNode) {
        const [oldTable, path] = tableNode;
        this.removeTable();
        this.insertCells(oldTable, path, 'columns');
      }
    }
  };
}
