import { IUpdateQuestionCategoryRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { createQuestionCategoryScheme } from './create-question-category.scheme';

export const updateQuestionCategoriesScheme: PartialSchema<
  IUpdateQuestionCategoryRequest[]
> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createQuestionCategoryScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createQuestionCategoryScheme.properties,
    },
  },
};
