import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  INgroupBillingDetailsModel,
  INgroupBillingDetailsState,
} from '../types';

// default state
const defaultState: INgroupBillingDetailsState = {
  refreshKey: 0,
  list: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushNgroupBillingDetailsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createNgroupBillingDetailsRequest = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createNgroupBillingDetailsSuccess = (
  state: INgroupBillingDetailsState,
  payload: INgroupBillingDetailsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createNgroupBillingDetailsFailed = (
  state: INgroupBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Creation actions
 */
const createBulkNgroupBillingDetailsRequest = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createBulkNgroupBillingDetailsSuccess = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const createBulkNgroupBillingDetailsFailed = (
  state: INgroupBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getNgroupBillingDetailsListRequest = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getNgroupBillingDetailsListSuccess = (
  state: INgroupBillingDetailsState,
  payload: INgroupBillingDetailsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getNgroupBillingDetailsListFailed = (
  state: INgroupBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getNgroupBillingDetailsCountRequest = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getNgroupBillingDetailsCountSuccess = (
  state: INgroupBillingDetailsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getNgroupBillingDetailsCountFailed = (
  state: INgroupBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteNgroupBillingDetailsRequest = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteNgroupBillingDetailsFailed = (
  state: INgroupBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateNgroupBillingDetailsRequest = (
  state: INgroupBillingDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateNgroupBillingDetailsFailed = (
  state: INgroupBillingDetailsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * NgroupBillingDetails reducer
 */
export const ngroupBillingDetails = createReducer<INgroupBillingDetailsState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushNgroupBillingDetailsState, flushNgroupBillingDetailsState)
  // creation actions
  .on(
    actions.createNgroupBillingDetailsRequest,
    createNgroupBillingDetailsRequest,
  )
  .on(
    actions.createNgroupBillingDetailsSuccess,
    createNgroupBillingDetailsSuccess,
  )
  .on(
    actions.createNgroupBillingDetailsFailed,
    createNgroupBillingDetailsFailed,
  )
  // bulk creation actions
  .on(
    actions.createBulkNgroupBillingDetailsRequest,
    createBulkNgroupBillingDetailsRequest,
  )
  .on(
    actions.createBulkNgroupBillingDetailsSuccess,
    createBulkNgroupBillingDetailsSuccess,
  )
  .on(
    actions.createBulkNgroupBillingDetailsFailed,
    createBulkNgroupBillingDetailsFailed,
  )
  // get list actions
  .on(
    actions.getNgroupBillingDetailsListRequest,
    getNgroupBillingDetailsListRequest,
  )
  .on(
    actions.getNgroupBillingDetailsListSuccess,
    getNgroupBillingDetailsListSuccess,
  )
  .on(
    actions.getNgroupBillingDetailsListFailed,
    getNgroupBillingDetailsListFailed,
  )
  // get count actions
  .on(
    actions.getNgroupBillingDetailsCountRequest,
    getNgroupBillingDetailsCountRequest,
  )
  .on(
    actions.getNgroupBillingDetailsCountSuccess,
    getNgroupBillingDetailsCountSuccess,
  )
  .on(
    actions.getNgroupBillingDetailsCountFailed,
    getNgroupBillingDetailsCountFailed,
  )
  // deletion actions
  .on(
    actions.deleteNgroupBillingDetailsRequest,
    deleteNgroupBillingDetailsRequest,
  )
  .on(
    actions.deleteNgroupBillingDetailsFailed,
    deleteNgroupBillingDetailsFailed,
  )
  // updating actions
  .on(
    actions.updateNgroupBillingDetailsRequest,
    updateNgroupBillingDetailsRequest,
  )
  .on(
    actions.updateNgroupBillingDetailsFailed,
    updateNgroupBillingDetailsFailed,
  );

export default ngroupBillingDetails;
