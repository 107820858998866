import React from 'react';
import { AnyObject } from 'src/modules/types';
import { TableContext } from '../../TableContext';
import { ITableHeadProps, TableHead } from '../TableHead';
import { ITableBodyItem, ITableBodyProps, TableBody } from '../TableBody';
import { getItemFromLocalStorage } from 'src/modules/utils/localStorageWrapper';
import { IHeadCell } from 'src/modules/types/table';
import { Box, Table, TableProps } from '@mui/joy';
import { NoData } from 'src/components/EnhancedTable/NoData';

export interface ITableContentProps<T extends ITableBodyItem>
  extends ITableBodyProps<T>,
    TableProps,
    Pick<ITableHeadProps, 'headCells' | 'order' | 'orderBy' | 'onSort'> {
  onRowCheckboxClick?: (selectedEntitiesId: number[]) => void;
  selectedRows?: number[];
  isDataLoading?: boolean;
  fullHeight?: boolean;
}

// @TODO: add opportunity to pass rows as children of TableContent component
export const TableContent = <P extends ITableBodyItem>({
  data,
  itemUniqueKey,
  headCells: _headCells,
  onRowCheckboxClick,
  order,
  orderBy,
  onSort,
  onTableRowClick,
  selectedRows = [],
  stickyHeader = true,
  stickyFooter = true,
  children,
  isDataLoading,
  fullHeight = true,
}: React.PropsWithChildren<ITableContentProps<P>>) => {
  const { headCells, setHeadCells, tableUniqueKey } = React.useContext(
    TableContext,
  );

  const rowsQuantity = data.length;
  const selectedRowsQuantity = Number(selectedRows.length);

  const onSelectAllCheckboxClick = () => {
    onRowCheckboxClick &&
      onRowCheckboxClick(
        selectedRowsQuantity < rowsQuantity ? data.map(({ id }) => id) : [],
      );
  };

  const onBodyRowCheckboxClick = (id: number, checked: boolean) => {
    onRowCheckboxClick &&
      onRowCheckboxClick(
        checked
          ? [...selectedRows, id]
          : selectedRows?.filter((selectedId) => selectedId !== id),
      );
  };

  React.useEffect(() => {
    const tablesConfiguration = getItemFromLocalStorage(
      'tablesConfiguration',
    ) as AnyObject;

    const headCellsFromLocalStorage =
      tablesConfiguration && tablesConfiguration[tableUniqueKey];

    setHeadCells(
      _headCells.map((headCell) => {
        if (headCellsFromLocalStorage) {
          const headCellDataInLocalStorage = headCellsFromLocalStorage.find(
            (hC: IHeadCell) => hC.id === headCell.id,
          );

          return {
            ...headCell,
            isVisible: headCellDataInLocalStorage
              ? headCellDataInLocalStorage.isVisible
              : true,
          };
        }

        return { ...headCell, isVisible: true };
      }),
    );
  }, [_headCells, setHeadCells, tableUniqueKey]);

  const filteredHeadCells = headCells.filter((headCell) => headCell.isVisible);

  return (
    <Box
      sx={{
        overflow: 'auto',
        width: '100%',
        height: '100%',
        flexGrow: 1,
        position: 'relative',
      }}
    >
      <Table
        sx={{
          minWidth: '100%',
          width: 'auto',
          height:
            (isDataLoading || !data.length) && fullHeight ? '100%' : 'auto',
        }}
        stickyHeader={stickyHeader}
        stickyFooter={stickyFooter}
        hoverRow={Boolean(onTableRowClick)}
      >
        {!data.length && !isDataLoading && (
          <Box
            component="tbody"
            sx={{
              width: '100%',
              height: !data.length ? '100%' : 'auto',
              // height: !data.length ? pageWithCopyrightContentHeight : 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <NoData />
          </Box>
        )}
        {Boolean(data.length) && (
          <>
            <TableHead
              order={order}
              orderBy={orderBy}
              onSort={onSort}
              headCells={filteredHeadCells}
              rowsQuantity={rowsQuantity}
              selectedRowsQuantity={selectedRowsQuantity}
              onSelectAllCheckboxClick={
                onRowCheckboxClick ? onSelectAllCheckboxClick : undefined
              }
            />
            <TableBody
              data={data}
              headCells={filteredHeadCells}
              selectedRows={selectedRows}
              itemUniqueKey={itemUniqueKey}
              onTableRowClick={onTableRowClick}
              onCheckboxClick={
                onRowCheckboxClick ? onBodyRowCheckboxClick : undefined
              }
            >
              {children}
            </TableBody>
          </>
        )}
      </Table>
    </Box>
  );
};
