import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthDailyReport',
);

// get list actions
export const {
  getListRequest: getCardinalHealthDailyReportListRequest,
  getListSuccess: getCardinalHealthDailyReportListSuccess,
  getListFailed: getCardinalHealthDailyReportListFailed,
} = actionCreator.takeListActions<ICardinalHealthDailyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthDailyReportCountRequest,
  getCountSuccess: getCardinalHealthDailyReportCountSuccess,
  getCountFailed: getCardinalHealthDailyReportCountFailed,
} = actionCreator.takeCountActions();
