import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { Api } from '../utils';
import * as actions from '../actions';
import { ExportSagaCreator } from '../utils/helpers/creators/export';

import {
  ICountResponse,
  ICreateMetatagRequest,
  IdsArray,
  IFilter,
  IMetatagModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../types';

/**
 * Create a new metatag
 */
export const createMetatagRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreateMetatagRequest>>): SagaIterator {
  try {
    const response: IMetatagModel = yield call(Api.Metatag.create, data);

    yield put(actions.getMetatagListRequest({ filter: list }));

    yield put(actions.getMetatagCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createMetatagSuccess(response));
  } catch (e) {
    yield put(actions.createMetatagFailed());
  }
};

/**
 * Get metatag list
 */
export const fetchMetaTagListRequestSaga = function* (
  filter: IFilter,
): SagaIterator {
  return yield call(Api.Metatag.list, filter);
};

/**
 * Get metatag list
 */
export const getMetatagListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const metaTagsList = yield call(
      fetchMetaTagListRequestSaga,
      action.payload,
    );
    yield put(actions.getMetatagListSuccess(metaTagsList));
  } catch (e) {
    yield put(actions.getMetatagListFailed());
  }
};

/**
 * Get metatag count
 */
export const getMetatagCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Metatag.count,
      action?.payload,
    );
    yield put(actions.getMetatagCountSuccess(response));
  } catch (e) {
    yield put(actions.getMetatagCountFailed());
  }
};

/**
 * Delete metatags
 */
export const deleteMetatagRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Metatag.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getMetatagListRequest({ filter: list }));

    yield put(actions.getMetatagCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteMetatagSuccess());
  } catch (e) {
    yield put(actions.deleteMetatagFailed());
  }
};

/**
 * Bulk update metatags
 */
export const updateMetatagsRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IMetatagModel[]>>): SagaIterator {
  try {
    yield call(Api.Metatag.bulkUpdate, data);

    yield put(actions.getMetatagListRequest({ filter: list }));

    yield put(actions.getMetatagCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
    yield put(actions.updateMetatagsSuccess());
  } catch (e) {
    yield put(actions.updateMetatagsFailed());
  }
};

/**
 * Get combobox list
 */
export const getMetatagComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IMetatagModel>[] = yield call(
      Api.Metatag.list,
      action.payload,
    );
    yield put(actions.getMetatagComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getMetatagComboboxListFailed());
  }
};

/**
 * Export
 */
export const {
  exportRequestSaga: exportMetatagRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Metatag.export,
  actionFailed: actions.exportMetatagFailed,
  actionSuccess: actions.exportMetatagSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  /**
   * Metatag sagas
   */
  { action: actions.createMetatagRequest, saga: createMetatagRequestSaga },
  { action: actions.getMetatagListRequest, saga: getMetatagListRequestSaga },
  { action: actions.getMetatagCountRequest, saga: getMetatagCountRequestSaga },
  { action: actions.deleteMetatagRequest, saga: deleteMetatagRequestSaga },
  // bulk updating metatags
  { action: actions.updateMetatagsRequest, saga: updateMetatagsRequestSaga },
  {
    action: actions.getMetatagComboboxListRequest,
    saga: getMetatagComboboxListRequestSaga,
  },
  { action: actions.exportMetatagRequest, saga: exportMetatagRequestSaga },
];
