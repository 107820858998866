import React from 'react';
import { subWeeks } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { YEAR_WEEK_ISO, format } from 'src/modules/utils/dateWrapper';
import { concatenateYearWeek } from 'src/modules/utils/helpers/formatters';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { SynchronizedCharts } from 'src/components/Charts/2d/SynchronizedCharts';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTheme } from '@mui/joy';
import { IPaymentStatisticChartItem } from 'src/modules/types/executive-reports';
import { useSynchronizedChartRequiredUtils } from 'src/components/Charts/common';

interface IPaymentStatisticChartProps {
  from?: number;
  to?: number;
}

export const PaymentStatisticChart = ({
  from,
  to,
}: IPaymentStatisticChartProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const today = new Date();
  const toDate = subWeeks(today, 1);
  const toYearWeek = format(YEAR_WEEK_ISO)(toDate);

  const fromDate = subWeeks(toDate, 13);
  const fromYearWeek = format(YEAR_WEEK_ISO)(fromDate);

  const [toYear, toWeek] = toYearWeek.split('-W');
  const [fromYear, fromWeek] = fromYearWeek.split('-W');

  const { fetchData, data, isDataLoading } = useDataFetcherWithData<
    Array<IPaymentStatisticChartItem>
  >('/invoice/statistic/for-executives/chart', []);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const ref1 = React.useRef<Highcharts.Chart | null>(null);
  const ref2 = React.useRef<Highcharts.Chart | null>(null);
  const ref3 = React.useRef<Highcharts.Chart | null>(null);

  const {
    exportCharts,
    toggleFullScreen,
  } = useSynchronizedChartRequiredUtils();

  const mapChartsRefToCharts = () => {
    return [ref1, ref2, ref3]
      .filter((ref) => Boolean(ref.current))
      .map((ref) => (ref.current as any)?.chart);
  };

  const categories = React.useMemo(() => {
    const categoriesSet = new Set(
      (data as any).map((item: any) =>
        generateCategory({ yearNumber: item.year, weekNumber: item.week }),
      ),
    );

    return Array.from(categoriesSet);
  }, [data]);

  const generateSeriesItem = React.useCallback(
    ({
      title,
      name,
      unit,
    }: {
      title: string;
      name: 'totalMarkupCost' | 'totalInvoicedAmount' | 'totalInvoicesQuantity';
      unit: string;
    }) => {
      return {
        title: {
          text: title,
          style: {
            fontSize: '12px',
            fontWeight: 'regular',
            color: theme.palette.colors.text.text_primary.main,
          },
        },
        name: name,
        unit: unit,
        type: 'area' as const,
        data: categories.map((category) => {
          const existedCategoryDetails = (data as any).find(
            (item: any) =>
              category ===
              generateCategory({
                yearNumber: item.year,
                weekNumber: item.week,
              }),
          );

          return existedCategoryDetails ? existedCategoryDetails[name] : null;
        }),
      };
    },
    [categories, data, theme.palette.colors.text.text_primary.main],
  );

  const series = React.useMemo(
    () => [
      generateSeriesItem({
        unit: '$',
        name: 'totalInvoicedAmount',
        title: t('productions_uph_reports.total_invoiced_amount'),
      }),
      generateSeriesItem({
        unit: '$',
        name: 'totalMarkupCost',
        title: t('productions_uph_reports.total_markup_cost'),
      }),
      generateSeriesItem({
        unit: '',
        name: 'totalInvoicesQuantity',
        title: t('productions_uph_reports.total_invoices_quantity'),
      }),
    ],
    [generateSeriesItem, t],
  );

  function generateCategory({
    yearNumber,
    weekNumber,
  }: {
    yearNumber: number;
    weekNumber: number;
  }) {
    return yearNumber && weekNumber ? `${yearNumber}-W${weekNumber}` : '';
  }

  React.useEffect(() => {
    // We have next states of from/to:
    // 1. from = undefined and to = undefined => user does not touch filter, we should use default from/to
    // 2. from = "some value" and to = "some value" => user selected yearWeek, we use user selection
    // 3. from = null || to = null, user select then remove a value, we should not use any value
    const and = [];
    if (from !== null) {
      and.push({
        yearWeek: {
          gte:
            from ??
            Number(
              concatenateYearWeek({
                year: Number(fromYear),
                week: Number(fromWeek),
              }),
            ),
        },
      });
    }
    if (to !== null) {
      and.push({
        yearWeek: {
          lt:
            to ??
            Number(
              concatenateYearWeek({
                year: Number(toYear),
                week: Number(toWeek),
              }),
            ),
        },
      });
    }

    fetchData({
      filter: {
        order: ['yearWeek asc'],
        where: { and },
      },
    });
  }, [fetchData, from, fromWeek, fromYear, to, toWeek, toYear]);

  return (
    <ChartContainer fullHeigh={false}>
      <ChartContainerHeader>
        <ChartContainerUtils
          onToggleFullScreen={() => {
            if (containerRef.current) {
              toggleFullScreen(mapChartsRefToCharts(), containerRef.current);
            }
          }}
          onExport={() => {
            exportCharts(mapChartsRefToCharts(), {
              filename: t('financial_reports.revenue_cost_number_of_sites'),
            });
          }}
        />
      </ChartContainerHeader>
      <ChartContainerBody
        ref={containerRef}
        isLoading={isDataLoading}
        childrenContainerSx={{ display: 'block' }}
      >
        <SynchronizedCharts
          chartsRefs={[ref1, ref2, ref3]}
          series={series}
          categories={categories as string[]}
          chartHeight={isDataLoading ? 100 : 233}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
