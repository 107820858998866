import React from 'react';
import { Box, IconButton, useTheme } from '@mui/joy';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { Trash04Svg } from 'src/components/svgIcons';

interface IFilterPanelFilterContainerProps {
  children: React.ReactNode;
  isPermanentFilter: boolean;
  onTrashBinClick: () => void;
}

export const FilterPanelFilterContainer = ({
  children,
  onTrashBinClick,
  isPermanentFilter,
}: IFilterPanelFilterContainerProps) => {
  const theme = useTheme();

  const { filterPanelMode, elementsHeight } = React.useContext(
    FilterPanelContext,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'inherit',
        overflow: 'hidden',
        mr: filterPanelMode === FilterPanelModes.ready ? 0.5 : 0,
        mt: 0.25,
        mb: 0.25,
        borderStyle: 'solid',
        borderRadius: filterPanelMode === FilterPanelModes.ready ? 8 : 0,
        borderWidth: filterPanelMode === FilterPanelModes.ready ? 1 : 0,
        borderColor:
          filterPanelMode === FilterPanelModes.ready
            ? 'colors.border.border_primary'
            : 'transparent',
      }}
    >
      {children}

      {filterPanelMode !== FilterPanelModes.setup && !isPermanentFilter && (
        <IconButton
          size="sm"
          onClick={onTrashBinClick}
          sx={{
            minHeight: elementsHeight,
            minWidth: elementsHeight,
          }}
        >
          <Trash04Svg
            style={{
              height: 16,
              width: 16,
            }}
            fill={theme.palette.colors.foreground.fg_error_primary}
          />
        </IconButton>
      )}
    </Box>
  );
};
