import { IProductionWeeklyUphBySiteSkuAndEmployeeModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionWeeklyUphBySiteSkuAndEmployee',
);

// get list actions
export const {
  getListRequest: getProductionWeeklyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getProductionWeeklyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getProductionWeeklyUphBySiteSkuAndEmployeeListFailed,
} = actionCreator.takeListActions<
  IProductionWeeklyUphBySiteSkuAndEmployeeModel
>();

// get count actions
export const {
  getCountRequest: getProductionWeeklyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteSkuAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionWeeklyUphBySiteSkuAndEmployeeRequest,
  exportReportSuccess: exportProductionWeeklyUphBySiteSkuAndEmployeeSuccess,
  exportReportFailed: exportProductionWeeklyUphBySiteSkuAndEmployeeFailed,
} = new ExportActionCreator(
  'ProductionWeeklyUphBySiteSkuAndEmployee',
).takeExportActions();
