import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map, reduce } from 'lodash';
import { ExportType, IEmailData, IExportColumnsData } from 'src/modules/types';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { List, Sheet, Typography } from '@mui/joy';
import { ButtonGroup } from 'src/components/_ui-kit/ButtonGroup';
import { ListItemWithCheckbox } from 'src/components/_ui-kit/ListItem';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { HeadCell } from 'src/components/EnhancedTable';
import { TableContext } from 'src/components/Table/TableContext';
import { EmailDataForm } from 'src/components/EnhancedTable/EmailDataForm';
import {
  ITableActionsExportButtonProps,
  TableActionsExportButton,
} from './TableActionsExportButton';
import { IRequestFilters } from 'src/modules/utils/hooks/table';
import { guessTimezone } from 'src/modules/utils/dateWrapper';

const defaultData = {
  type: 'csv' as const,
  exportAllPages: true,
};

export interface ITableActionsExportProps {
  requestFilters?: IRequestFilters;
  onExportCSV?: (filters: IExportColumnsData) => void;
  onExportPDF?: (filters: IExportColumnsData) => void;
  onExportExcel?: (filters: IExportColumnsData) => void;
  onExportEmail?: (filters: IExportColumnsData) => void;
  exportBtnContainerProps?: ITableActionsExportButtonProps['containerSx'];
  isDataExporting?: boolean;
  summaryLocalization?: { [key: string]: string };
}

interface ITableActionsExportExportColumn {
  [id: string]: {
    id: HeadCell['id'];
    label: HeadCell['label'];
    checked: boolean;
  };
}

export const TableActionsExport: React.FC<ITableActionsExportProps> = ({
  onExportCSV,
  onExportPDF,
  onExportExcel,
  onExportEmail,
  exportBtnContainerProps,
  requestFilters = {} as IRequestFilters,
  isDataExporting = false,
  summaryLocalization,
}) => {
  const { t } = useTranslation();

  const { headCells } = React.useContext(TableContext);

  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);

  const onClose = () => setIsDrawerOpened(false);

  const [type, setType] = useState<ExportType>(defaultData.type);
  const [isEmailDataFormOpen, setIsEmailDatFormOpen] = useState(false);
  const [exportAllPages, setExportAllPages] = useState<boolean>(
    defaultData.exportAllPages,
  );
  const [columnsToExport, setColumnsToExport] = useState<
    ITableActionsExportExportColumn
  >({});

  const pagesToExportButtons = React.useMemo(() => {
    return [
      { value: true, label: t('export.common.all_pages') },
      { value: false, label: t('export.common.current_page') },
    ];
  }, [t]);
  const exportingFormatsButtons = React.useMemo(() => {
    return [
      { value: 'csv' as const, label: t('export.common.csv') },
      { value: 'xls' as const, label: t('export.common.excel') },
      { value: 'pdf' as const, label: t('export.common.pdf') },
      { value: 'email' as const, label: t('export.common.email') },
    ];
  }, [t]);

  const onEmailDataFormCancel = () => setIsEmailDatFormOpen(false);

  const initColumnsToExport = React.useCallback(() => {
    return reduce(
      headCells,
      (acc, cur) => {
        acc[cur.id] = {
          id: cur.id,
          label: cur.label,
          checked: true,
        };
        return acc;
      },
      {} as ITableActionsExportExportColumn,
    );
  }, [headCells]);

  useEffect(() => {
    setColumnsToExport(initColumnsToExport());
  }, [initColumnsToExport]);

  const selectedAll = useMemo(() => {
    for (const i in columnsToExport) {
      if (!columnsToExport[i].checked) {
        return false;
      }
    }
    return true;
  }, [columnsToExport]);

  const isAllCellCheckboxIndeterminate = useMemo(() => {
    let hasChecked = false;
    let hasUnchecked = false;

    for (const i in columnsToExport) {
      if (!columnsToExport[i].checked) {
        hasUnchecked = true;
      } else {
        hasChecked = true;
      }
    }

    return hasChecked && hasUnchecked;
  }, [columnsToExport]);

  const onColumnSelect = (id: HeadCell['id']) => (checked: boolean) =>
    setColumnsToExport((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        checked,
      },
    }));

  const onSelectAll = (checked: boolean) =>
    setColumnsToExport(
      reduce(
        columnsToExport,
        (acc, cur, index) => {
          acc[index] = {
            ...cur,
            checked,
          };
          return acc;
        },
        {} as ITableActionsExportExportColumn,
      ),
    );
  const filters = React.useMemo(
    () => ({
      filter: {
        ...requestFilters,
        limit: exportAllPages ? undefined : requestFilters?.limit,
        offset: exportAllPages ? undefined : requestFilters?.offset,
        fields: Object.keys(columnsToExport).reduce((acc, key) => {
          if (columnsToExport[key].checked) {
            return { ...acc, [key]: true };
          }
          return acc;
        }, {}),
      },
      columnNamesLocalization: Object.entries(columnsToExport).reduce(
        (acc, column) => {
          const headCellDetails = headCells.find(
            (cell) => cell.id === column[0],
          );

          if (headCellDetails && columnsToExport[column[0]].checked) {
            acc[column[0]] = headCellDetails.label;
          }

          return acc;
        },
        {},
      ),
      timezone: guessTimezone(),
      localization: summaryLocalization,
    }),
    [
      columnsToExport,
      exportAllPages,
      headCells,
      requestFilters,
      summaryLocalization,
    ],
  );

  const exportDataViaEmail = (emailData: IEmailData) => {
    const composedFilters = filters;
    setIsEmailDatFormOpen(false);
    onExportEmail &&
      onExportEmail({
        ...composedFilters,
        emailData,
        localization: summaryLocalization,
      } as any);
  };

  const onExport = () => {
    switch (type) {
      case 'csv':
      default:
        onExportCSV && onExportCSV(filters);
        break;
      case 'xls':
        onExportExcel && onExportExcel(filters);
        break;
      case 'pdf':
        onExportPDF && onExportPDF(filters);
        break;
      case 'email':
        setIsEmailDatFormOpen(true);
        break;
    }

    onClose();
  };

  const onChangePages = (value: boolean) => setExportAllPages(value);

  const onChangeType = (value: ExportType) => setType(value);

  const onReset = () => {
    setType(defaultData.type);
    setExportAllPages(defaultData.exportAllPages);
    setColumnsToExport(initColumnsToExport());
  };

  return (
    <>
      <TableActionsExportButton
        onClick={() => setIsDrawerOpened((prev) => !prev)}
        containerSx={exportBtnContainerProps}
        loading={isDataExporting}
      />
      <Drawer open={isDrawerOpened} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('export.common.form_title')}
        </DrawerHeader>
        <DrawerBody>
          <Sheet sx={{ p: 2 }}>
            <ButtonGroup
              label={t('export.common.export_settings')}
              buttons={pagesToExportButtons}
              onButtonClick={onChangePages}
              activeButtonValue={exportAllPages}
            />

            <Sheet sx={{ mt: 2 }}>
              <ButtonGroup
                label={t('export.common.exporting_format')}
                buttons={exportingFormatsButtons}
                onButtonClick={onChangeType}
                activeButtonValue={type}
              />
            </Sheet>

            <Sheet
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                mt: 2,
              }}
            >
              <Typography level="text_sm" fontWeight="medium">
                {t('export.common.export_columns')}
              </Typography>
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: 'transparent',
                }}
                component="ul"
              >
                <ListItemWithCheckbox
                  underline
                  id={-1}
                  isChecked={selectedAll}
                  isIndeterminate={isAllCellCheckboxIndeterminate}
                  onClick={onSelectAll}
                >
                  <Typography noWrap fontWeight="medium" level="text_sm">
                    {t('common.select_all')}
                  </Typography>
                </ListItemWithCheckbox>
                {map(columnsToExport, (column) => (
                  <ListItemWithCheckbox
                    key={column.id}
                    id={column.id}
                    isChecked={column.checked}
                    onClick={onColumnSelect(column.id)}
                  >
                    <Typography noWrap fontWeight="medium" level="text_sm">
                      {column.label}
                    </Typography>
                  </ListItemWithCheckbox>
                ))}
              </List>
            </Sheet>
          </Sheet>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar onReset={onReset} onCancel={onClose} onApply={onExport} />
        </DrawerFooter>

        <EmailDataForm
          isOpen={isEmailDataFormOpen}
          onCancel={onEmailDataFormCancel}
          onExport={exportDataViaEmail}
        />
      </Drawer>
    </>
  );
};
