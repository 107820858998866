import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { map, omit } from 'lodash';

import FormikCombobox, { IFormikComboboxProps } from '../FormikCombobox';
import { getCurrentRole } from '../../../modules/selectors/auth';
import { useFetchRolesCombobox } from '../../../modules/utils';
import { ComboBoxOption } from '../../ComboBox';
import { getRolesComboboxListByFieldId } from '../../../modules/selectors/role';

interface IRolesComboboxProps extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  name: string;
  fieldAsId: 'id' | 'key';
}

const RolesCombobox: React.FC<IRolesComboboxProps> = (props) => {
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const fetchRolesCombobox = useFetchRolesCombobox();

  useEffect(() => {
    fetchRolesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const roles = useSelector(
    getRolesComboboxListByFieldId,
    shallowEqual,
  )(props.fieldAsId);

  const options = (useMemo(
    () =>
      map(roles, (role) => ({
        id: role.id,
        name: role.name,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.fieldAsId, roles],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...omit(props, ['fieldAsId'])}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default RolesCombobox;
