import {
  ICardinalHealthDailyReportModel,
  ICardinalHealthDailyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthDailyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthDailyUphBySiteAndEmployeeListRequest,
  getListSuccess: getCardinalHealthDailyUphBySiteAndEmployeeListSuccess,
  getListFailed: getCardinalHealthDailyUphBySiteAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthDailyReportState,
  ICardinalHealthDailyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthDailyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthDailyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthDailyUphBySiteAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthDailyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthDailyUphBySiteAndEmployee = createReducer<
  ICardinalHealthDailyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthDailyUphBySiteAndEmployeeListRequest,
    getCardinalHealthDailyUphBySiteAndEmployeeListRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndEmployeeListSuccess,
    getCardinalHealthDailyUphBySiteAndEmployeeListSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndEmployeeListFailed,
    getCardinalHealthDailyUphBySiteAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthDailyUphBySiteAndEmployeeCountRequest,
    getCardinalHealthDailyUphBySiteAndEmployeeCountRequest,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndEmployeeCountSuccess,
    getCardinalHealthDailyUphBySiteAndEmployeeCountSuccess,
  )
  .on(
    actions.getCardinalHealthDailyUphBySiteAndEmployeeCountFailed,
    getCardinalHealthDailyUphBySiteAndEmployeeCountFailed,
  );

export default cardinalHealthDailyUphBySiteAndEmployee;
