import { IAuth, IMe, IPageSettingModel } from '../../types';
import { merge } from 'lodash';
import { i18n } from '../i18n';
import {
  parse,
  composeDate,
  EXPIRES_AT_FORMAT,
  isBefore,
  getNow,
} from '../../utils/dateWrapper';

interface IUserLocalStore {
  user?: Partial<IMe>;
  auth?: Partial<IAuth>;
  pageSettings?: Partial<IPageSettingModel>;
}

/**
 * Fetch a user from localStorage
 */
export const getUserObjectFromStorage = (): IUserLocalStore => {
  return JSON.parse(localStorage.getItem('user') || '{}');
};

/**
 * Store a user to localStorage
 * @param data - Data to store
 */
export const saveUserToStorage = (data: IUserLocalStore): void => {
  const storedUser = getUserObjectFromStorage();
  const toStore = merge(storedUser, data);
  localStorage.setItem('user', JSON.stringify(toStore));
  data.user?.lang && i18n.changeLanguage(data.user?.lang);
};

/**
 * Remove a user from localStore
 */
export const clearUserFromStorage = (): void => {
  localStorage.removeItem('user');
};

/**
 * Checks if token for the logged in user is expired and if so, remove the current user from the localStorage
 */
export const isUserTokenExpired = () => {
  const { auth: { expires_at } = {} } = getUserObjectFromStorage();

  if (!expires_at) {
    return true;
  }

  return isBefore(composeDate(expires_at, parse(EXPIRES_AT_FORMAT)), getNow());
};
