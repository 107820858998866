import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  AnyObject,
  IPageSettingModel,
  ISagaAction,
  ISagaActionBind,
} from '../types';
import { setItemInLocalStorage } from '../utils/localStorageWrapper';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getPageSettingRequest,
    saga: function* (): SagaIterator {
      try {
        const response: IPageSettingModel = yield call(Api.PageSettings.get);
        yield put(actions.getPageSettingSuccess(response));
        // save user data to localStore
        setItemInLocalStorage('pageSetting', response as AnyObject);
      } catch (e) {
        yield put(actions.getPageSettingFailed());
      }
    },
  },
  {
    action: actions.updatePageSettingRequest,
    saga: function* ({
      payload,
    }: ISagaAction<IPageSettingModel>): SagaIterator {
      try {
        const response: IPageSettingModel = yield call(
          Api.PageSettings.update,
          payload,
        );
        yield put(actions.updatePageSettingSuccess(response));
        // save user data to localStore
        setItemInLocalStorage('pageSetting', response as AnyObject);

        yield put(actions.getPageSettingRequest());

        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
            message: 'page_setting.polices_update_success',
          }),
        );
      } catch (e) {
        yield put(actions.updatePageSettingFailed());
      }
    },
  },
];
