import React from 'react';
import { Modal, Box } from '@mui/joy';

interface IViewReviewsFilePreviewProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
}

export const ViewReviewsFilePreview = ({
  isOpen,
  onClose,
  src,
}: IViewReviewsFilePreviewProps) => {
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Box
          onClick={onClose}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: '64px',
          }}
        >
          <Box
            src={src}
            component="img"
            alt={'File preview'}
            sx={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          />
        </Box>
      </Modal>
    </>
  );
};
