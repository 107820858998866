import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  IFilter,
  ISagaAction,
  IWhere,
  ListWithSummary,
  ISagaActionBind,
} from '../../types';
import { createExportSaga } from '../../utils/helpers/creators/export';
import { ICostPlusWeeklyModel } from '../../types/CostPlusWeeklyTypes';

/**
 * Get Cost Plus Weekly list
 */
export const getCostPlusWeeklyListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<ICostPlusWeeklyModel> = yield call(
      Api.CostPlusWeekly.list,
      action.payload,
    );
    yield put(actions.getCostPlusWeeklyListSuccess(response));
  } catch (e) {
    yield put(actions.getCostPlusWeeklyListFailed());
  }
};

/**
 * Get Cost Plus Weekly count
 */
export const getCostPlusWeeklyCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.CostPlusWeekly.count,
      action?.payload,
    );
    yield put(actions.getCostPlusWeeklyCountSuccess(response));
  } catch (e) {
    yield put(actions.getCostPlusWeeklyCountFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCostPlusWeeklyListRequest,
    saga: getCostPlusWeeklyListRequestSaga,
  },
  {
    action: actions.getCostPlusWeeklyCountRequest,
    saga: getCostPlusWeeklyCountRequestSaga,
  },
  {
    action: actions.exportCostPlusWeeklyRequest,
    saga: createExportSaga({
      apiCall: Api.CostPlusWeekly.export,
      actionSuccess: actions.exportCostPlusWeeklySuccess,
      actionFailed: actions.exportCostPlusWeeklyFailed,
    }),
  },
];
