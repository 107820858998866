import {
  ICardinalHealthWeeklyReportModel,
  ICardinalHealthWeeklyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthWeeklyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthWeeklyReportState,
  ICardinalHealthWeeklyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthWeeklyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyUphBySiteSkuAndEmployee = createReducer<
  ICardinalHealthWeeklyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListRequest,
    getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListSuccess,
    getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListFailed,
    getCardinalHealthWeeklyUphBySiteSkuAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountRequest,
    getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountSuccess,
    getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountFailed,
    getCardinalHealthWeeklyUphBySiteSkuAndEmployeeCountFailed,
  );

export default cardinalHealthWeeklyUphBySiteSkuAndEmployee;
