import { JSONSchemaType } from 'ajv';
import { ICreateBom } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createBomScheme: JSONSchemaType<ICreateBom> = {
  type: 'object',
  required: ['name', 'siteId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
  },
};
