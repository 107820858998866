import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { ICountResponse, IPolicyModel, IPolicyState } from '../../types';

// default state
const defaultState: IPolicyState = {
  id: NaN,
  refreshKey: 0,
  ptype: '',
  list: [],
  comboboxList: [],
  count: 0,
  isUpdatePoliciesCreateInProgress: false,
};

/**
 * Flush actions
 */
const flushPolicyState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createPolicyRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createPolicySuccess = (state: IPolicyState, payload: IPolicyModel) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createPolicyFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Creation by page actions
 */
const createPolicyByPageRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isUpdatePoliciesCreateInProgress: true,
});

const createPolicyByPageSuccess = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: false,
  isUpdatePoliciesCreateInProgress: false,
});

const createPolicyByPageFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isUpdatePoliciesCreateInProgress: false,
});

const createPolicyByOperationRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isUpdatePoliciesCreateInProgress: true,
});

const createPolicyByOperationSuccess = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: false,
  isUpdatePoliciesCreateInProgress: false,
});

const createPolicyByOperationFailed = (
  state: IPolicyState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isUpdatePoliciesCreateInProgress: false,
});

/**
 * Get list actions
 */
const getPolicyListRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPolicyListSuccess = (
  state: IPolicyState,
  payload: IPolicyModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getPolicyListFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getPolicyCountRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPolicyCountSuccess = (
  state: IPolicyState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getPolicyCountFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deletePolicyRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deletePolicyFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updatePolicyRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePolicyFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getPolicyComboboxListRequest = (state: IPolicyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPolicyComboboxListSuccess = (
  state: IPolicyState,
  payload: Partial<IPolicyModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getPolicyComboboxListFailed = (state: IPolicyState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Policy reducer
 */
export const policy = createReducer<IPolicyState>({}, defaultState)
  // flush actions
  .on(actions.flushPolicyState, flushPolicyState)
  // creation actions
  .on(actions.createPolicyRequest, createPolicyRequest)
  .on(actions.createPolicySuccess, createPolicySuccess)
  .on(actions.createPolicyFailed, createPolicyFailed)
  // creation by page actions
  .on(actions.createPolicyByPageRequest, createPolicyByPageRequest)
  .on(actions.createPolicyByPageSuccess, createPolicyByPageSuccess)
  .on(actions.createPolicyByPageFailed, createPolicyByPageFailed)
  //
  .on(actions.createPolicyByOperationRequest, createPolicyByOperationRequest)
  .on(actions.createPolicyByOperationSuccess, createPolicyByOperationSuccess)
  .on(actions.createPolicyByOperationFailed, createPolicyByOperationFailed)
  // get list actions
  .on(actions.getPolicyListRequest, getPolicyListRequest)
  .on(actions.getPolicyListSuccess, getPolicyListSuccess)
  .on(actions.getPolicyListFailed, getPolicyListFailed)
  // get count actions
  .on(actions.getPolicyCountRequest, getPolicyCountRequest)
  .on(actions.getPolicyCountSuccess, getPolicyCountSuccess)
  .on(actions.getPolicyCountFailed, getPolicyCountFailed)
  // deletion actions
  .on(actions.deletePolicyRequest, deletePolicyRequest)
  .on(actions.deletePolicyFailed, deletePolicyFailed)
  // updating actions
  .on(actions.updatePolicyRequest, updatePolicyRequest)
  .on(actions.updatePolicyFailed, updatePolicyFailed)
  // get combobox list actions
  .on(actions.getPolicyComboboxListRequest, getPolicyComboboxListRequest)
  .on(actions.getPolicyComboboxListSuccess, getPolicyComboboxListSuccess)
  .on(actions.getPolicyComboboxListFailed, getPolicyComboboxListFailed);

export default policy;
