import React from 'react';
import { useTranslation } from 'react-i18next';
import { subWeeks } from 'date-fns';
import {
  YEAR_WEEK_ISO,
  format,
} from '../../../../../modules/utils/dateWrapper';
import ColumnChart from '../../../../../components/Charts/2d/ColumnChart';
import { concatenateYearWeek } from '../../../../../modules/utils/helpers/formatters';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from '../../../../../components/Charts/ChartContainer';
import { useChartRequiredUtils } from '../../../../../components/Charts/common';
import { executiveSummaryChartHeight } from '../executiveSummary.constants';
import { fetchExecutiveSummaryWeeklyActiveInactiveHiredChartData } from '../../../../../modules/utils/Api/executivesSummaryReport';
import { useGetDataForCharts } from '../../../../../modules/utils/hooks/common/request';

interface IExecutiveSummaryWeeklyActiveInactiveHiredProps {
  siteId?: number;
}

export const ExecutiveSummaryWeeklyActiveInactiveHired = ({
  siteId,
}: IExecutiveSummaryWeeklyActiveInactiveHiredProps) => {
  const { t } = useTranslation();

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const toDate = new Date();
  const toYearWeek = format(YEAR_WEEK_ISO)(toDate);

  const fromDate = subWeeks(toDate, 13);
  const fromYearWeek = format(YEAR_WEEK_ISO)(fromDate);

  const [toYear, toWeek] = toYearWeek.split('-W');
  const [fromYear, fromWeek] = fromYearWeek.split('-W');

  const {
    data,
    getData: fetchData,
    isLoading: isDataLoading,
  } = useGetDataForCharts(
    fetchExecutiveSummaryWeeklyActiveInactiveHiredChartData,
    { initialData: { items: [] } },
  );

  const categories = React.useMemo(
    () => data.items.map((item) => `${item.year}-W${item.week}`),
    [data.items],
  );
  const hiredHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.hired_headcount'),
      data: data.items.map((item) => item.hiredHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );
  const inactivatedHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.inactivated_headcount'),
      data: data.items.map((item) => item.inactivatedHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );
  const withApprovedHoursHeadcount = React.useMemo(
    () => ({
      name: t('productions_uph_reports.active'),
      data: data.items.map((item) => item.withApprovedHoursHeadcount),
      type: 'column' as const,
    }),
    [data.items, t],
  );

  React.useEffect(() => {
    fetchData(
      {
        siteId: siteId ? { eq: siteId } : undefined,
        and: [
          {
            yearWeek: {
              gte: concatenateYearWeek({
                year: Number(fromYear),
                week: Number(fromWeek),
              }),
            },
          },
          {
            yearWeek: {
              lt: concatenateYearWeek({
                year: Number(toYear),
                week: Number(toWeek),
              }),
            },
          },
        ],
      },
      ['yearWeek asc'],
    );
  }, [fetchData, fromWeek, fromYear, siteId, toWeek, toYear]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('financial_reports.weekly_active_inactive_hired')}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('weekly_active_inactive_hired')}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isDataLoading}
        showNoData={
          !hiredHeadcount.data.length &&
          !inactivatedHeadcount.data.length &&
          !withApprovedHoursHeadcount.data.length
        }
      >
        <ColumnChart
          ref={chartRef}
          height={executiveSummaryChartHeight}
          categories={categories}
          series={[
            hiredHeadcount,
            inactivatedHeadcount,
            withApprovedHoursHeadcount,
          ]}
          yAxis={[
            {
              min: 0,
              title: {
                enabled: false,
              } as Highcharts.YAxisTitleOptions,
            },
            {
              min: 0,
              allowDecimals: false,
              opposite: true,
              title: {
                enabled: false,
              } as Highcharts.YAxisTitleOptions,
            },
          ]}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
