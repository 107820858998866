import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FilterValue, FilterValueDescriptor } from 'src/modules/types/table';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { DatePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DatePickerFilter';
import { ShiftsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ShiftsComboBoxFilter';
import { TerminatedReasonsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TerminatedReasonsComboBoxFilter';
import { ComboboxTableFilter } from '../../../components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { useSupervisorCombobox } from '../../../modules/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { getSupervisorsAndGMsNamesBySitesComboboxList } from 'src/modules/selectors/user';

interface ITerminatedEmployeesFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(
    filtersData: Record<string, FilterValue>,
  ): Record<string, FilterValueDescriptor>;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const TerminatedEmployeesFilter: React.FC<ITerminatedEmployeesFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();
  const fetchSupervisorsCombobox = useSupervisorCombobox();

  useEffect(() => {
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const supervisors = useSelector(
    getSupervisorsAndGMsNamesBySitesComboboxList,
    shallowEqual,
  );

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'firstName',
            labelPrefix: t('employees.terminated.first_name'),
          })}
          {...getFilterCommonPropsByFilterName('firstName')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'lastName',
            labelPrefix: t('employees.terminated.last_name'),
          })}
          {...getFilterCommonPropsByFilterName('lastName')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ComboboxTableFilter
          options={supervisors}
          label={getLabel({
            filterName: 'supervisorName',
            labelPrefix: t('employees.active_but_not_present.supervisorName'),
          })}
          {...getFilterCommonPropsByFilterName('supervisorName')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'badge',
            labelPrefix: t('employees.terminated.badge'),
          })}
          {...getFilterCommonPropsByFilterName('badge')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'employeeId',
            labelPrefix: t('employees.terminated.emp_id'),
          })}
          {...getFilterCommonPropsByFilterName('employeeId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'staffingProvider',
            labelPrefix: t('employees.terminated.staffing_provider'),
          })}
          {...getFilterCommonPropsByFilterName('staffingProvider')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SitesComboBoxFilter
          label={getLabel({
            filterName: 'siteId',
            labelPrefix: t('employees.terminated.site'),
          })}
          {...getFilterCommonPropsByFilterName('siteId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <DatePickerFilter
          label={getLabel({
            filterName: 'separationdate',
            labelPrefix: t('employees.terminated.separationdate'),
          })}
          {...getFilterCommonPropsByFilterName('separationdate')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <ShiftsComboBoxFilter
          label={getLabel({
            filterName: 'shiftId',
            labelPrefix: t('employees.terminated.shift'),
          })}
          {...getFilterCommonPropsByFilterName('shiftId')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TerminatedReasonsComboBoxFilter
          propertyAsID="name"
          label={getLabel({
            filterName: 'terminationReasonName',
            labelPrefix: t('employees.terminated.termination_reason_name'),
          })}
          {...getFilterCommonPropsByFilterName('terminationReasonName')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
