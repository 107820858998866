import React from 'react';
import { TableContext } from '../../TableContext';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/joy';
import { Td } from 'src/components/TableUiComponents/Td';
import { Tr } from 'src/components/TableUiComponents';

export type ITableSummaryProps = {
  title: string;
  values: { [key: string]: string | number | React.ReactNode };
  additionalColumnsQuantityToSpan?: number;
};

export const TableSummary: React.FC<ITableSummaryProps> = ({
  values,
  title,
  additionalColumnsQuantityToSpan = 0,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { visibleHeadCells } = React.useContext(TableContext);

  const totalColspan = React.useMemo(() => {
    for (let i = 0; i < visibleHeadCells.length; i++) {
      if (visibleHeadCells[i].id in values) {
        return i;
      }
    }
    return 0;
  }, [visibleHeadCells, values]);

  const colspanWithAdditional = totalColspan + additionalColumnsQuantityToSpan;

  return (
    <Tr
      sx={{
        bottom: 0,
        position: 'sticky',
      }}
    >
      {colspanWithAdditional > 0 && (
        <Td
          colSpan={colspanWithAdditional}
          sx={{
            bgcolor: `${theme.palette.colors.background.bg_primary_hover} !important`,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
          }}
        >
          <Typography level="text_xs" textColor="colors.text.text_primary.main">
            {t(title)}
          </Typography>
        </Td>
      )}
      {visibleHeadCells.slice(totalColspan).map(({ id }) => (
        <Td
          key={id}
          sx={{
            bgcolor: `${theme.palette.colors.background.bg_primary_hover} !important`,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
          }}
        >
          <Typography
            level="text_xs"
            textColor="colors.text.text_primary.main"
            fontWeight="semi_bold"
          >
            {values[id] ?? null}
          </Typography>
        </Td>
      ))}
    </Tr>
  );
};
