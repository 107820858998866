import { createSelector } from 'reselect';

import { IPageSettingModel, IStoreState } from '../types';
import { usePageSettingHelpers } from '../utils/hooks/page-settings.hook';

export const getPageSettingData = (state: IStoreState) =>
  state.pageSetting as IPageSettingModel;

export const getPageSettingDataRequest = createSelector(
  getPageSettingData,
  (data) => {
    const { policyConfigToPageSettingViewConfig } = usePageSettingHelpers();
    return policyConfigToPageSettingViewConfig(data.config);
  },
);

export const getManageEntitiesConfigData = createSelector(
  getPageSettingData,
  (data) => data.manageEntitiesConfig ?? {},
);

export const getPageSettingPolicies = createSelector(
  getPageSettingData,
  (data) => data.config,
);

/**
 * Get server error
 * @param pageSetting - State pageSetting
 */
export const getServerError = ({ pageSetting }: IStoreState) =>
  pageSetting.error;

export const getIsPageSettingUpdateInProgress = createSelector(
  getPageSettingData as any,
  (data) => data.isPageSettingUpdateInProgress,
);
