import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import EnhancedTable, {
  ITableSyncProps,
} from '../../../components/EnhancedTable';
import {
  getChecklistsCountRequest,
  getChecklistsRequest,
  deleteChecklistRequest,
} from '../../../modules/actions';
import { useFilter } from '../../../modules/utils/hooks';
import {
  getChecklistRefreshKey,
  getChecklistsAsTableData,
  getChecklistsCount,
} from 'src/modules/selectors/checklist.selector';
import {
  useChecklistTableFilters,
  useChecklistTableHeadCells,
  useFetchChecklistsEffect,
  useChecklistTableDefaultFilter,
} from 'src/modules/utils/hooks/checklist';
import { CreateChecklist } from './forms/CreateChecklist';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import { Close, DeleteForever } from '@mui/icons-material';
import { AnyObject } from 'src/modules/types';
import { UpdateChecklist } from './forms/UpdateChecklist';
import { ChecklistFormTour } from './tours/ChecklistFormTour';

export const ChecklistView: React.FC = () => {
  const dispatcher = useDispatch();
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [activeChecklistId, setActiveChecklistId] = React.useState<
    number | undefined
  >();

  const defaultFilter = useChecklistTableDefaultFilter();
  const checklists = useSelector(getChecklistsAsTableData);
  const count = useSelector(getChecklistsCount);
  const headCells = useChecklistTableHeadCells();
  const filters = useChecklistTableFilters();
  const { filterCount } = useFilter(defaultFilter);

  useFetchChecklistsEffect(defaultFilter);
  useEffect(() => {
    dispatcher(getChecklistsCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  // handle table synchronization
  const onSync = ({
    order,
    page,
    rowsPerPage,
    where,
    include: newInclude,
  }: ITableSyncProps) => {
    const offset = page * rowsPerPage;
    dispatcher(
      getChecklistsRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );

    dispatcher(
      getChecklistsCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  const onRowClick = (checklistDetails: AnyObject) => {
    setActiveChecklistId(checklistDetails.id);
    setIsDialogOpen(true);
  };

  const onChecklistDelete = () => {
    activeChecklistId && dispatcher(deleteChecklistRequest(activeChecklistId));

    setIsDialogOpen(false);
  };

  const onClose = () => {
    setIsDialogOpen(false);
    setActiveChecklistId(undefined);
  };

  return (
    <>
      <EnhancedTable
        disableSelection
        data={checklists}
        count={count}
        selectIndex="id"
        disableQsFilters={true}
        tableName={t('checklist.checklist_title')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        include={defaultFilter.include}
        createEntityBtnProps={{
          onClick: () => setIsDialogOpen(true),
          title: t('checklist.create'),
        }}
        onRowClick={onRowClick}
      />
      <Dialog fullWidth open={isDialogOpen} maxWidth="sm" onClose={onClose}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 3 }}>
            <ChecklistFormTour />
            <DialogTitle>
              <Box>
                {t(activeChecklistId ? 'checklist.edit' : 'checklist.create')}
              </Box>
            </DialogTitle>
          </Box>
          <Box>
            {activeChecklistId && (
              <IconButton onClick={onChecklistDelete} size="large">
                <DeleteForever color="error" />
              </IconButton>
            )}
            <IconButton onClick={onClose} size="large">
              <Close />
            </IconButton>
          </Box>
        </Box>
        <DialogContent dividers>
          {activeChecklistId ? (
            <UpdateChecklist
              checklistId={activeChecklistId}
              onSubmit={onClose}
            />
          ) : (
            <CreateChecklist onSubmit={onClose} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function ChecklistViewRefreshable() {
  return <ChecklistView key={useSelector(getChecklistRefreshKey)} />;
}
