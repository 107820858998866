import React, { FC } from 'react';
import { FilterByEntityLayout } from '../components/FilterByEntityLayout';
import {
  FilterBySitesComponent,
  IFilterBySitesComponent,
} from './FilterBySitesComponent';

interface IFilterBySitesProps extends IFilterBySitesComponent {
  expanded?: boolean;
  alwaysExpanded?: boolean;
  title?: string;
}

export const FilterBySites: FC<IFilterBySitesProps> = ({
  children,
  entityId,
  onSelect,
  expanded = true,
  alwaysExpanded = undefined,
  title,
}) => {
  return (
    <FilterByEntityLayout
      title={title}
      expanded={expanded}
      alwaysExpanded={alwaysExpanded}
      filterComponent={
        <FilterBySitesComponent entityId={entityId} onSelect={onSelect} />
      }
    >
      {children}
    </FilterByEntityLayout>
  );
};

export default FilterBySites;
