import { map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import {
  useTerminatedEmployees,
  useTerminatedEmployeesComposeWhere,
  useTerminatedEmployeesTableFiltersConfiguration,
} from 'src/modules/utils/hooks/terminated-employees.hooks';
import { TerminatedEmployeesFilter } from 'src/pages/TerminatedEmployees/components/TerminatedEmployeesFilter';
import { TableContextProvider } from 'src/components/Table/TableContext';

const reportUrl = '/employees/terminated';

export const TerminatedEmployees: React.FC = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl);

  const filterFieldsConfiguration = useTerminatedEmployeesTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useTerminatedEmployeesComposeWhere({
    firstName: filterFields.firstName.value,
    lastName: filterFields.lastName.value,
    supervisorName: filterFields.supervisorName.value,
    badge: +filterFields.badge.value,
    employeeId: +filterFields.employeeId.value,
    shiftId: +filterFields.shiftId.value,
    siteId: +filterFields.siteId.value,
    staffingProvider: filterFields.staffingProvider.value,
    separationdate: filterFields.separationdate.value,
    terminationReasonName: filterFields.terminationReasonName.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isCountDataLoading,
    countData,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useTerminatedEmployees(reportUrl, where);

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(data, (item) => {
        return {
          ...item,
          maxLoggedTime: item.maxLoggedTime
            ? composeDate(
                item.maxLoggedTime,
                parse(DATETIME_TIMEZONE),
                format(DATE_FORMAT),
              )
            : '',
          separationdate: item.separationdate
            ? composeDate(
                item.separationdate,
                parse(DATETIME_TIMEZONE),
                format(DATE_FORMAT),
              )
            : '',
        };
      }),
    [data],
  );

  return (
    <TableContextProvider>
      <PageContentWithTopToolbar>
        <PageContentChildContainer sx={{ pb: 1, pt: 2 }} fullHeight={false}>
          <TableToolbar>
            <TableTitle>{t('employees.terminated.page_title')}</TableTitle>

            <TableActions>
              <TerminatedEmployeesFilter
                getLabel={getLabel}
                onFiltersFormSubmit={onFiltersFormSubmit}
                getFilterCommonPropsByFilterName={
                  getFilterCommonPropsByFilterName
                }
              />

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={requestFilters}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>

        <PageContentChildContainer>
          <ReportTable
            heightWithTabs
            data={computedList as any}
            count={countData.count}
            isDataLoading={isDataLoading || isCountDataLoading}
            tableUniqueKey={reportUrl}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        </PageContentChildContainer>
      </PageContentWithTopToolbar>
    </TableContextProvider>
  );
};
