import React from 'react';
import { Sheet } from '@mui/joy';

interface IDrawerBodySectionContentProps {
  children: React.ReactNode;
}

export const DrawerBodySectionContent = ({
  children,
}: IDrawerBodySectionContentProps) => {
  return (
    <Sheet
      sx={{
        bgcolor: 'inherit',
      }}
    >
      {children}
    </Sheet>
  );
};
