import React from 'react';
import { Box } from '@mui/material';

import { KpiRating } from 'src/modules/utils';

export const KpiDataGridWrapper: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        [`& .${KpiRating.red}`]: {
          backgroundColor: '#FF9F89',
          fontWeight: '600',
        },
        [`& .${KpiRating.green}`]: {
          backgroundColor: '#C6E0B4',
          fontWeight: '600',
        },
      }}
    >
      {children}
    </Box>
  );
};
