import React from 'react';
import TabPanel, { TabPanelProps } from '@mui/joy/TabPanel';
import { PageContentCopyright } from '../PageContentCopyright';

interface IPageContentTabPanelProps extends TabPanelProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}

export const PageContentTabPanel = ({
  children,
  sx,
  fullHeight = true,
  ...props
}: IPageContentTabPanelProps) => {
  return (
    <TabPanel
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pt: 0,
        pb: 0,
        px: 2,
        ...(fullHeight ? { overflow: 'auto' } : {}),
        ...sx,
      }}
      {...props}
    >
      {children}

      <PageContentCopyright />
    </TabPanel>
  );
};
