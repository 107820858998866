import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useValidate } from '../../../modules/utils/hooks';
import { ICreatePolicyByPageRequest } from '../../../modules/types';
import { createPolicyByPageScheme } from '../../../modules/schemes';
import { FormActions } from '../../../components/Form/FormActions';
import RolesCombobox from '../../../components/Formik/comboboxes-with-entities/RolesCombobox';
import PagesCombobox from '../../../components/Formik/comboboxes-with-entities/PagesCombobox';
import { createPolicyByPageRequest } from '../../../modules/actions';
import {
  MultiRowForm,
  MultiRowFormContainer,
  MultiRowFormDeleteRow,
  MultiRowFormField,
  MultiRowFormRow,
} from '../../../components/MultiRowForm';
import { MultiRowFormAddRow } from '../../../components/MultiRowForm/MultiRowFormAddRow';
import { getIsPoliciesCreateByConfigInProgress } from 'src/modules/selectors/policy';

export const PoliciesCreateByPage = () => {
  const { t } = useTranslation();

  const isLoading = useSelector(getIsPoliciesCreateByConfigInProgress);

  const initialValues: ICreatePolicyByPageRequest = {
    page: '',
    role: '',
  };

  const validate = useValidate<Array<ICreatePolicyByPageRequest>>(
    createPolicyByPageScheme,
  );

  // create dispatcher
  const dispatcher = useDispatch();

  const formik = useFormik<Array<ICreatePolicyByPageRequest>>({
    initialValues: [initialValues],
    validate,
    onSubmit: (data) => {
      dispatcher(createPolicyByPageRequest(data));
    },
  });

  return (
    <MultiRowFormContainer>
      <MultiRowForm onSubmit={formik.handleSubmit} noValidate>
        {formik.values.map((_, index) => (
          <MultiRowFormRow key={index}>
            <MultiRowFormField
              fieldId={`${index}.page`}
              fieldLabel={t('policies.page')}
              renderField={({ id, label }) => (
                <PagesCombobox
                  required
                  id={id}
                  name={id}
                  placeholder={label}
                  formik={formik}
                />
              )}
            />
            <MultiRowFormField
              fieldId={`${index}.role`}
              fieldLabel={t('policies.role')}
              renderField={({ id, label }) => (
                <RolesCombobox
                  id={id}
                  name={id}
                  required
                  placeholder={label}
                  fieldAsId="key"
                  formik={formik}
                />
              )}
            />
            <MultiRowFormDeleteRow formik={formik} index={index} />
          </MultiRowFormRow>
        ))}

        <FormActions
          isLoading={isLoading}
          submitBtnTitle={t('common.create')}
        />

        <MultiRowFormAddRow
          formik={formik}
          nullishValues={{ page: '', role: '' }}
        />
      </MultiRowForm>
    </MultiRowFormContainer>
  );
};
