import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SupervisorsComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SupervisorsComboBoxFilter';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FilterValue, FilterValueDescriptor } from 'src/modules/types/table';

interface IPayboardSummaryByWorkAndSupervisorWeekFilterProps<
  T = Record<string, any>
> {
  siteId?: number;
  onFiltersFormSubmit(
    filtersData: Record<string, FilterValue>,
  ): Record<string, FilterValueDescriptor>;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const PayboardSummaryByWorkAndSupervisorWeekFilter: React.FC<IPayboardSummaryByWorkAndSupervisorWeekFilterProps> = ({
  siteId,
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'firstName',
            labelPrefix: t('payboard.summary_by_work_and_supervisor.firstName'),
          })}
          {...getFilterCommonPropsByFilterName('firstName')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <TextFieldFilter
          label={getLabel({
            filterName: 'lastName',
            labelPrefix: t('payboard.summary_by_work_and_supervisor.lastName'),
          })}
          {...getFilterCommonPropsByFilterName('lastName')}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SupervisorsComboBoxFilter
          siteId={siteId}
          label={getLabel({
            filterName: 'supervisorId',
            labelPrefix: t(
              'payboard.summary_by_work_and_supervisor.supervisor',
            ),
          })}
          {...getFilterCommonPropsByFilterName('supervisorId')}
        />
      </FormFieldContainer>
    </Filter>
  );
};
