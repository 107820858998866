import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  IChecklistTaskModel,
  IChecklistState,
  ICountResponse,
  IChecklistModelWithRelations,
} from '../types';

// default state
const defaultState: IChecklistState = {
  refreshKey: 0,
  taskRefreshKey: 0,
  checklists: [],
  tasks: [],
  combobox: [],
  count: 0,
};

const getChecklistsRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getChecklistsSuccess = (
  state: IChecklistState,
  checklists: IChecklistModelWithRelations[],
) => ({
  ...state,
  checklists,
  is_data_requested: false,
});
const getChecklistsFailed = (state: IChecklistState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getChecklistsCountRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getChecklistsCountSuccess = (
  state: IChecklistState,
  count: ICountResponse,
) => ({
  ...state,
  ...count,
  is_data_requested: false,
});
const getChecklistsCountFailed = (state: IChecklistState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getChecklistComboboxDataRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getChecklistComboboxDataSuccess = (
  state: IChecklistState,
  combobox: IChecklistModelWithRelations[],
) => ({
  ...state,
  combobox,
  is_data_requested: false,
});
const getChecklistComboboxDataFailed = (
  state: IChecklistState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const createChecklistTaskRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const createChecklistTaskSuccess = (state: IChecklistState) => ({
  ...state,
  is_data_requested: false,
});
const createChecklistTaskFailed = (
  state: IChecklistState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updateChecklistTaskRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateChecklistTaskSuccess = (state: IChecklistState) => ({
  ...state,
  is_data_requested: false,
});
const updateChecklistTaskFailed = (
  state: IChecklistState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const deleteChecklistTaskRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteChecklistTaskSuccess = (
  state: IChecklistState,
  deletedTaskId: IChecklistTaskModel['id'],
) => ({
  ...state,
  tasks: state.tasks.filter((task) => task.id !== deletedTaskId),
  is_data_requested: false,
});
const deleteChecklistTaskFailed = (
  state: IChecklistState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const deleteChecklistRecurringTaskRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteChecklistRecurringTaskSuccess = (state: IChecklistState) => ({
  ...state,
  is_data_requested: false,
});
const deleteChecklistRecurringTaskFailed = (
  state: IChecklistState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const createChecklistRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const createChecklistSuccess = (state: IChecklistState) => ({
  ...state,
  is_data_requested: false,
});
const createChecklistFailed = (state: IChecklistState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updateChecklistRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateChecklistSuccess = (state: IChecklistState) => ({
  ...state,
  is_data_requested: false,
});
const updateChecklistFailed = (state: IChecklistState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const deleteChecklistRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteChecklistSuccess = (state: IChecklistState) => ({
  ...state,
  is_data_requested: false,
});
const deleteChecklistFailed = (state: IChecklistState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getChecklistTasksRequest = (state: IChecklistState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getChecklistTasksSuccess = (
  state: IChecklistState,
  tasks: IChecklistTaskModel[],
) => ({
  ...state,
  is_data_requested: false,
  tasks,
});
const getChecklistTasksFailed = (state: IChecklistState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const flushChecklistState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

const flushChecklistTaskState = () => ({
  ...defaultState,
  taskRefreshKey: new Date().getTime(),
});

export const checklist = createReducer<IChecklistState>({}, defaultState)
  .on(actions.flushChecklistState, flushChecklistState)
  .on(actions.flushChecklistTaskState, flushChecklistTaskState)

  .on(actions.getChecklistTasksRequest, getChecklistTasksRequest)
  .on(actions.getChecklistTasksSuccess, getChecklistTasksSuccess)
  .on(actions.getChecklistTasksFailed, getChecklistTasksFailed)

  .on(actions.createChecklistTaskRequest, createChecklistTaskRequest)
  .on(actions.createChecklistTaskSuccess, createChecklistTaskSuccess)
  .on(actions.createChecklistTaskFailed, createChecklistTaskFailed)

  .on(actions.updateChecklistTaskRequest, updateChecklistTaskRequest)
  .on(actions.updateChecklistTaskSuccess, updateChecklistTaskSuccess)
  .on(actions.updateChecklistTaskFailed, updateChecklistTaskFailed)

  .on(actions.deleteChecklistTaskRequest, deleteChecklistTaskRequest)
  .on(actions.deleteChecklistTaskSuccess, deleteChecklistTaskSuccess)
  .on(actions.deleteChecklistTaskFailed, deleteChecklistTaskFailed)

  .on(
    actions.deleteChecklistRecurringTaskRequest,
    deleteChecklistRecurringTaskRequest,
  )
  .on(
    actions.deleteChecklistRecurringTaskSuccess,
    deleteChecklistRecurringTaskSuccess,
  )
  .on(
    actions.deleteChecklistRecurringTaskFailed,
    deleteChecklistRecurringTaskFailed,
  )

  .on(actions.createChecklistRequest, createChecklistRequest)
  .on(actions.createChecklistSuccess, createChecklistSuccess)
  .on(actions.createChecklistFailed, createChecklistFailed)

  .on(actions.updateChecklistRequest, updateChecklistRequest)
  .on(actions.updateChecklistSuccess, updateChecklistSuccess)
  .on(actions.updateChecklistFailed, updateChecklistFailed)

  .on(actions.deleteChecklistRequest, deleteChecklistRequest)
  .on(actions.deleteChecklistSuccess, deleteChecklistSuccess)
  .on(actions.deleteChecklistFailed, deleteChecklistFailed)

  .on(actions.getChecklistsRequest, getChecklistsRequest)
  .on(actions.getChecklistsSuccess, getChecklistsSuccess)
  .on(actions.getChecklistsFailed, getChecklistsFailed)

  .on(actions.getChecklistsCountRequest, getChecklistsCountRequest)
  .on(actions.getChecklistsCountSuccess, getChecklistsCountSuccess)
  .on(actions.getChecklistsCountFailed, getChecklistsCountFailed)

  .on(actions.getChecklistComboboxDataRequest, getChecklistComboboxDataRequest)
  .on(actions.getChecklistComboboxDataSuccess, getChecklistComboboxDataSuccess)
  .on(actions.getChecklistComboboxDataFailed, getChecklistComboboxDataFailed);

export default checklist;
