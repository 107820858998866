import { createReducer } from 'redux-act';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import * as actions from '../../actions';

import {
  ICountResponse,
  IOverdueQuestionnaire,
  IQuestionEmployeeHistoryItem,
  IQuestionEmployeeState,
} from '../../types';

const defaultState: IQuestionEmployeeState = {
  history: {
    list: [],
    count: 0,
    refreshKey: 0,
  },
  overdue: {
    list: [],
    count: 0,
  },
  isOverdueQuestionnairesInProgress: false,
  isOverdueQuestionnairesCountInProgress: false,
  isQuestionEmployeeHistoryDataRequestInProgress: false,
};

const flushQuestionEmployeeState = (state: IQuestionEmployeeState) => ({
  ...state,
  history: {
    ...defaultState.history,
    refreshKey: new Date().getTime(),
  },
});

const fillInQuestionnaireRequest = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const fillInQuestionnaireSuccess = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});
const fillInQuestionnaireFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updateQuestionnaireAnswersRequest = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateQuestionnaireAnswersSuccess = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});
const updateQuestionnaireAnswersFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getQuestionEmployeeHistoryDataRequest = (
  state: IQuestionEmployeeState,
) => ({
  ...state,
  error: false,
  isQuestionEmployeeHistoryDataRequestInProgress: true,
});
const getQuestionEmployeeHistoryDataSuccess = (
  state: IQuestionEmployeeState,
  payload: IQuestionEmployeeHistoryItem[],
) => ({
  ...state,
  history: {
    ...state.history,
    list: payload,
  },
  error: false,
  isQuestionEmployeeHistoryDataRequestInProgress: false,
});
const getQuestionEmployeeHistoryDataFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isQuestionEmployeeHistoryDataRequestInProgress: false,
});

const getQuestionEmployeeHistoryDataCountRequest = (
  state: IQuestionEmployeeState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getQuestionEmployeeHistoryDataCountSuccess = (
  state: IQuestionEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  history: {
    ...state.history,
    ...payload,
  },
  error: false,
  is_data_requested: false,
});
const getQuestionEmployeeHistoryDataCountFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getOverdueQuestionnairesRequest = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  isOverdueQuestionnairesInProgress: true,
});
const getOverdueQuestionnairesSuccess = (
  state: IQuestionEmployeeState,
  payload: IOverdueQuestionnaire[],
) => ({
  ...state,
  overdue: {
    ...state.overdue,
    list: payload.map((item) => ({
      ...item,
      answerDate: composeDate(
        item.reviewedAt,
        parse(DATETIME_TIMEZONE),
        format(DATETIME_FORMAT_TO_SHOW),
      ),
      nextReviewAt: composeDate(
        item.nextReviewAt,
        parse(DATETIME_TIMEZONE),
        format(DATETIME_FORMAT_TO_SHOW),
      ),
    })),
  },
  error: false,
  isOverdueQuestionnairesInProgress: false,
});
const getOverdueQuestionnairesFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isOverdueQuestionnairesInProgress: false,
});

const getOverdueQuestionnairesCountRequest = (
  state: IQuestionEmployeeState,
) => ({
  ...state,
  error: false,
  isOverdueQuestionnairesCountInProgress: true,
});
const getOverdueQuestionnairesCountSuccess = (
  state: IQuestionEmployeeState,
  payload: ICountResponse,
) => ({
  ...state,
  overdue: {
    ...state.overdue,
    ...payload,
  },
  error: false,
  isOverdueQuestionnairesCountInProgress: false,
});
const getOverdueQuestionnairesCountFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isOverdueQuestionnairesCountInProgress: false,
});

const deleteQuestionEmployeeRequest = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteQuestionEmployeeSuccess = (state: IQuestionEmployeeState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});
const deleteQuestionEmployeeFailed = (
  state: IQuestionEmployeeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * QuestionEmployee reducer
 */
export const questionEmployee = createReducer<IQuestionEmployeeState>(
  {},
  defaultState,
)
  .on(actions.flushQuestionEmployeeState, flushQuestionEmployeeState)
  // creation actions
  .on(actions.fillInQuestionnaireRequest, fillInQuestionnaireRequest)
  .on(actions.fillInQuestionnaireSuccess, fillInQuestionnaireSuccess)
  .on(actions.fillInQuestionnaireFailed, fillInQuestionnaireFailed)
  // update
  .on(
    actions.updateQuestionnaireAnswersRequest,
    updateQuestionnaireAnswersRequest,
  )
  .on(
    actions.updateQuestionnaireAnswersSuccess,
    updateQuestionnaireAnswersSuccess,
  )
  .on(
    actions.updateQuestionnaireAnswersFailed,
    updateQuestionnaireAnswersFailed,
  )
  // get list actions
  .on(
    actions.getQuestionEmployeeHistoryDataRequest,
    getQuestionEmployeeHistoryDataRequest,
  )
  .on(
    actions.getQuestionEmployeeHistoryDataSuccess,
    getQuestionEmployeeHistoryDataSuccess,
  )
  .on(
    actions.getQuestionEmployeeHistoryDataFailed,
    getQuestionEmployeeHistoryDataFailed,
  )
  // get count actions
  .on(
    actions.getQuestionEmployeeHistoryDataCountRequest,
    getQuestionEmployeeHistoryDataCountRequest,
  )
  .on(
    actions.getQuestionEmployeeHistoryDataCountSuccess,
    getQuestionEmployeeHistoryDataCountSuccess,
  )
  .on(
    actions.getQuestionEmployeeHistoryDataCountFailed,
    getQuestionEmployeeHistoryDataCountFailed,
  )
  // get list actions
  .on(actions.getOverdueQuestionnairesRequest, getOverdueQuestionnairesRequest)
  .on(actions.getOverdueQuestionnairesSuccess, getOverdueQuestionnairesSuccess)
  .on(actions.getOverdueQuestionnairesFailed, getOverdueQuestionnairesFailed)
  // get count actions
  .on(
    actions.getOverdueQuestionnairesCountRequest,
    getOverdueQuestionnairesCountRequest,
  )
  .on(
    actions.getOverdueQuestionnairesCountSuccess,
    getOverdueQuestionnairesCountSuccess,
  )
  .on(
    actions.getOverdueQuestionnairesCountFailed,
    getOverdueQuestionnairesCountFailed,
  )
  // deletion actions
  .on(actions.deleteQuestionEmployeeRequest, deleteQuestionEmployeeRequest)
  .on(actions.deleteQuestionEmployeeSuccess, deleteQuestionEmployeeSuccess)
  .on(actions.deleteQuestionEmployeeFailed, deleteQuestionEmployeeFailed);

export default questionEmployee;
