import React from 'react';
import {
  Button,
  Sheet,
  Typography,
  ButtonGroup as JoyButtonGroup,
  ButtonGroupProps,
  Box,
} from '@mui/joy';
import { ActiveItemIndicator } from 'src/components/ActiveItemIndicator';

export * from './ButtonsGroupContainer';

interface IButtonInButtonGroup<T extends string | number | boolean> {
  value: T;
  label: string;
  isDisabled?: boolean;
}

interface IButtonGroupProps<U extends string | number | boolean> {
  label?: string;
  buttons: Array<IButtonInButtonGroup<U>>;
  onButtonClick: (value: U) => void;
  activeButtonValue?: string | number | boolean;
}

export const ButtonGroup = <M extends string | number | boolean>({
  label,
  buttons,
  onButtonClick,
  activeButtonValue,
  ...rest
}: IButtonGroupProps<M> & ButtonGroupProps) => {
  // In EnhancedTable component there is initialFilterData and when it used
  // the value will be in a such format: { [operator: string]: value } ex: {eq: true},
  // so we need to parse this case
  const parsedActiveButtonValue =
    activeButtonValue && typeof activeButtonValue === 'object'
      ? Object.values(activeButtonValue)[0]
      : activeButtonValue;
  return (
    <Sheet sx={{ bgcolor: 'inherit' }}>
      {label && (
        <Typography level="text_sm" fontWeight="medium" sx={{ mb: 1 }}>
          {label}
        </Typography>
      )}
      <JoyButtonGroup
        size="md"
        sx={{ borderRadius: 'md', bgcolor: 'inherit' }}
        {...rest}
      >
        {buttons.map((item) => (
          <Button
            key={item.label}
            onClick={() => onButtonClick(item.value)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'colors.background.bg_primary',
            }}
          >
            {item.value === parsedActiveButtonValue && (
              <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
                <ActiveItemIndicator />
              </Box>
            )}
            {item.label}
          </Button>
        ))}
      </JoyButtonGroup>
    </Sheet>
  );
};
