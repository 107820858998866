import React from 'react';
import { Box } from '@mui/joy';
import { PageContent } from './PageContent';
import { TopToolbarRegular } from '../TopToolbar';
import { PageContentCopyright } from './PageContentCopyright';

interface IPageContentWithTopToolbarProps {
  children: React.ReactNode;
}

export const PageContentWithTopToolbar = ({
  children,
}: IPageContentWithTopToolbarProps) => {
  return (
    <PageContent hideCopyright>
      <Box sx={{ flexShrink: 0, flexGrow: 0 }}>
        <TopToolbarRegular />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {children}
        </Box>
        <Box sx={{ flexShrink: 0, flexGrow: 0 }}>
          <PageContentCopyright />
        </Box>
      </Box>
    </PageContent>
  );
};
