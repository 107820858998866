import { IDeleteRelationModel, IStoreState } from '../types';
import { createSelector } from 'reselect';
import { has } from 'lodash';

/**
 * Get delete relation state
 * @param deleteRelations - State deleteRelations
 */
export const getDeleteRelationsList = ({ deleteRelations }: IStoreState) =>
  deleteRelations.list;

/**
 * Prepare Delete Relations
 */
export const getDeleteRelations = createSelector(
  getDeleteRelationsList,
  (deleteRelationsList: IDeleteRelationModel) => (
    modelName: string,
  ): IDeleteRelationModel | undefined => {
    /**
     * Did this way because of we need undefined to make decision
     * to fetch data inside the useFetchDeleteRelations hook
     * if (isEmpty(relations) && !isEmpty(modelName)) {...
     */
    if (!has(deleteRelationsList, modelName)) {
      return undefined;
    }

    return {
      [modelName]: deleteRelationsList[modelName],
    };
  },
);
