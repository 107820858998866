import { JSONSchemaType } from 'ajv';
import { IUploadProductionData } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const uploadProductionDataScheme: JSONSchemaType<IUploadProductionData> = {
  type: 'object',
  required: ['siteId', 'file'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    file: {
      default: null,
      required: [],
      type: 'object',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
  },
};
