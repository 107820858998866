import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import {
  useCostPlusWeekly,
  useCostPlusWeeklyComposeWhere,
  useCostPlusWeeklyTableFiltersConfiguration,
} from 'src/modules/utils/hooks/weekly-cost-plus.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { ReportPage } from 'src/components/ReportPage';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { CostPlusWeeklyFilterFilter } from 'src/pages/CustomerReports/components/CostPlusWeeklyFilter';

const reportUrl = '/cost-plus-weekly';

export const CostPlusWeeklyList = () => {
  const { t } = useTranslation();
  const exportProps = useReportExportProps(reportUrl);

  const filterFieldsConfiguration = useCostPlusWeeklyTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useCostPlusWeeklyComposeWhere({
    siteId: +filterFields.siteId.value,
    shift: filterFields.shift.value,
    department: filterFields.department.value,
    year: +filterFields.year.value,
    week: +filterFields.week.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isCountDataLoading,
    countData,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useCostPlusWeekly(reportUrl, where);

  return (
    <ReportPage hideNoData>
      <PageContentWithTopToolbar>
        <PageContentChildContainer sx={{ pt: 2, pb: 1 }} fullHeight={false}>
          <TableToolbar>
            <TableTitle>
              {t('main_menu.customer_reports.cost_plus_weekly')}
            </TableTitle>

            <TableActions>
              <CostPlusWeeklyFilterFilter
                getLabel={getLabel}
                onFiltersFormSubmit={onFiltersFormSubmit}
                getFilterCommonPropsByFilterName={
                  getFilterCommonPropsByFilterName
                }
              />

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={requestFilters}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>

        <ReportTable
          heightWithTabs
          data={data.items as any}
          count={countData.count}
          summaryData={data.summary}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      </PageContentWithTopToolbar>
    </ReportPage>
  );
};
