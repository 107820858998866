import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IOpenPositionModel,
  ISagaAction,
  ICreateOpenPosition,
  IUpdateOpenPosition,
  IFilter,
  IOpenPositionDeleteRequest,
  IdsArray,
  ICountResponse,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../../types';
import { paths } from '../../../config';
import { ROWS_PER_PAGE_DEFAULT } from '../../../components/EnhancedTable';
import { map } from 'lodash';

/**
 * Create a new openPosition
 */
export const createOpenPositionRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreateOpenPosition>>): SagaIterator {
  try {
    const response: IOpenPositionModel = yield call(
      Api.OpenPosition.create,
      data,
    );
    yield put(actions.flushOpenPositionState());
    yield put(actions.createOpenPositionSuccess(response));
    yield call(navigate, {
      pathname: paths.RECRUITING_DASHBOARD_DETAILS.replace(
        ':siteId',
        `${data.siteId}`,
      ),
    });
  } catch (e) {
    yield put(actions.createOpenPositionFailed());
  }
};

/**
 * Delete openPositions
 */
export const deleteOpenPositionRequestSaga = function* (
  action: ISagaAction<IOpenPositionDeleteRequest>,
): SagaIterator {
  try {
    yield call(Api.OpenPosition.delete, {
      where: {
        id: {
          inq: action.payload.ids,
        },
      },
    });
    yield put(actions.flushOpenPositionState());
    yield put(actions.deleteOpenPositionSuccess());
    const requestData = {
      siteId: action.payload.siteId,
      filter: {
        filter: {
          offset: 0,
          limit: ROWS_PER_PAGE_DEFAULT,
        },
      },
    };
    yield put(actions.getRecruitingDashboardDetailsListRequest(requestData));
    yield put(actions.getRecruitingDashboardDetailsCountRequest(requestData));
  } catch (e) {
    yield put(actions.deleteOpenPositionFailed());
  }
};

/**
 * Bulk update openPositions
 */
export const updateOpenPositionsRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IUpdateOpenPosition[]>>): SagaIterator {
  try {
    const [{ siteId }] = data;
    yield call(Api.OpenPosition.bulkUpdate, data);
    yield put(actions.flushOpenPositionState());
    yield put(actions.updateOpenPositionSuccess());
    yield call(navigate, {
      pathname: paths.RECRUITING_DASHBOARD_DETAILS.replace(
        ':siteId',
        (siteId as unknown) as string,
      ),
    });
  } catch (e) {
    yield put(actions.updateOpenPositionsFailed());
  }
};

/**
 * Get open position list
 */
export const getOpenPositionListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IOpenPositionModel[] = yield call(
      Api.OpenPosition.list,
      action.payload,
    );
    yield put(actions.getOpenPositionListSuccess(response));
  } catch (e) {
    yield put(actions.getOpenPositionListFailed());
  }
};

/**
 * Get openPosition count
 */
export const getOpenPositionCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.OpenPosition.count,
      action?.payload,
    );
    yield put(actions.getOpenPositionCountSuccess(response));
  } catch (e) {
    yield put(actions.getOpenPositionCountFailed());
  }
};

/**
 * Get combobox list
 */
export const getOpenPositionComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IOpenPositionModel>[] = yield call(
      Api.OpenPosition.list,
      action.payload,
    );
    yield put(actions.getOpenPositionComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getOpenPositionComboboxListFailed());
  }
};

/**
 * Close open positions
 */
export const closeOpenPositionRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(
      Api.OpenPosition.bulkUpdate,
      (map(action.payload, (id) => ({
        id,
        closed: true,
      })) as unknown) as IUpdateOpenPosition[],
    );
    yield put(actions.closeOpenPositionSuccess());
    yield put(actions.flushOpenPositionState());
  } catch (e) {
    yield put(actions.closeOpenPositionFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createOpenPositionRequest,
    saga: createOpenPositionRequestSaga,
  },
  {
    action: actions.deleteOpenPositionRequest,
    saga: deleteOpenPositionRequestSaga,
  },
  // bulk updating open position
  {
    action: actions.updateOpenPositionsRequest,
    saga: updateOpenPositionsRequestSaga,
  },
  {
    action: actions.getOpenPositionListRequest,
    saga: getOpenPositionListRequestSaga,
  },
  {
    action: actions.getOpenPositionCountRequest,
    saga: getOpenPositionCountRequestSaga,
  },
  {
    action: actions.getOpenPositionComboboxListRequest,
    saga: getOpenPositionComboboxListRequestSaga,
  },
  {
    action: actions.closeOpenPositionRequest,
    saga: closeOpenPositionRequestSaga,
  },
];
