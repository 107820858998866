import React from 'react';
import { useSiteRelationInclusion } from './sites';

export const useAdditionalFeeDefaultFilter = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => {
    return {
      include: [{ relation: 'additionalFeeCategory' }, siteRelationInclusion],
      order: ['createdAt DESC'],
    };
  }, [siteRelationInclusion]);
};
