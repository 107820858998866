import React from 'react';
import { Sheet, SheetProps } from '@mui/joy';

interface IDrawerBodyProps extends SheetProps {
  children: React.ReactNode;
}

export const DrawerBody = ({ children, ...rest }: IDrawerBodyProps) => {
  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'hidden',
        bgcolor: 'base.white',
        position: 'relative',
      }}
    >
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          backgroundColor: 'transparent',
          overflowY: 'auto',
        }}
        {...rest}
      >
        {children}
      </Sheet>
    </Sheet>
  );
};
