import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { updateClientsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { updateClientScheme } from 'src/modules/schemes/clients';
import { getClientList } from 'src/modules/selectors/client';

const initialValues = {
  name: '',
  clientKey: '',
};

interface IClientsFormsUpdateClientProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  clientsToUpdateIds: IdsArray;
}

export const ClientsFormsUpdateClient = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  clientsToUpdateIds,
}: IClientsFormsUpdateClientProps) => {
  const { t } = useTranslation();

  const allClients = useSelector(getClientList);

  const clientsToUpdateFullData = allClients.filter((client) =>
    clientsToUpdateIds.includes(client.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updateClientScheme);

  const formik = useFormik({
    initialValues: clientsToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateClientsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('client.update_clients_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {clientsToUpdateFullData.map((client, index) => (
              <DrawerBodySectionContent key={client.id}>
                <DrawerBodySectionTitle>{`${client.name}`}</DrawerBodySectionTitle>
                <FormFieldContainer>
                  <FormikTextField
                    required
                    fullWidth
                    id={generateFullIdInMultiEntitiesForm('name', index)}
                    name={generateFullIdInMultiEntitiesForm('name', index)}
                    formik={formik}
                    variant="outlined"
                    label={t('clients.name')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    required
                    id={generateFullIdInMultiEntitiesForm('clientKey', index)}
                    name={generateFullIdInMultiEntitiesForm('clientKey', index)}
                    formik={formik}
                    variant="outlined"
                    label={t('clients.clientKey')}
                  />
                </FormFieldContainer>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
