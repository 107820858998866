import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IUpdatePoint } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createPointScheme } from './create-points.scheme';

export const updatePointsScheme: JSONSchemaType<IUpdatePoint[]> = {
  type: 'array',
  items: {
    ...createPointScheme,
    type: 'object',
    required: ['datetime', 'employeeId', 'pointTypeId', 'id'],
    properties: {
      ...createPointScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
