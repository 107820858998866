import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  IFilter,
  ISagaAction,
  IWhere,
  PayboardDetailsRequest,
  PayboardDetailsWithTotal,
  ListWithSummary,
  IPayboardModel,
  PayboardWeek,
  IDailyHoursReportItem,
  ISagaActionBind,
  NewPayboardWeek,
  IPayboardCostByDay,
  IPayboardCostByDayAndDepartment,
  IPayboardCostByDepartment,
  IPayboardDetailedModel,
  PayboardDay,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';

/**
 * Get payboard list
 */
export const getPayboardListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IPayboardModel> = yield call(
      Api.Payboard.list,
      action.payload,
    );
    yield put(actions.getPayboardListSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardListFailed());
  }
};

/**
 * Get payboard count
 */
export const getPayboardCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.count,
      action?.payload,
    );
    yield put(actions.getPayboardCountSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardCountFailed());
  }
};

/**
 * Get payboard list
 */
export const getPayboardDetailedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IPayboardDetailedModel> = yield call(
      Api.Payboard.listDetailed,
      action.payload,
    );
    yield put(actions.getPayboardDetailedListSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardDetailedListFailed());
  }
};

/**
 * Get payboard count
 */
export const getPayboardDetailedCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.countDetailed,
      action?.payload,
    );
    yield put(
      actions.getPayboardDetailedCountSuccess({
        countDetailed: response.count,
      }),
    );
  } catch (e) {
    yield put(actions.getPayboardDetailedCountFailed());
  }
};
/**
 * Get payboard weekly detailed list
 */
export const getPayboardWeeklyDetailedListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IPayboardDetailedModel> = yield call(
      Api.Payboard.listWeeklyDetailed,
      action.payload,
    );
    yield put(actions.getPayboardWeeklyDetailedListSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardWeeklyDetailedListFailed());
  }
};

/**
 * Get payboard weekly detailed count
 */
export const getPayboardWeeklyDetailedCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.countWeeklyDetailed,
      action?.payload,
    );
    yield put(
      actions.getPayboardWeeklyDetailedCountSuccess({
        countWeeklyDetailed: response.count,
      }),
    );
  } catch (e) {
    yield put(actions.getPayboardWeeklyDetailedCountFailed());
  }
};

/**
 * Get payboard details
 */
export const getPayboardDetailsRequestSaga = function* (
  action: ISagaAction<PayboardDetailsRequest>,
): SagaIterator {
  try {
    const response: PayboardDetailsWithTotal = yield call(
      Api.Payboard.detailsView,
      action.payload,
    );
    yield put(
      actions.getPayboardDetailsSuccess({
        items: response.items,
        total: response.total,
        count: response.count,
        year: action.payload.year,
        week: action.payload.week,
        badge: action.payload.badge,
      }),
    );
  } catch (e) {
    yield put(actions.getPayboardDetailsFailed());
  }
};

/**
 * Get payboard by week
 */
export const getPayboardWeekRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<PayboardWeek> = yield call(
      Api.Payboard.weekView,
      action.payload,
    );
    yield put(actions.getPayboardWeekSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardWeekFailed());
  }
};

export const getPayboardDayRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<PayboardDay> = yield call(
      Api.Payboard.dayView,
      action.payload,
    );
    yield put(actions.getPayboardDaySuccess(response));
  } catch (e) {
    yield put(actions.getPayboardDayFailed());
  }
};

/**
 * Get payboard weekly data count
 */
export const getPayboardWeekCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.weekCount,
      action?.payload,
    );
    yield put(actions.getPayboardWeekCountSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardWeekCountFailed());
  }
};

export const getPayboardDayCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.dayCount,
      action?.payload,
    );
    yield put(actions.getPayboardDayCountSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardDayCountFailed());
  }
};
/**
 * Get new payboard by week
 */
export const getNewPayboardWeekRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<NewPayboardWeek> = yield call(
      Api.Payboard.newWeekView,
      action.payload,
    );
    yield put(actions.getNewPayboardWeekSuccess(response));
  } catch (e) {
    yield put(actions.getNewPayboardWeekFailed());
  }
};

/**
 * Get new payboard weekly data count
 */
export const getNewPayboardWeekCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.newWeekCount,
      action?.payload,
    );
    yield put(actions.getNewPayboardWeekCountSuccess(response));
  } catch (e) {
    yield put(actions.getNewPayboardWeekCountFailed());
  }
};

export const getPayboardDailyHoursSummaryReportDataCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.dailyHoursSummaryReportCount,
      action?.payload,
    );
    yield put(actions.getPayboardWeekCountSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardWeekCountFailed());
  }
};

export const getPayboardDailyHoursSummaryReportDataRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IDailyHoursReportItem> = yield call(
      Api.Payboard.dailyHoursSummaryReport,
      action.payload,
    );
    yield put(actions.getPayboardDailyHoursSummaryReportDataSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardDailyHoursSummaryReportDataFailed());
  }
};

export const getPayboardDailyHoursDashboardReportDataRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IDailyHoursReportItem> = yield call(
      Api.Payboard.dailyHoursDashboardReport,
      action.payload,
    );
    yield put(actions.getPayboardDailyHoursSummaryReportDataSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardDailyHoursSummaryReportDataFailed());
  }
};

export const getPayboardSumByDayRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IPayboardCostByDay> = yield call(
      Api.Payboard.costsSumByDay,
      action.payload,
    );
    yield put(actions.getPayboardSumByDaySuccess(response));
  } catch (e) {
    yield put(actions.getPayboardSumByDayFailed());
  }
};

export const getPayboardSumByDayAndDepartmentRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IPayboardCostByDayAndDepartment> = yield call(
      Api.Payboard.costsSumByDayAndDepartment,
      action.payload,
    );
    yield put(actions.getPayboardSumByDayAndDepartmentSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardSumByDayAndDepartmentFailed());
  }
};

export const getPayboardSumByDepartmentRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IPayboardCostByDepartment> = yield call(
      Api.Payboard.costSumByDepartment,
      action.payload,
    );
    yield put(actions.getPayboardSumByDepartmentSuccess(response));
  } catch (e) {
    yield put(actions.getPayboardSumByDepartmentFailed());
  }
};

export const getPayboardDailyHoursDashboardInReportDataRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IDailyHoursReportItem> = yield call(
      Api.Payboard.dailyHoursDashboardInReport,
      action.payload,
    );
    yield put(
      actions.getPayboardDailyHoursDashboardInReportDataSuccess(response),
    );
  } catch (e) {
    yield put(actions.getPayboardDailyHoursDashboardInReportDataFailed());
  }
};

export const getPayboardDailyHoursDashboardInReportCountRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Payboard.dailyHoursDashboardInReportCount,
      action.payload,
    );
    yield put(
      actions.getPayboardDailyHoursDashboardInReportCountSuccess(response),
    );
  } catch (e) {
    yield put(actions.getPayboardDailyHoursDashboardInReportCountFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportPayboardRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.export,
      actionFailed: actions.exportPayboardFailed,
      actionSuccess: actions.exportPayboardSuccess,
    }),
  },
  {
    action: actions.exportPayboardDetailedRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.exportDetailed,
      actionFailed: actions.exportPayboardDetailedFailed,
      actionSuccess: actions.exportPayboardDetailedSuccess,
    }),
  },
  {
    action: actions.exportPayboardWeeklyDetailedRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.exportWeeklyDetailed,
      actionFailed: actions.exportPayboardWeeklyDetailedFailed,
      actionSuccess: actions.exportPayboardWeeklyDetailedSuccess,
    }),
  },
  {
    action: actions.exportPayboardWeekRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.exportWeek,
      actionFailed: actions.exportPayboardWeekFailed,
      actionSuccess: actions.exportPayboardWeekSuccess,
    }),
  },
  {
    action: actions.exportPayboardDayRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.exportDay,
      actionFailed: actions.exportPayboardDayFailed,
      actionSuccess: actions.exportPayboardDaySuccess,
    }),
  },
  {
    action: actions.exportNewPayboardWeekRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.exportNewWeek,
      actionFailed: actions.exportNewPayboardWeekFailed,
      actionSuccess: actions.exportNewPayboardWeekSuccess,
    }),
  },
  {
    action: actions.exportPayboardDailyHoursRequest,
    saga: createExportSaga({
      apiCall: Api.Payboard.exportDailyHours,
      actionFailed: actions.exportPayboardDailyHoursFailed,
      actionSuccess: actions.exportPayboardDailyHoursSuccess,
    }),
  },
  // get payboard list
  { action: actions.getPayboardListRequest, saga: getPayboardListRequestSaga },
  // get payboard count
  {
    action: actions.getPayboardCountRequest,
    saga: getPayboardCountRequestSaga,
  },
  // get payboard detailed list
  {
    action: actions.getPayboardDetailedListRequest,
    saga: getPayboardDetailedListRequestSaga,
  },
  // get payboard detailed count
  {
    action: actions.getPayboardDetailedCountRequest,
    saga: getPayboardDetailedCountRequestSaga,
  },
  // get payboard weekly detailed list
  {
    action: actions.getPayboardWeeklyDetailedListRequest,
    saga: getPayboardWeeklyDetailedListRequestSaga,
  },
  // get payboard weekly detailed count
  {
    action: actions.getPayboardWeeklyDetailedCountRequest,
    saga: getPayboardWeeklyDetailedCountRequestSaga,
  },
  // get new payboard count
  {
    action: actions.getNewPayboardWeekCountRequest,
    saga: getNewPayboardWeekCountRequestSaga,
  },
  // get payboard details
  {
    action: actions.getPayboardDetailsRequest,
    saga: getPayboardDetailsRequestSaga,
  },
  // get payboard by week
  { action: actions.getPayboardWeekRequest, saga: getPayboardWeekRequestSaga },
  { action: actions.getPayboardDayRequest, saga: getPayboardDayRequestSaga },
  // get new payboard by week
  {
    action: actions.getNewPayboardWeekRequest,
    saga: getNewPayboardWeekRequestSaga,
  },
  // get payboard weekly data count
  {
    action: actions.getPayboardWeekCountRequest,
    saga: getPayboardWeekCountRequestSaga,
  },
  {
    action: actions.getPayboardDayCountRequest,
    saga: getPayboardDayCountRequestSaga,
  },
  {
    action: actions.getPayboardDailyHoursSummaryReportDataCountRequest,
    saga: getPayboardDailyHoursSummaryReportDataCountRequestSaga,
  },
  {
    action: actions.getPayboardDailyHoursSummaryReportDataRequest,
    saga: getPayboardDailyHoursSummaryReportDataRequestSaga,
  },
  {
    action: actions.getPayboardDailyHoursDashboardReportDataRequest,
    saga: getPayboardDailyHoursDashboardReportDataRequestSaga,
  },
  {
    action: actions.getPayboardSumByDayRequest,
    saga: getPayboardSumByDayRequestSaga,
  },
  {
    action: actions.getPayboardSumByDayAndDepartmentRequest,
    saga: getPayboardSumByDayAndDepartmentRequestSaga,
  },
  {
    action: actions.getPayboardSumByDepartmentRequest,
    saga: getPayboardSumByDepartmentRequestSaga,
  },
  {
    action: actions.getPayboardDailyHoursDashboardInReportDataRequest,
    saga: getPayboardDailyHoursDashboardInReportDataRequestSaga,
  },
  {
    action: actions.getPayboardDailyHoursDashboardInReportCountRequest,
    saga: getPayboardDailyHoursDashboardInReportCountRequestSaga,
  },
];
