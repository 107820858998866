import React from 'react';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  Box,
  Typography,
  useTheme,
} from '@mui/joy';
import { ChevronDownSvg, Pencil02Svg } from 'src/components/svgIcons';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { ComboBoxOption } from 'src/components/ComboBox';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FilterPanelFilterContainer } from '../components/FilterPanelFilterContainer';

export type IFilterPanelComboboxProps = AutocompleteProps<
  ComboBoxOption,
  undefined,
  undefined,
  undefined
> & {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
};

export const FilterPanelCombobox = ({
  filterId,
  onRemoveFilter,
  placeholder,
  ...autocompleteProps
}: IFilterPanelComboboxProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    setEditingFiltersIds,
    elementsHeight,
    setFilterPanelMode,
    filterPanelMode,
    isFilterPermanent,
  } = React.useContext(FilterPanelContext);

  const [isFilterInEditingMode, setIsFilterInEditingMode] = React.useState(
    false,
  );

  const getOptionLabel = (option: ComboBoxOption) => option.name || '';

  const getOptionSelected = (option: ComboBoxOption, value: ComboBoxOption) =>
    isEqual(option, value);

  const onClick = () => {
    if (!isFilterInEditingMode) {
      setIsFilterInEditingMode(true);

      filterPanelMode !== FilterPanelModes.setup &&
        setFilterPanelMode(FilterPanelModes.setup);
    }
  };

  const onTrashBinClick = () => {
    setEditingFiltersIds((prev) => prev.filter((item) => item !== filterId));

    setFilterPanelMode(FilterPanelModes.setup);

    onRemoveFilter && onRemoveFilter(filterId);
  };

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.ready) {
      setIsFilterInEditingMode(false);
    }
  }, [filterPanelMode]);

  return (
    <FilterPanelFilterContainer
      onTrashBinClick={onTrashBinClick}
      isPermanentFilter={isFilterPermanent(filterId)}
    >
      <Box onClick={onClick}>
        <Autocomplete
          freeSolo={false as any}
          disableClearable={(isFilterInEditingMode ? false : true) as any}
          placeholder={placeholder ?? t('common.select')}
          popupIcon={isFilterInEditingMode ? <ChevronDownSvg /> : null}
          endDecorator={!isFilterInEditingMode ? <Pencil02Svg /> : null}
          getOptionLabel={getOptionLabel}
          variant="plain"
          renderOption={(props, option) => {
            return (
              <AutocompleteOption
                {...props}
                key={props.id ?? option.id}
                sx={{ py: '9px', px: '10px' }}
              >
                <Typography
                  // noWrap
                  level="text_sm"
                  fontWeight="medium"
                  textColor="colors.text.text_secondary.hover"
                >
                  {option.name}
                </Typography>
              </AutocompleteOption>
            );
          }}
          isOptionEqualToValue={getOptionSelected}
          openText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          closeText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          slotProps={{
            root: {
              sx: {
                fontSize: '0.75rem',
                '& li.MuiOption-root': {
                  fontSize: '0.875rem',
                },
                borderRadius: 0,
                margin: 0,
                height: elementsHeight,
                bgcolor: 'transparent',

                '& .MuiAutocomplete-popupIndicator': {
                  display: isFilterInEditingMode ? 'inline-flex' : 'none',
                },
              },
            },
            input: {
              sx: {
                '&::placeholder': {
                  fontWeight: theme.fontWeight.regular,
                  color: theme.palette.colors.text.text_placeholder,
                  ...theme.typography.text_xs,
                },
              },
            },
          }}
          {...autocompleteProps}
        />
      </Box>
    </FilterPanelFilterContainer>
  );
};
