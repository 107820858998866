import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
export interface IWeekDay {
  label: string;
  selected: boolean;
  index: number;
}

interface IWeeklyRecurrenceCheckboxesProps {
  onChange: (changedWeekDay: IWeekDay) => void;
  preselectedWeekDays?: number[];
}

export const WeeklyRecurrenceCheckboxes: React.FC<IWeeklyRecurrenceCheckboxesProps> = ({
  preselectedWeekDays,
  onChange,
}) => {
  const [weekDays, setWeekDays] = React.useState([
    {
      label: 'Su.',
      selected: preselectedWeekDays?.includes(6),
      index: 6,
    },
    {
      label: 'Mo.',
      selected: preselectedWeekDays?.includes(0),
      index: 0,
    },
    {
      label: 'Tu.',
      selected: preselectedWeekDays?.includes(1),
      index: 1,
    },
    {
      label: 'We.',
      selected: preselectedWeekDays?.includes(2),
      index: 2,
    },
    {
      label: 'Th.',
      selected: preselectedWeekDays?.includes(3),
      index: 3,
    },
    {
      label: 'Fr.',
      selected: preselectedWeekDays?.includes(4),
      index: 4,
    },
    {
      label: 'Sa.',
      selected: preselectedWeekDays?.includes(5),
      index: 5,
    },
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e.target.name;

    let newValue: any = null;
    setWeekDays((prevState) =>
      prevState.map((item) => {
        if (item.label !== label) {
          return item;
        }

        newValue = { ...item, selected: !item.selected };

        return newValue;
      }),
    );

    newValue && onChange(newValue);
  };

  return (
    <FormControl
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 2,
      }}
    >
      <Typography sx={{ minWidth: 140 }}>Repeat on</Typography>
      <Box sx={{ width: '100%' }}>
        {weekDays.map(({ label, selected }) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={selected}
                onChange={handleChange}
                name={label}
              />
            }
            label={label}
            labelPlacement="top"
            style={{ marginLeft: 4, marginRight: 4 }}
          />
        ))}
      </Box>
    </FormControl>
  );
};
