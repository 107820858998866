import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import { updateQuestionnaireAnswersRequest } from 'src/modules/actions';
import { Box, Drawer } from '@mui/material';
import { getEmployeesReview } from 'src/modules/selectors/questionEmployee';
import { QuestionnaireRadioButton } from '../../../components/QuestionnaireRadioButton';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { FormikFilesUpload } from 'src/components/Formik/FormikFilesUpload';
import { FileData } from 'src/components/UploadFiles';
import { Alert, Typography } from '@mui/joy';
import FormikSignatureField from 'src/components/Formik/FormikSignatureField';
import { dataUrlToFile } from 'src/modules/utils/helpers/common';

interface IUpdateReviewsProps {
  reviewsIds: IdsArray;
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const UpdateReviews = ({
  reviewsIds,
  isOpen,
  onClose,
  filterList,
  filterCount,
}: IUpdateReviewsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allReviews = useSelector(getEmployeesReview);

  let reviewsToUpdate = React.useMemo(
    () =>
      allReviews
        .filter((review) => reviewsIds.includes(review.id))
        .map((item) => ({
          ...item,
          files: [] as Array<
            Omit<FileData, 'preview'> & {
              preview: FileData['preview'] & { id: number };
            }
          >,
          signature: null,
        })),
    [allReviews, reviewsIds],
  );

  const isSomeReviewsToUpdateSignatured = reviewsToUpdate.some((review) =>
    Boolean(review.signatureUrl),
  );

  reviewsToUpdate = React.useMemo(
    () =>
      reviewsToUpdate
        .filter((review) => !Boolean(review.signatureUrl))
        .map((item) => ({
          ...item,
          files: item.attachments.map((preview) => ({ preview })),
        })),
    [reviewsToUpdate],
  );

  const warningMessage = (
    <Alert color="warning" variant="soft">
      <Typography level="text_xs">{`* ${t(
        'questionnaire_history.singed_reviews_in_update_form_warning',
      )}`}</Typography>
    </Alert>
  );

  const formik = useFormikInUpdateForm({
    initialValues: reviewsToUpdate,
    enableReinitialize: true,
    onSubmit: (data) => {
      const formData = new FormData();

      data.forEach((item, index) => {
        const filesToDelete = item.attachments.filter((attachment) =>
          item.files.every((file) => file.preview.id !== attachment.id),
        );

        filesToDelete.forEach((fileToDeleteData, i) => {
          formData.append(
            `reviews[${index}][filesToDelete][${i}][id]`,
            String(fileToDeleteData.id),
          );
          formData.append(
            `reviews[${index}][filesToDelete][${i}][url]`,
            String(fileToDeleteData.url),
          );
        });

        Object.keys(item).forEach((key) => {
          if (
            key === 'id' ||
            key === 'files' ||
            key === 'signature' ||
            key === 'questionsEmployees'
          ) {
            if (key === 'questionsEmployees') {
              item.questionsEmployees.forEach((answer, answerIndex) => {
                Object.keys(answer).forEach((answerKey) => {
                  if (answerKey === 'id' || answerKey === 'value') {
                    formData.append(
                      `reviews[${index}][questionsEmployees][${answerIndex}][${answerKey}]`,
                      answer[answerKey] as any,
                    );
                  }
                });
              });
            } else if (key === 'files') {
              item.files.forEach((file, fileIndex) => {
                if (file.file) {
                  formData.append(
                    `reviews[${index}][files][attachments][${fileIndex}]`,
                    file.file,
                  );
                }
              });
            } else if (key === 'signature' && item.signature) {
              formData.append(
                `reviews[${index}][files][signature]`,
                dataUrlToFile(
                  item.signature,
                  `signature_${new Date().toISOString()}.png`,
                ),
              );
            } else {
              formData.append(`reviews[${index}][${key}]`, item[key] as any);
            }
          }
        });
      });
      dispatch(
        updateQuestionnaireAnswersRequest({
          data: formData,
          filters: { count: filterCount, list: filterList },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('main_menu.employees_questionnaire_history_update')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {isSomeReviewsToUpdateSignatured && (
            <Box sx={{ px: 1, fontStyle: 'italic' }}>{warningMessage}</Box>
          )}
          {reviewsToUpdate.map((review, index) => (
            <DrawerBodySectionContent key={review.id}>
              <DrawerBodySectionTitle>
                {`${review.employee.firstName} ${review.employee.lastName}`}
              </DrawerBodySectionTitle>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 500,
                }}
              >
                {review.questionsEmployees.map((answer, i) => {
                  if (answer.questionType === 'options') {
                    return (
                      <FormFieldContainer key={answer.id}>
                        <QuestionnaireRadioButton
                          label={answer.questionName}
                          id={`${index}.questionsEmployees.${i}.value`}
                          formik={formik}
                        />
                      </FormFieldContainer>
                    );
                  }

                  return (
                    <FormFieldContainer key={answer.id}>
                      <FormikTextarea
                        id={`${index}.questionsEmployees.${i}.value`}
                        name={`${index}.questionsEmployees.${i}.value`}
                        key={answer.id}
                        formik={formik}
                        label={answer.questionName}
                        minRows={2}
                      />
                    </FormFieldContainer>
                  );
                })}

                <FormFieldContainer>
                  <FormikSignatureField
                    formik={formik}
                    id={`${index}.signature`}
                    label={t('common.signature')}
                  />
                </FormFieldContainer>

                <Box
                  sx={{
                    px: 4,
                    bgcolor: 'inherit',
                    mt: 2,
                  }}
                >
                  <FormikFilesUpload
                    name={`${index}.files`}
                    inputId={`review_upload_files_${index}`}
                    formik={formik}
                    accept={{
                      'image/*': ['.png', '.jpg', '.jpeg'],
                      'application/pdf': ['.pdf'],
                      'application/msword': ['.doc'],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.docx',
                      ],
                      'application/vnd.ms-excel': ['.xls'],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                        '.xlsx',
                      ],
                    }}
                  />
                </Box>
              </Box>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
