import { JSONSchemaType } from 'ajv';
import { ISendSmsToEmployees } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const sendSmsToEmployeesScheme: JSONSchemaType<ISendSmsToEmployees> = {
  type: 'object',
  required: ['text', 'employeesIds'],
  additionalProperties: true,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    text: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    employeesIds: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
  },
};
