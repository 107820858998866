import { isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getPricingByIds, getPrycingByMetatags } from '../../selectors/pricing';
import { AnyObject, IdsArray, IStoreState } from '../../types';
import { getPricingListRequest } from '../../actions';
import { ROWS_PER_PAGE_DEFAULT } from '../../../components/EnhancedTable';

export const pricingInclusions = [
  {
    relation: 'site',
  },
  {
    relation: 'pricingMetadata',
    scope: {
      include: [
        {
          relation: 'metatag',
        },
      ],
    },
  },
];

/**
 * A custom hook to fetch pricing entities from store if they exist.
 * Otherwise, to make a request to fetch needed pricing entities from
 * the server
 */
export const useFetchPricingSingleEntities = (
  siteId?: number,
  ids?: IdsArray,
) => {
  const dispatcher = useDispatch();
  // fetch pricing list from store
  const pricingEntities = useSelector(
    (state) => getPricingByIds(state as IStoreState)(siteId ?? NaN, ids ?? []),
    shallowEqual,
  );
  return () => {
    if (isEmpty(pricingEntities) && siteId) {
      dispatcher(
        getPricingListRequest({
          siteId: +siteId,
          filter: {
            filter: {
              where: {
                id: {
                  inq: ids,
                },
              },
              offset: 0,
              limit: ROWS_PER_PAGE_DEFAULT,
              include: pricingInclusions,
            },
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch pricing entities from store if they exist.
 * Otherwise, to make a request to fetch needed pricing range entities from
 * the server
 */
export const useFetchPricingRangeEntities = (
  siteId: number,
  metatags: AnyObject,
) => {
  const dispatcher = useDispatch();
  // fetch pricing list from store
  const pricingEntities = useSelector(
    (state) => getPrycingByMetatags(state as IStoreState)(siteId, metatags),
    shallowEqual,
  );
  return () => {
    if (isEmpty(pricingEntities)) {
      dispatcher(
        getPricingListRequest({
          siteId: +siteId,
          filter: {
            filter: {
              where: Object.entries(metatags).reduce((acc, cur) => {
                const [index, value] = cur;
                acc[index] = { eq: value };
                return acc;
              }, {}),
              offset: 0,
              limit: ROWS_PER_PAGE_DEFAULT,
              include: pricingInclusions,
            },
          },
        }),
      );
    }
  };
};
