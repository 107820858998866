import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mui/material';
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import Api from '../Api';
import {
  IFinancialTrackerCreate,
  IFinancialTrackerUpdate,
} from '../../types/financial-tracker';
import { AnyObject, IdsArray, IFilterData } from '../../types';
import { getQueryKey } from '../helpers/reactQuery.helpers';
import {
  IWeeklyGrossMarginValueChartData,
  WeeklyGrossMarginBySiteAndWeekChart,
} from '../../../pages/FinancialTracker/Splash/WeeklyGrossMarginBySiteAndWeekChart';
import { WeeklySynchronizedCharts } from '../../../pages/FinancialTracker/Splash/WeeklySynchronizedCharts';
import { WeeklyGrossMarginBySitePercentageAndWeekChart } from '../../../pages/FinancialTracker/Splash/WeeklyGrossMarginBySitePercentageAndWeekChart';
import { EmployeesStatisticChart } from '../../../pages/FinancialTracker/Splash/EmployeesStatisticChart';
import { FinancialTrackerTable } from '../../../pages/FinancialTracker/Splash/FinancialTrackerTable';

export const useFinancialTrackerCreate = () => {
  const {
    mutate: createFinancialTracker,
    isLoading,
    isSuccess,
    isError,
  } = useMutation((data: IFinancialTrackerCreate) => {
    const sendingData = {
      ...data,
      /**
       * Convert string values to numbers because backend expects those as numbers
       * but formik can't validate numbers input as decimal (only integers)
       * so we use text input to validate decimal
       */
      revenue: Number(data.revenue),
      staffingCost: Number(data.staffingCost),
      internalCost: Number(data.internalCost),
      otherCosts: Number(data.otherCosts),
    };
    return Api.FinancialTracker.create(sendingData);
  });

  return { createFinancialTracker, isLoading, isSuccess, isError };
};

export const useGetFinancialTrackersByIds = (ids: IdsArray) => {
  const filter: IFilterData = {
    where: {
      id: { inq: ids },
    },
  };
  const queryKey = getQueryKey('/financial-tracker', { filter });

  const { data, isLoading, isSuccess, isError } = useQuery(
    queryKey,
    () => Api.FinancialTracker.list({ filter }),
    { enabled: !isEmpty(ids), retry: false },
  );

  return { data, isLoading, isSuccess, isError };
};

export const useFinancialTrackerUpdate = () => {
  const {
    mutate: updateFinancialTracker,
    isLoading,
    isSuccess,
    isError,
  } = useMutation((data: IFinancialTrackerUpdate[]) => {
    const sendingData = data.map((item) => ({
      ...item,
      /**
       * Convert string values to numbers because backend expects those as numbers
       * but formik can't validate numbers input as decimal (only integers)
       * so we use text input to validate decimal
       */
      revenue: Number(item.revenue),
      staffingCost: Number(item.staffingCost),
      internalCost: Number(item.internalCost),
      otherCosts: Number(item.otherCosts),
    }));
    return Api.FinancialTracker.bulkUpdate(sendingData);
  });

  return { updateFinancialTracker, isLoading, isSuccess, isError };
};

export const useFinancialTrackerChartsData = <T extends AnyObject>(
  data: T[],
) => {
  const { t } = useTranslation();

  const categories = React.useMemo(() => {
    const [firstItem] = data;
    return firstItem?.grouped_week_formatted as string[];
  }, [data]);

  const grossMarginValueSeries = React.useMemo(
    () =>
      data.map((item) => ({
        type: 'area' as const,
        name: (item?.name ?? '') as string,
        data: (item.grouped_value as unknown) as number[],
      })),
    [data],
  );
  const grossMarginPercentageValueSeries = React.useMemo(
    () =>
      data.map((item) => ({
        name: (item?.name ?? '') as string,
        data: (item.grouped_percent as unknown) as number[],
      })),
    [data],
  );

  const weeklyGrossMarginValue: IWeeklyGrossMarginValueChartData = React.useMemo(() => {
    return {
      categories,
      series: grossMarginValueSeries,
    };
  }, [categories, grossMarginValueSeries]);

  const weeklyGrossMarginPercentageValue = React.useMemo(() => {
    return {
      categories,
      series: grossMarginPercentageValueSeries,
    };
  }, [categories, grossMarginPercentageValueSeries]);

  const configuration = React.useMemo(() => {
    return [
      {
        tabLabel: t('common.charts'),
        component: (
          <Box width="100%" height="100%" display="flex" flexDirection="column">
            <Paper style={{ marginBottom: '16px' }}>
              <WeeklyGrossMarginBySiteAndWeekChart
                weeklyGrossMarginValue={weeklyGrossMarginValue}
              />
            </Paper>
            <Paper style={{ marginBottom: '16px' }}>
              <WeeklySynchronizedCharts />
            </Paper>
            <Paper style={{ marginBottom: '16px' }}>
              <WeeklyGrossMarginBySitePercentageAndWeekChart
                {...weeklyGrossMarginPercentageValue}
              />
            </Paper>
            <Paper style={{ marginBottom: '16px' }}>
              <EmployeesStatisticChart />
            </Paper>
          </Box>
        ),
      },
      {
        tabLabel: t('common.tables'),
        component: <FinancialTrackerTable />,
      },
    ];
  }, [t, weeklyGrossMarginPercentageValue, weeklyGrossMarginValue]);

  return {
    categories,
    grossMarginValueSeries,
    grossMarginPercentageValueSeries,
    weeklyGrossMarginValue,
    weeklyGrossMarginPercentageValue,
    configuration,
  };
};
