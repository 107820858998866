import React from 'react';
import { Sheet } from '@mui/joy';
import {
  IStepProps,
  STEP_OUTLINE_OFFSET,
  Step,
  stepContainerClassName,
  stepDefaultSize,
  stepSizeToLabelBottomMargin,
  stepSizeToSxDataMapper,
} from './Step';

const lineWidth = 2;

const childClassName = 'progressStepChild';

type IProgressStepProps = IStepProps;

export const ProgressStep = (props: IProgressStepProps) => {
  const size = props.size ?? stepDefaultSize;
  const connectorBgColor =
    props.status === 'error'
      ? 'colors.utility.error.utility_error_100'
      : 'colors.utility.brand.utility_brand_700';

  const stepSxData = stepSizeToSxDataMapper[size];
  const labelBottomMargin = stepSizeToLabelBottomMargin[size];

  return (
    <Sheet
      sx={{
        bgcolor: 'inherit',
        [`&:not(:last-child) .${childClassName}::after`]: {
          content: '""',
          display: 'block',
          position: 'relative',
          width: lineWidth,
          height: 46,
          borderRadius: 2,
          bgcolor: connectorBgColor,
          left: `calc(${
            stepSxData.externalCircleSize.width / 2
          }px + ${STEP_OUTLINE_OFFSET}px - ${lineWidth / 2}px)`,
        },
        [`&:last-child .${childClassName}`]: {
          mb: `${labelBottomMargin}px`,
        },
        position: 'relative',
        display: 'flex',
      }}
    >
      <Sheet
        className={childClassName}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          bgcolor: 'inherit',
          [`& .${stepContainerClassName}`]: { marginBottom: '0' },
        }}
      >
        <Step {...props} />
      </Sheet>
    </Sheet>
  );
};
