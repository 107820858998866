import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from '@mui/material';
import { chain, cloneDeep, pullAt, set } from 'lodash';

import { useSitesOptions } from 'src/modules/utils';
import { FormActions } from 'src/components/Form/FormActions';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { KpiBasisOptions, KpiSettingsConfig } from 'src/modules/types';
import { useKpiSettingsForm } from 'src/modules/utils/hooks/kpi-settings';
import KpiSettingField from './components/KpiSettingField';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import KpiSettingBasis from './components/KpiSettingBasis';

interface IKpiSettingsForm {
  siteId: number;
  buttonTitle: string;
}

const KpiSettingsForm: React.FC<IKpiSettingsForm> = ({
  siteId,
  buttonTitle,
}) => {
  const { t } = useTranslation();

  const {
    settings,
    isLoading,
    sendToServer,
    sendLoading,
    month,
    monthCount,
    weekCount,
    week,
  } = useKpiSettingsForm(siteId);

  const initialKpiBasis = React.useMemo<KpiBasisOptions>(
    () => ({
      month: month ?? false,
      monthCount: monthCount ?? 1,
      weekCount: weekCount ?? 3,
      week: week ?? true,
    }),
    [month, monthCount, week, weekCount],
  );

  const isCreateForm = React.useMemo(() => !settings, [settings]);

  const hasUserAccessToCreateKpiSettings =
    useHasUserAccessToAction(manageEntitiesConfig.kpi_settings.create.id) &&
    isCreateForm;

  const hasUserAccessToUpdateKpiSettings =
    useHasUserAccessToAction(manageEntitiesConfig.kpi_settings.update.id) &&
    !isCreateForm;

  const [kpiSettings, setKpiSettings] = React.useState<KpiSettingsConfig>(
    settings || [],
  );

  const [kpiBasis, setKpiBasis] = React.useState<KpiBasisOptions>(
    initialKpiBasis,
  );

  React.useEffect(() => setKpiBasis(initialKpiBasis), [initialKpiBasis]);

  React.useEffect(() => setKpiSettings(settings || []), [settings]);

  const handleTextChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setKpiSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(
        value,
        id,
        event.target.type === 'number'
          ? Number(event.target.value)
          : event.target.value,
      );
      return value;
    });
  };

  const handleTypeChange = (id: string) => (
    e: React.SyntheticEvent<Element, Event>,
    v: ComboBoxOption | null,
  ) => {
    setKpiSettings((prevState) => {
      const value = cloneDeep(prevState);
      set(value, id, v?.id);
      return value;
    });
  };

  const handleAddingNewField = () => {
    setKpiSettings((prevState) => [
      ...prevState,
      {
        title: '',
        threshold: 0,
        thresholdType: 'gte',
        type: 'string',
      },
    ]);
  };

  const handleRowDeleting = (rowIndex: number) => {
    setKpiSettings((prevState) => {
      const newState = [...prevState];
      pullAt(newState, [rowIndex]);
      return newState;
    });
  };

  const handleOptionsChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setKpiSettings((prevState: KpiSettingsConfig) => {
      const value = cloneDeep(prevState);
      set(value, id, event.target.value.split('\n'));
      return value;
    });
  };

  const formFields: JSX.Element[] = React.useMemo(() => {
    return chain(kpiSettings)
      .map((setting, index) => (
        <KpiSettingField
          key={index}
          index={index}
          setting={setting}
          onOptionsChange={handleOptionsChange}
          onTextChange={handleTextChange}
          onTypeChange={handleTypeChange}
          onRowDelete={handleRowDeleting}
        />
      ))
      .value();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiSettings]);

  const handleSubmit = () => {
    sendToServer(kpiSettings, kpiBasis);
  };

  if (isLoading) {
    return (
      <Box sx={{ position: 'relative', minHeight: '40vh' }}>
        <MainContentLoader />
      </Box>
    );
  }

  const handleBasisChange = (basis: 'week' | 'month', value: boolean) => {
    setKpiBasis((prevState) => {
      const newValue = cloneDeep(prevState);
      set(newValue, basis, value);

      return newValue;
    });
  };

  const handleCountChange = (
    count: 'weekCount' | 'monthCount',
    value: number,
  ) => {
    setKpiBasis((prevState) => {
      const newValue = cloneDeep(prevState);
      set(newValue, count, value);

      return newValue;
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <KpiSettingBasis
          {...kpiBasis}
          onBasisChange={handleBasisChange}
          onCountChange={handleCountChange}
        />

        {!!kpiSettings.length && formFields}
        {!kpiSettings.length && (
          <Grid container>
            <Typography typography="text_xl" sx={{ marginBottom: 4 }}>
              {t('kpi.no_settings')}
            </Typography>
          </Grid>
        )}

        {(hasUserAccessToCreateKpiSettings ||
          hasUserAccessToUpdateKpiSettings) && (
          <Fab
            sx={{ position: 'fixed', bottom: 8, right: 56 }}
            color="primary"
            aria-label="add"
            onClick={handleAddingNewField}
          >
            <AddIcon />
          </Fab>
        )}
      </Box>

      {(hasUserAccessToCreateKpiSettings ||
        hasUserAccessToUpdateKpiSettings) && (
        <FormActions
          onClick={handleSubmit}
          submitBtnTitle={buttonTitle}
          isLoading={sendLoading}
        />
      )}
    </>
  );
};

export const KpiSettings: React.FC = () => {
  const { t } = useTranslation();
  const [site, setSite] = React.useState<ComboBoxOption>({
    id: NaN,
    name: '',
  });

  const siteOptions = useSitesOptions();

  const handleSiteChange = React.useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: ComboBoxOption | null,
    ) => value && setSite(value as ComboBoxOption),
    [setSite],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <FormFieldContainer>
          <Typography typography="text_xl">{t('kpi.settings.main')}</Typography>
          <Box sx={{ marginBottom: '1rem' }}>
            <ComboBox
              id="site"
              placeholder={t('kpi.settings.site')}
              options={siteOptions}
              value={site}
              onChange={handleSiteChange}
            />
          </Box>

          <Box>
            {Boolean(site?.id) && (
              <>
                <KpiSettingsForm
                  siteId={site.id}
                  buttonTitle={t('common.save')}
                />
              </>
            )}
          </Box>
        </FormFieldContainer>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
