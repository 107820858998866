import { IProductionWeeklyUphBySiteModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionWeeklyUphBySite',
);

// get list actions
export const {
  getListRequest: getProductionWeeklyUphBySiteListRequest,
  getListSuccess: getProductionWeeklyUphBySiteListSuccess,
  getListFailed: getProductionWeeklyUphBySiteListFailed,
} = actionCreator.takeListActions<IProductionWeeklyUphBySiteModel>();

// get count actions
export const {
  getCountRequest: getProductionWeeklyUphBySiteCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionWeeklyUphBySiteRequest,
  exportReportSuccess: exportProductionWeeklyUphBySiteSuccess,
  exportReportFailed: exportProductionWeeklyUphBySiteFailed,
} = new ExportActionCreator('ProductionWeeklyUphBySite').takeExportActions();
