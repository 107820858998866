import React from 'react';
import { Sheet } from '@mui/joy';

interface IDrawerBodyStepperContentProps {
  children: React.ReactNode;
}

export const DrawerBodyStepperContent = ({
  children,
}: IDrawerBodyStepperContentProps) => {
  return (
    <Sheet sx={{ pb: 3, bgcolor: 'inherit' }} component="form">
      {children}
    </Sheet>
  );
};
