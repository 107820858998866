import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { updateDepartmentsRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { DepartmentIncentive, departmentIncentiveOptions } from 'src/config';
import {
  getDepartmentList,
  getDepartmentsComboboxListBySiteId,
} from 'src/modules/selectors/department';
import { updateDepartmentsScheme } from 'src/modules/schemes/departments';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';

const initialValues = {
  name: '',
  siteId: NaN,
  indirect: false,
  archived: false,
  includeToUph: true,
  includeToCostPlus: true,
  active: true,
  costPlus: null,
  staticCostPlus: null,
  parentId: null,
};

interface IDepartmentsFormsUpdateDepartmentProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  departmentsToUpdateIds: IdsArray;
}

export const DepartmentsFormsUpdateDepartment = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  departmentsToUpdateIds,
}: IDepartmentsFormsUpdateDepartmentProps) => {
  const { t } = useTranslation();

  const allDepartments = useSelector(
    getDepartmentList,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ).map(({ site, ...rest }) => ({ ...rest }));

  const departmentsToUpdateFullData = allDepartments.filter((department) =>
    departmentsToUpdateIds.includes(department.id),
  );

  const dispatch = useDispatch();

  const validate = useValidate(updateDepartmentsScheme, {
    allowUnionTypes: true,
  });

  const parentDepartmentsBySiteId = useSelector(
    getDepartmentsComboboxListBySiteId,
  );

  const formik = useFormik({
    initialValues: departmentsToUpdateFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateDepartmentsRequest({
          data: data.map((item) => ({
            ...item,
            includeToCostPlus: Boolean(item.includeToCostPlus),
          })),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('departments.update_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {formik.values.length === departmentsToUpdateFullData.length
              ? departmentsToUpdateFullData.map((department, index) => (
                  <DrawerBodySectionContent key={department.id}>
                    <DrawerBodySectionTitle>{`${department.name}`}</DrawerBodySectionTitle>
                    <FormFieldContainer>
                      <SitesComboBox
                        id={`${index}.siteId`}
                        required={true}
                        formik={formik}
                        label={t('employees.site')}
                        placeholder={t('employees.site')}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        id={`${index}.name`}
                        name={`${index}.name`}
                        variant="outlined"
                        fullWidth
                        label={t('departments.name')}
                        autoComplete="name"
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        variant="outlined"
                        fullWidth
                        id={`${index}.costPlus`}
                        name={`${index}.costPlus`}
                        label={t('departments.cost_plus')}
                        autoComplete="costPlus"
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        variant="outlined"
                        fullWidth
                        id={`${index}.staticCostPlus`}
                        name={`${index}.staticCostPlus`}
                        label={t('departments.static_cost_plus')}
                        autoComplete="staticCostPlus"
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCombobox
                        id={`${index}.parentId`}
                        label={t('departments.parent')}
                        placeholder={t('departments.parent')}
                        options={parentDepartmentsBySiteId(department.siteId)}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        variant="outlined"
                        fullWidth
                        id={`${index}.desiredEmployeesNumber`}
                        name={`${index}.desiredEmployeesNumber`}
                        label={t('departments.desiredEmployeesNumber')}
                        autoComplete="desiredEmployeesNumber"
                        formik={formik}
                        type="number"
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCombobox
                        required
                        id={`${index}.incentive`}
                        name={`${index}.incentive`}
                        placeholder={t('departments.incentive')}
                        label={t('departments.incentive')}
                        options={departmentIncentiveOptions as ComboBoxOption[]}
                        errorMode="onFieldChange"
                        formik={formik}
                      />
                    </FormFieldContainer>

                    {[
                      DepartmentIncentive.costPlus,
                      DepartmentIncentive.indirect,
                    ].includes(formik.values[index].incentive!) && (
                      <FormFieldContainer>
                        <FormikTextField
                          variant="outlined"
                          fullWidth
                          id={`${index}.incentiveSettings.price.${formik.values[index].incentive}`}
                          name={`${index}.incentiveSettings.price.${formik.values[index].incentive}`}
                          label={t('departments.incentivePrice')}
                          formik={formik}
                          type="number"
                          errorMode="onFieldChange"
                        />
                      </FormFieldContainer>
                    )}

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.active`}
                        sx={{ display: 'flex' }}
                        formik={formik}
                        label={t('departments.active')}
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.archived`}
                        sx={{ display: 'flex' }}
                        formik={formik}
                        label={t('departments.archived')}
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.includeToUph`}
                        sx={{ display: 'flex' }}
                        formik={formik}
                        label={t('departments.includeToUph')}
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikCheckbox
                        id={`${index}.includeToCostPlus`}
                        sx={{ display: 'flex' }}
                        formik={formik}
                        label={t('departments.includeToCostPlus')}
                      />
                    </FormFieldContainer>
                  </DrawerBodySectionContent>
                ))
              : null}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
