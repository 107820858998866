import {
  composeDate,
  DATETIME_TIMEZONE,
  parse,
  format,
  differenceInMilliseconds,
  addMilliSeconds,
} from '../dateWrapper';

export const concatDatesPartials = (
  strWithDatePart: string,
  strWithTimePart: string,
) => {
  return `${strWithDatePart.split('T')[0]}T${strWithTimePart.split('T')[1]}`;
};

export const calculateTaskDuration = (startTime: string, endTime: string) => {
  return differenceInMilliseconds(
    composeDate(endTime, parse(DATETIME_TIMEZONE)),
    composeDate(startTime, parse(DATETIME_TIMEZONE)),
  );
};

export const generateStartDateTimeOfRecurrentTask = (
  recurringDate: string,
  taskStartTime: string,
) => {
  const startDateStr = concatDatesPartials(recurringDate, taskStartTime);

  return composeDate(startDateStr, parse(DATETIME_TIMEZONE));
};

export const generateEndDateTimeOfRecurrentTask = (
  recurringDate: string,
  taskStartTime: string,
  taskEndTime: string,
) => {
  const startDate = generateStartDateTimeOfRecurrentTask(
    recurringDate,
    taskStartTime,
  );

  const duration = calculateTaskDuration(taskStartTime, taskEndTime);

  return composeDate(startDate, addMilliSeconds(duration));
};

export const generateStartDateTimeOfAutogeneratedTask = (
  date: Date,
  taskStartTime: string,
) => {
  const dateStr = composeDate(date, format(DATETIME_TIMEZONE));
  const startDateStr = concatDatesPartials(dateStr, taskStartTime);

  return composeDate(startDateStr, parse(DATETIME_TIMEZONE));
};

export const generateEndDateTimeOfTask = (
  startDate: Date,
  taskStartTime: string,
  taskEndTime: string,
) => {
  const duration = calculateTaskDuration(taskStartTime, taskEndTime);

  return composeDate(startDate, addMilliSeconds(duration));
};
