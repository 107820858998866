import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { MUI_SIZE_10, MUI_SIZE_12, MUI_SIZE_2, MUI_SIZE_3 } from 'src/config';
import { AnyObject } from 'src/modules/types';
import { TextField } from 'src/components/_ui-kit/TextField';
import { Grid } from '@mui/joy';

export enum KpiThresholdType {
  lt = '<',
  lte = '<=',
  gt = '>',
  gte = '>=',
}

interface IKpiSettingField {
  index: number;
  setting: AnyObject;
  onTextChange(
    id: string,
  ): (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTypeChange(
    id: string,
  ): (
    e: React.SyntheticEvent<Element, Event>,
    v: ComboBoxOption | null,
  ) => void;
  onOptionsChange(
    id: string,
  ): (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRowDelete(index: number): void;
}

const KpiSettingField: React.FC<IKpiSettingField> = ({
  index,
  setting,
  onTypeChange,
  onTextChange,
  onRowDelete,
}) => {
  const { t } = useTranslation();

  const { title, type, threshold, thresholdType } = setting;

  const typesOptions = React.useMemo(
    () =>
      ([
        {
          id: 'string',
          name: 'string',
        },
        {
          id: 'number',
          name: 'number',
        },
      ] as unknown) as ComboBoxOption[],
    [],
  );

  const thresholdTypeOptions = React.useMemo(
    () =>
      map(Object.keys(KpiThresholdType), (key) => ({
        id: (key as unknown) as number,
        name: KpiThresholdType[key],
      })),
    [],
  );

  const getOption = React.useCallback(
    (type: string) => typesOptions.find((i) => `${i.id}` === type),
    [typesOptions],
  );

  const getThresholdTypeOption = React.useCallback(
    (type: string) => thresholdTypeOptions.find((i) => `${i.id}` === type),
    [thresholdTypeOptions],
  );

  return (
    <>
      <Grid
        container
        spacing={MUI_SIZE_2}
        alignItems="flex-start"
        sx={{ marginBottom: '1rem' }}
      >
        <Grid md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <TextField
            fullWidth
            label={t('kpi.settings.title')}
            value={title}
            onChange={onTextChange(`${index}.title`)}
          />
        </Grid>
        <Grid md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <ComboBox
            label={t('kpi.settings.type')}
            options={typesOptions}
            value={getOption(type)}
            onChange={onTypeChange(`${index}.type`)}
          />
        </Grid>
        <Grid md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <TextField
            fullWidth
            label={t('kpi.settings.threshold')}
            value={threshold}
            type="number"
            onChange={onTextChange(`${index}.threshold`)}
          />
        </Grid>
        <Grid container md={MUI_SIZE_3} xs={MUI_SIZE_12} alignItems="center">
          <Grid xs={MUI_SIZE_10}>
            <ComboBox
              label={t('kpi.settings.thresholdType')}
              options={thresholdTypeOptions}
              value={getThresholdTypeOption(thresholdType)}
              onChange={onTypeChange(`${index}.thresholdType`)}
            />
          </Grid>
          <Grid xs={MUI_SIZE_2}>
            <IconButton onClick={() => onRowDelete(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default KpiSettingField;
