import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  IFilledAssociatesState,
  ListWithSummary,
  IFilledAssociatesModel,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IFilledAssociatesState = {
  siteId: NaN,
  siteName: '',
  requiredAssociates: 0,
  activeCount: 0,
  filledAssociates: 0,
  count: 0,
  list: { items: [], summary: [] },
};

/**
 * Get list actions
 */
const getFilledAssociatesListRequest = (state: IFilledAssociatesState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getFilledAssociatesListSuccess = (
  state: IFilledAssociatesState,
  payload: ListWithSummary<IFilledAssociatesModel>,
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getFilledAssociatesListFailed = (
  state: IFilledAssociatesState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getFilledAssociatesCountRequest = (state: IFilledAssociatesState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getFilledAssociatesCountSuccess = (
  state: IFilledAssociatesState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getFilledAssociatesCountFailed = (
  state: IFilledAssociatesState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IFilledAssociatesState>();

/**
 * FilledAssociates reducer
 */
export const filledAssociates = createReducer<IFilledAssociatesState>(
  {},
  defaultState,
)
  // get list actions
  .on(actions.getFilledAssociatesListRequest, getFilledAssociatesListRequest)
  .on(actions.getFilledAssociatesListSuccess, getFilledAssociatesListSuccess)
  .on(actions.getFilledAssociatesListFailed, getFilledAssociatesListFailed)
  // get count actions
  .on(actions.getFilledAssociatesCountRequest, getFilledAssociatesCountRequest)
  .on(actions.getFilledAssociatesCountSuccess, getFilledAssociatesCountSuccess)
  .on(actions.getFilledAssociatesCountFailed, getFilledAssociatesCountFailed)

  // export actions
  .on(actions.exportFilledAssociatesRequest, exportRequest)
  .on(actions.exportFilledAssociatesSuccess, exportSuccess)
  .on(actions.exportFilledAssociatesFailed, exportFailed);

export default filledAssociates;
