import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere, IdsArray } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { getMetatagList } from 'src/modules/selectors/metatag';
import { updateMetatagsScheme } from 'src/modules/schemes/metatags';
import { updateMetatagsRequest } from 'src/modules/actions';

const initialValues = {
  siteId: NaN,
  name: '',
};

interface IMetatagsUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  ids: IdsArray;
}

export const MetatagsUpdate = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  ids,
}: IMetatagsUpdateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allEntities = useSelector(getMetatagList);

  const entitiesFullData = allEntities.filter((entity) =>
    ids.includes(entity.id),
  );

  const validate = useValidate(updateMetatagsScheme);

  const formik = useFormik({
    initialValues: entitiesFullData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        updateMetatagsRequest({
          data: data.map((item) => ({
            id: item.id,
            name: item.name,
            siteId: item.siteId,
          })),
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.setValues(formik.values.map(({ id }) => ({ id, ...initialValues })));
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('metatags.update_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {formik.values.length === entitiesFullData.length
              ? entitiesFullData.map((entity, index) => (
                  <DrawerBodySectionContent key={entity.id}>
                    <DrawerBodySectionTitle>{`${entity.name}`}</DrawerBodySectionTitle>

                    <FormFieldContainer>
                      <SitesComboBox
                        id={`${index}.siteId`}
                        required={true}
                        formik={formik}
                        label={t('metatags.site')}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>

                    <FormFieldContainer>
                      <FormikTextField
                        id={`${index}.name`}
                        variant="outlined"
                        required
                        fullWidth
                        label={t('metatags.name')}
                        name={`${index}.name`}
                        formik={formik}
                        errorMode="onFieldChange"
                      />
                    </FormFieldContainer>
                  </DrawerBodySectionContent>
                ))
              : null}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
