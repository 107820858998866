import React from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import { Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Sheet } from '@mui/joy';

import { MUI_SIZE_12, MUI_SIZE_6 } from 'src/config';
import { TableContext } from '../../../../../../TableContext';
import { YearWeekCombobox } from 'src/components/_ui-kit/YearWeekComboBox';
import { ComboBoxOption } from '../../../../../../../ComboBox';

const PopoverGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

export interface IYearWeekRangePickerFilterValue {
  start?: string | null;
  end?: string | null;
}

export interface IYearWeekRangePickerFilterProps {
  name: string;
  label?: string;
  value?: IYearWeekRangePickerFilterValue;
  fromWeek?: number;
  toWeek?: number;
  fromYear?: number;
  toYear?: number;
}

export const YearWeekRangePickerFilter: React.FC<IYearWeekRangePickerFilterProps> = ({
  name,
  value,
  label,
  fromWeek,
  fromYear,
  toWeek,
  toYear,
}) => {
  const { t } = useTranslation();

  const {
    setFilter,
    filters,
    useInitFilterEffect,
    getFilterByName,
  } = React.useContext(TableContext);

  const yearWeekRangeValue = getFilterByName(name) ?? (value as any) ?? [];

  const handleYearWeekRangePickerFilter = (
    position: keyof IYearWeekRangePickerFilterValue,
  ) => (
    e: React.SyntheticEvent<Element, Event>,
    option: ComboBoxOption | null,
  ) => {
    const v: [string | number | null, string | number | null] = [
      get(filters, [name, 0], null),
      get(filters, [name, 1], null),
    ];

    set(v, [position === 'start' ? 0 : 1], option?.id);

    setFilter(name, v);
  };

  useInitFilterEffect(name, value);

  return (
    <Sheet sx={{ width: '100%', bgcolor: 'inherit' }}>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <PopoverGrid container justifyContent="center" spacing={3}>
          <Grid item md={MUI_SIZE_6} xs={MUI_SIZE_12}>
            <YearWeekCombobox
              id={`${name}_from`}
              label={t('common.from')}
              value={yearWeekRangeValue[0] ?? null}
              optionsFromWeek={fromWeek}
              optionsToWeek={toWeek}
              optionsFromYear={fromYear}
              optionsToYear={toYear}
              onChange={handleYearWeekRangePickerFilter('start')}
            />
          </Grid>
          <Grid item md={MUI_SIZE_6} xs={MUI_SIZE_12}>
            <YearWeekCombobox
              id={`${name}_to`}
              label={t('common.to')}
              value={yearWeekRangeValue[1] ?? null}
              optionsFromWeek={fromWeek}
              optionsToWeek={toWeek}
              optionsFromYear={fromYear}
              optionsToYear={toYear}
              onChange={handleYearWeekRangePickerFilter('end')}
            />
          </Grid>
        </PopoverGrid>
      </FormControl>
    </Sheet>
  );
};
