import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateOptiturnCredentials,
  IdsArray,
  IFilter,
  IOptiturnCredentialsModel,
  IUpdateOptiturnCredentials,
  PayloadWithNavigateFunc,
} from '../types';

// Flush actions
export const flushOptiturnCredentialsState = createAction(
  'Flush optiturnCredentials data in store',
);

// Creation actions
export const createOptiturnCredentialsRequest = createAction<
  PayloadWithNavigateFunc<ICreateOptiturnCredentials>
>('Make request to create a new optiturnCredentials');
export const createOptiturnCredentialsSuccess = createAction<
  IOptiturnCredentialsModel
>('New optiturnCredentials has been created successfully');
export const createOptiturnCredentialsFailed = createAction(
  'Cannot create a new optiturnCredentials',
);

// Fetch optiturnCredentials actions
export const getOptiturnCredentialsListRequest = createAction<IFilter>(
  'Make request to fetch optiturnCredentials list',
);
export const getOptiturnCredentialsListSuccess = createAction<
  IOptiturnCredentialsModel[]
>('OptiturnCredentials list has been fetched successfully');
export const getOptiturnCredentialsListFailed = createAction(
  'Cannot fetch optiturnCredentials list',
);

// get optiturnCredentials count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getOptiturnCredentialsCountRequest = createAction<IFilter>(
  'Make request to get optiturnCredentials count',
);
export const getOptiturnCredentialsCountSuccess = createAction<ICountResponse>(
  'OptiturnCredentials count has been fetched successfully',
);
export const getOptiturnCredentialsCountFailed = createAction(
  'Cannot get optiturnCredentials count',
);

// delete actions
export const deleteOptiturnCredentialsRequest = createAction<IdsArray>(
  'Make request to delete optiturnCredentials',
);
export const deleteOptiturnCredentialsFailed = createAction(
  'Cannot delete optiturnCredentials',
);

// Updating actions
export const updateOptiturnCredentialsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateOptiturnCredentials[]>
>('Make request to update optiturnCredentials');
export const updateOptiturnCredentialsFailed = createAction(
  'Cannot update optiturnCredentials',
);

// Fetch combobox list actions
export const getOptiturnCredentialsComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of optiturnCredentials',
);
export const getOptiturnCredentialsComboboxListSuccess = createAction<
  Partial<IOptiturnCredentialsModel>[]
>('Combobox list of optiturnCredentials has been fetched successfully');
export const getOptiturnCredentialsComboboxListFailed = createAction(
  'Cannot fetch combobox optiturnCredentials list',
);
