import React from 'react';
import { Box } from '@mui/material';

interface ISectionProps {
  title: string;
}
export const Section: React.FC<ISectionProps> = ({ title, children }) => {
  return (
    <Box
      component="fieldset"
      sx={{
        width: '100%',
        padding: '16px 8px',
        borderRadius: '8px',
        marginBottom: 4,
        border: '1px solid rgb(240, 240, 240)',
      }}
    >
      <Box component="legend" sx={{ fontSize: '1.4em' }}>
        {title}
      </Box>
      {children}
    </Box>
  );
};
