import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import {
  deleteQuestionCategoryRequest,
  exportQuestionCategoryRequest,
  getQuestionCategoryCountRequest,
  getQuestionCategoryListRequest,
} from 'src/modules/actions';
import {
  getIsQuestionCategoriesDataLoading,
  getQuestionCategoryCount,
  getQuestionCategoryList,
} from 'src/modules/selectors/questionCategory';
import { IdsArray } from 'src/modules/types';
import { useCreateExportProps, useFilter } from 'src/modules/utils';
import { useQuestionCategoryDefaultFilters } from 'src/modules/utils/hooks/questionCategory';
import { CreateQuestionCategory } from './CreateQuestionCategory';
import { QuestionCategoriesUpdate } from './QuestionCategoriesUpdate';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';

export const QuestionsCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultFilter = useQuestionCategoryDefaultFilters();

  const { filterCount, filterList } = useFilter(defaultFilter);

  const exportProps = useCreateExportProps(exportQuestionCategoryRequest);

  const [
    isCreateQuestionCategoryFormVisible,
    setIsCreateQuestionCategoryFormVisible,
  ] = React.useState(false);
  const [
    isUpdateQuestionCategoriesPointFormVisible,
    setIsUpdateQuestionCategoriesFormVisible,
  ] = React.useState(false);

  const [
    selectedQuestionCategoriesIds,
    setSelectedQuestionCategoriesIds,
  ] = React.useState<IdsArray>([]);

  const hasUserAccessToUpdateQuestionCategories = useHasUserAccessToAction(
    manageEntitiesConfig.question_category.update.id,
  );
  const hasUserAccessToCreateQuestionCategories = useHasUserAccessToAction(
    manageEntitiesConfig.question_category.create.id,
  );
  const hasUserAccessToDeleteQuestionCategories = useHasUserAccessToAction(
    manageEntitiesConfig.question_category.delete.id,
  );

  const isTableDataLoading = useSelector(getIsQuestionCategoriesDataLoading);

  const list = useSelector(getQuestionCategoryList);
  const count = useSelector(getQuestionCategoryCount);

  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('question_category.name') },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('question_category.site'),
    },
    {
      id: 'numberOfWeeksToRepeat',
      disablePadding: false,
      label: t('question_category.number_of_weeks_to_repeat'),
    },
  ];

  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('question_category.name'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('question_category.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
  ];

  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;

    dispatch(
      getQuestionCategoryListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );

    dispatch(
      getQuestionCategoryCountRequest({
        filter: { where, include: newInclude },
      }),
    );
  };

  const handleDeletion = (ids: IdsArray) => {
    dispatch(
      deleteQuestionCategoryRequest({
        data: ids,
        filters: { list: filterList.filter ?? {}, count: filterCount.filter },
      }),
    );
  };

  const handleUpdating = (ids: IdsArray) => {
    setSelectedQuestionCategoriesIds(ids);
    setIsUpdateQuestionCategoriesFormVisible(true);
  };

  React.useEffect(() => {
    dispatch(getQuestionCategoryListRequest(filterList));
    dispatch(getQuestionCategoryCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EnhancedTable
        data={list}
        count={count}
        disableQsFilters
        selectIndex="id"
        isTableDataLoading={isTableDataLoading}
        tableName={t('question_category.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={
          hasUserAccessToDeleteQuestionCategories ? handleDeletion : undefined
        }
        onUpdate={
          hasUserAccessToUpdateQuestionCategories ? handleUpdating : undefined
        }
        include={defaultFilter.include}
        exportProps={exportProps}
        deleteModelName={ModelsToDelete.QuestionCategory}
        createEntityBtnProps={
          hasUserAccessToCreateQuestionCategories
            ? {
                title: t('question_category.create'),
                onClick: () =>
                  setIsCreateQuestionCategoryFormVisible((prev) => !prev),
              }
            : undefined
        }
      />

      <>
        <CreateQuestionCategory
          isOpen={isCreateQuestionCategoryFormVisible}
          onClose={() => setIsCreateQuestionCategoryFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />

        <QuestionCategoriesUpdate
          questionCategoriesIds={selectedQuestionCategoriesIds}
          isOpen={isUpdateQuestionCategoriesPointFormVisible}
          onClose={() => setIsUpdateQuestionCategoriesFormVisible(false)}
          filterList={filterList.filter ?? {}}
          filterCount={filterCount.filter}
        />
      </>
    </>
  );
};
