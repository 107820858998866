import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateMetatagRequest,
  IdsArray,
  IFilter,
  IMetatagModel,
  IUpdateMetatag,
  PayloadWithFilters,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushMetatagState = createAction('Flush metatag data in store');

// Creation actions
export const createMetatagRequest = createAction<
  PayloadWithFilters<ICreateMetatagRequest>
>('Make request to create a new metatag');
export const createMetatagSuccess = createAction<IMetatagModel>(
  'New metatag has been created successfully',
);
export const createMetatagFailed = createAction('Cannot create a new metatag');

// Fetch metatags actions
export const getMetatagListRequest = createAction<IFilter>(
  'Make request to fetch metatag list',
);
export const getMetatagListSuccess = createAction<IMetatagModel[]>(
  'Metatags list has been fetched successfully',
);
export const getMetatagListFailed = createAction('Cannot fetch metatag list');

// get metatags count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getMetatagCountRequest = createAction<IFilter>(
  'Make request to get metatag count',
);
export const getMetatagCountSuccess = createAction<ICountResponse>(
  'Metatags count has been fetched successfully',
);
export const getMetatagCountFailed = createAction('Cannot get metatag count');

// delete actions
export const deleteMetatagRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete metatags',
);
export const deleteMetatagSuccess = createAction('Delete metatags success');
export const deleteMetatagFailed = createAction('Cannot delete metatags');

// Updating actions
export const updateMetatagsRequest = createAction<
  PayloadWithFilters<IUpdateMetatag[]>
>('Make request to update metatags');
export const updateMetatagsSuccess = createAction('Update metatags success');
export const updateMetatagsFailed = createAction('Cannot update metatags');

// Fetch combobox list actions
export const getMetatagComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of metatags',
);
export const getMetatagComboboxListSuccess = createAction<
  Partial<IMetatagModel>[]
>('Combobox list of metatags has been fetched successfully');
export const getMetatagComboboxListFailed = createAction(
  'Cannot fetch combobox metatags list',
);

// export actions
export const {
  exportReportRequest: exportMetatagRequest,
  exportReportSuccess: exportMetatagSuccess,
  exportReportFailed: exportMetatagFailed,
} = new ExportActionCreator('Metatag').takeExportActions();
