import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/joy';

import YearWeekComboBox from '../Formik/comboboxes-with-entities/YearWeekComboBox';
import { ComboBoxOption } from '../ComboBox';
import { AnyObject } from '../../modules/types';
import { get } from 'lodash';
import { Backdrop } from '@mui/material';

export interface IFormikYearWeekRangeValue {
  start?: ComboBoxOption | null;
  end?: ComboBoxOption | null;
}

export interface IFormikYearWeekRangeProps {
  id: string;
  label?: string;
  value?: IFormikYearWeekRangeValue;
  formik: AnyObject;
}

const FormikYearWeekRange: React.FC<IFormikYearWeekRangeProps> = (props) => {
  const { t } = useTranslation();

  const [isLeftOpen, setIsLeftOpen] = React.useState(false);
  const [isRightOpen, setIsRightOpen] = React.useState(false);

  const [values, setValues] = useState<IFormikYearWeekRangeValue | undefined>(
    undefined,
  );

  const { optionsFromYear, optionsFromWeek } = React.useMemo(() => {
    const optionsFromYear = (
      get(props, ['formik', 'values', props.id, 0], '') || ''
    )
      .toString()
      .substring(0, 4);

    const optionsFromWeek = (
      get(props, ['formik', 'values', props.id, 0], '') || ''
    )
      .toString()
      .substring(4, 6);

    return { optionsFromYear, optionsFromWeek };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(props, ['formik', 'values', props.id, 0])]);

  useEffect(() => {
    if (props.value) {
      setValues(props.value);
      return;
    }
  }, [props]);

  const handleYearWeekChange = (position: keyof IFormikYearWeekRangeValue) => (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    const updatedValues = {
      ...values,
      [position]: value,
    } as IFormikYearWeekRangeValue;

    setValues(updatedValues);
    setIsLeftOpen(false);
    setIsRightOpen(false);
  };

  const id = React.useMemo(() => {
    if ((props as any).operator === 'between') {
      return `${props.id}.between`;
    }

    return props.id;
  }, [props]);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Backdrop
        sx={{ backgroundColor: 'transparent', zIndex: 9 }}
        open={isLeftOpen || isRightOpen}
        onClick={() => {
          setIsLeftOpen(false);
          setIsRightOpen(false);
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <YearWeekComboBox
          id={`${id}.0`}
          label={props.label ?? ''}
          value={values?.start}
          formik={props.formik}
          placeholder={t('common.from')}
          onChange={handleYearWeekChange('start')}
        />
        <Box
          sx={{
            mb: 2.5,
            mx: '4px',
            minWidth: '8px',
            minHeight: '1px',
            bgcolor: 'colors.foreground.fg_quarterary',
          }}
        />
        <YearWeekComboBox
          id={`${id}.1`}
          {...(optionsFromYear ? { optionsFromYear } : {})}
          {...(optionsFromWeek ? { optionsFromWeek } : {})}
          value={values?.end}
          formik={props.formik}
          placeholder={t('common.to')}
          onChange={handleYearWeekChange('end')}
        />
      </Box>
    </Box>
  );
};

export default FormikYearWeekRange;
