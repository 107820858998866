import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { MUI_SIZE_12, MUI_SIZE_2, MUI_SIZE_3 } from 'src/config';
import { TextField } from 'src/components/_ui-kit/TextField';
import { Checkbox } from 'src/components/_ui-kit/Checkbox';

interface IKpiSettingBasis {
  week: boolean;
  weekCount: number;
  month: boolean;
  monthCount: number;
  onBasisChange(basis: 'week' | 'month', value: boolean): void;
  onCountChange(count: 'weekCount' | 'monthCount', value: number): void;
}

const KpiSettingBasis: React.FC<IKpiSettingBasis> = ({
  month,
  monthCount,
  weekCount,
  week,
  onBasisChange,
  onCountChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        spacing={MUI_SIZE_2}
        alignItems="flex-start"
        sx={{ marginBottom: '1rem' }}
      >
        <Grid item md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <Checkbox
            label={t('kpi.settings.week')}
            name="week"
            checked={week}
            onChange={(e) => onBasisChange('week', e.target.checked)}
          />
        </Grid>
        <Grid item md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <TextField
            fullWidth
            label={t('kpi.settings.weekCount')}
            value={weekCount}
            type="number"
            onChange={(e) => onCountChange('weekCount', +e.target.value)}
          />
        </Grid>
        <Grid item md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <Checkbox
            label={t('kpi.settings.month')}
            name="month"
            checked={month}
            onChange={(e) => onBasisChange('month', e.target.checked)}
          />
        </Grid>
        <Grid item md={MUI_SIZE_3} xs={MUI_SIZE_12}>
          <TextField
            fullWidth
            label={t('kpi.settings.monthCount')}
            value={monthCount}
            type="number"
            onChange={(e) => onCountChange('monthCount', +e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default KpiSettingBasis;
