import React from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { chain, reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';

import {
  getManageEntitiesConfigData,
  getPageSettingDataRequest,
} from '../../../modules/selectors/page-setting.selector';
import { AnyObject, IPageSettingViewConfig } from '../../../modules/types';
import { useValidate } from '../../../modules/utils';
import { updatePageSettingScheme } from '../../../modules/schemes';
import PageSettingsCard from './PageSettingsCard';
import { MUI_SIZE_2 } from '../../../config';
import { useFormLayoutData } from '../../../modules/utils/hooks/layout';
import { FormActions } from '../../../components/Form/FormActions';
import { usePageSettingHelpers } from '../../../modules/utils/hooks/page-settings.hook';
import {
  addProcessStatus,
  updatePageSettingRequest,
} from '../../../modules/actions';
import { ConfirmPageLeave } from '../../../components/ConfirmPageLeave';
import { useIsObjectChanged } from '../../../modules/utils/hooks/common/forms';
import PageSettingPagination from './PageSettingPagination';
import {
  privateRoutes,
  privateRoutesTabs,
} from '../../../config/privateRoutes';

export const PolicesToViewPages: React.FC = () => {
  const data = useSelector(getPageSettingDataRequest, shallowEqual);
  const manageEntitiesConfig = useSelector(getManageEntitiesConfigData);
  const { pageSettingViewConfigToPolicyConfig } = usePageSettingHelpers();

  const allRouts = React.useMemo(
    () => ({ ...privateRoutes, ...privateRoutesTabs }),
    [],
  );

  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const validate = useValidate(updatePageSettingScheme);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
  const [page, setPage] = React.useState<number>(0);
  const [filter, setFilter] = React.useState<string>();

  const formik = useFormik<IPageSettingViewConfig>({
    initialValues: data,
    validate,
    onSubmit: (data) => {
      dispatcher(
        updatePageSettingRequest({
          config: pageSettingViewConfigToPolicyConfig(data),
          manageEntitiesConfig,
        }),
      );
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const formikIndexes = React.useMemo(
    () =>
      reduce(
        formik.values,
        (acc, cur, index) => {
          acc.set(cur.route, index);
          return acc;
        },
        new Map(),
      ),
    [formik.values],
  );

  React.useEffect(() => {
    if (!formik.isValid) {
      for (const i in formik.errors) {
        const route = formik.values[i].route;
        const cardTitle = allRouts[route]
          ? t(allRouts[route].title)
          : t('page_settings.select_page');

        dispatcher(
          addProcessStatus({
            message: t('page_settings.error', { cardTitle }),
            variant: 'error',
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, formik.errors]);

  const [count, setCount] = React.useState<number>(formik.values.length);

  const isFormDataChanged = useIsObjectChanged(formik.values as AnyObject);

  const { onFormSubmit, isSubmitting } = useFormLayoutData(
    formik.isValid,
    formik.handleSubmit as any,
  );

  const offset = React.useMemo(() => rowsPerPage * page, [page, rowsPerPage]);

  const getFormikCardIndex = React.useCallback(
    (route: string) => formikIndexes.get(route),
    [formikIndexes],
  );

  const cards = React.useMemo(
    () =>
      chain(formik.values)
        .filter((i) => {
          if (!filter) {
            return true;
          }

          const cardTitle = allRouts[i.route]
            ? t(allRouts[i.route].title)
            : t('page_settings.select_page');

          return cardTitle
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase());
        })
        .drop(offset)
        .take(rowsPerPage)
        .map(({ route, policies }) => (
          <PageSettingsCard
            formikCardIndex={getFormikCardIndex(route)}
            formik={formik}
            key={`${getFormikCardIndex(route)}.route`}
            route={route}
            policies={policies}
          />
        ))
        .value(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, formik.errors, rowsPerPage, page, filter, offset],
  );

  const handleAdd = () => {
    setPage(Math.floor(formik.values.length / rowsPerPage));
    const newCard = {
      page: null,
      policies: [
        {
          id: null,
          resource: null,
          action: null,
          clientId: null,
        },
      ],
    };

    formik.setFieldValue(`${formik.values.length}`, newCard);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event: React.FormEvent<AnyObject>) => {
    event.preventDefault();
    const value = event.currentTarget.filter.value;
    setFilter(value);
    setPage(0);
    setCount(value ? cards.length : formik.values.length);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="baseline">
        <Box
          noValidate
          component="form"
          onSubmit={handleFilter}
          pr={MUI_SIZE_2}
          sx={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <IconButton type="submit" sx={{ padding: 0 }}>
            <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          </IconButton>
          <TextField
            name="filter"
            label={t('common.filter')}
            variant="standard"
          />
        </Box>
        <Button variant="outlined" onClick={handleAdd}>
          {t('page_settings.add_page')}
        </Button>
        <PageSettingPagination
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <Box noValidate component="form" onSubmit={onFormSubmit}>
        {cards}
        <ConfirmPageLeave isActive={isFormDataChanged && !isSubmitting} />
        <FormActions submitBtnTitle={t('common.update')} />
      </Box>
    </Box>
  );
};
