import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICostPlusWeeklyModel,
  ICostPlusWeeklyState,
} from '../../types/CostPlusWeeklyTypes';
import { ICountResponse, ListWithSummary } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: ICostPlusWeeklyState = {
  list: { items: [], summary: [] },
  details: {},
  count: 0,
};

/**
 * Get list actions
 */
const getCostPlusWeeklyListRequest = (state: ICostPlusWeeklyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getCostPlusWeeklyListSuccess = (
  state: ICostPlusWeeklyState,
  payload: ListWithSummary<ICostPlusWeeklyModel>,
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getCostPlusWeeklyListFailed = (
  state: ICostPlusWeeklyState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getCostPlusWeeklyCountRequest = (state: ICostPlusWeeklyState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getCostPlusWeeklyCountSuccess = (
  state: ICostPlusWeeklyState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getCostPlusWeeklyCountFailed = (
  state: ICostPlusWeeklyState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest: exportCostPlusWeeklyRequest,
  exportSuccess: exportCostPlusWeeklySuccess,
  exportFailed: exportCostPlusWeeklyFailed,
} = new ExportReducerCreator().takeHandlers<ICostPlusWeeklyState>();

/**
 * Cost Plus Weekly reducer
 */
export const costPlusWeekly = createReducer<ICostPlusWeeklyState>(
  {},
  defaultState,
)
  // get list actions
  .on(actions.getCostPlusWeeklyListRequest, getCostPlusWeeklyListRequest)
  .on(actions.getCostPlusWeeklyListSuccess, getCostPlusWeeklyListSuccess)
  .on(actions.getCostPlusWeeklyListFailed, getCostPlusWeeklyListFailed)
  // get count actions
  .on(actions.getCostPlusWeeklyCountRequest, getCostPlusWeeklyCountRequest)
  .on(actions.getCostPlusWeeklyCountSuccess, getCostPlusWeeklyCountSuccess)
  .on(actions.getCostPlusWeeklyCountFailed, getCostPlusWeeklyCountFailed)
  //export cost plus weekly actions
  .on(actions.exportCostPlusWeeklyRequest, exportCostPlusWeeklyRequest)
  .on(actions.exportCostPlusWeeklySuccess, exportCostPlusWeeklySuccess)
  .on(actions.exportCostPlusWeeklyFailed, exportCostPlusWeeklyFailed);

export default costPlusWeekly;
