import React from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { IInvoiceDetails } from 'src/modules/types';

type IInvoiceBackupDetailsProps = IInvoiceDetails['backupDetails'] &
  Pick<IInvoiceDetails, 'total'>;

const capitalizedHeader = ['cpu', 'lbsph', 'lpph'];
const shouldCapitalize = (headerItem: string) =>
  capitalizedHeader.includes(headerItem);
const shouldCenter = (index: number) => index !== 0;

const getFormattedNumber = (
  value: string | number,
  minimumFractionDigits = 2,
) => {
  if (typeof value !== 'number') {
    return value;
  }

  return new Intl.NumberFormat('us-US', {
    minimumFractionDigits,
  }).format(value);
};

export const InvoiceBackupDetailsTable: React.FC<IInvoiceBackupDetailsProps> = ({
  headers,
  items,
  total,
}) => {
  return (
    <Box>
      <Box
        component="table"
        sx={{
          width: '100%',
          '& td': {
            padding: 0.5,
          },
          ' & th': {
            textTransform: 'capitalize',
            padding: 1,
            backgroundColor: '#bac3fd',
          },
          '& .uppercase': {
            textTransform: 'uppercase',
          },
        }}
      >
        <tr>
          {headers.map((headerItem) => (
            <th
              className={clsx({ uppercase: shouldCapitalize(headerItem) })}
              key={headerItem}
            >
              {headerItem}
            </th>
          ))}
        </tr>
        {items.map((item, i) => (
          <tr key={i}>
            {headers.map((headerItem, dataIndex) => (
              <Box
                component="td"
                sx={shouldCenter(dataIndex) ? { textAlign: 'center' } : null}
                key={i + headerItem}
              >
                {getFormattedNumber(
                  item[headerItem],
                  headerItem === 'cpu' ? 5 : 2,
                )}
              </Box>
            ))}
          </tr>
        ))}
        <tr>
          <td colSpan={headers.length - 1} style={{ textAlign: 'right' }}>
            <span style={{ fontWeight: 'bold' }}>Total Invoice:</span>
          </td>
          <td>
            <span style={{ fontWeight: 'bold' }}>
              US$ {getFormattedNumber(total)}
            </span>
          </td>
        </tr>
      </Box>
    </Box>
  );
};
