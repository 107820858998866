import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateQuestionRequest,
  IdsArray,
  IFilter,
  IQuestionModel,
  ISagaAction,
  ISagaActionBind,
  IUpdateQuestionRequest,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

export const createQuestionRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ICreateQuestionRequest>>): SagaIterator {
  try {
    const response: IQuestionModel = yield call(Api.Question.create, data);
    yield put(actions.createQuestionSuccess(response));

    yield put(actions.getQuestionListRequest({ filter: filters.list }));
    yield put(actions.getQuestionCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'question.question_successfully_created',
      }),
    );
  } catch (e) {
    yield put(actions.createQuestionFailed());
  }
};

export const getQuestionListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IQuestionModel[] = yield call(
      Api.Question.list,
      action.payload,
    );
    yield put(actions.getQuestionListSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionListFailed());
  }
};

export const getQuestionCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Question.count,
      action?.payload,
    );
    yield put(actions.getQuestionCountSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionCountFailed());
  }
};

export const deleteQuestionRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Question.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getQuestionListRequest({ filter: filters.list }));
    yield put(actions.getQuestionCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'question.questions_successfully_deleted'
            : 'question.question_successfully_deleted',
      }),
    );
  } catch (e) {
    yield put(actions.deleteQuestionFailed());
  }
};

export const updateQuestionsRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IUpdateQuestionRequest[]>>): SagaIterator {
  try {
    yield call(Api.Question.bulkUpdate, data);

    yield put(actions.getQuestionListRequest({ filter: filters.list }));
    yield put(actions.getQuestionCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title:
          data.length > 1
            ? 'question.questions_successfully_updated'
            : 'question.question_successfully_updated',
      }),
    );
  } catch (e) {
    yield put(actions.updateQuestionCategoriesFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Question.export,
  actionFailed: actions.exportQuestionFailed,
  actionSuccess: actions.exportQuestionSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportQuestionRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createQuestionRequest,
    saga: createQuestionRequestSaga,
  },
  {
    action: actions.getQuestionListRequest,
    saga: getQuestionListRequestSaga,
  },
  {
    action: actions.getQuestionCountRequest,
    saga: getQuestionCountRequestSaga,
  },
  {
    action: actions.deleteQuestionRequest,
    saga: deleteQuestionRequestSaga,
  },

  {
    action: actions.updateQuestionsRequest,
    saga: updateQuestionsRequestSaga,
  },
  {
    action: actions.exportQuestionRequest,
    saga: exportQuestionRequestSaga,
  },
];
