import React from 'react';
import { Sheet } from '@mui/joy';
import { Copyright } from '../Copyright';

export const PageContentCopyright = () => {
  return (
    <Sheet
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'inherit',
        height: 'var(--bottom-copyright-height)',
        minHeight: 'var(--bottom-copyright-height)',
      }}
    >
      <Copyright />
    </Sheet>
  );
};
