import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IInvoiceModel,
  ICountResponse,
  ICreateWeekBasedInvoice,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IInvoiceModelWithRelations,
  IInvoiceDetails,
  IExportInvoice,
  ICreateMonthBasedInvoiceRequest,
  IMonthBasedInvoiceModel,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';
import fileDownload from 'js-file-download';

/**
 * Create a new Invoice
 */
export const createInvoiceRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<ICreateWeekBasedInvoice>>): SagaIterator {
  try {
    const response = yield call(Api.Invoice.create, data);

    yield put(actions.getInvoiceListRequest({ filter: filters.list }));
    yield put(actions.getInvoiceCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createInvoiceSuccess(response));
  } catch (e) {
    yield put(actions.createInvoiceFailed());
  }
};
export const createMonthInvoiceRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<ICreateMonthBasedInvoiceRequest>
>): SagaIterator {
  try {
    const response = yield call(Api.Invoice.createMonth, data);

    yield put(actions.getMonthInvoiceListRequest({ filter: filters.list }));
    yield put(actions.getMonthInvoiceCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createMonthInvoiceSuccess(response));
  } catch (e) {
    yield put(actions.createMonthInvoiceFailed());
  }
};

/**
 * Get Invoice list
 */
export const getInvoiceListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IInvoiceModelWithRelations[] = yield call(
      Api.Invoice.list,
      action.payload,
    );
    yield put(actions.getInvoiceListSuccess(response));
  } catch (e) {
    yield put(actions.getInvoiceListFailed());
  }
};

export const getMonthInvoiceListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IInvoiceModelWithRelations[] = yield call(
      Api.Invoice.monthList,
      action.payload,
    );
    yield put(actions.getMonthInvoiceListSuccess(response));
  } catch (e) {
    yield put(actions.getMonthInvoiceListFailed());
  }
};

export const getInvoiceByIdRequestSaga = function* (
  action: ISagaAction<number>,
): SagaIterator {
  try {
    const response: IInvoiceDetails = yield call(
      Api.Invoice.byId,
      action.payload,
    );
    yield put(actions.getInvoiceByIdSuccess(response));
  } catch (e) {
    yield put(actions.getInvoiceByIdFailed());
  }
};

/**
 * Get Invoice count
 */
export const getInvoiceCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Invoice.count,
      action?.payload,
    );
    yield put(actions.getInvoiceCountSuccess(response));
  } catch (e) {
    yield put(actions.getInvoiceCountFailed());
  }
};

export const getMonthInvoiceCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Invoice.monthCount,
      action?.payload,
    );
    yield put(actions.getMonthInvoiceCountSuccess(response));
  } catch (e) {
    yield put(actions.getMonthInvoiceCountFailed());
  }
};

/**
 * Delete Invoices
 */
export const deleteInvoiceRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Invoice.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getInvoiceListRequest({ filter: filters.list }));
    yield put(actions.getInvoiceCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteInvoiceSuccess());
  } catch (e) {
    yield put(actions.deleteInvoiceFailed());
  }
};

export const deleteMonthInvoiceRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Invoice.delete, {
      where: { monthInvoiceId: { inq: data } },
    });

    yield put(actions.getMonthInvoiceListRequest({ filter: filters.list }));
    yield put(actions.getMonthInvoiceCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteMonthInvoiceSuccess());
  } catch (e) {
    yield put(actions.deleteMonthInvoiceFailed());
  }
};

/**
 * Bulk update Invoices
 */
export const updateInvoicesRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IInvoiceModel[]>>): SagaIterator {
  try {
    yield call(Api.Invoice.bulkUpdate, data);

    yield put(actions.getInvoiceListRequest({ filter: filters.list }));
    yield put(actions.getInvoiceCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateInvoicesSuccess());
  } catch (e) {
    yield put(actions.updateInvoicesFailed());
  }
};

export const updateMonthInvoicesRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IMonthBasedInvoiceModel[]>>): SagaIterator {
  try {
    yield call(Api.Invoice.monthBulkUpdate, data);

    yield put(actions.getMonthInvoiceListRequest({ filter: filters.list }));
    yield put(actions.getMonthInvoiceCountRequest({ filter: filters.count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateMonthInvoicesSuccess());
  } catch (e) {
    yield put(actions.updateMonthInvoicesFailed());
  }
};

const freezeInvoiceRequestSaga = function* ({
  payload,
}: ISagaAction<number>): SagaIterator {
  try {
    yield call(Api.Invoice.freeze, payload);
    yield put(actions.freezeInvoiceSuccess(payload));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.freezeInvoiceFailed());
  }
};
const freezeMonthInvoiceRequestSaga = function* ({
  payload,
}: ISagaAction<string>): SagaIterator {
  try {
    yield call(Api.Invoice.freezeMonth, payload);
    yield put(actions.freezeMonthInvoiceSuccess(payload));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.freezeMonthInvoiceFailed());
  }
};

export const unfreezeInvoiceRequestSaga = function* ({
  payload,
}: ISagaAction<number>): SagaIterator {
  try {
    yield call(Api.Invoice.unfreeze, payload);
    yield put(actions.unfreezeInvoiceSuccess(payload));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.unfreezeInvoiceFailed());
  }
};
export const unfreezeMonthInvoiceRequestSaga = function* ({
  payload,
}: ISagaAction<string>): SagaIterator {
  try {
    yield call(Api.Invoice.unfreezeMonth, payload);
    yield put(actions.unfreezeMonthInvoiceSuccess(payload));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.unfreezeMonthInvoiceFailed());
  }
};

export const exportInvoiceDetailsRequestSaga = function* ({
  payload,
}: ISagaAction<IExportInvoice>): SagaIterator {
  try {
    const { content, fileName } = yield call(
      Api.Invoice.exportDetails,
      payload,
    );
    yield put(actions.exportInvoiceDetailsSuccess());

    fileDownload(content, fileName);
  } catch (e) {
    yield put(actions.exportInvoiceDetailsFailed());
  }
};

export const exportMonthInvoiceDetailsRequestSaga = function* ({
  payload,
}: ISagaAction<IExportInvoice>): SagaIterator {
  try {
    const { content, fileName } = yield call(
      Api.Invoice.exportMonthDetails,
      payload,
    );
    yield put(actions.exportInvoiceDetailsSuccess());

    fileDownload(content, fileName);
  } catch (e) {
    yield put(actions.exportMonthInvoiceDetailsFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Invoice.export,
  actionFailed: actions.exportInvoiceFailed,
  actionSuccess: actions.exportInvoiceSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const exportInvoiceRequestSaga = exportRequestSaga;
const { exportRequestSaga: exportMonthRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Invoice.monthExport,
  actionFailed: actions.exportMonthInvoiceFailed,
  actionSuccess: actions.exportMonthInvoiceSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportMonthInvoiceRequestSaga = exportMonthRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createInvoiceRequest, saga: createInvoiceRequestSaga },
  {
    action: actions.createMonthInvoiceRequest,
    saga: createMonthInvoiceRequestSaga,
  },
  { action: actions.getInvoiceListRequest, saga: getInvoiceListRequestSaga },
  { action: actions.getInvoiceCountRequest, saga: getInvoiceCountRequestSaga },
  {
    action: actions.getMonthInvoiceListRequest,
    saga: getMonthInvoiceListRequestSaga,
  },
  {
    action: actions.getMonthInvoiceCountRequest,
    saga: getMonthInvoiceCountRequestSaga,
  },
  { action: actions.deleteInvoiceRequest, saga: deleteInvoiceRequestSaga },
  {
    action: actions.deleteMonthInvoiceRequest,
    saga: deleteMonthInvoiceRequestSaga,
  },
  // bulk updating Invoices
  { action: actions.updateInvoicesRequest, saga: updateInvoicesRequestSaga },
  {
    action: actions.updateMonthInvoicesRequest,
    saga: updateMonthInvoicesRequestSaga,
  },
  { action: actions.freezeInvoiceRequest, saga: freezeInvoiceRequestSaga },
  {
    action: actions.freezeMonthInvoiceRequest,
    saga: freezeMonthInvoiceRequestSaga,
  },
  { action: actions.unfreezeInvoiceRequest, saga: unfreezeInvoiceRequestSaga },
  {
    action: actions.unfreezeMonthInvoiceRequest,
    saga: unfreezeMonthInvoiceRequestSaga,
  },
  { action: actions.exportInvoiceRequest, saga: exportInvoiceRequestSaga },
  {
    action: actions.exportMonthInvoiceRequest,
    saga: exportMonthInvoiceRequestSaga,
  },
  {
    action: actions.exportInvoiceDetailsRequest,
    saga: exportInvoiceDetailsRequestSaga,
  },
  {
    action: actions.exportMonthInvoiceDetailsRequest,
    saga: exportMonthInvoiceDetailsRequestSaga,
  },

  { action: actions.getInvoiceByIdRequest, saga: getInvoiceByIdRequestSaga },
];
