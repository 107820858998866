import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { ICountResponse, IMetatagModel, IMetatagState } from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IMetatagState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  name: '',
  list: [],
  comboboxList: [],
  count: 0,
  isMetatagsDataLoading: false,
  isMetatagsDataProcessing: false,
};

/**
 * Flush actions
 */
const flushMetatagState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createMetatagRequest = (state: IMetatagState) => ({
  ...state,
  error: false,
  isMetatagsDataProcessing: true,
});

const createMetatagSuccess = (
  state: IMetatagState,
  payload: IMetatagModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isMetatagsDataProcessing: false,
});

const createMetatagFailed = (state: IMetatagState, payload: string) => ({
  ...state,
  error: payload,
  isMetatagsDataProcessing: false,
});

/**
 * Get list actions
 */
const getMetatagListRequest = (state: IMetatagState) => ({
  ...state,
  error: false,
  isMetatagsDataLoading: true,
});

const getMetatagListSuccess = (
  state: IMetatagState,
  payload: IMetatagModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isMetatagsDataLoading: false,
});

const getMetatagListFailed = (state: IMetatagState, payload: string) => ({
  ...state,
  error: payload,
  isMetatagsDataLoading: false,
});

/**
 * Get count actions
 */
const getMetatagCountRequest = (state: IMetatagState) => ({
  ...state,
  error: false,
  isMetatagsDataLoading: true,
});

const getMetatagCountSuccess = (
  state: IMetatagState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isMetatagsDataLoading: false,
});

const getMetatagCountFailed = (state: IMetatagState, payload: string) => ({
  ...state,
  error: payload,
  isMetatagsDataLoading: false,
});

/**
 * Deletion actions
 */
const deleteMetatagRequest = (state: IMetatagState) => ({
  ...state,
  error: false,
  isMetatagsDataProcessing: true,
});

const deleteMetatagSuccess = (state: IMetatagState) => ({
  ...state,
  isMetatagsDataProcessing: false,
});

const deleteMetatagFailed = (state: IMetatagState, payload: string) => ({
  ...state,
  error: payload,
  isMetatagsDataProcessing: false,
});

/**
 * Updating actions
 */
const updateMetatagsRequest = (state: IMetatagState) => ({
  ...state,
  error: false,
  isMetatagsDataProcessing: true,
});

const updateMetatagsSuccess = (state: IMetatagState) => ({
  ...state,
  isMetatagsDataProcessing: false,
});

const updateMetatagsFailed = (state: IMetatagState, payload: string) => ({
  ...state,
  error: payload,
  isMetatagsDataProcessing: false,
});

/**
 * Get combobox list actions
 */
const getMetatagComboboxListRequest = (state: IMetatagState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getMetatagComboboxListSuccess = (
  state: IMetatagState,
  payload: Partial<IMetatagModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getMetatagComboboxListFailed = (
  state: IMetatagState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IMetatagState>();

/**
 * Metatag reducer
 */
export const metatag = createReducer<IMetatagState>({}, defaultState)
  // flush actions
  .on(actions.flushMetatagState, flushMetatagState)
  // creation actions
  .on(actions.createMetatagRequest, createMetatagRequest)
  .on(actions.createMetatagSuccess, createMetatagSuccess)
  .on(actions.createMetatagFailed, createMetatagFailed)
  // get list actions
  .on(actions.getMetatagListRequest, getMetatagListRequest)
  .on(actions.getMetatagListSuccess, getMetatagListSuccess)
  .on(actions.getMetatagListFailed, getMetatagListFailed)
  // get count actions
  .on(actions.getMetatagCountRequest, getMetatagCountRequest)
  .on(actions.getMetatagCountSuccess, getMetatagCountSuccess)
  .on(actions.getMetatagCountFailed, getMetatagCountFailed)
  // deletion actions
  .on(actions.deleteMetatagRequest, deleteMetatagRequest)
  .on(actions.deleteMetatagSuccess, deleteMetatagSuccess)
  .on(actions.deleteMetatagFailed, deleteMetatagFailed)
  // updating actions
  .on(actions.updateMetatagsRequest, updateMetatagsRequest)
  .on(actions.updateMetatagsSuccess, updateMetatagsSuccess)
  .on(actions.updateMetatagsFailed, updateMetatagsFailed)
  // get combobox list actions
  .on(actions.getMetatagComboboxListRequest, getMetatagComboboxListRequest)
  .on(actions.getMetatagComboboxListSuccess, getMetatagComboboxListSuccess)
  .on(actions.getMetatagComboboxListFailed, getMetatagComboboxListFailed)
  // export actions
  .on(actions.exportMetatagRequest, exportRequest)
  .on(actions.exportMetatagSuccess, exportSuccess)
  .on(actions.exportMetatagFailed, exportFailed);

export default metatag;
