import { Box } from '@mui/material';
import React from 'react';
import RRule, { Frequency } from 'rrule';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import {
  getDate,
  getWeekOfMonth,
  getDay,
  composeDate,
  DATE_FORMAT,
  parse,
} from 'src/modules/utils/dateWrapper';
import {
  createRRuleRecurrence,
  rruleToName,
} from 'src/modules/utils/rRuleWrapper';

interface IMonthlyRecurrenceComboboxProps {
  startDate: Date;
  interval: number;
  onChange: (value: { id: any; name: string } | null) => void;
  value: ComboBoxOption | null;
  recurrenceEnd: string | number | null;
}

const weekDayNumberToWeekDayStrMapper = {
  1: 'MO',
  2: 'TU',
  3: 'WE',
  4: 'TH',
  5: 'FR',
  6: 'SA',
  0: 'SU',
};

export const MonthlyRecurrenceCombobox: React.FC<IMonthlyRecurrenceComboboxProps> = ({
  startDate,
  interval,
  onChange,
  value,
  recurrenceEnd,
}) => {
  const options = React.useMemo(() => {
    const monthDay = getDate(startDate);
    const weekOfMonth = getWeekOfMonth(startDate);
    const weekDay = getDay(startDate);
    const hours = 0;
    const minutes = 0;
    const until =
      typeof recurrenceEnd === 'string'
        ? composeDate(recurrenceEnd, parse(DATE_FORMAT))
        : undefined;

    const count = typeof recurrenceEnd === 'number' ? recurrenceEnd : undefined;

    const firstOptionRule = createRRuleRecurrence({
      interval,
      bymonthday: monthDay,
      dtstart: startDate,
      freq: Frequency.MONTHLY,
      byhour: hours,
      byminute: minutes,
      until,
      count,
    });

    const secondOptionRule = createRRuleRecurrence({
      interval,
      byweekday: RRule[weekDayNumberToWeekDayStrMapper[weekDay]].nth(
        weekOfMonth,
      ),
      dtstart: startDate,
      freq: Frequency.MONTHLY,
      byhour: hours,
      byminute: minutes,
      until: until,
      count,
    });

    return [firstOptionRule, secondOptionRule].map((rule) => ({
      id: rule.toString(),
      name: rruleToName(rule),
    }));
  }, [interval, recurrenceEnd, startDate]);

  const onSelectValue = (e: any, value: ComboBoxOption | null) => {
    onChange(value);
  };

  React.useEffect(() => {
    if (value) {
      return;
    }
    // Select first option if value is not provider from parent component
    onChange(options[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const isOptionsRecalculated = !options.some(
      (option) => String(option.id) === String(value?.id),
    );

    // Select first option after options recalculated
    if (isOptionsRecalculated) {
      onChange(options[0] as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Box sx={{ minHeight: 82 }}>
      <ComboBox
        value={value}
        onChange={onSelectValue}
        options={options as any}
      />
    </Box>
  );
};
