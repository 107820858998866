import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useTaktExportSitesOptions, useValidate } from 'src/modules/utils';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { sendRangePunchesScheme } from 'src/modules/schemes';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import { FormNote } from 'src/components/Form/FormNote';

const initialValues = {
  siteId: NaN,
  dateStart: null,
  dateEnd: null,
};

interface ITaktSettingsExportRangeProps {
  isOpen: boolean;
  onClose: () => void;
  onExportRangePunches: (data: typeof initialValues) => void;
}

export const TaktSettingsExportRange = ({
  isOpen,
  onClose,
  onExportRangePunches,
}: ITaktSettingsExportRangeProps) => {
  const { t } = useTranslation();

  const validate = useValidate(sendRangePunchesScheme);

  const siteOptions = useTaktExportSitesOptions();

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      onExportRangePunches(data);

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('site-takt-exporting-settings.export_range_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormNote>
                * Will include approved punches only which have logOut time and
                with more than 3 minutes of totalTime
              </FormNote>
            </FormFieldContainer>
            <FormFieldContainer>
              <FormikCombobox
                required
                id="siteId"
                placeholder={t('productions.site.name')}
                options={siteOptions as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikDatepicker
                required
                fullWidth
                label={t('site-takt-exporting-settings.start_date')}
                id="dateStart"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikDatepicker
                required
                fullWidth
                label={t('site-takt-exporting-settings.end_date')}
                id="dateEnd"
                formik={formik}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
