import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { fetchHRDashboardActualVsNeededChartData } from 'src/modules/utils/Api/hrDashboard';
import { useTheme } from '@mui/joy';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { IAutocompleteOption } from 'src/modules/types/autocomplete';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';

interface IHRDashboardActiveVsNeededProps {
  siteId?: number;
  shiftId?: number;
}

export const HRDashboardActiveVsNeeded = ({
  siteId,
  shiftId,
}: IHRDashboardActiveVsNeededProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const chartTypeOptions = React.useMemo(
    () => [
      { id: 'active' as const, label: t('common.active') },
      { id: 'needed' as const, label: t('common.needed') },
      { id: 'active_vs_needed' as const, label: t('common.active_vs_needed') },
    ],
    [t],
  );

  const activeCalculatedByOptions = React.useMemo(
    () => [
      { id: 'total' as const, label: t('common.total') },
      { id: 'shift' as const, label: t('common.by_shift') },
    ],
    [t],
  );

  const [activeCalculatedBy, setActiveCalculatedBy] = React.useState<
    IAutocompleteOption<'shift' | 'total'>
  >(activeCalculatedByOptions[1]);
  const [activeChartType, setActiveChartType] = React.useState<
    IAutocompleteOption<'active' | 'needed' | 'active_vs_needed'>
  >(chartTypeOptions[2]);

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRDashboardActualVsNeededChartData,
    {
      initialData: [],
    },
  );

  const categories = React.useMemo(() => {
    if (activeCalculatedBy.id === 'total') {
      return [data[0].siteName];
    }
    return data.map((item) => item.shiftName);
  }, [activeCalculatedBy.id, data]);

  const series = React.useMemo(() => {
    const activeIndex = 0;
    const neededIndex = 1;

    const activeCommonData = {
      name: t('common.active'),
      type: 'column' as const,
      color: theme.palette.success[600],
      data: [],
    };
    const neededCommonData = {
      name: t('common.needed'),
      type: 'column' as const,
      color: theme.palette.colors.foreground.fg_error_primary,
      data: [],
    };

    if (activeCalculatedBy.id === 'total') {
      const { totalActive, totalNeeded } = data.reduce(
        (acc, item) => {
          acc.totalActive += item.totalActiveEmployees;
          acc.totalNeeded += item.numberNeededEmployees;

          return acc;
        },
        {
          totalActive: 0,
          totalNeeded: 0,
        },
      );

      return [
        {
          ...activeCommonData,
          data: [totalActive],
        },
        {
          ...neededCommonData,
          data: [totalNeeded],
        },
      ];
    }

    return data.reduce<
      Array<{
        name: string;
        data: Array<number | null>;
        type: 'column';
        color: string;
      }>
    >(
      (all, item) => {
        all[activeIndex].data = [
          ...all[activeIndex].data,
          activeChartType.id !== 'needed' ? item.totalActiveEmployees : null,
        ];
        all[neededIndex].data = [
          ...all[neededIndex].data,
          activeChartType.id !== 'active' ? item.numberNeededEmployees : null,
        ];

        return all;
      },
      [activeCommonData, neededCommonData],
    );
  }, [
    t,
    data,
    activeChartType.id,
    activeCalculatedBy.id,
    theme.palette.success,
    theme.palette.colors.foreground.fg_error_primary,
  ]);

  React.useEffect(() => {
    getData({ siteId, shiftId });
  }, [getData, siteId, shiftId]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('common.active_vs_needed')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(t('common.active_vs_needed'))}
          />
          <DoubledCombobox>
            <LeftCombobox
              disableClearable
              value={activeCalculatedBy}
              options={activeCalculatedByOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveCalculatedBy(value);
                }
              }}
            />
            <ComboboxSeparator />
            <RightCombobox
              disableClearable
              value={activeChartType}
              options={chartTypeOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveChartType(value);
                }
              }}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <ColumnChart
          fullscreenTitle={t('common.active_vs_needed')}
          ref={chartRef}
          height={chartHeight}
          series={series}
          categories={categories}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
