import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  IStoreState,
  IdsArray,
  AnyObject,
  IUpdateSite,
} from 'src/modules/types';
import { getSitesByIds } from 'src/modules/selectors/site';
import { updateSitesScheme } from 'src/modules/schemes/sites';
import { useFormikInUpdateForm } from 'src/modules/utils/hooks/common/forms';
import { updateSitesDesiredEmployeesNumberRequest } from 'src/modules/actions';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';

interface ISitesUpdateEmployeesNumberProps {
  isOpen: boolean;
  onClose: () => void;
  entitiesToUpdateIds: IdsArray;
  filterList: AnyObject;
  filterCount: AnyObject;
}

export const SitesUpdateEmployeesNumber = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  entitiesToUpdateIds,
}: ISitesUpdateEmployeesNumberProps) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const sites = useSelector((state) =>
    getSitesByIds(state as IStoreState)(entitiesToUpdateIds),
  );

  const validate = useValidate(updateSitesScheme, {
    allowUnionTypes: true,
  }) as any;

  const formik = useFormikInUpdateForm({
    initialValues: sites,
    validate,
    onSubmit: (
      data: Pick<IUpdateSite, 'id' | 'requiredAssociates' | 'absenteeism'>[],
    ) => {
      dispatcher(
        updateSitesDesiredEmployeesNumberRequest({
          data: data.map(({ id, requiredAssociates, absenteeism }) => ({
            id,
            requiredAssociates,
            absenteeism,
          })),
          filters: {
            list: filterList,
            count: filterCount,
          },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('sites.updateDesiredEmployeesNumber')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {sites.map((site, index) => (
            <DrawerBodySectionContent key={site.id}>
              <DrawerBodySectionTitle>{site.name}</DrawerBodySectionTitle>
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.requiredAssociates`}
                  name={`${index}.requiredAssociates`}
                  label={t('sites.requiredAssociates')}
                  autoComplete="requiredAssociates"
                  formik={formik}
                  type="number"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.absenteeism`}
                  label={t('sites.absenteeism')}
                  name={`${index}.absenteeism`}
                  formik={formik}
                  type="number"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
