import React, { useMemo } from 'react';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import { AnyObject } from '../../modules/types';
import { get } from 'lodash';
import { ErrorMode } from './types';
import Datepicker, {
  IDatePickerProps,
} from '../_ui-kit/Combobox/Pickers/Datepicker';

interface IProps<Values>
  extends Omit<IDatePickerProps, 'onChange' | 'variant'> {
  id: string;
  formik: FormikHelpers<Values> & FormikState<Values>;
  errorMode?: ErrorMode;
  variant?: any;
}

export default function FormikDatepicker<Values extends AnyObject>(
  props: IProps<Values>,
) {
  const { id, formik, errorMode = 'onFormSubmit', ...restProps } = props;
  const handleChange = (time: string | null) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, time);
  };

  const option = useMemo(() => get(formik.values, id), [formik.values, id]);
  const error = useMemo(() => get(formik.errors, id), [formik.errors, id]);
  const touched = useMemo(() => get(formik.touched, id), [formik.touched, id]);
  const showError = useMemo<boolean>(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  return (
    <Datepicker
      {...restProps}
      id={id}
      value={option}
      onChange={handleChange}
      error={showError}
      helperText={showError && error}
    />
  );
}
