import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  IOptiturnCredentialsModel,
  IOptiturnCredentialsState,
} from '../types';

// default state
const defaultState: IOptiturnCredentialsState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  login: '',
  password: '',
  list: [],
  comboboxList: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushOptiturnCredentialsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createOptiturnCredentialsRequest = (
  state: IOptiturnCredentialsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createOptiturnCredentialsSuccess = (
  state: IOptiturnCredentialsState,
  payload: IOptiturnCredentialsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createOptiturnCredentialsFailed = (
  state: IOptiturnCredentialsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getOptiturnCredentialsListRequest = (
  state: IOptiturnCredentialsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getOptiturnCredentialsListSuccess = (
  state: IOptiturnCredentialsState,
  payload: IOptiturnCredentialsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getOptiturnCredentialsListFailed = (
  state: IOptiturnCredentialsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getOptiturnCredentialsCountRequest = (
  state: IOptiturnCredentialsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getOptiturnCredentialsCountSuccess = (
  state: IOptiturnCredentialsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getOptiturnCredentialsCountFailed = (
  state: IOptiturnCredentialsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteOptiturnCredentialsRequest = (
  state: IOptiturnCredentialsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteOptiturnCredentialsFailed = (
  state: IOptiturnCredentialsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateOptiturnCredentialsRequest = (
  state: IOptiturnCredentialsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateOptiturnCredentialsFailed = (
  state: IOptiturnCredentialsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getOptiturnCredentialsComboboxListRequest = (
  state: IOptiturnCredentialsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getOptiturnCredentialsComboboxListSuccess = (
  state: IOptiturnCredentialsState,
  payload: Partial<IOptiturnCredentialsModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getOptiturnCredentialsComboboxListFailed = (
  state: IOptiturnCredentialsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * OptiturnCredentials reducer
 */
export const optiturnCredentials = createReducer<IOptiturnCredentialsState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushOptiturnCredentialsState, flushOptiturnCredentialsState)
  // creation actions
  .on(
    actions.createOptiturnCredentialsRequest,
    createOptiturnCredentialsRequest,
  )
  .on(
    actions.createOptiturnCredentialsSuccess,
    createOptiturnCredentialsSuccess,
  )
  .on(actions.createOptiturnCredentialsFailed, createOptiturnCredentialsFailed)
  // get list actions
  .on(
    actions.getOptiturnCredentialsListRequest,
    getOptiturnCredentialsListRequest,
  )
  .on(
    actions.getOptiturnCredentialsListSuccess,
    getOptiturnCredentialsListSuccess,
  )
  .on(
    actions.getOptiturnCredentialsListFailed,
    getOptiturnCredentialsListFailed,
  )
  // get count actions
  .on(
    actions.getOptiturnCredentialsCountRequest,
    getOptiturnCredentialsCountRequest,
  )
  .on(
    actions.getOptiturnCredentialsCountSuccess,
    getOptiturnCredentialsCountSuccess,
  )
  .on(
    actions.getOptiturnCredentialsCountFailed,
    getOptiturnCredentialsCountFailed,
  )
  // deletion actions
  .on(
    actions.deleteOptiturnCredentialsRequest,
    deleteOptiturnCredentialsRequest,
  )
  .on(actions.deleteOptiturnCredentialsFailed, deleteOptiturnCredentialsFailed)
  // updating actions
  .on(
    actions.updateOptiturnCredentialsRequest,
    updateOptiturnCredentialsRequest,
  )
  .on(actions.updateOptiturnCredentialsFailed, updateOptiturnCredentialsFailed)
  // get combobox list actions
  .on(
    actions.getOptiturnCredentialsComboboxListRequest,
    getOptiturnCredentialsComboboxListRequest,
  )
  .on(
    actions.getOptiturnCredentialsComboboxListSuccess,
    getOptiturnCredentialsComboboxListSuccess,
  )
  .on(
    actions.getOptiturnCredentialsComboboxListFailed,
    getOptiturnCredentialsComboboxListFailed,
  );

export default optiturnCredentials;
