import { createAction } from 'redux-act';
import {
  ClientKeys,
  ICountResponse,
  ICreateSiteImportingSettings,
  IdsArray,
  IFilter,
  ISiteImportingSettingsModel,
  ISiteModel,
  IUpdateSiteImportingSettings,
  PayloadWithFilters,
} from '../types';

// Flush actions
export const flushSiteImportingSettingsState = createAction(
  'Flush siteImportingSettings data in store',
);

// Creation actions
export const createSiteImportingSettingsRequest = createAction<
  PayloadWithFilters<ICreateSiteImportingSettings>
>('Make request to create a new siteImportingSettings');
export const createSiteImportingSettingsSuccess = createAction<
  ISiteImportingSettingsModel
>('New siteImportingSettings has been created successfully');
export const createSiteImportingSettingsFailed = createAction(
  'Cannot create a new siteImportingSettings',
);

// Fetch siteImportingSettings actions
export const getSiteImportingSettingsListRequest = createAction<IFilter>(
  'Make request to fetch siteImportingSettings list',
);
export const getSiteImportingSettingsListSuccess = createAction<
  ISiteImportingSettingsModel[]
>('SiteImportingSettings list has been fetched successfully');
export const getSiteImportingSettingsListFailed = createAction(
  'Cannot fetch siteImportingSettings list',
);

// get siteImportingSettings count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getSiteImportingSettingsCountRequest = createAction<IFilter>(
  'Make request to get siteImportingSettings count',
);
export const getSiteImportingSettingsCountSuccess = createAction<
  ICountResponse
>('SiteImportingSettings count has been fetched successfully');
export const getSiteImportingSettingsCountFailed = createAction(
  'Cannot get siteImportingSettings count',
);

// delete actions
export const deleteSiteImportingSettingsRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete siteImportingSettings');
export const deleteSiteImportingSettingsSuccess = createAction(
  'SiteImportingSettings deleted successfully',
);
export const deleteSiteImportingSettingsFailed = createAction(
  'Cannot delete siteImportingSettings',
);

// Updating actions
export const updateSiteImportingSettingsRequest = createAction<
  PayloadWithFilters<IUpdateSiteImportingSettings[]>
>('Make request to update siteImportingSettings');
export const updateSiteImportingSettingsSuccess = createAction(
  'SiteImportingSettings successfully updated',
);
export const updateSiteImportingSettingsFailed = createAction(
  'Cannot update siteImportingSettings',
);

// Fetch combobox list actions
export const getSiteImportingSettingsComboboxListRequest = createAction<
  IFilter
>('Make request to fetch combobox list of siteImportingSettings');
export const getSiteImportingSettingsComboboxListSuccess = createAction<
  Partial<ISiteImportingSettingsModel>[]
>('Combobox list of siteImportingSettings has been fetched successfully');
export const getSiteImportingSettingsComboboxListFailed = createAction(
  'Cannot fetch combobox siteImportingSettings list',
);

// Fetch combobox importing sites list actions
export const getSiteImportingComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of importing sites',
);
export const getSiteImportingComboboxListSuccess = createAction<ISiteModel[]>(
  'Combobox list of importing sites has been fetched successfully',
);
export const getSiteImportingComboboxListFailed = createAction(
  'Cannot fetch combobox importing sites list',
);

// Fetch client keys actions
export const getSiteImportingSettingsClientKeysRequest = createAction(
  'Make request to fetch client keys',
);
export const getSiteImportingSettingsClientKeysSuccess = createAction<
  ClientKeys
>('Client keys have been fetched successfully');
export const getSiteImportingSettingsClientKeysFailed = createAction(
  'Cannot fetch client keys list',
);
