import { createSelector } from 'reselect';
import { IStoreState } from '../types';
import { format, parseISO } from 'date-fns';
import { DATETIME_FORMAT_TO_SHOW } from '../utils/dateWrapper';

export const getQuestionEmployeeData = (state: IStoreState) =>
  state.questionEmployee;

export const getQuestionEmployeeHistoryData = (state: IStoreState) =>
  state.questionEmployee.history;

export const getOverdueQuestionnairesData = (state: IStoreState) =>
  state.questionEmployee.overdue;

export const getQuestionEmployeeHistoryRefreshKey = createSelector(
  getQuestionEmployeeHistoryData,
  (data) => data.refreshKey,
);

export const getQuestionEmployeeHistoryList = createSelector(
  getQuestionEmployeeHistoryData,
  (historyData) => historyData.list,
);

export const getEmployeesReview = createSelector(
  getQuestionEmployeeHistoryList,
  (list) =>
    list.map((item) => {
      return {
        ...item,
        reviewedAt: format(parseISO(item.reviewedAt), DATETIME_FORMAT_TO_SHOW),
      };
    }),
);

export const getEmployeesReviewCount = createSelector(
  getQuestionEmployeeHistoryData,
  (historyData) => historyData.count,
);

export const getOverdueQuestionnaireList = createSelector(
  getOverdueQuestionnairesData,
  (overdueQuestionnairesData) => overdueQuestionnairesData.list,
);

export const getOverdueEmployeesReviewCount = createSelector(
  getOverdueQuestionnairesData,
  (overdueQuestionnairesData) => overdueQuestionnairesData.count,
);

export const getIsQuestionEmployeeHistoryDataRequestInProgress = createSelector(
  getQuestionEmployeeData,
  (overdueQuestionnairesData) =>
    overdueQuestionnairesData.isQuestionEmployeeHistoryDataRequestInProgress,
);

export const getIsOverdueQuestionnairesInProgress = createSelector(
  getQuestionEmployeeData,
  (overdueQuestionnairesData) =>
    overdueQuestionnairesData.isOverdueQuestionnairesInProgress ||
    overdueQuestionnairesData.isOverdueQuestionnairesCountInProgress,
);

export const getOverdueEmployeesReview = createSelector(
  getOverdueQuestionnaireList,
  (list) =>
    list.map((item) => {
      return {
        ...item,
        reviewedAt: format(parseISO(item.reviewedAt), DATETIME_FORMAT_TO_SHOW),
      };
    }),
);
