import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  getOpenPositionCountRequest,
  getOpenPositionListRequest,
} from '../../modules/actions';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { useTranslation } from 'react-i18next';
import {
  useFetchActiveDepartmentsCombobox,
  useFetchPositionsCombobox,
  useFetchShiftsCombobox,
  useFilter,
  useOpenPositionsDefaultFilters,
} from '../../modules/utils';
import { ContentLayout } from '../../components/Layout/ContentLayout';
import { map, merge } from 'lodash';
import { ModelsToDelete } from '../../config';
import { getPositionsComboboxListById } from '../../modules/selectors/position';
import { ComboBoxOption } from '../../components/ComboBox';
import { NoIcon, YesIcon } from '../../components/Icons';
import {
  getOpenPositionCount,
  getOpenPositionList,
  getOpenPositionRefreshKey,
} from '../../modules/selectors/openPosition';
import { Tooltip } from '@mui/material';
import {
  parse,
  format,
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
} from '../../modules/utils/dateWrapper';
import { getShiftOptionsByGloballySites } from '../../modules/selectors/shift';
import { getDepartmentOptionsByGloballySites } from '../../modules/selectors/department';

const ClosedPositionsList = () => {
  const { t } = useTranslation();

  const defaultFilter = useOpenPositionsDefaultFilters();

  const SHORT_COMMENT_LENGTH = 50;

  // fetch recruiting dashboard list
  const list = useSelector(getOpenPositionList, shallowEqual);
  const count = useSelector(getOpenPositionCount, shallowEqual);

  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          dateStart: item.dateStart
            ? composeDate(
                item.dateStart,
                parse(DATETIME_TIMEZONE),
                format(DATE_FORMAT),
              )
            : '',
          closed: item.closed ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          description:
            item.description &&
            item.description?.length > SHORT_COMMENT_LENGTH ? (
              <Tooltip title={item.description}>
                <span>
                  {item.description.substring(0, SHORT_COMMENT_LENGTH) + '...'}
                </span>
              </Tooltip>
            ) : (
              item.description
            ),
        };
      }),
    [list],
  );

  const fetchPositionsCombobox = useFetchPositionsCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  useEffect(() => {
    fetchPositionsCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  const listFilter = useMemo(
    () =>
      merge(filterList, {
        filter: {
          where: {
            closed: true,
          },
        },
      }),
    [filterList],
  );

  const countFilter = useMemo(
    () =>
      merge(filterCount, {
        filter: {
          where: {
            closed: true,
          },
        },
      }),
    [filterCount],
  );

  const positions = useSelector(getPositionsComboboxListById);
  const shifts = useSelector(getShiftOptionsByGloballySites, shallowEqual);
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'id',
      disablePadding: false,
      label: t('open_position.id'),
    },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('open_position.siteId'),
    },
    {
      id: 'position.name',
      disablePadding: false,
      label: t('open_position.positionId'),
    },
    {
      id: 'currentDuration',
      disablePadding: false,
      label: t('open_position.currentDuration'),
    },
    {
      id: 'numberNeeded',
      disablePadding: false,
      label: t('open_position.numberNeeded'),
    },
    {
      id: 'shift.name',
      disablePadding: false,
      label: t('open_position.shiftId'),
    },
    {
      id: 'department.name',
      disablePadding: false,
      label: t('open_position.departmentId'),
    },
    {
      id: 'dateStart',
      disablePadding: false,
      label: t('open_position.dateStart'),
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('open_position.description'),
    },
    {
      id: 'closed',
      disablePadding: false,
      label: t('open_position.closed'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'site.id',
      label: t('open_position.siteId'),
      operator: 'eq',
      type: 'comboboxSites',
    },
    {
      name: 'position.id',
      label: t('open_position.positionId'),
      operator: 'eq',
      type: 'combobox',
      options: positions as ComboBoxOption[],
    },
    {
      name: 'currentDuration',
      label: t('open_position.currentDuration'),
      operator: 'eq',
    },
    {
      name: 'numberNeeded',
      label: t('open_position.numberNeeded'),
      operator: 'eq',
    },
    {
      name: 'shift.id',
      label: t('open_position.shiftId'),
      operator: 'eq',
      type: 'combobox',
      options: shifts as ComboBoxOption[],
    },
    {
      name: 'department.id',
      label: t('open_position.departmentId'),
      operator: 'eq',
      type: 'combobox',
      options: departments as ComboBoxOption[],
    },
    {
      name: 'dateStart',
      label: t('open_position.dateStart'),
      operator: 'eq',
      type: 'date',
    },
    {
      name: 'description',
      label: t('open_position.description'),
      operator: 'like',
    },
  ];

  // make request to fetch recruitingDashboard details when component is mounted
  useEffect(() => {
    dispatcher(getOpenPositionListRequest(listFilter));
    // get total count
    dispatcher(getOpenPositionCountRequest(countFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getOpenPositionListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where: merge(where, {
            closed: true,
          }),
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getOpenPositionCountRequest({
        filter: {
          where: merge(where, {
            closed: true,
          }),
          include: newInclude,
        },
      }),
    );
  };

  return (
    <ContentLayout>
      <EnhancedTable
        data={computedList}
        count={count}
        disableSelection={true}
        selectIndex="id"
        tableName={t('open_position.table_name_closed')}
        headCells={headCells}
        include={defaultFilter.include}
        filters={filters}
        onSync={onSync}
        deleteModelName={ModelsToDelete.ClosedPositions}
      />
    </ContentLayout>
  );
};
/**
 * Wrapper to refresh component on flush store
 */
export default function ClosedPositionsListRefreshable() {
  return <ClosedPositionsList key={useSelector(getOpenPositionRefreshKey)} />;
}
