import React from 'react';
import { Typography } from '@mui/joy';
import {
  ToggleButtonGroup,
  ToggleButton,
} from 'src/components/_ui-kit/ToggleButton';

export const quarters = {
  q1: { label: 'Q1', value: 'q1' },
  q2: { label: 'Q2', value: 'q2' },
  q3: { label: 'Q3', value: 'q3' },
  q4: { label: 'Q4', value: 'q4' },
};

export type QuarterValue = typeof quarters[keyof typeof quarters]['value'];

interface IQuarterButtonsProps {
  view: QuarterValue;
  onViewChange: (nexView: QuarterValue) => void;
}

export const QuarterButtons = ({
  view,
  onViewChange,
}: IQuarterButtonsProps) => {
  return (
    <ToggleButtonGroup
      exclusive
      value={view}
      sx={{
        marginLeft: 2,
      }}
      onChange={(_, nextView) => onViewChange(nextView)}
    >
      {Object.values(quarters).map((quarter) => (
        <ToggleButton key={quarter.value} value={quarter.value}>
          <Typography>{quarter.label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
