import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { PayboardIncentivePayment } from './panels/PayboardIncentivePayment/PayboardIncentivePayment';
import { PayboardIncentiveDetailedPayment } from './panels/PayboardIncentivePayment/PayboardIncentiveDetailedPayment';

export const Incentive = () => {
  const { t } = useTranslation();

  const hasUserAccessToPayboardIncentivePaymentTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_INCENTIVE_INCENTIVE_PAYMENT_TAB,
  );
  const hasUserAccessToPayboardIncentiveDetailedPaymentTab = useHasUserAccessToViewTab(
    tabsPaths.TIME_KEEPING_INCENTIVE_INCENTIVE_DETAILED_PAYMENT_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('payboard.incentive_payment.title'),
          hasUserAccess: hasUserAccessToPayboardIncentivePaymentTab,
          component: <PayboardIncentivePayment />,
        },
        {
          title: t('payboard.incentive_detailed_payment.title'),
          hasUserAccess: hasUserAccessToPayboardIncentiveDetailedPaymentTab,
          component: <PayboardIncentiveDetailedPayment />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToPayboardIncentivePaymentTab,
      hasUserAccessToPayboardIncentiveDetailedPaymentTab,
      t,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
