import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
} from '../../modules/types';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import { ReportTable } from '../../components/ReportPage/ReportTable';
import { useAddReportTableDataToReportExportStateEffect } from '../../modules/utils/hooks/reports.hooks';

interface IProps {
  siteId?: number;
  startDate?: string;
  endDate?: string;
  shiftId?: number;
}

export const McEntireDailyUphBySiteAndShift: React.FC<IProps> = ({
  siteId,
  startDate,
  endDate,
  shiftId,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'date',
  };

  const where = React.useMemo(() => {
    const and = [];

    if (startDate) {
      and.push({ date: { gte: startDate } });
    }
    if (endDate) {
      and.push({ date: { lte: endDate } });
    }

    return {
      siteId,
      shiftId,
      ...(and.length ? { and } : {}),
    };
  }, [siteId, shiftId, startDate, endDate]);

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >('/mc-entire/daily-uph/by-site-and-shift', {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `/mc-entire/daily-uph/by-site-and-shift/count`,
    { count: 0 },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('productions_uph_reports.date'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'shiftName',
        orderConfig: { orderBy: 'shiftName' },
        label: t('productions_uph_reports.shift.name'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.lbs'),
      },
      {
        id: 'hrs',
        orderConfig: { orderBy: 'hrs' },
        label: t('productions_uph_reports.hrs'),
      },
      {
        id: 'uph',
        orderConfig: { orderBy: 'uph' },
        label: t('productions_uph_reports.lph'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <ReportTable
      data={data.items as any}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey="/mc-entire/daily-uph/by-site-and-shift"
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
