import React from 'react';
import { SelectProps, Select as JoyUiSelect, Box } from '@mui/joy';
import { ChevronDownSvg } from 'src/components/svgIcons';

export const Select = ({
  listboxOpen,
  onListboxOpenChange,
  indicator,
  ...props
}: SelectProps<any, any>) => {
  const [_listboxOpen, _onListboxOpenChange] = React.useState(false);

  const _onListboxOpenChangeHandler = () => {
    _onListboxOpenChange((prev) => !prev);
  };

  const rotate = _listboxOpen ?? listboxOpen ? -180 : 0;

  const _indicator = (
    <Box
      onClick={() =>
        onListboxOpenChange
          ? onListboxOpenChange(!listboxOpen)
          : _onListboxOpenChangeHandler()
      }
      sx={{
        display: 'flex',
        transform: `rotate(${rotate}deg)`,
        transition: 'transform 200ms',
      }}
    >
      <ChevronDownSvg />
    </Box>
  );

  return (
    <JoyUiSelect
      listboxOpen={listboxOpen ?? _listboxOpen}
      onListboxOpenChange={onListboxOpenChange ?? _onListboxOpenChangeHandler}
      indicator={indicator ?? _indicator}
      {...props}
    />
  );
};
