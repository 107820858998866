import { JSONSchemaType } from 'ajv';
import { ICreateSiteBillingDetailsFormData } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createSiteBillingDetailsScheme: JSONSchemaType<ICreateSiteBillingDetailsFormData> = {
  type: 'object',
  required: ['siteId'],
  additionalProperties: true,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
