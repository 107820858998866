import React from 'react';
import { Box } from '@mui/joy';
import {
  FilesDownloaderFile,
  IFilesDownloaderFileData,
  IFilesDownloaderFileProps,
} from './FilesDownloaderDrawerFile';
import { useTranslation } from 'react-i18next';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '../_ui-kit/Drawer';
import { ActionsBar } from '../_ui-kit/ActionsBar';
import { FilePreview } from './FilePreview';

interface IExtendedFilesConfigItem extends IFilesDownloaderFileData {
  id: string;
  linkTag: HTMLAnchorElement;
  isSelected: IFilesDownloaderFileProps['isSelected'];
}

export interface IFilesDownloaderDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  filesConfig: Array<IFilesDownloaderFileData>;
}

export const FilesDownloaderDrawer = ({
  isOpen,
  onClose,
  filesConfig,
}: IFilesDownloaderDrawerProps) => {
  const { t } = useTranslation();

  const [fileToPreviewUrl, setFileToPreviewUrl] = React.useState<string | null>(
    null,
  );

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const createUniqueFileKey = React.useCallback(
    ({ fileName, fileUrl }: IFilesDownloaderFileData) => {
      return `${fileName}_${fileUrl}`;
    },
    [],
  );

  const createLinkTag = React.useCallback(
    ({ fileName, fileUrl }: IFilesDownloaderFileData) => {
      const a = document.createElement('a');

      a.style.display = 'none';

      a.href = fileUrl;

      a.download = fileName;

      a.setAttribute('target', '_blank');

      if (containerRef.current) {
        containerRef.current.appendChild(a);
      }

      return a;
    },
    [],
  );

  const createExtendedFilesConfig = React.useCallback(
    (filesConfig: Array<IFilesDownloaderFileData>) => {
      return filesConfig.map((fileConfig) => ({
        ...fileConfig,
        isSelected: false,
        linkTag: createLinkTag(fileConfig),
        id: createUniqueFileKey(fileConfig),
      }));
    },
    [createLinkTag, createUniqueFileKey],
  );

  const [extendedFilesConfig, setExtendedFilesConfig] = React.useState<
    Array<IExtendedFilesConfigItem>
  >(createExtendedFilesConfig(filesConfig));

  const onFileDownloaderItemClick = React.useCallback((fileUrl: string) => {
    setFileToPreviewUrl(fileUrl);
    // setFilesToPre viewUrls((prev) => {
    //   const isSelected = prev;
    // prev.map((item) => {
    //   if (item === fileUrl) {

    //   }
    // })
    // });
    // setExtendedFilesConfig((prev) =>
    //   prev.map((item) => {
    //     if (item.id === fileId) {
    //       return {
    //         ...item,
    //         isSelected: !item.isSelected,
    //       };
    //     }
    //     return { ...item };
    //   }),
    // );
  }, []);

  // const onApply = () => {
  //   extendedFilesConfig.forEach((item, i) => {
  //     if (item.isSelected) {
  //       item.linkTag.click();
  //     }
  //   });
  // };

  // const isAllFilesCheckboxStateIndeterminate = React.useMemo(
  //   () => extendedFilesConfig.some(({ isSelected }) => isSelected),
  //   [extendedFilesConfig],
  // );
  // const isAllFilesCheckboxStateChecked = React.useMemo(
  //   () => extendedFilesConfig.every(({ isSelected }) => isSelected),
  //   [extendedFilesConfig],
  // );

  // const onAllFilesCheckboxChange = () => {
  //   if (isAllFilesCheckboxStateChecked) {
  //     setExtendedFilesConfig((prev) =>
  //       prev.map((item) => ({
  //         ...item,
  //         isSelected: false,
  //       })),
  //     );
  //     return;
  //   }

  //   setExtendedFilesConfig((prev) =>
  //     prev.map((item) => ({
  //       ...item,
  //       isSelected: true,
  //     })),
  //   );
  // };

  React.useEffect(() => {
    setExtendedFilesConfig(createExtendedFilesConfig(filesConfig));
  }, [createExtendedFilesConfig, filesConfig]);

  return (
    <>
      <Box ref={containerRef}>
        <Drawer open={isOpen} onClose={onClose} anchor="right">
          <DrawerHeader onCloseClick={onClose}>
            {t('common.uploaded_files')}
          </DrawerHeader>
          <DrawerBody>
            {/* <Box
              sx={{
                px: 4,
                py: 1.5,
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid transparent',
                borderColor: 'colors.border.border_secondary',
                bgcolor: 'colors.background.bg_secondary',
              }}
            >
              <Box sx={{ mr: 1.5 }}>
                <Checkbox
                  checked={isAllFilesCheckboxStateChecked}
                  indeterminate={
                    !isAllFilesCheckboxStateChecked &&
                    isAllFilesCheckboxStateIndeterminate
                  }
                  onChange={onAllFilesCheckboxChange}
                />
              </Box>

              <Typography level="text_xs" textColor="colors.text.text_tertiary">
                {t('common.file_name')}
              </Typography>
            </Box> */}

            {extendedFilesConfig.map((item) => (
              <FilesDownloaderFile
                {...item}
                key={item.id}
                onClick={onFileDownloaderItemClick}
              />
            ))}
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar onCancel={onClose} />
          </DrawerFooter>
        </Drawer>
      </Box>

      <FilePreview
        fileUrl={fileToPreviewUrl}
        onClose={() => setFileToPreviewUrl(null)}
      />
    </>
  );
};
