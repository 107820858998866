import React from 'react';
// import { grey } from '@mui/material/colors';
import { Box, Tooltip, Typography } from '@mui/joy';
import { InfoCircleSvg } from 'src/components/svgIcons';

interface IChartContainerHeaderProps {
  title?: string;
  tooltip?: string;
}

export const ChartContainerHeader = ({
  title,
  tooltip,
  children,
}: React.PropsWithChildren<IChartContainerHeaderProps>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 48,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'colors.border.border_secondary',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <Box sx={{ display: 'flex', padding: 0.5, mt: '2px' }}>
              <InfoCircleSvg />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
