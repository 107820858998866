import {
  filter,
  isEmpty,
  map,
  omit,
  omitBy,
  reduce,
  isNull,
  set,
} from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateIssue } from '../types';
import { SeriesColumnOptions } from 'highcharts';
import React from 'react';

export const getIssueRefreshKey = (state: IStoreState) =>
  state.issue.refreshKey;

export const getIssueData = (state: IStoreState) => state.issue;

/**
 * Get server error
 * @param issue - State issue
 */
export const getServerError = ({ issue }: IStoreState) => issue.error;

/**
 * Get issue list
 * @param issue - State issue
 */
export const getIssueList = ({ issue }: IStoreState) => issue.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param issue - State issue
 */
export const getIssuesTable = createSelector(getIssueList, (issues) =>
  filter(issues, (s) => !isEmpty(s.department) && !isEmpty(s.issueType)),
);

/**
 * Get issues by array of ids
 * @param issue - State issue
 */
export const getIssuesByIds = createSelector(
  getIssueList,
  (issues) => (ids: IdsArray) => {
    const initial: IUpdateIssue[] = [];
    return reduce(
      issues,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          const issueData = omit(cur, ['issueType', 'department']);
          acc.push((omitBy(issueData, isNull) as unknown) as IUpdateIssue);
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get issue count
 * @param issue - State issue
 */
export const getIssueCount = ({ issue }: IStoreState) => issue.count;

/**
 * Get combobox list
 */
export const getIssuesComboboxList = ({ issue }: IStoreState) => (
  keyType: 'id' = 'id',
) =>
  map(issue.comboboxList, (issueItem) => ({
    id: issueItem[keyType],
    name: issueItem.downTime,
  }));

export const getDowntimeChartData = createSelector(
  getIssueData,
  ({ downtimeChartData }) => {
    return React.useMemo(() => {
      const datesSet = new Set<string>();
      downtimeChartData.forEach((item) => {
        datesSet.add(item.weekLabel);
      });

      const dates = Array.from(datesSet.values());
      const series = downtimeChartData.reduce<SeriesColumnOptions[]>(
        (series, item) => {
          // check if set already exists in series
          const setIndex = series.findIndex(
            (set: any) =>
              set.name === item.issueType && set.stack === item.site,
          );
          // find out proper index in data array of the set based on weekLabel
          const dataIndex = dates.findIndex(
            (weekLabel) => weekLabel === item.weekLabel,
          );
          // add data item to set if the set already exists
          if (setIndex !== -1) {
            set(series, [setIndex, 'data', dataIndex], item.duration);
          } else {
            // every set should have null or number for appropriate value from categories array
            // so by default we set null for all categories
            const issuesDurationList = Array(dates.length).fill(null);
            // put data in correct place based on index of category
            issuesDurationList[dataIndex] = item.duration;
            // put set in series
            series.push({
              type: 'column',
              name: item.issueType,
              stack: item.site,
              data: issuesDurationList,
            });
          }

          return series;
        },
        [],
      );

      return {
        series,
        categories: dates,
      };
    }, [downtimeChartData]);
  },
);

export const getIDowntimeChartDataLoading = createSelector(
  getIssueData,
  (data) => data.isDowntimeChartDataLoading,
);
