import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeadCell } from '../../components/EnhancedTable';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import ReportWithOwnState from '../../components/Reports/ReportWithOwnState';
import { paths } from '../../config';
import { useParseChangelogData } from '../../modules/utils/hooks/changelog.hooks';
import { ComboBoxOption } from '../../components/ComboBox';
import { useFetchUsersCombobox } from '../../modules/utils';
import { IEntityBySiteComboboxOption } from '../../components/Formik/comboboxes-with-entities/EntitiesBySyteCombobox';
import { shallowEqual, useSelector } from 'react-redux';
import { getUsersComboboxList } from '../../modules/selectors/user';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

const DEFAULT_ORDER_BY = 'createdAt';
const DEFAULT_ORDER = 'desc';

const Changelog: React.FC = () => {
  const { t } = useTranslation();

  const parseData = useParseChangelogData();

  const fetchUsers = useFetchUsersCombobox();

  React.useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const users: IEntityBySiteComboboxOption[] = useSelector(
    getUsersComboboxList,
    shallowEqual,
  );

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'createdAt',
      disablePadding: false,
      label: t('changelog.createdAt'),
    },
    {
      id: 'url',
      disablePadding: false,
      label: t('changelog.url'),
    },
    {
      id: 'method',
      disablePadding: false,
      label: t('changelog.method'),
    },
    {
      id: 'initiatorEmail',
      disablePadding: false,
      label: t('changelog.initiatorEmail'),
    },
    {
      id: 'payload',
      disablePadding: false,
      disableSorting: true,
      label: t('changelog.payload'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'createdAt',
      label: t('changelog.createdAt'),
      type: 'datetimerange',
      operator: 'between',
    },
    {
      name: 'url',
      label: t('changelog.url'),
      operator: 'like',
    },
    {
      name: 'initiatorId',
      label: t('changelog.user'),
      operator: 'eq',
      type: 'combobox',
      options: users as ComboBoxOption[],
    },
  ];

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <ReportWithOwnState
          disableSelection
          reportUrl={paths.CHANGELOG}
          tableName={t('changelog.table_name')}
          headCells={headCells}
          filters={filters}
          parseData={parseData}
          defaultOrderBy={DEFAULT_ORDER_BY}
          defaultOrder={DEFAULT_ORDER}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default Changelog;
