import React from 'react';
import { isNil } from 'lodash';

import FormikTextField from '../../../components/Formik/FormikTextField';
import { AnyObject } from '../../../modules/types';
import DepartmentComboBox from '../../../components/DepartmentComboBox';
import { ComboBoxOption } from '../../../components/ComboBox';
import FormikCombobox from '../../../components/Formik/FormikCombobox';
import FormikCheckbox from '../../../components/Formik/FormikCheckbox';
import { FormFieldContainer } from '../../../components/Form/FormFieldContainer';

interface IMetatagsProps {
  name: string;
  formik: AnyObject;
  index?: number;
  settings?: {
    type: 'text' | 'combobox' | 'checkbox';
    name: string;
    title: string;
    options?: string[];
  };
}

const Metatags: React.FC<IMetatagsProps> = ({
  name,
  formik,
  index,
  settings,
}) => {
  const getInputIdentity = React.useCallback(
    (n: string) => (!isNil(index) ? `${index}.${n}` : n),
    [index],
  );

  const siteId = !isNil(index)
    ? formik.values[index].siteId
    : formik.values.siteId;

  const getSettingsOptions = React.useMemo(
    () =>
      (settings?.options?.map((i) => ({
        id: i,
        name: i,
      })) as unknown) as ComboBoxOption[],
    [settings?.options],
  );

  const renderedSettings = React.useMemo(() => {
    if (settings) {
      switch (settings.type) {
        case 'checkbox':
          return (
            <FormFieldContainer>
              <FormikCheckbox
                required
                id={getInputIdentity(settings.name)}
                formik={formik}
                label={settings.title}
              />
            </FormFieldContainer>
          );
        case 'combobox':
          return (
            <FormFieldContainer>
              <FormikCombobox
                required
                id={getInputIdentity(settings.name)}
                label={settings.title}
                placeholder={settings.title}
                options={getSettingsOptions}
                errorMode="onFieldChange"
                formik={formik}
              />
            </FormFieldContainer>
          );
        default:
          return (
            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                required // all metatags are required
                fullWidth
                id={getInputIdentity(settings.name)}
                label={settings.title}
                name={getInputIdentity(settings.name)}
                formik={formik}
                key={settings.name}
              />
            </FormFieldContainer>
          );
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.errors, getInputIdentity, settings]);

  if (['department', 'line'].includes(name.toLowerCase())) {
    return (
      <DepartmentComboBox
        {...(name.toLowerCase() === 'line'
          ? { placeholder: 'line', label: 'line' }
          : {})}
        formik={formik}
        id={getInputIdentity(name)}
        siteId={siteId}
      />
    );
  }

  return settings ? (
    renderedSettings
  ) : (
    <FormFieldContainer>
      <FormikTextField
        variant="outlined"
        required // all metatags are required
        fullWidth
        id={getInputIdentity(name)}
        label={name}
        name={getInputIdentity(name)}
        formik={formik}
        key={name}
      />
    </FormFieldContainer>
  );
};

export default Metatags;
