import { IUpdateQuestionRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { createQuestionScheme } from './create-question.scheme';

export const updateQuestionScheme: PartialSchema<IUpdateQuestionRequest[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createQuestionScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createQuestionScheme.properties,
    },
  },
};
