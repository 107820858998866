import React from 'react';
import { Box } from '@mui/joy';

interface IReportPageActionsGroupItemProps {
  children: React.ReactNode;
}

export const ReportPageActionsGroupItem = ({
  children,
}: IReportPageActionsGroupItemProps) => {
  return <Box sx={{ display: 'flex', ml: 1 }}>{children}</Box>;
};
