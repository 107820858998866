import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/joy';

import {
  TableActions,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';

import {
  AnyObject,
  IAutomaticPointsRejectReasonModel,
} from 'src/modules/types';
import { TableWithActions } from 'src/components/TableWithActions';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { ModelsToDelete } from 'src/config';
import { TableWithActionsDeleteModal } from 'src/components/TableWithActions/components/TableWithActionsDeleteModal';
import {
  useAutomaticPointsRejectReason,
  useAutomaticPointsRejectReasonFilter,
  useAutomaticPointsRejectReasonsPermissions,
  useAutomaticPointsRejectReasonTableFiltersConfiguration,
} from 'src/modules/utils';
import { AutomaticPointsRejectReasonFilter } from './components/AutomaticPointsRejectReasonFilter';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import { AutomaticPointsRejectReasonsCreate } from './AutomaticPointsRejectReasonCreate';
import { AutomaticPointsRejectReasonsUpdate } from './AutomaticPointsRejectReasonUpdate';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

const reportUrl = '/automatic-points-reject-reasons';

export const AutomaticPointsRejectReason = () => {
  const { t } = useTranslation();

  const [appliedFilters, setAppliedFilters] = React.useState<AnyObject>({});

  const exportProps = useReportExportProps(reportUrl, true);

  const {
    allowedToUpdate,
    allowedToDelete,
    allowedToCreate,
  } = useAutomaticPointsRejectReasonsPermissions();

  const [selectedItems, setSelectedItems] = React.useState<
    IAutomaticPointsRejectReasonModel[]
  >([]);

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState(false);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = React.useState(false);

  const filter = useAutomaticPointsRejectReasonFilter(appliedFilters);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    countData,
    isDataLoading,
    isCountDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
    removeDataByIds,
    fetchData,
  } = useAutomaticPointsRejectReason(
    reportUrl,
    filter!.where!,
    filter!.include!,
  );

  const filterFieldsConfiguration = useAutomaticPointsRejectReasonTableFiltersConfiguration();

  const { getLabel, getFilterCommonPropsByFilterName } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const handleFiltersSubmit = (filters: AnyObject) => {
    setAppliedFilters(filters);
  };

  const onTableRowCheckboxClick = (
    items: Array<IAutomaticPointsRejectReasonModel>,
  ) => {
    setSelectedItems(items);
  };

  const handleDelete = async () => {
    const ids = selectedItems.map((i) => +i.id!);
    await removeDataByIds(ids);
    await fetchData({
      filter,
    });
    setShowDeleteModal(false);
  };

  // handle updating
  const handleUpdating = (items: IAutomaticPointsRejectReasonModel[]) => {
    setIsUpdateFormVisible(true);
    setSelectedItems(items);
  };

  const handleCreate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsCreateFormVisible(false);
  };

  const handleUpdate = async () => {
    setSelectedItems([]);
    await fetchData({
      filter,
    });
    setIsUpdateFormVisible(false);
  };

  const ids = React.useMemo(() => selectedItems.map((i) => +i.id!), [
    selectedItems,
  ]);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: isDataLoading ? 400 : 'auto',
              height: isDataLoading || !data.length ? '100%' : 'auto',
            }}
          >
            <TableToolbar>
              <TableTitle>
                {t('automaticPointsRejectReason.menu_title')}
              </TableTitle>

              <TableActions>
                <AutomaticPointsRejectReasonFilter
                  getLabel={getLabel}
                  onFiltersFormSubmit={handleFiltersSubmit}
                  getFilterCommonPropsByFilterName={
                    getFilterCommonPropsByFilterName
                  }
                />

                <SelectHeadCells />

                <TableActionsExport
                  requestFilters={requestFilters}
                  exportBtnContainerProps={{ ml: 3 }}
                  {...exportProps}
                />

                {allowedToCreate && (
                  <ReportPageActionsGroupItem>
                    <Button onClick={() => setIsCreateFormVisible(true)}>
                      {t('automaticPointsRejectReason.create_form_title')}
                    </Button>
                  </ReportPageActionsGroupItem>
                )}
              </TableActions>
            </TableToolbar>

            <TableWithActionsDeleteModal
              open={showDeleteModal}
              onOk={handleDelete}
              onCancel={() => setShowDeleteModal(false)}
              deleteModelName={ModelsToDelete.AutomaticPointsRejectReason}
            />

            <TableWithActions<IAutomaticPointsRejectReasonModel>
              heightWithTabs
              selectedIndex="id"
              data={data as any}
              count={countData.count}
              isDataLoading={isDataLoading || isCountDataLoading}
              tableUniqueKey={reportUrl}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              limit={limit}
              page={page}
              onSort={handleSort}
              selectedItems={selectedItems}
              onRowCheckboxClick={onTableRowCheckboxClick}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={(page) => handlePageChange(page)}
              {...(allowedToUpdate
                ? {
                    updateActionConfiguration: {
                      onClick: () => {
                        handleUpdating(selectedItems);
                      },
                    },
                  }
                : {})}
              {...(allowedToDelete
                ? {
                    deleteActionConfiguration: {
                      onClick: () => setShowDeleteModal(true),
                    },
                  }
                : {})}
            />

            <AutomaticPointsRejectReasonsCreate
              isOpen={isCreateFormVisible}
              onClose={() => setIsCreateFormVisible(false)}
              onCreate={handleCreate}
            />

            <AutomaticPointsRejectReasonsUpdate
              ids={ids}
              isOpen={isUpdateFormVisible}
              onUpdate={handleUpdate}
              onClose={() => setIsUpdateFormVisible(false)}
            />
          </Box>
        </TableContextProvider>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
