import React from 'react';
import { isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IdsArray } from '../../types';
import { getMetatagListRequest } from '../../actions';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';
import { getMetatagTable } from '../../selectors/metatag';

export const metatagsInclusions = [
  {
    relation: 'site',
  },
];

export const getMetaTagsFilter = (ids?: IdsArray) => ({
  filter: {
    where: {
      id: {
        inq: ids,
      },
    },
    include: metatagsInclusions,
  },
});

/**
 * A custom hook to fetch metatags from store if they exist otherwise to make a request to fetch needed metatags from
 * the server
 */
export const useFetchMetatagsByIds = (ids?: IdsArray) => {
  const dispatcher = useDispatch();
  return () => {
    dispatcher(getMetatagListRequest(getMetaTagsFilter(ids)));
  };
};

export const useMetatagsDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteInclusion],
    }),
    [siteInclusion],
  );
};

export const useFetchMetatagsList = () => {
  const dispatcher = useDispatch();
  // fetch clientBigtableModels list from store
  const list = useSelector(getMetatagTable, shallowEqual);
  const metatagFilter = getMetaTagsFilter();

  return () => {
    if (isEmpty(list)) {
      dispatcher(getMetatagListRequest(metatagFilter));
    }
  };
};
