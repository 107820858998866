import { Editor } from 'slate';

const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element: any) =>
    ['video', 'image'].includes(element.type) ? true : isVoid(element);
  return editor;
};

export default withEmbeds;
