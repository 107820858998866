import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IPointCollectionModel } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createPointCollectionScheme } from './create-point-collection.scheme';

export const updatePointCollectionsScheme: JSONSchemaType<
  IPointCollectionModel[]
> = {
  type: 'array',
  items: {
    ...createPointCollectionScheme,
    type: 'object',
    required: ['id', 'numberOfPoints'],
    properties: {
      ...createPointCollectionScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
