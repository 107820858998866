import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AnyObject,
  IAutomaticPointsRejectReasonModel,
  ICountResponse,
  IFilterData,
  IFilterInclude,
  IFilterWhere,
} from 'src/modules/types';
import { useDataFetcherWithData } from './common/reports';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useGenerateHeadCellsData } from './table';
import { useDynamicBaseReport } from './reports.hooks';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export const useAutomaticPointsRejectReasonsPermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.automaticPointsRejectReason.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.automaticPointsRejectReason.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.automaticPointsRejectReason.delete.id,
  );

  return {
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
  };
};

export interface IAutomaticPointsRejectReasonsFilterPanelFilters {
  name?: string;
  siteId?: number;
}

export const useAutomaticPointsRejectReasonTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      name: {
        value: '',
        property: 'name',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
    }),
    [],
  );

export const useAutomaticPointsRejectReasonInclusion = () => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo(
    () => [
      {
        relation: 'site',
        scope: {
          ...(!isEmpty(selectedSites)
            ? {
                where: {
                  id: {
                    inq: selectedSites,
                  },
                },
              }
            : {}),
        },
      },
    ],
    [selectedSites],
  );
};

export const useAutomaticPointsRejectReasonComposeWhere = (
  where: Partial<IAutomaticPointsRejectReasonModel>,
) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.name
        ? {
            name: {
              like: `%${where.name}%`,
            },
          }
        : {}),
      ...(where.siteId
        ? {
            siteId: {
              eq: where.siteId,
            },
          }
        : {}),
    }),
    [where.name, where.siteId],
  );
};

export const useAutomaticPointsRejectReasonFilter = (
  appliedFilters: IAutomaticPointsRejectReasonsFilterPanelFilters,
) => {
  const _include = useAutomaticPointsRejectReasonInclusion();
  const _where = useAutomaticPointsRejectReasonComposeWhere(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};

export const useAutomaticPointsRejectReasonTableInclusionObject = () => {
  return React.useMemo(
    () => ({
      site: {
        relationType: 'inner',
      },
    }),
    [],
  );
};

export const useAutomaticPointsRejectReason = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();
  const inclusionObj = useAutomaticPointsRejectReasonTableInclusionObject();

  const initialData: AnyObject[] = [];

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'name',
        orderConfig: { orderBy: 'name' },
        label: t('automaticPointsRejectReason.name'),
      },
      {
        id: 'site.name',
        orderConfig: { orderBy: 'site.name' },
        label: t('automaticPointsRejectReason.site'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  return useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });
};
