import React from 'react';
import { CancelButton } from './CancelButton';
import { FormActionsContainer } from './FormActionsContainer';
import { SubmitButton, SubmitButtonProps } from './SubmitButton';
import { Theme } from '@mui/material';

interface IFormActionsProps extends Pick<SubmitButtonProps, 'isLoading'> {
  submitBtnTitle?: string;
  cancelBtnTitle?: string;
  onClick?: (values: any) => void;
}

export const FormActions: React.FC<IFormActionsProps> = ({
  isLoading,
  submitBtnTitle,
  cancelBtnTitle,
  onClick,
}) => {
  return (
    <FormActionsContainer>
      <CancelButton sx={{ marginRight: (theme: Theme) => theme.spacing(1.5) }}>
        {cancelBtnTitle}
      </CancelButton>
      <SubmitButton onClick={onClick} isLoading={isLoading}>
        {submitBtnTitle}
      </SubmitButton>
    </FormActionsContainer>
  );
};
