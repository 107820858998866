import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';

import {
  ICountResponse,
  ICreateOtherCosts,
  IdsArray,
  IFilter,
  IOtherCostsModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createOtherCostsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<PayloadWithFilters<ICreateOtherCosts>>): SagaIterator {
      const payloadData = {
        ...data,
        value: Number(data.value),
      };
      try {
        const response: IOtherCostsModel = yield call(
          Api.OtherCosts.create,
          payloadData,
        );
        yield put(actions.createOtherCostsSuccess(response));
        yield put(actions.getOtherCostsListRequest({ filter: filters.list }));
        yield put(actions.getOtherCostsCountRequest({ filter: filters.count }));
        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
      } catch (e) {
        yield put(actions.createOtherCostsFailed());
      }
    },
  },
  {
    action: actions.getOtherCostsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: IOtherCostsModel[] = yield call(
          Api.OtherCosts.list,
          action.payload,
        );
        yield put(actions.getOtherCostsListSuccess(response));
      } catch (e) {
        yield put(actions.getOtherCostsListFailed());
      }
    },
  },
  {
    action: actions.getOtherCostsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.OtherCosts.count,
          action?.payload,
        );
        yield put(actions.getOtherCostsCountSuccess(response));
      } catch (e) {
        yield put(actions.getOtherCostsCountFailed());
      }
    },
  },
  {
    action: actions.deleteOtherCostsRequest,
    saga: function* (
      action: ISagaAction<PayloadWithFilters<{ ids: IdsArray }>>,
    ): SagaIterator {
      const {
        filters,
        data: { ids },
      } = action.payload;
      try {
        yield call(Api.OtherCosts.delete, {
          where: { id: { inq: ids } },
        });
        yield put(actions.getOtherCostsListRequest({ filter: filters.list }));
        yield put(actions.getOtherCostsCountRequest({ filter: filters.count }));
        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
      } catch (e) {
        yield put(actions.deleteOtherCostsFailed());
      }
    },
  },
  {
    action: actions.updateOtherCostsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<PayloadWithFilters<IOtherCostsModel[]>>): SagaIterator {
      const payloadData = data.map((otherCost) => ({
        ...otherCost,
        value: Number(otherCost.value),
      }));
      try {
        yield call(Api.OtherCosts.bulkUpdate, payloadData);
        yield put(actions.getOtherCostsListRequest({ filter: filters.list }));
        yield put(
          actions.addProcessStatus({
            variant: 'success',
            title: 'common.success',
          }),
        );
        // Do we really need to change count during update?
        // yield put(actions.getOtherCostsCountRequest({ filter: filters.count }));
      } catch (e) {
        yield put(actions.updateOtherCostsFailed());
      }
    },
  },
];
