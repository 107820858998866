import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { findIndex, get, isEmpty, set } from 'lodash';
import { useFormik } from 'formik';

import { getLogtimesByIds } from '../../selectors/logtime';
import {
  useEmployeesInclusion,
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useSiteRelationInclusion,
  useValidate,
} from '.';
import { getEmployeeListRequest, getLogtimeListRequest } from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import { guessTimezone } from '../dateWrapper';
import { updateLogtimeBulksScheme } from '../../schemes/logtimes';
import { getDepartmentsComboboxListBySiteId } from '../../selectors/department';
import { getShiftsComboboxListBySiteId } from '../../selectors/shift';

import {
  ICreateLogtimeBulkRequest,
  IdsArray,
  IFilterData,
  IFilterInclude,
  IStoreState,
  IUpdateLogtimeBulkRequest,
  IWhere,
} from '../../types';
import { getEmployeesByIds } from 'src/modules/selectors/employee';
import { getUserObjectFromStorage } from '../user';
import { getCurrentRole } from '../../selectors/auth';
import { supervisorRoles } from '../../../config';
import { useTranslation } from 'react-i18next';

export const logtimesInclusions = [
  {
    relation: 'department',
  },
  {
    relation: 'shift',
  },
  {
    relation: 'staffing',
  },
  {
    relation: 'employee',
    scope: {
      include: [{ relation: 'site' }],
    },
  },
];

export const useLogtimesInclusionWithGloballySelectedSites = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => [
      {
        relation: 'department',
      },
      {
        relation: 'shift',
      },
      {
        relation: 'staffing',
      },
      {
        relation: 'employee',
        scope: {
          include: [
            {
              relation: 'supervisor',
            },
            siteInclusion,
          ],
        },
      },
    ],
    [siteInclusion],
  );
};

export const useLogtimeInclusion = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => [
      {
        relation: 'editUser',
        relationType: 'left',
        alias: 'editUser',
        scope: {
          fields: {
            id: true,
            email: true,
            firstName: true,
            lastName: true,
          },
        },
      },
      {
        relation: 'approvedUser',
        relationType: 'left',
        alias: 'approvedUser',
        scope: {
          fields: {
            id: true,
            email: true,
            firstName: true,
            lastName: true,
          },
        },
      },
      {
        relation: 'department',
      },
      {
        relation: 'shift',
      },
      {
        relation: 'staffing',
      },
      {
        relation: 'employee',
        scope: {
          include: [
            {
              relation: 'supervisor',
            },
            siteInclusion,
          ],
        },
      },
    ],
    [siteInclusion],
  );
};

export const useLogtimeSupervisorInclusion = () => {
  const include = useLogtimeInclusion();
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const { user: { id = null } = {} } = getUserObjectFromStorage();

  return React.useMemo(() => {
    const i = [...include];
    if (supervisorRoles.includes(currentRole)) {
      const employeeIndex = findIndex(
        include,
        (r) => r.relation === 'employee',
      );

      const supervisorIndex = findIndex(
        get(i, [employeeIndex, 'scope', 'include']),
        (r: IFilterInclude) => r.relation === 'supervisor',
      );

      if (employeeIndex && supervisorIndex) {
        set(
          i,
          [
            employeeIndex,
            'scope',
            'include',
            supervisorIndex,
            'scope',
            'where',
            'id',
            'eq',
          ],
          id,
        );
      }
    }

    return i;
  }, [currentRole, id, include]);
};

export const defaultSupervisorViewFilter = {
  filter: {
    include: logtimesInclusions,
    where: {
      timeOut: null,
    },
  },
};

export const logtimeComboboxFilter = {
  filter: {
    fields: {
      id: true,
      badge: true,
    },
    include: logtimesInclusions,
  },
};

/**
 * A custom hook to fetch logtimes from store if they exist otherwise to make a request to fetch needed logtimes from
 * the server
 */
export const useFetchLogtimesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch logtimes list from store
  const logtimes = useSelector(
    (state) => getLogtimesByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  useEffect(() => {
    if (isEmpty(logtimes)) {
      dispatcher(
        getLogtimeListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: logtimesInclusions,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(
    () => ({
      bulks: logtimes,
      globalFields: { timeIn: '', timeOut: '', punchType: 'regular' },
    }),
    [logtimes],
  );
};
export const useFetchEmployeesToCreateLogtimesByIds = (
  ids: IdsArray,
): ICreateLogtimeBulkRequest => {
  const dispatcher = useDispatch();
  const employeesInclusion = useEmployeesInclusion();
  // fetch em list from store
  const employees = useSelector(
    (state) => getEmployeesByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  useEffect(() => {
    if (isEmpty(employees)) {
      dispatcher(
        getEmployeeListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: employeesInclusion,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(
    () => ({
      bulks: employees.map((employee) => ({
        employeeId: Number(employee.id),
        badge: employee.badge,
        timeIn: null,
        timeOut: null,
        shiftId: null,
        departmentId: null,
        punchType: 'regular',
        timezone: guessTimezone(),
      })),
      globalFields: {
        timeIn: '',
        timeOut: '',
        punchType: 'regular',
      },
    }),
    [employees],
  );
};

export const useLogtimeSupervisorDefaultFilters = () => {
  const include = useLogtimeSupervisorInclusion();
  return () =>
    ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      limit: 25,
      offset: 0,
      include,
    } as IFilterData);
};

export const useLogtimeDefaultFilters = (where?: IWhere) => {
  const include = useLogtimeInclusion();

  return React.useMemo(
    () => ({
      where,
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [include],
  );
};

export const logtimeInitial = {
  badge: NaN,
  timeIn: null,
  timeOut: null,
  shiftId: undefined,
  departmentId: undefined,
  timezone: guessTimezone(),
  punchType: 'regular' as const,
};

export const INITIAL_ROWS = 3; // TODO: move it in config?

// helper to get initial array of values
const getInitialValues = (length: number): ICreateLogtimeBulkRequest => {
  const initial: ICreateLogtimeBulkRequest = {
    bulks: [],
    globalFields: { timeIn: '', timeOut: '', punchType: '' },
  };

  for (let i = 0; i < length; i++) {
    initial.bulks.push({ ...logtimeInitial }); // added objects should be unique
  }

  return initial;
};

export const useDepartmentsAndShiftsCombobox = (siteId: number) => {
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const wasFetched = useRef<boolean>(false);

  // make request to fetch clients and logtimes from the server if we don't have them in the store
  useEffect(() => {
    if (!siteId || wasFetched.current) {
      return;
    }

    fetchDepartmentsCombobox();
    fetchShiftsCombobox();
    wasFetched.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  // fetch departments from store
  const departments = useSelector(
    getDepartmentsComboboxListBySiteId,
    shallowEqual,
  )(siteId);

  // fetch shifts from store
  const shifts = useSelector(
    getShiftsComboboxListBySiteId,
    shallowEqual,
  )(siteId);

  return React.useMemo(
    () => ({
      departments,
      shifts,
    }),
    [departments, shifts],
  );
};

export const useLogtimesFormik = (
  initialValues: IUpdateLogtimeBulkRequest = getInitialValues(INITIAL_ROWS),
  submitHandler: (values: IUpdateLogtimeBulkRequest) => void,
) => {
  const validate = useValidate(updateLogtimeBulksScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: submitHandler,
  });

  return {
    formik,
  };
};

export const usePunchTypesOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () => [
      { id: 'regular' as any, name: t('logtimes.regular') },
      { id: 'pto' as any, name: t('logtimes.pto') },
      { id: 'holiday' as any, name: t('logtimes.holiday') },
      { id: 'retroPay' as any, name: t('logtimes.retro_pay') },
    ],
    [t],
  );
};
