import { i18n } from '../modules/utils';

// we cannot use enum here since we cannot fetch numeric values from enum correctly
export const WeekStartDay = {
  Saturday: 5,
  Sunday: 6,
  Monday: 3,
};

export const weekStartDayTranslations = {
  [WeekStartDay.Saturday]: i18n?.t('sites.startWeekDaySaturday'),
  [WeekStartDay.Sunday]: i18n?.t('sites.startWeekDaySunday'),
  [WeekStartDay.Monday]: i18n?.t('sites.startWeekDayMonday'),
};
