import React from 'react';

import { IFilterPanelOption } from 'src/components/FilterPanel';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { FilterPanelEmployeesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelEmployeesCombobox';

export interface IHistoryEmployeeFilterPanelFilters {
  employeeId?: number;
}

interface IHistoryEmployeeFilterPanelProps {
  onApplyFilters: (appliedFilters: IHistoryEmployeeFilterPanelFilters) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const HistoryEmployeeFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: IHistoryEmployeeFilterPanelProps) => {
  const [filters, setFilters] = React.useState<
    IHistoryEmployeeFilterPanelFilters
  >({});

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<{
      otherFilters: IHistoryEmployeeFilterPanelFilters;
    }>(
      (obj, filterId) => {
        obj.otherFilters[filterId] = filters[filterId];
        return obj;
      },
      {
        otherFilters: {} as IHistoryEmployeeFilterPanelFilters,
      },
    );

    onApplyFilters(appliedFiltersData.otherFilters);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'employeeId':
        return (
          <FilterPanelEmployeesCombobox
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
    }

    return null;
  };

  return (
    <ReportPageFilterPanel
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
