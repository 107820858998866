import React from 'react';
import { IFilterPanelOption } from '.';

export const availableElementsHeights = {
  small: 24,
  big: 34,
};

type ElementsHeight = typeof availableElementsHeights[keyof typeof availableElementsHeights];

interface IFilterPanelContextProviderProps {
  children: React.ReactNode;
}

export enum FilterPanelModes {
  setup,
  ready,
}

export interface IFilterPanelContext {
  filtersConfig: Array<IFilterPanelOption>;
  setFiltersConfig: React.Dispatch<
    React.SetStateAction<IFilterPanelContext['filtersConfig']>
  >;

  filterPanelMode: FilterPanelModes;
  setFilterPanelMode: React.Dispatch<
    React.SetStateAction<IFilterPanelContext['filterPanelMode']>
  >;

  appliedOptionsIds: Array<string>;
  setAppliedOptionsIds: React.Dispatch<
    React.SetStateAction<IFilterPanelContext['appliedOptionsIds']>
  >;

  editingFiltersIds: Array<string>;
  setEditingFiltersIds: React.Dispatch<
    React.SetStateAction<IFilterPanelContext['editingFiltersIds']>
  >;

  elementsHeight: ElementsHeight;
  setElementsHeight: React.Dispatch<
    React.SetStateAction<IFilterPanelContext['elementsHeight']>
  >;

  isFilterPermanent: (filterId: string) => boolean;
}

export const FilterPanelContext = React.createContext<IFilterPanelContext>({
  filterPanelMode: FilterPanelModes.ready,
  setFilterPanelMode: () => ({}),

  filtersConfig: [],
  setFiltersConfig: () => ({}),

  appliedOptionsIds: [],
  setAppliedOptionsIds: () => ({}),

  editingFiltersIds: [],
  setEditingFiltersIds: () => ({}),

  elementsHeight: availableElementsHeights.big,
  setElementsHeight: () => NaN,

  isFilterPermanent: () => false,
});

export const FilterPanelContextProvider = ({
  children,
}: IFilterPanelContextProviderProps) => {
  const [filtersConfig, setFiltersConfig] = React.useState<
    Array<IFilterPanelOption>
  >([]);
  const [filterPanelMode, setFilterPanelMode] = React.useState<
    IFilterPanelContext['filterPanelMode']
  >(FilterPanelModes.ready);

  const [appliedOptionsIds, setAppliedOptionsIds] = React.useState<
    IFilterPanelContext['appliedOptionsIds']
  >([]);

  const [editingFiltersIds, setEditingFiltersIds] = React.useState<
    IFilterPanelContext['editingFiltersIds']
  >([]);

  const [elementsHeight, setElementsHeight] = React.useState<ElementsHeight>(
    availableElementsHeights.big,
  );

  const isFilterPermanent = React.useCallback(
    (filterId: string) => {
      return filtersConfig.some(
        (item) => item.id === filterId && item.isPermanent,
      );
    },
    [filtersConfig],
  );

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.setup) {
      setElementsHeight(34);
    } else {
      setElementsHeight(24);
    }
  }, [filterPanelMode]);

  return (
    <FilterPanelContext.Provider
      value={{
        filtersConfig,
        setFiltersConfig,
        filterPanelMode,
        setFilterPanelMode,
        appliedOptionsIds,
        setAppliedOptionsIds,
        editingFiltersIds,
        setEditingFiltersIds,
        elementsHeight,
        setElementsHeight,
        isFilterPermanent,
      }}
    >
      {children}
    </FilterPanelContext.Provider>
  );
};
