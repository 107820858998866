import React from 'react';
import FormControl, { FormControlProps } from '@mui/joy/FormControl';
import FormLabel, { FormLabelProps } from '@mui/joy/FormLabel';
import FormHelperText, { FormHelperTextProps } from '@mui/joy/FormHelperText';
import { CheckboxProps, Typography, Checkbox as JoyCheckbox } from '@mui/joy';

export interface ICheckboxProps extends CheckboxProps {
  label?: string;
  helperText?: string;
  helperTextProps?: Omit<FormHelperTextProps, 'children'>;
  labelProps?: Omit<FormLabelProps, 'label'>;
  formControlProps?: FormControlProps;
}

export const Checkbox = ({
  label,
  helperText,
  labelProps,
  helperTextProps,
  formControlProps,
  ...checkboxProps
}: ICheckboxProps) => {
  return (
    <FormControl {...formControlProps}>
      {label && (
        <FormLabel {...labelProps}>
          <Typography
            level="text_sm"
            fontWeight="medium"
            textColor="colors.text.text_secondary.main"
          >
            {label}
          </Typography>
        </FormLabel>
      )}
      <JoyCheckbox {...checkboxProps} />
      {helperText && (
        <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
