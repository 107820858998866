import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateDepartmentRequest,
  IDepartmentModel,
  IdsArray,
  IFilter,
  IUpdateDepartment,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushDepartmentState = createAction(
  'Flush department data in store',
);

// Creation actions
export const createDepartmentRequest = createAction<
  PayloadWithFilters<ICreateDepartmentRequest>
>('Make request to create a new department');
export const createDepartmentSuccess = createAction<IDepartmentModel>(
  'New department has been created successfully',
);
export const createDepartmentFailed = createAction(
  'Cannot create a new department',
);

// Fetch departments actions
export const getDepartmentListRequest = createAction<IFilter>(
  'Make request to fetch department list',
);
export const getDepartmentListSuccess = createAction<IDepartmentModel[]>(
  'Departments list has been fetched successfully',
);
export const getDepartmentListFailed = createAction(
  'Cannot fetch department list',
);

// get departments count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getDepartmentCountRequest = createAction<IFilter>(
  'Make request to get department count',
);
export const getDepartmentCountSuccess = createAction<ICountResponse>(
  'Departments count has been fetched successfully',
);
export const getDepartmentCountFailed = createAction(
  'Cannot get department count',
);

// delete actions
export const deleteDepartmentRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete departments');
export const deleteDepartmentSuccess = createAction(
  'Departments successfully deleted',
);
export const deleteDepartmentFailed = createAction('Cannot delete departments');

// Updating actions
export const updateDepartmentsRequest = createAction<
  PayloadWithFilters<IUpdateDepartment[]>
>('Make request to update departments');
export const updateDepartmentsSuccess = createAction(
  'Departments successfully updated',
);
export const updateDepartmentsFailed = createAction(
  'Cannot update departments',
);

// UpdateDesiredEmployeesNumber actions
export const updateDepartmentsDesiredEmployeesNumberRequest = createAction<
  PayloadWithFilters<Pick<IUpdateDepartment, 'desiredEmployeesNumber'>[]>
>('Make request to update UpdateDesiredEmployeesNumber');
export const updateDepartmentsDesiredEmployeesNumberSuccess = createAction(
  'UpdateDesiredEmployeesNumber successfully updated',
);
export const updateDepartmentsDesiredEmployeesNumberFailed = createAction(
  'Cannot update UpdateDesiredEmployeesNumber',
);

// Fetch combobox list actions
export const getDepartmentComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of departments',
);
export const getDepartmentComboboxListSuccess = createAction<
  Partial<IDepartmentModel>[]
>('Combobox list of departments has been fetched successfully');
export const getDepartmentComboboxListFailed = createAction(
  'Cannot fetch combobox departments list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Department').takeExportActions();

export const exportDepartmentRequest = exportReportRequest;
export const exportDepartmentSuccess = exportReportSuccess;
export const exportDepartmentFailed = exportReportFailed;
