import { createAction } from 'redux-act';
import { IExportParams } from '../../../../types';

/**
 * ExportActionCreator will be used for generating export actions
 */
export class ExportActionCreator {
  private readonly prefix: string = '';

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  /**
   * Generates export actions
   */
  takeExportActions() {
    const exportReportRequest = createAction<IExportParams>(
      `Make request to export ${this.prefix} or send it by email`,
    );

    const exportReportSuccess = createAction(
      `${this.prefix} successfully exported`,
    );

    const exportReportFailed = createAction(
      `Export of ${this.prefix} report failed`,
    );

    return {
      exportReportRequest,
      exportReportSuccess,
      exportReportFailed,
    };
  }
}
