import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/joy';
import { get, map, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { IdsArray, IShiftModel, IUpdateShift } from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { Api } from 'src/modules/utils';
import { updateShiftsScheme } from 'src/modules/schemes/shift/update-shifts.scheme';
import FormikTimepicker from 'src/components/Formik/FormikTimepicker';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { ifSiteHasLunch } from 'src/modules/selectors/site';

interface IShiftUpdateProps {
  isOpen: boolean;
  ids: IdsArray;
  onUpdate: () => void;
  onClose: () => void;
}

export const ShiftsUpdate: React.FC<IShiftUpdateProps> = ({
  isOpen,
  ids,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const hasLunch = useSelector(ifSiteHasLunch);

  const [initialValues, setInitialValues] = React.useState<Array<IShiftModel>>(
    [],
  );

  React.useEffect(() => {
    if (isOpen) {
      Api.Shift.list({
        filter: { where: { id: { inq: ids } } },
      }).then((data) =>
        setInitialValues(
          data.map((i) => {
            const lunch = hasLunch(get(i, 'siteId'));

            return omit(i, [
              'duration',
              ...(!lunch ? ['lunchAdj', 'lunchMin'] : []),
            ]) as IShiftModel;
          }),
        ),
      );
    }
  }, [hasLunch, ids, isOpen]);

  const validate = useValidate(updateShiftsScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IUpdateShift[]) => {
      await Api.Shift.bulkUpdate(data);
      formik.resetForm();
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('shifts.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        {map(formik.values, (incentivePrice, index) => (
          <DrawerBodyContent key={incentivePrice.id}>
            <FormFieldContainer>
              <FormikTextField
                id={`${index}.name`}
                name={`${index}.name`}
                formik={formik}
                required={true}
                variant="outlined"
                fullWidth={true}
                label={t('shifts.name')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SitesComboBox
                id={`${index}.siteId`}
                required={true}
                formik={formik}
                label={t('shifts.site_name')}
                placeholder={t('shifts.site_name')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                id={`${index}.desiredEmployeesNumber`}
                name={`${index}.desiredEmployeesNumber`}
                formik={formik}
                variant="outlined"
                fullWidth={true}
                label={t('shifts.desiredEmployeesNumber')}
                type="number"
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTimepicker
                id={`${index}.startTime`}
                formik={formik}
                required={true}
                label={t('shifts.start_time')}
                fullWidth={true}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTimepicker
                id={`${index}.endTime`}
                formik={formik}
                required={true}
                label={t('shifts.end_time')}
                fullWidth={true}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id={`${index}.overnight`}
                formik={formik}
                label={t('shifts.overnight')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id={`${index}.default`}
                formik={formik}
                label={t('shifts.default')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                id={`${index}.delay`}
                name={`${index}.delay`}
                formik={formik}
                variant="outlined"
                fullWidth={true}
                label={t('shifts.delay')}
                type="number"
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            {hasLunch(formik.values[index].siteId) && (
              <>
                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.lunchMin`}
                    name={`${index}.lunchMin`}
                    formik={formik}
                    variant="outlined"
                    fullWidth={true}
                    label={t('shifts.lunchMin')}
                    type="number"
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`${index}.lunchAdj`}
                    name={`${index}.lunchAdj`}
                    formik={formik}
                    variant="outlined"
                    fullWidth={true}
                    label={t('shifts.lunchAdj')}
                    type="number"
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>
              </>
            )}
          </DrawerBodyContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
