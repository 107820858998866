import { SagaIterator } from 'redux-saga';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';

import {
  Api,
  getMappedPricingTypes,
  getMetaTagsFilter,
  getPricingFilter,
  getSiteComboboxFilter,
  pricingInclusions,
} from '../utils';
import * as actions from '../actions';
import { createExportSaga } from '../utils/helpers/creators/export';
import { paths } from '../../config';

import {
  getGloballySelectedSites,
  getSitesComboboxList,
} from '../selectors/site';
import { getPricingMetaTags, getPricingTypesList } from '../selectors/pricing';
import { fetchMetaTagListRequestSaga } from './metatag.sagas';
import { ROWS_PER_PAGE_DEFAULT } from '../../components/EnhancedTable';

import {
  ICreatePricingSingleRequest,
  IFilter,
  ISagaAction,
  IPricingDeleteRequest,
  IPricingModel,
  IPricingWithMeta,
  ISagaActionBind,
  ICreatePricingRangeRequest,
  ICreatePricingRangeApiRequest,
  IUpdatePricingRangeRequest,
  IUpdatePricingRangeRequestAction,
  IUpdatePricingRangeWithoutMeta,
  IPricingRangeDeleteRequest,
  IMetatagModel,
  IWhere,
  PayloadWithNavigateFunc,
} from '../types';
import { AnyObject } from '../../modules/types';

/**
 * Get Pricing types
 */
export const getPricingTypesSaga = function* (): SagaIterator {
  const pricingTypesList = yield select(getPricingTypesList);
  if (!isEmpty(pricingTypesList)) {
    return;
  }

  const globallySelectedSiteIds = yield select(getGloballySelectedSites);
  const sitePricingTypesFilter = yield call(
    getPricingFilter,
    globallySelectedSiteIds,
  );
  try {
    const pricingTypes = yield call([Api.Site, 'list'], sitePricingTypesFilter);
    const mappedPricingTypes = yield call(getMappedPricingTypes, pricingTypes);
    yield put(actions.setPricingTypesList(mappedPricingTypes));
  } catch (e) {
    console.error('Error in initPricingFetchSaga', (e as AnyObject).message);
  }
};

/**
 * Get Sites Combobox
 */
export const getSitesComboboxSaga = function* (): SagaIterator {
  const sites = yield select(getSitesComboboxList);
  if (!isEmpty(sites)) {
    return;
  }

  const globallySelectedSiteIds = yield select(getGloballySelectedSites);

  const siteComboboxFilter = yield call(
    getSiteComboboxFilter,
    globallySelectedSiteIds,
  );
  yield put(actions.getSiteComboboxListRequest(siteComboboxFilter));
};

/**
 * Get Meta Tags
 */
export const getPricingMetaTagsSaga = function* (): SagaIterator {
  const metaTags = yield select(getPricingMetaTags);
  if (!isEmpty(metaTags)) {
    return;
  }

  try {
    const response: IMetatagModel[] = yield call(
      fetchMetaTagListRequestSaga,
      getMetaTagsFilter(),
    );
    yield put(actions.setPricingMetaTags(response));
  } catch (e) {
    console.error('Error in getPricingMetaTags', (e as AnyObject).message);
  }

  const globallySelectedSiteIds = yield select(getGloballySelectedSites);

  const siteComboboxFilter = yield call(
    getSiteComboboxFilter,
    globallySelectedSiteIds,
  );
  yield put(actions.getSiteComboboxListRequest(siteComboboxFilter));
};

/**
 * Create a new pricing
 */
export const createPricingSingleRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreatePricingSingleRequest>
>): SagaIterator {
  const payloadData = {
    ...data,
    price: Number(data.price),
    incentivePrice: Number(data.incentivePrice),
    overtimePrice: Number(data.overtimePrice),
  };
  try {
    const response: IPricingModel = yield call(
      Api.Pricing.createSingle,
      payloadData,
    );
    yield put(actions.flushPricingState());
    yield put(actions.createPricingSingleSuccess(response));
    yield call(navigate, { pathname: '/pricing' });
  } catch (e) {
    yield put(actions.createPricingSingleFailed());
  }
};

/**
 * Create new pricing range
 */
export const createPricingRangeRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreatePricingRangeRequest>
>): SagaIterator {
  const { bulks, siteId, ...metatags } = data;
  const payload: ICreatePricingRangeApiRequest = bulks.reduce(
    (acc, cur, index, array): ICreatePricingRangeApiRequest => {
      const {
        price,
        incentivePrice,
        overtimePrice,
        minUph,
        maxUph,
        description,
      } = cur;
      const item: ICreatePricingSingleRequest = {
        siteId,
        price: +price,
        incentivePrice: +incentivePrice,
        overtimePrice: +overtimePrice,
        minUph: index === 0 ? minUph : array[index - 1].maxUph,
        maxUph,
        ...metatags,
      };
      if (description) {
        item.description = description;
      }
      acc.push(item);
      return acc;
    },
    ([] as unknown) as ICreatePricingRangeApiRequest,
  );
  try {
    const response: IPricingModel[] = yield call(
      Api.Pricing.createRange,
      payload,
    );
    yield put(actions.flushPricingState());
    yield put(actions.createPricingRangeSuccess(response));
    yield call(navigate, { pathname: '/pricing' });
  } catch (e) {
    yield put(actions.createPricingRangeFailed());
  }
};

/**
 * Get pricing list
 */
export const getPricingListRequestSaga = function* (
  action: ISagaAction<{
    siteId: number;
    filter: IFilter;
  }>,
): SagaIterator {
  try {
    const response: IPricingWithMeta = yield call(
      Api.Pricing.list,
      action.payload.siteId,
      action.payload.filter,
    );
    yield put(
      actions.getPricingListSuccess({
        data: response,
        siteId: action.payload.siteId,
      }),
    );
  } catch (e) {
    yield put(actions.getPricingListFailed());
  }
};

/**
 * Init fetch for Pricing Meta Tags Form
 */
export const initPricingFetchSaga = function* (
  action: ISagaAction<{ siteId: number; where?: IWhere }>,
): SagaIterator {
  const { siteId, where = {} } = action.payload;
  yield all([
    call(getPricingTypesSaga),
    call(getSitesComboboxSaga),
    call(getPricingMetaTagsSaga),
  ]);

  if (siteId) {
    yield call(
      getPricingListRequestSaga,
      actions.getPricingListRequest({
        siteId,
        filter: {
          filter: {
            limit: ROWS_PER_PAGE_DEFAULT,
            offset: 0,
            include: pricingInclusions,
            where,
          },
        },
      }),
    );
  }
};

export const clearPricingListByIdSaga = function* (
  action: ISagaAction<number>,
): SagaIterator {
  yield put(
    actions.getPricingListSuccess({
      siteId: action.payload,
    }),
  );
};

/**
 * Delete pricing
 */
export const deletePricingRequestSaga = function* (
  action: ISagaAction<IPricingDeleteRequest>,
): SagaIterator {
  try {
    const { ids } = action.payload;
    yield call(Api.Pricing.delete, {
      where: { id: { inq: ids } },
    });
    yield put(actions.flushPricingState());
  } catch (e) {
    yield put(actions.deletePricingFailed());
  }
};

export const deletePricingRangeRequestSaga = function* (
  action: ISagaAction<IPricingRangeDeleteRequest>,
): SagaIterator {
  try {
    const { siteId, metaTags } = action.payload;
    const whereMetatags = Object.entries(metaTags).reduce((acc, cur) => {
      const [index, value] = cur;
      acc[index] = { eq: value };
      return acc;
    }, {});
    yield call(Api.Pricing.deleteRange, +siteId, {
      ...whereMetatags,
    });
    yield put(actions.flushPricingState());
  } catch (e) {
    yield put(actions.deletePricingRangeFailed());
  }
};

/**
 * Bulk update pricing
 */
export const updatePricingRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IPricingModel[]>>): SagaIterator {
  const payloadData = data.map((item) => ({
    ...item,
    price: Number(item.price),
    incentivePrice: Number(item.incentivePrice),
    overtimePrice: Number(item.overtimePrice),
  }));
  try {
    yield call(Api.Pricing.bulkUpdate, payloadData);
    yield put(actions.flushPricingState());
    yield call(navigate, { pathname: paths.PRICING });
  } catch (e) {
    yield put(actions.updatePricingSingleFailed());
  }
};

export const updatePricingRangeRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IUpdatePricingRangeRequestAction>
>): SagaIterator {
  const { bulks, siteId, metatags } = data;
  const payload = reduce<
    IUpdatePricingRangeWithoutMeta,
    IUpdatePricingRangeRequest[]
  >(
    bulks,
    (acc, cur, index, array) => {
      const { id, price, incentivePrice, overtimePrice, minUph, maxUph } = cur;
      const item = {
        id,
        siteId: +siteId,
        price: +price,
        incentivePrice: +incentivePrice,
        overtimePrice: +overtimePrice,
        minUph: index === 0 ? minUph : array[index - 1].maxUph,
        maxUph,
        ...metatags,
      };
      acc.push(item);
      return acc;
    },
    [],
  );
  try {
    yield call(Api.Pricing.updateRange, payload);
    yield put(actions.flushPricingState());
    yield call(navigate, { pathname: paths.PRICING });
  } catch (e) {
    yield put(actions.updatePricingRangeFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.initPricingFetch,
    saga: initPricingFetchSaga,
  },
  {
    action: actions.clearPricingListById,
    saga: clearPricingListByIdSaga,
  },
  {
    action: actions.exportPricingRequest,
    saga: createExportSaga({
      apiCall: Api.Pricing.export,
      actionSuccess: actions.exportPricingSuccess,
      actionFailed: actions.exportPricingFailed,
    }),
  },
  {
    action: actions.createPricingSingleRequest,
    saga: createPricingSingleRequestSaga,
  },
  {
    action: actions.createPricingRangeRequest,
    saga: createPricingRangeRequestSaga,
  },
  {
    bindFunc: takeEvery,
    action: actions.getPricingListRequest,
    saga: getPricingListRequestSaga,
  },
  { action: actions.deletePricingRequest, saga: deletePricingRequestSaga },
  {
    action: actions.deletePricingRangeRequest,
    saga: deletePricingRangeRequestSaga,
  },
  // bulk updating pricing
  {
    action: actions.updatePricingSingleRequest,
    saga: updatePricingRequestSaga,
  },
  {
    action: actions.updatePricingRangeRequest,
    saga: updatePricingRangeRequestSaga,
  },
];
