import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { createSkillRequest } from '../../modules/actions';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import {
  useBrowserHistoryFunctions,
  useValidate,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';

import { createSkillScheme } from '../../modules/schemes/skills';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { ICreateSkillRequest, SkillTerm } from '../../modules/types';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const initialValues: ICreateSkillRequest = {
  name: '',
  siteId: NaN,
  skillTerm: 'expiration', // 'expiration' | 'permanent'
  expirationPeriod: null,
};

export const isExpiration = (value: SkillTerm) => value === 'expiration';
export const isPermanent = (value: SkillTerm) => value === 'permanent';

const SkillsCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  // create dispatcher
  const dispatcher = useDispatch();

  const validate = useValidate(createSkillScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: ({ expirationPeriod, name, siteId, skillTerm }) => {
      const sendData = {
        expirationPeriod,
        name,
        siteId,
        hasExpiration: isExpiration(skillTerm),
        isPermanent: isPermanent(skillTerm),
      };
      dispatcher(createSkillRequest({ data: sendData, navigate }));
    },
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('skills.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id="name"
              label={t('skills.name')}
              name="name"
              autoComplete="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('skills.site')}
              placeholder={t('skills.site')}
            />
          </FormFieldContainer>

          <Box pt={3}>
            <FormFieldContainer>
              <RadioGroup
                aria-label="skillTerm"
                name="skillTerm"
                value={formik.values.skillTerm}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="expiration"
                  control={<Radio />}
                  label={t('emp_skills.has_expiration_date')}
                />

                <FormControlLabel
                  value="permanent"
                  control={<Radio />}
                  label={t('emp_skills.permanent')}
                />
              </RadioGroup>
            </FormFieldContainer>
          </Box>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="expirationPeriod"
              name="expirationPeriod"
              label={t('emp_skills.expiration_period')}
              formik={formik}
              type="number"
              required={isExpiration(formik.values.skillTerm)}
              disabled={isPermanent(formik.values.skillTerm)}
              // required={formik.values.hasExpiration}
              // disabled={!formik.values.hasExpiration || formik.values.isPermanent}
            />
          </FormFieldContainer>

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default SkillsCreate;
