import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getCostPlusSettingsTypesRequest,
  getCostPlusSettingsListRequest,
} from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import {
  getCostPlusTypes,
  getCostPlusSettingsByIds,
} from '../../selectors/cost-plus-settings.selector';
import { useSiteRelationInclusion } from './sites';

export const useCostPlusSettingsInclusion = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => [siteRelationInclusion], [siteRelationInclusion]);
};

/**
 * A custom hook to fetch costPlusSettings from store if they exist otherwise to make a request to fetch needed costPlusSettings from
 * the server
 */
export const useFetchCostPlusSettingsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch costPlusSettings list from store
  const costPlusSettings = useSelector(
    (state) => getCostPlusSettingsByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  const costPlusSettingsInclusions = useCostPlusSettingsInclusion();

  return () => {
    if (isEmpty(costPlusSettings)) {
      dispatcher(
        getCostPlusSettingsListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: costPlusSettingsInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch costPlusSettings types from store if they exist otherwise to make a request to fetch needed costPlusSettings types from
 * the server
 */
export const useFetchCostPlusTypes = () => {
  const dispatcher = useDispatch();
  // fetch types list from store
  const types = useSelector(getCostPlusTypes, shallowEqual);
  return () => {
    if (isEmpty(types)) {
      dispatcher(getCostPlusSettingsTypesRequest());
    }
  };
};

export const useCostPlusSettingsDefaultFilters = () => {
  const costPlusSettingsInclusions = useCostPlusSettingsInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: costPlusSettingsInclusions,
    }),
    [costPlusSettingsInclusions],
  );
};
