import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from 'src/components/EnhancedTable';
import {
  deletePositionRequest,
  exportPositionRequest,
  getPositionCountRequest,
  getPositionListRequest,
} from 'src/modules/actions';
import {
  getPositionCount,
  getPositionList,
  getPositionRefreshKey,
} from 'src/modules/selectors/position';
import { ITableFilter } from 'src/components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from 'src/modules/types';
import { useTranslation } from 'react-i18next';
import {
  useSearch,
  useCreateExportProps,
  useFilter,
  usePositionsDefaultFilters,
  useBrowserHistoryFunctions,
} from 'src/modules/utils';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { PositionsUpdateEmployeesNumber } from './components/PositionsUpdateEmployeesNumber';
import { ICustomAction } from 'src/components/EnhancedTable/EnhancedTableSelectedItemsActions';
import { ScreenUsers } from 'src/components/svgIcons';

const PositionsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.position.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.position.update.id,
  );
  const allowedToUpdateDesiredEmployeesNumber = useHasUserAccessToAction(
    manageEntitiesConfig.position.updateDesiredEmployeesNumber.id,
  );

  const [
    isPositionUpdateEmployeesNumberVisible,
    setIsPositionUpdateEmployeesNumberVisible,
  ] = React.useState(false);

  const [positionIds, setPositionIds] = React.useState<IdsArray>([]);

  const defaultFilter = usePositionsDefaultFilters();
  // fetch position list
  const list = useSelector(getPositionList, shallowEqual);
  const exportProps = useCreateExportProps(exportPositionRequest);
  const count = useSelector(getPositionCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('positions.name') },
    {
      id: 'desiredEmployeesNumber',
      disablePadding: false,
      label: t('positions.desiredEmployeesNumber'),
    },
    { id: 'site.name', disablePadding: false, label: t('positions.site') },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('common.name'),
      operator: 'like',
    },
    {
      name: 'desiredEmployeesNumber',
      label: t('positions.desiredEmployeesNumber'),
      operator: 'eq',
    },
    {
      name: 'site.id',
      label: t('boms.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
  ];

  // make request to fetch positions when component is mounted
  useEffect(() => {
    dispatcher(getPositionListRequest(filterList));
    // get total count
    dispatcher(getPositionCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getPositionListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getPositionCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deletePositionRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: '/positions/update', search });
  };

  const customActions: Array<ICustomAction> = [];

  if (allowedToUpdateDesiredEmployeesNumber) {
    customActions.push({
      title: t('departments.updateDesiredEmployeesNumber'),
      icon: <ScreenUsers />,
      onClick: (ids: IdsArray) => {
        setPositionIds(ids);
        setIsPositionUpdateEmployeesNumberVisible(true);
      },
    });
  }

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={list}
          count={count}
          selectIndex="id"
          tableName={t('positions.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          customActions={customActions}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          include={defaultFilter.include}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.Position}
        />

        <>
          <PositionsUpdateEmployeesNumber
            entitiesToUpdateIds={positionIds}
            isOpen={isPositionUpdateEmployeesNumberVisible}
            onClose={() => setIsPositionUpdateEmployeesNumberVisible(false)}
            filterCount={filterCount.filter ?? {}}
            filterList={filterList.filter ?? {}}
          />
        </>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function PositionsListRefreshable() {
  return <PositionsList key={useSelector(getPositionRefreshKey)} />;
}
