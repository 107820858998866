import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateSiteBillingDetails,
  IdsArray,
  IFilter,
  ISiteBillingDetailsModel,
  IUpdateSiteBillingDetails,
  PayloadWithNavigateFunc,
} from '../types';

// Flush actions
export const flushSiteBillingDetailsState = createAction(
  'Flush SiteBillingDetails data in store',
);

// Creation actions
export const createSiteBillingDetailsRequest = createAction<
  PayloadWithNavigateFunc<ICreateSiteBillingDetails>
>('Make request to create a new SiteBillingDetails');
export const createSiteBillingDetailsSuccess = createAction<
  ISiteBillingDetailsModel
>('New SiteBillingDetails has been created successfully');
export const createSiteBillingDetailsFailed = createAction(
  'Cannot create a new SiteBillingDetails',
);
// Bulk Creation actions
export const createBulkSiteBillingDetailsRequest = createAction<
  PayloadWithNavigateFunc<ICreateSiteBillingDetails[]>
>('Make request to create a new SiteBillingsDetails');
export const createBulkSiteBillingDetailsSuccess = createAction<
  ISiteBillingDetailsModel
>('New SiteBillingsDetails has been created successfully');
export const createBulkSiteBillingDetailsFailed = createAction(
  'Cannot create a new SiteBillingsDetails',
);

// Fetch SiteBillingDetails actions
export const getSiteBillingDetailsListRequest = createAction<IFilter>(
  'Make request to fetch SiteBillingDetails list',
);
export const getSiteBillingDetailsListSuccess = createAction<
  ISiteBillingDetailsModel[]
>('SiteBillingDetails list has been fetched successfully');
export const getSiteBillingDetailsListFailed = createAction(
  'Cannot fetch SiteBillingDetails list',
);

// get SiteBillingDetails count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getSiteBillingDetailsCountRequest = createAction<IFilter>(
  'Make request to get SiteBillingDetails count',
);
export const getSiteBillingDetailsCountSuccess = createAction<ICountResponse>(
  'SiteBillingDetails count has been fetched successfully',
);
export const getSiteBillingDetailsCountFailed = createAction(
  'Cannot get SiteBillingDetails count',
);

// delete actions
export const deleteSiteBillingDetailsRequest = createAction<IdsArray>(
  'Make request to delete SiteBillingDetails',
);
export const deleteSiteBillingDetailsFailed = createAction(
  'Cannot delete SiteBillingDetails',
);

// Updating actions
export const updateSiteBillingDetailsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateSiteBillingDetails[]>
>('Make request to update SiteBillingDetails');
export const updateSiteBillingDetailsFailed = createAction(
  'Cannot update SiteBillingDetails',
);
