import React from 'react';
import Tab, { TabProps } from '@mui/joy/Tab';
import { MenuItem } from '@mui/joy';
import { useIsTabsOnSmallDevice } from './pageContentWithTabs.constants';

interface IPageContentTabProps extends Omit<TabProps, 'slotProps'> {
  children: React.ReactNode;
}

export const PageContentTab = ({
  children,
  ...props
}: IPageContentTabProps) => {
  const isSmallDevice = useIsTabsOnSmallDevice();

  return (
    <Tab
      {...props}
      slotProps={{
        root: {
          sx: {
            width: isSmallDevice ? '100%' : 'auto',
            alignSelf: 'center',
            borderRadius: 'sm',
            ['&:after']: { display: 'none' },
            ['&.Mui-selected']: {
              bgcolor: 'colors.utility.brand.utility_brand_100',
            },
          },
        },
      }}
    >
      {isSmallDevice && <MenuItem>{children}</MenuItem>}

      {!isSmallDevice && children}
    </Tab>
  );
};
