import React, { useEffect, useMemo } from 'react';
import { filter } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { createApplicantRequest } from '../../modules/actions';
import {
  useFetchOpenPositionsCombobox,
  useFetchStaffingProvidersCombobox,
  useValidate,
} from '../../modules/utils/hooks';
import { getCurrentRole } from '../../modules/selectors/auth';
import { createApplicantScheme } from '../../modules/schemes';
import { getOpenPositionsComboboxList } from '../../modules/selectors/openPosition';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import { useApplicantUpdateFormTourConfig } from '../../config/tours/applicant';
import { omitIfNull } from '../../modules/utils/helpers/filter';

import CreateFormLayout, {
  CreateFormHandleSubmit,
} from '../../components/Layout/CreateFormLayout';
import { ComboBoxOption } from '../../components/ComboBox';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import FormikTextField from '../../components/Formik/FormikTextField';
import FormikDatepicker from '../../components/Formik/FormikDatepicker';
import FormikCheckbox from '../../components/Formik/FormikCheckbox';
import { FormActions } from '../../components/Form/FormActions';
import SupervisorComboBox from '../../components/Formik/comboboxes-with-entities/SupervisorComboBox';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';

import { ICreateApplicant } from '../../modules/types';
import { useNavigate } from 'react-router-dom';
import FormikTextarea from 'src/components/Formik/FormikTextarea';

const ApplicantCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialValues: ICreateApplicant = {
    firstName: '',
    lastName: '',
    openPositionId: 0,
    payRate: 0,
    dateInterview: null,
    dateStart: '',
    badge: 0,
    supervisorId: 0,
    siteId: 0,
    staffingId: 0,
  };

  const fetchOpenPositionCombobox = useFetchOpenPositionsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const validate = useValidate<ICreateApplicant>(createApplicantScheme);

  // create dispatcher
  const dispatcher = useDispatch();

  const formik = useFormik<ICreateApplicant>({
    initialValues,
    validate,
    onSubmit: (data) => {
      const optionalFields: Array<keyof ICreateApplicant> = ['dateInterview'];
      const sendData = omitIfNull(data, optionalFields);

      dispatcher(createApplicantRequest({ data: sendData, navigate }));
    },
  });

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchOpenPositionCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const restSelectors = {
    openPositions: useSelector(getOpenPositionsComboboxList),
  };

  const openPositions = useMemo(
    () =>
      filter(
        restSelectors.openPositions,
        (i) => i.siteId === formik.values.siteId && !i.closed,
      ),
    [restSelectors.openPositions, formik.values.siteId],
  );
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  const applicantCreateTourConfig = useApplicantUpdateFormTourConfig();

  return (
    <CreateFormLayout
      cardTitle={t('applicant.create_form_card_title')}
      handleFormSubmit={formik.handleSubmit as CreateFormHandleSubmit}
      fields={formik.values}
      isFormValid={formik.isValid}
    >
      <SitesComboBox
        id="siteId"
        required={true}
        formik={formik}
        placeholder={t('applicant.siteId')}
        data-tour={applicantCreateTourConfig.tour.site.target}
      />

      <FormikTextField
        id="firstName"
        name="firstName"
        required={true}
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('applicant.firstName')}
        data-tour={applicantCreateTourConfig.tour.firstName.target}
      />

      <FormikTextField
        id="lastName"
        name="lastName"
        required={true}
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('applicant.lastName')}
        data-tour={applicantCreateTourConfig.tour.lastName.target}
      />

      <FormikCombobox
        id="openPositionId"
        required={true}
        formik={formik}
        placeholder={t('applicant.openPositionId')}
        options={openPositions as ComboBoxOption[]}
        data-tour={applicantCreateTourConfig.tour.openPosition.target}
      />

      <FormikTextField
        id="payRate"
        name="payRate"
        required={true}
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('applicant.payRate')}
        type="number"
        data-tour={applicantCreateTourConfig.tour.payRate.target}
      />

      <FormikDatepicker
        id="dateInterview"
        formik={formik}
        label={t('applicant.dateInterview')}
        fullWidth={true}
        data-tour={applicantCreateTourConfig.tour.dateInterview.target}
      />

      <FormikDatepicker
        id="dateStart"
        formik={formik}
        required={true}
        label={t('applicant.dateStart')}
        fullWidth={true}
        data-tour={applicantCreateTourConfig.tour.dateStart.target}
      />

      <FormikTextField
        id="badge"
        name="badge"
        required={true}
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('applicant.badge')}
        type="number"
        data-tour={applicantCreateTourConfig.tour.badge.target}
      />

      <SupervisorComboBox
        id="supervisorId"
        required={true}
        formik={formik}
        placeholder={t('applicant.supervisorId')}
        data-tour={applicantCreateTourConfig.tour.supervisor.target}
      />

      <FormikCombobox
        id="staffingId"
        required={true}
        formik={formik}
        placeholder={t('applicant.staffingId')}
        options={staffingProviders as ComboBoxOption[]}
        data-tour={applicantCreateTourConfig.tour.staffingProvider.target}
      />

      <FormikCheckbox
        id="I9"
        formik={formik}
        label={t('applicant.I9')}
        data-tour={applicantCreateTourConfig.tour.I9.target}
      />

      <FormikCheckbox
        id="bgCheck"
        formik={formik}
        label={t('applicant.bgCheck')}
        data-tour={applicantCreateTourConfig.tour.backgroundCheck.target}
      />

      <FormikTextarea
        id="interviewNotes"
        name="interviewNotes"
        formik={formik}
        variant="outlined"
        label={t('applicant.interviewNotes')}
        minRows={2}
        data-tour={applicantCreateTourConfig.tour.interviewNotes.target}
      />

      <FormikTextField
        id="phonenumber"
        name="phonenumber"
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('applicant.phonenumber')}
        data-tour={applicantCreateTourConfig.tour.phoneNumber.target}
      />

      <FormikTextField
        id="address"
        name="address"
        formik={formik}
        variant="outlined"
        fullWidth={true}
        label={t('applicant.address')}
        data-tour={applicantCreateTourConfig.tour.address.target}
      />

      <FormActions submitBtnTitle={t('common.create')} />
    </CreateFormLayout>
  );
};

export default ApplicantCreate;
