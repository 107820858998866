import React from 'react';
import { filter, isEmpty, map, pick, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateLogtime } from '../types';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { i18n } from '../utils';

export const getLogtimeRefreshKey = (state: IStoreState) =>
  state.logtime.refreshKey;

export const getLogtimeData = (state: IStoreState) => state.logtime;

/**
 * Get server error
 * @param logtime - State logtime
 */
export const getServerError = ({ logtime }: IStoreState) => logtime.error;

/**
 * Get logtime list
 * @param logtime - State logtime
 */
export const getLogtimeList = ({ logtime }: IStoreState) => logtime.list;

export const getLogtimeApprovedList = ({ logtime }: IStoreState) =>
  logtime.approvedList;
export const getLogtimeUnapprovedList = ({ logtime }: IStoreState) =>
  logtime.unapprovedList;
export const getLogtimeMissedList = ({ logtime }: IStoreState) =>
  logtime.missedList;

export const getLogtimeListForSupervisorView = ({ logtime }: IStoreState) =>
  logtime.supervisorViewList;

export const getLogtimeTableDataForSupervisorView = createSelector(
  getLogtimeListForSupervisorView,
  (list) =>
    list.filter(
      (item) =>
        !isEmpty(item.employee) &&
        !isEmpty(item.department) &&
        !isEmpty(item.shift),
    ),
);

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param logtime - State logtime
 */
export const getLogtimeTable = createSelector(getLogtimeList, (logtime) =>
  filter(
    logtime,
    (s) => !isEmpty(s.employee) && !isEmpty(s.department) && !isEmpty(s.shift),
  ),
);

export const getLogtimeApprovedTable = createSelector(
  getLogtimeApprovedList,
  (logtime) => {
    return logtime
      .filter(
        (item) =>
          !isEmpty(item.employee) &&
          !isEmpty(item.department) &&
          !isEmpty(item.shift),
      )
      .map((item) => ({
        ...item,
        approved: <YesIcon color="primary" />,
        isArchived: item.isArchived
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      }));
  },
);

export const getLogtimeUnapprovedTable = createSelector(
  getLogtimeUnapprovedList,
  (logtime) => {
    return logtime
      .filter(
        (item) =>
          !isEmpty(item.employee) &&
          !isEmpty(item.department) &&
          !isEmpty(item.shift),
      )
      .map((item) => ({
        ...item,
        approved: <NoIcon color="primary" />,
        isArchived: item.isArchived
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      }));
  },
);

export const getLogtimeMissedTable = createSelector(
  getLogtimeMissedList,
  (logtime) => {
    return logtime
      .filter(
        (item) =>
          !isEmpty(item.employee) &&
          !isEmpty(item.department) &&
          !isEmpty(item.shift),
      )
      .map((item) => ({
        ...item,
        approved: <NoIcon color="primary" />,
        isArchived: item.isArchived
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
        isMissed: item.isMissed ? i18n.t('common.yes') : i18n.t('common.no'),
      }));
  },
);

/**
 * Get logtimes by array of ids
 * @param logtime - State logtime
 */
export const getLogtimesByIds = createSelector(
  getLogtimeList,
  (logtimes) => (ids: IdsArray) => {
    const initial: IUpdateLogtime[] = [];
    return reduce(
      logtimes,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push(
            pick(cur, [
              'id',
              'departmentId',
              'shiftId',
              'staffingId',
              'timeIn',
              'timeOut',
              'badge',
              'timezone',
              'punchType',
              'employee',
              'comment',
            ]),
          );
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get logtime count
 * @param logtime - State logtime
 */
export const getLogtimeCount = ({ logtime }: IStoreState) => logtime.count;

export const getLogtimeApprovedCount = ({ logtime }: IStoreState) =>
  logtime.approvedCount;
export const getLogtimeUnapprovedCount = ({ logtime }: IStoreState) =>
  logtime.unapprovedCount;

export const getLogtimeMissedPunchesList = ({ logtime }: IStoreState) =>
  logtime.missedList;
export const getLogtimeMissedPunchesCount = ({ logtime }: IStoreState) =>
  logtime.missedCount;
export const getIsMissedPunchesRequestInProgress = ({ logtime }: IStoreState) =>
  logtime.isMissedPunchesRequestInProgress;

/**
 * Get combobox list
 */
export const getLogtimesComboboxList = ({ logtime }: IStoreState) =>
  map(logtime.comboboxList, (employeeItem) => ({
    id: employeeItem.id,
    name: employeeItem.badge,
  }));

export const isLogtimesDataLoading = createSelector(
  getLogtimeData,
  (data) => data.isListDataLoading || data.isCountDataLoading,
);
