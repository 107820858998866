import React from 'react';
import { Input, Sheet } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SearchSvg } from 'src/components/svgIcons';
import { useMainLayoutContext } from '../../../MainLayoutContext';

interface ISideBarGlobalSitesDropdownSearchProps {
  value: string;
  onSearchTermChange: (searchTerm: string) => void;
}

export const SideBarGlobalSitesDropdownSearch = ({
  value,
  onSearchTermChange,
}: ISideBarGlobalSitesDropdownSearchProps) => {
  const { t } = useTranslation();

  const { sideBar, toggleSideBar, createTransition } = useMainLayoutContext();

  const onSearchIconClick = () => {
    if (sideBar.state === 'collapsed') {
      toggleSideBar();
    }
  };

  return (
    <Sheet
      sx={{
        py: 1.5,
        px: `${
          sideBar.state === 'expanded' ? sideBar.padding + 4 : sideBar.padding
        }px`,
        backgroundColor: 'inherit',
        transition: createTransition('padding'),
      }}
    >
      <Input
        size="lg"
        value={value}
        onChange={(e) => onSearchTermChange(e.target.value)}
        placeholder={t('common.search')}
        startDecorator={<SearchSvg onClick={onSearchIconClick} />}
        sx={{
          px: 1.5,
          backgroundColor: 'base.white',
          borderColor: 'gray.300',
          boxShadow: 'sx',
        }}
      />
    </Sheet>
  );
};
