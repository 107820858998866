import { t } from 'i18next';
import * as Yup from 'yup';

const pointSchema = Yup.object().shape({
  min: Yup.number().required(t('common.required')),
  max: Yup.number().required(t('common.required')),
  removePercentage: Yup.number().required(t('common.required')),
});

export const createIncentivePaymentSettingsScheme = Yup.object().shape({
  serviceKey: Yup.string().required(t('common.required')),
  points: Yup.array()
    .of(pointSchema)
    .test(
      'min-max-order',
      'Each min value must be greater than the previous max value',
      function (points) {
        if (!points) return true;
        for (let i = 1; i < points.length; i++) {
          if (points[i].min <= points[i - 1].max) {
            return this.createError({
              path: `points[${i}].min`,
              message: `${t(
                'common.min_value_must_be_greater_than_the_previous_max_value',
              )}`,
            });
          }
        }
        return true;
      },
    )
    .test(
      'min-less-max',
      'Each min value must be less than the max value',
      function (points) {
        if (!points) return true;
        for (let i = 0; i < points.length; i++) {
          if (points[i].min > points[i].max) {
            return this.createError({
              path: `points[${i}].min`,
              message: `${t('common.min_value_must_be_less_than_max_value')}`,
            });
          }
        }
        return true;
      },
    ),
});
