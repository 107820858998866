import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';

import { Api } from '../utils';
import * as actions from '../actions';

import {
  ICountResponse,
  ICreateSiteBillingDetails,
  IdsArray,
  IFilter,
  ISiteBillingDetailsModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createSiteBillingDetailsRequest,
    saga: function* ({
      payload: { data },
    }: ISagaAction<
      PayloadWithNavigateFunc<ICreateSiteBillingDetails>
    >): SagaIterator {
      try {
        const response: ISiteBillingDetailsModel = yield call(
          Api.SiteBillingDetails.create,
          data,
        );
        yield put(actions.flushSiteBillingDetailsState());
        yield put(actions.createSiteBillingDetailsSuccess(response));
      } catch (e) {
        yield put(actions.createSiteBillingDetailsFailed());
      }
    },
  },
  {
    action: actions.createBulkSiteBillingDetailsRequest,
    saga: function* ({
      payload: { data },
    }: ISagaAction<
      PayloadWithNavigateFunc<ICreateSiteBillingDetails[]>
    >): SagaIterator {
      try {
        const response: ISiteBillingDetailsModel = yield call(
          Api.SiteBillingDetails.createBulk,
          data,
        );
        yield put(actions.flushSiteBillingDetailsState());
        yield put(actions.createSiteBillingDetailsSuccess(response));
      } catch (e) {
        yield put(actions.createSiteBillingDetailsFailed());
      }
    },
  },
  {
    action: actions.getSiteBillingDetailsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ISiteBillingDetailsModel[] = yield call(
          Api.SiteBillingDetails.list,
          action.payload,
        );
        yield put(actions.getSiteBillingDetailsListSuccess(response));
      } catch (e) {
        yield put(actions.getSiteBillingDetailsListFailed());
      }
    },
  },
  {
    action: actions.getSiteBillingDetailsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.SiteBillingDetails.count,
          action?.payload,
        );
        yield put(actions.getSiteBillingDetailsCountSuccess(response));
      } catch (e) {
        yield put(actions.getSiteBillingDetailsCountFailed());
      }
    },
  },
  {
    action: actions.deleteSiteBillingDetailsRequest,
    saga: function* (action: ISagaAction<IdsArray>): SagaIterator {
      try {
        yield call(Api.SiteBillingDetails.delete, {
          where: { id: { inq: action.payload } },
        });
        yield put(actions.flushSiteBillingDetailsState());
      } catch (e) {
        yield put(actions.deleteSiteBillingDetailsFailed());
      }
    },
  },
  {
    action: actions.updateSiteBillingDetailsRequest,
    saga: function* ({
      payload: { data },
    }: ISagaAction<
      PayloadWithNavigateFunc<ISiteBillingDetailsModel[]>
    >): SagaIterator {
      try {
        yield call(Api.SiteBillingDetails.bulkUpdate, data);
        yield put(actions.flushSiteBillingDetailsState());
      } catch (e) {
        yield put(actions.updateSiteBillingDetailsFailed());
      }
    },
  },
];
