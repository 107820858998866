import { useTheme } from '@mui/joy';
import { CalendarEventsSvg } from 'src/components/svgIcons';
import { IDatePickerProps } from './Datepicker';
import { AnyObject } from 'src/modules/types';

export const usePickersSlots = () => ({
  openPickerIcon: CalendarEventsSvg,
});

export const usePickersSx = (size: number, error?: boolean) => {
  const theme = useTheme();

  return {
    ['& .MuiInputBase-root']: {
      boxShadow: 'none',
      height: size,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '500',
      borderRadius: '8px',
      '&:hover fieldset': {
        borderColor: theme.palette.colors.border.border_primary,
      },
    },
    ['& .MuiInputBase-root fieldset legend']: {
      display: 'none',
    },
    ['& .MuiInputBase-root fieldset']: {
      top: 0,
      height: 'inherit',
      borderColor: theme.palette.colors.border.border_primary,
      boxShadow:
        'var(--ngroup-shadowRing, 0 0 #000),0px 1px 2px 0px rgba(var(--ngroup-shadowChannel, 21 21 21) / var(--ngroup-shadowOpacity, 0.08))',
    },
    ['& .MuiInputBase-root.Mui-error fieldset']: {
      borderColor: theme.palette.colors.border.border_error,
    },
    ['& .MuiInputBase-root.Mui-focused fieldset']: {
      outlineStyle: 'solid',
      outlineWidth: '4px',
      outlineColor: error
        ? theme.palette.error['100']
        : theme.palette.brand['100'],
      borderColor: error
        ? theme.palette.colors.border.border_error
        : theme.palette.colors.border.border_brand,
      borderWidth: 1,
    },
    ['& .MuiOutlinedInput-input']: {
      paddingLeft: '40px',
      fontWeight: theme.fontWeight.regular,
      color: theme.palette.colors.text.text_primary.main,
    },
    ['& .MuiIconButton-edgeEnd']: {
      position: 'absolute',
      left: 8,
      cursor: 'pointer',
      borderRadius: '2px',
      padding: 0.5,
    },
    ['& .MuiFormHelperText-root']: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      marginTop: '0.375rem',
      fontWeight: 400,
      marginLeft: 0,
      marginRight: 0,
      color: theme.palette.colors.text.text_error_primary.main,
    },
  };
};

export const usePickersSlotsProps = ({
  helperText,
  fullWidth,
  required,
  error,
  variant,
  id,
  slotProps,
}: Pick<
  IDatePickerProps,
  'helperText' | 'fullWidth' | 'required' | 'error' | 'variant' | 'id'
> & { slotProps?: AnyObject }) => {
  return {
    ...slotProps,
    textField: {
      ...slotProps?.textField,
      helperText,
      fullWidth,
      required,
      error,
      variant,
      margin: 'none' as const,
      id,
    },
    desktopPaper: {
      ...slotProps?.desktopPaper,
      sx: {
        ['& .MuiButtonBase-root']: {
          fontSize: '0.9em',
        },
        ['& .MuiIconButton-root']: {
          fontSize: '1.5rem',
        },
        ['& .MuiTypography-root.MuiTypography-caption']: {
          fontSize: '0.9em',
        },
      },
    },
  };
};
