import { sumBy } from 'lodash';
import { createSelector } from 'reselect';

import { getGloballySelectedSites } from './site';

import { IStoreState } from '../types';

export const getSupervisorRefreshKey = ({ supervisorView }: IStoreState) =>
  supervisorView.refreshKey;

export const getSupervisorViewData = (state: IStoreState) =>
  state.supervisorView;

/**
 * Get server error
 * @param site - State site
 */
export const getServerError = ({ supervisorView }: IStoreState) =>
  supervisorView.error;

/**
 * Get logged in employees list
 * @param site - State site
 */
export const getLoggedInList = ({ supervisorView }: IStoreState) =>
  supervisorView.loggedInList;

/**
 * Get logged in employees list
 * @param site - State site
 */
export const getFilteredLoggedInList = createSelector(
  getGloballySelectedSites,
  getLoggedInList,
  (globallySelectedSites, loggedInList) =>
    loggedInList.filter((loggedIn) =>
      globallySelectedSites.includes(loggedIn.siteId),
    ),
);

/**
 * Get logged in employees total count
 * @param site - State site
 */
export const getLoggedInTotal = createSelector(
  getFilteredLoggedInList,
  (loggedInItems) => sumBy(loggedInItems, (l) => l.count),
);

export const getIsLoggedInListDataLoading = createSelector(
  getSupervisorViewData,
  (data) => data.isLoggedInListDataLoading,
);
