import { JSONSchemaType } from 'ajv';
import { ICreateOtherCosts } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

const decimalRegex = '^[+-]?([0-9]+\\.?[0-9]*|\\.[0-9]+)$';
export const createOtherCostsScheme: JSONSchemaType<ICreateOtherCosts> = {
  type: 'object',
  required: ['siteId', 'yearNumber', 'weekNumber', 'value', 'description'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    weekNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    yearNumber: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    isRepeatable: {
      default: false,
      isNotEmpty: true,
      type: 'boolean',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    value: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      pattern: decimalRegex,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.type'),
        pattern: i18n.t('error.validation.number'),
      },
    },
    description: {
      type: 'string',
      nullable: true,
      errorMessage: {
        format: i18n.t('error.validation.string'),
      },
    },
  },
};
