import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useSelector } from 'react-redux';
import { ILogtimeSupervisorViewModel } from 'src/modules/types';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { getDepartmentsComboboxList } from 'src/modules/selectors/department';

interface ISupervisorViewChangeDepartmentDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (departmentId: number, employeesIds: Array<number>) => void;
  employees: Array<ILogtimeSupervisorViewModel>;
}

export const SupervisorViewChangeDepartmentDrawer = ({
  isOpen,
  onClose,
  employees,
  onApply,
}: ISupervisorViewChangeDepartmentDrawerProps) => {
  const { t } = useTranslation();

  const [departmentId, setDepartmentId] = React.useState<number | null>(null);
  const [
    comboBoxValue,
    setComboBoxValue,
  ] = React.useState<ComboBoxOption | null>(null);
  const [comboBoxInputValue, setComboBoxInputValue] = React.useState<
    string | undefined
  >(undefined);

  const departmentsComboboxList = useSelector(getDepartmentsComboboxList);

  const handleApply = () => {
    if (departmentId) {
      onApply(
        departmentId,
        employees.map(({ id }) => id),
      );

      setDepartmentId(null);
      setComboBoxValue(null);
      setComboBoxInputValue(undefined);
    }
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('supervisor_view.move_to_another_dep')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <ComboBox
              onChange={(_, value) => {
                setDepartmentId(value?.id ?? null);
                setComboBoxValue(value);
              }}
              onInputChange={(_, inputValue) => {
                setComboBoxInputValue(inputValue);
              }}
              options={departmentsComboboxList}
              label={t('supervisor_view.department')}
              value={comboBoxValue}
              inputValue={comboBoxInputValue}
            />
          </FormFieldContainer>
          <FormFieldContainer>
            {employees.map((employee) => (
              <DrawerBodySectionTitle key={employee.id}>
                {`${employee.employee?.firstName} ${employee.employee?.lastName}`}
              </DrawerBodySectionTitle>
            ))}
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onCancel={onClose}
          onApply={handleApply}
          applyButtonType="submit"
          onReset={() => setDepartmentId(null)}
        />
      </DrawerFooter>
    </Drawer>
  );
};
