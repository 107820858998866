import theme from '../../../assets/css/theme';

export const getChartItemColor = (itemIndex: number) => {
  const { chartColorScheme } = theme;
  const colorsQuantity = chartColorScheme.length;

  const division = Math.floor(itemIndex / colorsQuantity);

  const colorIndex =
    division < 1 ? itemIndex : itemIndex - colorsQuantity * division;

  return chartColorScheme[colorIndex];
};
