import { createAction } from 'redux-act';
import { ExportActionCreator } from '../../utils/helpers/creators/export';
import { ICountResponse, IFilter, IWhere, ListWithSummary } from '../../types';
import { ICostPlusWeeklyModel } from '../../types/CostPlusWeeklyTypes';

// get cost plus weekly data actions2
export const getCostPlusWeeklyListRequest = createAction<IFilter>(
  'Make request to fetch weekly cost plus list',
);
export const getCostPlusWeeklyListSuccess = createAction<
  ListWithSummary<ICostPlusWeeklyModel>
>('Cost Plus Weekly list has been fetched successfully');
export const getCostPlusWeeklyListFailed = createAction(
  'Cannot fetch Cost Plus Weekly list',
);
// get cost plus weekly count actions
export const getCostPlusWeeklyCountRequest = createAction<IWhere>(
  'Make request to get Cost Plus Weekly count',
);
export const getCostPlusWeeklyCountSuccess = createAction<ICountResponse>(
  'Cost Plus Weekly count has been fetched successfully',
);
export const getCostPlusWeeklyCountFailed = createAction(
  'Cannot fetch Cost Plus Weekly count',
);

// exporting actions
export const {
  exportReportRequest: exportCostPlusWeeklyRequest,
  exportReportSuccess: exportCostPlusWeeklySuccess,
  exportReportFailed: exportCostPlusWeeklyFailed,
} = new ExportActionCreator('CostPlusWeekly').takeExportActions();
