import React from 'react';
import { Button, ButtonProps, Sheet, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { ArrowClockwiseSvg } from 'src/components/svgIcons';

interface IActionsBarCustomAction {
  btn: React.ReactElement;
}

interface IActionsBarProps {
  applyText?: string;
  onReset?: () => void;
  onApply?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  applyButtonType?: ButtonProps['type'];
  customActions?: IActionsBarCustomAction[];
}

export const ActionsBar = ({
  applyText,
  onReset,
  onCancel,
  onApply,
  onDelete,
  applyButtonType,
  customActions,
}: IActionsBarProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isSubmitButton = applyButtonType === 'submit';

  return (
    <Sheet
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent:
          !onReset || (!onReset && !onApply) ? 'flex-end' : 'space-between',
        bgcolor: 'inherit',
      }}
    >
      {onReset && (
        <Button onClick={onReset} variant="plain">
          <ArrowClockwiseSvg fill={theme.palette.error[500]} />
        </Button>
      )}
      {customActions && customActions?.map(({ btn }) => btn)}
      <Sheet>
        {onCancel && (
          <Button onClick={onCancel} variant="plain" color="neutral">
            {t('common.cancel')}
          </Button>
        )}
        {onDelete && (
          <Button
            sx={{ ml: 1 }}
            onClick={onDelete}
            variant="outlined"
            color="danger"
          >
            {t('common.delete')}
          </Button>
        )}
        {(onApply || isSubmitButton) && (
          <Button
            type={applyButtonType}
            variant="solid"
            sx={{ ml: 1 }}
            onClick={onApply}
          >
            {applyText ?? t('common.apply')}
          </Button>
        )}
      </Sheet>
    </Sheet>
  );
};
