import React, { useMemo } from 'react';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import { AnyObject } from '../../modules/types';
import Timepicker, { ITimepickerProps } from '../Timepicker';
import { get } from 'lodash';
import { ErrorMode } from './types';
import {
  DATETIME_FORMAT_TO_SHOW,
  TIME_FORMAT,
  parse,
  format,
  composeDate,
} from '../../modules/utils/dateWrapper';

interface IProps<Values> extends Omit<ITimepickerProps, 'onChange'> {
  id: string;
  formik: FormikHelpers<Values> & FormikState<Values>;
  errorMode?: ErrorMode;
}

export default function FormikTimepicker<Values extends AnyObject>(
  props: IProps<Values>,
) {
  const { id, formik, errorMode = 'onFormSubmit', ...restProps } = props;
  const handleChange = (time: string) => {
    formik.setFieldError(id, undefined);
    formik.setFieldValue(id, time);
  };

  const option = useMemo(() => {
    const time = get(formik.values, id);

    if (!time) {
      return '';
    }

    return composeDate(
      time,
      parse(TIME_FORMAT),
      format(DATETIME_FORMAT_TO_SHOW),
    );
  }, [formik.values, id]);

  const error = useMemo(() => get(formik.errors, id), [formik.errors, id]);
  const touched = useMemo(() => get(formik.touched, id), [formik.touched, id]);
  const showError = useMemo<boolean>(() => {
    switch (errorMode) {
      case 'onFieldChange':
        return !!error;
      case 'onFormSubmit':
      default:
        return touched && !!error;
    }
  }, [error, errorMode, touched]);

  return (
    <Timepicker
      {...restProps}
      id={id}
      value={option}
      onChange={handleChange}
      error={showError}
      helperText={showError && error}
    />
  );
}
