import React, { useEffect, useMemo, useState } from 'react';

import { map, get, find, isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getStandardList,
  getStandardRefreshKey,
} from '../../modules/selectors/standard';
import {
  deleteStandardRequest,
  exportStandardRequest,
  getStandardListRequest,
} from '../../modules/actions';
import EnhancedTable, { ITableSyncProps } from '../../components/EnhancedTable';
import {
  standardsInclusions,
  useFetchMetatagsByIds,
  useSearch,
  useCreateExportProps,
} from '../../modules/utils/hooks';
import { Grid } from '@mui/material';
import {
  ModelsToDelete,
  MUI_SIZE_12,
  MUI_SIZE_2,
  useHasUserAccessToAction,
} from '../../config';
import { getSitesComboboxList } from '../../modules/selectors/site';
import { AnyObject, IdsArray, IWhere } from '../../modules/types';
import { getMetatagsNames } from '../../modules/selectors/metatag';
import { useTranslation } from 'react-i18next';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { Alert } from '../../components/Alert';
import {
  useQueryParams,
  useBrowserHistoryFunctions,
  useFetchSitesCombobox,
} from '../../modules/utils';
import FilterBySites from 'src/components/filtersByEntity/FilterBySites';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const DEFAULT_ORDER_BY = 'id';
const DEFAULT_ORDER = 'desc';

const StandardsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();
  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchMetatags = useFetchMetatagsByIds();
  const { siteId: siteIdDetails, filter } = useQueryParams();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.standard.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.standard.update.id,
  );

  const { pushSearchObject } = useBrowserHistoryFunctions();

  const [currentSiteId, setCurrentSiteId] = useState<number | null>(
    Number((siteIdDetails as AnyObject)?.eq) ?? null,
  );
  const [filterValues, setFilterValues] = React.useState<IWhere>();
  // fetch list
  const list = useSelector(getStandardList, shallowEqual);

  const exportProps = useCreateExportProps(exportStandardRequest);

  // make request to fetch clients and standards from the server if we don't have them in the store
  useEffect(() => {
    fetchMetatags();
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  // fetch meta tags names list from store
  const metatagNames = useSelector(getMetatagsNames, shallowEqual);

  // get standards table title
  const tableName = useMemo(
    () =>
      get(
        find(sites, (s) => +s.id === (currentSiteId as number)),
        'name',
        '',
      ),
    [sites, currentSiteId],
  );

  // get standards columns names for the site
  const standardColumns = useMemo(
    () => [
      {
        id: 'id',
        disablePadding: false,
        label: t('standards.id'),
      },
      {
        id: 'standard',
        disablePadding: false,
        label: t('standards.standard'),
      },
      {
        id: 'baseTime',
        disablePadding: false,
        label: t('standards.base_time'),
      },
      ...map(metatagNames[currentSiteId as number] ?? [], (field) => ({
        id: field,
        disablePadding: false,
        label: field,
      })),
    ],
    [t, metatagNames, currentSiteId],
  );

  // get standards filters
  const standardFilters = useMemo(
    () => [
      {
        name: 'id',
        label: t('standards.id'),
        operator: 'eq',
      },
      {
        name: 'standard',
        label: t('standards.standard'),
        operator: 'eq',
      },
      {
        name: 'baseTime',
        label: t('standards.base_time'),
        operator: 'eq',
      },
      ...map(metatagNames[currentSiteId as number] ?? [], (field) => ({
        name: field,
        label: field,
        operator: 'eq',
      })),
    ],
    [currentSiteId, metatagNames, t],
  );

  const standardsList = useMemo(
    () => get(list, [currentSiteId as number, 'data'], []),
    [currentSiteId, list],
  );

  const standardsCount = useMemo(
    () => get(list, [currentSiteId as number, 'count'], 0),
    [currentSiteId, list],
  );

  const initialFilterData = useMemo(
    () => ({ siteId: { eq: currentSiteId as number } }),
    [currentSiteId],
  );

  // create dispatcher
  const dispatcher = useDispatch();

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclusion } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getStandardListRequest({
        siteId: currentSiteId as number,
        filter: {
          filter: {
            limit: rowsPerPage,
            offset,
            order,
            where,
            include: newInclusion,
          },
        },
      }),
    );
    setFilterValues(props.where);
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(
      deleteStandardRequest({
        siteId: currentSiteId as number,
        ids,
      }),
    );
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids, siteId: currentSiteId as number });
    pushToHistory({ pathname: '/standards/update', search });
  };

  const handleSiteChoosing = ({ id: entityId }: { id: number | null }) => {
    if (!entityId) {
      return;
    }

    setCurrentSiteId(entityId);
    pushSearchObject({ siteId: { eq: entityId } });
    setFilterValues(
      Object.keys(filterValues ?? {}).reduce((res, key) => {
        res[key] = { eq: key === 'siteId' ? entityId : undefined };
        return res;
      }, {}),
    );
    dispatcher(
      getStandardListRequest({
        siteId: entityId,
        filter: {
          filter: {
            limit: 25,
            offset: 0,
            order: [`${DEFAULT_ORDER_BY} ${DEFAULT_ORDER}`],
            include: standardsInclusions,
          },
        },
      }),
    );
  };

  // Fetch data on component mount based on data in query params
  // and initialize filterValues for import
  React.useEffect(() => {
    const _filter = ((filter as AnyObject) ? { filter } : {}) as AnyObject;

    if (currentSiteId) {
      dispatcher(
        getStandardListRequest({
          siteId: currentSiteId,
          filter: _filter,
        }),
      );
    }
    if ((filter as AnyObject)?.where) {
      setFilterValues((filter as AnyObject).where);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <FilterBySites entityId={currentSiteId} onSelect={handleSiteChoosing}>
          {!currentSiteId && isEmpty(standardsList) ? (
            <Grid container={true}>
              <Grid item={true} xs={MUI_SIZE_12}>
                <Alert variant="outlined" severity="info">
                  {t('standards.site_filter_alert')}
                </Alert>
              </Grid>
            </Grid>
          ) : (
            <Grid container={true} spacing={MUI_SIZE_2}>
              <Grid item={true} xs={MUI_SIZE_12}>
                <EnhancedTable
                  data={standardsList}
                  count={standardsCount}
                  selectIndex="id"
                  defaultOrder={DEFAULT_ORDER}
                  defaultOrderBy={DEFAULT_ORDER_BY}
                  tableName={tableName}
                  headCells={standardColumns}
                  filters={standardFilters as ITableFilter[]}
                  initialFilterData={initialFilterData}
                  onSync={onSync}
                  onDelete={allowedToDelete ? handleDeletion : undefined}
                  onUpdate={allowedToUpdate ? handleUpdating : undefined}
                  include={standardsInclusions}
                  // disableQsFilters={true}
                  exportProps={exportProps}
                  deleteModelName={ModelsToDelete.Standard}
                  additionalWhereForExport={{
                    siteId: { eq: currentSiteId },
                    ...filterValues,
                  }}
                />
              </Grid>
            </Grid>
          )}
        </FilterBySites>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function StandardsListRefreshable() {
  return <StandardsList key={useSelector(getStandardRefreshKey)} />;
}
