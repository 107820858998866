import React, { useEffect, useMemo } from 'react';
import { map, get } from 'lodash';

import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, {
  IFormikComboboxProps,
} from 'src/components/Formik/FormikCombobox';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject } from 'src/modules/types';

interface IAutomaticPointsRejectReasonsComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  fieldAsId?: 'id' | 'name';
}

const AutomaticPointsRejectReasonsComboBox: React.FC<IAutomaticPointsRejectReasonsComboboxProps> = ({
  fieldAsId = 'id',
  ...props
}) => {
  const { data, fetchData } = useDataFetcherWithData<AnyObject>(
    '/automatic-points-reject-reasons',
    [],
  );

  useEffect(() => {
    fetchData({
      filter: {
        include: [
          {
            relation: 'site',
          },
        ],
      },
    });
  }, [fetchData]);

  const options = (useMemo(
    () =>
      map(data, (item) => ({
        id: item[fieldAsId],
        name: `${item.name} (${get(item, 'site.name')})`,
        siteId: item.siteId,
      })),
    [fieldAsId, data],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default AutomaticPointsRejectReasonsComboBox;
