import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { HorizontalBarChart } from 'src/components/Charts/2d/HorizontalBarChart';
import { QuarterButtons, QuarterValue, quarters } from './QuarterButtons';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { fetchProjectionVsActualQuarterlyChartData } from 'src/modules/utils/Api/projectionVsActualChartsDashboardDataRequests';
import {
  financialColor,
  projectionColor,
  ydtLegends,
} from '../FinancialDashboardTotalsChart/constants';
import { useChartRequiredUtils } from 'src/components/Charts/common';

interface IMetricOption {
  id: 'revenue' | 'grossMargin';
  label: string;
}

export const FinancialDashboardQuarterlyChart = () => {
  const { t } = useTranslation();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchProjectionVsActualQuarterlyChartData,
    {
      initialData: [],
    },
  );

  const metricsOptions = React.useMemo(() => {
    return [
      { id: 'revenue' as const, label: t('common.revenue') },
      { id: 'grossMargin' as const, label: t('common.gross_margin') },
    ];
  }, [t]);

  const [view, setView] = React.useState<QuarterValue>(quarters.q1.value);
  const [metric, setMetric] = React.useState<IMetricOption>(metricsOptions[0]);

  const series = React.useMemo(() => {
    const currentYear = new Date().getFullYear();

    const fullData = data.find(
      (item) => item.quarter === view && item.yearNumber === currentYear,
    );

    if (metric.id === 'revenue') {
      return [
        {
          name: ydtLegends.sumOfYDTRevenue,
          type: 'bar' as const,
          color: financialColor,
          data: fullData ? [fullData.revenue] : [],
        },
        {
          name: ydtLegends.sumOfYDTProjRevenue,
          color: projectionColor,
          type: 'bar' as const,
          data: fullData ? [fullData.projectionRevenue] : [],
        },
      ];
    }

    if (metric.id === 'grossMargin') {
      return [
        {
          name: ydtLegends.sumOfYDTGrossMargin,
          type: 'bar' as const,
          color: financialColor,
          data: fullData ? [fullData.grossMargin] : [],
        },
        {
          name: ydtLegends.sumOfYDTProjGrossMargin,
          color: projectionColor,
          type: 'bar' as const,
          data: fullData ? [fullData.projectionGrossMargin] : [],
        },
      ];
    }

    return [];
  }, [data, metric.id, view]);

  const onViewChange = (nextView: string) => {
    setView(nextView);
  };

  const onMetricChange = (newMetric: IMetricOption) => {
    setMetric(newMetric);
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t(
          'financial_reporting_dashboard.chart_title.quarterly_analysis',
        )}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`quarterly_analysis`)}
          />
          <DoubledCombobox>
            <LeftCombobox
              disableClearable
              readOnly
              options={[]}
              popupIcon={null}
              value={t('common.metric')}
            />
            <ComboboxSeparator />
            <RightCombobox
              disableClearable
              options={metricsOptions}
              value={metric}
              onChange={(_, value) => onMetricChange(value)}
            />
          </DoubledCombobox>
          <QuarterButtons view={view} onViewChange={onViewChange} />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <HorizontalBarChart
          ref={chartRef}
          height={chartHeight}
          categories={[quarters[view]?.label]}
          series={series}
          plotOptions={{
            series: {
              dataLabels: {
                enabled: true,
                format: '{y} $',
              },
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
