import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateProduction,
  IdsArray,
  IFilter,
  IProductionModel,
  IUpdateProduction,
  PayloadWithNavigateFunc,
} from '../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Flush actions
export const flushProductionState = createAction(
  'Flush production data in store',
);

// Creation actions
export const createProductionRequest = createAction<
  PayloadWithNavigateFunc<Array<ICreateProduction>>
>('Make request to create a new production');
export const createProductionSuccess = createAction<IProductionModel>(
  'New production has been created successfully',
);
export const createProductionFailed = createAction(
  'Cannot create a new production',
);

// Fetch productions actions
export const getProductionListRequest = createAction<IFilter>(
  'Make request to fetch production list',
);
export const getProductionListSuccess = createAction<IProductionModel[]>(
  'Productions list has been fetched successfully',
);
export const getProductionListFailed = createAction(
  'Cannot fetch production list',
);

// get productions count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getProductionCountSuccess = createAction<ICountResponse>(
  'Productions count has been fetched successfully',
);
export const getProductionCountFailed = createAction(
  'Cannot get production count',
);

// delete actions
export const deleteProductionRequest = createAction<IdsArray>(
  'Make request to delete productions',
);
export const deleteProductionFailed = createAction('Cannot delete productions');

// Updating actions
export const updateProductionsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateProduction[]>
>('Make request to update productions');
export const updateProductionsFailed = createAction(
  'Cannot update productions',
);

// Fetch combobox list actions
export const getProductionComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of productions',
);
export const getProductionComboboxListSuccess = createAction<
  Partial<IProductionModel>[]
>('Combobox list of productions has been fetched successfully');
export const getProductionComboboxListFailed = createAction(
  'Cannot fetch combobox productions list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Production').takeExportActions();

export const exportProductionRequest = exportReportRequest;
export const exportProductionSuccess = exportReportSuccess;
export const exportProductionFailed = exportReportFailed;
