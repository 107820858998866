import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  useBrowserHistoryFunctions,
  useFetchPayboardDetails,
  useFetchSitesCombobox,
  useFetchStaffingProvidersCombobox,
  useFilter,
  useProductionWeeklyReportsDefaultFilters,
  useSearch,
  useSupervisorCombobox,
} from '../../../../../modules/utils';
import { useReportWithOwnState } from '../../../../../modules/utils/hooks/common/reports';
import { useReportExportProps } from '../../../../../modules/utils/hooks/reports.hooks';
import { roles } from '../../../../../config';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../../components/EnhancedTable';
import { shallowEqual, useSelector } from 'react-redux';
import {
  getUserRoles,
  getUsersComboboxList,
} from '../../../../../modules/selectors/user';
import {
  IdsArray,
  ListWithSummary,
  IPayboardModel,
  PayboardDetailsRequest,
  IStoreState,
  AnyObject,
} from '../../../../../modules/types';
import { ITableFilter } from '../../../../../components/EnhancedTable/EnhancedTableFilter';
import { map } from 'lodash';
import { ComboBoxOption } from '../../../../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import { getStaffingProvidersComboboxList } from '../../../../../modules/selectors/staffingProvider';
import { getSitesComboboxList } from '../../../../../modules/selectors/site';
import {
  getIsDetailsViewDataLoading,
  getPayboardDetailsData,
} from '../../../../../modules/selectors/payboard';
import { PayboardDetailsTable } from '../../../Payboard/modals/PayboardDetailsTable';

const allowedDeleteRoles = [roles.SUPER_ADMIN];
const reportUrl = '/payboard-historical';
const ignoreGlobalSites = true;
const disableSelectionInTable = true;
const disableDeletionInTable = true;
const enableUpdationInTable = true;
const DEFAULT_ORDER_BY = 'yearWeek';
const DEFAULT_ORDER = 'desc';

function PayboardHistoricalList() {
  const { t } = useTranslation();
  const abortControllerRef = useRef<AbortController | null>(null);

  // week object that is used to determine which week and badge is selected by a user
  const [week, setWeek] = useState<PayboardDetailsRequest>({
    year: NaN,
    week: NaN,
    badge: NaN,
  });
  // that's used to show week modal dialog
  const [weekModal, setWeekModal] = useState<boolean>(false);

  const fetchPayboardDetails = useFetchPayboardDetails(week);
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();
  const fetchSitesComboBox = useFetchSitesCombobox();

  // make request to fetch departments from the server if we don't have them in the store
  useEffect(() => {
    fetchSitesComboBox();
    fetchStaffingProvidersCombobox();
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // make request to fetch payboard information by week from the server if we don't have it in the store
  useEffect(() => {
    fetchPayboardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week]);

  // get payboard weekly data from storage
  const weekData = useSelector(
    (state) => getPayboardDetailsData(state as IStoreState)(week),
    shallowEqual,
  );

  // fetch staffing list from store
  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );
  // fetch supervisors providers list from store
  const supervisors = useSelector(getUsersComboboxList, shallowEqual);
  // fetch sites list from store
  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const { data, fetchData, deleteRows, isDataLoading } = useReportWithOwnState<
    ListWithSummary<IPayboardModel>
  >(reportUrl);
  const exportProps = useReportExportProps(reportUrl, ignoreGlobalSites);
  const composeSearch = useSearch();
  const { pushToHistory } = useBrowserHistoryFunctions();
  // const { items, count, summary } = data;

  // for Daily and Weekly reports different hooks
  const getDefaultFilterHook = useProductionWeeklyReportsDefaultFilters;

  const defaultFilter = getDefaultFilterHook();

  const { filterList, filterCount } = useFilter(defaultFilter);

  // define is user can delete rows
  let selectIndex = '';
  let disableSelection = true;
  const userRoles = useSelector(getUserRoles, shallowEqual) ?? [];
  const hasUserPermission = userRoles.some((role) =>
    allowedDeleteRoles.includes(role),
  );
  if (hasUserPermission) {
    selectIndex = 'id'; // define index for selection
    disableSelection = false; // enable row selection
  }
  const handleDelete = React.useCallback(
    async (ids: IdsArray) => {
      await deleteRows(ids);

      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();

      await fetchData(filterList, filterCount, {
        signalList: abortControllerRef.current?.signal,
        signalCount: abortControllerRef.current?.signal,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterList, filterCount],
  );

  const handleUpdate = React.useCallback(
    async (ids: IdsArray) => {
      // handle updating
      const search = composeSearch({ ids });
      pushToHistory({ pathname: `${reportUrl}/update`, search });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportUrl],
  );

  /**
   * @Todo !!IMPORTANT!!
   * If you use 'disableQsFilters' as true for <EnhancedTable/>
   * You do NOT need useEffect additionally to fetch data on first mount
   * because it use onSync callback inside EnhancedTable inside
   * src/components/Filter/index.tsx:275
   * which cause reset filter which call 'handlerFilterReset' in EnhancedTable itself
   * But you should left cancelling request anyway for unmount event
   */
  // fetch report on mount
  React.useEffect(() => {
    //   abortControllerRef.current?.abort();
    //   abortControllerRef.current = new AbortController();
    //
    //   fetchData(filterList, filterCount, {
    //     signalList: abortControllerRef.current?.signal,
    //     signalCount: abortControllerRef.current?.signal,
    //   });
    //
    return () => {
      abortControllerRef.current?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);
  const isDetailsViewDataLoading = useSelector(getIsDetailsViewDataLoading);

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    fetchData(
      {
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      },
      { filter: { where } },
      {
        signalList: abortControllerRef.current?.signal,
        signalCount: abortControllerRef.current?.signal,
      },
    );
  };

  const { items, count, summary } = data;

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'extEmployeeId',
      disablePadding: false,
      label: t('payboard.extEmployeeId'),
    },
    { id: 'badge', disablePadding: false, label: t('payboard.badge') },
    { id: 'firstName', disablePadding: false, label: t('payboard.first_name') },
    { id: 'lastName', disablePadding: false, label: t('payboard.last_name') },
    {
      id: 'staffingProvider',
      disablePadding: false,
      label: 'Staffing Provider',
    },
    { id: 'payRate', disablePadding: false, label: t('payboard.payrate') },
    {
      id: 'supervisor',
      disablePadding: false,
      label: t('payboard.supervisor'),
    },
    { id: 'site', disablePadding: false, label: t('payboard.site') },
    {
      id: 'year',
      disablePadding: false,
      label: t('payboard.year'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'week',
      disablePadding: false,
      label: t('payboard.week'),
      orderByAnotherField: 'yearWeek',
    },
    {
      id: 'weekEndingDate',
      disablePadding: false,
      label: t('payboard.week_ending_date'),
      orderByAnotherField: 'yearWeek',
    },
    { id: 'markup', disablePadding: false, label: t('payboard.markup') },
    { id: 'client', disablePadding: false, label: t('payboard.client') },
    {
      id: 'sumTimeTotal',
      disablePadding: false,
      label: t('payboard.time_total'),
    },
    {
      id: 'regularHoursDec',
      disablePadding: false,
      label: t('payboard.reg_hrs'),
    },
    {
      id: 'overtimeHoursDec',
      disablePadding: false,
      label: t('payboard.ot_hrs'),
    },
    {
      id: 'sickHoursDec',
      disablePadding: false,
      label: t('payboard.sick_hrs'),
    },
    {
      id: 'ptoHoursDec',
      disablePadding: false,
      label: t('payboard.pto_hrs'),
    },
    {
      id: 'holidayHoursDec',
      disablePadding: false,
      label: t('payboard.holiday_hrs'),
    },
    {
      id: 'regularPay',
      disablePadding: false,
      label: t('payboard.reg_pay'),
      orderByAnotherField: 'regularPay',
    },
    {
      id: 'overtimePay',
      disablePadding: false,
      label: t('payboard.ot_pay'),
      orderByAnotherField: 'overtimePay',
    },
    {
      id: 'overtimePremiumPay',
      disablePadding: false,
      label: t('payboard.ot_premium_pay'),
      orderByAnotherField: 'overtimePremiumPay',
    },
    {
      id: 'totalPay',
      disablePadding: false,
      label: t('payboard.total_pay'),
      orderByAnotherField: 'totalPay',
    },
    {
      id: 'markupCost',
      disablePadding: false,
      label: t('payboard.markup_cost'),
      orderByAnotherField: 'markupCost',
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'site',
      label: t('payboard.site'),
      operator: 'eq',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(sites, (site) => ({
            id: site.name,
            name: site.name,
          })),
        [sites],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'supervisor',
      label: t('payboard.supervisor'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(supervisors, (supervisor) => ({
            id: supervisor.name,
            name: supervisor.name,
          })),
        [supervisors],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'staffingProvider',
      label: t('payboard.staffing_provider'),
      operator: 'like',
      type: 'combobox',
      options: (useMemo(
        () =>
          map(staffingProviders, (staffingProvider) => ({
            id: staffingProvider.name,
            name: staffingProvider.name,
          })),
        [staffingProviders],
      ) as unknown) as ComboBoxOption[],
    },
    {
      name: 'badge',
      label: t('payboard.employee'),
      operator: 'like',
      type: 'comboboxEmployee',
      propertyAsID: 'badge',
      onlyActive: false,
    },
    {
      name: 'extEmployeeId',
      label: t('payboard.extEmployeeId'),
      operator: 'like',
    },
    {
      name: 'yearWeek',
      label: t('payboard.year_week'),
      operator: 'eq',
      type: 'comboboxYearWeek',
    },
  ];

  // handle row click
  const hanleRowClick = (row: AnyObject) => {
    setWeek({ year: row.year, badge: row.badge, week: row.week });
    setWeekModal(true);
  };

  return (
    <>
      <EnhancedTable
        disableQsFilters
        data={items}
        count={count}
        isTableDataLoading={isDataLoading}
        summaryRows={summary}
        selectIndex={selectIndex}
        defaultOrder={DEFAULT_ORDER}
        defaultOrderBy={DEFAULT_ORDER_BY}
        disableSelection={disableSelectionInTable ?? disableSelection}
        onSync={onSync}
        onRowClick={hanleRowClick}
        onDelete={disableDeletionInTable ? undefined : handleDelete}
        onUpdate={enableUpdationInTable ? handleUpdate : undefined}
        exportProps={exportProps}
        additionalWhereForExport={defaultFilter.where}
        tableName={t('payboard.historical_table_name')}
        headCells={headCells}
        filters={filters}
      />
      <>
        <PayboardDetailsTable
          title={t('payboard.details_title', week)}
          isOpen={weekModal}
          onClose={() => setWeekModal(false)}
          items={weekData.items}
          count={weekData.count}
          total={weekData.total}
          isTableDataLoading={isDetailsViewDataLoading}
        />
      </>
    </>
  );
}

export default PayboardHistoricalList;
