import React from 'react';
import { Typography, IconButton, Sheet, Button, useTheme } from '@mui/joy';
import EnhancedTableFilter, {
  ITableFilterData,
  ITableFilter,
} from './EnhancedTableFilter';
import { useTranslation } from 'react-i18next';
import {
  EnhancedTableExport,
  IEnhancedTableExportProps,
} from './EnhancedTableExport';
import { ComposeFiltersProps } from '../../modules/utils';
import { IWhere } from '../../modules/types';
import { ICellsSelectorProps } from './components/CellsSelector';
import { Columns02Svg, FilterFunnel02Svg } from '../svgIcons';
import { EnhancedTableColumnsSelector } from './EnhancedTableColumnsSelector';

export interface ICreateEntityBtnProps {
  title: string;
  onClick: () => void;
}
export interface EnhancedTableToolbarProps {
  title: string;
  onFilter: (filterData: ITableFilterData) => void;
  onFilterReset: () => void;
  createEntityBtnProps?: ICreateEntityBtnProps;
  filters?: ITableFilter[];
  initialFilterData?: ITableFilterData;
  exportProps?: Omit<
    IEnhancedTableExportProps,
    'composeFilters' | 'onClose' | 'isOpen'
  >;
  composeFilters: ComposeFiltersProps;
  cellsSelectorPros: ICellsSelectorProps;
  isQsFiltersDisabled?: boolean;
  additionalWhereForExport?: IWhere;
  ignoreGlobalSitesOnExport?: boolean;
  hideFilterResetButton?: boolean;
  hideTableName?: boolean;
  hideFilters?: boolean;
  filterComponent?: React.ReactElement;
}

/**
 * Component that contains actions like delete button, selected count information etc.
 */
const EnhancedTableToolbar = ({
  title,
  filters,
  initialFilterData,
  createEntityBtnProps,
  composeFilters,
  cellsSelectorPros,
  hideFilterResetButton,
  hideTableName,
  filterComponent,
  hideFilters,
  onFilterReset,
  isQsFiltersDisabled,
  onFilter,
  exportProps,
  additionalWhereForExport,
  ignoreGlobalSitesOnExport,
}: EnhancedTableToolbarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isFiltersVisible, setIsFiltersVisible] = React.useState(false);
  const [
    isColumnsSelectorVisible,
    setIsColumnsSelectorVisible,
  ] = React.useState(false);
  const [isExportVisible, setIsExportVisible] = React.useState(false);

  const handleFiltersSubmit = (filterData: ITableFilterData) => {
    onFilter(filterData);
  };

  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: title ? 'space-between' : 'flex-end',
        pb: 1,
        pt: 2,
      }}
    >
      {title && !hideTableName && (
        <Typography
          level="display_sm"
          fontWeight="medium"
          textColor="colors.text.text_primary.main"
          whiteSpace="nowrap"
          sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
        >
          {title}
        </Typography>
      )}

      <Sheet
        sx={{
          width: '100%',
          alignSelf: 'flex-end',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Sheet>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => setIsFiltersVisible((prev) => !prev)}
          >
            <FilterFunnel02Svg />
            <Typography
              sx={{ ml: 0.5 }}
              level="text_sm"
              fontWeight="semi_bold"
              textColor="colors.buttons.tertiary.button_tertiary_fg"
            >
              {t('common.filters')}
            </Typography>
          </IconButton>
          <IconButton
            onClick={() => setIsColumnsSelectorVisible((prev) => !prev)}
          >
            <Columns02Svg />
            <Typography
              sx={{ ml: 0.5 }}
              level="text_sm"
              fontWeight="semi_bold"
              textColor="colors.buttons.tertiary.button_tertiary_fg"
            >
              {t('common.columns')}
            </Typography>
          </IconButton>
        </Sheet>

        <Sheet
          sx={{
            ml: !exportProps && !createEntityBtnProps ? 0 : 3,
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'end',
            },
          }}
        >
          {exportProps && (
            <Button
              sx={{ [theme.breakpoints.down('sm')]: { mb: 0.5 } }}
              color="neutral"
              variant="outlined"
              onClick={() => setIsExportVisible((prev) => !prev)}
            >
              {t('common.export')}
            </Button>
          )}
          {createEntityBtnProps && (
            <Button sx={{ ml: 1 }} onClick={createEntityBtnProps.onClick}>
              {createEntityBtnProps.title}
            </Button>
          )}
        </Sheet>
      </Sheet>

      {filterComponent ? (
        filterComponent
      ) : !hideFilters ? (
        <EnhancedTableFilter
          isOpen={isFiltersVisible}
          onClose={() => setIsFiltersVisible(false)}
          filterProps={{
            filters,
            initialFilterData,
            onSubmit: handleFiltersSubmit,
            onFilterReset,
            onClose: () => setIsFiltersVisible(false),
            isQsFiltersDisabled,
            hideFilterResetButton,
          }}
        />
      ) : null}

      <EnhancedTableColumnsSelector
        isOpen={isColumnsSelectorVisible}
        onApply={cellsSelectorPros.onAllCellsVisibilityChange}
        onClose={() => setIsColumnsSelectorVisible(false)}
        cellsWithVisibilityFlag={cellsSelectorPros.cellsWithVisibilityFlag}
      />

      <EnhancedTableExport
        isOpen={isExportVisible}
        onClose={() => setIsExportVisible(false)}
        composeFilters={composeFilters}
        additionalWhereForExport={additionalWhereForExport}
        ignoreGlobalSitesOnExport={ignoreGlobalSitesOnExport}
        {...exportProps}
      />
    </Sheet>
  );
};

export default EnhancedTableToolbar;
