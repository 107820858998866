import React from 'react';
import { Box } from '@mui/joy';
import { SxProps } from '@mui/system/styleFunctionSx';

interface ITableToolbar {
  sx?: SxProps<object>;
}

export const TableToolbar: React.FC<ITableToolbar> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pb: 1,
        pt: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
