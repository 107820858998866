import React from 'react';
import { Box, useTheme } from '@mui/joy';
import { initHighchartsTheme } from 'src/themes/highcharts';
import { getIsGloballySelectedSitesInitialized } from 'src/modules/selectors/site';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from '../Layout/components/PageTour/MainContentLoader';
import { filterForGlobalSitesDropdown } from 'src/modules/utils/helpers/filter';
import { getSiteForGlobalDropdownRequest } from 'src/modules/actions';
import { isLoggedIn } from 'src/modules/selectors/auth';
import { isUserTokenExpired } from 'src/modules/utils/user';

interface IPackagesInitializerProps {
  children: React.ReactNode;
}

export const PackagesInitializer = ({
  children,
}: IPackagesInitializerProps) => {
  const dispatch = useDispatch();

  const isGloballySelectedSitesInitialized = useSelector(
    getIsGloballySelectedSitesInitialized,
  );

  const theme = useTheme();

  const isUserLoggedIn = useSelector(isLoggedIn);

  React.useEffect(() => {
    initHighchartsTheme({
      chartsBackground: theme.palette.colors.foreground.fg_white,
    });
  }, [theme.palette.colors.foreground.fg_white]);

  React.useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(
        getSiteForGlobalDropdownRequest({
          filter: filterForGlobalSitesDropdown,
        }),
      );
    }
  }, [dispatch, isUserLoggedIn]);

  if (
    isUserLoggedIn &&
    !isGloballySelectedSitesInitialized &&
    !isUserTokenExpired()
  ) {
    return (
      <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
        <MainContentLoader />;
      </Box>
    );
  }

  return <>{children}</>;
};
