import React from 'react';
import { subWeeks } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { YEAR_WEEK_ISO, format } from 'src/modules/utils/dateWrapper';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { SynchronizedCharts } from 'src/components/Charts/SynchronizedCharts';
import { concatenateYearWeek } from 'src/modules/utils/helpers/formatters';
import { IQSJWT } from '../../../modules/types';

interface IProps extends IQSJWT {
  url?: string;
}

export const WeeklySynchronizedCharts: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const today = new Date();
  const toDate = subWeeks(today, 1);
  const toYearWeek = format(YEAR_WEEK_ISO)(toDate);

  const fromDate = subWeeks(toDate, 13);
  const fromYearWeek = format(YEAR_WEEK_ISO)(fromDate);

  const [toYear, toWeek] = toYearWeek.split('-W');
  const [fromYear, fromWeek] = fromYearWeek.split('-W');

  const { fetchData, data } = useDataFetcherWithData<any>(
    props.url ?? '/financial-tracker/weekly-synchronized',
    [{ series: [], categories: [] }],
  );

  const series = React.useMemo(() => {
    return (data as any)?.series
      ? (data as any)?.series.map((item: any) => ({
          ...item,
          unit: item.name !== 'numberOfSites' ? '$' : '',
          title: {
            text: t(`financial_tracker.${item.name}`),
          },
        }))
      : [];
  }, [data, t]);
  React.useEffect(() => {
    fetchData({
      filter: {
        order: ['yearWeek asc'],
        where: {
          and: [
            {
              yearWeek: {
                gte: concatenateYearWeek({
                  year: Number(fromYear),
                  week: Number(fromWeek),
                }),
              },
            },
            {
              yearWeek: {
                lt: concatenateYearWeek({
                  year: Number(toYear),
                  week: Number(toWeek),
                }),
              },
            },
          ],
        },
      },
      ...(props.token ? { token: props.token } : {}),
      ...(props['x-auth-type']
        ? { ['x-auth-type']: props['x-auth-type'] }
        : {}),
    });
  }, [fetchData, fromWeek, fromYear, props, toWeek, toYear]);

  return (
    <SynchronizedCharts
      series={series}
      categories={(data as any)?.categories ?? []}
      chartHeight={230}
    />
  );
};
