import { createAction } from 'redux-act';
import { PolicyConfig } from '../types';

export const getResourcesRequest = createAction(
  'Make request to get resources',
);
export const getResourcesSuccess = createAction<PolicyConfig[]>(
  'Resources have been fetched successfully',
);
export const getResourcesFailed = createAction('Cannot fetch resources');
