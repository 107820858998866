import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateIssueType,
  IdsArray,
  IFilter,
  IIssueTypeModel,
  IUpdateIssueType,
  PayloadWithFilters,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushIssueTypeState = createAction(
  'Flush issueType data in store',
);

// Creation actions
export const createIssueTypeRequest = createAction<
  PayloadWithFilters<ICreateIssueType>
>('Make request to create a new issueType');
export const createIssueTypeSuccess = createAction<IIssueTypeModel>(
  'New issueType has been created successfully',
);
export const createIssueTypeFailed = createAction(
  'Cannot create a new issueType',
);

// Fetch issueTypes actions
export const getIssueTypeListRequest = createAction<IFilter>(
  'Make request to fetch issueType list',
);
export const getIssueTypeListSuccess = createAction<IIssueTypeModel[]>(
  'IssueTypes list has been fetched successfully',
);
export const getIssueTypeListFailed = createAction(
  'Cannot fetch issueType list',
);

// get issueTypes count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getIssueTypeCountRequest = createAction<IFilter>(
  'Make request to get issueType count',
);
export const getIssueTypeCountSuccess = createAction<ICountResponse>(
  'IssueTypes count has been fetched successfully',
);
export const getIssueTypeCountFailed = createAction(
  'Cannot get issueType count',
);

// delete actions
export const deleteIssueTypeRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete issueTypes');
export const deleteIssueTypeSuccess = createAction('Delete issueTypes success');
export const deleteIssueTypeFailed = createAction('Cannot delete issueTypes');

// Updating actions
export const updateIssueTypesRequest = createAction<
  PayloadWithFilters<IUpdateIssueType[]>
>('Make request to update issueTypes');
export const updateIssueTypesSuccess = createAction(
  'Update issueTypes success',
);
export const updateIssueTypesFailed = createAction('Cannot update issueTypes');

// Fetch combobox list actions
export const getIssueTypeComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of issueTypes',
);
export const getIssueTypeComboboxListSuccess = createAction<
  Partial<IIssueTypeModel>[]
>('Combobox list of issueTypes has been fetched successfully');
export const getIssueTypeComboboxListFailed = createAction(
  'Cannot fetch combobox issueTypes list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('IssueType').takeExportActions();

export const exportIssueTypeRequest = exportReportRequest;
export const exportIssueTypeSuccess = exportReportSuccess;
export const exportIssueTypeFailed = exportReportFailed;
