import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  ICpuBasedBaselineWithRelations,
  ICpuBasedBaselineState,
  IdsArray,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

const defaultState: ICpuBasedBaselineState = {
  list: [],
  refreshKey: 0,
  count: 0,
};

const flushCpuBasedBaselineState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

const createCpuBasedBaselineRequest = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const createCpuBasedBaselineSuccess = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});
const createCpuBasedBaselineFailed = (state: ICpuBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const getCpuBasedBaselineListRequest = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getCpuBasedBaselineListSuccess = (
  state: ICpuBasedBaselineState,
  payload: ICpuBasedBaselineWithRelations[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});
const getCpuBasedBaselineListFailed = (state: ICpuBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const getCpuBasedBaselineCountRequest = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getCpuBasedBaselineCountSuccess = (
  state: ICpuBasedBaselineState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const getCpuBasedBaselineCountFailed = (state: ICpuBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const deleteCpuBasedBaselineRequest = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteCpuBasedBaselineSuccess = (
  state: ICpuBasedBaselineState,
  payload: IdsArray,
) => ({
  ...state,
  list: state.list.filter(({ id }) => !payload.includes(id)),
  error: false,
  is_data_requested: false,
});
const deleteCpuBasedBaselineFailed = (state: ICpuBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const updateCpuBasedBaselinesRequest = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateCpuBasedBaselinesFailed = (state: ICpuBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const manageCpuBasedBaselinesRequest = (state: ICpuBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const manageCpuBasedBaselinesFailed = (state: ICpuBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<ICpuBasedBaselineState>();

const exportCpuBasedBaselineRequest = exportRequest;
const exportCpuBasedBaselineSuccess = exportSuccess;
const exportCpuBasedBaselineFailed = exportFailed;

/**
 * CpuBasedBaseline reducer
 */
export const cpuBasedBaseline = createReducer<ICpuBasedBaselineState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushCpuBasedBaselineState, flushCpuBasedBaselineState)
  // creation actions
  .on(actions.createCpuBasedBaselineRequest, createCpuBasedBaselineRequest)
  .on(actions.createCpuBasedBaselineSuccess, createCpuBasedBaselineSuccess)
  .on(actions.createCpuBasedBaselineFailed, createCpuBasedBaselineFailed)
  // get list actions
  .on(actions.getCpuBasedBaselineListRequest, getCpuBasedBaselineListRequest)
  .on(actions.getCpuBasedBaselineListSuccess, getCpuBasedBaselineListSuccess)
  .on(actions.getCpuBasedBaselineListFailed, getCpuBasedBaselineListFailed)
  // get count actions
  .on(actions.getCpuBasedBaselineCountRequest, getCpuBasedBaselineCountRequest)
  .on(actions.getCpuBasedBaselineCountSuccess, getCpuBasedBaselineCountSuccess)
  .on(actions.getCpuBasedBaselineCountFailed, getCpuBasedBaselineCountFailed)
  // deletion actions
  .on(actions.deleteCpuBasedBaselineRequest, deleteCpuBasedBaselineRequest)
  .on(actions.deleteCpuBasedBaselineSuccess, deleteCpuBasedBaselineSuccess)
  .on(actions.deleteCpuBasedBaselineFailed, deleteCpuBasedBaselineFailed)
  // updating actions
  .on(actions.updateCpuBasedBaselinesRequest, updateCpuBasedBaselinesRequest)
  .on(actions.updateCpuBasedBaselinesFailed, updateCpuBasedBaselinesFailed)
  // manage actions
  .on(actions.manageCpuBasedBaselinesRequest, manageCpuBasedBaselinesRequest)
  .on(actions.manageCpuBasedBaselinesFailed, manageCpuBasedBaselinesFailed)
  // export actions
  .on(actions.exportCpuBasedBaselineRequest, exportCpuBasedBaselineRequest)
  .on(actions.exportCpuBasedBaselineSuccess, exportCpuBasedBaselineSuccess)
  .on(actions.exportCpuBasedBaselineFailed, exportCpuBasedBaselineFailed);

export default cpuBasedBaseline;
