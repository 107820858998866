import React from 'react';
import {
  ChartsDashboard,
  ChartsDashboardBody,
  ChartsDashboardChart,
  ChartsDashboardChartsContainer,
} from 'src/components/ChartsDashboard';
import { HRChangesFilterPanel } from './HRChangesFilterPanel';
import { HRChangesMainIndicatorsWidget } from './HRChangesMainIndicatorsWidget';
import { Box } from '@mui/joy';
import { HRChangesAddedBySite } from './HRChangesAddedBySite';
import { HRChangesLostBySite } from './HRChangesLostBySite';
import { HRChangesTerminatedBySiteAndReason } from './HRChangesTerminatedBySiteAndReason';
import { HRChangesActiveBySiteAndStaffingProvider } from './HRChangesActiveBySiteAndStaffingProvider';

const hrDashboardFilterInitialValue = {
  siteId: undefined,
};

export interface IHRChangesFilters {
  siteId?: number;
}

export const HRChanges = () => {
  const [filters, setFilters] = React.useState<IHRChangesFilters>({
    siteId: undefined,
  });

  return (
    <>
      <HRChangesFilterPanel
        filterInitialData={filters}
        onApplyFilters={setFilters}
        onRemoveFilters={() => setFilters(hrDashboardFilterInitialValue)}
      />

      <Box sx={{ pt: 1 }}>
        <HRChangesMainIndicatorsWidget />
      </Box>

      <ChartsDashboard>
        <ChartsDashboardBody>
          <ChartsDashboardChartsContainer>
            <ChartsDashboardChart>
              <HRChangesAddedBySite siteId={filters.siteId} />
            </ChartsDashboardChart>
            <ChartsDashboardChart>
              <HRChangesTerminatedBySiteAndReason siteId={filters.siteId} />
            </ChartsDashboardChart>
          </ChartsDashboardChartsContainer>
          <ChartsDashboardChartsContainer>
            <ChartsDashboardChart>
              <HRChangesLostBySite siteId={filters.siteId} />
            </ChartsDashboardChart>
            <ChartsDashboardChart>
              <HRChangesActiveBySiteAndStaffingProvider
                siteId={filters.siteId}
              />
            </ChartsDashboardChart>
          </ChartsDashboardChartsContainer>
        </ChartsDashboardBody>
      </ChartsDashboard>
    </>
  );
};
