import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { IStoreState } from '../types';

export const getFetchedTimeStamp = ({ area }: IStoreState) =>
  area.list.fetchedTimeStamp;

export const getAreaRawList = ({ area }: IStoreState) => area.rawList;

export const getArea = ({ area }: IStoreState) => area;

// we assume getting Areas for certain site id
export const getAreaMap = ({ area }: IStoreState) => area.list.map[0] ?? [];

export const getAllowedProperties = ({ area }: IStoreState) =>
  area.allowedProperties;

export const getLoading = ({ area }: IStoreState) => area.isLoading;

export const getForUpdateData = createSelector(getAreaMap, (areaMap) => {
  if (isEmpty(areaMap)) {
    return { initialValues: {}, areaNameList: [] };
  }
  const globalAreaObject = areaMap.map;
  const areaNameList: string[] = [];
  const formValues = {};

  for (const areaName in globalAreaObject) {
    areaNameList.push(areaName);
    formValues[areaName] = [];

    for (const obj of globalAreaObject[areaName]) {
      const fieldValues = [];
      for (const [key, value] of Object.entries(obj)) {
        fieldValues.push({ areaName: key, areaValue: value });
      }
      formValues[areaName].push(fieldValues);
    }
  }

  return { initialValues: formValues, areaNameList };
});

export const getAreaMeta = createSelector(getAreaMap, ({ id, siteId }) => ({
  id,
  siteId,
}));

export const getAreaNamesMappedToSiteId = createSelector(
  getAreaRawList,
  (areaList) => {
    return areaList.reduce<{ [siteId: number]: Array<string> }>((acc, area) => {
      const areaNames = Object.keys(area.map);

      acc[area.siteId] = areaNames;

      return acc;
    }, {});
  },
);
