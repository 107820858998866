import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthWeeklyUphBySiteAndSku',
);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyUphBySiteAndSkuListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteAndSkuListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteAndSkuListFailed,
} = actionCreator.takeListActions<ICardinalHealthWeeklyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteAndSkuCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteAndSkuCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteAndSkuCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyUphBySiteAndSkuRequest,
  exportReportSuccess: exportCardinalHealthWeeklyUphBySiteAndSkuSuccess,
  exportReportFailed: exportCardinalHealthWeeklyUphBySiteAndSkuFailed,
} = new ExportActionCreator(
  'CardinalHealthWeeklyUphBySiteAndSku',
).takeExportActions();
