import { JSONSchemaType } from 'ajv';
import { IUpdateStandard } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { generateCreateStandardScheme } from './create-standard.scheme';

export const generateUpdateStandardScheme = (
  metatags: string[],
): JSONSchemaType<IUpdateStandard[]> => {
  const createStandardsScheme = generateCreateStandardScheme(metatags);

  return {
    type: 'array',
    items: {
      ...createStandardsScheme,
      type: 'object',
      required: createStandardsScheme.required,
      properties: {
        ...createStandardsScheme.properties,
        id: {
          type: 'number',
          isNotEmpty: true,
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
          },
        },
      },
    },
  };
};
