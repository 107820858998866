import React from 'react';
import { Sheet, useTheme } from '@mui/joy';
import { Copyright } from '../../Copyright';
import LogoBlack from '../../../assets/images/logo-black.png';

interface IAuthLayoutProps {
  children: React.ReactNode;
}

const marginLeftOffset = '-20px';

export const AuthLayout = ({ children }: IAuthLayoutProps) => {
  const theme = useTheme();

  const downMd = theme.breakpoints.down('md');

  return (
    <Sheet
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
      }}
    >
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexGrow: 1,
          flexBasis: '100%',
          pl: 10,
          pr: 6,
          py: 5,
          [downMd]: {
            pl: 5,
            pr: 5,
            py: 5,
          },
        }}
      >
        <Sheet
          component="img"
          src={LogoBlack}
          sx={{
            position: 'relative',
            left: marginLeftOffset,
            width: '120px',
            height: '40px',
          }}
        />

        <Sheet
          sx={{
            maxWidth: '320px',
            width: '100%',
            alignSelf: 'flex-start',
            [downMd]: { alignSelf: 'center' },
          }}
        >
          {children}
        </Sheet>

        <Sheet sx={{ left: marginLeftOffset }}>
          <Copyright />
        </Sheet>
      </Sheet>
      <Sheet
        sx={{
          flexGrow: 1,
          flexBasis: '100%',
          backgroundImage: 'url(/images/auth_layout_bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100%',
          [downMd]: {
            display: 'none',
          },
        }}
      />
    </Sheet>
  );
};
