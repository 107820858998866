import React from 'react';
import { Box } from '@mui/joy';

export const TableActions: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};
