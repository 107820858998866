import React from 'react';
import { Sheet } from '@mui/joy';
import { TopToolbarUserCommonData } from './TopToolbarUserCommonData';
import { useMainLayoutContext } from '../Layout/MainLayout/MainLayoutContext';

export const TopToolbarRegular = () => {
  const { headerHeight } = useMainLayoutContext();

  return (
    <Sheet
      sx={{
        px: 2,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: 'inherit',
        height: headerHeight,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'colors.border.border_secondary',
      }}
    >
      <TopToolbarUserCommonData />
    </Sheet>
  );
};
