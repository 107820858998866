import { JSONSchemaType } from 'ajv';
import { ICreateCpuBasedBaseline } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createCpuBasedBaselineScheme: JSONSchemaType<ICreateCpuBasedBaseline> = {
  type: 'object',
  required: ['siteId', 'minCPU', 'maxCPU', 'baseline'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    minCPU: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    maxCPU: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    baseline: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
  },
};
