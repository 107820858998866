import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormActions } from '../../../components/Form/FormActions';
import RolesCombobox from '../../../components/Formik/comboboxes-with-entities/RolesCombobox';
import { createPolicyByOperationRequest } from '../../../modules/actions';
import {
  MultiRowForm,
  MultiRowFormContainer,
  MultiRowFormDeleteRow,
  MultiRowFormField,
  MultiRowFormRow,
} from '../../../components/MultiRowForm';
import { MultiRowFormAddRow } from '../../../components/MultiRowForm/MultiRowFormAddRow';
import ComboBox from 'src/components/ComboBox';
import { getManageEntitiesConfigData } from 'src/modules/selectors/page-setting.selector';
import {
  MANAGE_ENTITIES_CONCATENATED_ID_SEPARATOR,
  manageEntitiesConfig,
} from 'src/config/manageEntitiesConfig';
import { getIsPoliciesCreateByConfigInProgress } from 'src/modules/selectors/policy';

interface ICreatePolicyByOperationFormikData {
  role: string;
  operation: { id: string; name: string } | null;
}

export const PoliciesCreateByOperation = () => {
  const { t } = useTranslation();

  const manageEntitiesConfigData = useSelector(getManageEntitiesConfigData);

  const isPoliciesCreateByConfigInProgress = useSelector(
    getIsPoliciesCreateByConfigInProgress,
  );

  const operationOptionsIds = Object.keys(manageEntitiesConfigData)
    .map((key) => {
      const [entityName, operation] = key.split(
        MANAGE_ENTITIES_CONCATENATED_ID_SEPARATOR,
      );

      if (
        manageEntitiesConfig[entityName] &&
        manageEntitiesConfig[entityName][operation]
      ) {
        return {
          id: key,
          name: manageEntitiesConfig[entityName][operation].title,
        };
      }

      return;
    })
    .filter(Boolean);
  const initialValues: ICreatePolicyByOperationFormikData = {
    operation: null,
    role: '',
  };

  // create dispatcher
  const dispatcher = useDispatch();

  const formik = useFormik<Array<ICreatePolicyByOperationFormikData>>({
    initialValues: [initialValues],
    // validate,
    onSubmit: (data) => {
      dispatcher(
        createPolicyByOperationRequest(
          data.map((item) => ({
            ...item,
            operation: item.operation?.id ?? '',
          })),
        ),
      );
    },
  });

  return (
    <MultiRowFormContainer>
      <MultiRowForm onSubmit={formik.handleSubmit} noValidate>
        {formik.values.map((_, index) => (
          <MultiRowFormRow key={index}>
            <MultiRowFormField
              fieldId={`${index}.operation`}
              fieldLabel={t('policies.operation')}
              renderField={({ id, label }) => (
                <ComboBox
                  id={id}
                  name={id}
                  placeholder={label}
                  options={operationOptionsIds as any}
                  value={formik.values[id]}
                  onChange={(_, value) => {
                    formik.setFieldValue(id, value);
                  }}
                />
              )}
            />
            <MultiRowFormField
              fieldId={`${index}.role`}
              fieldLabel={t('policies.role')}
              renderField={({ id, label }) => (
                <RolesCombobox
                  id={id}
                  name={id}
                  required
                  placeholder={label}
                  fieldAsId="key"
                  formik={formik}
                />
              )}
            />
            <MultiRowFormDeleteRow formik={formik} index={index} />
          </MultiRowFormRow>
        ))}

        <FormActions
          submitBtnTitle={t('common.create')}
          isLoading={isPoliciesCreateByConfigInProgress}
        />

        <MultiRowFormAddRow
          formik={formik}
          nullishValues={{ operation: '', role: '' }}
        />
      </MultiRowForm>
    </MultiRowFormContainer>
  );
};
