import React from 'react';
import { Drawer } from '@mui/joy';
import {
  useFetchSitesWithIncentiveSettingsCombobox,
  useValidate,
} from 'src/modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { IIncentiveCreateRequest } from 'src/modules/types/incentive';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { createIncentiveScheme } from 'src/modules/schemes';
import { Api } from 'src/modules/utils';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { useSelector } from 'react-redux';
import { getSitesWithIncentiveSettingsComboboxList } from 'src/modules/selectors/site';

const initialValues: IIncentiveCreateRequest = {
  // Required properties
  siteId: NaN,
  week: NaN,
  year: NaN,
};

interface IPayboardInceitiveProps {
  shouldHaveIncentiveSettings?: boolean;
  isOpen: boolean;
  onCreateStart: () => void;
  onCreateFinish: () => void;
  onClose: () => void;
}

export const PayboardInceitiveCreate: React.FC<IPayboardInceitiveProps> = ({
  isOpen,
  onClose,
  onCreateStart,
  onCreateFinish,
  shouldHaveIncentiveSettings,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(createIncentiveScheme);

  const fetchSitesCombobox = useFetchSitesWithIncentiveSettingsCombobox();
  React.useEffect(() => {
    if (shouldHaveIncentiveSettings) {
      fetchSitesCombobox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sites = useSelector(getSitesWithIncentiveSettingsComboboxList);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (data) => {
      onCreateStart();
      await Api.Incentive.create(data);
      // create incentive
      onCreateFinish();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('payboard.incentive.create')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            {!shouldHaveIncentiveSettings && (
              <SitesComboBox
                id="siteId"
                required={true}
                formik={formik}
                label={t('payboard.site')}
                placeholder={t('payboard.site')}
              />
            )}
            {shouldHaveIncentiveSettings && (
              <FormikCombobox
                formik={formik}
                options={sites}
                label={t('payboard.site')}
                placeholder={t('payboard.site')}
                id="siteId"
                required={true}
              />
            )}
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="year"
              label={t('payboard.year')}
              name="year"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id="week"
              label={t('payboard.week')}
              name="week"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
