import React, { useMemo } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  AnyObject,
  ICountResponse,
  IFilterData,
  IFilterWhere,
  IWorkdaysModel,
} from 'src/modules/types';
import { defaultOrderDetails } from 'src/modules/utils/helpers/filter';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  IFilterInclude,
  IHeadCellWithOrderConfig,
} from 'src/modules/types/table';
import { useDataFetcherWithData } from './common/reports';
import { useGenerateHeadCellsData } from './table';
import { useDynamicBaseReport } from './reports.hooks';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { useShiftInclusion } from './shifts';

export const useWorkdaysInclusion = (
  appliedFilters?: IWorkdaysFilterPanelFilters,
) => {
  const include = useShiftInclusion();

  return React.useMemo(
    () => [
      {
        relation: 'shift',
        scope: {
          include,
          ...(appliedFilters?.shiftId
            ? {
                where: {
                  id: appliedFilters?.shiftId,
                },
              }
            : {}),
        },
      },
    ],
    [appliedFilters?.shiftId, include],
  );
};

export const useWorkdaysDefaultFilters = () => {
  const include = useWorkdaysInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};

export const useWorkdaysPermissions = () => {
  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.workdays.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.workdays.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.workdays.delete.id,
  );

  return {
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
  };
};

export interface IWorkdaysFilterPanelFilters {
  shiftId?: number;
  mon?: boolean;
  tue?: boolean;
  wed?: boolean;
  thu?: boolean;
  fri?: boolean;
  sat?: boolean;
  sun?: boolean;
}

export const useWorkdaysTableFiltersConfiguration = () =>
  React.useMemo(
    () => ({
      shiftName: {
        value: '',
        property: 'shift.name',
        operator: 'like' as const,
      },
      ...['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].reduce(
        (acc, cur) => {
          acc[cur] = {
            value: '',
            property: cur,
            operator: 'eq' as const,
          };
          return acc;
        },
        {},
      ),
    }),
    [],
  );

export const useWorkdaysComposeWhere = (where: Partial<IWorkdaysModel>) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].reduce(
        (acc, cur) => {
          if (where[cur]) {
            acc[cur] = where[cur];
          }
          return acc;
        },
        {},
      ),
    }),
    [where],
  );
};

export const useWorkdaysFilter = (
  appliedFilters: IWorkdaysFilterPanelFilters,
) => {
  const _where = useWorkdaysComposeWhere(appliedFilters);
  const _include = useWorkdaysInclusion(appliedFilters);

  return React.useMemo<IFilterData>(
    () => ({
      where: _where,
      include: _include,
      order: ['id desc'],
    }),
    [_include, _where],
  );
};

export const useWorkdaysTableInclusionObject = () => {
  return React.useMemo(
    () => ({
      shift: {
        relationType: 'inner',
      },
    }),
    [],
  );
};

export const useWorkdays = (
  reportUrl: string,
  where: IFilterWhere,
  include: IFilterInclude[],
) => {
  const { t } = useTranslation();
  const inclusionObj = useWorkdaysTableInclusionObject();

  const initialData: AnyObject[] = [];

  const {
    data,
    fetchData,
    isDataLoading,
    removeDataByIds,
  } = useDataFetcherWithData(reportUrl, initialData);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'shift.name',
        orderConfig: { orderBy: 'shift.name' },
        label: t('workdays.shift_name'),
      },
      ...['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].reduce(
        (acc, cur) => {
          acc.push({
            id: cur,
            orderConfig: { orderBy: cur },
            label: t(`common.${cur}`),
          });
          return acc;
        },
        [] as IHeadCellWithOrderConfig[],
      ),
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const report = useDynamicBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'id',
    omitCount: false,
    include,
    inclusionObj,
    data: data as AnyObject[],
    fetchData,
    isDataLoading,
    removeDataByIds,
    countData,
    fetchCountData,
    isCountDataLoading,
    headCells,
    headCellsOrderDetails,
  });

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(report.data, (item) => {
        return {
          ...item,
          shift: {
            ...item.shift,
            name: item.shift?.name && (
              <div>
                {item.shift.name}
                <br />({item.shift.site?.name})
              </div>
            ),
          },
          mon: item.mon ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          tue: item.tue ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          wed: item.wed ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          thu: item.thu ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          fri: item.fri ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          sat: item.sat ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
          sun: item.sun ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
        };
      }),
    [report.data],
  );

  return {
    ...report,
    data: computedList,
  };
};
