import React from 'react';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps,
} from '@mui/material';

export const ToggleButton = (props: ToggleButtonProps) => {
  return (
    <MuiToggleButton
      sx={{
        '&:not(:last-of-type)': {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          borderColor: '#F1F2F4',
        },
        '&:not(:first-of-type)': {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          borderColor: '#F1F2F4',
        },
        '& .MuiTypography-root': {
          fontSize: '10px',
        },
        padding: '4px',
      }}
      {...props}
    />
  );
};
