import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  IStoreState,
  IdsArray,
  AnyObject,
  IUpdatePositionRequest,
} from 'src/modules/types';
import { getPositionsByIds } from 'src/modules/selectors/position';
import { updatePositionsScheme } from 'src/modules/schemes/positions';
import { useFormikInUpdateForm } from 'src/modules/utils/hooks/common/forms';
import { updatePositionsDesiredEmployeesNumberRequest } from 'src/modules/actions';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';

interface IPositionsUpdateEmployeesNumberProps {
  isOpen: boolean;
  onClose: () => void;
  entitiesToUpdateIds: IdsArray;
  filterList: AnyObject;
  filterCount: AnyObject;
}

export const PositionsUpdateEmployeesNumber = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  entitiesToUpdateIds,
}: IPositionsUpdateEmployeesNumberProps) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const positions = useSelector((state) =>
    getPositionsByIds(state as IStoreState)(entitiesToUpdateIds),
  );

  const validate = useValidate(updatePositionsScheme, {
    allowUnionTypes: true,
  });

  const formik = useFormikInUpdateForm({
    initialValues: positions,
    validate,
    onSubmit: (
      data: Pick<IUpdatePositionRequest, 'id' | 'desiredEmployeesNumber'>[],
    ) => {
      dispatcher(
        updatePositionsDesiredEmployeesNumberRequest({
          data: data.map(({ id, desiredEmployeesNumber }) => ({
            id,
            desiredEmployeesNumber,
          })),
          filters: {
            list: filterList,
            count: filterCount,
          },
        }),
      );
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('positions.updateDesiredEmployeesNumber')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {positions.map((position, index) => (
            <DrawerBodySectionContent key={position.id}>
              <DrawerBodySectionTitle>{position.name}</DrawerBodySectionTitle>
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.desiredEmployeesNumber`}
                  name={`${index}.desiredEmployeesNumber`}
                  label={t('positions.desiredEmployeesNumber')}
                  autoComplete="desiredEmployeesNumber"
                  formik={formik}
                  type="number"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
