import React from 'react';
import InputMask from 'react-input-mask';
import {
  Typography,
  Input,
  InputProps,
  FormHelperTextProps,
  FormLabelProps,
  FormControl,
  FormLabel,
  useTheme,
  FormHelperText,
} from '@mui/joy';

export interface InputPhoneNumberProps extends InputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mask?: string;
  placeholder?: string;

  label?: string;
  helperText?: string;
  helperTextProps?: Omit<FormHelperTextProps, 'children'>;
  labelProps?: Omit<FormLabelProps, 'label'>;
}

const normalizePhoneNumber = (formatted: string): string => {
  // Remove all non-digit characters to get the raw phone number
  return formatted.replace(/\D/g, ''); // Only digits
};

const maskForUSAPhoneNumber = '+1 (999) 999-9999';
const placeholderForUSAPhoneNumber = '+1 (123) 456-7890';

export const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({
  label,
  error,
  labelProps,
  helperText,
  helperTextProps,
  slotProps,
  value,
  onChange,
  mask = maskForUSAPhoneNumber,
  placeholder = placeholderForUSAPhoneNumber,
  ...inputProps
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = normalizePhoneNumber(event.target.value);
    onChange(event);
  };

  const theme = useTheme();

  return (
    <FormControl error={error}>
      {label && (
        <FormLabel {...labelProps}>
          <Typography
            level="text_sm"
            fontWeight="medium"
            textColor="colors.text.text_secondary.main"
          >
            {label}
          </Typography>
        </FormLabel>
      )}
      <InputMask
        mask={mask}
        value={value}
        maskChar={'_'}
        onChange={handleChange}
      >
        {(propsFromInputMask: any) => (
          <Input
            sx={{
              '&::placeholder': {
                fontWeight: theme.fontWeight.regular,
                color: theme.palette.colors.text.text_placeholder,
                ...theme.typography.text_md,
              },
            }}
            slotProps={{
              ...slotProps,
              input: {
                ...slotProps?.input,
              },
            }}
            placeholder={placeholder}
            {...inputProps}
            {...propsFromInputMask}
          />
        )}
      </InputMask>
      {helperText && (
        <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
