import React from 'react';
import { Box, IconButton, IconButtonProps, Typography } from '@mui/joy';
import { RefreshCw03Svg } from 'src/components/svgIcons';
import { useTranslation } from 'react-i18next';
import { FilterPanelContainer } from './FilterPanelActionContainer';
import { ReportSyncDrawer } from 'src/components/Reports/ReportSyncDrawer';
import { ClientKey } from 'src/modules/constants';
import { useSelector } from 'react-redux';
import { getProcessStatusList } from 'src/modules/selectors/processStatus';
import { keyframes } from '@mui/material';

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

type IFilterPanelSyncProps = IconButtonProps & {
  client?: ClientKey;
};

export const FilterPanelSync = ({
  client,
  ...iconButtonProps
}: IFilterPanelSyncProps) => {
  const { t } = useTranslation();

  const [isSyncDrawerOpened, setIsSyncDrawerOpened] = React.useState(false);

  const [isSyncInProgress, setIsSyncInProgress] = React.useState(false);
  const processStatusList = useSelector(getProcessStatusList);

  React.useEffect(() => {
    const isSyncProcessFinished = processStatusList.some(
      ({ precessKey }) => precessKey === 'productionDataSync',
    );
    if (isSyncProcessFinished) {
      setIsSyncInProgress(false);
    }
  }, [processStatusList]);

  return (
    <>
      <FilterPanelContainer>
        <IconButton
          {...iconButtonProps}
          onClick={() => setIsSyncDrawerOpened(true)}
        >
          <Box sx={{ display: 'flex', mr: 0.5 }}>
            <Box
              sx={[
                { display: 'flex' },
                isSyncInProgress && {
                  animation: `${rotateAnimation} 1s linear infinite`,
                  color: 'green',
                },
              ]}
            >
              <RefreshCw03Svg />
            </Box>
          </Box>
          <Typography
            level="text_sm"
            fontWeight="semi_bold"
            textColor="colors.buttons.tertiary.button_tertiary_fg"
          >
            {t('common.sync')}
          </Typography>
        </IconButton>
      </FilterPanelContainer>

      <ReportSyncDrawer
        client={client}
        isOpen={isSyncDrawerOpened}
        onClose={() => setIsSyncDrawerOpened(false)}
        onSynchronized={() => setIsSyncInProgress(true)}
      />
    </>
  );
};
