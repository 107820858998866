import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeadCell } from '../../components/EnhancedTable';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import ReportWithOwnState from '../../components/Reports/ReportWithOwnState';
import { clients } from '../../modules/constants';

const DEFAULT_ORDER_BY = 'yearWeek';
export const WeeklyStaticCostPlus = () => {
  const { t } = useTranslation();

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'year',
      disablePadding: false,
      label: t('productions_uph_reports.year'),
    },
    {
      id: 'week',
      disablePadding: false,
      label: t('productions_uph_reports.week'),
    },
    {
      id: 'yearWeek',
      disablePadding: false,
      label: t('productions_uph_reports.year_week'),
    },
    {
      id: 'site.name',
      disablePadding: false,
      label: t('productions_uph_reports.site.name'),
    },
    {
      id: 'department',
      disablePadding: false,
      label: t('productions_uph_reports.department'),
    },
    {
      id: 'priceFmt',
      disablePadding: false,
      label: t('productions_uph_reports.price'),
    },
    {
      id: 'overtimePriceFmt',
      disablePadding: false,
      label: t('dorman_reports.overtime_price'),
    },
    {
      id: 'regularHoursFmt',
      disablePadding: false,
      label: t('productions_uph_reports.regular_hours'),
    },
    {
      id: 'overtimeHoursFmt',
      disablePadding: false,
      label: t('productions_uph_reports.overtime_hours'),
    },
    {
      id: 'regularPayFmt',
      disablePadding: false,
      label: t('productions_uph_reports.regular_pay'),
    },
    {
      id: 'overtimePayFmt',
      disablePadding: false,
      label: t('dorman_reports.overtime_pay'),
    },
    {
      id: 'totalFmt',
      disablePadding: false,
      label: t('productions_uph_reports.total'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'year',
      label: t('productions_uph_reports.year'),
      operator: 'eq',
    },
    {
      name: 'week',
      label: t('productions_uph_reports.week'),
      operator: 'eq',
    },
    {
      name: 'siteId',
      label: t('productions_uph_reports.site.name'),
      type: 'comboboxSites',
      operator: 'eq',
      clientKey: clients.brookfield,
    },
  ];

  return (
    <ReportWithOwnState
      reportUrl="/static-cost-plus/client/brookfield"
      tableName={t('brookfield_reports.table_names.weekly_static_cost_plus')}
      headCells={headCells}
      filters={filters}
      reportType="weekly"
      defaultOrderBy={DEFAULT_ORDER_BY}
    />
  );
};
