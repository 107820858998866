import { memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateCostPlusSettings } from '../types';

export const getCostPlusSettingsRefreshKey = (state: IStoreState) =>
  state.costPlusSettings.refreshKey;

/**
 * Get server error
 * @param costPlusSettings - State costPlusSettings
 */
export const getServerError = ({ costPlusSettings }: IStoreState) =>
  costPlusSettings.error;

/**
 * Get costPlusSettings list
 * @param costPlusSettings - State costPlusSettings
 */
export const getCostPlusSettingsList = ({ costPlusSettings }: IStoreState) =>
  costPlusSettings.list;

/**
 * Get cosrtPlusSettings by array of ids
 * @param cosrtPlusSettings - State cosrtPlusSettings
 */
export const getCostPlusSettingsByIds = createSelector(
  getCostPlusSettingsList,
  (cosrtPlusSettings) =>
    memoize((ids: IdsArray) => {
      const initial: IUpdateCostPlusSettings[] = [];
      return reduce(
        cosrtPlusSettings,
        (acc, cur) => {
          if (ids.includes(cur.id)) {
            const costPlusSettingsData = omit(cur, ['site']);
            costPlusSettingsData.isActive = !!costPlusSettingsData.isActive;
            acc.push(costPlusSettingsData as IUpdateCostPlusSettings);
          }
          return acc;
        },
        initial,
      );
    }),
);

/**
 * Get costPlusSettings count
 * @param costPlusSettings - State costPlusSettings
 */
export const getCostPlusSettingsCount = ({ costPlusSettings }: IStoreState) =>
  costPlusSettings.count;

/**
 * Get costPlus types
 * @param costPlusSettings - State costPlusSettings
 */
export const getCostPlusTypes = ({ costPlusSettings }: IStoreState) =>
  costPlusSettings.costPlusTypes;

export const getIsCostPlusSettingsDataLoading = ({
  costPlusSettings: {
    is_data_requested,
    is_cost_plus_settings_list_loading,
    is_cost_plus_settings_count_loading,
  },
}: IStoreState) =>
  is_data_requested ||
  is_cost_plus_settings_list_loading ||
  is_cost_plus_settings_count_loading;
