import React from 'react';
import { useDispatch } from 'react-redux';
import { createArea } from 'src/modules/actions';
import { IAreaFormData, IFilterData } from 'src/modules/types';
import { AreaForm } from './AreaForm';

interface ICreateAreaFormProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
}

export const CreateAreaForm = ({
  isOpen,
  onClose,
  filterList,
}: ICreateAreaFormProps) => {
  const dispatch = useDispatch();

  const onSubmit = ({ siteId, area, values }: IAreaFormData) => {
    if (siteId && area) {
      const dataToSend = {
        siteId,
        values: {
          [area]: values.map((internalValues) =>
            internalValues.map((value) => ({
              areaName: value.fieldName,
              areaValue: value.fieldValue,
            })),
          ),
        },
      };

      dispatch(
        createArea({
          data: dataToSend,
          filters: {
            count: {},
            list: filterList,
          },
        }),
      );
    }

    onClose();
  };

  return <AreaForm onSubmit={onSubmit} onClose={onClose} isOpen={isOpen} />;
};
