import React from 'react';
import { useTranslation } from 'react-i18next';
import { green, orange } from '@mui/material/colors';
import { isEmpty } from 'lodash';

import {
  AnyObject,
  IFilterData,
  IFilterWhere,
  IListWithSummary,
  IPayboardWeeklyHoursSummaryChartData,
  IPayboardWeeklyHoursSummaryData,
} from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { getChartItemColor } from 'src/modules/utils/helpers/theme';
import { SeriesColumnOptions } from 'highcharts';

export const useWeeklyHoursDashboardComposeWhere = (
  where: Partial<
    IPayboardWeeklyHoursSummaryData & { yearWeek: { from: number; to: number } }
  >,
) => {
  return React.useMemo<IFilterWhere>(
    () => ({
      ...(where.siteId
        ? {
            siteId: {
              eq: where?.siteId,
            },
          }
        : {}),
      ...(where.yearWeek
        ? {
            yearWeek: {
              between: [where?.yearWeek.from, where?.yearWeek.to],
            },
          }
        : {}),
      ...(where.department
        ? {
            department: {
              like: `%${where?.department}%`,
            },
          }
        : {}),
    }),
    [where.siteId, where.yearWeek, where.department],
  );
};

export const useWeeklyHoursDashboard = (
  reportUrl: string,
  where: IFilterWhere,
) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'weekEndingDate',
        orderConfig: { orderBy: 'weekEndingDate' },
        label: t('payboard.week_ending_date'),
      },
      {
        id: 'department',
        orderConfig: { orderBy: 'department' },
        label: t('payboard.department'),
      },
      {
        id: 'regularHours',
        orderConfig: { orderBy: 'regularHours' },
        label: t('payboard.regular_hours'),
      },
      {
        id: 'overtimeHours',
        orderConfig: { orderBy: 'overtimeHours' },
        label: t('payboard.overtime_hours'),
      },
      {
        id: 'overtimeHoursPercentage',
        orderConfig: { orderBy: 'overtimeHoursPercentage' },
        label: t('payboard.overtime_hours_percentage'),
      },
      {
        id: 'regularPay',
        orderConfig: { orderBy: 'regularPay' },
        label: t('payboard.reg_pay'),
      },
      {
        id: 'overtimePay',
        orderConfig: { orderBy: 'overtimePay' },
        label: t('payboard.ot_pay'),
      },
      {
        id: 'totalPay',
        orderConfig: { orderBy: 'totalPay' },
        label: t('payboard.total_pay'),
      },
      {
        id: 'markupTotal',
        orderConfig: { orderBy: 'markupTotal' },
        label: t('payboard.markup_total'),
      },
    ],
    [t],
  );

  return useBaseReport<IListWithSummary<IPayboardWeeklyHoursSummaryData>>({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'weekEndingDate',
    initialData: {
      items: [],
      summary: [],
    },
  });
};

interface IUseWeeklyHoursDashboardChartProps {
  siteId?: number;
  yearWeek?: { from: number; to: number };
}

interface IUseWeeklyHoursDashboardChartValue {
  series: SeriesColumnOptions[];
  drilldown: AnyObject[];
  isDataLoading?: boolean;
  categories: string[];
}

export const useWeeklyHoursDashboardChart = ({
  siteId,
  yearWeek,
}: IUseWeeklyHoursDashboardChartProps): IUseWeeklyHoursDashboardChartValue => {
  const regularPointColor = green['300'];
  const overtimePointColor = orange['300'];

  const payboardWeeklyHoursSummaryReportChartDataUrl =
    '/payboard/weekly-hours-summary-dashboard-report/chart';

  const generateRegularViewDataItem = React.useCallback(
    (item: AnyObject, drilldownKey: string) => {
      return {
        // This name is used as xAxis category
        name: item.yearWeekISO,
        y: item.totalTimeHours,
        // Here we link regular view to drilldown view
        drilldown: drilldownKey,
        // This custom filed will be used in breadcrumbs drilldown title
        custom: { name: `Reg|OT hours ${item.yearWeekISO}` },
      };
    },
    [],
  );

  const { fetchData, data, isDataLoading } = useDataFetcherWithData<
    IPayboardWeeklyHoursSummaryChartData[]
  >(payboardWeeklyHoursSummaryReportChartDataUrl, []);

  React.useEffect(() => {
    if (!siteId || isEmpty(yearWeek)) {
      return;
    }

    const filter: IFilterData = {
      where: { siteId: { eq: siteId } },
      order: ['yearWeek asc'],
    };

    if (yearWeek) {
      filter.where = {
        ...filter.where,
        yearWeek: {
          between: [yearWeek.from, yearWeek.to],
        },
      };
    }

    fetchData({
      filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, yearWeek]);

  const { seriesObj, drilldownSeriesObj, categories } = React.useMemo<
    AnyObject
  >(() => {
    const colors: any = {}; //
    return data?.reduce<AnyObject>(
      (acc, item: AnyObject) => {
        if (!acc.categories.includes(item.yearWeekISO)) {
          acc.categories.push(item.yearWeekISO);
        }

        // Since colors are regenerated randomly after switching
        // between drilldown and regular view we need to fix them
        // by department
        if (!colors[item.departmentName]) {
          colors[item.departmentName] = getChartItemColor(
            Object.keys(colors).length,
          );
        }
        // This key is used to group data by site and department
        const key = `${item.siteName}_${item.departmentName}`;
        // This key is used as link to drilldown
        const drilldownKey = `${item.siteName}_${item.departmentName}_${item.yearWeek}`;

        // Generate configuration for series
        if (!acc.seriesObj[key]) {
          acc.seriesObj[key] = {
            name: item.departmentName,
            // This custom field will be used in breadcrumbs title
            custom: { name: 'Total Hours' },
            // This stack groups stacked columns by site
            stack: item.siteName,
            type: 'column',
            color: colors[item.departmentName],
            data: [generateRegularViewDataItem(item, drilldownKey)],
          };
        } else {
          acc.seriesObj[key].data.push(
            generateRegularViewDataItem(item, drilldownKey),
          );
        }

        // Generate configuration for series in drilldown
        acc.drilldownSeriesObj[drilldownKey] = {
          tooltip: {
            headerFormat:
              '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat:
              '<span style="color:{point.color}">\u25CF</span> {point.custom.tooltip}: <b>{point.y}</b><br/>',
          },
          // Id is used to render appropriate drilldown on column click on regular view
          // if xAxis category name is clicked (week number), then drilldowns of all
          // columns fo appropriate week will be rendered
          id: drilldownKey,
          // This name is used as xAxis category
          name: item.departmentName,
          type: 'column',
          // Disable legend since it is hard to customize it, by default it will show
          // data from regular view, but we have 2 colors in drilldown view, one for
          // regular hours and another for overtime ones
          showInLegend: false,
          data: [
            {
              // Since we are using the same
              // department name for overtime data (object below)
              // these two object will become one stack in UI
              name: item.departmentName,
              color: regularPointColor,
              y: item.regularHours,
              // This custom field will be used in tooltip
              custom: { tooltip: `Reg Hours` },
            },
            {
              name: item.departmentName,
              color: overtimePointColor,
              y: item.overtimeHours,
              custom: { tooltip: `OT Hours` },
            },
          ],
        };

        return acc;
      },
      {
        categories: [],
        seriesObj: {},
        drilldownSeriesObj: {},
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    categories,
    series: Object.values(seriesObj),
    drilldown: Object.values(drilldownSeriesObj),
    isDataLoading,
  };
};
