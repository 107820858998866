import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IIssueTrackerIssueModel, IIssueTrackerState } from '../../types';

// default state
const defaultState: IIssueTrackerState = {
  refreshKey: 0,
  list: [],
  details: null,
};

/**
 * Flush actions
 */
const flushIssueTrackerState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createIssueInIssueTrackerRequest = (state: IIssueTrackerState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createIssueInIssueTrackerSuccess = (
  state: IIssueTrackerState,
  payload: IIssueTrackerIssueModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createIssueInIssueTrackerFailed = (state: IIssueTrackerState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getIssuesFromIssueTrackerListRequest = (state: IIssueTrackerState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getIssuesFromIssueTrackerListSuccess = (
  state: IIssueTrackerState,
  payload: IIssueTrackerIssueModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getIssuesFromIssueTrackerListFailed = (state: IIssueTrackerState) => ({
  ...state,
  is_data_requested: false,
});

export const issueTracker = createReducer<IIssueTrackerState>({}, defaultState)
  // flush actions
  .on(actions.flushIssueTrackerState, flushIssueTrackerState)
  // creation actions
  .on(
    actions.createIssueInIssueTrackerRequest,
    createIssueInIssueTrackerRequest,
  )
  .on(
    actions.createIssueInIssueTrackerSuccess,
    createIssueInIssueTrackerSuccess,
  )
  .on(actions.createIssueInIssueTrackerFailed, createIssueInIssueTrackerFailed)
  // get list actions
  .on(
    actions.getIssuesFromIssueTrackerListRequest,
    getIssuesFromIssueTrackerListRequest,
  )
  .on(
    actions.getIssuesFromIssueTrackerListSuccess,
    getIssuesFromIssueTrackerListSuccess,
  )
  .on(
    actions.getIssuesFromIssueTrackerListFailed,
    getIssuesFromIssueTrackerListFailed,
  );

export default issueTracker;
