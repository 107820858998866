import React from 'react';
import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from '../FilterPanelCombobox';
import { useSelector } from 'react-redux';
import { useFetchActiveDepartmentsCombobox } from 'src/modules/utils';
import { getDepartmentsComboboxListBySiteId } from 'src/modules/selectors/department';
import { AnyObject, DepartmentComboboxOption } from 'src/modules/types';

type IFilterPanelDepartmentsComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
  value?: number | null;
  siteId?: number;
  propertyAsId?: 'id' | 'name';
};

export const FilterPanelDepartmentsCombobox = ({
  filterId,
  value,
  siteId,
  propertyAsId = 'id',
  ...props
}: IFilterPanelDepartmentsComboboxProps) => {
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  React.useEffect(() => {
    fetchDepartmentsCombobox();
  }, [fetchDepartmentsCombobox]);

  const departments = useSelector(getDepartmentsComboboxListBySiteId)(
    siteId ?? 0,
  ) as DepartmentComboboxOption[];

  const getDepName = React.useCallback(
    (department: AnyObject) =>
      department?.site?.name
        ? `${department.name} / ${(department as any)?.site?.name}`
        : department.name,
    [],
  );

  const options = React.useMemo(() => {
    if (siteId) {
      return departments
        .filter((department) => department.siteId === siteId)
        .map((department) => ({
          ...department,
          ...(propertyAsId === 'name'
            ? { id: department.name }
            : { id: department.id }),
          name: getDepName(department),
        }));
    }

    return departments.map((department) => ({
      ...department,
      ...(propertyAsId === 'name'
        ? { id: department.name }
        : { id: department.id }),
      name: getDepName(department),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments, propertyAsId, siteId]) as DepartmentComboboxOption[];

  const comboboxValue = options.find(
    (site) => site.id === value,
  ) as DepartmentComboboxOption;

  return (
    <FilterPanelCombobox
      value={comboboxValue}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
