import { SagaIterator } from 'redux-saga';

import { call, put, takeEvery } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICreateBaselineRequest,
  IFilter,
  ISagaAction,
  IBaselineDeleteRequest,
  IBaselineModel,
  IBaselineWithMeta,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../../types';
import { createExportSaga } from '../../utils/helpers/creators/export';
import { paths } from '../../../config';

/**
 * Create a new baseline
 */
export const createBaselineRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreateBaselineRequest>>): SagaIterator {
  const payloadData = {
    ...data,
    baseline: Number(data.baseline),
    minCpu: Number(data.minCpu),
    maxCpu: Number(data.maxCpu),
  };
  try {
    const response: IBaselineModel = yield call(
      Api.Baseline.create,
      payloadData,
    );
    yield put(actions.flushBaselineState());
    yield put(actions.createBaselineSuccess(response));
    yield call(navigate, { pathname: paths.PRICING });
  } catch (e) {
    yield put(actions.createBaselineFailed());
  }
};

/**
 * Get baseline list
 */
export const getBaselineListRequestSaga = function* (
  action: ISagaAction<{
    siteId: number;
    filter: IFilter;
  }>,
): SagaIterator {
  try {
    const response: IBaselineWithMeta = yield call(
      Api.Baseline.list,
      action.payload.siteId,
      action.payload.filter,
    );
    yield put(
      actions.getBaselineListSuccess({
        data: response,
        siteId: action.payload.siteId,
      }),
    );
  } catch (e) {
    yield put(actions.getBaselineListFailed());
  }
};

/**
 * Delete baselines
 */
export const deleteBaselineRequestSaga = function* (
  action: ISagaAction<IBaselineDeleteRequest>,
): SagaIterator {
  try {
    const { ids } = action.payload;
    yield call(Api.Baseline.delete, {
      where: { id: { inq: ids } },
    });
    yield put(actions.flushBaselineState());
  } catch (e) {
    yield put(actions.deleteBaselineFailed());
  }
};

/**
 * Bulk update baselines
 */
export const updateBaselinesRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IBaselineModel[]>>): SagaIterator {
  const payloadData = data.map((item) => ({
    ...item,
    baseline: Number(item.baseline),
    minCpu: Number(item.minCpu),
    maxCpu: Number(item.maxCpu),
  }));
  try {
    yield call(Api.Baseline.bulkUpdate, payloadData);
    yield put(actions.flushBaselineState());
    yield call(navigate, { pathname: paths.PRICING });
  } catch (e) {
    yield put(actions.updateBaselinesFailed());
  }
};

/**
 * Get combobox list
 */
export const getBaselineComboboxListRequestSaga = function* (): SagaIterator {
  // try {
  //   const response: Partial<IBaselineModel>[] = yield call(
  //     Api.Baseline.list,
  //     action.payload,
  //   );
  //   yield put(actions.getBaselineComboboxListSuccess(response));
  // } catch (e) {
  //   yield put(actions.getBaselineComboboxListFailed());
  // yield put(
  //   actions.createBaselineNotification({
  //     severity: 'error',
  //     content: i18n.et('baseline', (e as any).message),
  //   }),
  // );
  // }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportBaselineRequest,
    saga: createExportSaga({
      apiCall: Api.Baseline.export,
      actionSuccess: actions.exportBaselineSuccess,
      actionFailed: actions.exportBaselineFailed,
    }),
  },
  { action: actions.createBaselineRequest, saga: createBaselineRequestSaga },
  {
    bindFunc: takeEvery,
    action: actions.getBaselineListRequest,
    saga: getBaselineListRequestSaga,
  },
  { action: actions.deleteBaselineRequest, saga: deleteBaselineRequestSaga },
  // bulk updating baselines
  { action: actions.updateBaselinesRequest, saga: updateBaselinesRequestSaga },
  {
    action: actions.getBaselineComboboxListRequest,
    saga: getBaselineComboboxListRequestSaga,
  },
];
