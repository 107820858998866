import React from 'react';

import { IWikiPage } from 'src/modules/types/wiki-setup';
import DetailWikiView from 'src/pages/Wiki/DetailWikiView';
import { ModalDrillDown } from 'src/components/_ui-kit/ModalDrillDown';

interface IProps {
  page?: IWikiPage;
  isOpen: boolean;
  isLoading: boolean;
  isError: boolean;
  onClose: () => void;
}

const WikiViewPageDialog: React.FC<IProps> = ({
  page,
  isOpen,
  onClose,
  isError,
  isLoading,
}) => {
  return (
    <ModalDrillDown title={page?.title} open={isOpen} onClose={onClose}>
      <DetailWikiView
        disablePageTitle={true}
        page={page}
        loading={isLoading}
        error={isError}
      />
    </ModalDrillDown>
  );
};

export default WikiViewPageDialog;
