import React from 'react';
import { Report, ReportPage } from 'src/components/ReportPage';
import { clients } from 'src/modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { WilliamsSonomaWeeklyCpu } from './WeeklyCpu';
import { WilliamsSonomaDailyCpu } from './DailyCpu';
import WeeklyGrossMargin from './WeeklyGrossMargin';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  IWilliamsSonomaFilterPanelFilters,
  WilliamsSonomaFilterPanel,
} from './WilliamsSonomaFilterPanel';
import { useTranslation } from 'react-i18next';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { UploadImportingFile } from 'src/components/Table/components/TableActions/components/UploadImportingFile';

const client = clients.williamsSonoma;

const reportsDefaultFilters: IWilliamsSonomaFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

export const WilliamsSonomaReports = () => {
  const { t } = useTranslation();

  const reportsProps = useReportsProps(client);

  const hasUserAccessToUploadWilliamsSonomaProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[client].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IWilliamsSonomaFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('productions_uph_reports.site.name') },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
      { id: 'month', label: t('productions_uph_reports.month') },
      { id: 'date', label: t('productions_uph_reports.date') },
      { id: 'area', label: t('productions_uph_reports.area') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IWilliamsSonomaFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={client}>
        <ReportPageItemContainer>
          <ReportTableToolbar reportName={t('williams_sonoma_reports.main')}>
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>
            </ReportPageActionsGroupContainer>

            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <ReportPageExport reportProps={reportsProps} />
              </ReportPageActionsGroupItem>

              {hasUserAccessToUploadWilliamsSonomaProductionData && (
                <ReportPageActionsGroupItem>
                  <UploadImportingFile clientKey={client} />
                </ReportPageActionsGroupItem>
              )}
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
        </ReportPageItemContainer>

        <ReportPageItemContainer>
          <WilliamsSonomaFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportsProps.weeklyCpu}>
          <ReportPageItemContainer fullHeight>
            <WilliamsSonomaWeeklyCpu
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              week={appliedFilters.week}
              year={appliedFilters.year}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyCpu}>
          <ReportPageItemContainer fullHeight>
            <WilliamsSonomaDailyCpu
              siteId={appliedFilters.siteId}
              area={appliedFilters.area}
              week={appliedFilters.week}
              date={appliedFilters.date}
              year={appliedFilters.year}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMargin}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
              year={appliedFilters.year}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
