import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IShiftModel, IShiftState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IShiftState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  list: [],
  comboboxList: [],
  count: 0,
  startTime: '',
  endTime: '',
  duration: NaN,
  delay: 0,
  siteId: NaN,
};

/**
 * Flush actions
 */
const flushShiftState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createShiftRequest = (state: IShiftState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createShiftSuccess = (state: IShiftState, payload: IShiftModel) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createShiftFailed = (state: IShiftState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getShiftListRequest = (state: IShiftState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getShiftListSuccess = (state: IShiftState, payload: IShiftModel[]) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getShiftListFailed = (state: IShiftState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getShiftCountRequest = (state: IShiftState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getShiftCountSuccess = (state: IShiftState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getShiftCountFailed = (state: IShiftState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteShiftRequest = (state: IShiftState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteShiftFailed = (state: IShiftState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateShiftsRequest = (state: IShiftState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateShiftsFailed = (state: IShiftState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getShiftComboboxListRequest = (state: IShiftState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getShiftComboboxListSuccess = (
  state: IShiftState,
  payload: Partial<IShiftModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getShiftComboboxListFailed = (state: IShiftState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IShiftState>();

const exportShiftRequest = exportRequest;
const exportShiftSuccess = exportSuccess;
const exportShiftFailed = exportFailed;

/**
 * Shift reducer
 */
export const shift = createReducer<IShiftState>({}, defaultState)
  // flush actions
  .on(actions.flushShiftState, flushShiftState)
  // creation actions
  .on(actions.createShiftRequest, createShiftRequest)
  .on(actions.createShiftSuccess, createShiftSuccess)
  .on(actions.createShiftFailed, createShiftFailed)
  // get list actions
  .on(actions.getShiftListRequest, getShiftListRequest)
  .on(actions.getShiftListSuccess, getShiftListSuccess)
  .on(actions.getShiftListFailed, getShiftListFailed)
  // get count actions
  .on(actions.getShiftCountRequest, getShiftCountRequest)
  .on(actions.getShiftCountSuccess, getShiftCountSuccess)
  .on(actions.getShiftCountFailed, getShiftCountFailed)
  // deletion actions
  .on(actions.deleteShiftRequest, deleteShiftRequest)
  .on(actions.deleteShiftFailed, deleteShiftFailed)
  // updating actions
  .on(actions.updateShiftsRequest, updateShiftsRequest)
  .on(actions.updateShiftsFailed, updateShiftsFailed)
  // get combobox list actions
  .on(actions.getShiftComboboxListRequest, getShiftComboboxListRequest)
  .on(actions.getShiftComboboxListSuccess, getShiftComboboxListSuccess)
  .on(actions.getShiftComboboxListFailed, getShiftComboboxListFailed)
  // export actions
  .on(actions.exportShiftRequest, exportShiftRequest)
  .on(actions.exportShiftSuccess, exportShiftSuccess)
  .on(actions.exportShiftFailed, exportShiftFailed);

export default shift;
