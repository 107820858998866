// @@TODO: finilize this file after all application redesigned
import React, { useEffect, useMemo } from 'react';
import { Sheet, useTheme } from '@mui/joy';
import { map, omit } from 'lodash';
import { AnyObject, IFilter, WhereOperator } from '../../modules/types';
import isEmpty from 'lodash/isEmpty';
import { ComboBoxOption } from '../ComboBox';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormikTextField, {
  IFormikTextFieldProps,
} from '../Formik/FormikTextField';
import FormikDatetimepicker from '../Formik/FormikDatetimepicker';
import FormikDatepicker from '../Formik/FormikDatepicker';
import FormikCheckbox from '../Formik/FormikCheckbox';
import FormikCombobox, { IFormikComboboxProps } from '../Formik/FormikCombobox';
import FormikRange from '../Formik/FormikRange';
import { JSONSchemaType } from 'ajv';
import {
  IFilterType,
  useBrowserHistoryFunctions,
  useGlobalFilter,
  useQueryParams,
  useValidate,
} from '../../modules/utils';
import {
  getSiteIdPath,
  hasFormFilters,
} from '../../modules/utils/helpers/filter';
import { useSelector } from 'react-redux';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import EntitiesBySiteComboBox, {
  IEntityBySiteComboboxOption,
} from '../Formik/comboboxes-with-entities/EntitiesBySyteCombobox';
import { ClientKey } from '../../modules/constants';
import { FilterFieldContainer } from './FilterFieldContainer';
import {
  FormikButtonGroup,
  FormikButtonGroupConfig,
} from '../Formik/FormikButtonGroup';
import { ActionsBar } from '../_ui-kit/ActionsBar';
import { ButtonGroupsContainer } from '../_ui-kit/ButtonGroup';
import SitesComboBox from '../Formik/comboboxes-with-entities/SitesCombobox';
import YearWeekFilterComboBox from '../Formik/comboboxes-with-entities/YearWeekComboBox';
import FormikYearWeekRange from '../Formik/FormikYearWeekRange';
import FormikYearMonthRange from '../Formik/FormikYearMonthRange';
import { EmployeesComboBox } from '../Formik/comboboxes-with-entities/EmployeesComboBox';
import ClientKeysComboBox from '../Formik/comboboxes-with-entities/ClientKeysCombobox';
import FormikDateRangePicker from '../Formik/FormikDateRangePicker';

export * from './FilterContainer';

export interface IFilterItem<T = AnyObject> {
  name: string;
  label: string;
  operator: WhereOperator;
  key?: string;
  type?: IFilterType;
  options?: ComboBoxOption[] | IEntityBySiteComboboxOption[];
  validate?: JSONSchemaType<T>['properties'] & { required?: boolean };
  clientKey?: ClientKey; // filter sites combobox for certain client key
  useReports?: boolean;
  propertyAsID?: 'badge' | 'id' | 'employeeId' | string;
  onlyActive?: boolean;
  config?: AnyObject;
  placeholder?: string;
  hidden?: boolean;
  siteFieldName?: string;
  siteAs?: 'name' | 'id';
  onChange?:
    | IFormikComboboxProps['onChange']
    | IFormikTextFieldProps['onChange'];
}

export interface IFilterItemData {
  [fieldName: string]: { [operator: string]: any } | any;
}

export interface IFilterProps {
  filters?: IFilterItem[];
  initialFilterData?: IFilterItemData;
  onFilterReset: () => void;
  onSubmit: (filterData: IFilterItemData) => void;
  onClose?: () => void; // @@TODO: make it required or review logic here
  isQsFiltersDisabled?: boolean;
  hideFilterResetButton?: boolean;
  // This prop is used when we have "managed" filter, that means
  // that all filters data comes in initialFilterData prop
  shouldInitialFilterDataBeUsedAsSourceOfTruth?: boolean;
  formStyles?: React.CSSProperties;
  fieldsContainerStyles?: React.CSSProperties;
  actionsBarContainerStyles?: React.CSSProperties;
}

export const OPERATORS = {
  eq: '=',
  neq: 'Not Equal',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  inq: 'IN',
  nin: 'NOT IN',
  between: 'BETWEEN',
  exists: 'EXISTS',
  and: 'AND',
  or: 'OR',
  like: '=',
  nlike: 'NOT LIKE',
  ilike: 'ILIKE',
  nilike: 'NOT ILIKE',
  regexp: 'REGEXP',
};

const getComparisonOperator = (operator: WhereOperator): string => {
  return OPERATORS[operator];
};

/**
 * This component contains modal popup with filtering form to filter table data
 */
const Filter = ({
  filters,
  initialFilterData,
  onFilterReset,
  onSubmit,
  onClose,
  isQsFiltersDisabled,
  formStyles,
  fieldsContainerStyles,
  actionsBarContainerStyles,
  shouldInitialFilterDataBeUsedAsSourceOfTruth,
}: IFilterProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [isFilterOpen] = React.useState<boolean>(false);
  // set isFilterBeingUsed to true when submit of reset filter
  const [isFilterBeingUsed] = React.useState<boolean>(false);
  const globallySelectedSites = useSelector(getGloballySelectedSites);
  const { pushFilterDataObjectToQueryParams } = useBrowserHistoryFunctions();

  const qsFilters = useQueryParams() as IFilter;

  const {
    encodeName,
    dataToIWhere,
    initialValues,
    validationScheme,
  } = useGlobalFilter({
    filters,
    initialFilterData: shouldInitialFilterDataBeUsedAsSourceOfTruth
      ? initialFilterData
      : isFilterBeingUsed
      ? {}
      : initialFilterData,
  });

  const validate = useValidate(validationScheme, { strict: false });

  /**
   * Instantiate formik
   */
  const formik = useFormik<AnyObject>({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      const filtersToSubmit = dataToIWhere(data);

      // // setIsFilterOpen(false);
      // // setIsFilterBeingUsed(true);
      // if (isEmpty(filtersToSubmit)) {
      //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
      //   handleFilterReset();
      // } else {
      //   onSubmit(filtersToSubmit);
      // }

      if (filtersToSubmit) {
        onSubmit(filtersToSubmit);
      }

      onClose && onClose();
    },
  });

  const isQsHasFormFilters: boolean = useMemo(
    () => hasFormFilters(qsFilters.filter),
    [qsFilters],
  );

  const formStylesDefault = {
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties;
  const fieldsContainerStylesDefault = {
    overflowX: 'auto',
    flexGrow: 1,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 24,
  } as React.CSSProperties;
  const actionsBarContainerStylesDefault = {
    padding: '16px',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.colors.border.border_tertiary,
    marginTop: 24,
  } as React.CSSProperties;

  useEffect(() => {
    if (!isFilterOpen) {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [isFilterOpen]);

  useEffect(() => {
    // Run this effect on component mount and after globally selected sites have changed to
    // sync initial filters with browser's query params

    // If we remove !isQsFiltersDisabled check, then for the case when query string filters are
    // disabled initial filter will be synced with query params anyway, this breaks behavior
    // of the pages with tabs, such as Points
    if (
      !isEmpty(initialFilterData) &&
      !isQsHasFormFilters &&
      !isQsFiltersDisabled
    ) {
      pushFilterDataObjectToQueryParams({ where: initialFilterData } ?? {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globallySelectedSites]);

  useEffect(() => {
    if (!isQsHasFormFilters && isFilterBeingUsed) {
      // isResetButtonShown && setIsFilterBeingUsed(true);
      // setIsResetButtonShown(false);
      formik.resetForm();
      onFilterReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQsHasFormFilters]);

  useEffect(() => {
    // Reset form filters and hide reset filter button after globallySelectedSites were changed
    // for components that disables filters in query params
    if (!isQsFiltersDisabled) {
      return;
    }

    // setIsResetButtonShown(false);
    formik.resetForm();
    onFilterReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQsFiltersDisabled, globallySelectedSites]);

  const renderFilterItem = (filterItem: IFilterItem<AnyObject>) => {
    switch (filterItem.type) {
      case 'text':
      case 'number':
      default:
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikTextField
                key={filterItem.key ?? filterItem.name}
                variant="outlined"
                //
                fullWidth
                id={encodeName(filterItem.name)}
                label={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                name={encodeName(filterItem.name)}
                formik={formik}
                size="lg"
                type={filterItem.type}
                placeholder={t('common.add_your_text_here')}
                {...(filterItem.onChange
                  ? {
                      onChange: filterItem.onChange as IFormikTextFieldProps['onChange'],
                    }
                  : {})}
              />
            </FilterFieldContainer>
          )
        );
      case 'range':
        return (
          <FilterFieldContainer>
            <FormikRange
              key={filterItem.key ?? filterItem.name}
              id={encodeName(filterItem.name)}
              label={
                filterItem.label +
                ` ${getComparisonOperator(filterItem.operator)}`
              }
              formik={formik}
            />
          </FilterFieldContainer>
        );
      case 'datetime':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikDatetimepicker
                key={filterItem.key ?? filterItem.name}
                fullWidth
                id={encodeName(filterItem.name)}
                label={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'date':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikDatepicker
                key={filterItem.key ?? filterItem.name}
                fullWidth
                id={encodeName(filterItem.name)}
                label={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'daterange':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikDateRangePicker
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                operator={filterItem.operator}
                label={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxYearWeekRange':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikYearWeekRange
                {...filterItem}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxYearMonthRange':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikYearMonthRange
                {...filterItem}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'checkbox':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikCheckbox
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                label={filterItem.label}
                formik={formik}
              />
            </FilterFieldContainer>
            // null
          )
        );
      case 'time':
        return (
          !filterItem.hidden &&
          // <FormikTimepicker
          //   key={filterItem.key ?? filterItem.name}
          //   fullWidth
          //   id={encodeName(filterItem.name)}
          //   label={
          //     filterItem.label +
          //     ` ${getComparisonOperator(filterItem.operator)}`
          //   }
          //   formik={formik}
          // />
          null
        );
      case 'combobox':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikCombobox
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                label={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                placeholder={filterItem.placeholder ?? t('common.select')}
                options={filterItem.options as ComboBoxOption[]}
                formik={formik}
                {...(filterItem.onChange
                  ? {
                      onChange: filterItem.onChange as IFormikComboboxProps['onChange'],
                    }
                  : {})}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxEmployee':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <EmployeesComboBox
                {...omit(filterItem, ['onChange'])}
                siteFieldName={filterItem.siteFieldName}
                siteAs={filterItem.siteAs}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                placeholder={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxSites':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <SitesComboBox
                {...filterItem}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                label={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                placeholder={filterItem.placeholder ?? t('common.select')}
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxClientKeys':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <ClientKeysComboBox
                {...omit(filterItem, ['onChange'])}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                placeholder={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxEntitiesBySite':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <EntitiesBySiteComboBox
                {...omit(filterItem, ['onChange'])}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                siteIdPath={getSiteIdPath(filters)}
                placeholder={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
                options={filterItem.options as IEntityBySiteComboboxOption[]}
              />
            </FilterFieldContainer>
          )
        );
      case 'comboboxYearWeek':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <YearWeekFilterComboBox
                {...omit(filterItem, ['onChange'])}
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                placeholder={
                  filterItem.label +
                  ` ${getComparisonOperator(filterItem.operator)}`
                }
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
      case 'buttonGroup':
        return (
          !filterItem.hidden && (
            <FilterFieldContainer>
              <FormikButtonGroup
                {...filterItem}
                config={
                  (filterItem.config as AnyObject)
                    .buttons as FormikButtonGroupConfig
                }
                key={filterItem.key ?? filterItem.name}
                id={encodeName(filterItem.name)}
                formik={formik}
              />
            </FilterFieldContainer>
          )
        );
    }
  };

  let currentGroup: Array<IFilterItem<AnyObject>> = [];
  const groupedFilters = filters?.reduce<
    Array<IFilterItem<AnyObject> | Array<IFilterItem<AnyObject>>>
  >((allFilters, filterItem, i) => {
    if (filterItem.type === 'buttonGroup') {
      currentGroup.push(filterItem);

      if (i === filters.length - 1) {
        allFilters.push(currentGroup);
      }

      return allFilters;
    }

    if (currentGroup.length) {
      allFilters.push(currentGroup);

      currentGroup = [];
    }

    allFilters.push(filterItem);

    return allFilters;
  }, []);

  return (
    <form
      noValidate
      style={{
        ...(formStyles ?? formStylesDefault),
        backgroundColor: 'inherit',
      }}
    >
      <Sheet
        style={fieldsContainerStyles ?? fieldsContainerStylesDefault}
        sx={{ bgcolor: 'inherit' }}
      >
        {map(groupedFilters, (filterItem) => {
          return Array.isArray(filterItem) ? (
            <ButtonGroupsContainer key={filterItem.map((f) => f.name).join()}>
              {map(filterItem, (nestedFilterItem) => (
                <Sheet key={nestedFilterItem.name} sx={{ bgcolor: 'inherit' }}>
                  {renderFilterItem(nestedFilterItem)}
                </Sheet>
              ))}
            </ButtonGroupsContainer>
          ) : (
            <Sheet key={filterItem.name} sx={{ bgcolor: 'inherit' }}>
              {renderFilterItem(filterItem)}
            </Sheet>
          );
        })}
      </Sheet>
      <Sheet
        style={actionsBarContainerStyles ?? actionsBarContainerStylesDefault}
        sx={{ bgcolor: 'inherit' }}
      >
        <ActionsBar
          applyButtonType="submit"
          onApply={formik.handleSubmit}
          onCancel={onClose}
          onReset={() => {
            formik.setValues(
              Object.entries(initialValues).reduce((acc, [filterName]) => {
                acc[filterName] = undefined;

                return acc;
              }, {}),
            );
          }}
        />
      </Sheet>
    </form>
  );
};

export default Filter;
