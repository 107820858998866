import React, { useMemo } from 'react';
import { HeadCell } from './index';
import { useTranslation } from 'react-i18next';
import { SummaryInfo } from '../../modules/types';
import { Typography, useTheme } from '@mui/joy';
import { Td } from '../TableUiComponents/Td';
import { Tr } from '../TableUiComponents';

interface EnhancedTableSummaryProps {
  headCells: HeadCell[];
  summary: SummaryInfo;
  disableSelection?: boolean;
}

/**
 * Table Header component
 */
function EnhancedTableSummary({
  headCells,
  summary,
  disableSelection = true,
}: EnhancedTableSummaryProps) {
  const { t } = useTranslation();

  const theme = useTheme();

  // calculate how many cols can be allocated for summary title
  const titleColspan = useMemo(() => {
    for (let i = 0; i < headCells.length; i++) {
      if (headCells[i].id in summary.values) {
        return i;
      }
    }
    return 0;
  }, [headCells, summary]);

  const extraSpan = disableSelection ? 0 : 1;
  const colspan = titleColspan + extraSpan;

  return (
    <Tr
      sx={{
        bottom: 0,
        position: 'sticky',
      }}
    >
      {titleColspan > 0 && (
        <Td
          sx={{
            bgcolor: `${theme.palette.colors.background.bg_primary_hover} !important`,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
          }}
          colSpan={colspan}
        >
          <Typography level="text_xs" textColor="colors.text.text_primary.main">
            {t(summary.title)}
          </Typography>
        </Td>
      )}
      {headCells.slice(titleColspan).map(({ id }) => (
        <Td
          key={id}
          sx={{
            bgcolor: `${theme.palette.colors.background.bg_primary_hover} !important`,
            borderTopWidth: 1,
            borderTopStyle: 'solid',
          }}
        >
          <Typography
            level="text_xs"
            textColor="colors.text.text_primary.main"
            fontWeight="semi_bold"
          >
            {summary.values[id] ?? null}
          </Typography>
        </Td>
      ))}
    </Tr>
  );
}

export default EnhancedTableSummary;
