import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { ICountResponse, IProductionModel, IProductionState } from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IProductionState = {
  refreshKey: 0,
  id: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  bomId: NaN,
  date: '',
  sku: '',
  department: '',
  qty: NaN,
  container: '',
  lineOrDoor: '',
  startTime: '',
  endTime: '',
  elapsedTime: NaN,
  shiftId: NaN,
  siteId: NaN,
};

/**
 * Flush actions
 */
const flushProductionState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createProductionRequest = (state: IProductionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createProductionSuccess = (
  state: IProductionState,
  payload: IProductionModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createProductionFailed = (state: IProductionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getProductionListRequest = (state: IProductionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getProductionListSuccess = (
  state: IProductionState,
  payload: IProductionModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getProductionListFailed = (state: IProductionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getProductionCountSuccess = (
  state: IProductionState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getProductionCountFailed = (
  state: IProductionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteProductionRequest = (state: IProductionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteProductionFailed = (state: IProductionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateProductionsRequest = (state: IProductionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateProductionsFailed = (state: IProductionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getProductionComboboxListRequest = (state: IProductionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getProductionComboboxListSuccess = (
  state: IProductionState,
  payload: Partial<IProductionModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getProductionComboboxListFailed = (
  state: IProductionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IProductionState>();

const exportProductionRequest = exportRequest;
const exportProductionSuccess = exportSuccess;
const exportProductionFailed = exportFailed;

/**
 * Production reducer
 */
export const production = createReducer<IProductionState>({}, defaultState)
  // flush actions
  .on(actions.flushProductionState, flushProductionState)
  // creation actions
  .on(actions.createProductionRequest, createProductionRequest)
  .on(actions.createProductionSuccess, createProductionSuccess)
  .on(actions.createProductionFailed, createProductionFailed)
  // get list actions
  .on(actions.getProductionListRequest, getProductionListRequest)
  .on(actions.getProductionListSuccess, getProductionListSuccess)
  .on(actions.getProductionListFailed, getProductionListFailed)
  // get count actions
  .on(actions.getProductionCountSuccess, getProductionCountSuccess)
  .on(actions.getProductionCountFailed, getProductionCountFailed)
  // deletion actions
  .on(actions.deleteProductionRequest, deleteProductionRequest)
  .on(actions.deleteProductionFailed, deleteProductionFailed)
  // updating actions
  .on(actions.updateProductionsRequest, updateProductionsRequest)
  .on(actions.updateProductionsFailed, updateProductionsFailed)
  // get combobox list actions
  .on(
    actions.getProductionComboboxListRequest,
    getProductionComboboxListRequest,
  )
  .on(
    actions.getProductionComboboxListSuccess,
    getProductionComboboxListSuccess,
  )
  .on(actions.getProductionComboboxListFailed, getProductionComboboxListFailed)
  // export actions
  .on(actions.exportProductionRequest, exportProductionRequest)
  .on(actions.exportProductionSuccess, exportProductionSuccess)
  .on(actions.exportProductionFailed, exportProductionFailed);

export default production;
