import { JSONSchemaType } from 'ajv';
import { ICreateBaselineRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { pricingRegex } from '../pricing';

export const generateCreateBaselineScheme = (
  metatags: string[],
): JSONSchemaType<ICreateBaselineRequest> => {
  const metatagsScheme = metatags.reduce((scheme, key) => {
    scheme[key] = {
      isNotEmpty: true,
      default: '',
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    };

    return scheme;
  }, {});

  return {
    type: 'object',
    required: ['siteId', 'baseline', 'minCpu', 'maxCpu', ...metatags],
    additionalProperties: false,
    errorMessage: {
      required: i18n.t('error.validation.required'),
    },
    properties: {
      siteId: {
        default: NaN,
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      baseline: {
        default: NaN,
        isNotEmpty: true,
        type: 'string',
        pattern: pricingRegex,
        errorMessage: {
          type: i18n.t('error.validation.type'),
          isNotEmpty: i18n.t('error.validation.required'),
          pattern: i18n.t('error.validation.number'),
        },
      },
      minCpu: {
        default: NaN,
        isNotEmpty: true,
        type: 'string',
        pattern: pricingRegex,
        errorMessage: {
          type: i18n.t('error.validation.type'),
          isNotEmpty: i18n.t('error.validation.required'),
          pattern: i18n.t('error.validation.number'),
        },
      },
      maxCpu: {
        default: NaN,
        isNotEmpty: true,
        type: 'string',
        pattern: pricingRegex,
        errorMessage: {
          type: i18n.t('error.validation.type'),
          isNotEmpty: i18n.t('error.validation.required'),
          pattern: i18n.t('error.validation.number'),
        },
      },
      ...metatagsScheme,
    },
  };
};
