import React from 'react';
import { Box, Typography } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface IDrawerSectionWithTitleProps {
  title: string;
  children: React.ReactNode;
  containerSx?: SxProps;
  withTopOffset?: boolean;
}

export const DrawerSectionWithTitle = ({
  children,
  title,
  containerSx,
  withTopOffset,
}: IDrawerSectionWithTitleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: withTopOffset ? -5 : 0,
        mb: 3,
        pb: 2,
        ...containerSx,
      }}
    >
      <Box>
        <Box
          sx={{
            px: 2,
            pt: 3,
            pb: 1.5,
            mb: 2,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: 'colors.border.border_primary',
          }}
        >
          <Typography
            level="text_xs"
            fontWeight="medium"
            textColor="colors.text.text_primary.main"
          >
            {title}
          </Typography>
        </Box>
      </Box>

      {children}
    </Box>
  );
};
