import {
  IYdtChartsResponseItem,
  IProjectionVsActualTotalsMonthlyResponseItem,
  IProjectionVsActualTotalsQuarterlyResponseItem,
  IProjectionVsActualTotalsWeeklyResponseItem,
} from 'src/modules/types/projection-vs-actual-dashboard';
import Api from '.';
import { getYDTYearWeekRange } from '../dateWrapper';

interface IFetchProjectionVsActualTotalsWeeklyChartDataArgs {
  week: number;
  year: number;
}
export const fetchProjectionVsActualTotalsWeeklyChartData = async ({
  week,
  year,
}: IFetchProjectionVsActualTotalsWeeklyChartDataArgs) => {
  return await Api.Request.get<
    Array<IProjectionVsActualTotalsWeeklyResponseItem>
  >(`projection-vs-actual/totals/weekly`, {
    filter: {
      where: { yearWeek: { eq: `${year}${String(week).padStart(2, '0')}` } },
      order: ['yearWeek asc'],
    },
  });
};

interface IFetchProjectionVsActualTotalsMonthlyChartDataArgs {
  month: number;
  year: number;
}
export const fetchProjectionVsActualTotalsMonthlyChartData = async ({
  month,
  year,
}: IFetchProjectionVsActualTotalsMonthlyChartDataArgs) => {
  return await Api.Request.get<
    Array<IProjectionVsActualTotalsMonthlyResponseItem>
  >(`projection-vs-actual/totals/monthly`, {
    filter: {
      where: {
        and: [{ yearNumber: { eq: year } }, { monthNumber: { eq: month } }],
      },
      order: ['yearNumber asc', 'monthNumber asc'],
    },
  });
};

interface IFetchProjectionVsActualTotalsYearlyChartDataArgs {
  year: number;
}
export const fetchProjectionVsActualTotalsYearlyChartData = async ({
  year,
}: IFetchProjectionVsActualTotalsYearlyChartDataArgs) => {
  return await Api.Request.get<
    Array<IProjectionVsActualTotalsMonthlyResponseItem>
  >(`projection-vs-actual/totals/yearly`, {
    filter: {
      where: { yearNumber: { eq: year } },
      order: ['yearNumber asc'],
    },
  });
};

export const fetchProjectionVsActualQuarterlyChartData = async () => {
  const response = await Api.Request.get<
    Array<IProjectionVsActualTotalsQuarterlyResponseItem>
  >(`projection-vs-actual/totals/quarterly`);

  return response;
};

export const fetchProjectionVsActualRevenueByCustomerChartData = async () => {
  const { startYearWeek, endYearWeek } = getYDTYearWeekRange({
    subWeeksFromEndYearWeek: 1,
  });

  const response = await Api.Request.get<Array<IYdtChartsResponseItem>>(
    `projection-vs-actual/ydt-chart-data`,
    {
      filter: {
        where: { yearWeek: { between: [startYearWeek, endYearWeek] } },
        order: ['clientName asc'],
      },
    },
  );

  return response;
};

export const fetchProjectionVsActualRevenuePercentageYDTChartData = async () => {
  const { startYearWeek, endYearWeek } = getYDTYearWeekRange({
    subWeeksFromEndYearWeek: 1,
  });
  const response = await Api.Request.get<Array<IYdtChartsResponseItem>>(
    'projection-vs-actual/ydt-chart-data',
    {
      filter: {
        where: { yearWeek: { between: [startYearWeek, endYearWeek] } },
        order: ['clientName asc'],
      },
    },
  );

  return response;
};
