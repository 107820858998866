import { map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { useFilterFieldsData } from 'src/modules/utils/hooks/table';
import {
  useJobAbandonment,
  useJobAbandonmentComposeWhere,
  useJobAbandonmentTableFiltersConfiguration,
} from 'src/modules/utils/hooks/job-abandonment.hooks';
import { JobAbandonmentFilter } from 'src/pages/JobAbandonment/components/JobAbandonmentFilter';
import {
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import { ReportPage } from '../../components/ReportPage';

const reportUrl = '/employees/active-but-not-present';

export const JobAbandonment: React.FC = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl);

  const filterFieldsConfiguration = useJobAbandonmentTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = useJobAbandonmentComposeWhere({
    siteId: +filterFields.siteId.value,
    supervisorId: +filterFields.supervisorId.value,
    employeeId: filterFields.employeeId.value,
    staffingProvider: filterFields.staffingProvider.value,
    badge: +filterFields.badge.value,
    lastName: filterFields.lastName.value,
    firstName: filterFields.firstName.value,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isCountDataLoading,
    countData,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = useJobAbandonment(reportUrl, where);

  // computed list with icons
  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(data, (item) => {
        return {
          ...item,
          maxLoggedTime: item.maxLoggedTime
            ? composeDate(
                item.maxLoggedTime,
                parse(DATETIME_TIMEZONE),
                format(DATE_FORMAT),
              )
            : '',
        };
      }),
    [data],
  );

  return (
    <ReportPage hideNoData>
      <PageContentWithTopToolbar>
        <PageContentChildContainer sx={{ pt: 2, pb: 1 }} fullHeight={false}>
          <TableToolbar>
            <TableTitle>
              {t('employees.active_but_not_present.page_title')}
            </TableTitle>

            <TableActions>
              <JobAbandonmentFilter
                getLabel={getLabel}
                onFiltersFormSubmit={onFiltersFormSubmit}
                getFilterCommonPropsByFilterName={
                  getFilterCommonPropsByFilterName
                }
              />

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={requestFilters}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>

        <ReportTable
          heightWithTabs
          data={computedList as any}
          count={countData.count}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      </PageContentWithTopToolbar>
    </ReportPage>
  );
};
