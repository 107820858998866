import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IUpdateChecklistTaskData } from '../../types';
import { createChecklistTaskScheme } from './create-checklist-task.scheme';

export const updateChecklistTaskScheme: JSONSchemaType<IUpdateChecklistTaskData> = {
  type: 'object',
  required: ['id', 'title', 'checklistId', 'startTime', 'endTime'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    ...createChecklistTaskScheme.properties,
    id: {
      type: ['number', 'string'],
      isNotEmpty: true,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    recurrenceStartDate: {
      nullable: true,
      type: 'string',
    },
    checklistTaskId: {
      nullable: true,
      type: 'number',
    },
  },
};
