import { JSONSchemaType } from 'ajv';
import { ICreateSkillRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createSkillScheme: JSONSchemaType<ICreateSkillRequest> = {
  type: 'object',
  required: ['name', 'siteId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    skillTerm: {
      type: 'string',
      default: 'permanent',
      enum: ['expiration', 'permanent'],
    },
    expirationPeriod: {
      default: null,
      nullable: true,
      type: 'number',
    },
  },

  if: {
    properties: {
      skillTerm: {
        const: 'expiration',
      },
    },
  },
  then: {
    properties: {
      expirationPeriod: {
        nullable: false,
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
