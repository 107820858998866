import {
  IProductionDailyUphBySiteAndEmployeeModel,
  IProductionDailyUphBySiteAndEmployeeState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionDailyUphBySiteAndEmployeeState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionDailyUphBySiteAndEmployeeListRequest,
  getListSuccess: getProductionDailyUphBySiteAndEmployeeListSuccess,
  getListFailed: getProductionDailyUphBySiteAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  IProductionDailyUphBySiteAndEmployeeState,
  IProductionDailyUphBySiteAndEmployeeModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionDailyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getProductionDailyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getProductionDailyUphBySiteAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<
  IProductionDailyUphBySiteAndEmployeeState
>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionDailyUphBySiteAndEmployeeRequest,
  exportSuccess: exportProductionDailyUphBySiteAndEmployeeSuccess,
  exportFailed: exportProductionDailyUphBySiteAndEmployeeFailed,
} = new ExportReducerCreator().takeHandlers<
  IProductionDailyUphBySiteAndEmployeeState
>();

/**
 * actually Reducer
 */
export const productionDailyUphBySiteAndEmployee = createReducer<
  IProductionDailyUphBySiteAndEmployeeState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionDailyUphBySiteAndEmployeeListRequest,
    getProductionDailyUphBySiteAndEmployeeListRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteAndEmployeeListSuccess,
    getProductionDailyUphBySiteAndEmployeeListSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteAndEmployeeListFailed,
    getProductionDailyUphBySiteAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getProductionDailyUphBySiteAndEmployeeCountRequest,
    getProductionDailyUphBySiteAndEmployeeCountRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteAndEmployeeCountSuccess,
    getProductionDailyUphBySiteAndEmployeeCountSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteAndEmployeeCountFailed,
    getProductionDailyUphBySiteAndEmployeeCountFailed,
  )
  // export action
  .on(
    actions.exportProductionDailyUphBySiteAndEmployeeRequest,
    exportProductionDailyUphBySiteAndEmployeeRequest,
  )
  .on(
    actions.exportProductionDailyUphBySiteAndEmployeeSuccess,
    exportProductionDailyUphBySiteAndEmployeeSuccess,
  )
  .on(
    actions.exportProductionDailyUphBySiteAndEmployeeFailed,
    exportProductionDailyUphBySiteAndEmployeeFailed,
  );

export default productionDailyUphBySiteAndEmployee;
