import React from 'react';
import { Box, Sheet } from '@mui/joy';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  AnyObject,
  IdsArray,
  IQuestionCategoryModel,
  IQuestionEmployeeAnswerItem,
  IStoreState,
} from '../../../modules/types';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../../modules/utils/hooks/common/forms';
import {
  fillInQuestionnaireRequest,
  getQuestionCategoryListRequest,
  getQuestionListRequest,
} from 'src/modules/actions';
import {
  getQuestionCategoriesWithQuestions,
  getQuestionCategoryList,
} from 'src/modules/selectors/questionCategory';
import { getEmployeesByIds } from 'src/modules/selectors/employee';
import ComboBox from 'src/components/ComboBox';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { ButtonGroupsContainer } from 'src/components/_ui-kit/ButtonGroup';
import { FormikButtonGroup } from 'src/components/Formik/FormikButtonGroup';
import { FormFiledButtonsGroupItemContainer } from 'src/components/Form/FormFiledButtonsGroupItemContainer';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import { FormikFilesUpload } from 'src/components/Formik/FormikFilesUpload';
import FormikSignatureField from 'src/components/Formik/FormikSignatureField';
import { dataUrlToFile } from 'src/modules/utils/helpers/common';
import * as Yup from 'yup';

export const questionnaireRadioButtonValues = {
  no: 'false',
  yes: 'true',
  notApplicable: 'Not Applicable',
};

interface IEmployeeToQuestionCategoryMap {
  [id: string]: {
    questionCategoryOptions: IQuestionCategoryModel[];
    questionCategoryId: number | null;
  };
}
interface IFillInQuestionnaireFormInitialValue {
  id: number;
  employeeId: number;
  employeeFullName: string;
  questionCategoryId: number | null;
  questionsEmployees: IQuestionEmployeeAnswerItem[];
  files: Array<AnyObject>;
  signature?: string;
}

interface IEmployeesAddReviewProps {
  isOpen: boolean;
  onClose: () => void;
  employeesToReviewIds: IdsArray;
}

export const EmployeesAddReview = ({
  isOpen,
  onClose,
  employeesToReviewIds,
}: IEmployeesAddReviewProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [existedValues, setExistedValues] = React.useState<
    IFillInQuestionnaireFormInitialValue[]
  >([]);

  const [
    employeeToQuestionCategoryMap,
    setEmployeeToQuestionCategoryMap,
  ] = React.useState<IEmployeeToQuestionCategoryMap>(
    employeesToReviewIds.reduce((acc, employeeId) => {
      acc[employeeId] = {
        questionCategoryOptions: [],
        questionCategoryId: null,
      };

      return acc;
    }, {}),
  );

  const questionCategories = useSelector(getQuestionCategoryList);
  const employees = useSelector(
    (state) => getEmployeesByIds(state as IStoreState)(employeesToReviewIds),
    shallowEqual,
  );
  const questionCategoriesWithQuestions = useSelector(
    getQuestionCategoriesWithQuestions,
  );

  const initialValues = React.useMemo(() => {
    return employees.reduce<IFillInQuestionnaireFormInitialValue[]>(
      (employeesWithQuestions, employee, i) => {
        const questionCategoryId =
          employeeToQuestionCategoryMap[employee.id]?.questionCategoryId;

        let questionsEmployees: IQuestionEmployeeAnswerItem[] = [];
        let signature: string | undefined = undefined;

        const files = existedValues[i] ? existedValues[i].files : [];

        if (questionCategoryId) {
          const employeeExistedData = existedValues.find(
            (existedValue) =>
              existedValue.employeeId === employee.id &&
              questionCategoryId === existedValue.questionCategoryId,
          );

          questionsEmployees = employeeExistedData
            ? employeeExistedData.questionsEmployees
            : questionCategoriesWithQuestions[questionCategoryId];

          signature = employeeExistedData
            ? employeeExistedData.signature
            : undefined;
        }

        employeesWithQuestions.push({
          questionCategoryId,
          employeeFullName: `${employee.firstName} ${employee.lastName}`,
          id: employee.id,
          employeeId: employee.id,
          questionsEmployees,
          files,
          signature,
        });

        return employeesWithQuestions;
      },
      [],
    );
  }, [
    employees,
    employeeToQuestionCategoryMap,
    existedValues,
    questionCategoriesWithQuestions,
  ]);

  const questionCategorySchema = Yup.object().shape({
    questionCategoryId: Yup.number()
      .required(t('error.validation.required'))
      .integer(t('error.validation.required')),
  });
  const validationSchema = Yup.array()
    .of(questionCategorySchema)
    .required(t('error.validation.required'))
    .min(1, t('error.validation.required'));

  const formik = useFormikInUpdateForm({
    initialValues,
    validationSchema,
    onSubmit: (data) => {
      const formData = new FormData();

      data.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          if (key !== 'id' && key !== 'employeeFullName') {
            if (key === 'questionsEmployees') {
              item.questionsEmployees.forEach((answer, answerIndex) => {
                Object.keys(answer).forEach((answerKey) => {
                  formData.append(
                    `reviews[${index}][questionsEmployees][${answerIndex}][${answerKey}]`,
                    answer[answerKey],
                  );
                });
              });
            } else if (key === 'files') {
              item.files.forEach((file, fileIndex) => {
                formData.append(
                  `reviews[${index}][files][attachments][${fileIndex}]`,
                  file.file,
                );
              });
            } else if (key === 'signature' && item.signature) {
              formData.append(
                `reviews[${index}][files][signature]`,
                dataUrlToFile(
                  item.signature,
                  `signature_${new Date().toISOString()}.png`,
                ),
              );
            } else {
              formData.append(`reviews[${index}][${key}]`, item[key]);
            }
          }
        });
      });

      dispatch(fillInQuestionnaireRequest(formData));

      setExistedValues((prev) =>
        prev.map((item) => ({
          ...item,
          files: [],
          questionsEmployees: [],
          questionCategoryId: null,
        })),
      );

      formik.values.forEach((_, i) => {
        formik.setFieldValue(`${i}.files`, []);
        formik.setFieldValue(`${i}.questionsEmployees`, []);
        formik.setFieldValue(`${i}.questionCategoryId`, null);
      });

      setEmployeeToQuestionCategoryMap(
        employees.reduce((acc, employee) => {
          const questionCategoryOptions = questionCategories.filter(
            (questionCategory) => questionCategory.siteId === employee.siteId,
          );

          acc[employee.id] = {
            questionCategoryOptions,
            selectedQuestionCategory: null,
          };

          return acc;
        }, {}),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  React.useEffect(() => {
    setEmployeeToQuestionCategoryMap(
      employees.reduce((acc, employee) => {
        const questionCategoryOptions = questionCategories.filter(
          (questionCategory) => questionCategory.siteId === employee.siteId,
        );

        acc[employee.id] = {
          questionCategoryOptions,
          selectedQuestionCategory: null,
        };

        return acc;
      }, {}),
    );
  }, [employees, questionCategories]);

  React.useEffect(() => {
    dispatch(getQuestionListRequest({}));
    dispatch(getQuestionCategoryListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setExistedValues(formik.values);
  }, [formik.values]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      {isOpen && (
        <>
          <DrawerHeader onCloseClick={onClose}>
            {t('common.review')}
          </DrawerHeader>
          <DrawerBody>
            <DrawerBodyContent>
              {formik.values.map((employeeQuestionsData, index) => (
                <Sheet
                  key={employeeQuestionsData.id}
                  sx={{ bgcolor: 'inherit' }}
                >
                  <DrawerBodySectionTitle>
                    {employeeQuestionsData.employeeFullName}
                  </DrawerBodySectionTitle>
                  <FormFieldContainer>
                    <ComboBox
                      options={
                        employeeToQuestionCategoryMap[
                          employeeQuestionsData.employeeId
                        ]?.questionCategoryOptions ?? []
                      }
                      onChange={(_, value) => {
                        setEmployeeToQuestionCategoryMap((prev) => ({
                          ...prev,
                          [employeeQuestionsData.employeeId]: {
                            ...prev[employeeQuestionsData.employeeId],
                            questionCategoryId: value?.id,
                          },
                        }));
                      }}
                      label={t('common.questions_category')}
                      error={Boolean(formik?.errors[index]?.questionCategoryId)}
                      helperText={
                        Boolean(formik?.errors[index]?.questionCategoryId)
                          ? formik?.errors[index]?.questionCategoryId
                          : undefined
                      }
                    />
                  </FormFieldContainer>
                  {employeeQuestionsData.questionsEmployees.map((answer, i) => {
                    if (answer.questionType === 'options') {
                      return (
                        <FormFieldContainer key={`${index}-answ-${i}`}>
                          <ButtonGroupsContainer>
                            <FormFiledButtonsGroupItemContainer>
                              <FormikButtonGroup
                                id={`${index}.questionsEmployees.${i}.value`}
                                label={answer.name}
                                formik={formik}
                                config={[
                                  {
                                    label: t('common.yes'),
                                    value: questionnaireRadioButtonValues.yes,
                                  },
                                  {
                                    label: t('common.no'),
                                    value: questionnaireRadioButtonValues.no,
                                  },
                                  {
                                    label: t('common.not_applicable'),
                                    value:
                                      questionnaireRadioButtonValues.notApplicable,
                                  },
                                ]}
                              />
                            </FormFiledButtonsGroupItemContainer>
                          </ButtonGroupsContainer>
                        </FormFieldContainer>
                      );
                    }

                    return (
                      <FormFieldContainer key={answer.id}>
                        <FormikTextarea
                          id={`${index}.questionsEmployees.${i}.value`}
                          name={`${index}.questionsEmployees.${i}.value`}
                          formik={formik}
                          label={answer.name}
                          minRows={2}
                        />
                      </FormFieldContainer>
                    );
                  })}

                  <FormFieldContainer>
                    <FormikSignatureField
                      formik={formik}
                      id={`${index}.signature`}
                      label={t('common.signature')}
                    />
                  </FormFieldContainer>

                  <Box
                    sx={{
                      px: 4,
                      bgcolor: 'inherit',
                      mt: 2,
                    }}
                  >
                    <FormikFilesUpload
                      name={`${index}.files`}
                      inputId={`review_upload_files_${index}`}
                      formik={formik}
                      accept={{
                        'image/*': ['.png', '.jpg', '.jpeg'],
                        'application/pdf': ['.pdf'],
                        'application/msword': ['.doc'],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                          '.docx',
                        ],
                        'application/vnd.ms-excel': ['.xls'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                          '.xlsx',
                        ],
                      }}
                    />
                  </Box>
                </Sheet>
              ))}
            </DrawerBodyContent>
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar
              onReset={onReset}
              onApply={formik.handleSubmit}
              onCancel={onClose}
            />
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
