import { ISagaActionBind } from '../../types';
import { IProductionWeeklyUphBySiteAndSkuModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionWeeklyUphBySiteAndSkuListRequest,
    saga: createListSaga<IProductionWeeklyUphBySiteAndSkuModel>({
      apiCall: Api.ProductionWeeklyUphBySiteAndSku.list,
      actionSuccess: actions.getProductionWeeklyUphBySiteAndSkuListSuccess,
      actionFailed: actions.getProductionWeeklyUphBySiteAndSkuListFailed,
    }),
  },
  {
    action: actions.getProductionWeeklyUphBySiteAndSkuCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionWeeklyUphBySiteAndSku.count,
      actionSuccess: actions.getProductionWeeklyUphBySiteAndSkuCountSuccess,
      actionFailed: actions.getProductionWeeklyUphBySiteAndSkuCountFailed,
    }),
  },
  {
    action: actions.exportProductionWeeklyUphBySiteAndSkuRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionWeeklyUphBySiteAndSku.export,
      actionSuccess: actions.exportProductionWeeklyUphBySiteAndSkuSuccess,
      actionFailed: actions.exportProductionWeeklyUphBySiteAndSkuFailed,
    }),
  },
];
