import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { useTop5CpuChartQuery } from '../../modules/utils/hooks/cpuTop5Chart.hooks';
import { paths } from '../../config';

interface IProps {
  year?: number;
  month?: number;
}

const Top5CpuChart: React.FC<IProps> = ({ year, month }) => {
  const { options } = useTop5CpuChartQuery(paths.TARGET_TOP5_CPU, year, month);

  return (
    options && (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    )
  );
};

export default Top5CpuChart;
