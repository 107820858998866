import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  ISiteTaktExportingSettingsModel,
  ISiteTaktExportingSettingsState,
} from '../types';

// default state
const defaultState: ISiteTaktExportingSettingsState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  apiKey: '',
  apiKeySecret: '',
  employeesEndpoint: '',
  punchesEndpoint: '',
  list: [],
  count: 0,
  comboboxList: [],
  comboboxSitesExportingList: [],
  isTaktSettingsListInProgress: false,
  isTaktSettingsCountInProgress: false,
  isManageTaktSettingsOperationInProgress: false,
};

/**
 * Flush actions
 */
const flushSiteTaktExportingSettingsState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createSiteTaktExportingSettingsRequest = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  error: false,
  isManageTaktSettingsOperationInProgress: true,
});

const createSiteTaktExportingSettingsSuccess = (
  state: ISiteTaktExportingSettingsState,
  payload: ISiteTaktExportingSettingsModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isManageTaktSettingsOperationInProgress: false,
});

const createSiteTaktExportingSettingsFailed = (
  state: ISiteTaktExportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isManageTaktSettingsOperationInProgress: false,
});

/**
 * Get list actions
 */
const getSiteTaktExportingSettingsListRequest = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  error: false,
  isTaktSettingsListInProgress: true,
});

const getSiteTaktExportingSettingsListSuccess = (
  state: ISiteTaktExportingSettingsState,
  payload: ISiteTaktExportingSettingsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isTaktSettingsListInProgress: false,
});

const getSiteTaktExportingSettingsListFailed = (
  state: ISiteTaktExportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isTaktSettingsListInProgress: false,
});

/**
 * Get count actions
 */
const getSiteTaktExportingSettingsCountRequest = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  error: false,
  isTaktSettingsCountInProgress: true,
});

const getSiteTaktExportingSettingsCountSuccess = (
  state: ISiteTaktExportingSettingsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isTaktSettingsCountInProgress: false,
});

const getSiteTaktExportingSettingsCountFailed = (
  state: ISiteTaktExportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isTaktSettingsCountInProgress: false,
});

/**
 * Deletion actions
 */
const deleteSiteTaktExportingSettingsRequest = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  error: false,
  isManageTaktSettingsOperationInProgress: true,
});

const deleteSiteTaktExportingSettingsSuccess = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  isManageTaktSettingsOperationInProgress: false,
});

const deleteSiteTaktExportingSettingsFailed = (
  state: ISiteTaktExportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isManageTaktSettingsOperationInProgress: false,
});

/**
 * Updating actions
 */
const updateSiteTaktExportingSettingsRequest = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  error: false,
  isManageTaktSettingsOperationInProgress: true,
});

const updateSiteTaktExportingSettingsSuccess = (
  state: ISiteTaktExportingSettingsState,
) => ({
  ...state,
  isManageTaktSettingsOperationInProgress: false,
});

const updateSiteTaktExportingSettingsFailed = (
  state: ISiteTaktExportingSettingsState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isManageTaktSettingsOperationInProgress: false,
});

/**
 * SiteTaktExportingSettings reducer
 */
export const siteTaktExportingSettings = createReducer<
  ISiteTaktExportingSettingsState
>({}, defaultState)
  // flush actions
  .on(
    actions.flushSiteTaktExportingSettingsState,
    flushSiteTaktExportingSettingsState,
  )
  // creation actions
  .on(
    actions.createSiteTaktExportingSettingsRequest,
    createSiteTaktExportingSettingsRequest,
  )
  .on(
    actions.createSiteTaktExportingSettingsSuccess,
    createSiteTaktExportingSettingsSuccess,
  )
  .on(
    actions.createSiteTaktExportingSettingsFailed,
    createSiteTaktExportingSettingsFailed,
  )
  // get list actions
  .on(
    actions.getSiteTaktExportingSettingsListRequest,
    getSiteTaktExportingSettingsListRequest,
  )
  .on(
    actions.getSiteTaktExportingSettingsListSuccess,
    getSiteTaktExportingSettingsListSuccess,
  )
  .on(
    actions.getSiteTaktExportingSettingsListFailed,
    getSiteTaktExportingSettingsListFailed,
  )
  // get count actions
  .on(
    actions.getSiteTaktExportingSettingsCountRequest,
    getSiteTaktExportingSettingsCountRequest,
  )
  .on(
    actions.getSiteTaktExportingSettingsCountSuccess,
    getSiteTaktExportingSettingsCountSuccess,
  )
  .on(
    actions.getSiteTaktExportingSettingsCountFailed,
    getSiteTaktExportingSettingsCountFailed,
  )
  // deleting actions
  .on(
    actions.deleteSiteTaktExportingSettingsRequest,
    deleteSiteTaktExportingSettingsRequest,
  )
  .on(
    actions.deleteSiteTaktExportingSettingsSuccess,
    deleteSiteTaktExportingSettingsSuccess,
  )
  .on(
    actions.deleteSiteTaktExportingSettingsFailed,
    deleteSiteTaktExportingSettingsFailed,
  )
  // updating actions
  .on(
    actions.updateSiteTaktExportingSettingsRequest,
    updateSiteTaktExportingSettingsRequest,
  )
  .on(
    actions.updateSiteTaktExportingSettingsSuccess,
    updateSiteTaktExportingSettingsSuccess,
  )
  .on(
    actions.updateSiteTaktExportingSettingsFailed,
    updateSiteTaktExportingSettingsFailed,
  );

export default siteTaktExportingSettings;
