import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { isEmpty, filter, omit } from 'lodash';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IAdditionalFeeModel,
  IAdditionalFeeCategory,
  IAdditionalFee,
  IAdditionalFeeCategoryModel,
  PayloadWithFilters,
} from '../types';
import { getAdditionalFeeCategoryList } from '../selectors/additionalFee.selector';

const getFilterWithIds = (ids: IdsArray) => ({
  filter: {
    where: {
      id: {
        inq: ids,
      },
    },
  },
});

function* fetchAdditionalFeeListRequest({
  payload: filter,
}: {
  payload: IFilter;
}): SagaIterator {
  try {
    const response: IAdditionalFeeModel[] = yield call(
      Api.AdditionalFee.list,
      filter,
    );
    yield put(actions.fetchAdditionalFeeListSuccess(response));
  } catch (e) {
    yield put(actions.fetchAdditionalFeeListFailure());
  }
}

function* fetchAdditionalFeeCountRequest(
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.AdditionalFee.count,
      action.payload,
    );
    yield put(actions.fetchAdditionalFeeCountSuccess(response.count));
  } catch (e) {
    yield put(actions.fetchAdditionalFeeCountFailure());
  }
}

function* createAdditionalFeeRequest({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IAdditionalFee>>): SagaIterator {
  const payloadData: IAdditionalFee = {
    ...omit(data, ['startingYearWeek', 'finishingYearWeek']),
  };

  if (data.startingYearWeek) {
    payloadData.startingYearWeek = Number(data.startingYearWeek);
  }

  if (data.finishingYearWeek) {
    payloadData.finishingYearWeek = Number(data.finishingYearWeek);
  }

  payloadData.cpu = Number(data.cpu);
  payloadData.quantity = Number(data.quantity);

  try {
    yield call(Api.AdditionalFee.create, payloadData);
    yield put(actions.createAdditionalFeeSuccess());
    yield put(actions.fetchAdditionalFeeListRequest({ filter: filters.list }));
    yield put(
      actions.fetchAdditionalFeeCountRequest({ filter: filters.count }),
    );
  } catch (e) {
    yield put(actions.createCostPlusSettingsFailed());
  }
}

function* deleteAdditionalFeeRequest(
  action: ISagaAction<PayloadWithFilters<{ ids: IdsArray }>>,
): SagaIterator {
  const {
    filters,
    data: { ids },
  } = action.payload;
  try {
    yield call(Api.AdditionalFee.delete, {
      where: { id: { inq: ids } },
    });
    yield put(actions.fetchAdditionalFeeListRequest({ filter: filters.list }));
    yield put(
      actions.fetchAdditionalFeeCountRequest({ filter: filters.count }),
    );
  } catch (e) {
    yield put(actions.deleteAdditionalFeeFailure());
  }
}

function* fetchAdditionalFeeCategoryListRequest(action: {
  payload: IFilter;
}): SagaIterator {
  try {
    const response: IAdditionalFeeCategoryModel[] = yield call(
      Api.AdditionalFeeCategory.list,
      action.payload,
    );
    yield put(actions.fetchAdditionalFeeCategoryListSuccess(response));
  } catch (e) {
    yield put(actions.fetchAdditionalFeeCategoryListFailure());
  }
}

function* fetchAdditionalFeeCategoriesCountRequest(
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.AdditionalFeeCategory.count,
      action.payload,
    );
    yield put(actions.fetchAdditionalFeeCategoriesCountSuccess(response.count));
  } catch (e) {
    yield put(actions.fetchAdditionalFeeCategoriesCountFailure());
  }
}

function* getAdditionalFeeCategoryListForUpdateRequest({
  payload: ids,
}: {
  payload: IdsArray;
}): SagaIterator {
  const { list: additionalFeeCategoryList } = (yield select(
    getAdditionalFeeCategoryList,
  )) as {
    list: IAdditionalFeeCategoryModel[];
  };
  // if categories already fetched
  if (!isEmpty(additionalFeeCategoryList)) {
    const feeCategoriesForUpdate = filter(
      additionalFeeCategoryList,
      (category) => ids?.includes(category.id),
    );
    yield put(
      actions.fetchAdditionalFeeCategoryListSuccess(feeCategoriesForUpdate),
    );
  } else {
    const filter = getFilterWithIds(ids);
    yield call(fetchAdditionalFeeCategoryListRequest, { payload: filter });
  }
}

function* sendForUpdateAdditionalFeesRequest({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IAdditionalFeeModel[]>>): SagaIterator {
  const payloadData: IAdditionalFeeModel[] = data.map(
    (additionalFee: IAdditionalFeeModel) => {
      const payloadAdditionalFee: IAdditionalFeeModel = {
        ...additionalFee,
      };

      if (additionalFee.startingYearWeek) {
        payloadAdditionalFee.startingYearWeek = Number(
          additionalFee.startingYearWeek,
        );
      }

      if (additionalFee.finishingYearWeek) {
        payloadAdditionalFee.finishingYearWeek = Number(
          additionalFee.finishingYearWeek,
        );
      }

      payloadAdditionalFee.cpu = Number(additionalFee.cpu);
      payloadAdditionalFee.quantity = Number(additionalFee.quantity);

      return payloadAdditionalFee;
    },
  );

  try {
    yield call(Api.AdditionalFee.bulkUpdate, payloadData);
    yield put(actions.fetchAdditionalFeeListRequest({ filter: filters.list }));
    // Do we really need to change count during update?
    // yield put(
    //   actions.fetchAdditionalFeeCountRequest({ filter: filters.count }),
    // );
  } catch (e) {
    yield put(actions.sendForUpdateAdditionalFeesFailure());
  }
}

function* sendForUpdateAdditionalFeeCategoriesRequest({
  payload: { data, filters },
}: ISagaAction<
  PayloadWithFilters<IAdditionalFeeCategoryModel[]>
>): SagaIterator {
  try {
    yield call(Api.AdditionalFeeCategory.bulkUpdate, data);
    yield put(
      actions.fetchAdditionalFeeCategoryListRequest({ filter: filters.list }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.sendForUpdateAdditionalFeeCategoriesFailure());
  }
}

function* createAdditionalFeeCategoryRequest({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IAdditionalFeeCategory>>): SagaIterator {
  try {
    yield call(Api.AdditionalFeeCategory.create, data);
    yield put(
      actions.fetchAdditionalFeeCategoryListRequest({ filter: filters.list }),
    );
    yield put(
      actions.fetchAdditionalFeeCategoriesCountRequest({
        filter: filters.count,
      }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.createAdditionalFeeCategoryFailure());
  }
}

function* deleteAdditionalFeeCategoryRequest(
  action: ISagaAction<PayloadWithFilters<IdsArray>>,
): SagaIterator {
  const { data, filters } = action.payload;
  try {
    yield call(Api.AdditionalFeeCategory.delete, {
      where: { id: { inq: data } },
    });
    yield put(
      actions.fetchAdditionalFeeCategoryListRequest({ filter: filters.list }),
    );
    yield put(
      actions.fetchAdditionalFeeCategoriesCountRequest({
        filter: filters.count,
      }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.deleteAdditionalFeeCategoryFailure());
  }
}

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.fetchAdditionalFeeListRequest,
    saga: fetchAdditionalFeeListRequest,
  },
  {
    action: actions.fetchAdditionalFeeCountRequest,
    saga: fetchAdditionalFeeCountRequest,
  },
  {
    action: actions.createAdditionalFeeRequest,
    saga: createAdditionalFeeRequest,
  },
  {
    action: actions.deleteAdditionalFeeRequest,
    saga: deleteAdditionalFeeRequest,
  },

  // Categories
  {
    action: actions.fetchAdditionalFeeCategoryListRequest,
    saga: fetchAdditionalFeeCategoryListRequest,
  },
  {
    action: actions.fetchAdditionalFeeCategoriesCountRequest,
    saga: fetchAdditionalFeeCategoriesCountRequest,
  },
  {
    action: actions.getAdditionalFeeCategoryListForUpdateRequest,
    saga: getAdditionalFeeCategoryListForUpdateRequest,
  },

  {
    action: actions.sendForUpdateAdditionalFeesRequest,
    saga: sendForUpdateAdditionalFeesRequest,
  },

  {
    action: actions.sendForUpdateAdditionalFeeCategoriesRequest,
    saga: sendForUpdateAdditionalFeeCategoriesRequest,
  },
  {
    action: actions.createAdditionalFeeCategoryRequest,
    saga: createAdditionalFeeCategoryRequest,
  },
  {
    action: actions.deleteAdditionalFeeCategoryRequest,
    saga: deleteAdditionalFeeCategoryRequest,
  },
];
