import React from 'react';
import { Box } from '@mui/material';
import { IWeekBasedInvoiceModel } from 'src/modules/types';

type IInvoiceGeneralInfoTableProps = Pick<
  IWeekBasedInvoiceModel,
  | 'purchaseOrder'
  | 'dueDate'
  | 'weekEndingDate'
  | 'paymentTerms'
  | 'invoiceDate'
  | 'weekNumber'
>;

export const InvoiceGeneralInfoTable: React.FC<IInvoiceGeneralInfoTableProps> = ({
  purchaseOrder,
  dueDate,
  weekEndingDate,
  paymentTerms,
  invoiceDate,
  weekNumber,
}) => {
  return (
    <Box>
      <Box
        component="table"
        sx={{
          width: '100%',
          '& td': {
            padding: 0.5,
          },
          ' & th': {
            padding: 1,
            backgroundColor: '#bac3fd',
          },
        }}
      >
        <tr>
          <th>PO</th>
          <th>Week Ending Day</th>
          <th>Payment Terms</th>
          <th>Week</th>
          <th>Invoice Date</th>
          <th>Due Date</th>
        </tr>
        <tr>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {purchaseOrder}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {weekEndingDate}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {paymentTerms}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {weekNumber}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {invoiceDate}
          </Box>
          <Box component="td" sx={{ textAlign: 'center' }}>
            {dueDate}
          </Box>
        </tr>
      </Box>
    </Box>
  );
};
