import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

exporting(Highcharts);

interface ISplineSymbolsChartSeriesItem {
  name: string;
  data: number[];
}

export type SplineSymbolsChartSeries = ISplineSymbolsChartSeriesItem[];

export interface SplineSymbolsChartProps {
  categories: string[];
  series: SplineSymbolsChartSeries;
  title?: string;
  yAxisTitle?: string;
  yAxisTitleFormat?: string;
}

export const SplineSymbolsChart = ({
  categories,
  series,
  title,
  yAxisTitle,
  yAxisTitleFormat,
}: SplineSymbolsChartProps) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'spline',
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: title,
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      labels: {
        format: yAxisTitleFormat, // '{value}°'
      },
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1,
        },
      },
    },
    series: series.map((item) => ({ ...item, type: 'spline' })),
    exporting: {
      enabled: true,
    },
  };

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Box>
  );
};
