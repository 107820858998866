import React from 'react';
import { Api } from 'src/modules/utils';
import ModalDialog from 'src/components/ModalDialog';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Options } from 'highcharts';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

exporting(Highcharts);

interface IGap {
  minutes: string;
  datetime: string;
}
interface IExpeditorsGapsChartDataResponse {
  date: string;
  startShiftDateTime: string;
  endShiftDateTime: string;
  siteId: number;
  siteName: string;
  wmsLogin: string;
  employee: string;
  gaps: IGap[];
}

interface IExpeditorsGapsDailyChartProps {
  filter: {
    date: string;
    shiftId: number;
    siteId: number;
    wmsLogin: string;
  };
  onClose: () => void;
}

const COLOR_GRAY = '#CCCCCC';
const MAX_NORMAL_TRANSACTION_DURATION_IN_MINUTES = 10;

export const ExpeditorsGapsDailyChart: React.FC<IExpeditorsGapsDailyChartProps> = ({
  filter,
  onClose,
}) => {
  const [data, setData] = React.useState<IExpeditorsGapsChartDataResponse>({
    gaps: [],
    date: filter.date,
    siteId: filter.siteId,
    siteName: '',
    startShiftDateTime: '',
    endShiftDateTime: '',
    wmsLogin: '',
    employee: '',
  });
  const [isRequestInProgress, setIsRequestInProgress] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const gapChartData = await Api.ExpeditorsDaily.gapChart({
          filter: { where: filter },
        });
        setData(gapChartData);
        setIsRequestInProgress(false);
      } catch {
        setIsRequestInProgress(false);
      }
    };

    fetchData();
  }, [filter]);

  const gaps = data.gaps.map((gap) => ({
    ...gap,
    // We assume that date in UTC timezone
    datetime: Date.parse(gap.datetime + '+00:00'),
  }));
  // Chart could handle only properly sorted data
  gaps.sort(function (a, b) {
    return Number(new Date(a.datetime)) - Number(new Date(b.datetime));
  });

  const options: Options = {
    title: {
      text: data.employee,
    },

    tooltip: {
      style: {
        border: '1px solid yellow',
      },
      borderColor: COLOR_GRAY,
      pointFormat: `<span style=color:${COLOR_GRAY}>\u25CF</span> {series.name}: <b>{point.y}</b><br/>`,
    },

    credits: {
      enabled: false,
    },

    chart: {
      height: 600,
    },

    yAxis: {
      title: {
        text: 'Transaction Time (minutes)',
      },
    },

    xAxis: {
      type: 'datetime',
      title: {
        text: 'Shift Time',
      },
    },

    legend: {
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'bottom',
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: true,
          fillColor: '#a8a8a8',
          radius: 4,
        },
      },
    },

    series: [
      {
        name: 'Transaction Duration',
        type: 'line',
        color: COLOR_GRAY,
        lineWidth: 3,

        data: gaps.map((gap) => ({
          x: gap.datetime,
          y: Number(gap.minutes),
          name: gap.datetime,
        })),

        zoneAxis: 'x',
        zones: gaps.map((gap) => ({
          value: gap.datetime,
          color:
            parseFloat(gap.minutes) > MAX_NORMAL_TRANSACTION_DURATION_IN_MINUTES
              ? 'red'
              : COLOR_GRAY,
        })),
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <ModalDialog title="Gaps" onClose={onClose} maxWidth="xl">
      <Box
        style={{
          height: '70vh',
          display: 'flex',
        }}
      >
        {isRequestInProgress ? (
          <MainContentLoader />
        ) : (
          <Box style={{ width: '100%' }}>
            <HighchartsReact
              options={options}
              highcharts={Highcharts}
              updateArgs={[true, true, true]}
            />
          </Box>
        )}
      </Box>
    </ModalDialog>
  );
};
