import React from 'react';
import { Box } from '@mui/material';

interface IInvoiceParticipant {
  title?: string;
}

export const InvoiceParticipant: React.FC<IInvoiceParticipant> = ({
  title,
  children,
}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {title && <Box style={{ paddingRight: 8 }}>{title}</Box>}
      <Box>{children}</Box>
    </Box>
  );
};
