import React from 'react';
import { useTranslation } from 'react-i18next';

import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { CheckboxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/CheckboxFilter';
import { AnyObject } from 'src/modules/types';
import { ShiftsComboBoxFilter } from '../../../../../components/Table/components/TableActions/components/Filter/components/ShiftsComboBoxFilter';

interface IWorkdaysFilterProps<T = Record<string, any>> {
  onFiltersFormSubmit(filters: AnyObject): void;
  getLabel({
    filterName,
    labelPrefix,
  }: {
    filterName: keyof T;
    labelPrefix: string;
  }): string;
  getFilterCommonPropsByFilterName(
    filterName: keyof T,
  ): { value: any; name: keyof T };
}

export const WorkdaysFilter: React.FC<IWorkdaysFilterProps> = ({
  getFilterCommonPropsByFilterName,
  onFiltersFormSubmit,
  getLabel,
}) => {
  const { t } = useTranslation();

  const days = React.useMemo(
    () =>
      ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((i) => (
        <FormFieldContainer key={i}>
          <FormFieldContainer>
            <CheckboxFilter
              label={getLabel({
                filterName: i,
                labelPrefix: t(`common.${i}`),
              })}
              {...getFilterCommonPropsByFilterName(i)}
            />
          </FormFieldContainer>
        </FormFieldContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Filter onSubmit={onFiltersFormSubmit}>
      <FormFieldContainer>
        <ShiftsComboBoxFilter
          label={getLabel({
            filterName: 'shiftId',
            labelPrefix: t('workdays.shift_name'),
          })}
          {...getFilterCommonPropsByFilterName('shiftId')}
        />
      </FormFieldContainer>

      {days}
    </Filter>
  );
};
