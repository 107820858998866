import { Box, useTheme } from '@mui/joy';
import { Typography } from '@mui/joy';
import React from 'react';
import { ArrowDownSvg } from '../svgIcons';

interface IThProps {
  children: React.ReactNode;
  sortDirection?: 'asc' | 'desc';
  onClick?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
}

export const Th = ({ children, onClick, sortDirection }: IThProps) => {
  const theme = useTheme();

  return (
    <Box
      component="th"
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'text',
        bgcolor: `${theme.palette.colors.background.bg_primary} !important`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 44,
          px: 3,
          bgcolor: sortDirection ? 'colors.background.bg_secondary' : 'inherit',
        }}
      >
        <Typography
          level="text_xs"
          fontWeight="semi_bold"
          textColor="colors.text.text_tertiary.main"
        >
          {children}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'transform 150ms ease-in-out',
            opacity: sortDirection ? 1 : 0,
            ml: '2px',
            transform:
              sortDirection === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <ArrowDownSvg />
        </Box>
      </Box>
    </Box>
  );
};
