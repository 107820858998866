import React from 'react';
import {
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  Sheet,
  useTheme,
} from '@mui/joy';
import TabList, { TabListProps } from '@mui/joy/TabList';
import { TopToolbarUserCommonData } from '../../TopToolbar';
import { useMainLayoutContext } from '../../Layout/MainLayout/MainLayoutContext';
import { MoreVert } from '@mui/icons-material';
import { useIsTabsOnSmallDevice } from './pageContentWithTabs.constants';

interface IPageContentTabsListProps extends TabListProps {
  children: React.ReactNode;
}

export const PageContentTabsList = ({
  children,
  ...props
}: IPageContentTabsListProps) => {
  const { headerHeight } = useMainLayoutContext();

  const isSmallDevice = useIsTabsOnSmallDevice();

  return (
    <Sheet
      sx={{
        px: isSmallDevice ? 0 : 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'base.white',
        height: headerHeight,
      }}
    >
      {isSmallDevice && (
        <SmallDeviceBody {...props}>{children}</SmallDeviceBody>
      )}
      {!isSmallDevice && (
        <RegularDeviceBody {...props}>{children}</RegularDeviceBody>
      )}

      <TopToolbarUserCommonData />
    </Sheet>
  );
};

function RegularDeviceBody({ children, ...props }: IPageContentTabsListProps) {
  return (
    <TabList
      {...props}
      sx={{
        width: '100%',
        boxShadow: 'none',
        ...props?.sx,
      }}
    >
      {children}
    </TabList>
  );
}

function SmallDeviceBody({ children, ...props }: IPageContentTabsListProps) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleOpenChange = React.useCallback(
    (event: React.SyntheticEvent | null, isOpen: boolean) => {
      setOpen(isOpen);
    },
    [],
  );

  return (
    <Dropdown open={open} onOpenChange={handleOpenChange}>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{
          root: {
            variant: 'plain',
          },
        }}
      >
        <MoreVert />
      </MenuButton>
      <Menu sx={{ px: 1, py: 1, zIndex: theme.zIndex.snackbar + 1 }}>
        <TabList
          {...props}
          sx={{
            width: '100%',
            boxShadow: 'none',
            ...props?.sx,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </TabList>
      </Menu>
    </Dropdown>
  );
}
