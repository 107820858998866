import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/joy';

export interface ITableSelectedItemsActionsItemProps {
  tooltip: string;
  icon: React.ReactElement;
  onClick: () => void;
}

export const TableSelectedItemsActionsItem = ({
  tooltip,
  icon,
  onClick,
}: ITableSelectedItemsActionsItemProps) => {
  return (
    <Box sx={{ ml: 1 }}>
      <Tooltip title={tooltip}>
        <IconButton onClick={onClick}>{icon}</IconButton>
      </Tooltip>
    </Box>
  );
};
