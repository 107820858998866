import React from 'react';
import { AnyObject } from 'src/modules/types';
import { TableContext } from '../../../../../../TableContext';

export interface IFilterFormProps {
  onSubmit: (filters: AnyObject) => void;
}

export const FilterForm = ({
  children,
  onSubmit,
}: React.PropsWithChildren<IFilterFormProps>) => {
  const { filters } = React.useContext(TableContext);

  const handleFilterFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(filters);
  };

  return <form onSubmit={handleFilterFormSubmit}>{children}</form>;
};
