import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormActionsContainer } from 'src/components/Form/FormActionsContainer';
import { SubmitButton } from 'src/components/Form/SubmitButton';

export type DeletionType = 'all' | 'current';

interface IDeleteRecurringTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDeletion: (deletionType: DeletionType) => void;
}

export const DeleteRecurringTaskModal: React.FC<IDeleteRecurringTaskModalProps> = ({
  isOpen,
  onClose,
  onConfirmDeletion,
}) => {
  const { t } = useTranslation();

  const [deletionType, setDeletionType] = React.useState<DeletionType>(
    'current',
  );

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onConfirmDeletion(deletionType);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton onClick={onClose} size="large">
          <Close />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box
          component="form"
          noValidate
          sx={{ width: 250 }}
          onSubmit={onSubmit}
        >
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={deletionType}
            onChange={(e) => setDeletionType(e.target.value as DeletionType)}
          >
            <FormControlLabel
              value="current"
              control={<Radio />}
              label="Current task"
            />
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="All tasks"
            />
          </RadioGroup>

          <FormActionsContainer>
            <SubmitButton
              sx={(theme: any) => ({
                backgroundColor: theme.palette.error.main,
                '&:hover': {
                  backgroundColor: theme.palette.error.dark,
                },
              })}
            >
              {t('common.delete')}
            </SubmitButton>
          </FormActionsContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
