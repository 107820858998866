import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api, i18n } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  IFilter,
  IRecruitingDashboardSummaryModel,
  ISagaAction,
  ISagaActionBind,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Get recruiting dashboard summary list
 */
export const getRecruitingDashboardSummaryListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IRecruitingDashboardSummaryModel[] = yield call(
      Api.RecruitingDashboardSummary.list,
      action.payload,
    );
    yield put(actions.getRecruitingDashboardSummaryListSuccess(response));
  } catch (e) {
    yield put(actions.getRecruitingDashboardSummaryListFailed());
    yield put(
      actions.addProcessStatus({
        variant: 'error',
        message: i18n.et('recruiting_dashboard', (e as any).message),
      }),
    );
  }
};

/**
 * Get recruiting dashboard summary count
 */
export const getRecruitingDashboardSummaryCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.RecruitingDashboardSummary.count,
      action?.payload,
    );
    yield put(actions.getRecruitingDashboardSummaryCountSuccess(response));
  } catch (e) {
    yield put(actions.getRecruitingDashboardSummaryCountFailed());
    yield put(
      actions.addProcessStatus({
        variant: 'error',
        message: i18n.et('recruiting_dashboard', (e as any).message),
      }),
    );
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.RecruitingDashboardSummary.export,
  actionFailed: actions.exportRecruitingDashboardSummaryFailed,
  actionSuccess: actions.exportRecruitingDashboardSummarySuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportRecruitingDashboardSummaryRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  // get recruiting dashboard summary list
  {
    action: actions.getRecruitingDashboardSummaryListRequest,
    saga: getRecruitingDashboardSummaryListRequestSaga,
  },
  // get recruiting dashboard summary count
  {
    action: actions.getRecruitingDashboardSummaryCountRequest,
    saga: getRecruitingDashboardSummaryCountRequestSaga,
  },
  // get recruiting dashboard summary export
  {
    action: actions.exportRecruitingDashboardSummaryRequest,
    saga: exportRecruitingDashboardSummaryRequestSaga,
  },
];
