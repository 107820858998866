import React from 'react';
import { useDispatch } from 'react-redux';
import { createPolicyRequest } from '../../modules/actions';
import {
  useBrowserHistoryFunctions,
  useValidate,
} from '../../modules/utils/hooks';
import { ICreatePolicyRequest } from '../../modules/types';
import { useTranslation } from 'react-i18next';
import { createBulkPolicyScheme } from '../../modules/schemes';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { FormActions } from '../../components/Form/FormActions';
import ResourcesWithActionsComboBox from '../../components/Formik/comboboxes-with-entities/ResourcesWithActionsComboBox';
import ClientKeysComboBox from '../../components/Formik/comboboxes-with-entities/ClientKeysCombobox';
import {
  MultiRowForm,
  MultiRowFormContainer,
  MultiRowFormDeleteRow,
  MultiRowFormField,
  MultiRowFormRow,
} from 'src/components/MultiRowForm';
import { MultiRowFormAddRow } from 'src/components/MultiRowForm/MultiRowFormAddRow';
import RolesCombobox from '../../components/Formik/comboboxes-with-entities/RolesCombobox';
import { PageContentWithTopToolbar } from 'src/components/PageContent';

const PoliciesCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  const initialValues: ICreatePolicyRequest = {
    v0: '',
    v1: '',
    v2: '',
  };

  const validate = useValidate<Array<ICreatePolicyRequest>>(
    createBulkPolicyScheme,
  );

  // create dispatcher
  const dispatcher = useDispatch();

  const formik = useFormik<Array<ICreatePolicyRequest>>({
    initialValues: [initialValues],
    validate,
    onSubmit: (data) => {
      dispatcher(
        createPolicyRequest({
          data: data.map((item) => omit(item, 'id')),
          navigate,
        }),
      );
    },
  });

  return (
    <PageContentWithTopToolbar>
      <MultiRowFormContainer>
        <MultiRowForm onSubmit={formik.handleSubmit} noValidate>
          {formik.values.map((_, index) => (
            <MultiRowFormRow key={index}>
              <MultiRowFormField
                fieldId={`${index}.v0`}
                fieldLabel={t('policies.v0')}
                renderField={({ id, label }) => (
                  <RolesCombobox
                    id={id}
                    name={id}
                    required
                    placeholder={label}
                    fieldAsId="key"
                    formik={formik}
                  />
                )}
              />
              <MultiRowFormField
                fieldId={`${index}.v1`}
                fieldLabel={t('policies.v1')}
                renderField={({ id, label }) => (
                  <ResourcesWithActionsComboBox
                    id={`${index}.id`}
                    action={`${index}.v2`}
                    resource={id}
                    required={true}
                    formik={formik}
                    placeholder={label}
                  />
                )}
              />
              <MultiRowFormField
                fieldId={`${index}.v3`}
                fieldLabel={t('policies.v3')}
                renderField={({ id, label }) => (
                  <ClientKeysComboBox
                    id={id}
                    formik={formik}
                    placeholder={label}
                  />
                )}
              />
              <MultiRowFormDeleteRow formik={formik} index={index} />
            </MultiRowFormRow>
          ))}

          <FormActions submitBtnTitle={t('common.create')} />

          <MultiRowFormAddRow
            formik={formik}
            nullishValues={{ v0: '', v1: '', v2: '' }}
          />
        </MultiRowForm>
      </MultiRowFormContainer>
    </PageContentWithTopToolbar>
  );
};

export default PoliciesCreate;
