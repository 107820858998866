import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExceptionsFromEmployeesReport } from 'src/components/Reports/ExceptionsFromEmployeesReport';
import { clients } from 'src/modules/constants';

interface IProps {
  siteId?: number;
  employeeId?: number;
}

export const OptoroExceptionsFromEmployees: React.FC<IProps> = ({
  siteId,
  employeeId,
}) => {
  const { t } = useTranslation();

  return (
    <ExceptionsFromEmployeesReport
      client={clients.optoro}
      siteId={siteId}
      employeeId={employeeId}
      tableName={t('optoro_reports.table_names.exception_from_employees')}
    />
  );
};
