import React from 'react';
import { Box, Dialog, TextField } from '@mui/material';
import { Button, MaterialIcon } from '../../components';
import { isBlockActive } from '../../utils/SlateUtilityFunctions';
import usePopup from '../../utils/usePopup';
import { insertEmbed } from '../../utils/embed';
import { Button as SubmitButton } from '../../../../../components/Form/Button';
import { useTranslation } from 'react-i18next';
import { sendMedia } from '../../../../../modules/utils/hooks/wiki-setup.hooks';

let imageFile: File | null = null;
const Embed = ({ editor, format }: any) => {
  const { t } = useTranslation();
  const urlInputRef = React.useRef<any>();
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [showInput, setShowInput] = usePopup(urlInputRef);
  const [formData, setFormData] = React.useState({
    url: '',
    width: '',
    height: '',
  });
  // const [file, setFile] = React.useState(null);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState<string>('');
  const inputRef = React.useRef<any>(null);
  const handleButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // // setShowInput((prev) => !prev);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    // TODO: move preview functionality to common hook
    const fileReader: FileReader = new FileReader();
    const [file] = [...event.target.files!];

    fileReader.onload = () => {
      setImagePreview(fileReader.result as string);
    };

    if (file) {
      fileReader.readAsDataURL(file);
      // setFile(file);
      imageFile = file;
    }

    // onChange(file);
  };

  const handleDialogClose = () => {
    inputRef.current.value = null;
    setImagePreview('');
    // setFile(null);
    imageFile = null;
  };
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setImageLoading(true);
    const url = await sendMedia(imageFile as any);
    insertEmbed(editor, { ...formData, url }, format);
    // setShowInput(false);
    setFormData({
      url: '',
      width: '',
      height: '',
    });
    setImageLoading(false);
    handleDialogClose();
  };
  return (
    <Box
      // ref={urlInputRef}
      className="popup-wrapper"
    >
      <Button
        // style={{
        //   border: showInput ? '1px solid lightgray' : '',
        //   borderBottom: 'none',
        // }}
        format={format}
        onClick={handleButtonClick}
      >
        <MaterialIcon icon={format} active={isBlockActive(editor, format)} />
      </Button>
      {/* Hidden input for upload file */}
      <input ref={inputRef} type="file" onChange={handleImageChange} hidden />
      {/*{imagePreview && (*/}
      <Dialog
        onClose={handleDialogClose}
        open={!!imagePreview}
        // className={classes.dialog}
      >
        <Box sx={{ margin: '8px auto', height: 300, padding: 3 }}>
          <img
            // width="100%"
            height="300"
            src={imagePreview}
            alt="preview"
          />
        </Box>

        <Box
          sx={{
            padding: 2,
            margin: '24px auto 0 ',
            maxWidth: '75%',
            minWidth: 390,
          }}
        >
          {/*<input*/}
          {/*  type="text"*/}
          {/*  placeholder="Enter url"*/}
          {/*  value={formData.url}*/}
          {/*  onChange={(e) =>*/}
          {/*    setFormData((prev) => ({ ...prev, url: e.target.value }))*/}
          {/*  }*/}
          {/*/>*/}
          <Box sx={{ display: 'flex', gap: 16 }}>
            <TextField
              placeholder="Enter width of frame"
              value={formData.width}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, width: e.target.value }))
              }
            />
            <TextField
              placeholder="Enter height of frame"
              value={formData.height}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, height: e.target.value }))
              }
            />
          </Box>
          <Box
            sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}
          >
            <SubmitButton
              onClick={handleFormSubmit}
              isLoading={Number(imageLoading)}
              color="primary"
            >
              {t('common.upload')}
            </SubmitButton>
          </Box>
        </Box>
      </Dialog>
      {/*// <Box className="popup">// // </Box>*/}
      {/*)}*/}
    </Box>
  );
};

export default Embed;
