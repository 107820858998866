import React from 'react';
import { Box, Typography, TypographyProps } from '@mui/joy';

interface IMainIndicatorsWidgetItemValueProps {
  value?: number;
  units?: string;
  valueProps?: TypographyProps;
  unitsProps?: TypographyProps;
}

export const MainIndicatorsWidgetItemValue = ({
  value,
  units,
  valueProps,
  unitsProps,
}: IMainIndicatorsWidgetItemValueProps) => {
  const isValueProvided = value !== undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography
        level="display_md"
        fontWeight="semi_bold"
        textColor="colors.text.text_primary.main"
        {...valueProps}
      >
        {isValueProvided ? value.toLocaleString() : '-'}
      </Typography>
      {units && isValueProvided && (
        <Typography
          level="display_md"
          fontWeight="semi_bold"
          textColor="colors.text.text_primary.main"
          {...unitsProps}
        >
          {units}
        </Typography>
      )}
    </Box>
  );
};
