import { useMutation, useQuery, useQueryClient } from 'react-query';

import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from 'src/modules/utils/Api';
import {
  AnyObject,
  IFilter,
  ShiftAutomaticPointsSettings,
} from 'src/modules/types';

const getShiftAutomaticPointsSettingsFetchParameters = (shiftId?: number) => {
  const filter: IFilter = { filter: { where: { shiftId } } };
  const queryKey = getQueryKey('/shift-automatic-points-settings', filter);

  return { filter, queryKey };
};

export const useShiftAutomaticPointsSettings = (shiftId?: number) => {
  const { filter, queryKey } = getShiftAutomaticPointsSettingsFetchParameters(
    shiftId,
  );
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => Api.ShiftAutomaticPointsSettings.getSettings(filter),
    { enabled: Boolean(shiftId), retry: false, cacheTime: 0 },
  );

  return {
    data,
    isLoading,
    error,
  };
};

export const useShiftAutomaticPointsSettingsForm = (shiftId?: number) => {
  const { queryKey } = getShiftAutomaticPointsSettingsFetchParameters(shiftId);
  const queryClient = useQueryClient();

  // get data
  const { data, isLoading } = useShiftAutomaticPointsSettings(shiftId);

  // get mutation functions for sending data to server
  const { mutate: createSettings, isLoading: createLoading } = useMutation(
    (data: AnyObject) => Api.ShiftAutomaticPointsSettings.createSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );
  const { mutate: updateSettings, isLoading: updateLoading } = useMutation(
    (data: AnyObject) => Api.ShiftAutomaticPointsSettings.updateSettings(data),
    // invalidate data
    { onSuccess: () => queryClient.invalidateQueries(queryKey) },
  );

  // submit function
  const sendToServer = (settings: ShiftAutomaticPointsSettings) => {
    // update Settings
    if (data?.id) {
      updateSettings({
        id: data.id,
        ...settings,
      });
    }
    // create new Settings
    else {
      createSettings({
        ...settings,
        shiftId,
      });
    }
  };

  const sendLoading = createLoading || updateLoading;

  return { data, isLoading, sendToServer, sendLoading };
};
