import React from 'react';
import { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';

import { IFilterData } from 'src/modules/types';
import { ICommonFilterProps } from '../../types';
import { TableContext } from '../../../../../../TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';

interface IComboboxTableFilterProps
  extends Omit<IComboboxProps, 'value' | 'name'>,
    ICommonFilterProps {
  requestFilter?: IFilterData;
}
export const ComboboxTableFilter: React.FC<IComboboxTableFilterProps> = ({
  value,
  name,
  options,
  ...restComboBoxProps
}) => {
  const {
    setFilter,
    getFilterByName,
    useInitFilterEffect,
    filters,
  } = React.useContext(TableContext);

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue = options.find((option) => option.id === valueId) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
    reason: AutocompleteChangeReason,
  ) => {
    setFilter(name, value?.id);

    if (restComboBoxProps.onChange) {
      restComboBoxProps.onChange(e, value, reason);
    }
  };

  useInitFilterEffect(name, value);

  React.useEffect(() => {
    // Some filters depends on other filters (ex: departments combobox may depends on site one)
    // and we should update value in current combobox in case value in one that current depends on
    // is changed
    const comboBoxValue =
      options.find((option) => option.id === valueId) || null;

    if (!comboBoxValue && filters[name]) {
      setFilter(name, '');
    }
  }, [filters, name, options, setFilter, valueId]);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={options}
      {...restComboBoxProps}
      onChange={onSelectOption}
    />
  );
};
