import { createAction } from 'redux-act';
import { ExportActionCreator } from 'src/modules/utils/helpers/creators/export';
import {
  ICountResponse,
  ICreateQuestionRequest,
  IdsArray,
  IFilter,
  IQuestionModel,
  IUpdateQuestionRequest,
  PayloadWithFilters,
} from '../../types';

export const flushQuestionState = createAction('Flush question data in store');

export const createQuestionRequest = createAction<
  PayloadWithFilters<ICreateQuestionRequest>
>('Make request to create a new question');
export const createQuestionSuccess = createAction<IQuestionModel>(
  'New question has been created successfully',
);
export const createQuestionFailed = createAction(
  'Cannot create a new question',
);

export const getQuestionListRequest = createAction<IFilter>(
  'Make request to fetch question list',
);
export const getQuestionListSuccess = createAction<IQuestionModel[]>(
  'Question list has been fetched successfully',
);
export const getQuestionListFailed = createAction('Cannot fetch question list');

export const getQuestionCountRequest = createAction<IFilter>(
  'Make request to get question count',
);
export const getQuestionCountSuccess = createAction<ICountResponse>(
  'Question count has been fetched successfully',
);
export const getQuestionCountFailed = createAction('Cannot get question count');

export const deleteQuestionRequest = createAction<PayloadWithFilters<IdsArray>>(
  'Make request to delete questions',
);
export const deleteQuestionSuccess = createAction(
  'Questions deleted successfully',
);
export const deleteQuestionFailed = createAction('Cannot delete questions');

export const updateQuestionsRequest = createAction<
  PayloadWithFilters<IUpdateQuestionRequest[]>
>('Make request to update questions');
export const updateQuestionsFailed = createAction('Cannot update questions');

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Question').takeExportActions();

export const exportQuestionRequest = exportReportRequest;
export const exportQuestionSuccess = exportReportSuccess;
export const exportQuestionFailed = exportReportFailed;
