import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/joy/Button';
import { AnyObject } from 'src/modules/types';

export interface SubmitButtonProps extends AnyObject {
  isLoading?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  disabled,
  isLoading,
  fullWidth,
  ...rest
}) => {
  const { t } = useTranslation();

  const title = children || t('common.submit');

  return (
    <Button
      {...rest}
      type="submit"
      color="primary"
      disabled={disabled}
      fullWidth={fullWidth}
      loading={isLoading}
    >
      {title}
    </Button>
  );
};
