import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { HeadCell, ITableSyncProps } from '../../../components/EnhancedTable';
import {
  exportRecruitingDashboardSummaryRequest,
  getRecruitingDashboardSummaryCountRequest,
  getRecruitingDashboardSummaryListRequest,
} from '../../../modules/actions';
import {
  getRecruitingDashboardSummaryCount,
  getRecruitingDashboardSummaryList,
} from '../../../modules/selectors/recruitingDashboardSummary';
import { ITableFilter } from '../../../components/EnhancedTable/EnhancedTableFilter';
import { useTranslation } from 'react-i18next';
import {
  useBrowserHistoryFunctions,
  useCreateExportProps,
  useRecruitingDashboardSummaryDefaultFilters,
} from '../../../modules/utils';
import { map } from 'lodash';
import { AnyObject } from '../../../modules/types';
import { paths } from '../../../config';
import { TableInPageWithTabs } from 'src/components/EnhancedTable/TableInPageWithTabs';

const DEFAULT_ORDER_BY = 'siteId';
const DEFAULT_ORDER = 'desc';

export const RecruitingDashboardSummaryTable = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const defaultFilter = useRecruitingDashboardSummaryDefaultFilters();

  // fetch recruiting dashboard list
  const list = useSelector(getRecruitingDashboardSummaryList, shallowEqual);

  const exportProps = useCreateExportProps(
    exportRecruitingDashboardSummaryRequest,
  );
  const count = useSelector(getRecruitingDashboardSummaryCount, shallowEqual);

  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          fillPercent: `${item.fillPercent}%`,
          timeDiff: item.timeDiff,
          sx: { cursor: 'pointer' },
        };
      }),
    [list],
  );

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'siteId',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.siteId'),
    },
    {
      id: 'siteName',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.siteName'),
    },
    {
      id: 'openPositions',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.openPositions'),
    },
    {
      id: 'totalActivePosition',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.totalActivePosition'),
    },
    {
      id: 'timeDiff',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.timeDiff'),
    },
    {
      id: 'fillPercent',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.fillPercent'),
    },
    {
      id: 'totalLost',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.totalLost'),
    },
    {
      id: 'totalAdded',
      disablePadding: false,
      label: t('recruiting_dashboards_summary.totalAdded'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'siteId',
      label: t('recruiting_dashboards_summary.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
  ];

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    dispatcher(
      getRecruitingDashboardSummaryListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getRecruitingDashboardSummaryCountRequest({ filter: { where } }),
    );
  };

  const onRowClick = (row: AnyObject) => {
    pushToHistory({
      pathname: paths.RECRUITING_DASHBOARD_DETAILS.replace(
        ':siteId',
        row.siteId,
      ),
    });
  };

  return (
    <TableInPageWithTabs
      data={computedList}
      count={count}
      selectIndex="siteId"
      defaultOrder={DEFAULT_ORDER}
      defaultOrderBy={DEFAULT_ORDER_BY}
      tableName={t('recruiting_dashboards_summary.table_name')}
      headCells={headCells}
      filters={filters}
      onSync={onSync}
      onRowClick={onRowClick}
      disableSelection={true}
      exportProps={exportProps}
      additionalWhereForExport={defaultFilter.where}
    />
  );
};
