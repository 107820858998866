import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EmailDataForm } from '../EmailDataForm';
import {
  ExportType,
  IEmailData,
  IExportColumnsData,
} from '../../../modules/types/export';
import { useTranslation } from 'react-i18next';
import { ComposeFiltersProps, useComposeFilters } from '../../../modules/utils';
import { map, reduce, filter, isEmpty } from 'lodash';
import { HeadCell } from '../index';
import { IWhere } from 'src/modules/types';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { List, Sheet, Typography } from '@mui/joy';
import { ButtonGroup } from 'src/components/_ui-kit/ButtonGroup';
import { ListItemWithCheckbox } from 'src/components/_ui-kit/ListItem';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useSelector } from 'react-redux';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

const defaultData = {
  type: 'csv' as const,
  exportAllPages: true,
};

export interface IEnhancedTableExportProps {
  isOpen: boolean;
  onClose: () => void;
  composeFilters: ComposeFiltersProps;
  onExportCSV?: (filters: IExportColumnsData) => void;
  onExportPDF?: (filters: IExportColumnsData) => void;
  onExportExcel?: (filters: IExportColumnsData) => void;
  onExportEmail?: (filters: IExportColumnsData) => void;
  additionalWhereForExport?: IWhere;
  ignoreGlobalSitesOnExport?: boolean;
}

interface IEnhancedTableExportExportColumn {
  [id: string]: {
    id: HeadCell['id'];
    label: HeadCell['label'];
    checked: boolean;
  };
}

export const EnhancedTableExport: React.FC<IEnhancedTableExportProps> = ({
  composeFilters,
  onExportCSV,
  onExportPDF,
  onExportExcel,
  onExportEmail,
  additionalWhereForExport,
  ignoreGlobalSitesOnExport,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const globallySelectedSitesIds = useSelector(getGloballySelectedSites);

  const composeFiltersWithAdditionalWhere = React.useMemo(() => {
    const siteId = composeFilters.where?.siteId
      ? composeFilters.where?.siteId
      : (additionalWhereForExport as any)?.siteId
      ? (additionalWhereForExport as any)?.siteId
      : !ignoreGlobalSitesOnExport
      ? { inq: globallySelectedSitesIds }
      : false;

    return {
      ...composeFilters,
      where: {
        ...additionalWhereForExport,
        ...composeFilters.where,
        ...(!isEmpty(siteId) ? { siteId } : {}),
      },
    };
  }, [
    ignoreGlobalSitesOnExport,
    additionalWhereForExport,
    composeFilters,
    globallySelectedSitesIds,
  ]);

  const getComposedFilters = useComposeFilters(
    composeFiltersWithAdditionalWhere,
  );

  const [type, setType] = useState<ExportType>(defaultData.type);
  const [isEmailDataFormOpen, setIsEmailDatFormOpen] = useState(false);
  const [exportAllPages, setExportAllPages] = useState<boolean>(
    defaultData.exportAllPages,
  );
  const [columnsToExport, setColumnsToExport] = useState<
    IEnhancedTableExportExportColumn
  >({});

  const pagesToExportButtons = React.useMemo(() => {
    return [
      { value: true, label: t('export.common.all_pages') },
      { value: false, label: t('export.common.current_page') },
    ];
  }, [t]);
  const exportingFormatsButtons = React.useMemo(() => {
    return [
      { value: 'csv' as const, label: t('export.common.csv') },
      { value: 'xls' as const, label: t('export.common.excel') },
      { value: 'pdf' as const, label: t('export.common.pdf') },
      { value: 'email' as const, label: t('export.common.email') },
    ];
  }, [t]);

  const onEmailDataFormCancel = () => setIsEmailDatFormOpen(false);

  const initColumnsToExport = React.useCallback(() => {
    return reduce(
      composeFilters.headCells,
      (acc, cur) => {
        acc[cur.id] = {
          id: cur.id,
          label: cur.label,
          checked: true,
        };
        return acc;
      },
      {} as IEnhancedTableExportExportColumn,
    );
  }, [composeFilters.headCells]);

  useEffect(() => {
    setColumnsToExport(initColumnsToExport());
  }, [initColumnsToExport]);

  const selectedAll = useMemo(() => {
    for (const i in columnsToExport) {
      if (!columnsToExport[i].checked) {
        return false;
      }
    }
    return true;
  }, [columnsToExport]);

  const isAllCellCheckboxIndeterminate = useMemo(() => {
    let hasChecked = false;
    let hasUnchecked = false;

    for (const i in columnsToExport) {
      if (!columnsToExport[i].checked) {
        hasUnchecked = true;
      } else {
        hasChecked = true;
      }
    }

    return hasChecked && hasUnchecked;
  }, [columnsToExport]);

  const onColumnSelect = (id: HeadCell['id']) => (checked: boolean) =>
    setColumnsToExport((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        checked,
      },
    }));

  const onSelectAll = (checked: boolean) =>
    setColumnsToExport(
      reduce(
        columnsToExport,
        (acc, cur, index) => {
          acc[index] = {
            ...cur,
            checked,
          };
          return acc;
        },
        {} as IEnhancedTableExportExportColumn,
      ),
    );

  const filterHeadCells = useCallback(
    () =>
      filter(
        composeFilters.headCells,
        (item) => columnsToExport[item.id].checked,
      ),
    [columnsToExport, composeFilters.headCells],
  );

  const exportDataViaEmail = (emailData: IEmailData) => {
    const composedFilters = getComposedFilters(
      exportAllPages,
      filterHeadCells(),
    );
    setIsEmailDatFormOpen(false);
    onExportEmail &&
      onExportEmail({
        ...composedFilters,
        emailData,
      });
  };

  const onExport = () => {
    switch (type) {
      case 'csv':
      default:
        onExportCSV &&
          onExportCSV(getComposedFilters(exportAllPages, filterHeadCells()));
        break;
      case 'xls':
        onExportExcel &&
          onExportExcel(getComposedFilters(exportAllPages, filterHeadCells()));
        break;
      case 'pdf':
        onExportPDF &&
          onExportPDF(getComposedFilters(exportAllPages, filterHeadCells()));
        break;
      case 'email':
        setIsEmailDatFormOpen(true);
        break;
    }

    onClose();
  };

  const onChangePages = (value: boolean) => setExportAllPages(value);

  const onChangeType = (value: ExportType) => setType(value);

  const onReset = () => {
    setType(defaultData.type);
    setExportAllPages(defaultData.exportAllPages);
    setColumnsToExport(initColumnsToExport());
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('export.common.form_title')}
      </DrawerHeader>
      <DrawerBody>
        <Sheet sx={{ p: 2 }}>
          <ButtonGroup
            label={t('export.common.export_settings')}
            buttons={pagesToExportButtons}
            onButtonClick={onChangePages}
            activeButtonValue={exportAllPages}
          />

          <Sheet sx={{ mt: 2 }}>
            <ButtonGroup
              label={t('export.common.exporting_format')}
              buttons={exportingFormatsButtons}
              onButtonClick={onChangeType}
              activeButtonValue={type}
            />
          </Sheet>

          <Sheet
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              mt: 2,
            }}
          >
            <Typography level="text_sm" fontWeight="medium">
              {t('export.common.export_columns')}
            </Typography>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                backgroundColor: 'transparent',
              }}
              component="ul"
            >
              <ListItemWithCheckbox
                underline
                id={-1}
                isChecked={selectedAll}
                isIndeterminate={isAllCellCheckboxIndeterminate}
                onClick={onSelectAll}
              >
                <Typography noWrap fontWeight="medium" level="text_sm">
                  {t('common.select_all')}
                </Typography>
              </ListItemWithCheckbox>
              {map(columnsToExport, (column) => (
                <ListItemWithCheckbox
                  key={column.id}
                  id={column.id}
                  isChecked={column.checked}
                  onClick={onColumnSelect(column.id)}
                >
                  <Typography noWrap fontWeight="medium" level="text_sm">
                    {column.label}
                  </Typography>
                </ListItemWithCheckbox>
              ))}
            </List>
          </Sheet>
        </Sheet>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar onReset={onReset} onCancel={onClose} onApply={onExport} />
      </DrawerFooter>

      <EmailDataForm
        isOpen={isEmailDataFormOpen}
        onCancel={onEmailDataFormCancel}
        onExport={exportDataViaEmail}
      />
    </Drawer>
  );
};
