import React, { useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPointTypesByIds } from '../../modules/selectors/pointType';
import { useQueryParams, useValidate } from '../../modules/utils/hooks';
import { IdsArray, IStoreState, IUpdatePointType } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchPointTypesByIds } from '../../modules/utils/hooks';
import { updatePointTypesRequest } from '../../modules/actions/pointType';
import { useTranslation } from 'react-i18next';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { updatePointTypesScheme } from '../../modules/schemes/point-types';
import { FormActions } from '../../components/Form/FormActions';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { useBrowserHistoryFunctions } from '../../modules/utils/hooks';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IPointTypeUpdateProps {
  pointTypes: IUpdatePointType[];
  onSubmit: (pointTypes: IUpdatePointType[]) => void;
}

const PointTypeUpdateForm = ({
  onSubmit,
  pointTypes,
}: IPointTypeUpdateProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updatePointTypesScheme);

  const formik = useFormikInUpdateForm({
    initialValues: pointTypes,
    validate,
    onSubmit,
  });

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (pointType, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('point_types.update_title', pointType)}
          key={pointType.id}
        >
          {/**@@TODO: fix inputProps */}
          <FormFieldContainer>
            <FormikTextarea
              variant="outlined"
              required
              id={`${index}.reason`}
              label={t('point_types.reason')}
              name={`${index}.reason`}
              formik={formik}
              // inputProps={{
              //   maxLength: REASON_MAX_LENGTH,
              // }}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id={`${index}.siteId`}
              required={true}
              formik={formik}
              label={t('point_types.site')}
              placeholder={t('point_types.site')}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              fullWidth
              formik={formik}
              id={`${index}.point`}
              variant="outlined"
              label={t('point_types.point')}
              name={`${index}.point`}
              type="number"
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              type="number"
              variant="outlined"
              fullWidth
              id={`${index}.lifetime`}
              label={t('point_types.points_lifetime')}
              name={`${index}.lifetime`}
              formik={formik}
            />
          </FormFieldContainer>
        </CreateForm>
      ))}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const PointTypesUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchPointTypes = useFetchPointTypesByIds(ids);

  // fetch pointTypes list from store
  const pointTypes = useSelector(
    (state) => getPointTypesByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch pointTypes from the server if we don't have them in the store
  useEffect(() => {
    fetchPointTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated pointTypes
   */
  const handleFormSubmit = (data: IUpdatePointType[]) => {
    dispatcher(updatePointTypesRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <PointTypeUpdateForm
          pointTypes={pointTypes}
          onSubmit={handleFormSubmit}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default PointTypesUpdate;
