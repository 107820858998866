import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { TableContext } from 'src/components/Table/TableContext';
import { FormFieldContainer } from '../../../../../Form/FormFieldContainer';
import { ClientKey } from '../../../../../../modules/constants';
import { Api, useValidate } from '../../../../../../modules/utils';
import { uploadProductionDataScheme } from '../../../../../../modules/schemes/productions/upload-production-data.scheme';
import { getSiteImportingSettingsTable } from '../../../../../../modules/selectors/site-importing-settings.selector';
import { IUploadProductionData } from '../../../../../../modules/types';
import {
  addProcessStatus,
  getSiteImportingSettingsListRequest,
} from '../../../../../../modules/actions';
import SitesByClientComboBox from '../../../../../Formik/comboboxes-with-entities/SitesByClientCombobox';
import FormikFileUpload from '../../../../../Formik/FormikFileUpload';

export interface IUploadImportingFileProps {
  clientKey: ClientKey;
}

const initialValues = {
  siteId: NaN,
  file: null,
};

export const UploadImportingFile: React.FC<IUploadImportingFileProps> = ({
  clientKey,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validate = useValidate(uploadProductionDataScheme);
  const importingSettingsList = useSelector(getSiteImportingSettingsTable);

  const [isOpen, setIsOpen] = React.useState(false);

  const { headCells, setHeadCells } = React.useContext(TableContext);
  const [headCellsCurrentState, setHeadCellsCurrentState] = React.useState(
    headCells,
  );

  React.useEffect(() => {
    setHeadCellsCurrentState(headCells);
  }, [headCells]);

  const formik = useFormik<IUploadProductionData>({
    initialValues,
    validate,
    onSubmit: async (data) => {
      const formData = new FormData();

      formData.append('file', data.file as File);
      formData.append('siteId', String(data.siteId));

      try {
        await Api.Production.uploadProduction(formData);

        formik.resetForm();

        dispatch(
          addProcessStatus({
            variant: 'success',
            message: t('productions.file_upload_started'),
          }),
        );
      } catch (err) {}
    },
  });

  const onReset = async () => {
    setHeadCellsCurrentState(headCells);
    await formik.resetForm();
  };

  const onApply = async () => {
    setIsOpen(false);
    setHeadCells(headCellsCurrentState);
    await formik.submitForm();
    await formik.resetForm();
  };

  React.useEffect(() => {
    dispatch(getSiteImportingSettingsListRequest({}));
  }, [dispatch]);

  if (!importingSettingsList.length) {
    return null;
  }

  return (
    <>
      <Button sx={{ mr: 1 }} onClick={() => setIsOpen((prev) => !prev)}>
        {t('common.upload')}
      </Button>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} anchor="right">
        <DrawerHeader onCloseClick={() => setIsOpen(false)}>
          {t('productions.upload_production_data_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <SitesByClientComboBox
                id="siteId"
                required={true}
                formik={formik}
                clientKey={clientKey}
                placeholder={t('productions.site.name')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikFileUpload
                name="file"
                inputId="upload_file"
                formik={formik}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            applyText={t('common.upload')}
            onApply={onApply}
            onReset={onReset}
            onCancel={() => setIsOpen(false)}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
