import { ISagaActionBind } from '../../types';
import { IProductionDailyUphBySiteAndEmployeeModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionDailyUphBySiteAndEmployeeListRequest,
    saga: createListSaga<IProductionDailyUphBySiteAndEmployeeModel>({
      apiCall: Api.ProductionDailyUphBySiteAndEmployee.list,
      actionSuccess: actions.getProductionDailyUphBySiteAndEmployeeListSuccess,
      actionFailed: actions.getProductionDailyUphBySiteAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getProductionDailyUphBySiteAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionDailyUphBySiteAndEmployee.count,
      actionSuccess: actions.getProductionDailyUphBySiteAndEmployeeCountSuccess,
      actionFailed: actions.getProductionDailyUphBySiteAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportProductionDailyUphBySiteAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionDailyUphBySiteAndEmployee.export,
      actionSuccess: actions.exportProductionDailyUphBySiteAndEmployeeSuccess,
      actionFailed: actions.exportProductionDailyUphBySiteAndEmployeeFailed,
    }),
  },
];
