import { omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import {
  IApplicantModel,
  IdsArray,
  IStoreState,
  IUpdateApplicant,
} from '../types';
import { ComboBoxOption } from '../../components/ComboBox';
import {
  parse,
  format,
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
} from '../../modules/utils/dateWrapper';

export const getApplicantRefreshKey = ({ applicant }: IStoreState) =>
  applicant.refreshKey;

/**
 * Get applicant list
 * @param applicant - State applicant
 */
export const getApplicantList = ({ applicant }: IStoreState) => applicant.list;

/**
 * Get applicant view list
 */
export const getApplicantViewList = createSelector(
  getApplicantList,
  (applicants) => (ids: IdsArray) =>
    reduce(
      applicants,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push({
            ...cur,
            dateInterview: composeDate(
              cur.dateInterview,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
            dateStart: composeDate(
              cur.dateStart,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
            hire: !!cur.hire,
            I9: !!cur.I9,
            bgCheck: !!cur.bgCheck,
          });
        }
        return acc;
      },
      [] as IApplicantModel[],
    ),
);

/**
 * Get applicants by array of ids
 * @param applicant - State applicant
 */
export const getApplicantsByIds = createSelector(
  getApplicantList,
  (applicants) => (ids: IdsArray) =>
    reduce(
      applicants,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push({
            ...omit(cur, ['supervisor', 'openPosition', 'site', 'staffing']),
            dateInterview: composeDate(
              cur.dateInterview,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
            dateStart: composeDate(
              cur.dateStart,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
            hire: !!cur.hire,
            I9: !!cur.I9,
            bgCheck: !!cur.bgCheck,
          });
        }
        return acc;
      },
      [] as IUpdateApplicant[],
    ),
);

/**
 * Get combobox list
 */
export const getApplicantsComboboxList = ({ applicant }: IStoreState) =>
  applicant.comboboxList as ComboBoxOption[];

/**
 * Get Applicant count
 * @param applicant - State Applicant
 */
export const getApplicantCount = ({ applicant }: IStoreState) =>
  applicant.count;
