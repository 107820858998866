import {
  IProductionDailyUphBySiteAndMetatagModel,
  IProductionDailyUphBySiteAndMetatagState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionDailyUphBySiteAndMetatagState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionDailyUphBySiteAndMetatagListRequest,
  getListSuccess: getProductionDailyUphBySiteAndMetatagListSuccess,
  getListFailed: getProductionDailyUphBySiteAndMetatagListFailed,
} = reducerCreator.takeListHandlers<
  IProductionDailyUphBySiteAndMetatagState,
  IProductionDailyUphBySiteAndMetatagModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionDailyUphBySiteAndMetatagCountRequest,
  getCountSuccess: getProductionDailyUphBySiteAndMetatagCountSuccess,
  getCountFailed: getProductionDailyUphBySiteAndMetatagCountFailed,
} = reducerCreator.takeCountHandlers<
  IProductionDailyUphBySiteAndMetatagState
>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionDailyUphBySiteAndMetatagRequest,
  exportSuccess: exportProductionDailyUphBySiteAndMetatagSuccess,
  exportFailed: exportProductionDailyUphBySiteAndMetatagFailed,
} = new ExportReducerCreator().takeHandlers<
  IProductionDailyUphBySiteAndMetatagState
>();

/**
 * actually Reducer
 */
export const productionDailyUphBySiteAndSku = createReducer<
  IProductionDailyUphBySiteAndMetatagState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionDailyUphBySiteAndMetatagListRequest,
    getProductionDailyUphBySiteAndMetatagListRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteAndMetatagListSuccess,
    getProductionDailyUphBySiteAndMetatagListSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteAndMetatagListFailed,
    getProductionDailyUphBySiteAndMetatagListFailed,
  )
  // get count actions
  .on(
    actions.getProductionDailyUphBySiteAndMetatagCountRequest,
    getProductionDailyUphBySiteAndMetatagCountRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteAndMetatagCountSuccess,
    getProductionDailyUphBySiteAndMetatagCountSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteAndMetatagCountFailed,
    getProductionDailyUphBySiteAndMetatagCountFailed,
  )
  // export action
  .on(
    actions.exportProductionDailyUphBySiteAndMetatagRequest,
    exportProductionDailyUphBySiteAndMetatagRequest,
  )
  .on(
    actions.exportProductionDailyUphBySiteAndMetatagSuccess,
    exportProductionDailyUphBySiteAndMetatagSuccess,
  )
  .on(
    actions.exportProductionDailyUphBySiteAndMetatagFailed,
    exportProductionDailyUphBySiteAndMetatagFailed,
  );

export default productionDailyUphBySiteAndSku;
