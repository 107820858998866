import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getOptiturnCredentialsByIds } from '../../modules/selectors/optiturn-credentials.selector';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useValidate,
} from '../../modules/utils';
import { IdsArray, IUpdateOptiturnCredentials } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchOptiturnCredentialsByIds } from '../../modules/utils';
import { updateOptiturnCredentialsRequest } from '../../modules/actions';
import { useTranslation } from 'react-i18next';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { updateOptiturnCredentialsScheme } from '../../modules/schemes';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IUpdateOptiturnCredentialsProps {
  optiturnCredentials: IUpdateOptiturnCredentials[];
  onSubmit: (optiturnCredentials: IUpdateOptiturnCredentials[]) => void;
}

const OptiturnCredentialsUpdateForm = ({
  optiturnCredentials,
  onSubmit,
}: IUpdateOptiturnCredentialsProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateOptiturnCredentialsScheme);

  const formik = useFormikInUpdateForm({
    initialValues: optiturnCredentials,
    validate,
    onSubmit,
  });

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      isFormValid={formik.isValid}
      fields={formik.values}
    >
      {map(formik.values, (optiturnCredentials, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('optiturn-credentials.update_title', {
            id: optiturnCredentials.id,
          })}
          key={optiturnCredentials.id}
        >
          <FormFieldContainer>
            <FormikTextField
              id={`${index}.login`}
              variant="outlined"
              required
              fullWidth
              label={t('optiturn-credentials.login')}
              name={`${index}.login`}
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              id={`${index}.password`}
              variant="outlined"
              required
              fullWidth
              label={t('optiturn-credentials.password')}
              name={`${index}.password`}
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id={`${index}.siteId`}
              required={true}
              formik={formik}
              label={t('optiturn-credentials.site')}
              placeholder={t('optiturn-credentials.site')}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>
        </CreateForm>
      ))}
      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const OptiturnCredentialsUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchOptiturnCredentials = useFetchOptiturnCredentialsByIds(ids);

  // fetch optiturnCredentials list from store
  const optiturnCredentials = useSelector(getOptiturnCredentialsByIds)(ids);

  // make request to fetch optiturnCredentials from the server if we don't have them in the store
  useEffect(() => {
    fetchOptiturnCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated optiturnCredentials
   */
  const handleFormSubmit = (data: IUpdateOptiturnCredentials[]) => {
    dispatcher(updateOptiturnCredentialsRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <OptiturnCredentialsUpdateForm
          optiturnCredentials={optiturnCredentials}
          onSubmit={handleFormSubmit}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default OptiturnCredentialsUpdate;
