import React from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { EnhancedTableDeleteEntitiesConfirmation } from 'src/components/EnhancedTable/EnhancedTableDeleteEntitiesConfiramtion';
import { IStoreState } from 'src/modules/types';
import { getDeleteRelations } from 'src/modules/selectors/deleteRelations';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  ITableSelectedItemsActionsItemProps,
  TableSelectedItemsActionsItem,
} from './TableSelectedItemsActionsItem';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { useFetchDeleteRelations } from '../../../../modules/utils';
import { getUserRoles } from '../../../../modules/selectors/user';
import { roles } from '../../../../config';

export interface ICommonActionItemConfiguration
  extends Omit<ITableSelectedItemsActionsItemProps, 'icon' | 'tooltip'> {
  tooltip?: string;
  icon?: React.ReactElement;
}
export interface ITableSelectedItemsActionsProps {
  selectedItemsQuantity: number;
  deleteModelName?: string;
  actionsConfiguration?: Array<ITableSelectedItemsActionsItemProps>;
  deleteActionConfiguration?: ICommonActionItemConfiguration;
  updateActionConfiguration?: ICommonActionItemConfiguration;
}

export const TableSelectedItemsActions = ({
  selectedItemsQuantity,
  deleteModelName,
  deleteActionConfiguration,
  updateActionConfiguration,
  actionsConfiguration = [],
}: ITableSelectedItemsActionsProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isSmallScreen = window.innerWidth <= theme.breakpoints.values.sm;

  const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(
    false,
  );

  const userRoles = useSelector(getUserRoles);
  const fetchDeleteRelations = useFetchDeleteRelations(deleteModelName || '');

  React.useEffect(() => {
    if (
      !isEmpty(userRoles) &&
      userRoles!.includes(roles.SUPER_ADMIN) &&
      deleteModelName
    ) {
      fetchDeleteRelations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteRelations = useSelector((state) =>
    getDeleteRelations(state as IStoreState)(deleteModelName || ''),
  );

  const deleteMsg = React.useMemo(
    () =>
      deleteModelName &&
      deleteRelations &&
      !isEmpty(deleteRelations[deleteModelName])
        ? t('common.confirm_remove_with_details', {
            models: deleteRelations[deleteModelName].join(', '),
          })
        : t('common.confirm_remove'),
    [deleteModelName, deleteRelations, t],
  );

  const handleDeleteConfirmation = () => {
    if (deleteActionConfiguration) {
      setShowConfirmDialog(false);
      deleteActionConfiguration.onClick();
    }
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: 52,
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {!isSmallScreen && (
          <Typography
            level="text_xs"
            fontWeight="regular"
            textColor="colors.text.text_primary.main"
          >
            {t('common.selected_items')}:{' '}
            <Typography fontWeight="semi_bold">
              {selectedItemsQuantity}
            </Typography>
          </Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {updateActionConfiguration && (
          <TableSelectedItemsActionsItem
            onClick={updateActionConfiguration.onClick}
            icon={updateActionConfiguration.icon ?? <Edit02Svg />}
            tooltip={updateActionConfiguration.tooltip ?? t('common.update')}
          />
        )}

        <Box sx={{ display: 'flex' }}>
          {actionsConfiguration.map((action, i) => (
            <Box key={i} sx={{ ml: 1 }}>
              <Tooltip title={action.tooltip}>
                <IconButton onClick={action.onClick}>{action.icon}</IconButton>
              </Tooltip>
            </Box>
          ))}

          {deleteActionConfiguration && (
            <TableSelectedItemsActionsItem
              onClick={() => setShowConfirmDialog(true)}
              icon={deleteActionConfiguration.icon ?? <Trash04Svg />}
              tooltip={deleteActionConfiguration.tooltip ?? t('common.delete')}
            />
          )}
        </Box>
      </Box>

      <EnhancedTableDeleteEntitiesConfirmation
        open={showConfirmDialog}
        onOk={handleDeleteConfirmation}
        onCancel={onCancel}
      >
        {deleteMsg}
      </EnhancedTableDeleteEntitiesConfirmation>
    </Box>
  );
};
