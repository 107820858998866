import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Sheet,
} from '@mui/joy';

import {
  getIsDeleteNotificationInProgress,
  getIsMoreNotificationsRequestProcessing,
  getNotificationsCount,
  getNotificationsCountUnread,
  getNotificationsList,
} from '../../modules/selectors/notification';

import { NotificationCard } from './components/NotificationCard';
import { Badge } from './components/Badge';

import {
  NotificationEvents,
  useNotificationsDefaultFilters,
  useSocketNotifications,
} from 'src/modules/utils/hooks/notifications.hooks';
import * as actions from '../../modules/actions';
import { IFilter } from '../../modules/types';
import { Drawer, DrawerHeader } from '../_ui-kit/Drawer';
import { MUI_SIZE_12 } from 'src/config';
import { MainContentLoader } from '../Layout/components/PageTour/MainContentLoader';
import { NotificationCardButton } from './components/NotificationCard/NotificationCardButton';
import { DeleteConfirmation } from '../DeleteConfirmation';

const SHAKE_EFFECT_DURATION = 15000;

const defaultPage = 1;

export const UserNotification = () => {
  const { t } = useTranslation();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const { socket } = useSocketNotifications();
  const notificationsList = useSelector(getNotificationsList);
  const notificationsCount = useSelector(getNotificationsCountUnread);
  const notificationsTotalCount = useSelector(getNotificationsCount);
  const [isShakeEnabled, setIsShakeEnabled] = React.useState(false);
  const [page, setPage] = React.useState(defaultPage);
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirmDeleteAll, setShowConfirmDeleteAll] = React.useState(false);

  const isMoreNotificationsRequestProcessing = useSelector(
    getIsMoreNotificationsRequestProcessing,
  );
  const isDeleteNotificationInProgress = useSelector(
    getIsDeleteNotificationInProgress,
  );

  const defaultFilter = useNotificationsDefaultFilters();
  const dispatcher = useDispatch();

  const handleClick = () => {
    setIsOpen(true);

    if (isShakeEnabled) {
      setIsShakeEnabled(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCardClick = () => {
    setIsOpen(false);
  };

  const handleRemoveAll = () => {
    setShowConfirmDeleteAll(true);
  };

  const handleRemoveAllConfirm = () => {
    dispatcher(actions.deleteAllNotificationsRequest());
    setShowConfirmDeleteAll(false);
  };

  const handleChangeAllNotificationsStatus = () => {
    dispatcher(actions.changeAllNotificationsStatusRequest({ read: true }));
  };

  const handleRemoveOne = (id: number) => {
    setPage(defaultPage);

    dispatcher(
      actions.deleteNotificationRequest({
        data: id,
        filters: {
          list: {
            order: ['createdAt desc'],
            limit: defaultFilter.limit,
          },
          count: {},
        },
      }),
    );
  };

  const enableShake = () => {
    setIsShakeEnabled(true);

    return setTimeout(() => {
      setIsShakeEnabled(false);
    }, SHAKE_EFFECT_DURATION);
  };

  const loadMoreNotifications = React.useCallback(() => {
    if (isMoreNotificationsRequestProcessing) return;

    const filter = {
      filter: {
        order: ['createdAt desc'],
        limit: defaultFilter.limit,
        offset: page * defaultFilter.limit,
      },
    };

    dispatcher(actions.getMoreNotificationsListRequest(filter));

    setPage((prevPage) => prevPage + 1);
  }, [
    defaultFilter.limit,
    dispatcher,
    isMoreNotificationsRequestProcessing,
    page,
  ]);

  const handleScroll = React.useCallback(() => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        page * defaultFilter.limit <=
          notificationsTotalCount + defaultFilter.limit
      ) {
        loadMoreNotifications();
      }
    }
  }, [
    defaultFilter.limit,
    loadMoreNotifications,
    notificationsTotalCount,
    page,
  ]);

  React.useEffect(() => {
    if (!socket) {
      return;
    }

    const filter: IFilter = {
      filter: {
        ...defaultFilter,
      },
    };

    dispatcher(actions.getNotificationsListRequest(filter));
  }, [defaultFilter, dispatcher, socket]);

  React.useEffect(() => {
    if (!socket) {
      return;
    }

    let timerId: any;

    socket.on(NotificationEvents.fetchRecent, () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = enableShake();
    });

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [dispatcher, socket]);

  React.useEffect(() => {
    const element = contentRef.current;

    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, isOpen]);

  return (
    <Box>
      <IconButton onClick={handleClick} size="lg">
        <Badge
          isShakeEnabled={isShakeEnabled}
          unreadNotificationsQuantity={notificationsCount}
        />
      </IconButton>

      <Drawer
        open={isOpen}
        onClose={handleClose}
        anchor="right"
        slotProps={{ content: { sx: { minWidth: 400 } } }}
      >
        <DrawerHeader onCloseClick={handleClose}>
          {t('notifications.title')}
        </DrawerHeader>

        {notificationsList.length ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: 2,
              py: 1,
            }}
          >
            <NotificationCardButton
              onClick={handleChangeAllNotificationsStatus}
            >
              {t('notifications.mark_all_as_read')}
            </NotificationCardButton>
            <NotificationCardButton onClick={handleRemoveAll}>
              {t('notifications.remove_all')}
            </NotificationCardButton>
          </Box>
        ) : null}

        <Sheet
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            overflow: 'hidden',
            bgcolor: 'base.white',
            position: 'relative',
          }}
        >
          <Sheet
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: 'transparent',
              overflowY: 'auto',
            }}
            ref={contentRef}
          >
            {isDeleteNotificationInProgress && <MainContentLoader />}

            <Box sx={{ padding: 2 }}>
              {notificationsList.map((notification) => (
                <NotificationCard
                  onRemove={handleRemoveOne}
                  onCardClick={handleCardClick}
                  key={notification.id}
                  {...notification}
                />
              ))}

              {!notificationsList.length ? (
                <Grid container={true}>
                  <Grid xs={MUI_SIZE_12}>
                    <Alert
                      variant="soft"
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {t('notifications.no_notifications')}
                    </Alert>
                  </Grid>
                </Grid>
              ) : null}

              {isMoreNotificationsRequestProcessing && (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                  <CircularProgress size="md" />
                </Box>
              )}
            </Box>
          </Sheet>
        </Sheet>
      </Drawer>

      <DeleteConfirmation
        open={showConfirmDeleteAll}
        onOk={handleRemoveAllConfirm}
        onCancel={() => setShowConfirmDeleteAll(false)}
      >
        {t('notifications.remove_all_confirmation')}
      </DeleteConfirmation>
    </Box>
  );
};
