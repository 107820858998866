import React from 'react';
import { insertLink } from '../utils/link';
import { Button, MaterialIcon } from '../components';
import { isBlockActive } from '../utils/SlateUtilityFunctions';

export const LinkButton = (props: any) => {
  const { editor } = props;
  const handleInsertLink = () => {
    const url = prompt('Enter URL');
    insertLink(editor, url!);
  };
  return (
    <Button format={'link'} onClick={handleInsertLink}>
      <MaterialIcon icon="link" active={isBlockActive(editor, 'link')} />
    </Button>
  );
};
