import React from 'react';
import { Navigate } from 'react-router-dom';
import Link from '@mui/joy/Link';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { loginRequest } from '../modules/actions';
import { isLoggedIn } from '../modules/selectors/auth';
import { isDataRequested } from '../modules/selectors/auth';
import { useValidate } from '../modules/utils';
import { loginScheme } from '../modules/schemes/login.scheme';

import { AuthLayout } from '../components/Layout/AuthLayout';
import FormikTextField from '../components/Formik/FormikTextField';
import { getUserObjectFromStorage } from '../modules/utils/user';
import { getLandingRouteBasedOnRole } from '../modules/utils/helpers/route.helper';
import { Box, Button, Sheet, Theme, Typography } from '@mui/joy';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { getUserPolicies, getUserRoles } from 'src/modules/selectors/user';
import { roles } from 'src/config';
import { isEmpty } from 'lodash';

const initialValues = {
  email: '',
  password: '',
};

export default function SignIn() {
  const validate = useValidate(loginScheme);
  const dispatcher = useDispatch();

  const policies = useSelector(getUserPolicies);
  const userRoles = useSelector(getUserRoles, shallowEqual);

  const isSuperAdmin = userRoles && userRoles.includes(roles.SUPER_ADMIN);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(loginRequest(data));
    },
  });

  const { t } = useTranslation();

  // check if a user is logged in
  const is_logged_in = useSelector(isLoggedIn, shallowEqual);

  // check if data is requested to show preloader
  const isLoading = useSelector(isDataRequested, shallowEqual);

  if (is_logged_in) {
    // return user to page after login
    // get current user role from localStorage directly
    const { auth: { role = '' } = {} } = getUserObjectFromStorage();
    const redirectPath = getLandingRouteBasedOnRole(role);
    return isSuperAdmin || !isEmpty(policies) ? (
      <Navigate to={redirectPath} />
    ) : (
      <MainContentLoader />
    );
  }

  return (
    <AuthLayout>
      <Box
        component="form"
        sx={{
          width: '100%', // Fix IE 11 issue.
          marginTop: (theme: Theme) => theme.spacing(1),
        }}
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Sheet sx={{ mb: 2 }}>
          <FormikTextField
            required
            fullWidth
            id="email"
            label={t('auth.email')}
            name="email"
            autoComplete="email"
            autoFocus
            formik={formik}
          />
        </Sheet>
        <Sheet sx={{ mb: '6px' }}>
          <FormikTextField
            required
            fullWidth
            name="password"
            label={t('auth.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            formik={formik}
          />
        </Sheet>
        <Sheet>
          <Link href="/forgot-password">
            <Typography fontSize="0.875rem" textColor="gray.700">
              {t('auth.forgot_password')}
            </Typography>
          </Link>
        </Sheet>
        <Sheet sx={{ pt: 1 }}>
          <Button fullWidth size="lg" type="submit" loading={isLoading}>
            <Typography fontSize="0.875rem" textColor="base.white">
              {t('auth.sign_in')}
            </Typography>
          </Button>
        </Sheet>
      </Box>
    </AuthLayout>
  );
}
