import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IWhere,
  PayboardDetailsRequest,
  PayboardDetailsResponse,
  ListWithSummary,
  IPayboardModel,
  PayboardWeek,
  IDailyHoursReportItem,
  NewPayboardWeek,
  IPayboardCostByDay,
  IPayboardCostByDayAndDepartment,
  IPayboardCostByDepartment,
  IPayboardDetailedModel,
  ICountDetailedResponse,
  PayboardDay,
  ICountWeeklyDetailedResponse,
} from '../types';
import { ExportActionCreator } from '../utils/helpers/creators/export';

// Fetch payboards actions
export const getPayboardListRequest = createAction<IFilter>(
  'Make request to fetch payboard list',
);
export const getPayboardListSuccess = createAction<
  ListWithSummary<IPayboardModel>
>('Payboards list has been fetched successfully');
export const getPayboardListFailed = createAction('Cannot fetch payboard list');

// get payboards count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPayboardCountRequest = createAction<IWhere>(
  'Make request to get payboard count',
);
export const getPayboardCountSuccess = createAction<ICountResponse>(
  'Payboards count has been fetched successfully',
);
export const getPayboardCountFailed = createAction('Cannot get payboard count');

// Fetch payboards detailed actions
export const getPayboardDetailedListRequest = createAction<IFilter>(
  'Make request to fetch payboard detailed list',
);
export const getPayboardDetailedListSuccess = createAction<
  ListWithSummary<IPayboardDetailedModel>
>('Payboards detailed list has been fetched successfully');
export const getPayboardDetailedListFailed = createAction(
  'Cannot fetch payboard detailed list',
);

// get payboards detailed count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPayboardDetailedCountRequest = createAction<IWhere>(
  'Make request to get payboard detailed count',
);
export const getPayboardDetailedCountSuccess = createAction<
  ICountDetailedResponse
>('Payboards detailed count has been fetched successfully');
export const getPayboardDetailedCountFailed = createAction(
  'Cannot get payboard detailed count',
);

export const getPayboardWeeklyDetailedListRequest = createAction<IFilter>(
  'Make request to fetch payboard weekly detailed list',
);
export const getPayboardWeeklyDetailedListSuccess = createAction<
  ListWithSummary<IPayboardDetailedModel>
>('Payboards weekly detailed list has been fetched successfully');
export const getPayboardWeeklyDetailedListFailed = createAction(
  'Cannot fetch payboard weekly detailed list',
);

export const getPayboardWeeklyDetailedCountRequest = createAction<IWhere>(
  'Make request to get payboard weekly detailed count',
);
export const getPayboardWeeklyDetailedCountSuccess = createAction<
  ICountWeeklyDetailedResponse
>('Payboards weekly detailed count has been fetched successfully');
export const getPayboardWeeklyDetailedCountFailed = createAction(
  'Cannot get payboard weekly detailed count',
);

// Fetch payboards by week actions
export const getPayboardDetailsRequest = createAction<PayboardDetailsRequest>(
  'Make request to fetch payboard by week',
);
export const getPayboardDetailsSuccess = createAction<PayboardDetailsResponse>(
  'Payboards by week has been fetched successfully',
);
export const getPayboardDetailsFailed = createAction(
  'Cannot fetch payboard by week',
);

// get payboards weekly data count actions
export const getPayboardWeekCountRequest = createAction<IWhere>(
  'Make request to get payboard weekly data count',
);
export const getPayboardWeekCountSuccess = createAction<ICountResponse>(
  'Payboards weekly data count has been fetched successfully',
);
export const getPayboardWeekCountFailed = createAction(
  'Cannot get payboard weekly data count',
);

// get new payboards weekly data count actions
export const getNewPayboardWeekCountRequest = createAction<IWhere>(
  'Make request to get new payboard weekly data count',
);
export const getNewPayboardWeekCountSuccess = createAction<ICountResponse>(
  'New Payboards weekly data count has been fetched successfully',
);
export const getNewPayboardWeekCountFailed = createAction(
  'Cannot get new payboard weekly data count',
);

// Fetch payboards week data actions
export const getPayboardWeekRequest = createAction<IFilter>(
  'Make request to fetch payboard week data',
);
export const getPayboardWeekSuccess = createAction<
  ListWithSummary<PayboardWeek>
>('Payboard week data has been fetched successfully');
export const getPayboardWeekFailed = createAction(
  'Cannot fetch payboard week data',
);

// Fetch payboards week data actions
export const getPayboardDayRequest = createAction<IFilter>(
  'Make request to fetch payboard day data',
);
export const getPayboardDaySuccess = createAction<ListWithSummary<PayboardDay>>(
  'Payboard day data has been fetched successfully',
);
export const getPayboardDayFailed = createAction(
  'Cannot fetch payboard day data',
);

export const getPayboardDayCountRequest = createAction<IWhere>(
  'Make request to get new payboard daily data count',
);
export const getPayboardDayCountSuccess = createAction<ICountResponse>(
  'New Payboards daily data count has been fetched successfully',
);
export const getPayboardDayCountFailed = createAction(
  'Cannot get new payboard dailt data count',
);

// Fetch new payboards week data actions
export const getNewPayboardWeekRequest = createAction<IFilter>(
  'Make request to fetch payboard week data',
);
export const getNewPayboardWeekSuccess = createAction<
  ListWithSummary<NewPayboardWeek>
>('Payboard week data has been fetched successfully');
export const getNewPayboardWeekFailed = createAction(
  'Cannot fetch payboard week data',
);

// Daily hours summary report actions
export const getPayboardDailyHoursSummaryReportDataRequest = createAction<
  IFilter
>('Make request to fetch daily hours summary report data');
export const getPayboardDailyHoursSummaryReportDataSuccess = createAction<
  ListWithSummary<IDailyHoursReportItem>
>('Daily hours summary report data fetched successfully');
export const getPayboardDailyHoursSummaryReportDataFailed = createAction(
  'Cannot fetch daily hours summary report data',
);

export const getPayboardDailyHoursDashboardInReportDataRequest = createAction<
  IFilter
>('Make request to fetch daily hours  dashboard in report data');
export const getPayboardDailyHoursDashboardInReportDataSuccess = createAction<
  ListWithSummary<IDailyHoursReportItem>
>('Daily hours  dashboard in report data fetched successfully');
export const getPayboardDailyHoursDashboardInReportDataFailed = createAction(
  'Cannot fetch daily hours  dashboard in report data',
);

export const getPayboardDailyHoursDashboardInReportCountRequest = createAction<
  IFilter
>('Make request to fetch daily hours dashboard in report count');
export const getPayboardDailyHoursDashboardInReportCountSuccess = createAction<
  ICountResponse
>('Daily hours  dashboard in report count fetched successfully');
export const getPayboardDailyHoursDashboardInReportCountFailed = createAction(
  'Cannot fetch daily hours  dashboard in report count',
);

// Daily hours dashboard report actions
export const getPayboardDailyHoursDashboardReportDataRequest = createAction<
  IFilter
>('Make request to fetch daily hours dashboard report data');
export const getPayboardDailyHoursDashboardReportDataSuccess = createAction<
  ListWithSummary<IDailyHoursReportItem>
>('Daily hours dashboard report data fetched successfully');
export const getPayboardDailyHoursDashboardReportDataFailed = createAction(
  'Cannot fetch daily hours dashboard report data',
);

// Get payboards daily hours summary report count actions
export const getPayboardDailyHoursSummaryReportDataCountRequest = createAction<
  IWhere
>('Make request to get payboard daily hours summary report data count');
export const getPayboardDailyHoursSummaryReportDataCountSuccess = createAction<
  ICountResponse
>('Payboards daily hours summary report count has been fetched successfully');
export const getPayboardDailyHoursSummaryReportDataCountFailed = createAction(
  'Cannot get payboard daily hours summary report count',
);

// export actions
export const {
  exportReportRequest: exportPayboardRequest,
  exportReportSuccess: exportPayboardSuccess,
  exportReportFailed: exportPayboardFailed,
} = new ExportActionCreator('Payboard').takeExportActions();

export const {
  exportReportRequest: exportPayboardWeeklyDetailedRequest,
  exportReportSuccess: exportPayboardWeeklyDetailedSuccess,
  exportReportFailed: exportPayboardWeeklyDetailedFailed,
} = new ExportActionCreator('Payboard').takeExportActions();

export const {
  exportReportRequest: exportPayboardDetailedRequest,
  exportReportSuccess: exportPayboardDetailedSuccess,
  exportReportFailed: exportPayboardDetailedFailed,
} = new ExportActionCreator('PayboardDetailed').takeExportActions();

// export week actions
export const {
  exportReportRequest: exportPayboardWeekRequest,
  exportReportSuccess: exportPayboardWeekSuccess,
  exportReportFailed: exportPayboardWeekFailed,
} = new ExportActionCreator('PayboardWeek').takeExportActions();

// export day actions
export const {
  exportReportRequest: exportPayboardDayRequest,
  exportReportSuccess: exportPayboardDaySuccess,
  exportReportFailed: exportPayboardDayFailed,
} = new ExportActionCreator('PayboardDay').takeExportActions();

// export new week actions
export const {
  exportReportRequest: exportNewPayboardWeekRequest,
  exportReportSuccess: exportNewPayboardWeekSuccess,
  exportReportFailed: exportNewPayboardWeekFailed,
} = new ExportActionCreator('NewPayboardWeek').takeExportActions();

// export daily-hours actions
export const {
  exportReportRequest: exportPayboardDailyHoursRequest,
  exportReportSuccess: exportPayboardDailyHoursSuccess,
  exportReportFailed: exportPayboardDailyHoursFailed,
} = new ExportActionCreator('PayboardDailyHours').takeExportActions();

// Get costs for sum by department
export const getPayboardSumByDepartmentRequest = createAction<IFilter>(
  'Make request to fetch sum of cost by department',
);
export const getPayboardSumByDepartmentSuccess = createAction<
  ListWithSummary<IPayboardCostByDepartment>
>('Sum of cost by department fetched successfully');
export const getPayboardSumByDepartmentFailed = createAction(
  'Cannot fetch sum of cost by department',
);

// Get costs for sum by day
export const getPayboardSumByDayRequest = createAction<IFilter>(
  'Make request to fetch sum of cost by day',
);
export const getPayboardSumByDaySuccess = createAction<
  ListWithSummary<IPayboardCostByDay>
>('Sum of cost by day fetched successfully');
export const getPayboardSumByDayFailed = createAction(
  'Cannot fetch sum of cost by day',
);

// Get costs for sum by day and department
export const getPayboardSumByDayAndDepartmentRequest = createAction<IFilter>(
  'Make request to fetch sum of cost by day and department',
);
export const getPayboardSumByDayAndDepartmentSuccess = createAction<
  ListWithSummary<IPayboardCostByDayAndDepartment>
>('Sum of cost by day and department fetched successfully');
export const getPayboardSumByDayAndDepartmentFailed = createAction(
  'Cannot fetch sum of cost by day and department',
);
