import React from 'react';
import { Box } from '@mui/joy';
import { IFilterPanelYearWeekComboBoxProps } from '../FilterPanelYearWeekComboBox';
import { ComboBoxOption } from 'src/components/ComboBox';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { FilterPanelFilterContainer } from '../components/FilterPanelFilterContainer';
import { FilterPanelYearWeekRangeAutocomplete } from './FilterPanelYearWeekRangeAutocomplete';
import { getISOWeek, getYear, parseISO, subYears } from 'date-fns';
import {
  IConcatenateYearWeekArgs,
  concatenateYearWeekAsNumber,
} from 'src/modules/utils/helpers/formatters';
import { Pencil02Svg } from 'src/components/svgIcons';

const FIRST_WEEK = 1;
const currentYear = getYear(new Date());
const currentWeek = getISOWeek(new Date());
const thirtyYearsAgoYear = getYear(subYears(new Date(), 30));

function formatOption({ year, week }: IConcatenateYearWeekArgs) {
  return `${year}-W${String(week).padStart(2, '0')}`;
}

interface IOption {
  id: number;
  name: string;
}
type IFilterPanelYearWeekRangeProps = Omit<
  IFilterPanelYearWeekComboBoxProps,
  'onChange' | 'filterId' | 'onRemoveFilter' | 'value' | 'startDecorator'
> & {
  filterId: string;
  onChange: ({ from, to }: { from?: IOption; to?: IOption }) => void;
  fromStartDecorator?: string;
  toStartDecorator?: string;
  onRemoveFilter?: (filterId: string) => void;
};

export const FilterPanelYearWeekRange = ({
  filterId,
  onRemoveFilter,
  optionsFromYear = Number(thirtyYearsAgoYear),
  optionsFromWeek = FIRST_WEEK,
  optionsToYear = currentYear,
  optionsToWeek = currentWeek,
  formatOptionId = concatenateYearWeekAsNumber,
  formatOptionName = formatOption,
  optionsOrder = 'desc',
  fromStartDecorator,
  toStartDecorator,
  onChange,

  ...autocompleteProps
}: IFilterPanelYearWeekRangeProps) => {
  const {
    setEditingFiltersIds,
    setFilterPanelMode,
    filterPanelMode,
    isFilterPermanent,
  } = React.useContext(FilterPanelContext);

  const [isFilterInEditingMode, setIsFilterInEditingMode] = React.useState(
    false,
  );

  const [yearWeekRange, setYearWeekRange] = React.useState<{
    from?: IOption;
    to?: IOption;
  }>({
    from: undefined,
    to: undefined,
  });

  const width = React.useMemo(() => (isFilterInEditingMode ? 250 : 170), [
    isFilterInEditingMode,
  ]);

  const generateYearWeeks = (): Array<IOption> => {
    const options: Array<IOption> = [];

    for (let year = optionsFromYear; year <= optionsToYear; year++) {
      const lastWeekDate = parseISO(`${year}-12-28`);
      const lastWeek = getISOWeek(lastWeekDate);

      let weekToCompareWith = lastWeek;
      if (year === optionsToYear) {
        weekToCompareWith = optionsToWeek;
      }

      let week = year === optionsFromYear ? optionsFromWeek : FIRST_WEEK;
      while (week <= weekToCompareWith) {
        options.push({
          id: Number(formatOptionId({ year, week })),
          name: formatOptionName({ year, week }),
        });

        week++;
      }
    }

    return options;
  };

  const options =
    optionsOrder === 'asc'
      ? generateYearWeeks()
      : generateYearWeeks().reverse();

  const createOnChangeHandler = (fieldName: 'from' | 'to') => (
    _: React.SyntheticEvent<Element, Event>,
    option: ComboBoxOption | null,
  ) => {
    setYearWeekRange((prev) => {
      const otherFieldName = fieldName === 'from' ? 'to' : 'from';

      const value = option?.id ?? null;

      const isFromYearWeekGreaterThenToYearWeek =
        otherFieldName === 'to' && Number(value) > Number(prev?.to?.id);
      const isToYearWeekLessThenFromYearWeek =
        otherFieldName === 'from' &&
        Number(option?.id) < Number(prev?.from?.id);

      if (
        isFromYearWeekGreaterThenToYearWeek ||
        isToYearWeekLessThenFromYearWeek
      ) {
        const data = {
          [otherFieldName]: null,
          [fieldName]: option,
        };

        onChange(data);

        return data;
      }

      const data = {
        ...prev,
        [fieldName]: option,
      };

      onChange(data);

      return data;
    });
  };

  const onClick = () => {
    if (!isFilterInEditingMode) {
      setIsFilterInEditingMode(true);

      filterPanelMode !== FilterPanelModes.setup &&
        setFilterPanelMode(FilterPanelModes.setup);
    }
  };

  const onTrashBinClick = () => {
    setEditingFiltersIds((prev) => prev.filter((item) => item !== filterId));

    setFilterPanelMode(FilterPanelModes.setup);

    onRemoveFilter && onRemoveFilter(filterId);
  };

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.ready) {
      setIsFilterInEditingMode(false);
    }
  }, [filterPanelMode]);

  return (
    <FilterPanelFilterContainer
      onTrashBinClick={onTrashBinClick}
      isPermanentFilter={isFilterPermanent(filterId)}
    >
      <Box display="flex" flexDirection="row" onClick={onClick}>
        <Box sx={{ width }}>
          <FilterPanelYearWeekRangeAutocomplete
            options={options}
            value={yearWeekRange.from ?? null}
            onChange={createOnChangeHandler('from')}
            isAutocompleteInEditingMode={isFilterInEditingMode}
            startDecorator={fromStartDecorator}
            {...autocompleteProps}
          />
        </Box>
        <Box sx={{ width }}>
          <FilterPanelYearWeekRangeAutocomplete
            options={options}
            value={yearWeekRange.to ?? null}
            onChange={createOnChangeHandler('to')}
            isAutocompleteInEditingMode={isFilterInEditingMode}
            startDecorator={toStartDecorator}
            {...autocompleteProps}
          />
        </Box>
      </Box>

      {!isFilterInEditingMode ? (
        <Box sx={{ display: 'flex', mr: '10px' }} onClick={onClick}>
          <Pencil02Svg />
        </Box>
      ) : null}
    </FilterPanelFilterContainer>
  );
};
