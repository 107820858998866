import React from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import {
  CsvIconSvg,
  DocIconSvg,
  DocxIconSvg,
  EyeIconSvg,
  FileIconSvg,
  GifIconSvg,
  ImgIconSvg,
  JpegIconSvg,
  PdfIconSvg,
  PngIconSvg,
  TxtIconSvg,
  XlsIconSvg,
  XlsxIconSvg,
} from 'src/components/svgIcons';

export interface IFilesDownloaderFileData {
  fileUrl: string;
  fileName: string;
}

export interface IFilesDownloaderFileProps extends IFilesDownloaderFileData {
  id: string;
  isSelected: boolean;
  onClick: (fileUrl: string) => void;
}

export const FilesDownloaderFile = ({
  // id,
  fileName,
  // isSelected,
  fileUrl,
  onClick,
}: IFilesDownloaderFileProps) => {
  const _onClick = () => {
    onClick(fileUrl);
  };

  const getIconByFileName = (fileName: string) => {
    const fileNameArr = fileName.split('.');
    const extension = fileNameArr[fileNameArr.length - 1];

    switch (extension) {
      case 'pdf':
        return <PdfIconSvg />;
      case 'docx':
        return <DocxIconSvg />;
      case 'doc':
        return <DocIconSvg />;
      case 'txt':
        return <TxtIconSvg />;
      case 'jpeg':
        return <JpegIconSvg />;
      case 'gif':
        return <GifIconSvg />;
      case 'png':
        return <PngIconSvg />;
      case 'csv':
        return <CsvIconSvg />;
      case 'xls':
        return <XlsIconSvg />;
      case 'img':
        return <ImgIconSvg />;
      case 'xlsx':
        return <XlsxIconSvg />;

      default:
        return <FileIconSvg />;
    }
  };

  return (
    <Box
      onClick={_onClick}
      sx={{
        px: 4,
        py: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid transparent',
        borderColor: 'colors.border.border_secondary',
      }}
    >
      {/* <Box sx={{ mr: 1.5 }}>
        <Checkbox checked={isSelected} />
      </Box> */}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', mr: 1.5 }}>
          {/* <PdfIconSvg /> */}
          {getIconByFileName(fileName)}
        </Box>
        <Typography level="text_sm" textColor="colors.text.text_primary">
          {fileName}
        </Typography>
      </Box>

      <IconButton>
        <EyeIconSvg />
      </IconButton>
    </Box>
  );
};
