import React from 'react';
import { Box } from '@mui/joy';

export const ThreeDotsMenuDot = () => {
  return (
    <Box
      sx={{
        width: 3,
        height: 3,
        borderRadius: '50%',
        bgcolor: 'colors.foreground.fg_quinary',

        '&:not(:last-of-type)': {
          mb: '2px',
        },
      }}
    />
  );
};
