export const skillLevels = {
  NOT_TRAINED: 'not trained',
  IN_TRAINING: 'in training',
  TRAINED: 'trained',
  CAN_TRAIN: 'can train',
};

export const skillWarnings = {
  expired: 'expired',
  expireSoon: 'expireSoon',
  trained: 'trained',
};
