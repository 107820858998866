import { ISagaActionBind } from '../../types';
import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyUphBySiteListRequest,
    saga: createListSaga<ICardinalHealthWeeklyReportModel>({
      apiCall: Api.CardinalHealthWeeklyUphBySite.list,
      actionSuccess: actions.getCardinalHealthWeeklyUphBySiteListSuccess,
      actionFailed: actions.getCardinalHealthWeeklyUphBySiteListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyUphBySiteCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySite.count,
      actionSuccess: actions.getCardinalHealthWeeklyUphBySiteCountSuccess,
      actionFailed: actions.getCardinalHealthWeeklyUphBySiteCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyUphBySiteRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySite.export,
      actionSuccess: actions.exportCardinalHealthWeeklyUphBySiteSuccess,
      actionFailed: actions.exportCardinalHealthWeeklyUphBySiteFailed,
    }),
  },
];
