import React from 'react';
import { IconButton } from '@mui/material';

export const Button = (props: any) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { children, format, active, ...rest } = props;
  return (
    <IconButton
      // className={active ? 'btnActive' : 'lol'}
      title={format}
      // style={{ width: '30px', height: '20px', margin: '0 2px' }}
      {...rest}
      size="large"
    >
      {children}
    </IconButton>
  );
};
