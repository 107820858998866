import React from 'react';
import { useDispatch } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { useNavigate } from 'react-router-dom';
import { createIssueInIssueTrackerRequest } from 'src/modules/actions';
import { useValidate } from 'src/modules/utils';
import { createIssueInIssueTrackerScheme } from 'src/modules/schemes/issue-tracker';
import { PriorityDropdown } from './dropdowns/PriorityDropdown';
import { SupportCategory } from './dropdowns/SupportCategoryDropdown';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikImagesUpload from 'src/components/Formik/FormikImagesUpload';
import { IImageData } from 'src/components/UploadImages';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

const initialValues = {
  siteId: NaN,
  summary: '',
  description: '',
  priorityId: '',
  label: '',
  type: 'Task' as const,
  files: [] as Array<IImageData>,
};

export const CreateIssueInIssueTracker = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatcher = useDispatch();

  const validate = useValidate(createIssueInIssueTrackerScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(
        createIssueInIssueTrackerRequest({
          data: {
            ...data,
            files: data.files.map(({ file }) => file!),
          },
          navigate,
        }),
      );
    },
  });

  const onPriorityChange = (_: any, option: ComboBoxOption | null) => {
    if (option) {
      formik.setFieldValue('priorityId', option.id);
    }
  };

  const onSupportCategoryChange = (_: any, option: ComboBoxOption | null) => {
    if (option) {
      formik.setFieldValue('label', option.id);
    }
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('issue_tracker.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          <SitesComboBox
            id="siteId"
            required={true}
            formik={formik}
            placeholder={t('employees.site')}
          />

          <FormikTextField
            variant="outlined"
            required
            fullWidth
            id="summary"
            label={t('issue_tracker.subject')}
            name="summary"
            formik={formik}
          />

          <PriorityDropdown
            value={formik.values.priorityId}
            onChange={onPriorityChange}
            errorMessage={
              formik.touched.priorityId ? formik.errors.priorityId : undefined
            }
          />

          <SupportCategory
            value={formik.values.label}
            onChange={onSupportCategoryChange}
            errorMessage={
              formik.touched.label ? formik.errors.label : undefined
            }
          />

          <FormikTextarea
            variant="outlined"
            id="description"
            label={t('issue_tracker.description')}
            name="description"
            formik={formik}
            minRows={5}
          />

          <FormikImagesUpload name="files" id="files" formik={formik} />

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
