export const operatorToLabelMap = {
  eq: '=',
  neq: 'Not Equal',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  inq: 'IN',
  nin: 'NOT IN',
  between: 'BETWEEN',
  exists: 'EXISTS',
  and: 'AND',
  or: 'OR',
  like: '=',
  nlike: 'NOT LIKE',
  ilike: 'ILIKE',
  nilike: 'NOT ILIKE',
  regexp: 'REGEXP',
};
