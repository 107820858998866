import { IdsArray } from '../../types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getApplicantsComboboxList } from '../../selectors/applicant';
import {
  getApplicantComboboxListRequest,
  getApplicantListRequest,
} from '../../actions';
import { defaultOrderDetails } from '../helpers/filter';
import React from 'react';
import { useSiteRelationInclusion } from '.';

export const useApplicantInclusions = () => {
  const siteRelationInclusion = useSiteRelationInclusion();

  return React.useMemo(() => {
    // IMPORTANT!!!!!!! Don't change order of relations otherwise you might not get all you need
    return [
      {
        relation: 'supervisor',
      },
      {
        relation: 'staffing',
      },
      {
        relation: 'openPosition',
        scope: {
          include: [
            {
              relation: 'position',
            },
          ],
        },
      },
      siteRelationInclusion,
    ];
  }, [siteRelationInclusion]);
};

export const applicantComboboxFilter = {
  filter: {
    fields: {
      id: true,
      firstName: true,
      lastName: true,
      badge: true,
      siteId: true,
    },
  },
};

/**
 * A custom hook to fetch applicants from store if they exist otherwise to make a request to fetch needed applicants from
 * the server
 */
export const useFetchApplicantsByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  const applicantInclusions = useApplicantInclusions();

  return () => {
    dispatcher(
      getApplicantListRequest({
        filter: {
          where: {
            id: {
              inq: ids,
            },
          },
          include: applicantInclusions,
        },
      }),
    );
  };
};

/**
 * A custom hook to fetch applicant combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchApplicantsCombobox = () => {
  const dispatcher = useDispatch();
  const siteRelationInclusion = useSiteRelationInclusion();
  // fetch combobox list from store
  const combobox = useSelector(getApplicantsComboboxList, shallowEqual);

  const dispatchGetApplicationComboboxListRequest = React.useCallback(() => {
    dispatcher(
      getApplicantComboboxListRequest({
        filter: {
          fields: {
            id: true,
            firstName: true,
            lastName: true,
            badge: true,
            siteId: true,
          },
          include: [siteRelationInclusion],
        },
      }),
    );
  }, [siteRelationInclusion, dispatcher]);

  React.useEffect(() => {
    dispatchGetApplicationComboboxListRequest();
  }, [dispatchGetApplicationComboboxListRequest]);

  return () => {
    if (isEmpty(combobox)) {
      dispatchGetApplicationComboboxListRequest();
    }
  };
};

export const useApplicantsDefaultFilters = () => {
  const include = useApplicantInclusions();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include,
    }),
    [include],
  );
};
