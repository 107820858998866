// Helpers
export enum WikiPageTypeEnum {
  faq = 'FAQs',
  quickReferencesGuide = 'Quick References Guide',
  addingNewEmployee = 'Adding a New Employee',
  bestPractices = 'Best Practices',
  changingDepartments = 'Changing Departments',
  nSiteMap = 'nSite Site Map',
  reviewingEmployeeHours = 'Reviewing Employee Hours',
  terminatingEmployee = 'Terminating an Employee',
  terminationReasonCodes = 'Termination Reason Codes',
  submittingPayroll = 'Submitting Payroll',
}
