import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { map, reduce, set } from 'lodash';
import theme from '../../assets/css/theme';
import { SeriesOptionsType } from 'highcharts';
import { i18n } from '../utils/i18n';
import { getChartItemColor } from '../utils/helpers/theme';

export const getRecruitingDashboardSummaryData = (state: IStoreState) =>
  state.recruitingDashboardSummary;

/**
 * Get recruiting dashboard summary list
 * @param recruitingDashboardSummary - State recruitingDashboardSummary
 */
export const getRecruitingDashboardSummaryList = ({
  recruitingDashboardSummary,
}: IStoreState) => recruitingDashboardSummary.list;

/**
 * Get recruiting dashboard summary count
 * @param recruitingDashboardSummary - State recruitingDashboardSummary
 */
export const getRecruitingDashboardSummaryCount = ({
  recruitingDashboardSummary,
}: IStoreState) => recruitingDashboardSummary.count;

export const getRecruitingDashboardSummaryFilledChartSeries = createSelector(
  getRecruitingDashboardSummaryList,
  (list) => {
    const maxRadius = 100;
    const widthOfGapsBetweenCircles = 2;

    const step = maxRadius / (list.length + 1);

    let radius = maxRadius + step;
    let innerRadius = maxRadius;

    return map(list, (item, i) => {
      radius -= step;
      innerRadius -= step;

      return {
        name: item.siteName,
        data: [
          {
            color: getChartItemColor(i),
            radius: `${radius - widthOfGapsBetweenCircles}%`,
            innerRadius: `${innerRadius}%`,
            y: item.fillPercent,
          },
        ],
      } as SeriesOptionsType;
    });
  },
);

export const getRecruitingDashboardSummaryCountsChartData = createSelector(
  getRecruitingDashboardSummaryList,
  (list) => {
    const colors = [...theme.chartColorScheme].reverse();

    const calculatedValues = reduce(
      list,
      (acc, cur, index) => {
        acc.categories.push(cur.siteName);

        // category total active position
        set(
          acc.series,
          [0, 'name'],
          i18n.t('recruiting_dashboards_summary.totalActivePosition'),
        );
        set(acc.series, [0, 'data', index], cur.totalActivePosition);

        // category numbers needed
        set(
          acc.series,
          [1, 'name'],
          i18n.t('recruiting_dashboards_summary.openPositions'),
        );
        set(acc.series, [1, 'data', index], cur.openPositions);

        return acc;
      },
      { categories: [], series: [] } as {
        categories: string[];
        series: SeriesOptionsType[];
      },
    );

    return {
      ...calculatedValues,
      colors,
    };
  },
);

export const getRecruitingDashboardSummaryBarChartData = createSelector(
  getRecruitingDashboardSummaryList,
  (list) =>
    reduce(
      list,
      (acc, cur, index) => {
        acc.categories.push(cur.siteName);

        // category total active position
        set(
          acc.series,
          [0, 'name'],
          i18n.t('recruiting_dashboards_summary.timeDiff'),
        );
        set(acc.series, [0, 'data', index], {
          y: cur.timeDiff,
          color: getChartItemColor(index),
        });

        return acc;
      },
      { categories: [], series: [] } as {
        categories: string[];
        series: SeriesOptionsType[];
      },
    ),
);
