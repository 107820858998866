import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getFilteredLoggedInList,
  getIsLoggedInListDataLoading,
  getLoggedInTotal,
} from '../../../modules/selectors/supervisorView';
import LoggedInTable from './LoggedInTable';
import {
  exportSupervisorViewRequest,
  getLoggedInListRequest,
} from '../../../modules/actions';
import { EmployeesTable } from './EmployeesTable';
import { omit } from 'lodash';
import {
  useCreateExportProps,
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useFetchSitesCombobox,
  useSupervisorCombobox,
} from '../../../modules/utils/hooks';
import {
  ICountResponse,
  ILogtimeModel,
  ILogtimeSupervisorViewModel,
  MoveToDepartmentRequestType,
} from '../../../modules/types';
import { getDepartmentsComboboxList } from '../../../modules/selectors/department';
import { useTranslation } from 'react-i18next';
import {
  composeDate,
  DATETIME_FORMAT_TO_PASS,
  getNow,
  format,
  guessTimezone,
} from '../../../modules/utils/dateWrapper';
import { getUsersComboboxList } from '../../../modules/selectors/user';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import {
  TableContext,
  TableContextProvider,
} from 'src/components/Table/TableContext';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import {
  useSupervisorViewEmployeesTableDefaultWhere,
  useSupervisorViewEmployeesTableFiltersConfiguration,
  useSupervisorViewEmployeesTableHeadCellsConfiguration,
  useSupervisorViewEmployeesTableInclusionObject,
  useSupervisorViewTableDefaultFilter,
} from 'src/modules/utils/hooks/supervisorView.hooks';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { defaultOrderDetails } from 'src/modules/utils/helpers/filter';
import { EmployeesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/EmployeesComboBoxFilter';
import { EntitiesBySiteComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/EntitiesBySiteComboBoxFilter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { DateTimePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DateTimePickerFilter';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';
import { SupervisorViewChangeDepartmentDrawer } from './SupervisorViewChangeDepartmentDrawer';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { Api } from 'src/modules/utils';
import { ActionConfirmation } from 'src/components/ActionConfirmation';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useTheme } from '@mui/joy';

const SupervisorViewComponent = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isSmallScreen = window.innerWidth <= theme.breakpoints.values.sm;

  const dispatch = useDispatch();

  const { filters: currentFiltersState } = React.useContext(TableContext);

  const [isConfirmLogoutOpen, setIsConfirmLogoutOpen] = React.useState(false);

  const hasUserAccessToLogoutEmployeePoints = useHasUserAccessToAction(
    manageEntitiesConfig.employee.logout.id,
  );
  const hasUserAccessToMoveToAnotherDepartmentEmployeePoints = useHasUserAccessToAction(
    manageEntitiesConfig.employee.move_to_another_department.id,
  );

  const [
    isChangeDepartmentVisible,
    setIsChangeDepartmentVisible,
  ] = React.useState(false);

  const [selectedEmployees, setSelectedEmployees] = React.useState<
    Array<ILogtimeSupervisorViewModel>
  >([]);

  const [
    isMoveEmployeeToAnotherDepartmentProcessing,
    setIsMoveEmployeeToAnotherDepartmentProcessing,
  ] = React.useState(false);

  const {
    fetchData: fetchEmployeesTableData,
    data: employeesTableData,
    isDataLoading: isEmployeesTableDataLoading,
  } = useDataFetcherWithData<Array<ILogtimeSupervisorViewModel>>(
    apiEndpoints.LOGTIMES_SUPERVISOR_VIEW,
    [],
  );
  const {
    data: employeesTableCountData,
    fetchData: fetchEmployeesTableCountData,
    isDataLoading: isEmployeesTableCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    apiEndpoints.LOGTIMES_SUPERVISOR_VIEW_COUNT,
    {
      count: 0,
    },
  );

  const employeesTableDefaultFilter = useSupervisorViewTableDefaultFilter();
  const headCellsConfig = useSupervisorViewEmployeesTableHeadCellsConfiguration();
  const supervisorViewEmployeesTableDefaultWhere = useSupervisorViewEmployeesTableDefaultWhere();
  const employeesTableFiltersConfiguration = useSupervisorViewEmployeesTableFiltersConfiguration();
  const supervisorViewEmployeesTableInclusionObj = useSupervisorViewEmployeesTableInclusionObject();
  const supervisorViewEmployeesTableFiltersConfiguration = useSupervisorViewEmployeesTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration: employeesTableFiltersConfiguration,
  });

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: 'paidTimeIn',
  });

  const requestFilters = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    defaultWhere: supervisorViewEmployeesTableDefaultWhere,
    inclusionObj: supervisorViewEmployeesTableInclusionObj,
    filterFieldsConfiguration: supervisorViewEmployeesTableFiltersConfiguration,
  });

  const loggedInList = useSelector(getFilteredLoggedInList, shallowEqual);

  const isLoggedInListDataLoading = useSelector(getIsLoggedInListDataLoading);

  const fetchSitesCombobox = useFetchSitesCombobox();
  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchSupervisorsCombobox = useSupervisorCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  const supervisors = useSelector(getUsersComboboxList);

  const departments = useSelector(getDepartmentsComboboxList);
  const departmentsBySite = React.useMemo(() => {
    if (currentFiltersState.siteId) {
      return departments.filter(
        (department) =>
          department.siteId === Number(currentFiltersState.siteId),
      );
    }

    return departments;
  }, [currentFiltersState.siteId, departments]);

  const shifts = useSelector(getShiftsComboboxList);
  const shiftsBySite = React.useMemo(() => {
    if (currentFiltersState.siteId) {
      return shifts.filter(
        (department) =>
          department.siteId === Number(currentFiltersState.siteId),
      );
    }

    return shifts;
  }, [currentFiltersState.siteId, shifts]);

  const loggedInTotal = useSelector(getLoggedInTotal, shallowEqual);

  useEffect(() => {
    fetchSitesCombobox();
    fetchShiftsCombobox();
    fetchDepartmentsCombobox();
    fetchSupervisorsCombobox();
    dispatch(getLoggedInListRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // dispatch the action only once

  React.useEffect(() => {
    const { limit, offset, ...commonFilters } = requestFilters;

    fetchEmployeesTableCountData({ filter: { ...commonFilters } });
    fetchEmployeesTableData({ filter: { limit, offset, ...commonFilters } });
  }, [fetchEmployeesTableCountData, fetchEmployeesTableData, requestFilters]);

  const onTableRowCheckboxClick = (
    employees: Array<ILogtimeSupervisorViewModel>,
  ) => {
    setSelectedEmployees(employees);
  };

  const onMoveEmployeesInOtherDepartment = async (
    departmentId: number,
    employeesIds: Array<number>,
  ) => {
    if (departmentId) {
      setIsChangeDepartmentVisible(false);

      setSelectedEmployees([]);

      setIsMoveEmployeeToAnotherDepartmentProcessing(true);

      await Api.Logtime.moveToDepartment(
        employeesIds.map((id) => ({
          id: id,
          timezone: guessTimezone(),
          departmentId: departmentId,
        })) as MoveToDepartmentRequestType[],
      );

      setIsMoveEmployeeToAnotherDepartmentProcessing(false);

      dispatch(getLoggedInListRequest());

      fetchEmployeesTableData({ filter: { ...employeesTableDefaultFilter } });
      fetchEmployeesTableCountData({
        filter: { ...omit(employeesTableDefaultFilter, ['limit', 'offset']) },
      });
    }
  };

  const onLogoutEmployees = async (employeesIds: Array<number>) => {
    setIsConfirmLogoutOpen(false);

    setSelectedEmployees([]);

    setIsMoveEmployeeToAnotherDepartmentProcessing(true);

    await Api.Logtime.bulkUpdate(
      employeesIds.map(
        (id) =>
          ({
            id: id,
            timezone: guessTimezone(),
            timeOut: composeDate(getNow(), format(DATETIME_FORMAT_TO_PASS)),
          } as unknown),
      ) as ILogtimeModel[],
    );

    setIsMoveEmployeeToAnotherDepartmentProcessing(false);

    dispatch(getLoggedInListRequest());

    fetchEmployeesTableData({ filter: { ...employeesTableDefaultFilter } });
    fetchEmployeesTableCountData({
      filter: { ...omit(employeesTableDefaultFilter, ['limit', 'offset']) },
    });
  };

  const exportProps = useCreateExportProps(exportSupervisorViewRequest);

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer fullHeight={false}>
          <TableToolbar>
            {!isSmallScreen && (
              <TableTitle>{t('time_keeping.supervisor_view')}</TableTitle>
            )}

            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'siteId',
                      labelPrefix: t('supervisor_view.site'),
                    })}
                    {...getFilterCommonPropsByFilterName('siteId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <EmployeesComboBoxFilter
                    propertyAsID="id"
                    label={getLabel({
                      filterName: 'employeeId',
                      labelPrefix: t('supervisor_view.employee'),
                    })}
                    {...getFilterCommonPropsByFilterName('employeeId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <EntitiesBySiteComboBoxFilter
                    options={supervisors}
                    label={getLabel({
                      filterName: 'employeeSupervisorId',
                      labelPrefix: t('applicant.supervisorId'),
                    })}
                    {...getFilterCommonPropsByFilterName(
                      'employeeSupervisorId',
                    )}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'badge',
                      labelPrefix: t('supervisor_view.badge'),
                    })}
                    {...getFilterCommonPropsByFilterName('badge')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <DateTimePickerFilter
                    label={getLabel({
                      filterName: 'paidTimeIn',
                      labelPrefix: t('supervisor_view.time_in_paid'),
                    })}
                    {...getFilterCommonPropsByFilterName('paidTimeIn')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    options={departmentsBySite}
                    label={getLabel({
                      filterName: 'departmentId',
                      labelPrefix: t('supervisor_view.department'),
                    })}
                    {...getFilterCommonPropsByFilterName('departmentId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <ComboboxTableFilter
                    options={shiftsBySite}
                    label={getLabel({
                      filterName: 'shiftId',
                      labelPrefix: t('supervisor_view.shift'),
                    })}
                    {...getFilterCommonPropsByFilterName('shiftId')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              <TableActionsExport
                requestFilters={requestFilters}
                exportBtnContainerProps={{ ml: 3 }}
                {...exportProps}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>

        <PageContentChildContainer fullHeight={false}>
          <LoggedInTable
            isDataLoading={
              isLoggedInListDataLoading ||
              isMoveEmployeeToAnotherDepartmentProcessing
            }
            rows={loggedInList}
            total={loggedInTotal}
          />
        </PageContentChildContainer>

        <PageContentChildContainer sx={{ pt: 1 }}>
          <EmployeesTable
            tableData={employeesTableData}
            tableDataCount={employeesTableCountData.count}
            onLimitChange={setLimit}
            onOrderByChange={setOrderBy}
            onPageChange={setPage}
            onOrderChange={setOrder}
            onRowCheckboxClick={onTableRowCheckboxClick}
            limit={limit}
            page={page}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            selectedEmployees={selectedEmployees}
            isDataLoading={
              isEmployeesTableDataLoading ||
              isEmployeesTableCountDataLoading ||
              isMoveEmployeeToAnotherDepartmentProcessing
            }
            onMoveToAnotherDepartmentClick={
              hasUserAccessToMoveToAnotherDepartmentEmployeePoints
                ? () => setIsChangeDepartmentVisible(true)
                : undefined
            }
            onLogoutEmployeesClick={
              hasUserAccessToLogoutEmployeePoints
                ? () => {
                    setIsConfirmLogoutOpen(true);
                  }
                : undefined
            }
          />
        </PageContentChildContainer>
      </PageContentWithTopToolbar>

      <SupervisorViewChangeDepartmentDrawer
        employees={selectedEmployees}
        isOpen={isChangeDepartmentVisible}
        onApply={onMoveEmployeesInOtherDepartment}
        onClose={() => setIsChangeDepartmentVisible(false)}
      />

      <ActionConfirmation
        type="success"
        open={isConfirmLogoutOpen}
        onCancel={() => setIsConfirmLogoutOpen(false)}
        onOk={() => onLogoutEmployees(selectedEmployees.map(({ id }) => id))}
      >
        {t('supervisor_view.confirm_logout')}
      </ActionConfirmation>
    </>
  );
};

export const SupervisorView = () => {
  return (
    <TableContextProvider>
      <SupervisorViewComponent />
    </TableContextProvider>
  );
};
