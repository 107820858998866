import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  deleteSkillRequest,
  exportSkillRequest,
  getSkillCountRequest,
  getSkillListRequest,
} from '../../modules/actions';
import {
  getSkillCount,
  getSkillRefreshKey,
  getSkillTable,
} from '../../modules/selectors/skill';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { IdsArray } from '../../modules/types';
import {
  useSkillsDefaultFilters,
  useSearch,
  useCreateExportProps,
  useFilter,
  useBrowserHistoryFunctions,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { ModelsToDelete, useHasUserAccessToAction } from '../../config';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const SkillsList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.skill.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.skill.update.id,
  );

  const defaultFilter = useSkillsDefaultFilters();
  // fetch Supervisor list
  const list = useSelector(getSkillTable, shallowEqual);

  const exportProps = useCreateExportProps(exportSkillRequest);
  const count = useSelector(getSkillCount, shallowEqual);

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  // compose table header cells
  const headCells: HeadCell[] = [
    { id: 'name', disablePadding: false, label: t('skills.name') },
    { id: 'site.name', disablePadding: false, label: t('skills.site') },
    {
      id: 'expirationPeriod',
      disablePadding: false,
      label: t('emp_skills.expiration_period'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'name',
      label: t('common.name'),
      operator: 'like',
    },
    {
      name: 'site.id',
      label: t('skills.site'),
      operator: 'eq',
      type: 'comboboxSites',
    },
  ];

  // make request to fetch skills when component is mounted
  useEffect(() => {
    dispatcher(getSkillListRequest(filterList));
    // get total count
    dispatcher(getSkillCountRequest(filterCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where, include: newInclude } = props;
    const offset = page * rowsPerPage;
    dispatcher(
      getSkillListRequest({
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
          include: newInclude,
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getSkillCountRequest({ filter: { where, include: newInclude } }),
    );
  };

  // handle deletion
  const handleDeletion = (ids: IdsArray) => {
    dispatcher(deleteSkillRequest(ids));
  };

  // handle updating
  const handleUpdating = (ids: IdsArray) => {
    const search = composeSearch({ ids });
    pushToHistory({ pathname: '/skills/update', search });
  };

  const computedList = useMemo(
    () =>
      list.map((item) => ({
        ...item,
        expirationPeriod:
          item.expirationPeriod ?? (item.isPermanent ? 'N/A' : ''),
      })),
    [list],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EnhancedTable
          data={computedList}
          count={count}
          selectIndex="id"
          tableName={t('skills.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={onSync}
          onDelete={allowedToDelete ? handleDeletion : undefined}
          onUpdate={allowedToUpdate ? handleUpdating : undefined}
          include={defaultFilter.include}
          exportProps={exportProps}
          deleteModelName={ModelsToDelete.Skill}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

/**
 * Wrapper to refresh component on flush store
 */
export default function SkillsListRefreshable() {
  return <SkillsList key={useSelector(getSkillRefreshKey)} />;
}
