import React from 'react';
import { IFilter, IFilterData } from 'src/modules/types';
import { ICommonFilterProps } from '../../types';
import { TableContext } from '../../../../../../TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import {
  useQueryParams,
  useSupervisorsComboBoxOptions,
} from 'src/modules/utils';
import { get } from 'lodash';

interface ISupervisorsComboBoxFilterProps
  extends Omit<IComboboxProps, 'value' | 'options' | 'name'>,
    ICommonFilterProps {
  requestFilter?: IFilterData;
  siteId?: number;
}
export const SupervisorsComboBoxFilter: React.FC<ISupervisorsComboBoxFilterProps> = ({
  value,
  name,
  siteId: _siteId,
  ...restComboBoxProps
}) => {
  const filter = useQueryParams() as IFilter;
  // entityId should be a number or null, but in filter it is a string
  const siteId = _siteId || Number(get(filter, 'filter.where.siteId')) || null;

  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const supervisors = useSupervisorsComboBoxOptions();

  const supervisorOptions = React.useMemo(
    () => supervisors.filter((s) => siteId && s.sites.includes(siteId)),
    [siteId, supervisors],
  );

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue = React.useMemo(
    () =>
      supervisorOptions.find((option) => option.id === Number(valueId)) || null,
    [supervisorOptions, valueId],
  );
  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={supervisorOptions}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
