import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { ContentLayout, IContentLayoutProps } from './ContentLayout';
import Accordion from '../Accordion';
import { useIsObjectChanged } from '../../modules/utils/hooks/common/forms';
import { ConfirmPageLeave } from '../ConfirmPageLeave';
import { useFormLayoutData } from '../../modules/utils/hooks/layout';

export type CreateFormHandleSubmit = (event: React.FormEvent) => void;

interface ICreateFormLayout extends IContentLayoutProps {
  cardTitle: string;
  handleFormSubmit: CreateFormHandleSubmit;
  fields: Record<any, any>;
  isFormValid: boolean;
  formStyle?: any;
}

const CreateFormLayout: React.FC<ICreateFormLayout> = ({
  cardTitle,
  children,
  handleFormSubmit,
  fields,
  isFormValid,
  maxWidth = 'sm',
  formStyle,
  sx,
}) => {
  const { onFormSubmit, isSubmitting } = useFormLayoutData(
    isFormValid,
    handleFormSubmit,
  );

  const isFormDataChanged = useIsObjectChanged(fields);

  return (
    <ContentLayout
      maxWidth={maxWidth}
      sx={[{ justifyContent: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <form
        id={kebabCase(cardTitle)}
        className={` create-form`}
        noValidate={true}
        onSubmit={onFormSubmit}
        style={{ width: '100%', ...formStyle }}
      >
        <Accordion defaultExpanded={true} cardTitle={cardTitle}>
          {children}
        </Accordion>
      </form>
      <ConfirmPageLeave isActive={isFormDataChanged && !isSubmitting} />
    </ContentLayout>
  );
};

export default CreateFormLayout;
