import React from 'react';
import { SelectProps, Typography } from '@mui/joy';
import { Select } from '../_ui-kit/Select';

type ISelectForChartProps = SelectProps<any, any>;

export const SelectForChart = ({ sx, ...props }: ISelectForChartProps) => {
  return (
    <Select
      sx={{
        borderRadius: 8,
        borderColor: 'transparent',
        boxShadow: 'none',
        bgcolor: 'colors.background.bg_secondary',
        ...sx,
      }}
      {...props}
      size="sm"
      renderValue={({ label, ...option }: any) => {
        return (
          <Typography
            {...option}
            level="text_xs"
            textColor="colors.text.text_primary.main"
          >
            {label}
          </Typography>
        );
      }}
    />
  );
};
