import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthWeeklyUphBySiteAndShift',
);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyUphBySiteAndShiftListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteAndShiftListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteAndShiftListFailed,
} = actionCreator.takeListActions<ICardinalHealthWeeklyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteAndShiftCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteAndShiftCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteAndShiftCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyUphBySiteAndShiftRequest,
  exportReportSuccess: exportCardinalHealthWeeklyUphBySiteAndShiftSuccess,
  exportReportFailed: exportCardinalHealthWeeklyUphBySiteAndShiftFailed,
} = new ExportActionCreator(
  'CardinalHealthWeeklyUphBySiteAndShift',
).takeExportActions();
