import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api, i18n } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  IRecruitingDashboardDetailsModel,
  IRecruitingDashboardDetailsRequest,
  ISagaAction,
  ISagaActionBind,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Get recruiting dashboard list
 */
export const getRecruitingDashboardDetailsListRequestSaga = function* (
  action: ISagaAction<IRecruitingDashboardDetailsRequest>,
): SagaIterator {
  try {
    const response: IRecruitingDashboardDetailsModel[] = yield call(
      Api.RecruitingDashboardDetails.list,
      action.payload,
    );
    yield put(actions.getRecruitingDashboardDetailsListSuccess(response));
  } catch (e) {
    yield put(actions.getRecruitingDashboardDetailsListFailed());
    yield put(
      actions.addProcessStatus({
        variant: 'error',
        message: i18n.et('recruiting_dashboard', (e as any).message),
      }),
    );
  }
};

/**
 * Get recruiting dashboard count
 */
export const getRecruitingDashboardDetailsCountRequestSaga = function* (
  action: ISagaAction<IRecruitingDashboardDetailsRequest>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.RecruitingDashboardDetails.count,
      action.payload,
    );
    yield put(actions.getRecruitingDashboardDetailsCountSuccess(response));
  } catch (e) {
    yield put(actions.getRecruitingDashboardDetailsCountFailed());
    yield put(
      actions.addProcessStatus({
        variant: 'error',
        message: i18n.et('recruiting_dashboard', (e as any).message),
      }),
    );
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.RecruitingDashboardDetails.export,
  actionFailed: actions.exportRecruitingDashboardDetailsFailed,
  actionSuccess: actions.exportRecruitingDashboardDetailsSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportRecruitingDashboardDetailsRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  // get recruiting dashboard details list
  {
    action: actions.getRecruitingDashboardDetailsListRequest,
    saga: getRecruitingDashboardDetailsListRequestSaga,
  },
  // get recruiting dashboard details count
  {
    action: actions.getRecruitingDashboardDetailsCountRequest,
    saga: getRecruitingDashboardDetailsCountRequestSaga,
  },
  // get recruiting dashboard details export
  {
    action: actions.exportRecruitingDashboardDetailsRequest,
    saga: exportRecruitingDashboardDetailsRequestSaga,
  },
];
