import React from 'react';
import { Sheet, useTheme } from '@mui/joy';
import { SideBarLogo } from './SideBarLogo';
import { SideBarMainMenu } from './SideBarMainMenu';
import { useMainLayoutContext } from '../MainLayoutContext';
import { SideBarToggleWidthBtn } from './SideBarToggleWidthBtn';
import { SideBarGlobalSitesDropdown } from './SideBarGlobalSitesDropdown';

export const SideBar = () => {
  const theme = useTheme();

  const { sideBar, createTransition, sideBarColors } = useMainLayoutContext();

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: `${sideBar.width}px`,
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        transition: createTransition(['width', 'padding']),
        backgroundColor: sideBarColors.background,
        zIndex: theme.zIndex.modal,
      }}
    >
      <SideBarLogo />

      <SideBarGlobalSitesDropdown />

      <SideBarMainMenu />

      <SideBarToggleWidthBtn />
    </Sheet>
  );
};
