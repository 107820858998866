import { createReducer } from 'redux-act';

import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import * as actions from '../../actions';

import { IDailyRawReportState } from '../../types/reports';
import { IProductionDailyUphBase } from '../../types/productionUphReports';

const defaultState: IDailyRawReportState = {
  list: [],
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getOptoroDailyReportListRequest,
  getListSuccess: getOptoroDailyReportListSuccess,
  getListFailed: getOptoroDailyReportListFailed,
} = reducerCreator.takeListHandlers<
  IDailyRawReportState,
  IProductionDailyUphBase
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getOptoroDailyReportCountRequest,
  getCountSuccess: getOptoroDailyReportCountSuccess,
  getCountFailed: getOptoroDailyReportCountFailed,
} = reducerCreator.takeCountHandlers<IDailyRawReportState>();

/**
 * actually Reducer
 */
export const optoroDailyReport = createReducer<IDailyRawReportState>(
  {},
  defaultState,
)
  // get list actions
  .on(actions.getOptoroDailyReportListRequest, getOptoroDailyReportListRequest)
  .on(actions.getOptoroDailyReportListSuccess, getOptoroDailyReportListSuccess)
  .on(actions.getOptoroDailyReportListFailed, getOptoroDailyReportListFailed)
  // get count actions
  .on(
    actions.getOptoroDailyReportCountRequest,
    getOptoroDailyReportCountRequest,
  )
  .on(
    actions.getOptoroDailyReportCountSuccess,
    getOptoroDailyReportCountSuccess,
  )
  .on(actions.getOptoroDailyReportCountFailed, getOptoroDailyReportCountFailed);

export default optoroDailyReport;
