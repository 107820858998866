import React from 'react';
import {
  IAdditionalFeeCategoryModel,
  IAdditionalFeeModel,
  IdsArray,
  IStoreState,
} from '../types';
import { formatAsCurrency } from '../utils/helpers/formatters';
import { NoIcon, YesIcon } from '../../components/Icons';
import { memoize } from 'lodash';
import { createSelector } from 'reselect';

export const getAdditionalFeeList = ({ additionalFee }: IStoreState) => ({
  list: additionalFee.feeList.map((listItem) => ({
    ...listItem,
    isTemporary: !!listItem.isTemporary,
    cpu: String(listItem.cpu),
    quantity: String(listItem.quantity),
  })),
  count: additionalFee.feeCount,
});

export const getAdditionalFeeListForTable = ({
  additionalFee,
}: IStoreState) => ({
  list: additionalFee.feeList.map((item) => ({
    ...item,
    cpu: formatAsCurrency(Number(item.cpu)),
    isTemporary: item.isTemporary ? (
      <YesIcon color="primary" />
    ) : (
      <NoIcon color="primary" />
    ),
  })),
  count: additionalFee.feeCount,
});

export const getAdditionalFeeCategoryList = ({
  additionalFee,
}: IStoreState) => ({
  list: additionalFee.categoryList,
  count: additionalFee.categoryCount,
});

export const getAdditionalFeeCategoriesByIds = createSelector(
  getAdditionalFeeCategoryList,
  (additionalFeeCategoryState) =>
    memoize((ids: IdsArray) => {
      const initial: IAdditionalFeeCategoryModel[] = [];
      return additionalFeeCategoryState.list.reduce((acc, cur) => {
        if (ids.includes(cur.id)) {
          acc.push(cur as IAdditionalFeeCategoryModel);
        }
        return acc;
      }, initial);
    }),
);

export const getAdditionalFeeByIds = createSelector(
  getAdditionalFeeList,
  (additionalFeeState) =>
    memoize((ids: IdsArray) => {
      const initial: IAdditionalFeeModel[] = [];
      return additionalFeeState.list.reduce((acc, cur) => {
        if (ids.includes(cur.id)) {
          acc.push(cur as IAdditionalFeeModel);
        }
        return acc;
      }, initial);
    }),
);

export const getIsAdditionalFeeDataLoading = ({
  additionalFee: {
    is_additional_fee_list_loading,
    is_additional_fee_count_loading,
  },
}: IStoreState) =>
  is_additional_fee_list_loading || is_additional_fee_count_loading;

export const getIsAdditionalFeeCategoryDataLoading = ({
  additionalFee: {
    is_additional_fee_category_count_loading,
    is_additional_fee_category_list_loading,
  },
}: IStoreState) =>
  is_additional_fee_category_count_loading ||
  is_additional_fee_category_list_loading;
