import {
  ICardinalHealthWeeklyReportModel,
  ICardinalHealthWeeklyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthWeeklyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyUphBySiteListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthWeeklyReportState,
  ICardinalHealthWeeklyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthWeeklyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyUphBySite = createReducer<
  ICardinalHealthWeeklyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteListRequest,
    getCardinalHealthWeeklyUphBySiteListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteListSuccess,
    getCardinalHealthWeeklyUphBySiteListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteListFailed,
    getCardinalHealthWeeklyUphBySiteListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteCountRequest,
    getCardinalHealthWeeklyUphBySiteCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteCountSuccess,
    getCardinalHealthWeeklyUphBySiteCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteCountFailed,
    getCardinalHealthWeeklyUphBySiteCountFailed,
  );

export default cardinalHealthWeeklyUphBySite;
