import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  IdsArray,
  IFilterData,
  IStoreState,
  IUpdateInvoice,
  IWhere,
} from '../../../../../../modules/types';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { updateInvoiceScheme } from '../../../../../../modules/schemes/invoice';
import { getInvoicesByIds } from '../../../../../../modules/selectors/invoice';
import { updateInvoicesRequest } from '../../../../../../modules/actions';
import { Sheet } from '@mui/joy';
import FormikDatepicker from '../../../../../../components/Formik/FormikDatepicker';

interface IUpdateWeeklyInvoiceProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  invoicesToUpdateIds: IdsArray;
}

export const UpdateWeeklyInvoice = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
  invoicesToUpdateIds,
}: IUpdateWeeklyInvoiceProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(updateInvoiceScheme);

  const invoices = useSelector(
    (state) => getInvoicesByIds(state as IStoreState)(invoicesToUpdateIds),
    shallowEqual,
  );

  const formik = useFormik({
    initialValues: invoices.map((invoice) => ({
      id: invoice.id,
      paymentTerms: invoice.paymentTerms,
      purchaseOrder: invoice.purchaseOrder,
      dueDate: invoice.dueDate,
      backupInvoiceNumber: invoice.backupInvoiceNumber,
    })),
    validate,
    enableReinitialize: true,
    onSubmit: (data: IUpdateInvoice[]) => {
      dispatch(
        updateInvoicesRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('invoice.create_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        {isOpen ? (
          <DrawerBodyContent>
            {formik.values.map((invoice, index) => (
              <DrawerBodySectionContent key={invoice.id}>
                <DrawerBodySectionTitle>
                  {t('invoice.update_title', { id: invoice.id })}
                </DrawerBodySectionTitle>
                <Sheet sx={{ bgcolor: 'inherit' }}>
                  <FormFieldContainer>
                    <FormikTextField
                      fullWidth
                      variant="outlined"
                      id={`${index}.purchaseOrder`}
                      label={t('invoice.purchase_order')}
                      name={`${index}.purchaseOrder`}
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      fullWidth
                      variant="outlined"
                      id={`${index}.backupInvoiceNumber`}
                      label={t('invoice.backup_invoice_number')}
                      name={`${index}.backupInvoiceNumber`}
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikTextField
                      fullWidth
                      variant="outlined"
                      id={`${index}.paymentTerms`}
                      label={t('invoice.payment_terms')}
                      name={`${index}.paymentTerms`}
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikDatepicker
                      formik={formik as any}
                      label={t('invoice.due_date')}
                      id={`${index}.dueDate`}
                    />
                  </FormFieldContainer>
                </Sheet>
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        ) : null}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
