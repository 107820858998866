import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateSiteTaktExportingSettings,
  IdsArray,
  IFilter,
  ISiteTaktExportingSettingsModel,
  IUpdateSiteTaktExportingSettings,
  PayloadWithFilters,
} from '../types';

// Flush actions
export const flushSiteTaktExportingSettingsState = createAction(
  'Flush siteTaktExportingSettings data in store',
);

// Creation actions
export const createSiteTaktExportingSettingsRequest = createAction<
  PayloadWithFilters<ICreateSiteTaktExportingSettings>
>('Make request to create a new siteTaktExportingSettings');
export const createSiteTaktExportingSettingsSuccess = createAction<
  ISiteTaktExportingSettingsModel
>('New siteTaktExportingSettings has been created successfully');
export const createSiteTaktExportingSettingsFailed = createAction(
  'Cannot create a new siteTaktExportingSettings',
);

// Fetch siteTaktExportingSettings actions
export const getSiteTaktExportingSettingsListRequest = createAction<IFilter>(
  'Make request to fetch siteTaktExportingSettings list',
);
export const getSiteTaktExportingSettingsListSuccess = createAction<
  ISiteTaktExportingSettingsModel[]
>('SiteTaktExportingSettings list has been fetched successfully');
export const getSiteTaktExportingSettingsListFailed = createAction(
  'Cannot fetch siteTaktExportingSettings list',
);

// get siteTaktExportingSettings count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getSiteTaktExportingSettingsCountRequest = createAction<IFilter>(
  'Make request to get siteTaktExportingSettings count',
);
export const getSiteTaktExportingSettingsCountSuccess = createAction<
  ICountResponse
>('SiteTaktExportingSettings count has been fetched successfully');
export const getSiteTaktExportingSettingsCountFailed = createAction(
  'Cannot get siteTaktExportingSettings count',
);

// delete actions
export const deleteSiteTaktExportingSettingsRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete siteTaktExportingSettings');
export const deleteSiteTaktExportingSettingsSuccess = createAction(
  'Successfully deleted siteTaktExportingSettings',
);
export const deleteSiteTaktExportingSettingsFailed = createAction(
  'Cannot delete siteTaktExportingSettings',
);

// Updating actions
export const updateSiteTaktExportingSettingsRequest = createAction<
  PayloadWithFilters<IUpdateSiteTaktExportingSettings[]>
>('Make request to update siteTaktEXportingSettings');
export const updateSiteTaktExportingSettingsSuccess = createAction(
  'Successfully updated siteTaktExportingSettings',
);
export const updateSiteTaktExportingSettingsFailed = createAction(
  'Cannot update siteTaktExportingSettings',
);
