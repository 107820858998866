import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  getRecruitingDashboardSummaryBarChartData,
  getRecruitingDashboardSummaryCountsChartData,
  getRecruitingDashboardSummaryFilledChartSeries,
} from '../../../modules/selectors/recruitingDashboardSummary';
import { useTranslation } from 'react-i18next';
import ActivityGaugeChart from '../../../components/Charts/ActivityGaugeChart';
import RadialBarChart from '../../../components/Charts/RadialBarChart';
import HorizontalBarChart from '../../../components/Charts/HorizontalBarChart';
import { ChartsGrid } from 'src/components/ChartsGrid';

export const RecruitingDashboardSummaryCharts = () => {
  const { t } = useTranslation();

  const filledChartSeries = useSelector(
    getRecruitingDashboardSummaryFilledChartSeries,
    shallowEqual,
  );
  const {
    categories: countChartCategories,
    series: countChartSeries,
    colors: countChartColors,
  } = useSelector(getRecruitingDashboardSummaryCountsChartData, shallowEqual);
  const {
    categories: barChartCategories,
    series: barChartSeries,
  } = useSelector(getRecruitingDashboardSummaryBarChartData, shallowEqual);

  return (
    <ChartsGrid
      charts={[
        <ActivityGaugeChart
          key={t('recruiting_dashboards_summary.summary_filled_chart.title')}
          title={t('recruiting_dashboards_summary.summary_filled_chart.title')}
          series={filledChartSeries}
        />,
        <RadialBarChart
          key={t('recruiting_dashboards_summary.count_chart.title')}
          title={t('recruiting_dashboards_summary.count_chart.title')}
          categories={countChartCategories}
          series={countChartSeries}
          colors={countChartColors}
        />,
        <HorizontalBarChart
          key={t('recruiting_dashboards_summary.bar_chart.title')}
          title={t('recruiting_dashboards_summary.bar_chart.title')}
          tooltipSuffix={t('recruiting_dashboards_summary.bar_chart.hours')}
          categories={barChartCategories}
          series={barChartSeries}
        />,
      ]}
    />
  );
};
