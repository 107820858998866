import React from 'react';
import AccordionMui from '@mui/joy/Accordion';
import AccordionSummary from '@mui/joy/AccordionSummary';
import AccordionDetails from '@mui/joy/AccordionDetails';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { MUI_SIZE_12, MUI_SIZE_2 } from '../../config';
import { useTheme } from '@mui/material';
import { AccordionProps } from '@mui/joy';

interface IAccordionProps extends Omit<AccordionProps, 'sx'> {
  cardTitle?: string;
  cardTitleContent?: React.ReactElement;
  children: any;
  defaultExpanded?: boolean;
  alwaysExpanded?: boolean;
  fullWidth?: boolean;
}

const Accordion: React.FC<IAccordionProps> = ({
  cardTitle,
  cardTitleContent,
  children,
  fullWidth,
  defaultExpanded,
  alwaysExpanded,
  ...restProps
}) => {
  const theme = useTheme();

  return (
    <AccordionMui
      sx={{
        width: '100%',
        maxWidth: fullWidth ? '100%' : theme.breakpoints.values.md,
      }}
      defaultExpanded={defaultExpanded}
      {...restProps}
      expanded={alwaysExpanded}
    >
      <AccordionSummary>
        {cardTitleContent ?? (
            <Typography variant="h5">{cardTitle}</Typography>
          ) ??
          ''}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} spacing={MUI_SIZE_2}>
          <Grid item={true} xs={MUI_SIZE_12}>
            {children}
          </Grid>
        </Grid>
      </AccordionDetails>
    </AccordionMui>
  );
};

export default Accordion;
