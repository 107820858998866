import React from 'react';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { fetchHRDashboardEmployeeTurnover } from 'src/modules/utils/Api/hrDashboard';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import {
  getLastNDaysStarEndDates,
  getYDTStarEndDates,
} from 'src/modules/utils/dateWrapper';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { IAutocompleteOption } from 'src/modules/types/autocomplete';

interface IHRDashboardNetEmployeesProps {
  siteId?: number;
  shiftId?: number;
}

export const HRDashboardNetEmployees = ({
  siteId,
  shiftId,
}: IHRDashboardNetEmployeesProps) => {
  const { t } = useTranslation();

  const chartTypeOptions = React.useMemo(
    () => [
      { id: 'ydt' as const, label: t('common.ydt') },
      { id: 'last30days' as const, label: t('common.last_n_days', { n: 30 }) },
      { id: 'last7days' as const, label: t('common.last_n_days', { n: 7 }) },
    ],
    [t],
  );

  const activeCalculatedByOptions = React.useMemo(
    () => [
      { id: 'total' as const, label: t('common.total') },
      { id: 'shift' as const, label: t('common.by_shift') },
    ],
    [t],
  );

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const [activeCalculatedBy, setActiveCalculatedBy] = React.useState<
    IAutocompleteOption<'shift' | 'total'>
  >(activeCalculatedByOptions[1]);
  const [activeChartType, setActiveChartType] = React.useState<
    IAutocompleteOption<'ydt' | 'last30days' | 'last7days'>
  >(chartTypeOptions[0]);

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRDashboardEmployeeTurnover,
    {
      initialData: [],
    },
  );

  const ydt = getYDTStarEndDates({ suDaysFromEndDate: 1 });
  const last30Days = getLastNDaysStarEndDates(30);
  const last7Days = getLastNDaysStarEndDates(7);

  const categories = React.useMemo(() => {
    if (activeCalculatedBy.id === 'total') {
      return [data[0].siteName];
    }

    return data.map((item) => item.shiftName);
  }, [activeCalculatedBy.id, data]);

  const series = React.useMemo(() => {
    const netCommonData = {
      type: 'column' as const,
      name: 'Net',
    };
    if (activeCalculatedBy.id === 'total') {
      return [
        {
          ...netCommonData,
          data: [
            data.reduce(
              (total, item) =>
                (total += item.hiredTotalCount - item.terminatedTotalCount),
              0,
            ),
          ],
        },
      ];
    }

    return [
      {
        ...netCommonData,
        data: data.map(
          (item) => item.hiredTotalCount - item.terminatedTotalCount,
        ),
      },
    ];
  }, [activeCalculatedBy.id, data]);

  React.useEffect(() => {
    let startDate = ydt.startDateFormatted;
    let endDate = ydt.endDateFormatted;
    if (activeChartType.id === 'last30days') {
      startDate = last30Days.startDateFormatted;
      endDate = last30Days.endDateFormatted;
    }
    if (activeChartType.id === 'last7days') {
      startDate = last7Days.startDateFormatted;
      endDate = last7Days.endDateFormatted;
    }

    getData({
      startDate,
      endDate,
      siteId,
      shiftId,
    });
  }, [
    activeChartType,
    getData,
    siteId,
    shiftId,
    ydt.startDateFormatted,
    ydt.endDateFormatted,
    last30Days.startDateFormatted,
    last30Days.endDateFormatted,
    last7Days.startDateFormatted,
    last7Days.endDateFormatted,
  ]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('dashboard.net_employees')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`net_employees`)}
          />
          <DoubledCombobox>
            <LeftCombobox
              disableClearable
              value={activeCalculatedBy}
              options={activeCalculatedByOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveCalculatedBy(value);
                }
              }}
            />
            <ComboboxSeparator />
            <RightCombobox
              disableClearable
              value={activeChartType}
              options={chartTypeOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveChartType(value);
                }
              }}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <ColumnChart
          fullscreenTitle={t('dashboard.net_employees')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
          yAxis={{
            allowDecimals: false,
            title: {
              text: '',
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
