import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  ICreateBom,
  IdsArray,
  IFilter,
  IBomModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';
import { paths } from '../../config';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportBomRequest,
    saga: createExportSaga({
      apiCall: Api.Bom.export,
      actionFailed: actions.exportBomFailed,
      actionSuccess: actions.exportBomSuccess,
      actionNotification: actions.addProcessStatus,
    }),
  },
  {
    action: actions.createBomRequest,
    saga: function* ({
      payload: { data, navigate },
    }: ISagaAction<PayloadWithNavigateFunc<ICreateBom>>): SagaIterator {
      try {
        const response: IBomModel = yield call(Api.Bom.create, data);
        yield put(actions.flushBomState());
        yield put(actions.createBomSuccess(response));
        yield call(navigate, { pathname: paths.BOMS });
      } catch (e) {
        yield put(actions.createBomFailed());
      }
    },
  },
  {
    action: actions.getBomListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: IBomModel[] = yield call(Api.Bom.list, action.payload);
        yield put(actions.getBomListSuccess(response));
      } catch (e) {
        yield put(actions.getBomListFailed());
      }
    },
  },
  {
    action: actions.getBomCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.Bom.count,
          action?.payload,
        );
        yield put(actions.getBomCountSuccess(response));
      } catch (e) {
        yield put(actions.getBomCountFailed());
      }
    },
  },
  {
    action: actions.deleteBomRequest,
    saga: function* (action: ISagaAction<IdsArray>): SagaIterator {
      try {
        yield call(Api.Bom.delete, {
          where: { id: { inq: action.payload } },
        });
        yield put(actions.flushBomState());
      } catch (e) {
        yield put(actions.deleteBomFailed());
      }
    },
  },
  {
    action: actions.updateBomsRequest,
    saga: function* ({
      payload: { data, navigate },
    }: ISagaAction<PayloadWithNavigateFunc<IBomModel[]>>): SagaIterator {
      try {
        yield call(Api.Bom.bulkUpdate, data);
        yield put(actions.flushBomState());
        yield call(navigate, { pathname: paths.BOMS });
      } catch (e) {
        yield put(actions.updateBomsFailed());
      }
    },
  },
  {
    action: actions.getBomComboboxListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: Partial<IBomModel>[] = yield call(
          Api.Bom.list,
          action.payload,
        );
        yield put(actions.getBomComboboxListSuccess(response));
      } catch (e) {
        yield put(actions.getBomComboboxListFailed());
      }
    },
  },
];
