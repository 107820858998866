import React, { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getAdditionalFeeCategoryList } from '../../../../modules/selectors/additionalFee.selector';
import * as actions from '../../../../modules/actions';

import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';

interface IAdditionalFeeCategoryProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  selectedSiteId?: number;
}

const AdditionalFeeCategoriesComboBox: React.FC<IAdditionalFeeCategoryProps> = (
  props,
) => {
  const dispatch = useDispatch();
  const { list: categories } = useSelector(getAdditionalFeeCategoryList);

  // make request to fetch types from the server if we don't have them in the store
  useEffect(() => {
    if (isEmpty(categories)) {
      dispatch(actions.fetchAdditionalFeeCategoryListRequest({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = (useMemo(() => {
    if (!props.selectedSiteId) {
      return [];
    }

    return categories
      .filter((category) => category.siteId === props.selectedSiteId)
      .map(({ id, name }) => ({ id, name }));
  }, [categories, props.selectedSiteId]) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default AdditionalFeeCategoriesComboBox;
