import React from 'react';
import { DeleteConfirmation } from 'src/components/DeleteConfirmation';

interface IEnhancedTableDeleteEntitiesConfirmationProps {
  children: React.ReactNode;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const EnhancedTableDeleteEntitiesConfirmation = ({
  open,
  onOk,
  onCancel,
  children,
}: IEnhancedTableDeleteEntitiesConfirmationProps) => {
  return (
    <DeleteConfirmation open={open} onCancel={onCancel} onOk={onOk}>
      {children}
    </DeleteConfirmation>
  );
};
