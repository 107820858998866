import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ICommonFilterProps } from 'src/components/Table/components/TableActions/components/Filter/types';
import { TableContext } from 'src/components/Table/TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { useFetchTerminationReasonsCombobox } from 'src/modules/utils';
import {
  getTerminationReasonsComboboxList,
  getTerminationReasonsComboboxListWithNameAsId,
} from 'src/modules/selectors/terminationReason';

interface ITerminatedReasonsComboBoxFilterProps
  extends Omit<IComboboxProps, 'value' | 'options' | 'name'>,
    ICommonFilterProps {
  propertyAsID?: 'id' | 'name';
}

export const TerminatedReasonsComboBoxFilter: React.FC<ITerminatedReasonsComboBoxFilterProps> = ({
  value,
  name,
  propertyAsID = 'id',
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const fetchTerminatedReasonsCombobox = useFetchTerminationReasonsCombobox();

  const options = useSelector(
    propertyAsID === 'id'
      ? getTerminationReasonsComboboxList
      : getTerminationReasonsComboboxListWithNameAsId,
  );

  useEffect(() => {
    fetchTerminatedReasonsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueId = getFilterByName(name) ?? value;

  const comboBoxValue =
    options.find((option) => option.id === Number(valueId)) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={options}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
