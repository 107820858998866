import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { IPageSettingModel, IPageSettingState } from '../types';
import { getItemFromLocalStorage } from '../utils/localStorageWrapper';

const localData = getItemFromLocalStorage<IPageSettingModel>('pageSetting');

// default state
const defaultState: IPageSettingState = {
  id: NaN,
  config: {},
  manageEntitiesConfig: {},
  isPageSettingUpdateInProgress: false,
  ...localData,
};

/**
 * Get list actions
 */
const getPageSettingRequest = (state: IPageSettingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPageSettingSuccess = (
  state: IPageSettingState,
  payload: IPageSettingModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getPageSettingFailed = (state: IPageSettingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updatePageSettingRequest = (state: IPageSettingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isPageSettingUpdateInProgress: true,
});

const updatePageSettingSuccess = (
  state: IPageSettingState,
  payload: IPageSettingModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  isPageSettingUpdateInProgress: false,
});

const updatePageSettingFailed = (
  state: IPageSettingState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isPageSettingUpdateInProgress: false,
});

/**
 * Bom reducer
 */
export const pageSetting = createReducer<IPageSettingState>({}, defaultState)
  .on(actions.getPageSettingRequest, getPageSettingRequest)
  .on(actions.getPageSettingSuccess, getPageSettingSuccess)
  .on(actions.getPageSettingFailed, getPageSettingFailed)
  .on(actions.updatePageSettingRequest, updatePageSettingRequest)
  .on(actions.updatePageSettingSuccess, updatePageSettingSuccess)
  .on(actions.updatePageSettingFailed, updatePageSettingFailed);

export default pageSetting;
