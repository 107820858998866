import { ISagaActionBind } from '../../types';
import { ICardinalHealthDailyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthDailyUphBySiteAndSkuListRequest,
    saga: createListSaga<ICardinalHealthDailyReportModel>({
      apiCall: Api.CardinalHealthDailyUphBySiteAndSku.list,
      actionSuccess: actions.getCardinalHealthDailyUphBySiteAndSkuListSuccess,
      actionFailed: actions.getCardinalHealthDailyUphBySiteAndSkuListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthDailyUphBySiteAndSkuCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthDailyUphBySiteAndSku.count,
      actionSuccess: actions.getCardinalHealthDailyUphBySiteAndSkuCountSuccess,
      actionFailed: actions.getCardinalHealthDailyUphBySiteAndSkuCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthDailyUphBySiteAndSkuRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthDailyUphBySiteAndSku.export,
      actionSuccess: actions.exportCardinalHealthDailyUphBySiteAndSkuSuccess,
      actionFailed: actions.exportCardinalHealthDailyUphBySiteAndSkuFailed,
    }),
  },
];
