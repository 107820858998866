import React from 'react';
import { TablePagination } from '@mui/material';

interface IProps {
  page: number;
  rowsPerPage: number;
  count: number;
  onChangeRowsPerPage(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void;
  onChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void;
}

const PageSettingPagination: React.FC<IProps> = ({
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  count,
  page,
}) => {
  const rowsPerPageOptions = [5];

  return (
    <TablePagination
      showFirstButton
      showLastButton
      component="div"
      count={count}
      page={page}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default PageSettingPagination;
