import { Action, Store } from 'redux';

// It should be used when we need to dispatch redux action outside React component

let _store: Store | null = null;

export const initActionsDispatcher = (store: Store) => {
  _store = store;
};

export const dispatchAction = (action: Action) => {
  return _store?.dispatch(action);
};
