import React from 'react';
import { Sheet } from '@mui/joy';

interface IButtonGroupsContainerProps {
  children: React.ReactNode;
}

export const ButtonGroupsContainer = ({
  children,
}: IButtonGroupsContainerProps) => {
  return (
    <Sheet
      sx={{
        p: 1.5,
        borderRadius: 'md',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'colors.border.border_primary',
        bgcolor: 'colors.background.bg_secondary',
      }}
    >
      {children}
    </Sheet>
  );
};
