import React from 'react';
import { Box } from '@mui/material';

export const ComboboxSeparator = () => {
  return (
    <Box
      sx={{
        width: '1px',
        alignSelf: 'stretch',
        backgroundColor: '#E0E3E6',
      }}
    />
  );
};
