import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Typography } from '@mui/material';
import React from 'react';

export const TableInfoMessage: React.FC = ({ children }) => {
  return (
    <Paper
      elevation={0}
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '0 16px',
        padding: 8,
        borderColor: 'orange',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        icon={faExclamationCircle}
        color="orange"
        fontSize={22}
        style={{ float: 'left', marginRight: 8 }}
      />
      <Typography variant="h6">{children}</Typography>
    </Paper>
  );
};
