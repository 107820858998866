import React from 'react';
import { Typography, TypographyProps } from '@mui/joy';

export const TableTitle: React.FC<
  TypographyProps & {
    children: string;
  }
> = ({ children, ...restProps }) => {
  return (
    <Typography
      level="text_xl"
      fontSize="1.5rem"
      textColor="colors.text.text_primary.main"
      {...restProps}
    >
      {children}
    </Typography>
  );
};
