import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  IPointCollectionModel,
  IPointCollectionState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IPointCollectionState = {
  refreshKey: 0,
  id: NaN,
  numberOfPoints: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  isPointCollectionDataLoading: false,
  isPointCollectionCountDataLoading: false,
  isPointCollectionDataProcessing: false,
};

/**
 * Flush actions
 */
const flushPointCollectionState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createPointCollectionRequest = (state: IPointCollectionState) => ({
  ...state,
  error: false,
  isPointCollectionDataProcessing: true,
});

const createPointCollectionSuccess = (
  state: IPointCollectionState,
  payload: IPointCollectionModel,
) => ({
  ...state,
  ...payload,
  error: false,
  isPointCollectionDataProcessing: false,
});

const createPointCollectionFailed = (
  state: IPointCollectionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isPointCollectionDataProcessing: false,
});

/**
 * Get list actions
 */
const getPointCollectionListRequest = (state: IPointCollectionState) => ({
  ...state,
  error: false,
  isPointCollectionDataLoading: true,
});

const getPointCollectionListSuccess = (
  state: IPointCollectionState,
  payload: IPointCollectionModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  isPointCollectionDataLoading: false,
});

const getPointCollectionListFailed = (
  state: IPointCollectionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isPointCollectionDataLoading: false,
});

/**
 * Get count actions
 */
const getPointCollectionCountRequest = (state: IPointCollectionState) => ({
  ...state,
  error: false,
  isPointCollectionCountDataLoading: true,
});

const getPointCollectionCountSuccess = (
  state: IPointCollectionState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isPointCollectionCountDataLoading: false,
});

const getPointCollectionCountFailed = (
  state: IPointCollectionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isPointCollectionCountDataLoading: false,
});

/**
 * Deletion actions
 */
const deletePointCollectionRequest = (state: IPointCollectionState) => ({
  ...state,
  error: false,
  isPointCollectionDataProcessing: true,
});

const deletePointCollectionSuccess = (state: IPointCollectionState) => ({
  ...state,
  isPointCollectionDataProcessing: false,
});

const deletePointCollectionFailed = (
  state: IPointCollectionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isPointCollectionDataProcessing: false,
});

/**
 * Updating actions
 */
const updatePointCollectionsRequest = (state: IPointCollectionState) => ({
  ...state,
  error: false,
  isPointCollectionDataProcessing: true,
});

const updatePointCollectionsSuccess = (state: IPointCollectionState) => ({
  ...state,
  isPointCollectionDataProcessing: false,
});

const updatePointCollectionsFailed = (
  state: IPointCollectionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isPointCollectionDataProcessing: false,
});

/**
 * Get combobox list actions
 */
const getPointCollectionComboboxListRequest = (
  state: IPointCollectionState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPointCollectionComboboxListSuccess = (
  state: IPointCollectionState,
  payload: Partial<IPointCollectionModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getPointCollectionComboboxListFailed = (
  state: IPointCollectionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IPointCollectionState>();

const exportPointCollectionRequest = exportRequest;
const exportPointCollectionSuccess = exportSuccess;
const exportPointCollectionFailed = exportFailed;

/**
 * PointCollection reducer
 */
export const pointCollection = createReducer<IPointCollectionState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushPointCollectionState, flushPointCollectionState)
  // creation actions
  .on(actions.createPointCollectionRequest, createPointCollectionRequest)
  .on(actions.createPointCollectionSuccess, createPointCollectionSuccess)
  .on(actions.createPointCollectionFailed, createPointCollectionFailed)
  // get list actions
  .on(actions.getPointCollectionListRequest, getPointCollectionListRequest)
  .on(actions.getPointCollectionListSuccess, getPointCollectionListSuccess)
  .on(actions.getPointCollectionListFailed, getPointCollectionListFailed)
  // get count actions
  .on(actions.getPointCollectionCountRequest, getPointCollectionCountRequest)
  .on(actions.getPointCollectionCountSuccess, getPointCollectionCountSuccess)
  .on(actions.getPointCollectionCountFailed, getPointCollectionCountFailed)
  // deletion actions
  .on(actions.deletePointCollectionRequest, deletePointCollectionRequest)
  .on(actions.deletePointCollectionSuccess, deletePointCollectionSuccess)
  .on(actions.deletePointCollectionFailed, deletePointCollectionFailed)
  // updating actions
  .on(actions.updatePointCollectionsRequest, updatePointCollectionsRequest)
  .on(actions.updatePointCollectionsSuccess, updatePointCollectionsSuccess)
  .on(actions.updatePointCollectionsFailed, updatePointCollectionsFailed)
  // get combobox list actions
  .on(
    actions.getPointCollectionComboboxListRequest,
    getPointCollectionComboboxListRequest,
  )
  .on(
    actions.getPointCollectionComboboxListSuccess,
    getPointCollectionComboboxListSuccess,
  )
  .on(
    actions.getPointCollectionComboboxListFailed,
    getPointCollectionComboboxListFailed,
  )
  // export actions
  .on(actions.exportPointCollectionRequest, exportPointCollectionRequest)
  .on(actions.exportPointCollectionSuccess, exportPointCollectionSuccess)
  .on(actions.exportPointCollectionFailed, exportPointCollectionFailed);

export default pointCollection;
