import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from '@mui/joy';
import { useFormik } from 'formik';
import { createProductionRequest } from '../../modules/actions';
import {
  useBrowserHistoryFunctions,
  useFetchBomsCombobox,
  useFetchEmployeesComboboxWithBadgeAsId,
  useFetchShiftsCombobox,
  useProductionSettings,
  useValidate,
  getProductionWithMetatags,
  useProductionSettingsForm,
} from '../../modules/utils';
import { getCurrentRole } from '../../modules/selectors/auth';
import { createProductionSchemeArray } from '../../modules/schemes';
import { getBomsComboboxList } from '../../modules/selectors/bom.selector';
import {
  ICreateProduction,
  MetatagColumn,
  ProductionSettingConfig,
} from '../../modules/types';
import { getEmployeesComboboxList } from '../../modules/selectors/employee';
import { getShiftOptionsByGloballySites } from '../../modules/selectors/shift';
import { ComboBoxOption } from '../../components/ComboBox';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';
import FormikDatepicker from '../../components/Formik/FormikDatepicker';
import FormikDatetimepicker from '../../components/Formik/FormikDatetimepicker';
import FormikMultipleSelectWithSearch, {
  MultipleSelectOption,
} from '../../components/Formik/FormikMultipleSelectWithSearch';
import { ContentLayout } from '../../components/Layout/ContentLayout';
import { Box, Grid, IconButton } from '@mui/material';
import { MUI_SIZE_12 } from '../../config';
import { Delete } from '@mui/icons-material';
import ProductionMetatagsFields from './ProductionMetatagsFields';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerHeader,
} from '../../components/_ui-kit/Drawer';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';

interface ICreateProductionsProps {
  metatagColumns: MetatagColumn[];
  siteId: number;
  fields: ProductionSettingConfig['fields'];
  metatags: ProductionSettingConfig['metatags'];
}

const ProductionCreateForm: React.FC<ICreateProductionsProps> = ({
  metatagColumns,
  fields,
  metatags,
  siteId,
}) => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = React.useState({
    siteId,
    date: '',
    qty: null,
    container: '',
    lineOrDoor: '',
    comment: '',
    startTime: '',
    endTime: '',
    shiftId: NaN,
    employees: [],
  });

  const dispatcher = useDispatch();

  const scheme = getProductionWithMetatags(
    metatags,
    createProductionSchemeArray,
  );
  const validate = useValidate(scheme, { allowUnionTypes: true });

  React.useEffect(() => {
    setInitialValues((prev) => ({ ...prev, siteId }));
  }, [siteId]);

  React.useEffect(() => {
    if (!metatags.length) {
      return;
    }

    const metatagsInitialValues = {};
    for (const i in metatags) {
      if (metatags[i].type === 'checkbox') {
        metatagsInitialValues[metatags[i].name] = false;
      } else {
        metatagsInitialValues[metatags[i].name] = '';
      }
    }

    setInitialValues((prev) => ({ ...prev, ...metatagsInitialValues }));
  }, [metatagColumns, metatags]);

  const formik = useFormik<Array<ICreateProduction>>({
    initialValues: [{ ...initialValues }],
    validate,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => {
      dispatcher(createProductionRequest({ data, navigate }));
    },
  });

  const fetchBomsCombobox = useFetchBomsCombobox();
  const fetchEmployeesCombobox = useFetchEmployeesComboboxWithBadgeAsId();
  const fetchShiftsCombobox = useFetchShiftsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  React.useEffect(() => {
    fetchBomsCombobox();
    fetchEmployeesCombobox();
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const comboboxes = {
    boms: useSelector(getBomsComboboxList),
    employees: useSelector(getEmployeesComboboxList),
    shifts: useSelector(getShiftOptionsByGloballySites, shallowEqual),
  };

  const boms = React.useMemo(
    () => filter(comboboxes.boms, (bom) => bom.siteId === siteId),
    [comboboxes.boms, siteId],
  );
  const employees = React.useMemo(
    () =>
      filter(
        comboboxes.employees,
        (employee) => employee.entity.siteId === siteId,
      ),
    [comboboxes.employees, siteId],
  );
  const shifts = React.useMemo(
    () => filter(comboboxes.shifts, (shift) => shift.siteId === siteId),
    [comboboxes.shifts, siteId],
  );

  const shouldRender = React.useCallback((name) => fields[name].enabled, [
    fields,
  ]);

  const createDuplicateFormDataHandler = (index: number) => () => {
    formik.setValues(
      formik.values.reduce<Array<ICreateProduction>>((acc, value, i) => {
        if (i === index) {
          acc.push(...[value, value]);
        } else {
          acc.push(value);
        }

        return acc;
      }, []),
    );
  };

  const createRemoveFormDataHandler = (index: number) => () => {
    formik.setValues(formik.values.filter((_, i) => i !== index));
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {formik.values.map((_, index) => (
        <Box key={`pr_${index}`} sx={{ mb: 2 }}>
          <FormFieldContainer>
            <Box display="flex" justifyContent="space-between">
              <Button onClick={createDuplicateFormDataHandler(index)}>
                {t('common.duplicate')}
              </Button>
              {formik.values.length > 1 && (
                <IconButton onClick={createRemoveFormDataHandler(index)}>
                  <Delete />
                </IconButton>
              )}
            </Box>
          </FormFieldContainer>
          <Box>
            {shouldRender('employee') && (
              <FormFieldContainer>
                <FormikMultipleSelectWithSearch
                  id={`${index}.employees`}
                  title={fields.employee.title}
                  formik={formik}
                  fullWidth={true}
                  options={(employees as unknown) as MultipleSelectOption[]}
                />
              </FormFieldContainer>
            )}
            {shouldRender('shift') && (
              <FormFieldContainer>
                <FormikCombobox
                  id={`${index}.shiftId`}
                  placeholder={fields.shift.title}
                  options={shifts as ComboBoxOption[]}
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('bom') && (
              <FormFieldContainer>
                <FormikCombobox
                  id={`${index}.bomId`}
                  placeholder={fields.bom.title}
                  options={boms as ComboBoxOption[]}
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('date') && (
              <FormFieldContainer>
                <FormikDatepicker
                  fullWidth
                  label={fields.date.title}
                  id={`${index}.date`}
                  formik={formik}
                />
              </FormFieldContainer>
            )}

            <ProductionMetatagsFields
              metatags={metatags}
              index={index}
              formik={formik}
            />

            {shouldRender('qty') && (
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.qty`}
                  label={fields.qty.title}
                  name={`${index}.qty`}
                  type="number"
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('container') && (
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.container`}
                  label={fields.container.title}
                  name={`${index}.container`}
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('isPartialContainer') && (
              <FormFieldContainer>
                <FormikCheckbox
                  id={`${index}.isPartialContainer`}
                  label={fields.isPartialContainer.title}
                  name={`${index}.isPartialContainer`}
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('lineOrDoor') && (
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.lineOrDoor`}
                  label={fields.lineOrDoor.title}
                  name={`${index}.lineOrDoor`}
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('comment') && (
              <FormFieldContainer>
                <FormikTextarea
                  variant="outlined"
                  id={`${index}.comment`}
                  label={fields.comment.title}
                  name={`${index}.comment`}
                  formik={formik}
                />
              </FormFieldContainer>
            )}
            {shouldRender('startTime') && (
              <FormFieldContainer>
                <FormikDatetimepicker
                  fullWidth
                  id={`${index}.startTime`}
                  formik={formik}
                  label={fields.startTime.title}
                />
              </FormFieldContainer>
            )}
            {shouldRender('endTime') && (
              <FormFieldContainer>
                <FormikDatetimepicker
                  fullWidth
                  id={`${index}.endTime`}
                  formik={formik}
                  label={fields.endTime.title}
                />
              </FormFieldContainer>
            )}
          </Box>
        </Box>
      ))}

      <Box style={{ alignSelf: 'flex-end' }}>
        <FormActions submitBtnTitle={t('common.create')} />
      </Box>
    </form>
  );
};

interface ICreateProductionProps {
  isOpen: boolean;
  onClose: () => void;
  siteId: number;
}

export const ProductionsCreate: React.FC<ICreateProductionProps> = ({
  isOpen,
  onClose,
  siteId,
}) => {
  const { t } = useTranslation();
  const { config, error } = useProductionSettings(siteId);
  const { fields, metatags } = useProductionSettingsForm(siteId);

  if (error) {
    return (
      <ContentLayout>
        <Grid container={true}>
          <Grid item={true} xs={MUI_SIZE_12}>
            <Alert variant="soft" security="error">
              {t('error.not_chosen_policy')}
            </Alert>
          </Grid>
        </Grid>
      </ContentLayout>
    );
  }

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('productions.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent component="div">
          <ProductionCreateForm
            metatagColumns={config.metatagColumns}
            fields={fields}
            metatags={metatags}
            siteId={siteId}
          />
        </DrawerBodyContent>
      </DrawerBody>
    </Drawer>
  );
};
