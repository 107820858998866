import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { IFinancialTrackerCreate } from '../../types/financial-tracker';
import { createFinancialTrackerScheme } from './create-financial-tracker.scheme';

export const updateFinancialTrackerScheme: JSONSchemaType<
  IFinancialTrackerCreate[]
> = {
  type: 'array',
  items: {
    ...createFinancialTrackerScheme,
    properties: {
      ...createFinancialTrackerScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: i18n.t('error.validation.required'),
      },
    },
  },
};
