import React from 'react';
import { Sheet } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { ModalDrillDown } from 'src/components/_ui-kit/ModalDrillDown';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import {
  AnyObject,
  ICountResponse,
  IRecruitingHistoricalReport,
} from 'src/modules/types';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import {
  TableActions,
  TableContextProvider,
  TableToolbar,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableWithActions } from 'src/components/TableWithActions';
import {
  composeDate,
  getISOYear,
  getWeekNumber,
  sub,
} from 'src/modules/utils/dateWrapper';

interface IHistoricalRecruitingProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  siteId?: number;
}

const incentivePaymentOrderDetails = {
  order: 'desc' as const,
  orderBy: 'date',
};

const url = '/recruiting-dashboard/recruiting/historical';

export const HistoricalRecruiting: React.FC<IHistoricalRecruitingProps> = (
  props,
) => {
  const { t } = useTranslation();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    Array<IRecruitingHistoricalReport>
  >(url, []);

  const year13WeeksAgo = React.useMemo(
    () => composeDate(new Date(), sub({ weeks: 13 }), getISOYear()),
    [],
  );

  const week13WeeksAgo = React.useMemo(
    () => composeDate(new Date(), sub({ weeks: 13 }), getWeekNumber()),
    [],
  );

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${url}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('recruiting_dashboards_report.siteName'),
      },
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('recruiting_dashboards_report.date'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('recruiting_dashboards_report.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('recruiting_dashboards_report.week'),
      },
      {
        id: 'desiredHeadCount',
        orderConfig: { orderBy: 'desiredHeadCount' },
        label: t('recruiting_dashboards_report.desiredHeadCount'),
      },
      {
        id: 'absenteeism',
        orderConfig: { orderBy: 'absenteeism' },
        label: t('recruiting_dashboards_report.absenteeism'),
      },
      {
        id: 'optimalHeadCount',
        orderConfig: { orderBy: 'optimalHeadCount' },
        label: t('recruiting_dashboards_report.optimalHeadCount'),
      },
      {
        id: 'countOfActiveEmployees',
        orderConfig: { orderBy: 'countOfActiveEmployees' },
        label: t('recruiting_dashboards_report.countOfActiveEmployees'),
      },
      {
        id: 'openOrders',
        orderConfig: { orderBy: 'openOrders' },
        label: t('recruiting_dashboards_report.openOrders'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: incentivePaymentOrderDetails.order,
    defaultOrderBy: incentivePaymentOrderDetails.orderBy,
  });

  const filterFieldsConfiguration = React.useMemo(() => {
    return {
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
      year: {
        value: '',
        property: 'year',
        operator: 'eq' as const,
      },
    };
  }, []);

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    clearFilterFieldsData,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({ filterFieldsConfiguration });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const handleClose = () => {
    clearFilterFieldsData();
    props.onClose();
  };

  const _filter = React.useMemo(
    () => ({
      filter: {
        where: {
          ...filter.where,
          week: (filter?.where as AnyObject)?.week || {
            gte: week13WeeksAgo,
          },
          year: (filter?.where as AnyObject)?.year || {
            gte: year13WeeksAgo,
          },
          siteId: props.siteId,
        },
      },
    }),
    [filter.where, props.siteId, week13WeeksAgo, year13WeeksAgo],
  );

  React.useEffect(() => {
    if (props?.siteId) {
      fetchData(_filter);
      fetchCountData(_filter);
    }
  }, [props?.siteId, fetchCountData, fetchData, _filter]);

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where: filter.where,
    fetchData,
  });

  return (
    <ModalDrillDown
      title={props.title}
      open={props.isOpen}
      onClose={handleClose}
    >
      <Sheet
        sx={{
          paddingX: '40px',
        }}
      >
        {isDataLoading && isCountDataLoading && <MainContentLoader />}

        <TableContextProvider>
          <TableToolbar sx={{ justifyContent: 'flex-end' }}>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'week',
                      labelPrefix: t('recruiting_dashboards_report.week'),
                    })}
                    {...getFilterCommonPropsByFilterName('week')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'year',
                      labelPrefix: t('recruiting_dashboards_report.year'),
                    })}
                    {...getFilterCommonPropsByFilterName('year')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />
            </TableActions>
          </TableToolbar>

          <TableWithActions
            heightWithTabs
            selectedIndex="id"
            data={data as any}
            count={countData.count}
            isDataLoading={isDataLoading || isCountDataLoading}
            tableUniqueKey={url}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            limit={limit}
            page={page}
            onSort={handleSort}
            selectedItems={[]}
            onRowCheckboxClick={() => ({})}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(page) => handlePageChange(page)}
          />
        </TableContextProvider>
      </Sheet>
    </ModalDrillDown>
  );
};
