import React from 'react';
import { Box } from '@mui/joy';

interface IReportPageItemContainerProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}

export const ReportPageItemContainer = ({
  children,
  fullHeight,
}: IReportPageItemContainerProps) => {
  return (
    <Box
      id="ReportPageItemContainer"
      sx={{ flexGrow: fullHeight ? 1 : 0, pb: 1, '&:last-child': { pb: 0 } }}
    >
      {children}
    </Box>
  );
};
