import { memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  IStoreState,
  IUpdateQuestionRequest,
  QuestionComboboxOption,
} from '../types';

export const getQuestionRefreshKey = (state: IStoreState) =>
  state.question.refreshKey;

export const getQuestionData = (state: IStoreState) => state.question;

export const getQuestionList = createSelector(
  getQuestionData,
  (data) => data.list,
);

export const getQuestionCount = createSelector(
  getQuestionData,
  (data) => data.count,
);

export const getQuestionsComboboxList = createSelector(
  getQuestionData,
  (data) => data.comboboxList as QuestionComboboxOption[],
);

export const getQuestionsByIds = createSelector(getQuestionList, (questions) =>
  memoize((ids: IdsArray) => {
    const initial: IUpdateQuestionRequest[] = [];
    return reduce(
      questions,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          // omit columns we should not request with
          const question = omit(cur, ['questionCategory']);
          acc.push(question);
        }
        return acc;
      },
      initial,
    );
  }),
);

export const getQuestionListForFillInOverdueForm = createSelector(
  getQuestionList,
  (list) =>
    list.map((item) => ({
      ...item,
      value: null,
    })),
);

export const getIsQuestionsDataLoading = createSelector(
  getQuestionData,
  ({ is_data_requested }) => is_data_requested,
);
