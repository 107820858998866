import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  IInvoiceDetails,
  IInvoiceModel,
  IInvoiceModelWithRelations,
  IInvoiceState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IInvoiceState = {
  list: [],
  invoiceDetails: null,
  refreshKey: 0,
  count: 0,
  monthCount: 0,
  monthList: [],
  is_weekly_invoices_list_loading: true,
  is_weekly_invoices_count_loading: true,
  is_monthly_invoices_list_loading: true,
  is_monthly_invoices_count_loading: true,
  is_weekly_invoices_managing: false,
  is_monthly_invoices_managing: false,
};

/**
 * Flush actions
 */
const flushInvoiceState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: true,
});

const createInvoiceSuccess = (
  state: IInvoiceState,
  payload: IInvoiceModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_weekly_invoices_managing: false,
});

const createInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});

const createMonthInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_monthly_invoices_managing: true,
});

const createMonthInvoiceSuccess = (
  state: IInvoiceState,
  payload: Array<IInvoiceModel>,
) => ({
  ...state,
  ...payload,
  error: false,
  is_monthly_invoices_managing: false,
});

const createMonthInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_monthly_invoices_managing: false,
});

/**
 * Get list actions
 */
const getInvoiceListRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_weekly_invoices_list_loading: true,
});

const getInvoiceListSuccess = (
  state: IInvoiceState,
  payload: IInvoiceModelWithRelations[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
  is_weekly_invoices_list_loading: false,
});

const getInvoiceListFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
  is_weekly_invoices_list_loading: false,
});

const getMonthInvoiceListRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_monthly_invoices_list_loading: true,
});

const getMonthInvoiceListSuccess = (
  state: IInvoiceState,
  payload: IInvoiceModelWithRelations[],
) => ({
  ...state,
  monthList: payload,
  error: false,
  is_data_requested: false,
  is_monthly_invoices_list_loading: false,
});

const getMonthInvoiceListFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
  is_monthly_invoices_list_loading: false,
});

const getInvoiceByIdRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getInvoiceByIdSuccess = (
  state: IInvoiceState,
  payload: IInvoiceDetails,
) => ({
  ...state,
  invoiceDetails: payload,
  error: false,
  is_data_requested: false,
});

/**
 * Freeze actions
 */
const freezeInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: true,
});

const freezeInvoiceSuccess = (state: IInvoiceState, payload: number) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: false,
  list: state.list.map((item) => ({
    ...item,
    isUnchangeable: item.id === payload ? true : item.isUnchangeable,
  })),
});
const freezeInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});
const freezeMonthInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const freezeMonthInvoiceSuccess = (state: IInvoiceState, payload: string) => ({
  ...state,
  error: false,
  is_data_requested: false,
  monthList: state.monthList.map((item) => ({
    ...item,
    isUnchangeable:
      item.monthInvoiceId === payload ? true : item.isUnchangeable,
  })),
});
const freezeMonthInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Unfreeze actions
 */
const unfreezeInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: true,
});

const unfreezeInvoiceSuccess = (state: IInvoiceState, payload: number) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: false,
  list: state.list.map((item) => ({
    ...item,
    isUnchangeable: item.id === payload ? false : item.isUnchangeable,
  })),
});

const unfreezeInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});

const unfreezeMonthInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const unfreezeMonthInvoiceSuccess = (
  state: IInvoiceState,
  payload: string,
) => ({
  ...state,
  error: false,
  is_data_requested: false,
  monthList: state.monthList.map((item) => ({
    ...item,
    isUnchangeable:
      item.monthInvoiceId === payload ? false : item.isUnchangeable,
  })),
});

const unfreezeMonthInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getInvoiceCountRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_weekly_invoices_count_loading: true,
});

const getInvoiceCountSuccess = (
  state: IInvoiceState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  is_weekly_invoices_count_loading: false,
});

const getInvoiceCountFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
  is_weekly_invoices_count_loading: false,
});

const getMonthInvoiceCountRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_monthly_invoices_count_loading: true,
});

const getMonthInvoiceCountSuccess = (
  state: IInvoiceState,
  payload: ICountResponse,
) => ({
  ...state,
  monthCount: payload.count,
  error: false,
  is_data_requested: false,
  is_monthly_invoices_count_loading: false,
});

const getMonthInvoiceCountFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
  is_monthly_invoices_count_loading: false,
});

/**
 * Deletion actions
 */
const deleteInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: true,
});

const deleteInvoiceSuccess = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});

const deleteInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});

const deleteMonthInvoiceRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_monthly_invoices_managing: true,
});

const deleteMonthInvoiceSuccess = (state: IInvoiceState) => ({
  ...state,
  is_monthly_invoices_managing: false,
});

const deleteMonthInvoiceFailed = (state: IInvoiceState) => ({
  ...state,
  is_monthly_invoices_managing: false,
});

/**
 * Updating actions
 */
const updateInvoicesRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_weekly_invoices_managing: true,
});

const updateInvoicesSuccess = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});

const updateInvoicesFailed = (state: IInvoiceState) => ({
  ...state,
  is_weekly_invoices_managing: false,
});

const updateMonthInvoicesRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_monthly_invoices_managing: true,
});

const updateMonthInvoicesSuccess = (state: IInvoiceState) => ({
  ...state,
  is_monthly_invoices_managing: false,
});

const updateMonthInvoicesFailed = (state: IInvoiceState) => ({
  ...state,
  is_monthly_invoices_managing: false,
});

const exportInvoiceDetailsRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const exportInvoiceDetailsSuccess = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const exportInvoiceDetailsFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
});

const exportMonthInvoiceDetailsRequest = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const exportMonthInvoiceDetailsSuccess = (state: IInvoiceState) => ({
  ...state,
  error: false,
  is_data_requested: false,
});

const exportMonthInvoiceDetailsFailed = (state: IInvoiceState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IInvoiceState>();

const {
  exportRequest: exportMonthRequest,
  exportFailed: exportMonthFailed,
  exportSuccess: exportMonthSuccess,
} = new ExportReducerCreator().takeHandlers<IInvoiceState>();

const exportInvoiceRequest = exportRequest;
const exportInvoiceSuccess = exportSuccess;
const exportInvoiceFailed = exportFailed;

const exportMonthInvoiceRequest = exportMonthRequest;
const exportMonthInvoiceSuccess = exportMonthSuccess;
const exportMonthInvoiceFailed = exportMonthFailed;

/**
 * Invoice reducer
 */
export const invoice = createReducer<IInvoiceState>({}, defaultState)
  // flush actions
  .on(actions.flushInvoiceState, flushInvoiceState)
  // creation actions
  .on(actions.createInvoiceRequest, createInvoiceRequest)
  .on(actions.createInvoiceSuccess, createInvoiceSuccess)
  .on(actions.createInvoiceFailed, createInvoiceFailed)
  .on(actions.createMonthInvoiceRequest, createMonthInvoiceRequest)
  .on(actions.createMonthInvoiceSuccess, createMonthInvoiceSuccess)
  .on(actions.createMonthInvoiceFailed, createMonthInvoiceFailed)
  // get list actions
  .on(actions.getInvoiceListRequest, getInvoiceListRequest)
  .on(actions.getInvoiceListSuccess, getInvoiceListSuccess)
  .on(actions.getInvoiceListFailed, getInvoiceListFailed)
  // get month list actions
  .on(actions.getMonthInvoiceListRequest, getMonthInvoiceListRequest)
  .on(actions.getMonthInvoiceListSuccess, getMonthInvoiceListSuccess)
  .on(actions.getMonthInvoiceListFailed, getMonthInvoiceListFailed)

  .on(actions.getInvoiceByIdRequest, getInvoiceByIdRequest)
  .on(actions.getInvoiceByIdSuccess, getInvoiceByIdSuccess)
  .on(actions.getInvoiceByIdFailed, getInvoiceListFailed)
  // freeze actions
  .on(actions.freezeInvoiceRequest, freezeInvoiceRequest)
  .on(actions.freezeInvoiceSuccess, freezeInvoiceSuccess)
  .on(actions.freezeInvoiceFailed, freezeInvoiceFailed)
  .on(actions.freezeMonthInvoiceRequest, freezeMonthInvoiceRequest)
  .on(actions.freezeMonthInvoiceSuccess, freezeMonthInvoiceSuccess)
  .on(actions.freezeMonthInvoiceFailed, freezeMonthInvoiceFailed)
  // unfreeze actions
  .on(actions.unfreezeInvoiceRequest, unfreezeInvoiceRequest)
  .on(actions.unfreezeInvoiceSuccess, unfreezeInvoiceSuccess)
  .on(actions.unfreezeInvoiceFailed, unfreezeInvoiceFailed)
  .on(actions.unfreezeMonthInvoiceRequest, unfreezeMonthInvoiceRequest)
  .on(actions.unfreezeMonthInvoiceSuccess, unfreezeMonthInvoiceSuccess)
  .on(actions.unfreezeMonthInvoiceFailed, unfreezeMonthInvoiceFailed)
  // get count actions
  .on(actions.getInvoiceCountRequest, getInvoiceCountRequest)
  .on(actions.getInvoiceCountSuccess, getInvoiceCountSuccess)
  .on(actions.getInvoiceCountFailed, getInvoiceCountFailed)
  // get month count actions
  .on(actions.getMonthInvoiceCountRequest, getMonthInvoiceCountRequest)
  .on(actions.getMonthInvoiceCountSuccess, getMonthInvoiceCountSuccess)
  .on(actions.getMonthInvoiceCountFailed, getMonthInvoiceCountFailed)
  // deletion actions
  .on(actions.deleteInvoiceRequest, deleteInvoiceRequest)
  .on(actions.deleteInvoiceSuccess, deleteInvoiceSuccess)
  .on(actions.deleteInvoiceFailed, deleteInvoiceFailed)
  .on(actions.deleteMonthInvoiceRequest, deleteMonthInvoiceRequest)
  .on(actions.deleteMonthInvoiceSuccess, deleteMonthInvoiceSuccess)
  .on(actions.deleteMonthInvoiceFailed, deleteMonthInvoiceFailed)
  // updating actions
  .on(actions.updateInvoicesRequest, updateInvoicesRequest)
  .on(actions.updateInvoicesSuccess, updateInvoicesSuccess)
  .on(actions.updateInvoicesFailed, updateInvoicesFailed)
  .on(actions.updateMonthInvoicesRequest, updateMonthInvoicesRequest)
  .on(actions.updateMonthInvoicesSuccess, updateMonthInvoicesSuccess)
  .on(actions.updateMonthInvoicesFailed, updateMonthInvoicesFailed)
  // export actions
  .on(actions.exportInvoiceDetailsRequest, exportInvoiceDetailsRequest)
  .on(actions.exportInvoiceDetailsSuccess, exportInvoiceDetailsSuccess)
  .on(actions.exportInvoiceDetailsFailed, exportInvoiceDetailsFailed)

  .on(
    actions.exportMonthInvoiceDetailsRequest,
    exportMonthInvoiceDetailsRequest,
  )
  .on(
    actions.exportMonthInvoiceDetailsSuccess,
    exportMonthInvoiceDetailsSuccess,
  )
  .on(actions.exportMonthInvoiceDetailsFailed, exportMonthInvoiceDetailsFailed)

  .on(actions.exportInvoiceRequest, exportInvoiceRequest)
  .on(actions.exportInvoiceSuccess, exportInvoiceSuccess)
  .on(actions.exportInvoiceFailed, exportInvoiceFailed)

  .on(actions.exportMonthInvoiceRequest, exportMonthInvoiceRequest)
  .on(actions.exportMonthInvoiceSuccess, exportMonthInvoiceSuccess)
  .on(actions.exportMonthInvoiceFailed, exportMonthInvoiceFailed);

export default invoice;
