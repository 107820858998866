import React from 'react';
import { FinancialDashboardRevenueYDTPercentageChart } from './FinancialDashboardRevenueYDTPercentageChart';
import { FinancialDashboardTotalsChart } from './FinancialDashboardTotalsChart';
import {
  ChartsDashboardBody,
  ChartsDashboard,
  ChartsDashboardChart,
  ChartsDashboardChartsContainer,
} from 'src/components/ChartsDashboard';
import { FinancialDashboardQuarterlyChart } from './FinancialDashboardQuarterlyChart';
import { FinancialDashboardYDTByCustomerChart } from './FinancialDashboardYDTByCustomerChart';

export const FinancialDashboard = () => {
  return (
    <ChartsDashboard>
      <ChartsDashboardBody>
        <ChartsDashboardChartsContainer>
          <ChartsDashboardChart>
            <FinancialDashboardRevenueYDTPercentageChart />
          </ChartsDashboardChart>
        </ChartsDashboardChartsContainer>
        <ChartsDashboardChartsContainer>
          <ChartsDashboardChart>
            <FinancialDashboardTotalsChart />
          </ChartsDashboardChart>
          <ChartsDashboardChart>
            <FinancialDashboardQuarterlyChart />
          </ChartsDashboardChart>
          <ChartsDashboardChart>
            <FinancialDashboardYDTByCustomerChart />
          </ChartsDashboardChart>
        </ChartsDashboardChartsContainer>
      </ChartsDashboardBody>
    </ChartsDashboard>
  );
};
