import { IUpdatePointType } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { createPointTypeScheme } from './create-point-type.scheme';

export const updatePointTypesScheme: PartialSchema<IUpdatePointType[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createPointTypeScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createPointTypeScheme.properties,
    },
  },
};
