import {
  differenceWith,
  forEach,
  get,
  has,
  isEmpty,
  isEqual,
  isMatch,
} from 'lodash';
import React from 'react';
import { roles, tabPrefix } from 'src/config';
import {
  IMenuItem,
  IUserPolicy,
  PolicyConfig,
  PolicyConfigItem,
} from 'src/modules/types';

export const useSideBarMainMenuHelpers = () => {
  const filterMenuItems = React.useCallback(
    (
      userRoles: string[],
      userPolicies: IUserPolicy['policies'],
      subitems: IMenuItem[],
      acc: IMenuItem[] = [],
      allPolicies: PolicyConfig,
    ) => {
      if (
        (!isEmpty(userRoles) && !isEmpty(userPolicies)) ||
        (userRoles && userRoles.includes(roles.SUPER_ADMIN))
      ) {
        forEach(subitems, (menuItem, index) => {
          const currentPolicy = get(allPolicies, [menuItem.url as string], []);

          if (has(menuItem, ['subitems'])) {
            acc[index] = acc[index] || {
              ...menuItem,
              subitems: [],
            };
            filterMenuItems(
              userRoles,
              userPolicies,
              menuItem.subitems!,
              acc[index].subitems,
              allPolicies,
            );
            if (isEmpty(Object.values(acc[index].subitems ?? []))) {
              acc.splice(index, 1);
            }
          } else {
            if (
              // push the menu item if the current user is admin or if the menu does not have url
              // which might mean it's a top level menu item
              (userRoles && userRoles.includes(roles.SUPER_ADMIN)) ||
              !has(menuItem, ['url'])
            ) {
              acc[index] = menuItem;
            } else if (menuItem.type === 'reports' && menuItem.url) {
              // All reports per client available under single menu item
              // as a result we show menu item if user has access at least one report,
              // access to other reports will be handled on report page dropdowns side.
              // Also page with tabs has the same conditions
              const isSomeReportedAllowedToView = userPolicies.some((policy) =>
                currentPolicy.some((requiredPolicy) =>
                  isEqual(policy, requiredPolicy),
                ),
              );
              if (isSomeReportedAllowedToView) {
                acc[index] = menuItem;
              }
            } else if (menuItem.pageType === 'withTabs') {
              // For pages with tabs in dynamic page config we will have shallow paths
              // with "/tab" prefix. To render this menu item we need to check that user has
              // access to at least one tab
              const tabsRequiredPolices = Object.entries(allPolicies).reduce<
                Array<Array<PolicyConfigItem>>
              >((acc, [path, requiredPolicies]) => {
                if (path.startsWith(`${menuItem.url}${tabPrefix}`)) {
                  acc.push(requiredPolicies);
                }

                return acc;
              }, []);

              const hasUserRequiredPoliciesAtLeastForOneTab = tabsRequiredPolices.some(
                (tabPolicies) =>
                  tabPolicies.every((tabPolicy) =>
                    userPolicies.some((userPolicy) =>
                      isMatch(tabPolicy, userPolicy),
                    ),
                  ),
              );

              if (hasUserRequiredPoliciesAtLeastForOneTab) {
                acc[index] = menuItem;
              }
            } else {
              const foundPolicies = differenceWith(
                currentPolicy,
                userPolicies!,
                isMatch,
              );

              if (!isEmpty(currentPolicy) && isEmpty(foundPolicies)) {
                acc[index] = menuItem;
              }
            }
          }
        });
      }
    },
    [],
  );

  const filterBySearchTerm = React.useCallback(
    (searchTerm: string, menuItems: IMenuItem[]) => {
      const normalisedPattern = searchTerm.replaceAll(/\W/g, '');

      return menuItems.reduce<IMenuItem[]>((filtered, menuItem) => {
        const regex = new RegExp(normalisedPattern, 'i');

        if (regex.test(menuItem.searchBy.replaceAll(' ', '') ?? '')) {
          filtered.push(menuItem);
          return filtered;
        }

        if (menuItem.subitems) {
          const filteredSubItems = filterBySearchTerm(
            normalisedPattern,
            menuItem.subitems,
          );

          if (filteredSubItems.length) {
            filtered.push({
              ...menuItem,
              subitems: filteredSubItems,
            });
          }
        }

        return filtered;
      }, []);
    },
    [],
  );

  return {
    filterMenuItems,
    filterBySearchTerm,
  };
};
