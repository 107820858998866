import React from 'react';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';

export const Copyright = () => {
  const { t } = useTranslation();

  return (
    <Typography level="text_xs" textColor="gray.600">
      {t('common.copyright', { year: new Date().getFullYear() })}
    </Typography>
  );
};
