import { isEmpty } from 'lodash';

import { getResourcesRequest } from '../../actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getResourcesList } from '../../selectors/resources.selector';

export const useFetchResources = () => {
  const dispatcher = useDispatch();
  const list = useSelector(getResourcesList, shallowEqual);

  return () => {
    if (isEmpty(list)) {
      dispatcher(getResourcesRequest());
    }
  };
};
