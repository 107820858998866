import { JSONSchemaType } from 'ajv';

// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { IAdditionalFeeCategory } from '../../types';

export const createAdditionalFeeCategoryScheme: JSONSchemaType<IAdditionalFeeCategory> = {
  type: 'object',
  required: ['name', 'siteId'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      type: 'number',
      isNotEmpty: true,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    description: {
      nullable: true,
      type: 'string',
    },
  },
};
