import React from 'react';
import { useTranslation } from 'react-i18next';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import Api from '../Api';

export const useIssuesFromIssueTrackerHeadCellsConfig = (): IHeadCellWithOrderConfig[] => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return [
      {
        id: 'created',
        label: t('issue_tracker.fields.created'),
        orderConfig: { orderBy: 'created' },
      },
      {
        id: 'summary',
        label: t('issue_tracker.fields.subject'),
      },
      {
        id: 'description',
        label: t('issue_tracker.fields.description'),
      },
      {
        id: 'priority',
        label: t('issue_tracker.fields.priority'),
      },
      {
        id: 'supportCategory',
        label: t('issue_tracker.fields.support_category'),
      },
      {
        id: 'status',
        label: t('issue_tracker.fields.status_name'),
      },
    ];
  }, [t]);
};

export const useFetchJiraAttachmentsByIds = (ids: Array<string>) => {
  const [attachments, setAttachments] = React.useState<
    Array<{ preview: string; id: string }>
  >([]);

  React.useEffect(() => {
    fetchAttachmentsByIds(ids);

    async function fetchAttachmentsByIds(ids: Array<string>) {
      const allAttachments: Array<{ preview: string; id: string }> = [];

      for (const id of ids) {
        const attachment = await Api.IssueTracker.getAttachmentContent(id);
        const preview = await readFileAsDataUrl(attachment.content);

        allAttachments.push({ preview, id });
      }

      setAttachments(allAttachments);
    }
  }, [ids]);

  return { attachments };
};

async function readFileAsDataUrl(file: Blob): Promise<string> {
  const fileReader: FileReader = new FileReader();

  fileReader.readAsDataURL(file);

  return new Promise((res) => {
    fileReader.onload = () => {
      const base64File = fileReader.result as string;

      res(base64File);
    };
  });
}
