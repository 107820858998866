import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import {
  useAutomaticPointsPermissions,
  useAutomaticPointsTableInclusionObject,
} from 'src/modules/utils/hooks';
import { AnyObject, ICountResponse } from 'src/modules/types';
import { apiEndpoints } from 'src/config/apiEndpoints';
import {
  Pagination,
  TableActions,
  TableBottom,
  TableComponent,
  TableContent,
  TableContextProvider,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import {
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { CheckDone02Svg, MinusSquareSvg } from 'src/components/svgIcons';
import { DeleteConfirmation } from 'src/components/DeleteConfirmation';
import { Api } from '../../../../modules/utils';
import { AutomaticPointsReject } from './components/AutomaticPointsReject';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { map } from 'lodash';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from 'src/modules/utils/dateWrapper';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { EmployeesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/EmployeesComboBoxFilter';
import { DateTimePickerFilter } from 'src/components/Table/components/TableActions/components/Filter/components/DateTimePickerFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getPointTypesComboboxList } from 'src/modules/selectors/pointType';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { ComboBoxOption } from 'src/components/ComboBox';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { addProcessStatus } from 'src/modules/actions';

const reportUrl = apiEndpoints.AUTOMATIC_POINTS_TO_APPROVE;

export const AutomaticPointsToApprove = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);

  const [showApprovalModal, setShowApprovalModal] = React.useState<boolean>(
    false,
  );
  const [showRejectionModal, setShowRejectionModal] = React.useState<boolean>(
    false,
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    AnyObject[]
  >(reportUrl, []);

  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const inclusionObj = useAutomaticPointsTableInclusionObject();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'employee.firstName',
        orderConfig: {
          orderBy: 'firstName',
          orderByInclusionPath: ['employee', 'scope'] as InclusionPath,
        },
        label: t('points.emp_first_name'),
      },
      {
        id: 'employee.lastName',
        orderConfig: {
          orderBy: 'lastName',
          orderByInclusionPath: ['employee', 'scope'] as InclusionPath,
        },
        label: t('points.emp_last_name'),
      },
      {
        id: 'employee.badge',
        orderConfig: {
          orderBy: 'badge',
          orderByInclusionPath: ['employee', 'scope'] as InclusionPath,
        },
        label: t('points.badge'),
      },
      {
        id: 'employee.supervisor',
        label: t('points.supervisor'),
      },
      {
        id: 'employee.active',
        orderConfig: {
          orderBy: 'active',
          orderByInclusionPath: ['employee', 'scope'] as InclusionPath,
        },
        label: t('points.emp_active'),
      },
      {
        id: 'employee.site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: [
            'employee',
            'scope',
            'include',
            'site',
            'scope',
          ] as InclusionPath,
        },
        label: t('points.site.name'),
      },
      {
        id: 'datetime',
        orderConfig: { orderBy: 'datetime' },
        label: t('points.datetime'),
      },
      {
        id: 'pointType.reason',
        orderConfig: {
          orderBy: 'reason',
          orderByInclusionPath: ['pointType', 'scope'] as InclusionPath,
        },
        label: t('points.reason'),
      },
      {
        id: 'pointType.point',
        orderConfig: {
          orderBy: 'point',
          orderByInclusionPath: ['pointType', 'scope'] as InclusionPath,
        },
        label: t('points.points'),
      },
      {
        id: 'createdAt',
        orderConfig: {
          orderBy: 'createdAt',
        },
      },
    ],
    [t],
  );

  const filterFieldsConfiguration = React.useMemo(
    () => ({
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'eq' as const,
      },
      datetime: {
        value: '',
        property: 'datetime',
        operator: 'eq' as const,
      },
      pointTypeId: {
        value: '',
        property: 'pointTypeId',
        operator: 'eq' as const,
      },
    }),
    [],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultOrder: 'desc',
    defaultOrderBy: 'createdAt',
  });

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const listFilter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });
  const countFilter = useGenerateRequestFilter({
    inclusionObj,
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const pointTypes = useSelector(getPointTypesComboboxList);

  const { allowedToApprove, allowedToReject } = useAutomaticPointsPermissions();

  const computedList = React.useMemo(
    () =>
      map(data, (item) => ({
        ...item,
        datetime: composeDate(
          item.datetime,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
        employee: {
          ...item.employee,
          supervisor: `${item.employee?.supervisor?.firstName} ${item.employee?.supervisor?.lastName}`,
          active: item.employee?.active ? (
            <YesIcon color="primary" />
          ) : (
            <NoIcon color="primary" />
          ),
        },
      })),
    [data],
  );

  const actionsConfiguration = [];
  if (allowedToApprove) {
    actionsConfiguration.push({
      tooltip: t('points.approve'),
      onClick: () => setShowApprovalModal(true),
      icon: <CheckDone02Svg />,
    });
  }
  if (allowedToReject) {
    actionsConfiguration.push({
      tooltip: t('points.reject'),
      onClick: () => setShowRejectionModal(true),
      icon: <MinusSquareSvg />,
    });
  }

  const handleApprove = async () => {
    if (selectedEntitiesIds) {
      await Api.Point.autoPointApprove(
        selectedEntitiesIds.map((id) => ({
          id,
        })),
      );

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );

      setShowApprovalModal(false);
      setSelectedEntitiesIds([]);

      fetchData({ filter: listFilter });
      fetchCountData({ filter: countFilter });
    }
  };

  const handleReject = async () => {
    if (selectedEntitiesIds) {
      setShowRejectionModal(false);
      setSelectedEntitiesIds([]);

      fetchData({ filter: listFilter });
      fetchCountData({ filter: countFilter });
    }
  };

  React.useEffect(() => {
    fetchData({ filter: listFilter });
  }, [fetchData, listFilter]);

  React.useEffect(() => {
    fetchCountData({ filter: countFilter });
  }, [countFilter, fetchCountData]);

  return (
    <>
      <TableContextProvider>
        <TableToolbar>
          <TableTitle>
            {t('points.table_name.automatic_points_to_approve')}
          </TableTitle>
          <TableActions>
            <Filter onSubmit={onFiltersFormSubmit}>
              <FormFieldContainer>
                <EmployeesComboBoxFilter
                  label={getLabel({
                    filterName: 'employeeId',
                    labelPrefix: t('points.employee'),
                  })}
                  {...getFilterCommonPropsByFilterName('employeeId')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <DateTimePickerFilter
                  label={getLabel({
                    filterName: 'datetime',
                    labelPrefix: t('points.datetime'),
                  })}
                  {...getFilterCommonPropsByFilterName('datetime')}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <ComboboxTableFilter
                  options={pointTypes as ComboBoxOption[]}
                  label={getLabel({
                    filterName: 'pointTypeId',
                    labelPrefix: t('points.reason'),
                  })}
                  {...getFilterCommonPropsByFilterName('pointTypeId')}
                />
              </FormFieldContainer>
            </Filter>

            <SelectHeadCells />
          </TableActions>
        </TableToolbar>

        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <TableComponent
            tableUniqueKey="points/automatic-points-to-approve"
            sx={{
              flexGrow: 0,
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {(isDataLoading || isCountDataLoading) && <MainContentLoader />}

            <TableContent
              data={computedList as any}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading || isCountDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={countData.count}
                rowsPerPage={limit}
                onPageChange={(_, pageNumber) => setPage(pageNumber)}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </Box>
      </TableContextProvider>

      <DeleteConfirmation
        type="warning"
        confirmTextColor="warning"
        open={showApprovalModal}
        onCancel={() => setShowApprovalModal(false)}
        onOk={handleApprove}
        confirmText={t('points.approve')}
      >
        {t('points.confirm_approving')}
      </DeleteConfirmation>

      <AutomaticPointsReject
        isOpen={showRejectionModal}
        ids={selectedEntitiesIds}
        onReject={handleReject}
        onClose={() => setShowRejectionModal(false)}
      />
    </>
  );
};
