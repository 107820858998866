import { JSONSchemaType } from 'ajv';

// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { IAdditionalFee } from '../../types';
import { pricingRegex } from '../pricing';

export const createAdditionalFeeScheme: JSONSchemaType<IAdditionalFee> = {
  type: 'object',
  required: ['siteId', 'additionalFeeCategoryId', 'name', 'quantity', 'cpu'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    additionalFeeCategoryId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        type: i18n.t('error.validation.type'),
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    quantity: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      pattern: pricingRegex,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
        pattern: i18n.t('error.validation.number'),
      },
    },
    isTemporary: {
      nullable: false,
      type: 'boolean',
      default: false,
    },
    startingYearWeek: {
      type: 'number',
      nullable: true,
      default: null,
      errorMessage: {
        type: i18n.t('error.validation.type'),
      },
    },
    finishingYearWeek: {
      type: 'number',
      nullable: true,
      default: null,
      errorMessage: {
        type: i18n.t('error.validation.type'),
      },
    },
    cpu: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      pattern: pricingRegex,
      errorMessage: {
        type: i18n.t('error.validation.required'),
        isNotEmpty: i18n.t('error.validation.required'),
        pattern: i18n.t('error.validation.number'),
      },
    },
  },
  if: {
    properties: {
      isTemporary: {
        const: true,
      },
    },
  },
  then: {
    required: ['startingYearWeek', 'finishingYearWeek'],
    properties: {
      startingYearWeek: {
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
        },
      },
      finishingYearWeek: {
        type: 'number',
        errorMessage: {
          type: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
