import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFilter, useIssuesDefaultFilters } from 'src/modules/utils/hooks';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { Api } from 'src/modules/utils';
import { set } from 'lodash';
import { SeriesColumnOptions } from 'highcharts';

export const DowntimeChart = ({
  hasChartDataBeRefetch,
  onRefetchEnd,
}: {
  hasChartDataBeRefetch: boolean;
  onRefetchEnd: () => void;
}) => {
  const { t } = useTranslation();
  const defaultFilter = useIssuesDefaultFilters();

  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<{
    series: SeriesColumnOptions[];
    categories: Array<string>;
  }>({
    series: [],
    categories: [],
  });

  const { filterList } = useFilter(defaultFilter);

  React.useEffect(() => {
    fetchDowntimeChartData();

    async function fetchDowntimeChartData() {
      try {
        setIsLoading(true);

        const data = await Api.Issue.getDowntimeChartData(filterList);

        const datesSet = new Set<string>();
        data.forEach((item) => {
          datesSet.add(item.weekLabel);
        });

        const dates = Array.from(datesSet.values());
        const series = data.reduce<SeriesColumnOptions[]>((series, item) => {
          // check if set already exists in series
          const setIndex = series.findIndex(
            (set: any) =>
              set.name === item.issueType && set.stack === item.site,
          );
          // find out proper index in data array of the set based on weekLabel
          const dataIndex = dates.findIndex(
            (weekLabel) => weekLabel === item.weekLabel,
          );
          // add data item to set if the set already exists
          if (setIndex !== -1) {
            set(series, [setIndex, 'data', dataIndex], item.duration);
          } else {
            // every set should have null or number for appropriate value from categories array
            // so by default we set null for all categories
            const issuesDurationList = Array(dates.length).fill(null);
            // put data in correct place based on index of category
            issuesDurationList[dataIndex] = item.duration;
            // put set in series
            series.push({
              type: 'column',
              name: item.issueType,
              stack: item.site,
              data: issuesDurationList,
            });
          }

          return series;
        }, []);

        setIsLoading(false);

        setData({ series, categories: dates });

        onRefetchEnd();
      } catch {
        setIsLoading(false);
        onRefetchEnd();
      }
    }
  }, [filterList, hasChartDataBeRefetch, onRefetchEnd]);

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  return (
    <ChartContainer fullHeigh>
      <ChartContainerHeader title={t('charts.total_downtime_title')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`downtime`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isLoading}
        showNoData={!data.series.length && !isLoading}
      >
        <ColumnChart
          ref={chartRef}
          height={'100%'}
          categories={data.categories}
          series={data.series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
