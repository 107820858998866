import React from 'react';
import { map } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import {
  useFetchApplicantsByIds,
  useQueryParams,
} from '../../../modules/utils/hooks';
import { IdsArray, IStoreState } from '../../../modules/types';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../components/Accordion';
import { getApplicantViewList } from '../../../modules/selectors/applicant';
import { ContentLayout } from '../../../components/Layout/ContentLayout';
import ApplicantsView from './ApplicantsView';

const ApplicantsViewList = () => {
  const { t } = useTranslation();

  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  // fetch applicants list from store
  const applicants = useSelector(
    (state) => getApplicantViewList(state as IStoreState)(ids),
    shallowEqual,
  );

  useFetchApplicantsByIds(ids);

  return (
    <ContentLayout>
      {map(applicants, (applicant, index) => (
        <Accordion
          key={index}
          defaultExpanded={index === 0}
          cardTitle={t('applicant.details_title', { id: applicant.id })}
        >
          <ApplicantsView applicant={applicant} />
        </Accordion>
      ))}
    </ContentLayout>
  );
};

export default ApplicantsViewList;
