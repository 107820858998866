import React from 'react';
import { Sheet } from '@mui/joy';

interface IFilterFieldContainerProps {
  children: React.ReactNode;
}

export const FilterFieldContainer = ({
  children,
}: IFilterFieldContainerProps) => {
  return <Sheet sx={{ mb: 2, bgcolor: 'inherit' }}>{children}</Sheet>;
};
