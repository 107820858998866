import React from 'react';
import { AnyObject } from 'src/modules/types';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import RRule, { Frequency } from 'rrule';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { MonthlyRecurrenceCombobox } from './MonthlyRecurrenceCombobox';
import {
  IWeekDay,
  WeeklyRecurrenceCheckboxes,
} from './WeeklyRecurrenceCheckboxes';
import { FormActionsContainer } from 'src/components/Form/FormActionsContainer';
import { SubmitButton } from 'src/components/Form/SubmitButton';
import {
  composeDate,
  getDayWithMonAsFirstDay,
  parse,
  DATE_FORMAT,
} from 'src/modules/utils/dateWrapper';
import {
  createRRuleRecurrence,
  createRRuleFromStr,
  rruleToName,
} from 'src/modules/utils/rRuleWrapper';
import { RecurrenceEnd } from './RecurrenceEnd';

export interface ICustomRecurrenceProps {
  taskStartDate: Date;
  recurrenceStartDate: Date;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (option: any) => void;
  existedRecurrence?: string;
  customRecurrenceOptionIndex: number;
}

interface ICustomRecurrenceValues {
  interval: string;
  frequency: {
    id: Frequency.MONTHLY | Frequency.WEEKLY | Frequency.DAILY;
    name: string;
  } | null;
  weekDays: number[];
  monthDetails: ComboBoxOption | null;
  recurrenceEnd: string | number | null;
}

const frequencyOptions = [
  { id: Frequency.DAILY as const, name: 'Day' },
  { id: Frequency.WEEKLY as const, name: 'Week' },
  { id: Frequency.MONTHLY as const, name: 'Month' },
];

export const CustomRecurrence: React.FC<ICustomRecurrenceProps> = ({
  taskStartDate,
  recurrenceStartDate,
  isOpen,
  onClose,
  onSubmit,
  existedRecurrence,
  customRecurrenceOptionIndex,
}) => {
  const { t } = useTranslation();

  const startTimeWeekDay = getDayWithMonAsFirstDay(taskStartDate);

  const [formValues, setFormValues] = React.useState<ICustomRecurrenceValues>({
    weekDays: [startTimeWeekDay],
    frequency: frequencyOptions[1],
    interval: '1',
    monthDetails: null,
    recurrenceEnd: null,
  });

  React.useEffect(() => {
    // In case user click on "custom" option we
    // just need to open custom recurrence form
    if (Number(existedRecurrence) === customRecurrenceOptionIndex) {
      return;
    }

    let weekDays = [startTimeWeekDay];
    let frequency = frequencyOptions[1];
    let interval = '1';
    let monthDetails = null;
    const recurrenceEnd = null;

    // If task has recurrence we need to parse it
    // and extract values from there to reflect
    // recurrence state in custom recurrence form
    if (existedRecurrence) {
      const rrule = createRRuleFromStr(existedRecurrence);
      const { freq, byweekday, interval: rruleInterval } = rrule.rrules()[0]
        .options as any;
      const frequencyOption =
        freq && frequencyOptions.find((option) => option.id === freq);

      weekDays = byweekday ? byweekday : weekDays;
      frequency = frequencyOption ? frequencyOption : frequency;
      interval = rruleInterval ? String(rruleInterval) : interval;
      monthDetails =
        rruleInterval === Frequency.MONTHLY
          ? ({ id: rrule.toString(), name: rruleToName(rrule) } as any)
          : monthDetails;
    }

    const updatedFormValues = {
      interval,
      frequency,
      weekDays,
      monthDetails,
      recurrenceEnd,
    };

    setFormValues(updatedFormValues);
  }, [existedRecurrence, startTimeWeekDay, customRecurrenceOptionIndex]);

  const onIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
      monthDetails: null,
    }));
  };

  const onFrequencyChange = (_: AnyObject, option: ComboBoxOption | null) => {
    setFormValues((prevValues) => ({ ...prevValues, frequency: option }));
  };

  const onWeekDaysChange = (weekDay: IWeekDay) => {
    const updatedWeekDays = weekDay.selected
      ? [...formValues.weekDays, weekDay.index]
      : formValues.weekDays.filter((day) => day !== weekDay.index);

    setFormValues((prevValues) => ({
      ...prevValues,
      weekDays: updatedWeekDays,
    }));
  };

  const onMothOptionSelection = (value: { id: any; name: string } | null) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      monthDetails: value,
    }));
  };

  const onRecurrenceEndChange = (
    recurrenceEnd: ICustomRecurrenceValues['recurrenceEnd'],
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      recurrenceEnd,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!formValues.frequency) {
      return;
    }
    if (formValues.frequency.id === Frequency.MONTHLY) {
      onSubmit(formValues.monthDetails);
      return;
    }

    const until =
      typeof formValues.recurrenceEnd === 'string'
        ? composeDate(formValues.recurrenceEnd, parse(DATE_FORMAT))
        : undefined;
    const count =
      typeof formValues.recurrenceEnd === 'number'
        ? formValues.recurrenceEnd
        : undefined;

    if (formValues.frequency.id === Frequency.WEEKLY) {
      const weeklyRule = createRRuleRecurrence({
        interval: Number(formValues.interval),
        byweekday: formValues.weekDays,
        wkst: RRule.MO,
        dtstart: recurrenceStartDate,
        byhour: taskStartDate.getUTCHours(),
        byminute: taskStartDate.getUTCMinutes(),
        freq: Frequency.WEEKLY,
        until,
        count,
      });

      onSubmit({
        id: weeklyRule.toString(),
        name: rruleToName(weeklyRule),
      });
      return;
    }

    const dailyRule = createRRuleRecurrence({
      interval: Number(formValues.interval),
      freq: RRule.DAILY,
      dtstart: recurrenceStartDate,
      byhour: taskStartDate.getUTCHours(),
      byminute: taskStartDate.getUTCMinutes(),
      until,
      count,
    });

    onSubmit({
      id: dailyRule.toString(),
      name: rruleToName(dailyRule),
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton onClick={onClose} size="large">
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ width: 550 }} dividers>
        <Box component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              marginBottom: 2,
            }}
          >
            <Typography sx={{ minWidth: 140 }}>Repeat every</Typography>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <TextField
                type="number"
                name="interval"
                sx={(theme) => ({
                  width: theme.spacing(15),
                  marginRight: 3,
                })}
                value={formValues.interval}
                variant="outlined"
                onChange={onIntervalChange}
              />
              <ComboBox
                id="frequency"
                options={frequencyOptions}
                value={formValues.frequency}
                onChange={onFrequencyChange}
              />
            </Box>
          </Box>
          {formValues.frequency?.id === Frequency.WEEKLY && (
            <WeeklyRecurrenceCheckboxes
              preselectedWeekDays={formValues.weekDays}
              onChange={onWeekDaysChange}
            />
          )}
          {formValues.frequency?.id === Frequency.MONTHLY && (
            <MonthlyRecurrenceCombobox
              startDate={recurrenceStartDate}
              interval={Number(formValues.interval)}
              recurrenceEnd={formValues.recurrenceEnd}
              onChange={onMothOptionSelection}
              value={formValues.monthDetails}
            />
          )}
          <RecurrenceEnd
            recurrenceEndValue={formValues.recurrenceEnd}
            onRecurrenceEndChange={onRecurrenceEndChange}
          />
          <FormActionsContainer>
            <SubmitButton>{t('common.done')}</SubmitButton>
          </FormActionsContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
