import React from 'react';
import { FormikContextType } from 'formik';
import {
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../../../../components/Formik/FormikTextField';
import { Box, IconButton } from '@mui/joy';
import { MinusSquareSvg, PlusSvg } from 'src/components/svgIcons';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { deepClone } from '@mui/x-data-grid/utils/utils';

interface IPricingCreateRangeRecordsProps {
  formik: FormikContextType<any>;
  pricingMetatags: React.ReactNode;
}

const fieldSlotProps = {
  input: {
    sx: {
      width: 32,
    },
  },
  root: {
    sx: {
      width: 48,
      height: 24,
      p: 0.5,
    },
  },
};

const helperTextProps = {
  sx: { fontSize: '12px' },
};

export const PricingCreateRangeRecords = ({
  formik,
  pricingMetatags,
}: IPricingCreateRangeRecordsProps) => {
  const { t } = useTranslation();

  const initialItem = {
    price: NaN,
    incentivePrice: NaN,
    overtimePrice: NaN,
    minUph: 0,
    maxUph: NaN,
  };

  const shouldMinusBeShown = (length: number, index: number): boolean => {
    // Minimal line items should be in the pricing range list
    const minimalLines = 3;
    if (length <= minimalLines) {
      return false;
    }

    if (index !== 0 && index !== length - 1) {
      return true;
    }

    return false;
  };

  const shouldPlusBeShown = (index: number): boolean => {
    return index > 0;
  };

  const shouldMaxBeDisabled = (length: number, index: number): boolean => {
    return index === length - 1;
  };

  const createOnMaxUphChangeHandler = (formik: any, i: number) => (
    _: any,
    value: string,
  ) => {
    formik.setFieldValue(`bulks[${i + 1}].minUph`, value);
    if (formik.values.bulks[i + 1]) {
    }
  };

  return (
    <>
      <DrawerBodySectionContent>
        <DrawerBodySectionTitle>
          {t('pricing.meta_tags')}
        </DrawerBodySectionTitle>

        {pricingMetatags}
      </DrawerBodySectionContent>

      <DrawerBodySectionContent>
        <DrawerBodySectionTitle>
          {t('common.form_fields')}
        </DrawerBodySectionTitle>

        <FormFieldContainer>
          <Box
            sx={{
              borderRadius: 8,
              overflow: 'hidden',
              borderColor: 'colors.border.border_primary',
              borderWidth: 1,
              borderStyle: 'solid',
              paddingBottom: 2,
            }}
          >
            <Box
              sx={{
                height: 48,
                alignItems: 'center',
                backgroundColor: '#fafafa',
                paddingLeft: 2,
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 6fr)',
                gridColumnGap: '8px',
              }}
            >
              <Box>{t('pricing.price')}*</Box>
              <Box>{t('pricing.incentivePrice')}*</Box>
              <Box>{t('pricing.overtimePrice')}*</Box>
              <Box>{t('pricing.minUph')}*</Box>
              <Box>{t('pricing.maxUph')}*</Box>
              <Box></Box>
            </Box>
            {formik.values.bulks.map((_: any, i: number) => {
              return (
                <Box
                  sx={(theme) => ({
                    height: 80,
                    alignItems: 'end',
                    padding: `0 ${theme.spacing(2)}`,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 5fr) 2fr',
                    gridColumnGap: '16px',
                  })}
                  key={i}
                >
                  <FormikTextField
                    required
                    id={`bulks[${i}].price`}
                    formik={formik}
                    name={`bulks[${i}].price`}
                    label={''}
                    slotProps={fieldSlotProps}
                    placeholder="0"
                    variant="soft"
                    helperTextProps={helperTextProps}
                  />
                  <FormikTextField
                    required
                    id={`bulks[${i}].incentivePrice`}
                    formik={formik}
                    name={`bulks[${i}].incentivePrice`}
                    label={''}
                    slotProps={fieldSlotProps}
                    placeholder="0"
                    variant="soft"
                    helperTextProps={helperTextProps}
                  />
                  <FormikTextField
                    required
                    id={`bulks[${i}].overtimePrice`}
                    formik={formik}
                    name={`bulks[${i}].overtimePrice`}
                    label={''}
                    slotProps={fieldSlotProps}
                    placeholder="0"
                    variant="soft"
                    helperTextProps={helperTextProps}
                  />
                  <FormikTextField
                    required
                    disabled
                    id={`bulks[${i}].minUph`}
                    formik={formik}
                    name={`bulks[${i}].minUph`}
                    label={''}
                    autoComplete="minUph"
                    slotProps={fieldSlotProps}
                    placeholder="0"
                    variant="soft"
                    helperTextProps={helperTextProps}
                  />
                  <FormikTextField
                    required
                    disabled={shouldMaxBeDisabled(
                      formik.values.bulks.length,
                      i,
                    )}
                    id={`bulks[${i}].maxUph`}
                    formik={formik}
                    name={`bulks[${i}].maxUph`}
                    label={''}
                    autoComplete="maxUph"
                    slotProps={fieldSlotProps}
                    placeholder="0"
                    variant="soft"
                    helperTextProps={helperTextProps}
                    onChange={createOnMaxUphChangeHandler(formik, i)}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      sx={{
                        visibility: shouldPlusBeShown(i) ? 'visible' : 'hidden',
                      }}
                      onClick={() => {
                        const clonedValues = deepClone(formik.values);

                        clonedValues.bulks.splice(i, 0, {
                          ...initialItem,
                          minUph: clonedValues.bulks[i - 1].maxUph,
                        });

                        formik.setValues(clonedValues);
                      }}
                    >
                      <PlusSvg />
                    </IconButton>

                    <IconButton
                      sx={{
                        visibility: shouldMinusBeShown(
                          formik.values.bulks.length,
                          i,
                        )
                          ? 'visible'
                          : 'hidden',
                      }}
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          bulks: formik.values.bulks.filter(
                            (_: any, j: any) => i !== j,
                          ),
                        })
                      }
                    >
                      <MinusSquareSvg />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </FormFieldContainer>
      </DrawerBodySectionContent>
    </>
  );
};
