import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import {
  Pagination,
  TableTitle,
  TableBottom,
  TableActions,
  TableContent,
  TableToolbar,
  TableComponent,
  TableContextProvider,
} from 'src/components/Table';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableSelectedItemsActions } from 'src/components/Table/components/TableSelectedItemsActions';
import { Edit02Svg, Trash04Svg } from 'src/components/svgIcons';
import { ModelsToDelete, useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import {
  getSiteTaktExportingSettingsListRequest,
  getSiteTaktExportingSettingsCountRequest,
  deleteSiteTaktExportingSettingsRequest,
  addProcessStatus,
} from 'src/modules/actions';
import {
  IHeadCellWithOrderConfig,
  InclusionPath,
} from 'src/modules/types/table';
import {
  useTableContentEffect,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
  useTableDataWithQueryParamsSynchronization,
  useFilterFieldsDataWithQueryParamsSynchronization,
} from 'src/modules/utils/hooks/table';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SitesComboBoxFilter } from 'src/components/Table/components/TableActions/components/Filter/components/SitesComboBoxFilter';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import {
  getIsTaktSettingsDataLoading,
  getSiteTaktExportingSettingsCount,
  getSiteTaktExportingSettingsList,
} from 'src/modules/selectors/site-takt-exporting-settings.selector';
import { TaktSettingsCreate } from './taktSettingsForms/TaktSettingsCreate';
import { TaktSettingsUpdate } from './taktSettingsForms/TaktSettingsUpdate';
import { TaktSettingsExportDay } from './taktSettingsForms/TaktSettingsExportDay';
import { TaktSettingsExportRange } from './taktSettingsForms/TaktSettingsExportRange';
import {
  useExportDailyPunches,
  useExportRangePunches,
} from 'src/modules/utils/hooks/takt-exporting.hooks';

export const SiteTaktExportingSettingsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedEntitiesIds, setSelectedEntitiesIds] = React.useState<
    Array<number>
  >([]);
  const [isCreateFormOpened, setIsCreateFormOpened] = React.useState(false);
  const [isUpdateFormOpened, setIsUpdateFormOpened] = React.useState(false);
  const [isExportDayFormOpened, setIsExportDayFormOpened] = React.useState(
    false,
  );
  const [isExportRangeFormOpened, setIsExportRangeFormOpened] = React.useState(
    false,
  );

  const {
    exportDailyPunches,
    isSuccess: isDayExportSuccess,
    isLoading: isDayExportLoading,
  } = useExportDailyPunches();

  const {
    exportRangePunches,
    isLoading: isRangeExportLoading,
    isSuccess: isRangeExportSuccess,
  } = useExportRangePunches();

  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  const isDataLoading = useSelector(getIsTaktSettingsDataLoading);

  const allowedToCreate = useHasUserAccessToAction(
    manageEntitiesConfig.takt_settings.create.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.takt_settings.update.id,
  );
  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.takt_settings.delete.id,
  );
  const allowedToExportDay = useHasUserAccessToAction(
    manageEntitiesConfig.takt_settings.export_day.id,
  );
  const allowedToExportRange = useHasUserAccessToAction(
    manageEntitiesConfig.takt_settings.export_range.id,
  );

  const inclusionObj = React.useMemo(() => {
    return {
      site: {
        relationType: 'left',
        scope: {
          where: {
            id: {
              inq: globallySelectedSiteIds,
            },
          },
          include: {
            client: {
              relationType: 'left',
            },
          },
        },
      },
    };
  }, [globallySelectedSiteIds]);

  const headCellsConfig: IHeadCellWithOrderConfig[] = React.useMemo(
    () => [
      {
        id: 'site.name',
        orderConfig: {
          orderBy: 'name',
          orderByInclusionPath: ['site', 'scope'],
        },
        label: t('departments.site'),
      },
      {
        id: 'apiKey',
        orderConfig: { orderBy: 'apiKey' },
        label: t('site-takt-exporting-settings.api_key_secret'),
      },
      {
        id: 'apiKeySecret',
        orderConfig: { orderBy: 'apiKeySecret' },
        label: t('site-takt-exporting-settings.api_key_secret'),
      },
      {
        id: 'employeesEndpoint',
        orderConfig: { orderBy: 'employeesEndpoint' },
        label: t('site-takt-exporting-settings.employees_endpoint'),
      },
      {
        id: 'punchesEndpoint',
        orderConfig: { orderBy: 'punchesEndpoint' },
        label: t('site-takt-exporting-settings.punches_endpoint'),
      },
    ],
    [t],
  );
  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const filterFieldsConfiguration = React.useMemo(
    () => ({
      siteId: {
        value: '',
        property: 'id',
        operator: 'eq' as const,
        inclusionPath: ['site', 'scope'] as InclusionPath,
      },
      apiKey: {
        value: '',
        property: 'apiKey',
        operator: 'like' as const,
      },
      apiKeySecret: {
        value: '',
        property: 'apiKeySecret',
        operator: 'like' as const,
      },
      employeesEndpoint: {
        value: '',
        property: 'employeesEndpoint',
        operator: 'like' as const,
      },
      punchesEndpoint: {
        value: '',
        property: 'punchesEndpoint',
        operator: 'like' as const,
      },
    }),
    [],
  );

  const {
    page,
    limit,
    order,
    orderBy,
    setPage,
    setLimit,
    setOrder,
    setOrderBy,
  } = useTableDataWithQueryParamsSynchronization({
    headCellsOrderDetails,
  });

  const {
    filterFields,
    getLabel,
    clearFilters,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsDataWithQueryParamsSynchronization({
    filterFieldsConfiguration,
  });

  const filter = useGenerateRequestFilter({
    page,
    limit,
    order,
    orderBy,
    filterFields,
    inclusionObj,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const { data, count } = useTableContentEffect({
    filter,
    selectData: getSiteTaktExportingSettingsList,
    selectCount: getSiteTaktExportingSettingsCount,
    getEntitiesRequest: getSiteTaktExportingSettingsListRequest,
    getEntitiesCountRequest: getSiteTaktExportingSettingsCountRequest,
  });

  const onDelete = () => {
    clearFilters();

    dispatch(
      deleteSiteTaktExportingSettingsRequest({
        data: selectedEntitiesIds,
        filters: {
          list: { include: filter.include },
          count: { include: filter.include },
        },
      }),
    );

    setSelectedEntitiesIds([]);
  };
  const onUpdateClick = () => {
    setIsUpdateFormOpened(true);
  };

  const actionsConfiguration = [];
  if (allowedToUpdate) {
    actionsConfiguration.push({
      tooltip: t('common.update'),
      onClick: onUpdateClick,
      icon: <Edit02Svg />,
    });
  }

  React.useEffect(() => {
    if (isDayExportSuccess || isRangeExportSuccess) {
      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );
    }
  }, [dispatch, isDayExportSuccess, isRangeExportSuccess]);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight>
        <TableContextProvider>
          <TableToolbar>
            <TableTitle>
              {t('site-takt-exporting-settings.table_name')}
            </TableTitle>
            <TableActions>
              <Filter onSubmit={onFiltersFormSubmit}>
                <FormFieldContainer>
                  <SitesComboBoxFilter
                    label={getLabel({
                      filterName: 'siteId',
                      labelPrefix: t('departments.site'),
                    })}
                    {...getFilterCommonPropsByFilterName('siteId')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'apiKey',
                      labelPrefix: t('site-takt-exporting-settings.api_key'),
                    })}
                    {...getFilterCommonPropsByFilterName('apiKey')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'apiKeySecret',
                      labelPrefix: t(
                        'site-takt-exporting-settings.api_key_secret',
                      ),
                    })}
                    {...getFilterCommonPropsByFilterName('apiKeySecret')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'employeesEndpoint',
                      labelPrefix: t(
                        'site-takt-exporting-settings.employees_endpoint',
                      ),
                    })}
                    {...getFilterCommonPropsByFilterName('employeesEndpoint')}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <TextFieldFilter
                    label={getLabel({
                      filterName: 'punchesEndpoint',
                      labelPrefix: t(
                        'site-takt-exporting-settings.punches_endpoint',
                      ),
                    })}
                    {...getFilterCommonPropsByFilterName('punchesEndpoint')}
                  />
                </FormFieldContainer>
              </Filter>

              <SelectHeadCells />

              {allowedToExportRange && (
                <Button
                  sx={{ ml: 1 }}
                  loading={isRangeExportLoading}
                  onClick={() => setIsExportRangeFormOpened(true)}
                >
                  {t('site-takt-exporting-settings.export_range')}
                </Button>
              )}
              {allowedToExportDay && (
                <Button
                  sx={{ ml: 1 }}
                  loading={isDayExportLoading}
                  onClick={() => setIsExportDayFormOpened(true)}
                >
                  {t('site-takt-exporting-settings.export_day')}
                </Button>
              )}
              {allowedToCreate && (
                <Button
                  sx={{ ml: 1 }}
                  onClick={() => setIsCreateFormOpened(true)}
                >
                  {t('common.create')}
                </Button>
              )}
            </TableActions>
          </TableToolbar>

          <TableComponent
            tableUniqueKey="setup/takt_settings"
            sx={{
              height: isDataLoading || !data.length ? '100%' : 'auto',
              maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
            }}
          >
            {isDataLoading && <MainContentLoader />}

            <TableContent
              data={data}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              isDataLoading={isDataLoading}
              onRowCheckboxClick={(ids) => {
                setSelectedEntitiesIds(ids);
              }}
              selectedRows={selectedEntitiesIds}
              onSort={(orderBy, order) => {
                setOrder(order);
                setOrderBy(orderBy);
              }}
            />

            {Boolean(selectedEntitiesIds.length) && (
              <TableSelectedItemsActions
                selectedItemsQuantity={selectedEntitiesIds.length}
                actionsConfiguration={actionsConfiguration}
                deleteModelName={ModelsToDelete.TaktSiteImportingSetting}
                deleteActionConfiguration={
                  allowedToDelete
                    ? {
                        tooltip: t('common.delete'),
                        onClick: onDelete,
                        icon: <Trash04Svg />,
                      }
                    : undefined
                }
              />
            )}

            <TableBottom>
              <Pagination
                currentPageNumber={page}
                count={count}
                rowsPerPage={limit}
                onPageChange={setPage}
                onRowsPerPageChange={setLimit}
              />
            </TableBottom>
          </TableComponent>
        </TableContextProvider>
      </PageContentChildContainer>

      <TaktSettingsCreate
        isOpen={isCreateFormOpened}
        onClose={() => setIsCreateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <TaktSettingsUpdate
        isOpen={isUpdateFormOpened}
        entitiesToUpdateIds={selectedEntitiesIds}
        onClose={() => setIsUpdateFormOpened(false)}
        filterList={filter}
        filterCount={{ where: filter.where, include: filter.include }}
      />

      <TaktSettingsExportDay
        isOpen={isExportDayFormOpened}
        onExportDailyPunches={exportDailyPunches}
        onClose={() => setIsExportDayFormOpened(false)}
      />

      <TaktSettingsExportRange
        isOpen={isExportRangeFormOpened}
        onExportRangePunches={exportRangePunches}
        onClose={() => setIsExportRangeFormOpened(false)}
      />
    </PageContentWithTopToolbar>
  );
};
