import React, { useEffect, useMemo } from 'react';
import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { shallowEqual, useSelector } from 'react-redux';
import { getUsersComboboxList } from '../../../../modules/selectors/user';
import { filter, isArray, intersection, isEmpty, get } from 'lodash';
import { useSupervisorCombobox } from '../../../../modules/utils';
import { getCurrentRole } from '../../../../modules/selectors/auth';

interface ISupervisorComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  // site id to filter options by
  // if not supplied, try to get site id from formik fields
  siteId?: number | number[];
  // in update form we often has several entities
  // and path to side id will be like [valueIndex].siteId
  siteIdPathInFormik?: string;
  sitesPathInFormik?: string;
  activeOnly?: boolean;
}

const SupervisorComboBox: React.FC<ISupervisorComboboxProps> = ({
  siteId,
  siteIdPathInFormik = 'siteId',
  sitesPathInFormik = 'sites',
  activeOnly,
  ...props
}) => {
  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);
  const fetchSupervisorsCombobox = useSupervisorCombobox(activeOnly);

  // make request to fetch clients from the server if we don't have them in the store
  useEffect(() => {
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const allSupervisors = useSelector(getUsersComboboxList, shallowEqual);

  const supervisors = useMemo(
    () =>
      filter(allSupervisors, (i) => {
        const siteIdFromFormik = get(props.formik.values, siteIdPathInFormik);
        if (isArray(siteId) || isArray(siteIdFromFormik)) {
          return !isEmpty(
            intersection(
              i.sites,
              siteId ?? get(props.formik.values, sitesPathInFormik),
            ),
          );
        } else {
          return i.sites.includes(siteId ?? siteIdFromFormik);
        }
      }),
    [
      allSupervisors,
      props.formik.values,
      siteId,
      siteIdPathInFormik,
      sitesPathInFormik,
    ],
  );

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={supervisors as ComboBoxOption[]}
      error={props.error}
    />
  );
};

export default SupervisorComboBox;
