import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IApplicantModel,
  ISagaAction,
  ICreateApplicant,
  IUpdateApplicant,
  IFilter,
  IdsArray,
  ICountResponse,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../../types';
import { paths } from '../../../config';
import { map } from 'lodash';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';
/**
 * Create a new applicant
 */
export const createApplicantRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreateApplicant>>): SagaIterator {
  try {
    const response: IApplicantModel = yield call(Api.Applicant.create, data);
    yield put(actions.createApplicantSuccess(response));
    yield call(navigate, {
      pathname: paths.RECRUITING_DASHBOARD_LIST_APPLICANTS,
    });
  } catch (e) {
    yield put(actions.createApplicantFailed());
  }
};

/**
 * Delete applicants
 */
export const deleteApplicantRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Applicant.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushApplicantState());
  } catch (e) {
    yield put(actions.deleteApplicantFailed());
  }
};

/**
 * Bulk update applicants
 */
export const updateApplicantsRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IUpdateApplicant[]>>): SagaIterator {
  try {
    yield call(Api.Applicant.bulkUpdate, data);
    yield put(actions.updateApplicantSuccess());
    yield call(navigate, {
      pathname: paths.RECRUITING_DASHBOARD_LIST_APPLICANTS,
    });
  } catch (e) {
    yield put(actions.updateApplicantsFailed());
  }
};

/**
 * Get applicant list
 */
export const getApplicantListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IApplicantModel[] = yield call(
      Api.Applicant.list,
      action.payload,
    );
    yield put(actions.getApplicantListSuccess(response));
  } catch (e) {
    yield put(actions.getApplicantListFailed());
  }
};

/**
 * Get applicant count
 */
export const getApplicantCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Applicant.count,
      action?.payload,
    );
    yield put(actions.getApplicantCountSuccess(response));
  } catch (e) {
    yield put(actions.getApplicantCountFailed());
  }
};

/**
 * Get combobox list
 */
export const getApplicantComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IApplicantModel>[] = yield call(
      Api.Applicant.list,
      action.payload,
    );
    yield put(actions.getApplicantComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getApplicantComboboxListFailed());
  }
};

/**
 * Close open positions
 */
export const hireApplicantRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(
      Api.Applicant.bulkUpdate,
      (map(action.payload, (id) => ({
        id,
        hire: true,
      })) as unknown) as IUpdateApplicant[],
    );
    yield put(actions.hireApplicantSuccess());
    yield put(actions.flushApplicantState());
  } catch (e) {
    yield put(actions.hireApplicantFailed());
  }
};
/**
 * Export
 */
export const {
  exportRequestSaga: exportApplicantRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Applicant.export,
  actionFailed: actions.exportApplicantFailed,
  actionSuccess: actions.exportApplicantSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const autobind: ISagaActionBind<any>[] = [
  /**
   * Applicants sagas
   */
  { action: actions.createApplicantRequest, saga: createApplicantRequestSaga },
  { action: actions.deleteApplicantRequest, saga: deleteApplicantRequestSaga },
  // bulk updating applicant
  {
    action: actions.updateApplicantsRequest,
    saga: updateApplicantsRequestSaga,
  },
  {
    action: actions.getApplicantListRequest,
    saga: getApplicantListRequestSaga,
  },
  {
    action: actions.getApplicantCountRequest,
    saga: getApplicantCountRequestSaga,
  },
  {
    action: actions.getApplicantComboboxListRequest,
    saga: getApplicantComboboxListRequestSaga,
  },
  // hire open positions
  { action: actions.hireApplicantRequest, saga: hireApplicantRequestSaga },
  { action: actions.exportApplicantRequest, saga: exportApplicantRequestSaga },
];
