import React, { useEffect } from 'react';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import {
  format,
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_FORMAT_TO_PASS,
  tryToGenerateDateFromAnything,
} from '../../../../../modules/utils/dateWrapper';
import { TextFieldProps } from '@mui/material/TextField';
import { Backdrop } from '@mui/material';
import { FormControl, FormLabel, Typography } from '@mui/joy';
import {
  usePickersSlots,
  usePickersSlotsProps,
  usePickersSx,
} from '../pickers.hooks';

export interface IDateTimePickerProps
  extends Omit<
    DateTimePickerProps<Date | string | undefined>,
    'onChange' | 'value'
  > {
  id: string;
  label: string;
  onChange: (date: string | null) => void;
  value?: string | null;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  variant?: TextFieldProps['variant'];
  helperText?: React.ReactNode;
  inputProps?: TextFieldProps['inputProps'];
  disabled?: boolean;
  margin?: TextFieldProps['margin'];
  size?: number;
}

export default function Datetimepicker({
  value,
  onChange,
  label,
  helperText,
  fullWidth,
  required,
  error,
  variant,
  id,
  size = 40,
  slotProps,
  onClose,
  onOpen,
  open,
  ...rest
}: IDateTimePickerProps) {
  const sx = usePickersSx(size, error);
  const slots = usePickersSlots();
  const propsForSlot = usePickersSlotsProps({
    id,
    helperText,
    fullWidth,
    required,
    error,
    variant,
    slotProps,
  });

  const [isOpen, setIsOpen] = React.useState(open ?? false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  // init state from props
  useEffect(() => {
    const dateObj = tryToGenerateDateFromAnything(value);

    // This is previous solution:
    //    if (dateObj) {
    //   setSelectedDate(dateObj);
    // }
    // and it does not work when we need to clear value
    setSelectedDate(dateObj);
  }, [value]);

  // select data
  const handleDateChange = (value: Date | string | null | undefined) => {
    const dateObj = tryToGenerateDateFromAnything(value);
    let formattedDate = null;

    if (dateObj) {
      formattedDate = composeDate(dateObj, format(DATETIME_FORMAT_TO_PASS));
      setSelectedDate(dateObj);
    } else if (value === '' || value === null) {
      setSelectedDate(null);
    }

    onChange(formattedDate);
  };

  React.useEffect(() => {
    setIsOpen(open ?? false);
  }, [open]);

  return (
    <FormControl>
      <FormLabel>
        <Typography
          level="text_sm"
          fontWeight="medium"
          textColor="colors.text.text_secondary.main"
        >
          {label}
        </Typography>
      </FormLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Backdrop
          sx={{ backgroundColor: 'transparent', zIndex: 9 }}
          open={isOpen}
          onClick={() => setIsOpen(false)}
        />
        <DateTimePicker
          {...rest}
          open={isOpen}
          onClose={(...data) => {
            setIsOpen(false);
            onClose && onClose(...data);
          }}
          onOpen={(...data) => {
            setIsOpen(true);
            onOpen && onOpen(...data);
          }}
          ampm={false}
          value={selectedDate}
          format={DATETIME_FORMAT_TO_SHOW}
          sx={{ bgcolor: 'common.white', borderRadius: 1, ...sx }}
          slots={slots}
          slotProps={propsForSlot}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
