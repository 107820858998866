import React from 'react';
import { Sheet, useTheme } from '@mui/joy';
import { IRowsPerPageProps, RowsPerPage } from './RowsPerPage';
import { CurrentViewDetails } from './CurrentViewDetails';
import {
  IPaginationButtonsProps,
  PaginationButtons,
} from './PaginationButtons';

interface IEnhancedTablePaginationProps
  extends Pick<IRowsPerPageProps, 'onRowsPerPageChange'>,
    Pick<IPaginationButtonsProps, 'onPageChange'> {
  count: number;
  rowsPerPage: number;
  currentPageNumber: number;
}

export const EnhancedTablePagination = ({
  count,
  rowsPerPage,
  currentPageNumber,
  onRowsPerPageChange,
  onPageChange,
}: IEnhancedTablePaginationProps) => {
  const theme = useTheme();

  const isSmallDevice = window.innerWidth <= theme.breakpoints.values.sm;

  return (
    <Sheet>
      <Sheet
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: isSmallDevice ? 'column-reverse' : 'row',
          height: isSmallDevice ? 'auto' : '56px',
          bgcolor: 'colors.foreground.fg_white',
          px: 0.5,
          overflowX: 'auto',
        }}
      >
        {!isSmallDevice && (
          <RowsPerPage
            count={count}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        )}

        <CurrentViewDetails
          count={count}
          rowsPerPage={rowsPerPage}
          currentPageNumber={currentPageNumber}
          isSmallDevice={isSmallDevice}
        />

        <PaginationButtons
          count={count}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          currentPageNumber={currentPageNumber}
          isSmallDevice={isSmallDevice}
        />
      </Sheet>
    </Sheet>
  );
};
