import React from 'react';
import { Box, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

import './Slate/Editor.css';

import { IWikiPage } from 'src/modules/types/wiki-setup';
import Error404 from 'src/pages/Error404';

interface IProps {
  loading?: boolean;
  error?: boolean;
  page?: IWikiPage;
  disablePageTitle?: boolean;
}

const DetailWikiView: React.FC<IProps> = ({
  page,
  loading,
  error,
  disablePageTitle,
}) => {
  if (error) {
    return <Error404 />;
  }

  return (
    <Box sx={{ width: '100%', padding: '8px 32px' }}>
      {!disablePageTitle && (
        <Typography variant="h3">
          {loading ? <Skeleton /> : page?.title}
        </Typography>
      )}
      <div>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: page?.body.storage.value || '',
            }}
          />
        )}
      </div>
    </Box>
  );
};

export default DetailWikiView;
