import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { IFilterWhere, IHistoricalHookyFolksModel } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';

export const useAbsenteeHistoricalTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      date: {
        value: '',
        property: 'date',
        operator: 'eq' as const,
      },
      week: {
        value: '',
        property: 'week',
        operator: 'eq' as const,
      },
      yearWeek: {
        value: '',
        property: 'yearWeek',
        operator: 'eq' as const,
      },
      shiftId: {
        value: '',
        property: 'shiftId',
        operator: 'eq' as const,
      },
      departmentId: {
        value: '',
        property: 'departmentId',
        operator: 'eq' as const,
      },
      employeeName: {
        value: '',
        property: 'employeeName',
        operator: 'like' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
    };
  }, []);
};

export const useAbsenteeHistoricalComposeWhere = (
  where: Partial<IHistoricalHookyFolksModel>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      ...(where.date
        ? {
            date: where.date,
          }
        : {}),
      ...(where.week
        ? {
            week: where.week,
          }
        : {}),
      ...(where.yearWeek
        ? {
            yearWeek: where.yearWeek,
          }
        : {}),
      ...(where.shiftId
        ? {
            shiftId: where.shiftId,
          }
        : {}),
      ...(where.departmentId
        ? {
            departmentId: where.departmentId,
          }
        : {}),
      ...(where.employeeName
        ? {
            employeeName: {
              like: `%${where.employeeName}%`,
            },
          }
        : {}),
      ...(where.badge
        ? {
            badge: {
              like: `%${where.badge}%`,
            },
          }
        : {}),
    }),
    [
      selectedSites,
      where.siteId,
      where.date,
      where.week,
      where.yearWeek,
      where.shiftId,
      where.departmentId,
      where.employeeName,
      where.badge,
    ],
  );
};

export const useAbsenteeHistorical = (
  reportUrl: string,
  where: IFilterWhere,
  ignoreInitialRequest: boolean,
) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('historicalHookyFolks.date'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('historicalHookyFolks.week'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('historicalHookyFolks.yearWeek'),
      },
      {
        id: 'employeeName',
        orderConfig: { orderBy: 'employeeName' },
        label: t('historicalHookyFolks.employeeName'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('historicalHookyFolks.badge'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('historicalHookyFolks.siteName'),
      },
      {
        id: 'shiftName',
        orderConfig: { orderBy: 'shiftName' },
        label: t('historicalHookyFolks.shiftName'),
      },
      {
        id: 'departmentName',
        orderConfig: { orderBy: 'departmentName' },
        label: t('historicalHookyFolks.departmentName'),
      },
      {
        id: 'active',
        orderConfig: { orderBy: 'active' },
        label: t('historicalHookyFolks.active'),
      },
      {
        id: 'supervisorName',
        orderConfig: { orderBy: 'supervisorName' },
        label: t('historicalHookyFolks.supervisorName'),
      },
      {
        id: 'supervisorEmail',
        orderConfig: { orderBy: 'supervisorEmail' },
        label: t('historicalHookyFolks.supervisorEmail'),
      },
      {
        id: 'defaultShiftStartTime',
        orderConfig: { orderBy: 'defaultShiftStartTime' },
        label: t('historicalHookyFolks.defaultShiftStartTime'),
      },
      {
        id: 'defaultShiftEndTime',
        orderConfig: { orderBy: 'defaultShiftEndTime' },
        label: t('historicalHookyFolks.defaultShiftEndTime'),
      },
      {
        id: 'overnight',
        orderConfig: { orderBy: 'overnight' },
        label: t('historicalHookyFolks.overnight'),
      },
      {
        id: 'scheduled',
        orderConfig: { orderBy: 'scheduled' },
        label: t('historicalHookyFolks.scheduled'),
      },
    ],
    [t],
  );

  return useBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'desc',
    defOrderBy: 'date',
    ignoreInitialRequest,
  });
};
