import { createAction } from 'redux-act';
import {
  ICompleteCreationRequest,
  ICompleteCreationResponse,
  ICountResponse,
  IdsArray,
  IFilter,
  IMe,
  INotificationsPreferences,
  IUserModel,
  IUserPolicy,
  IUserUpdatePasswordRequest,
  IUserUpdateProfileRequest,
  IUserUpdateResponse,
  LockActivateUserRequestType,
  LockUnlockUserRequestType,
  ICreateUserWithAttributesRequest,
  PayloadWithNavigateFunc,
  IUpdateUserWithAttributes,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushUserState = createAction('Flush user data in store');

// Fetching profile data actions
export const meRequest = createAction('Make request to get users profile');
export const meSuccess = createAction<IMe>(
  'User profile has been fetched successfully',
);
export const meFailed = createAction('Cannot fetch user profile');

// Fetching policy data actions
export const myPolicyRequest = createAction(
  'Make request to get current user policy',
);
export const myPolicySuccess = createAction<IUserPolicy>(
  'User policy has been fetched successfully',
);
export const myPolicyFailed = createAction('Cannot fetch user policy');

// Updating profile data actions
export const meUpdateRequest = createAction<IUserUpdateProfileRequest>(
  'Make request to update users profile',
);
export const meUpdateSuccess = createAction<IUserUpdateResponse>(
  'User profile has been updated successfully',
);
export const meUpdateFailed = createAction('Cannot update user profile');

// Updating password actions
export const passwordUpdateRequest = createAction<IUserUpdatePasswordRequest>(
  'Make request to update users password',
);
export const passwordUpdateSuccess = createAction<IUserUpdateResponse>(
  'User password has been updated successfully',
);
export const passwordUpdateFailed = createAction('Cannot update user password');

// Fetch users actions
export const getUserListRequest = createAction<IFilter>(
  'Make request to fetch user list',
);
export const getUserListSuccess = createAction<IUserModel[]>(
  'Users list has been fetched successfully',
);
export const getUserListFailed = createAction('Cannot fetch user list');

// get users count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getUserCountRequest = createAction<IFilter>(
  'Make request to get user count',
);
export const getUserCountSuccess = createAction<ICountResponse>(
  'Users count has been fetched successfully',
);
export const getUserCountFailed = createAction('Cannot get user count');

// delete actions
export const deleteUserRequest = createAction<IdsArray>(
  'Make request to delete users',
);
export const deleteUserFailed = createAction('Cannot delete users');

// Fetch combobox list actions
export const getUserComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of users',
);
export const getUserComboboxListSuccess = createAction<Partial<IUserModel>[]>(
  'Combobox list of users has been fetched successfully',
);
export const getUserComboboxListFailed = createAction(
  'Cannot fetch combobox users list',
);

// Lock a user actions
export const lockUserRequest = createAction<IdsArray>(
  'Make request to lock users',
);
export const lockUserSuccess = createAction<LockUnlockUserRequestType[]>(
  'Users are locked successfully',
);
export const lockUserFailed = createAction('Cannot lock users');

// Unlock a user actions
export const unlockUserRequest = createAction<IdsArray>(
  'Make request to unlock users',
);
export const unlockUserSuccess = createAction<LockUnlockUserRequestType[]>(
  'Users are unlocked successfully',
);
export const unlockUserFailed = createAction('Cannot unlock users');

export const resetPasswordByAdminRequest = createAction<IdsArray>(
  'Make request to reset password by admin',
);
export const resetPasswordByAdminSuccess = createAction(
  'Password was reset successfully',
);
export const resetPasswordByAdminFailed = createAction(
  'Can not reset password',
);

// Activate a user actions
export const activateUserRequest = createAction<IdsArray>(
  'Make request to activate users',
);
export const activateUserSuccess = createAction<LockActivateUserRequestType[]>(
  'Users are activated successfully',
);
export const activateUserFailed = createAction('Cannot activate users');

// Deactivate a user actions
export const deactivateUserRequest = createAction<IdsArray>(
  'Make request to deactivate users',
);
export const deactivateUserSuccess = createAction<
  LockActivateUserRequestType[]
>('Users are deactivated successfully');
export const deactivateUserFailed = createAction('Cannot deactivate users');

// Creation a new user
export const createUserRequest = createAction<
  PayloadWithNavigateFunc<ICreateUserWithAttributesRequest>
>('Make request to create a new user');
export const createUserSuccess = createAction<IUserModel>(
  'New user has been created successfully',
);
export const createUserFailed = createAction('Cannot create a new user');

// Creation a new user
export const updateUsersRequest = createAction<
  PayloadWithNavigateFunc<IUpdateUserWithAttributes[]>
>('Make request to update user');
export const updateUsersFailed = createAction('Cannot update user');

// Complete creation actions
export const completeCreationUserRequest = createAction<
  ICompleteCreationRequest
>('Make request to complete user creation');
export const completeCreationUserSuccess = createAction<
  ICompleteCreationResponse
>('New user has completed his profile successfully');
export const completeCreationUserFailed = createAction(
  'Cannot complete creation for a new user',
);

// Updating notifications preferences actions
export const changeNotificationsPreferencesRequest = createAction<
  INotificationsPreferences
>('Make request to change notifications preferences');
export const changeNotificationsPreferencesSuccess = createAction<
  INotificationsPreferences
>('Notifications preferences has been updated successfully');
export const changeNotificationsPreferencesFailed = createAction(
  'Cannot change notifications preferences',
);

// Prefilled data actions
export const getUserPrefilledDataRequest = createAction<string>(
  'Make request to get prefilled data for completing profile',
);
export const getUserPrefilledDataSuccess = createAction<Partial<IUserModel>>(
  'Prefilled data is fetched successfully',
);
export const getUserPrefilledDataFailed = createAction(
  'Cannot create fetch prefilled data',
);
const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('User').takeExportActions();

export const exportUserRequest = exportReportRequest;
export const exportUserSuccess = exportReportSuccess;
export const exportUserFailed = exportReportFailed;
