export const brand = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
  950: '#062C41',
};

export const warning = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
  950: '#4E1D09',
};

export const error = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
  950: '#55160C',
};

export const success = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#DCFAE6',
  200: '#A9EFC5',
  300: '#75E0A7',
  400: '#47CD89',
  500: '#17B26A',
  600: '#079455',
  700: '#067647',
  800: '#085D3A',
  900: '#074D31',
  950: '#053321',
};

export const gray = {
  25: '#FAFAFA',
  50: '#F5F5F6',
  100: '#F0F1F1',
  200: '#ECECED',
  300: '#CECFD2',
  400: '#94969C',
  500: '#85888E',
  600: '#61646C',
  700: '#333741',
  800: '#1F242F',
  900: '#161B26',
  950: '#0C111D',
};

export const blueLight = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
  950: '#062C41',
};

export const base = {
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'rgba(71, 84, 103, 1)',
};

export const colors = {
  text: {
    text_primary: {
      main: gray[50],
      on_brand: gray[50],
    },
    text_secondary: {
      main: gray[300],
      hover: gray[200],
      on_brand: gray[300],
    },
    text_tertiary: {
      main: gray[400],
    },
    text_placeholder: {
      main: gray[500],
    },
    text_error_primary: {
      main: error[400],
    },
  },
  background: {
    bg_primary: gray[950],
    bg_secondary: gray[900],
    bg_primary_solid: gray[900],
    bg_secondary_solid: gray[600],
    bg_primary_hover: gray[800],
    bg_success_secondary: success[600],
    bg_error_secondary: error[600],
    bg_warning_secondary: warning[600],
    bg_active: gray[800],
    bg_tertiary: gray[800],
    bg_error: error[300],
    bg_warning: warning[300],
  },
  border: {
    border_primary: gray[700],
    border_secondary: gray[800],
    border_tertiary: gray[800],
    border_disabled_subtle: gray[800],
    border_error: error[400],
    border_brand: brand[400],
  },
  avatar: {
    avatar_bg: gray[800],
    avatar_contrast_border: 'rgba(255,255,255,0.08)',
    avatar_focus_border: 'rgba(152, 162, 179, 0.14)',
  },
  foreground: {
    fg_primary: base.white,
    fg_secondary: gray[300],
    fg_white: base.white,
    fg_disabled_subtle: gray[600],
    fg_quinary: gray[500],
    fg_error_primary: error[500],
    fg_senary: gray[600],
    fg_quarterary: gray[400],
  },
  alpha: {
    white_50: `${gray[950]}90`,
  },
  utility: {
    brand: {
      utility_brand_100: blueLight[900],
      utility_brand_600: blueLight[400],
      utility_brand_700: blueLight[300],
      utility_brand_800: blueLight[200],
    },
    error: {
      utility_error_50: error[950],
      utility_error_100: error[900],
      utility_error_200: error[800],
      utility_error_300: error[700],
    },
    success: {
      utility_success_50: success[950],
      utility_success_200: success[800],
    },
    warning: {
      utility_warning_50: warning[950],
      utility_warning_100: warning[900],
      utility_warning_200: warning[800],
    },
  },
  icons: {
    featured_icon_light_fg_error: error[200],
    featured_icon_light_fg_warning: warning[200],
    featured_icon_light_fg_success: success[200],
  },
  buttons: {
    tertiary: {
      button_tertiary_fg: gray[400],
    },
    tertiary_colors: {
      button_tertiary_color_fg: blueLight[300],
    },
  },
};
