import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IRoleModel, IRoleState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IRoleState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  isBase: false,
  list: [],
  comboboxList: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushRoleState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createRoleRequest = (state: IRoleState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createRoleSuccess = (state: IRoleState, payload: IRoleModel) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createRoleFailed = (state: IRoleState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getRoleListRequest = (state: IRoleState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRoleListSuccess = (state: IRoleState, payload: IRoleModel[]) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getRoleListFailed = (state: IRoleState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getRoleCountRequest = (state: IRoleState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRoleCountSuccess = (state: IRoleState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getRoleCountFailed = (state: IRoleState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateRolesRequest = (state: IRoleState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateRolesFailed = (state: IRoleState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getRoleComboboxListRequest = (state: IRoleState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRoleComboboxListSuccess = (
  state: IRoleState,
  payload: Partial<IRoleModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getRoleComboboxListFailed = (state: IRoleState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IRoleState>();

/**
 * Role reducer
 */
export const role = createReducer<IRoleState>({}, defaultState)
  // flush actions
  .on(actions.flushRoleState, flushRoleState)
  // creation actions
  .on(actions.createRoleRequest, createRoleRequest)
  .on(actions.createRoleSuccess, createRoleSuccess)
  .on(actions.createRoleFailed, createRoleFailed)
  // get list actions
  .on(actions.getRoleListRequest, getRoleListRequest)
  .on(actions.getRoleListSuccess, getRoleListSuccess)
  .on(actions.getRoleListFailed, getRoleListFailed)
  // get count actions
  .on(actions.getRoleCountRequest, getRoleCountRequest)
  .on(actions.getRoleCountSuccess, getRoleCountSuccess)
  .on(actions.getRoleCountFailed, getRoleCountFailed)
  // updating actions
  .on(actions.updateRolesRequest, updateRolesRequest)
  .on(actions.updateRolesFailed, updateRolesFailed)
  // get combobox list actions
  .on(actions.getRoleComboboxListRequest, getRoleComboboxListRequest)
  .on(actions.getRoleComboboxListSuccess, getRoleComboboxListSuccess)
  .on(actions.getRoleComboboxListFailed, getRoleComboboxListFailed)
  // export actions
  .on(actions.exportRoleRequest, exportRequest)
  .on(actions.exportRoleSuccess, exportSuccess)
  .on(actions.exportRoleFailed, exportFailed);

export default role;
