import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useTranslation } from 'react-i18next';
import { useValidate } from '../../../../../../modules/utils';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { createWeekBasedInvoiceScheme } from '../../../../../../modules/schemes/invoice';
import { createInvoiceRequest } from '../../../../../../modules/actions';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { IFilterData, IWhere } from 'src/modules/types';

const initialValues = {
  siteId: NaN,
  yearNumber: NaN, // 2022
  weekNumber: NaN, // 43
};

interface ICreateInvoice {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}
export const CreateWeeklyInvoice = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateInvoice) => {
  const { t } = useTranslation();
  const dispatcher = useDispatch();
  const validate = useValidate(createWeekBasedInvoiceScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: ({ siteId, weekNumber, yearNumber }) => {
      dispatcher(
        createInvoiceRequest({
          data: { siteId, weekNumber, yearNumber },
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('invoice.create_form_card_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              label={t('employees.site')}
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              fullWidth
              type="number"
              id="yearNumber"
              name="yearNumber"
              formik={formik}
              variant="outlined"
              label={t('invoice.year_number')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              fullWidth
              type="number"
              id="weekNumber"
              name="weekNumber"
              formik={formik}
              variant="outlined"
              label={t('invoice.week_number')}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
