import {
  IProductionDailyUphBySiteModel,
  IProductionDailyUphBySiteState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionDailyUphBySiteState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionDailyUphBySiteListRequest,
  getListSuccess: getProductionDailyUphBySiteListSuccess,
  getListFailed: getProductionDailyUphBySiteListFailed,
} = reducerCreator.takeListHandlers<
  IProductionDailyUphBySiteState,
  IProductionDailyUphBySiteModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionDailyUphBySiteCountRequest,
  getCountSuccess: getProductionDailyUphBySiteCountSuccess,
  getCountFailed: getProductionDailyUphBySiteCountFailed,
} = reducerCreator.takeCountHandlers<IProductionDailyUphBySiteState>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionDailyUphBySiteRequest,
  exportSuccess: exportProductionDailyUphBySiteSuccess,
  exportFailed: exportProductionDailyUphBySiteFailed,
} = new ExportReducerCreator().takeHandlers<IProductionDailyUphBySiteState>();

/**
 * actually Reducer
 */
export const productionDailyUphBySite = createReducer<
  IProductionDailyUphBySiteState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionDailyUphBySiteListRequest,
    getProductionDailyUphBySiteListRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteListSuccess,
    getProductionDailyUphBySiteListSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteListFailed,
    getProductionDailyUphBySiteListFailed,
  )
  // get count actions
  .on(
    actions.getProductionDailyUphBySiteCountRequest,
    getProductionDailyUphBySiteCountRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteCountSuccess,
    getProductionDailyUphBySiteCountSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteCountFailed,
    getProductionDailyUphBySiteCountFailed,
  )
  // export action
  .on(
    actions.exportProductionDailyUphBySiteRequest,
    exportProductionDailyUphBySiteRequest,
  )
  .on(
    actions.exportProductionDailyUphBySiteSuccess,
    exportProductionDailyUphBySiteSuccess,
  )
  .on(
    actions.exportProductionDailyUphBySiteFailed,
    exportProductionDailyUphBySiteFailed,
  );

export default productionDailyUphBySite;
