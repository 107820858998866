import React from 'react';

import { useChartRequiredUtils } from 'src/components/Charts/common';
import {
  ChartsDashboard,
  ChartsDashboardBody,
  ChartsDashboardChart,
  ChartsDashboardChartsContainer,
} from 'src/components/ChartsDashboard';
import { SumByDepartmentChart } from 'src/pages/TimeKeeping/DailyHours/panels/DailyHrsDashboard/charts/SumByDepartmentChart';
import { usePayboardPaymentsSumChartsData } from 'src/modules/utils';
import { useSelector } from 'react-redux';
import { getIsDailyHrsLoading } from '../../../../../modules/selectors/payboard';

export const DailyHrsDashboardCharts: React.FC = () => {
  const [
    payChart,
    stdMarkupChart,
    costPlusChart,
  ] = usePayboardPaymentsSumChartsData();

  const {
    chartHeight: payChartHeight,
    chartRef: payChartRef,
    toggleFullScreen: payToggleFullscreen,
    exportChart: payExport,
  } = useChartRequiredUtils();

  const {
    chartHeight: stdChartHeight,
    chartRef: stdChartRef,
    toggleFullScreen: stdToggleFullscreen,
    exportChart: stdExport,
  } = useChartRequiredUtils();

  const {
    chartHeight: costChartHeight,
    chartRef: costChartRef,
    toggleFullScreen: costToggleFullscreen,
    exportChart: costExport,
  } = useChartRequiredUtils();

  const inLoading = useSelector(getIsDailyHrsLoading);

  return (
    <ChartsDashboard>
      <ChartsDashboardBody>
        <ChartsDashboardChartsContainer>
          <ChartsDashboardChart>
            <SumByDepartmentChart
              isLoading={inLoading}
              ref={payChartRef}
              chartHeight={payChartHeight}
              data={payChart}
              onToggleFullScreen={payToggleFullscreen}
              onExport={payExport}
            />
          </ChartsDashboardChart>

          <ChartsDashboardChart>
            <SumByDepartmentChart
              isLoading={inLoading}
              ref={stdChartRef}
              chartHeight={stdChartHeight}
              data={stdMarkupChart}
              onToggleFullScreen={stdToggleFullscreen}
              onExport={stdExport}
            />
          </ChartsDashboardChart>
        </ChartsDashboardChartsContainer>

        <ChartsDashboardChartsContainer>
          <ChartsDashboardChart>
            <SumByDepartmentChart
              isLoading={inLoading}
              ref={costChartRef}
              chartHeight={costChartHeight}
              data={costPlusChart}
              onToggleFullScreen={costToggleFullscreen}
              onExport={costExport}
            />
          </ChartsDashboardChart>
        </ChartsDashboardChartsContainer>
      </ChartsDashboardBody>
    </ChartsDashboard>
  );
};
