import React from 'react';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { fetchHRDashboardEmployeeTurnover } from 'src/modules/utils/Api/hrDashboard';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import ColumnChart from 'src/components/Charts/2d/ColumnChart';
import { useTheme } from '@mui/joy';
import {
  getLastNDaysStarEndDates,
  getYDTStarEndDates,
} from 'src/modules/utils/dateWrapper';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { IAutocompleteOption } from 'src/modules/types/autocomplete';

interface IHRDashboardEmployeeTurnoverProps {
  siteId?: number;
  shiftId?: number;
}

export const HRDashboardEmployeeTurnover = ({
  siteId,
  shiftId,
}: IHRDashboardEmployeeTurnoverProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const chartTypeOptions = React.useMemo(
    () => [
      { id: 'ydt' as const, label: t('common.ydt') },
      { id: 'last30days' as const, label: t('common.last_n_days', { n: 30 }) },
      { id: 'last7days' as const, label: t('common.last_n_days', { n: 7 }) },
    ],
    [t],
  );

  const activeCalculatedByOptions = React.useMemo(
    () => [
      { id: 'total' as const, label: t('common.total') },
      { id: 'shift' as const, label: t('common.by_shift') },
    ],
    [t],
  );

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const [activeCalculatedBy, setActiveCalculatedBy] = React.useState<
    IAutocompleteOption<'shift' | 'total'>
  >(activeCalculatedByOptions[1]);
  const [activeChartType, setActiveChartType] = React.useState<
    IAutocompleteOption<'ydt' | 'last30days' | 'last7days'>
  >(chartTypeOptions[0]);

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRDashboardEmployeeTurnover,
    {
      initialData: [],
    },
  );

  const ydt = getYDTStarEndDates({ suDaysFromEndDate: 1 });
  const last30Days = getLastNDaysStarEndDates(30);
  const last7Days = getLastNDaysStarEndDates(7);

  const categories = React.useMemo(() => {
    if (activeCalculatedBy.id === 'total') {
      return [data[0].siteName];
    }
    return data.map((item) => item.shiftName);
  }, [activeCalculatedBy.id, data]);

  const series = React.useMemo(() => {
    const hiredCommonData = {
      type: 'column' as const,
      name: t('employees.hired'),
      color: theme.palette.success[600],
    };
    const terminatedCommonData = {
      type: 'column' as const,
      name: t('employees.terminated.main'),
      color: theme.palette.colors.foreground.fg_error_primary,
    };

    if (activeCalculatedBy.id === 'total') {
      const { totalHired, totalTerminated } = data.reduce(
        (acc, item) => {
          acc.totalHired += item.hiredTotalCount;
          acc.totalTerminated += item.terminatedTotalCount;

          return acc;
        },
        {
          totalHired: 0,
          totalTerminated: 0,
        },
      );
      return [
        {
          ...hiredCommonData,
          data: [totalHired],
        },
        {
          ...terminatedCommonData,
          data: [totalTerminated],
        },
      ];
    }

    return [
      {
        ...hiredCommonData,
        data: data.map((item) => item.hiredTotalCount),
      },
      {
        ...terminatedCommonData,
        data: data.map((item) => item.terminatedTotalCount),
      },
    ];
  }, [
    t,
    data,
    activeCalculatedBy.id,
    theme.palette.success,
    theme.palette.colors.foreground.fg_error_primary,
  ]);

  React.useEffect(() => {
    let startDate = ydt.startDateFormatted;
    let endDate = ydt.endDateFormatted;
    if (activeChartType.id === 'last30days') {
      startDate = last30Days.startDateFormatted;
      endDate = last30Days.endDateFormatted;
    }
    if (activeChartType.id === 'last7days') {
      startDate = last7Days.startDateFormatted;
      endDate = last7Days.endDateFormatted;
    }

    getData({
      startDate,
      endDate,
      siteId,
      shiftId,
    });
  }, [
    getData,
    siteId,
    shiftId,
    activeChartType,
    ydt.startDateFormatted,
    ydt.endDateFormatted,
    last7Days.startDateFormatted,
    last7Days.endDateFormatted,
    last30Days.startDateFormatted,
    last30Days.endDateFormatted,
  ]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('dashboard.employee_turnover')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`employees_turnover`)}
          />
          <DoubledCombobox>
            <LeftCombobox
              disableClearable
              value={activeCalculatedBy}
              options={activeCalculatedByOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveCalculatedBy(value);
                }
              }}
            />
            <ComboboxSeparator />
            <RightCombobox
              disableClearable
              value={activeChartType}
              options={chartTypeOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveChartType(value);
                }
              }}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <ColumnChart
          fullscreenTitle={t('dashboard.employee_turnover')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
          yAxis={{
            allowDecimals: false,
            title: {
              text: '',
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
