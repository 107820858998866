import { createAction } from 'redux-act';
import {
  DataWithCount,
  IFilter,
  INotificationData,
  INotificationModel,
  PayloadWithFilters,
} from '../../types';

export const getNotificationsListRequest = createAction<IFilter>(
  'Make request to fetch notifications list',
);
export const getNotificationsListSuccess = createAction<
  DataWithCount<INotificationModel> & { countUnread: number }
>('Notifications list has been fetched successfully');
export const getRecentNotificationSuccess = createAction<INotificationModel>(
  'Recent Notification has been fetched successfully',
);
export const getNotificationsListFailed = createAction(
  'Cannot fetch notifications list',
);

export const getMoreNotificationsListRequest = createAction<IFilter>(
  'Make request to fetch more notifications list',
);
export const getMoreNotificationsListSuccess = createAction<
  DataWithCount<INotificationModel> & { countUnread: number }
>('More notifications has been fetched successfully');
export const getMoreNotificationsListFailed = createAction(
  'Cannot fetch more notifications',
);

export const changeNotificationStatusRequest = createAction<INotificationData>(
  'Make request to update notification status (read/unread)',
);
export const changeNotificationStatusSuccess = createAction<INotificationData>(
  'Mark notification as read/unread',
);
export const changeNotificationStatusFailed = createAction(
  'Fail to change notification status (read/unread)',
);

export const changeAllNotificationsStatusRequest = createAction<
  Pick<INotificationModel, 'read'>
>('Make request to mark all notifications as read');
export const changeAllNotificationsStatusSuccess = createAction<
  Pick<INotificationModel, 'read'>
>('Mark all notifications as read success');
export const changeAllNotificationsStatusFailed = createAction(
  'Mark all notifications as read failed',
);

export const deleteNotificationRequest = createAction<
  PayloadWithFilters<number>
>('Delete notification');
export const deleteNotificationSuccess = createAction<number>(
  'Delete notification',
);
export const deleteNotificationFailed = createAction('Delete notification');

export const deleteAllNotificationsRequest = createAction(
  'Delete all notifications',
);
export const deleteAllNotificationsSuccess = createAction(
  'Delete all notifications success',
);
export const deleteAllNotificationsFailed = createAction(
  'Delete all notifications failed',
);
