import React from 'react';
import { Sheet } from '@mui/joy';
import { AnyObject } from 'src/modules/types';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { useTranslation } from 'react-i18next';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SupervisorComboBox from 'src/components/Formik/comboboxes-with-entities/SupervisorComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { useSelector } from 'react-redux';
import { getStaffingProvidersComboboxList } from 'src/modules/selectors/staffingProvider';
import { getShiftsComboboxListBySiteId } from 'src/modules/selectors/shift';
import { getDepartmentsComboboxListBySiteId } from 'src/modules/selectors/department';
import { getPositionsComboboxListBySiteId } from 'src/modules/selectors/position';
import { ComboBoxOption } from 'src/components/ComboBox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { useEmployeeFormTourConfig } from 'src/config/tours/employees';

interface IUpdateEmployeeRequireFieldsProps {
  formik: AnyObject;
  index: number;
}

export const UpdateEmployeeRequireFields = ({
  formik,
  index,
}: IUpdateEmployeeRequireFieldsProps) => {
  const { t } = useTranslation();

  const employeeFormTourConfig = useEmployeeFormTourConfig();

  const selectedSiteId = formik.values[index].siteId;

  // fetch staffing providers list from store
  const staffingProviders = useSelector(getStaffingProvidersComboboxList);

  // fetch shifts from store
  const shifts = useSelector(getShiftsComboboxListBySiteId)(selectedSiteId);

  // fetch departments from store
  const departments = useSelector(getDepartmentsComboboxListBySiteId)(
    selectedSiteId,
  );

  // fetch positions
  const positions = useSelector(getPositionsComboboxListBySiteId)(
    selectedSiteId,
  );

  return (
    <Sheet sx={{ bgcolor: 'inherit' }}>
      <FormFieldContainer>
        <SitesComboBox
          disabled
          id={generateFullIdInMultiEntitiesForm('siteId', index)}
          required={true}
          formik={formik}
          label={t('employees.site')}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.site.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          required
          fullWidth
          id={generateFullIdInMultiEntitiesForm('firstName', index)}
          label={t('employees.first_name')}
          name={generateFullIdInMultiEntitiesForm('firstName', index)}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.firstName.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={generateFullIdInMultiEntitiesForm('lastName', index)}
          label={t('employees.last_name')}
          name={generateFullIdInMultiEntitiesForm('lastName', index)}
          //
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.lastName.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={generateFullIdInMultiEntitiesForm('employeeId', index)}
          label={t('employees.emp_id')}
          name={generateFullIdInMultiEntitiesForm('employeeId', index)}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.employeeId.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={generateFullIdInMultiEntitiesForm('badge', index)}
          label={t('employees.badge')}
          name={generateFullIdInMultiEntitiesForm('badge', index)}
          type="number"
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.badge.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={generateFullIdInMultiEntitiesForm('positionId', index)}
          label={t('employees.position')}
          options={positions as ComboBoxOption[]}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.position.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={generateFullIdInMultiEntitiesForm('staffingId', index)}
          label={t('employees.staffing_provider')}
          options={staffingProviders as ComboBoxOption[]}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.staffingProvider.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <SupervisorComboBox
          required
          activeOnly
          id={generateFullIdInMultiEntitiesForm('supervisorId', index)}
          siteIdPathInFormik={generateFullIdInMultiEntitiesForm(
            'siteId',
            index,
          )}
          label={t('employees.supervisor')}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.supervisor.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          required
          fullWidth
          id={generateFullIdInMultiEntitiesForm('payRate', index)}
          label={t('employees.pay_rate')}
          name={generateFullIdInMultiEntitiesForm('payRate', index)}
          formik={formik}
          placeholder={t('common.add_your_text_here')}
          data-tour={employeeFormTourConfig.tour.payRate.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikDatepicker
          required
          fullWidth
          label={t('employees.date_hired')}
          id={generateFullIdInMultiEntitiesForm('dateHired', index)}
          formik={formik as any}
          data-tour={employeeFormTourConfig.tour.dateHired.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={generateFullIdInMultiEntitiesForm('defaultShiftId', index)}
          label={t('employees.default_shift')}
          options={shifts}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.defaultShift.target}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikCombobox
          required
          id={generateFullIdInMultiEntitiesForm('defaultDepartmentId', index)}
          label={t('employees.default_department')}
          options={departments}
          formik={formik}
          placeholder={t('common.select')}
          data-tour={employeeFormTourConfig.tour.defaultDepartment.target}
        />
      </FormFieldContainer>
    </Sheet>
  );
};
