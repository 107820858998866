import React from 'react';
import { useTranslation } from 'react-i18next';
import { IHeadCellWithOrderConfig, Order } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { ICountResponse, IListWithSummary } from 'src/modules/types';
import {
  useFetchReportTableDataAndCountEffect,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { IEmployeesStatisticResponseItem } from 'src/modules/types/executive-reports';

const employeesStatisticDefaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'yearWeek',
};

interface IEmployeesStatisticProps {
  year?: number;
  week?: number;
}

export const EmployeesStatisticTable = ({
  year,
  week,
}: IEmployeesStatisticProps) => {
  const { t } = useTranslation();

  const reportProps = useReportsProps();

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<IEmployeesStatisticResponseItem>
  >(reportProps.employeesStatistic.resourceUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${reportProps.employeesStatistic.resourceUrl}/count`,
    { count: 0 },
  );

  const where = React.useMemo(
    () => ({
      week: week ? week : undefined,
      year: year ? year : undefined,
    }),
    [week, year],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        label: t('productions_uph_reports.year'),
        orderConfig: { orderBy: 'year' },
      },
      {
        id: 'week',
        label: t('productions_uph_reports.week'),
        orderConfig: { orderBy: 'week' },
      },
      {
        id: 'hiredHeadcount',
        label: t('productions_uph_reports.hired_headcount'),
        orderConfig: { orderBy: 'hiredHeadcount' },
      },
      {
        id: 'inactivatedHeadcount',
        label: t('productions_uph_reports.inactivated_headcount'),
        orderConfig: { orderBy: 'inactivatedHeadcount' },
      },
      {
        id: 'withApprovedHoursHeadcount',
        label: t('productions_uph_reports.active'),
        orderConfig: { orderBy: 'withApprovedHoursHeadcount' },
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: employeesStatisticDefaultOrderDetails.order,
    defaultOrderBy: employeesStatisticDefaultOrderDetails.orderBy,
  });

  const handleChangeRowsPerPage = (limit: number) => {
    setLimit(limit);

    setPage(DEFAULT_PAGE);

    fetchData({
      filter: {
        where,
        offset: DEFAULT_PAGE * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handleSort = (orderBy: string, order: Order) => {
    setOrderBy(orderBy);
    setOrder(order);

    fetchData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);

    fetchData({
      filter: {
        where,
        offset: page * limit,
        limit: limit,
        order: [`${orderBy} ${order}`],
      },
    });
  };

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? employeesStatisticDefaultOrderDetails.order,
    orderBy: orderBy ?? employeesStatisticDefaultOrderDetails.orderBy,
  });

  return (
    <ReportTable
      data={data.items as any}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey="executivesReports/employeesStatistic"
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
