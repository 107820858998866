import { i18n } from '../../utils/i18n';

export const updatePageSettingScheme = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: ['route', 'policies'],
    properties: {
      route: {
        type: 'string',
        errorMessage: {
          type: i18n.t('error.validation.required'),
        },
      },
      policies: {
        type: 'array',
        items: {
          type: 'object',
          required: ['resource', 'action'],
          properties: {
            id: {
              type: 'string',
              errorMessage: {
                type: i18n.t('error.validation.required'),
              },
            },
            resource: {
              type: 'string',
              errorMessage: {
                type: i18n.t('error.validation.required'),
              },
            },
            action: {
              type: 'string',
              enum: ['view', 'create', 'update', 'delete'],
              errorMessage: {
                type: i18n.t('error.validation.required'),
                enum: i18n.t('error.validation.required'),
              },
            },
            clientKey: { type: 'string', nullable: true },
          },
        },
      },
    },
  },
};
