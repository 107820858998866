import React from 'react';
import { Drawer } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import {
  IdsArray,
  IIncentivePriceModel,
  IIncentivePriceUpdateRequest,
} from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { IncentiveMetaTags } from './components/IncentivePriceMetaTags';
import { updateIncentivePriceScheme } from 'src/modules/schemes/incentive/update-incentive-price.scheme';
import { map } from 'lodash';
import { Api } from '../../../../modules/utils';

interface IIncentivePriceUpdateProps {
  isOpen: boolean;
  ids: IdsArray;
  onUpdate: () => void;
  onClose: () => void;
}

export const IncentivePriceUpdate: React.FC<IIncentivePriceUpdateProps> = ({
  isOpen,
  ids,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = React.useState<
    Array<IIncentivePriceModel>
  >([]);

  React.useEffect(() => {
    if (isOpen) {
      Api.IncentivePrice.listWithMeta({
        filter: { where: { id: { inq: ids } } },
      }).then((data) => setInitialValues(data));
    }
  }, [ids, isOpen]);

  const validate = useValidate(updateIncentivePriceScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IIncentivePriceUpdateRequest) => {
      await Api.IncentivePrice.bulkUpdate(data);
      formik.resetForm();
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('incentivePrice.create_form_title')}
      </DrawerHeader>
      <DrawerBody>
        {map(formik.values, (incentivePrice, index) => (
          <DrawerBodyContent key={incentivePrice.id}>
            <FormFieldContainer>
              <SitesComboBox
                id={`${index}.siteId`}
                required={true}
                formik={formik}
                placeholder={t('incentivePrice.site')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id={`${index}.price`}
                label={t('incentivePrice.price')}
                name={`${index}.price`}
                formik={formik}
                type="number"
              />
            </FormFieldContainer>

            <IncentiveMetaTags formik={formik} index={index} />
          </DrawerBodyContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
