import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useRemovalReasonsOptions, useValidate } from 'src/modules/utils';
import { useDispatch } from 'react-redux';
import { removePointsRequest } from 'src/modules/actions';
import { IFilterData, IPointModel, IWhere } from 'src/modules/types';
import { removePointsSchema } from 'src/modules/schemes/points/remove-point.scheme';
import { IImageData } from 'src/components/UploadImages';
import { TextField } from 'src/components/_ui-kit/TextField';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';

interface IRemovePointsProps {
  points: Array<IPointModel>;
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const RemovePoints = ({
  isOpen,
  onClose,
  filterCount,
  filterList,
  points,
}: IRemovePointsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const initialValues = React.useMemo(() => {
    return points.map((point) => ({
      id: point.id,
      removalReason: null,
    }));
  }, [points]);

  const removalReasonOptions = useRemovalReasonsOptions();

  const validate = useValidate(removePointsSchema);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: initialValues,
    validate,
    onSubmit: (data) => {
      const formDataArray = data.map((item) => {
        const formData = new FormData();

        Object.keys(item).forEach((fieldName) => {
          if (fieldName === 'removalReasonAttachments') {
            item.removalReasonAttachments?.forEach(({ file }: IImageData) => {
              if (file) {
                formData.append(String(item.id), file);
              }
            });
          } else {
            formData.append(fieldName, item[fieldName]);
          }
        });

        return formData;
      });

      dispatch(
        removePointsRequest({
          data: formDataArray,
          filters: {
            list: filterList,
            count: filterCount,
          },
        }),
      );

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('points.remove_points')}
      </DrawerHeader>
      <DrawerBody>
        {points.map((point, index) => (
          <DrawerBodySectionContent key={point.id}>
            <DrawerBodySectionTitle>
              {`${point.employee?.firstName} ${point.employee?.lastName} (${point.employee?.badge})`}
            </DrawerBodySectionTitle>
            <FormFieldContainer>
              <TextField
                disabled
                fullWidth
                label={'Datetime'}
                value={point.datetime}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <TextField
                disabled
                fullWidth
                label={'Point Type'}
                value={point.pointType?.reason}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id={generateFullIdInMultiEntitiesForm(`removalReason`, index)}
                label={t('points.removal_reason')}
                options={(removalReasonOptions as unknown) as ComboBoxOption[]}
                formik={formik}
                placeholder={t('common.select')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            {/**Temporary hidden: view https://nsite.atlassian.net/browse/NSIT-2226 for details */}
            {/* <FormikImagesUpload
              name="removalReasonAttachments"
              id="removalReasonAttachments"
              formik={formik}
            /> */}
          </DrawerBodySectionContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
