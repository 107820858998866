import {
  Action,
  applyMiddleware,
  CombinedState,
  combineReducers,
  createStore,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './reducers';

import rootSaga from './sagas';
import * as actions from './actions';
import { removeItemFromLocalStorage } from './utils/localStorageWrapper';

const { NODE_ENV } = process.env;

let store: Store;
const sagaMiddleware = createSagaMiddleware();

// all application reducers
const appReducer = combineReducers(reducers);

// root reducer just to be able to clear entire state
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: CombinedState<any>, action: Action) => {
  // clear all state on logout action
  if (action.type === actions.logoutSuccess.getType()) {
    removeItemFromLocalStorage('globallySelectedSites');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

if (NODE_ENV === 'development') {
  // enable dev tools extension for development mode
  store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );
} else {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
}

export default store;

sagaMiddleware.run(rootSaga);
