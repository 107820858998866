import React from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalBarChart } from 'src/components/Charts/2d/HorizontalBarChart';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { fetchProjectionVsActualRevenuePercentageYDTChartData } from 'src/modules/utils/Api/projectionVsActualChartsDashboardDataRequests';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import {
  financialColor,
  projectionColor,
} from '../FinancialDashboardTotalsChart/constants';

export const FinancialDashboardRevenueYDTPercentageChart = () => {
  const { t } = useTranslation();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const { getData, isLoading, data } = useGetDataForCharts(
    fetchProjectionVsActualRevenuePercentageYDTChartData,
    {
      initialData: [],
    },
  );

  const clients = React.useMemo(() => data.map((item) => item.clientName), [
    data,
  ]);

  const currentYearNumber = new Date().getFullYear();

  const series = React.useMemo(() => {
    return [
      {
        name: `Sum of YTD ${currentYearNumber} Revenue`,
        type: 'bar' as const,
        color: financialColor,
        data: data.map((item) => item.revenuePercentage),
      },
      {
        name: `Sum of YTD ${currentYearNumber} Proj Revenue`,
        type: 'bar' as const,
        color: projectionColor,
        data: data.map((item) => item.projectionRevenuePercentage),
      },
    ];
  }, [currentYearNumber, data]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t(
          'financial_reporting_dashboard.chart_title.revenue_vs_projections_by_account',
        )}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`revenue_vs_projections_by_account`)}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <HorizontalBarChart
          ref={chartRef}
          height={chartHeight}
          categories={clients}
          series={series}
          plotOptions={{
            series: {
              dataLabels: {
                enabled: true,
                format: '{y} %',
              },
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
