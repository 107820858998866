import React from 'react';
import { ButtonProps, Sheet, Button } from '@mui/joy';

interface IPaginationButtonProps extends ButtonProps {
  isActive?: boolean;
  isThreeDots?: boolean;
  children: React.ReactNode;
  isSmallDevice: boolean;
}

export const PaginationButton = ({
  children,
  isActive,
  isThreeDots,
  isSmallDevice,
  ...props
}: IPaginationButtonProps) => {
  return (
    <Sheet sx={{ mx: '1px', bgcolor: 'inherit' }}>
      <Button
        sx={{
          color: 'colors.text.text_tertiary.main',
          minWidth: isSmallDevice ? 20 : 40,
          minHeight: isSmallDevice ? 20 : 40,
          px: isSmallDevice ? '3px' : '6px',
          py: isSmallDevice ? '8px' : '16px',
          cursor: isThreeDots ? 'default' : 'pointer',
          bgcolor: isActive ? 'colors.background.bg_primary_hover' : 'inherit',
          '&:hover': {
            bgcolor: isThreeDots
              ? 'transparent'
              : 'colors.background.bg_primary_hover',
          },
        }}
        {...props}
      >
        {children}
      </Button>
    </Sheet>
  );
};
