import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { IEmployeesTerminatedModel, IFilterWhere } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import { useBaseReport } from 'src/modules/utils/hooks/reports.hooks';

export const useTerminatedEmployeesTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      firstName: {
        value: '',
        property: 'firstName',
        operator: 'like' as const,
      },
      lastName: {
        value: '',
        property: 'lastName',
        operator: 'like' as const,
      },
      supervisorName: {
        value: '',
        property: 'supervisorName',
        operator: 'like' as const,
      },
      badge: {
        value: '',
        property: 'badge',
        operator: 'like' as const,
      },
      employeeId: {
        value: '',
        property: 'employeeId',
        operator: 'like' as const,
      },
      supervisorId: {
        value: '',
        property: 'supervisorId',
        operator: 'eq' as const,
      },
      shiftId: {
        value: '',
        property: 'shiftId',
        operator: 'eq' as const,
      },
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      staffingProvider: {
        value: '',
        property: 'staffingProvider',
        operator: 'like' as const,
      },
      separationdate: {
        value: '',
        property: 'separationdate',
        operator: 'eq' as const,
      },
      terminationReasonName: {
        value: '',
        property: 'terminationReasonName',
        operator: 'eq' as const,
      },
    };
  }, []);
};

export const useTerminatedEmployeesComposeWhere = (
  where: Partial<IEmployeesTerminatedModel>,
) => {
  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  return React.useMemo<IFilterWhere>(
    () => ({
      ...(selectedSites.length || where.siteId
        ? {
            siteId: {
              inq: where.siteId
                ? [where.siteId]
                : selectedSites.length
                ? selectedSites
                : [],
            },
          }
        : {}),
      ...(where.firstName
        ? {
            firstName: {
              like: `%${where.firstName}%`,
            },
          }
        : {}),
      ...(where.lastName
        ? {
            lastName: {
              like: `%${where.lastName}%`,
            },
          }
        : {}),
      ...(where.supervisorName
        ? {
            supervisorName: {
              like: `%${where.supervisorName}%`,
            },
          }
        : {}),
      ...(where.badge
        ? {
            badge: {
              like: `%${where.badge}%`,
            },
          }
        : {}),
      ...(where.employeeId
        ? {
            employeeId: {
              like: `%${where.employeeId}%`,
            },
          }
        : {}),
      ...(where.staffingProvider
        ? {
            staffingProvider: {
              like: `%${where.staffingProvider}%`,
            },
          }
        : {}),
      ...(where.terminationReasonName
        ? {
            terminationReasonName: where.terminationReasonName,
          }
        : {}),
      ...(where.shiftId
        ? {
            shiftId: where.shiftId,
          }
        : {}),
      ...(where.separationdate
        ? {
            separationdate: where.separationdate,
          }
        : {}),
    }),
    [
      selectedSites,
      where.siteId,
      where.firstName,
      where.lastName,
      where.supervisorName,
      where.badge,
      where.employeeId,
      where.staffingProvider,
      where.terminationReasonName,
      where.shiftId,
      where.separationdate,
    ],
  );
};

export const useTerminatedEmployees = (
  reportUrl: string,
  where: IFilterWhere,
) => {
  const { t } = useTranslation();

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'id',
        orderConfig: { orderBy: 'id' },
        label: t('employees.terminated.id'),
      },
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('employees.terminated.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('employees.terminated.last_name'),
      },
      {
        id: 'supervisorName',
        orderConfig: { orderBy: 'supervisorName' },
        label: t('employees.terminated.supervisorName'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('employees.terminated.badge'),
      },
      {
        id: 'employeeId',
        orderConfig: { orderBy: 'employeeId' },
        label: t('employees.terminated.emp_id'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('employees.terminated.site'),
      },
      {
        id: 'staffingProvider',
        orderConfig: { orderBy: 'staffingProvider' },
        label: t('employees.terminated.staffing_provider'),
      },
      {
        id: 'shiftName',
        orderConfig: { orderBy: 'shiftName' },
        label: t('employees.terminated.shift'),
      },
      {
        id: 'separationdate',
        orderConfig: { orderBy: 'separationdate' },
        label: t('employees.terminated.separationdate'),
      },
      {
        id: 'terminationReasonName',
        orderConfig: { orderBy: 'terminationReasonName' },
        label: t('employees.terminated.termination_reason_name'),
      },
      {
        id: 'maxLoggedTime',
        orderConfig: { orderBy: 'maxLoggedTime' },
        label: t('employees.terminated.max_logged_time'),
      },
    ],
    [t],
  );

  return useBaseReport({
    reportUrl,
    headCellsConfig,
    where,
    defOrder: 'asc',
    defOrderBy: 'firstName',
  });
};
