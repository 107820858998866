import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../../../assets/lang/en.json';
import es from '../../../assets/lang/es.json';

export enum Lang {
  EN = 'en',
  ES = 'es',
}

class I18n {
  constructor() {
    this.init();
  }

  private init() {
    i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
        resources: { en, es },
        fallbackLng: Lang.EN,
        interpolation: {
          escapeValue: false,
        },
        debug: process.env.NODE_ENV === 'development',
        detection: {
          order: ['localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
          lookupLocalStorage: 'i18nextLng',

          // cache user language
          caches: ['localStorage'],
          excludeCacheFor: ['cimode'],
        },
      });
  }

  public t(...params: Parameters<typeof i18next.t>) {
    return i18next.t(...params);
  }

  public et(scope: string, err: string) {
    const errKey = err.replace(/\./g, '_');

    return (
      i18next.t(`error.api.${scope}.${errKey}`, '') ||
      i18next.t(`error.api.${errKey}`, err)
    );
  }

  public on(...params: Parameters<typeof i18next.on>) {
    i18next.on(...params);
  }

  public changeLanguage(...params: Parameters<typeof i18next.changeLanguage>) {
    i18next.changeLanguage(...params);
  }
}

export const i18n = new I18n();
