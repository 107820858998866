import { matchPath, useLocation } from 'react-router-dom';
import { paths } from '../../../config';
import isFunction from 'lodash/isFunction';

// sometimes we need to match path instead of patter
// for example, we need to use it as a patter /recruiting-dashboard/:siteId, since siteId is quite dynamic
// but we cannot use /:clientKey/import/upload and we must use /optoro/import/upload instead
export const pathPatternExceptions = [/\/import\/upload/];

export const usePathPattern = (): string => {
  const { pathname } = useLocation();

  const exception = pathPatternExceptions.find((p) => p.test(pathname));

  if (exception) {
    return pathname;
  }

  for (const pattern of Object.values(paths)) {
    const ptrn = isFunction(pattern) ? pattern() : pattern;
    const match = matchPath(ptrn as string, pathname);
    if (match) {
      return ptrn;
    }
  }

  return '';
};
