import React from 'react';
import { Box, Theme } from '@mui/material';
import { MultiRowFormContext } from './MultiRowFormContext';
import { MultiRowFormRow } from './MultiRowFormRow';

export * from './MultiRowFormField';
export * from './MultiRowFormContainer';
export * from './MultiRowFormRow';
export * from './MultiRowFormDeleteRow';

interface IMultiRowFormProps {
  onSubmit: (data: any) => void;
  noValidate?: boolean;
  columnDirectionMaxWidth?: number;
}

export const MultiRowForm: React.FC<IMultiRowFormProps> = ({
  children,
  onSubmit,
  noValidate,
  columnDirectionMaxWidth,
}) => {
  const {
    headerLabels,
    setColumnDirectionMaxWidth,
    columnDirectionMaxWidth: _columnDirectionMaxWidth,
  } = React.useContext(MultiRowFormContext);

  React.useEffect(() => {
    if (columnDirectionMaxWidth) {
      setColumnDirectionMaxWidth(columnDirectionMaxWidth);
    }
  }, [columnDirectionMaxWidth, setColumnDirectionMaxWidth]);

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      noValidate={noValidate}
      sx={(theme: Theme) => ({
        display: 'grid',
        gap: 1,
        [theme.breakpoints.down(_columnDirectionMaxWidth)]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
        },
      })}
    >
      <MultiRowFormRow
        sx={(theme: Theme) => ({
          backgroundColor: 'rgb(230, 230, 230)',
          borderRadius: 0.5,
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr) min-content',
          [theme.breakpoints.down(Number(Number(_columnDirectionMaxWidth)))]: {
            display: 'none',
          },
        })}
      >
        {headerLabels.map((label) => (
          <Box
            sx={{
              fontWeight: 'bold',
              fontSize: '1.2em',
              textAlign: 'center',
              padding: '8px 4px',
            }}
            key={label}
          >
            {label}
          </Box>
        ))}
      </MultiRowFormRow>
      {children}
    </Box>
  );
};
