import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreatePolicyByOperationRequest,
  ICreatePolicyByPageRequest,
  ICreatePolicyRequest,
  IdsArray,
  IFilter,
  IPolicyModel,
  IUpdatePolicy,
  PayloadWithNavigateFunc,
} from '../../types';

// Flush actions
export const flushPolicyState = createAction('Flush policy data in store');

// Creation actions
export const createPolicyRequest = createAction<
  PayloadWithNavigateFunc<Array<ICreatePolicyRequest>>
>('Make request to create a new policy');
export const createPolicySuccess = createAction<IPolicyModel>(
  'New policy has been created successfully',
);
export const createPolicyFailed = createAction('Cannot create a new policy');

// Creation actions
export const createPolicyByPageRequest = createAction<
  Array<ICreatePolicyByPageRequest>
>('Make request to create a new policy by page');
export const createPolicyByPageSuccess = createAction<IPolicyModel[]>(
  'New policies have been created successfully',
);
export const createPolicyByPageFailed = createAction(
  'Cannot create new policies by page',
);

// Creation actions
export const createPolicyByOperationRequest = createAction<
  Array<ICreatePolicyByOperationRequest>
>('Make request to create a new policy by Operation');
export const createPolicyByOperationSuccess = createAction<IPolicyModel[]>(
  'New policies have been created successfully',
);
export const createPolicyByOperationFailed = createAction(
  'Cannot create new policies by Operation',
);

// Fetch policys actions
export const getPolicyListRequest = createAction<IFilter>(
  'Make request to fetch policy list',
);
export const getPolicyListSuccess = createAction<IPolicyModel[]>(
  'Policy list has been fetched successfully',
);
export const getPolicyListFailed = createAction('Cannot fetch policy list');

// get policys count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getPolicyCountRequest = createAction<IFilter>(
  'Make request to get policy count',
);
export const getPolicyCountSuccess = createAction<ICountResponse>(
  'Policy count has been fetched successfully',
);
export const getPolicyCountFailed = createAction('Cannot get policy count');

// delete actions
export const deletePolicyRequest = createAction<IdsArray>(
  'Make request to delete policys',
);
export const deletePolicyFailed = createAction('Cannot delete policys');

// Updating actions
export const updatePolicyRequest = createAction<
  PayloadWithNavigateFunc<IUpdatePolicy[]>
>('Make request to update policys');
export const updatePolicyFailed = createAction('Cannot update policys');

// Fetch combobox list actions
export const getPolicyComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of policys',
);
export const getPolicyComboboxListSuccess = createAction<
  Partial<IPolicyModel>[]
>('Combobox list of policys has been fetched successfully');
export const getPolicyComboboxListFailed = createAction(
  'Cannot fetch combobox policys list',
);
