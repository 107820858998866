import { JSONSchemaType } from 'ajv';

import { i18n } from 'src/modules/utils/i18n';
import { IIncentivePriceUpdateRequest } from 'src/modules/types';
import { createIncentivePriceScheme } from './create-incentive-price.scheme';

export const updateIncentivePriceScheme: JSONSchemaType<IIncentivePriceUpdateRequest> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createIncentivePriceScheme.required,
    properties: {
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createIncentivePriceScheme.properties,
    },
  },
};
