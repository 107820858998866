import { IStoreState } from '../types';

export const getRecruitingDashboardDetailsData = (state: IStoreState) =>
  state.recruitingDashboardDetails;

/**
 * Get recruiting dashboard details list
 * @param recruitingDashboardDetails - State recruitingDashboardDetails
 */
export const getRecruitingDashboardDetailsList = ({
  recruitingDashboardDetails,
}: IStoreState) => recruitingDashboardDetails.list;

/**
 * Get recruiting dashboard details count
 * @param recruitingDashboardDetails - State recruitingDashboardDetails
 */
export const getRecruitingDashboardDetailsCount = ({
  recruitingDashboardDetails,
}: IStoreState) => recruitingDashboardDetails.count;
