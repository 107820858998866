import { ISagaActionBind } from '../../types';
import { IProductionDailyUphBySiteModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionDailyUphBySiteListRequest,
    saga: createListSaga<IProductionDailyUphBySiteModel>({
      apiCall: Api.ProductionDailyUphBySite.list,
      actionSuccess: actions.getProductionDailyUphBySiteListSuccess,
      actionFailed: actions.getProductionDailyUphBySiteListFailed,
    }),
  },
  {
    action: actions.getProductionDailyUphBySiteCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionDailyUphBySite.count,
      actionSuccess: actions.getProductionDailyUphBySiteCountSuccess,
      actionFailed: actions.getProductionDailyUphBySiteCountFailed,
    }),
  },
  {
    action: actions.exportProductionDailyUphBySiteRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionDailyUphBySite.export,
      actionSuccess: actions.exportProductionDailyUphBySiteSuccess,
      actionFailed: actions.exportProductionDailyUphBySiteFailed,
    }),
  },
];
