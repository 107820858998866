import React from 'react';
import { useSelected, useFocused } from 'slate-react';

const Image = ({ attributes, element, children }: any) => {
  const { url, width, height } = element;
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div
      {...attributes}
      className="element-image"
      style={{
        display: 'flex',
        justifyContent: 'center',
        boxShadow: selected && focused && '0 0 3px 3px lightgray',
      }}
    >
      <div contentEditable={false}>
        <img alt={element.alt} src={url} width={width} height={height} />
      </div>
      {children}
    </div>
  );
};
export default Image;
