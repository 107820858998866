import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import { IDeleteRelationState, IDeleteRelationPayload } from '../../types';

// default state
const defaultState: IDeleteRelationState = {
  list: {},
};

/**
 * Get list actions
 */
const getDeleteRelationsRequest = (state: IDeleteRelationState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getDeleteRelationsSuccess = (
  state: IDeleteRelationState,
  payload: IDeleteRelationPayload,
) => ({
  ...state,
  list: { ...state.list, ...{ [payload.modelName]: payload.relations } },
  error: false,
  is_data_requested: false,
});

const getDeleteRelationsFailed = (state: IDeleteRelationState) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Delete relations reducer
 */
export const deleteRelations = createReducer<IDeleteRelationState>(
  {},
  defaultState,
)
  // get list actions
  .on(actions.getDeleteRelationsRequest, getDeleteRelationsRequest)
  .on(actions.getDeleteRelationsSuccess, getDeleteRelationsSuccess)
  .on(actions.getDeleteRelationsFailed, getDeleteRelationsFailed);

export default deleteRelations;
