import React, { useEffect, useMemo } from 'react';
import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { shallowEqual, useSelector } from 'react-redux';
import { map } from 'lodash';
import { useFetchCostPlusTypes } from '../../../../modules/utils/hooks/cost-plus-settings.hooks';
import { getCostPlusTypes } from '../../../../modules/selectors/cost-plus-settings.selector';

interface ICostPlusTypesComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
}

const CostPlusTypesComboBox: React.FC<ICostPlusTypesComboboxProps> = (
  props,
) => {
  const fetchCostPlusTypes = useFetchCostPlusTypes();

  // make request to fetch types from the server if we don't have them in the store
  useEffect(() => {
    fetchCostPlusTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const costPlusTypes = useSelector(getCostPlusTypes, shallowEqual);

  const options = (useMemo(
    () =>
      map(costPlusTypes, (costPlusType) => ({
        id: costPlusType,
        name: costPlusType,
      })),
    [costPlusTypes],
  ) as unknown) as ComboBoxOption[];

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default CostPlusTypesComboBox;
