import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { fetchHRDashboardActualVsNeededChartData } from 'src/modules/utils/Api/hrDashboard';
import { PieChart } from 'src/components/Charts/2d/PieChart';
import { round } from 'lodash';

interface IHRDashboardActiveByShiftProps {
  siteId?: number;
  shiftId?: number;
}

export const HRDashboardActiveByShift = ({
  siteId,
  shiftId,
}: IHRDashboardActiveByShiftProps) => {
  const { t } = useTranslation();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const { data, getData, isLoading } = useGetDataForCharts(
    fetchHRDashboardActualVsNeededChartData,
    {
      initialData: [],
    },
  );

  const series = React.useMemo(() => {
    const totalActiveEmployees = data.reduce((total, item) => {
      total += item.totalActiveEmployees;

      return total;
    }, 0);

    return [
      {
        type: 'pie' as const,
        innerSize: '40%',
        data: data.map((item) => ({
          name: item.shiftName,
          y: round((item.totalActiveEmployees * 100) / totalActiveEmployees, 2),
        })),
      },
    ];
  }, [data]);

  React.useEffect(() => {
    getData({ siteId, shiftId });
  }, [getData, siteId, shiftId]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('dashboard.active_employees_by_shift')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('active_by_shift')}
          />
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <PieChart
          fullscreenTitle={t('dashboard.active_employees_by_shift')}
          ref={chartRef}
          height={chartHeight}
          series={series}
          plotOptions={{
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              showInLegend: true,
              dataLabels: {
                style: {
                  fontSize: '10px',
                },
                enabled: true,
                format: '<b>{point.y}</b>%',
                distance: '10%',
              },
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
