import React from 'react';
import { Report, ReportPage } from 'src/components/ReportPage';
import { clients } from 'src/modules/constants';
import { useReportsProps } from 'src/modules/utils/hooks/reportPage.hooks';
import { WeeklyStaticCostPlus } from './WeeklyStaticCostPlus';
import { WeeklyGrossMargin } from './WeeklyGrossMargin';
import { WeeklyGrossMarginDetailed } from './WeeklyGrossMarginDetailed';

const brookfieldClient = clients.brookfield;

export const BrookfieldReports = () => {
  const reportsProps = useReportsProps(
    `productions/reports/clients/${brookfieldClient}`,
  );

  return (
    <ReportPage client={brookfieldClient}>
      <Report {...reportsProps.weeklyStaticCostPlus}>
        <WeeklyStaticCostPlus />
      </Report>
      <Report {...reportsProps.weeklyGrossMargin}>
        <WeeklyGrossMargin />
      </Report>
      <Report {...reportsProps.weeklyGrossMarginDetailed}>
        <WeeklyGrossMarginDetailed />
      </Report>
    </ReportPage>
  );
};
