import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Theme } from '@mui/material';

interface IChartsGridProps {
  charts: React.ReactElement[];
}

export const ChartsGrid: React.FC<IChartsGridProps> = ({ charts }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {charts.map((chart, i) => (
          <Grid key={i} item xs={12} sm={6}>
            <Paper
              sx={{
                padding: (theme: Theme) => theme.spacing(2),
                textAlign: 'center',
                color: (theme: Theme) => theme.palette.text.secondary,
              }}
            >
              {chart}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
