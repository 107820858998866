import { RolesType } from '../modules/types';
import { values } from 'lodash';

export const roles = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  GM: 'gm',
  OPERATIONAL_GM: 'operational-gm',
  ACCOUNTING: 'accounting',
  RECRUITER: 'recruiter',
  STAFFING: 'staffing',
  HOURS_VIEWER: 'hours-viewer',
  SUPERVISOR: 'supervisor',
  SUPERVISOR_3PL: '3-pl-supervisor',
  LEAD_SUPERVISOR: 'lead-supervisor',
  EXECUTIVE: 'executive',
  CUSTOMER: 'customer',
  DIRECTOR: 'director',
  GUEST: '',
};

/**
 * Get allowed role for current user
 * @param currentRole - Role for current user
 */
export const getAllowedRoles = (currentRole: RolesType) => {
  switch (currentRole) {
    case roles.SUPER_ADMIN:
      return values(roles);
    case roles.ADMIN:
      return [
        roles.GM,
        roles.OPERATIONAL_GM,
        roles.RECRUITER,
        roles.STAFFING,
        roles.SUPERVISOR,
        roles.HOURS_VIEWER,
        roles.CUSTOMER,
        roles.LEAD_SUPERVISOR,
        roles.EXECUTIVE,
        roles.DIRECTOR,
      ];
    case roles.GM:
      return [
        roles.GM,
        roles.OPERATIONAL_GM,
        roles.RECRUITER,
        roles.STAFFING,
        roles.HOURS_VIEWER,
        roles.SUPERVISOR,
        roles.CUSTOMER,
        roles.LEAD_SUPERVISOR,
        roles.EXECUTIVE,
        roles.DIRECTOR,
      ];
    default:
      return [];
  }
};

export const supervisorRoles = [
  roles.LEAD_SUPERVISOR,
  roles.SUPERVISOR,
  roles.SUPERVISOR_3PL,
  roles.GM,
  roles.OPERATIONAL_GM,
  roles.DIRECTOR,
];
