import React from 'react';
import { useNotifications } from '../../modules/utils/hooks/notifications.hooks';

interface INotificationProviderProps {
  children: React.ReactElement;
}

const NotificationProvider: React.FC<INotificationProviderProps> = ({
  children,
}) => {
  const listenNotification = useNotifications();
  listenNotification();
  return children;
};

export default NotificationProvider;
