import { Box, Button, Sheet, Typography, useTheme } from '@mui/joy';
import { omit, set } from 'lodash';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addProcessStatus, setIsDataRequested } from 'src/modules/actions';
import {
  AnyObject,
  INgroupBillingDetailsFormData,
  INgroupBillingDetailsModel,
} from 'src/modules/types';
import { Api } from 'src/modules/utils';
import { FieldsContainer } from './components/FieldsContainer';
import {
  InvoiceContactDetailsFields,
  IValueChangeArgs,
} from './components/InvoiceContactDetailsFields';
import { useHasUserAccessToAction } from 'src/config';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';

export const NgroupBillingDetails = () => {
  const theme = useTheme();

  const hasUserAccessToMangeBillingDetails = useHasUserAccessToAction(
    manageEntitiesConfig.ngroup_billing_details.manage.id,
  );

  const [
    ngroupBillingDetailsFields,
    setNgroupBillingDetailsFields,
  ] = React.useState<INgroupBillingDetailsFormData[]>([
    {
      type: 'name' as const,
      value: '',
      oldValue: '',
    },
    {
      type: 'email' as const,
      value: '',
      oldValue: '',
    },
    {
      type: 'street' as const,
      value: '',
      oldValue: '',
    },
    {
      type: 'phone-number' as const,
      value: '',
      oldValue: '',
    },
    {
      type: 'position-title' as const,
      value: '',
      oldValue: '',
    },
    {
      type: 'city-state-zip' as const,
      value: '',
      oldValue: '',
    },
    {
      type: 'additional-phone-number' as const,
      value: '',
      oldValue: '',
    },
  ]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { toCreate, toUpdate, toDelete } = ngroupBillingDetailsFields.reduce<
      any
    >(
      (parsed, item) => {
        if (item.value !== item.oldValue) {
          if (!item.oldValue && item.value) {
            parsed.toCreate.push(omit({ ...item }, 'oldValue'));
          }
          if (item.oldValue && item.value) {
            parsed.toUpdate.push(omit({ ...item }, 'oldValue'));
          }
          if (item.oldValue && !item.value && item.id) {
            parsed.toDelete.push(item.id);
          }
        }
        return parsed;
      },
      {
        toCreate: [],
        toUpdate: [],
        toDelete: [],
      },
    );

    if (toCreate.length) {
      await makeRequest(Api.NgroupBillingDetails.createBulk, toCreate);
    }
    if (toUpdate.length) {
      await makeRequest(Api.NgroupBillingDetails.bulkUpdate, toUpdate);
    }
    if (toDelete.length) {
      await makeRequest(Api.NgroupBillingDetails.delete, {
        where: { id: { inq: toDelete } },
      });
    }

    const data = await fetchNgroupBillingDetails();

    initDataInForm(data);

    dispatch(
      addProcessStatus({ message: t('common.success'), variant: 'success' }),
    );
  };

  function initDataInForm(billingDetails: INgroupBillingDetailsModel[]) {
    function updateState(items: INgroupBillingDetailsFormData[]) {
      return items.map((item) => {
        const existedData = billingDetails.find(
          (billingDetailsItem) => billingDetailsItem.type === item.type,
        );

        if (existedData) {
          return {
            ...item,
            id: existedData.id,
            value: existedData.value,
            oldValue: existedData.value,
          };
        }

        return { ...item, value: '', oldValue: '', id: undefined };
      });
    }

    setNgroupBillingDetailsFields(updateState);
  }

  async function fetchNgroupBillingDetails() {
    const data = await Api.NgroupBillingDetails.list();

    return data;
  }

  async function makeRequest<T>(
    request: (args: T) => Promise<AnyObject | void>,
    args: T,
  ) {
    try {
      dispatch(setIsDataRequested(true));
      const response = await request(args);
      dispatch(setIsDataRequested(false));

      return response;
    } catch (e) {
      dispatch(setIsDataRequested(false));
      resetFormData();

      throw e;
    }
  }

  function resetFormData() {
    setNgroupBillingDetailsFields((prev) =>
      prev.map((item) => ({ ...item, value: item.oldValue })),
    );
  }

  const onNgroupBillingDetailsValueChange = ({
    value,
    path,
  }: IValueChangeArgs) => {
    const newValues = set([...ngroupBillingDetailsFields], path, value);

    setNgroupBillingDetailsFields(newValues);
  };

  const isSomeFieldDataChanged = ngroupBillingDetailsFields.some(
    (field) => field.value !== field.oldValue,
  );

  React.useEffect(() => {
    initialize();

    async function initialize() {
      try {
        dispatch(setIsDataRequested(true));
        const billingDetails = await fetchNgroupBillingDetails();
        dispatch(setIsDataRequested(false));

        if (billingDetails.length) {
          initDataInForm(billingDetails);
        }
      } catch {
        dispatch(setIsDataRequested(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ maxWidth: 400, height: '100%' }}
      component="form"
      noValidate
      onSubmit={onSubmit}
    >
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography
          level="display_sm"
          fontWeight="medium"
          textColor="colors.text.text_primary.main"
          whiteSpace="nowrap"
          sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}
        >
          {t('main_menu.ngroup_billing_details.main_item')}
        </Typography>
      </Box>

      <Sheet
        sx={{
          display: 'flex',
          pb: 1,
          marginRight: -0.5,
          marginLeft: -0.5,
        }}
      >
        <FieldsContainer title={''}>
          <InvoiceContactDetailsFields
            fields={ngroupBillingDetailsFields}
            onValueChange={onNgroupBillingDetailsValueChange}
          />
        </FieldsContainer>
      </Sheet>

      {hasUserAccessToMangeBillingDetails && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // marginTop: 2,
          }}
        >
          <Button
            variant="outlined"
            disabled={!isSomeFieldDataChanged}
            onClick={resetFormData}
          >
            {t('common.restore')}
          </Button>
          <Button
            disabled={!isSomeFieldDataChanged}
            sx={{ marginLeft: 2 }}
            variant="solid"
            type="submit"
            color="primary"
          >
            {t('common.save')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
