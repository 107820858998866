import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateEmployeeSkillRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createEmployeeSkillScheme } from './create-employee-skill.scheme';
import { skillLevels } from '../../../config';

export const updateEmployeeSkillsScheme: JSONSchemaType<
  ICreateEmployeeSkillRequest[]
> = {
  type: 'array',
  items: {
    ...createEmployeeSkillScheme,
    properties: {
      ...createEmployeeSkillScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
    if: {
      properties: {
        level: {
          const: skillLevels.TRAINED,
        },
      },
    },
    then: {
      required: ['trainingDate'],
      properties: {
        trainingDate: {
          isNotEmpty: true,
          type: 'string',
          errorMessage: {
            isNotEmpty: i18n.t('error.validation.required'),
            type: i18n.t('error.validation.required'),
          },
        },
      },
    },
  },
};
