import React from 'react';
import { MUI_SIZE_2 } from '../../config';
import { useIsArrayChanged } from '../../modules/utils/hooks/common/forms';
import { ContentLayout } from './ContentLayout';
import { ConfirmPageLeave } from '../ConfirmPageLeave';
import { useFormLayoutData } from '../../modules/utils/hooks/layout';
import { Box, Theme } from '@mui/material';

interface IUpdateFormLayout {
  fields: Record<any, any>[];
  onSubmit: (e: React.FormEvent<any>) => void;
  noValidate?: boolean;
  isFormValid: boolean;
  formStyle?: React.CSSProperties;
}
export const UpdateFormLayout: React.FC<IUpdateFormLayout> = ({
  children,
  fields,
  onSubmit,
  isFormValid,
  formStyle,
  noValidate = true,
}) => {
  const { onFormSubmit, isSubmitting } = useFormLayoutData(
    isFormValid,
    onSubmit,
  );

  const isFormDataChanged = useIsArrayChanged(fields);

  return (
    <ContentLayout>
      <Box
        component="form"
        onSubmit={onFormSubmit}
        noValidate={noValidate}
        className={`update-form`}
        style={formStyle}
        sx={{ padding: (theme: Theme) => theme.spacing(MUI_SIZE_2) }}
      >
        {children}
      </Box>
      <ConfirmPageLeave isActive={isFormDataChanged && !isSubmitting} />
    </ContentLayout>
  );
};
