import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterData, IWhere } from 'src/modules/types';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { createDepartmentRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { createDepartmentScheme } from 'src/modules/schemes/departments';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { getDepartmentsComboboxListBySiteId } from 'src/modules/selectors/department';
import { DepartmentIncentive, departmentIncentiveOptions } from 'src/config';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { toNumber } from 'lodash';

const initialValues = {
  name: '',
  siteId: NaN,
  indirect: false,
  archived: false,
  includeToUph: true,
  includeToCostPlus: true,
  active: true,
  costPlus: null,
  staticCostPlus: null,
  parentId: null,
  incentive: DepartmentIncentive.none,
};

interface IDepartmentsFormsCreateDepartmentProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const DepartmentsFormsCreateDepartment = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: IDepartmentsFormsCreateDepartmentProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createDepartmentScheme, {
    allowUnionTypes: true,
  });

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(
        createDepartmentRequest({
          data: {
            ...data,
            costPlus: toNumber(data.costPlus),
          },
          filters: { list: filterList, count: filterCount },
        }),
      );

      onClose();
    },
  });

  const departments = useSelector(getDepartmentsComboboxListBySiteId)(
    formik.values.siteId,
  );

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('departments.create_form_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            <FormFieldContainer>
              <FormikTextField
                required
                variant="outlined"
                fullWidth
                id="name"
                label={t('departments.name')}
                name="name"
                autoComplete="name"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SitesComboBox
                id="siteId"
                required={true}
                formik={formik}
                label={t('employees.site')}
                placeholder={t('employees.site')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="costPlus"
                label={t('departments.cost_plus')}
                name="costPlus"
                autoComplete="costPlus"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                id="staticCostPlus"
                label={t('departments.static_cost_plus')}
                name="staticCostPlus"
                autoComplete="staticCostPlus"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                id="parentId"
                label={t('departments.parent')}
                placeholder={t('departments.parent')}
                options={departments}
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikTextField
                variant="outlined"
                fullWidth
                type="number"
                id="desiredEmployeesNumber"
                label={t('departments.desiredEmployeesNumber')}
                name="desiredEmployeesNumber"
                autoComplete="desiredEmployeesNumber"
                formik={formik}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCombobox
                required
                id="incentive"
                placeholder={t('departments.incentive')}
                label={t('departments.incentive')}
                options={departmentIncentiveOptions as ComboBoxOption[]}
                formik={formik}
              />
            </FormFieldContainer>

            {[
              DepartmentIncentive.costPlus,
              DepartmentIncentive.indirect,
            ].includes(formik.values.incentive!) && (
              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`incentiveSettings.price.${formik.values.incentive}`}
                  name={`incentiveSettings.price.${formik.values.incentive}`}
                  label={t('departments.incentivePrice')}
                  formik={formik}
                  type="number"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>
            )}

            <FormFieldContainer>
              <FormikCheckbox
                id="active"
                sx={{ '& .MuiFormControl-root': { display: 'flex' } }}
                formik={formik}
                label={t('departments.active')}
              />
            </FormFieldContainer>

            {/*<FormFieldContainer>*/}
            {/*  <FormikCheckbox*/}
            {/*    id="indirect"*/}
            {/*    sx={{ display: 'flex' }}*/}
            {/*    formik={formik}*/}
            {/*    label={t('departments.indirect')}*/}
            {/*  />*/}
            {/*</FormFieldContainer>*/}

            <FormFieldContainer>
              <FormikCheckbox
                id="archived"
                sx={{ display: 'flex' }}
                formik={formik}
                label={t('departments.archived')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="includeToUph"
                sx={{ display: 'flex' }}
                formik={formik}
                label={t('departments.includeToUph')}
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <FormikCheckbox
                id="includeToCostPlus"
                sx={{ display: 'flex' }}
                formik={formik}
                label={t('departments.includeToCostPlus')}
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
