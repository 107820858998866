import {
  IProductionDailyUphBySiteSkuAndEmployeeModel,
  IProductionDailyUphBySiteSkuAndEmployeeState,
} from '../../types/productionUphReports';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: IProductionDailyUphBySiteSkuAndEmployeeState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getProductionDailyUphBySiteSkuAndEmployeeListRequest,
  getListSuccess: getProductionDailyUphBySiteSkuAndEmployeeListSuccess,
  getListFailed: getProductionDailyUphBySiteSkuAndEmployeeListFailed,
} = reducerCreator.takeListHandlers<
  IProductionDailyUphBySiteSkuAndEmployeeState,
  IProductionDailyUphBySiteSkuAndEmployeeModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getProductionDailyUphBySiteSkuAndEmployeeCountRequest,
  getCountSuccess: getProductionDailyUphBySiteSkuAndEmployeeCountSuccess,
  getCountFailed: getProductionDailyUphBySiteSkuAndEmployeeCountFailed,
} = reducerCreator.takeCountHandlers<
  IProductionDailyUphBySiteSkuAndEmployeeState
>();

/**
 * Export actions
 */
const {
  exportRequest: exportProductionDailyUphBySiteSkuAndEmployeeRequest,
  exportSuccess: exportProductionDailyUphBySiteSkuAndEmployeeSuccess,
  exportFailed: exportProductionDailyUphBySiteSkuAndEmployeeFailed,
} = new ExportReducerCreator().takeHandlers<
  IProductionDailyUphBySiteSkuAndEmployeeState
>();

/**
 * actually Reducer
 */
export const productionDailyUphBySiteSkuAndEmployee = createReducer<
  IProductionDailyUphBySiteSkuAndEmployeeState
>({}, defaultState)
  // get list actions
  .on(
    actions.getProductionDailyUphBySiteSkuAndEmployeeListRequest,
    getProductionDailyUphBySiteSkuAndEmployeeListRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteSkuAndEmployeeListSuccess,
    getProductionDailyUphBySiteSkuAndEmployeeListSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteSkuAndEmployeeListFailed,
    getProductionDailyUphBySiteSkuAndEmployeeListFailed,
  )
  // get count actions
  .on(
    actions.getProductionDailyUphBySiteSkuAndEmployeeCountRequest,
    getProductionDailyUphBySiteSkuAndEmployeeCountRequest,
  )
  .on(
    actions.getProductionDailyUphBySiteSkuAndEmployeeCountSuccess,
    getProductionDailyUphBySiteSkuAndEmployeeCountSuccess,
  )
  .on(
    actions.getProductionDailyUphBySiteSkuAndEmployeeCountFailed,
    getProductionDailyUphBySiteSkuAndEmployeeCountFailed,
  )
  // export action
  .on(
    actions.exportProductionDailyUphBySiteSkuAndEmployeeRequest,
    exportProductionDailyUphBySiteSkuAndEmployeeRequest,
  )
  .on(
    actions.exportProductionDailyUphBySiteSkuAndEmployeeSuccess,
    exportProductionDailyUphBySiteSkuAndEmployeeSuccess,
  )
  .on(
    actions.exportProductionDailyUphBySiteSkuAndEmployeeFailed,
    exportProductionDailyUphBySiteSkuAndEmployeeFailed,
  );

export default productionDailyUphBySiteSkuAndEmployee;
