import React from 'react';
import { IconButton, styled } from '@mui/material';
import { Check, Clear, CheckCircle, Close } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

const DEFAULT_TONE = 500;

export const YesIcon = styled(Check)(() => ({
  color: green[DEFAULT_TONE],
}));

export const NoIcon = styled(Clear)(() => ({
  color: red[DEFAULT_TONE],
}));

export const ApproveIcon = styled(CheckCircle)(() => ({
  color: green[DEFAULT_TONE],
}));

export const UnapproveIcon = styled(Close)(() => ({
  color: red[DEFAULT_TONE],
}));

export const FontAwesomeMenuIcon: React.FC<FontAwesomeIconProps> = (props) => {
  return (
    <FontAwesomeIcon color="#8b8b8b" fontSize="16px" fixedWidth {...props} />
  );
};

export interface IToolTipProps {
  title: string;
  onClick?: () => void;
}

export const HeaderToolTip: React.FC<IToolTipProps> = ({ title, onClick }) => {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        sx={{
          marginRight: (theme: Theme) => theme.spacing(2),
          fontSize: '20px',
          cursor: 'pointer',
          color: 'white',
        }}
        onClick={onClick}
        size="large"
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </IconButton>
    </Tooltip>
  );
};
