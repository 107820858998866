import { SimpleActionCreator } from 'redux-act';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import { ISagaAction } from '../../../../types';
import { i18n } from '../../../i18n';
import { IExportParams, IExportResponse } from '../../../../types';
import { IProcessStatusModel } from '../../../../types/processStatus';
import * as actions from '../../../../actions';
import { Action } from 'redux';

export interface ExportSagaCreatorConfig {
  apiCall: (...args: any) => any;
  actionSuccess: () => Action;
  actionFailed: () => Action;
  actionNotification?: SimpleActionCreator<IProcessStatusModel>;
}

export const createExportSaga = ({
  actionFailed,
  actionNotification,
  actionSuccess,
  apiCall,
}: ExportSagaCreatorConfig) => {
  const notify = actionNotification ?? actions.addProcessStatus;

  return function* (action: ISagaAction<IExportParams>): SagaIterator {
    try {
      const { content, fileName }: IExportResponse = yield call(
        apiCall,
        action.payload,
      );

      yield put(actionSuccess());

      if (fileName) {
        fileDownload(content, fileName);
      }

      if (action.payload.exportType === 'email') {
        yield put(
          notify({
            variant: 'success',
            message: i18n.t('export.email.success'),
          }),
        );
      }
    } catch (err) {
      yield put(
        notify({
          variant: 'error',
          message: i18n.t('export.common.failed'),
        }),
      );

      yield put(actionFailed());
    }
  };
};

/**
 * ExportSagaCreator will be used for generating export sagas
 */
export class ExportSagaCreator {
  private readonly config: ExportSagaCreatorConfig;

  constructor(config: ExportSagaCreatorConfig) {
    this.config = config;
  }

  /**
   * Generates export saga handler
   */
  takeSagas() {
    return {
      exportRequestSaga: createExportSaga(this.config),
    };
  }
}
