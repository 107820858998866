import React from 'react';
import { Grid } from '@mui/joy';

import { useTurnoverByReason } from 'src/modules/utils/hooks/turnover.hooks';
import { MUI_SIZE_12, MUI_SIZE_6 } from 'src/config';
import { TurnoverByReasonChart } from 'src/pages/TurnoverDashboard/components/TurnoverByReasonChart';
import { MainContentLoader } from '../../../components/Layout/components/PageTour/MainContentLoader';

interface ITurnoverByReasonPanelProps {
  siteId?: number;
  year?: number;
}

export const TurnoverByReasonPanel: React.FC<ITurnoverByReasonPanelProps> = ({
  siteId,
  year,
}) => {
  const { data, isDataLoading } = useTurnoverByReason({ siteId, year });

  return (
    <>
      {isDataLoading && <MainContentLoader />}
      <Grid container spacing={2}>
        {data.map(({ series, siteName, categories }) => (
          <Grid key={siteName} xs={MUI_SIZE_12} md={MUI_SIZE_6}>
            <TurnoverByReasonChart
              title={siteName}
              categories={categories ?? []}
              showNoData={!series.length}
              series={series ?? []}
              isLoading={isDataLoading}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
