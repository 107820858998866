import React from 'react';
import { getISOWeek, getYear, parseISO, subYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  IConcatenateYearWeekArgs,
  concatenateYearWeekAsNumber,
} from 'src/modules/utils/helpers/formatters';
import { FilterPanelContext, FilterPanelModes } from '../../FilterPanelContext';
import { FilterPanelFilterContainer } from '../components/FilterPanelFilterContainer';
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteProps,
  Box,
  Typography,
  useTheme,
} from '@mui/joy';
import { ChevronDownSvg, Pencil02Svg } from 'src/components/svgIcons';
import { ComboBoxOption } from 'src/components/ComboBox';
import { isEqual } from 'lodash';

export interface IOption {
  id: number;
  name: string;
}

export type IFilterPanelYearWeekComboBoxProps = Omit<
  AutocompleteProps<ComboBoxOption, undefined, undefined, undefined>,
  'options'
> & {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
  optionsToYear?: number;
  optionsToWeek?: number;
  optionsFromYear?: number;
  optionsFromWeek?: number;
  optionsOrder?: 'desc' | 'asc';
  label?: string;
  value?: IOption['id'] | null;
  formatOptionId?: (args: IConcatenateYearWeekArgs) => number;
  formatOptionName?: (args: IConcatenateYearWeekArgs) => string;
};

const FIRST_WEEK = 1;
const currentYear = getYear(new Date());
const currentWeek = getISOWeek(new Date());
const thirtyYearsAgoYear = getYear(subYears(new Date(), 30));

function formatOption({ year, week }: IConcatenateYearWeekArgs) {
  return `${year}-W${String(week).padStart(2, '0')}`;
}

export const FilterPanelYearWeekComboBox: React.FC<IFilterPanelYearWeekComboBoxProps> = ({
  optionsFromYear = Number(thirtyYearsAgoYear),
  optionsFromWeek = FIRST_WEEK,
  optionsToYear = currentYear,
  optionsToWeek = currentWeek,
  formatOptionId = concatenateYearWeekAsNumber,
  formatOptionName = formatOption,
  optionsOrder = 'desc',
  filterId,
  onRemoveFilter,
  placeholder,
  value,
  ...autocompleteProps
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    setEditingFiltersIds,
    setFilterPanelMode,
    filterPanelMode,
    isFilterPermanent,
    elementsHeight,
  } = React.useContext(FilterPanelContext);

  const [isFilterInEditingMode, setIsFilterInEditingMode] = React.useState(
    false,
  );

  const [open, setOpen] = React.useState(false);

  const width = React.useMemo(() => (isFilterInEditingMode ? 250 : 200), [
    isFilterInEditingMode,
  ]);

  const handleOpen = () => {
    // we need it because when user clicks Add filter button
    // or edit any filter UI of all filters are changed
    // and dropdowns shifted too much from their required positions
    if (!isFilterInEditingMode) {
      setTimeout(() => setOpen((prev) => !prev), 300);
    } else {
      setOpen((prev) => !prev);
    }
  };

  const getOptionLabel = (option: ComboBoxOption) => option.name || '';

  const getOptionSelected = (option: ComboBoxOption, value: ComboBoxOption) =>
    isEqual(option, value);

  const generateYearWeeks = (): Array<IOption> => {
    const options: Array<IOption> = [];

    for (let year = optionsFromYear; year <= optionsToYear; year++) {
      const lastWeekDate = parseISO(`${year}-12-28`);
      const lastWeek = getISOWeek(lastWeekDate);

      let weekToCompareWith = lastWeek;
      if (year === optionsToYear) {
        weekToCompareWith = optionsToWeek;
      }

      let week = year === optionsFromYear ? optionsFromWeek : FIRST_WEEK;
      while (week <= weekToCompareWith) {
        options.push({
          id: formatOptionId({ year, week }),
          name: formatOptionName({ year, week }),
        });

        week++;
      }
    }

    return options;
  };

  const options =
    optionsOrder === 'asc'
      ? generateYearWeeks()
      : generateYearWeeks().reverse();

  const autocompleteValue = React.useMemo(() => {
    if (typeof value === 'number') {
      return options.find((option) => option.id === value);
    }

    return value ?? null;
  }, [options, value]);

  const onClick = () => {
    if (!isFilterInEditingMode) {
      setIsFilterInEditingMode(true);

      filterPanelMode !== FilterPanelModes.setup &&
        setFilterPanelMode(FilterPanelModes.setup);
    }
  };

  const onTrashBinClick = () => {
    setEditingFiltersIds((prev) => prev.filter((item) => item !== filterId));

    setFilterPanelMode(FilterPanelModes.setup);

    onRemoveFilter && onRemoveFilter(filterId);
  };

  React.useEffect(() => {
    if (filterPanelMode === FilterPanelModes.ready) {
      setIsFilterInEditingMode(false);
    }
  }, [filterPanelMode]);

  return (
    <FilterPanelFilterContainer
      onTrashBinClick={onTrashBinClick}
      isPermanentFilter={isFilterPermanent(filterId)}
    >
      <Box onClick={onClick} sx={{ width }}>
        <Autocomplete
          options={options}
          value={autocompleteValue}
          open={open}
          onOpen={handleOpen}
          onClose={() => setOpen(false)}
          freeSolo={(isFilterInEditingMode ? false : true) as any}
          disableClearable={(isFilterInEditingMode ? false : true) as any}
          placeholder={placeholder ?? t('common.select')}
          popupIcon={isFilterInEditingMode ? <ChevronDownSvg /> : null}
          endDecorator={!isFilterInEditingMode ? <Pencil02Svg /> : null}
          getOptionLabel={getOptionLabel}
          variant="plain"
          renderOption={(props, option) => {
            return (
              <AutocompleteOption
                {...props}
                key={props.id ?? option.id}
                sx={{ py: '9px', px: '10px' }}
              >
                <Typography
                  // noWrap
                  level="text_sm"
                  fontWeight="medium"
                  textColor="colors.text.text_secondary.hover"
                >
                  {option.name}
                </Typography>
              </AutocompleteOption>
            );
          }}
          isOptionEqualToValue={getOptionSelected}
          openText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          closeText={`autocomplete-trigger-for-${autocompleteProps.id}`}
          slotProps={{
            root: {
              sx: {
                fontSize: '0.75rem',
                '& li.MuiOption-root': {
                  fontSize: '0.875rem',
                },
                borderRadius: 0,
                margin: 0,
                height: elementsHeight,
                bgcolor: 'transparent',
                '&:focus-within': {
                  outline: 'none',
                },
              },
            },
            input: {
              sx: {
                '&::placeholder': {
                  fontWeight: theme.fontWeight.regular,
                  color: theme.palette.colors.text.text_placeholder,
                  ...theme.typography.text_xs,
                },
              },
            },
          }}
          {...autocompleteProps}
        />
      </Box>
    </FilterPanelFilterContainer>
  );
};
