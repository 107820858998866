import React, { useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStaffingProvidersByIds } from '../../modules/selectors/staffingProvider';
import {
  useBrowserHistoryFunctions,
  useQueryParams,
  useValidate,
} from '../../modules/utils/hooks';
import {
  IdsArray,
  IStaffingProviderModel,
  IStoreState,
} from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { useFetchStaffingProviders } from '../../modules/utils/hooks';
import { updateStaffingProvidersRequest } from '../../modules/actions/staffingProvider';
import { useTranslation } from 'react-i18next';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import FormikTextField from '../../components/Formik/FormikTextField';
import { FormActions } from '../../components/Form/FormActions';

import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { updateStaffingProvidersScheme } from '../../modules/schemes/staffing-providers';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IStaffingProviderUpdateProps {
  staffingProviders: IStaffingProviderModel[];
  onSubmit: (staffingProviders: IStaffingProviderModel[]) => void;
}

const StaffingProviderUpdateForm = ({
  onSubmit,
  staffingProviders,
}: IStaffingProviderUpdateProps) => {
  const { t } = useTranslation();

  const validate = useValidate(updateStaffingProvidersScheme);

  const formik = useFormikInUpdateForm({
    initialValues: staffingProviders,
    validate,
    onSubmit,
  });

  return (
    <UpdateFormLayout
      onSubmit={formik.handleSubmit}
      fields={formik.values}
      isFormValid={formik.isValid}
    >
      {map(formik.values, (staffingProvider, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('staffing_prov.update_title', staffingProvider)}
          key={staffingProvider.id}
        >
          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id={`${index}.staffingProvider`}
              label={t('staffing_prov.name')}
              name={`${index}.staffingProvider`}
              autoComplete="staffingProvider"
              formik={formik}
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id={`${index}.markup`}
              label={t('staffing_prov.markup')}
              name={`${index}.markup`}
              autoComplete="markup"
              formik={formik}
              type="number"
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              variant="outlined"
              fullWidth
              id={`${index}.ngroupMarkup`}
              label={t('staffing_prov.ng_markup')}
              name={`${index}.ngroupMarkup`}
              autoComplete="ngroupMarkup"
              formik={formik}
              type="number"
              errorMode="onFieldChange"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id={`${index}.ptoMarkup`}
              label={t('staffing_prov.pto_markup')}
              name={`${index}.ptoMarkup`}
              autoComplete="ptoMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id={`${index}.holidayMarkup`}
              label={t('staffing_prov.holiday_markup')}
              name={`${index}.holidayMarkup`}
              autoComplete="holidayMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id={`${index}.etoMarkup`}
              label={t('staffing_prov.eto_markup')}
              name={`${index}.etoMarkup`}
              autoComplete="etoMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id={`${index}.securityName`}
              label={t('staffing_prov.sec_name')}
              name={`${index}.securityName`}
              autoComplete="securityName"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              fullWidth
              id={`${index}.otMarkup`}
              label={t('staffing_prov.ot_markup')}
              name={`${index}.otMarkup`}
              autoComplete="otMarkup"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>
        </CreateForm>
      ))}

      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const StaffingProvidersUpdate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  const fetchStaffingProviders = useFetchStaffingProviders(ids);

  // fetch staffingProviders list from store
  const staffingProviders = useSelector(
    (state) => getStaffingProvidersByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  // make request to fetch staffingProviders from the server if we don't have them in the store
  useEffect(() => {
    fetchStaffingProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated staffingProviders
   */
  const handleFormSubmit = (data: IStaffingProviderModel[]) => {
    dispatcher(updateStaffingProvidersRequest({ data, navigate }));
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <StaffingProviderUpdateForm
          staffingProviders={staffingProviders}
          onSubmit={handleFormSubmit}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default StaffingProvidersUpdate;
