import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { LineChart } from 'src/components/Charts/2d/LineChart';
import { fetchHRChangesLostBySite } from 'src/modules/utils/Api/hrChanges';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { IAutocompleteOption } from 'src/modules/types/autocomplete';
import { getLastNDaysStarEndDates } from 'src/modules/utils/dateWrapper';
import {
  calculateTopNSites,
  generateSumByEntity,
  useActiveDurationOptions,
  useActiveTopSitesOptions,
} from '../hrChanges.helpers';
import {
  composeObjectKey,
  decomposeObjectKey,
} from 'src/modules/utils/helpers/common';

const siteNameIndex = 0;
const siteNameSiteIdIndex = 0;

interface IHRChangesLostBySiteProps {
  siteId?: number;
}

export const HRChangesLostBySite = ({ siteId }: IHRChangesLostBySiteProps) => {
  const { t } = useTranslation();

  const activeTopSitesOptions = useActiveTopSitesOptions();
  const activeDurationOptions = useActiveDurationOptions();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const [activeTopSites, setActiveTopSites] = React.useState<
    IAutocompleteOption<'top3' | 'top10' | 'all'>
  >(activeTopSitesOptions[0]);
  const [activeDuration, setActiveDuration] = React.useState<
    IAutocompleteOption<'last7days' | 'last30days'>
  >(activeDurationOptions[0]);

  const { data: rawData, getData, isLoading } = useGetDataForCharts(
    fetchHRChangesLostBySite,
    {
      initialData: [],
    },
  );

  const data = rawData.map((item) => ({
    ...item,
    siteNameAndSiteId: composeObjectKey(item.site, String(item.siteId)),
  }));

  const last30Days = getLastNDaysStarEndDates(30);
  const last7Days = getLastNDaysStarEndDates(7);

  const sumBySiteArray = generateSumByEntity(
    data,
    'siteNameAndSiteId',
    'countLost',
  );

  const categories = React.useMemo(() => {
    const categoriesSet = new Set(data.map((item) => item.date));

    return [...categoriesSet];
  }, [data]);

  const series = React.useMemo(() => {
    const datesWithZeroValues = categories.reduce((categoriesObj, category) => {
      categoriesObj[category] = 0;

      return categoriesObj;
    }, {});

    const dataObject = data.reduce<{
      [siteNameAndSiteId: string]: { [date: string]: number | null };
    }>((acc, item) => {
      if (!acc[item.siteNameAndSiteId]) {
        acc[item.siteNameAndSiteId] = { ...datesWithZeroValues };
      }

      acc[item.siteNameAndSiteId][item.date] = item.countLost;

      return acc;
    }, {});

    let entriesToUse = Object.entries(dataObject);
    if (activeTopSites.id === 'top3') {
      const top3Sites = calculateTopNSites(sumBySiteArray, 3);

      entriesToUse = entriesToUse.filter((entry) =>
        top3Sites.includes(entry[siteNameSiteIdIndex]),
      );
    }
    if (activeTopSites.id === 'top10') {
      const top10Sites = calculateTopNSites(sumBySiteArray, 10);
      entriesToUse = entriesToUse.filter((entry) =>
        top10Sites.includes(entry[siteNameSiteIdIndex]),
      );
    }

    return entriesToUse.map(([siteNameSiteId, siteDataObj]) => ({
      name: decomposeObjectKey(siteNameSiteId)[siteNameIndex],
      type: 'line' as const,
      data: Object.values(siteDataObj),
      marker: {
        symbol: 'circle',
        radius: 2,
      },
    }));
  }, [activeTopSites.id, categories, data, sumBySiteArray]);

  React.useEffect(() => {
    const dateWhere = {
      and: [
        {
          date: {
            gte:
              activeDuration.id === 'last7days'
                ? last7Days.startDateFormatted
                : last30Days.startDateFormatted,
          },
        },
        {
          date: {
            lt:
              activeDuration.id === 'last7days'
                ? last7Days.endDateFormatted
                : last30Days.endDateFormatted,
          },
        },
      ],
    };

    getData({ siteId, ...dateWhere }, ['date asc']);
  }, [
    getData,
    siteId,
    activeDuration.id,
    last30Days.endDateFormatted,
    last30Days.startDateFormatted,
    last7Days.endDateFormatted,
    last7Days.startDateFormatted,
  ]);

  return (
    <ChartContainer>
      <ChartContainerHeader title={t('dashboard.lost_by_site')}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart('lost_by_site')}
          />

          <DoubledCombobox>
            <LeftCombobox
              disableClearable
              value={activeTopSites}
              options={activeTopSitesOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveTopSites(value);
                }
              }}
            />
            <ComboboxSeparator />
            <RightCombobox
              disableClearable
              value={activeDuration}
              options={activeDurationOptions}
              onChange={(_, value) => {
                if (value) {
                  setActiveDuration(value);
                }
              }}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={!data.length}>
        <LineChart
          fullscreenTitle={t('dashboard.lost_by_site')}
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
