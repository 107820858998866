import React from 'react';
import { Avatar, Sheet, Typography, TypographyProps, useTheme } from '@mui/joy';

const AvatarLabelGroupText = Typography;
const AvatarLabelGroupTitle = Typography;
const AvatarLabelGroupContainer = Sheet;
const AvatarLabelGroupTextContainer = Sheet;
const AvatarLabelGroupAvatarContainer = Sheet;

type AvatarLabelGroupSize = 'sm' | 'md' | 'lg';

interface IAvatarLabelGroupProps {
  text: string;
  title: string;
  avatarSrc?: string;
  size?: AvatarLabelGroupSize;
}

export const AvatarLabelGroup = ({
  text,
  title,
  children,
  avatarSrc,
  size = 'sm',
}: React.PropsWithChildren<IAvatarLabelGroupProps>) => {
  const theme = useTheme();

  const typography = pickTypographyStylesBySize(size);

  return (
    <AvatarLabelGroupContainer
      sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'inherit' }}
    >
      <AvatarLabelGroupAvatarContainer sx={{ backgroundColor: 'inherit' }}>
        <Avatar
          variant="outlined"
          size={size}
          src={avatarSrc}
          sx={{
            backgroundColor: 'colors.avatar.avatar_bg',
            borderColor: 'colors.avatar.avatar_contrast_border',
          }}
        >
          {children}
        </Avatar>
      </AvatarLabelGroupAvatarContainer>
      <AvatarLabelGroupTextContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ml: '10px',
          backgroundColor: 'inherit',
          alignItems: 'flex-start',
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        }}
      >
        <AvatarLabelGroupTitle
          noWrap
          component="span"
          level={typography.title.level}
          fontWeight={typography.title.fontWeight}
          textColor="colors.text.text_primary.main"
        >
          {title}
        </AvatarLabelGroupTitle>
        <AvatarLabelGroupText
          noWrap
          level={typography.text.level}
          fontWeight={typography.text.fontWeight}
          component="span"
          textColor="colors.text.text_primary.main"
        >
          {text}
        </AvatarLabelGroupText>
      </AvatarLabelGroupTextContainer>
    </AvatarLabelGroupContainer>
  );
};

function pickTypographyStylesBySize(
  size: AvatarLabelGroupSize,
): { [key: string]: TypographyProps } {
  if (size === 'md') {
    return {
      title: {
        level: 'text_sm',
        fontWeight: 'semi_bold',
      },
      text: {
        level: 'text_sm',
        fontWeight: 'regular',
      },
    };
  }

  if (size === 'lg') {
    return {
      title: {
        level: 'text_md',
        fontWeight: 'semi_bold',
      },
      textSx: {
        level: 'text_md',
        fontWeight: 'regular',
      },
    };
  }

  return {
    title: {
      level: 'text_sm',
      fontWeight: 'semi_bold',
    },
    text: {
      level: 'text_xs',
      fontWeight: 'regular',
    },
  };
}
