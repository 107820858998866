import { IProductionDailyUphBySiteAndEmployeeModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionDailyUphBySiteAndEmployee',
);

// get list actions
export const {
  getListRequest: getProductionDailyUphBySiteAndEmployeeListRequest,
  getListSuccess: getProductionDailyUphBySiteAndEmployeeListSuccess,
  getListFailed: getProductionDailyUphBySiteAndEmployeeListFailed,
} = actionCreator.takeListActions<IProductionDailyUphBySiteAndEmployeeModel>();

// get count actions
export const {
  getCountRequest: getProductionDailyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getProductionDailyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getProductionDailyUphBySiteAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionDailyUphBySiteAndEmployeeRequest,
  exportReportSuccess: exportProductionDailyUphBySiteAndEmployeeSuccess,
  exportReportFailed: exportProductionDailyUphBySiteAndEmployeeFailed,
} = new ExportActionCreator(
  'ProductionDailyUphBySiteAndEmployee',
).takeExportActions();
