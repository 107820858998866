import { get, map, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateStandard } from '../types';

export const getStandardRefreshKey = (state: IStoreState) =>
  state.standard.refreshKey;

export const getStandardData = (state: IStoreState) => state.standard;

/**
 * Get server error
 * @param standard - State standard
 */
export const getServerError = ({ standard }: IStoreState) => standard.error;

/**
 * Get standard list
 * @param standard - State standard
 */
export const getStandardList = ({ standard }: IStoreState) => standard.list;

/**
 * Get standards list count by siteId
 * @param standard - State standard
 */
export const getStandardsCount = ({ standard }: IStoreState) => (
  siteId: number,
) => get(standard, ['list', siteId, 'count'], 0);

/**
 * Get standards by array of ids
 * @param standard - State standard
 */
export const getStandardsByIds = createSelector(
  getStandardList,
  (standards) => (siteId: number, ids: IdsArray) =>
    reduce(
      get(standards, [siteId, 'data'], []),
      (acc, cur) => {
        if (ids.includes(`${(cur as IUpdateStandard).id}`)) {
          // omit columns we should not request with
          acc.push(
            omit(cur as IUpdateStandard, [
              'site',
              'standardMetadata',
            ]) as IUpdateStandard,
          );
        }
        return acc;
      },
      [] as IUpdateStandard[],
    ),
);

/**
 * Get combobox list
 */
export const getStandardsComboboxList = ({ standard }: IStoreState) =>
  map(standard.comboboxList, (standardItem) => ({
    id: standardItem.id,
    name: `${standardItem.standard} - ${standardItem.baseTime}`,
  }));
