import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabsNgroup } from 'src/components/TabsNgroup';
import UpdateProfile from './panels/UpdateProfile';
import UpdatePassword from './panels/UpdatePassword';
import NotificationsPreferences from './panels/NotificationsPreferences';

export const Profile = () => {
  const { t } = useTranslation();

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('profile.update_title'),
          hasUserAccess: true,
          component: <UpdateProfile />,
        },
        {
          title: t('profile.update_pwd_title'),
          hasUserAccess: true,
          component: <UpdatePassword />,
        },
        {
          title: t('profile.notifications_preferences'),
          hasUserAccess: true,
          component: <NotificationsPreferences />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [t],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
