import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ISagaAction,
  ICreateCpuBasedBaseline,
  IUpdateCpuBasedBaseline,
  IFilter,
  IdsArray,
  ICountResponse,
  ISagaActionBind,
  PayloadWithNavigateFunc,
  ICpuBasedBaselineWithRelations,
  IManageCpuBasedBaselines,
} from '../../types';
import { paths } from '../../../config';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

export const createCpuBasedBaselineRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateCpuBasedBaseline[]>
>): SagaIterator {
  try {
    yield call(Api.Baseline.createManyCpuBased, data);
    yield put(actions.createCpuBasedBaselineSuccess());
    yield call(navigate, {
      pathname: paths.CPU_BASED_BASELINE,
    });
  } catch (e) {
    yield put(actions.createCpuBasedBaselineFailed());
  }
};

export const deleteCpuBasedBaselineRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Baseline.deleteCpuBased, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.deleteCpuBasedBaselineSuccess(action.payload));
  } catch (e) {
    yield put(actions.deleteCpuBasedBaselineFailed());
  }
};

export const updateCpuBasedBaselinesRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IUpdateCpuBasedBaseline[]>
>): SagaIterator {
  try {
    yield call(Api.Baseline.bulkUpdateCpuBased, data);
    yield put(actions.updateCpuBasedBaselinesSuccess());
    yield call(navigate, {
      pathname: paths.CPU_BASED_BASELINE,
    });
  } catch (e) {
    yield put(actions.updateCpuBasedBaselinesFailed());
  }
};

export const manageCpuBasedBaselinesRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IManageCpuBasedBaselines>
>): SagaIterator {
  try {
    if (data.toCreate) {
      yield call(Api.Baseline.createManyCpuBased, data.toCreate);
    }
    if (data.toUpdate) {
      yield call(Api.Baseline.bulkUpdateCpuBased, data.toUpdate);
    }
    if (data.toDelete) {
      yield call(Api.Baseline.deleteCpuBased, {
        where: { id: { inq: data.toDelete } },
      });
    }

    yield put(actions.manageCpuBasedBaselinesSuccess());
    yield call(navigate, {
      pathname: paths.CPU_BASED_BASELINE,
    });
  } catch (e) {
    yield put(actions.manageCpuBasedBaselinesFailed());
  }
};

export const getCpuBasedBaselineListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICpuBasedBaselineWithRelations[] = yield call(
      Api.Baseline.listCpuBased,
      action.payload,
    );
    yield put(actions.getCpuBasedBaselineListSuccess(response));
  } catch (e) {
    yield put(actions.getCpuBasedBaselineListFailed());
  }
};

export const getCpuBasedBaselineCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Baseline.countCpuBased,
      action?.payload,
    );
    yield put(actions.getCpuBasedBaselineCountSuccess(response));
  } catch (e) {
    yield put(actions.getCpuBasedBaselineCountFailed());
  }
};

export const {
  exportRequestSaga: exportCpuBasedBaselineRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Baseline.exportCpuBased,
  actionFailed: actions.exportCpuBasedBaselineFailed,
  actionSuccess: actions.exportCpuBasedBaselineSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createCpuBasedBaselineRequest,
    saga: createCpuBasedBaselineRequestSaga,
  },
  {
    action: actions.deleteCpuBasedBaselineRequest,
    saga: deleteCpuBasedBaselineRequestSaga,
  },
  {
    action: actions.updateCpuBasedBaselinesRequest,
    saga: updateCpuBasedBaselinesRequestSaga,
  },
  {
    action: actions.manageCpuBasedBaselinesRequest,
    saga: manageCpuBasedBaselinesRequestSaga,
  },
  {
    action: actions.getCpuBasedBaselineListRequest,
    saga: getCpuBasedBaselineListRequestSaga,
  },
  {
    action: actions.getCpuBasedBaselineCountRequest,
    saga: getCpuBasedBaselineCountRequestSaga,
  },
  {
    action: actions.exportCpuBasedBaselineRequest,
    saga: exportCpuBasedBaselineRequestSaga,
  },
];
