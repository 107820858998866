import { useTranslation } from 'react-i18next';

export const useEmployeeFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      site: {
        target: 'site',
        content: t('guide.employee.site'),
      },
      firstName: {
        target: 'first-name',
        content: t('guide.employee.first_name'),
      },
      lastName: {
        target: 'last-name',
        content: t('guide.employee.last_name'),
      },
      employeeId: {
        target: 'employee-id',
        content: t('guide.employee.employee_id'),
      },
      badge: {
        target: 'badge',
        content: t('guide.employee.badge'),
      },
      position: {
        target: 'position',
        content: t('guide.employee.position'),
      },
      staffingProvider: {
        target: 'staffing-provider',
        content: t('guide.employee.staffing_provider'),
      },
      supervisor: {
        target: 'supervisor',
        content: t('guide.employee.supervisor'),
      },
      payRate: {
        target: 'pay-rate',
        content: t('guide.employee.pay_rate'),
      },
      dateHired: {
        target: 'date-hired',
        content: t('guide.employee.date_hired'),
      },
      separationDate: {
        target: 'separation-date',
        content: t('guide.employee.separation_date'),
      },
      defaultShift: {
        target: 'default-shift',
        content: t('guide.employee.default_shift'),
      },
      defaultDepartment: {
        target: 'default-department',
        content: t('guide.employee.default_department'),
      },
      phoneNumber: {
        target: 'phone-number',
        content: t('guide.employee.phone_number'),
      },
      wmsLogin: {
        target: 'wms-login',
        content: t('guide.employee.wms_login'),
      },
      taktId: {
        target: 'takt-id',
        content: t('guide.employee.wms_login'),
      },
      terminationReason: {
        target: 'termination-reason',
        content: t('guide.employee.termination_reason'),
      },
      canRehire: {
        target: 'can-rehire',
        content: t('guide.employee.can_rehire'),
      },
      emergencyContact: {
        target: 'emergencyContact',
        content: t('guide.employee.emergencyContact'),
      },
      comments: {
        target: 'comments',
        content: t('guide.employee.comments'),
      },
    },
  };
};
