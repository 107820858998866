import { createAction } from 'redux-act';
import {
  IFilterData,
  ILoggedInModel,
  IUpdateLogtime,
  MoveToDepartmentRequestType,
} from '../../types';

// Fetch logged in employees actions
export const getLoggedInListRequest = createAction(
  'Make request to fetch logged in employees list',
);
export const getLoggedInListSuccess = createAction<ILoggedInModel[]>(
  'Logged in employees list has been fetched successfully',
);
export const getLoggedInListFailed = createAction(
  'Cannot fetch logged in employees list',
);

// Log out employees actions
export const logOutEmployeeRequest = createAction<{
  data: IUpdateLogtime[];
  filter: IFilterData;
}>('Make request to log out employees');
export const logOutEmployeeFailed = createAction('Cannot logout employees');

// Move employees to another department actions
export const moveToDepartmentRequest = createAction<{
  data: MoveToDepartmentRequestType[];
  filter: IFilterData;
}>(
  'Make request to log out employees from the previous department and log in to the next one',
);
export const moveToDepartmentFailed = createAction(
  'Cannot move employees to another department',
);

// Flush actions
export const flushSupervisorViewState = createAction(
  'Flush supervisor view state data in store',
);
