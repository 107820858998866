import React from 'react';
import { format, getMonth, getYear, parse, parseISO, subYears } from 'date-fns';

import { ComboBoxOption } from '../../../ComboBox';
import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';
import { IConcatenateYearWeekArgs } from '../../../../modules/utils/helpers/formatters';

interface IYearMonthComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  optionsToYear?: number;
  optionsToMonth?: number;
  optionsFromYear?: number;
  optionsFromMonth?: number;
  optionsOrder?: 'desc' | 'asc';
}

const formatOptionName = ({ year, week: month }: IConcatenateYearWeekArgs) => {
  return `${year}-${format(parse(`${month}`, 'M', new Date()), 'MMM')}`;
};

const formatOptionId = ({ year, week: month }: IConcatenateYearWeekArgs) => {
  return Number(`${year}${String(month).padStart(2, '0')}`);
};

const FIRST_MONTH = 1;
const currentYear = getYear(new Date());
const currentMonth = getMonth(new Date()) + 1;
const thirtyYearsAgoYear = getYear(subYears(new Date(), 30));

const generateYearMonths = ({
  optionsFromYear = Number(thirtyYearsAgoYear),
  optionsFromMonth = FIRST_MONTH,
  optionsToYear = currentYear,
  optionsToMonth = currentMonth,
}): Array<ComboBoxOption<number>> => {
  const options: Array<ComboBoxOption<number>> = [];

  for (let year = optionsFromYear; year <= optionsToYear; year++) {
    const lastMonthDate = parseISO(`${year}-12-01`);
    const lastMonth = getMonth(lastMonthDate) + 1;

    let monthToCompareWith = lastMonth;
    if (year === optionsToYear) {
      monthToCompareWith = optionsToMonth;
    }

    let month = year === optionsFromYear ? optionsFromMonth : FIRST_MONTH;
    while (month <= monthToCompareWith) {
      options.push({
        id: formatOptionId({ year, week: month }),
        name: formatOptionName({ year, week: month }),
      });

      month++;
    }
  }

  return options;
};

const YearMonthFilterComboBox: React.FC<IYearMonthComboboxProps> = ({
  optionsFromYear = Number(thirtyYearsAgoYear),
  optionsFromMonth = FIRST_MONTH,
  optionsToYear = currentYear,
  optionsToMonth = currentMonth,
  optionsOrder = 'desc',
  ...props
}) => {
  const options =
    optionsOrder === 'asc'
      ? generateYearMonths({
          optionsFromYear,
          optionsFromMonth,
          optionsToYear,
          optionsToMonth,
        })
      : generateYearMonths({
          optionsFromYear,
          optionsFromMonth,
          optionsToYear,
          optionsToMonth,
        }).reverse();

  return (
    <FormikCombobox
      {...props}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default YearMonthFilterComboBox;
