import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableContext } from '../../../../TableContext';
import { FilterForm, IFilterFormProps } from './_components/FilterForm';
import { FilterActionsTriggers } from './_components/FilterActionsTriggers';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';

export interface IFilterProps extends IFilterFormProps {
  onFilterIconClick?: () => void;
  onClearFiltersIconClick?: () => void;
}

export const Filter: React.FC<IFilterProps> = ({
  onSubmit,
  onFilterIconClick,
  onClearFiltersIconClick,
  ...props
}) => {
  const [isFilterFormOpened, setIsFilterModalOpened] = React.useState(false);

  const { filters, setFilters } = React.useContext(TableContext);

  const { t } = useTranslation();

  const handleFilterIconClick = () => {
    setIsFilterModalOpened(true);

    onFilterIconClick && onFilterIconClick();
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpened(false);
  };

  const handleClearFiltersIconClick = () => {
    onClearFiltersIconClick && onClearFiltersIconClick();
  };

  const handleOkClick = () => {
    onSubmit(filters);
    setIsFilterModalOpened(false);
  };

  return (
    <>
      <FilterActionsTriggers
        handleFilterIconClick={handleFilterIconClick}
        handleClearFiltersIconClick={handleClearFiltersIconClick}
      />
      <Drawer
        open={isFilterFormOpened}
        onClose={onFilterModalClose}
        anchor="right"
      >
        <DrawerHeader onCloseClick={onFilterModalClose}>
          {t('common.filters')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent component="div">
            <FilterForm onSubmit={onSubmit} {...props} />
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            applyButtonType="submit"
            onApply={handleOkClick}
            onCancel={onFilterModalClose}
            onReset={() => {
              setFilters(
                Object.keys(filters).reduce(
                  (acc, key) => ({ ...acc, [key]: '' }),
                  {},
                ),
              );
            }}
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
