import { AnyObject, ProductionSettingConfig } from '../../modules/types';
import React from 'react';
import { map } from 'lodash';
import Metatags from './components/Metatags';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

interface IProductionMetatagsFieldsProps {
  index: number;
  formik: AnyObject;
  metatags: ProductionSettingConfig['metatags'];
}

const ProductionMetatagsFields: React.FC<IProductionMetatagsFieldsProps> = ({
  metatags,
  index,
  formik,
}) => {
  return (
    <>
      {map(metatags, (mt) => (
        <FormFieldContainer key={mt.name}>
          <Metatags
            index={index}
            name={mt.name}
            formik={formik}
            settings={mt}
          />
        </FormFieldContainer>
      ))}
    </>
  );
};

export default ProductionMetatagsFields;
