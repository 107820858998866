import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AnyObject } from 'src/modules/types';

interface IMultiRowFormAddRowProps {
  formik: AnyObject;
  nullishValues: {
    [key: string]: null | undefined | '';
  };
}

export const MultiRowFormAddRow: React.FC<IMultiRowFormAddRowProps> = ({
  formik,
  nullishValues,
}) => {
  return (
    <Fab
      style={{ position: 'fixed', bottom: 8, right: 56 }}
      onClick={() => formik.setValues([...formik.values, nullishValues])}
      color="primary"
      aria-label="add"
    >
      <AddIcon />
    </Fab>
  );
};
