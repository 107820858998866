import React from 'react';

export const useRecruitingReportTableFiltersConfiguration = () => {
  return React.useMemo(() => {
    return {
      siteName: {
        value: '',
        property: 'siteName',
        operator: 'eq' as const,
      },
      desiredHeadCount: {
        value: '',
        property: 'desiredHeadCount',
        operator: 'like' as const,
      },
      absenteeism: {
        value: '',
        property: 'absenteeism',
        operator: 'like' as const,
      },
      optimalHeadCount: {
        value: '',
        property: 'optimalHeadCount',
        operator: 'like' as const,
      },
      countOfActiveEmployees: {
        value: '',
        property: 'countOfActiveEmployees',
        operator: 'like' as const,
      },
      openOrders: {
        value: '',
        property: 'openOrders',
        operator: 'like' as const,
      },
    };
  }, []);
};
