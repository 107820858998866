import React from 'react';
import { Text, Editor, Element } from 'slate';
import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';

import { AnyObject } from '../../../../modules/types';

// TODO: add types;
interface NodeElement extends AnyObject {
  bold: any;
  type?: string;
}

export const serializeToHtml = (node: NodeElement): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<i>${string}</i>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = node.children.map((n: any) => serializeToHtml(n)).join('');

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case 'code':
      return `<code>${children}</code>`;
    case 'table':
      return `<table class="slate-custom-table">${children}</table>`;
    case 'table-row':
      return `<tr>${children}</tr>`;
    case 'table-cell':
      return `<td>${children}</td>`;
    case 'image':
      return `<img src="${escapeHtml(node.url)}" alt="${node.alt}" />`;
    case 'orderedList':
      return `<ol>${children}</ol>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'unorderedList':
      return `<ul>${children}</ul>`;
    case 'alignCenter':
      return `<div class="slate-custom-alignCenter">${children}</div>`;
    case 'alignRight':
      return `<div class="slate-custom-alignRight">${children}</div>`;
    default:
      return children;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deserializeFromHtml: any = (el: any, markAttributes = {}) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map(deserializeFromHtml);

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  switch (el.nodeName) {
    // Elements:
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'H1':
      return jsx('element', { type: 'headingOne' }, children);
    case 'H2':
      return jsx('element', { type: 'headingTwo' }, children);
    case 'H3':
      return jsx('element', { type: 'headingThree' }, children);
    case 'IMG':
      return jsx('element', { type: 'image', url: el.src }, children);

    case 'TABLE':
      const tableChildren = (children[0] as any).children;
      return jsx(
        'element',
        { type: 'table', className: el.className },
        tableChildren,
      );
    case 'TBODY':
      return jsx('element', { type: 'table-body' }, children);
    case 'TR':
      return jsx('element', { type: 'table-row' }, children);
    case 'TH':
      return jsx('element', { type: 'table-header' }, children);
    case 'TD':
      return jsx('element', { type: 'table-cell' }, children);
    case 'OL':
      return jsx('element', { type: 'orderedList' }, children);
    case 'UL':
      return jsx('element', { type: 'unorderedList' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'DIV':
      let type = 'alignCenter';
      if (el.className === 'slate-custom-alignRight') {
        type = 'alignRight';
      }
      return jsx('element', { type }, children);

    // Leafs:
    case 'STRONG':
      return { text: el.textContent, bold: true };
    case 'EM':
      return { text: el.textContent, italic: true };
    case 'U':
      return { text: el.textContent, underline: true };

    default:
      return el.textContent;
  }
};

// export const toolbarGroups = [
//   [
//     {
//       id: 1,
//       format: 'fontFamily',
//       type: 'dropdown',
//       options: [
//         { text: 'Sans Serif', value: 'sans' },
//         { text: 'Serif', value: 'serif' },
//         { text: 'MonoSpace', value: 'monospace' },
//       ],
//     },
//     {
//       id: 2,
//       format: 'fontSize',
//       type: 'dropdown',
//       options: [
//         { text: 'Small', value: 'small' },
//         { text: 'Normal', value: 'normal' },
//         { text: 'Medium', value: 'medium' },
//         { text: 'Huge', value: 'huge' },
//       ],
//     },
//   ],
//   [
//     {
//       id: 3,
//       format: 'bold',
//       type: 'mark',
//     },
//     {
//       id: 4,
//       format: 'italic',
//       type: 'mark',
//     },
//     {
//       id: 6,
//       format: 'strikethrough',
//       type: 'mark',
//     },
//   ],
//
// ];

export const useTable = (editor: any) => {
  const [isTable, setIsTable] = React.useState(false);
  React.useEffect(() => {
    if (editor.selection) {
      const [tableNode] = Editor.nodes(editor, {
        match: (n: AnyObject) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (n as any).type === 'table',
      });

      setIsTable(!!tableNode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.selection]);

  return isTable;
};
