import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { Api, useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  IFilterData,
  IWhere,
  IdsArray,
  AnyObject,
  ICreateEmployeeSkillAttachment,
} from 'src/modules/types';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { updateEmployeeSkillsRequest } from 'src/modules/actions';
import { EmployeesComboBox } from 'src/components/Formik/comboboxes-with-entities/EmployeesComboBox';
import { updateEmployeeSkillsScheme } from 'src/modules/schemes/employees-skills';
import { getEmployeeSkillList } from 'src/modules/selectors/employeeSkill';
import { map, omit } from 'lodash';
import { skillLevels } from 'src/config';
import { transformToCapitalize } from 'src/modules/utils/helpers/common';
import { getSkillsComboboxList } from 'src/modules/selectors/skill';
import FormikDatepicker from 'src/components/Formik/FormikDatepicker';
import EmployeeSkillAttachmentUploader from './EmployeeSkillAttachmentUploader';
import { IAttachmentData } from 'src/components/UploadAttachments';

interface IUpdateEmployeesSkillsProps {
  employeesSkillsIds: IdsArray;
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
  onRequestsWithAttachmentsStart: () => void;
  onRequestsWithAttachmentsEnd: () => void;
}

export const UpdateEmployeesSkills = ({
  isOpen,
  onClose,
  filterCount,
  filterList,
  employeesSkillsIds,
  onRequestsWithAttachmentsStart,
  onRequestsWithAttachmentsEnd,
}: IUpdateEmployeesSkillsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(updateEmployeeSkillsScheme);

  const employeesSkillsIdsNumber = React.useMemo(
    () => employeesSkillsIds.map(Number),
    [employeesSkillsIds],
  );

  const allEmployeesSkills = useSelector(getEmployeeSkillList);

  const employeesSkills = React.useMemo(
    () =>
      allEmployeesSkills
        .filter((employeeSkill) =>
          employeesSkillsIdsNumber.includes(employeeSkill.id),
        )
        .map((employeeSkill) => ({
          ...employeeSkill,
          attachments: employeeSkill.attachments
            ? employeeSkill.attachments.filter((attachment) =>
                Boolean(attachment.name),
              )
            : [],
        })),
    [allEmployeesSkills, employeesSkillsIdsNumber],
  );

  const skills = useSelector(getSkillsComboboxList);

  const levels = React.useMemo(
    () =>
      map(skillLevels, (skillLevel) => ({
        id: skillLevel,
        name: transformToCapitalize(skillLevel),
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })),
    [],
  );

  const [attachmentsByIndexData, setAttachmentsByIndexData] = React.useState<{
    [entityIndexInFormik: number]: {
      all: Array<File | IAttachmentData | undefined>;
      toAdd: Array<File>;
      toRemove: Array<number | string>;
    };
  }>({});

  const formik = useFormik({
    initialValues: employeesSkills,
    validate,
    enableReinitialize: true,
    onSubmit: async (data) => {
      onClose();

      onRequestsWithAttachmentsStart();

      for (const [index] of data.entries()) {
        // Add new attachments
        for (const toAdd of attachmentsByIndexData[index].toAdd) {
          const formData = new FormData();
          formData.append('file', toAdd);
          formData.append('employeeSkillId', String(data[index].id));
          await Api.EmployeeSkill.createAttachment(
            (formData as unknown) as ICreateEmployeeSkillAttachment,
          );
        }

        // Remove attachments that were deleted by user
        if (attachmentsByIndexData[index].toRemove.length) {
          await Api.EmployeeSkill.deleteAttachment({
            where: { id: { inq: attachmentsByIndexData[index].toRemove } },
          });
        }
      }

      // Update employee skill itself
      const sendData = data.map((item) => {
        if (item.expirationDate === 'N/A' || !item.expirationDate) {
          item.expirationDate = null;
        }

        if (!item.trainingDate) {
          item.trainingDate = null;
        }

        return omit(item, ['skill', 'attachments', 'employee', 'warning']);
      });

      onRequestsWithAttachmentsEnd();

      dispatch(
        updateEmployeeSkillsRequest({
          data: sendData,
          filters: { list: filterList, count: filterCount },
        }),
      );
    },
  });

  const showAttachmentUpload = React.useCallback(
    (index: number) =>
      formik.values[index] &&
      formik.values[index].level === skillLevels.TRAINED,
    [formik.values],
  );

  const onReset = () => {
    formik.resetForm();
  };

  const onLevelChange = (index: number) => (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption<string | number> | null,
  ) => {
    if (value?.id !== skillLevels.TRAINED) {
      formik.setFieldValue(`${index}.trainingDate`, null);
      formik.setFieldValue(`${index}.expirationDate`, null);
    }
  };

  const disableTrainingDate = React.useCallback(
    (index: number) =>
      formik.values[index] &&
      formik.values[index].level !== skillLevels.TRAINED,
    [formik.values],
  );

  React.useMemo(() => {
    setAttachmentsByIndexData(
      employeesSkills.reduce((acc, eS, index) => {
        acc[index] = {
          all: eS.attachments ?? [],
          toRemove: [],
          toAdd: [],
        };

        return acc;
      }, {}),
    );
  }, [employeesSkills]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('emp_skills.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {employeesSkills.map((skill, index) => (
            <DrawerBodySectionContent key={skill.id}>
              <DrawerBodySectionTitle>
                {`${skill.employee?.firstName} ${skill.employee?.lastName} (${skill.skill?.name})`}
              </DrawerBodySectionTitle>
              <FormFieldContainer>
                <EmployeesComboBox
                  onlyActive
                  id={generateFullIdInMultiEntitiesForm('employeeId', index)}
                  placeholder={t('common.select')}
                  label={t('emp_skills.employee')}
                  formik={formik}
                  errorMode="onFieldChange"
                  disabled
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id={generateFullIdInMultiEntitiesForm('skillId', index)}
                  placeholder={t('common.select')}
                  label={t('emp_skills.skill')}
                  options={skills as ComboBoxOption[]}
                  formik={formik}
                  errorMode="onFieldChange"
                  disabled
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id={generateFullIdInMultiEntitiesForm('level', index)}
                  placeholder={t('common.select')}
                  label={t('emp_skills.level')}
                  options={(levels as unknown) as ComboBoxOption[]}
                  formik={formik}
                  onChange={onLevelChange(index)}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikDatepicker
                  required
                  fullWidth
                  disabled={disableTrainingDate(index)}
                  label={t('emp_skills.training_date')}
                  id={generateFullIdInMultiEntitiesForm('trainingDate', index)}
                  formik={formik}
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikDatepicker
                  required
                  id={generateFullIdInMultiEntitiesForm(
                    'expirationDate',
                    index,
                  )}
                  label={t('emp_skills.expiration_date')}
                  formik={formik}
                  disabled
                />
              </FormFieldContainer>

              {showAttachmentUpload(index) && (
                <FormFieldContainer>
                  <EmployeeSkillAttachmentUploader
                    id={`employee-skill-attachment_${index}`}
                    onAttachmentsChange={(uploadedFile) =>
                      setAttachmentsByIndexData((prev) => {
                        const isAttachmentAlreadyAdded = prev[index].toAdd.some(
                          (a) =>
                            a.name === uploadedFile.name &&
                            a.size === uploadedFile.size,
                        );
                        // Add attachment only once
                        if (isAttachmentAlreadyAdded) {
                          return { ...prev };
                        }

                        return {
                          ...prev,
                          [index]: {
                            ...prev[index],
                            all: [...prev[index].all, uploadedFile],
                            toAdd: [...prev[index].toAdd, uploadedFile],
                          },
                        };
                      })
                    }
                    onAttachmentRemove={(attachmentId) => {
                      setAttachmentsByIndexData((prev) => {
                        const attachmentFullData = prev[index].all.find(
                          (_, i) => i === attachmentId,
                        );

                        return {
                          ...prev,
                          [index]: {
                            ...prev[index],
                            all: prev[index].all.filter(
                              (_, i) => i !== attachmentId,
                            ),
                            toAdd:
                              attachmentFullData &&
                              attachmentFullData instanceof File
                                ? prev[index].toAdd.filter(
                                    (a) =>
                                      a.name !== attachmentFullData.name &&
                                      a.size !== attachmentFullData.size,
                                  )
                                : [...prev[index].toAdd],
                            toRemove:
                              attachmentFullData &&
                              !(attachmentFullData instanceof File)
                                ? [
                                    ...prev[index].toRemove,
                                    attachmentFullData.id as any,
                                  ]
                                : [...prev[index].toRemove],
                          },
                        };
                      });
                    }}
                    attachments={attachmentsByIndexData[index].all as any}
                    employeeSkillId={skill.id}
                  />
                </FormFieldContainer>
              )}
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
