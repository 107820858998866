import { createSelector } from 'reselect';
import { IStoreState } from '../types';
import { NotificationPriority } from '../../config';

export const getNotifications = (state: IStoreState) => state.notification;

export const getNotificationsList = createSelector(
  getNotifications,
  (notifications) => {
    return notifications.list;
  },
);

export const getNotificationsCount = createSelector(
  getNotifications,
  (notifications) => {
    return notifications.count;
  },
);

export const getNotificationsCountUnread = createSelector(
  getNotifications,
  (notifications) => {
    return notifications.countUnread;
  },
);

export const getImportantNotificationsList = createSelector(
  getNotificationsList,
  (notifications) =>
    notifications.filter((n) =>
      [NotificationPriority.highest, NotificationPriority.high].includes(
        n.priority,
      ),
    ),
);

export const getIsMoreNotificationsRequestProcessing = createSelector(
  getNotifications,
  (notification) => {
    return notification.isGetMoreNotificationsRequestProcessing;
  },
);

export const getIsDeleteNotificationInProgress = createSelector(
  getNotifications,
  (notification) => {
    return notification.isDeleteNotificationInProgress;
  },
);

export const getIsAllNotificationRead = createSelector(
  getNotifications,
  (notification) => {
    return notification.list;
  },
);
