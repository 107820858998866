import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  IAdditionalFeeModel,
  IdsArray,
  IFilterData,
  IStoreState,
  IWhere,
} from '../../../../../../modules/types';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { sendForUpdateAdditionalFeesRequest } from '../../../../../../modules/actions';
import { Sheet } from '@mui/joy';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCheckbox from '../../../../../../components/Formik/FormikCheckbox';
import { updateAdditionalFeeScheme } from '../../../../../../modules/schemes/additional-fee/update-additional-fee.scheme';
import { useGetYearWeeksOptionsForAdditionalFee } from '../../../../../../modules/utils/hooks/common/yearWeek.hooks';
import { getAdditionalFeeByIds } from '../../../../../../modules/selectors/additionalFee.selector';
import AdditionalFeeCategoriesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/AdditionalFeeCategoryCombobox';
import FormikCombobox from '../../../../../../components/Formik/FormikCombobox';
import { omit } from 'lodash';

interface IUpdateAdditionalFeeProps {
  isOpen: boolean;
  onClose: () => void;
  idsToUpdate: IdsArray;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const UpdateAdditionalFee = ({
  isOpen,
  onClose,
  idsToUpdate,
  filterList,
  filterCount,
}: IUpdateAdditionalFeeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(updateAdditionalFeeScheme);
  const yearWeeks = useGetYearWeeksOptionsForAdditionalFee(
    new Date('2024-01-01T03:00:00'),
    2,
  );

  const additionalFeeList = useSelector(
    (state) => getAdditionalFeeByIds(state as IStoreState)(idsToUpdate),
    shallowEqual,
  );

  const formik = useFormikInUpdateForm({
    initialValues: additionalFeeList.map(
      (additionalFee) =>
        omit(additionalFee, [
          'site',
          'additionalFeeCategory',
        ]) as IAdditionalFeeModel,
    ),
    validate,
    enableReinitialize: true,
    onSubmit: (data: IAdditionalFeeModel[]) => {
      dispatch(
        sendForUpdateAdditionalFeesRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('additional-fee.update_fee')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {formik.values.map((additionalFee, index) => (
            <DrawerBodySectionContent key={additionalFee.id}>
              <DrawerBodySectionTitle>
                {`${t('additional-fee.update_fee')}: ${additionalFee.id}`}
              </DrawerBodySectionTitle>
              <Sheet sx={{ bgcolor: 'inherit' }}>
                <FormFieldContainer>
                  <SitesComboBox
                    id={`[${index}].siteId`}
                    formik={formik}
                    placeholder={t('payboard.site')}
                    required
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <AdditionalFeeCategoriesComboBox
                    id={`[${index}].additionalFeeCategoryId`}
                    selectedSiteId={formik.values[index].siteId}
                    formik={formik}
                    placeholder={t('additional-fee.category')}
                    required
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`[${index}].name`}
                    variant="outlined"
                    fullWidth
                    label={t('additional-fee.name')}
                    name={`[${index}].name`}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`[${index}].quantity`}
                    variant="outlined"
                    fullWidth
                    label={t('additional-fee.quantity')}
                    name={`[${index}].quantity`}
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCheckbox
                    id={`[${index}].isTemporary`}
                    name={`[${index}].isTemporary`}
                    formik={formik}
                    label={t('additional-fee.isTemporary')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    id={`[${index}].startingYearWeek`}
                    placeholder={t('additional-fee.startingYearWeek')}
                    options={yearWeeks}
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCombobox
                    id={`[${index}].finishingYearWeek`}
                    placeholder={t('additional-fee.finishingYearWeek')}
                    options={yearWeeks}
                    formik={formik}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    id={`[${index}].cpu`}
                    variant="outlined"
                    fullWidth
                    label={t('additional-fee.cpu')}
                    name={`[${index}].cpu`}
                    formik={formik}
                  />
                </FormFieldContainer>
              </Sheet>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default UpdateAdditionalFee;
