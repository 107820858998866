import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IClientModel,
  ICountResponse,
  ICreateClientRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new client
 */
export const createClientRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<ICreateClientRequest>>): SagaIterator {
  try {
    yield call(Api.Client.create, data);

    yield put(actions.getClientListRequest({ filter: list }));

    yield put(actions.getClientCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.createClientSuccess());
  } catch (e) {
    yield put(actions.createClientFailed());
  }
};

/**
 * Get client list
 */
export const getClientListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IClientModel[] = yield call(
      Api.Client.list,
      action.payload,
    );
    yield put(actions.getClientListSuccess(response));
  } catch (e) {
    yield put(actions.getClientListFailed());
  }
};

/**
 * Get client count
 */
export const getClientCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Client.count,
      action?.payload,
    );
    yield put(actions.getClientCountSuccess(response));
  } catch (e) {
    yield put(actions.getClientCountFailed());
  }
};

/**
 * Delete clients
 */
export const deleteClientRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IdsArray>>): SagaIterator {
  try {
    yield call(Api.Client.delete, {
      where: { id: { inq: data } },
    });

    yield put(actions.getClientListRequest({ filter: list }));

    yield put(actions.getClientCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.deleteClientSuccess());
  } catch (e) {
    yield put(actions.deleteClientFailed());
  }
};

/**
 * Bulk update clients
 */
export const updateClientsRequestSaga = function* ({
  payload: {
    data,
    filters: { list, count },
  },
}: ISagaAction<PayloadWithFilters<IClientModel[]>>): SagaIterator {
  try {
    yield call(Api.Client.bulkUpdate, data);

    yield put(actions.getClientListRequest({ filter: list }));

    yield put(actions.getClientCountRequest({ filter: count }));

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );

    yield put(actions.updateClientsSuccess());
  } catch (e) {
    yield put(actions.updateClientsFailed());
  }
};

/**
 * Get combobox list
 */
export const getClientComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IClientModel>[] = yield call(
      Api.Client.list,
      action.payload,
    );
    yield put(actions.getClientComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getClientComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Client.export,
  actionFailed: actions.exportClientFailed,
  actionSuccess: actions.exportClientSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportClientRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createClientRequest, saga: createClientRequestSaga },
  { action: actions.getClientListRequest, saga: getClientListRequestSaga },
  { action: actions.getClientCountRequest, saga: getClientCountRequestSaga },
  { action: actions.deleteClientRequest, saga: deleteClientRequestSaga },
  // bulk updating clients
  { action: actions.updateClientsRequest, saga: updateClientsRequestSaga },
  {
    action: actions.getClientComboboxListRequest,
    saga: getClientComboboxListRequestSaga,
  },
  { action: actions.exportClientRequest, saga: exportClientRequestSaga },
];
