import React from 'react';
import { Box } from '@mui/joy';

interface IReportPageActionsGroupContainerProps {
  children: React.ReactNode;
}

export const ReportPageActionsGroupContainer = ({
  children,
}: IReportPageActionsGroupContainerProps) => {
  return <Box sx={{ display: 'flex', ml: 2 }}>{children}</Box>;
};
