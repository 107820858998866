import React from 'react';
import UploadAttachments, {
  IAttachmentData,
} from '../../../../components/UploadAttachments';

interface IProps {
  id: string;
  employeeSkillId: number;
  onAttachmentRemove: (attachmentId: number) => void;
  onAttachmentsChange: (uploadedFile: File) => void;
  attachments?: Array<IAttachmentData>;
}

const EmployeeSkillAttachmentUploader: React.FC<IProps> = ({
  id,
  attachments,
  onAttachmentRemove,
  onAttachmentsChange,
}) => {
  return (
    <UploadAttachments
      id={id}
      accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      onChange={onAttachmentsChange}
      onRemove={onAttachmentRemove}
      attachments={attachments}
    />
  );
};

export default EmployeeSkillAttachmentUploader;
