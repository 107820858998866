import React from 'react';
import { blue, green } from '@mui/material/colors';
import { createSelector } from 'reselect';
import { IChecklistState, IChecklistTaskAsEvent, IStoreState } from '../types';
import * as uuid from 'uuid';
import { NoIcon, YesIcon } from 'src/components/Icons';
import { getAllOccurrencesBetween } from '../utils/rRuleWrapper';
import {
  composeDate,
  DATETIME_TIMEZONE,
  parse,
  format,
  isSameSecond,
  DATE_FORMAT,
} from '../utils/dateWrapper';
import {
  generateEndDateTimeOfTask,
  generateStartDateTimeOfAutogeneratedTask,
  generateStartDateTimeOfRecurrentTask,
} from '../utils/helpers/recurringTasks';

export const getChecklistRefreshKey = (state: IStoreState) =>
  state.checklist.refreshKey;

export const getChecklistTaskRefreshKey = (state: IStoreState) =>
  state.checklist.taskRefreshKey;

export const getChecklistState = (state: IStoreState) =>
  state.checklist as IChecklistState;

export const getChecklists = createSelector(
  getChecklistState,
  ({ checklists }) => checklists,
);

export const getChecklistsCount = createSelector(
  getChecklistState,
  ({ count }) => count,
);

export const getChecklistComboboxData = createSelector(
  getChecklistState,
  ({ combobox }) => combobox,
);

export const getChecklistsAsTableData = createSelector(
  getChecklists,
  (checklists) =>
    checklists.map((checklist) => ({
      ...checklist,
      userFullName: `${checklist.user.firstName} ${checklist.user.lastName}`,
      className: 'checklist-table-row',
      done: checklist.done ? (
        <YesIcon color="primary" />
      ) : (
        <NoIcon color="primary" />
      ),
    })),
);

export const getChecklistCombobox = createSelector(
  getChecklistComboboxData,
  (checklists) =>
    checklists.map((checklist) => ({
      ...checklist,
      name: checklist.title,
    })),
);

export const getTasks = createSelector(getChecklistState, ({ tasks }) => tasks);

export const getTasksAsEvents = (datesRangeInCalendar: [Date, Date] | []) => {
  return createSelector(getTasks, (tasks) =>
    tasks.reduce<IChecklistTaskAsEvent[]>((allTasks, task) => {
      // For regular task we just need to add some additional fields
      const taskWithAdditionalProps = {
        ...task,
        id: String(task.id),
        start: task.startTime,
        end: task.endTime,
        color: task.done ? green[400] : blue[400],
      };
      // We should wait till full calendar pass dates range
      // for the current view in other case rrule lib
      // will go to infinity loop
      if (!datesRangeInCalendar.length) {
        return [...allTasks, taskWithAdditionalProps];
      }
      // Regular task
      if (!task.recurrence) {
        return [...allTasks, taskWithAdditionalProps];
      }
      // This function generates start/end dates for by task recurrence
      // for the current full calendar view day/week/month etc..
      const autogeneratedRecurringTasksDates = getAllOccurrencesBetween(
        task.recurrence,
        datesRangeInCalendar,
      );

      const recurringTasks = autogeneratedRecurringTasksDates.map((date) => {
        // When user change status (done/undone) of autogenerated task
        // we persist it in DB and it should be used instead of autogenerated
        const existedRecurringTask = (task.recurringTasks || [])
          .filter((t) => t.id)
          .find((recurringTask) => {
            const recurringTaskStartDateTime = generateStartDateTimeOfRecurrentTask(
              recurringTask.date ?? recurringTask.startTime,
              task.startTime,
            );

            return composeDate(recurringTaskStartDateTime, isSameSecond(date));
          });

        if (existedRecurringTask) {
          const recurringTaskStartDateTime = generateStartDateTimeOfRecurrentTask(
            existedRecurringTask.date ?? existedRecurringTask.startTime,
            task.startTime,
          );
          const recurringTaskEndDateTime = generateEndDateTimeOfTask(
            recurringTaskStartDateTime,
            task.startTime,
            task.endTime,
          );

          return {
            id: String(existedRecurringTask.id),
            title: task.title,
            done: Boolean(existedRecurringTask.done),
            startTime: composeDate(
              recurringTaskStartDateTime,
              format(DATETIME_TIMEZONE),
            ),
            start: composeDate(
              recurringTaskStartDateTime,
              format(DATETIME_TIMEZONE),
            ),
            text: task.text,
            checklistId: task.checklistId,
            endTime: composeDate(
              recurringTaskEndDateTime,
              format(DATETIME_TIMEZONE),
            ),
            end: composeDate(
              recurringTaskEndDateTime,
              format(DATETIME_TIMEZONE),
            ),
            checklistTaskId: task.id,
            color: existedRecurringTask.done ? green[400] : blue[400],
            recurrence: task.recurrence,
            recurrenceStartDate: composeDate(
              task.startTime,
              parse(DATETIME_TIMEZONE),
              format(DATE_FORMAT),
            ),
          };
        }

        const autogeneratedTaskStartDateTime = generateStartDateTimeOfAutogeneratedTask(
          date,
          task.startTime,
        );
        const autogeneratedTaskEndDateTime = generateEndDateTimeOfTask(
          autogeneratedTaskStartDateTime,
          task.startTime,
          task.endTime,
        );

        return {
          id: uuid.v4(),
          title: task.title,
          done: false,
          startTime: composeDate(
            autogeneratedTaskStartDateTime,
            format(DATETIME_TIMEZONE),
          ),
          start: composeDate(
            autogeneratedTaskStartDateTime,
            format(DATETIME_TIMEZONE),
          ),
          text: task.text,
          checklistId: task.checklistId,
          endTime: composeDate(
            autogeneratedTaskEndDateTime,
            format(DATETIME_TIMEZONE),
          ),
          end: composeDate(
            autogeneratedTaskEndDateTime,
            format(DATETIME_TIMEZONE),
          ),
          checklistTaskId: task.id,
          color: blue[400],
          recurrence: task.recurrence,
          recurrenceStartDate: composeDate(
            task.startTime,
            parse(DATETIME_TIMEZONE),
            format(DATE_FORMAT),
          ),
        };
      });
      return [...allTasks, ...recurringTasks];
    }, []),
  );
};
