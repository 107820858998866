import { createReducer } from 'redux-act';
import * as actions from '../../actions';
import {
  ICountResponse,
  IRecruitingDashboardDetailsModel,
  IRecruitingDashboardDetailsState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IRecruitingDashboardDetailsState = {
  openPositionId: NaN,
  position: '',
  openPositions: NaN,
  siteName: '',
  dateRequested: '',
  whoRequested: NaN,
  fillPercent: NaN,
  list: [],
  count: 0,
  description: '',
};

/**
 * Get list actions
 */
const getRecruitingDashboardDetailsListRequest = (
  state: IRecruitingDashboardDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRecruitingDashboardDetailsListSuccess = (
  state: IRecruitingDashboardDetailsState,
  payload: IRecruitingDashboardDetailsModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getRecruitingDashboardDetailsListFailed = (
  state: IRecruitingDashboardDetailsState,
) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getRecruitingDashboardDetailsCountRequest = (
  state: IRecruitingDashboardDetailsState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getRecruitingDashboardDetailsCountSuccess = (
  state: IRecruitingDashboardDetailsState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getRecruitingDashboardDetailsCountFailed = (
  state: IRecruitingDashboardDetailsState,
) => ({
  ...state,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IRecruitingDashboardDetailsState>();

const exportRecruitingDashboardDetailsRequest = exportRequest;
const exportRecruitingDashboardDetailsSuccess = exportSuccess;
const exportRecruitingDashboardDetailsFailed = exportFailed;

/**
 * RecruitingDashboardDetails reducer
 */
export const recruitingDashboardDetails = createReducer<
  IRecruitingDashboardDetailsState
>({}, defaultState)
  // get list actions
  .on(
    actions.getRecruitingDashboardDetailsListRequest,
    getRecruitingDashboardDetailsListRequest,
  )
  .on(
    actions.getRecruitingDashboardDetailsListSuccess,
    getRecruitingDashboardDetailsListSuccess,
  )
  .on(
    actions.getRecruitingDashboardDetailsListFailed,
    getRecruitingDashboardDetailsListFailed,
  )
  // get count actions
  .on(
    actions.getRecruitingDashboardDetailsCountRequest,
    getRecruitingDashboardDetailsCountRequest,
  )
  .on(
    actions.getRecruitingDashboardDetailsCountSuccess,
    getRecruitingDashboardDetailsCountSuccess,
  )
  .on(
    actions.getRecruitingDashboardDetailsCountFailed,
    getRecruitingDashboardDetailsCountFailed,
  )
  // export actions
  .on(
    actions.exportRecruitingDashboardDetailsRequest,
    exportRecruitingDashboardDetailsRequest,
  )
  .on(
    actions.exportRecruitingDashboardDetailsSuccess,
    exportRecruitingDashboardDetailsSuccess,
  )
  .on(
    actions.exportRecruitingDashboardDetailsFailed,
    exportRecruitingDashboardDetailsFailed,
  );

export default recruitingDashboardDetails;
