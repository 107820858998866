import React from 'react';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useFetchClientsCombobox } from 'src/modules/utils';
import { HorizontalBarChart } from 'src/components/Charts/2d/HorizontalBarChart';
import { getClientsComboboxList } from 'src/modules/selectors/client';
import { useSelector } from 'react-redux';
import {
  ComboboxSeparator,
  DoubledCombobox,
  LeftCombobox,
  RightCombobox,
} from 'src/components/_ui-kit/Combobox/DoubledCombobox';
import { useTranslation } from 'react-i18next';
import { useGetDataForCharts } from 'src/modules/utils/hooks/common/request';
import { fetchProjectionVsActualRevenueByCustomerChartData } from 'src/modules/utils/Api/projectionVsActualChartsDashboardDataRequests';
import {
  financialColor,
  projectionColor,
  ydtLegends,
} from '../FinancialDashboardTotalsChart/constants';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import { useStateOnMountedOnly } from 'src/modules/utils/hooks/common/reports';
import { ListItem } from '@mui/joy';

export const FinancialDashboardYDTByCustomerChart = () => {
  const { t } = useTranslation();

  const {
    chartRef,
    chartHeight,
    exportChart,
    toggleFullScreen,
  } = useChartRequiredUtils();

  const clients = useSelector(getClientsComboboxList);

  const { data: client, setData: setClient } = useStateOnMountedOnly<
    string | null | undefined
  >(null);

  const fetchClientsCombobox = useFetchClientsCombobox({
    filter: {
      fields: {
        id: true,
        name: true,
      },
      where: {
        name: {
          nin: ['Client_Name_R1', 'New_Client_Name_1'],
        },
      },
    },
  });

  const { getData, isLoading, data } = useGetDataForCharts(
    fetchProjectionVsActualRevenueByCustomerChartData,
    {
      initialData: [],
    },
  );

  const categories = React.useMemo(() => (client ? [client] : []), [client]);

  const series = React.useMemo(() => {
    return [
      {
        name: ydtLegends.sumOfYDTRevenue,
        type: 'bar' as const,
        color: financialColor,
        data: data
          .filter((item) => item.clientName === client)
          .map((item) => item.revenue),
      },
      {
        name: ydtLegends.sumOfYDTProjRevenue,
        type: 'bar' as const,
        color: projectionColor,
        data: data
          .filter((item) => item.clientName === client)
          .map((item) => item.projectionRevenue),
      },
    ];
  }, [client, data]);

  React.useEffect(() => {
    if (clients.length) {
      setClient(clients[0]?.name ?? null);
    }
  }, [clients, setClient]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  React.useEffect(() => {
    fetchClientsCombobox();
  }, [fetchClientsCombobox]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t(
          'financial_reporting_dashboard.chart_title.ydt_revenue_by_customer',
        )}
      >
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(`ydt_revenue_by_customer`)}
          />
          <DoubledCombobox>
            <LeftCombobox
              readOnly
              disableClearable
              options={[]}
              popupIcon={null}
              value={t('common.customer')}
            />
            <ComboboxSeparator />
            <RightCombobox
              disableClearable={!!client}
              value={client}
              options={clients.map((client) => client.name)}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  sx={{
                    cursor: 'pointer',
                    bgcolor:
                      option === client
                        ? 'colors.background.bg_primary_hover'
                        : 'transparent',
                  }}
                >
                  {option}
                </ListItem>
              )}
              onChange={(_, value) => setClient(value)}
            />
          </DoubledCombobox>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody
        isLoading={isLoading}
        showNoData={!series[0].data.length && !series[1].data.length}
      >
        <HorizontalBarChart
          ref={chartRef}
          height={chartHeight}
          categories={categories}
          series={series}
          plotOptions={{
            series: {
              dataLabels: {
                enabled: true,
                format: '{y} $',
              },
            },
          }}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
