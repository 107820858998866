import { createReducer } from 'redux-act';
import * as actions from '../actions';
import {
  ICountResponse,
  IdsArray,
  ILogtimeModel,
  ILogtimeState,
  ILogtimeSupervisorViewModel,
} from '../types';
import { remove } from 'lodash';
import { ExportReducerCreator } from '../utils/helpers/creators/export';
import { getNow, guessTimezone } from '../utils/dateWrapper';

// default state
const defaultState: ILogtimeState = {
  refreshKey: 0,
  id: NaN,
  departmentId: NaN,
  shiftId: NaN,
  staffingId: NaN,
  timeIn: '',
  badge: NaN,
  timezone: guessTimezone(),
  list: [],
  supervisorViewList: [],
  comboboxList: [],
  count: 0,

  approvedList: [],
  approvedCount: 0,

  unapprovedList: [],
  unapprovedCount: 0,

  missedList: [],
  missedCount: 0,
  isMissedPunchesRequestInProgress: false,

  isListDataLoading: false,
  isCountDataLoading: false,
};

/**
 * Flush actions
 */
const flushLogtimeState = (state: ILogtimeState) => ({
  ...state,
  refreshKey: getNow().getTime(),
});

/**
 * Creation actions
 */
const createLogtimeRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createLogtimeSuccess = (
  state: ILogtimeState,
  payload: ILogtimeModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createLogtimeFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getLogtimeListRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getLogtimeListSuccess = (
  state: ILogtimeState,
  payload: ILogtimeModel[],
) => ({
  ...state,
  list: payload.map((punch) => ({
    ...punch,
    punchType: !punch.punchType ? 'regular' : punch.punchType,
  })),
  error: false,
  is_data_requested: false,
});

const getLogtimeListFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});
/**
 * Get list for supervisor view actions
 */
const getLogtimeListForSupervisorViewRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getLogtimeListForSupervisorViewSuccess = (
  state: ILogtimeState,
  payload: ILogtimeSupervisorViewModel[],
) => ({
  ...state,
  supervisorViewList: payload,
  error: false,
  is_data_requested: false,
});

const getLogtimeListForSupervisorViewFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getLogtimeCountRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getLogtimeCountSuccess = (
  state: ILogtimeState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getLogtimeCountFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteLogtimeRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteLogtimeFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateLogtimesRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateLogtimesFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getLogtimeComboboxListRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getLogtimeComboboxListSuccess = (
  state: ILogtimeState,
  payload: Partial<ILogtimeModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getLogtimeComboboxListFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Approve actions
 */
const approveLogtimesRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const approveLogtimesSuccess = (state: ILogtimeState, payload: IdsArray) => {
  const unapprovedList = remove(state.unapprovedList, (item) =>
    payload.includes(item.id),
  );
  return {
    ...state,
    unapprovedList,
    error: false,
    is_data_requested: false,
  };
};

const approveLogtimesFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const unapproveLogtimesRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const unapproveLogtimesSuccess = (state: ILogtimeState, payload: IdsArray) => {
  const approvedList = remove(state.approvedList, (item) =>
    payload.includes(item.id),
  );
  return {
    ...state,
    approvedList,
    error: false,
    is_data_requested: false,
  };
};

const unapproveLogtimesFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<ILogtimeState>();

// Approved
const getLogtimeApprovedListRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isListDataLoading: true,
});

const getLogtimeApprovedListSuccess = (
  state: ILogtimeState,
  payload: ILogtimeModel[],
) => ({
  ...state,
  approvedList: payload,
  error: false,
  is_data_requested: false,
  isListDataLoading: false,
});

const getLogtimeApprovedListFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isListDataLoading: false,
});

const getLogtimeApprovedCountRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isCountDataLoading: true,
});

const getLogtimeApprovedCountSuccess = (
  state: ILogtimeState,
  payload: ICountResponse,
) => ({
  ...state,
  approvedCount: payload.count,
  error: false,
  is_data_requested: false,
  isCountDataLoading: false,
});

const getLogtimeApprovedCountFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isCountDataLoading: false,
});

const {
  exportRequest: exportApprovedRequest,
  exportFailed: exportApprovedFailed,
  exportSuccess: exportApprovedSuccess,
} = new ExportReducerCreator().takeHandlers<ILogtimeState>();

// Unapproved
const getLogtimeUnapprovedListRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isListDataLoading: true,
});

const getLogtimeUnapprovedListSuccess = (
  state: ILogtimeState,
  payload: ILogtimeModel[],
) => ({
  ...state,
  unapprovedList: payload,
  error: false,
  is_data_requested: false,
  isListDataLoading: false,
});

const getLogtimeUnapprovedListFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isListDataLoading: false,
});

const moveToMissedLogtimesRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isListDataLoading: true,
});

const moveToMissedLogtimesSuccess = (
  state: ILogtimeState,
  movedPunchesIds: IdsArray,
) => ({
  ...state,
  unapprovedList: state.unapprovedList.filter(
    (item) => !movedPunchesIds.includes(item.id),
  ),
  error: false,
  is_data_requested: false,
  isListDataLoading: false,
});

const moveToMissedLogtimesFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isListDataLoading: false,
});

const getLogtimeUnapprovedCountRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isCountDataLoading: true,
});

const getLogtimeUnapprovedCountSuccess = (
  state: ILogtimeState,
  payload: ICountResponse,
) => ({
  ...state,
  unapprovedCount: payload.count,
  error: false,
  is_data_requested: false,
  isCountDataLoading: false,
});

const getLogtimeUnapprovedCountFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isCountDataLoading: false,
});

const {
  exportRequest: exportUnapprovedRequest,
  exportFailed: exportUnapprovedFailed,
  exportSuccess: exportUnapprovedSuccess,
} = new ExportReducerCreator().takeHandlers<ILogtimeState>();

// Missed
const getLogtimeMissedListRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  isMissedPunchesRequestInProgress: true,
});

const getLogtimeMissedListSuccess = (
  state: ILogtimeState,
  payload: ILogtimeModel[],
) => ({
  ...state,
  missedList: payload,
  error: false,
  isMissedPunchesRequestInProgress: false,
});

const getLogtimeMissedListFailed = (state: ILogtimeState, payload: string) => ({
  ...state,
  error: payload,
  isMissedPunchesRequestInProgress: false,
});

const getLogtimeMissedCountRequest = (state: ILogtimeState) => ({
  ...state,
  error: false,
  isMissedPunchesRequestInProgress: true,
});

const getLogtimeMissedCountSuccess = (
  state: ILogtimeState,
  payload: ICountResponse,
) => ({
  ...state,
  missedCount: payload.count,
  error: false,
  isMissedPunchesRequestInProgress: false,
});

const getLogtimeMissedCountFailed = (
  state: ILogtimeState,
  payload: string,
) => ({
  ...state,
  error: payload,
  isMissedPunchesRequestInProgress: false,
});

const {
  exportRequest: exportMissedRequest,
  exportFailed: exportMissedFailed,
  exportSuccess: exportMissedSuccess,
} = new ExportReducerCreator().takeHandlers<ILogtimeState>();

/**
 * Logtime reducer
 */
export const logtime = createReducer<ILogtimeState>({}, defaultState)
  // flush actions
  .on(actions.flushLogtimeState, flushLogtimeState)
  // creation actions
  .on(actions.createLogtimeRequest, createLogtimeRequest)
  .on(actions.createLogtimeSuccess, createLogtimeSuccess)
  .on(actions.createLogtimeFailed, createLogtimeFailed)
  // get list actions
  .on(
    actions.getLogtimeListForSupervisorViewRequest,
    getLogtimeListForSupervisorViewRequest,
  )
  .on(
    actions.getLogtimeListForSupervisorViewSuccess,
    getLogtimeListForSupervisorViewSuccess,
  )
  .on(
    actions.getLogtimeListForSupervisorViewFailed,
    getLogtimeListForSupervisorViewFailed,
  )
  // get list for supervisor view actions
  .on(actions.getLogtimeListRequest, getLogtimeListRequest)
  .on(actions.getLogtimeListSuccess, getLogtimeListSuccess)
  .on(actions.getLogtimeListFailed, getLogtimeListFailed)
  // get count actions
  .on(actions.getLogtimeCountRequest, getLogtimeCountRequest)
  .on(actions.getLogtimeCountSuccess, getLogtimeCountSuccess)
  .on(actions.getLogtimeCountFailed, getLogtimeCountFailed)
  // deletion actions
  .on(actions.deleteLogtimeRequest, deleteLogtimeRequest)
  .on(actions.deleteLogtimeFailed, deleteLogtimeFailed)
  // updating actions
  .on(actions.updateLogtimesRequest, updateLogtimesRequest)
  .on(actions.updateLogtimesFailed, updateLogtimesFailed)
  // get combobox list actions
  .on(actions.getLogtimeComboboxListRequest, getLogtimeComboboxListRequest)
  .on(actions.getLogtimeComboboxListSuccess, getLogtimeComboboxListSuccess)
  .on(actions.getLogtimeComboboxListFailed, getLogtimeComboboxListFailed)
  // approve actions
  .on(actions.approveLogtimesRequest, approveLogtimesRequest)
  .on(actions.approveLogtimesSuccess, approveLogtimesSuccess)
  .on(actions.approveLogtimesFailed, approveLogtimesFailed)
  .on(actions.unapproveLogtimesRequest, unapproveLogtimesRequest)
  .on(actions.unapproveLogtimesSuccess, unapproveLogtimesSuccess)
  .on(actions.unapproveLogtimesFailed, unapproveLogtimesFailed)
  // export actions
  .on(actions.exportLogtimeRequest, exportRequest)
  .on(actions.exportLogtimeSuccess, exportSuccess)
  .on(actions.exportLogtimeFailed, exportFailed)
  // export supervisor-view actions
  .on(actions.exportSupervisorViewRequest, exportRequest)
  .on(actions.exportSupervisorViewSuccess, exportSuccess)
  .on(actions.exportSupervisorViewFailed, exportFailed)

  .on(actions.getLogtimeApprovedListRequest, getLogtimeApprovedListRequest)
  .on(actions.getLogtimeApprovedListSuccess, getLogtimeApprovedListSuccess)
  .on(actions.getLogtimeApprovedListFailed, getLogtimeApprovedListFailed)
  .on(actions.getLogtimeApprovedCountRequest, getLogtimeApprovedCountRequest)
  .on(actions.getLogtimeApprovedCountSuccess, getLogtimeApprovedCountSuccess)
  .on(actions.getLogtimeApprovedCountFailed, getLogtimeApprovedCountFailed)
  .on(actions.exportLogtimeRequest, exportApprovedRequest)
  .on(actions.exportLogtimeSuccess, exportApprovedSuccess)
  .on(actions.exportLogtimeFailed, exportApprovedFailed)

  .on(actions.getLogtimeUnapprovedListRequest, getLogtimeUnapprovedListRequest)
  .on(actions.getLogtimeUnapprovedListSuccess, getLogtimeUnapprovedListSuccess)
  .on(actions.getLogtimeUnapprovedListFailed, getLogtimeUnapprovedListFailed)
  .on(
    actions.getLogtimeUnapprovedCountRequest,
    getLogtimeUnapprovedCountRequest,
  )
  .on(
    actions.getLogtimeUnapprovedCountSuccess,
    getLogtimeUnapprovedCountSuccess,
  )
  .on(actions.getLogtimeUnapprovedCountFailed, getLogtimeUnapprovedCountFailed)
  .on(actions.exportLogtimeRequest, exportUnapprovedRequest)
  .on(actions.exportLogtimeSuccess, exportUnapprovedSuccess)
  .on(actions.exportLogtimeFailed, exportUnapprovedFailed)
  // Missed
  .on(actions.getLogtimeMissedListRequest, getLogtimeMissedListRequest)
  .on(actions.getLogtimeMissedListSuccess, getLogtimeMissedListSuccess)
  .on(actions.getLogtimeMissedListFailed, getLogtimeMissedListFailed)
  .on(actions.getLogtimeMissedCountRequest, getLogtimeMissedCountRequest)
  .on(actions.getLogtimeMissedCountSuccess, getLogtimeMissedCountSuccess)
  .on(actions.getLogtimeMissedCountFailed, getLogtimeMissedCountFailed)
  .on(actions.exportLogtimeRequest, exportMissedRequest)
  .on(actions.exportLogtimeSuccess, exportMissedSuccess)
  .on(actions.exportLogtimeFailed, exportMissedFailed)
  .on(actions.moveToMissedLogtimesRequest, moveToMissedLogtimesRequest)
  .on(actions.moveToMissedLogtimesSuccess, moveToMissedLogtimesSuccess)
  .on(actions.moveToMissedLogtimesFailed, moveToMissedLogtimesFailed);

export default logtime;
