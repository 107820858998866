import { memoize, reduce, omit } from 'lodash';
import { createSelector } from 'reselect';
import {
  IdsArray,
  IStoreState,
  IUpdateSiteTaktExportingSettings,
} from '../types';

const getSiteTaktExportingData = (state: IStoreState) => {
  return state.siteTaktExportingSettings;
};

/**
 * Get Refresh Key
 * @param state
 */
export const getSiteTaktExportingSettingsRefreshKey = (state: IStoreState) =>
  state.siteTaktExportingSettings.refreshKey;

/**
 * Get Server Error
 * @param siteTaktExportingSettings
 */
export const getServerError = ({ siteTaktExportingSettings }: IStoreState) =>
  siteTaktExportingSettings.error;

/**
 * Get Settings List
 * @param siteTaktExportingSettings
 */
export const getSiteTaktExportingSettingsList = ({
  siteTaktExportingSettings,
}: IStoreState) => siteTaktExportingSettings.list;

/**
 * Get siteTaktExportingSettings by array of ids
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getSiteTaktExportingSettingsByIds = createSelector(
  getSiteTaktExportingSettingsList,
  (siteTaktExportingSettings) =>
    memoize((ids: IdsArray) => {
      const initial: IUpdateSiteTaktExportingSettings[] = [];
      return reduce(
        siteTaktExportingSettings,
        (acc, cur) => {
          if (ids.includes(`${cur.id}`)) {
            acc.push(
              (omit(cur, [
                'site',
              ]) as unknown) as IUpdateSiteTaktExportingSettings,
            );
          }
          return acc;
        },
        initial,
      );
    }),
);

/**
 * Get Settings Count
 * @param siteTaktExportingSettings
 */
export const getSiteTaktExportingSettingsCount = ({
  siteTaktExportingSettings,
}: IStoreState) => siteTaktExportingSettings.count;

export const getIsTaktSettingsDataLoading = createSelector(
  getSiteTaktExportingData,
  (data) =>
    data.isTaktSettingsListInProgress ||
    data.isTaktSettingsCountInProgress ||
    data.isManageTaktSettingsOperationInProgress,
);
