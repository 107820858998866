import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IChecklistModelWithRelations,
  IChecklistTaskModel,
  ICreateChecklistTaskData,
  ICountResponse,
  ICreateChecklistData,
  IChecklistModel,
  IUpdateChecklistData,
  IChecklistRecurringTaskModel,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createChecklistTaskRequest,
    saga: function* (
      action: ISagaAction<ICreateChecklistTaskData>,
    ): SagaIterator {
      try {
        const response: IChecklistTaskModel = yield call(
          Api.Checklist.createTask,
          action.payload,
        );
        yield put(actions.createChecklistTaskSuccess(response));
        yield put(actions.flushChecklistTaskState());
      } catch (e) {
        yield put(actions.createChecklistTaskFailed());
      }
    },
  },
  {
    action: actions.createChecklistRequest,
    saga: function* (action: ISagaAction<ICreateChecklistData>): SagaIterator {
      try {
        const response: IChecklistModel = yield call(
          Api.Checklist.createChecklist,
          action.payload,
        );
        yield put(actions.flushChecklistState());
        yield put(actions.createChecklistSuccess(response));
      } catch (e) {
        yield put(actions.createChecklistFailed());
      }
    },
  },
  {
    action: actions.getChecklistsRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: IChecklistModelWithRelations[] = yield call(
          Api.Checklist.list,
          action.payload,
        );
        yield put(actions.getChecklistsSuccess(response));
      } catch (e) {
        yield put(actions.getChecklistsFailed());
      }
    },
  },
  {
    action: actions.getChecklistComboboxDataRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: IChecklistModelWithRelations[] = yield call(
          Api.Checklist.list,
          action.payload,
        );
        yield put(actions.getChecklistComboboxDataSuccess(response));
      } catch (e) {
        yield put(actions.getChecklistComboboxDataFailed());
      }
    },
  },
  {
    action: actions.getChecklistTasksRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: IChecklistTaskModel[] = yield call(
          Api.Checklist.tasks,
          action.payload,
        );
        yield put(actions.getChecklistTasksSuccess(response));
      } catch (e) {
        yield put(actions.getChecklistTasksFailed());
      }
    },
  },
  {
    action: actions.updateChecklistTaskRequest,
    saga: function* (
      action: ISagaAction<IChecklistTaskModel & IChecklistRecurringTaskModel>,
    ): SagaIterator {
      try {
        yield call(Api.Checklist.updateTask, action.payload);
        yield put(actions.updateChecklistTaskSuccess(action.payload));
        yield put(actions.flushChecklistTaskState());
      } catch (e) {
        yield put(actions.updateChecklistTaskFailed());
      }
    },
  },
  {
    action: actions.updateChecklistRequest,
    saga: function* (action: ISagaAction<IUpdateChecklistData>): SagaIterator {
      try {
        yield call(Api.Checklist.updateChecklist, action.payload);

        yield put(actions.flushChecklistState());
        yield put(actions.updateChecklistSuccess());
      } catch (e) {
        yield put(actions.updateChecklistFailed());
      }
    },
  },
  {
    action: actions.deleteChecklistTaskRequest,
    saga: function* (
      action: ISagaAction<IChecklistTaskModel['id']>,
    ): SagaIterator {
      try {
        yield call(Api.Checklist.deleteTask, {
          where: { id: { eq: action.payload } },
        });
        yield put(actions.deleteChecklistTaskSuccess(action.payload));
      } catch (e) {
        yield put(actions.deleteChecklistTaskFailed());
      }
    },
  },
  {
    action: actions.deleteChecklistRecurringTaskRequest,
    saga: function* (
      action: ISagaAction<IChecklistRecurringTaskModel['id']>,
    ): SagaIterator {
      try {
        yield call(Api.Checklist.deleteRecurringTask, {
          where: { id: { eq: action.payload } },
        });
        yield put(actions.deleteChecklistTaskSuccess(action.payload));
      } catch (e) {
        yield put(actions.deleteChecklistTaskFailed());
      }
    },
  },
  {
    action: actions.deleteChecklistRequest,
    saga: function* (action: ISagaAction<IChecklistModel['id']>): SagaIterator {
      try {
        yield call(Api.Checklist.deleteChecklist, {
          where: { id: { eq: action.payload } },
        });
        yield put(actions.flushChecklistState());
        yield put(actions.deleteChecklistSuccess());
      } catch (e) {
        yield put(actions.deleteChecklistFailed());
      }
    },
  },
  {
    action: actions.getChecklistsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.Checklist.countChecklists,
          action?.payload,
        );
        yield put(actions.getChecklistsCountSuccess(response));
      } catch (e) {
        yield put(actions.getChecklistsCountFailed());
      }
    },
  },
];
