import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ComboBoxOption } from 'src/components/ComboBox';
import { updateIssuesRequest } from 'src/modules/actions/issue';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { getIssueTypesComboboxList } from 'src/modules/selectors/issueType';
import { IUpdateIssue } from 'src/modules/types';
import {
  useFetchActiveDepartmentsCombobox,
  useFetchIssueTypesCombobox,
  useValidate,
} from 'src/modules/utils/hooks';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikImageUpload from 'src/components/Formik/FormikImageUpload';
import { useFormikInUpdateForm } from 'src/modules/utils/hooks/common/forms';
import { updateIssuesScheme } from 'src/modules/schemes/issues';
import { generateFormDataFieldsForUpdateEntityForm } from 'src/modules/utils/helpers/form';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import { getDepartmentOptionsByGloballySites } from 'src/modules/selectors/department';
import FormikTextarea from 'src/components/Formik/FormikTextarea';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { parseISO } from 'date-fns';

interface IDowntimeUpdateFormProps {
  issues: IUpdateIssue[];
  onSubmit: (issues: IUpdateIssue[]) => void;
  isOpen: boolean;
  onClose: () => void;
}

const DowntimeUpdateForm: React.FC<IDowntimeUpdateFormProps> = ({
  issues,
  onSubmit,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const validate = useValidate(updateIssuesScheme);

  const formik = useFormikInUpdateForm({
    initialValues: issues,
    validate,
    onSubmit,
  });

  const onReset = () => {
    formik.resetForm();
  };

  const fetchIssueTypesCombobox = useFetchIssueTypesCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  useEffect(() => {
    fetchIssueTypesCombobox();
    fetchDepartmentsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);
  // fetch issueTypes list from store
  const issueTypes = useSelector(getIssueTypesComboboxList);
  // fetch departments list from store
  const departments = useSelector(
    getDepartmentOptionsByGloballySites,
    shallowEqual,
  );

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('issues.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent component="div">
          {map(formik.values, (issue, index) => (
            <DrawerBodySectionContent key={issue.id}>
              <DrawerBodySectionTitle>
                {t('issues.update_title', { id: issue.id })}
              </DrawerBodySectionTitle>
              <FormFieldContainer>
                <FormikDatetimepicker
                  required
                  label={t('issues.downtime')}
                  id={`${index}.downTime`}
                  fullWidth
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id={`${index}.issueTypeId`}
                  placeholder={t('issues.type')}
                  options={issueTypes as ComboBoxOption[]}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikCombobox
                  required
                  id={`${index}.departmentId`}
                  placeholder={t('issues.department')}
                  options={departments as ComboBoxOption[]}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextarea
                  variant="outlined"
                  id={`${index}.description`}
                  label={t('issues.description')}
                  name={`${index}.description`}
                  formik={formik}
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikTextField
                  variant="outlined"
                  fullWidth
                  id={`${index}.duration`}
                  label={t('issues.duration')}
                  name={`${index}.duration`}
                  formik={formik}
                  type="number"
                  errorMode="onFieldChange"
                />
              </FormFieldContainer>

              <FormFieldContainer>
                <FormikImageUpload
                  name={`${index}.file`}
                  image={formik.values[index].picture}
                  formik={formik}
                />
              </FormFieldContainer>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

interface IDowntimeUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  issues: IUpdateIssue[];
  onUpdate: () => void;
}

export const DowntimeUpdate: React.FC<IDowntimeUpdateProps> = ({
  isOpen,
  onClose,
  issues,
  onUpdate,
}) => {
  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated issues
   */
  const handleFormSubmit = (data: IUpdateIssue[]) => {
    const formData = generateFormDataFieldsForUpdateEntityForm(
      data.map((i) => ({
        ...i,
        downTime: parseISO(i.downTime).toISOString(),
      })),
      ['picture', 'department', 'issueType', 'attachment'],
    );

    dispatcher(
      updateIssuesRequest({
        data: (formData as unknown) as IUpdateIssue[],
        func: onUpdate,
      }),
    );
  };

  return (
    <DowntimeUpdateForm
      isOpen={isOpen}
      onClose={onClose}
      issues={issues}
      onSubmit={handleFormSubmit}
    />
  );
};
