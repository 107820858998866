import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateIssue,
  IDowntimeChartPointData,
  IdsArray,
  IFilter,
  IIssueModel,
  IUpdateIssue,
  PayloadWithCustomFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushIssueState = createAction('Flush issue data in store');

// Creation actions
export const createIssueRequest = createAction<
  PayloadWithCustomFunc<ICreateIssue, () => void>
>('Make request to create a new issue');
export const createIssueSuccess = createAction<IIssueModel>(
  'New issue has been created successfully',
);
export const createIssueFailed = createAction('Cannot create a new issue');

// Fetch issues actions
export const getIssueListRequest = createAction<IFilter>(
  'Make request to fetch issue list',
);
export const getIssueListSuccess = createAction<IIssueModel[]>(
  'Issues list has been fetched successfully',
);
export const getIssueListFailed = createAction('Cannot fetch issue list');

// get issues count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getIssueCountRequest = createAction<IFilter>(
  'Make request to get issue count',
);
export const getIssueCountSuccess = createAction<ICountResponse>(
  'Issues count has been fetched successfully',
);
export const getIssueCountFailed = createAction('Cannot get issue count');

// delete actions
export const deleteIssueRequest = createAction<IdsArray>(
  'Make request to delete issues',
);
export const deleteIssueFailed = createAction('Cannot delete issues');

// Updating actions
export const updateIssuesRequest = createAction<
  PayloadWithCustomFunc<IUpdateIssue[], () => void>
>('Make request to update issues');
export const updateIssuesFailed = createAction('Cannot update issues');

// Fetch combobox list actions
export const getIssueComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of issues',
);
export const getIssueComboboxListSuccess = createAction<Partial<IIssueModel>[]>(
  'Combobox list of issues has been fetched successfully',
);
export const getIssueComboboxListFailed = createAction(
  'Cannot fetch combobox issues list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('Issue').takeExportActions();

export const exportIssueRequest = exportReportRequest;
export const exportIssueSuccess = exportReportSuccess;
export const exportIssueFailed = exportReportFailed;

// Fetch downtime chart data
export const getDowntimeChartDataRequest = createAction<IFilter>(
  'Make request to fetch downtime chart data',
);
export const getDowntimeChartDataSuccess = createAction<
  IDowntimeChartPointData[]
>('Downtime chart data has been fetched successfully');
export const getDowntimeChartDataFailed = createAction(
  'Cannot fetch downtime chart data',
);
