import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateShiftRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  IShiftModel,
  PayloadWithNavigateFunc,
} from '../../types';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new shift
 */
export const createShiftRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<ICreateShiftRequest>>): SagaIterator {
  try {
    const response: IShiftModel = yield call(Api.Shift.create, data);
    yield put(actions.flushShiftState());
    yield put(actions.createShiftSuccess(response));
    yield call(navigate, { pathname: '/shifts' });
  } catch (e) {
    yield put(actions.createShiftFailed());
  }
};

/**
 * Get shift list
 */
export const getShiftListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IShiftModel[] = yield call(Api.Shift.list, action.payload);
    yield put(actions.getShiftListSuccess(response));
  } catch (e) {
    yield put(actions.getShiftListFailed());
  }
};

/**
 * Get shift count
 */
export const getShiftCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Shift.count,
      action?.payload,
    );
    yield put(actions.getShiftCountSuccess(response));
  } catch (e) {
    yield put(actions.getShiftCountFailed());
  }
};

/**
 * Delete shifts
 */
export const deleteShiftRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Shift.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushShiftState());
  } catch (e) {
    yield put(actions.deleteShiftFailed());
  }
};

/**
 * Bulk update shifts
 */
export const updateShiftsRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<PayloadWithNavigateFunc<IShiftModel[]>>): SagaIterator {
  try {
    yield call(Api.Shift.bulkUpdate, data);
    yield put(actions.flushShiftState());
    yield call(navigate, { pathname: '/shifts' });
  } catch (e) {
    yield put(actions.updateShiftsFailed());
  }
};

/**
 * Get combobox list
 */
export const getShiftComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IShiftModel>[] = yield call(
      Api.Shift.list,
      action.payload,
    );
    yield put(actions.getShiftComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getShiftComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.Shift.export,
  actionFailed: actions.exportShiftFailed,
  actionSuccess: actions.exportShiftSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportShiftRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  { action: actions.createShiftRequest, saga: createShiftRequestSaga },
  { action: actions.getShiftListRequest, saga: getShiftListRequestSaga },
  { action: actions.getShiftCountRequest, saga: getShiftCountRequestSaga },
  { action: actions.deleteShiftRequest, saga: deleteShiftRequestSaga },
  // bulk updating shifts
  { action: actions.updateShiftsRequest, saga: updateShiftsRequestSaga },
  {
    action: actions.getShiftComboboxListRequest,
    saga: getShiftComboboxListRequestSaga,
  },
  { action: actions.exportShiftRequest, saga: exportShiftRequestSaga },
];
