import React from 'react';
import { Box, Typography } from '@mui/joy';
import { IMenuIcon } from 'src/modules/types';
import { useTranslation } from 'react-i18next';
import { useMainLayoutContext } from '../../MainLayoutContext';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const MainMenuItemLinkWithIconContainer = Box;
const MainMenuLink = Box;
const MainMenuIconContainer = Box;
const MainMenuTextContainer = Box;

export const transitionSpeed = 50;

interface IMainMenuItemLinkWithIconProps {
  name: string;
  nesting: number;
  to: string;
  isActive: boolean;
  Icon: IMenuIcon;
  withoutWrapper?: boolean;
  external?: boolean;
}

export const MainMenuItemLinkWithIcon = ({
  name,
  isActive,
  Icon,
  nesting,
  to,
  external,
}: IMainMenuItemLinkWithIconProps) => {
  const { t } = useTranslation();

  const {
    sideBar,
    toggleSideBar,
    isSmallScreen,
    sideBarColors,
    sideBarMenuItemHeight,
    sideBarTextOffsetStep,
    sideBarIconOffsetStep,
  } = useMainLayoutContext();

  const title = t(name);

  const externalRedirect = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(to, '_blank');
  };

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink
          to={to}
          ref={ref}
          style={{ textDecoration: 'none' }}
          {...itemProps}
          {...(external
            ? {
                onClick: (e) => {
                  externalRedirect(e);
                  isSmallScreen && toggleSideBar();
                },
              }
            : {
                onClick: () => {
                  isSmallScreen && toggleSideBar();
                },
              })}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [to],
  );

  // // If this is top level tab (nesting === 1) then we calculate
  // // its color based on isExpanded prop in other case we inherit it
  // // from the parent
  // let color = 'inherit';
  // if (nesting === 1) {
  // }

  const color = isActive ? sideBarColors.textActive : sideBarColors.text;

  return (
    <MainMenuItemLinkWithIconContainer
      sx={{
        color,
        backgroundColor: 'inherit',
        transition: `color ${transitionSpeed}ms`,
        width: '100%',
      }}
    >
      <MainMenuLink
        sx={{
          height: `${sideBarMenuItemHeight}px`,
          display: 'flex',
          alignItems: 'center',
          color: 'inherit',
          backgroundColor: 'inherit',
          position: 'relative',
          width: '100%',
        }}
        component={renderLink}
      >
        {Icon && (
          <MainMenuIconContainer
            sx={{
              display: 'flex',
              position: 'absolute',
              backgroundColor: 'inherit',
              left: `${sideBarIconOffsetStep}px`,
            }}
          >
            <Icon
              fill={isActive ? sideBarColors.textActive : sideBarColors.text}
              style={{
                transition: `fill ${transitionSpeed}ms`,
              }}
            />
          </MainMenuIconContainer>
        )}
        <MainMenuTextContainer
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginLeft:
              nesting === 1
                ? `${sideBarTextOffsetStep * 2}px`
                : `${sideBarTextOffsetStep * nesting}px`,
            backgroundColor: 'inherit',
            color: 'inherit',
            opacity: sideBar.state === 'expanded' ? 1 : 0,
            transition: 'opacity .1s',
          }}
        >
          <Typography
            component="span"
            sx={{
              fontWeight: 'medium',
              lineHeight: '20px',
              fontSize: '14px',
              color: 'inherit',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {title}
          </Typography>
        </MainMenuTextContainer>
        {isActive && (
          <Box sx={{ mr: '18px' }}>
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: 'success.600',
              }}
            />
          </Box>
        )}
      </MainMenuLink>
    </MainMenuItemLinkWithIconContainer>
  );
};
