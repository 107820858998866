import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api, userComboboxFilter } from '../../utils';

import * as actions from '../../actions';
import {
  ICompleteCreationRequest,
  ICompleteCreationResponse,
  ICountResponse,
  ICreateUserWithAttributesRequest,
  IdsArray,
  IFilter,
  IMeResponse,
  INotificationsPreferences,
  ISagaAction,
  ISagaActionBind,
  IUpdateUserWithAttributesRequest,
  IUserModel,
  IUserPolicy,
  IUserUpdatePasswordRequest,
  IUserUpdateProfileRequest,
  IUserUpdateResponse,
  PayloadWithNavigateFunc,
} from '../../types';
import { saveUserToStorage } from '../../utils/user';
import { map } from 'lodash';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';
import { paths } from '../../../config';

/**
 * Get user's profile data
 */
export const meRequestSaga = function* (): SagaIterator {
  try {
    const { profile, ...restUserData }: IMeResponse = yield call(Api.User.me);

    const parsedResponse = {
      ...restUserData,
      profile: {
        ...profile,
        smsNotificationsEnabled: Boolean(profile.smsNotificationsEnabled),
        webNotificationsEnabled: Boolean(profile.webNotificationsEnabled),
        emailNotificationsEnabled: Boolean(profile.emailNotificationsEnabled),
      },
    };

    // save user data to localStore
    saveUserToStorage({ user: parsedResponse });
    yield put(actions.meSuccess(parsedResponse));
  } catch (e) {
    yield put(actions.meFailed());
  }
};

/**
 * Get user's policy data
 */
export const myPolicyRequestSaga = function* (): SagaIterator {
  try {
    const response: IUserPolicy = yield call(Api.User.myPolicy);
    yield put(actions.myPolicySuccess(response));
    // save user data to localStore
    saveUserToStorage({
      user: {
        policy: response,
      },
    });
  } catch (e) {
    yield put(actions.myPolicyFailed());
  }
};

/**
 * Update user's profile data
 */
export const meUpdateRequestSaga = function* (
  action: ISagaAction<IUserUpdateProfileRequest>,
): SagaIterator {
  try {
    const response: IUserUpdateResponse = yield call(
      Api.User.profileUpdate,
      action.payload,
    );
    // update storage if user profile has been updated successfully
    if (response.status) {
      saveUserToStorage({ user: action.payload });

      yield put(
        actions.addProcessStatus({
          variant: 'success',
          message: 'profile.update_profile_success',
        }),
      );
    }
    yield put(actions.meUpdateSuccess(response));
  } catch (e) {
    yield put(actions.meUpdateFailed());
  }
};

/**
 * Update user's notifications preferences
 */
export const changeNotificationsPreferencesRequestSaga = function* (
  action: ISagaAction<INotificationsPreferences>,
): SagaIterator {
  try {
    yield call(Api.User.notificationsPreferencesChange, action.payload);

    yield put(actions.changeNotificationsPreferencesSuccess(action.payload));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        message: 'profile.change_notifications_preferences_success',
      }),
    );

    saveUserToStorage({ user: { profile: action.payload } });
  } catch (e) {
    yield put(actions.changeNotificationsPreferencesFailed());
  }
};
/**
 * Update user's password
 */
export const passwordUpdateRequestSaga = function* (
  action: ISagaAction<IUserUpdatePasswordRequest>,
): SagaIterator {
  try {
    const { clear, ...data } = action.payload;
    const response: IUserUpdateResponse = yield call(
      Api.User.passwordUpdate,
      data,
    );
    yield put(actions.passwordUpdateSuccess(response));
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        message: 'profile.update_password_success',
      }),
    );

    // call the method to clear state
    // TODO: needs to be refactored
    if (clear) {
      yield call(clear);
    }
  } catch (e) {
    yield put(actions.passwordUpdateFailed());
  }
};

/**
 * Get user list
 */
export const getUserListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IUserModel[] = yield call(Api.User.list, action.payload);
    yield put(actions.getUserListSuccess(response));
  } catch (e) {
    yield put(actions.getUserListFailed());
  }
};

/**
 * Get user count
 */
export const getUserCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.User.count,
      action?.payload,
    );
    yield put(actions.getUserCountSuccess(response));
  } catch (e) {
    yield put(actions.getUserCountFailed());
  }
};

/**
 * Delete users
 */
export const deleteUserRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.User.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushUserState());
  } catch (e) {
    yield put(actions.deleteUserFailed());
  }
};

/**
 * Get combobox list
 */
export const getUserComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<IUserModel>[] = yield call(
      Api.User.list,
      action.payload,
    );
    yield put(actions.getUserComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getUserComboboxListFailed());
  }
};

/**
 * Reset password
 */
export const resetPasswordSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.User.resetPassword, action.payload);
    yield put(actions.resetPasswordByAdminSuccess());
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        message: 'users.reset_password_email_sent',
      }),
    );
  } catch (e) {
    yield put(actions.resetPasswordByAdminFailed());
  }
};

/**
 * Lock users
 */
export const lockUserRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.User.lock, action.payload);
    yield put(
      actions.lockUserSuccess(
        map(action.payload, (id) => ({
          id: id as number,
          locked: true,
        })),
      ),
    );
  } catch (e) {
    yield put(actions.lockUserFailed());
  }
};

/**
 * Unlock users
 */
export const unlockUserRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.User.unlock, action.payload);
    yield put(
      actions.unlockUserSuccess(
        map(action.payload, (id) => ({
          id: id as number,
          locked: false,
        })),
      ),
    );
  } catch (e) {
    yield put(actions.unlockUserFailed());
  }
};

/**
 * Activate users
 */
export const activateUserRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.User.activate, action.payload);
    yield put(
      actions.activateUserSuccess(
        map(action.payload, (id) => ({
          id: id as number,
          activated: true,
        })),
      ),
    );
  } catch (e) {
    yield put(actions.activateUserFailed());
  }
};

/**
 * Deactivate users
 */
export const deactivateUserRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.User.deactivate, action.payload);
    yield put(
      actions.deactivateUserSuccess(
        map(action.payload, (id) => ({
          id: id as number,
          activated: false,
        })),
      ),
    );
  } catch (e) {
    yield put(actions.deactivateUserFailed());
  }
};

/**
 * Create a new user
 */
export const createUserRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateUserWithAttributesRequest>
>): SagaIterator {
  try {
    const response: IUserModel = yield call(Api.User.create, data);
    yield put(actions.createUserSuccess(response));
    yield put(actions.getUserComboboxListRequest(userComboboxFilter));
    yield call(navigate, { pathname: paths.USERS });
  } catch (e) {
    yield put(actions.createUserFailed());
  }
};

export const updateUsersRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IUpdateUserWithAttributesRequest[]>
>): SagaIterator {
  try {
    yield call(Api.User.bulkUpdate, data);
    yield put(actions.flushUserState());
    yield call(navigate, { pathname: paths.USERS });
  } catch (e) {
    yield put(actions.updateUsersFailed());
  }
};

/**
 * Complete creation
 */
export const completeCreationUserRequestSaga = function* (
  action: ISagaAction<ICompleteCreationRequest>,
): SagaIterator {
  try {
    const response: ICompleteCreationResponse = yield call(
      Api.User.completeCreation,
      action.payload,
    );
    yield put(actions.completeCreationUserSuccess(response));
  } catch (e) {
    yield put(actions.completeCreationUserFailed());
  }
};

/**
 * Get prefilled data
 * @param action
 */
export const getUserPrefilledDataRequestSaga = function* (
  action: ISagaAction<string>,
): SagaIterator {
  try {
    const prefilledData: Partial<IUserModel> = yield call(
      Api.User.getPrefilledData,
      action.payload,
    );
    yield put(actions.getUserPrefilledDataSuccess(prefilledData));
  } catch (e) {
    yield put(actions.getUserPrefilledDataFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.User.export,
  actionFailed: actions.exportUserFailed,
  actionSuccess: actions.exportUserSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportUserRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  /**
   * Policy sagas
   */
  // fetch current user policy
  { action: actions.myPolicyRequest, saga: myPolicyRequestSaga },
  /**
   * Profile sagas
   */
  // fetch users profile
  { action: actions.meRequest, saga: meRequestSaga },
  // update users profile
  { action: actions.meUpdateRequest, saga: meUpdateRequestSaga },
  // update users password
  { action: actions.passwordUpdateRequest, saga: passwordUpdateRequestSaga },
  // change notifications preferences
  {
    action: actions.changeNotificationsPreferencesRequest,
    saga: changeNotificationsPreferencesRequestSaga,
  },
  /**
   * Users sagas
   */
  // get user list
  { action: actions.getUserListRequest, saga: getUserListRequestSaga },
  // get user count
  { action: actions.getUserCountRequest, saga: getUserCountRequestSaga },
  // delete users
  { action: actions.deleteUserRequest, saga: deleteUserRequestSaga },
  // combobox list
  {
    action: actions.getUserComboboxListRequest,
    saga: getUserComboboxListRequestSaga,
  },
  // reset password
  { action: actions.resetPasswordByAdminRequest, saga: resetPasswordSaga },
  // lock users
  { action: actions.lockUserRequest, saga: lockUserRequestSaga },
  // unlock users
  { action: actions.unlockUserRequest, saga: unlockUserRequestSaga },
  // activate users
  { action: actions.activateUserRequest, saga: activateUserRequestSaga },
  // deactivate users
  { action: actions.deactivateUserRequest, saga: deactivateUserRequestSaga },
  // create a new user
  { action: actions.createUserRequest, saga: createUserRequestSaga },
  // update users
  { action: actions.updateUsersRequest, saga: updateUsersRequestSaga },
  // complete user creation
  {
    action: actions.completeCreationUserRequest,
    saga: completeCreationUserRequestSaga,
  },
  // update staffings
  {
    action: actions.getUserPrefilledDataRequest,
    saga: getUserPrefilledDataRequestSaga,
  },
  // export
  { action: actions.exportUserRequest, saga: exportUserRequestSaga },
];
