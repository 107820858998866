import { ISagaActionBind } from '../../types';
import { IProductionDailyUphBySiteSkuAndEmployeeModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionDailyUphBySiteSkuAndEmployeeListRequest,
    saga: createListSaga<IProductionDailyUphBySiteSkuAndEmployeeModel>({
      apiCall: Api.ProductionDailyUphBySiteSkuAndEmployee.list,
      actionSuccess:
        actions.getProductionDailyUphBySiteSkuAndEmployeeListSuccess,
      actionFailed: actions.getProductionDailyUphBySiteSkuAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getProductionDailyUphBySiteSkuAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionDailyUphBySiteSkuAndEmployee.count,
      actionSuccess:
        actions.getProductionDailyUphBySiteSkuAndEmployeeCountSuccess,
      actionFailed:
        actions.getProductionDailyUphBySiteSkuAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportProductionDailyUphBySiteSkuAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionDailyUphBySiteSkuAndEmployee.export,
      actionSuccess:
        actions.exportProductionDailyUphBySiteSkuAndEmployeeSuccess,
      actionFailed: actions.exportProductionDailyUphBySiteSkuAndEmployeeFailed,
    }),
  },
];
