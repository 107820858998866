import { ISagaActionBind } from '../../types';
import { IProductionWeeklyUphBySiteSkuAndEmployeeModel } from '../../types/productionUphReports';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getProductionWeeklyUphBySiteSkuAndEmployeeListRequest,
    saga: createListSaga<IProductionWeeklyUphBySiteSkuAndEmployeeModel>({
      apiCall: Api.ProductionWeeklyUphBySiteSkuAndEmployee.list,
      actionSuccess:
        actions.getProductionWeeklyUphBySiteSkuAndEmployeeListSuccess,
      actionFailed:
        actions.getProductionWeeklyUphBySiteSkuAndEmployeeListFailed,
    }),
  },
  {
    action: actions.getProductionWeeklyUphBySiteSkuAndEmployeeCountRequest,
    saga: createCountSaga({
      apiCall: Api.ProductionWeeklyUphBySiteSkuAndEmployee.count,
      actionSuccess:
        actions.getProductionWeeklyUphBySiteSkuAndEmployeeCountSuccess,
      actionFailed:
        actions.getProductionWeeklyUphBySiteSkuAndEmployeeCountFailed,
    }),
  },
  {
    action: actions.exportProductionWeeklyUphBySiteSkuAndEmployeeRequest,
    saga: createExportSaga({
      apiCall: Api.ProductionWeeklyUphBySiteSkuAndEmployee.export,
      actionSuccess:
        actions.exportProductionWeeklyUphBySiteSkuAndEmployeeSuccess,
      actionFailed: actions.exportProductionWeeklyUphBySiteSkuAndEmployeeFailed,
    }),
  },
];
