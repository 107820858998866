import { ISagaActionBind } from '../../types';
import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { Api } from '../../utils';
import * as actions from '../../actions';
import { createExportSaga } from '../../utils/helpers/creators/export';
import {
  createCountSaga,
  createListSaga,
} from '../../utils/helpers/creators/listWithSummary';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.getCardinalHealthWeeklyUphBySiteAndSkuListRequest,
    saga: createListSaga<ICardinalHealthWeeklyReportModel>({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndSku.list,
      actionSuccess: actions.getCardinalHealthWeeklyUphBySiteAndSkuListSuccess,
      actionFailed: actions.getCardinalHealthWeeklyUphBySiteAndSkuListFailed,
    }),
  },
  {
    action: actions.getCardinalHealthWeeklyUphBySiteAndSkuCountRequest,
    saga: createCountSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndSku.count,
      actionSuccess: actions.getCardinalHealthWeeklyUphBySiteAndSkuCountSuccess,
      actionFailed: actions.getCardinalHealthWeeklyUphBySiteAndSkuCountFailed,
    }),
  },
  {
    action: actions.exportCardinalHealthWeeklyUphBySiteAndSkuRequest,
    saga: createExportSaga({
      apiCall: Api.CardinalHealthWeeklyUphBySiteAndSku.export,
      actionSuccess: actions.exportCardinalHealthWeeklyUphBySiteAndSkuSuccess,
      actionFailed: actions.exportCardinalHealthWeeklyUphBySiteAndSkuFailed,
    }),
  },
];
