import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { IFilterData, IWhere } from 'src/modules/types';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { createQuestionCategoryRequest } from 'src/modules/actions';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { createQuestionCategoryScheme } from 'src/modules/schemes/question-category/create-question-category.scheme';

const initialValues = {
  siteId: NaN,
  name: '',
  numberOfWeeksToRepeat: undefined,
};

interface ICreateQuestionCategoryProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreateQuestionCategory = ({
  isOpen,
  onClose,
  filterList,
  filterCount,
}: ICreateQuestionCategoryProps) => {
  const { t } = useTranslation();

  const dispatcher = useDispatch();

  const validate = useValidate(createQuestionCategoryScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(
        createQuestionCategoryRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );

      formik.resetForm();

      onClose();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('question_category.create_form_card_title')}
      </DrawerHeader>

      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikTextField
              required
              id="name"
              label={t('question_category.name')}
              placeholder={t('common.add_your_text_here')}
              name="name"
              autoComplete="name"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              type="number"
              id={'numberOfWeeksToRepeat'}
              label={t('question_category.number_of_weeks_to_repeat')}
              name={'numberOfWeeksToRepeat'}
              errorMode="onFieldChange"
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              required
              id="siteId"
              formik={formik}
              label={t('question_category.site')}
              placeholder={t('common.select')}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
