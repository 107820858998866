import { createReducer } from 'redux-act';
import * as actions from '../actions';

import {
  ICountResponse,
  IPayboardState,
  PayboardDetailsResponse,
  ListWithSummary,
  IPayboardModel,
  PayboardWeek,
  IDailyHoursReportItem,
  NewPayboardWeek,
  IPayboardCostByDay,
  IPayboardCostByDayAndDepartment,
  IPayboardCostByDepartment,
  IPayboardDetailedModel,
  ICountDetailedResponse,
  PayboardDay,
  ICountWeeklyDetailedResponse,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

const composeCostsHandlers = <Model>(
  stateName: keyof IPayboardState['costs'],
) => {
  const onRequest = (state: IPayboardState) => ({
    ...state,
    error: false,
    is_data_requested: true,
  });
  const onSuccess = (state: IPayboardState, costs: ListWithSummary<Model>) => ({
    ...state,
    costs: {
      ...state.costs,
      [stateName]: costs,
    },
    error: false,
    is_data_requested: false,
  });
  const onFail = (state: IPayboardState, payload: string) => ({
    ...state,
    error: payload,
    is_data_requested: false,
  });
  return { onRequest, onSuccess, onFail };
};

// default state
const defaultState: IPayboardState = {
  badge: NaN,
  employeeId: NaN,
  firstName: '',
  lastName: '',
  staffingProvider: '',
  payRate: NaN,
  shift: '',
  department: '',
  supervisor: '',
  site: '',
  year: NaN,
  week: NaN,
  markup: NaN,
  client: '',
  sumTimeTotal: '',
  regularHoursDec: NaN,
  overtimeHoursDec: NaN,
  sickHoursDec: NaN,
  regularPay: NaN,
  overtimePay: NaN,
  overtimePremiumPay: NaN,
  totalPay: NaN,
  markupCost: NaN,
  list: { items: [], summary: [] },
  listDetailed: { items: [], summary: [] },
  listWeeklyDetailed: { items: [], summary: [] },
  countWeeklyDetailed: 0,
  weeks: {
    items: [],
    summary: [],
  },
  days: {
    items: [],
    summary: [],
  },
  newWeeks: {
    items: [],
    summary: [],
  },
  details: {},
  comboboxList: [],
  count: 0,
  countDetailed: 0,
  dailyHoursSummaryData: {
    items: [],
    summary: [],
  },
  costs: {
    sumByDay: {
      items: [],
      summary: [],
    },
    sumByDayAndDepartment: {
      items: [],
      summary: [],
    },
    sumByDepartment: {
      items: [],
      summary: [],
    },
  },
  dailyHoursDashboardInReportData: {
    items: [],
    summary: [],
  },
  dailyHoursDashboardInCount: 0,
  isNewPayboardWeekListRequestInProgress: false,
  isNewPayboardWeekCountRequestInProgress: false,
  is_details_view_loading: false,
  is_summary_list_loading: false,
  is_summary_count_loading: false,
  is_detailed_list_loading: false,
  is_detailed_count_loading: false,
  is_weekly_detailed_list_loading: false,
  is_weekly_detailed_count_loading: false,
  is_by_week_list_loading: false,
  is_by_week_count_loading: false,
  is_by_day_count_loading: false,
  is_by_day_list_loading: false,
  is_dashboard_in_requested: false,
  is_dashboard_in_count_requested: false,
};

/**
 * Get list actions
 */
const getPayboardListRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_summary_list_loading: true,
  is_data_requested: true,
});

const getPayboardListSuccess = (
  state: IPayboardState,
  payload: ListWithSummary<IPayboardModel>,
) => ({
  ...state,
  list: payload,
  error: false,
  is_summary_list_loading: false,
  is_data_requested: false,
});

const getPayboardListFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_summary_list_loading: false,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getPayboardCountRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_summary_count_loading: true,
  is_by_week_count_loading: true,
  is_data_requested: true,
});

const getPayboardCountSuccess = (
  state: IPayboardState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_summary_count_loading: false,
  is_by_week_count_loading: false,
  is_data_requested: false,
});

const getPayboardCountFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_summary_count_loading: false,
  is_by_week_count_loading: false,
  is_data_requested: false,
});

const getPayboardDetailedListRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_detailed_list_loading: true,
  is_data_requested: true,
});

const getPayboardDetailedListSuccess = (
  state: IPayboardState,
  payload: ListWithSummary<IPayboardDetailedModel>,
) => ({
  ...state,
  listDetailed: payload,
  error: false,
  is_detailed_list_loading: false,
  is_data_requested: false,
});

const getPayboardDetailedListFailed = (
  state: IPayboardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_detailed_list_loading: false,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getPayboardDetailedCountRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_detailed_count_loading: true,
  is_data_requested: true,
});

const getPayboardDetailedCountSuccess = (
  state: IPayboardState,
  payload: ICountDetailedResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_detailed_count_loading: false,
  is_data_requested: false,
});

const getPayboardDetailedCountFailed = (
  state: IPayboardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_detailed_count_loading: false,
  is_data_requested: false,
});

/**
 * Get Weekly Detailed data
 */
const getPayboardWeeklyDetailedListRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_weekly_detailed_list_loading: true,
  is_data_requested: true,
});

const getPayboardWeeklyDetailedListSuccess = (
  state: IPayboardState,
  payload: ListWithSummary<IPayboardDetailedModel>,
) => ({
  ...state,
  listWeeklyDetailed: payload,
  error: false,
  is_weekly_detailed_list_loading: false,
  is_data_requested: false,
});

const getPayboardWeeklyDetailedListFailed = (
  state: IPayboardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_weekly_detailed_list_loading: false,
  is_data_requested: false,
});

/**
 * Get Weekly Detailed count
 */
const getPayboardWeeklyDetailedCountRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_weekly_detailed_count_loading: true,
  is_data_requested: true,
});

const getPayboardWeeklyDetailedCountSuccess = (
  state: IPayboardState,
  payload: ICountWeeklyDetailedResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_weekly_detailed_count_loading: false,
  is_data_requested: false,
});

const getPayboardWeeklyDetailedCountFailed = (
  state: IPayboardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_weekly_detailed_count_loading: false,
  is_data_requested: false,
});

/**
 * Get details actions
 */
const getPayboardDetailsRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_details_view_loading: true,
  is_data_requested: true,
});

const getPayboardDetailsSuccess = (
  state: IPayboardState,
  payload: PayboardDetailsResponse,
) => ({
  ...state,
  details: {
    [payload.year]: {
      [payload.week]: {
        [payload.badge]: {
          items: payload.items,
          total: payload.total,
          count: payload.count,
        },
      },
    },
  },
  error: false,
  is_details_view_loading: false,
  is_data_requested: false,
});

const getPayboardDetailsFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_details_view_loading: false,
  is_data_requested: false,
});

/**
 * Get by week actions
 */
const getPayboardWeekRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_by_week_list_loading: true,
  is_data_requested: true,
});

const getPayboardWeekSuccess = (
  state: IPayboardState,
  payload: ListWithSummary<PayboardWeek>,
) => ({
  ...state,
  weeks: payload,
  error: false,
  is_by_week_list_loading: false,
  is_data_requested: false,
});

const getPayboardWeekFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_by_week_list_loading: false,
  is_data_requested: false,
});

const getPayboardDayRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  is_by_day_list_loading: true,
  is_data_requested: true,
});

const getPayboardDaySuccess = (
  state: IPayboardState,
  payload: ListWithSummary<PayboardDay>,
) => ({
  ...state,
  days: payload,
  error: false,
  is_by_day_list_loading: false,
  is_data_requested: false,
});

const getPayboardDayFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_by_day_list_loading: false,
  is_data_requested: false,
});

/**
 * Get new by week actions
 */
const getNewPayboardWeekRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  isNewPayboardWeekListRequestInProgress: true,
});

const getNewPayboardWeekSuccess = (
  state: IPayboardState,
  payload: ListWithSummary<NewPayboardWeek>,
) => ({
  ...state,
  newWeeks: payload,
  error: false,
  isNewPayboardWeekListRequestInProgress: false,
});
/**
 * Get new week count actions
 */
const getNewPayboardCountRequest = (state: IPayboardState) => ({
  ...state,
  error: false,
  isNewPayboardWeekCountRequestInProgress: true,
});

const getNewPayboardCountSuccess = (
  state: IPayboardState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  isNewPayboardWeekCountRequestInProgress: false,
});

const getNewPayboardCountFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getNewPayboardWeekFailed = (state: IPayboardState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const getPayboardDailyHoursSummaryReportDataRequest = (
  state: IPayboardState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_daily_hrs_data_requested: true,
});

const getPayboardDailyHoursDashboardReportDataRequest = (
  state: IPayboardState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
  is_daily_hrs_data_requested: true,
});

const getPayboardDailyHoursDashboardInReportDataRequest = (
  state: IPayboardState,
) => ({
  ...state,
  error: false,
  is_dashboard_in_requested: true,
});

const getPayboardDailyHoursDashboardInReportCountRequest = (
  state: IPayboardState,
) => ({
  ...state,
  error: false,
  is_dashboard_in_count_requested: true,
});

const getPayboardDailyHoursSummaryReportDataSuccess = (
  state: IPayboardState,
  dailyHoursSummaryData: ListWithSummary<IDailyHoursReportItem>,
) => ({
  ...state,
  dailyHoursSummaryData,
  error: false,
  is_data_requested: false,
  is_daily_hrs_data_requested: false,
});

const getPayboardDailyHoursDashboardInReportDataSuccess = (
  state: IPayboardState,
  dailyHoursDashboardInReportData: ListWithSummary<IDailyHoursReportItem>,
) => ({
  ...state,
  dailyHoursDashboardInReportData,
  error: false,
  is_dashboard_in_requested: false,
});

const getPayboardDailyHoursDashboardInReportCountSuccess = (
  state: IPayboardState,
  countData: ICountResponse,
) => ({
  ...state,
  dailyHoursDashboardInCount: countData.count,
  is_dashboard_in_count_requested: false,
});

const getPayboardDailyHoursSummaryReportDataFailed = (
  state: IPayboardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  is_daily_hrs_data_requested: false,
});

const getPayboardDailyHoursDashboardInReportDataFailed = (
  state: IPayboardState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_dashboard_in_requested: false,
});

const getPayboardDailyHoursSummaryReportDataCountRequest = (
  state: IPayboardState,
) => ({
  ...state,
  error: false,
  is_summary_count_loading: true,
});

const getPayboardDailyHoursSummaryReportDataCountSuccess = (
  state: IPayboardState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_summary_count_loading: false,
});

const getPayboardDailyHoursSummaryReportDataCountFailed = (
  state: IPayboardState,
) => ({
  ...state,
  is_summary_count_loading: false,
});

/**
 * Sum of costs by day
 */
const {
  onRequest: getPayboardSumByDayRequest,
  onSuccess: getPayboardSumByDaySuccess,
  onFail: getPayboardSumByDayFailed,
} = composeCostsHandlers<IPayboardCostByDay>('sumByDay');

/**
 * Sum of costs by day and department
 */
const {
  onRequest: getPayboardSumByDayAndDepartmentRequest,
  onSuccess: getPayboardSumByDayAndDepartmentSuccess,
  onFail: getPayboardSumByDayAndDepartmentFailed,
} = composeCostsHandlers<IPayboardCostByDayAndDepartment>(
  'sumByDayAndDepartment',
);

/**
 * Sum of costs by department
 */
const {
  onRequest: getPayboardSumByDepartmentRequest,
  onSuccess: getPayboardSumByDepartmentSuccess,
  onFail: getPayboardSumByDepartmentFailed,
} = composeCostsHandlers<IPayboardCostByDepartment>('sumByDepartment');

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IPayboardState>();

/**
 * Payboard reducer
 */
export const payboard = createReducer<IPayboardState>({}, defaultState)
  // get list actions
  .on(actions.getPayboardListRequest, getPayboardListRequest)
  .on(actions.getPayboardListSuccess, getPayboardListSuccess)
  .on(actions.getPayboardListFailed, getPayboardListFailed)
  // get count actions
  .on(actions.getPayboardCountRequest, getPayboardCountRequest)
  .on(actions.getPayboardCountSuccess, getPayboardCountSuccess)
  .on(actions.getPayboardCountFailed, getPayboardCountFailed)
  // get detailed list actions
  .on(actions.getPayboardDetailedListRequest, getPayboardDetailedListRequest)
  .on(actions.getPayboardDetailedListSuccess, getPayboardDetailedListSuccess)
  .on(actions.getPayboardDetailedListFailed, getPayboardDetailedListFailed)
  // get detailed count actions
  .on(actions.getPayboardDetailedCountRequest, getPayboardDetailedCountRequest)
  .on(actions.getPayboardDetailedCountSuccess, getPayboardDetailedCountSuccess)
  .on(actions.getPayboardDetailedCountFailed, getPayboardDetailedCountFailed)
  // get weekly detailed list actions
  .on(
    actions.getPayboardWeeklyDetailedListRequest,
    getPayboardWeeklyDetailedListRequest,
  )
  .on(
    actions.getPayboardWeeklyDetailedListSuccess,
    getPayboardWeeklyDetailedListSuccess,
  )
  .on(
    actions.getPayboardWeeklyDetailedListFailed,
    getPayboardWeeklyDetailedListFailed,
  )
  // get weekly detailed count actions
  .on(
    actions.getPayboardWeeklyDetailedCountRequest,
    getPayboardWeeklyDetailedCountRequest,
  )
  .on(
    actions.getPayboardWeeklyDetailedCountSuccess,
    getPayboardWeeklyDetailedCountSuccess,
  )
  .on(
    actions.getPayboardWeeklyDetailedCountFailed,
    getPayboardWeeklyDetailedCountFailed,
  )
  // get details actions
  .on(actions.getPayboardDetailsRequest, getPayboardDetailsRequest)
  .on(actions.getPayboardDetailsSuccess, getPayboardDetailsSuccess)
  .on(actions.getPayboardDetailsFailed, getPayboardDetailsFailed)
  // get by weeks actions
  .on(actions.getPayboardWeekRequest, getPayboardWeekRequest)
  .on(actions.getPayboardWeekSuccess, getPayboardWeekSuccess)
  .on(actions.getPayboardWeekFailed, getPayboardWeekFailed)
  .on(actions.getPayboardDayRequest, getPayboardDayRequest)
  .on(actions.getPayboardDaySuccess, getPayboardDaySuccess)
  .on(actions.getPayboardDayFailed, getPayboardDayFailed)
  // get week count actions
  .on(actions.getPayboardWeekCountRequest, getPayboardCountRequest)
  .on(actions.getPayboardWeekCountSuccess, getPayboardCountSuccess)
  .on(actions.getPayboardWeekCountFailed, getPayboardCountFailed)
  // get week count actions
  .on(actions.getPayboardDayCountRequest, getPayboardCountRequest)
  .on(actions.getPayboardDayCountSuccess, getPayboardCountSuccess)
  .on(actions.getPayboardDayCountFailed, getPayboardCountFailed)
  // get by new weeks actions
  .on(actions.getNewPayboardWeekRequest, getNewPayboardWeekRequest)
  .on(actions.getNewPayboardWeekSuccess, getNewPayboardWeekSuccess)
  .on(actions.getNewPayboardWeekFailed, getNewPayboardWeekFailed)
  // get new week count actions
  .on(actions.getNewPayboardWeekCountRequest, getNewPayboardCountRequest)
  .on(actions.getNewPayboardWeekCountSuccess, getNewPayboardCountSuccess)
  .on(actions.getNewPayboardWeekCountFailed, getNewPayboardCountFailed)
  // get daily hours summary report count actions
  .on(
    actions.getPayboardDailyHoursSummaryReportDataCountRequest,
    getPayboardDailyHoursSummaryReportDataCountRequest,
  )
  .on(
    actions.getPayboardDailyHoursSummaryReportDataSuccess,
    getPayboardDailyHoursSummaryReportDataSuccess,
  )
  .on(
    actions.getPayboardDailyHoursSummaryReportDataFailed,
    getPayboardDailyHoursSummaryReportDataFailed,
  )
  // get daily hours summary report data actions
  .on(
    actions.getPayboardDailyHoursSummaryReportDataRequest,
    getPayboardDailyHoursSummaryReportDataRequest,
  )

  // get daily hours dashboard report data actions
  .on(
    actions.getPayboardDailyHoursDashboardReportDataRequest,
    getPayboardDailyHoursDashboardReportDataRequest,
  )

  .on(
    actions.getPayboardDailyHoursSummaryReportDataCountSuccess,
    getPayboardDailyHoursSummaryReportDataCountSuccess,
  )
  .on(
    actions.getPayboardDailyHoursSummaryReportDataCountFailed,
    getPayboardDailyHoursSummaryReportDataCountFailed,
  )

  // Payboard daily hours dashboard in data
  .on(
    actions.getPayboardDailyHoursDashboardInReportDataRequest,
    getPayboardDailyHoursDashboardInReportDataRequest,
  )
  .on(
    actions.getPayboardDailyHoursDashboardInReportDataSuccess,
    getPayboardDailyHoursDashboardInReportDataSuccess,
  )
  .on(
    actions.getPayboardDailyHoursDashboardInReportDataFailed,
    getPayboardDailyHoursDashboardInReportDataFailed,
  )
  // Payboard daily hours dashboard in count
  .on(
    actions.getPayboardDailyHoursDashboardInReportCountRequest,
    getPayboardDailyHoursDashboardInReportCountRequest,
  )
  .on(
    actions.getPayboardDailyHoursDashboardInReportCountSuccess,
    getPayboardDailyHoursDashboardInReportCountSuccess,
  )
  .on(
    actions.getPayboardDailyHoursDashboardInReportDataFailed,
    getPayboardDailyHoursDashboardInReportDataFailed,
  )

  // get costs by day actions
  .on(actions.getPayboardSumByDayRequest, getPayboardSumByDayRequest)
  .on(actions.getPayboardSumByDaySuccess, getPayboardSumByDaySuccess)
  .on(actions.getPayboardSumByDayFailed, getPayboardSumByDayFailed)

  // get costs by day and department actions
  .on(
    actions.getPayboardSumByDayAndDepartmentRequest,
    getPayboardSumByDayAndDepartmentRequest,
  )
  .on(
    actions.getPayboardSumByDayAndDepartmentSuccess,
    getPayboardSumByDayAndDepartmentSuccess,
  )
  .on(
    actions.getPayboardSumByDayAndDepartmentFailed,
    getPayboardSumByDayAndDepartmentFailed,
  )

  // get costs by day and department actions
  .on(
    actions.getPayboardSumByDepartmentRequest,
    getPayboardSumByDepartmentRequest,
  )
  .on(
    actions.getPayboardSumByDepartmentSuccess,
    getPayboardSumByDepartmentSuccess,
  )
  .on(
    actions.getPayboardSumByDepartmentFailed,
    getPayboardSumByDepartmentFailed,
  )

  // export actions
  .on(actions.exportPayboardRequest, exportRequest)
  .on(actions.exportPayboardSuccess, exportSuccess)
  .on(actions.exportPayboardFailed, exportFailed)

  // export detailed actions
  .on(actions.exportPayboardDetailedRequest, exportRequest)
  .on(actions.exportPayboardDetailedSuccess, exportSuccess)
  .on(actions.exportPayboardDetailedFailed, exportFailed)

  // export week actions
  .on(actions.exportPayboardWeekRequest, exportRequest)
  .on(actions.exportPayboardWeekSuccess, exportSuccess)
  .on(actions.exportPayboardWeekFailed, exportFailed)

  // export new payboard week actions
  .on(actions.exportNewPayboardWeekRequest, exportRequest)
  .on(actions.exportNewPayboardWeekSuccess, exportSuccess)
  .on(actions.exportNewPayboardWeekFailed, exportFailed)

  // export daily-hours actions
  .on(actions.exportPayboardDailyHoursRequest, exportRequest)
  .on(actions.exportPayboardDailyHoursSuccess, exportSuccess)
  .on(actions.exportPayboardDailyHoursFailed, exportFailed);

export default payboard;
