import { filter, isEmpty, map, memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateMetatag } from '../types';
import { filterListBySiteId } from '../utils/helpers/common';

export const getMetatagRefreshKey = (state: IStoreState) =>
  state.metatag.refreshKey;

export const getMetatagData = (state: IStoreState) => state.metatag;

/**
 * Get server error
 * @param metatag - State metatag
 */
export const getServerError = ({ metatag }: IStoreState) => metatag.error;

/**
 * Get metatag list
 * @param metatag - State metatag
 */
export const getMetatagList = ({ metatag }: IStoreState) => metatag.list;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param metatag - State metatag
 */
export const getMetatagTable = createSelector(getMetatagList, (metatag) =>
  map(filter(metatag, (s) => !isEmpty(s.site))),
);

/**
 * Get metatags by array of ids
 * @param metatag - State metatag
 */
export const getMetatagsByIds = createSelector(
  getMetatagList,
  (metatags) => (ids: IdsArray) => {
    const initial: IUpdateMetatag[] = [];
    return reduce(
      metatags,
      (acc, cur) => {
        if (ids.includes(`${cur.id}`)) {
          acc.push(omit(cur, ['site']));
        }
        return acc;
      },
      initial,
    );
  },
);

/**
 * Get metatags names
 * @param metatag - State metatag
 */
export const getMetatagsNames = createSelector(getMetatagList, (metatags) =>
  reduce(
    metatags,
    (acc, cur) => {
      acc[cur.siteId] = acc[cur.siteId] ?? [];
      acc[cur.siteId].push(cur.name);
      return acc;
    },
    {} as {
      [id: number]: string[];
    },
  ),
);

/**
 * Get metatag count
 * @param metatag - State metatag
 */
export const getMetatagCount = ({ metatag }: IStoreState) => metatag.count;

export const getMetatagListBySiteId = createSelector(
  getMetatagList,
  (metatag) =>
    memoize((siteId?: number) => filterListBySiteId(metatag, siteId ?? NaN)),
);

export const getIsMetatagDataLoading = createSelector(
  getMetatagData,
  (metatag) =>
    metatag.isMetatagsDataLoading || metatag.isMetatagsDataProcessing,
);
