import { JSONSchemaType } from 'ajv';
import { TFunction } from 'i18next';
import { ICreateIssue } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createIssueScheme: JSONSchemaType<Omit<
  ICreateIssue,
  'picture'
>> = {
  type: 'object',
  required: ['downTime', 'issueTypeId', 'departmentId'],
  additionalProperties: true,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    downTime: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    issueTypeId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    departmentId: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.number'),
      },
    },
    description: {
      nullable: true,
      type: 'string',
    },
    duration: {
      nullable: true,
      type: 'number',
      maximum: 999999999,
      exclusiveMinimum: 0,
      errorMessage: {
        exclusiveMinimum: (i18n.t as TFunction)(
          'error.validation.exclusiveMinimum',
          {
            number: 0,
          },
        ),
        maximum: (i18n.t as TFunction)('error.validation.maximum', {
          number: 999999999,
        }),
      },
    },
    file: {
      nullable: true,
      required: [],
      type: 'object',
    },
  },
};
