import { IProductionWeeklyUphBySiteAndEmployeeModel } from '../../types/productionUphReports';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'ProductionWeeklyUphBySiteAndEmployee',
);

// get list actions
export const {
  getListRequest: getProductionWeeklyUphBySiteAndEmployeeListRequest,
  getListSuccess: getProductionWeeklyUphBySiteAndEmployeeListSuccess,
  getListFailed: getProductionWeeklyUphBySiteAndEmployeeListFailed,
} = actionCreator.takeListActions<IProductionWeeklyUphBySiteAndEmployeeModel>();

// get count actions
export const {
  getCountRequest: getProductionWeeklyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getProductionWeeklyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getProductionWeeklyUphBySiteAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportProductionWeeklyUphBySiteAndEmployeeRequest,
  exportReportSuccess: exportProductionWeeklyUphBySiteAndEmployeeSuccess,
  exportReportFailed: exportProductionWeeklyUphBySiteAndEmployeeFailed,
} = new ExportActionCreator(
  'ProductionWeeklyUphBySiteAndEmployee',
).takeExportActions();
