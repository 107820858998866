import React, { useEffect } from 'react';
import { map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  useFetchApplicantsByIds,
  useFetchOpenPositionsCombobox,
  useFetchStaffingProvidersCombobox,
  useQueryParams,
  useValidate,
} from '../../modules/utils/hooks';
import { IdsArray, IStoreState, IUpdateApplicant } from '../../modules/types';
import CreateForm from '../../components/Form/CreateForm';
import { getCurrentRole } from '../../modules/selectors/auth';
import { ComboBoxOption } from '../../components/ComboBox';
import { useTranslation } from 'react-i18next';
import FormikCombobox from '../../components/Formik/FormikCombobox';
import FormikTextField from '../../components/Formik/FormikTextField';
import { updateApplicantsRequest } from '../../modules/actions';
import { updateApplicantsScheme } from '../../modules/schemes';
import FormikDatepicker from '../../components/Formik/FormikDatepicker';
import { getApplicantsByIds } from '../../modules/selectors/applicant';
import FormikCheckbox from '../../components/Formik/FormikCheckbox';
import { getStaffingProvidersComboboxList } from '../../modules/selectors/staffingProvider';
import { useFormikInUpdateForm } from '../../modules/utils/hooks/common/forms';
import { UpdateFormLayout } from '../../components/Layout/UpdateFormLayout';
import { FormActions } from '../../components/Form/FormActions';
import { useApplicantUpdateFormTourConfig } from '../../config/tours/applicant';
import SupervisorComboBox from '../../components/Formik/comboboxes-with-entities/SupervisorComboBox';
import { getOpenPositionsComboboxListBySiteId } from '../../modules/selectors/openPosition';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import { useNavigate } from 'react-router-dom';
import FormikTextarea from 'src/components/Formik/FormikTextarea';

interface IProps {
  applicants: IUpdateApplicant[];
  onSubmit: (applicants: IUpdateApplicant[]) => void;
}

const ApplicantUpdateForm = (props: IProps) => {
  const { t } = useTranslation();

  const { applicants, onSubmit } = props;

  const fetchOpenPositionCombobox = useFetchOpenPositionsCombobox();
  const fetchStaffingProvidersCombobox = useFetchStaffingProvidersCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    fetchOpenPositionCombobox();
    fetchStaffingProvidersCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);
  const openPositionsBySiteId = useSelector(
    getOpenPositionsComboboxListBySiteId,
    shallowEqual,
  );

  const staffingProviders = useSelector(
    getStaffingProvidersComboboxList,
    shallowEqual,
  );

  const validate = useValidate(updateApplicantsScheme);

  const formik = useFormikInUpdateForm({
    initialValues: applicants,
    validate,
    onSubmit: (data) => onSubmit(data),
  });

  const applicantUpdateTourConfig = useApplicantUpdateFormTourConfig();

  return (
    <UpdateFormLayout
      fields={formik.values}
      isFormValid={formik.isValid}
      onSubmit={formik.handleSubmit}
    >
      {map(formik.values, (applicants, index) => (
        <CreateForm
          defaultExpanded={index === 0}
          cardTitle={t('applicant.update_title', { id: applicants.id })}
          key={applicants.id}
        >
          <SitesComboBox
            id={`${index}.siteId`}
            required={true}
            formik={formik}
            placeholder={t('applicant.siteId')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.site.target}
          />

          <FormikTextField
            id={`${index}.firstName`}
            name={`${index}.firstName`}
            required={true}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('applicant.firstName')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.firstName.target}
          />

          <FormikTextField
            id={`${index}.lastName`}
            name={`${index}.lastName`}
            required={true}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('applicant.lastName')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.lastName.target}
          />

          <FormikCombobox
            id={`${index}.openPositionId`}
            required={true}
            formik={formik}
            placeholder={t('applicant.openPositionId')}
            options={openPositionsBySiteId(applicants.siteId)}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.openPosition.target}
          />

          <FormikTextField
            id={`${index}.payRate`}
            name={`${index}.payRate`}
            required={true}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('applicant.payRate')}
            type="number"
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.payRate.target}
          />

          <FormikDatepicker
            id={`${index}.dateInterview`}
            formik={formik}
            label={t('applicant.dateInterview')}
            fullWidth={true}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.dateInterview.target}
          />

          <FormikDatepicker
            id={`${index}.dateStart`}
            formik={formik}
            required={true}
            label={t('applicant.dateStart')}
            fullWidth={true}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.dateStart.target}
          />

          <FormikTextField
            id={`${index}.badge`}
            name={`${index}.badge`}
            required={true}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('applicant.badge')}
            type="number"
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.badge.target}
          />

          <SupervisorComboBox
            id={`${index}.supervisorId`}
            required={true}
            siteId={formik.values[index].siteId}
            formik={formik}
            placeholder={t('applicant.supervisorId')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.supervisor.target}
          />

          <FormikCombobox
            id={`${index}.staffingId`}
            required={true}
            formik={formik}
            placeholder={t('applicant.staffingId')}
            options={staffingProviders as ComboBoxOption[]}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.staffingProvider.target}
          />

          <FormikCheckbox
            id={`${index}.hire`}
            required={true}
            formik={formik}
            label={t('applicant.hire')}
            errorMode="onFieldChange"
          />

          <FormikCheckbox
            id={`${index}.I9`}
            formik={formik}
            label={t('applicant.I9')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.I9.target}
          />

          <FormikCheckbox
            id={`${index}.bgCheck`}
            formik={formik}
            label={t('applicant.bgCheck')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.backgroundCheck.target}
          />

          <FormikTextarea
            id={`${index}.interviewNotes`}
            name={`${index}.interviewNotes`}
            formik={formik}
            variant="outlined"
            label={t('applicant.interviewNotes')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.interviewNotes.target}
          />

          <FormikTextField
            id={`${index}.phonenumber`}
            name={`${index}.phonenumber`}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('applicant.phonenumber')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.phoneNumber.target}
          />

          <FormikTextField
            id={`${index}.address`}
            name={`${index}.address`}
            formik={formik}
            variant="outlined"
            fullWidth={true}
            label={t('applicant.address')}
            errorMode="onFieldChange"
            data-tour={applicantUpdateTourConfig.tour.address.target}
          />
        </CreateForm>
      ))}
      <FormActions submitBtnTitle={t('common.update')} />
    </UpdateFormLayout>
  );
};

const ApplicantUpdate = () => {
  const navigate = useNavigate();
  // get ids from query string
  const { ids } = useQueryParams() as { ids: IdsArray };

  // const fetchApplicants = useFetchApplicants(ids);

  // fetch applicants list from store
  const applicants = useSelector(
    (state) => getApplicantsByIds(state as IStoreState)(ids),
    shallowEqual,
  );

  const fetchApplicants = useFetchApplicantsByIds(ids);

  useEffect(() => {
    fetchApplicants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // create dispatcher
  const dispatcher = useDispatch();

  /**
   * Form submit handler
   * @param data - Updated applicants
   */
  const handleFormSubmit = (data: IUpdateApplicant[]) => {
    dispatcher(updateApplicantsRequest({ data, navigate }));
  };

  return (
    <ApplicantUpdateForm applicants={applicants} onSubmit={handleFormSubmit} />
  );
};

export default ApplicantUpdate;
