import React from 'react';
import { Divider, IconButton, List, Sheet, Typography } from '@mui/joy';
import { ListItemWithCheckbox } from 'src/components/_ui-kit/ListItem';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Drawer, DrawerBody } from 'src/components/_ui-kit/Drawer';
import { TableContext } from 'src/components/Table/TableContext';
import { Columns02Svg } from 'src/components/svgIcons';

const allColumnsOptionIndex = -1;

export const SelectHeadCells = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isAllChecked, setIsAllChecked] = React.useState(false);

  const { headCells, setHeadCells } = React.useContext(TableContext);
  const [headCellsCurrentState, setHeadCellsCurrentState] = React.useState(
    headCells,
  );

  const isAllCellCheckboxIndeterminate = React.useMemo(() => {
    return (
      headCellsCurrentState.some(({ isVisible }) => isVisible) &&
      headCellsCurrentState.some(({ isVisible }) => !isVisible)
    );
  }, [headCellsCurrentState]);

  const onAllClick = () => {
    const isAllCheckedNewState = !isAllChecked;

    setHeadCellsCurrentState(
      headCellsCurrentState.map((cell) => ({
        ...cell,
        isVisible: isAllCheckedNewState,
      })),
    );

    setIsAllChecked(isAllCheckedNewState);
  };

  const onCellVisibilityChange = (isVisible: boolean, id: string | number) => {
    const updatedHeadCells = headCellsCurrentState.map((headCell) => {
      if (headCell.id === id) {
        return { ...headCell, isVisible };
      }

      return headCell;
    });

    setHeadCellsCurrentState(updatedHeadCells);
  };

  const onReset = () => {
    setHeadCellsCurrentState(headCells);
  };

  const onApply = () => {
    setIsOpen(false);
    setHeadCells(headCellsCurrentState);
  };

  React.useEffect(() => {
    setIsAllChecked(headCellsCurrentState.every((cell) => cell.isVisible));
  }, [headCellsCurrentState]);

  React.useEffect(() => {
    setHeadCellsCurrentState(headCells);
  }, [headCells]);

  return (
    <>
      <IconButton onClick={() => setIsOpen((prev) => !prev)}>
        <Columns02Svg />
        <Typography
          sx={{ ml: 0.5 }}
          level="text_sm"
          fontWeight="semi_bold"
          textColor="colors.buttons.tertiary.button_tertiary_fg"
        >
          {t('common.columns')}
        </Typography>
      </IconButton>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} anchor="right">
        <DrawerBody>
          <List
            sx={{
              p: 1,
              bgcolor: 'inherit',
            }}
            component="ul"
          >
            <Sheet sx={{ ml: 2, bgcolor: 'inherit' }}>
              <ListItemWithCheckbox
                id={allColumnsOptionIndex}
                isChecked={isAllChecked}
                isIndeterminate={isAllCellCheckboxIndeterminate}
                onClick={onAllClick}
              >
                <Typography noWrap fontWeight="medium" level="text_sm">
                  {t('common.select_all')}
                </Typography>
              </ListItemWithCheckbox>
            </Sheet>
            <Divider />
            {Object.values(headCellsCurrentState).map((cell) => (
              <Sheet key={cell.id} sx={{ ml: 2, bgcolor: 'inherit' }}>
                <ListItemWithCheckbox
                  id={cell.id}
                  isChecked={Boolean(cell.isVisible)}
                  onClick={onCellVisibilityChange}
                >
                  <Typography noWrap fontWeight="medium" level="text_sm">
                    {cell.label}
                  </Typography>
                </ListItemWithCheckbox>
              </Sheet>
            ))}
          </List>
          <Sheet
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          ></Sheet>
        </DrawerBody>
        <Sheet
          sx={{
            p: 2,
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 1,
            borderStyle: 'solid',
            borderColor: 'gray.200',
            bgcolor: 'inherit',
          }}
        >
          <ActionsBar
            onApply={onApply}
            onReset={onReset}
            onCancel={() => setIsOpen(false)}
          />
        </Sheet>
      </Drawer>
    </>
  );
};

// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { IHeadCell } from 'src/modules/types/table';
// import ModalDialog from 'src/components/ModalDialog';
// import { Box, Checkbox } from '@mui/material';
// import { TableContext } from 'src/components/Table/TableContext';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTableColumns } from '@fortawesome/free-solid-svg-icons';
// import { FilterActionItem } from '../Filter/_components/FilterActionItem';

// export const SelectHeadCells: React.FC = () => {
//   const { t } = useTranslation();

//   const [isOpen, setIsOpen] = React.useState(false);
//   const [isAllChecked, setIsAllChecked] = React.useState(false);

//   const { headCells, setHeadCells } = React.useContext(TableContext);

//   const onAllClick = () => {
//     const isAllCheckedNewState = !isAllChecked;

//     setHeadCells(
//       headCells.map((cell) => ({
//         ...cell,
//         isVisible: isAllCheckedNewState,
//       })),
//     );

//     setIsAllChecked(isAllCheckedNewState);
//   };

//   const onCellVisibilityChange = (cell: IHeadCell & { isVisible: boolean }) => {
//     const updatedHeadCells = headCells.map((headCell) => {
//       if (headCell.id === cell.id) {
//         return cell;
//       }

//       return headCell;
//     });

//     setHeadCells(updatedHeadCells);
//   };

//   React.useEffect(() => {
//     setIsAllChecked(headCells.every((cell) => cell.isVisible));
//   }, [headCells]);

//   return (
//     <>
//       <FilterActionItem
//         title="Show/hide columns"
//         onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
//       >
//         <FontAwesomeIcon fontSize={16} icon={faTableColumns} />
//       </FilterActionItem>
//       <ModalDialog
//         open={isOpen}
//         title={t('common.columns_visibility')}
//         onClose={() => setIsOpen(false)}
//         sxForDialogContent={{ maxHeight: '80vh' }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             cursor: 'pointer',
//             marginBottom: 3,
//             fontWeight: 'bold',
//           }}
//         >
//           <Checkbox checked={isAllChecked} onClick={onAllClick} />
//           <Box>{t('common.all')}</Box>
//         </Box>
//         {headCells.map((cell) => (
//           <Box
//             onClick={() =>
//               onCellVisibilityChange({ ...cell, isVisible: !cell.isVisible })
//             }
//             key={cell.id}
//             sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
//           >
//             <Checkbox checked={cell.isVisible} />
//             <Box>{cell.label}</Box>
//           </Box>
//         ))}
//       </ModalDialog>
//     </>
//   );
// };
