import React from 'react';
import { Alert, Box, Button, Grid, IconButton, Typography } from '@mui/joy';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { Api, useSitesOptions } from 'src/modules/utils';
import ComboBox, { ComboBoxOption } from 'src/components/ComboBox';
import { useTranslation } from 'react-i18next';
import { PageContentScrollableChildContainer } from 'src/components/PageContent/PageContentScrollableChildContainer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { Checkbox } from 'src/components/_ui-kit/Checkbox';
import { useFormik } from 'formik';
import { MUI_SIZE_12 } from 'src/config';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { Trash04Svg } from 'src/components/svgIcons';
import { createIncentivePaymentSettingsScheme } from 'src/modules/schemes/incentive-payment-settings';
import { IIncentivePaymentSettingsResponseData } from 'src/modules/types/incentive-payment-settings';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { useDispatch } from 'react-redux';
import { addProcessStatus } from 'src/modules/actions';

interface IIncentivePaymentFormPoint {
  min: string;
  max: string;
  removePercentage: string;
}
interface IIncentivePaymentSettingsFormData {
  id?: number;
  siteId?: number;
  enabled: boolean;
  serviceKey: string;
  points: Array<IIncentivePaymentFormPoint>;
}

const defaultPointValue = {
  min: '',
  max: '',
  removePercentage: '',
};

const defaultValues: IIncentivePaymentSettingsFormData = {
  enabled: true,
  serviceKey: '',
  points: [],
};

export const IncentivePaymentSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isSubmitInProgress, setIsSubmitInProgress] = React.useState(false);

  const [
    selectedSiteOption,
    setSelectedSiteOption,
  ] = React.useState<ComboBoxOption | null>(null);

  const siteOptions = useSitesOptions();

  const {
    data,
    isDataLoading,
    fetchData,
  } = useDataFetcherWithData<IIncentivePaymentSettingsResponseData | null>(
    apiEndpoints.INCENTIVE_PAYMENT_SETTINGS,
    null,
  );

  const parsedData = React.useMemo(() => {
    if (!data) {
      return defaultValues;
    }

    return {
      ...data,
      points: data.points.map((point) => ({
        ...point,
        min: String(point.min),
        max: String(point.max),
        removePercentage: String(point.removePercentage),
      })),
    };
  }, [data]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: parsedData,
    validationSchema: createIncentivePaymentSettingsScheme,
    onSubmit: async (data) => {
      if (selectedSiteOption) {
        const dataToSubmit = {
          ...data,
          siteId: selectedSiteOption.id,
          points: data.points.map((point) => ({
            ...point,
            min: Number(point.min.replace(/,/g, '.')),
            max: Number(point.max.replace(/,/g, '.')),
            removePercentage: Number(point.removePercentage.replace(/,/g, '.')),
          })),
        };

        try {
          setIsSubmitInProgress(true);

          if (dataToSubmit.id) {
            await Api.incentivePaymentSettings.update(dataToSubmit as any);
          } else {
            await Api.incentivePaymentSettings.create(dataToSubmit);
          }

          setIsSubmitInProgress(false);

          dispatch(
            addProcessStatus({
              variant: 'success',
              title: t('common.success'),
            }),
          );
        } catch {
          setIsSubmitInProgress(false);
        }
      }
    },
  });

  const handleSiteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => setSelectedSiteOption(value);

  React.useEffect(() => {
    if (selectedSiteOption) {
      fetchData({
        filter: { where: { siteId: { eq: selectedSiteOption.id } } },
      });
    }
  }, [fetchData, selectedSiteOption]);

  const onAddRow = () => {
    formik.setValues({
      ...formik.values,
      points: [...formik.values.points, defaultPointValue],
    });
  };

  const createRemoveRowHandler = (index: number) => () => {
    formik.setValues({
      ...formik.values,
      points: formik.values.points.filter((_, i) => i !== index),
    });
  };

  return (
    <PageContentWithTopToolbar>
      <PageContentScrollableChildContainer>
        <Box sx={{ maxWidth: 600 }}>
          <Box sx={{ pt: 2, pb: 1 }}>
            <Typography
              level="text_xl"
              textColor="colors.text.text_primary.main"
            >
              {t('incentive_payment.settings')}
            </Typography>
          </Box>
          <Box
            sx={{
              borderColor: 'colors.border.border_primary',
              borderWidth: 1,
              borderRadius: 8,
              borderStyle: 'solid',
              bgcolor: 'common.white',
            }}
          >
            <Box
              sx={{
                py: 3,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <FormFieldContainer>
                <ComboBox
                  id="site"
                  label={t('kpi.settings.site')}
                  options={siteOptions}
                  value={selectedSiteOption}
                  onChange={handleSiteChange}
                />
              </FormFieldContainer>
            </Box>

            <Box sx={{ position: 'relative', minHeight: 200 }}>
              {(isDataLoading || isSubmitInProgress) && <MainContentLoader />}

              {!Boolean(selectedSiteOption) && (
                <FormFieldContainer>
                  <Grid container={true}>
                    <Grid xs={MUI_SIZE_12}>
                      <Alert variant="soft">
                        {t('reports.select_site_from_filter')}
                      </Alert>
                    </Grid>
                  </Grid>
                </FormFieldContainer>
              )}

              {Boolean(selectedSiteOption) && (
                <FormFieldContainer>
                  <FormikTextField
                    required
                    fullWidth
                    id="serviceKey"
                    label={t('incentive_payment.service_key')}
                    name="serviceKey"
                    //
                    formik={formik}
                    placeholder={t('common.add_your_text_here')}
                  />
                </FormFieldContainer>
              )}
              {Boolean(selectedSiteOption) && (
                <FormFieldContainer>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Checkbox
                      id="enabled"
                      size="lg"
                      label={t('common.active')}
                      checked={formik.values.enabled}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </FormFieldContainer>
              )}
              {Boolean(selectedSiteOption) && (
                <FormFieldContainer>
                  <Grid
                    spacing={2}
                    container
                    sx={{ flexGrow: 1, alignItems: 'center', pt: 2 }}
                  >
                    <Grid xs={3.5}>{t('common.min')}</Grid>
                    <Grid xs={3.5}>{t('common.max')}</Grid>
                    <Grid xs={3.5}>
                      {t('incentive_payment.withholding')} (%)
                    </Grid>
                    <Grid xs={1.5}></Grid>
                  </Grid>
                </FormFieldContainer>
              )}
              {Boolean(selectedSiteOption) &&
                formik.values.points.map((point, i) => (
                  <FormFieldContainer key={i}>
                    <Grid spacing={2} container sx={{ flexGrow: 1 }}>
                      <Grid xs={3.5}>
                        <FormikTextField
                          required
                          variant="soft"
                          id={`points.${generateFullIdInMultiEntitiesForm(
                            'min',
                            i,
                          )}`}
                          name={`points.${generateFullIdInMultiEntitiesForm(
                            'min',
                            i,
                          )}`}
                          formik={formik}
                          placeholder=" "
                        />
                      </Grid>
                      <Grid xs={3.5}>
                        <FormikTextField
                          required
                          variant="soft"
                          id={`points.${generateFullIdInMultiEntitiesForm(
                            'max',
                            i,
                          )}`}
                          name={`points.${generateFullIdInMultiEntitiesForm(
                            'max',
                            i,
                          )}`}
                          formik={formik}
                          placeholder=" "
                        />
                      </Grid>
                      <Grid xs={3.5}>
                        <FormikTextField
                          required
                          variant="soft"
                          id={`points.${generateFullIdInMultiEntitiesForm(
                            'removePercentage',
                            i,
                          )}`}
                          name={`points.${generateFullIdInMultiEntitiesForm(
                            'removePercentage',
                            i,
                          )}`}
                          formik={formik}
                          placeholder=" "
                        />
                      </Grid>
                      <Grid xs={1.5} sx={{ display: 'flex' }}>
                        <IconButton onClick={createRemoveRowHandler(i)}>
                          <Trash04Svg />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </FormFieldContainer>
                ))}
            </Box>

            <Box
              sx={{
                borderTopColor: 'colors.border.border_tertiary',
                borderTopStyle: 'solid',
                borderTopWidth: 1,
                py: 2,
                px: 1,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Box>
                  <Button onClick={onAddRow} variant="outlined">
                    {t('common.add_row')}
                  </Button>
                  <Button
                    type="submit"
                    variant="solid"
                    sx={{ ml: 1 }}
                    onClick={formik.handleSubmit as any}
                  >
                    {t('common.save')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageContentScrollableChildContainer>
    </PageContentWithTopToolbar>
  );
};
