import React from 'react';
import Api from '../Api';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getWeek, format } from 'date-fns';
import { AnyObject } from 'src/modules/types';
import { IHeadCell } from 'src/modules/types/table';
import { addProcessStatus, setIsDataRequested } from 'src/modules/actions';
import { ScorecardReportResponseTableItem } from 'src/modules/types/scorecard';
import { ScorecardReportHeadCell } from 'src/components/Reports/ScorecardReport/components/HeadCell';
interface IUseScorecardFilterFieldsConfigurationArgs {
  week: number;
  year: number;
  siteId: number;
}
export const useScorecardFilterFieldsConfiguration = ({
  week,
  year,
  siteId,
}: IUseScorecardFilterFieldsConfigurationArgs) => {
  return React.useMemo(() => {
    return {
      siteId: {
        value: siteId,
        property: 'id',
        operator: 'eq' as const,
      },
      year: {
        value: year,
        property: 'year',
        operator: 'eq' as const,
      },
      week: {
        value: week,
        property: 'week',
        operator: 'eq' as const,
      },
    };
  }, [siteId, week, year]);
};

const useScorecardReportFilters = (
  siteId?: number,
  year?: number,
  week?: number,
) => {
  const currentDate = new Date();
  const currentWeek = getWeek(currentDate);
  const currentYear = Number(format(currentDate, 'yyyy'));

  const filterAsWhere = React.useMemo(
    () => ({
      yearWeek: Number(`${year ?? currentYear}${week ?? currentWeek}`),
      siteId: siteId!,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteId, week, year],
  );

  return {
    filterAsWhere,
  };
};
export const useScorecardReportDataEffect = (
  url: string,
  siteId?: number,
  year?: number,
  week?: number,
) => {
  const dispatch = useDispatch();

  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const [scorecardData, setScorecardData] = React.useState<
    Array<ScorecardReportResponseTableItem>
  >([]);

  const [headCells, setHeadCells] = React.useState<Array<IHeadCell>>([]);
  const [headCellsForExport, setHeadCellsForExport] = React.useState<
    Array<IHeadCell>
  >([]);

  const { filterAsWhere } = useScorecardReportFilters(siteId, year, week);

  React.useEffect(() => {
    fetchData();

    async function fetchData() {
      try {
        dispatch(setIsDataRequested(true));
        setIsRequestInProgress(true);

        const data = await Api.Request.get<
          Array<ScorecardReportResponseTableItem>
        >(url, {
          filter: {
            where: filterAsWhere,
          },
        });

        dispatch(setIsDataRequested(false));
        setIsRequestInProgress(false);

        const tableHeadCells = generateScorecardTableHeadCells(data);
        const tableHeadCellsForExport = generateScorecardTableHeadCellsForExport(
          data,
        );

        setScorecardData(data);
        setHeadCells(tableHeadCells);
        setHeadCellsForExport(tableHeadCellsForExport);
      } catch (e) {
        dispatch(setIsDataRequested(false));
        setIsRequestInProgress(false);
        dispatch(
          addProcessStatus({
            message: (e as AnyObject).message,
            variant: 'error',
          }),
        );
      }
    }
  }, [url, dispatch, year, week, siteId, filterAsWhere]);

  return {
    scorecardData,
    headCells,
    headCellsForExport,
    filterAsWhere,
    isRequestInProgress,
  };
};

function generateScorecardTableHeadCells(
  scorecardResponseData: Array<ScorecardReportResponseTableItem>,
) {
  if (!scorecardResponseData.length) {
    return [];
  }

  const datesCells = Object.keys(scorecardResponseData[0]).map((columnName) => {
    let label: React.ReactElement | string = '';
    if (columnName !== 'rowName' && columnName !== 'weekTotal') {
      label = <ScorecardReportHeadCell date={columnName} />;
    }
    if (columnName === 'weekTotal') {
      label = <Box>Week</Box>;
    }

    return {
      id: columnName,
      label: label,
      align: columnName === 'rowName' ? ('left' as const) : ('center' as const),
    };
  });

  return [...datesCells];
}

function generateScorecardTableHeadCellsForExport(
  scorecardResponseData: Array<ScorecardReportResponseTableItem>,
) {
  if (!scorecardResponseData.length) {
    return [];
  }
  const datesCells = Object.keys(scorecardResponseData[0]).map((columnName) => {
    let label: React.ReactElement | string = '';
    if (columnName !== 'rowName' && columnName !== 'weekTotal') {
      label = columnName;
    }
    if (columnName === 'weekTotal') {
      label = 'Week';
    }

    return {
      id: columnName,
      label: label,
    };
  });

  return [...datesCells];
}
