import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/joy';
import { Copy01Svg } from 'src/components/svgIcons';
import {
  IPolicyToMangeEntitiesConfig,
  PolicyConfigItem,
} from 'src/modules/types';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getManageEntitiesConfigData,
  getIsPageSettingUpdateInProgress,
  getPageSettingDataRequest,
} from 'src/modules/selectors/page-setting.selector';
import PolicesToCreateUpdateDeletePolicyForm from './PolicyForm';
import { TextField } from 'src/components/_ui-kit/TextField';
import { updatePageSettingRequest } from 'src/modules/actions';
import { usePageSettingHelpers } from 'src/modules/utils/hooks/page-settings.hook';
import { useTranslation } from 'react-i18next';
import { PolicesStatusAlert } from '../PolicesStatusAlert';
import {
  ManageEntitiesConfigItem,
  generateManageEntitiesConfigItemId,
  manageEntitiesConfig,
  mapEntityKeyToEntityName,
} from 'src/config/manageEntitiesConfig';

const defaultValue: PolicyConfigItem = {
  action: '',
  resource: '',
  clientKey: undefined,
};

export const PolicesToCreateUpdateDelete = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [search, setSearch] = React.useState('');

  const { pageSettingViewConfigToPolicyConfig } = usePageSettingHelpers();

  const viewConfig = useSelector(getPageSettingDataRequest);

  const manageEntitiesConfigServerData = useSelector(
    getManageEntitiesConfigData,
  );

  const isPageSettingUpdateInProgress = useSelector(
    getIsPageSettingUpdateInProgress,
  );

  const entitiesNames = React.useMemo(() => {
    const filteredEntitiesNames = Object.keys(
      manageEntitiesConfig,
    ).filter((key) => key.toLowerCase().includes(search.toLowerCase()));

    filteredEntitiesNames.sort();

    return filteredEntitiesNames;
  }, [search]);

  const createUpdateDeleteOperationsConfigFromInitialData = React.useMemo(() => {
    return Object.values(manageEntitiesConfig).reduce((acc, value) => {
      Object.keys(value).forEach((operation) => {
        acc[value[operation].id] =
          manageEntitiesConfigServerData[value[operation].id] ?? [];
      });

      return acc;
    }, {});
  }, [manageEntitiesConfigServerData]);

  const formik = useFormik<IPolicyToMangeEntitiesConfig>({
    initialValues: createUpdateDeleteOperationsConfigFromInitialData,
    onSubmit: (data) => {
      dispatch(
        updatePageSettingRequest({
          config: pageSettingViewConfigToPolicyConfig(viewConfig),
          manageEntitiesConfig: data,
        }),
      );
    },
    enableReinitialize: true,
  });

  const onAddPolicy = (id: string) => {
    formik.setFieldValue(id, [...formik.values[id], defaultValue]);
  };

  const checkIfAtLeastOnePolicyAppliedForOperation = (operation: string) => {
    return Boolean(formik.values[operation]?.length);
  };

  const checkIfAtLeastOnePolicyAppliedForAnyEntityOperation = (
    entityName: string,
  ) => {
    const entityOperations = manageEntitiesConfig[entityName];

    if (!entityOperations) {
      return false;
    }

    return Object.values(entityOperations).some((operation: any) =>
      checkIfAtLeastOnePolicyAppliedForOperation(operation.id),
    );
  };

  const copyValuesFromCreateToUpdateAction = (entityName: string) => {
    const entityOperations = manageEntitiesConfig[entityName];

    if (!entityOperations) {
      return;
    }

    formik.setFieldValue(
      generateManageEntitiesConfigItemId(entityName, 'update'),
      [
        ...formik.values[
          generateManageEntitiesConfigItemId(entityName, 'create')
        ],
      ],
    );
  };

  return (
    <Box>
      <Box sx={{ py: 2 }}>
        <FormControl id="free-solo-demo">
          <TextField
            value={search}
            placeholder="Search by entity..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </FormControl>
      </Box>
      <AccordionGroup>
        {entitiesNames.map((name) => (
          <Accordion key={name}>
            <AccordionSummary>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PolicesStatusAlert
                  hasPolices={checkIfAtLeastOnePolicyAppliedForAnyEntityOperation(
                    name,
                  )}
                />

                <Typography fontWeight="semi_bold" level="text_sm">
                  {mapEntityKeyToEntityName(name)}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionGroup>
                {Object.entries(
                  manageEntitiesConfig[name] as ManageEntitiesConfigItem,
                ).map(([operation, operationDetails]) => (
                  <Accordion key={operationDetails.id}>
                    <AccordionSummary>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PolicesStatusAlert
                          hasPolices={checkIfAtLeastOnePolicyAppliedForOperation(
                            operationDetails.id,
                          )}
                        />

                        <Typography level="text_sm">
                          {operationDetails.title}
                        </Typography>

                        {operation === 'create' && (
                          <Box sx={{ display: 'flex' }}>
                            <Tooltip title="Copy polices to update operation">
                              <IconButton
                                sx={{ width: 20 }}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  copyValuesFromCreateToUpdateAction(name);
                                }}
                              >
                                <Copy01Svg style={{ width: 20 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {formik.values[operationDetails.id]?.map(
                        (
                          {
                            client,
                            ...item
                          }: PolicyConfigItem & { client: number | null },
                          i: number,
                        ) => (
                          <PolicesToCreateUpdateDeletePolicyForm
                            key={i}
                            selectedResource={item}
                            selectedClientId={client}
                            label={`Policy #${i + 1}`}
                            id={`${name}_${operation})_${i}`}
                            selectedResources={
                              formik.values[operationDetails.id]
                            }
                            onPolicyChange={(value) => {
                              formik.setFieldValue(
                                `${operationDetails.id}[${i}]`,
                                {
                                  ...formik.values[operationDetails.id][i],
                                  ...value,
                                },
                              );
                            }}
                            onClientChange={(value) =>
                              formik.setFieldValue(
                                `${operationDetails.id}[${i}]`,
                                {
                                  ...formik.values[operationDetails.id][i],
                                  client: value?.id,
                                },
                              )
                            }
                            onDelete={() => {
                              formik.setFieldValue(
                                operationDetails.id,
                                formik.values[operationDetails.id].filter(
                                  (_: any, a: number) => a !== i,
                                ),
                              );
                            }}
                          />
                        ),
                      )}
                      <Button
                        variant="soft"
                        onClick={() => onAddPolicy(operationDetails.id)}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography sx={{ fontWeight: 'normal' }}>
                            Add{' '}
                            <Box
                              component="span"
                              sx={{ textTransform: 'lowercase' }}
                            >{`${mapEntityKeyToEntityName(
                              name,
                            )} ${operation} policy`}</Box>
                          </Typography>
                        </Box>
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionGroup>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionGroup>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          loading={isPageSettingUpdateInProgress}
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          {t('common.submit')}
        </Button>
      </Box>
    </Box>
  );
};
