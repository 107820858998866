import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { MUI_SIZE_12 } from '../../../config';
import { Alert } from '@mui/joy';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { ExpeditorsGapsDailyChart } from './GapsChart';

interface IExpeditorsGapsDailyProps {
  date?: string;
  siteId?: number;
}

interface IDailyGapsItem {
  countOfGaps: number;
  date: string;
  employee: string;
  shiftId: number;
  shiftName: string;
  siteId: number;
  siteName: string;
  wmsLogin: string;
}

const gapsDailyUrl = '/expeditors/gaps/daily';

const defaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'date',
};

export const ExpeditorsGapsDaily = ({
  date,
  siteId,
}: IExpeditorsGapsDailyProps) => {
  const { t } = useTranslation();
  const [
    gapToViewChart,
    setGapToViewChart,
  ] = React.useState<IDailyGapsItem | null>(null);
  const where = React.useMemo(
    () => ({
      date: date ?? undefined,
      siteId: siteId ?? undefined,
    }),
    [date, siteId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<{
    data: Array<AnyObject>;
    count: number;
  }>(gapsDailyUrl, {
    data: [],
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('productions_uph_reports.date'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'siteId',
        orderConfig: { orderBy: 'siteId' },
        label: t('recruiting_dashboards_summary.siteId'),
      },
      {
        id: 'wmsLogin',
        orderConfig: { orderBy: 'wmsLogin' },
        label: t('employees.wms_login'),
      },
      {
        id: 'employee',
        orderConfig: { orderBy: 'employee' },
        label: t('productions_uph_reports.employee'),
      },
      {
        id: 'countOfGaps',
        orderConfig: { orderBy: 'countOfGaps' },
        label: t('expeditors_reports.countOfGaps'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
  });

  const alertMessage = React.useMemo(() => {
    let alertMessage = null;
    if (!siteId) {
      alertMessage = t('reports.select_site_from_filter');
    }
    if (!date) {
      alertMessage = t('reports.select_date_from_filter');
    }

    return alertMessage;
  }, [date, siteId, t]);

  React.useEffect(() => {
    if (siteId && date) {
      fetchData({
        filter: {
          where,
          limit,
          offset: page,
          order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
        },
      });
    }
  }, [date, fetchData, limit, order, orderBy, page, siteId, where]);

  return (
    <>
      {alertMessage && (
        <Grid container={true}>
          <Grid item={true} xs={MUI_SIZE_12}>
            <Alert variant="soft">{alertMessage}</Alert>
          </Grid>
        </Grid>
      )}
      {!alertMessage && (
        <ReportTable
          data={data.data as any}
          count={data.count}
          // summaryData={data.summary}
          isDataLoading={isDataLoading}
          tableUniqueKey={gapsDailyUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}

      {gapToViewChart && (
        <ExpeditorsGapsDailyChart
          onClose={() => setGapToViewChart(null)}
          filter={gapToViewChart}
        />
      )}
    </>
  );
};
