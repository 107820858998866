import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useValidate } from 'src/modules/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  IdsArray,
  IFilterData,
  IStoreState,
  IUpdateCostPlusSettings,
  IWhere,
} from '../../../../../../modules/types';
import { FormFieldContainer } from '../../../../../../components/Form/FormFieldContainer';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import { updateCostPlusSettingsRequest } from '../../../../../../modules/actions';
import { Sheet } from '@mui/joy';
import { updateCostPlusSettingScheme } from '../../../../../../modules/schemes';
import { useFormikInUpdateForm } from '../../../../../../modules/utils/hooks/common/forms';
import { getCostPlusSettingsByIds } from '../../../../../../modules/selectors/cost-plus-settings.selector';
import SitesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/SitesCombobox';
import FormikCheckbox from '../../../../../../components/Formik/FormikCheckbox';
import CostPlusTypesComboBox from '../../../../../../components/Formik/comboboxes-with-entities/CostPlusTypesCombobox';

interface IUpdateCostPlusSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  idsToUpdate: IdsArray;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const UpdateCostPlusSettings = ({
  isOpen,
  onClose,
  idsToUpdate,
  filterList,
  filterCount,
}: IUpdateCostPlusSettingsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useValidate(updateCostPlusSettingScheme);

  // fetch costPlusSettings list from store
  // const costPlusSettings = useSelector(getCostPlusSettingsByIds)(idsToUpdate);
  const costPlusSettings = useSelector(
    (state) => getCostPlusSettingsByIds(state as IStoreState)(idsToUpdate),
    shallowEqual,
  );

  const formik = useFormikInUpdateForm({
    initialValues: costPlusSettings,
    validate,
    enableReinitialize: true,
    onSubmit: (data: IUpdateCostPlusSettings[]) => {
      dispatch(
        updateCostPlusSettingsRequest({
          data,
          filters: { list: filterList, count: filterCount },
        }),
      );
      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('cost-plus-settings.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          {formik.values.map((costPlusSetting, index) => (
            <DrawerBodySectionContent key={costPlusSetting.id}>
              <DrawerBodySectionTitle>
                {t('cost-plus-settings.update_title', {
                  id: costPlusSetting.id,
                })}
              </DrawerBodySectionTitle>
              <Sheet sx={{ bgcolor: 'inherit' }}>
                <FormFieldContainer>
                  <SitesComboBox
                    id={`${index}.siteId`}
                    required={true}
                    formik={formik}
                    placeholder={t('payboard.site')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikCheckbox
                    id={`${index}.isActive`}
                    formik={formik}
                    label={t('cost-plus-settings.active')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <CostPlusTypesComboBox
                    id={`${index}.type`}
                    required={true}
                    formik={formik}
                    placeholder={t('cost-plus-settings.type')}
                    errorMode="onFieldChange"
                  />
                </FormFieldContainer>

                {['description'].map((key) => (
                  <FormFieldContainer key={key}>
                    <FormikTextField
                      id={`${index}.${key}`}
                      variant="outlined"
                      required={!['siteId', 'active', 'type'].includes(key)}
                      fullWidth
                      label={t(`cost-plus-settings.${key}`)}
                      name={`${index}.${key}`}
                      formik={formik}
                      errorMode="onFieldChange"
                    />
                  </FormFieldContainer>
                ))}
              </Sheet>
            </DrawerBodySectionContent>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};

export default UpdateCostPlusSettings;
