import React from 'react';
import {
  PageContentTab,
  PageContentTabPanel,
  PageContentTabs,
  PageContentTabsList,
} from 'src/components/PageContent';
import { FilterPanelContextProvider } from 'src/components/FilterPanel/FilterPanelContext';

interface IPageTabConfigItem {
  title: string;
  hasUserAccess: boolean;
  component: React.ReactNode;
}

export interface ITabsNgroupProps {
  config: Array<IPageTabConfigItem>;
  toolbarComponent?: React.ReactNode;
  fullHeight?: boolean;
  onTabChange?(): void;
}

export const TabsNgroup = ({
  config,
  toolbarComponent,
  fullHeight = true,
  onTabChange,
}: ITabsNgroupProps) => {
  const [activeTab, setActiveTab] = React.useState<number | null>(0);

  return (
    <PageContentTabs
      fullHeight={fullHeight}
      value={activeTab}
      onChange={(_, value) => {
        onTabChange && onTabChange();
        setActiveTab(value !== null ? Number(value) : value);
      }}
    >
      <PageContentTabsList>
        {config.map((tabConfig, index) => (
          <PageContentTab key={tabConfig.title} value={index}>
            {tabConfig.title}
          </PageContentTab>
        ))}
      </PageContentTabsList>

      {toolbarComponent}

      {config.map((tabConfig, index) =>
        index === activeTab ? (
          <PageContentTabPanel
            fullHeight={fullHeight}
            value={index}
            key={tabConfig.title}
          >
            <FilterPanelContextProvider>
              {tabConfig.component}
            </FilterPanelContextProvider>
          </PageContentTabPanel>
        ) : null,
      )}
    </PageContentTabs>
  );
};
