import React from 'react';
import { useTranslation } from 'react-i18next';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { Questions } from './panels/Questions';
import { QuestionsCategories } from './panels/QuestionsCategories';
import { TabsNgroup } from 'src/components/TabsNgroup';

export const EmployeesQuestions = () => {
  const { t } = useTranslation();

  const hasUserAccessEmployeesQuestionsTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEE_QUESTIONS_TAB,
  );
  const hasUserAccessEmployeesQuestionsCategoriesTab = useHasUserAccessToViewTab(
    tabsPaths.EMPLOYEE_QUESTIONS_CATEGORIES_TAB,
  );

  const questionsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('main_menu.employees_questions'),
          hasUserAccess: hasUserAccessEmployeesQuestionsTab,
          component: <Questions />,
        },
        {
          title: t('main_menu.employees_question_categories'),
          hasUserAccess: hasUserAccessEmployeesQuestionsCategoriesTab,
          component: <QuestionsCategories />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessEmployeesQuestionsTab,
      hasUserAccessEmployeesQuestionsCategoriesTab,
      t,
    ],
  );

  return <TabsNgroup config={questionsTabsConfig} />;
};
