import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  IPointModel,
  IPointReport,
  IPointState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IPointState = {
  refreshKey: 0,
  id: NaN,
  employeeId: NaN,
  datetime: '',
  pointTypeId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
  pointsReport: [],
  pointsReportCount: 0,
  isPointsListRequestInProgress: false,
  isPointComboboxListRequestInProgress: false,
};

/**
 * Flush actions
 */
const flushPointState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createPointRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createPointSuccess = (state: IPointState, payload: IPointModel) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createPointFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getPointListRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isPointsListRequestInProgress: true,
});

const getPointListSuccess = (state: IPointState, payload: IPointModel[]) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
  isPointsListRequestInProgress: false,
});

const getPointListFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isPointsListRequestInProgress: false,
});

const getPointReportSuccess = (
  state: IPointState,
  payload: IPointReport[],
) => ({
  ...state,
  pointsReport: payload,
});

const getPointReportCountSuccess = (
  state: IPointState,
  payload: ICountResponse,
) => ({
  ...state,
  pointsReportCount: payload.count,
});

/**
 * Get count actions
 */
const getPointCountRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isPointsListRequestInProgress: true,
});

const getPointCountSuccess = (state: IPointState, payload: ICountResponse) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
  isPointsListRequestInProgress: false,
});

const getPointCountFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isPointsListRequestInProgress: false,
});

/**
 * Deletion actions
 */
const deletePointRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deletePointFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const removePointsRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const removePointsFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updatePointsRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePointsFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getPointComboboxListRequest = (state: IPointState) => ({
  ...state,
  error: false,
  is_data_requested: true,
  isPointComboboxListRequestInProgress: true,
});

const getPointComboboxListSuccess = (
  state: IPointState,
  payload: Partial<IPointModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
  isPointComboboxListRequestInProgress: false,
});

const getPointComboboxListFailed = (state: IPointState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
  isPointComboboxListRequestInProgress: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IPointState>();

const exportPointRequest = exportRequest;
const exportPointSuccess = exportSuccess;
const exportPointFailed = exportFailed;

/**
 * Point reducer
 */
export const point = createReducer<IPointState>({}, defaultState)
  // flush actions
  .on(actions.flushPointState, flushPointState)
  // creation actions
  .on(actions.createPointRequest, createPointRequest)
  .on(actions.createPointSuccess, createPointSuccess)
  .on(actions.createPointFailed, createPointFailed)
  // get list actions
  .on(actions.getPointListRequest, getPointListRequest)
  .on(actions.getPointListSuccess, getPointListSuccess)
  .on(actions.getPointListFailed, getPointListFailed)
  // points report actions
  .on(actions.getPointsReportSuccess, getPointReportSuccess)
  .on(actions.getPointsReportCountSuccess, getPointReportCountSuccess)
  // get count actions
  .on(actions.getPointCountRequest, getPointCountRequest)
  .on(actions.getPointCountSuccess, getPointCountSuccess)
  .on(actions.getPointCountFailed, getPointCountFailed)
  // deletion actions
  .on(actions.deletePointRequest, deletePointRequest)
  .on(actions.deletePointFailed, deletePointFailed)
  // remove actions
  .on(actions.removePointsRequest, removePointsRequest)
  .on(actions.removePointsFailed, removePointsFailed)
  // updating actions
  .on(actions.updatePointsRequest, updatePointsRequest)
  .on(actions.updatePointsFailed, updatePointsFailed)
  // get combobox list actions
  .on(actions.getPointComboboxListRequest, getPointComboboxListRequest)
  .on(actions.getPointComboboxListSuccess, getPointComboboxListSuccess)
  .on(actions.getPointComboboxListFailed, getPointComboboxListFailed)
  // export actions
  .on(actions.exportPointRequest, exportPointRequest)
  .on(actions.exportPointSuccess, exportPointSuccess)
  .on(actions.exportPointFailed, exportPointFailed);

export default point;
