import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { map } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useValidate } from 'src/modules/utils';
import { useSelector } from 'react-redux';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { getMetatagsNames } from 'src/modules/selectors/metatag';
import Metatags from 'src/pages/Productions/components/Metatags';
import { IBaselineModel, IUpdateBaseline } from 'src/modules/types';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateUpdateBaselineScheme } from 'src/modules/schemes/baseline';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';

interface IBaselineUpdateRecordsProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (baselines: IBaselineModel[]) => void;
  baselines: IUpdateBaseline[];
}

export const BaselineUpdateRecords = ({
  isOpen,
  onClose,
  baselines,
  onSubmit,
}: IBaselineUpdateRecordsProps) => {
  const { t } = useTranslation();

  const metatagNames = useSelector(getMetatagsNames);

  const sitesIdSet = new Set<number>();
  baselines.forEach(({ siteId }) => sitesIdSet.add(siteId));
  const sitesId = [...sitesIdSet.values()];
  const metatags = sitesId.reduce<string[]>((all, siteId) => {
    if (metatagNames[siteId]) {
      return [...all, ...metatagNames[siteId]];
    }
    return all;
  }, []);

  const formik = useFormik({
    initialValues: baselines.map((item) => ({
      ...item,
      minCpu: String(item.minCpu),
      maxCpu: String(item.maxCpu),
      baseline: String(item.baseline),
    })),
    enableReinitialize: true,
    validate: useValidate(generateUpdateBaselineScheme(metatags)),
    onSubmit: async (data) => {
      onSubmit(
        data.map((item) => ({
          ...item,
          siteId: Number(item.siteId),
          baseline: Number(item.baseline),
          minCpu: Number(item.minCpu),
          maxCpu: Number(item.maxCpu),
        })),
      );

      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  const getMetatagsBySite = React.useCallback(
    (siteId: number) => metatagNames[siteId] ?? [],
    [metatagNames],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right">
        <DrawerHeader onCloseClick={onClose}>
          {t('baseline.update_form_card_title')}
        </DrawerHeader>
        <DrawerBody>
          <DrawerBodyContent>
            {formik.values.map((item, i) => (
              <DrawerBodySectionContent key={item.id}>
                <DrawerBodySectionTitle>{i + 1}</DrawerBodySectionTitle>

                <FormFieldContainer>
                  <SitesComboBox
                    required
                    id={`${i}.siteId`}
                    formik={formik}
                    label={t('standards.site')}
                    placeholder={t('standards.site')}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('baseline.baseline')}
                    id={`${i}.baseline`}
                    name={`${i}.baseline`}
                    autoComplete="baseline"
                    formik={formik}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('baseline.min_cpu')}
                    id={`${i}.minCpu`}
                    name={`${i}.minCpu`}
                    autoComplete="minCpu"
                    formik={formik}
                  />
                </FormFieldContainer>
                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    required
                    fullWidth
                    label={t('baseline.max_cpu')}
                    id={`${i}.maxCpu`}
                    name={`${i}.maxCpu`}
                    autoComplete="maxCpu"
                    formik={formik}
                  />
                </FormFieldContainer>

                {map(getMetatagsBySite(item.siteId), (metatag: string) => {
                  return (
                    <Metatags
                      name={metatag}
                      formik={formik}
                      index={i}
                      key={metatag}
                    />
                  );
                })}
              </DrawerBodySectionContent>
            ))}
          </DrawerBodyContent>
        </DrawerBody>
        <DrawerFooter>
          <ActionsBar
            onReset={onReset}
            onApply={formik.handleSubmit}
            onCancel={onClose}
            applyButtonType="submit"
          />
        </DrawerFooter>
      </Drawer>
    </>
  );
};
