import React, { useEffect, useState } from 'react';
import Highcharts, { SeriesAreaOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

exporting(Highcharts);

interface StackedAreaChartProps {
  title: string;
  tooltipPrefix?: string;
  categories: string[];
  series: SeriesAreaOptions[];
  yAxis?: string;
  xAxis?: string;
}

const StackedAreaChart = (props: StackedAreaChartProps) => {
  const [series, setSeries] = useState<SeriesAreaOptions[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  // we need to keep data in the local state in order to to be able to update the chart when we fetch data from the server
  useEffect(() => {
    setSeries(props.series);
    // TODO: chart has incorrect width on initial render, resize fixes it
    // find out how to fix it without hack below
    window.dispatchEvent(new Event('resize'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.series]);

  useEffect(() => {
    setCategories(props.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.categories]);

  const options: Highcharts.Options = {
    chart: {
      zooming: { type: 'x' },
      type: 'area',
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: props.title,
    },
    accessibility: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: props.yAxis ?? undefined,
      },
    },
    xAxis: {
      categories,
      tickmarkPlacement: 'on',
      title: {
        text: props.xAxis ?? undefined,
      },
    },
    tooltip: {
      split: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#666666',
        },
      },
    },
    series,
    exporting: {
      enabled: true,
    },
  };

  return (
    <Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Box>
  );
};

export default StackedAreaChart;
