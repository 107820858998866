import React from 'react';
import Card from '@mui/material/Card';
import { Button, CardActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardContent from '@mui/material/CardContent';
import map from 'lodash/map';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import pullAt from 'lodash/pullAt';

import PagesCombobox from '../../../components/Formik/comboboxes-with-entities/PagesCombobox';
import { AnyObject, IPageSettingViewConfigItem } from '../../../modules/types';
import {
  privateRoutes,
  privateRoutesTabs,
} from '../../../config/privateRoutes';
import { MUI_SIZE_2 } from '../../../config';
import PageSettingPolicy from './PageSettingPolicy';
import Accordion from '../../../components/Accordion';

interface PageSettingsCardProps extends IPageSettingViewConfigItem {
  formikCardIndex: number;
  formik: AnyObject;
}

const PageSettingsCard: React.FC<PageSettingsCardProps> = ({
  formikCardIndex,
  formik,
  route,
  policies,
}) => {
  const { t } = useTranslation();

  const allRouts = React.useMemo(
    () => ({ ...privateRoutes, ...privateRoutesTabs }),
    [],
  );

  const cardTitle = React.useMemo(
    () =>
      allRouts[route]
        ? t(allRouts[route].title)
        : t('page_settings.select_page'),
    [allRouts, route, t],
  );

  const handleDelete = () => {
    const value = cloneDeep(formik.values);
    pullAt(value, formikCardIndex);
    formik.setValues(value);
  };

  const handleAdd = () => {
    const value = cloneDeep(get(formik.values, [formikCardIndex]));
    value.policies.push({
      id: null,
      resource: null,
      action: null,
      clientId: null,
    });
    formik.setFieldValue(formikCardIndex, value);
  };

  return (
    <React.Fragment>
      <Accordion cardTitle={cardTitle} fullWidth>
        <Card variant="outlined" sx={{ marginBottom: MUI_SIZE_2 }}>
          <CardContent>
            <PagesCombobox
              sx={{ marginBottom: MUI_SIZE_2 }}
              required
              id={`${formikCardIndex}.route`}
              name={`${formikCardIndex}.route`}
              placeholder={t('page_settings.page')}
              formik={formik}
            />

            {map(policies, (policy, policyIndex) => (
              <PageSettingPolicy
                key={`${policyIndex}${policy.resource}${policy.action}`}
                formik={formik}
                formikCardIndex={formikCardIndex}
                policyIndex={policyIndex}
              />
            ))}
          </CardContent>
          <CardActions>
            <Button variant="outlined" color="error" onClick={handleDelete}>
              {t('common.remove')}
            </Button>
            <Button variant="outlined" onClick={handleAdd}>
              {t('page_settings.add_policy')}
            </Button>
          </CardActions>
        </Card>
      </Accordion>
    </React.Fragment>
  );
};

export default PageSettingsCard;
