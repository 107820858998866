import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import {
  format,
  composeDate,
  DATE_FORMAT,
  tryToGenerateDateFromAnything,
} from '../../../../../modules/utils/dateWrapper';
import { FormControl, FormLabel, Typography } from '@mui/joy';
import {
  usePickersSlots,
  usePickersSlotsProps,
  usePickersSx,
} from '../pickers.hooks';
import { isValid } from 'date-fns';

export interface IDatePickerProps
  extends Omit<
    DatePickerProps<Date | string | undefined>,
    'onChange' | 'value'
  > {
  id: string;
  label: string;
  onChange: (date: string) => void;
  value?: string | null;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  helperText?: React.ReactNode;
  size?: number;
  parseDate?(date: any): Date | null;
}

export default function Datepicker({
  value,
  onChange,
  label,
  helperText,
  minDate,
  maxDate,
  fullWidth,
  required,
  error,
  variant,
  id,
  size = 40,
  slotProps,
  parseDate,
  ...rest
}: IDatePickerProps) {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const sx = usePickersSx(size, error);
  const slots = usePickersSlots();
  const propsForSlot = usePickersSlotsProps({
    id,
    helperText,
    fullWidth,
    required,
    error,
    variant,
    slotProps,
  });

  // Update selectedDate after props.value has changed
  useEffect(() => {
    if (
      value !==
      (selectedDate && isValid(selectedDate)
        ? composeDate(selectedDate, format(DATE_FORMAT))
        : selectedDate)
    ) {
      const dateObj = parseDate
        ? parseDate(value)
        : tryToGenerateDateFromAnything(value);

      setSelectedDate(dateObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // select data
  const handleDateChange = (value: Date | string | null | undefined) => {
    const dateObj = tryToGenerateDateFromAnything(value);
    let formattedDate = null;

    if (dateObj) {
      formattedDate = composeDate(dateObj, format(DATE_FORMAT));
      setSelectedDate(dateObj);
    }

    onChange(formattedDate);
  };

  return (
    <FormControl>
      <FormLabel>
        <Typography
          level="text_sm"
          fontWeight="medium"
          textColor="colors.text.text_secondary.main"
        >
          {label}
        </Typography>
      </FormLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          {...rest}
          sx={sx}
          slots={slots}
          minDate={minDate}
          maxDate={maxDate}
          value={selectedDate}
          format={DATE_FORMAT}
          slotProps={propsForSlot}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
