import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  IAreaBasedBaselineModel,
  ISagaAction,
  ICreateAreaBasedBaseline,
  IUpdateAreaBasedBaseline,
  IFilter,
  IdsArray,
  ICountResponse,
  ISagaActionBind,
  PayloadWithNavigateFunc,
  IAreaBasedBaselineWithRelations,
} from '../../types';
import { paths } from '../../../config';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

export const createAreaBasedBaselineRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateAreaBasedBaseline>
>): SagaIterator {
  try {
    const response: IAreaBasedBaselineModel = yield call(
      Api.Baseline.createAreaBased,
      data,
    );
    yield put(actions.createAreaBasedBaselineSuccess(response));
    yield call(navigate, {
      pathname: paths.AREA_BASED_BASELINE,
    });
  } catch (e) {
    yield put(actions.createAreaBasedBaselineFailed());
  }
};

export const deleteAreaBasedBaselineRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.Baseline.deleteAreaBased, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushAreaBasedBaselineState());
  } catch (e) {
    yield put(actions.deleteAreaBasedBaselineFailed());
  }
};

export const updateAreaBasedBaselinesRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<IUpdateAreaBasedBaseline[]>
>): SagaIterator {
  try {
    yield call(Api.Baseline.bulkUpdateAreaBased, data);
    yield put(actions.updateAreaBasedBaselinesSuccess());
    yield call(navigate, {
      pathname: paths.AREA_BASED_BASELINE,
    });
  } catch (e) {
    yield put(actions.updateAreaBasedBaselinesFailed());
  }
};

export const getAreaBasedBaselineListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IAreaBasedBaselineWithRelations[] = yield call(
      Api.Baseline.listAreaBased,
      action.payload,
    );
    yield put(actions.getAreaBasedBaselineListSuccess(response));
  } catch (e) {
    yield put(actions.getAreaBasedBaselineListFailed());
  }
};

export const getAreaBasedBaselineCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.Baseline.countAreaBased,
      action?.payload,
    );
    yield put(actions.getAreaBasedBaselineCountSuccess(response));
  } catch (e) {
    yield put(actions.getAreaBasedBaselineCountFailed());
  }
};

export const {
  exportRequestSaga: exportAreaBasedBaselineRequestSaga,
} = new ExportSagaCreator({
  apiCall: Api.Baseline.exportAreaBased,
  actionFailed: actions.exportAreaBasedBaselineFailed,
  actionSuccess: actions.exportAreaBasedBaselineSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createAreaBasedBaselineRequest,
    saga: createAreaBasedBaselineRequestSaga,
  },
  {
    action: actions.deleteAreaBasedBaselineRequest,
    saga: deleteAreaBasedBaselineRequestSaga,
  },
  {
    action: actions.updateAreaBasedBaselinesRequest,
    saga: updateAreaBasedBaselinesRequestSaga,
  },
  {
    action: actions.getAreaBasedBaselineListRequest,
    saga: getAreaBasedBaselineListRequestSaga,
  },
  {
    action: actions.getAreaBasedBaselineCountRequest,
    saga: getAreaBasedBaselineCountRequestSaga,
  },
  {
    action: actions.exportAreaBasedBaselineRequest,
    saga: exportAreaBasedBaselineRequestSaga,
  },
];
