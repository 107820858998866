import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  exportLogtimeApprovedRequest,
  getLogtimeApprovedCountRequest,
  getLogtimeApprovedListRequest,
  unapproveLogtimesRequest,
} from '../../../../../modules/actions';
import {
  getLogtimeApprovedCount,
  getLogtimeApprovedTable,
  getLogtimeRefreshKey,
  isLogtimesDataLoading,
} from '../../../../../modules/selectors/logtime';
import { AnyObject, IdsArray } from '../../../../../modules/types';
import {
  parse,
  format,
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
} from '../../../../../modules/utils/dateWrapper';
import { TimePunchesTable } from '../../TimePunchesTable';
import { XSquareSvg } from 'src/components/svgIcons';
import { SCOPES, useHasUserAccessToResource } from 'src/config';
import { apiEndpoints } from 'src/config/apiEndpoints';

const HEAD_CELLS_HIDDEN_BY_DEFAULT = [
  'approvedAt',
  'approvedBy',
  'editUser',
  'updatedAt',
];

export const TimePunchesApprovedComponent = () => {
  const { t } = useTranslation();

  const dispatcher = useDispatch();

  const hasUserAccessToUnapproveTimePunch = useHasUserAccessToResource(
    apiEndpoints.LOGTIMES,
    SCOPES.UPDATE,
  );

  const handleUnapprove = (ids: IdsArray) => {
    dispatcher(unapproveLogtimesRequest(ids));
  };

  const handleComputedList = (item: AnyObject) => ({
    approvedAt: item.approvedAt
      ? composeDate(
          item.approvedAt,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        )
      : '',
    approvedBy: item.approvedUser?.id
      ? `${item.approvedUser.firstName} ${item.approvedUser.lastName} (${item.approvedUser.email})`
      : '',
  });

  return (
    <TimePunchesTable
      tableName={t('logtimes.table_name')}
      listAction={getLogtimeApprovedListRequest}
      countAction={getLogtimeApprovedCountRequest}
      tableSelector={getLogtimeApprovedTable}
      countSelector={getLogtimeApprovedCount}
      isTableDataLoadingSelector={isLogtimesDataLoading}
      exportFunction={exportLogtimeApprovedRequest}
      customAction={
        hasUserAccessToUnapproveTimePunch
          ? {
              title: t('common.unapprove'),
              icon: <XSquareSvg />,
              onClick: handleUnapprove,
            }
          : undefined
      }
      headCells={[
        {
          id: 'approvedAt',
          disablePadding: false,
          label: t('logtimes.approvedAt'),
        },
        {
          id: 'approvedBy',
          disablePadding: false,
          disableSorting: true,
          label: t('logtimes.approvedBy'),
        },
      ]}
      handleComputedList={handleComputedList}
      hiddenColumns={HEAD_CELLS_HIDDEN_BY_DEFAULT}
    />
  );
};

export const TimePunchesApproved = () => {
  return (
    <TimePunchesApprovedComponent key={useSelector(getLogtimeRefreshKey)} />
  );
};
