import React from 'react';
import { chain } from 'lodash';
import { SeriesLineOptions } from 'highcharts';
import { useActiveTopSitesOptions } from 'src/pages/Dashboard/panels/FinancialReports/financialReports.helpers';
import { useChartRequiredUtils } from 'src/components/Charts/common';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerToolbar,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { SelectForChart } from 'src/components/SelectForChart';
import { OptionForChart } from 'src/components/SelectForChart/OptionForChart';
import { LineChart } from 'src/components/Charts/2d/LineChart';
import { dashboardChartHeight } from 'src/pages/TurnoverDashboard/turnover.constants';

interface ITurnoverDashboardChartProps {
  title: string;
  categories: string[];
  series: SeriesLineOptions[];
  showNoData?: boolean;
  isLoading?: boolean;
}

export const TurnoverDashboardChart: React.FC<ITurnoverDashboardChartProps> = ({
  title,
  categories,
  series,
  isLoading,
  showNoData = true,
}) => {
  const activeTopSitesOptions = useActiveTopSitesOptions();

  const { chartRef, exportChart, toggleFullScreen } = useChartRequiredUtils();

  const [activeTopSites, setActiveTopSites] = React.useState<
    'top3' | 'top10' | 'all'
  >(activeTopSitesOptions[0].id);

  const limit = React.useMemo(() => {
    switch (activeTopSites) {
      case 'all':
        return -1;
      case 'top10':
        return 10;
      case 'top3':
      default:
        return 3;
    }
  }, [activeTopSites]);

  const computedSeries = React.useMemo(
    () => {
      let v = chain(series).orderBy((item) => {
        return item.data?.reduce<number>((acc: number, cur: any) => {
          acc += cur;
          return acc;
        }, 0);
      }, 'desc');

      if (activeTopSites !== 'all') {
        v = v.take(limit);
      }

      return v.value();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [series, activeTopSites],
  );

  return (
    <ChartContainer>
      <ChartContainerHeader title={title}>
        <ChartContainerToolbar>
          <ChartContainerUtils
            onToggleFullScreen={toggleFullScreen}
            onExport={() => exportChart(title)}
          />

          <SelectForChart
            value={activeTopSites}
            onChange={(_, value) => {
              if (value) {
                setActiveTopSites(value);
              }
            }}
          >
            {activeTopSitesOptions.map((option) => (
              <OptionForChart key={option.id} value={option.id}>
                {option.label}
              </OptionForChart>
            ))}
          </SelectForChart>
        </ChartContainerToolbar>
      </ChartContainerHeader>
      <ChartContainerBody isLoading={isLoading} showNoData={showNoData}>
        <LineChart
          ref={chartRef}
          height={dashboardChartHeight}
          categories={categories}
          series={computedSeries}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
