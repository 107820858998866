import React, { useRef } from 'react';
import SignatureCanvas, {
  ReactSignatureCanvasProps,
} from 'react-signature-canvas';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Typography,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';

export interface ISignatureFieldProps
  extends Omit<ReactSignatureCanvasProps, 'onEnd'> {
  label?: string;
  helperText?: string;
  helperTextProps?: Omit<FormHelperTextProps, 'children'>;
  labelProps?: Omit<FormLabelProps, 'label'>;
  error?: boolean;
  onClear?: (canvasData: SignatureCanvas) => void;
  onSave?: (dataUrl: string, canvasData: SignatureCanvas) => void;
  onEnd?: (dataUrl: string, canvasData: SignatureCanvas) => void;
}

export const SignatureField = ({
  label,
  helperText,
  helperTextProps,
  labelProps,
  error,
  onClear,
  onSave,
  onEnd,
  ...canvasProps
}: ISignatureFieldProps) => {
  const { t } = useTranslation();

  const canvasRef = useRef<SignatureCanvas | null>(null);

  const clear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();

      onClear && onClear(canvasRef.current);
    }
  };

  const save = async () => {
    if (canvasRef.current && onSave) {
      const dataUrl = canvasRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');

      onSave(dataUrl, canvasRef.current);
    }
  };

  const handleEnd = () => {
    if (canvasRef.current && onEnd) {
      const dataUrl = canvasRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');

      onEnd(dataUrl, canvasRef.current);
    }
  };

  return (
    <FormControl error={error}>
      {label && (
        <FormLabel {...labelProps}>
          <Typography
            level="text_sm"
            fontWeight="medium"
            textColor="colors.text.text_secondary.main"
          >
            {label}
          </Typography>
        </FormLabel>
      )}
      <Box
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 8,
          p: 0.5,
          borderColor: 'colors.border.border_primary',
        }}
      >
        <Box>
          <SignatureCanvas
            penColor="black"
            onEnd={handleEnd}
            canvasProps={{
              height: 400,
              style: {
                width: '100%',
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 8,
                borderColor:
                  'var(--ngroup-palette-colors-border-border_secondary)',
              },
            }}
            ref={canvasRef}
            {...canvasProps}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: '-2px',
          }}
        >
          <Button
            sx={{ fontWeight: 100 }}
            variant="plain"
            size="sm"
            color="neutral"
            onClick={clear}
          >
            {t('common.clear')}
          </Button>

          {onSave && (
            <Button
              sx={{ fontWeight: 100 }}
              variant="plain"
              size="sm"
              onClick={save}
            >
              {t('common.save')}
            </Button>
          )}
        </Box>

        {helperText && (
          <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};
