import { createReducer } from 'redux-act';
import * as uuid from 'uuid';
import * as actions from '../../actions';
import {
  IProcessStatusModel,
  IProcessStatusState,
} from '../../types/processStatus';

const defaultState: IProcessStatusState = {
  list: [],
};

const addProcessStatus = (
  state: IProcessStatusState,
  processStatus: IProcessStatusModel,
) => {
  const key = uuid.v4();

  return {
    ...state,
    list: [...state.list, { ...processStatus, key }],
  };
};

const removeProcessStatus = (
  state: IProcessStatusState,
  processStatusIndex: string,
) => ({
  ...state,
  list: state.list.filter(({ key }) => key !== processStatusIndex),
});

export const processStatus = createReducer<IProcessStatusState>(
  {},
  defaultState,
)
  .on(actions.addProcessStatus, addProcessStatus)
  .on(actions.removeProcessStatus, removeProcessStatus);

export default processStatus;
