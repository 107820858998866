import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
  PulseSummary,
} from '../../modules/types';
import { ReportTable } from '../../components/ReportPage/ReportTable';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import {
  composeDate,
  DATETIME_FORMAT_TO_SHOW,
  DATETIME_TIMEZONE,
  format,
  parse,
} from '../../modules/utils/dateWrapper';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  summaryRow?: PulseSummary;
}

export default function PulseDetails({ summaryRow }: IProps) {
  const { t } = useTranslation();

  const reportUrl = `/optiturn/pulse/detailed/${summaryRow?.id}`;

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'timestamp',
  };

  const where = React.useMemo(
    () => ({
      siteId: summaryRow?.siteId,
    }),
    [summaryRow?.siteId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportUrl}/count`, {
    count: 0,
  });

  const computedItems = React.useMemo(
    () =>
      data.items.map((item) => ({
        ...item,
        timestamp: composeDate(
          item.timestamp,
          parse(DATETIME_TIMEZONE),
          format(DATETIME_FORMAT_TO_SHOW),
        ),
      })),
    [data.items],
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('pulse_detailed.siteName'),
      },
      {
        id: 'categoryName',
        orderConfig: { orderBy: 'categoryName' },
        label: t('pulse_detailed.categoryName'),
      },
      {
        id: 'subCategoryName',
        orderConfig: { orderBy: 'subCategoryName' },
        label: t('pulse_detailed.subCategoryName'),
      },
      {
        id: 'timestamp',
        orderConfig: { orderBy: 'timestamp' },
        label: t('pulse_detailed.timestamp'),
      },
      {
        id: 'diff',
        orderConfig: { orderBy: 'diff' },
        label: t('pulse_detailed.diff'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <>
      <ReportTable
        fullHeight={false}
        data={computedItems as any}
        count={countData.count}
        summaryData={data.summary}
        isDataLoading={isDataLoading || isCountDataLoading}
        tableUniqueKey={reportUrl}
        headCells={headCells}
        order={order}
        orderBy={orderBy}
        rowsPerPage={limit}
        currentPageNumber={page}
        onSort={handleSort}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={(_, page) => handlePageChange(page)}
      />
    </>
  );
}
