import { useQuery } from 'react-query';
import { find, get, isEmpty, map, omit, reduce } from 'lodash';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';
import {
  IKpi,
  IKpiDataGridRow,
  IKpiYearMonthOrWeekRange,
  KpiBasis,
  KpiSettingsConfig,
} from '../../types';
import { paths, useHasUserAccessToAction } from '../../../config';
import { useKpiSettings } from './kpi-settings';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';

export enum KpiRating {
  red = 'MuiDataGrid-cell--red',
  green = 'MuiDataGrid-cell--green',
  gray = 'MuiDataGrid-cell--gray',
}

const getFetchParameters = (
  siteId?: number,
  basis?: KpiBasis,
  yearWeekOrMonthRange?: IKpiYearMonthOrWeekRange,
  historical?: boolean,
) => {
  const filter = {
    filter: {
      where: {
        ...(!historical ? { archivedAt: null } : {}),
        siteId: { eq: siteId },
        [basis === 'week' ? 'yearWeek' : 'yearMonth']: {
          between: yearWeekOrMonthRange,
        },
      },
    },
  };
  const queryKey = getQueryKey(paths.KPI, filter);

  return { filter, queryKey };
};

export const useKpiColumns = (
  settings: KpiSettingsConfig,
  basis?: KpiBasis,
) => {
  const { t } = useTranslation();

  const hasUserAccessToCreateKpi = useHasUserAccessToAction(
    manageEntitiesConfig.kpi.create.id,
  );
  const hasUserAccessToEditKpi = useHasUserAccessToAction(
    manageEntitiesConfig.kpi.create.id,
  );

  const columns: GridColDef[] = [
    {
      field: basis === 'week' ? `yearWeekToShow` : `yearMonthToShow`,
      headerName: basis === 'week' ? t('kpi.yearWeek') : t('kpi.yearMonth'),
      editable: false,
      flex: 1,
      type: 'string',
      minWidth: 150,
    },
    ...map(settings, ({ name, title }) => ({
      field: name!,
      headerName: title!,
      editable: hasUserAccessToCreateKpi && hasUserAccessToEditKpi,
      flex: 1,
      type: 'number',
      minWidth: 150,
      cellClassName: ({
        field,
        value,
        ...rest
      }: GridCellParams<any, number>) => {
        const setting = settings.find((i) => i.name === field);

        if (!setting || rest.id === 'threshold') {
          return '';
        }

        if (value === null) {
          return KpiRating.gray;
        }

        switch (setting.thresholdType) {
          case 'gt':
            return Number(value) > setting.threshold
              ? KpiRating.green
              : KpiRating.red;
          case 'gte':
            return Number(value) >= setting.threshold
              ? KpiRating.green
              : KpiRating.red;
          case 'lt':
            return Number(value) < setting.threshold
              ? KpiRating.green
              : KpiRating.red;
          case 'lte':
            return Number(value) <= setting.threshold
              ? KpiRating.green
              : KpiRating.red;
        }

        return '';
      },
    })),
  ];

  return columns;
};

export const useKpiDataTable = (
  siteId?: number,
  basis?: KpiBasis,
  yearWeekOrMonthRange?: IKpiYearMonthOrWeekRange,
  historical?: boolean,
  mode: 'table' | 'report' = 'table',
) => {
  const { filter, queryKey } = getFetchParameters(
    siteId,
    basis,
    yearWeekOrMonthRange,
    historical,
  );

  const { data: payload, isLoading, error } = useQuery(
    queryKey,
    () =>
      Api.Kpi[mode === 'table' ? 'getDataTable' : 'getDataReport'](
        filter,
        basis ?? 'week',
        get(yearWeekOrMonthRange, [0], 0),
        get(yearWeekOrMonthRange, [1], 0),
      ),
    {
      enabled: Boolean(siteId && basis && !isEmpty(yearWeekOrMonthRange)),
      retry: false,
      cacheTime: 0,
    },
  );

  return {
    payload,
    isLoading,
    error,
  };
};

export const useKpiDataGrid = (
  siteId?: number,
  basis?: KpiBasis,
  yearWeekOrMonthRange?: IKpiYearMonthOrWeekRange,
  historical?: boolean,
  mode: 'table' | 'report' = 'table',
) => {
  const { payload, isLoading: isDataLoading } = useKpiDataTable(
    siteId,
    basis,
    yearWeekOrMonthRange,
    historical,
    mode,
  );

  const { settings, isLoading: isSettingsLoading } = useKpiSettings(siteId);

  const columns = useKpiColumns(settings, basis);

  return {
    rows: payload ?? [],
    columns,
    loading: isDataLoading || isSettingsLoading,
  };
};

export const useKpiCreateOrUpdate = (siteId: number, basis: KpiBasis) => {
  const { settings } = useKpiSettings(siteId);

  const convertKpiRowToData = (siteId: number, row: IKpiDataGridRow): IKpi => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      id,
      yearWeek,
      yearWeekToShow,
      yearMonth,
      yearMonthToShow,
      serverId,
      ...data
    } = row;

    return {
      id: serverId,
      siteId: +siteId,
      ...(basis === 'week'
        ? {
            yearWeek: +yearWeek!,
          }
        : {
            yearMonth: +yearMonth!,
          }),
      data: reduce(
        data,
        (acc, cur, key) => {
          const setting = find(settings, (s) => s.name === key);

          if (setting) {
            acc[key] = {
              title: setting.title,
              value: cur,
            };
          }

          return acc;
        },
        {},
      ),
    };
  };

  // submit function
  const sendToServer = async (row: IKpiDataGridRow): Promise<IKpi> => {
    const dataToSend = convertKpiRowToData(siteId, row);
    if (dataToSend.id) {
      return Api.Kpi.updateData(dataToSend);
    } else {
      return Api.Kpi.createData(siteId, omit(dataToSend, ['id']));
    }
  };

  return { sendToServer };
};
