import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Alert } from '@mui/joy';
import {
  usePayboardSummaryByWorkWeek,
  usePayboardSummaryByWorkWeekTableFiltersConfiguration,
} from 'src/modules/utils';
import { IFilterWhere } from 'src/modules/types';
import { ReportTable } from 'src/components/ReportPage/ReportTable';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { TableActionsExport } from 'src/components/Table/components/TableActions/components/TableActionsExport';
import { useReportExportProps } from 'src/modules/utils/hooks/reports.hooks';
import { getGloballySelectedSites } from 'src/modules/selectors/site';
import { MUI_SIZE_12 } from 'src/config';
import { PayboardSummaryByWorkWeekFilter } from 'src/pages/TimeKeeping/Payboard/panels/PayboardSummaryByWorkWeek/components/PayboardSummaryByWorkWeekFilter';
import { useFilterFieldsData } from '../../../../../modules/utils/hooks/table';
import { ReportPage } from '../../../../../components/ReportPage';

const reportUrl = '/payboard/daily-summary-by-work-week';

export const PayboardSummaryByWorkWeek: React.FC = () => {
  const { t } = useTranslation();

  const exportProps = useReportExportProps(reportUrl, true);

  const selectedSites = useSelector(getGloballySelectedSites, shallowEqual);

  const selectedSite = React.useMemo(
    () => (selectedSites.length === 1 ? selectedSites[0] : undefined),
    [selectedSites],
  );

  const filterFieldsConfiguration = usePayboardSummaryByWorkWeekTableFiltersConfiguration();

  const {
    getLabel,
    filterFields,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({
    filterFieldsConfiguration,
  });

  const where = React.useMemo<IFilterWhere>(
    () => ({
      siteId: selectedSite,
      ...(filterFields?.firstName?.value
        ? {
            firstName: {
              like: `%${filterFields?.firstName?.value}%`,
            },
          }
        : {}),
      ...(filterFields?.lastName?.value
        ? {
            lastName: {
              like: `%${filterFields?.lastName?.value}%`,
            },
          }
        : {}),
    }),
    [selectedSite, filterFields.firstName, filterFields.lastName],
  );

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
    data,
    isDataLoading,
    requestFilters,
    headCells,
    orderBy,
    page,
    order,
    limit,
  } = usePayboardSummaryByWorkWeek(where, reportUrl);

  return (
    <ReportPage hideNoData disableHorizontalPadding>
      <TableToolbar>
        <TableTitle>{t('main_menu.payboard_summary_by_work_week')}</TableTitle>

        <TableActions>
          <PayboardSummaryByWorkWeekFilter
            siteId={selectedSite}
            getLabel={getLabel}
            onFiltersFormSubmit={onFiltersFormSubmit}
            getFilterCommonPropsByFilterName={getFilterCommonPropsByFilterName}
          />

          <SelectHeadCells />

          <TableActionsExport
            requestFilters={requestFilters}
            exportBtnContainerProps={{ ml: 3 }}
            {...exportProps}
          />
        </TableActions>
      </TableToolbar>

      <>
        {!selectedSite && (
          <Grid container={true}>
            <Grid item={true} xs={MUI_SIZE_12}>
              <Alert variant="soft">
                {t(
                  'payboard.summary_by_work_and_supervisor.select_only_one_site',
                )}
              </Alert>
            </Grid>
          </Grid>
        )}
        {selectedSite && (
          <ReportTable
            heightWithTabs
            data={data as any}
            count={data.length}
            isDataLoading={isDataLoading}
            tableUniqueKey={reportUrl}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            rowsPerPage={limit}
            currentPageNumber={page}
            onSort={handleSort}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={(_, page) => handlePageChange(page)}
          />
        )}
      </>
    </ReportPage>
  );
};
