import * as React from 'react';
import { ChevronDownSvg } from 'src/components/svgIcons';
import { Autocomplete, AutocompleteProps, useTheme, Box } from '@mui/joy';
import { getSlotProps, renderOption, size } from './doubledComboboxCommonProps';

export const LeftCombobox = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  minWidth = 60,
  width,
  ...props
}: Omit<
  AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
> & {
  minWidth?: number;
  width?: number;
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        size={size}
        renderOption={renderOption}
        popupIcon={props.popupIcon ?? <ChevronDownSvg />}
        slotProps={getSlotProps({ width, minWidth, theme })}
        {...props}
      />
    </Box>
  );
};
