// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { HeadCell } from '../../components/EnhancedTable';
// import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
// import ReportWithOwnState from '../../components/Reports/ReportWithOwnState';
// import { useSelector } from 'react-redux';
// import { getGloballySelectedSites } from '../../modules/selectors/site';
// import { Grid } from '@mui/material';
// import { MUI_SIZE_12 } from '../../config';
// import { Alert } from '../../components/Alert';

// export const DailyUphByDepartment = () => {
//   const { t } = useTranslation();
//   const globallySelectedSites = useSelector(getGloballySelectedSites);
//   // to fetch data for the specific site only
//   const isSiteSelected = globallySelectedSites.length === 1;

//   // compose table header cells
//   const headCells: HeadCell[] = [
//     {
//       id: 'date',
//       disablePadding: false,
//       label: t('productions_uph_reports.date'),
//     },
//     {
//       id: 'siteName',
//       disablePadding: false,
//       label: t('productions_uph_reports.site.name'),
//     },
//     {
//       id: 'Department',
//       disablePadding: false,
//       label: t('productions_uph_reports.department'),
//     },
//     {
//       id: 'qty',
//       disablePadding: false,
//       label: t('productions_uph_reports.qty'),
//     },
//     {
//       id: 'hrs',
//       disablePadding: false,
//       label: t('productions_uph_reports.hrs'),
//     },
//     {
//       id: 'uph',
//       disablePadding: false,
//       label: t('productions_uph_reports.uph'),
//     },
//   ];

//   // table filters
//   const filters: ITableFilter[] = [
//     {
//       name: 'date',
//       label: t('productions_uph_reports.date'),
//       type: 'date',
//       operator: 'eq',
//     },
//     {
//       name: 'Department',
//       label: t('productions_uph_reports.department'),
//       type: 'text',
//       operator: 'like',
//     },
//   ];

//   return (
//     <>
//       {!isSiteSelected && (
//         <Grid container={true}>
//           <Grid item={true} xs={MUI_SIZE_12}>
//             <Alert variant="outlined" severity="info">
//               {t('reports.select_global_site')}
//             </Alert>
//           </Grid>
//         </Grid>
//       )}
//       {isSiteSelected && (
//         <ReportWithOwnState
//           defaultOrderBy="date"
//           reportUrl="/perry-ellis/daily-uph/by-site-and-department"
//           tableName={t(
//             'perry_ellis_reports.table_names.daily_uph_by_site_and_department',
//           )}
//           headCells={headCells}
//           filters={filters}
//           reportType="daily"
//         />
//       )}
//     </>
//   );
// };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/joy';
import { MUI_SIZE_12 } from '../../config';
import { Alert } from '@mui/joy';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { AnyObject, ICountResponse, IListWithSummary } from 'src/modules/types';
import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from 'src/modules/utils/hooks/table';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';
import { ReportTable } from 'src/components/ReportPage/ReportTable';

interface IDailyUphByDepartmentProps {
  date?: string;
  department?: string;
  siteId?: number;
}

const reportDataUrl = '/perry-ellis/daily-uph/by-site-and-department';

const defaultOrderDetails = {
  order: 'desc' as const,
  orderBy: 'date',
};

export const DailyUphByDepartment = ({
  date,
  department,
  siteId,
}: IDailyUphByDepartmentProps) => {
  const { t } = useTranslation();
  // to fetch data for the specific site only
  const isSiteSelected = Boolean(siteId);

  const where = React.useMemo(
    () => ({
      date: date ?? undefined,
      siteId: siteId ?? undefined,
      Department: department ?? undefined,
    }),
    [date, department, siteId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(reportDataUrl, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(`${reportDataUrl}/count`, {
    count: 0,
  });

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      // {
      //       id: 'date',
      //       disablePadding: false,
      //       label: t('productions_uph_reports.date'),
      //     },
      //     {
      //       id: 'siteName',
      //       disablePadding: false,
      //       label: t('productions_uph_reports.site.name'),
      //     },
      //     {
      //       id: 'Department',
      //       disablePadding: false,
      //       label: t('productions_uph_reports.department'),
      //     },
      //     {
      //       id: 'qty',
      //       disablePadding: false,
      //       label: t('productions_uph_reports.qty'),
      //     },
      //     {
      //       id: 'hrs',
      //       disablePadding: false,
      //       label: t('productions_uph_reports.hrs'),
      //     },
      //     {
      //       id: 'uph',
      //       disablePadding: false,
      //       label: t('productions_uph_reports.uph'),
      //     },
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('productions_uph_reports.date'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'Department',
        orderConfig: { orderBy: 'Department' },
        label: t('productions_uph_reports.department'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'hrs',
        orderConfig: { orderBy: 'hrs' },
        label: t('productions_uph_reports.hrs'),
      },
      {
        id: 'uph',
        orderConfig: { orderBy: 'uph' },
        label: t('productions_uph_reports.uph'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  React.useEffect(() => {
    if (siteId) {
      fetchData({
        filter: {
          where,
          limit,
          offset: page,
          order: orderBy && order ? [`${orderBy} ${order}`] : undefined,
        },
      });

      fetchCountData({ filter: { where } });
    }
  }, [fetchCountData, fetchData, limit, order, orderBy, page, siteId, where]);

  return (
    <>
      {!isSiteSelected && (
        <Grid container={true}>
          <Grid xs={MUI_SIZE_12}>
            <Alert variant="soft">{t('reports.select_site_from_filter')}</Alert>
          </Grid>
        </Grid>
      )}
      {isSiteSelected && (
        <ReportTable
          data={data.items as any}
          count={countData.count}
          summaryData={data.summary}
          isDataLoading={isDataLoading || isCountDataLoading}
          tableUniqueKey={reportDataUrl}
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          currentPageNumber={page}
          onSort={handleSort}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={(_, page) => handlePageChange(page)}
        />
      )}
    </>
  );
};
