import React from 'react';
import {
  Grid,
  Button,
  Box,
  Input,
  Typography,
  FormHelperText,
} from '@mui/material';
import { CSV_MIME_TYPE } from '../../config';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

interface IProductionDataUploaderProps {
  file: File | null;
  onChange: (file: File | null) => void;
  errorMessage?: string;
}

export default function ProductionDataUploader({
  onChange,
  errorMessage,
  file,
}: IProductionDataUploaderProps) {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = [...event.target.files!];

    onChange(file);
  };

  return (
    <Grid container={true}>
      <Grid
        sx={{
          width: '100%',
          // '& > *': {
          //   display: 'inline-block',
          marginTop: 1,
          // },
        }}
        item={true}
      >
        <Button
          sx={() => ({
            width: '100%',
            textTransform: 'none',
            borderRadius: 0,
            borderBottom: errorMessage
              ? '1px solid #f44336'
              : '1px solid rgba(0, 0, 0, 0.24)',
          })}
          component="label"
        >
          <Input
            sx={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            value={''}
            onChange={handleChange}
            inputProps={{
              accept: [CSV_MIME_TYPE],
              multiple: false,
            }}
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography
              sx={{
                marginLeft: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                color: errorMessage
                  ? '#f44336'
                  : file?.name
                  ? 'rgba(0, 0, 0, 0.87)'
                  : 'rgba(0, 0, 0, 0.54)',
              }}
            >
              {file?.name || `${t('common.file')} *`}
            </Typography>
            <FontAwesomeIcon color="gray" fontSize={18} icon={faFileUpload} />
          </Box>
        </Button>
        <FormHelperText error variant="filled">
          {errorMessage}
        </FormHelperText>
      </Grid>
    </Grid>
  );
}
