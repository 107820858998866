import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import { ICountResponse, IPositionModel, IPositionState } from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: IPositionState = {
  refreshKey: 0,
  id: NaN,
  name: '',
  siteId: NaN,
  list: [],
  comboboxList: [],
  count: 0,
};

/**
 * Flush actions
 */
const flushPositionState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createPositionRequest = (state: IPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createPositionSuccess = (
  state: IPositionState,
  payload: IPositionModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createPositionFailed = (state: IPositionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getPositionListRequest = (state: IPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPositionListSuccess = (
  state: IPositionState,
  payload: IPositionModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getPositionListFailed = (state: IPositionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getPositionCountRequest = (state: IPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPositionCountSuccess = (
  state: IPositionState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getPositionCountFailed = (state: IPositionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deletePositionRequest = (state: IPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deletePositionFailed = (state: IPositionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updatePositionsRequest = (state: IPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePositionsFailed = (state: IPositionState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updatePositionsDesiredEmployeesNumberRequest = (
  state: IPositionState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePositionsDesiredEmployeesNumberFailed = (
  state: IPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getPositionComboboxListRequest = (state: IPositionState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPositionComboboxListSuccess = (
  state: IPositionState,
  payload: Partial<IPositionModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getPositionComboboxListFailed = (
  state: IPositionState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IPositionState>();

const exportPositionRequest = exportRequest;
const exportPositionSuccess = exportSuccess;
const exportPositionFailed = exportFailed;

/**
 * Position reducer
 */
export const position = createReducer<IPositionState>({}, defaultState)
  // flush actions
  .on(actions.flushPositionState, flushPositionState)
  // creation actions
  .on(actions.createPositionRequest, createPositionRequest)
  .on(actions.createPositionSuccess, createPositionSuccess)
  .on(actions.createPositionFailed, createPositionFailed)
  // get list actions
  .on(actions.getPositionListRequest, getPositionListRequest)
  .on(actions.getPositionListSuccess, getPositionListSuccess)
  .on(actions.getPositionListFailed, getPositionListFailed)
  // get count actions
  .on(actions.getPositionCountRequest, getPositionCountRequest)
  .on(actions.getPositionCountSuccess, getPositionCountSuccess)
  .on(actions.getPositionCountFailed, getPositionCountFailed)
  // deletion actions
  .on(actions.deletePositionRequest, deletePositionRequest)
  .on(actions.deletePositionFailed, deletePositionFailed)
  // updating actions
  .on(actions.updatePositionsRequest, updatePositionsRequest)
  .on(actions.updatePositionsFailed, updatePositionsFailed)
  // updating actions
  .on(
    actions.updatePositionsDesiredEmployeesNumberRequest,
    updatePositionsDesiredEmployeesNumberRequest,
  )
  .on(
    actions.updatePositionsDesiredEmployeesNumberFailed,
    updatePositionsDesiredEmployeesNumberFailed,
  )
  // get combobox list actions
  .on(actions.getPositionComboboxListRequest, getPositionComboboxListRequest)
  .on(actions.getPositionComboboxListSuccess, getPositionComboboxListSuccess)
  .on(actions.getPositionComboboxListFailed, getPositionComboboxListFailed)
  // export actions
  .on(actions.exportPositionRequest, exportPositionRequest)
  .on(actions.exportPositionSuccess, exportPositionSuccess)
  .on(actions.exportPositionFailed, exportPositionFailed);

export default position;
