import React, { useEffect, useState, useMemo } from 'react';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import more from 'highcharts/highcharts-more';
import solidgauge from 'highcharts/modules/solid-gauge';
import HighchartsReact from 'highcharts-react-official';
import { map, get } from 'lodash';
import exporting from 'highcharts/modules/exporting';
import { Box } from '@mui/material';

more(Highcharts);
solidgauge(Highcharts);
exporting(Highcharts);

interface IProps {
  title: string;
  series: SeriesOptionsType[];
}

const ActivityGaugeChart = (props: IProps) => {
  const [series, setSeries] = useState<SeriesOptionsType[]>([]);

  // we need to keep data in the local state in order to to be able to update the chart when we fetch data from the server
  useEffect(() => {
    setSeries(props.series);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.series]);

  React.useEffect(() => {
    // TODO: chart has incorrect width on initial render, resize fixes it
    // find out how to fix it without hack below
    window.dispatchEvent(new Event('resize'));
  }, []);

  const paneBg = useMemo(
    () =>
      map(series, (item) => {
        const color = get(item, ['data', 0, 'color']);
        const radius = get(item, ['data', 0, 'radius']);
        const innerRadius = get(item, ['data', 0, 'innerRadius']);
        return {
          // Track for Move
          outerRadius: radius,
          innerRadius: innerRadius,
          backgroundColor: Highcharts.color(color).setOpacity(0.3).get(),
          borderWidth: 0,
        };
      }),
    [series],
  );

  const options: Highcharts.Options = {
    chart: {
      type: 'solidgauge',
      style: {
        fontFamily: "Inter, 'sans serif'",
      },
    },
    title: {
      text: props.title ?? undefined,
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: 'none',
      shadow: false,
      style: {
        fontSize: '10px',
        textAlign: 'center',
      },
      valueSuffix: '%',
      pointFormat:
        '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
      positioner: function (labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.plotHeight / 2 + 15,
        };
      },
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: paneBg,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    series: series,
    exporting: {
      enabled: true,
    },
  };

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    </Box>
  );
};

export default ActivityGaugeChart;
