import React from 'react';
import { Typography, TypographyProps } from '@mui/joy';

interface IMainIndicatorsWidgetItemTextProps extends TypographyProps {
  children: string;
}

export const MainIndicatorsWidgetItemText = ({
  children,
  ...props
}: IMainIndicatorsWidgetItemTextProps) => {
  return (
    <Typography
      level="text_md"
      fontWeight="semi_bold"
      textColor="colors.text.text_primary.main"
      {...props}
    >
      {children}
    </Typography>
  );
};
