import React from 'react';
import { Box } from '@mui/joy';

export const FilterPanelDivider = () => {
  return (
    <Box
      sx={{
        minHeight: '18px',
        minWidth: '1px',
        bgcolor: 'colors.foreground.fg_senary',
      }}
    />
  );
};
