import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Sheet } from '@mui/joy';
import { useFormik } from 'formik';
import {
  useFetchActiveDepartmentsCombobox,
  useFetchShiftsCombobox,
  useValidate,
} from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { createLogtimeBulkRequest } from 'src/modules/actions';
import { guessTimezone } from 'src/modules/utils/dateWrapper';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { createLogtimesScheme } from 'src/modules/schemes/logtimes';
import {
  DepartmentComboboxOption,
  ICreateLogtimeRequest,
  IStoreState,
  IdsArray,
  ShiftComboboxOption,
} from 'src/modules/types';
import { getEmployeesByIds } from 'src/modules/selectors/employee';
import { getDepartmentsComboboxList } from 'src/modules/selectors/department';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';
import { filterListBySiteId } from 'src/modules/utils/helpers/common';
import { generateFullIdInMultiEntitiesForm } from 'src/modules/utils/helpers/form';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { PunchTypeComboBox } from 'src/pages/TimeKeeping/TimePunches/PunchTypeComboBox';
import { useLogtimeCreateFormTourConfig } from 'src/config/tours/logtime';
import FormikTextarea from 'src/components/Formik/FormikTextarea';

const initialEmployeeData = {
  badge: NaN,
  timeIn: null,
  timeOut: null,
  shiftId: undefined,
  departmentId: undefined,
  comment: '',
  timezone: guessTimezone(),
  punchType: 'regular' as const,
};

interface ICreateEmployeeProps {
  isOpen: boolean;
  onClose: () => void;
  employeesToLogTimeIds: IdsArray;
}

export const EmployeesLogTime = ({
  isOpen,
  onClose,
  employeesToLogTimeIds,
}: ICreateEmployeeProps) => {
  const { t } = useTranslation();

  const logtimeCreateConfigTour = useLogtimeCreateFormTourConfig();

  const dispatch = useDispatch();

  const fetchShiftsCombobox = useFetchShiftsCombobox();
  const fetchDepartmentsCombobox = useFetchActiveDepartmentsCombobox();

  const shifts = useSelector(getShiftsComboboxList);
  const departments = useSelector(getDepartmentsComboboxList);

  const employees = useSelector((state) =>
    getEmployeesByIds(state as IStoreState)(employeesToLogTimeIds),
  );

  const { initialData, comboboxesData, sectionsTitles } = React.useMemo(
    () =>
      employees.reduce<{
        initialData: Array<ICreateLogtimeRequest>;
        sectionsTitles: Array<string>;
        comboboxesData: Array<{
          shifts: Array<ShiftComboboxOption>;
          departments: Array<DepartmentComboboxOption>;
        }>;
      }>(
        (all, employee) => {
          all.sectionsTitles.push(`${employee.firstName} ${employee.lastName}`);
          all.initialData.push({
            ...initialEmployeeData,
            badge: employee.badge,
            employeeId: employee.id,
          });
          all.comboboxesData.push({
            shifts: filterListBySiteId(shifts, employee.siteId),
            departments: filterListBySiteId(departments, employee.siteId),
          });

          return all;
        },
        { initialData: [], comboboxesData: [], sectionsTitles: [] },
      ),
    [departments, employees, shifts],
  );

  const validate = useValidate(createLogtimesScheme);

  const formik = useFormik({
    initialValues: initialData,
    validate,
    enableReinitialize: true,
    onSubmit: (data) => {
      dispatch(
        createLogtimeBulkRequest({
          data: data.map((item) => ({
            ...item,
            punchType: item.punchType === 'regular' ? null : item.punchType,
          })),
        }),
      );

      onClose();
      formik.resetForm();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  React.useEffect(() => {
    fetchDepartmentsCombobox();
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      {isOpen && (
        <>
          <DrawerHeader onCloseClick={onClose}>
            {t('logtimes.create_form_card_title')}
          </DrawerHeader>
          <DrawerBody>
            <DrawerBodyContent>
              {formik.values.map((_, index) => (
                <Sheet key={index} sx={{ bgcolor: 'inherit' }}>
                  <DrawerBodySectionTitle>
                    {sectionsTitles[index]}
                  </DrawerBodySectionTitle>

                  <FormFieldContainer>
                    <FormikDatetimepicker
                      required
                      fullWidth
                      id={generateFullIdInMultiEntitiesForm('timeIn', index)}
                      formik={formik}
                      label={t('logtimes.login_datetime')}
                      data-tour={logtimeCreateConfigTour.tour.login.target}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikDatetimepicker
                      fullWidth
                      id={generateFullIdInMultiEntitiesForm('timeOut', index)}
                      formik={formik}
                      label={t('logtimes.logout_datetime')}
                      data-tour={logtimeCreateConfigTour.tour.logout.target}
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikCombobox
                      setToUndefinedOnFieldClear
                      id={generateFullIdInMultiEntitiesForm(
                        'departmentId',
                        index,
                      )}
                      formik={formik}
                      label={t('logtimes.department')}
                      placeholder={t('common.select')}
                      options={
                        comboboxesData.length &&
                        comboboxesData[index]?.departments
                          ? comboboxesData[index]?.departments
                          : []
                      }
                      data-tour={logtimeCreateConfigTour.tour.department.target}
                      data-testid="department"
                    />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <FormikCombobox
                      setToUndefinedOnFieldClear
                      id={generateFullIdInMultiEntitiesForm('shiftId', index)}
                      formik={formik}
                      label={t('logtimes.shift')}
                      options={
                        comboboxesData.length && comboboxesData[index]?.shifts
                          ? comboboxesData[index]?.shifts
                          : []
                      }
                      placeholder={t('common.select')}
                      data-tour={logtimeCreateConfigTour.tour.shift.target}
                    />
                  </FormFieldContainer>
                  <FormFieldContainer>
                    <PunchTypeComboBox
                      placeholder={t('logtimes.punch_type')}
                      formik={formik}
                      id={generateFullIdInMultiEntitiesForm('punchType', index)}
                    />
                  </FormFieldContainer>
                  <FormFieldContainer>
                    <FormikTextarea
                      id={generateFullIdInMultiEntitiesForm('comment', index)}
                      name={generateFullIdInMultiEntitiesForm('comment', index)}
                      label={t('logtimes.comment')}
                      formik={formik}
                      minRows={2}
                    />
                  </FormFieldContainer>
                </Sheet>
              ))}
            </DrawerBodyContent>
          </DrawerBody>
          <DrawerFooter>
            <ActionsBar
              onReset={onReset}
              onApply={formik.handleSubmit}
              onCancel={onClose}
            />
          </DrawerFooter>
        </>
      )}
    </Drawer>
  );
};
