import React from 'react';
import { useSlate } from 'slate-react';
import { Box, Paper } from '@mui/material';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import {
  toggleBlock,
  toggleMark,
  isMarkActive,
  addMarkData,
  isBlockActive,
  activeMark,
} from '../utils/SlateUtilityFunctions';
import useTable from '../utils/useTable';
import defaultToolbarGroups from './toolbarGroups';
import { buttonsLine } from './toolbarGroups';

import { Button, MaterialIcon } from '../components';
import { LinkButton } from '../components';
import Embed from '../Elements/Embed/Embed';
import Table from '../Elements/Table/Table';
import InTable from '../Elements/Table/InTable';

const Toolbar = () => {
  const editor = useSlate();
  const isTable = useTable(editor);
  const [toolbarGroups, setToolbarGroups] = React.useState(
    defaultToolbarGroups,
  );
  React.useEffect(() => {
    let filteredGroups = [...defaultToolbarGroups];
    if (isTable) {
      filteredGroups = toolbarGroups.map((grp) =>
        grp.filter((element) => element.type !== 'block'),
      );
      filteredGroups = filteredGroups.filter((elem) => elem.length);
    }
    setToolbarGroups(filteredGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTable]);

  const changeMarkData = (event: any, format: string) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  const BlockButton = ({ format }: { format: string }) => {
    return (
      <Button
        format={format}
        onMouseDown={(e: any) => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <MaterialIcon icon={format} active={isBlockActive(editor, format)} />
      </Button>
    );
  };
  const MarkButton = ({ format }: { format: string }) => {
    return (
      <Button
        format={format}
        onMouseDown={(e: any) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <MaterialIcon icon={format} active={isMarkActive(editor, format)} />
      </Button>
    );
  };
  const Dropdown = ({
    format,
    options,
  }: {
    format: string;
    options: any[];
  }) => {
    return (
      <Select
        value={activeMark(editor, format)}
        onChange={(e) => changeMarkData(e, format)}
        sx={{ width: 180 }}
      >
        {options!.map(({ text, value }) => (
          <MenuItem value={value} key={text}>
            {text}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Paper>
      <Box sx={{ display: 'flex', gap: 1, padding: 1, margin: '8px 0' }}>
        <Dropdown {...buttonsLine.fontFamily} />
        <Dropdown {...buttonsLine.fontSize} />
      </Box>
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap', padding: 1, margin: '8px 0' }}
      >
        <MarkButton {...buttonsLine.bold} />
        <MarkButton {...buttonsLine.italic} />
        <MarkButton {...buttonsLine.underline} />
        <MarkButton {...buttonsLine.strikethrough} />
        <LinkButton active={isBlockActive(editor, 'link')} editor={editor} />
        <Embed format={buttonsLine.image.format} editor={editor} />
        <Table editor={editor} />
        {isTable ? (
          <InTable editor={editor} />
        ) : (
          <>
            <BlockButton {...buttonsLine.orderedList} />
            <BlockButton {...buttonsLine.unorderedList} />
            <BlockButton {...buttonsLine.alignLeft} />
            <BlockButton {...buttonsLine.alignCenter} />
            <BlockButton {...buttonsLine.alignRight} />
          </>
        )}
      </Box>
      <Box></Box>
    </Paper>
  );
};

export default Toolbar;
