import { createAction } from 'redux-act';
import {
  ICountResponse,
  IFilter,
  IRecruitingDashboardSummaryModel,
} from '../../types';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Fetch recruiting dashboards summary actions
export const getRecruitingDashboardSummaryListRequest = createAction<IFilter>(
  'Make request to fetch recruiting dashboard summary list',
);
export const getRecruitingDashboardSummaryListSuccess = createAction<
  IRecruitingDashboardSummaryModel[]
>('Recruiting dashboards summary list has been fetched successfully');
export const getRecruitingDashboardSummaryListFailed = createAction(
  'Cannot fetch recruiting dashboard summary list',
);

// Counting recruiting dashboards summary actions
export const getRecruitingDashboardSummaryCountRequest = createAction<IFilter>(
  'Make request to get recruiting dashboard summary count',
);
export const getRecruitingDashboardSummaryCountSuccess = createAction<
  ICountResponse
>('Recruiting dashboard summary count has been fetched successfully');
export const getRecruitingDashboardSummaryCountFailed = createAction(
  'Cannot get recruiting dashboard summary count',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('RecruitingDashboardSummary').takeExportActions();

export const exportRecruitingDashboardSummaryRequest = exportReportRequest;
export const exportRecruitingDashboardSummarySuccess = exportReportSuccess;
export const exportRecruitingDashboardSummaryFailed = exportReportFailed;
