import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  ITerminationReasonModel,
  ITerminationReasonState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

// default state
const defaultState: ITerminationReasonState = {
  refreshKey: 0,
  id: NaN,
  siteId: NaN,
  name: '',
  list: [],
  comboboxList: [],
  count: 0,
  voluntary: false,
};

/**
 * Flush actions
 */
const flushTerminationReasonState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createTerminationReasonRequest = (state: ITerminationReasonState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createTerminationReasonSuccess = (
  state: ITerminationReasonState,
  payload: ITerminationReasonModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createTerminationReasonFailed = (
  state: ITerminationReasonState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getTerminationReasonListRequest = (state: ITerminationReasonState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getTerminationReasonListSuccess = (
  state: ITerminationReasonState,
  payload: ITerminationReasonModel[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getTerminationReasonListFailed = (
  state: ITerminationReasonState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get count actions
 */
const getTerminationReasonCountRequest = (state: ITerminationReasonState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getTerminationReasonCountSuccess = (
  state: ITerminationReasonState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const getTerminationReasonCountFailed = (
  state: ITerminationReasonState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deleteTerminationReasonRequest = (state: ITerminationReasonState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deleteTerminationReasonFailed = (
  state: ITerminationReasonState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updateTerminationReasonsRequest = (state: ITerminationReasonState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updateTerminationReasonsFailed = (
  state: ITerminationReasonState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getTerminationReasonComboboxListRequest = (
  state: ITerminationReasonState,
) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getTerminationReasonComboboxListSuccess = (
  state: ITerminationReasonState,
  payload: Partial<ITerminationReasonModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getTerminationReasonComboboxListFailed = (
  state: ITerminationReasonState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Export actions
 * @param state
 */

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<ITerminationReasonState>();

const exportTerminationReasonRequest = exportRequest;
const exportTerminationReasonSuccess = exportSuccess;
const exportTerminationReasonFailed = exportFailed;

/**
 * TerminationReason reducer
 */
export const terminationReason = createReducer<ITerminationReasonState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushTerminationReasonState, flushTerminationReasonState)
  // creation actions
  .on(actions.createTerminationReasonRequest, createTerminationReasonRequest)
  .on(actions.createTerminationReasonSuccess, createTerminationReasonSuccess)
  .on(actions.createTerminationReasonFailed, createTerminationReasonFailed)
  // get list actions
  .on(actions.getTerminationReasonListRequest, getTerminationReasonListRequest)
  .on(actions.getTerminationReasonListSuccess, getTerminationReasonListSuccess)
  .on(actions.getTerminationReasonListFailed, getTerminationReasonListFailed)
  // get count actions
  .on(
    actions.getTerminationReasonCountRequest,
    getTerminationReasonCountRequest,
  )
  .on(
    actions.getTerminationReasonCountSuccess,
    getTerminationReasonCountSuccess,
  )
  .on(actions.getTerminationReasonCountFailed, getTerminationReasonCountFailed)
  // deletion actions
  .on(actions.deleteTerminationReasonRequest, deleteTerminationReasonRequest)
  .on(actions.deleteTerminationReasonFailed, deleteTerminationReasonFailed)
  // updating actions
  .on(actions.updateTerminationReasonsRequest, updateTerminationReasonsRequest)
  .on(actions.updateTerminationReasonsFailed, updateTerminationReasonsFailed)
  // get combobox list actions
  .on(
    actions.getTerminationReasonComboboxListRequest,
    getTerminationReasonComboboxListRequest,
  )
  .on(
    actions.getTerminationReasonComboboxListSuccess,
    getTerminationReasonComboboxListSuccess,
  )
  .on(
    actions.getTerminationReasonComboboxListFailed,
    getTerminationReasonComboboxListFailed,
  )
  // export actions
  .on(actions.exportTerminationReasonRequest, exportTerminationReasonRequest)
  .on(actions.exportTerminationReasonSuccess, exportTerminationReasonSuccess)
  .on(actions.exportTerminationReasonFailed, exportTerminationReasonFailed);

export default terminationReason;
