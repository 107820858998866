import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  ICreateTerminationReasonRequest,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  ITerminationReasonModel,
  PayloadWithNavigateFunc,
} from '../../types';
import { paths } from '../../../config';
import { ExportSagaCreator } from '../../utils/helpers/creators/export';

/**
 * Create a new terminationReason
 */
export const createTerminationReasonRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ICreateTerminationReasonRequest>
>): SagaIterator {
  try {
    const response: ITerminationReasonModel = yield call(
      Api.TerminationReason.create,
      data,
    );
    yield put(actions.flushTerminationReasonState());
    yield put(actions.createTerminationReasonSuccess(response));
    yield call(navigate, { pathname: paths.TERMINATION_REASONS });
  } catch (e) {
    yield put(actions.createTerminationReasonFailed());
  }
};

/**
 * Get terminationReason list
 */
export const getTerminationReasonListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ITerminationReasonModel[] = yield call(
      Api.TerminationReason.list,
      action.payload,
    );
    yield put(actions.getTerminationReasonListSuccess(response));
  } catch (e) {
    yield put(actions.getTerminationReasonListFailed());
  }
};

/**
 * Get terminationReason count
 */
export const getTerminationReasonCountRequestSaga = function* (
  action?: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.TerminationReason.count,
      action?.payload,
    );
    yield put(actions.getTerminationReasonCountSuccess(response));
  } catch (e) {
    yield put(actions.getTerminationReasonCountFailed());
  }
};

/**
 * Delete terminationReasons
 */
export const deleteTerminationReasonRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.TerminationReason.delete, {
      where: { id: { inq: action.payload } },
    });
    yield put(actions.flushTerminationReasonState());
  } catch (e) {
    yield put(actions.deleteTerminationReasonFailed());
  }
};

/**
 * Bulk update terminationReasons
 */
export const updateTerminationReasonsRequestSaga = function* ({
  payload: { data, navigate },
}: ISagaAction<
  PayloadWithNavigateFunc<ITerminationReasonModel[]>
>): SagaIterator {
  try {
    yield call(Api.TerminationReason.bulkUpdate, data);
    yield put(actions.flushTerminationReasonState());
    yield call(navigate, { pathname: paths.TERMINATION_REASONS });
  } catch (e) {
    yield put(actions.updateTerminationReasonsFailed());
  }
};

/**
 * Get combobox list
 */
export const getTerminationReasonComboboxListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: Partial<ITerminationReasonModel>[] = yield call(
      Api.TerminationReason.list,
      action.payload,
    );
    yield put(actions.getTerminationReasonComboboxListSuccess(response));
  } catch (e) {
    yield put(actions.getTerminationReasonComboboxListFailed());
  }
};

const { exportRequestSaga } = new ExportSagaCreator({
  apiCall: Api.TerminationReason.export,
  actionFailed: actions.exportTerminationReasonFailed,
  actionSuccess: actions.exportTerminationReasonSuccess,
  actionNotification: actions.addProcessStatus,
}).takeSagas();
export const exportTerminationReasonRequestSaga = exportRequestSaga;

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createTerminationReasonRequest,
    saga: createTerminationReasonRequestSaga,
  },
  {
    action: actions.getTerminationReasonListRequest,
    saga: getTerminationReasonListRequestSaga,
  },
  {
    action: actions.getTerminationReasonCountRequest,
    saga: getTerminationReasonCountRequestSaga,
  },
  {
    action: actions.deleteTerminationReasonRequest,
    saga: deleteTerminationReasonRequestSaga,
  },
  // bulk updating terminationReasons
  {
    action: actions.updateTerminationReasonsRequest,
    saga: updateTerminationReasonsRequestSaga,
  },
  {
    action: actions.getTerminationReasonComboboxListRequest,
    saga: getTerminationReasonComboboxListRequestSaga,
  },
  {
    action: actions.exportTerminationReasonRequest,
    saga: exportTerminationReasonRequestSaga,
  },
];
