import React from 'react';
import { Sites } from './panels/Sites';
import { useTranslation } from 'react-i18next';
import { TabsNgroup } from 'src/components/TabsNgroup';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { ArchivedSites } from './panels/ArchivedSites';
import { ImportingSettings } from './panels/ImportingSettings';

export const Site = () => {
  const { t } = useTranslation();

  const hasUserAccessToSitesTab = useHasUserAccessToViewTab(
    tabsPaths.SITES_TAB,
  );
  const hasUserAccessToArchivedSitesTab = useHasUserAccessToViewTab(
    tabsPaths.ARCHIVED_SITES_TAB,
  );
  const hasUserAccessToImportingSettingsTab = useHasUserAccessToViewTab(
    tabsPaths.IMPORTING_SETTINGS_TAB,
  );

  const pointsTabsConfig = React.useMemo(
    () =>
      [
        {
          title: t('sites.table_name'),
          hasUserAccess: hasUserAccessToSitesTab,
          component: <Sites />,
        },
        {
          title: t('sites.archived_table_name'),
          hasUserAccess: hasUserAccessToArchivedSitesTab,
          component: <ArchivedSites />,
        },
        {
          title: t('site-importing-settings.main'),
          hasUserAccess: hasUserAccessToImportingSettingsTab,
          component: <ImportingSettings />,
        },
      ].filter((tabConfig) => Boolean(tabConfig.hasUserAccess)),
    [
      hasUserAccessToArchivedSitesTab,
      hasUserAccessToSitesTab,
      hasUserAccessToImportingSettingsTab,
      t,
    ],
  );

  return <TabsNgroup config={pointsTabsConfig} />;
};
