import React from 'react';
import { Drawer } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IdsArray } from 'src/modules/types';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Api } from 'src/modules/utils';
import AutomaticPointsRejectReasonsComboBox from 'src/components/Formik/comboboxes-with-entities/AutomaticPointsRejectReasonsCombobox';
import { useDispatch } from 'react-redux';
import { addProcessStatus } from 'src/modules/actions';

interface IAutomaticPointsRejectProps {
  isOpen: boolean;
  ids: IdsArray;
  onReject: () => void;
  onClose: () => void;
}

export const AutomaticPointsReject: React.FC<IAutomaticPointsRejectProps> = ({
  isOpen,
  ids,
  onClose,
  onReject,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const initialValues = {
    rejectReasonId: NaN,
  };

  const validationSchema = Yup.object({
    rejectReasonId: Yup.number()
      .required(t('error.validation.required'))
      .typeError(t('error.validation.required')),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    isInitialValid: true,
    validationSchema,
    onSubmit: async (data: { rejectReasonId: number }) => {
      await Api.Point.autoPointReject(
        ids.map((id) => ({
          id: +id,
          rejectReasonId: +data.rejectReasonId,
        })),
      );
      formik.resetForm();
      onReject();
      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>{t('points.reject')}</DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <AutomaticPointsRejectReasonsComboBox
              id="rejectReasonId"
              name="rejectReasonId"
              formik={formik}
              required={true}
              variant="outlined"
              label={t('points.reject_reason')}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
