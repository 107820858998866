import { filter, get, memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateBaseline } from '../types';

// CPU based
export const getCpuBasedBaselineData = (state: IStoreState) =>
  state.cpuBasedBaseline;

export const getCpuBasedBaselineRefreshKey = createSelector(
  getCpuBasedBaselineData,
  (issueTrackerData) => {
    return issueTrackerData.refreshKey;
  },
);

export const getCpuBasedBaselinesList = createSelector(
  getCpuBasedBaselineData,
  ({ list }) => {
    return list || [];
  },
);

export const getCpuBasedBaselinesCount = createSelector(
  getCpuBasedBaselineData,
  ({ count }) => {
    return count;
  },
);

// Area based
export const getAreaBasedBaselineData = (state: IStoreState) =>
  state.areaBasedBaseline;

export const getAreaBasedBaselineRefreshKey = createSelector(
  getAreaBasedBaselineData,
  (issueTrackerData) => {
    return issueTrackerData.refreshKey;
  },
);

export const getAreaBasedBaselinesList = createSelector(
  getAreaBasedBaselineData,
  ({ list }) => {
    return list || [];
  },
);

export const getAreaBasedBaselinesCount = createSelector(
  getAreaBasedBaselineData,
  ({ count }) => {
    return count;
  },
);

export const getAreaBasedBaselineByIds = createSelector(
  getAreaBasedBaselinesList,
  (baselines) =>
    memoize((ids: IdsArray) =>
      filter(baselines, (baseline) => ids.includes(`${baseline.id}`)),
    ),
);

export const getCpuBasedBaselineByIds = createSelector(
  getCpuBasedBaselinesList,
  (baselines) =>
    memoize((ids: IdsArray) =>
      filter(baselines, (baseline) => ids.includes(`${baseline.id}`)),
    ),
);

export const getCpuBasedBaselineBySiteIds = createSelector(
  getCpuBasedBaselinesList,
  (baselines) =>
    memoize((ids: IdsArray) =>
      filter(baselines, (baseline) => ids.includes(`${baseline.siteId}`)),
    ),
);

export const getBaselineRefreshKey = (state: IStoreState) =>
  state.baseline.refreshKey;

export const getBaselineData = (state: IStoreState) => state.baseline;

/**
 * Get server error
 * @param baseline - State baseline
 */
export const getServerError = ({ baseline }: IStoreState) => baseline.error;

/**
 * Get baseline list
 * @param baseline - State baseline
 */
export const getBaselineList = ({ baseline }: IStoreState) => baseline.list;

/**
 * Get baselines list count by siteId
 * @param baseline - State baseline
 */
export const getBaselinesCount = ({ baseline }: IStoreState) => (
  siteId: number,
) => get(baseline, ['list', siteId, 'count'], 0);

/**
 * Get baselines by array of ids
 * @param baseline - State baseline
 */
export const getBaselinesByIds = createSelector(
  getBaselineList,
  (baselines) => (siteId: number, ids: IdsArray) =>
    reduce(
      get(baselines, [siteId, 'data'], []),
      (acc, cur) => {
        if (ids.includes(`${((cur as unknown) as IUpdateBaseline).id}`)) {
          // omit columns we should not request with
          const pushingItem = omit((cur as unknown) as IUpdateBaseline, [
            'site',
            'baselineMetadata',
          ]) as IUpdateBaseline;
          acc.push({
            ...pushingItem,
            baseline: String(pushingItem.baseline),
            minCpu: String(pushingItem.minCpu),
            maxCpu: String(pushingItem.maxCpu),
          });
        }
        return acc;
      },
      [] as IUpdateBaseline[],
    ),
);
