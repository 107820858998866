import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/joy';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import {
  IdsArray,
  IAutomaticPointsRejectReasonModel,
  IUpdateAutomaticPointsRejectReason,
} from 'src/modules/types';
import { useValidate } from 'src/modules/utils/hooks';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { Api } from 'src/modules/utils';
import { updateAutomaticPointsRejectReasonsScheme } from 'src/modules/schemes/automatic-points-reject-reason';
import { ifSiteHasLunch } from 'src/modules/selectors/site';

interface IAutomaticPointsRejectReasonUpdateProps {
  isOpen: boolean;
  ids: IdsArray;
  onUpdate: () => void;
  onClose: () => void;
}

export const AutomaticPointsRejectReasonsUpdate: React.FC<IAutomaticPointsRejectReasonUpdateProps> = ({
  isOpen,
  ids,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const hasLunch = useSelector(ifSiteHasLunch);

  const [initialValues, setInitialValues] = React.useState<
    Array<IAutomaticPointsRejectReasonModel>
  >([]);

  React.useEffect(() => {
    if (isOpen) {
      Api.AutomaticPointsRejectReason.list({
        filter: { where: { id: { inq: ids } } },
      }).then((data) => setInitialValues(data));
    }
  }, [hasLunch, ids, isOpen]);

  const validate = useValidate(updateAutomaticPointsRejectReasonsScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IUpdateAutomaticPointsRejectReason[]) => {
      await Api.AutomaticPointsRejectReason.bulkUpdate(data);
      formik.resetForm();
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('automaticPointsRejectReason.update_form_title')}
      </DrawerHeader>
      <DrawerBody>
        {map(formik.values, (incentivePrice, index) => (
          <DrawerBodyContent key={incentivePrice.id}>
            <FormFieldContainer>
              <FormikTextField
                id={`${index}.name`}
                name={`${index}.name`}
                formik={formik}
                required={true}
                variant="outlined"
                fullWidth={true}
                label={t('automaticPointsRejectReason.name')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            <FormFieldContainer>
              <SitesComboBox
                id={`${index}.siteId`}
                required={true}
                formik={formik}
                label={t('automaticPointsRejectReason.site')}
                placeholder={t('automaticPointsRejectReason.site')}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>
          </DrawerBodyContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
