import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { useProductionDailyReportsDefaultFilters } from './productions.hooks';
import { useFilter } from './useFilter';
import { useQueryParams } from './useQueryParams';
import { getQueryKey } from '../helpers/reactQuery.helpers';
import Api from '../Api';

import { ClientKey } from '../../constants';
import { AnyObject, IFilterData } from '../../types';
import { UphChartProps } from '../../../components/ReportPage/UphChart';
import React from 'react';

const chartOptions = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    title: {
      text: 'UPH',
    },
  },
  series: [
    {
      name: 'UPH',
      data: [],
    },
  ],
};

const uphChartConfig = {
  'cardinal-health': 'Cardinal Health',
  optoro: 'Optoro',
  expeditors: 'Expeditors',
  'mc-entire': 'McEntire',
  daily: 'Daily Report',
  weekly: 'Weekly Report',
};

const ACTUAL_RANGE = 14; // 14 days

const getUrl = (client: ClientKey, reportTypeId: string) =>
  `/${client}/${reportTypeId}-uph/by-site`;

export const chartHelper = {
  daily(fetchedData: { date: string; uph: string }[]): any {
    const categories: string[] = [];
    const data: number[] = [];
    const actualData = fetchedData.slice(0, ACTUAL_RANGE);
    actualData.forEach(({ date, uph }) => {
      categories.push(date);
      data.push(Number(uph));
    });
    categories.sort();

    return { categories, data };
  },

  weekly(fetchedData: { week: string; year: string; uph: string }[]): any {
    const categories: string[] = [];
    const data: number[] = [];
    fetchedData.forEach(({ week, year, uph }) => {
      categories.push(`${week}/${year}`);
      data.push(Number(uph));
    });
    categories.sort();

    return { categories, data };
  },
};

const useChartFilter = <F extends AnyObject>(
  reportTypeId: string,
  filters?: F,
) => {
  const defaultFilter = useProductionDailyReportsDefaultFilters(
    reportTypeId === 'daily' ? 'date' : 'yearWeek',
  );
  const { filterList } = useFilter(defaultFilter);
  const { filter = {} } = useQueryParams() as { filter: IFilterData };

  const additionalWhere: AnyObject = {};

  const where = React.useMemo(() => {
    const and = [];

    if (filters?.startDate) {
      and.push({ date: { gte: filters?.startDate } });
    }

    if (filters?.endDate) {
      and.push({ date: { lte: filters?.endDate } });
    }

    if (filters?.year) {
      additionalWhere.year = filters?.year;
    }

    if (filters?.week) {
      additionalWhere.week = filters?.week;
    }

    return {
      ...(and.length ? { and } : {}),
      ...additionalWhere,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const requestFilter = isEmpty(filter) ? filterList : { filter };

  return {
    dateFilter: [],
    requestFilter: {
      ...requestFilter,
      filter: {
        ...requestFilter.filter,
        where: {
          ...requestFilter.filter?.where,
          ...where,
        },
      },
    },
  };
};

export const useUphChartQuery = <F extends AnyObject>({
  reportTypeId,
  client,
  filters,
}: UphChartProps<F>) => {
  const { dateFilter, requestFilter } = useChartFilter(reportTypeId, filters);

  const response = useQuery(
    getQueryKey(getUrl(client, reportTypeId), requestFilter),
    () =>
      Api.Report.list(
        getUrl(client, reportTypeId),
        requestFilter,
      ).then(({ items }) => chartHelper[reportTypeId](items)),
  );

  const categories = response.data?.categories;
  const data = response.data?.data;

  const title = `${uphChartConfig[client]} ${uphChartConfig[reportTypeId]}`;

  let options = null;
  if (categories && data) {
    chartOptions.xAxis.categories = categories;
    chartOptions.series[0].data = data;
    options = chartOptions;
  }

  return { options, title, dateFilter };
};
