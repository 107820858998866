import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Alert } from '@mui/joy';

import {
  useFetchUsersSitesCombobox,
  useKpiDataGrid,
  useQueryParams,
} from 'src/modules/utils';
import { AnyObject } from 'src/modules/types';
import KpiFilter from './components/KpiFilter';
import { shallowEqual, useSelector } from 'react-redux';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { getCurrentRole } from 'src/modules/selectors/auth';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { TableActions, TableTitle, TableToolbar } from 'src/components/Table';
import { TableContextProvider } from 'src/components/Table/TableContext';
import { KpiTable } from './components/KpiTable';

const KpiReport: React.FC = () => {
  const { t } = useTranslation();

  const MONTHS = 13;
  const WEEKS = 53;

  const queryParams = useQueryParams();

  const [filters, setFilters] = React.useState<AnyObject>({
    yearWeek: queryParams?.yearWeek
      ? JSON.parse(queryParams.yearWeek as string)
      : [],
    yearMonth: queryParams?.yearMonth
      ? JSON.parse(queryParams.yearMonth as string)
      : [],
    siteId: queryParams?.siteId ?? '',
    basis: queryParams?.basis ?? null,
  });

  const { siteId, basis, yearWeek, yearMonth } = (filters ?? {}) as any;

  const showTable = Boolean(
    siteId && basis && (!isEmpty(yearMonth) || !isEmpty(yearWeek)),
  );

  const { columns, rows, loading } = useKpiDataGrid(
    siteId,
    basis,
    basis === 'week' ? yearWeek : yearMonth,
    false,
    'report',
  );

  const [isLoading, setIsLoading] = React.useState<boolean>();

  React.useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const fetchSitesCombobox = useFetchUsersSitesCombobox();
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  useEffect(() => {
    fetchSitesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const sites = useSelector(getSitesComboboxList, shallowEqual);

  const site = React.useMemo(() => sites.find((s) => +s.id === +siteId), [
    siteId,
    sites,
  ]);

  const title = React.useMemo(
    () =>
      site ? t('kpi.report_for', { siteName: site.name }) : t('kpi.report'),
    [site, t],
  );

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer fullHeight={false}>
          <TableToolbar>
            <TableTitle>{title}</TableTitle>
            <TableActions>
              <KpiFilter
                weeks={WEEKS}
                months={MONTHS}
                filters={filters}
                onSubmit={(data) => {
                  setFilters(data);
                }}
              />
            </TableActions>
          </TableToolbar>
        </PageContentChildContainer>
        <PageContentChildContainer fullHeight={false}>
          {!showTable && (
            <Alert variant="soft">{t('kpi.select_filters_message')}</Alert>
          )}

          {showTable && (
            <KpiTable
              isCellEditable={() => false}
              showCellVerticalBorder
              disableRowSelectionOnClick
              isLoading={isLoading}
              rows={rows}
              columns={columns}
            />
          )}
        </PageContentChildContainer>
      </PageContentWithTopToolbar>
    </>
  );
};

export default () => {
  return (
    <TableContextProvider>
      <KpiReport />
    </TableContextProvider>
  );
};
