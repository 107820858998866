import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../../utils';
import * as actions from '../../actions';
import {
  ICountResponse,
  IdsArray,
  IFilter,
  ISagaAction,
  ISagaActionBind,
  PayloadWithFilters,
} from '../../types';
import {
  IOverdueQuestionnaire,
  IQuestionEmployeeHistoryItem,
  IQuestionEmployeeModel,
} from 'src/modules/types/question-employee';

export const fillInQuestionnaireRequestSaga = function* ({
  payload,
}: ISagaAction<FormData>): SagaIterator {
  try {
    yield call(Api.QuestionEmployee.fillInQuestionnaire, payload);
    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.createQuestionCategoryFailed());
  }
};

export const getQuestionEmployeeHistoryDataRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IQuestionEmployeeHistoryItem[] = yield call(
      Api.QuestionEmployee.employeesReview,
      action.payload,
    );
    yield put(actions.getQuestionEmployeeHistoryDataSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionEmployeeHistoryDataFailed());
  }
};

export const getQuestionEmployeeHistoryDataCountRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.QuestionEmployee.employeesReviewCount,
      action.payload,
    );
    yield put(actions.getQuestionEmployeeHistoryDataCountSuccess(response));
  } catch (e) {
    yield put(actions.getQuestionEmployeeHistoryDataCountFailed());
  }
};

export const getOverdueQuestionnairesRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: IOverdueQuestionnaire[] = yield call(
      Api.QuestionEmployee.overdue,
      action.payload,
    );
    yield put(actions.getOverdueQuestionnairesSuccess(response));
  } catch (e) {
    yield put(actions.getOverdueQuestionnairesFailed());
  }
};

export const getOverdueQuestionnairesCountRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.QuestionEmployee.overdueCount,
      action.payload,
    );
    yield put(actions.getOverdueQuestionnairesCountSuccess(response));
  } catch (e) {
    yield put(actions.getOverdueQuestionnairesCountFailed());
  }
};

export const updateQuestionnaireAnswersRequestSaga = function* ({
  payload: { data, filters },
}: ISagaAction<PayloadWithFilters<IQuestionEmployeeModel[]>>): SagaIterator {
  try {
    yield call(Api.QuestionEmployee.bulkUpdate, data);

    yield put(
      actions.getQuestionEmployeeHistoryDataRequest({ filter: filters.list }),
    );
    yield put(
      actions.getQuestionEmployeeHistoryDataCountRequest({
        filter: filters.count,
      }),
    );

    yield put(
      actions.addProcessStatus({
        variant: 'success',
        title: 'common.success',
      }),
    );
  } catch (e) {
    yield put(actions.updateQuestionnaireAnswersFailed());
  }
};

export const deleteQuestionEmployeeRequestSaga = function* (
  action: ISagaAction<IdsArray>,
): SagaIterator {
  try {
    yield call(Api.QuestionEmployee.delete, action.payload);
    yield put(actions.deleteQuestionEmployeeSuccess());
  } catch (e) {
    yield put(actions.deleteQuestionEmployeeFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.fillInQuestionnaireRequest,
    saga: fillInQuestionnaireRequestSaga,
  },
  {
    action: actions.getQuestionEmployeeHistoryDataRequest,
    saga: getQuestionEmployeeHistoryDataRequestSaga,
  },
  {
    action: actions.getQuestionEmployeeHistoryDataCountRequest,
    saga: getQuestionEmployeeHistoryDataCountRequestSaga,
  },
  {
    action: actions.getOverdueQuestionnairesRequest,
    saga: getOverdueQuestionnairesRequestSaga,
  },
  {
    action: actions.getOverdueQuestionnairesCountRequest,
    saga: getOverdueQuestionnairesCountRequestSaga,
  },
  {
    action: actions.updateQuestionnaireAnswersRequest,
    saga: updateQuestionnaireAnswersRequestSaga,
  },
  {
    action: actions.deleteQuestionEmployeeRequest,
    saga: deleteQuestionEmployeeRequestSaga,
  },
];
