import { createReducer } from 'redux-act';
import * as actions from '../actions';

import { PolicyConfig } from '../types';
import { IResourcesState } from '../types/resources';

// default state
const defaultState: IResourcesState = {
  list: [],
};

/**
 * Get list actions
 */
const getResourcesRequest = (state: IResourcesState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getResourcesSuccess = (
  state: IResourcesState,
  payload: PolicyConfig[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});

const getResourcesFailed = (state: IResourcesState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Bom reducer
 */
export const resources = createReducer<IResourcesState>({}, defaultState)
  .on(actions.getResourcesRequest, getResourcesRequest)
  .on(actions.getResourcesSuccess, getResourcesSuccess)
  .on(actions.getResourcesFailed, getResourcesFailed);

export default resources;
