import { useTranslation } from 'react-i18next';

export const useApplicantCreateFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      site: {
        target: 'site',
        content: t('guide.applicant.site'),
      },
      firstName: {
        target: 'first-name',
        content: t('guide.applicant.first_name'),
      },
      lastName: {
        target: 'last-name',
        content: t('guide.applicant.last_name'),
      },
      openPosition: {
        target: 'open-position',
        content: t('guide.applicant.open_position'),
      },
      payRate: {
        target: 'pay-rate',
        content: t('guide.applicant.pay_rate'),
      },
      dateInterview: {
        target: 'date-interview',
        content: t('guide.applicant.date_interview'),
      },
      dateStart: {
        target: 'date-start',
        content: t('guide.applicant.date_start'),
      },
      badge: {
        target: 'badge',
        content: t('guide.applicant.badge'),
      },
      supervisor: {
        target: 'supervisor',
        content: t('guide.applicant.supervisor'),
      },
      staffingProvider: {
        target: 'staffing-provider',
        content: t('guide.applicant.staffing_provider'),
      },
      I9: {
        target: 'I9',
        content: t('guide.applicant.I9'),
      },
      backgroundCheck: {
        target: 'background-check',
        content: t('guide.applicant.background_check'),
      },
      interviewNotes: {
        target: 'interview-notes',
        content: t('guide.applicant.interview_notes'),
      },
      phoneNumber: {
        target: 'phone-number',
        content: t('guide.applicant.phone_number'),
      },
      address: {
        target: 'address',
        content: t('guide.applicant.address'),
      },
    },
  };
};

export const useApplicantUpdateFormTourConfig = () => {
  const { t } = useTranslation();

  return {
    tour: {
      site: {
        target: 'site',
        content: t('guide.applicant.site'),
      },
      firstName: {
        target: 'first-name',
        content: t('guide.applicant.first_name'),
      },
      lastName: {
        target: 'last-name',
        content: t('guide.applicant.last_name'),
      },
      openPosition: {
        target: 'open-position',
        content: t('guide.applicant.open_position'),
      },
      payRate: {
        target: 'pay-rate',
        content: t('guide.applicant.pay_rate'),
      },
      dateInterview: {
        target: 'date-interview',
        content: t('guide.applicant.date_interview'),
      },
      dateStart: {
        target: 'date-start',
        content: t('guide.applicant.date_start'),
      },
      badge: {
        target: 'badge',
        content: t('guide.applicant.badge'),
      },
      supervisor: {
        target: 'supervisor',
        content: t('guide.applicant.supervisor'),
      },
      staffingProvider: {
        target: 'staffing-provider',
        content: t('guide.applicant.staffing_provider'),
      },
      I9: {
        target: 'I9',
        content: t('guide.applicant.I9'),
      },
      backgroundCheck: {
        target: 'background-check',
        content: t('guide.applicant.background_check'),
      },
      interviewNotes: {
        target: 'interview-notes',
        content: t('guide.applicant.interview_notes'),
      },
      phoneNumber: {
        target: 'phone-number',
        content: t('guide.applicant.phone_number'),
      },
      address: {
        target: 'address',
        content: t('guide.applicant.address'),
      },
    },
  };
};
