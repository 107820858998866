import {
  ICardinalHealthWeeklyReportModel,
  ICardinalHealthWeeklyReportState,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthWeeklyReportState = {
  list: {
    items: [],
    summary: [],
  },
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthWeeklyUphBySiteAndSkuListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteAndSkuListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteAndSkuListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthWeeklyReportState,
  ICardinalHealthWeeklyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteAndSkuCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteAndSkuCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteAndSkuCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthWeeklyReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthWeeklyUphBySiteAndSku = createReducer<
  ICardinalHealthWeeklyReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndSkuListRequest,
    getCardinalHealthWeeklyUphBySiteAndSkuListRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndSkuListSuccess,
    getCardinalHealthWeeklyUphBySiteAndSkuListSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndSkuListFailed,
    getCardinalHealthWeeklyUphBySiteAndSkuListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndSkuCountRequest,
    getCardinalHealthWeeklyUphBySiteAndSkuCountRequest,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndSkuCountSuccess,
    getCardinalHealthWeeklyUphBySiteAndSkuCountSuccess,
  )
  .on(
    actions.getCardinalHealthWeeklyUphBySiteAndSkuCountFailed,
    getCardinalHealthWeeklyUphBySiteAndSkuCountFailed,
  );

export default cardinalHealthWeeklyUphBySiteAndSku;
