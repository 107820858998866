import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { useUphChartQuery } from '../../modules/utils/hooks/uphChart.hooks';
import { ClientKey } from '../../modules/constants';
import { IMcEntireReportsFilterPanelFilters } from '../../pages/McEntire/McEntireReportsFilterPanel';
import { AnyObject } from '../../modules/types';

export interface UphChartProps<F extends AnyObject> {
  reportTypeId: string;
  client: ClientKey;
  filters?: F;
}

const UphChart: React.FC<UphChartProps<IMcEntireReportsFilterPanelFilters>> = ({
  reportTypeId,
  client,
  filters,
}) => {
  const { options } = useUphChartQuery({
    reportTypeId,
    client,
    filters,
  });

  return (
    options && (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true, true, true]}
      />
    )
  );
};

export default UphChart;
