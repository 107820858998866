import React from 'react';
import { ProfileForm } from '../ProfileForm';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../../components/Formik/FormikTextField';
import { useValidate } from '../../../modules/utils';
import { updatePasswordScheme } from '../../../modules/schemes/profile/update-password.scheme';
import { useFormik } from 'formik';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { useDispatch } from 'react-redux';
import { passwordUpdateRequest } from 'src/modules/actions';
import { Box } from '@mui/joy';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const UpdatePassword = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(updatePasswordScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatch(passwordUpdateRequest(data));
    },
  });

  const onReset = () => {
    formik.setValues({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  return (
    <ProfileForm
      onApply={formik.handleSubmit}
      onReset={onReset}
      title={t('profile.profile_settings')}
    >
      {/** Without it search of side bar will be autofill with email */}
      <Box sx={{ position: 'absolute', left: -1000 }}>
        <FormikTextField
          id="email"
          name="email"
          autoComplete="email"
          formik={formik}
          type="email"
        />
      </Box>
      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="oldPassword"
          label={t('profile.old_pwd')}
          name="oldPassword"
          autoComplete="oldPassword"
          formik={formik}
          type="password"
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="newPassword"
          label={t('profile.new_pwd')}
          name="newPassword"
          autoComplete="newPassword"
          type="password"
          formik={formik}
        />
      </FormFieldContainer>

      <FormFieldContainer>
        <FormikTextField
          variant="outlined"
          fullWidth
          id="confirmPassword"
          label={t('profile.confirm_pwd')}
          name="confirmPassword"
          autoComplete="confirmPassword"
          type="password"
          formik={formik}
        />
      </FormFieldContainer>
    </ProfileForm>
  );
};

export default UpdatePassword;
