import React from 'react';
import { ILoggedInModel } from '../../../modules/types';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  Typography,
} from '@mui/joy';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { Th, Tr } from 'src/components/TableUiComponents';
import { Td } from 'src/components/TableUiComponents/Td';
import { get } from 'lodash';

interface ILoggedInTableProps {
  rows: ILoggedInModel[];
  total: number;
  isDataLoading: boolean;
}

const LoggedInTable = ({ rows, total, isDataLoading }: ILoggedInTableProps) => {
  const { t } = useTranslation();

  const headCells = React.useMemo(
    () => [
      {
        id: 'site',
        label: t('supervisor_view.site_name'),
      },
      {
        id: 'department',
        label: t('supervisor_view.dep_name'),
      },
      {
        id: 'count',
        label: t('supervisor_view.cont_emp_logged'),
      },
    ],
    [t],
  );

  return (
    <Box
      sx={{
        borderRadius: 'md',
        borderStyle: 'solid',
        borderColor: 'colors.border.border_primary',
        width: '100%',
        overflow: 'hidden',
        borderWidth: 1,
      }}
    >
      <Accordion defaultExpanded sx={{ width: '100%' }}>
        <AccordionSummary slotProps={{ indicator: { sx: { px: 2 } } }}>
          <Box sx={{ p: 2 }}>
            <Typography
              level="text_sm"
              textColor="colors.text.text_primary.main"
            >
              {t('common.totals_by_department')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ width: '100%' }}>
          <Box
            sx={{
              maxHeight: 290,
              minHeight: isDataLoading ? 290 : 'auto',
              position: 'relative',
              overflowY: 'auto',
              overflowX: 'auto',
              width: '100%',
            }}
          >
            {isDataLoading && <MainContentLoader />}

            {!isDataLoading && (
              <Table
                sx={{
                  borderColor: 'transparent',
                  width: 'auto',
                  height: 'auto',
                  minWidth: '100%',
                }}
              >
                <Box component="thead" sx={{ position: 'sticky', top: 0 }}>
                  <Tr>
                    {headCells.map((cell) => (
                      <Th key={cell.id}>{cell.label}</Th>
                    ))}
                  </Tr>
                </Box>
                <Box component="tbody" sx={{ width: '100%' }}>
                  {rows.map((row, i) => (
                    <Tr key={i}>
                      {headCells.map((headCell) => (
                        <Td key={headCell.id}>{get(row, headCell.id)}</Td>
                      ))}
                    </Tr>
                  ))}
                </Box>
                {Boolean(rows.length) && (
                  <Box component="tfoot" sx={{ position: 'sticky', bottom: 0 }}>
                    <Tr>
                      <Td colSpan={headCells.length - 1}>
                        {t('supervisor_view.total')}
                      </Td>
                      <Td>{total}</Td>
                    </Tr>
                  </Box>
                )}
              </Table>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default LoggedInTable;
