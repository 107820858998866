import React from 'react';

import { useSitesByClientCombobox } from '../../../../modules/utils/hooks/common/forms';

import FormikCombobox, { IFormikComboboxProps } from '../../FormikCombobox';

import { ClientKey } from '../../../../modules/constants';

interface ISitesByClientComboboxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  clientKey: ClientKey;
  useReports?: boolean;
  fieldAsId?: 'id' | 'name' | 'uuid';
}

const SitesByClientComboBox: React.FC<ISitesByClientComboboxProps> = ({
  useReports,
  fieldAsId = 'id',
  clientKey,
  ...props
}) => {
  const options = useSitesByClientCombobox(fieldAsId, clientKey, useReports);

  return (
    <FormikCombobox
      {...props}
      required={true}
      formik={props.formik}
      placeholder={props.placeholder}
      options={options}
      error={props.error}
    />
  );
};

export default SitesByClientComboBox;
