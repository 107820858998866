import { IFilterOrder, IWhere } from 'src/modules/types';
import Api from '.';
import {
  IExecutiveSummaryReportWeeklyGrossMarginChartResponseDataItem,
  IExecutiveSummaryReportWeeklyRevenueAndCostChartResponseData,
  IExecutiveSummaryWeeklyActiveInactiveHiredChartResponseDataItem,
} from 'src/modules/types/executives-summary';

export const fetchExecutiveSummaryReportWeeklyGrossMarginChartData = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    Array<IExecutiveSummaryReportWeeklyGrossMarginChartResponseDataItem>
  >(`/new-executive-dashboard/charts/weekly-gross-margin`, {
    filter: { where },
  });

  return response;
};

export const fetchExecutiveSummaryReportWeeklyRevenueAndCostChartData = async (
  where?: IWhere,
) => {
  const response = await Api.Request.get<
    IExecutiveSummaryReportWeeklyRevenueAndCostChartResponseData
  >(`/new-executive-dashboard/charts/weekly-revenue-and-cost`, {
    filter: { where },
  });

  return response;
};

export const fetchExecutiveSummaryWeeklyActiveInactiveHiredChartData = async (
  where?: IWhere,
  order?: IFilterOrder,
) => {
  const response = await Api.Request.get<{
    items: Array<
      IExecutiveSummaryWeeklyActiveInactiveHiredChartResponseDataItem
    >;
  }>(`/new-executive-dashboard/charts/employees/active-inactive-hired`, {
    filter: { where, order },
  });

  return response;
};
