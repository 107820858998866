import { createAction } from 'redux-act';
import {
  CostPlusTypes,
  ICountResponse,
  ICreateCostPlusSettings,
  IdsArray,
  IFilter,
  ICostPlusSettingsModel,
  IUpdateCostPlusSettings,
  PayloadWithFilters,
} from '../types';

// Flush actions
export const flushCostPlusSettingsState = createAction(
  'Flush costPlusSettings data in store',
);

// Creation actions
export const createCostPlusSettingsRequest = createAction<
  ICreateCostPlusSettings
>('Make request to create a new costPlusSettings');
export const createCostPlusSettingsSuccess = createAction<
  ICostPlusSettingsModel
>('New costPlusSettings has been created successfully');
export const createCostPlusSettingsFailed = createAction(
  'Cannot create a new costPlusSettings',
);

// Fetch costPlusSettings actions
export const getCostPlusSettingsListRequest = createAction<IFilter>(
  'Make request to fetch costPlusSettings list',
);
export const getCostPlusSettingsListSuccess = createAction<
  ICostPlusSettingsModel[]
>('CostPlusSettings list has been fetched successfully');
export const getCostPlusSettingsListFailed = createAction(
  'Cannot fetch costPlusSettings list',
);

// get costPlusSettings count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getCostPlusSettingsCountRequest = createAction<IFilter>(
  'Make request to get costPlusSettings count',
);
export const getCostPlusSettingsCountSuccess = createAction<ICountResponse>(
  'CostPlusSettings count has been fetched successfully',
);
export const getCostPlusSettingsCountFailed = createAction(
  'Cannot get costPlusSettings count',
);

// delete actions
export const deleteCostPlusSettingsRequest = createAction<
  PayloadWithFilters<{ ids: IdsArray }>
>('Make request to delete costPlusSettings');
export const deleteCostPlusSettingsFailed = createAction(
  'Cannot delete costPlusSettings',
);

// Updating actions
export const updateCostPlusSettingsRequest = createAction<
  PayloadWithFilters<IUpdateCostPlusSettings[]>
>('Make request to update costPlusSettings');
export const updateCostPlusSettingsFailed = createAction(
  'Cannot update costPlusSettings',
);

// Fetch combobox list actions
export const getCostPlusSettingsComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of costPlusSettings',
);
export const getCostPlusSettingsComboboxListSuccess = createAction<
  Partial<ICostPlusSettingsModel>[]
>('Combobox list of costPlusSettings has been fetched successfully');
export const getCostPlusSettingsComboboxListFailed = createAction(
  'Cannot fetch combobox costPlusSettings list',
);

// Fetch Cost Plus Settings Types actions
export const getCostPlusSettingsTypesRequest = createAction(
  'Make request to fetch cost plus types',
);
export const getCostPlusSettingsTypesSuccess = createAction<CostPlusTypes>(
  'Cost Plus types have been fetched successfully',
);
export const getCostPlusSettingsTypesFailed = createAction(
  'Cannot fetch cost plus types list',
);
