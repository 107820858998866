import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteArea, updateArea } from 'src/modules/actions';
import { getAreaRawList } from '../../../modules/selectors/area.selector';
import {
  IAreaFormData,
  IAreaListForTable,
  IFilterData,
} from 'src/modules/types';

import { AreaForm } from './AreaForm';

interface ICreateAreaFormProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  rowData: (IAreaListForTable & { id: number }) | null;
}

export const UpdateAreaForm = ({
  isOpen,
  onClose,
  filterList,
  rowData,
}: ICreateAreaFormProps) => {
  const dispatch = useDispatch();

  const rawAreas = useSelector(getAreaRawList);

  const existedDataForSite = rowData
    ? rawAreas.find((item) => Number(item.siteId) === Number(rowData.siteId))
    : null;

  const existedSiteSelectedAreaData =
    rowData?.areaName && existedDataForSite
      ? existedDataForSite.map[rowData.areaName]
      : null;

  const initialValues = rowData
    ? {
        id: rowData.entityId,
        siteId: rowData.siteId,
        area: rowData.areaName,
        values: existedSiteSelectedAreaData
          ? existedSiteSelectedAreaData.map((item) => {
              return Object.entries(item).map(([key, value]) => ({
                fieldName: key,
                fieldValue: value,
              }));
            })
          : [],
      }
    : null;

  const onSubmit = ({ id, siteId, area, values }: IAreaFormData) => {
    if (id && siteId && rowData && area) {
      // On server side there is a validation that checks if
      // fields in area are matched with production files columns
      // so we need to use id of new site in case site has been changed
      // and send previous one to remove the area from previous site
      let _id = id;
      if (siteId !== rowData.siteId) {
        const prevEntity = rawAreas.find(
          (rawArea) => rawArea.siteId === siteId,
        );

        _id = prevEntity?.id as number;
      }
      const dataToSend = {
        id: _id,
        prevEntityId: siteId !== rowData.siteId ? rowData.entityId : undefined,
        siteId,
        prevAreaName: rowData.areaName !== area ? rowData.areaName : undefined,
        values: {
          [area]: values.map((internalValues) =>
            internalValues.map((value) => ({
              areaName: value.fieldName,
              areaValue: value.fieldValue,
            })),
          ),
        },
      };

      dispatch(
        updateArea({
          data: dataToSend,
          filters: {
            count: {},
            list: filterList,
          },
        }),
      );
    }

    onClose();
  };

  const onDelete = () => {
    if (rowData) {
      dispatch(
        deleteArea({
          data: { siteId: rowData.siteId, area: rowData.areaName },
          filters: {
            count: {},
            list: filterList,
          },
        }),
      );
    }

    onClose();
  };

  if (!initialValues) {
    return null;
  }

  return (
    <AreaForm
      initialData={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      onDelete={onDelete}
      isOpen={isOpen}
    />
  );
};
