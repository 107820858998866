import React from 'react';
import { useTheme } from '@mui/joy';

export const HEADER_HEIGHT = 54;
export const TABS_HEIGHT = 54;
export const SIDE_BAR_MENU_ITEM_HEIGHT = 40;
export const SIDE_BAR_TEXT_OFFSET_STEP = 26;
export const SIDE_BAR_ICON_OFFSET_STEP = 20;
export const SIDEBAR_WIDTH = {
  expanded: 260,
  collapsed: 64,
};
export const SIDEBAR_PADDING = {
  expanded: 16,
  collapsed: 8,
};

const pageWithCopyrightContentHeight = `calc(100vh - var(--bottom-copyright-height) - ${HEADER_HEIGHT}px)`;
const pageWithTabsHeight = `calc(100vh - var(--bottom-copyright-height) - ${
  HEADER_HEIGHT + TABS_HEIGHT
}px)`;

const defaultSideBarState = 'expanded' as const;

interface ISideBar {
  width: number;
  padding: number;
  state: keyof typeof SIDEBAR_WIDTH;
}

interface ISideBarColors {
  text: string;
  textActive: string;
  background: string;
}

interface IMainLayoutContext {
  headerHeight: number;
  sideBarMenuItemHeight: number;
  sideBarTextOffsetStep: number;
  sideBarIconOffsetStep: number;
  sideBarColors: ISideBarColors;
  pageWithCopyrightContentHeight: string;
  pageWithTabsHeight: string;
  sideBar: ISideBar;
  isSmallScreen: boolean;
  toggleSideBar: () => void;
  createTransition: (cssProperties: string | Array<string>) => string;
}

export const MainLayoutContext = React.createContext<IMainLayoutContext>({
  headerHeight: HEADER_HEIGHT,
  isSmallScreen: false,
  sideBarMenuItemHeight: SIDE_BAR_MENU_ITEM_HEIGHT,
  sideBarTextOffsetStep: SIDE_BAR_TEXT_OFFSET_STEP,
  sideBarIconOffsetStep: SIDE_BAR_ICON_OFFSET_STEP,
  pageWithCopyrightContentHeight,
  pageWithTabsHeight,
  sideBarColors: {
    text: '',
    textActive: '',
    background: '',
  },
  sideBar: {
    state: defaultSideBarState,
    width: SIDEBAR_WIDTH[defaultSideBarState],
    padding: SIDEBAR_PADDING[defaultSideBarState],
  },
  toggleSideBar: () => ({}),
  createTransition: () => '',
});

export const MainLayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const theme = useTheme();

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.innerWidth <= theme.breakpoints.values.md,
  );

  const defaultSideBarState =
    window.innerWidth <= theme.breakpoints.values.md ? 'collapsed' : 'expanded';

  const [sideBar, setSideBar] = React.useState<ISideBar>({
    state: defaultSideBarState,
    width: SIDEBAR_WIDTH[defaultSideBarState],
    padding: SIDEBAR_PADDING[defaultSideBarState],
  });

  const pageWithCopyrightContentHeight = React.useMemo(
    () => `calc(100vh - var(--bottom-copyright-height) - ${HEADER_HEIGHT}px)`,
    [],
  );

  const pageWithTabsHeight = React.useMemo(
    () =>
      `calc(100vh - var(--bottom-copyright-height) - ${
        HEADER_HEIGHT + TABS_HEIGHT
      }px)`,
    [],
  );

  const toggleSideBar = React.useCallback(() => {
    setSideBar((prev) => {
      const newState = prev.state === 'collapsed' ? 'expanded' : 'collapsed';

      return {
        ...prev,
        state: newState,
        width: SIDEBAR_WIDTH[newState],
        padding: SIDEBAR_PADDING[newState],
      };
    });
  }, []);

  const createTransition = React.useCallback(
    (cssProperties: string | Array<string>) => {
      const { create, duration, easing } = theme.transitions;

      return create(cssProperties, {
        duration:
          sideBar.state === 'collapsed'
            ? duration.leavingScreen
            : duration.enteringScreen,
        easing: easing.sharp,
      });
    },
    [sideBar.state, theme.transitions],
  );

  React.useEffect(() => {
    setIsSmallScreen(window.innerWidth <= theme.breakpoints.values.md);
  }, [theme.breakpoints.values.md]);

  return (
    <MainLayoutContext.Provider
      value={{
        sideBar,
        isSmallScreen,
        toggleSideBar,
        createTransition,
        headerHeight: HEADER_HEIGHT,
        pageWithCopyrightContentHeight,
        pageWithTabsHeight,
        sideBarMenuItemHeight: SIDE_BAR_MENU_ITEM_HEIGHT,
        sideBarTextOffsetStep: SIDE_BAR_TEXT_OFFSET_STEP,
        sideBarIconOffsetStep: SIDE_BAR_ICON_OFFSET_STEP,
        sideBarColors: {
          text: theme.palette.gray[400],
          textActive: theme.palette.gray[200],
          background: theme.palette.gray[950],
        },
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => {
  return React.useContext(MainLayoutContext);
};
