import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../components/EnhancedTable';
import {
  closeOpenPositionRequest,
  deleteOpenPositionRequest,
  getRecruitingDashboardDetailsCountRequest,
  getRecruitingDashboardDetailsListRequest,
} from '../../modules/actions';
import {
  getRecruitingDashboardDetailsCount,
  getRecruitingDashboardDetailsList,
} from '../../modules/selectors/recruitingDashboardDetails';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { useTranslation } from 'react-i18next';
import {
  useSearch,
  useFetchPositionsCombobox,
  useFilter,
  useRecruitingDashboardDetailsDefaultFilters,
  useBrowserHistoryFunctions,
} from '../../modules/utils';
import { ContentLayout } from '../../components/Layout/ContentLayout';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import { ModelsToDelete, paths } from '../../config';
import { IdsArray } from '../../modules/types';
import { getPositionsComboboxListByIdWithNameAsId } from '../../modules/selectors/position';
import { ComboBoxOption } from '../../components/ComboBox';

import { CloseActionMenuItemType } from '../../components/EnhancedTable/EnhancedTableToolbarMenu';
import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Typography,
} from '@mui/material';
import { getOpenPositionRefreshKey } from '../../modules/selectors/openPosition';
import Prompt from '../../components/Prompt';
import {
  parse,
  format,
  composeDate,
  DATE_FORMAT,
  DATETIME_TIMEZONE,
} from '../../modules/utils/dateWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faXmarkSquare } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_ORDER_BY = 'openPositionId';
const DEFAULT_ORDER = 'desc';

const RecruitingDashboardDetails = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();
  const { t } = useTranslation();
  const composeSearch = useSearch();

  const defaultFilter = useRecruitingDashboardDetailsDefaultFilters();

  const { siteId } = useParams();

  const fetchPositionsCombobox = useFetchPositionsCombobox();

  const [modal, setModal] = useState<JSX.Element | null>(null);

  useEffect(() => {
    fetchPositionsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch recruiting dashboard list
  const list = useSelector(getRecruitingDashboardDetailsList, shallowEqual);
  const count = useSelector(getRecruitingDashboardDetailsCount, shallowEqual);

  // tslint:disable-next-line:cyclomatic-complexity
  const computedList = useMemo(
    () =>
      map(list, (item) => {
        return {
          ...item,
          fillPercent: `${Math.round(item.fillPercent)}%`,
          dateRequested: item.dateRequested
            ? composeDate(
                item.dateRequested,
                parse(DATETIME_TIMEZONE),
                format(DATE_FORMAT),
              )
            : '',
        };
      }),
    [list],
  );

  // create dispatcher
  const dispatcher = useDispatch();
  const { filterCount, filterList } = useFilter(defaultFilter);

  const positions = useSelector(getPositionsComboboxListByIdWithNameAsId);

  // compose table header cells
  const headCells: HeadCell[] = [
    {
      id: 'openPositionId',
      disablePadding: true,
      label: t('recruiting_dashboards_details.openPositionId'),
    },
    {
      id: 'position',
      disablePadding: true,
      label: t('recruiting_dashboards_details.position'),
    },
    {
      id: 'openPositions',
      disablePadding: true,
      label: t('recruiting_dashboards_details.openPositions'),
    },
    {
      id: 'dateRequested',
      disablePadding: true,
      label: t('recruiting_dashboards_details.dateRequested'),
    },
    {
      id: 'whoRequested',
      disablePadding: true,
      label: t('recruiting_dashboards_details.whoRequested'),
    },
    {
      id: 'fillPercent',
      disablePadding: true,
      label: t('recruiting_dashboards_details.fillPercent'),
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('recruiting_dashboards_details.description'),
    },
    {
      id: 'requisitionNumber',
      disablePadding: false,
      label: t('recruiting_dashboards_details.requisitionNumber'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'openPositionId',
      label: t('recruiting_dashboards_details.openPositionId'),
      operator: 'eq',
    },
    {
      name: 'position',
      label: t('recruiting_dashboards_details.position'),
      operator: 'eq',
      type: 'combobox',
      options: positions as ComboBoxOption[],
    },
    {
      name: 'siteName',
      label: t('recruiting_dashboards_details.siteName'),
      operator: 'like',
    },
    {
      name: 'dateRequested',
      label: t('recruiting_dashboards_details.dateRequested'),
      operator: 'eq',
      type: 'date',
    },
    {
      name: 'whoRequested',
      label: t('recruiting_dashboards_details.whoRequested'),
      operator: 'like',
    },
    {
      name: 'description',
      label: t('recruiting_dashboards_details.description'),
      operator: 'like',
    },
    {
      name: 'requisitionNumber',
      label: t('recruiting_dashboards_details.requisitionNumber'),
      operator: 'like',
    },
  ];

  // make request to fetch recruitingDashboard details when component is mounted
  useEffect(() => {
    if (!siteId) {
      return;
    }

    dispatcher(
      getRecruitingDashboardDetailsListRequest({ siteId, filter: filterList }),
    );
    // get total count
    dispatcher(
      getRecruitingDashboardDetailsCountRequest({
        siteId,
        filter: filterCount,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]); // dispatch the action only once

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    if (!siteId) {
      return;
    }

    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...defaultFilter.where,
      ...rawWhere,
    };

    dispatcher(
      getRecruitingDashboardDetailsListRequest({
        siteId,
        filter: {
          filter: {
            limit: rowsPerPage,
            offset,
            where,
            order,
          },
        },
      }),
    );
    // update count accordingly to applied filters
    dispatcher(
      getRecruitingDashboardDetailsCountRequest({
        siteId,
        filter: { filter: { where } },
      }),
    );
  };

  const handleUpdating = (ids: IdsArray) => {
    if (!siteId) {
      return;
    }

    const search = composeSearch({ ids });
    pushToHistory({
      pathname: paths.RECRUITING_DASHBOARD_UPDATE_OPEN_POSITION.replace(
        ':siteId',
        siteId,
      ),
      search,
    });
  };

  const handleDeletion = (ids: IdsArray) => {
    if (!siteId) {
      return;
    }

    dispatcher(
      deleteOpenPositionRequest({
        siteId,
        ids,
      }),
    );
  };

  const renderAdditionalActions = (
    ids: IdsArray,
    closeCallback: CloseActionMenuItemType,
  ): React.ReactElement<MenuItemProps>[] => {
    // handle view
    const handleView = () => {
      if (!siteId) {
        return;
      }

      const search = composeSearch({ ids });
      pushToHistory({
        pathname: paths.RECRUITING_DASHBOARD_OPEN_POSITION_VIEW.replace(
          ':siteId',
          siteId,
        ),
        search,
      });
      closeCallback();
    };

    // handle close
    const handleClose = () => {
      setModal(
        <Prompt
          message={
            <>
              <p>{t('recruiting_dashboards_details.confirm_closing')}</p>
            </>
          }
          onOk={() => {
            setModal(null);
            dispatcher(closeOpenPositionRequest(ids));
          }}
          onCancel={() => setModal(null)}
        />,
      );
      closeCallback();
    };

    return [
      <MenuItem key="applicant-menu-item-view" onClick={handleView}>
        <ListItemIcon>
          <FontAwesomeIcon color="black" icon={faEye} />
        </ListItemIcon>
        <Typography variant="inherit" color="primary">
          {t('recruiting_dashboards_details.view_details')}
        </Typography>
      </MenuItem>,
      <MenuItem key="applicant-menu-item-close" onClick={handleClose}>
        <ListItemIcon>
          <FontAwesomeIcon fontSize={18} color="black" icon={faXmarkSquare} />
        </ListItemIcon>
        <Typography variant="inherit" color="primary">
          {t('recruiting_dashboards_details.close')}
        </Typography>
      </MenuItem>,
    ].filter((elm) => !!elm) as React.ReactElement<MenuItemProps>[];
  };

  return (
    <ContentLayout>
      {modal}
      <EnhancedTable
        data={computedList}
        count={count}
        selectIndex="openPositionId"
        defaultOrderBy={DEFAULT_ORDER_BY}
        defaultOrder={DEFAULT_ORDER}
        tableName={t('recruiting_dashboards_details.table_name')}
        headCells={headCells}
        filters={filters}
        onSync={onSync}
        onDelete={handleDeletion}
        onUpdate={handleUpdating}
        renderActions={renderAdditionalActions}
        deleteModelName={ModelsToDelete.OpenPositions}
        additionalWhereForExport={defaultFilter.where}
      />
    </ContentLayout>
  );
};
/**
 * Wrapper to refresh component on flush store
 */
export default function RecruitingDashboardDetailsRefreshable() {
  return (
    <RecruitingDashboardDetails key={useSelector(getOpenPositionRefreshKey)} />
  );
}
