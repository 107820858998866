import { HeadCell, HeadCellWithOrderBy } from '../index';

export function addHeadCellOrderByGetter(
  headCell: HeadCell,
): HeadCellWithOrderBy {
  const headCellInternal = {
    ...headCell,
    orderBy: headCell.orderByAnotherField || headCell.id,
  };

  return (headCellInternal as unknown) as HeadCellWithOrderBy;
}

export default addHeadCellOrderByGetter;
