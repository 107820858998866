import React, { useState } from 'react';
import { noop, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Snackbar } from '@mui/material';

import {
  useWikiList,
  useWikiPagination,
} from '../../modules/utils/hooks/wiki-setup.hooks';
import EnhancedTable, {
  HeadCell,
  IEnhancedTableProps,
} from '../../components/EnhancedTable';
import { Button } from '../../components';
import { Alert } from '../../components/Alert';
import { WikiPageTypeEnum } from '../../config/wiki-pages-types';
import { ITableFilter } from '../../components/EnhancedTable/EnhancedTableFilter';
import { getQueryParams } from '../../modules/utils';
import { IWikiPage } from '../../modules/types/wiki-setup';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { useHasUserAccessToAction } from '../../config';
import { manageEntitiesConfig } from '../../config/manageEntitiesConfig';

const WikiSetupList: React.FC = () => {
  const { t } = useTranslation();
  const { wikiList, onUpdate, onDelete, redirectToDetailView } = useWikiList();
  const setPagination = useWikiPagination();

  const allowedToDelete = useHasUserAccessToAction(
    manageEntitiesConfig.pages.delete.id,
  );
  const allowedToUpdate = useHasUserAccessToAction(
    manageEntitiesConfig.pages.update.id,
  );

  const [alert, setAlert] = useState<string>('');

  const customPagination = React.useMemo<
    IEnhancedTableProps<IWikiPage>['customPagination']
  >(
    () => ({
      hideNextButton: !wikiList?._links?.next,
      hidePrevButton: !wikiList?._links?.prev,
      onPageChange: (event, newPage, item) => {
        const type = item?.type === 'previous' ? 'prev' : 'next';
        const { cursor } = getQueryParams(wikiList?._links[type] || '');

        if (cursor) {
          setPagination(cursor as string, type);
        }
      },
    }),
    [setPagination, wikiList?._links],
  );

  const headCells: HeadCell[] = [
    {
      id: 'title',
      disablePadding: false,
      label: t('common.title'),
    },
    {
      id: 'sites',
      disablePadding: false,
      disableSorting: true,
      label: t('knowledge_setup.sites'),
    },
    {
      id: 'author',
      disablePadding: false,
      disableSorting: true,
      label: t('knowledge_setup.author'),
    },
    {
      id: 'pageType',
      disablePadding: false,
      disableSorting: true,
      label: t('knowledge_setup.type'),
    },
    {
      id: 'detailView',
      disablePadding: false,
      disableSorting: true,
      label: t('knowledge_setup.detail_view'),
    },
  ];

  // table filters
  const filters: ITableFilter[] = [
    {
      name: 'title',
      label: t('common.title'),
      operator: 'like',
    },
  ];

  const computedList = React.useMemo(
    () =>
      (wikiList?.results || []).map((item) => ({
        ...item,
        sites: map(
          item.metadata?.properties.meta.value.siteObjects,
          'name',
        ).join(', '),
        author: `${item.metadata?.properties.meta.value.author?.firstName} ${item.metadata?.properties.meta.value.author?.lastName}`,
        pageType:
          WikiPageTypeEnum[item.metadata?.properties.meta.value.pageType || ''],
        detailView: (
          <Button onClick={redirectToDetailView(item.id!)} variant="outlined">
            {t('knowledge_setup.detail_view')}
          </Button>
        ),
      })),
    [wikiList, redirectToDetailView, t],
  );

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <Snackbar
          open={!!alert}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={setAlert.bind(null, '')}
        >
          <Alert onClose={setAlert.bind(null, '')} severity="warning">
            {alert}
          </Alert>
        </Snackbar>
        <EnhancedTable
          data={computedList}
          selectIndex="id"
          defaultOrderBy="title"
          tableName={t('knowledge_setup.table_name')}
          headCells={headCells}
          filters={filters}
          onSync={noop}
          onDelete={allowedToDelete ? onDelete : undefined}
          onUpdate={allowedToUpdate ? onUpdate : undefined}
          customPagination={customPagination}
        />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default WikiSetupList;
