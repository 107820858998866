import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/joy';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/joy/Tooltip';

import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerBodySectionTitle,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import SitesComboBox from 'src/components/Formik/comboboxes-with-entities/SitesCombobox';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { addProcessStatus } from 'src/modules/actions';
import { useIncentivePricingSettingsForm } from 'src/modules/utils/hooks/incentive-pricing-settings';
import {
  MUI_SIZE_1,
  MUI_SIZE_10,
  MUI_SIZE_12,
  MUI_SIZE_2,
  MUI_SIZE_5,
} from 'src/config';
import { PlusSvg } from 'src/components/svgIcons';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { AnyObject, IncentivePriceSettingsOptions } from 'src/modules/types';
import { ComboBoxOption } from 'src/components/ComboBox';

interface IIncentivePriceSettingsCreateProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialData = {
  siteId: undefined,
  settings: [],
};

export const IncentivePriceSettingsCreate = ({
  isOpen,
  onClose,
}: IIncentivePriceSettingsCreateProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [siteId, setSiteId] = React.useState<number>();

  const validationSchemaForSiteId = Yup.object().shape({
    siteId: Yup.number()
      .required(t('common.required'))
      .typeError(t('common.required')),
  });

  const { settings, sendToServer } = useIncentivePricingSettingsForm(siteId);

  const formik = useFormik({
    initialValues: settings ? { siteId, settings } : initialData,
    validationSchema: validationSchemaForSiteId,
    onSubmit: () => {
      sendToServer(formik.values.settings);

      dispatch(
        addProcessStatus({
          variant: 'success',
          title: 'common.success',
        }),
      );

      setSiteId(undefined);
      formik.resetForm();

      onClose();
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onReset = () => {
    formik.resetForm();
  };

  const changeSite = (
    event: React.ChangeEvent<AnyObject>,
    value: ComboBoxOption | null,
  ) => {
    setSiteId(+value!.id);
  };

  const handleRowDelete = (index: number) => {
    const settings = get(formik.values, ['settings'], []);
    settings.splice(index, 1);

    formik.setFieldValue('settings', settings);
  };

  const addNewOption = () => {
    const s: IncentivePriceSettingsOptions[] = get(
      formik.values,
      ['settings'],
      [],
    );

    const i = s.length;

    s.push({
      name: `Name ${i + 1}`,
      title: `Title ${i + 1}`,
    });

    formik.setFieldValue('settings', s);
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('pricing.create_settings_form_title')}
      </DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              formik={formik}
              label={t('pricing.settings.site')}
              placeholder={t('pricing.settings.site')}
              onChange={changeSite}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid xs={MUI_SIZE_10}>
                <DrawerBodySectionTitle>
                  {t('pricing.meta_tags')}
                </DrawerBodySectionTitle>
              </Grid>
              <Grid xs={MUI_SIZE_2}>
                <Tooltip
                  key={0}
                  title={t('incentivePrice.create_new_meta')}
                  variant="solid"
                >
                  <IconButton onClick={addNewOption}>
                    <PlusSvg />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </FormFieldContainer>

          {formik.values.settings.map((v, index) => (
            <Grid
              container
              spacing={MUI_SIZE_1}
              alignItems="center"
              justifyContent="center"
              key={index}
              sx={{ marginBottom: '1rem', width: '100%' }}
            >
              <Grid md={MUI_SIZE_5} xs={MUI_SIZE_12}>
                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    label={t('pricing.settings.title')}
                    id={`settings[${index}].title`}
                    name={`settings[${index}].title`}
                    formik={formik}
                  />
                </FormFieldContainer>
              </Grid>
              <Grid md={MUI_SIZE_5} xs={MUI_SIZE_12}>
                <FormFieldContainer>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    label={t('pricing.settings.name')}
                    id={`settings[${index}].name`}
                    name={`settings[${index}].name`}
                    formik={formik}
                  />
                </FormFieldContainer>
              </Grid>
              <Grid md={MUI_SIZE_2} xs={MUI_SIZE_12}>
                <FormFieldContainer>
                  <IconButton onClick={() => handleRowDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </FormFieldContainer>
              </Grid>
            </Grid>
          ))}
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onCancel={onClose}
          applyButtonType="submit"
          onApply={formik.handleSubmit}
        />
      </DrawerFooter>
    </Drawer>
  );
};
