import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateCostPlusSettings } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createCostPlusSettingScheme } from './create-cost-plus-settings.scheme';

export const updateCostPlusSettingScheme: JSONSchemaType<
  ICreateCostPlusSettings[]
> = {
  type: 'array',
  items: {
    ...createCostPlusSettingScheme,
    properties: {
      ...createCostPlusSettingScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
