import React from 'react';
import { entities, EntitiesTabs } from './EntitiesTabs';
import { RestoreEmployees } from './tables/RestoreEmployees';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

export const RestoreEntities = () => {
  const [activeTab, setActiveTab] = React.useState(entities[0].name);

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <EntitiesTabs
          activeEntity={activeTab}
          onActiveEntityChange={setActiveTab}
        />

        {activeTab === 'employee' && <RestoreEmployees />}
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};
