import { SagaIterator } from 'redux-saga';

import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICreateProduction,
  IdsArray,
  IFilter,
  IProductionModel,
  ISagaAction,
  ISagaActionBind,
  PayloadWithNavigateFunc,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';
import { paths } from '../../config';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportProductionRequest,
    saga: createExportSaga({
      apiCall: Api.Production.export,
      actionFailed: actions.exportProductionFailed,
      actionSuccess: actions.exportProductionSuccess,
      actionNotification: actions.addProcessStatus,
    }),
  },
  {
    action: actions.createProductionRequest,
    saga: function* ({
      payload: { data, navigate },
    }: ISagaAction<
      PayloadWithNavigateFunc<Array<ICreateProduction>>
    >): SagaIterator {
      try {
        const response: IProductionModel = yield call(
          Api.Production.bulkCreate,
          data,
        );
        yield put(actions.flushProductionState());
        yield put(actions.createProductionSuccess(response));
        yield call(navigate, { pathname: paths.PRODUCTIONS });
      } catch (e) {
        yield put(actions.createProductionFailed());
      }
    },
  },
  {
    action: actions.getProductionListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const {
          data,
          count,
        }: { data: IProductionModel[]; count: number } = yield call(
          Api.Production.list,
          action.payload,
        );
        yield put(actions.getProductionListSuccess(data));
        yield put(actions.getProductionCountSuccess({ count }));
      } catch (e) {
        yield put(actions.getProductionListFailed());
      }
    },
  },
  {
    action: actions.deleteProductionRequest,
    saga: function* (action: ISagaAction<IdsArray>): SagaIterator {
      try {
        yield call(Api.Production.delete, {
          where: { id: { inq: action.payload } },
        });
        yield put(actions.flushProductionState());
      } catch (e) {
        yield put(actions.deleteProductionFailed());
      }
    },
  },
  {
    action: actions.updateProductionsRequest,
    saga: function* ({
      payload: { data, navigate },
    }: ISagaAction<PayloadWithNavigateFunc<IProductionModel[]>>): SagaIterator {
      try {
        yield call(Api.Production.bulkUpdate, data);
        yield put(actions.flushProductionState());
        yield call(navigate, { pathname: paths.PRODUCTIONS });
      } catch (e) {
        yield put(actions.updateProductionsFailed());
      }
    },
  },
  {
    action: actions.getProductionComboboxListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: Partial<IProductionModel>[] = yield call(
          Api.Production.list,
          action.payload,
        );
        yield put(actions.getProductionComboboxListSuccess(response));
      } catch (e) {
        yield put(actions.getProductionComboboxListFailed());
      }
    },
  },
];
