import React from 'react';
import { useTranslation } from 'react-i18next';

import { paths } from '../../config';
import { ReportTable } from '../../components/ReportPage/ReportTable';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
} from '../../modules/types';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { useFetchReportTableDataAndCountEffect } from '../../modules/utils/hooks/reportPage.hooks';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  siteId?: number;
  year?: number;
  week?: number;
  date?: string;
  area?: string;
  category?: string;
  chargeType?: string;
}

export const TargetDailyCpu: React.FC<IProps> = ({
  siteId,
  year,
  area,
  chargeType,
  week,
  date,
  category,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'yearWeek',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      year,
      area,
      chargeType,
      week,
      category,
      date,
    }),
    [siteId, year, area, chargeType, week, category, date],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >(paths.TARGET_DAILY_CPU, {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `${paths.TARGET_DAILY_CPU}/count`,
    { count: 0 },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'year',
        orderConfig: { orderBy: 'year' },
        label: t('productions_uph_reports.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'week' },
        label: t('productions_uph_reports.week'),
      },
      {
        id: 'date',
        orderConfig: { orderBy: 'date' },
        label: t('productions_uph_reports.date'),
      },
      {
        id: 'yearWeek',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('productions_uph_reports.year_week'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'category',
        orderConfig: { orderBy: 'category' },
        label: t('productions_uph_reports.category'),
      },
      {
        id: 'chargeType',
        orderConfig: { orderBy: 'chargeType' },
        label: t('productions_uph_reports.chargeType'),
      },
      {
        id: 'area',
        orderConfig: { orderBy: 'area' },
        label: t('productions_uph_reports.area'),
      },
      {
        id: 'qty',
        orderConfig: { orderBy: 'qty' },
        label: t('productions_uph_reports.qty'),
      },
      {
        id: 'price',
        orderConfig: { orderBy: 'price' },
        label: t('productions_uph_reports.price'),
      },
      {
        id: 'cpu',
        orderConfig: { orderBy: 'cpu' },
        label: t('productions_uph_reports.cpu'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <ReportTable
      data={data.items as any}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey={paths.TARGET_DAILY_CPU}
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
