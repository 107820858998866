import React from 'react';
import { useDispatch } from 'react-redux';
import CreateFormLayout from '../../components/Layout/CreateFormLayout';
import { createPointTypeRequest } from '../../modules/actions/pointType';
import {
  useBrowserHistoryFunctions,
  useValidate,
} from '../../modules/utils/hooks';
import { useTranslation } from 'react-i18next';
import { FormActions } from '../../components/Form/FormActions';
import { useFormik } from 'formik';
import FormikTextField from '../../components/Formik/FormikTextField';
import { createPointTypeScheme } from '../../modules/schemes/point-types';
import SitesComboBox from '../../components/Formik/comboboxes-with-entities/SitesCombobox';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';
import { FormFieldContainer } from '../../components/Form/FormFieldContainer';

const DEFAULT_POINTS_LIFETIME = 90;

const initialValues = {
  siteId: NaN,
  reason: '',
  point: NaN,
  lifetime: DEFAULT_POINTS_LIFETIME,
};

const PointTypesCreate = () => {
  const { pushToHistory: navigate } = useBrowserHistoryFunctions();
  const { t } = useTranslation();

  const dispatcher = useDispatch();

  const validate = useValidate(createPointTypeScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      dispatcher(createPointTypeRequest({ data, navigate }));
    },
  });

  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <CreateFormLayout
          cardTitle={t('point_types.create_form_card_title')}
          handleFormSubmit={formik.handleSubmit as any}
          fields={formik.values}
          isFormValid={formik.isValid}
        >
          {/**@@TODO: fix inputProps */}
          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              id="reason"
              label={t('point_types.reason')}
              name="reason"
              formik={formik}

              // inputProps={{
              //   maxLength: REASON_MAX_LENGTH,
              // }}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <SitesComboBox
              id="siteId"
              required={true}
              formik={formik}
              label={t('point_types.site')}
              placeholder={t('point_types.site')}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              variant="outlined"
              required
              fullWidth
              id="point"
              label={t('point_types.point')}
              name="point"
              formik={formik}
              type="number"
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikTextField
              required
              type="number"
              variant="outlined"
              fullWidth
              id="lifetime"
              label={t('point_types.points_lifetime')}
              name="lifetime"
              formik={formik}
            />
          </FormFieldContainer>

          <FormActions submitBtnTitle={t('common.create')} />
        </CreateFormLayout>
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
};

export default PointTypesCreate;
