import React from 'react';
import { useTranslation } from 'react-i18next';

export const generateSumByEntity = <T extends string, U extends string>(
  data: Array<{ [key in T | U]: any }>,
  entityNameKey: T,
  dataToCountKey: U,
) => {
  const sumBySite = data.reduce<{ [siteName: string]: number }>((acc, item) => {
    acc[item[entityNameKey]] = acc[item[entityNameKey]]
      ? acc[item[entityNameKey]] + item[dataToCountKey]
      : item[dataToCountKey];

    return acc;
  }, {});

  const sumBySiteArray = Object.entries(sumBySite);

  sumBySiteArray.sort((a, b) => b[1] - a[1]);

  return sumBySiteArray;
};

export const calculateTopNSites = (
  sumBySiteArray: Array<[string, number]>,
  n: number,
) => {
  const topNSites = [];

  let i = 0;
  while (i < n && sumBySiteArray[i]) {
    topNSites.push(sumBySiteArray[i][0]);
    i++;
  }

  return topNSites;
};

export const useActiveDurationOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () => [
      { id: 'last7days' as const, label: t('common.last_n_days', { n: 7 }) },
      { id: 'last30days' as const, label: t('common.last_n_days', { n: 30 }) },
    ],
    [t],
  );
};

export const useActiveTopSitesOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () => [
      { id: 'top3' as const, label: t('common.top_n', { n: 3 }) },
      { id: 'top10' as const, label: t('common.top_n', { n: 10 }) },
      { id: 'all' as const, label: t('common.all') },
    ],
    [t],
  );
};
