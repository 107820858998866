import React from 'react';
import { Box, BoxProps } from '@mui/joy';

interface ITableCellWithColorProps extends BoxProps {
  color: string;
}

export const TableCellWithColor = ({
  color,
  children,
  sx,
  ...restBoxProps
}: ITableCellWithColorProps) => {
  return (
    <Box
      sx={{
        height: 24,
        width: 100,
        borderRadius: 4,
        bgcolor: color,
        ...sx,
      }}
      {...restBoxProps}
    >
      {children}
    </Box>
  );
};
