import React from 'react';
import { useSelector } from 'react-redux';
import { getGloballySelectedSites } from 'src/modules/selectors/site';

export const useEmployeesReviewDefaultFilters = () => {
  const globallySelectedSiteIds = useSelector(getGloballySelectedSites);

  return React.useMemo(
    () => ({
      where: {
        siteId: { inq: globallySelectedSiteIds },
      },
      order: ['reviewedAt DESC'],
    }),
    [globallySelectedSiteIds],
  );
};
