import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Datepicker from 'src/components/_ui-kit/Combobox/Pickers/Datepicker';

interface IRecurrenceEndProps {
  recurrenceEndValue: string | number | null;
  onRecurrenceEndChange: (value: string | number | null) => void;
}

export const RecurrenceEnd: React.FC<IRecurrenceEndProps> = ({
  recurrenceEndValue,
  onRecurrenceEndChange,
}) => {
  const [value, setValue] = React.useState('never');

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (e.target.value === 'never') {
      onRecurrenceEndChange(null);
    }
  };

  const onRecurrenceEndDateChange = (value: string | null) => {
    onRecurrenceEndChange(value);
  };

  const onAfterOccurrencesNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    const valueNumber = Number(value);

    if (valueNumber < 1) {
      return;
    }

    onRecurrenceEndChange(valueNumber);
  };

  return (
    <FormControl
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 2,
      }}
    >
      <Typography sx={{ minWidth: 140 }}>Ends</Typography>
      <RadioGroup
        aria-label="recurrence-end"
        value={value}
        name="recurrence-end"
        onChange={onValueChange}
      >
        <FormControlLabel value="never" control={<Radio />} label="Never" />
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <FormControlLabel
            sx={{ marginRight: 4 }}
            value="on"
            control={<Radio />}
            label="On"
          />
          <Box
            sx={{
              width: 195,

              '& .MuiFormControl-marginNormal': {
                marginTop: 0,
              },
            }}
          >
            <Datepicker
              id="recurrence-end-date"
              label=""
              disabled={value !== 'on'}
              value={
                typeof recurrenceEndValue === 'string'
                  ? recurrenceEndValue
                  : null
              }
              onChange={onRecurrenceEndDateChange}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel value="after" control={<Radio />} label="After" />
          <TextField
            type="number"
            sx={{
              '& input': {
                paddingRight: '45px',
              },
            }}
            name="recurrences-quantity"
            disabled={value !== 'after'}
            value={
              typeof recurrenceEndValue === 'number' ? recurrenceEndValue : ''
            }
            onChange={onAfterOccurrencesNumberChange}
          />
          <Box
            component="span"
            sx={[
              {
                display: 'block',
                minWidth: 85,
                marginLeft: '-32px',
                marginRight: '28px',
              },
              value !== 'after' && {
                color: 'rgba(0, 0, 0, 0.26)',
              },
            ]}
          >
            {recurrenceEndValue && Number(recurrenceEndValue) > 1
              ? 'occurrences'
              : 'occurrence'}
          </Box>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
