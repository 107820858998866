import { createAction } from 'redux-act';
import {
  ICountResponse,
  ICreateTerminationReasonRequest,
  IdsArray,
  IFilter,
  ITerminationReasonModel,
  IUpdateTerminationReason,
  PayloadWithNavigateFunc,
} from '../../types';
// don't make this import shorter because of circular importing
import { ExportActionCreator } from '../../utils/helpers/creators/export';

// Flush actions
export const flushTerminationReasonState = createAction(
  'Flush terminationReason data in store',
);

// Creation actions
export const createTerminationReasonRequest = createAction<
  PayloadWithNavigateFunc<ICreateTerminationReasonRequest>
>('Make request to create a new terminationReason');
export const createTerminationReasonSuccess = createAction<
  ITerminationReasonModel
>('New terminationReason has been created successfully');
export const createTerminationReasonFailed = createAction(
  'Cannot create a new terminationReason',
);

// Fetch terminationReasons actions
export const getTerminationReasonListRequest = createAction<IFilter>(
  'Make request to fetch terminationReason list',
);
export const getTerminationReasonListSuccess = createAction<
  ITerminationReasonModel[]
>('TerminationReasons list has been fetched successfully');
export const getTerminationReasonListFailed = createAction(
  'Cannot fetch terminationReason list',
);

// get terminationReasons count actions
// since action creator doesn't support an optional payload we will need to supply en empty object if we don't want to
// pass any where object filters
export const getTerminationReasonCountRequest = createAction<IFilter>(
  'Make request to get terminationReason count',
);
export const getTerminationReasonCountSuccess = createAction<ICountResponse>(
  'TerminationReasons count has been fetched successfully',
);
export const getTerminationReasonCountFailed = createAction(
  'Cannot get terminationReason count',
);

// delete actions
export const deleteTerminationReasonRequest = createAction<IdsArray>(
  'Make request to delete terminationReasons',
);
export const deleteTerminationReasonFailed = createAction(
  'Cannot delete terminationReasons',
);

// Updating actions
export const updateTerminationReasonsRequest = createAction<
  PayloadWithNavigateFunc<IUpdateTerminationReason[]>
>('Make request to update terminationReasons');
export const updateTerminationReasonsFailed = createAction(
  'Cannot update terminationReasons',
);

// Fetch combobox list actions
export const getTerminationReasonComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of terminationReasons',
);
export const getTerminationReasonComboboxListSuccess = createAction<
  Partial<ITerminationReasonModel>[]
>('Combobox list of terminationReasons has been fetched successfully');
export const getTerminationReasonComboboxListFailed = createAction(
  'Cannot fetch combobox terminationReasons list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('TerminationReason').takeExportActions();

export const exportTerminationReasonRequest = exportReportRequest;
export const exportTerminationReasonSuccess = exportReportSuccess;
export const exportTerminationReasonFailed = exportReportFailed;
