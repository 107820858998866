import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchReportTableDataAndCountEffect } from 'src/modules/utils/hooks/reportPage.hooks';
import { useDataFetcherWithData } from '../../modules/utils/hooks/common/reports';
import {
  AnyObject,
  ICountResponse,
  IListWithSummary,
} from '../../modules/types';
import { IHeadCellWithOrderConfig } from '../../modules/types/table';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  useGenerateHeadCellsData,
  useTableCommonHandlers,
  useTableData,
} from '../../modules/utils/hooks/table';
import { ReportTable } from '../../components/ReportPage/ReportTable';
import { useAddReportTableDataToReportExportStateEffect } from 'src/modules/utils/hooks/reports.hooks';

interface IProps {
  siteId?: number;
  year?: number;
  week?: number;
  employeeId?: number;
}

export const PayboardSummary: React.FC<IProps> = ({
  siteId,
  year,
  week,
  employeeId,
}) => {
  const { t } = useTranslation();

  const defaultOrderDetails = {
    order: 'desc' as const,
    orderBy: 'year',
  };

  const where = React.useMemo(
    () => ({
      siteId,
      year,
      week,
      employeeId,
    }),
    [siteId, year, week, employeeId],
  );

  const { data, fetchData, isDataLoading } = useDataFetcherWithData<
    IListWithSummary<AnyObject>
  >('/taylor-farms/payboard-summary', {
    items: [],
    summary: [],
  });
  const {
    data: countData,
    fetchData: fetchCountData,
    isDataLoading: isCountDataLoading,
  } = useDataFetcherWithData<ICountResponse>(
    `/taylor-farms/payboard-summary/count`,
    { count: 0 },
  );

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'extEmployeeId',
        orderConfig: { orderBy: 'extEmployeeId' },
        label: t('payboard.extEmployeeId'),
      },
      {
        id: 'badge',
        orderConfig: { orderBy: 'badge' },
        label: t('payboard.badge'),
      },
      {
        id: 'firstName',
        orderConfig: { orderBy: 'firstName' },
        label: t('payboard.first_name'),
      },
      {
        id: 'lastName',
        orderConfig: { orderBy: 'lastName' },
        label: t('payboard.last_name'),
      },
      {
        id: 'staffingProvider',
        orderConfig: { orderBy: 'staffingProvider' },
        label: 'Staffing Provider',
      },
      {
        id: 'payRate',
        orderConfig: { orderBy: 'payRate' },
        label: t('payboard.payrate'),
      },
      {
        id: 'supervisor',
        orderConfig: { orderBy: 'supervisor' },
        label: t('payboard.supervisor'),
      },
      {
        id: 'siteName',
        orderConfig: { orderBy: 'siteName' },
        label: t('payboard.site'),
      },
      {
        id: 'year',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('payboard.year'),
      },
      {
        id: 'week',
        orderConfig: { orderBy: 'yearWeek' },
        label: t('payboard.week'),
      },
      {
        id: 'markup',
        orderConfig: { orderBy: 'markup' },
        label: t('payboard.markup'),
      },
      {
        id: 'regularHours',
        orderConfig: { orderBy: 'regularHours' },
        label: t('payboard.reg_hrs'),
        orderByAnotherField: 'regularHours',
      },
      {
        id: 'overtimeHours',
        orderConfig: { orderBy: 'overtimeHours' },
        label: t('payboard.ot_hrs'),
      },
      {
        id: 'regularPay',
        orderConfig: { orderBy: 'regularPay' },
        label: t('payboard.reg_pay'),
      },
      {
        id: 'overtimePay',
        orderConfig: { orderBy: 'overtimePay' },
        label: t('payboard.ot_pay'),
      },
      {
        id: 'overtimePremiumPay',
        orderConfig: { orderBy: 'overtimePremiumPay' },
        label: t('payboard.ot_premium_pay'),
      },
      {
        id: 'totalPay',
        orderConfig: { orderBy: 'totalPay' },
        label: t('payboard.total_pay'),
      },
      {
        id: 'markupCost',
        orderConfig: { orderBy: 'markupCost' },
        label: t('payboard.markup_cost'),
      },
    ],
    [t],
  );

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const {
    order,
    orderBy,
    setOrder,
    limit,
    setLimit,
    page,
    setPage,
    setOrderBy,
  } = useTableData({
    headCellsOrderDetails,
    defaultPage: DEFAULT_PAGE,
    defaultLimit: DEFAULT_LIMIT,
    defaultOrder: defaultOrderDetails.order,
    defaultOrderBy: defaultOrderDetails.orderBy,
  });

  const {
    handleChangeRowsPerPage,
    handlePageChange,
    handleSort,
  } = useTableCommonHandlers({
    page,
    order,
    setOrder,
    limit,
    setPage,
    setLimit,
    setOrderBy,
    orderBy,
    where,
    fetchData,
  });

  useFetchReportTableDataAndCountEffect({
    fetchData,
    fetchCountData,
    setPage,
    where,
    limit,
    order: order ?? defaultOrderDetails.order,
    orderBy: orderBy ?? defaultOrderDetails.orderBy,
  });

  useAddReportTableDataToReportExportStateEffect({
    filter: React.useMemo(
      () => ({
        limit,
        where,
        offset: page,
        order: [`${orderBy} ${order}`],
      }),
      [limit, order, orderBy, page, where],
    ),
    localization: React.useMemo(
      () =>
        data.summary.reduce((acc, item) => {
          acc[item.title] = t(item.title);
          return acc;
        }, {}),
      [data.summary, t],
    ),
  });

  return (
    <ReportTable
      data={data.items as any}
      count={countData.count}
      summaryData={data.summary}
      isDataLoading={isDataLoading || isCountDataLoading}
      tableUniqueKey="/taylor-farms/payboard-summary"
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      rowsPerPage={limit}
      currentPageNumber={page}
      onSort={handleSort}
      onRowsPerPageChange={handleChangeRowsPerPage}
      onPageChange={(_, page) => handlePageChange(page)}
    />
  );
};
