import React from 'react';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { clients } from 'src/modules/constants';
import { Report, ReportPage } from 'src/components/ReportPage';
import { WeeklyCpu } from './WeeklyCpu';
import { WeeklyGrossMargin } from './WeeklyGrossMargin';
import { WeeklyGrossMarginDetailed } from './WeeklyGrossMarginDetailed';
import DailyUphBySite from './DailyUphBySite';
import WeeklyUphBySite from './WeeklyUphBySite';
import {
  ICarpartsReportsFilterPanelFilters,
  CarpartsReportsFilterPanel,
} from '../Carparts/CarpartsReportsFilterPanel';
import { useTranslation } from 'react-i18next';
import { PageContentWithTopToolbar } from '../../components/PageContent';
import { ReportPageItemContainer } from '../../components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from '../../components/ReportPage/ReportTableToolbar';
import { SelectHeadCells } from '../../components/Table/components/TableActions/components/SelectHeadCells';
import { UploadImportingFile } from '../../components/Table/components/TableActions/components/UploadImportingFile';

const client = clients.carparts;

const reportsDefaultFilters: ICarpartsReportsFilterPanelFilters = {
  year: undefined,
  week: undefined,
  siteId: undefined,
};

export const CarpartsReports = () => {
  const { t } = useTranslation();

  const reportsProps = useReportsProps(client);

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof ICarpartsReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      { id: 'siteId', label: t('productions_uph_reports.site.name') },
      { id: 'year', label: t('productions_uph_reports.year') },
      { id: 'week', label: t('productions_uph_reports.week') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    ICarpartsReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={client}>
        <ReportPageItemContainer>
          <ReportTableToolbar reportName={t('main_menu.carparts.main')}>
            <SelectHeadCells />
            <UploadImportingFile clientKey={client} />
          </ReportTableToolbar>
        </ReportPageItemContainer>
        <ReportPageItemContainer>
          <CarpartsReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        <Report {...reportsProps.dailyUphBySite}>
          <ReportPageItemContainer fullHeight>
            <DailyUphBySite />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySite}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySite />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyCpu}>
          <ReportPageItemContainer fullHeight>
            <WeeklyCpu
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMargin}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin
              siteId={appliedFilters.siteId}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
