import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { useFormik } from 'formik';
import { useFetchPointTypesCombobox, useValidate } from 'src/modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ICreatePoint, IFilterData, IWhere } from 'src/modules/types';
import { ComboBoxOption } from 'src/components/ComboBox';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { generateFormDataFields } from 'src/modules/utils/helpers/form';
import { createPointScheme } from 'src/modules/schemes/points';
import { createPointRequest } from 'src/modules/actions';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { getPointTypesComboboxList } from 'src/modules/selectors/pointType';
import { getEmployeesComboboxList } from 'src/modules/selectors/employee';
import FormikDatetimepicker from 'src/components/Formik/FormikDatetimepicker';
import { EmployeesComboBox } from 'src/components/Formik/comboboxes-with-entities/EmployeesComboBox';
import FormikImageUpload from 'src/components/Formik/FormikImageUpload';

const initialValues = {
  employeeId: NaN,
  datetime: '',
  pointTypeId: NaN,
};

interface ICreatePointProps {
  isOpen: boolean;
  onClose: () => void;
  filterList: IFilterData;
  filterCount: IWhere;
}

export const CreatePoint = ({
  isOpen,
  onClose,
  filterCount,
  filterList,
}: ICreatePointProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validate = useValidate(createPointScheme);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (data) => {
      const formData = generateFormDataFields(data);

      dispatch(
        createPointRequest({
          data: (formData as unknown) as ICreatePoint,
          filters: {
            list: filterList,
            count: filterCount,
          },
        }),
      );

      formik.resetForm();

      onClose();
    },
  });

  const fetchPointTypesCombobox = useFetchPointTypesCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole);

  // make request to fetch shifts, positions and departments from the server if we don't have them in the store
  React.useEffect(() => {
    fetchPointTypesCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  // fetch pointTypes list from store
  const pointTypes = useSelector(getPointTypesComboboxList);
  const employeesCombobox = useSelector(getEmployeesComboboxList);

  const pointsTypesBySiteId = React.useMemo(() => {
    const selectedEmployeeDetails = employeesCombobox.find(
      (employee) => employee.id === formik.values.employeeId,
    );

    if (!selectedEmployeeDetails) {
      return [];
    }

    return pointTypes.filter(
      (pointType) => pointType.siteId === selectedEmployeeDetails.entity.siteId,
    );
  }, [employeesCombobox, formik.values.employeeId, pointTypes]);

  const onReset = () => {
    formik.resetForm();
  };

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>{t('points.add')}</DrawerHeader>
      <DrawerBody>
        <DrawerBodyContent>
          <FormFieldContainer>
            <FormikDatetimepicker
              required
              fullWidth
              id="datetime"
              label={t('points.datetime')}
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <EmployeesComboBox
              required
              onlyActive
              id="employeeId"
              propertyAsID="id"
              label={t('points.employee')}
              placeholder={t('common.select')}
              formik={formik}
            />
          </FormFieldContainer>
          <FormFieldContainer>
            <FormikCombobox
              required
              id="pointTypeId"
              label={t('points.type')}
              placeholder={t('common.select')}
              options={pointsTypesBySiteId as ComboBoxOption[]}
              formik={formik}
            />
          </FormFieldContainer>

          <FormFieldContainer>
            <FormikImageUpload
              name="file"
              isImageFromPropsSourceOfTruth
              inputId="create_point_attachment"
              file={formik.values.file}
              formik={formik}
            />
          </FormFieldContainer>
        </DrawerBodyContent>
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
