import { Box } from '@mui/joy';
import * as React from 'react';

export * from './LeftCombobox';
export * from './RightCombobox';
export * from './ComboboxSeparator';

interface IDoubledComboboxProps {
  children: React.ReactNode;
}

export const DoubledCombobox = ({ children }: IDoubledComboboxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        transform: 'translate(1px, 0)',
        bgcolor: 'colors.background.bg_secondary',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
};
