import { createReducer } from 'redux-act';
import * as actions from '../../actions';

import {
  ICountResponse,
  IAreaBasedBaselineModel,
  IAreaBasedBaselineWithRelations,
  IAreaBasedBaselineState,
} from '../../types';
import { ExportReducerCreator } from '../../utils/helpers/creators/export';

const defaultState: IAreaBasedBaselineState = {
  list: [],
  refreshKey: 0,
  count: 0,
};

const flushAreaBasedBaselineState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

const createAreaBasedBaselineRequest = (state: IAreaBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const createAreaBasedBaselineSuccess = (
  state: IAreaBasedBaselineState,
  payload: IAreaBasedBaselineModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const createAreaBasedBaselineFailed = (state: IAreaBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const getAreaBasedBaselineListRequest = (state: IAreaBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getAreaBasedBaselineListSuccess = (
  state: IAreaBasedBaselineState,
  payload: IAreaBasedBaselineWithRelations[],
) => ({
  ...state,
  list: payload,
  error: false,
  is_data_requested: false,
});
const getAreaBasedBaselineListFailed = (state: IAreaBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const getAreaBasedBaselineCountRequest = (state: IAreaBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const getAreaBasedBaselineCountSuccess = (
  state: IAreaBasedBaselineState,
  payload: ICountResponse,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});
const getAreaBasedBaselineCountFailed = (state: IAreaBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const deleteAreaBasedBaselineRequest = (state: IAreaBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const deleteAreaBasedBaselineFailed = (state: IAreaBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const updateAreaBasedBaselinesRequest = (state: IAreaBasedBaselineState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});
const updateAreaBasedBaselinesFailed = (state: IAreaBasedBaselineState) => ({
  ...state,
  is_data_requested: false,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IAreaBasedBaselineState>();

const exportAreaBasedBaselineRequest = exportRequest;
const exportAreaBasedBaselineSuccess = exportSuccess;
const exportAreaBasedBaselineFailed = exportFailed;

/**
 * AreaBasedBaseline reducer
 */
export const areaBasedBaseline = createReducer<IAreaBasedBaselineState>(
  {},
  defaultState,
)
  // flush actions
  .on(actions.flushAreaBasedBaselineState, flushAreaBasedBaselineState)
  // creation actions
  .on(actions.createAreaBasedBaselineRequest, createAreaBasedBaselineRequest)
  .on(actions.createAreaBasedBaselineSuccess, createAreaBasedBaselineSuccess)
  .on(actions.createAreaBasedBaselineFailed, createAreaBasedBaselineFailed)
  // get list actions
  .on(actions.getAreaBasedBaselineListRequest, getAreaBasedBaselineListRequest)
  .on(actions.getAreaBasedBaselineListSuccess, getAreaBasedBaselineListSuccess)
  .on(actions.getAreaBasedBaselineListFailed, getAreaBasedBaselineListFailed)

  // get count actions
  .on(
    actions.getAreaBasedBaselineCountRequest,
    getAreaBasedBaselineCountRequest,
  )
  .on(
    actions.getAreaBasedBaselineCountSuccess,
    getAreaBasedBaselineCountSuccess,
  )
  .on(actions.getAreaBasedBaselineCountFailed, getAreaBasedBaselineCountFailed)
  // deletion actions
  .on(actions.deleteAreaBasedBaselineRequest, deleteAreaBasedBaselineRequest)
  .on(actions.deleteAreaBasedBaselineFailed, deleteAreaBasedBaselineFailed)
  // updating actions
  .on(actions.updateAreaBasedBaselinesRequest, updateAreaBasedBaselinesRequest)
  .on(actions.updateAreaBasedBaselinesFailed, updateAreaBasedBaselinesFailed)
  // export actions
  .on(actions.exportAreaBasedBaselineRequest, exportAreaBasedBaselineRequest)
  .on(actions.exportAreaBasedBaselineSuccess, exportAreaBasedBaselineSuccess)
  .on(actions.exportAreaBasedBaselineFailed, exportAreaBasedBaselineFailed);

export default areaBasedBaseline;
