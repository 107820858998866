import { ICardinalHealthWeeklyReportModel } from '../../types/cardinal-health';
import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const actionCreator = new ListWithSummaryActionCreator(
  'CardinalHealthWeeklyUphBySiteAndEmployee',
);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyUphBySiteAndEmployeeListRequest,
  getListSuccess: getCardinalHealthWeeklyUphBySiteAndEmployeeListSuccess,
  getListFailed: getCardinalHealthWeeklyUphBySiteAndEmployeeListFailed,
} = actionCreator.takeListActions<ICardinalHealthWeeklyReportModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyUphBySiteAndEmployeeCountRequest,
  getCountSuccess: getCardinalHealthWeeklyUphBySiteAndEmployeeCountSuccess,
  getCountFailed: getCardinalHealthWeeklyUphBySiteAndEmployeeCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyUphBySiteAndEmployeeRequest,
  exportReportSuccess: exportCardinalHealthWeeklyUphBySiteAndEmployeeSuccess,
  exportReportFailed: exportCardinalHealthWeeklyUphBySiteAndEmployeeFailed,
} = new ExportActionCreator(
  'CardinalHealthWeeklyUphBySiteAndEmployee',
).takeExportActions();
