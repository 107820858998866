import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikCombobox, {
  IFormikComboboxProps,
} from 'src/components/Formik/FormikCombobox';
import { AnyObject } from 'src/modules/types';

export const usePunchTypesOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(
    () => [
      { id: 'regular' as any, name: t('logtimes.regular') },
      { id: 'pto' as any, name: t('logtimes.pto') },
      { id: 'sick' as any, name: t('logtimes.sick') },
      { id: 'holiday' as any, name: t('logtimes.holiday') },
      { id: 'retroPay' as any, name: t('logtimes.retro_pay') },
    ],
    [t],
  );
};

interface IPunchTypeComboBoxProps
  extends Omit<IFormikComboboxProps, 'options'> {
  id: string;
  formik: AnyObject;
}

export const PunchTypeComboBox: React.FC<IPunchTypeComboBoxProps> = ({
  formik,
  id,
  ...rest
}) => {
  const punchTypesOptions = usePunchTypesOptions();

  return (
    <FormikCombobox
      {...rest}
      setToUndefinedOnFieldClear
      id={id}
      formik={formik}
      options={punchTypesOptions}
    />
  );
};
