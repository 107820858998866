import React from 'react';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import { highchartsReactComponentProps, plotOptionsSeries } from '../common';
import { Box } from '@mui/joy';

exporting(Highcharts);

interface IHorizontalBarChartProps {
  height: string;
  fullscreenTitle?: string;
  series: SeriesOptionsType[];
  categories?: string[];
  chart?: Highcharts.ChartOptions;
  yAxis?: Highcharts.YAxisOptions | Array<Highcharts.YAxisOptions>;
  tooltip?: Highcharts.TooltipOptions;
  plotOptions?: Highcharts.PlotOptions;
  xAxis?: Omit<Highcharts.XAxisOptions, 'categories'>;
  legend?: Highcharts.LegendOptions;
}

export const HorizontalBarChart = React.forwardRef<
  Highcharts.Chart,
  IHorizontalBarChartProps
>(
  (
    {
      categories,
      series,
      tooltip,
      chart,
      xAxis,
      yAxis,
      plotOptions,
      legend,
      height,
      fullscreenTitle,
    },
    ref,
  ) => {
    const options: Highcharts.Options = {
      chart: {
        type: 'bar',
        ...(fullscreenTitle
          ? {
              events: {
                fullscreenOpen: function () {
                  (this as any).update({
                    title: {
                      text: fullscreenTitle,
                      align: 'left',
                    },
                  });
                },
                fullscreenClose: function () {
                  (this as any).update({
                    title: {
                      text: '',
                    },
                  });
                },
              },
            }
          : {}),
        ...chart,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: xAxis
        ? xAxis
        : {
            title: {
              text: null,
            },
            categories,
          },
      yAxis: yAxis
        ? yAxis
        : ({
            min: 0,
            title: {
              enabled: false,
            },
            labels: {
              overflow: 'justify',
            },
          } as Highcharts.YAxisOptions),
      legend: legend
        ? legend
        : {
            enabled: true,
          },
      tooltip: {
        ...tooltip,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        ...plotOptions,
        bar: {
          ...plotOptions?.bar,
          dataLabels: {
            ...plotOptions?.bar?.dataLabels,
            enabled: true,
            align: 'right',
            color: '#FFFFFF',
            x: -4,
            style: {
              fontSize: '10px',
              lineHeight: '12px',
              textOutline: 'none',
              textShadow: '0px 0px 2px rgba(0, 0, 0, 1)',
            },
          },
        } as Highcharts.PlotBarOptions,
        series: {
          ...plotOptions?.series,
          ...plotOptionsSeries,
        } as Highcharts.PlotSeriesOptions,
      },
      exporting: {
        enabled: false,
      },

      series,
    };

    return (
      <Box sx={{ minWidth: '100%', height }}>
        <HighchartsReact
          ref={ref}
          options={options}
          highcharts={Highcharts}
          containerProps={highchartsReactComponentProps.containerProps}
        />
      </Box>
    );
  },
);
