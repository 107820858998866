import { createAction } from 'redux-act';
import { ExportActionCreator } from 'src/modules/utils/helpers/creators/export';
import {
  ICountResponse,
  ICreateQuestionCategoryRequest,
  IdsArray,
  IFilter,
  IQuestionCategoryModel,
  IUpdateQuestionCategoryRequest,
  PayloadWithFilters,
} from '../../types';

export const flushQuestionCategoryState = createAction(
  'Flush question category data in store',
);

export const createQuestionCategoryRequest = createAction<
  PayloadWithFilters<ICreateQuestionCategoryRequest>
>('Make request to create a new question category');
export const createQuestionCategorySuccess = createAction<
  IQuestionCategoryModel
>('New question category has been created successfully');
export const createQuestionCategoryFailed = createAction(
  'Cannot create a new question category',
);

export const getQuestionCategoryListRequest = createAction<IFilter>(
  'Make request to fetch question category list',
);
export const getQuestionCategoryListSuccess = createAction<
  IQuestionCategoryModel[]
>('QuestionCategory list has been fetched successfully');
export const getQuestionCategoryListFailed = createAction(
  'Cannot fetch question category list',
);

export const getQuestionCategoryCountRequest = createAction<IFilter>(
  'Make request to get question category count',
);
export const getQuestionCategoryCountSuccess = createAction<ICountResponse>(
  'QuestionCategory count has been fetched successfully',
);
export const getQuestionCategoryCountFailed = createAction(
  'Cannot get question category count',
);

export const deleteQuestionCategoryRequest = createAction<
  PayloadWithFilters<IdsArray>
>('Make request to delete question categories');
export const deleteQuestionCategoryFailed = createAction(
  'Cannot delete question categories',
);

export const updateQuestionCategoriesRequest = createAction<
  PayloadWithFilters<IUpdateQuestionCategoryRequest[]>
>('Make request to update question categories');
export const updateQuestionCategoriesFailed = createAction(
  'Cannot update question categories',
);

export const getQuestionCategoryComboboxListRequest = createAction<IFilter>(
  'Make request to fetch combobox list of question categories',
);
export const getQuestionCategoryComboboxListSuccess = createAction<
  Partial<IQuestionCategoryModel>[]
>('Combobox list of question categories has been fetched successfully');
export const getQuestionCategoryComboboxListFailed = createAction(
  'Cannot fetch combobox question categories list',
);

const {
  exportReportFailed,
  exportReportRequest,
  exportReportSuccess,
} = new ExportActionCreator('QuestionCategory').takeExportActions();

export const exportQuestionCategoryRequest = exportReportRequest;
export const exportQuestionCategorySuccess = exportReportSuccess;
export const exportQuestionCategoryFailed = exportReportFailed;
