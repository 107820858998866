import React from 'react';
import {
  ChartsDashboard,
  ChartsDashboardBody,
  ChartsDashboardChart,
  ChartsDashboardChartsContainer,
} from 'src/components/ChartsDashboard';
import { HRDashboardActiveVsNeeded } from './HRDashboardActiveVsNeeded';
import {
  HRDashboardFilterPanel,
  IHrDashboardFilters,
} from './HRDashboardFilterPanel';
import { useSelector } from 'react-redux';
import {
  getGloballySelectedSites,
  getNonTestSitesComboboxList,
} from 'src/modules/selectors/site';
import { HRDashboardTerminationReason } from './HRDashboardTerminationReason';
import { HRDashboardEmployeeTurnover } from './HRDashboardEmployeeTurnover';
import { HRDashboardNetEmployees } from './HRDashboardNetEmployees';
import { HRDashboardActiveByShift } from './HRDashboardActiveByShift';

const hrDashboardFilterInitialValue = {
  siteId: undefined,
};

export const HRDashboard = () => {
  const sites = useSelector(getNonTestSitesComboboxList);
  const globalSitesIds = useSelector(getGloballySelectedSites);

  const [filters, setFilters] = React.useState<IHrDashboardFilters>({
    siteId: sites[0]?.id ?? globalSitesIds[0],
  });

  return (
    <>
      <HRDashboardFilterPanel
        filterInitialData={filters}
        onApplyFilters={setFilters}
        onRemoveFilters={() => setFilters(hrDashboardFilterInitialValue)}
      />

      <ChartsDashboard>
        <ChartsDashboardBody>
          <ChartsDashboardChartsContainer>
            <ChartsDashboardChart>
              <HRDashboardActiveVsNeeded
                siteId={filters.siteId}
                shiftId={filters.shiftId}
              />
            </ChartsDashboardChart>
            <ChartsDashboardChart>
              <HRDashboardTerminationReason
                siteId={filters.siteId}
                shiftId={filters.shiftId}
              />
            </ChartsDashboardChart>
          </ChartsDashboardChartsContainer>
          <ChartsDashboardChartsContainer>
            <ChartsDashboardChart>
              <HRDashboardEmployeeTurnover
                siteId={filters.siteId}
                shiftId={filters.shiftId}
              />
            </ChartsDashboardChart>
            <ChartsDashboardChart>
              <HRDashboardNetEmployees
                siteId={filters.siteId}
                shiftId={filters.shiftId}
              />
            </ChartsDashboardChart>
            <ChartsDashboardChart>
              <HRDashboardActiveByShift siteId={filters.siteId} />
            </ChartsDashboardChart>
          </ChartsDashboardChartsContainer>
        </ChartsDashboardBody>
      </ChartsDashboard>
    </>
  );
};
