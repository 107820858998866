import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  ICreateCostPlusSettings,
  IdsArray,
  IFilter,
  ICostPlusSettingsModel,
  ISagaAction,
  ISagaActionBind,
  CostPlusTypes,
  PayloadWithFilters,
} from '../types';

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.createCostPlusSettingsRequest,
    saga: function* ({
      payload: data,
    }: ISagaAction<ICreateCostPlusSettings>): SagaIterator {
      try {
        const response: ICostPlusSettingsModel = yield call(
          Api.CostPlusSettings.create,
          data,
        );
        yield put(actions.flushCostPlusSettingsState());
        yield put(actions.createCostPlusSettingsSuccess(response));
      } catch (e) {
        yield put(actions.createCostPlusSettingsFailed());
      }
    },
  },
  {
    action: actions.getCostPlusSettingsListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICostPlusSettingsModel[] = yield call(
          Api.CostPlusSettings.list,
          action.payload,
        );
        yield put(actions.getCostPlusSettingsListSuccess(response));
      } catch (e) {
        yield put(actions.getCostPlusSettingsListFailed());
      }
    },
  },
  {
    action: actions.getCostPlusSettingsCountRequest,
    saga: function* (action?: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: ICountResponse = yield call(
          Api.CostPlusSettings.count,
          action?.payload,
        );
        yield put(actions.getCostPlusSettingsCountSuccess(response));
      } catch (e) {
        yield put(actions.getCostPlusSettingsCountFailed());
      }
    },
  },
  {
    action: actions.deleteCostPlusSettingsRequest,
    saga: function* (
      action: ISagaAction<PayloadWithFilters<{ ids: IdsArray }>>,
    ): SagaIterator {
      const {
        filters,
        data: { ids },
      } = action.payload;
      try {
        yield call(Api.CostPlusSettings.delete, {
          where: { id: { inq: ids } },
        });
        yield put(
          actions.getCostPlusSettingsListRequest({ filter: filters.list }),
        );
        yield put(
          actions.getCostPlusSettingsCountRequest({ filter: filters.count }),
        );
      } catch (e) {
        yield put(actions.deleteCostPlusSettingsFailed());
      }
    },
  },
  {
    action: actions.updateCostPlusSettingsRequest,
    saga: function* ({
      payload: { data, filters },
    }: ISagaAction<
      PayloadWithFilters<ICostPlusSettingsModel[]>
    >): SagaIterator {
      try {
        yield call(Api.CostPlusSettings.bulkUpdate, data);
        yield put(
          actions.getCostPlusSettingsListRequest({ filter: filters.list }),
        );
        yield put(
          actions.getCostPlusSettingsCountRequest({ filter: filters.count }),
        );
      } catch (e) {
        yield put(actions.updateCostPlusSettingsFailed());
      }
    },
  },
  {
    action: actions.getCostPlusSettingsComboboxListRequest,
    saga: function* (action: ISagaAction<IFilter>): SagaIterator {
      try {
        const response: Partial<ICostPlusSettingsModel>[] = yield call(
          Api.CostPlusSettings.list,
          action.payload,
        );
        yield put(actions.getCostPlusSettingsComboboxListSuccess(response));
      } catch (e) {
        yield put(actions.getCostPlusSettingsComboboxListFailed());
      }
    },
  },
  {
    action: actions.getCostPlusSettingsTypesRequest,
    saga: function* (): SagaIterator {
      try {
        const response: CostPlusTypes = yield call(Api.CostPlusSettings.types);
        yield put(actions.getCostPlusSettingsTypesSuccess(response));
      } catch (e) {
        yield put(actions.getCostPlusSettingsTypesFailed());
      }
    },
  },
];
