import React from 'react';
import { Input, Sheet, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SearchSvg } from 'src/components/svgIcons';
import { useMainLayoutContext } from '../../../MainLayoutContext';

interface ISideBarMenuItemSearchProps {
  value: string;
  onSearchTermChange: (searchTerm: string) => void;
}

export const SideBarMenuItemSearch = ({
  value,
  onSearchTermChange,
}: ISideBarMenuItemSearchProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    sideBar,
    toggleSideBar,
    createTransition,
    sideBarColors,
  } = useMainLayoutContext();

  const onSearchIconClick = () => {
    if (sideBar.state === 'collapsed') {
      toggleSideBar();
    }
  };

  return (
    <Sheet
      sx={{
        py: 1.5,
        px: `${
          sideBar.state === 'expanded' ? sideBar.padding : sideBar.padding
        }px`,
        backgroundColor: 'inherit',
        transition: createTransition('padding'),
      }}
    >
      <Input
        size="lg"
        value={value}
        onChange={(e) => onSearchTermChange(e.target.value)}
        placeholder={t('common.search')}
        type="search"
        name="main_menu_search"
        autoComplete="off"
        startDecorator={
          <SearchSvg
            fill={theme.palette.gray[400]}
            onClick={onSearchIconClick}
          />
        }
        sx={{
          px: 1.5,
          backgroundColor: sideBarColors.background,
          borderColor: 'gray.700',
          '& .MuiInput-input': {
            color: 'gray.400',
          },
          boxShadow: 'sx',
        }}
      />
    </Sheet>
  );
};
