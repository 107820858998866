import { JSONSchemaType } from 'ajv';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { IIncentiveCreateRequest } from '../../types/incentive';

export const createIncentiveScheme: JSONSchemaType<IIncentiveCreateRequest> = {
  type: 'object',
  required: ['siteId', 'week', 'year'],
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    siteId: {
      type: 'number',
    },
    week: {
      type: 'number',
    },
    year: {
      type: 'number',
    },
  },
};
