import {
  ICardinalHealthDailyRawReportState,
  ICardinalHealthDailyReportModel,
} from '../../types/cardinal-health';
import { ListWithSummaryReducerCreator } from '../../utils/helpers/creators/listWithSummary';
import { createReducer } from 'redux-act';
import * as actions from '../../actions';

const defaultState: ICardinalHealthDailyRawReportState = {
  list: [],
  count: 0,
};

const reducerCreator = new ListWithSummaryReducerCreator();

/**
 * Get list actions
 */
const {
  getListRequest: getCardinalHealthDailyReportListRequest,
  getListSuccess: getCardinalHealthDailyReportListSuccess,
  getListFailed: getCardinalHealthDailyReportListFailed,
} = reducerCreator.takeListHandlers<
  ICardinalHealthDailyRawReportState,
  ICardinalHealthDailyReportModel
>();

/**
 * Get count actions
 */
const {
  getCountRequest: getCardinalHealthDailyReportCountRequest,
  getCountSuccess: getCardinalHealthDailyReportCountSuccess,
  getCountFailed: getCardinalHealthDailyReportCountFailed,
} = reducerCreator.takeCountHandlers<ICardinalHealthDailyRawReportState>();

/**
 * actually Reducer
 */
export const cardinalHealthDailyReport = createReducer<
  ICardinalHealthDailyRawReportState
>({}, defaultState)
  // get list actions
  .on(
    actions.getCardinalHealthDailyReportListRequest,
    getCardinalHealthDailyReportListRequest,
  )
  .on(
    actions.getCardinalHealthDailyReportListSuccess,
    getCardinalHealthDailyReportListSuccess,
  )
  .on(
    actions.getCardinalHealthDailyReportListFailed,
    getCardinalHealthDailyReportListFailed,
  )
  // get count actions
  .on(
    actions.getCardinalHealthDailyReportCountRequest,
    getCardinalHealthDailyReportCountRequest,
  )
  .on(
    actions.getCardinalHealthDailyReportCountSuccess,
    getCardinalHealthDailyReportCountSuccess,
  )
  .on(
    actions.getCardinalHealthDailyReportCountFailed,
    getCardinalHealthDailyReportCountFailed,
  );

export default cardinalHealthDailyReport;
