import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBrowserHistoryFunctions } from '../../modules/utils';
import { ContentLayout } from '../../components/Layout/ContentLayout';
import {
  getIssuesFromIssueTrackerForTable,
  getIssueTrackerRefreshKey,
} from 'src/modules/selectors/issue-tracker';
import { getIssuesFromIssueTrackerListRequest } from 'src/modules/actions';
import {
  Table,
  TableContent,
  TableTitle,
  TableToolbar,
} from 'src/components/Table';
import { useIssuesFromIssueTrackerHeadCellsConfig } from 'src/modules/utils/hooks/issue-tracker';
import { useGenerateHeadCellsData } from 'src/modules/utils/hooks/table';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from '../../components/PageContent';

const IssueTrackerIssuesList = () => {
  const { pushToHistory } = useBrowserHistoryFunctions();

  const list = useSelector(getIssuesFromIssueTrackerForTable);

  const dispatch = useDispatch();

  const { headCells } = useGenerateHeadCellsData(
    useIssuesFromIssueTrackerHeadCellsConfig(),
  );

  useEffect(() => {
    dispatch(getIssuesFromIssueTrackerListRequest({}));
  }, [dispatch]); // dispatch the action only once

  return (
    <ContentLayout>
      <Table tableUniqueKey="issue_tracker_list">
        <TableToolbar>
          <TableTitle>Issues</TableTitle>
        </TableToolbar>
        <TableContent
          data={list}
          headCells={headCells}
          onTableRowClick={(e, row) =>
            pushToHistory(`/issue-tracker/details/${row.id}`)
          }
        />
      </Table>
    </ContentLayout>
  );
};

export default function IssueTrackersListRefreshable() {
  return (
    <PageContentWithTopToolbar>
      <PageContentChildContainer fullHeight={false}>
        <IssueTrackerIssuesList key={useSelector(getIssueTrackerRefreshKey)} />
      </PageContentChildContainer>
    </PageContentWithTopToolbar>
  );
}
