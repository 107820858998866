import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getIssueTypesByIds,
  getIssueTypesComboboxList,
} from '../../selectors/issueType';
import { IdsArray, IStoreState } from '../../types';
import { isEmpty } from 'lodash';
import {
  getIssueTypeComboboxListRequest,
  getIssueTypeListRequest,
} from '../../actions/issueType';
import { useSiteRelationInclusion } from '.';
import { defaultOrderDetails } from '../helpers/filter';

export const issueTypeInclusions = [
  {
    relation: 'site',
  },
];

export const issueTypeComboboxFilter = {
  filter: {
    fields: {
      id: true,
      name: true,
    },
    include: issueTypeInclusions,
  },
};

/**
 * A custom hook to fetch issueTypes from store if they exist otherwise to make a request to fetch needed issueTypes from
 * the server
 */
export const useFetchIssueTypesByIds = (ids: IdsArray) => {
  const dispatcher = useDispatch();
  // fetch issueTypes list from store
  const issueTypes = useSelector(
    (state) => getIssueTypesByIds(state as IStoreState)(ids),
    shallowEqual,
  );
  return () => {
    if (isEmpty(issueTypes)) {
      dispatcher(
        getIssueTypeListRequest({
          filter: {
            where: {
              id: {
                inq: ids,
              },
            },
            include: issueTypeInclusions,
          },
        }),
      );
    }
  };
};

/**
 * A custom hook to fetch issueType combobox list from store if they exist otherwise to make a request to fetch them from
 * the server
 */
export const useFetchIssueTypesCombobox = () => {
  const dispatcher = useDispatch();
  // fetch combobox list from store
  const combobox = useSelector(getIssueTypesComboboxList, shallowEqual);
  return () => {
    if (isEmpty(combobox)) {
      dispatcher(getIssueTypeComboboxListRequest(issueTypeComboboxFilter));
    }
  };
};

export const useIssueTypesDefaultFilters = () => {
  const siteInclusion = useSiteRelationInclusion();

  return React.useMemo(
    () => ({
      order: [`${defaultOrderDetails.orderBy} ${defaultOrderDetails.order}`],
      include: [siteInclusion],
    }),
    [siteInclusion],
  );
};
