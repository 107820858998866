import React from 'react';
import {
  Button,
  IconButton,
  Modal,
  Sheet,
  Typography,
  useTheme,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { AlertTriangleSvg, CheckCircleSvg, XCloseSvg } from '../svgIcons';

interface IActionConfirmationProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;

  okBtnText?: string;
  noBtnText?: string;
  type?: 'warning' | 'success';
  title?: string;
  children?: React.ReactNode;
}

export const ActionConfirmation = ({
  open,
  onOk,
  onCancel,
  title,
  // icon,
  children,
  okBtnText,
  noBtnText,
  type = 'warning',
}: IActionConfirmationProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  let btnColor = 'warning' as any;
  let mainColor = theme.palette.colors.background.bg_warning_secondary;
  let iconColor = theme.palette.colors.icons.featured_icon_light_fg_warning;

  if (type === 'success') {
    btnColor = 'success' as any;
    mainColor = theme.palette.colors.background.bg_success_secondary;
    iconColor = theme.palette.colors.icons.featured_icon_light_fg_success;
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={onCancel}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 500,
          borderRadius: 'lg',
          p: 3,
          boxShadow: 'lg',
          backgroundImage: 'url(/images/background_for_delete_icon.png)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Sheet
          sx={{
            bgcolor: 'transparent',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Sheet
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              bgcolor: mainColor,
            }}
          >
            <IconButton
              onClick={onOk}
              sx={{ '&:hover': { bgcolor: 'transparent' } }}
            >
              {type === 'warning' && (
                <AlertTriangleSvg
                  style={{ width: 24, height: 24 }}
                  fill={iconColor}
                />
              )}
              {type === 'success' && (
                <CheckCircleSvg
                  style={{ width: 24, height: 24 }}
                  fill={iconColor}
                />
              )}
            </IconButton>
          </Sheet>
          <Sheet>
            <IconButton onClick={onCancel}>
              <XCloseSvg />
            </IconButton>
          </Sheet>
        </Sheet>
        <Sheet sx={{ bgcolor: 'transparent', pt: 2, pb: 4 }}>
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            {title ?? t('common.confirm_your_action')}
          </Typography>
          <Typography>{children}</Typography>
        </Sheet>
        <Sheet sx={{ display: 'flex' }}>
          <Button
            fullWidth
            size="lg"
            color="neutral"
            variant="outlined"
            sx={{ mr: '6px' }}
            onClick={onCancel}
          >
            {noBtnText ?? t('common.cancel')}
          </Button>
          <Button
            fullWidth
            size="lg"
            color={btnColor}
            sx={{ ml: '6px' }}
            onClick={onOk}
          >
            {okBtnText ?? t('common.ok')}
          </Button>
        </Sheet>
      </Sheet>
    </Modal>
  );
};
