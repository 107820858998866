import React from 'react';
import { FormikHelpers, FormikState } from 'formik/dist/types';
import { get, isNumber } from 'lodash';

import { AnyObject } from '../../types';
import FormikTextField from '../../../components/Formik/FormikTextField';
import FormikLangCombobox from '../../../components/Formik/FormikLangCombobox';
import FormikMultipleSelect from '../../../components/Formik/FormikMultipleSelect';
import FormikCombobox from '../../../components/Formik/FormikCombobox';
import SupervisorComboBox from '../../../components/Formik/comboboxes-with-entities/SupervisorComboBox';
import { ComboBoxOption } from '../../../components/ComboBox';
import { useGetUserFieldsByRole } from './users';
import { FormFieldContainer } from '../../../components/Form/FormFieldContainer';
import { MultipleSelectOption } from 'src/components/Formik/FormikMultipleSelectWithSearch';

interface IRenderUserOtherFields<Values> {
  formik: FormikHelpers<Values> & FormikState<Values>;
  shifts: ComboBoxOption[];
  departments: ComboBoxOption[];
  sites?: MultipleSelectOption[];
  staffingProviders?: ComboBoxOption[];
  clients?: ComboBoxOption[];
}

export const useRenderUserOtherFields = <Values extends AnyObject>({
  sites,
  clients,
  shifts,
  staffingProviders,
  formik,
  departments,
}: IRenderUserOtherFields<Values>) => {
  const getConfig = useGetUserFieldsByRole();

  const getFields = React.useCallback(
    (index?: number) => {
      const role = isNumber(index)
        ? get(formik.values, [index, 'role'])
        : formik.values.role;

      const commonFields = getConfig(role).map((i) => {
        const fieldName = isNumber(index) ? `${index}.${i.name}` : i.name;

        switch (i.type) {
          case 'email':
            return (
              <FormFieldContainer>
                <FormikTextField
                  key={fieldName}
                  variant="outlined"
                  required={i.required}
                  disabled={isNumber(index)}
                  fullWidth
                  id={fieldName}
                  label={i.label}
                  name={fieldName}
                  autoComplete={fieldName}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'text':
            return (
              <FormFieldContainer>
                <FormikTextField
                  key={fieldName}
                  variant="outlined"
                  required={i.required}
                  fullWidth
                  id={fieldName}
                  label={i.label}
                  name={fieldName}
                  autoComplete={fieldName}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'lang':
            return (
              <FormFieldContainer>
                <FormikLangCombobox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'sites':
            return (
              <FormFieldContainer>
                <FormikMultipleSelect
                  key={fieldName}
                  id={fieldName}
                  title={i.label}
                  formik={formik}
                  required={i.required}
                  options={sites ?? []}
                />
              </FormFieldContainer>
            );
          default:
            return null;
        }
      });

      const fields = getConfig(role).map((i) => {
        const fieldName = isNumber(index) ? `${index}.${i.name}` : i.name;

        switch (i.type) {
          case 'shift':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  id={fieldName}
                  required={i.required}
                  label={i.label}
                  placeholder={i.label}
                  options={shifts}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'department':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  id={fieldName}
                  required={i.required}
                  label={i.label}
                  placeholder={i.label}
                  options={departments}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'supervisor':
            return (
              <FormFieldContainer>
                <SupervisorComboBox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  siteId={
                    isNumber(index)
                      ? formik.values[index].sites
                      : formik.values.sites
                  }
                  label={i.label}
                  placeholder={i.label}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'staffings':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  label={i.label}
                  placeholder={i.label}
                  options={staffingProviders ?? []}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          case 'clients':
            return (
              <FormFieldContainer>
                <FormikCombobox
                  key={fieldName}
                  required={i.required}
                  id={fieldName}
                  label={i.label}
                  placeholder={i.label}
                  options={clients ?? []}
                  formik={formik}
                />
              </FormFieldContainer>
            );
          default:
            return null;
        }
      });

      return [...commonFields, ...fields];

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getConfig, formik.values, formik.errors, formik.submitCount],
  );

  return (index?: number) => getFields(index);
};
