import React from 'react';

import { IFilterPanelOption } from 'src/components/FilterPanel';
import { ReportPageFilterPanel } from 'src/components/ReportPage/ReportPageFilterPanel';
import { FilterPanelSitesCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelDatePicker } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelDatePicker';
import { FilterPanelStaffingProvidersCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelStaffingProvidersCombobox';
import { FilterPanelTerminationReasonsCombobox } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelTerminationReasonsCombobox';
import { FilterPanelTextField } from 'src/components/FilterPanel/FilterPanelFilters/FilterPanelTextField';
import { FilterPanelCombobox } from '../../../components/FilterPanel/FilterPanelFilters/FilterPanelCombobox';
import { ComboBoxOption } from '../../../components/ComboBox';

export enum TurnoverDashboardPeriod {
  last7Days = 7,
  last30Days = 30,
}

export interface ITurnoverDashboardFilterPanelFilters {
  siteId?: number;
  staffingProvider?: string;
  terminationReason?: string;
  year?: number;
  week?: number;
  date?: string;
  period?: TurnoverDashboardPeriod;
}

interface ITurnoverDashboardFilterPanelProps {
  onApplyFilters: (
    appliedFilters: ITurnoverDashboardFilterPanelFilters,
  ) => void;
  onRemoveAllFilters: () => void;
  hideSyncBtn?: boolean;
  filtersOptionsConfig?: Array<IFilterPanelOption>;
}

export const TurnoverDashboardFilterPanel = ({
  onApplyFilters,
  hideSyncBtn,
  filtersOptionsConfig,
  onRemoveAllFilters,
}: ITurnoverDashboardFilterPanelProps) => {
  const [filters, setFilters] = React.useState<
    ITurnoverDashboardFilterPanelFilters
  >({});

  const periodOptions: ComboBoxOption[] = [
    {
      id: TurnoverDashboardPeriod.last7Days,
      name: 'Last 7 Days',
    },
    {
      id: TurnoverDashboardPeriod.last30Days,
      name: 'Last 30 Days',
    },
  ];

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<{
      otherFilters: ITurnoverDashboardFilterPanelFilters;
    }>(
      (obj, filterId) => {
        obj.otherFilters[filterId] = filters[filterId];
        return obj;
      },
      {
        otherFilters: {} as ITurnoverDashboardFilterPanelFilters,
      },
    );

    onApplyFilters(appliedFiltersData.otherFilters);
  };

  const renderFilter = (filter: IFilterPanelOption) => {
    switch (filter.id) {
      case 'siteId':
        return (
          <FilterPanelSitesCombobox
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? null}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'staffingProvider':
        return (
          <FilterPanelStaffingProvidersCombobox
            propertyAsId="name"
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id]}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'terminationReason':
        return (
          <FilterPanelTerminationReasonsCombobox
            propertyAsId="name"
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id]}
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'period':
        return (
          <FilterPanelCombobox
            filterId={filter.id}
            options={periodOptions}
            value={
              periodOptions.find((p) => p.id === filters[filter.id]) ??
              periodOptions[1]
            }
            onChange={(_, value) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: value?.id,
              }));
            }}
          />
        );
      case 'date':
        return (
          <FilterPanelDatePicker
            filterId={filter.id}
            value={filters[filter.id] ?? null}
            onChange={(date) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: date,
              }));
            }}
          />
        );
      case 'year':
      case 'week':
        return (
          <FilterPanelTextField
            filterId={filter.id}
            startDecorator={`${filter.label}:`}
            value={filters[filter.id] ?? ''}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                [filter.id]: e.target.value,
              }));
            }}
          />
        );
    }

    return null;
  };

  return (
    <ReportPageFilterPanel
      hideSyncBtn={hideSyncBtn}
      renderFilter={renderFilter}
      filtersOptionsConfig={filtersOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
