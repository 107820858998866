import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/joy';

import { IHeadCellWithOrderConfig } from 'src/modules/types/table';
import {
  useFilterFieldsData,
  useGenerateHeadCellsData,
  useGenerateRequestFilter,
} from 'src/modules/utils/hooks/table';
import { AnyObject, IArea, IAreaListForTable } from 'src/modules/types';
import {
  TableToolbar,
  TableTitle,
  TableActions,
  TableContextProvider,
  TableContent,
  TableComponent,
} from 'src/components/Table';
import { useHasUserAccessToAction } from 'src/config';
import { Filter } from 'src/components/Table/components/TableActions/components/Filter';
import { TextFieldFilter } from 'src/components/Table/components/TableActions/components/Filter/components/TextFieldFilter';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import * as actions from '../../modules/actions';
import {
  getAreaRawList,
  getLoading,
} from 'src/modules/selectors/area.selector';
import { getSitesListFilteredByGloballySelectedSites } from 'src/modules/selectors/site';
import {
  PageContentChildContainer,
  PageContentWithTopToolbar,
} from 'src/components/PageContent';
import { MainContentLoader } from 'src/components/Layout/components/PageTour/MainContentLoader';
import { ComboboxTableFilter } from 'src/components/Table/components/TableActions/components/Filter/components/ComboboxTableFilter';
import { getSiteImportingSettingsList } from 'src/modules/selectors/site-importing-settings.selector';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { CreateAreaForm, UpdateAreaForm } from './areaForms';

interface ISiteOption {
  id: number;
  name: string;
}

function parseAreas(
  rawAreas: IArea[],
  sitesOptions: ISiteOption[],
  t: (key: string) => string,
): IAreaListForTable[] {
  return rawAreas
    .reduce<Array<IAreaListForTable>>((result, item) => {
      const siteOptionFullData = sitesOptions.find(
        (site) => site.id === item.siteId,
      );
      const siteName = siteOptionFullData ? siteOptionFullData.name : '';

      const parsedDataFromMapField = Object.entries(item.map).reduce<any>(
        (nestedResult, [areaName, areaData]) => {
          const data = (areaData as AnyObject[]).reduce<any[]>(
            (acc, nestedItem, i) => {
              const parsedData = Object.entries(nestedItem).map(
                ([fieldKey, fieldValue], index) => ({
                  entityId: item.id,
                  siteId: item.siteId,
                  siteName,
                  areaName,
                  areaGroupIndex: i,
                  areaGroupItemIndex: index,
                  areaGroupName: `${t('common.group')} ${i + 1}`,
                  areaGroupItemFieldName: fieldKey,
                  areaGroupItemFieldValue: fieldValue,
                }),
              );

              return [...acc, ...parsedData];
            },
            [],
          );

          return [...nestedResult, ...data];
        },
        [],
      );

      return [...result, ...parsedDataFromMapField];
    }, [])
    .map((item, i) => ({ ...item, id: i }));
}

export const AreaSetup = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const rawAreas = useSelector(getAreaRawList);
  const isDataLoading = useSelector(getLoading);
  const sites = useSelector(getSitesListFilteredByGloballySelectedSites);
  const siteImportingSettings = useSelector(getSiteImportingSettingsList);

  const [areas, setAreas] = React.useState<Array<IAreaListForTable>>([]);
  const [isCreateAreaFormOpened, setIsCreateAreaFormOpened] = React.useState(
    false,
  );
  const [
    clickedRowData,
    setClickedRowData,
  ] = React.useState<IAreaListForTable | null>(null);

  const allowedToCreateArea = useHasUserAccessToAction(
    manageEntitiesConfig.area.create.id,
  );
  const allowedToUpdateArea = useHasUserAccessToAction(
    manageEntitiesConfig.area.update.id,
  );

  const sitesOptions = React.useMemo(() => {
    const filteredSites = sites.filter((site) => {
      return siteImportingSettings.some(
        (siteImportSetting) => siteImportSetting.siteUuid === site.uuid,
      );
    });

    return filteredSites.map(({ id, name }) => ({ id, name }));
  }, [siteImportingSettings, sites]);

  const headCellsConfig = React.useMemo<IHeadCellWithOrderConfig[]>(
    () => [
      {
        id: 'siteName',
        label: t('area.site_name'),
      },
      {
        id: 'areaName',
        label: t('area.area_name'),
      },
      {
        id: 'areaGroupName',
        label: t('area.area_group_name'),
      },
      {
        id: 'areaGroupItemFieldName',
        label: t('area.field_name'),
      },
      {
        id: 'areaGroupItemFieldValue',
        label: t('area.field_value'),
      },
    ],
    [t],
  );

  const filterFieldsConfiguration = React.useMemo(() => {
    return {
      siteId: {
        value: '',
        property: 'siteId',
        operator: 'eq' as const,
      },
      areaName: {
        value: '',
        property: 'areaName',
        operator: 'eq' as const,
      },
    };
  }, []);

  const {
    filterFields,
    getLabel,
    onFiltersFormSubmit,
    getFilterCommonPropsByFilterName,
  } = useFilterFieldsData({ filterFieldsConfiguration });

  const { headCells, headCellsOrderDetails } = useGenerateHeadCellsData(
    headCellsConfig,
  );

  const filter = useGenerateRequestFilter({
    filterFields,
    headCellsOrderDetails,
    filterFieldsConfiguration,
  });

  const handleRowClick = (_: any, item: IAreaListForTable) => {
    setClickedRowData(item);
  };

  const filterSiteId = (filter.where as any)?.siteId;
  const areaName = (filter.where as any)?.areaName?.eq;

  React.useEffect(() => {
    dispatch(actions.getSiteImportingSettingsListRequest({}));
    dispatch(actions.getSiteListRequest({}));
  }, [dispatch]);

  React.useEffect(() => {
    const args = filterSiteId
      ? { filter: { where: { siteId: filterSiteId } } }
      : undefined;

    dispatch(actions.getAreaRawListRequest(args));
  }, [dispatch, filterSiteId]);

  React.useEffect(() => {
    let parsedAreas = parseAreas(rawAreas, sitesOptions, t);

    if (areaName) {
      const regex = new RegExp(areaName, 'i');

      parsedAreas = parsedAreas.filter((area) => regex.test(area.areaName));
    }

    setAreas(parsedAreas);
  }, [areaName, rawAreas, sitesOptions, t]);

  return (
    <>
      <PageContentWithTopToolbar>
        <PageContentChildContainer fullHeight>
          <TableContextProvider>
            <TableToolbar>
              <TableTitle>{t('area.main')}</TableTitle>

              <TableActions>
                <Filter onSubmit={onFiltersFormSubmit}>
                  <FormFieldContainer>
                    <ComboboxTableFilter
                      options={sitesOptions}
                      label={getLabel({
                        filterName: 'siteId',
                        labelPrefix: t('payboard.site_name'),
                      })}
                      {...getFilterCommonPropsByFilterName('siteId')}
                    />
                  </FormFieldContainer>
                  <FormFieldContainer>
                    <TextFieldFilter
                      label={getLabel({
                        filterName: 'areaName',
                        labelPrefix: t('area.area_name'),
                      })}
                      {...getFilterCommonPropsByFilterName('areaName')}
                    />
                  </FormFieldContainer>
                </Filter>

                <SelectHeadCells />

                {allowedToCreateArea && (
                  <ReportPageActionsGroupItem>
                    <Button
                      sx={{ ml: 1 }}
                      onClick={() => setIsCreateAreaFormOpened(true)}
                    >
                      {t('area.create_area')}
                    </Button>
                  </ReportPageActionsGroupItem>
                )}
              </TableActions>
            </TableToolbar>
            <TableComponent
              heightWithTabs
              tableUniqueKey="setup/areas"
              sx={{
                height: isDataLoading || !areas.length ? '100%' : 'auto',
                maxHeight: `calc(100vh - var(--bottom-copyright-height) - 116px)`,
              }}
            >
              {isDataLoading && <MainContentLoader />}

              <TableContent
                data={areas}
                headCells={headCells}
                onTableRowClick={
                  allowedToUpdateArea ? handleRowClick : undefined
                }
                isDataLoading={isDataLoading}
              />
            </TableComponent>
          </TableContextProvider>
        </PageContentChildContainer>
      </PageContentWithTopToolbar>

      <CreateAreaForm
        isOpen={isCreateAreaFormOpened}
        onClose={() => setIsCreateAreaFormOpened(false)}
        filterList={filterSiteId ? { where: { siteId: filterSiteId } } : {}}
      />
      <UpdateAreaForm
        isOpen={Boolean(clickedRowData)}
        onClose={() => setClickedRowData(null)}
        filterList={filterSiteId ? { where: { siteId: filterSiteId } } : {}}
        rowData={clickedRowData}
      />
    </>
  );
};
