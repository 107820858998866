import React from 'react';
import {
  FilterPanel,
  IFilterPanelOption,
} from '../../../../../components/FilterPanel';
import { useTranslation } from 'react-i18next';
import { FilterPanelSitesCombobox } from '../../../../../components/FilterPanel/FilterPanelFilters/FilterPanelSitesCombobox';
import { FilterPanelYearWeekComboBox } from '../../../../../components/FilterPanel/FilterPanelFilters/FilterPanelYearWeekComboBox';
import { getYearWeekNumberForNWeeksAgoFromNow } from '../../../../../modules/utils/dateWrapper';

export interface IExecutiveSummaryFilters {
  siteId?: number;
  yearWeek?: number;
}

export const executiveSummaryFilterInitialData = {
  siteId: undefined,
  yearWeek: getYearWeekNumberForNWeeksAgoFromNow(2),
};

interface IExecutiveSummaryFilterPanelProps {
  onApplyFilters: (appliedFilters: IExecutiveSummaryFilters) => void;
  onRemoveAllFilters: () => void;
}

export const ExecutiveSummaryFilterPanel = ({
  onApplyFilters,
  onRemoveAllFilters,
}: IExecutiveSummaryFilterPanelProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = React.useState<IExecutiveSummaryFilters>(
    executiveSummaryFilterInitialData,
  );

  const filterOptionsConfig: Array<IFilterPanelOption> = React.useMemo(
    () => [
      {
        id: 'siteId' as const,
        label: t('productions_uph_reports.site.name'),
      },
      {
        id: 'yearWeek',
        label: t('productions_uph_reports.year_week'),
        isPreselected: true,
      },
    ],
    [t],
  );

  const renderFilter = (filter: IFilterPanelOption) => {
    if (filter.id === 'siteId') {
      return (
        <FilterPanelSitesCombobox
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters.siteId ?? null}
          onChange={(_, value) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: value?.id,
            }));
          }}
        />
      );
    }

    if (filter.id === 'yearWeek') {
      return (
        <FilterPanelYearWeekComboBox
          filterId={filter.id}
          startDecorator={`${filter.label}:`}
          value={filters[filter.id] as any}
          onChange={(_, option) => {
            setFilters((prev) => ({
              ...prev,
              [filter.id]: option?.id,
            }));
          }}
        />
      );
    }

    return null;
  };

  const onApplySelectedFilters = (selectedFiltersIds: Array<string>) => {
    const appliedFiltersData = selectedFiltersIds.reduce<
      IExecutiveSummaryFilters
    >((obj, filterId) => {
      obj[filterId] = filters[filterId];

      return obj;
    }, {});

    onApplyFilters(appliedFiltersData);
  };

  return (
    <FilterPanel
      renderFilter={renderFilter}
      filterOptionsConfig={filterOptionsConfig}
      onApplySelectedFilters={onApplySelectedFilters}
      onRemoveAllOptions={onRemoveAllFilters}
    />
  );
};
