import React from 'react';
import ExpeditorsDailyUphBySite from './DailyUphBySite';
import { Report, ReportPage } from 'src/components/ReportPage';
import ExpeditorsDailyUphBySiteAndEmployee from './DailyUphBySiteAndEmployee';
import WeeklyUphBySiteAndShift from './WeeklyUphBySiteAndShift';
import ExpeditorsExceptionsFromProduction from './ExceptionFromProduction';
import WeeklyUphBySiteAndEmployee from './WeeklyUphBySiteAndEmployee';
import ExpeditorsDailyUphBySiteAndShift from './DailyUphBySiteAndShift';
import { ExpeditorsExceptionsFromEmployees } from './ExceptionFromEmployees';
import WeeklyUphBySite from './WeeklyUphBySite';
import { clients } from 'src/modules/constants';
import {
  useCreateReportFiltersConfigWithDefaultOptions,
  useReportsProps,
} from 'src/modules/utils/hooks/reportPage.hooks';
import { ExpeditorsGapsDaily } from './GapsDaily';
import WeeklyUphBySiteAndDepartment from './WeeklyUphBySiteAndDepartment';
import DailyUphBySiteAndDepartment from './DailyUphBySiteAndDepartment';
// import { DailyUphByPickCartons } from './DailyUphByPickCartons';
// import { DailyLphByPickCartons } from './DailyLphByPickCartons';
// import { DailyLphByPutAwayHandlingUnit } from './DailyLphByPutAwayHandlingUnit';
// import { DailyLphByLoadHandlingUnit } from './DailyLphByLoadHandlingUnit';
// import { WeeklyUphByPickCartons } from './WeeklyUphByPickCartons';
// import { WeeklyLphByPickCartons } from './WeeklyLphByPickCartons';
// import { WeeklyLphByPutAwayHandlingUnit } from './WeeklyLphByPutAwayHandlingUnit';
// import { WeeklyLphByLoadHandlingUnit } from './WeeklyLphByLoadHandlingUnit';
import WeeklyGrossMargin from './WeeklyGrossMargin';
import WeeklyGrossMarginDetailed from './WeeklyGrossMarginDetailed';
// import DailyReport from '../../components/Reports/DailyReport';
// import { paths } from '../../config';
import ExpeditorsDailyUphBySiteEmployeeAndShift from './DailyUphBySiteEmployeeAndShift';
import ExpeditorsWeeklyUphBySiteEmployeeAndShift from './WeeklyUphBySiteEmployeeAndShift';
import ExpeditorsDailyUphBySiteEmployeeAndDepartment from './DailyUphBySiteEmployeeAndDepartment';
import ExpeditorsWeeklyUphBySiteEmployeeAndDepartment from './WeeklyUphBySiteEmployeeAndDepartment';
import ExpeditorsDailyUphBySiteShiftAndDepartment from './DailyUphBySiteShiftAndDepartment';
import ExpeditorsWeeklyUphBySiteShiftAndDepartment from './WeeklyUphBySiteShiftAndDepartment';
import ExpeditorsDailyUphBySiteEmployeeShiftAndDepartment from './DailyUphBySiteEmployeeShiftAndDepartment';
import ExpeditorsWeeklyUphBySiteEmployeeShiftAndDepartment from './WeeklyUphBySiteEmployeeShiftAndDepartment';
import { manageEntitiesConfig } from 'src/config/manageEntitiesConfig';
import { useHasUserAccessToAction } from 'src/config';
import {
  ExpeditorsReportsFilterPanel,
  IExpeditorsReportsFilterPanelFilters,
} from './ExpeditorsReportsFilterPanel';
import { useTranslation } from 'react-i18next';
import { PageContentWithTopToolbar } from 'src/components/PageContent';
import { ReportPageItemContainer } from 'src/components/ReportPage/ReportPageItemContainer';
import { ReportTableToolbar } from 'src/components/ReportPage/ReportTableToolbar';
import { ReportPageActionsGroupContainer } from 'src/components/ReportPage/ReportPageActionsGroupContainer';
import { ReportPageActionsGroupItem } from 'src/components/ReportPage/ReportPageActionsGroupItem';
import { SelectHeadCells } from 'src/components/Table/components/TableActions/components/SelectHeadCells';
import { ReportPageExport } from 'src/components/ReportPage/ReportPageExport';
import { UploadImportingFile } from 'src/components/Table/components/TableActions/components/UploadImportingFile';
// import { ChartsContainer } from 'src/components/ReportPage/ChartsContainer';
// import UphChart from 'src/components/ReportPage/UphChart';

const reportsDefaultFilters: IExpeditorsReportsFilterPanelFilters = {
  siteId: undefined,
  shiftId: undefined,
  date: undefined,
  departmentId: undefined,
};

const expeditorsClient = clients.expeditors;

export const ExpeditorsReports = () => {
  const { t } = useTranslation();

  const reportsProps = useReportsProps(expeditorsClient);

  const hasUserAccessToUploadTargetProductionData = useHasUserAccessToAction(
    manageEntitiesConfig[clients.expeditors].upload.id,
  );

  // This config should contain filters from all tables/charts
  // for the current report
  const optionalFiltersConfig: Array<{
    id: keyof IExpeditorsReportsFilterPanelFilters;
    label: string;
  }> = React.useMemo(
    () => [
      {
        id: 'siteId',
        label: t('productions_uph_reports.site.name'),
        isPreselected: true,
        isPermanent: true,
      },
      { id: 'date', label: t('productions_uph_reports.date') },
      { id: 'shiftId', label: t('productions.shift.name') },
      { id: 'departmentId', label: t('productions.department') },
      { id: 'employeeId', label: t('productions_uph_reports.employee') },
    ],
    [t],
  );

  const filtersOptionsConfig = useCreateReportFiltersConfigWithDefaultOptions(
    optionalFiltersConfig,
  );

  const [appliedFilters, setAppliedFilters] = React.useState<
    IExpeditorsReportsFilterPanelFilters
  >(reportsDefaultFilters);

  return (
    <PageContentWithTopToolbar>
      <ReportPage client={expeditorsClient}>
        <ReportPageItemContainer>
          <ReportTableToolbar
            reportName={t('main_menu.reports.expeditors_main')}
          >
            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <SelectHeadCells />
              </ReportPageActionsGroupItem>
            </ReportPageActionsGroupContainer>

            <ReportPageActionsGroupContainer>
              <ReportPageActionsGroupItem>
                <ReportPageExport reportProps={reportsProps} />
              </ReportPageActionsGroupItem>

              {hasUserAccessToUploadTargetProductionData && (
                <ReportPageActionsGroupItem>
                  <UploadImportingFile clientKey={clients.expeditors} />
                </ReportPageActionsGroupItem>
              )}
            </ReportPageActionsGroupContainer>
          </ReportTableToolbar>
        </ReportPageItemContainer>

        <ReportPageItemContainer>
          <ExpeditorsReportsFilterPanel
            filtersOptionsConfig={filtersOptionsConfig}
            onApplyFilters={(filtersToApply) => {
              setAppliedFilters(filtersToApply);
            }}
            onRemoveAllFilters={() => setAppliedFilters(reportsDefaultFilters)}
          />
        </ReportPageItemContainer>

        {/*<Report {...reportsProps.dailyReport}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyReport*/}
        {/*      fetchUrl={paths.EXPEDITORS_DAILY_REPORT}*/}
        {/*      clientKey={clients.expeditors}*/}
        {/*      filterTitle="expeditors_reports.table_names.daily_report"*/}
        {/*      tableTitle="expeditors_reports.table_names.daily_report"*/}
        {/*    />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        <Report {...reportsProps.dailyUphBySite}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySite
              siteId={appliedFilters.siteId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
          {/*<ChartsContainer>*/}
          {/*  <UphChart*/}
          {/*    reportTypeId={reportsProps.dailyUphBySite.reportTypeId}*/}
          {/*    client={expeditorsClient}*/}
          {/*  />*/}
          {/*</ChartsContainer>*/}
        </Report>

        <Report {...reportsProps.dailyUphBySiteAndShift}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySiteAndShift
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyUphBySiteAndEmployee}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySiteAndEmployee
              siteId={appliedFilters.siteId}
              employeeId={appliedFilters.employeeId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyUphBySiteAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <DailyUphBySiteAndDepartment
              siteId={appliedFilters.siteId}
              departmentId={appliedFilters.departmentId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyUphBySiteShiftAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySiteShiftAndDepartment
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              departmentId={appliedFilters.departmentId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyUphBySiteEmployeeAndShift}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySiteEmployeeAndShift
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              employeeId={appliedFilters.employeeId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyUphBySiteEmployeeAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySiteEmployeeAndDepartment
              siteId={appliedFilters.siteId}
              departmentId={appliedFilters.departmentId}
              employeeId={appliedFilters.employeeId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyUphBySiteEmployeeShiftAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsDailyUphBySiteEmployeeShiftAndDepartment
              siteId={appliedFilters.siteId}
              shiftId={appliedFilters.shiftId}
              departmentId={appliedFilters.departmentId}
              employeeId={appliedFilters.employeeId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.exceptionsFromEmployees}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsExceptionsFromEmployees
              siteId={appliedFilters.siteId}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.exceptionsFromProduction}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsExceptionsFromProduction
              siteId={appliedFilters.siteId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySite}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySite
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
          {/*<ChartsContainer>*/}
          {/*  <UphChart*/}
          {/*    reportTypeId={reportsProps.weeklyUphBySite.reportTypeId}*/}
          {/*    client={expeditorsClient}*/}
          {/*  />*/}
          {/*</ChartsContainer>*/}
        </Report>

        <Report {...reportsProps.weeklyUphBySiteAndShift}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySiteAndShift
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.dailyGaps}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsGapsDaily
              siteId={appliedFilters.siteId}
              date={appliedFilters.date}
            />
          </ReportPageItemContainer>
        </Report>

        {/*<Report {...reportsProps.dailyUphByPickCartons}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyUphByPickCartons />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.dailyLphByPickCartons}>*/}
        {/*  <TablesContainer>*/}
        {/*    <DailyLphByPickCartons />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*{isAllowed && (*/}
        {/*  <Report {...reportsProps.dailyLphByPutAwayHandlingUnit}>*/}
        {/*    <DailyLphByPutAwayHandlingUnit />*/}
        {/*  </Report>*/}
        {/*)}*/}
        {/*{isAllowed && (*/}
        {/*  <Report {...reportsProps.dailyLphByLoadHandlingUnit}>*/}
        {/*    <DailyLphByLoadHandlingUnit />*/}
        {/*  </Report>*/}
        {/*)}*/}
        {/*<Report {...reportsProps.weeklyUphByPickCartons}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyUphByPickCartons />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*<Report {...reportsProps.weeklyLphByPickCartons}>*/}
        {/*  <TablesContainer>*/}
        {/*    <WeeklyLphByPickCartons />*/}
        {/*  </TablesContainer>*/}
        {/*</Report>*/}
        {/*{isAllowed && (*/}
        {/*  <Report {...reportsProps.weeklyLphByPutAwayHandlingUnit}>*/}
        {/*    <WeeklyLphByPutAwayHandlingUnit />*/}
        {/*  </Report>*/}
        {/*)}*/}
        {/*{isAllowed && (*/}
        {/*  <Report {...reportsProps.weeklyLphByLoadHandlingUnit}>*/}
        {/*    <WeeklyLphByLoadHandlingUnit />*/}
        {/*  </Report>*/}
        {/*)}*/}
        <Report {...reportsProps.weeklyUphBySiteAndEmployee}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySiteAndEmployee
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySiteAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <WeeklyUphBySiteAndDepartment
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              departmentId={appliedFilters.departmentId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySiteShiftAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsWeeklyUphBySiteShiftAndDepartment
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              departmentId={appliedFilters.departmentId}
              shiftId={appliedFilters.shiftId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySiteEmployeeAndShift}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsWeeklyUphBySiteEmployeeAndShift
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              employeeId={appliedFilters.employeeId}
              shiftId={appliedFilters.shiftId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySiteEmployeeAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsWeeklyUphBySiteEmployeeAndDepartment
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              departmentId={appliedFilters.departmentId}
              employeeId={appliedFilters.employeeId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyUphBySiteEmployeeShiftAndDepartment}>
          <ReportPageItemContainer fullHeight>
            <ExpeditorsWeeklyUphBySiteEmployeeShiftAndDepartment
              siteId={appliedFilters.siteId}
              year={appliedFilters.year}
              week={appliedFilters.week}
              shiftId={appliedFilters.shiftId}
              employeeId={appliedFilters.employeeId}
              departmentId={appliedFilters.departmentId}
            />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMargin}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMargin />
          </ReportPageItemContainer>
        </Report>

        <Report {...reportsProps.weeklyGrossMarginDetailed}>
          <ReportPageItemContainer fullHeight>
            <WeeklyGrossMarginDetailed />
          </ReportPageItemContainer>
        </Report>
      </ReportPage>
    </PageContentWithTopToolbar>
  );
};
