import { IUpdateSkill } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { PartialSchema } from 'ajv/lib/types/json-schema';
import { createSkillScheme } from './create-skill.scheme';

export const updateSkillsScheme: PartialSchema<IUpdateSkill[]> = {
  type: 'array',
  items: {
    type: 'object',
    additionalProperties: false,
    required: createSkillScheme.required,
    properties: {
      id: {
        type: 'number',
        default: '',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
      ...createSkillScheme.properties,
    },
    if: { ...createSkillScheme.if },
    then: { ...createSkillScheme.then },

    // if: {
    //   properties: {
    //     skillTerm: {
    //       const: 'expiration',
    //     },
    //   },
    // },
    // then: {
    //   properties: {
    //     // type: 'object',
    //     expirationPeriod: {
    //       nullable: false,
    //       type: 'number',
    //       errorMessage: {
    //         type: i18n.t('error.validation.required'),
    //         isNotEmpty: i18n.t('error.validation.required'),
    //       },
    //     },
    //   },
    // },
  },
};
