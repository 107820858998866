import { JSONSchemaType } from 'ajv';

// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';
import { IAdditionalFeeCategory } from '../../types';
import { createAdditionalFeeCategoryScheme } from './create-additional-fee-category.scheme';

export const updateAdditionalFeeCategoryScheme: JSONSchemaType<
  IAdditionalFeeCategory[]
> = {
  type: 'array',
  items: {
    ...createAdditionalFeeCategoryScheme,
    properties: {
      ...createAdditionalFeeCategoryScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
