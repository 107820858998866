import { JSONSchemaType } from 'ajv';
import { ICreateStaffingProviderRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createStaffingProviderScheme: JSONSchemaType<ICreateStaffingProviderRequest> = {
  type: 'object',
  required: ['staffingProvider', 'markup', 'ngroupMarkup'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    staffingProvider: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    markup: {
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.integer'),
      },
    },
    ngroupMarkup: {
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.integer'),
      },
    },
    securityName: {
      nullable: true,
      type: 'string',
    },
    ptoMarkup: {
      default: null,
      nullable: true,
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.integer'),
      },
    },
    holidayMarkup: {
      nullable: true,
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.integer'),
      },
    },
    etoMarkup: {
      nullable: true,
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.integer'),
      },
    },
    otMarkup: {
      nullable: true,
      type: 'number',
      minimum: 0,
      maximum: 100,
      errorMessage: {
        type: i18n.t('error.validation.integer'),
      },
    },
  },
};
