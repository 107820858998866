import { createReducer } from 'redux-act';
import * as actions from '../actions';
import {
  IMetatagModel,
  IPricingListResponse,
  IPricingModel,
  IPricingState,
  PricingTypeObject,
} from '../types';
import { ExportReducerCreator } from '../utils/helpers/creators/export';

// default state
const defaultState: IPricingState = {
  refreshKey: 0,
  id: NaN,
  price: NaN,
  incentivePrice: NaN,
  overtimePrice: NaN,
  minUph: NaN,
  maxUph: NaN,
  siteId: NaN,
  metadata: {},
  list: {},
  comboboxList: [],
  pricingTypesList: {},
  metaTags: [],
  is_data_requested: false,
};

/**
 * Flush actions
 */
const flushPricingState = () => ({
  ...defaultState,
  refreshKey: new Date().getTime(),
});

/**
 * Creation actions
 */
const createPricingSingleRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const createPricingSingleSuccess = (
  state: IPricingState,
  payload: IPricingModel,
) => ({
  ...state,
  ...payload,
  error: false,
  is_data_requested: false,
});

const createPricingSingleFailed = (state: IPricingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get list actions
 */
const getPricingListRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPricingListSuccess = (
  state: IPricingState,
  payload: IPricingListResponse,
) => ({
  ...state,
  list: {
    ...state.list,
    [payload.siteId]: payload.data,
  },
  error: false,
  is_data_requested: false,
});

const getPricingListFailed = (state: IPricingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Deletion actions
 */
const deletePricingRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deletePricingFailed = (state: IPricingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const deletePricingRangeRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const deletePricingRangeFailed = (state: IPricingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Updating actions
 */
const updatePricingSingleRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePricingSingleFailed = (state: IPricingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const updatePricingRangeRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const updatePricingRangeFailed = (state: IPricingState, payload: string) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

/**
 * Get combobox list actions
 */
const getPricingComboboxListRequest = (state: IPricingState) => ({
  ...state,
  error: false,
  is_data_requested: true,
});

const getPricingComboboxListSuccess = (
  state: IPricingState,
  payload: Partial<IPricingModel>[],
) => ({
  ...state,
  comboboxList: payload,
  error: false,
  is_data_requested: false,
});

const getPricingComboboxListFailed = (
  state: IPricingState,
  payload: string,
) => ({
  ...state,
  error: payload,
  is_data_requested: false,
});

const setPricingTypesList = (
  state: IPricingState,
  payload: PricingTypeObject,
) => ({
  ...state,
  error: false,
  pricingTypesList: payload,
});

const setPricingMetaTags = (
  state: IPricingState,
  payload: IMetatagModel[],
) => ({
  ...state,
  error: false,
  metaTags: payload,
});

const {
  exportRequest,
  exportFailed,
  exportSuccess,
} = new ExportReducerCreator().takeHandlers<IPricingState>();

/**
 * Pricing reducer
 */
export const pricing = createReducer<IPricingState>({}, defaultState)
  // flush actions
  .on(actions.flushPricingState, flushPricingState)
  // creation actions
  .on(actions.createPricingSingleRequest, createPricingSingleRequest)
  .on(actions.createPricingSingleSuccess, createPricingSingleSuccess)
  .on(actions.createPricingSingleFailed, createPricingSingleFailed)
  // get list actions
  .on(actions.getPricingListRequest, getPricingListRequest)
  .on(actions.getPricingListSuccess, getPricingListSuccess)
  .on(actions.getPricingListFailed, getPricingListFailed)
  // deletion actions
  .on(actions.deletePricingRequest, deletePricingRequest)
  .on(actions.deletePricingFailed, deletePricingFailed)
  .on(actions.deletePricingRangeRequest, deletePricingRangeRequest)
  .on(actions.deletePricingRangeFailed, deletePricingRangeFailed)
  // updating actions
  .on(actions.updatePricingSingleRequest, updatePricingSingleRequest)
  .on(actions.updatePricingSingleFailed, updatePricingSingleFailed)
  .on(actions.updatePricingRangeRequest, updatePricingRangeRequest)
  .on(actions.updatePricingRangeFailed, updatePricingRangeFailed)
  // get combobox list actions
  .on(actions.getPricingComboboxListRequest, getPricingComboboxListRequest)
  .on(actions.getPricingComboboxListSuccess, getPricingComboboxListSuccess)
  .on(actions.getPricingComboboxListFailed, getPricingComboboxListFailed)
  // export actions
  .on(actions.exportPricingRequest, exportRequest)
  .on(actions.exportPricingSuccess, exportSuccess)
  .on(actions.exportPricingFailed, exportFailed)
  .on(actions.setPricingTypesList, setPricingTypesList)
  .on(actions.setPricingMetaTags, setPricingMetaTags);

export default pricing;
