export * from './grid';
export * from './roles';
export * from './skill-levels';
export * from './daysOfWeek';
export * from './authorization';
export * from './paths';
export * from './modelsToDelete';
export * from './menuItems';
export * from './hours-options';
export * from './week-start-day';
export * from './notifications';
export * from './headerTooltipToPageTitleMapper';
export * from './payment-terms';
export * from './department-incentive';
export * from './incentive';

export const DEFAULT_ROUTE = '/landing';

/**
 * Fake id value to be used by different selectors
 */
export const DUMMY_ID = -1;

export const QS_PARSER_DEFAULT_DEPTH = 10;

// MIME TYPES
export const CSV_MIME_TYPE = 'text/csv';
export const XLSX_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export enum XAuthType {
  qsjwt = 'qsjwt',
  jwt = 'jwt',
}
export const MAIN_MENU_WIDTH = {
  expanded: 260,
  collapsed: 64,
};

export const HEADER_HEIGHT = 48;
