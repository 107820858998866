import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDataFetcherWithData } from 'src/modules/utils/hooks/common/reports';
import { SynchronizedCharts } from 'src/components/Charts/2d/SynchronizedCharts';
import {
  ChartContainer,
  ChartContainerBody,
  ChartContainerHeader,
  ChartContainerUtils,
} from 'src/components/Charts/ChartContainer';
import { useSynchronizedChartRequiredUtils } from 'src/components/Charts/common';

export const FinancialReportSynchronizedChart = () => {
  const { t } = useTranslation();

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const ref1 = React.useRef<Highcharts.Chart | null>(null);
  const ref2 = React.useRef<Highcharts.Chart | null>(null);
  const ref3 = React.useRef<Highcharts.Chart | null>(null);

  const {
    exportCharts,
    toggleFullScreen,
  } = useSynchronizedChartRequiredUtils();

  const { fetchData, data, isDataLoading } = useDataFetcherWithData<any>(
    '/financial-tracker/weekly-synchronized',
    [{ series: [], categories: [] }],
  );

  const series = React.useMemo(() => {
    return data.series
      ? data.series.map((item: any) => ({
          ...item,
          unit: item.name !== 'numberOfSites' ? '$' : '',
          title: {
            align: 'left',
            text: t(`financial_tracker.${item.name}`),
            style: {
              fontSize: '12px',
              fontWeight: '400',
            },
          },
        }))
      : [];
  }, [data, t]);

  const formatter = React.useCallback((item: any, value: number) => {
    if (item.name !== 'numberOfSites') {
      return `${value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}`;
    }

    return `${value}`;
  }, []);

  const mapChartsRefToCharts = () => {
    return [ref1, ref2, ref3]
      .filter((ref) => Boolean(ref.current))
      .map((ref) => (ref.current as any)?.chart);
  };

  React.useEffect(() => {
    fetchData({});
  }, [fetchData]);

  return (
    <ChartContainer>
      <ChartContainerHeader
        title={t('financial_reports.revenue_cost_number_of_sites')}
      >
        <ChartContainerUtils
          onToggleFullScreen={() => {
            if (containerRef.current) {
              toggleFullScreen(mapChartsRefToCharts(), containerRef.current);
            }
          }}
          onExport={() => {
            exportCharts(mapChartsRefToCharts(), {
              filename: t('financial_reports.revenue_cost_number_of_sites'),
            });
          }}
        />
      </ChartContainerHeader>
      <ChartContainerBody
        ref={containerRef}
        isLoading={isDataLoading}
        sx={{
          display: 'block',
        }}
        childrenContainerSx={{ display: 'block' }}
      >
        <SynchronizedCharts
          chartsRefs={[ref1, ref2, ref3]}
          series={series}
          categories={data.categories}
          chartHeight={230}
          formatter={formatter}
        />
      </ChartContainerBody>
    </ChartContainer>
  );
};
