import React from 'react';
import { TableContext } from 'src/components/Table/TableContext';
import ComboBox, {
  ComboBoxOption,
  IComboboxProps,
} from 'src/components/ComboBox';
import { ICommonFilterProps } from '../../types';

export interface IEntityBySiteComboboxOption extends ComboBoxOption {
  sites: number[];
}

interface EntitiesBySiteComboBoxFilterProps
  extends Omit<IComboboxProps, 'value' | 'name' | 'options'>,
    ICommonFilterProps {
  options: IEntityBySiteComboboxOption[];
  siteIdFilterName?: string;
}

export const EntitiesBySiteComboBoxFilter: React.FC<EntitiesBySiteComboBoxFilterProps> = ({
  value,
  name,
  options,
  siteIdFilterName = 'siteId',
  ...restComboBoxProps
}) => {
  const { setFilter, getFilterByName, useInitFilterEffect } = React.useContext(
    TableContext,
  );

  const valueId = getFilterByName(name) ?? value;

  const entities = React.useMemo(() => {
    const siteId = getFilterByName(siteIdFilterName);

    if (!siteId) {
      return options;
    }

    return options.filter((option) => option.sites.includes(siteId as number));
  }, [getFilterByName, options, siteIdFilterName]);

  const comboBoxValue =
    entities.find(
      (option) =>
        option.id === (typeof valueId === 'string' ? valueId : Number(valueId)),
    ) || null;

  const onSelectOption = (
    e: React.SyntheticEvent<Element, Event>,
    value: ComboBoxOption | null,
  ) => {
    setFilter(name, value?.id);
  };

  useInitFilterEffect(name, value);

  return (
    <ComboBox
      id={name}
      value={comboBoxValue}
      options={entities}
      onChange={onSelectOption}
      {...restComboBoxProps}
    />
  );
};
