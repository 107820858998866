import { ListWithSummaryActionCreator } from '../../utils/helpers/creators/listWithSummary';
import { IProductionWeeklyCpuModel } from '../../types/productionCpuReports';
import { ExportActionCreator } from '../../utils/helpers/creators/export';

const prefix = 'CardinalHealthWeeklyCpu';
const actionCreator = new ListWithSummaryActionCreator(prefix);

// get list actions
export const {
  getListRequest: getCardinalHealthWeeklyCpuListRequest,
  getListSuccess: getCardinalHealthWeeklyCpuListSuccess,
  getListFailed: getCardinalHealthWeeklyCpuListFailed,
} = actionCreator.takeListActions<IProductionWeeklyCpuModel>();

// get count actions
export const {
  getCountRequest: getCardinalHealthWeeklyCpuCountRequest,
  getCountSuccess: getCardinalHealthWeeklyCpuCountSuccess,
  getCountFailed: getCardinalHealthWeeklyCpuCountFailed,
} = actionCreator.takeCountActions();

// exporting actions
export const {
  exportReportRequest: exportCardinalHealthWeeklyCpuRequest,
  exportReportSuccess: exportCardinalHealthWeeklyCpuSuccess,
  exportReportFailed: exportCardinalHealthWeeklyCpuFailed,
} = new ExportActionCreator(prefix).takeExportActions();
