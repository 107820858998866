import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateEmployee } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createEmployeeScheme } from './create-employee.scheme';
import { TFunction } from 'i18next';

export const updateEmployeesScheme: JSONSchemaType<
  (ICreateEmployee & { parentId?: number })[]
> = {
  type: 'array',
  items: {
    ...createEmployeeScheme,
    type: 'object',
    required: createEmployeeScheme.required,
    properties: {
      ...createEmployeeScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
    if: { properties: { active: { const: true } } },
    then: {
      properties: {
        separationdate: {
          const: null,
          errorMessage: {
            const: i18n.t(
              'error.validation.not_applicable_with_active_employee',
            ),
          },
        },
        terminationReasonId: {
          const: null,
          errorMessage: {
            const: i18n.t(
              'error.validation.not_applicable_with_active_employee',
            ),
          },
        },
      },
    },
    else: {
      properties: {
        terminationReasonId: {
          not: { const: null },
          errorMessage: i18n.t(
            'error.validation.required_for_inactive_employee',
          ),
        },
        separationdate: {
          not: { const: null },
          errorMessage: i18n.t(
            'error.validation.required_for_inactive_employee',
          ),
        },
        comments: {
          not: { const: null },
          type: 'string',
          minLength: 5,
          errorMessage: (i18n.t as TFunction)(
            'error.validation.not_less_than_n_letters',
            {
              n: 5,
            },
          ),
        },
      },
    },
  },
};
