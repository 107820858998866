import { JSONSchemaType } from 'ajv';
import { ICreateRoleRequest } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createRoleScheme: JSONSchemaType<ICreateRoleRequest> = {
  type: 'object',
  required: ['name'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    name: {
      default: '',
      isNotEmpty: true,
      type: 'string',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
      },
    },
    shouldExtend: {
      type: 'boolean',
      nullable: true,
      default: false,
    },
    toExtend: {
      type: 'number',
      nullable: true,
    },
  },
  if: {
    properties: {
      shouldExtend: {
        const: true,
      },
    },
  },
  then: {
    required: ['toExtend'],
    properties: {
      toExtend: {
        isNotEmpty: true,
        type: 'number',
        errorMessage: {
          type: 'To Extend is required',
        },
      },
    },
  },
};
