import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageContentChildContainer,
  PageContentTab,
  PageContentTabPanel,
  PageContentTabs,
  PageContentTabsList,
} from 'src/components/PageContent';
import { FilterPanelContextProvider } from 'src/components/FilterPanel/FilterPanelContext';
import { tabsPaths, useHasUserAccessToViewTab } from 'src/config';
import { ChartsDashboardHeader } from '../../components/ChartsDashboard';
import { ExecutiveDashboard } from './panels/ExecutiveDashboard';
import { FinancialDashboard } from './panels/FinancialDashboard';

export const ExecutiveSummary = () => {
  const { t } = useTranslation();

  const hasUserAccessToViewExecutiveDashboardTab = useHasUserAccessToViewTab(
    tabsPaths.EXECUTIVE_SUMMARY_EXECUTIVE_DASHBOARD_TAB,
  );

  const hasUserAccessToViewFinancialDashboardTab = useHasUserAccessToViewTab(
    tabsPaths.EXECUTIVE_SUMMARY_FINANCIAL_DASHBOARD_TAB,
  );

  const defaultActiveTab = React.useMemo(() => {
    if (hasUserAccessToViewExecutiveDashboardTab) {
      return 0;
    }
    if (hasUserAccessToViewFinancialDashboardTab) {
      return 1;
    }
    return null;
  }, [
    hasUserAccessToViewExecutiveDashboardTab,
    hasUserAccessToViewFinancialDashboardTab,
  ]);

  const [activeTab, setActiveTab] = React.useState<number | null>(
    defaultActiveTab,
  );

  const getPageTitleByActiveTabNumber = (activeTabNumber: number | null) => {
    if (activeTabNumber === 0) {
      return (
        <PageContentChildContainer sx={{ pt: 2 }} fullHeight={false}>
          <ChartsDashboardHeader>
            {t('main_menu.executive_reports.summary')}
          </ChartsDashboardHeader>
        </PageContentChildContainer>
      );
    }
    if (activeTabNumber === 1) {
      return (
        <PageContentChildContainer sx={{ pt: 2 }} fullHeight={false}>
          <ChartsDashboardHeader>
            {t('financial_reporting_dashboard.page_title')}
          </ChartsDashboardHeader>
        </PageContentChildContainer>
      );
    }

    // @@@TODO: render appropriate title based on user role
    return '';
  };

  React.useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return (
    <PageContentTabs
      value={activeTab}
      onChange={(_, value) =>
        setActiveTab(value !== null ? Number(value) : value)
      }
    >
      <PageContentTabsList>
        {hasUserAccessToViewExecutiveDashboardTab && (
          <PageContentTab value={0}>
            {t('main_menu.executive_reports.summary_config')}
          </PageContentTab>
        )}
        {hasUserAccessToViewFinancialDashboardTab && (
          <PageContentTab value={1}>
            {t('main_menu.executive_reports.financial_dashboard')}
          </PageContentTab>
        )}
      </PageContentTabsList>

      {getPageTitleByActiveTabNumber(activeTab)}

      {activeTab === 0 && hasUserAccessToViewExecutiveDashboardTab && (
        <PageContentTabPanel value={0}>
          <FilterPanelContextProvider>
            <ExecutiveDashboard />
          </FilterPanelContextProvider>
        </PageContentTabPanel>
      )}
      {activeTab === 1 && hasUserAccessToViewFinancialDashboardTab && (
        <PageContentTabPanel value={1}>
          <FilterPanelContextProvider>
            <FinancialDashboard />
          </FilterPanelContextProvider>
        </PageContentTabPanel>
      )}
    </PageContentTabs>
  );
};
