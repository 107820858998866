import { JSONSchemaType } from 'ajv';
import { IPointCollectionModel } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../../utils/i18n';

export const createPointCollectionScheme: JSONSchemaType<Pick<
  IPointCollectionModel,
  'numberOfPoints' | 'noReturn' | 'verbalWarning' | 'writtenWarning'
>> = {
  type: 'object',
  required: ['numberOfPoints'],
  additionalProperties: false,
  errorMessage: {
    required: i18n.t('error.validation.required'),
  },
  properties: {
    numberOfPoints: {
      default: NaN,
      isNotEmpty: true,
      type: 'number',
      errorMessage: {
        isNotEmpty: i18n.t('error.validation.required'),
        type: i18n.t('error.validation.required'),
      },
    },
    noReturn: {
      nullable: true,
      type: 'boolean',
    },
    verbalWarning: {
      nullable: true,
      type: 'boolean',
    },
    writtenWarning: {
      nullable: true,
      type: 'boolean',
    },
  },
};
