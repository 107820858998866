import { JSONSchemaType } from 'ajv';
import { i18n } from '../../utils/i18n';
import { ICreateSiteTaktExportingSettings } from '../../types';
// don't make this shorter since builder might face with circular dependencies
import { createSiteTaktExportingSettingScheme } from './create-site-takt-exporting-settings.scheme';

export const updateSiteTaktExportingSettingScheme: JSONSchemaType<
  ICreateSiteTaktExportingSettings[]
> = {
  type: 'array',
  items: {
    ...createSiteTaktExportingSettingScheme,
    properties: {
      ...createSiteTaktExportingSettingScheme.properties,
      id: {
        type: 'number',
        isNotEmpty: true,
        errorMessage: {
          isNotEmpty: i18n.t('error.validation.required'),
        },
      },
    },
  },
};
