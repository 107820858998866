import { shallowEqual, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { map, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  useFilter,
  usePointsSummaryDefaultFilters,
  useSupervisorCombobox,
} from '../../../../modules/utils/hooks';
import { useFetchReport } from '../../../../modules/utils/hooks/common/reports';
import EnhancedTable, {
  HeadCell,
  ITableSyncProps,
} from '../../../../components/EnhancedTable';
import { ITableFilter } from '../../../../components/EnhancedTable/EnhancedTableFilter';
import { IPointSummaryReport } from '../../../../modules/types';
import { useReportExportProps } from '../../../../modules/utils/hooks/reports.hooks';
import { NoIcon, YesIcon } from '../../../../components/Icons';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { ComboBoxOption } from '../../../../components/ComboBox';
import { getSupervisorsAndGMsBySitesComboboxList } from 'src/modules/selectors/user';

const reportUrl = apiEndpoints.POINTS_TOTAL_EARNED;

export const PointsSummary = () => {
  const { t } = useTranslation();

  const defaultFilter = usePointsSummaryDefaultFilters();

  const fetchSupervisorsCombobox = useSupervisorCombobox();

  useEffect(() => {
    fetchSupervisorsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch supervisors providers list from store
  const supervisors = useSelector(
    getSupervisorsAndGMsBySitesComboboxList,
    shallowEqual,
  );

  const headCells: HeadCell[] = [
    {
      id: 'siteName',
      disablePadding: false,
      label: t('points.site_name'),
    },
    {
      id: 'supervisor',
      disablePadding: false,
      disableSorting: true,
      label: t('points.supervisor'),
    },
    { id: 'firstName', disablePadding: false, label: t('points.first_name') },
    { id: 'lastName', disablePadding: false, label: t('points.last_name') },
    { id: 'active', disablePadding: false, label: t('points.emp_active') },
    { id: 'points', disablePadding: false, label: t('points.total_points') },
  ];

  const filters: ITableFilter[] = [
    {
      name: 'firstName',
      label: t('points.first_name'),
      operator: 'like',
    },
    {
      name: 'lastName',
      label: t('points.last_name'),
      operator: 'like',
    },
    {
      name: 'supervisorId',
      label: t('points.supervisor'),
      operator: 'eq',
      type: 'combobox',
      options: supervisors as ComboBoxOption[],
    },
    {
      name: 'points',
      label: t('points.points'),
      operator: 'gte',
    },
    {
      name: 'active',
      label: t('points.emp_active'),
      operator: 'eq',
      type: 'buttonGroup',
      config: {
        buttons: [
          { value: undefined, label: t('common.all') },
          { value: true, label: t('common.active') },
          { value: false, label: t('common.unactive') },
        ],
      },
    },
  ];

  const initialFilterData = React.useMemo(
    () => pick(defaultFilter.where, ['active']),
    [defaultFilter.where],
  );

  const { filterList, filterCount } = useFilter(defaultFilter);

  const { data, fetchData, isDataLoading } = useFetchReport<
    IPointSummaryReport
  >(reportUrl);

  const exportProps = useReportExportProps(reportUrl);

  const { items, count } = data;

  const computedList = useMemo(
    () =>
      map(items, (item) => ({
        ...item,
        supervisor: `${item.supervisorFirstName} ${item.supervisorLastName}`,
        active: item.active ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
      })),
    [items],
  );

  useEffect(() => {
    fetchData(filterList, filterCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  // handle table synchronization
  const onSync = (props: ITableSyncProps) => {
    const { order, page, rowsPerPage, where: rawWhere } = props;
    const offset = page * rowsPerPage;
    const where = {
      ...rawWhere,
      ...defaultFilter.where,
    };

    fetchData(
      {
        filter: {
          limit: rowsPerPage,
          offset,
          where,
          order,
        },
      },
      { filter: { where } },
    );
  };

  return (
    <EnhancedTable
      data={computedList}
      count={count}
      disableQsFilters
      isTableDataLoading={isDataLoading}
      initialFilterData={initialFilterData}
      headCells={headCells}
      filters={filters}
      selectIndex=""
      defaultOrderBy="points"
      tableName={t('points.table_name.points_summary')}
      disableSelection
      onSync={onSync}
      exportProps={exportProps}
    />
  );
};
