import React from 'react';
import { filter, isEmpty, map, memoize, omit, reduce } from 'lodash';
import { createSelector } from 'reselect';
import { IdsArray, IStoreState, IUpdateSiteImportingSettings } from '../types';
import { NoIcon, YesIcon } from 'src/components/Icons';

export const getSiteImportingSettingsRefreshKey = (state: IStoreState) =>
  state.siteImportingSettings.refreshKey;

export const getSiteImportingSettingsData = (state: IStoreState) =>
  state.siteImportingSettings;

/**
 * Get server error
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getServerError = ({ siteImportingSettings }: IStoreState) =>
  siteImportingSettings.error;

/**
 * Get siteImportingSettings list
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getSiteImportingSettingsList = ({
  siteImportingSettings,
}: IStoreState) => siteImportingSettings.list;

/**
 * Get getComboboxSitesImportingList list
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getComboboxSitesImportingList = ({
  siteImportingSettings,
}: IStoreState) => siteImportingSettings.comboboxSitesImportingList;

/**
 * Recreate list structure to be used in EnhancedTable component
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getSiteImportingSettingsTable = createSelector(
  getSiteImportingSettingsList,
  (siteImportingSettings) =>
    map(filter(siteImportingSettings, (s) => !isEmpty(s.clientKey))),
);
export const getSiteImportingSettingsTableWithParsedRows = createSelector(
  getSiteImportingSettingsTable,
  (list) =>
    map(list, (item) => {
      return {
        ...item,
        transformOnSync: item.transformOnSync ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
        isLocalInfile: item.isLocalInfile ? (
          <YesIcon color="primary" />
        ) : (
          <NoIcon color="primary" />
        ),
      };
    }),
);

/**
 * Get siteImportingSettings by array of ids
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getSiteImportingSettingsByIds = createSelector(
  getSiteImportingSettingsList,
  (siteImportingSettings) =>
    memoize((ids: IdsArray) => {
      const initial: IUpdateSiteImportingSettings[] = [];
      return reduce(
        siteImportingSettings,
        (acc, cur) => {
          if (ids.includes(`${cur.id}`)) {
            acc.push(omit(cur, ['site']));
          }
          return acc;
        },
        initial,
      );
    }),
);

/**
 * Get siteImportingSettings count
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getSiteImportingSettingsCount = ({
  siteImportingSettings,
}: IStoreState) => siteImportingSettings.count;

/**
 * Get client keys
 * @param siteImportingSettings - State siteImportingSettings
 */
export const getClientKeys = ({ siteImportingSettings }: IStoreState) =>
  siteImportingSettings.clientKeys;

export const getIsSiteImportingSettingsDataLoading = createSelector(
  getSiteImportingSettingsData,
  (data) => data.isDataLoading || data.isDataManaging,
);
