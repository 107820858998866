import React from 'react';
import {
  FilterPanelCombobox,
  IFilterPanelComboboxProps,
} from '../FilterPanelCombobox';
import { ClientKey } from 'src/modules/constants';
import { useSelector } from 'react-redux';
import { useFetchSitesCombobox } from 'src/modules/utils';
import { getCurrentRole } from 'src/modules/selectors/auth';
import { getSitesComboboxList } from 'src/modules/selectors/site';
import { get, map } from 'lodash';
import { ComboBoxOption } from 'src/components/ComboBox';

type IFilterPanelSitesComboboxProps = Omit<
  IFilterPanelComboboxProps,
  'options' | 'value'
> & {
  filterId: string;
  onRemoveFilter?: (filterId: string) => void;
  fieldAsId?: 'id' | 'name' | 'uuid';
  clientKey?: ClientKey;
  excludeTestSites?: boolean;
  value?: number | null;
};

export const FilterPanelSitesCombobox = ({
  filterId,
  fieldAsId = 'id',
  clientKey,
  excludeTestSites,
  value,
  ...props
}: IFilterPanelSitesComboboxProps) => {
  const currentRole = useSelector(getCurrentRole);
  const fetchSitesCombobox = useFetchSitesCombobox(clientKey, excludeTestSites);

  React.useEffect(() => {
    fetchSitesCombobox();
  }, [currentRole, fetchSitesCombobox]);

  const sites = useSelector(getSitesComboboxList);

  const options = (React.useMemo(
    () =>
      map(sites, (site) => ({
        id: site[fieldAsId],
        name: `${site.name} (${get(site, 'client.name')})`,
      })),
    [fieldAsId, sites],
  ) as unknown) as ComboBoxOption[];

  const comboboxValue = options.find((site) => site.id === value);

  return (
    <FilterPanelCombobox
      value={comboboxValue ?? null}
      filterId={filterId}
      options={options}
      {...props}
    />
  );
};
