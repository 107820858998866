import { JSONSchemaType } from 'ajv';
import { IEmailData } from '../types';
// don't make this shorter since builder might face with circular dependencies
import { i18n } from '../utils/i18n';

export const emailFormScheme: JSONSchemaType<IEmailData> = {
  type: 'object',
  required: ['to'],
  additionalProperties: false,
  errorMessage: {
    required: {
      to: i18n.t('error.validation.required'),
    },
  },
  properties: {
    to: {
      type: 'string',
      format: 'email',
      errorMessage: {
        format: i18n.t('error.validation.email'),
      },
    },
    from: {
      type: 'string',
      format: 'email',
      nullable: true,
      errorMessage: {
        format: i18n.t('error.validation.email'),
      },
    },
    subject: {
      type: 'string',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.string'),
      },
    },
    cc: {
      type: 'string',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.string'),
      },
    },
    bcc: {
      type: 'string',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.string'),
      },
    },
    message: {
      type: 'string',
      nullable: true,
      errorMessage: {
        type: i18n.t('error.validation.string'),
      },
    },
  },
};
