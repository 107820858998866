import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { Api } from '../utils';
import * as actions from '../actions';
import {
  ICountResponse,
  IFilter,
  ISagaAction,
  IWhere,
  ListWithSummary,
  IFilledAssociatesModel,
  ISagaActionBind,
} from '../types';
import { createExportSaga } from '../utils/helpers/creators/export';

/**
 * Get filledAssociates list
 */
export const getFilledAssociatesListRequestSaga = function* (
  action: ISagaAction<IFilter>,
): SagaIterator {
  try {
    const response: ListWithSummary<IFilledAssociatesModel> = yield call(
      Api.FilledAssociates.list,
      action.payload,
    );
    yield put(actions.getFilledAssociatesListSuccess(response));
  } catch (e) {
    yield put(actions.getFilledAssociatesListFailed());
  }
};

/**
 * Get filledAssociates count
 */
export const getFilledAssociatesCountRequestSaga = function* (
  action?: ISagaAction<IWhere>,
): SagaIterator {
  try {
    const response: ICountResponse = yield call(
      Api.FilledAssociates.count,
      action?.payload,
    );
    yield put(actions.getFilledAssociatesCountSuccess(response));
  } catch (e) {
    yield put(actions.getFilledAssociatesCountFailed());
  }
};

export const autobind: ISagaActionBind<any>[] = [
  {
    action: actions.exportFilledAssociatesRequest,
    saga: createExportSaga({
      apiCall: Api.FilledAssociates.export,
      actionFailed: actions.exportFilledAssociatesFailed,
      actionSuccess: actions.exportFilledAssociatesSuccess,
    }),
  },
  {
    action: actions.getFilledAssociatesListRequest,
    saga: getFilledAssociatesListRequestSaga,
  },
  {
    action: actions.getFilledAssociatesCountRequest,
    saga: getFilledAssociatesCountRequestSaga,
  },
];
