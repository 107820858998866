import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Drawer } from '@mui/joy';
import { map, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { IdsArray, IStoreState, IUpdateWorkdays } from 'src/modules/types';
import { useFetchShiftsCombobox, useValidate } from 'src/modules/utils/hooks';
import {
  DrawerBody,
  DrawerBodyContent,
  DrawerFooter,
  DrawerHeader,
} from 'src/components/_ui-kit/Drawer';
import { FormFieldContainer } from 'src/components/Form/FormFieldContainer';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Api } from 'src/modules/utils';
import { updateWorkdaysScheme } from 'src/modules/schemes';
import FormikCheckbox from 'src/components/Formik/FormikCheckbox';
import { ifSiteHasLunch } from 'src/modules/selectors/site';
import FormikCombobox from 'src/components/Formik/FormikCombobox';
import { ComboBoxOption } from 'src/components/ComboBox';
import { getShiftsComboboxList } from 'src/modules/selectors/shift';
import { getCurrentRole } from 'src/modules/selectors/auth';

interface IShiftUpdateProps {
  isOpen: boolean;
  ids: IdsArray;
  onUpdate: () => void;
  onClose: () => void;
}

export const WorkdaysUpdate: React.FC<IShiftUpdateProps> = ({
  isOpen,
  ids,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const hasLunch = useSelector(ifSiteHasLunch);

  const fetchShiftsCombobox = useFetchShiftsCombobox();

  // get user role
  const currentRole = useSelector(getCurrentRole, shallowEqual);

  const [initialValues, setInitialValues] = React.useState<
    Array<IUpdateWorkdays>
  >([]);

  React.useEffect(() => {
    if (isOpen) {
      Api.Workdays.list({
        filter: { where: { id: { inq: ids } } },
      }).then((data) => setInitialValues(data.map((i) => omit(i, ['shift']))));
    }
  }, [hasLunch, ids, isOpen]);

  useEffect(() => {
    fetchShiftsCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole]);

  const shifts = useSelector(
    (_state) => getShiftsComboboxList(_state as IStoreState),
    shallowEqual,
  );

  const validate = useValidate(updateWorkdaysScheme);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate,
    onSubmit: async (data: IUpdateWorkdays[]) => {
      await Api.Workdays.bulkUpdate(data);
      formik.resetForm();
      onUpdate();
    },
  });

  const onReset = () => {
    formik.resetForm();
  };

  const getDays = React.useCallback(
    (index: number) =>
      ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((i) => (
        <FormFieldContainer key={i}>
          <FormikCheckbox
            id={`${index}.${i}`}
            formik={formik}
            label={t(`common.${i}`)}
          />
        </FormFieldContainer>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values, formik.errors, t],
  );

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerHeader onCloseClick={onClose}>
        {t('workdays.bulk_update_title')}
      </DrawerHeader>
      <DrawerBody>
        {map(formik.values, (incentivePrice, index) => (
          <DrawerBodyContent key={incentivePrice.id}>
            <FormFieldContainer>
              <FormikCombobox
                required
                disabled
                formik={formik}
                id={`${index}.shiftId`}
                label={t('workdays.shift')}
                placeholder={t('workdays.shift')}
                options={shifts as ComboBoxOption[]}
                errorMode="onFieldChange"
              />
            </FormFieldContainer>

            {getDays(index)}
          </DrawerBodyContent>
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ActionsBar
          onReset={onReset}
          onApply={formik.handleSubmit}
          onCancel={onClose}
          applyButtonType="submit"
        />
      </DrawerFooter>
    </Drawer>
  );
};
