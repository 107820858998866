import React from 'react';
import { Divider, List, Sheet, Typography } from '@mui/joy';
import { ListItemWithCheckbox } from 'src/components/_ui-kit/ListItem';
import { useTranslation } from 'react-i18next';
import { HeadCellWithVisibilityFlag } from '..';
import { ActionsBar } from 'src/components/_ui-kit/ActionsBar';
import { Drawer, DrawerBody } from 'src/components/_ui-kit/Drawer';

const allColumnsOptionIndex = -1;

interface IEnhancedTableColumnsSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (cells: HeadCellWithVisibilityFlag[]) => void;
  cellsWithVisibilityFlag: HeadCellWithVisibilityFlag[];
}

export const EnhancedTableColumnsSelector = ({
  isOpen,
  onApply,
  onClose,
  cellsWithVisibilityFlag,
}: IEnhancedTableColumnsSelectorProps) => {
  const { t } = useTranslation();

  const [
    filteredCellsWithVisibilityFlag,
    setFilteredCellsWithVisibilityFlag,
  ] = React.useState(cellsWithVisibilityFlag);

  const isAllCellsSelected = React.useMemo(
    () => filteredCellsWithVisibilityFlag.every((cell) => cell.isVisible),
    [filteredCellsWithVisibilityFlag],
  );

  const isAllCellCheckboxIndeterminate = React.useMemo(() => {
    return (
      filteredCellsWithVisibilityFlag.some(({ isVisible }) => isVisible) &&
      filteredCellsWithVisibilityFlag.some(({ isVisible }) => !isVisible)
    );
  }, [filteredCellsWithVisibilityFlag]);

  const onSelectAllClick = () => {
    const isAllSelected = filteredCellsWithVisibilityFlag.every(
      (cell) => cell.isVisible,
    );

    setFilteredCellsWithVisibilityFlag((prev) =>
      prev.map((cell) => ({ ...cell, isVisible: !isAllSelected })),
    );
  };

  const onListItemClick = (isVisible: boolean, cellId: number | string) => {
    setFilteredCellsWithVisibilityFlag((prev) =>
      prev.map((cell) => ({
        ...cell,
        isVisible: cell.id === cellId ? isVisible : cell.isVisible,
      })),
    );
  };

  const onReset = () => {
    setFilteredCellsWithVisibilityFlag(cellsWithVisibilityFlag);
  };

  const handleApply = () => {
    onApply(filteredCellsWithVisibilityFlag);
    onClose();
  };

  React.useEffect(() => {
    setFilteredCellsWithVisibilityFlag(cellsWithVisibilityFlag);
  }, [cellsWithVisibilityFlag]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right">
      <DrawerBody>
        <List
          sx={{
            p: 1,
            bgcolor: 'inherit',
          }}
          component="ul"
        >
          <Sheet sx={{ ml: 2, bgcolor: 'inherit' }}>
            <ListItemWithCheckbox
              id={allColumnsOptionIndex}
              isChecked={isAllCellsSelected}
              isIndeterminate={isAllCellCheckboxIndeterminate}
              onClick={onSelectAllClick}
            >
              <Typography noWrap fontWeight="medium" level="text_sm">
                {t('common.select_all')}
              </Typography>
            </ListItemWithCheckbox>
          </Sheet>
          <Divider />
          {Object.values(filteredCellsWithVisibilityFlag).map((cell) => (
            <Sheet key={cell.id} sx={{ ml: 2, bgcolor: 'inherit' }}>
              <ListItemWithCheckbox
                id={cell.id}
                isChecked={cell.isVisible}
                onClick={onListItemClick}
              >
                <Typography noWrap fontWeight="medium" level="text_sm">
                  {cell.label}
                </Typography>
              </ListItemWithCheckbox>
            </Sheet>
          ))}
        </List>
        <Sheet
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        ></Sheet>
      </DrawerBody>
      <Sheet
        sx={{
          p: 2,
          borderBottomWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 1,
          borderStyle: 'solid',
          borderColor: 'gray.200',
          bgcolor: 'inherit',
        }}
      >
        <ActionsBar
          onReset={onReset}
          onCancel={onClose}
          onApply={handleApply}
        />
      </Sheet>
    </Drawer>
  );
};
